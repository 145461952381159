import store from '../store'
import find from 'lodash/find'


export function isUserOnline(userId) {	
	let isOnline=false
	const state = store.getState()
	const orgPresenceMembers=state.orgPresenceMembers
	if(orgPresenceMembers && orgPresenceMembers[userId]){
		isOnline=true
	}
	return isOnline
}
