import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import ToDoItem from '../../components/playground/ToDoItem'
import ToDoItemAlt from '../../components/playground/ToDoItemAlt'
import ResizablePanel from '../../components/playground/ResizablePanel'
import MultiplayerPlaygroundSelection from '../../components/playground/MultiplayerPlaygroundSelection'
import AccentColorSwatch from '../../components/playground/AccentColorSwatch'
import SpritesheetImage from '../../components/misc/SpritesheetImage'
import DoodleAvatar from '../../components/misc/DoodleAvatar'
//import SignIn from '../../components/playground/SignIn'

import WarpImage from '../../components/playground/WarpImage'
import IssueInlineExpandPrototype from '../../components/playground/IssueInlineExpandPrototype'
//import DocIssue from '../../components/playground/DocIssue'
//import LinearProjectIcon from '../../components/misc/LinearProjectIcon'
import LinearYarnPaletteMap from '../../components/playground/LinearYarnPaletteMap'
import Icon from '../../components/misc/Icon'
import LoadingPage from '../../components/LoadingPage'
import SignInPageTest from '../../components/playground/SignInPageTest'
import {getFigmaEmbedForId} from '../../utils/getFigmaEmbedForId'
import FigmaView from '../../components/figma/FigmaView'
import FigmaViewAnimatedZoomedModal from '../../components/figma/FigmaViewAnimatedZoomedModal'

import TLDrawTestContainer from './TLDrawTestContainer'
import WhiteboardModal from '../../components/whiteboards/WhiteboardModal'

import deepgram_nova from '../recordingTestData/deepgram_nova.json'
import NicoleTranscriptProto from './transcriptPlayground/TranscriptProto'
import TranscriptTest from './transcriptPlayground/TranscriptTest'

import RecordingPlaybackModalContainer from '../../components/recordingPlaybackModal/RecordingPlaybackModalContainer'


const boundingMaxContainerDimensions={
	width: 720,
	height: 500
}


class PlaygroundTestPage extends Component {

	constructor(props) {
		super(props)		
		
		this.state={			

			isExpandedIndex: null,					
			showModal: true,

			isExpandedIndex: null,		
			zoomed: false,
			showRecordingPlaybackModal: true,

		}
	} 



	render(){  

		const {zoomed} = this.state

		// const embedId='1421484731' // apple music
		// //const embedId='281279025' // iphone 14
		// const embedObj = getFigmaEmbedForId(embedId)	
	//	console.log(deepgram_nova)
		const transcript=deepgram_nova.results.channels[0].alternatives[0].transcript
		const paragraphs=deepgram_nova.results.channels[0].alternatives[0].paragraphs.paragraphs
		//console.log(paragraphs)

		return (	  	
			<div className='playgroundPage'>

				<TLDrawTestContainer/>


				<div className='playgroundPage-iconTest'>
					<Icon name='effects' />
				</div>
					{/*}
				<TranscriptTest/>
	
			
				<button onClick={()=>{this.setState({showRecordingPlaybackModal: !this.state.showRecordingPlaybackModal})}}>
					Open Recording Modal
				</button>

				<RecordingPlaybackModalContainer 
					showModal={this.state.showRecordingPlaybackModal}
					openModal={()=>{this.setState({showRecordingPlaybackModal: true})}}
					closeModal={()=>{this.setState({showRecordingPlaybackModal: false})}}
				/>
			<NicoleTranscriptProto/>
			{/*}*/}
			
			

				
				<WhiteboardModal 
					showModal={this.state.showModal}
					closeModal={()=>{this.setState({showModal: false})}}

				/>
			</div>   	
		)
	}
}



function mapStateToProps(state,ownProps) {


	return {
			//figmaEmbeds:state.figmaEmbeds
	}
}


export default withRouter(connect(mapStateToProps,
	{}
)(PlaygroundTestPage))	



	// {embedObj &&
	// 				<FigmaView 
	// 					embedId={embedId}
	// 					embedObj={embedObj}							
	// 					boundingMaxContainerDimensions={boundingMaxContainerDimensions}					
	// 					backgroundMaxWidth // if not cropped, fit full width. useful for mobile/portrait images.
	// 					disableMotion={false} // if	true, all animations are instant. useful for thumbnails. also probs a user setting in future.		
	// 					zoomed={zoomed}						
	// 					zoom={()=>{this.setState({zoomed: true})}}
	// 					unzoom={()=>{this.setState({zoomed: false})}}
	// 					toggleZoom={()=>{this.setState({zoomed: !zoomed})}}				
	// 					backgroundPadding={0}
	// 					backgroundImage={null}
	// 					deviceFrame={null}
	// 					deviceShadow={false}
	// 				/>
	// 			}

	// 			{embedObj && 
	// 				<FigmaViewAnimatedZoomedModal 
	// 					embedId={embedId}
	// 					embedObj={embedObj}						
	// 					boundingMaxContainerDimensions={boundingMaxContainerDimensions}					
	// 					backgroundMaxWidth // if not cropped, fit full width. useful for mobile/portrait images.				
	// 					disableMotion={false} // if	true, all animations are instant. useful for thumbnails. also probs a user setting in future.		
	// 					zoomed={zoomed}
	// 					zoom={()=>{this.setState({zoomed: true})}}
	// 					unzoom={()=>{this.setState({zoomed: false})}}
	// 					toggleZoom={()=>{this.setState({zoomed: !zoomed})}}
	// 					backgroundPadding={0}
	// 					backgroundImage={null}
	// 					deviceFrame={null}
	// 					deviceShadow={false}
	// 				/>
	// 			}
					
				
