import React from 'react'
import Icon from '../../misc/Icon'
import ReactCursorPosition from 'react-cursor-position';
import * as Tooltip from '@radix-ui/react-tooltip';

class DocImageZoomedModalBtnBtn extends React.Component{  
 

	render(){ 

		 const {
		    detectedEnvironment: {
		      isMouseDetected = false,
		      isTouchDetected = false
		    } = {},
		    elementDimensions: {
		      width = 0,
		      height = 0
		    } = {},
		    isActive = false,
		    isPositionOutside = false,
		    position: {
		      x = 0,
		      y = 0
		    } = {}
		  } = this.props


		  let tooltipAlign = 'center'
		  if(this.props.leftEdge){
		  	tooltipAlign = 'start'
		  }
		  if(this.props.rightEdge){
		  	tooltipAlign = 'end'
		  }
		
		
		return (
			<React.Fragment>
				<ReactCursorPosition>
					  <Tooltip.Provider>
					    <Tooltip.Root delayDuration={this.props.tooltipLongDelay ? 800 : 250}>
					      <Tooltip.Trigger asChild>
					      	<span tabIndex={0}>
						      	<button disabled={this.props.disabled} onClick={this.props.onClickFunction} className={'doc-zoomedImageModal-btn ' + (this.props.label ? ' doc-zoomedImageModal-btn--iconAndLabel ' : ' doc-zoomedImageModal-btn--iconOnly ') + (this.props.active ? ' doc-zoomedImageModal-btn--active ' : ' ') + (this.props.captionIcon ? ' doc-zoomedImageModal-btn--captionIcon ' : ' ') + (this.props.name ? ` doc-zoomedImageModal-btn--${this.props.name} ` : '')}>
											{this.props.iconName &&
											<div className='doc-zoomedImageModal-btn-iconContainer'>											
												<Icon name={this.props.iconName} />											
											</div>
											}

											{this.props.captionIcon && 
												<div className='doc-zoomedImageModal-btn-captionIcon'>
													CC
												</div>
											}

											{this.props.children &&
												<div className='doc-zoomedImageModal-btn-childrenContainer'>
													{this.props.children}
												</div>
											}
											
											{this.props.label && 
												<div className='doc-zoomedImageModal-btn-label'>
													{this.props.label}
												</div>
											}
											

											{!isPositionOutside &&
												<div style={{top: `${y}px`, left: `${x}px`}} className='doc-zoomedImageModal-btn-spotlight' />
											}
										</button>
									</span>
					      </Tooltip.Trigger>				
					      {this.props.tooltipLabel &&	      
				        <Tooltip.Content side={this.props.tooltipAbove ? "top" : "bottom"} align={tooltipAlign} className="tooltip tooltip--imageModal">
				          {this.props.tooltipLabel}
				        </Tooltip.Content>				
				        }	      
					    </Tooltip.Root>
					  </Tooltip.Provider>

					
				</ReactCursorPosition>
			</React.Fragment>
			
		)
	}
}




class DocImageZoomedModalBtn extends React.Component{  
 
  constructor(){
    super()
    this.state={
      // zoomed: false,  
      // mouseMoved:false
    }
  }  

	render(){ 

		
		
		return (			
			<div className={'doc-zoomedImageModal-btnContainer ' + (this.props.disabled ? ' doc-zoomedImageModal-btnContainer--disabled ' : '')}>
				<ReactCursorPosition>
					 <DocImageZoomedModalBtnBtn 
					 	onClickFunction={this.props.onClickFunction}
					 	iconName={this.props.iconName}
					 	label={this.props.label}
					 	tooltipLabel={this.props.tooltipLabel}
					 	tooltipAbove={this.props.tooltipAbove}
					 	tooltipLongDelay={this.props.tooltipLongDelay}
					 	active={this.props.active}
					 	captionIcon={this.props.captionIcon}
					 	disabled={this.props.disabled}
					 	leftEdge={this.props.leftEdge}
					 	rightEdge={this.props.rightEdge}
					 	name={this.props.name}				
					 	children={this.props.children}	 		
					 />
				</ReactCursorPosition>
			</div>
			
		)
	}
}

export default DocImageZoomedModalBtn
