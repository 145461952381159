import {fetch,put} from '../api'
import {showToastNotification} from './toastNotification'


export function fetchUserSettings() {
	return (dispatch) => fetch(`/settings`)
		.then((response) => {
			dispatch({ type: 'FETCH_USER_SETTINGS_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}



export function updateUserSettings(issueMentionSettings) {
	const requestBody={
		issueMentionSettings:issueMentionSettings
	}
	return (dispatch) => put(`/settings`,requestBody)
		.then((response) => {
			const actionType="saveDefaultIssueMentionDisplayProperties"   
      dispatch(showToastNotification(actionType))
			dispatch({ type: 'UPDATE_USER_SETTINGS_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}

