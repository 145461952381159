import {fetch,put,post,deleteRequest} from '../api'
import {showToastNotification} from './toastNotification'
import { Mixpanel } from '../Mixpanel'
import {getTemplateForId} from '../utils/templates/getTemplateForId'

export function fetchArchivedTemplates(){
	return (dispatch) => fetch(`/archived-templates`)
	.then((response) => {
		dispatch({ type: 'FETCH_ARCHIVED_TEMPLATES_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

export function restoreArchivedTemplate(templateId){
	return (dispatch) =>{
		dispatch({ type: 'RESTORE_TEMPLATE_REQUEST', templateId})
		return put(`/templates/${templateId}/restore`)
		.then((response) => {
			dispatch(fetchArchivedTemplates())
			dispatch(fetchTemplates())
			const templateObj=getTemplateForId(templateId)
			Mixpanel.track('restore_template',{templateId:templateId,templateName:templateObj.name,templateDescription:templateObj.description})
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}


export function fetchTemplates(){
	return (dispatch) => fetch(`/templates`)
	.then((response) => {
		dispatch({ type: 'FETCH_TEMPLATES_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

export function archiveTemplate(templateId){
	const templateObj=getTemplateForId(templateId)
	return (dispatch) =>{
		dispatch({ type: 'ARCHIVE_TEMPLATE_REQUEST', templateId})
		return deleteRequest(`/templates/${templateId}`)
		.then((response) => {
			dispatch(fetchArchivedTemplates())
			dispatch({ type: 'ARCHIVE_TEMPLATE_SUCCESS', templateId})
			const actionType="archivedTemplate"
			const userName=templateObj.name
    	dispatch(showToastNotification(actionType,userName))
			Mixpanel.track('archive_template',{templateId:templateId,templateName:templateObj.name,templateDescription:templateObj.description})
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

export function updateTemplateNameRequest(templateId,name){
	const requestBody={
		name:name
	}
	return (dispatch) => put(`/templates/${templateId}/name`,requestBody)
	.then((response) => {
		dispatch({ type: 'UPDATE_TEMPLATE_SUCCESS', response })
		const templateObj=getTemplateForId(templateId)
		Mixpanel.track('update_template_name',{templateId:templateId,templateName:name,templateDescription:templateObj.description})
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

export function updateTemplateDescriptionRequest(templateId,description){
	const requestBody={
		description:description
	}
	return (dispatch) => put(`/templates/${templateId}/description`,requestBody)
	.then((response) => {
		dispatch({ type: 'UPDATE_TEMPLATE_SUCCESS', response })
		const templateObj=getTemplateForId(templateId)
		Mixpanel.track('update_template_description',{templateId:templateId,templateName:templateObj.name,templateDescription:description})
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}



// export function createTemplateRequest(docId){
// 	const requestBody={
// 		docId:docId
// 	}
// 	return (dispatch) => post(`/templates`,requestBody)
// 	.then((response) => {
// 		dispatch({ type: 'CREATE_TEMPLATE_SUCCESS', response })
// 		dispatch({type:'SHOW_NEW_TEMPLATE_MODAL',newTemplateId:response.template_id})
// 		return response
// 	})
// 	.catch((error) => {
// 		console.log(error)
// 		return error
// 	})
// }




export function createTemplateRequest(docId,templateName,templateDescription){
	const requestBody={
		docId:docId,
		templateName:templateName,
		templateDescription:templateDescription
	}
	return (dispatch) => post(`/templates`,requestBody)
	.then((response) => {
		dispatch({ type: 'CREATE_TEMPLATE_SUCCESS', response })
		// const actionType="createdTemplate"
		// const userName=response.template_id
  //   dispatch(showToastNotification(actionType,userName))
    Mixpanel.track('create_template',{templateName:templateName,templateDescription:templateDescription})
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}



// export function createTemplateRequest(docId){
// 	const requestBody={
// 		docId:docId
// 	}
// 	return (dispatch) => post(`/templates`,requestBody)
// 	.then((response) => {
// 		dispatch({ type: 'CREATE_TEMPLATE_SUCCESS', response })
// 		const actionType="createdTemplate"
// 		console.log(response)
// 		const userName=response.template_id
//     dispatch(showToastNotification(actionType,userName))
// 	//	dispatch({type:'SHOW_NEW_TEMPLATE_MODAL',newTemplateId:response.template_id})
// 		return response
// 	})
// 	.catch((error) => {
// 		console.log(error)
// 		return error
// 	})
// }




export function setDocTemplateRequest(docId,template){
	const requestBody={
		template:template
	}
	return (dispatch) =>{
		dispatch({ type: 'UPDATE_DOC_TEMPLATE_REQUEST', docId,template })
		return put(`/docs/${docId}/template`,requestBody)
			.then((response) => {
				dispatch({ type: 'FETCH_A_DOC_SUCCESS', response })
				const templateObj=getTemplateForId(template)
				let name=''
				if(templateObj){
					name=template.name
				}
				Mixpanel.track('set_doc_template',{docId:docId,templateId:template,templateName:name})
				return response
			})
			.catch((error) => {
				console.log(error)
				return error
		})
	}
}