import findIndex from 'lodash/findIndex'

export default function (state = {}, action) {
	switch (action.type) {

		case 'START_UPLOAD':
			const uploadId=action.uploadId
			return {...state,
				[`${action.uploadId}`]:{progress:0}
			}

		case 'UPDATE_UPLOAD_PROGRESS':
			if(action.progress==100){
				return state
			}else{
				const progress=action.progress
					return {...state,
						[`${action.uploadId}`]:{
							progress:progress
					}
				}
			}
		

		case 'FINISH_UPLOAD':
			const newState = {...state};
			delete newState[action.uploadId];
			return newState;

    default:
      return state;

    case 'SIGN_OUT':
			return {}
	}
}

