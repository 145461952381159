import React from 'react'


class UnauthErrorPage extends React.Component{  


  render(){ 
    


    return (
      <div>
      403 Error!!! 
      You don't have access to this doc
      </div>
    )
  }
}

export default UnauthErrorPage
