import React from 'react'
import Icon from '../../components/misc/Icon'

class DocPlaceholderKey extends React.Component{  
	render(){			
	  return (			
			<div className={'doc-placeholder-key ' + (this.props.noMargin ? ' doc-placeholder-key--noMargin ' : '  ') + (this.props.period ? ' doc-placeholder-key--period ' : '  ') + (this.props.label ? ' doc-placeholder-key--label ' : ' doc-placeholder-key--iconOnly ')}>
				{this.props.children}
			</div>						
	  )	
	}
}


const placeholders=[	
	"Make every detail perfect and limit the number of details to perfect.",
	"If everything seems under control, you're not going fast enough.",
	"If you are not embarrassed by the first version of your product, you’ve launched too late.",
	"Don’t worry about failure; you only have to be right once.",
	"Innovation is saying no to a thousand things",
	"Talent wins games. Teamwork wins championships.",	
	"Teamwork makes the dream work.",
	"Do things that don't scale.",
	"Whatever you do, always give 100%. Unless you're donating blood.",
	"The best time to plant a tree was ten years ago. The second best time is now.",
	"Let’s go invent tomorrow rather than worrying about what happened yesterday."
]



class DocPlaceholderReactComponent extends React.Component{  

	render(){			
		const {docId}=this.props	
		//Use last digit (system generated docs will be sequential so e.g. lots of project docs will have the same first digit)
		const lastChar=docId.substring(docId.length-1,docId.length)
		const lastDigit=parseInt(lastChar, 10)
		let index=0
		if(lastDigit!==0){
			if(lastDigit<placeholders.length){
				index=lastDigit
			}else{
				index=placeholders.length % lastDigit 
			}	
		}
		const placeholder = placeholders[index]
	  return (
			<div className='doc-placeholder-innerContainer'>
				<div className='doc-para'>
					{placeholder}					
				</div>
				<div className='doc-placeholder-break--big' />	
								
				<div className='doc-para'>
					<DocPlaceholderKey ><Icon name='mentionSemibold'/></DocPlaceholderKey> to mention docs, projects, or Linear issues.
				</div>
				<div className='doc-placeholder-break--small' />
				<div className='doc-para'>
					<DocPlaceholderKey>/</DocPlaceholderKey> to insert things.
				</div>
				{/*}
				<div className='doc-placeholder-break--small' />
				<div className='doc-para'>
					<DocPlaceholderKey label>Paste</DocPlaceholderKey> images or Linear issues
				</div>
				*/}
										
				
			</div>
					
	  )	
	}
}

export default DocPlaceholderReactComponent