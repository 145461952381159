export default function (state = null, action) {
	switch (action.type) {
	
   
    case 'SIGN_OUT':
      return null
   
    case 'FETCH_GOOGLE_INTEGRATION_SUCCESS':
      return action.response

    case 'REMOVE_GOOGLE_INTEGRATION_SUCCESS':
      return null

    default:
      return state;
	}
}
