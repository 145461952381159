import {fetch,put,post,batchedFetch} from '../api'
import {showToastNotification} from './toastNotification'
import {closeArchivedTab} from '../utils/closeArchivedTab'
import {openDoc,makeUrlForDoc} from '../utils/openDoc'
import {randomID} from '../utils/randomID'
import {getDocForMeeting} from '../utils/meetings/getDocForMeeting'
import {fetchDocs} from './docs'
import {getCurrentUserId} from '../utils/getCurrentUser'
import moment from 'moment'
import { Mixpanel } from '../Mixpanel'
import {addLinkToLinear} from './docs'

let defaultMeeting={
	// meeting_start:"2023-07-07T17:00:00.079Z",
	// meeting_end:"2023-07-07T17:30:00.079Z",
	time_updated_at:null,
	type:'',
	template:null,
	participants:[],
	external_participants:[]
}

////FETCH MEETINGS///////
export function fetchMeetings(){
	return (dispatch) => batchedFetch(`/meetings`)
	.then((response) => {
		dispatch({ type: 'FETCH_MEETINGS_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

export function fetchAMeeting(meetingId){
	return (dispatch) => fetch(`/meetings/${meetingId}`)
	.then((response) => {
		const meeting = response
		dispatch({ type: 'FETCH_A_MEETING_SUCCESS', meeting })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}

//// CREATE MEETINGS
export function createNewMeetingFromCalEventRequest(meetingId,docId,doc,event,history) {
	const requestBody={
		meeting_id:meetingId,
		event_id:event.id,
		doc:doc,
		event:event
	}

	let meeting={...defaultMeeting}
	meeting.meeting_id=meetingId 
	meeting.participants=[]
	meeting.meeting_start = event.start.dateTime
	meeting.meeting_end = event.end.dateTime
	meeting.meeting_date=moment(event.start.dateTime).format('YYYY-MM-DD')
	meeting.event_id=event.id
	// if(event.recurringEventId){
	// 	console.log('is recurrring!!')
	// 	meeting.type='teamSync'
	// }
	let isRecurring=false
	if(event.recurringEventId){
		meeting.recurring_event=event.recurringEventId
		isRecurring=true
	}
	return (dispatch) =>{ 
		dispatch({ type: 'CREATE_DOC_REQUEST', doc })
		dispatch({ type: 'CREATE_MEETING_REQUEST', meeting })
		//openDoc(history,doc['doc_id'])
		 return  post(`/events/meetings`,requestBody)
			.then((response) => {
				const meeting=response.meeting
				const meetingDoc=response.meetingDoc
				dispatch({ type: 'CREATE_MEETING_SUCCESS', meeting })
				dispatch({ type: 'CREATE_DOC_SUCCESS', response:meetingDoc})
				Mixpanel.track('create_meeting')
				Mixpanel.track('create_meeting_from_g_cal',{isRecurring:isRecurring})
				//dispatch(syncRecurringEvents())

				return response
			})
			.catch((error) => {
				return error
			})
	}
}

export function createMeetingWithId(meetingType,meetingId,docId,doc,history,shouldAddLinkToLinear,projectId) {
	let meeting={...defaultMeeting}
	meeting.meeting_id=meetingId 
	const currentUserId=getCurrentUserId()
	meeting.participants=[currentUserId]
	meeting.type=meetingType
	const requestBody={
		meeting_id:meetingId,
		doc:doc,
		type:meetingType,
	}
	return (dispatch) =>{ 
		dispatch({ type: 'CREATE_DOC_REQUEST', doc })
		dispatch({ type: 'CREATE_MEETING_REQUEST', meeting })
		openDoc(history,doc['doc_id'])
		return  post(`/meetings`,requestBody)
			.then((response) => {
				const meeting=response.meeting
				const meetingDoc=response.meetingDoc
				dispatch({ type: 'CREATE_MEETING_SUCCESS', meeting })
				dispatch({ type: 'CREATE_DOC_SUCCESS', response:meetingDoc})
				Mixpanel.track('create_meeting')
				if(shouldAddLinkToLinear){
					dispatch(addLinkToLinear(meetingDoc.doc_id,projectId))
				}
				return response
			})
			.catch((error) => {
				return error
			})
		  }
}


export function createMeetingForDateRequest(date,meetingId,docId,doc,history) {
	let meeting={...defaultMeeting}
	meeting.meeting_id=meetingId 
	const currentUserId=getCurrentUserId()
	meeting.participants=[currentUserId]
	meeting.meeting_date=date
	const requestBody={
		meeting_id:meetingId,
		doc:doc,
		meeting_date:date
	}
	return (dispatch) =>{ 
		dispatch({ type: 'CREATE_DOC_REQUEST', doc })
		dispatch({ type: 'CREATE_MEETING_REQUEST', meeting })
		openDoc(history,doc['doc_id'])
		return  post(`/meetings`,requestBody)
			.then((response) => {
				const meeting=response.meeting
				const meetingDoc=response.meetingDoc
				dispatch({ type: 'CREATE_MEETING_SUCCESS', meeting })
				dispatch({ type: 'CREATE_DOC_SUCCESS', response:meetingDoc})
				Mixpanel.track('create_meeting')
				Mixpanel.track('create_meeting_for_date',{date:date})
				return response
			})
			.catch((error) => {
				return error
			})
		  }
}




export function addMeetingToGCalRequest(meeting) {
	const event_id=randomID()
	const doc=getDocForMeeting(meeting.meeting_id)
	let stem=`https://doc.yarn.so`
	const path=makeUrlForDoc(doc.doc_id)  
	const url=stem+path
	const requestBody={
		meeting:meeting,
		docUrl:url,
		event_id:event_id
	}

	return (dispatch) => post(`/google-cal/events`,requestBody)
		.then((response) => {
			dispatch({ type: 'FETCH_A_MEETING_SUCCESS', meeting:response })
			const actionType="addedMeetingToCal"   
			dispatch(showToastNotification(actionType))
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
}


///// UPDATE MEETINGS
export function updateMeetingParticipantsRequest(meetingId,participants,externalParticipants) {
	const requestBody={
		participants:participants,
		externalParticipants:externalParticipants
	}
	return (dispatch) =>{
		dispatch({ type: 'UPDATE_MEETING_PARTICIPANTS_REQUEST', meetingId,participants,externalParticipants})
		return put(`/meetings/${meetingId}/participants`,requestBody)
		.then((response) => {
			Mixpanel.track('update_meeting_participants',{participants:participants,participantsCount:participants.length,externalParticipants:externalParticipants,externalParticipantsCount:externalParticipants.length})
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

export function updateMeetingTypeRequest(meetingId,meetingType) {
	const requestBody={
		type:meetingType
	}
	return (dispatch) =>{
		dispatch({ type: 'UPDATE_MEETING_TYPE_REQUEST', meetingId,meetingType})
		return put(`/meetings/${meetingId}/type`,requestBody)
		.then((response) => {
			dispatch({ type: 'UPDATE_MEETING_SUCCESS', meeting:response})
			Mixpanel.track('set_meeting_type',{meetingId:meetingId,meetingType:meetingType})

			return response
		})
		.catch((error) => {
			
			return error
		})
	}
}

//For team syncs we can pick a template (for other meeting types the template is the same as meetingtype)
export function updateMeetingTemplateRequest(meetingId,template) {
	const requestBody={
		template:template
	}
	return (dispatch) =>{
		dispatch({ type: 'UPDATE_MEETING_TEMPLATE_REQUEST', meetingId,meetingType:template})
		return put(`/meetings/${meetingId}/template`,requestBody)
		.then((response) => {
			dispatch({ type: 'UPDATE_MEETING_SUCCESS', response})
			return response
		})
		.catch((error) => {
			
			return error
		})
	}
}


export function updateMeetingTimesRequest(meetingId,meetingDate,startTime,endTime) {
	const requestBody={
		meeting_start:startTime,
		meeting_end:endTime,
		meeting_date:meetingDate
	}
	return (dispatch) =>{
		dispatch({ type: 'UPDATE_MEETING_TIMES_REQUEST', meetingId,meetingDate,startTime,endTime})
		return put(`/meetings/${meetingId}/time`,requestBody)
		.then((response) => {
			Mixpanel.track('update_meeting_time')
			dispatch({ type: 'UPDATE_MEETING_SUCCESS', response})
			return response
		})
		.catch((error) => {
			return error
		})
	}
}

/// PROJECT CONNECTIONS

export function handleNewProjectConnectionPusher(connection){
	return (dispatch) => {
		dispatch({ type: 'CREATE_MEETING_PROJECT_CONNECTION_SUCCESS', response:connection})
	}
}

export function handleProjectConnectionDeletedPusher(connectionId){
	return (dispatch) => {
		dispatch({ type: 'ARCHIVE_PROJECT_CONNECTION', connectionId})
	}
}

export function archiveMeetingProjectConnectionRequest(connectionId) {
	return (dispatch) =>{ 
		dispatch({ type: 'ARCHIVE_PROJECT_CONNECTION', connectionId })
		return put(`/connection/${connectionId}/archive`)
		.then((response) => {
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

export function createMeetingProjectConnectionRequest(meetingId,projectId) {
	const id=randomID()
	const requestBody={
		project:projectId,
		connection_id:`${id}`,
		meeting:meetingId
	}
	return (dispatch) =>{
		dispatch({ type: 'CREATE_MEETING_PROJECT_CONNECTION_REQUEST', connection:requestBody})
		return put(`/meetings/${meetingId}/projects`,requestBody)
		.then((response) => {
			dispatch({ type: 'CREATE_MEETING_PROJECT_CONNECTION_SUCCESS', response})
			Mixpanel.track('create_project_connection',{meetingId:meetingId,projectId:projectId})
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

export function fetchMeetingProjectConnecions() {
	return (dispatch) =>{
		return fetch(`/project-connections`)
		.then((response) => {
			dispatch({ type: 'FETCH_MEETING_PROJECT_CONNECTIONS_SUCCESS', response})
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

//// ARCHIVE
//archive meeting and doc locally (doc gets archived on back end)
export function archiveMeeting(meetingId,docId) {
	return (dispatch) =>{ 
		dispatch({ type: 'ARCHIVE_MEETING_REQUEST', meetingId,docId })
		closeArchivedTab(docId)
		return put(`/meetings/${meetingId}/archive`)
		.then((response) => {
			dispatch({ type: 'ARCHIVE_MEETING_SUCCESS', meetingId})
			Mixpanel.track('archive_meeting')
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

//////// RECURRING EVENTS
// export function syncRecurringEvents() {
// 	console.log('SYNC RECURRING')
// 	return (dispatch) =>{ 
// 		return put(`/events/recurring`)
// 		.then((response) => {
// 			dispatch(fetchMeetings())
// 			dispatch(fetchDocs()) //temp hack to fetch new meeting docs
// 			return response
// 		})
// 		.catch((error) => {
// 			console.log(error)
// 			return error
// 		})
// 	}
// }
//TODO non recurring meetings can be team syncs
export function updateTeamForRecurringEventRequest(eventId,team) {
	const requestBody={
		team:team
	}
	return (dispatch) =>{ 
		return put(`/recurring/${eventId}/team`,requestBody)
		.then((response) => {
			dispatch(fetchMeetings())
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

// export function createMeeting(meetingType,history) {
// 	const requestBody={
// 		meetingName:meetingType,
// 		type:meetingType
// 	}
// 	return (dispatch) => post(`/meetings`,requestBody)
// 		.then((response) => {
// 			const meeting=response.meeting
// 			const meetingDoc=response.meetingDoc
// 			dispatch({ type: 'CREATE_MEETING_SUCCESS', meeting })
// 			dispatch({ type: 'CREATE_DOC_SUCCESS', response:meetingDoc})
// 			openDoc(history,meetingDoc['doc_id'])
// 			return response
// 		})
// 		.catch((error) => {
// 			return error
// 		})
// }


//Test gcal create event
// export function createMeetingWithId(meetingType,meetingId,docId,doc,history) {
// 	const requestBody={
// 		meetingName:meetingType,
// 		type:meetingType
// 	}
// 	console.log('create meeting here-------!!!!!')
// 	return (dispatch) => post(`/google-cal/events`,requestBody)
// 		.then((response) => {
// 			console.log('create gcal event response')
// 			console.log(response)
// 			// const meeting=response.meeting
// 			// const meetingDoc=response.meetingDoc
// 			// dispatch({ type: 'CREATE_MEETING_SUCCESS', meeting })
// 			// dispatch({ type: 'CREATE_DOC_SUCCESS', response:meetingDoc})
// 			// openDoc(history,meetingDoc['doc_id'])
// 			return response
// 		})
// 		.catch((error) => {
// 			console.log('create event error')
// 			console.log(error)
// 			return error
// 		})
// }


// export function createMeeting(meetingType,history) {
// 	const requestBody={
// 		meetingName:meetingType,
// 		type:meetingType
// 	}
// 	console.log('create meeting here-------!!!!!')
// 	return (dispatch) => post(`/google-cal/events`,requestBody)
// 		.then((response) => {
// 			console.log('create gcal event response')
// 			console.log(response)
// 			// const meeting=response.meeting
// 			// const meetingDoc=response.meetingDoc
// 			// dispatch({ type: 'CREATE_MEETING_SUCCESS', meeting })
// 			// dispatch({ type: 'CREATE_DOC_SUCCESS', response:meetingDoc})
// 			// openDoc(history,meetingDoc['doc_id'])
// 			return response
// 		})
// 		.catch((error) => {
// 			console.log('create event error')
// 			console.log(error)
// 			return error
// 		})
// }
