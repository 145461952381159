import React from 'react'
import {DOMSerializer} from "prosemirror-model"
import editorSchema from '../../prosemirror/schema/editorSchema'


function convertNodeToHTML(node){
  let fragment = DOMSerializer.fromSchema(editorSchema).serializeFragment(node)
  let tmp = document.createElement("div")
  tmp.appendChild(fragment)
  let html=tmp.innerHTML
  return html
}


function process(str) {

    var div = document.createElement('div');
    div.innerHTML = str.trim();

    return format(div, 0).innerHTML;
}

function format(node, level) {

    var indentBefore = new Array(level++ + 1).join('  '),
        indentAfter  = new Array(level - 1).join('  '),
        textNode;

    for (var i = 0; i < node.children.length; i++) {

        textNode = document.createTextNode('\n' + indentBefore);
        node.insertBefore(textNode, node.children[i]);

        format(node.children[i], level);

        if (node.lastElementChild == node.children[i]) {
            textNode = document.createTextNode('\n' + indentAfter);
            node.appendChild(textNode);
        }
    }

    return node;
}




class DocEditorDevOutputPanel extends React.Component{  

	render(){	
    let html
    if(this.props.pmDoc && Object.keys(this.props.pmDoc).length>0){
      html=convertNodeToHTML(this.props.pmDoc)
    }


	  return (	  	
  		<React.Fragment>
        {this.props.showEditorOutputPanel &&
          <div className='docEditor-outputPanelOuterContainer'>
            <div className='docEditor-outputPanelInnerContainer'>            
              <div className='docEditor-outputPanel'>
          			<div className='docEditor-outputPanel-title'>
                  PM Doc
                </div>
          			 <pre>
                  <code>
{/*                  {process(html)}*/}
                  {JSON.stringify(this.props.pmDoc, null, 2)}
       {/*             {JSON.stringify(html, null, 2)}*/}
                  </code>
                </pre>
          		</div>
            </div>
          </div>
        }
      </React.Fragment>
	  )
	}
}

export default DocEditorDevOutputPanel
