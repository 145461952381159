import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../../misc/Icon'
import AvatarImage from '../../../AvatarImage'
import * as Popover from '@radix-ui/react-popover';
import {jumpToCursor} from '../../../../utils/jumpToCursor'
import {pingCursorToUser} from '../../../../utils/pingCursorToUser'
import {isCurrentUser} from '../../../../utils/isCurrentUser'
import {isUserOnline} from '../../../../utils/isUserOnline'
import {openUserProfileInLinear} from '../../../../utils/openInLinear'
import moment from 'moment-timezone'



class MessagePanelHeaderFollowerMenuBtn extends React.PureComponent{  

  render(){ 
    const {remove} = this.props
    return (                  
      <button disabled={this.props.disabled} onClick={this.props.onClickFunction} className={'docEditor-messagePanel-header-follower-menu-actionBtn' + (this.props.disabled ? ' docEditor-messagePanel-header-follower-menu-actionBtn--disabled ':'') + (remove ? ' docEditor-messagePanel-header-follower-menu-actionBtn--remove ':'')}>       
        {this.props.iconName &&
          <div className='docEditor-messagePanel-header-follower-menu-actionBtn-iconContainer'>
            <Icon name={this.props.iconName} />
          </div>
        }
        <div className='docEditor-messagePanel-header-follower-menu-actionBtn-label'>
          {this.props.label}
        </div>               
      </button>
    )
  }
}


//pingCursorToUser(userId,docId,history)
class MessagePanelHeaderFollowerMenu extends React.Component{  
  render(){ 
    const {user,isCurrentUser} = this.props
    let localTime
    if(user && user.timezone){
      localTime=moment().tz(user.timezone).format('hh:mm A')
    }
    const isActive=isUserOnline(user.user_id)
    return (      
      <div className='docEditor-messagePanel-header-follower-menu popover'>             
        <div className='docEditor-messagePanel-header-follower-menu-header'>
          <div className='docEditor-messagePanel-header-follower-menu-header-avatar'>
            <AvatarImage 
              className='docEditor-messagePanel-header-follower-menu-header-avatar-avatar'
              avatarUrl={user.avatar}
              largeAvatar
            />            
          </div>
          <div className='docEditor-messagePanel-header-follower-menu-header-title'>
            <div className='docEditor-messagePanel-header-follower-menu-header-title-primary'>
              <div className='docEditor-messagePanel-header-follower-menu-header-title-primary-label'>
                {user.display_name} {isCurrentUser && <span className='docEditor-messagePanel-header-follower-menu-header-title-primary-label-you'>you</span>}
              </div>
            </div>
            <div className='docEditor-messagePanel-header-follower-menu-header-title-subtitle'>
              {localTime} local time
            </div>
          </div>
          <div className='docEditor-messagePanel-header-follower-menu-header-statusTagContainer'>
            {isActive &&
              <div className='docEditor-messagePanel-header-follower-menu-header-statusTag docEditor-messagePanel-header-follower-menu-header-statusTag--state--online'>
                <div className='docEditor-messagePanel-header-follower-menu-header-statusTag-dot' />
                Active
              </div>
            }
            {!isActive &&
              <div className='docEditor-messagePanel-header-follower-menu-header-statusTag docEditor-messagePanel-header-follower-menu-header-statusTag--state--away'>
                <div className='docEditor-messagePanel-header-follower-menu-header-statusTag-dot' />
                Away
              </div>
           }
          </div>
        </div>

        {!isCurrentUser &&
          <div className='docEditor-messagePanel-header-follower-menu-actionBtnList'>          
            


            <MessagePanelHeaderFollowerMenuBtn            
              disabled={!isActive}
              iconName='broadcast'
              label='Ping doc'
              onClickFunction={()=>{pingCursorToUser(user.user_id,this.props.docId,this.props.history)}}
            />                                                   

            <MessagePanelHeaderFollowerMenuBtn
              disabled={!isActive}
              iconName='jump'
              label='Jump to their cursor'
              onClickFunction={()=>{jumpToCursor(user.user_id,this.props.history)}}            
            />
            
            <div className='docEditor-messagePanel-header-follower-menu-actionBtnList-divider'/>    
  
            <MessagePanelHeaderFollowerMenuBtn
              iconName='linkArrow'
              label='Open Linear Profile'
              onClickFunction={()=>{openUserProfileInLinear(user.display_name)}}
            />       

            {/*} HOPEFULLY ADD BACK IN FUTURE

            <div className='docEditor-messagePanel-header-follower-menu-actionBtnList-divider'/>

            <MessagePanelHeaderFollowerMenuBtn
              iconName='slackIcon'
              label='Send link via Slack'
              disabled
            />
            
            <MessagePanelHeaderFollowerMenuBtn
              iconName='slackIcon'
              label='Send selection via Slack'
              disabled
            />
          

          <div className='docEditor-messagePanel-header-follower-menu-actionBtnList-divider'/>
          
          <div className='docEditor-messagePanel-header-follower-menu-slackMessage'>
            Message @{user.display_name} on Slack
          </div>
          */}

          {/*
          <MessagePanelHeaderFollowerMenuBtn
            remove
            label='Remove from Doc'    
            onClickFunction={()=>{this.props.removeFollowerFromDoc(user.user_id)}}        
          />     
          */}          
          </div>
        }
        
        {isCurrentUser &&
          <div className='docEditor-messagePanel-header-follower-menu-actionBtnList'>
            <MessagePanelHeaderFollowerMenuBtn
              iconName='linkArrow'
              label='Open Linear Profile'
              onClickFunction={()=>{openUserProfileInLinear(user.display_name)}}            
            />       
          </div>
         }   
        {/*
        {isCurrentUser &&
          <div className='docEditor-messagePanel-header-follower-menu-actionBtnList'>          
            <MessagePanelHeaderFollowerMenuBtn
              remove
              label='Leave Doc'
              onClickFunction={()=>{this.props.removeFollowerFromDoc(user.user_id)}}       
            />
          </div>
        }
        */}

        {/*
        <div className='docEditor-messagePanel-header-follower-menu-contributions'>
          15 edits · 4 edits
        </div>
        */}            
      </div>   
    )
  }
}


class MessagePanelHeaderFollower extends React.Component{  
  render(){ 
    const {follower,isActive} = this.props
    const currentUser=isCurrentUser(follower.user_id)
    return (           
      <div className='docEditor-messagePanel-header-follower' >        
        <div className='docEditor-messagePanel-header-follower-avatarContainer'>
          <AvatarImage
            className='docEditor-messagePanel-header-follower-avatar'
            avatarUrl={follower.avatar}
          />
        </div>
        <div className='docEditor-messagePanel-header-follower-popoverContainer'>
          <MessagePanelHeaderFollowerMenu
            user={follower}
            isCurrentUser={currentUser}
            docId={this.props.docId}
            history={this.props.history} 
          />
        </div>      
      </div>      
    )
  }
}

export default withRouter(MessagePanelHeaderFollower)
