//URL doc signatiure is docTitle-docId



export function getDocIdFromSignature(signature){
	const signatureComponentsArray=signature.split(/-/)
	if(signatureComponentsArray.length>1){
		const docId=signatureComponentsArray[signatureComponentsArray.length-1]
		return docId
	}else return null
}
