import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';

class DocFigmaEmbedEffectsToolbarSelectDevice extends React.Component{  
  
  handleFrameToggleBtnClick(selectedDeviceFrame){    
    if(this.props.deviceFrame !== selectedDeviceFrame){
    	this.props.editDeviceFrame(selectedDeviceFrame)	
    }
    else if(this.props.deviceFrame === selectedDeviceFrame){
    	this.props.editDeviceFrame(null)
    }
  }

	render(){ 
		const {device, deviceFrame} = this.props
		let deviceIsMobile = true
		if(device === 'desktop' || device === 'iPadPro11Portrait' || device === 'iPadPro129Portrait' || device === 'iPadPro11Landscape' || device === 'iPadPro129Landscape'){
			deviceIsMobile = false
		}

		const mobileDeviceFrame = `${device}`
		const tabletDeviceName = `${device}`

		let mobileDeviceBrand
		if(deviceIsMobile && device !== 'AndroidLarge' && device !== 'AndroidSmall'){
			mobileDeviceBrand = 'iPhone' 
		}
		if(deviceIsMobile && mobileDeviceBrand !== 'iPhone' ){
			mobileDeviceBrand = 'Android' 
		}

		let deviceIconName

		if(mobileDeviceBrand === 'iPhone'){
			deviceIconName = 'deviceiPhone'
		}else if(mobileDeviceBrand === 'Android'){
			deviceIconName = 'deviceAndroid'
		}else if(device === 'desktop' ){
			deviceIconName = 'deviceLaptop'
		}else if(device === 'iPadPro11Portrait' || device === 'iPadPro129Portrait'){
			deviceIconName = 'deviceiPadPortrait'
		}else if(device === 'iPadPro11Landscape' || device === 'iPadPro129Landscape'){
			deviceIconName = 'deviceiPadLandscape'
		}

		return (			
			<React.Fragment>				
				<div className='doc-figma-effectsToolbar-selectDeviceContainer'>
					{deviceIsMobile &&					
						<Tooltip.Provider>
		          <Tooltip.Root delayDuration={0}>
		            <Tooltip.Trigger asChild>                  
									<button onClick={()=>{this.handleFrameToggleBtnClick(device)}} className={'doc-figma-effectsToolbar-selectDevice-toggleBtn doc-figma-effectsToolbar-selectDevice-toggleBtn--mobile ' + (deviceFrame === mobileDeviceFrame ? ' doc-figma-effectsToolbar-selectDevice-toggleBtn--active ' : ' doc-figma-effectsToolbar-selectDevice-toggleBtn--inactive ')}>
										<div className='doc-figma-effectsToolbar-selectDevice-toggleBtn-deviceIconContainer'>
											<Icon name={deviceIconName} />
										</div>
										<div className='doc-figma-effectsToolbar-selectDevice-toggleBtn-label'>
											{mobileDeviceBrand}
										</div>
									</button>
								</Tooltip.Trigger>        
			          <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
			            {deviceFrame ? 'Hide Device' : 'Show Device'}
			          </Tooltip.Content>                          
			        </Tooltip.Root>
		        </Tooltip.Provider>
					}

					{!deviceIsMobile && device !== 'desktop' &&									
						<Tooltip.Provider>
		          <Tooltip.Root delayDuration={0}>
		            <Tooltip.Trigger asChild>                  
									<button onClick={()=>{this.handleFrameToggleBtnClick(tabletDeviceName)}}  className={'doc-figma-effectsToolbar-selectDevice-toggleBtn doc-figma-effectsToolbar-selectDevice-toggleBtn--tablet' + (deviceFrame === tabletDeviceName ? ' doc-figma-effectsToolbar-selectDevice-toggleBtn--active ' : ' doc-figma-effectsToolbar-selectDevice-toggleBtn--inactive ')}>
										<div className='doc-figma-effectsToolbar-selectDevice-toggleBtn-deviceIconContainer'>
											<Icon name={deviceIconName} />
										</div>
										<div className='doc-figma-effectsToolbar-selectDevice-toggleBtn-label'>
											iPad
										</div>
									</button>
								</Tooltip.Trigger>        
			          <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
			            {deviceFrame ? 'Hide Device' : 'Show Device'}
			          </Tooltip.Content>                          
			        </Tooltip.Root>
		        </Tooltip.Provider>
					}
					{device === 'desktop' &&									
						<Tooltip.Provider>
		          <Tooltip.Root delayDuration={0}>
		            <Tooltip.Trigger asChild>                  
									<button onClick={()=>{this.handleFrameToggleBtnClick('desktop-arc')}} className={'doc-figma-effectsToolbar-selectDevice-toggleBtn doc-figma-effectsToolbar-selectDevice-toggleBtn--desktop' + (deviceFrame === 'desktop-arc' ? ' doc-figma-effectsToolbar-selectDevice-toggleBtn--active ' : ' doc-figma-effectsToolbar-selectDevice-toggleBtn--inactive ')}>
										<div className='doc-figma-effectsToolbar-selectDevice-toggleBtn-deviceIconContainer'>
											<Icon name='deviceDesktop' />
										</div>
										<div className='doc-figma-effectsToolbar-selectDevice-toggleBtn-label'>
											Frame
										</div>
									</button>
								</Tooltip.Trigger>        
			          <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
			            {deviceFrame ? 'Hide Frame' : 'Show Frame'}
			          </Tooltip.Content>                          
			        </Tooltip.Root>
		        </Tooltip.Provider>
					}
								
				</div>			               
      </React.Fragment>						
		)
	}
}

export default DocFigmaEmbedEffectsToolbarSelectDevice
								{/* CAN ADD THINGS LIKE CHROME ETC. LATER 

								{device === 'desktop' && 
									<div className='doc-figma-effectsToolbar-selectDevice'>
										<Tooltip.Provider>
						          <Tooltip.Root delayDuration={0}>
						            <Tooltip.Trigger asChild>                  
													<button  onClick={()=>{this.handleFrameToggleBtnClick('desktop-arc')}} className={'doc-figma-effectsToolbar-selectDevice-toggleBtn doc-figma-effectsToolbar-selectDevice-toggleBtn--desktop' + (deviceFrame === 'desktop-arc' ? ' doc-figma-effectsToolbar-selectDevice-toggleBtn--active ' : ' doc-figma-effectsToolbar-selectDevice-toggleBtn--inactive ')}>
														<div className='doc-figma-effectsToolbar-selectDevice-toggleBtn-deviceIconContainer'>
															<Icon name='deviceDesktop' />
														</div>
														<div className='doc-figma-effectsToolbar-selectDevice-toggleBtn-label'>
															Frame
														</div>
													</button>	
												</Tooltip.Trigger>        
							          <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
							            {deviceFrame ? 'Hide Device' : 'Show Device'}
							          </Tooltip.Content>                          
							        </Tooltip.Root>
					        	</Tooltip.Provider>
					        	<Tooltip.Provider>
						          <Tooltip.Root delayDuration={0}>
						            <Tooltip.Trigger asChild>                  													
													<DropdownMenu.Root>
														<DropdownMenu.Trigger asChild>
															<button className='doc-figma-effectsToolbar-selectDevice-selectBtn'>
														<Icon name='chevronDown' />
													</button>
														</DropdownMenu.Trigger>   			 	
														<DropdownMenu.Content className='dropdownMenu forceDarkTheme dropdownMenu--narrow'>	       	 	
															<DropdownMenu.Item>
																Chrome
															</DropdownMenu.Item>					        
															<DropdownMenu.Item>
																macOS
															</DropdownMenu.Item>																
														</DropdownMenu.Content>
													</DropdownMenu.Root>	  

												</Tooltip.Trigger>        
							          <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
							            Select Desktop Frame
							          </Tooltip.Content>                          
							        </Tooltip.Root>
					        	</Tooltip.Provider>																										
									</div>
									}
									*/}
