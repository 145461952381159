import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import moment from 'moment'
import RoadmapsHomePageQuarterTimelineDraggableProject from './RoadmapsHomePageQuarterTimelineDraggableProject'
import {getProjectsForRoadmap} from '../../../utils/getProjectsForRoadmap'
import sortBy from 'lodash/sortBy'

const TEST_TODAY_OFFSET=0
const daysIntoFuture=20
const daysIntoPast=3
const hide_label_window_backward=3 //dont show label if it is today+= x days
const hide_label_window_forward=-2 //dont show label if it is today+= x days

function getTimelineProjectsForRoadmap(roadmap){
	const allProjects=getProjectsForRoadmap(roadmap)
	let timelineProjects=[]
	allProjects.forEach((project)=>{
		if(project.state=='started' ){
			const today = moment().startOf('day');  // Get today's date without the time component
			if(moment(project.target_date).isBefore(today)){
				// console.log('is overdue!!!')
			}else{
				timelineProjects.push(project)
			}
		}
	})
	const sortedTimelineProjects = sortBy(timelineProjects, function(project){
 		return project.name.toString().toLowerCase()
  })
	return sortedTimelineProjects
}

function formattedDateLabel(previousLabelDate,date,labelCount){
	const today = moment().startOf('day').add(TEST_TODAY_OFFSET,'days') //faks today with offset
	const differenceInDays = today.diff(date, 'days');	
	if(differenceInDays==0){
		return date.format('DD')
	}
	else if(differenceInDays !=0 && differenceInDays<hide_label_window_backward && differenceInDays>hide_label_window_forward){
		return null	
	}else{
		let isSameMonth //check if is the same month as the previous label
		if(previousLabelDate){
			isSameMonth = previousLabelDate.isSame(date, 'month');
		}
		if(isSameMonth && labelCount!=0){
			return date.format('DD')
		}else{
			return date.format('MMM DD') 
		}
	}
}

function getDatesArray(){
	let datesArray=[]
	let labelCount=0
	let previousLabelDate=null
	for (let i = (-1*daysIntoPast); i < daysIntoFuture; i++) {
		const today = moment().startOf('day').add(TEST_TODAY_OFFSET,'days')
		let isMonday=false
		let isToday=false
		let isPast=false
		let dateLabel=null			
		const newDate = today.add(i, 'days')

		const dayIndex = newDate.day()
		if(i<0){
			isPast=true
		}else if(i==0){
			isToday=true
			dateLabel= newDate.format('DD')
			// if(dateLabel){
			// 	previousLabelDate=newDate
			// }
		}
  	const formattedDate=newDate.format('YYYY-MM-DD')
		if(newDate.day() == 1){
			isMonday=true
			dateLabel=formattedDateLabel(previousLabelDate,newDate,labelCount)
			if(dateLabel && i!=0){
				labelCount+=1
				previousLabelDate=newDate
			}
		}		
		const dateObj={
			date:formattedDate,
			isMonday:isMonday,
			isToday:isToday,
			isPast:isPast,
			label:dateLabel,
			dayIndex:dayIndex,
		}

	  datesArray.push(dateObj)
	}
	return datesArray
}


class RoadmapsHomePageQuarterTimelineDate extends React.Component{  	

	render(){				
		const {dayPixelWidth, isMonday, dayIndex, isToday, isPast, date,label} = this.props

		const isNormalDay = !isMonday && !isToday && !isPast
	  return (	  	
  		<div style={{width: `${dayPixelWidth}px`}} className={'roadmapsHomePage-quarter-timeline-timeline-date ' + (isNormalDay ? ' roadmapsHomePage-quarter-timeline-timeline-date--isNormalDay ' : '' ) + (dayIndex ? ` roadmapsHomePage-quarter-timeline-timeline-date--dayIndex--${dayIndex} ` : ' roadmapsHomePage-quarter-timeline-timeline-date--dayIndex--0 ' )  + (isMonday ? ' roadmapsHomePage-quarter-timeline-timeline-date--isMonday ' : '' ) + (isToday ? ' roadmapsHomePage-quarter-timeline-timeline-date--isToday ' : '' )}>
  			<div className='roadmapsHomePage-quarter-timeline-timeline-date-line'/>  				
  			{isToday &&
  				<div className='roadmapsHomePage-quarter-timeline-timeline-date-todayLine'/>  				
  			}	
  			{label && isMonday && !isToday &&
	  			<div className='roadmapsHomePage-quarter-timeline-timeline-date-mondayLabel'>
	  				{label}
	  			</div>
  			}
  			{label && isToday &&
	  			<div className='roadmapsHomePage-quarter-timeline-timeline-date-todayLabel'>
	  				{label}
	  			</div>
  			}
  		</div>
	  )
	}
}

class RoadmapsHomePageQuarterTimelineDates extends React.Component{  	
	render(){		
		const {dates} = this.props
		return (	  	
			<div className='roadmapsHomePage-quarter-timeline-timeline-dateContainer'>
			{dates.map((date)=>{			
				return(
					<RoadmapsHomePageQuarterTimelineDate
						key={date.date}
						isMonday={date.isMonday}
						isToday={date.isToday}
						isPast={date.isPast}
						date={date.date}
						label={date.label}
						dayIndex={date.dayIndex}
						dayPixelWidth={this.props.dayPixelWidth}
					/>			
				)												
			})}
			</div>
		)
	}
}


class RoadmapsHomePageQuarterTimelineProjects extends React.PureComponent{  	

	render(){				
		const {dayPixelWidth, totalDays,timelineProjects} = this.props
	  return (	  	
  		<div className='roadmapsHomePage-quarter-timeline-timeline-projectsContainer'>
  		{dayPixelWidth && 
  			<React.Fragment>  
  				{timelineProjects.map((project)=>{
  				//	const projectStart=project.started_at || project.start_date
  					const projectStart=project.start_date
  					const today = moment().startOf('day'); 
  					const daysSinceProjectStart= moment(today).diff(moment(projectStart), 'days')
  					let startOffset
  					let previousStart=false
  					if(project.start_date){
  						if(daysSinceProjectStart>daysIntoPast){
  							previousStart=true
  						}
  						startOffset=Math.max(daysIntoPast-daysSinceProjectStart,0)
  					}
  					let timeLineDaysUntilProjectEnds //days until project ends capped out at end of timeline
  					let openEnd=false
  					let width
  					let futureEnd=false
  
  					if(project.target_date){
  						const daysUntilProjectEnd= moment(project.target_date).diff(moment(), 'days')
  						//console.log(`days until project ends------- ${daysUntilProjectEnd}`)
  						if(daysUntilProjectEnd+1>daysIntoFuture){
  							futureEnd=true
  							if(!previousStart){
  								width=(((daysIntoPast-startOffset)+daysIntoFuture)*dayPixelWidth)
  							}else{
  								width=(((daysIntoPast)+daysIntoFuture)*dayPixelWidth)
  							}

  						}else{
  							if(daysUntilProjectEnd==0){ //hacky for projects ending today TODO check all this stuff
  								width=(((daysIntoPast-startOffset)+daysUntilProjectEnd)*dayPixelWidth)
  							}else{
  								width=(((daysIntoPast-startOffset)+1+daysUntilProjectEnd)*dayPixelWidth)
  							}
  							
  						}
  						
  					}else{
  						openEnd=true
  						width=(((daysIntoPast-startOffset)+daysIntoFuture-5)*dayPixelWidth) //Put -4 so it fits on screen TODO check with jasper
  					}

  					return(	
			  			<RoadmapsHomePageQuarterTimelineDraggableProject 
			  				key={project.project_id}
			  				project={project}
			  				projectId={project.project_id}
			  				dayPixelWidth={dayPixelWidth}
			  				start={startOffset * dayPixelWidth}
	  						width={width}
	  						openEnd={openEnd}
	  						futureEnd={futureEnd}
	  						previousStart={previousStart}
	  						futureEndDate={project.target_date}
			  				daysIntoPast={daysIntoPast}	  	
			  				daysIntoFuture={daysIntoFuture}			
			  				setIsDraggingTimeline={this.props.setIsDraggingTimeline}
				  			unsetIsDraggingTimeline={this.props.unsetIsDraggingTimeline}
				  			isDraggingTimeline={this.props.isDraggingTimeline}
			  			/>
  						)
  				})}
  			</React.Fragment>



  		}
  		</div>
	  )
	}
}



class RoadmapsHomePageQuarterTimeline extends React.Component{  	

	constructor(){
    super()    
    const dates=getDatesArray()    
    this.state={
    	dates:dates,	
    }    
  }		

	calculateWidth(timelinePixelWidth, totalDays) {
		// mostly 70% width, but above 100% we apprortion the extra at 50% width
		const proportionTimeline = 0.825
		const smallerProportionalTimeline = 0.6
		const breakPointWidth = 1000

		let proportionalTimelineWidth = proportionTimeline * timelinePixelWidth
		if(timelinePixelWidth > breakPointWidth){
			proportionalTimelineWidth = (breakPointWidth * proportionTimeline) + ((timelinePixelWidth - breakPointWidth) * smallerProportionalTimeline)
		}
		const desiredWidth = Math.floor(proportionTimeline * timelinePixelWidth); // Calculate 70% of the timelinePixelWidth and round down
		if (desiredWidth % totalDays === 0) {
			// If the desired width is already divisible by totalDays, return it
			return desiredWidth;
		} else {
			// Find the closest number smaller than the desired width that is divisible by totalDays
			const smallerWidth = Math.floor(desiredWidth / totalDays) * totalDays;
			return smallerWidth;
		}
	}


	render(){		
		const {dates}=this.state
		const {quarterPixelWidth,roadmap} = this.props; // Example value
		const timelineProjects=getTimelineProjectsForRoadmap(roadmap)
		const adjustedQuarterPixelWidth = quarterPixelWidth - 2 // for borders
    const totalDays = dates.length; // Example value
    const timelinePixelWidth = this.calculateWidth(adjustedQuarterPixelWidth, totalDays);
    let dayPixelWidth = 0
    if(timelinePixelWidth){
    	dayPixelWidth = timelinePixelWidth / totalDays
    }

    const timelineProjectsCount = timelineProjects.length
  	// hardcoded – a bit messy
  	const totalHeightTimelineProject = 50
  	const timelineTopPadding = 64
  	const timelineBottomPadding = 48 // note last project doesn't have bottom margin
  	const timelinePixelHeight = Math.max(((timelineProjectsCount * totalHeightTimelineProject) + timelineTopPadding + timelineBottomPadding), 320)
 
	  return (	  	
			<div style={{height: `${timelinePixelHeight}px`, width: `${timelinePixelWidth}px`}} className='roadmapsHomePage-quarter-timeline-timelineContainer'>	  				
				<div className='roadmapsHomePage-quarter-timeline-timeline'>		
					<RoadmapsHomePageQuarterTimelineDates 
						dates={dates}
						dayPixelWidth={dayPixelWidth}
					/>

					{dayPixelWidth>0 && timelineProjects.length > 0 &&
						<RoadmapsHomePageQuarterTimelineProjects
							dayPixelWidth={dayPixelWidth}
							totalDays={totalDays}
							timelineProjects={timelineProjects}
							setIsDraggingTimeline={this.props.setIsDraggingTimeline}
							unsetIsDraggingTimeline={this.props.unsetIsDraggingTimeline}
							isDraggingTimeline={this.props.isDraggingTimeline}
						/>
					}
				</div>
			</div>
	  )
	}
}

export default RoadmapsHomePageQuarterTimeline
