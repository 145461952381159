const priorityLabels = {	
	'4':'Low',
	'3':'Medium',
	'2':'High',
	'1':'Urgent',
	'0':'No Priority',	
}



export function getPriorityLabel(priority) {	
	return priorityLabels[priority]
	
}
