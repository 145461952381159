import {Plugin,PluginKey} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import ReactDOM from 'react-dom'

// import DocTableSelectionBox from '../../../components/nodeViews/tables/DocTableSelectionBox'

import {TextSelection,NodeSelection} from "prosemirror-state"
import {getAnchorNode} from '../../utils/getAnchorNode'
import {getNodePos} from '../../utils/getNodePos'
import { TableMap,cellAround,selectedRect ,selectionCell,isInTable,CellSelection} from 'prosemirror-tables'
                                
const key = new PluginKey('tableCellPlugin')
const PARAGRAPH_SPAN_CLASSNAME="table-cell-test"

export function tableCellPlugin(mentionPluginKey) {
  return new Plugin({
    key,
    props: {
      handleKeyDown(view, e) {
        if(e.keyCode==40 || e.keyCode==38){ //prevent arrow keys if the mention menu is open
          const state=view.state
          const inTable=isInTable(state)
          const mentionMenuState=mentionPluginKey.getState(state)
          if(inTable && mentionMenuState.active){
            return true
          }
          return false
        }
      },
      decorations: (state) => {
        const {doc,selection}=state
        let decos=[]
        if(selection && isInTable(state)){
          const {$from,$head,from,$anchorCell}=selection
          const tableNode=getAnchorNode($from,'table')
          const tablePos=getNodePos(tableNode)  
          if(tableNode && tablePos){
            let rect={}
            if (selection instanceof NodeSelection) {
              const { from, to } = selection
              const cell = selectionCell(state)
              const cellNode = doc.nodeAt(cell.pos)
              const decoration = Decoration.node(cell.pos, cell.pos+cellNode.nodeSize, {
                class: 'doc-table-cell--cursorInside'
              })
              decos.push(decoration)
            }
            else if(selection instanceof TextSelection){
              const cell=selectionCell(state)  
              const cellNode=doc.nodeAt(cell.pos)
              const decoration = Decoration.node(cell.pos, cell.pos + cellNode.nodeSize, {
                  class: 'doc-table-cell--cursorInside'
                })
              decos.push(decoration)
            }
            else if(selection instanceof CellSelection){
              const map = TableMap.get(tableNode);
              const {$anchorCell,$headCell}=selection
              const rect = map.rectBetween($anchorCell.pos-tablePos-1, $headCell.pos-tablePos-1);
              selection.forEachCell((cell, pos) => {
                const cellRect = map.findCell(pos-tablePos-1);
                const isFirstRow=cellRect.top==rect.top
                const isFirstColumn=cellRect.left==rect.left
                const isLastRow=cellRect.bottom==rect.bottom 
                const isLastColumn=cellRect.right==rect.right              
                let className=`doc-table-cell--selectedCell`
                if(isFirstRow){
                  if(isFirstColumn){
                    className+=` doc-table-cell--selectedCell--nw`
                  }
                  if(isLastColumn){
                    className+=` doc-table-cell--selectedCell--ne`
                  }
                  className+=` doc-table-cell--selectedCell--n`
                }
                if(isLastRow){
                  if(isFirstColumn){
                    className+=` doc-table-cell--selectedCell--sw`
                  }
                  if(isLastColumn){
                    className+=` doc-table-cell--selectedCell--se`
                  }
                  className+=` doc-table-cell--selectedCell--s`
                }
                if(isFirstColumn){
                  if(!isFirstRow && !isLastRow){
                     className+=` doc-table-cell--selectedCell--w`
                  }
                }
                if(isLastColumn){
                  if(!isFirstRow && !isLastRow){
                     className+=` doc-table-cell--selectedCell--e`
                  }
                }
                const deco = Decoration.node(pos, pos + cell.nodeSize, {class: className});
                decos.push(deco);
              });
            }
          }
        }
        return DecorationSet.create(doc, decos)
      }
    }
  })
}



// const pos=getNodePos(tableNode)
          // const map=TableMap.get(tableNode)
          // console.log(map.map)
          // console.log(pos)
          // console.log(from)

          // const fromRelativeToTable=from-pos
          // console.log(fromRelativeToTable)

          // let cellIndexInMap

          // let offset=0 //for node boundaries
          // map.map.forEach((pos,i)=>{
          //   if(fromRelativeToTable>map.map[i]+offset){
          //     cellIndexInMap=i
          //   }
          //   offset+=1
          // })

          // console.log(`cell index-------- ${cellIndexInMap}`)




          
         
          // const cell=map.findCell(map.map[cellIndexInMap],tableNode)

          // console.log(cell)

          
          // // const cellPos=map.map[0]+pos+1

          // const dom=view.domAtPos(map.map[cellIndexInMap]+pos)
          // console.log(dom)

          // const node = dom.node.childNodes[dom.offset] 
          // let domWidth 
          // let domHeight 
          // if(node){
          //   domWidth = node.offsetWidth
          //   domHeight = node.offsetHeight
          // }
      
          // console.log(domWidth)

// let decos=[]
//         if(doc){
//           doc.descendants((node, pos) => {
//            // console.log(node.type.name)
//             if(node.type.name=='docTable'){

//             const selectionBoxDom = document.createElement("div")
//             selectionBoxDom.className="doc-table-selectionBoxContainer"
    
//             ReactDOM.render(
//               <DocTableSelectionBox
                
//               />, selectionBoxDom)



//                const widgetSpec={
//                 destroy(el){
//                   ReactDOM.unmountComponentAtNode(el)
//                 }
//                }

//              decos.push(Decoration.widget(pos+1, selectionBoxDom,widgetSpec))


//             }
//          })


 // if(doc){
 //          doc.descendants((node, pos) => {
 //           // console.log(node.type.name)
 //            if(node.type.name=='table_cell'){
 //              // console.log('here--!!!')
 //             // decos.push(Decoration.inline(pos, pos+node.nodeSize, {class:PARAGRAPH_SPAN_CLASSNAME}))
              
 //              const span=document.createElement("span")
 //              span.classList.add("doc-table-cellUIContainer")            
 //               ReactDOM.render(<DocTableCellUI
 //              />, span)

 //               const widgetSpec={
 //                destroy(el){
 //                  ReactDOM.unmountComponentAtNode(el)
 //                }
 //               }

 //             decos.push(Decoration.widget(pos+1, span,widgetSpec))


 //            }
 //         })
 //       }

// function findBoundingRectForCellSelection(view, cellSelection) {
//  // console.log(cellSelection)
//   let boundingRect = null;
//   const tablePos = cellSelection.$anchorCell.start(-1);
//   const tableDomNode = view.nodeDOM(tablePos);
//   const tableRect = tableDomNode.getBoundingClientRect();
//   cellSelection.forEachCell((node, pos) => {
//       const resolvedPos = view.state.doc.resolve(pos);
//       const domNode = view.nodeDOM(resolvedPos.pos);
//       let rect={}
//       if(domNode){
//         rect = domNode.getBoundingClientRect();
//       }
      
//       // Subtract the table's position to get a position relative to the table.
//       const relativeRect = {
//           left: rect.left - tableRect.left,
//           top: rect.top - tableRect.top,
//           right: rect.right - tableRect.left,
//           bottom: rect.bottom - tableRect.top,
//       };
//       if (boundingRect == null) {
//           // If this is the first cell, its rect is the bounding rect.
//           boundingRect = relativeRect;
//       } else {
//           // Otherwise, expand the bounding rect to include this cell's rect.
//           boundingRect = {
//               left: Math.min(boundingRect.left, relativeRect.left),
//               top: Math.min(boundingRect.top, relativeRect.top),
//               right: Math.max(boundingRect.right, relativeRect.right),
//               bottom: Math.max(boundingRect.bottom, relativeRect.bottom),
//           };
//       }
//   });
//   // The final bounding rect spans all cells in the selection.
//   // Transform it into {top, left, width, height} format.
//   return {
//       top: boundingRect.top,
//       left: boundingRect.left,
//       width: boundingRect.right - boundingRect.left,
//       height: boundingRect.bottom - boundingRect.top,
//   };
// }


// function createDeco(rect){
//   const el = document.createElement("div")
//   el.className="doc-table-selectionBox"
  

//   // ReactDOM.render(
//   //   <DocTableSelectionBox
//   //   selectCell={createCellSelection}
//   //   width={rect.width}
//   //   height={rect.height}
//   //   x={rect.top}
//   //   y={rect.left}
//   //   />, el)

//   const tablePaddingTop = 16 // a bit dirty, this is from CSS

//   el.style.top = `${Math.floor(rect.top) + tablePaddingTop}px`;
//   el.style.left = `${Math.floor(rect.left + 1)}px`;
//   el.style.width = `${Math.floor(rect.width - 3)}px`;
//   el.style.height = `${Math.floor(rect.height - 2)}px`;
//   return el
// }




// function createCellSelection(){
//  //   console.log('create cell selection0-------')
// //   // Suppose you have the view, startCellPos and endCellPos ready
// //   let view = window.view
// //   let selection=window.view.state.selection

// // // Create a new cell selection
// // let cellSelection = new CellSelection(view.state.doc.resolve(selection.$from));

// // // Apply the new selection
// // let transaction = view.state.tr.setSelection(cellSelection);
// // view.dispatch(transaction);

// }

