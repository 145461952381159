import React, { Component } from 'react'
import Icon from '../../components/misc/Icon'
import {showDocTemplatesModal} from '../../utils/templates/showDocTemplatesModal'

class SideMenuTemplateTab extends React.PureComponent{  

	render(){	
	  return (
			<div onClick={()=>{showDocTemplatesModal()}} className='sideMenu-tab sideMenu-tab--tabType--appTab sideMenu-tab--appTab--templates sideMenu-tab--inactive'>
				<div className='sideMenu-tab-iconContainer'>		
					<div className='sideMenu-tab-iconInnerContainer'>
						<Icon name='template' />												
					</div>		
				</div>
				<div className='sideMenu-tab-labelContainer'>
					<div className='sideMenu-tab-label'>
						Templates
					</div>
				</div>	
			</div>				
	  )
	}
}





export default SideMenuTemplateTab
