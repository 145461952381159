import {combineReducers} from 'redux'
import "../styles/App.scss"
import authenticationStatus from './authenticationStatus'
import user from './user'
import orgMembers from './orgMembers'
import docs from './docs'
import reactions from './reactions'
import docPresenceMembers from './docPresenceMembers'
import organization from './organization'
import theme from './theme'
import recentActivities from './recentActivities'
import collabInstances from './collabInstances'
import docScrollPositions from './docScrollPositions'
import orgPresenceMembers from './orgPresenceMembers'
import toastNotification from './toastNotification'
import messages from './messages'
import showNotionExportModal from './showNotionExportModal'
import showInsertDrawingModal from './showInsertDrawingModal'
import docEditorMediaModals from './docEditorMediaModals'
import openTabs from './openTabs'
import videoProgress from './videoProgress'
import videoModalIsZoomed from './videoModalIsZoomed'
import messageSelections from'./messageSelections'
import doNotDisturbUntil from './doNotDisturbUntil'
import pageActivities from './pageActivities'
import teams from './teams'
import projects from './projects'
import issues from './issues'
import workflowStates from './workflowStates'
import messagePluginStates from './messagePluginStates'
import projectPageSettings from './projectPageSettings'
import figmaIntegration from './figmaIntegration'
import figmaEmbeds from './figmaEmbeds'
import roadmaps from './roadmaps'
import roadmapImageUploads from './roadmapImageUploads'
import issuePanels from './issuePanels'
import userSettings from './userSettings'
import docSearchResults from './docSearchResults'
import activeThreads from './activeThreads'
import milestones from './milestones'
import projectMilestones from './projectMilestones'
import roadmapsHomePageSettings from './roadmapsHomePageSettings'
import heatmaps from './heatmaps'

import meetings from './meetings'
import googleIntegration from './googleIntegration'
import calendarEvents from './calendarEvents'
import recordings from './recordings'
import meetingProjectConnections from './meetingProjectConnections'
import messageMediaIsZoomed from './messageMediaIsZoomed'
import activeUploads from './activeUploads'
import contacts from './contacts'
import transcriptSearchResults from './transcriptSearchResults'
import transcripts from './transcripts'
import messagePanels from './messagePanels'
import highlights from './highlights'
import meetingsPageSettings from './meetingsPageSettings'
import docTemplates from './docTemplates'
import docTemplatesModal from './docTemplatesModal'
import archivedDocTemplates from './archivedDocTemplates'
import whiteboardModal from './whiteboardModal'
import whiteboardEmbeds from './whiteboardEmbeds'
import autoplayHighlight from './autoplayHighlight'
import whiteboardSettings from './whiteboardSettings'
import onboarding from './onboarding'

const rootReducer = combineReducers({
	openTabs,
	calendarEvents,
	meetingsPageSettings,
	meetingProjectConnections,
	googleIntegration,
	docSearchResults,
	issuePanels,
	activeThreads,
	theme,
	authenticationStatus,
	organization,
	user,
	docs,
	orgMembers,
	reactions,
	docPresenceMembers,
	recentActivities,
	collabInstances,
	docScrollPositions,
	orgPresenceMembers,
	toastNotification,
	messages,
	showNotionExportModal,
	showInsertDrawingModal,
	docEditorMediaModals,
	videoModalIsZoomed,
	messageSelections,
	doNotDisturbUntil,
	pageActivities,
	teams,
	projects,
	issues,
	workflowStates,
	messagePluginStates,
	projectPageSettings,
	figmaIntegration,
	figmaEmbeds,
	roadmaps,
	roadmapImageUploads,
	userSettings,
	milestones,
	projectMilestones,
	roadmapsHomePageSettings,
	heatmaps,
	meetings,
	recordings,
	messageMediaIsZoomed,
	activeUploads,
	contacts,
	transcriptSearchResults,
	transcripts,
	messagePanels,
	highlights,
	docTemplates,
	docTemplatesModal,
	archivedDocTemplates,
	whiteboardModal,
	whiteboardEmbeds,
	videoProgress,
	autoplayHighlight,
	whiteboardSettings,
	onboarding
})

export default rootReducer