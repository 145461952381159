/////Message linear issue mention
const LinearIssueMentionNodeSpec ={
    attrs: {
      issueId:{default:''},
      issueUpdateVersion:{default:0} //to bump version and force node to check for updates ////TODO make sure this updates as well as mentions in main doc
    },
    inline: true,
    group: "inline",
    draggable: true,
   //  toDOM(node) {
   //    const div = document.createElement('div');
   //    div.className = 'message-para-mention'
   //    div.innerHTML=`${node.attrs.text}`

   //    return div;
   // },
   parseDOM: [
    {
        tag: "linearIssue",
        getAttrs: (dom) => ({
          issueId: dom.getAttribute("data-issue-id"),
        })}
    ],
    toDOM(node) {
      const {issueId} = node.attrs;
      return ["div",{class:`message-para-linearIssueOuterContainer`,"data-issue-id":issueId}]
    }
    // toDOM(node) {
    //   const {issueId,layout} = node.attrs;
    //   return ["linearIssue",
    //   {"data-issue-id":issueId,
    //   "data-layout":layout
    // }]
    // }
}
    
   

  export default LinearIssueMentionNodeSpec


