import React from 'react'
import {showDocMediaModal} from '../../utils/showDocMediaModal'
import FigmaViewZoomedUI from './FigmaViewZoomedUI'
import FigmaViewZoomedUIStatus from './FigmaViewZoomedUIStatus'
import { Spring, Transition, animated, config } from 'react-spring'
import FigmaView from './FigmaView'

// From edge of modal to window
const zoomedFigmaModalOuterMargins={
	left: 20,
	right: 20,
	top: 30,
	bottom: 18,
}

// from modal to figma view
const zoomedFigmaModalInnerMargins={
	left: 0,
	right: 0,
	top: 42,
	bottom: 54,
}


function roundToFiveDecimalPlaces(number){
	return Math.round(number*100000)/100000
}


class FigmaViewAnimatedZoomedModal extends React.Component{  	
	static getDerivedStateFromProps(props, state) {
		if (props.zoomed !== state.zoomed) { //handle padding updated by another user
		let startX=0 
		let startY =0
		let startWidth=0
		let startHeight=0
		const figmaViewEl = document.getElementById(`figmaView--${props.embedId}`)	
		if(figmaViewEl){
			startY = figmaViewEl.getBoundingClientRect().top
			startX = figmaViewEl.getBoundingClientRect().left
			startWidth = figmaViewEl.getBoundingClientRect().width
			startHeight = figmaViewEl.getBoundingClientRect().height
		}
			return ({zoomed:props.zoomed,startX:startX,startY:startY,startWidth:startWidth,startHeight:startHeight}) // <- this is setState equivalent
		}
		return null
	}

  constructor(props){
    super(props)  
    this.disableCropMode=this.disableCropMode.bind(this)  
    this.onZoomAnimationRest=this.onZoomAnimationRest.bind(this)
    this.enableCropMode=this.enableCropMode.bind(this)
    this.handleClickOnView=this.handleClickOnView.bind(this)
    this.measureFigmaViewRect=this.measureFigmaViewRect.bind(this)
    this.saveCropValues=this.saveCropValues.bind(this)
    this.handleClickOnModalCenterBG=this.handleClickOnModalCenterBG.bind(this)
    this.handleZoomBGClick=this.handleZoomBGClick.bind(this)
    this.handleKeyDown=this.handleKeyDown.bind(this)
   // this.handleFigmaViewClick=this.handleFigmaViewClick.bind(this)

    this.state={
      forceMount: false,  
      cropModeActive:false,
      isCropping:false,      
      showEffectsToolbar:false,
    }
    this.measureTimeout=null
    this.disableTimeout=null
  }


  componentDidMount(){
  	window.addEventListener('keydown', this.handleKeyDown)
  	this.measureFigmaViewRect()   
  	this.resizeObserver= new ResizeObserver(entries => {
  		this.measureFigmaViewRect()
		})
		this.resizeObserver.observe(document.body)
  	const figmaViewEl = document.getElementById(`figmaView--${this.props.embedId}`)
		if(figmaViewEl){
			this.resizeObserver.observe(figmaViewEl)
		}else{
			if(process.env.REACT_APP_ENV !='production'){
				console.log('********************************************************')
				console.log('couldnt find element on mount to add resize observer')
				console.log('********************************************************')
			}
		}
  }

  componentWillUnmount(){
  	window.removeEventListener('keydown', this.handleKeyDown)
  	if(this.resizeObserver){
  		this.resizeObserver.disconnect()
  	}
  	if(this.measureTimeout){
  		clearTimeout(this.measureTimeout)
  	}
  	if(this.disableTimeout){
  		clearTimeout(this.disableTimeout)
  	}
  }

	handleKeyDown(e){		
		if(e.keyCode==27 && this.props.zoomed){//escape
			if(this.state.cropModeActive){
				this.disableCropMode()
			}
			else if(this.state.showEffectsToolbar){
				this.setState({showEffectsToolbar:false})	
			}
			else{
				this.props.unzoom()
			}	
		}
		if(e.keyCode==13 && this.props.zoomed){ //enter
			if(this.state.cropModeActive){
				this.disableCropMode()
			}
			if(this.state.showEffectsToolbar){
				this.setState({showEffectsToolbar:false})	
			}
		}
	}


  measureFigmaViewRect(){
  	let startX 
		let startY 
		let startWidth
		let startHeight
		const figmaViewEl = document.getElementById(`figmaView--${this.props.embedId}`)
		if(figmaViewEl){
			startY = figmaViewEl.getBoundingClientRect().top
			startX = figmaViewEl.getBoundingClientRect().left
			startWidth = figmaViewEl.getBoundingClientRect().width
			startHeight = figmaViewEl.getBoundingClientRect().height
		}
		else{
			if(process.env.REACT_APP_ENV !='production'){
				console.log('********************************************************')
				console.log('couldnt find element on mount to add resize observer')
				console.log('********************************************************')
			}
		}
		this.setState({startX:startX,startY:startY,startWidth:startWidth,startHeight:startHeight})
  }

  handleClickOnModalCenterBG(e){  	
  	if(this.state.cropModeActive){
  		this.setState({cropModeActive:false})	
  	}
  	if(this.state.showEffectsToolbar){
  		this.setState({showEffectsToolbar:false})	
  	}
  	if(!this.state.cropModeActive && !this.state.showEffectsToolbar){
  		this.props.unzoom()	
  	}
  }

  handleZoomBGClick(e){  	
		this.setState({cropModeActive:false})	
		this.setState({showEffectsToolbar:false})
		this.props.unzoom()	
		e.stopPropagation()  		
  }

  saveCropValues(crop){
  	this.props.saveCrop(crop)
  	this.setState({isCropping:true})
  	this.measureTimeout=setTimeout(() => {
   		this.measureFigmaViewRect()
  	}, 20);    

  	this.disableTimeout=setTimeout(() => {
   		this.setState({isCropping:false})
   		this.disableCropMode()
  	}, 90);    
  	
  }

  onZoomAnimationRest() {			
		if(!this.state.localZoomed){
			this.props.unzoom()	
		}
	}

  disableCropMode(){
  	this.setState({cropModeActive:false})
  }

  enableCropMode(){
  	this.setState({cropModeActive:true})
  }

  handleClickOnView(e){
  	if(this.state.cropModeActive){
  		// e.preventDefault()
  	}
  	if(!this.state.cropModeActive){
  		this.setState({zoomed:!this.state.zoomed})	
  	}
  }

  onRest() {	
		if(!this.props.zoomed){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

  calculateFitToZoomedModalDimensions(preZoomDimensions){		
		const viewportDimensions = {
			width: window.innerWidth,
			height: window.innerHeight	
		}
		// here we remove the inner and outer margins to find max available size
		const maxAvailableModalDimensions = {
			width: viewportDimensions.width - zoomedFigmaModalOuterMargins.left - zoomedFigmaModalOuterMargins.right - zoomedFigmaModalInnerMargins.left - zoomedFigmaModalInnerMargins.right,
			height: viewportDimensions.height - zoomedFigmaModalOuterMargins.top - zoomedFigmaModalOuterMargins.bottom - zoomedFigmaModalInnerMargins.top - zoomedFigmaModalInnerMargins.bottom
		} 
		const fitModalDimensions = this.calculateFitToBoundingContainerDimensions(preZoomDimensions, maxAvailableModalDimensions)
		// X and Y are to the screen
		const fitModalX = ((maxAvailableModalDimensions.width - fitModalDimensions.width) / 2) + (zoomedFigmaModalOuterMargins.left + zoomedFigmaModalInnerMargins.left)
		const fitModalY = ((maxAvailableModalDimensions.height - fitModalDimensions.height) / 2) + (zoomedFigmaModalOuterMargins.top + zoomedFigmaModalInnerMargins.top)
		return {
			width:fitModalDimensions.width,
			height:fitModalDimensions.height,
			zoomScale: roundToFiveDecimalPlaces(fitModalDimensions.scale),
			x: fitModalX,
			y: fitModalY
		}
	}

	calculateFitToBoundingContainerDimensions(preFitDimensions,boundingContainerDimensions){		
		const preFitAspectRatio = preFitDimensions.width / preFitDimensions.height
		const boundingContainerAspectRatio = boundingContainerDimensions.width / boundingContainerDimensions.height
		let width
		let height
		let heightConstrained = false		
		if(boundingContainerAspectRatio > preFitAspectRatio){// bounding is wider than figma, so figma fits bounding height
			height = boundingContainerDimensions.height
			width = boundingContainerDimensions.height * preFitAspectRatio
			heightConstrained = true
		}
		if(boundingContainerAspectRatio <= preFitAspectRatio){// bounding is narrower than figma, so figma fits bounding width		
			width = boundingContainerDimensions.width
			height = boundingContainerDimensions.width / preFitAspectRatio		
		}
		const scale = width / preFitDimensions.width
		return {
			width:width,
			height:height,
			scale:scale,
			heightConstrained: heightConstrained,
		}
	}


	render(){	
		const {cropModeActive}=this.state
		const {embedId,embedObj, boundingMaxContainerDimensions,backgroundMaxWidth,disableMotion,zoomed,crop}=this.props

		const boundingContainerDimensions = boundingMaxContainerDimensions
		const absRenderBounds=embedObj.absolute_render_bounds
		const frameDimensions = {width: absRenderBounds.width,height: absRenderBounds.height}

		let {startWidth,startHeight,startX,startY}=this.state
		const startDimensions = {width: startWidth,height: startHeight}
		const endDimensions = this.calculateFitToZoomedModalDimensions(startDimensions)		
		// TRANSFORM ORIGIN (this effectively translates position of slide)
		const transformOrigin = {
			x: (startX - endDimensions.x) / (endDimensions.width - startWidth),
			y: (startY - endDimensions.y) / (endDimensions.height - startHeight)
		}

		const showFigmaWorkflowStatusPrototype = false

	  return (
	  	<React.Fragment>
		  	<Transition	        
	        items={zoomed}
	        from={{ transform: 'scale(1)' }}
	        enter={{ transform: `scale(${endDimensions.zoomScale||1})` }}
	        update={{ transform: `scale(${endDimensions.zoomScale||1})`}}
	        leave={{ transform: 'scale(1)' }}
	        config={(item,state,stateLabel) =>{
	        	if(stateLabel == 'update'){
	        		return {
	        			duration: 0
	        		}
	        	}
	        	else{
	        		return { 
	        			tension: 320, friction: 34
	        		}
	        	}
	        }}                 	        
	       >		  	  				
  				{(styles, item) =>
			     	item && 
					  	<animated.div style={{transformOrigin: `${transformOrigin.x * 100}% ${transformOrigin.y * 100}%`, left:`${startX}px` , top:`${startY}px` , width: `${startWidth}px`, height: `${startHeight}px`, ...styles}} className={'figmaViewZoomedModal-viewWrapper ' + (cropModeActive ? ' figmaViewZoomedModal-viewWrapper--activeCrop ' : ' ')}>						  		
					  		<FigmaView 
									embedId={this.props.embedId}
									embedObj={this.props.embedObj}						
									boundingMaxContainerDimensions={this.props.boundingMaxContainerDimensions}					
									backgroundMaxWidth={this.props.backgroundMaxWidth} // if not cropped, fit full width. useful for mobile/portrait images.
									disableMotion={this.props.disableMotion} // if	true, all animations are instant. useful for thumbnails. also probs a user setting in future.		
									zoomed=	{this.props.zoomed}
									zoom={this.props.zoom}
									unzoom={this.props.unzoom}
									toggleZoom={this.props.toggleZoom}						
									backgroundPadding={this.props.backgroundPadding}
									backgroundImage={this.props.backgroundImage}
									deviceFrame={this.props.deviceFrame}
									crop={crop}
									showEffectsToolbar={this.state.showEffectsToolbar}
									hideEffectsToolbar={()=>{this.setState({showEffectsToolbar: false})}}
									openEffectsToolbar={()=>{this.setState({showEffectsToolbar: true})}}
									showCropContextMenu
									cropModeActive={this.state.cropModeActive}
									enableCropMode={this.enableCropMode}
									disableCropMode={this.disableCropMode}
									deleteFigmaEmbed={this.props.deleteFigmaEmbed}
								/>								 			  
			  			</animated.div>
		      }
			   </Transition> 		


			   {showFigmaWorkflowStatusPrototype && 
				   <Transition	        
		        items={zoomed}
		        from={{ opacity: 0, delay: 0}}
		        enter={{ opacity: 1, delay: 100}}
		        leave={{ opacity: 0, delay: 0}}
		        //immediate={disableMotion}
			      config={{
							tension: 500,
							friction: 44,
						}}
		        //reverse={zoomed}
		        //config={{tension: 700, friction: 36}}	        
		        // config={config.molasses}
		        //onRest={this.onRest}
		       >		  	  				
	  				{(statusStyles, item) =>
				     	item && 
						  	<animated.div style={{top: `${zoomedFigmaModalOuterMargins.top}px`, height: `${zoomedFigmaModalInnerMargins.top}px`, ...statusStyles}} className='doc-zoomedFigmaModal-statusContainer'>	
						  		<FigmaViewZoomedUIStatus 

									/>
				  			</animated.div>
			      }
				   </Transition> 		
			 	}


			   <Transition	        
	        items={zoomed}
	        from={{ opacity: 0, delay: 0}}
	        enter={{ opacity: 1, delay: 100}}
	        leave={{ opacity: 0, delay: 0}}
	        //immediate={disableMotion}
		      config={{
						tension: 500,
						friction: 44,
					}}
	        //reverse={zoomed}
	        //config={{tension: 700, friction: 36}}	        
	        // config={config.molasses}
	        //onRest={this.onRest}
	       >		  	  				
  				{(modalStyles, item) =>
			     	item && 
					  	<animated.div onClick={(e)=>{e.preventDefault()}} style={{transformOrigin: `${transformOrigin.x * 100}% ${transformOrigin.y * 100}%`, left: `${zoomedFigmaModalOuterMargins.left}px`, top: `${zoomedFigmaModalOuterMargins.top}px`, bottom: `${zoomedFigmaModalOuterMargins.bottom}px`, right: `${zoomedFigmaModalOuterMargins.right}px`, ...modalStyles}} className='figmaView-modal'>	
					  		<FigmaViewZoomedUI
					  			saveUpdatedCropValues={this.saveCropValues}
					  			zoomed={zoomed}
					  			embedObj={embedObj}
					  			unzoom={this.props.unzoom}
					  			enableCropMode={this.enableCropMode}
					  			disableCropMode={this.disableCropMode}
					  			transformOrigin={transformOrigin}
					  			zoomedFigmaModalOuterMargins={zoomedFigmaModalOuterMargins}
					  			zoomedFigmaModalInnerMargins={zoomedFigmaModalInnerMargins}
					  			disableMotion={disableMotion}
					  			cropModeActive={this.state.cropModeActive}
					  			viewOnlyMode={false} // for e.g. messages
					  			crop={crop}
									canvasWidth={window.innerWidth - zoomedFigmaModalOuterMargins.left - zoomedFigmaModalOuterMargins.right - zoomedFigmaModalInnerMargins.left - zoomedFigmaModalInnerMargins.right}
									canvasHeight={window.innerHeight - zoomedFigmaModalOuterMargins.top - zoomedFigmaModalOuterMargins.bottom - zoomedFigmaModalInnerMargins.top - zoomedFigmaModalInnerMargins.bottom}
									embeddedInZoomModal

									// for toolbar
									showEffectsToolbar={this.state.showEffectsToolbar}
									hideEffectsToolbar={()=>{this.setState({showEffectsToolbar: false})}}
									openEffectsToolbar={()=>{this.setState({showEffectsToolbar: true})}}

									backgroundPadding={this.props.backgroundPadding}
									editBackgroundPadding={this.props.editBackgroundPadding}
									backgroundImage={this.props.backgroundImage}
									editBackgroundImage={this.props.editBackgroundImage}
									deviceFrame={this.props.deviceFrame}
									editDeviceFrame={this.props.editDeviceFrame}
									device={this.props.device}
									toggleShadow={this.props.toggleShadow}

									handleClickOnModalCenterBG={this.handleClickOnModalCenterBG}
									docId={this.props.docId}
									sendMediaModalMessage={this.props.sendMediaModalMessage}
					  		/>
			  			</animated.div>
		      }
			   </Transition> 		
			   
			  <Transition	        
	        items={zoomed}
	        from={{ opacity: 0 }}
	        enter={{ opacity: 1  }}
	        leave={{ opacity: 0  }}
	        //reverse={zoomed}
	        //config={{tension: 700, friction: 36}}	        
	        // config={config.molasses}
	        //onRest={this.onRest}
	        config={{tension: 240, friction: 32}}	        
	       >		  	  				
  				{(modalBGStyles, item) =>
			     	item && 
					  	<animated.div onClick={this.handleZoomBGClick} style={modalBGStyles} className={'figmaViewZoomedModal-zoomBG ' + (zoomed ? ' figmaViewZoomedModal-zoomBG--zoomed ' : ' figmaViewZoomedModal-zoomBG--unzoomed ')} />
		      }
			   </Transition> 		


	  	</React.Fragment>
			
	  )
	}
}

export default FigmaViewAnimatedZoomedModal


  		
  		{/*}
  		<div onClick={this.props.toggleZoom} className={'figmaViewZoomedModal figmaViewZoomedModal--animated' + (zoomed ? ' figmaViewZoomedModal--visible' : ' figmaViewZoomedModal--hidden')}>
  		</div>



  		{/*}

  			<Spring
					native
					immediate={disableMotion || this.state.isCropping}
					transform={localZoomed ? `scale(${endDimensions.zoomScale})` :'scale(1)'}
					opacity={cropModeActive ? 0 : 1}					
					config={{
						tension: 100,
						friction: 34,
					}}
					onRest={this.onZoomAnimationRest}
					//delay={zoomed ? 0 : 50}
				>

  		
			*/}
	  		{/*<DocFigmaEmbedZoomedModal saveUpdatedCropValues={(crop)=>{this.setState({userCrop:crop})}}/> */}

