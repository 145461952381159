import React from 'react'
import Icon from '../../../../misc/Icon'
import { connect } from 'react-redux'
import {getDocForId} from '../../../../../utils/getDocForId'


class MessageInternalLinkReactComponent extends React.PureComponent{  
	
	render(){
		const {docId}=this.props
		const doc=getDocForId(docId)
		let name =''
		if(doc){
			name=doc.name
		}
		
	  return (
	  	<div className="message-para-internalLink" onClick={()=>{this.props.handleOpenDoc(docId)}}>	  					
	  		<div className="message-para-internalLink-iconContainer message-para-internalLink-iconContainer--noBoard">									  				  			
	  			<div className='message-para-internalLink-iconContainer--noBoard-inner'>	
	  				<Icon name='linkArrow' />
	  			</div>
	  		</div>
	  		
		  	<div className="message-para-internalLink-title">
		  		{name}
		  	</div>	  			 		  	
	  	</div>
	  )
	}
}
export default MessageInternalLinkReactComponent