import {fetch,put} from '../api'
import { Mixpanel } from '../Mixpanel'
import {fetchOrgMembers} from './orgMembers'
import {fetchTeams} from './teams'
import {fetchWorkflowStates} from './workflowStates'
import {fetchProjects} from './projects'
import {fetchIssues} from './issues'
import LogRocket from 'logrocket';

export function fetchUser() {
	return (dispatch) => fetch(`/user`)
		.then((response) => {
			Mixpanel.identify(response.user_id)
			Mixpanel.people.set({
        $name: response.name,
        $email: response.email,
        $org_id:response.organization
      });

			LogRocket.identify(response.user_id, {
				name: response.display_name,
				email: response.email
			});

			dispatch({ type: 'FETCH_USER_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}


export function fetchOrg() {
	return (dispatch) => fetch(`/organization`)
		.then((response) => {
			dispatch({ type: 'FETCH_ORGANIZATION_SUCCESS', response })
			Mixpanel.people.set({
        $org_name: response.name
      });
			return response
		})
		.catch((error) => {
			return error
		})
}

export function updateOrgQuarterlyRoadmap(roadmapId) {
	const requestBody={
		roadmapId:roadmapId
	}
	return (dispatch) => put(`/organization/roadmap`,requestBody)
		.then((response) => {
			dispatch({ type: 'FETCH_ORGANIZATION_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}




export function pingCursor(data) {
	return (dispatch) => post(`/organization/ping-cursor`,data)
		.then((response) => {
			return response
		})
		.catch((error) => {
			return error
		})
}




export function syncOrganization() {
	return (dispatch) => put(`/organization/sync`)
		.then((response) => {
			dispatch({ type: 'FETCH_ORGANIZATION_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}

//syncs all non webhooked linear data
//organization
//members
//teams
//workflow states
//milestones
export function syncLinearData() {

	return (dispatch) => put(`/sync-check`)
		.then((response) => {
	
			// dispatch(fetchOrg())
			// dispatch(fetchOrgMembers())
			// dispatch(fetchTeams())
			// dispatch(fetchWorkflowStates())
			// dispatch(fetchProjects())
			// dispatch(fetchIssues())
			return response
		})
		.catch((error) => {
			return error
		})
}

export function refetchLinearData() {

	return (dispatch) =>{
		dispatch(fetchOrg())
		dispatch(fetchOrgMembers())
		dispatch(fetchWorkflowStates())
		dispatch(fetchProjects())
		dispatch(fetchIssues())
	}
}


