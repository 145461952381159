import React from 'react'
import { connect } from 'react-redux'
import Icon from '../../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';

function formatRecordingLength(duration){
  if (duration < 60) {
    return Math.floor(duration) + 'sec';
  } else if(duration < 60 * 60){
    return Math.floor(duration / 60) + ' min';
  }
  else {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    return `${hours}hr ${minutes} min`;
  }
}

class DocEditorMeetingContentPlayRecordingButton extends React.PureComponent{  

  render(){ 
    const {recording,activeUploads,strong,light}=this.props
    const {mux_status,transcript_status}=recording
    let uploadState
    if(mux_status=='waiting_for_upload'){
			uploadState='uploading'
		}else if(mux_status=='created'){
			uploadState='processing'
		}else if(mux_status=='ready'){
			if(transcript_status=='ready'){
				uploadState='ready'
			}else{
				uploadState='processing'
			}
		}    
		let progress=100
		if(activeUploads && activeUploads[recording.upload_id]){
			progress=activeUploads[recording.upload_id].progress
		}

		const isAudio = recording.isAudio

		//let recordingLength = '35 min'
    let recordingLength = formatRecordingLength(recording.duration)
    return (   
    	<button onClick={uploadState === 'ready' ? this.props.openRecordingModal : null} className={'docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--recordingPlayback ' + (strong ? ' docEditor-meetingContent-menu-btn--strong ' : '') + (light ? ' docEditor-meetingContent-menu-btn--light ' : '') + (uploadState ? ` docEditor-meetingContent-menu-btn--recordingPlayback--${uploadState} ` : '') + (isAudio ? ' docEditor-meetingContent-menu-btn--recordingPlayback--audio ' : ' docEditor-meetingContent-menu-btn--recordingPlayback--video ')}>
        {uploadState === 'uploading' &&           
          <React.Fragment>              
            <div className='docEditor-meetingContent-menu-btn--recordingPlayback--uploading-progressBarContainer'>
              <div className='docEditor-meetingContent-menu-btn--recordingPlayback--uploading-progressBar'>
                <CircularProgressbar 
                  value={progress}
                  strokeWidth={12}
                />
              </div>  
            </div>            
            <div className='docEditor-meetingContent-menu-btn-label'>
              Uploading Recording ({Math.round(progress)}%)
            </div>                
          </React.Fragment>
        }
        {uploadState === 'processing' &&           
          <React.Fragment>
            <div className='docEditor-meetingContent-menu-btn-iconContainer'>                
              <div className='docEditor-meetingContent-menu-btn--recordingPlayback--processing-spinnerContainer'>
                <div className='docEditor-meetingContent-menu-btn--recordingPlayback--processing-spinnerInner' />
              </div>                                
            </div>
            <div className='docEditor-meetingContent-menu-btn-label'>
              Processing Recording
            </div>                
          </React.Fragment>
        }
        {uploadState === 'ready' && !isAudio &&            
          <React.Fragment>
            <div className='docEditor-meetingContent-menu-btn-iconContainer'>
              <Icon name='playTV' />
            </div>
            <div className='docEditor-meetingContent-menu-btn-label'>
              Play Recording ({recordingLength})
            </div>                
          </React.Fragment>
        }          
        {uploadState === 'ready' && isAudio &&            
          <React.Fragment>
            <div className='docEditor-meetingContent-menu-btn-iconContainer'>
              <Icon name='playbackPlay' />
            </div>
            <div className='docEditor-meetingContent-menu-btn-label'>
              Play Recording ({recordingLength})
            </div>                
          </React.Fragment>
        }          
      </button>
    )
  }
}

function mapStateToProps(state,ownProps) {
  return {
    activeUploads:state.activeUploads
  }
}


export default connect(mapStateToProps,
  {}
)(DocEditorMeetingContentPlayRecordingButton)
