import React from 'react'
import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import LinearMarker from '../misc/LinearMarker'
import LinearProjectStatusIcon from '../misc/LinearProjectStatusIcon'
import * as Dialog from '@radix-ui/react-dialog';
import * as Tooltip from '@radix-ui/react-tooltip';
import { Spring, Transition, animated, config, easings} from 'react-spring'
import {getProjectForId} from '../../utils/getProjectForId'
import {getTeamForId} from '../../utils/getTeamForId'
import {getUserForId} from '../../utils/getUserForId'
import EditMetadataPopoverWrapper from '../popovers/EditMetadataPopoverWrapper'
import {updateProject} from '../../utils/updateProject/updateProject'
import {createProject} from '../../utils/updateProject/createProject'

class EditProjectModalContentsTeamsBtnContents extends React.Component{  	
	render(){	
		const {teams} = this.props		
		//console.log(teams)
		// i don't think no teams should be possible
		// we might autoassign a team on create
		let teamObjects
		if(teams.length === 1){
			const teamObject = getTeamForId(teams[0])
			return (	  	  				
					<div className='editProjectModal-footerRow-footerBtn--teams-team editProjectModal-footerRow-footerBtn--teams-team--single'> 
						<div className='editProjectModal-footerRow-footerBtn--teams-team-markerContainer'>					
			      	<LinearMarker teamId={teams[0]}/> 
			      </div>
			      <div className='editProjectModal-footerRow-footerBtn--teams-team'>					
			      	{/* {teamObject.name} */}
			      	{teamObject.linear_key}
			      </div>
			    </div>		    
	    )
		}else if(teams.length > 1){
	  	return(
	  		<React.Fragment>
				{teams.map((team) => {
					const teamObject = getTeamForId(team)
			  	return (
			    	<div key={teamObject.linear_key} className='editProjectModal-footerRow-footerBtn--teams-team editProjectModal-footerRow-footerBtn--teams-team--multi'> 
							<div className='editProjectModal-footerRow-footerBtn--teams-team-markerContainer'>					
			      		<LinearMarker teamId={team}/> 
			      	</div>
			      	<div className='editProjectModal-footerRow-footerBtn--teams-team'>					
			      		{teamObject.linear_key}<span>,</span>
			      	</div>
			    	</div>
			  	)
				})
			}
			</React.Fragment>
			)
	  }
	  else return null
	}
}


class EditProjectModalContentsTeamsBtn extends React.Component{  	
	constructor(props){
    super(props)        
    this.state={
      showEditProjectTeamsPopover: false,
    }
  }	
	render(){	
		const {members,leadUserId} = this.props
		const {showEditProjectTeamsPopover} = this.state
		return (	  	  
			<EditMetadataPopoverWrapper
				showPopover={showEditProjectTeamsPopover}						
				openPopover={()=>{this.setState({showEditProjectTeamsPopover: true})}}
				closePopover={()=>{this.setState({showEditProjectTeamsPopover: false})}}		
				type="projectTeams"  // used to load in popover contents
				teams={this.props.teams}
        updateProjectTeams={this.props.handleUpdateProjectTeams}
				layout="editProjectModal" // used for positioning
				projectId={this.props.projectId}		
				portalled		
			>								
			 <div className='editProjectModal-footerRow-footerBtnContainer'>
				<Tooltip.Provider>
					<Tooltip.Root delayDuration={100}>
						<Tooltip.Trigger asChild>														
							<button onClick={()=>{this.setState({showEditProjectTeamsPopover: true})}} className='editProjectModal-footerRow-footerBtn editProjectModal-footerRow-footerBtn--teams'>
								<EditProjectModalContentsTeamsBtnContents 
									teams={this.props.teams}
								/>
							</button>								
						</Tooltip.Trigger>															 	
						<Tooltip.Content side="bottom" className={"tooltip tooltip--textSelectionUI " + (showEditProjectTeamsPopover ? ' tooltip--forceHide ' : ' ')}>	
							Project Team
						</Tooltip.Content>																														
					</Tooltip.Root>
				</Tooltip.Provider>
			</div>
		</EditMetadataPopoverWrapper>		
    )
	}
}


class EditProjectModalContentsLeadBtn extends React.Component{  	
	constructor(props){
    super(props)        
    this.state={
      showEditProjectLeadPopover: false,
    }
  }

	render(){	
		const {leadUserId} = this.props
		const {showEditProjectLeadPopover} = this.state
		let leadUser 
		if(leadUserId){
			leadUser = getUserForId(leadUserId)
		}
		return (	  	  
			<EditMetadataPopoverWrapper
				showPopover={showEditProjectLeadPopover}						
				openPopover={()=>{this.setState({showEditProjectLeadPopover: true})}}
				closePopover={()=>{this.setState({showEditProjectLeadPopover: false})}}		
				type="projectLead"  // used to load in popover contents
				updateProjectLead={this.props.handleUpdateProjectLead}
				leadUserId={leadUserId}
				layout="editProjectModal" // used for positioning
				projectId={this.props.projectId}				
			>
				<div className='editProjectModal-footerRow-footerBtnContainer'>
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={100}>
							<Tooltip.Trigger asChild>														
								<button onClick={()=>{this.setState({showEditProjectLeadPopover: true})}} className={'editProjectModal-footerRow-footerBtn editProjectModal-footerRow-footerBtn--leadUser ' + (leadUserId ? ' editProjectModal-footerRow-footerBtn--leadUser--empty ': ' editProjectModal-footerRow-footerBtn--leadUser--hasUser ')}>
									{leadUserId && 
										<React.Fragment>
											<div className='editProjectModal-footerRow-footerBtn--leadUser-avatarContainer'>
								      	<AvatarImage className='editProjectModal-footerRow-footerBtn--leadUser-avatar' avatarUrl={leadUser.avatar} smallAvatar/> 
								      </div>
								      <div className='editProjectModal-footerRow-footerBtn--leadUser-name'>					
								      	{leadUser.display_name}
								      </div>		    
							     	</React.Fragment>
						    	}

						    	{!leadUserId && 
										<React.Fragment>
											<div className='editProjectModal-footerRow-footerBtn--leadUser-iconContainer'>					
								      	<Icon name='userCircle' />
								      </div>
								      <div className='editProjectModal-footerRow-footerBtn--leadUser-name'>
								      	Lead
								      </div>		    
							     	</React.Fragment>
						    	}
								</button>
							</Tooltip.Trigger>							
							{leadUserId &&		 	
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Project Lead
								</Tooltip.Content>																							
							}
						</Tooltip.Root>
					</Tooltip.Provider>
				</div>
			</EditMetadataPopoverWrapper>		
    )
	}
}


class EditProjectModalContentsStatusBtn extends React.Component{  	
	constructor(props){
    super(props)        
    this.state={
      showEditProjectStatusPopover: false,
    }
  }	

	render(){	
		const {projectId,status} = this.props
		const {showEditProjectStatusPopover} = this.state
		let projectStatus = status // default for new projects
		let projectStatusLabel=status
    if(projectStatus === 'started'){
      projectStatusLabel = 'in progress'
    }
		return (	  	  
			<EditMetadataPopoverWrapper
				showPopover={showEditProjectStatusPopover}						
				openPopover={()=>{this.setState({showEditProjectLeadPopover: true})}}
				closePopover={()=>{this.setState({showEditProjectStatusPopover: false})}}		
				type="projectStatus"  // used to load in popover contents			
				layout="editProjectModal" // used for positioning
				projectStatus={projectStatus}	
				projectId={this.props.projectId}			
				updateProjectStatus={this.props.updateProjectStatus}
				portalled	
			>
				<div className='editProjectModal-footerRow-footerBtnContainer'>					
					<button onClick={()=>{this.setState({showEditProjectStatusPopover: true})}} className='editProjectModal-footerRow-footerBtn editProjectModal-footerRow-footerBtn--projectStatus'>
						<div className='editProjectModal-footerRow-footerBtn--projectStatus-markerContainer'>					
							<LinearProjectStatusIcon 
            		projectStatus={projectStatus}
          		/>
						</div>
						<div className='editProjectModal-footerRow-footerBtn--projectStatus-label'>
			      	{projectStatusLabel}
						</div>		    
					</button>
				</div>
			</EditMetadataPopoverWrapper>		
    )
	}
}
 



class EditProjectModalContentsMembersBtn extends React.Component{  	
	constructor(props){
    super(props)        
    this.state={
      showEditProjectMembersPopover: false,
    }
  }	

	render(){	
		const {members,leadUserId} = this.props
		const {showEditProjectMembersPopover} = this.state
		let membersCount 
		if(members){
			membersCount = members.length // lead user is in member list but not counted as a member
		}if(leadUserId){
			if(members.includes(leadUserId)){
				membersCount-=1
			}
		}
		const hasMembers = membersCount > 0
		let membersLabel
		if(membersCount === 1){
			membersLabel = '1 member'
		}
		if(membersCount > 1){
			membersLabel = `${membersCount} members`
		}

		return (	  	  
			<EditMetadataPopoverWrapper
				showPopover={showEditProjectMembersPopover}						
				openPopover={()=>{this.setState({showEditProjectMembersPopover: true})}}
				closePopover={()=>{this.setState({showEditProjectMembersPopover: false})}}		
				type="projectMembers"  // used to load in popover contents
				members={this.props.members}
				leadUserId={this.props.leadUserId}
        updateProjectMembers={this.props.handleUpdateProjectMembers}
				layout="editProjectModal" // used for positioning
				projectId={this.props.projectId}			
				portalled	
			>
					<button onClick={()=>{this.setState({showEditProjectMembersPopover: true})}} className={'editProjectModal-footerRow-footerBtn editProjectModal-footerRow-footerBtn--members ' + (hasMembers ? ' editProjectModal-footerRow-footerBtn--members--hasMembers ': ' editProjectModal-footerRow-footerBtn--members--empty ')}>
						{hasMembers && 
							<React.Fragment>
								<div className='editProjectModal-footerRow-footerBtn--members-avatarRow'>
									{members.map((member) => {
											const memberObj = getUserForId(member)
											if(memberObj.user_id !== leadUserId){
												return(
													<div key={memberObj.user_id} className='editProjectModal-footerRow-footerBtn--members-avatarContainer'>
														<AvatarImage avatarUrl={memberObj.avatar} className='editProjectModal-footerRow-footerBtn--members-avatar' smallAvatar/>
													</div>
												)
											}
										else return null											
										})}	
					      </div>
					      <div className='editProjectModal-footerRow-footerBtn--members-label'>					
					      	{membersLabel}
					      </div>		    
				     	</React.Fragment>
			    	}

			    	{!hasMembers && 
							<React.Fragment>
								<div className='editProjectModal-footerRow-footerBtn--members-iconContainer'>					
					      	<Icon name='plus' />
					      </div>
					      <div className='editProjectModal-footerRow-footerBtn--members-label'>
					      	Add Members
					      </div>		    
				     	</React.Fragment>
			    	}
					</button>
			</EditMetadataPopoverWrapper>		
    )
	}
}




class EditProjectModalContentsEditProjectIconBtn extends React.Component{  	
	
	constructor(props){
    super(props)        
    this.state={
      showEditProjectIconPopover: false,
    }
  }	

	render(){	
		const {projectId} = this.props
		const {showEditProjectIconPopover} = this.state
		const hasIcon = this.props.icon
		return (	  	  
			<div className='editProjectModal-projectIconRow-editBtnContainer'>	          		          
				<EditMetadataPopoverWrapper
					showPopover={showEditProjectIconPopover}						
					openPopover={()=>{this.setState({showEditProjectIconPopover: true})}}
					closePopover={()=>{this.setState({showEditProjectIconPopover: false})}}		
					type="projectIcon"  // used to load in popover contents
					layout="editProjectModal" // used for positioning
					projectId={this.props.projectId}		
					icon={this.props.icon}
        	color={this.props.color}	    
        	updateProjectIcon={this.props.handleUpdateProjectIcon}   		
				>	
					<button onClick={()=>{this.setState({showEditProjectIconPopover: true})}} className='editProjectModal-projectIconRow-editBtn'>
						{hasIcon && 
	          	<LinearMarker projectId={projectId} icon={this.props.icon} color={this.props.color}/>
						}
						{!hasIcon && 
							<Icon name='plus' />
						}
	        </button>
				</EditMetadataPopoverWrapper>		
			</div>

    )
	
	}
}


class EditProjectModalContentsSubmitBtn extends React.Component{  	
	render(){	
		const {newProject, showKeyboardHint, submitLoading, onClickFunction,projectName} = this.props		
		let buttonLabel = 'Edit Project'
		if(newProject){
			buttonLabel = 'New Project'
		}
		const disabled=projectName?false:true

		return (	  	  
			<div className='editProjectModal-footerRow-submitBtnContainer' >       	
      		{!submitLoading && 
	        	<button id={'project-submit-button'} disabled={disabled} className='editProjectModal-footerRow-submitBtn' onClick={this.props.onClickFunction}>
	        		<div className='editProjectModal-footerRow-submitBtn-label'>
	        			{buttonLabel}
	        		</div>
	        		{showKeyboardHint && 
		        		<div className='editProjectModal-footerRow-submitBtn-keyboardKeyContainer'>
		        			<div className='editProjectModal-footerRow-submitBtn-keyboardKey'>
		        				<div className='editProjectModal-footerRow-submitBtnKeyboardHint-iconContainer'>
		        					<Icon name='keyCommandMedium' />
		        				</div>
		        				<div className='editProjectModal-footerRow-submitBtnKeyboardHint-iconContainer'>
		        					<Icon name='enterKey' />
		        				</div>
		        			</div>
								</div>		   
							}     		
	        	</button>
        	}

        	{submitLoading && 
        	<button className='editProjectModal-footerRow-submitBtn'>        		        		
        		<div className='editProjectModal-footerRow-submitBtn-loadingBar'>
			  			<Spring
			  				native								
								from={{
									opacity: 0.4,
									transform: 'translateX(-120px)'
								}}
								to={[
		          		{ 
		          			opacity: 0.05,
		          			transform: 'translateX(80px)' 
		          		},	          		
		        		]}								
								loop								
								config={{									
									duration: 800,									
									easing: easings.easeInOutCubic,
								}}
							>
				  			{styles => 
									<animated.div style={styles} className='editProjectModal-footerRow-submitBtn-loadingBar-ball' />
				  			}
				  		</Spring>	  		 			
			  	</div>
        			
        	</button>
        	}
      </div>
    )
	}
}


 
class EditProjectModalContents extends React.Component{  	
	constructor(props){
    super(props)    
    this.handleTitleChange=this.handleTitleChange.bind(this)
    this.handleDescriptionChange=this.handleDescriptionChange.bind(this)
    this.saveChanges=this.saveChanges.bind(this)
    this.handleUpdateProjectLead=this.handleUpdateProjectLead.bind(this)
    this.handleUpdateProjectTeams=this.handleUpdateProjectTeams.bind(this)
    this.handleUpdateProjectMembers=this.handleUpdateProjectMembers.bind(this)
    this.handleUpdateProjectIcon=this.handleUpdateProjectIcon.bind(this)
    this.createProject=this.createProject.bind(this)
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.handleUpdateProjectStatus=this.handleUpdateProjectStatus.bind(this)
    this.state={
      nameValue: props.name,
      descriptionValue: props.description, 
      teams:props.teams,
      projectLead:props.projectLead,
      members:props.members,
      icon:props.icon,
      color:props.color,
      status:props.status,
      submitLoading:false  
    }
    this.focusTimeout=null
  }

  componentDidMount(){
  	window.addEventListener('keydown', this.handleKeyDown)	
  	const {editProjectModalFocusField}=this.props //title or description
  	let input
  	if(editProjectModalFocusField=='title' || this.props.newProject){
  		input = document.getElementById('project-title-input')
  	}else if(editProjectModalFocusField=='description'){
  		input = document.getElementById('project-description-input')
  	}
 		if(input){
 			this.focusTimeout=setTimeout(() => {
   	   	input.focus()
 				input.setSelectionRange(input.value.length,input.value.length)
   		}, 100);   
  	}
  }

  componentWillUnmount(){
  	window.removeEventListener('keydown', this.handleKeyDown)		
  	if(this.focusTimeout){
  		clearTimeout(this.focusTimeout)
  	}
  }

  handleKeyDown(e){ 

		if(e.keyCode==13){

			e.stopPropagation()
			e.preventDefault()
		}
		if(e.metaKey && e.keyCode==13){ //command enter to save
			e.stopPropagation()
			if(this.props.newProject){
				this.createProject()
			}else{
				this.saveChanges()
			}		
		}		
		if(e.keyCode==9){ //tab key- want tab to change focus from title-->description-->done button
			e.preventDefault()
			e.stopPropagation()
			if (document.activeElement) {
				const activeId=document.activeElement.id
  			//console.log("The current active element is: ", activeId);
  			let newActive
  			if(activeId=='project-title-input'){//focus description
  				newActive = document.getElementById('project-description-input')
  			}else if(activeId=='project-description-input'){//focus description
  				newActive = document.getElementById('project-submit-button')
  				//if there is no project title go back to the title
  				if(!this.state.nameValue){
  					newActive = document.getElementById('project-title-input')
  				}
  			}else{//focus title
  				newActive = document.getElementById('project-title-input')
  			}
  			if(newActive){
  				newActive.focus()
  				if(newActive.value){
  					newActive.setSelectionRange(newActive.value.length,newActive.value.length)
  				}
  			}
			} 
		}		
	}

	handleTitleChange(event) {    
    this.setState({nameValue: event.target.value})   
    this.props.updateHasChanges(true) 
  }

  handleDescriptionChange(event) {    
    this.setState({descriptionValue: event.target.value})   
    this.props.updateHasChanges(true)  
  }

  handleUpdateProjectLead(projectLead){
  	this.setState({projectLead:projectLead})
  	this.props.updateHasChanges(true) 
  }

  handleUpdateProjectTeams(projectTeams){
  	this.setState({teams:projectTeams})
  	this.props.updateHasChanges(true) 
  }

  handleUpdateProjectMembers(projectMembers){
  	this.setState({members:projectMembers})
  	this.props.updateHasChanges(true) 
  }

  handleUpdateProjectStatus(status){
  	this.setState({status:status})
  	this.props.updateHasChanges(true) 
  }

  handleUpdateProjectIcon(icon,color){
  	if(icon){
  		this.setState({icon:icon})
  	}if(color){
  		this.setState({color:color})
  	}	
  	this.props.updateHasChanges(true) 
  }

  saveChanges(){
  	this.setState({submitLoading:true})
  	const oldProject=getProjectForId(this.props.projectId)
  	let newProject={...oldProject}
  	newProject.name=this.state.nameValue
  	newProject.description=this.state.descriptionValue
  	newProject.lead=this.state.projectLead
  	newProject.teams=this.state.teams
  	newProject.members=this.state.members
  	newProject.icon=this.state.icon
  	newProject.color=this.state.color
  	newProject.state=this.state.status
  	updateProject(newProject)	
  	this.focusTimeout=setTimeout(() => {
   	   this.props.closeModal()
   	}, 100);  
  	
  
  }

  createProject(){
  	this.setState({submitLoading:true})
  	let newProject={}
  	newProject.name=this.state.nameValue
  	newProject.description=this.state.descriptionValue
  	newProject.lead=this.state.projectLead
  	newProject.teams=this.state.teams
  	newProject.members=this.state.members
  	newProject.icon=this.state.icon
  	newProject.color=this.state.color
  	newProject.state=this.state.status
  	if(this.props.newProjectRoadmapId){
  		newProject.roadmap=this.props.newProjectRoadmapId
  	}
  	return createProject(newProject).then(()=>{
  		this.props.closeModal()  		
  	})
  }


	render(){	
		const {newProject, projectId, name, description, leadUserId, members} = this.props	
		const {submitLoading}=this.state	

		let showKeyboardHint = !newProject

		if(newProject && this.state.nameValue.length > 0 && this.state.descriptionValue.length > 0){
			showKeyboardHint = true
		}

	  return (
	  	<React.Fragment>    					                     	
        {/* PROJECT ICON */}
        <div className='editProjectModal-projectIconRow'>
        	<EditProjectModalContentsEditProjectIconBtn 
        		projectId={projectId}
        		icon={this.state.icon}
        		color={this.state.color}	    
        		handleUpdateProjectIcon={this.handleUpdateProjectIcon}     		
        	/>
        </div>
        {/* TITLE */}
        <div className='editProjectModal-titleRow'>
        	<input 
						id='project-title-input'
						className='editProjectModal-titleRow-input'
						placeholder='Project Title'
						value={this.state.nameValue}					  						
						onChange={this.handleTitleChange} 				
						autoComplete="off"
						autoCorrect="off"
						spellCheck={false}		
						maxLength={80} // tested on Linear, looks like max				
					/>
        </div>
        {/* DESCRIPTION */}
        <div className='editProjectModal-descriptionContainer'>
        	<textarea 
						id='project-description-input'
						className='editProjectModal-description'
						placeholder='Project Description'
						value={this.state.descriptionValue}					  						
						onChange={this.handleDescriptionChange} 				
						autoComplete="off"
						autoCorrect="off"	
						maxLength={255} // tested on Linear, looks like max				
					/>
        </div>
        {/* TEAM, LEAD, MEMBERS, SUBMIT BTN */}
        <div className='editProjectModal-footerRow'>
      		<EditProjectModalContentsStatusBtn
      			status={this.state.status}
      			updateProjectStatus={this.handleUpdateProjectStatus}
      			projectId={projectId}
      		/>
        	<EditProjectModalContentsTeamsBtn 
        		teams={this.state.teams}
        		handleUpdateProjectTeams={this.handleUpdateProjectTeams}
        		projectId={projectId}
        	/>
        	<EditProjectModalContentsLeadBtn 
        		leadUserId={this.state.projectLead}
        		handleUpdateProjectLead={this.handleUpdateProjectLead}
        		projectId={projectId}
        	/>
        	<EditProjectModalContentsMembersBtn 
        		members={this.state.members}
        		handleUpdateProjectMembers={this.handleUpdateProjectMembers}
        		leadUserId={this.state.projectLead}
        	/>
        	<div className='editProjectModal-footerRow-spacer' />
        	<EditProjectModalContentsSubmitBtn
        		submitLoading={submitLoading}
        		projectName={this.state.nameValue}
        		onClickFunction={newProject ? this.createProject : this.saveChanges}
        		newProject={newProject}
        		showKeyboardHint={showKeyboardHint}
        	/>
        </div>
			</React.Fragment>
	  )
	}
}
 
export default EditProjectModalContents