import React from 'react'
import Icon from '../../../misc/Icon'
import { format,parseISO } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import * as Select from '@radix-ui/react-select';
import * as Popover from '@radix-ui/react-popover';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import moment from 'moment'

//gives 24hours in 15min increment
function calculateTimes(){
	let times = [];
	for (let i = 0; i < 24 * 4; i++) {
	  let hour = Math.floor(i / 4);
	  let minutes = (i % 4) * 15;
	  if (hour === 0) {
	    hour = 12;  // for midnight, use '12'
	  }
	  //hour = hour < 10 ? '0' + hour : hour;  // pad with zero if less than 10
	  minutes = minutes < 10 ? '0' + minutes : minutes;  // pad with zero if less than 10
	  times.push(`${hour}:${minutes}`);
	}
	return times
}

function calculateEndTimes(startTime){
	let times = [];
	let startDate = moment(startTime).add(15, 'minutes'); // Adds 15 minutes to the start date
	let startHour = startDate.hours();
	let startMinute = startDate.minutes();
	let duration=15

	// Round start minute up to the nearest 15 minutes
	startMinute = Math.ceil(startMinute / 15) * 15;
	if (startMinute === 60) {
		startMinute = 0;
		startHour += 1;
	}

	for (let i = startHour * 4 + startMinute / 15; i < 24 * 4; i++) {
		let hour = Math.floor(i / 4);
		let minutes = (i % 4) * 15;
		if (hour === 0) {
			hour = 12;  // for midnight, use '12'
		}
		minutes = minutes < 10 ? '0' + minutes : minutes;  // pad with zero if less than 10
		times.push({time:`${hour}:${minutes}`,duration:duration});
		duration+=15
	}
	return times;
}

function formatLabel(time) {
  let [hour, minutes] = time.split(':').map(Number);
  let period = hour < 12 || hour === 24 ? 'AM' : 'PM';
  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0 || hour === 24) {
    hour = 12;
  }
  minutes = minutes < 10 ? '0' + minutes : minutes; // Ensure minutes always have two digits
  return `${hour}:${minutes} ${period}`;
}

function roundDownToNearestQuarterHour(date) {
  let minutes = date.getMinutes();
  let hours = date.getHours();
  let remainder = minutes % 15;
  let roundedMinutes = minutes - remainder;
  date.setMinutes(roundedMinutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  if (hours === 0) {
      hours = 12;  // for midnight, use '12'
  }
  let formattedHour = hours;
  let formattedMinutes = roundedMinutes < 10 ? '0' + roundedMinutes : roundedMinutes;
  return `${formattedHour}:${formattedMinutes}`;
}


function formatCurrentActiveTime(currentTime){
	let time = roundDownToNearestQuarterHour(new Date(currentTime))
	return time
}

function formatDuration(duration){
	//return hours and mins
	const hours = Math.floor(duration / 60);
  const minutes = duration % 60;
  let string=''
  if(hours){
  	string+=hours + "h"
  }
  if(minutes){
  	string+=" " + minutes + " mins"
  }
  return string
}

class TimeSelectPopoverContentsRow extends React.Component{  
	ref = React.createRef()
	componentDidMount() {
		if(this.props.isActive){
			this.ref.current.scrollIntoView({ behavior: 'instant', block: 'center' });
		}    
   }
	render(){
		const {isActive,time,duration} = this.props		
	  return (	  	
  		<div  ref={this.ref} onClick={(e)=>{this.props.selectTime(e,time)}} className={'popover-item popover-item--editMeetingTime ' + (isActive ? ' popover-item--editMeetingTime--active ' : ' popover-item--editMeetingTime--inactive ')}>
  			<div className='popover-item-label'>
  				{this.props.label} <span className='popover-item--editMeetingTime-label-duration'>{duration?`– ${formatDuration(duration)}`:''}</span>
  			</div>
  		</div>			
	  )
	}
}

export function formatTimestamp(timestamp) {
	return moment(timestamp).format('h:mm A')
}

class DocEditorMeetingInfoEditDatePopoverContents extends React.Component{  

	constructor(props){
    super(props)        
    this.selectStartTime=this.selectStartTime.bind(this)
    this.selectEndTime=this.selectEndTime.bind(this)
  }



  selectStartTime(e,time){
  	e.stopPropagation()
  	e.preventDefault()
  	this.props.selectNewStartTime(time)
  }

	selectEndTime(e,time){
  	e.stopPropagation()
  	e.preventDefault()
  	this.props.selectNewEndTime(time)
  }


	render(){	
		const {meeting,isStartTime} = this.props
		const startTimeValue = meeting.meeting_start
		const endTimeValue = meeting.meeting_end
		const {showStartTimeDropdown, openStartTimeDropdown, closeStartTimeDropdown, showEndTimeDropdown, openEndTimeDropdown, closeEndTimeDropdown} = this.props
		const currentActiveTime=formatCurrentActiveTime(this.props.currentTime)
		if(isStartTime){
			const times = calculateTimes()
			return(	  		
		    <div className='editMeetingDateTimePopover-list editMeetingDateTimePopover-list--startTime'>
					{times.map((time)=>{
						let label = formatLabel(time)
						const currentActiveTime=formatCurrentActiveTime(startTimeValue)
						const isActive=currentActiveTime==time 
						return(
							<TimeSelectPopoverContentsRow 
								label={label} 
								isActive={isActive}
								time={time}
								selectTime={this.selectStartTime}
								closePopover={this.props.closePopover}
								/>
							)
						}
					)}
				</div>
			)
		}
	  else {
	  	const times = calculateEndTimes(startTimeValue)
	  	return (	  		
		    <div className='editMeetingDateTimePopover-list editMeetingDateTimePopover-list--endTime'>
					{times.map((time)=>{
						let label = formatLabel(time.time)
						const currentActiveTime=formatCurrentActiveTime(endTimeValue)
						const isActive=currentActiveTime==time.time
						return(
							<TimeSelectPopoverContentsRow 
								label={label} 
								isActive={isActive}
								time={time.time}
								duration={time.duration}
								selectTime={this.selectEndTime}
								closePopover={this.props.closePopover}
							/>
						)
				})}
				</div>
	  	)
	  }
	}
}

export default DocEditorMeetingInfoEditDatePopoverContents