import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_TEMPLATES_SUCCESS':
		return action.response

	case 'CREATE_TEMPLATE_SUCCESS':
		const template = action.response		
		return [...state,template]



	case 'UPDATE_TEMPLATE_SUCCESS':
		const template_id=action.response['template_id']
		let updatedIndex=findIndex(state,(template => {
			return template.template_id === template_id
		}))

		if(updatedIndex === -1){
			updatedIndex=state.length
		}
		return [
			...state.slice(0,updatedIndex),
			action.response,
			...state.slice(updatedIndex + 1)
		]
		

	case 'ARCHIVE_TEMPLATE_REQUEST':
	case 'ARCHIVE_TEMPLATE_SUCCESS':
		const templateId = action.templateId
		let index = findIndex(state,(template => {
			return template['template_id'] === templateId
		}))
		if(index>-1){
			return [
			...state.slice(0,index),
			...state.slice(index + 1)
			]
		}else return state

	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}

