import store from '../store'
import {createDoc,createDocWithId} from '../actions/docs'
import {getDocForId} from './getDocForId'
import {openDoc} from './openDoc'
import {randomID} from './randomID'
import {getCurrentUser} from './getCurrentUser'
import {hideDocTemplatesModal} from './templates/hideDocTemplatesModal'


const template={
	version:0,
	name:'Untitled Doc',
	source:{
		"type": "doc", "content": [
			{"type": "editorPage", "content": [
				{"type": "paragraph"}
			]}
		]}
}

function getNewDocTemplate(){
	const currentUser=getCurrentUser()
	let doc={}
	const nowDate=new Date()
	if(currentUser){
		doc.created_by=currentUser.user_id
	}
	doc.created_at=nowDate
	doc.updated_at=nowDate
	doc.name=template.name
	doc.source=template.source
	doc.version=1
	doc.team=null
	doc.roadmap=null
	doc.project=null
	doc.is_org_doc=false
	const docId=`${randomID()}`
	doc.doc_id=docId
	return doc
}


export function createNewDocFromTemplate(history,docTemplate) {

	let newDoc=getNewDocTemplate()
	newDoc.source = docTemplate.source
	newDoc.template = docTemplate.template_id
	store.dispatch(createDocWithId(newDoc))
	hideDocTemplatesModal()
	openDoc(history,newDoc['doc_id'])
}



export function createNewRoadmapDoc(history,roadmapId) {
	let newDoc=getNewDocTemplate()
	newDoc.roadmap=roadmapId
	store.dispatch(createDocWithId(newDoc))
	openDoc(history,newDoc['doc_id'])
}

export function createNewOrgDoc(history) {
	let newDoc=getNewDocTemplate()
	newDoc.is_org_doc=true
	store.dispatch(createDocWithId(newDoc))
	openDoc(history,newDoc['doc_id'])
}


export function createNewTeamDoc(history,teamId) {
	let newDoc=getNewDocTemplate()
	newDoc.team=teamId
	store.dispatch(createDocWithId(newDoc))
	openDoc(history,newDoc['doc_id'])
}

export function createNewProjectDoc(history,projectId) {
	let newDoc=getNewDocTemplate()
	newDoc.project=projectId
	newDoc.followers=[]
	store.dispatch(createDocWithId(newDoc))
	openDoc(history,newDoc['doc_id'])
}



//NOv 25th try to make this feel quicker by assigning id on client and navigating to it
export function createNewDoc(history) {
	let newDoc=getNewDocTemplate()
	store.dispatch(createDocWithId(newDoc))
	openDoc(history,newDoc['doc_id'])
}




export function createNewDocForLink(parentDoc,navigateToDoc,history) { //This used to have stuff about channels in it
	//find the channel of current doc then create new doc in that channel
	const doc=getDocForId(parentDoc)
	return store.dispatch(createDoc(null)).then((doc)=>{
		return doc
	})
}