import React from 'react'
import * as ContextMenu from '@radix-ui/react-context-menu';
import KeyboardShortcutTag from '../../../components/misc/KeyboardShortcutTag'
import {maybeAddTableColumnAfter,maybeAddTableColumnBefore,maybeAddTableRowBefore,maybeAddTableRowAfter} from '../../../prosemirror/utils/commands/tableCommands'

class TableCellContextMenuReactComponent extends React.Component{  

  constructor(){
    super()   
    this.onOpenChange=this.onOpenChange.bind(this)    
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.state={      
      contextMenuOpen:false
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)  
  }

  componentWillUnmount(){
    window.removeEventListener('keydown', this.handleKeyDown)  
  }

  onOpenChange(openState){
    this.props.handleContextMenuOpenChange(openState)
  }

  handleKeyDown(e){
    if(e.altKey && e.keyCode==38){ //option + up arrow
      if(this.state.contextMenuOpen){
        maybeAddTableRowBefore(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    }
    else if(e.altKey && e.keyCode==40){ //option + down arrow
      if(this.state.contextMenuOpen){
        maybeAddTableRowAfter(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    }
    else if(e.altKey && e.keyCode==38){ //option + up arrow
      if(this.state.contextMenuOpen){
        maybeAddTableRowBefore(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    }
    else if(e.altKey && e.keyCode==37){ //option + left arrow
      if(this.state.contextMenuOpen){
        maybeAddTableColumnBefore(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    }
    else if(e.altKey && e.keyCode==39){ //option + right arrow
      if(this.state.contextMenuOpen){
        maybeAddTableColumnAfter(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    } 
  }

  
  render(){ 
    return (
     <React.Fragment>
      <ContextMenu.Root onOpenChange={this.onOpenChange}>  
            <ContextMenu.Trigger asChild>      
              <div className='doc-table-cell-content-bgTrigger' />              
            </ContextMenu.Trigger>
            <ContextMenu.Portal>
              <ContextMenu.Content collisionPadding={10} className='contextMenu'>                           
                          
                
                <div className='contextMenu-menu-cellColorSelect'>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'white')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--white'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>                    
                  </div>

                  <div  onClick={(e)=>{this.props.setCellBackgroundColor(e,'purple')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--purple'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>

                  <div  onClick={(e)=>{this.props.setCellBackgroundColor(e,'red')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--red'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'blue')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--blue'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'orange')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--orange'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'green')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--green'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'pink')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--pink'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>                  
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'sky')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--sky'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>                
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'teal')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--teal'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>

                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'grey')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--grey'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                </div>

                <ContextMenu.Separator />      

                <ContextMenu.Item onSelect={()=>{this.props.addColumn('after')}}>                
                  Add After
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                   <KeyboardShortcutTag              
                    option                  
                    iconName='arrowRight'
                  />
                </div>
                </ContextMenu.Item>
                <ContextMenu.Item  onSelect={()=>{this.props.addColumn('before')}}>               
                  Add Before
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                     <KeyboardShortcutTag              
                      option                  
                      iconName='arrowLeft'
                    />
                  </div>
                </ContextMenu.Item>
                <ContextMenu.Item onSelect={()=>{this.props.addRow('above')}}>                
                  Add Above
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                     <KeyboardShortcutTag              
                      option                  
                      iconName='arrowUp'
                    />
                  </div>
                </ContextMenu.Item>
                <ContextMenu.Item onSelect={()=>{this.props.addRow('below')}}>                
                  Add Below
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                     <KeyboardShortcutTag              
                      option                  
                      iconName='arrowDown'
                    />
                  </div>
                </ContextMenu.Item>
                


                <ContextMenu.Separator />                  
                <ContextMenu.Item onSelect={this.props.clearCellContents}>                
                  Clear Contents
                </ContextMenu.Item>    
                <ContextMenu.Item onSelect={this.props.deleteColumn}>
                  Delete Column
                </ContextMenu.Item>    
                <ContextMenu.Item onSelect={this.props.deleteRow}>                
                  Delete Row
                </ContextMenu.Item>    
              </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>

        <ContextMenu.Root onOpenChange={this.onOpenChange}>  
            <ContextMenu.Trigger asChild>      
              <div className='doc-table-cell-content' ref={this.props.setRef}>                                  
                <div className='doc-table-cell-content-trigger' />
              </div>
            </ContextMenu.Trigger>
            <ContextMenu.Portal>
              <ContextMenu.Content collisionPadding={10} className='contextMenu'>                           
                          
                
                <div className='contextMenu-menu-cellColorSelect'>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'white')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--white'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>                    
                  </div>

                  <div  onClick={(e)=>{this.props.setCellBackgroundColor(e,'purple')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--purple'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>

                  <div  onClick={(e)=>{this.props.setCellBackgroundColor(e,'red')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--red'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'blue')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--blue'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'orange')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--orange'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'green')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--green'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'pink')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--pink'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>                  
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'sky')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--sky'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>                
                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'teal')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--teal'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>

                  <div onClick={(e)=>{this.props.setCellBackgroundColor(e,'grey')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--grey'>
                    <div className='contextMenu-menu-cellColorSelect-btn-inner'>

                    </div>
                    <div className='contextMenu-menu-cellColorSelect-btn-border' />
                  </div>
                </div>

                <ContextMenu.Separator />      

                <ContextMenu.Item onSelect={()=>{this.props.addColumn('after')}}>                
                  Add After
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                   <KeyboardShortcutTag              
                    option                  
                    iconName='arrowRight'
                  />
                </div>
                </ContextMenu.Item>
                <ContextMenu.Item  onSelect={()=>{this.props.addColumn('before')}}>               
                  Add Before
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                     <KeyboardShortcutTag              
                      option                  
                      iconName='arrowLeft'
                    />
                  </div>
                </ContextMenu.Item>
                <ContextMenu.Item onSelect={()=>{this.props.addRow('above')}}>                
                  Add Above
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                     <KeyboardShortcutTag              
                      option                  
                      iconName='arrowUp'
                    />
                  </div>
                </ContextMenu.Item>
                <ContextMenu.Item onSelect={()=>{this.props.addRow('below')}}>                
                  Add Below
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                     <KeyboardShortcutTag              
                      option                  
                      iconName='arrowDown'
                    />
                  </div>
                </ContextMenu.Item>
                


                <ContextMenu.Separator />                  
                <ContextMenu.Item onSelect={this.props.clearCellContents}>                
                  Clear Contents
                </ContextMenu.Item>    
                <ContextMenu.Item onSelect={this.props.deleteColumn}>
                  Delete Column
                </ContextMenu.Item>    
                <ContextMenu.Item onSelect={this.props.deleteRow}>                
                  Delete Row
                </ContextMenu.Item>    
              </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>   
        </React.Fragment>
    )
  }
}

export default TableCellContextMenuReactComponent
