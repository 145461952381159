import React from 'react'
import Icon from './Icon'

class FigmaStatusIcon extends React.PureComponent{    
  render(){ 
    const {status} = this.props
        
    return (
      <div className='figmaStatusIconContainer'>
            
        {status === 'concept' &&  // concept
          <div className='figmaStatusIcon figmaStatusIcon--concept'>
            <Icon name='linearStartedStateIconOneQuarter' />            
          </div>
        }

        {status === 'started' && // aka in progress
          <div className='figmaStatusIcon figmaStatusIcon--started'>            
            <Icon name='linearStartedStateIconOneHalf' />            
          </div>
        }

        {status === 'proposed' &&
          <div className='figmaStatusIcon figmaStatusIcon--proposed'>
            <Icon name='forwardArrowSemibold' />
            <div className='figmaStatusIcon--completed--innerBorder' />
          </div>
        }

        {status === 'completed' && // aka completed
          <div className='figmaStatusIcon figmaStatusIcon--completed'>
            <Icon name='toDoItemCheckmark' />
            <div className='figmaStatusIcon--completed--innerBorder' />
          </div>
        }
      
        {/*}
        {status === 'cancelled' &&
          <div className='figmaStatusIcon figmaStatusIcon--canceled'>
            <Icon name='linearCancelledStateIcon' /> 
            <div className='figmaStatusIcon--canceled--innerBorder' />
          </div>
        }
        
        {status === 'paused' &&
          <div className='figmaStatusIcon figmaStatusIcon--paused'>
            <Icon name='linearPausedPriorityIcon' /> 
            <div className='figmaStatusIcon--paused--innerBorder' />
          </div>
        } */}
      
      </div>
    )
  }
}

export default FigmaStatusIcon
