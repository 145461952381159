import React from 'react'
import Icon from '../../../misc/Icon'
import debounce from 'lodash/debounce'
import DocImageZoomedModalBtn from '../DocImageZoomedModalBtn'
import DocFigmaEmbedZoomedModalThreadPanel from './DocFigmaEmbedZoomedModalThreadPanel'
import DocFigmaEmbedZoomedModalHeader from './DocFigmaEmbedZoomedModalHeader'
import DocFigmaEmbedZoomedModalCanvas from './DocFigmaEmbedZoomedModalCanvas'
import DocFigmaEmbedZoomedModalFooter from './DocFigmaEmbedZoomedModalFooter'
import {getFigmaEmbedForId} from '../../../../utils/getFigmaEmbedForId'
import {updateFigmaEmbedCropValues} from '../../../../prosemirror/utils/updateFigmaEmbedCropValues'
import Measure from 'react-measure'


class DocFigmaEmbedZoomedModalContents extends React.Component{  
 
  constructor(props) {
    super(props)            
    this.handleKeyDown=this.handleKeyDown.bind(this)   
    this.setThreadPanelState=this.setThreadPanelState.bind(this)    
    this.clickCanvas = this.clickCanvas.bind(this);
    this.enableCropMode=this.enableCropMode.bind(this)
    this.disableCropMode=this.disableCropMode.bind(this)
    this.updateCropValues=this.updateCropValues.bind(this)
    this.updateCropPosition=this.updateCropPosition.bind(this)

    //find figma embed node
  	let figmaNode
		if(window.view && window.view.state){
			let tr = window.view.state.tr
			window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
				if(node.type.name==="docFigmaEmbed" && node.attrs.embedId==props.embedId){
					figmaNode=node
				}	
			})

		}

		const embedObj=getFigmaEmbedForId(props.embedId)
		let frameWidth=embedObj.absolute_render_bounds.width





		let cropboxWidth = embedObj.absolute_render_bounds.width
    let cropboxHeight = embedObj.absolute_render_bounds.height
    let frameHeight=embedObj.absolute_render_bounds.height
    let cropboxX = 0
    let cropboxY= 0   	
    if(figmaNode && figmaNode.attrs.crop){
    	const crop=figmaNode.attrs.crop
    	cropboxWidth = crop.width
    	cropboxHeight = crop.height
    	cropboxX = crop.x
    	cropboxY= crop.y
    }


    this.state = { 
    	frameWidth:frameWidth,
    	frameHeight:frameHeight,
   		cropboxWidth:  cropboxWidth,
      cropboxHeight: cropboxHeight,
      cropboxX: cropboxX,
      cropboxY: cropboxY, 	
    	showThreadPanel: false,
    	cropModeActive: false,  	
    	isChangingThreadPanelState: false,
    	justOpenedModal: true,
    	// centerContentDimensions: {
    	// 	width: -1,
    	// 	height: -1,
    	// },
    	modalDimensions: {
    		width: -1,
    		height: -1,
    	}, 
    	rightPanelDimensions: {
    		width: -1,
    		height: -1,
    	},	    
   	};
    this.timeout=null
  }

 	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)
		this.timeout=setTimeout(function() {
			this.setState({justOpenedModal: false})
		}.bind(this), 200)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
		if(this.timeout){
			clearTimeout(this.timeout)
		}
	}

	updateCropValues(values){
		const { cropboxWidth,cropboxHeight,cropboxX,cropboxY}=values
		this.setState({cropboxWidth:cropboxWidth,cropboxHeight:cropboxHeight,cropboxX:cropboxX,cropboxY:cropboxY})
	}

	updateCropPosition(values){
		const { cropboxX,cropboxY}=values
		this.setState({cropboxX:cropboxX,cropboxY:cropboxY})
	}


	handleKeyDown(e){		
		if(e.keyCode==27){//escape
			this.props.unzoom()
		}
	}

	setThreadPanelState(value){
		this.setState({showThreadPanel: value,isChangingThreadPanelState: true})
		this.timeout= setTimeout(function() {
			this.setState({isChangingThreadPanelState: false})
		}.bind(this), 200)		
	}


	clickCanvas(){
		if(!this.state.cropModeActive){
			this.props.unzoom()
		}
	}

	disableCropMode(){ //TODO maybe rename from diasable to end or something
		this.setState({cropModeActive: false})
		//also do save 
		const crop={
			width:this.state.cropboxWidth,
			height:this.state.cropboxHeight,
			x:this.state.cropboxX,
			y:this.state.cropboxY
		}
		updateFigmaEmbedCropValues(this.props.embedId,crop) //updates node attrs

		if(this.props.saveUpdatedCropValues){ //for playground
			this.props.saveUpdatedCropValues(crop)
		}
	}

	enableCropMode(){
		this.setState({cropModeActive: true})
	}

	render(){ 
		const {showThreadPanel, cropModeActive}=this.state

		// MISC STUFF		
		const {embedId}=this.props
		const embedObj=getFigmaEmbedForId(embedId)

		
		let title
		let width
		let height
		let deliveryUrl
		
		if(embedObj){
			width = embedObj.absolute_render_bounds.width // could also be absolute_bounding_box
			height = embedObj.absolute_render_bounds.height // could also be absolute_bounding_box
			deliveryUrl = embedObj.image_url
			title = embedObj.node_title	
		}

		const figmaImageScale = 4 

		const modalHeaderHeight = 40
		const modalFooterHeight = 56

		let centerContentWidth = this.state.modalDimensions.width
		if(showThreadPanel){
			centerContentWidth = this.state.modalDimensions.width - this.state.rightPanelDimensions.width
		}

		let panelRight = 0
		if(showThreadPanel){
			panelRight = this.state.rightPanelDimensions.width
		}

		const canvasHeight = this.state.modalDimensions.height - modalHeaderHeight - modalFooterHeight
		let canvasWidth = this.state.modalDimensions.width

		if(showThreadPanel){
			canvasWidth = this.state.modalDimensions.width - this.state.rightPanelDimensions.width	
		}


		return (
			<React.Fragment>
				<Measure
	        bounds
	        onResize={contentRect => {
	          this.setState({ modalDimensions: contentRect.bounds })
	        }}
	      >
	        {({ measureRef }) => (
	          <div ref={measureRef} className='doc-zoomedFigmaModal-modalMeasurer' />
	        )}
	      </Measure>

			<div style={{right: `${panelRight}px`, height: `${modalHeaderHeight}px`}}  className={'doc-zoomedFigmaModal-headerContainer ' + (showThreadPanel ? 'doc-zoomedFigmaModal-headerContainer--panelOpen' : 'doc-zoomedFigmaModal-headerContainer--panelClosed')}>				
				<DocFigmaEmbedZoomedModalHeader 
					title={title}
					showThreadPanel={showThreadPanel}					
					openThreadPanel={()=>{this.setThreadPanelState(true)}}
					cropModeActive={cropModeActive}				
					enableCropMode={this.enableCropMode}
					disableCropMode={this.disableCropMode}
				/>				
			</div>

			<div onClick={()=>{this.clickCanvas()}} onDoubleClick={()=>{this.clickCanvas()}} style={{top: `${modalHeaderHeight}px`, height: `${canvasHeight}px`, width: `${canvasWidth}px`}}  className='doc-zoomedFigmaModal-canvasOuterContainer'>
				<DocFigmaEmbedZoomedModalCanvas 					
					frameWidth={this.state.frameWidth}
					frameHeight={this.state.frameHeight}
		   		cropboxWidth={this.state.cropboxWidth}
     			cropboxHeight={this.state.cropboxHeight}
     			cropboxX={this.state.cropboxX}
      		cropboxY={this.state.cropboxY} 	
      		updateCropValues={this.updateCropValues}
      		updateCropPosition={this.updateCropPosition}
					cropModeActive={cropModeActive}
					frameImageUrl={deliveryUrl}
					canvasWidth={canvasWidth}
					canvasHeight={canvasHeight}
					isChangingThreadPanelState={this.state.isChangingThreadPanelState}
					justOpenedModal={this.state.justOpenedModal}
				/>									
      </div>
			
			<div style={{height: `${modalFooterHeight}px`}} className='doc-zoomedFigmaModal-footerContainer'>				
				<DocFigmaEmbedZoomedModalFooter		
					isPrivateDoc={this.props.isPrivateDoc}			
					cropModeActive={cropModeActive}				
					disableCropMode={this.disableCropMode}
					sendMediaModalMessage={this.props.sendMediaModalMessage}
					embedId={embedId}
					embedObj={embedObj}
					unzoom={this.props.unzoom}
				/>
			</div>

			<Measure
	        bounds
	        onResize={contentRect => {
	          this.setState({ rightPanelDimensions: contentRect.bounds })
	        }}
	      >
	        {({ measureRef }) => (
	          <div ref={measureRef} className='doc-zoomedFigmaModal-rightPanelWrapperContainer doc-zoomedFigmaModal-rightPanelWrapperContainer--ghost' />
	        )}
	      </Measure>
		
			{showThreadPanel &&
			<div className='doc-zoomedFigmaModal-rightPanelWrapperContainer'>									
					<DocFigmaEmbedZoomedModalThreadPanel
						closeThreadPanel={()=>{this.setThreadPanelState(false)}}
					/>																		
			</div>
			}	
			<div onClick={this.props.unzoom} className='doc-zoomedFigmaModal-closeBtnOuterContainer'>
				<DocImageZoomedModalBtn
					iconName='cross'								
				/>
			</div>
			
			</React.Fragment>
		)
	}
}

export default DocFigmaEmbedZoomedModalContents