import moment from 'moment'
import store from '../store'
import filter from 'lodash/filter'
import {getIssuesForRoadmap} from './getIssuesForRoadmap'
import {getRoadmaps} from './getRoadmaps'

const LOOKBACK_PERIOD=6 //months

const MAX_DAY_COUNT=5 //normalise to between 0 and 5

const MAX_SINGLE_DAY=10 //cap out at 10

function calculateValuesForHeatMap(startDate,endDate,issues,roadmap){
	let activityData=[]
	let currentDate = startDate.clone(); // Clone the startDate to avoid modifying the original date
	let totalCompletedCount=0
	let maxDayCount=0
	while (currentDate.isSameOrBefore(endDate)) {
		const formattedDate=currentDate.format("YYYY-MM-DD")
		let filteredIssues=filter(issues,function(issue){
			if(issue.completed_at||issue.started_at){
				if (currentDate.isSame(moment(issue.completed_at), 'day')) {
					totalCompletedCount+=1
					return issue
				}
			}
		})
		if(filteredIssues.length>maxDayCount){
			maxDayCount=filteredIssues.length
		}
  	activityData.push({date:formattedDate,count:filteredIssues.length})
 	 currentDate.add(1, 'days');
	}
	const adjustedMaxDayCount=Math.min(maxDayCount,MAX_SINGLE_DAY)
	if(adjustedMaxDayCount>MAX_DAY_COUNT){
		//normalise
		let newArray=[]
		activityData.forEach((data)=>{
			let newData={...data}
			newData.count=Math.ceil(Math.min(data.count,MAX_SINGLE_DAY)*MAX_DAY_COUNT/adjustedMaxDayCount)
			newArray.push(newData)
		})
		activityData=newArray
	}
	return activityData
}


export function calculateHeatmapForRoadmap(roadmap){
	const issues=getIssuesForRoadmap(roadmap)
	const startDate=moment().subtract(6,'months')
	const endDate=moment()
	const activityData=calculateValuesForHeatMap(startDate,endDate,issues,roadmap)

	return activityData
}



export function calculateAllRoadmapHeatmaps(){
	let heatmaps={}
	const roadmaps=getRoadmaps()
	roadmaps.forEach((roadmap)=>{
		const heatmap=calculateHeatmapForRoadmap(roadmap)
		heatmaps[roadmap.roadmap_id]=heatmap
	})
	return heatmaps
	//store.dispatch({ type:'UPDATE_ROADMAPS_HEATMAPS',heatmaps})
}


export function recalcHeatmaps(){
	const state = store.getState()
	const roadmaps = state.roadmaps
	let heatmaps={}
	roadmaps.forEach((roadmap)=>{
		const heatmap=calculateHeatmapForRoadmap(roadmap)
		heatmaps[roadmap.roadmap_id]=heatmap
	})
	// return heatmaps
	store.dispatch({ type:'UPDATE_ROADMAPS_HEATMAPS',heatmaps})
}



