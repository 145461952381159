import React from 'react'
import Icon from '../misc/Icon'
import LinearMarker from '../misc/LinearMarker'
import EditMetadataPopoverWrapper from '../popovers/EditMetadataPopoverWrapper'
import {getProjectForId} from '../../utils/getProjectForId'
import {updateProject} from '../../utils/updateProject/updateProject'

class DocEditorProjectIcon extends React.Component{  

	constructor(){
    super()   
    this.handleUpdateProjectIcon=this.handleUpdateProjectIcon.bind(this)    
    this.state={
      showEditProjectIconPopover: false, 

    }
  }

  handleUpdateProjectIcon(icon,color){
  	const oldProject=getProjectForId(this.props.projectId)
  	let newProject={...oldProject}
  	newProject.icon=icon
  	newProject.color=color
  	
  	updateProject(newProject)


  }


	render(){	  

		const {projectId} = this.props
		const project = getProjectForId(projectId)
    let icon
    let color
    if(project){
      icon=project.icon
      color=project.color
    }


		const {showEditProjectIconPopover} = this.state
		

	  return (	  	
      
      <EditMetadataPopoverWrapper
				showPopover={showEditProjectIconPopover}						
				openPopover={()=>{this.setState({showEditProjectIconPopover: true})}}
				closePopover={()=>{this.setState({showEditProjectIconPopover: false})}}		
				type="projectIcon"  // used to load in popover contents
				layout="editProjectModal" // used for positioning
				projectId={this.props.projectId}		
				icon={icon}
      	color={color}	    
      	updateProjectIcon={this.handleUpdateProjectIcon}   		
      	portalled        
			>	
				<div onClick={()=>{this.setState({showEditProjectIconPopover: true})}} className={'docEditor-projectMarker ' + (icon ? ' docEditor-projectMarker--hasIcon ' : ' docEditor-projectMarker--noIcon ')}>
          {icon &&
          	<LinearMarker projectId={this.props.projectId} />
          }
          {!icon &&
          	<Icon name='plus' />
          }
      		<div className='docEditor-projectMarker-bg' />
        </div>
			</EditMetadataPopoverWrapper>		

	  )
	}
}

export default DocEditorProjectIcon
