import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import {setMeetingType} from '../../utils/meetings/setMeetingType'

class MeetingsPageTableFilterPopover extends React.Component{  

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)	
		this.handleUpdateMeetingType=this.handleUpdateMeetingType.bind(this)	
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  handleUpdateMeetingType(meetingType){
  	const {meeting} = this.props 
  	setMeetingType(meeting.meeting_id,meetingType)
  	this.props.closePopover()
  }

	render(){	
		const {showPopover, openPopover, closePopover} = this.props
		const {forceMount} = this.state
		const translateY = -8
		const popoverSide = "bottom"
		let popoverSideOffset= 4
		let popoverAlign = "start"				
		let popoverAlignOffset= -10

	  return (
	  	<React.Fragment>
		  	<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>						
						{this.props.children}
					</Popover.Trigger>					
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'editMeetingTypePopoverContainer ' + (showPopover ? ' editMeetingTypePopoverContainer--visible ' : ' editMeetingTypePopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							onInteractOutside={closePopover}						
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
								enter={{ opacity: 1, transform: 'translateY(0px)'  }}
								leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 35}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="editMeetingTypePopover popover"> 	
										

									 	{this.props.setMeetingTypeFilter && 
										 	<React.Fragment>
												<div onClick={()=>{this.props.setMeetingTypeFilter('discovery')}} className='popoverMenu-item popoverMenu-item--tableFilter'>																									
													<div className='popoverMenu-item-label'>
														Discovery
													</div>
												</div>
												<div onClick={()=>{this.props.setMeetingTypeFilter('designReview')}} className='popoverMenu-item popoverMenu-item--tableFilter'>																									
													<div className='popoverMenu-item-label'>
														Design Review
													</div>
												</div>
												<div onClick={()=>{this.props.setMeetingTypeFilter('teamSync')}} className='popoverMenu-item popoverMenu-item--tableFilter'>																									
													<div className='popoverMenu-item-label'>
														Team Sync
													</div>
												</div>
											</React.Fragment>
										}


									 	{this.props.setTimeFilter && 
										 	<React.Fragment>
												<div onClick={()=>{this.props.setTimeFilter(null)}} className='popoverMenu-item popoverMenu-item--tableFilter'>																									
													<div className='popoverMenu-item-label'>
														Any Time
													</div>
												</div>
												<div onClick={()=>{this.props.setTimeFilter('future')}} className='popoverMenu-item popoverMenu-item--tableFilter'>																									
													<div className='popoverMenu-item-label'>
														Upcoming Meetings
													</div>
												</div>
												<div onClick={()=>{this.props.setTimeFilter('past')}} className='popoverMenu-item popoverMenu-item--tableFilter'>																									
													<div className='popoverMenu-item-label'>
														Past Meetings
													</div>
												</div>
											</React.Fragment>
										}

									 	{this.props.setMembershipFilter && 
										 	<React.Fragment>
												<div onClick={()=>{this.props.setMembershipFilter('everyone')}} className='popoverMenu-item popoverMenu-item--tableFilter'>																									
													<div className='popoverMenu-item-label'>
														Everyone
													</div>
												</div>
												<div onClick={()=>{this.props.setMembershipFilter('yourMeetings')}} className='popoverMenu-item popoverMenu-item--tableFilter'>																									
													<div className='popoverMenu-item-label'>
														Your Meetings
													</div>
												</div>
											</React.Fragment>
										}


									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>
			</React.Fragment>
	  )
	}
}

export default MeetingsPageTableFilterPopover
