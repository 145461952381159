import {updateUserSettings} from '../actions/userSettings'
import store from '../store'




export function saveUserSettings(issueMentionSettings) {
	store.dispatch(updateUserSettings(issueMentionSettings))
}

