import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import KeyboardShortcutTag from '../../misc/KeyboardShortcutTag'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import sortBy from 'lodash/sortBy'
import {toggleShowNotionExportModal}  from '../../../utils/toggleShowNotionExportModal'
import {duplicateDoc} from '../../../utils/duplicateDoc'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getUserForId} from '../../../utils/getUserForId'
import {moveDocToTeam,moveDocToProject} from '../../../utils/moveDoc'
import {getTabStarredStatus,starTab,unstarTab} from '../../../utils/starredTabs'
import MoveDocPopover from '../../MoveDocPopover'
import {deleteDoc} from '../../../utils/deleteDoc'
import {createTemplate} from '../../../utils/templates/createTemplate'
import DocEditorCreateTemplatePopover from '../DocEditorCreateTemplatePopover'


class DocEditorPrivateDocTopMenu extends React.Component{  

	constructor(props){
    super(props)    
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.state={
      showMoveDocPopover: false,
    }
  }

  componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)		
	}

	componentWillUnmount(){
		window.removeEventListener('keydown', this.handleKeyDown)		
	}
	
	handleKeyDown(e){ // Open menu on shift command p		
		if(e.shiftKey && e.metaKey && e.keyCode==80){
			this.setState({showMoveDocPopover:true})						
		}		
	}

	render(){	

		const {sideMenuVisible,docName,projects,teams,docId,showDocTitle} = this.props
		const {showMoveDocPopover} = this.state
		const starred = getTabStarredStatus('doc',docId)

		

	  return (
	  	<div className={'docEditor-topMenuContainer docEditor-topMenuContainer--privateDoc ' + (sideMenuVisible ? ' docEditor-topMenuContainer--sideMenuState--visible ' : ' docEditor-topMenuContainer--sideMenuState--hidden ')}>  												
					<div className='docEditor-topMenu-center'>
						<div className={'docEditor-topMenu-center-docTitle' + (showDocTitle ? ' docEditor-topMenu-center-docTitle--show ' : ' docEditor-topMenu-center-docTitle--hidden ')}>
							{docName}
						</div>
						<div className='docEditor-topMenu-center-hSpacer' />										
					</div>

					<div className='docEditor-newTemplatePopoverTriggerContainer'>
						<DocEditorCreateTemplatePopover
							showPopover={this.state.showCreateTemplatePopover}						
							openPopover={()=>{this.setState({showCreateTemplatePopover: true})}}
							closePopover={()=>{this.setState({showCreateTemplatePopover: false})}}
							docId={docId}												
						>
							<div className='docEditor-newTemplatePopoverTrigger'/>							
						</DocEditorCreateTemplatePopover>							
					</div>	

				<div className='docEditor-topMenu-right'>					

									

						<MoveDocPopover
							showPopover={showMoveDocPopover}						
							openPopover={()=>{this.setState({showMoveDocPopover: true})}}
							closePopover={()=>{this.setState({showMoveDocPopover: false})}}		
							docId={docId}		
							layout="topMenuPrivate"				
						>
							<div className='docEditor-topMenu-movePopoverAnchor' /> 								
						</MoveDocPopover>	

							<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>			
									<button onClick={()=>{this.setState({showMoveDocPopover: true})}} className={'docEditor-topMenu-button docEditor-topMenu-button--textAndIcon' + (this.state.showMoveDocPopover ? ' docEditor-topMenu-button--active ' : ' ')}> 
										<div className='docEditor-topMenu-button-iconContainer'>
											<Icon name='arrowTurnUpRightMedium' />
										</div>
										<div className='docEditor-topMenu-button-label'>
											Move to
										</div>
									</button>
								</Tooltip.Trigger>				
								{!this.state.showMoveDocPopover &&
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																										
										<KeyboardShortcutTag command shift letter='P' keyboardShortcutOnly />									
								</Tooltip.Content>
								}
							</Tooltip.Root>
					</Tooltip.Provider>					


					{!starred && 
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>																	
								<button className='docEditor-topMenu-button docEditor-topMenu-button--inactiveStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{starTab('doc',docId)}}>
									<Icon name='star' />
								</button>																	
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Star Doc
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					}

					{starred && 
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>																	
								<button className='docEditor-topMenu-button docEditor-topMenu-button--activeStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{unstarTab('doc',docId)}}>
									<Icon name='starFill' />
								</button>																	
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Unstar Doc
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					}


					<DropdownMenu.Root>
				    <DropdownMenu.Trigger className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--dropdown'>
				    	<Icon name='ellipses' />
				    </DropdownMenu.Trigger>   			 	
	   			 	<DropdownMenu.Content sideOffset={1} align="end" alignOffset={-8} className='dropdownMenu'>							
	   			 		
	   			 		{/*
	   			 		<DropdownMenu.Item onSelect={this.integrationStuff}>
		        		Start Notion Integration
		        	</DropdownMenu.Item>
		        	*/}
		        	
		        	
		        	<DropdownMenu.Item onSelect={()=>{this.setState({showCreateTemplatePopover: true})}}>
		        		Turn into template...
		        	</DropdownMenu.Item>		        	

		        	<DropdownMenu.Item onSelect={this.props.duplicateDoc} >
		        		Duplicate doc
		        	</DropdownMenu.Item>
		        	
		        	<DropdownMenu.Separator />
		        			        	
		        	{/*}
		        	<DropdownMenu.Separator />
		        	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
		        		Export to Notion
		        	</DropdownMenu.Item>
		        	*/}
		        					
		       	 	<DropdownMenu.Item  className='dropdownMenu-item--warning' onSelect={()=>{deleteDoc(docId,this.props.history)}}>
		        		Delete doc
		        	</DropdownMenu.Item>					        
				    </DropdownMenu.Content>
					</DropdownMenu.Root>	  
					 				
				</div>
				
			</div>		
			
	  )
	}
}

export default withRouter(DocEditorPrivateDocTopMenu)
