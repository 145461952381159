import React from 'react'
import AvatarImage from '../../AvatarImage'

class DocVideoCommentRowMessage extends React.Component{  
	   
	render(){ 						
		const {darkModeMessage} = this.props
		return (	
			<div className='doc-video-commentRow-message'>
				<div className='doc-video-commentRow-message-leftContainer'>	
					<div className='doc-video-commentRow-message-avatarContainer'>
						<AvatarImage
							className='doc-video-commentRow-message-avatar'
							avatarUrl={this.props.avatarUrl}
						/>
					</div>						
				</div>
				<div className='doc-video-commentRow-message-rightContainer'>	
					<div className='doc-video-commentRow-message-header'>
						<div className='doc-video-commentRow-message-header-name'>
							{this.props.name}
						</div>
						<div className='doc-video-commentRow-message-header-timestamp'>
							{this.props.timestampLabel}
						</div>
					</div>
					<div className='doc-video-commentRow-message-content'>
						<div className='message-para'>
							{this.props.message}
						</div>
					</div>

				</div>			
			</div>												
		)
	}	
}

export default DocVideoCommentRowMessage