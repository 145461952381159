import {InputRule} from 'prosemirror-inputrules'
import schema from '../../schema/editorSchema'


// https://discuss.prosemirror.net/t/input-rules-for-wrapping-marks/537/12
// function markInputRule(regexp, markType, getAttrs) {
//   return new InputRule(regexp, (state, match, start, end) => {
//     let attrs = getAttrs instanceof Function ? getAttrs(match) : getAttrs
//     let tr = state.tr
//     if (match[1]) {
//       let textStart = start + match[0].indexOf(match[1])
//       let textEnd = textStart + match[1].length
//       if (textEnd < end) tr.delete(textEnd, end)
//       if (textStart > start) tr.delete(start, textStart)
//       end = start + match[1].length
//     }
//     return tr.addMark(start, end, markType.create(attrs))
//   })
// }





export function markdownBoldInputRule(){
 return new InputRule(/\*(.*)\*$/, (state, match, start, end) => state.tr
    .replaceRangeWith(start, end, schema.text(match[1], [schema.marks.strong.create()]))
    .removeStoredMark(schema.marks.strong) // with or without doesn't change anything
  )
}

