import {wrappingInputRule} from 'prosemirror-inputrules'
import schema from '../../schema/editorSchema'
import {getAnchorNode} from '../../utils/getAnchorNode'



export function alphabetListInputRule(){
	return new wrappingInputRule(/^([A|a])([\.|\)])\s/g, schema.nodes.bulletListItem,(match)=>{
		const state=window.view.state
		let {$from} = state.selection
		const anchorNode=getAnchorNode($from,'paragraph')
		let indentLevel=0
		if(anchorNode){ //Think this should always be true so might not need this logic
			if(anchorNode.attrs.indentLevel){
				indentLevel=anchorNode.attrs.indentLevel
			}
		}

		let markerType//or letterLowerCase
		let separatorType
		if(match[1]=='a'){
			markerType='letterLowerCase'
		}else if(match[1]=='A'){
			markerType='letter'
		}

		if(match[2]=='.'){
			separatorType='period'
		}else if(match[2]==')'){
			separatorType='bracket'
		}

		return {indentLevel:indentLevel,markerType:markerType,separatorType:separatorType}
	}
	)
}
