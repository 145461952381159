import React from 'react'
import Icon from '../../../misc/Icon'
import VideoDuration from '../../../../utils/videoDuration'

//Preview Types
	//docSelection- text snapshot of a bit of the doc
	//message - snapshot of parent message in a whatsapp reply
	//video- for commenting on doc video (not the same as a video message) 
	//image -for commenting on doc image (not the same as image message)


class MessageInputBoxSelectionPreview extends React.Component{  

	render(){			
		const {selectionPreviewType,newMessageParentNodeSnapshot,messageTextSnapshot} = this.props
		
		let messageText
		if(this.props.newMessageParentMessage){
			messageText = this.props.newMessageParentMessage['plain_text']
		}

		let docSelectionText
		if(selectionPreviewType=='docSelection'){
			docSelectionText=messageTextSnapshot
		}

		let videoTitle,muxId,videoPosterSrc,mediaDuration,videoTimestamp
		let imageTitle,imageSrc
		let figmaEmbedDeliveryURL,figmaEmbedTitle

		if(selectionPreviewType=='video' && newMessageParentNodeSnapshot){
			videoTitle = newMessageParentNodeSnapshot.title
			muxId = newMessageParentNodeSnapshot.muxId
			videoPosterSrc = `https://image.mux.com/${muxId}/thumbnail.png?width=160&height=90&fit_mode=crop&time=0`
			mediaDuration = "3:05"
			videoTimestamp = null
		}
		else if(selectionPreviewType=='image' && newMessageParentNodeSnapshot){
			imageTitle = newMessageParentNodeSnapshot.title
			imageSrc = newMessageParentNodeSnapshot.deliveryUrl
		}
		else if(selectionPreviewType=='figmaEmbed' && newMessageParentNodeSnapshot){
			figmaEmbedTitle = newMessageParentNodeSnapshot.node_title
			figmaEmbedDeliveryURL = newMessageParentNodeSnapshot.image_url
		}

		//
		// TO DO ITEM HARDCODED VALUE
		const toDoItemLabel = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed finibus dapibus felis quis pharetra.'
		const toDoItemChecked = true


		console.log('selection preview--------')
		console.log(selectionPreviewType)

		return (	  	
			<div className={'docEditor-messagePanel-inputBox-selectionPreview ' + (selectionPreviewType ? ` docEditor-messagePanel-inputBox-selectionPreview--${selectionPreviewType} ` : '')}>

				<div className='docEditor-messagePanel-inputBox-selectionPreview-bar' />			
					{selectionPreviewType === 'video' && 			
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content'>
							<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer--video'>
								<img src={videoPosterSrc} className='docEditor-messagePanel-inputBox-selectionPreview-content-media' />
							</div>
							<div className='docEditor-messagePanel-inputBox-selectionPreview-content-rightContainer'>
								{videoTitle &&
									<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle'>										
										{videoTitle}
									</div>
								}
								{!videoTitle && 
									<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle--untitled'>
										Untitled Video
									</div>
								}
								{!videoTimestamp &&
									<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaDuration'>
										Video
									</div>
								}
								{videoTimestamp &&
									<div className='docEditor-messagePanel-inputBox-selectionPreview-content-videoTimestamp'>
										<div className='docEditor-messagePanel-inputBox-selectionPreview-content-videoTimestamp-iconContainer'>
											<Icon name='stopwatchBold' />
										</div>
										<div className='docEditor-messagePanel-inputBox-selectionPreview-content-videoTimestamp-label'>
											<VideoDuration seconds={videoTimestamp} />
										</div>
									</div>
								}
							</div>
						</div>					
				}
				
				{selectionPreviewType === 'docSelection'&& 	
					<React.Fragment>							
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content'>
							{docSelectionText}
						</div>
					</React.Fragment>
				}

				{selectionPreviewType === 'message' && 	
					<React.Fragment>							
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content'>
							{messageText}
						</div>
					</React.Fragment>
				}

				{selectionPreviewType === 'image' && 			
					<div className='docEditor-messagePanel-inputBox-selectionPreview-content'>
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer--image'>
							<img src={imageSrc} className='docEditor-messagePanel-inputBox-selectionPreview-content-media' />
						</div>
						
						{imageTitle &&
							<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle'>										
								{imageTitle}
							</div>
						}

						{!imageTitle && 
							<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle--untitled'>
								Untitled Image
							</div>
						}
					</div>
				}
				{selectionPreviewType === 'table' && 			
					<div className='docEditor-messagePanel-inputBox-selectionPreview-content'>
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle--untitled'>
							Table
						</div>
					</div>
				}

				{selectionPreviewType === 'figmaEmbed' && 			
					<div className='docEditor-messagePanel-inputBox-selectionPreview-content'>
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer docEditor-messagePanel-inputBox-selectionPreview-content-mediaContainer--figmaEmbed'>
							<img src={figmaEmbedDeliveryURL} className='docEditor-messagePanel-inputBox-selectionPreview-content-media' />
						</div>											
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content-mediaTitle'>										
							{figmaEmbedTitle}
						</div>
					</div>
				}

				{selectionPreviewType === 'todoItem' && 			
					<div className='docEditor-messagePanel-inputBox-selectionPreview-content'>
						{/*{message.selection_snapshot} */}
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content-bar' />
						<div className='docEditor-messagePanel-inputBox-selectionPreview-content'>
							<div className='docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckboxContainer'>
								<div className={'docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckbox ' + (toDoItemChecked ? ' docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckbox--checked ' : ' docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemCheckbox--unchecked ')}>
									{toDoItemChecked && <Icon name='toDoItemCheckmark' /> }											 
								</div>
							</div>
							<div className='docEditor-messagePanel-inputBox-selectionPreview-content-toDoItemLabel'>
								{toDoItemLabel}
							</div>
						</div>
					</div>				
				}

				<button onClick={this.props.clearMessageSelection} className='docEditor-messagePanel-inputBox-selectionPreview-deleteBtn'>
					<Icon name='crossBold' />
				</button>

				<div className='docEditor-messagePanel-inputBox-selectionPreview-bg' />
				<div className='docEditor-messagePanel-inputBox-selectionPreview-backgroundBlock' />

			</div>			
		)
	}
}

export default MessageInputBoxSelectionPreview