import React from 'react'
import StageIcon from '../../../../misc/StageIcon'
import Icon from '../../../../misc/Icon'
import {getIssueForId} from '../../../../../utils/getIssueForId'
import {getUserForId} from '../../../../../utils/getUserForId'
import {openIssueInLinear} from '../../../../../utils/openInLinear'
import LinearIssuePreviewPopover from '../../../mentionPopovers/LinearIssuePreviewPopover'

const OPEN_POPOVER_DELAY=500

class MessageLinearIssueReactComponent extends React.Component{  

	constructor(props){
		super(props)
		this.state = {
			inputIsFocused:false,
			messageHTML:null
		}
	} 
	
	render(){
		const {issueId,showPopover}=this.props
		const issue=getIssueForId(issueId)
    // The indexes are only used when type is "started" to create pie-chart icon
    const workflowTypeStateIndex = 1  // index of workflow states within workflow type
    const workflowTypeStatesCount = 1 // number of states in workflow type
		
		if(issue){ //TODO maybe default state is issue is deleted or not loaded?
			const title=issue.title
			const assignee=issue.assignee
			const identifier=issue.identifier
	    const priority = issue.priority    	 
	    const projectId = issue.project
	    let workflowColorHex
	    let workflowType
	    let workflowStateName
	    if(issue.workflow_state){
	    	workflowColorHex=issue.workflow_state.color
	    	workflowType=issue.workflow_state.type
	    	workflowStateName=issue.workflow_state.name
	    } 
	    const description = issue.description
	    let avatar
	    let assigneeObj
	    if(assignee){
	    	assigneeObj=getUserForId(assignee)
	    	if(assigneeObj){
	    		avatar=assigneeObj.avatar
	    	}
	    }
	    

			return (
				<React.Fragment>
					<button onClick={()=>{openIssueInLinear(identifier)}} className='message-para-inlineLinearIssueContainer'>
						<div onMouseEnter={this.props.openPopover} onMouseLeave={this.props.closePopover} className='message-para-inlineLinearIssue'>
							{assignee && 
								<div className='message-para-inlineLinearIssue-assigneeContainer'>
									<div className='message-para-inlineLinearIssue-assignee doc-inlineLinearIssue-assignee--assigned'>
										<img className='message-para-inlineLinearIssue-assignee-img' src={avatar} />
									</div>
									<div className='message-para-inlineLinearIssue-hoverIconContainer'>
										<Icon name='linkArrow' />
									</div>
								</div>
							}  		
							{!assignee && 
								<div className='message-para-inlineLinearIssue-assigneeContainer'>
									<div className='message-para-inlineLinearIssue-assignee doc-inlineLinearIssue-assignee--unassigned'>
										<Icon name='userCircle' />
									</div>
									<div className='message-para-inlineLinearIssue-hoverIconContainer'>
										<Icon name='linkArrow' />
									</div>
								</div>
							}  		
							<div className='message-para-inlineLinearIssue-identifer'>
								{identifier}
							</div>  
						</div> 

						<LinearIssuePreviewPopover 
							assignee={assignee ? assigneeObj : null}
							workflowType={workflowType}
							workflowColorHex={workflowColorHex}
							workflowTypeStateIndex={workflowTypeStateIndex}
							workflowTypeStatesCount={workflowTypeStatesCount}
							workflowStateName={workflowStateName}
							title={title}
							identifier={identifier}
							priority={priority}
							description={description}
							projectId={projectId}
							showPopover={showPopover}
						>
							<div className='message-para-inlineLinearIssue-popoverTrigger'/>
						</LinearIssuePreviewPopover>
					</button>	  
			</React.Fragment>
			)				
		}else return null
	}
}

export default MessageLinearIssueReactComponent
