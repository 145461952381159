import React from 'react'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import AvatarImage from '../../AvatarImage'
import {getUserForId} from '../../../utils/getUserForId'
import {getTeamForId} from '../../../utils/getTeamForId'
import LinearProjectStatusTag from '../../misc/LinearProjectStatusTag'


class LinearProjectPreviewPopoverContent extends React.Component{  	
	

	render(){	
		const {name, leadUserId, description, memberIds, teamIds, status, projectId} = this.props		
		
  	let leadUser

  	if(leadUserId){
  		leadUser = getUserForId(leadUserId)
  	}

  	let hasMembers = false
  	if(memberIds && memberIds.length > 1){
  		hasMembers = true
  	}

		return (

			<div className='linearProjectPreviewPopover popover'>
				<div className='linearProjectPreviewPopover-topLineContainer'>											
					<div className='linearProjectPreviewPopover-markerContainer'>
						<LinearMarker projectId={projectId} />
					</div>
					<div className='linearProjectPreviewPopover-projectStatusTagContainer'>												
						<LinearProjectStatusTag 
							projectStatus={status}
						/>												
					</div>
				</div>
				<div className='linearProjectPreviewPopover-title'>
					{name}
				</div>


				<div className='linearProjectPreviewPopover-teamsContainer'>
					
							{teamIds.map((teamId)=>{
									const teamObj=getTeamForId(teamId)
									
									return(
										<div key={teamId} className='linearProjectPreviewPopover-teamTag'> 
											<div className='linearProjectPreviewPopover-teamTag-markerContainer'>
												<LinearMarker teamId={teamId} />
											</div>
											<div className='linearProjectPreviewPopover-teamTag-label'>
												{teamObj.name}
											</div>
										</div>
										)
								})}

						{/*}
						{teamIds && teamIds.map((teamId,i)=>{   

							let team
							if(teamId){
								team = getTeamForId(teamId)
							}

							return(
								<div key={i} className='linearProjectPreviewPopover-teamTag'> 
									<div className='linearProjectPreviewPopover-teamTag-markerContainer'>

									</div>
									<div className='linearProjectPreviewPopover-teamTag-name'>
										{team.name}
									</div>
								</div>
							)
						})}
						*/}

				</div>
				
				{description &&
					<div className='linearProjectPreviewPopover-descriptionContainer'>
						<div className='linearProjectPreviewPopover-description'>
							{description}
						</div>
					</div>
				}

				{!description &&
					<div className='linearProjectPreviewPopover-descriptionContainer linearProjectPreviewPopover-descriptionContainer--noDescription'>
						<div className='linearProjectPreviewPopover-description'>
							No description
						</div>
					</div>
				}
				
				<div className='linearProjectPreviewPopover-divider'/>
				
				<div className='linearProjectPreviewPopover-projectLead'>
					<div className='linearProjectPreviewPopover-projectLead-title'>
						Project Lead
					</div>
					{leadUser && 
						<div className='linearProjectPreviewPopover-projectLead-user linearProjectPreviewPopover-projectLead-user--assigned'>
							<div className='linearProjectPreviewPopover-projectLead-user-avatarContainer'>
								<AvatarImage className='linearProjectPreviewPopover-projectLead-user-avatar' avatarUrl={leadUser.avatar} />
							</div>
							<div className='linearProjectPreviewPopover-projectLead-user-name'>
								{leadUser.display_name}
							</div>
						</div>
					}
					{!leadUser && 
						<div className='linearProjectPreviewPopover-projectLead-user linearProjectPreviewPopover-projectLead-user--unassigned'>
							<div className='linearProjectPreviewPopover-projectLead-user-avatarContainer'>
								<Icon name='circleUser' />
							</div>
							<div className='linearProjectPreviewPopover-projectLead-user-name'>
								Unassigned
							</div>
						</div>
					}
				</div>
				
				<div className='linearProjectPreviewPopover-members'>
					<div className='linearProjectPreviewPopover-members-title'>
						Members
					</div>
					{hasMembers && 
						<React.Fragment>
							{memberIds.map((memberId)=>{
								if(memberId!==leadUserId){
									const memberObj=getUserForId(memberId)														
										return(																
											<div key={memberId} className='linearProjectPreviewPopover-member linearProjectPreviewPopover-projectLead-member--member'>
												<div className='linearProjectPreviewPopover-member-avatarContainer'>
													<AvatarImage className='linearProjectPreviewPopover-member-avatar' avatarUrl={memberObj.avatar} />
												</div>
												<div className='linearProjectPreviewPopover-member-name'>
													{memberObj.display_name}
												</div>
											</div>
										)}
									})}
						</React.Fragment>
					}
					{!hasMembers && 
						<div className='linearProjectPreviewPopover-member linearProjectPreviewPopover-member--noMember'>													
							<div className='linearProjectPreviewPopover-member-name'>
								No other members
							</div>
						</div>
					}
				</div>
				



			</div>
		)
	}
}

export default LinearProjectPreviewPopoverContent