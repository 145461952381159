import React from 'react'

class FigmaViewDeviceFrame extends React.Component{  
  
	render(){ 
		
		const {device, backgroundImage, deviceFrameBorder, scale, deviceFrame}=this.props
		
		let deviceFrameImageUrl

		let shadowBorderRadius
		
		let shadowRight
		let shadowTop
		let shadowLeft
		let shadowBottom

		let boxShadow
		
		let mobileDevice = false

		if(!deviceFrame){
			
			shadowBorderRadius = 6 * scale
			shadowRight = 0
			shadowLeft = 0
			shadowTop = 0
			shadowBottom = 0

			boxShadow = `0px ${2*scale}px ${4*scale}px 0px rgba(0,0,0,0.22), 0px ${4*scale}px ${8*scale}px 0px rgba(0,0,0,0.16), 0px ${8*scale}px ${16*scale}px 0px rgba(0,0,0,0.12), 0px ${16*scale}px ${32*scale}px 0px rgba(0,0,0,0.08)`
		}

		if(deviceFrame === 'iPhone13ProMax'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675856996/Devices/phones/13ProMaxGraphite_ycu5fc.png'			
			
			shadowBorderRadius = 80 * scale
			shadowRight = 4 * scale
			shadowLeft = 3.5 * scale
			shadowTop = 0.25 * scale
			shadowBottom = 0.25 * scale

			mobileDevice = true
		}
		if(deviceFrame === 'iPhone14ProMax'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676504461/Devices/iPhone14ProMax_t6gk34.png'

			shadowBorderRadius = 85 * scale
			shadowLeft = 3.5 * scale
			shadowRight = 3 * scale
			shadowBottom = 1.5 * scale
			shadowTop = 1 * scale			

			mobileDevice = true
		}

		if(deviceFrame === 'iPhone14'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676514824/Devices/iPhone14_rqvwwn.png'			
			
			shadowBorderRadius = 80 * scale
			shadowLeft = 3.5 * scale
			shadowRight = 3 * scale
			shadowBottom = 0.5 * scale
			shadowTop = 0.5 * scale

			mobileDevice = true
		}
		if(deviceFrame === 'iPhone14Pro'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676514824/Devices/iPhone14Pro_p6bbxo.png'			
			
			shadowBorderRadius = 80 * scale
			shadowTop = 0.5 * scale
			shadowLeft = 3 * scale
			shadowRight = 3 * scale
			shadowBottom = 0.5 * scale

			mobileDevice = true
		}
		if(deviceFrame === 'iPhone13Mini'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676514903/Devices/iPhone13Mini_as8oln.png'			
			
			shadowBorderRadius = 80 * scale
			shadowTop = 0.5 * scale
			shadowLeft = 3 * scale
			shadowRight = 3 * scale
			shadowBottom = 0.5 * scale

			mobileDevice = true
		}

		if(deviceFrame === 'AndroidLarge'){
			// samsung s21
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676517361/Devices/androidlarge_qb9s9w.png'			

			shadowBorderRadius = 44 * scale
			shadowTop = 1.25 * scale
			shadowLeft = 1.25 * scale
			shadowRight = 3.75 * scale
			shadowBottom = 1.5 * scale

			mobileDevice = true
		}
		if(deviceFrame === 'AndroidSmall'){
			// google pixel 1
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676518074/Devices/androidsmall_hlrrer.png'

			shadowBorderRadius = 58 * scale
			shadowTop = 1.25 * scale
			shadowLeft = 1 * scale
			shadowRight = 3 * scale
			shadowBottom = 1.5 * scale		

			mobileDevice = true	
		}

		if(mobileDevice){
			boxShadow = `0px ${2*scale}px ${4*scale}px 0px rgba(0,0,0,0.40), 0px ${4*scale}px ${8*scale}px 0px rgba(0,0,0,0.44), 0px ${8*scale}px ${16*scale}px 0px rgba(0,0,0,0.24), 0px ${16*scale}px ${32*scale}px 0px rgba(0,0,0,0.16)`
		}

		let tabletDevice

		if(deviceFrame === 'iPadPro11Landscape'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676509855/Devices/iPad11Landscape_gskuhu.png'			

			shadowBorderRadius = 68 * scale
			shadowTop = 2 * scale
			shadowLeft = 4 * scale
			shadowRight = 1.5 * scale
			shadowBottom = 1.5 * scale	

			tabletDevice = true				

		}
		if(deviceFrame === 'iPadPro11Portrait'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676509855/Devices/iPad11Portrait_yhc3je.png'			

			shadowBorderRadius = 68 * scale
			shadowTop = 2 * scale
			shadowLeft = 2 * scale
			shadowRight = 4 * scale
			shadowBottom = 2 * scale		

			tabletDevice = true	
		}
		if(deviceFrame === 'iPadPro129Landscape'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676509855/Devices/iPad129Landscape_ho3cdl.png'			

			shadowBorderRadius = 64 * scale
			shadowTop = 3 * scale
			shadowLeft = 3 * scale
			shadowRight = 1.5 * scale
			shadowBottom = 2 * scale		

			tabletDevice = true	
		}
		if(deviceFrame === 'iPadPro129Portrait'){
			deviceFrameImageUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676509855/Devices/iPad129Portrait_h4bnqs.png'			

			shadowBorderRadius = 64 * scale
			shadowTop = 3 * scale
			shadowLeft = 1.5 * scale
			shadowRight = 3 * scale
			shadowBottom = 2 * scale		

			tabletDevice = true	
		}

		if(tabletDevice){
			boxShadow = `0px ${4*scale}px ${8*scale}px 0px rgba(0,0,0,0.34), 0px ${8*scale}px ${16*scale}px 0px rgba(0,0,0,0.32), 0px ${16*scale}px ${32*scale}px 0px rgba(0,0,0,0.18), 0px ${32*scale}px ${64*scale}px 0px rgba(0,0,0,0.10)`
		}

		const arcOuterCornerRadius = 16 * scale
		const arcInnerCornerRadius = 10 * scale

		const scaledDeviceFrameBorder ={
			top: deviceFrameBorder.top * scale,
			left: deviceFrameBorder.left * scale,
			bottom: deviceFrameBorder.bottom * scale,
			right: deviceFrameBorder.right * scale,
		}
		
		if(deviceFrame === 'desktop-arc'){		
			shadowBorderRadius = 16 * scale
			shadowTop = 0
			shadowLeft = 0
			shadowRight = 0
			shadowBottom = 0

			boxShadow = `0px ${4*scale}px ${8*scale}px 0px rgba(0,0,0,0.20), 0px ${8*scale}px ${16*scale}px 0px rgba(0,0,0,0.18), 0px ${16*scale}px ${32*scale}px 0px rgba(0,0,0,0.09), 0px ${32*scale}px ${64*scale}px 0px rgba(0,0,0,0.05)`
		}
		
		return (			
			
			<React.Fragment>

				{deviceFrameImageUrl &&
					<div className='figmaView-deviceFrame'>				
							<img src={deviceFrameImageUrl} className='figmaView-deviceFrame-img' />					
					</div>
				}

				
				{deviceFrame === 'desktop-arc' &&
					<div className='figmaView-deviceFrame figmaView-deviceFrame--arc'>
						<div style={{borderRadius: `${arcOuterCornerRadius}px`}} className={'figmaView-deviceFrame--arc-outerContainer ' + (backgroundImage ? `figmaView-deviceFrame--arc-outerContainer--backgroundImage--${this.props.backgroundImage}` : '')}>
							<div style={{borderRadius: `${arcInnerCornerRadius}px`, top:`${scaledDeviceFrameBorder.top}px`, bottom:`${scaledDeviceFrameBorder.bottom}px`, left:`${scaledDeviceFrameBorder.left}px`, right:`${scaledDeviceFrameBorder.right}px`}} className='figmaView-deviceFrame--arc-innerContainer' />
						</div>
					</div>
				}


								

				{backgroundImage &&
					<div style={{borderRadius: `${shadowBorderRadius}px`, right: `${shadowRight}px`, left: `${shadowLeft}px`, top: `${shadowTop}px`, bottom: `${shadowBottom}px`, boxShadow: `${boxShadow}`}} className={'figmaView-deviceFrame-shadow ' + (deviceFrame ? ` figmaView-deviceFrame-shadow--device--${deviceFrame} ` : ' figmaView-deviceFrame-shadow--device--noDeviceFrame ') + (backgroundImage ? ` figmaView-deviceFrame-shadow--background--${backgroundImage} ` : '')} />
				}



			</React.Fragment>


			
						
		)
	}
}

export default FigmaViewDeviceFrame
