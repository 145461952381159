import React from 'react'
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import KeyboardShortcutTag from '../../misc/KeyboardShortcutTag'
import * as Tooltip from '@radix-ui/react-tooltip';

class DocTableHoverMenuBtn extends React.Component{  

	render(){
		const {active, type, tooltipLabel, iconName, onClickFunction, activeColor, hasKeyboardShortcut, extraIconName}=this.props

		let dataBtnState = null
		if(type === 'headerRow' || type === 'headerCol' || type === 'altRowFill' || type === 'textAlign'){
			if(active){
				dataBtnState = 'active'
			}
			else{
				dataBtnState = 'inactive'
			}
		}

	  return (	  	
	  	
				<Tooltip.Root delayDuration={this.props.longTooltipDelay ? 400 : 0}>
					<Tooltip.Trigger asChild>
			  		<button data-btn-state={dataBtnState}  onClick={this.props.onClickFunction} className={'doc-table-actionMenu-btn ' + (type ? ` doc-table-actionMenu-btn--${type} ` : '') + (activeColor ? ` doc-table-actionMenu-btn--cellBG--activeColor--${activeColor} ` : '')}>
			  			{iconName && 
			  				<Icon name={iconName} /> 
			  			}
			  			{extraIconName && 
			  				<Icon name={extraIconName} /> 
			  			}
			  		</button>	  	
		  		</Tooltip.Trigger>				
						{(tooltipLabel || hasKeyboardShortcut) &&								
						<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI tooltip--tableHoverBtn">																																									
								<React.Fragment>		
								{tooltipLabel &&
									<span>
										{tooltipLabel}
									</span>
								}
								{hasKeyboardShortcut &&						
									<KeyboardShortcutTag
										command={this.props.keyboardShortcutCommand}
										shift={this.props.keyboardShortcutShift}
										option={this.props.keyboardShortcutOption}
										iconName={this.props.keyboardShortcutIconName}
										control={this.props.keyboardShortcutControl}
										backspace={this.props.keyboardShortcutBackspace}
										letter={this.props.keyboardShortcutLetter}
										keyboardShortcutOnly={this.props.keyboardShortcutOnly}
									/>								
								}						
								</React.Fragment>								
							</Tooltip.Content>													
							}
					</Tooltip.Root>
				
	  )
	}
}



class DocTableHoverMenu extends React.Component{  
	constructor(props){
		super(props)		
		this.state={      
      showDropdown: false,
    }
	} 

	render(){
		const {headerRow,headerColumn,width,height,activeCellAlignment}=this.props
	  return (
	  	<React.Fragment>
	  	<Tooltip.Provider>
	  	<div className='doc-table-actionMenu'>

	  		<DocTableHoverMenuBtn
	  			type='headerRow'
	  			onClickFunction={this.props.toggleHeaderRow}
	  			active={headerRow}
	  			iconName={headerRow ? 'headerRowActive' : 'headerRow'}
	  			tooltipLabel='Header Row'
	  		/>

	  		<DocTableHoverMenuBtn
	  			type='headerCol'
	  			onClickFunction={this.props.toggleHeaderColumn}
	  			active={headerColumn}
	  			iconName={headerColumn ? 'headerColActive' : 'headerCol'}  	
	  			tooltipLabel='Header Column'
	  		/>

	  		<DocTableHoverMenuBtn
	  			type='altRowFill'
	  			onClickFunction={this.props.toggleAlternatingRowBG}
	  			active={this.props.alternatingRowBG}
	  			iconName={this.props.alternatingRowBG ? 'altRowFillActive' : 'altRowFill'}
	  			tooltipLabel='Alternating Row Highlight'
	  		/>

	  		{/*
	  		<button data-btn-state={headerRow?"active":" "} onClick={} className='doc-table-actionMenu-btn doc-table-actionMenu-btn--headerRow'>
	  			<Icon name='headerRow' />
	  		</button>	
	  		*/}

	  		{/*}
	  		<button data-btn-state={headerColumn?"active":" "}  onClick={this.props.toggleHeaderColumn}  className='doc-table-actionMenu-btn doc-table-actionMenu-btn--headerCol'>
	  			<Icon name='headerCol' />
	  		</button>	
	  		*/}

	  		<div className='doc-table-actionMenu-divider' />

	  		
	  		<div className='doc-table-actionMenu-btnGroup doc-table-actionMenu-btnGroup--alignment'>
  				<DocTableHoverMenuBtn
		  			type='textAlign'
		  			onClickFunction={(e)=>{this.props.alignSelectedCells(e,'left')}}// make cell left aligned		  			
		  			iconName='alignTextLeft'	
		  			active={activeCellAlignment==='left'}
		  			//tooltipLabel='Left Align Cell Text' 	 
		  			//longTooltipDelay 	
		  		/>

		  		<DocTableHoverMenuBtn
		  			type='textAlign'
		  			onClickFunction={(e)=>{this.props.alignSelectedCells(e,'center')}}
		  			//onClickFunction={} // make cell center aligned		  			
		  			iconName='alignTextCenter'	 
		  			active={activeCellAlignment==='center'} 	
		  			//tooltipLabel='Center Align Cell Text' 	  	
		  			//longTooltipDelay
		  		/>

		  		<DocTableHoverMenuBtn
		  			type='textAlign'
		  			onClickFunction={(e)=>{this.props.alignSelectedCells(e,'right')}}
		  			//onClickFunction={} // make cell right aligned		  			
		  			iconName='alignTextRight'	  
		  			active={activeCellAlignment==='right'}
		  			//tooltipLabel='Right Align Cell Text' 
		  			//longTooltipDelay	  		
		  		/> 
	  		</div>
	  		
	  		<div className='doc-table-actionMenu-divider' />
			  		

	  		<DropdownMenu.Root>
		    <DropdownMenu.Trigger asChild>
		    	<div>
						<DocTableHoverMenuBtn
			  			type='cellBG'		  			
			  			activeColor={this.props.activeCellColor} 
			  			iconName='cellBG'	 
			  			tooltipLabel='Cell Background Color' 	
			  		/>	  				
		  		</div>					
		    </DropdownMenu.Trigger>
		    
		    <DropdownMenu.Content className='dropdownMenu dropdownMenu--tableColorSelect'>		    	
		    	<div className='contextMenu-menu-cellColorSelect'>
            <div onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'white')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--white'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>                    
            </div>

            <div  onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'purple')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--purple'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>

            <div  onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'red')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--red'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>
            <div onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'blue')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--blue'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>
            <div onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'orange')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--orange'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>
            <div onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'green')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--green'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>

            <div onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'pink')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--pink'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>

            <div onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'sky')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--sky'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>
            

            <div onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'teal')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--teal'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>

            <div onClick={(e)=>{this.props.setSelectedCellsBackgroundColor(e,'grey')}} className='contextMenu-menu-cellColorSelect-btn contextMenu-menu-cellColorSelect-btn--color--grey'>
              <div className='contextMenu-menu-cellColorSelect-btn-inner'>

              </div>
              <div className='contextMenu-menu-cellColorSelect-btn-border' />
            </div>
          </div>
		    	
		    </DropdownMenu.Content>
		    
		  </DropdownMenu.Root>



	  		<div className='doc-table-actionMenu-btn doc-table-actionMenu-divider' />

	  	


  		 	<div className='doc-table-actionMenu-rowCol doc-table-actionMenu-rowCol--col'>	  			  						  				 
	  			<DocTableHoverMenuBtn
		  			type='removeColumn'
		  			onClickFunction={this.props.removeLastColumn}			  			
		  			iconName='minus'
		  		/> 
	  			<div className='doc-table-actionMenu-rowCol-countLabel' >
	  				{width} Col
	  			</div>
	  			
	  			<DocTableHoverMenuBtn
		  			type='addColumn'
		  			onClickFunction={this.props.addNewColumn}				  			
		  			iconName='plus'	  
		  			tooltipLabel='Add right' 		
		  			hasKeyboardShortcut		  			
		  			keyboardShortcutOption
		  			keyboardShortcutIconName="arrowRight"
		  		/> 
	  		</div>

	  		<div className='doc-table-actionMenu-divider' />

	  		<div className='doc-table-actionMenu-rowCol doc-table-actionMenu-rowCol--row'>	  			  						  				 
	  			<DocTableHoverMenuBtn
		  			type='removeRow'
		  			onClickFunction={this.props.removeLastRow}			  			
		  			iconName='minus'
		  		/> 
	  			<div className='doc-table-actionMenu-rowCol-countLabel'>
	  				{height} Row
	  			</div>
	  			
	  			<DocTableHoverMenuBtn
		  			type='addRow'
		  			onClickFunction={this.props.addNewRow}				  			
		  			iconName='plus'	  
		  			tooltipLabel='Add below' 		
		  			hasKeyboardShortcut		  			
		  			keyboardShortcutOption
		  			keyboardShortcutIconName="arrowDown"
		  		/> 
	  		</div>
	  		

	  		<div className='doc-table-actionMenu-divider' />

		  		<DocTableHoverMenuBtn
		  			type='distributeCols'		  			
		  			//active 
		  				  			
		  			onClickFunction={this.props.fitTableToDocWidth}
		  			iconName='hideInterface'
		  			extraIconName='showInterface'	 
		  			tooltipLabel='Fit to Doc Width' 	
		  		/>	  		

		  		<DocTableHoverMenuBtn
		  			type='equalColWidths'		  	
		  			onClickFunction={this.props.fitTableToMaxColWidthWidth}	
		  			//active 
		  			iconName='equalColWidths'	 
		  			tooltipLabel='Equal Column Widths' 	
		  		/>	 

	  		{/*}
	  		<div className='doc-table-actionMenu-divider' />
	  		<button className='doc-table-actionMenu-btn doc-table-actionMenu-btn--delete'>
	  			<Icon name='ellipses' />
	  		</button>
	  		*/}


	  	</div>
	  	{/*
	  	<button onClick={this.props.deleteTable}>delete table</button>
	*/}
	  	{/*<button onClick={this.props.newMessage}>comment</button> */}


	  	{/*}
	  		<div className='doc-table-actionMenu'>
	  			<button className='doc-table-actionMenu-btn'>
						FW
					</button>
	  			<DropdownMenu.Root open={this.state.showDropdown}>
				    <DropdownMenu.Trigger asChild>
				    	<button onClick={()=>{this.setState({showDropdown: !this.state.showDropdown})}} className='doc-table-actionMenu-btn'>
								<Icon name='ellipses' />
							</button>
				    </DropdownMenu.Trigger>   			 	
	   			 	<DropdownMenu.Content 
	   			 		sideOffset={1}
	   			 		align="end"
	   			 		alignOffset={-8}
	   			 		className='dropdownMenu'
	   			 		onEscapeKeyDown={()=>{this.setState({showDropdown: false})}}
							onPointerDownOutside={()=>{this.setState({showDropdown: false})}}
							onInteractOutside={()=>{this.setState({showDropdown: false})}}
	   			 	> 			        					        		       
	        	
		        	<DropdownMenu.Item onSelect={this.props.toggleHeaderColumn} >
		        		Toggle Column Header
		        	</DropdownMenu.Item> 	

		        	<DropdownMenu.Item onSelect={this.props.toggleHeaderRow} >
		        		Toggle Row Header
		        	</DropdownMenu.Item> 	

		        	<DropdownMenu.Separator />	

		        	<DropdownMenu.Item onSelect={this.props.deleteTable} className='dropdownMenu-item--warning'>
		        		Delete 
		        	</DropdownMenu.Item>


			        	
		        	
			        	
			        	
				    </DropdownMenu.Content>
					</DropdownMenu.Root>	
			  </div>
		  */}

	  	</Tooltip.Provider>
		  </React.Fragment>

	  )
	}
}

export default DocTableHoverMenu
