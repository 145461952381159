//updated from 
// function randomID() {
// 	return Math.floor(Math.random() * 0xffffffff)
// }
//so generates INT not BIGINT


export function randomID() {
  return Math.floor(Math.random() * 2147483647)
}

