import React from 'react'
import Icon from '../../../misc/Icon'
import LinearMarkerInline from '../../../misc/LinearMarkerInline'
import LinearProjectStatusIconInline from '../../../misc/LinearProjectStatusIconInline'
import LinearProjectStatusIcon from '../../../misc/LinearProjectStatusIcon'
import { connect } from 'react-redux'
import {getTeamForId} from '../../../../utils/getTeamForId'
import {getProjectForId} from '../../../../utils/getProjectForId'
import LinearProjectPreviewPopover from '../../../docEditor/mentionPopovers/LinearProjectPreviewPopover'
import {getRootDocIdForProject} from '../../../../utils/getRootDocIdForProject'

const OPEN_POPOVER_DELAY=500

class DocProjectLinkReactComponent extends React.PureComponent{  
	
	constructor(props) {
    super();  
    this.handleMouseEnter=this.handleMouseEnter.bind(this) 
    this.handleMouseLeave=this.handleMouseLeave.bind(this)  
    this.handleOpenProjectDoc=this.handleOpenProjectDoc.bind(this)
    this.state = {    	
    	showPopover: false,        	
   	};
   	this.timeout=null
  }

  componentWillUnmount(){
		if(this.timeout){
			clearTimeout(this.timeout)
		}
  }

  handleOpenProjectDoc(){
  	const docId=getRootDocIdForProject(this.props.projectId)
  	if(docId){
  		this.props.handleOpenDoc(docId)
  	}
  }

  handleMouseEnter(){
  	this.timeout=setTimeout(function() {
			this.setState({showPopover:true})
		}.bind(this), OPEN_POPOVER_DELAY)
  }

  handleMouseLeave(){
  	if(this.timeout){
			clearTimeout(this.timeout)
		}
		if(this.state.showPopover){
			this.setState({showPopover:false})
		}
  }

	render(){
		const {docId,docNameSnapshot,projectId}=this.props
		const {showPopover} = this.state
		let project
		if(projectId){
			project = getProjectForId(projectId)
		}
		let projectStatus
		let teamIds = []
		let memberIds = []
		let projectName 
		if(project){
			projectStatus = project.state
			projectName = project.name
			teamIds = project.teams
			memberIds = project.members
		}
	  return (	  	
	  		<span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className='doc-para-projectLink' onClick={this.handleOpenProjectDoc}>					  			
		  		<span role="img" className='doc-para-projectLink-spacer' />
		  			
		  		<span role="img" className='doc-para-projectLink-statusContainer'>	  		  		
		  			
	  				<LinearProjectStatusIcon
							projectStatus={projectStatus}
						/>
						
						
	  			</span>

		  		<span className="doc-para-projectLink-markerContainer">
		  			<LinearMarkerInline projectId={projectId} />		  			
		  		</span>


			  	<span className="doc-para-projectLink-title">
			  		{projectName}
			  	</span>				  	

			  	
			  	{project && 
			  	<LinearProjectPreviewPopover 
						name={project.name}
						leadUserId={project.lead}
						description={project.description}
						memberIds={memberIds}
						teamIds={teamIds}
						status={project.state}
						projectId={project.project_id}						
						showPopover={showPopover}
						openPopover={()=>{this.setState({showPopover: true})}}
						closePopover={()=>{this.setState({showPopover: false})}}
					>				
						<span className='doc-para-projectLink-popoverTrigger' />					
					</LinearProjectPreviewPopover>
				}		  	
				
		  	</span>	
		  	
	  	
	  )
	}
}
export default DocProjectLinkReactComponent