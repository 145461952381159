import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import store from '../../store'
import {getProjectForId} from '../getProjectForId'


//return first 4 non private docs/projects

export function getRecentItems() {
	const state = store.getState()
	const docs= state.docs

	let publicDocs=[]

	docs.forEach((doc)=>{
		let docType 
		if(doc){
			if(doc.is_org_doc){
				docType='orgDoc'
			}
			else if(doc.roadmap){
				docType='roadmapDoc'
			}
			else if(doc.project){
				if(doc.is_project_doc){
					docType='projectRootDoc'
				}else{
					docType='projectSubDoc'
				}
			}else{
				docType='privateDoc'
			}
		}
		if(docType!='privateDoc'){
			publicDocs.push(doc)
		}
	})
	const sortedDocs= sortBy(publicDocs,"updated_at").reverse()
	const recent=sortedDocs.slice(0,6)
	let modifiedRecent=[] //replace project docs with project
	recent.forEach((doc)=>{
		if(doc.is_project_doc){
			const project=getProjectForId(doc.project)
			if(project){
				modifiedRecent.push(project)
			}
		}else{
			modifiedRecent.push(doc)
		}
	})
	return modifiedRecent
}


