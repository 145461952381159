import store from '../store'
import includes from 'lodash/includes'
import sortBy from 'lodash/sortBy'

//Can be in multiple roadmaps
export function getRoadmapsForProject(projectId) {	
	
	const state = store.getState()

	let projectRoadmaps=[]

	const roadmaps=state.roadmaps
	roadmaps.forEach((roadmap)=>{
		if(roadmap.projects){
			if(includes(roadmap.projects,projectId)){
				projectRoadmaps.push(roadmap)
			}
		}
	})

		const sortedRoadmaps = sortBy(projectRoadmaps, function(roadmap){
      return roadmap.name.toString().toLowerCase()
  })

	return sortedRoadmaps
}

