import React from 'react'
import Icon from '../misc/Icon'
import RecordingPlaybackModalHighlightGutterMarkerPopover from './RecordingPlaybackModalHighlightGutterMarkerPopover'


const OPEN_POPOVER_DELAY=100
const KEEP_POPOVER_OPEN_DELAY = 300 // have to beware of scroll

class RecordingPlaybackModalHighlightGutterMarker extends React.Component{  
	
	constructor(props) {
    super(props);  
    this.handleMouseEnter=this.handleMouseEnter.bind(this) 
    this.handleMouseLeave=this.handleMouseLeave.bind(this)     
    this.state={
    	markerHover: false,
    }
    this.timeout=null
  }

  componentWillUnmount(){
		if(this.mouseLeaveTimeout){
			clearTimeout(this.mouseLeaveTimeout)
		}
		if(this.mouseEnterTimeout){
			clearTimeout(this.mouseEnterTimeout)
		}
  }

  handleMouseEnter(){
    // clear the mouseLeaveTimeout if exists
    if(this.mouseLeaveTimeout){
      clearTimeout(this.mouseLeaveTimeout);
      this.mouseLeaveTimeout = null;
    }
  
    this.mouseEnterTimeout = setTimeout(() => {
      this.setState({markerHover: true});
    }, OPEN_POPOVER_DELAY);
  }

  handleMouseLeave(){
    // clear the mouseEnterTimeout if exists
    if(this.mouseEnterTimeout){
      clearTimeout(this.mouseEnterTimeout);
      this.mouseEnterTimeout = null;
    }
  
    this.mouseLeaveTimeout = setTimeout(() => {
      if(this.state.markerHover){
        this.setState({markerHover: false});
      }
    }, KEEP_POPOVER_OPEN_DELAY); // your delay
  }

  

	render(){	
		const {markerStartY, indentLevel, isSingleLine, markerEndY,isActive} = this.props
		const {markerHover} = this.state
		const height = markerEndY - markerStartY
		
		

		const showMarker=markerHover || isActive
		// const indentedWidth = 6
		// if(indentLevel > 0){
		// 	right = 52 + (indentLevel * indentedWidth)
		// }
	  return (	  		  	
			<div onClick={()=>{this.props.skipToHighlight(this.props.highlight)}} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} style={{height: `${height}px`, top: `${markerStartY}px`}} className={' recordingPlaybackModal-transcript-highlightGutter-marker ' + (markerHover ? ' recordingPlaybackModal-transcript-highlightGutter-marker--hovered ' : ' recordingPlaybackModal-transcript-highlightGutter-marker--notHovered ')  + (isSingleLine ? ' recordingPlaybackModal-transcript-highlightGutter-marker--singleLine ' : ' recordingPlaybackModal-transcript-highlightGutter-marker--multiLine ')  + (indentLevel > 0 ? ' recordingPlaybackModal-transcript-highlightGutter-marker--indented ' : ' recordingPlaybackModal-transcript-highlightGutter-marker--notIndented ') + (isActive ? ' recordingPlaybackModal-transcript-highlightGutter-marker--active ' : ' recordingPlaybackModal-transcript-highlightGutter-marker--inactive ')}>				
				<div className='recordingPlaybackModal-transcript-highlightGutter-marker-indicator'>
					<div className='recordingPlaybackModal-transcript-highlightGutter-marker-indicator-iconContainer'>									
						<Icon name='bookmarkFill' />																		
					</div>											
				</div>		  			
	  		<div className='recordingPlaybackModal-transcript-highlightGutter-marker-edge' />	  			
  			<RecordingPlaybackModalHighlightGutterMarkerPopover 								
					showPopover={showMarker}
					openPopover={()=>{this.setState({markerHover: true})}}
					closePopover={()=>{this.setState({markerHover: false})}}
					highlight={this.props.highlight}
					indentLevel={indentLevel}					
				>
					<div className='recordingPlaybackModal-transcript-highlightGutter-marker-popoverTrigger'/>			
				</RecordingPlaybackModalHighlightGutterMarkerPopover>
			</div>
	  )
	}
}



class RecordingPlaybackModalHighlightGutter extends React.Component{  	

	 constructor(props) {
    super(props)    
   	
  }

  

	render(){	
		const {highlights,videoCurrentTime}=this.props
		let scrollTop=0
		const scrollableEl=document.getElementById('transcript_scrollable_div')
		if(scrollableEl){
			scrollTop=scrollableEl.scrollTop
		}
		const topMenuOffset=100
	 	if(highlights.length && window.transcriptView && window.transcriptView.state){
	  return (	  	
			<div className='recordingPlaybackModal-transcript-highlightGutter'>
				{highlights.map((highlight,i)=>{
					const {start_pos,end_pos}=highlight //PM doc positions 
					const fromCoord=window.transcriptView.coordsAtPos(start_pos) //https://prosemirror.net/docs/ref/#view.EditorView.coordsAtPos
					const toCoord=window.transcriptView.coordsAtPos(end_pos)
					const markerStartY=fromCoord.top + scrollTop - topMenuOffset
					const markerEndY=toCoord.bottom + scrollTop - topMenuOffset
					let isActive=false
					if(videoCurrentTime>highlight.start_time && videoCurrentTime<highlight.end_time){
						isActive=true
					}

					return(
						<RecordingPlaybackModalHighlightGutterMarker 
							key={`highlight_${highlight.highlight_id}`}
							isActive={isActive}
							highlight={highlight}
							markerStartY={markerStartY}
							markerEndY={markerEndY}
							isSingleLine={false}
							highlight={highlight}
							skipToHighlight={this.props.skipToHighlight}
							indentLevel={0} // hopefully we can always set this to 0 away without this, but leaving code downstream in case we need it		
						/>
						)

				})}

			</div>	  					
	  )
	}else return null
	}
}

export default RecordingPlaybackModalHighlightGutter