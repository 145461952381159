import React from 'react'
import Icon from '../../misc/Icon'
import { Spring, animated, config } from 'react-spring'
import CommentThreadMarkerPopover from './CommentThreadMarkerPopover'
import filter from 'lodash/filter'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import {calculateUnreadsForThread} from '../../../utils/notifications/calculateUnreadsForThread'


const OPEN_POPOVER_DELAY=100

const MAGIC_NUMBER=240

//for now only include selection_type=docSelection TODO node selections
//TODO selection not valid!


function calculateThreadsFromMessages(messages,messageSelectionPluginKey){
	let threads=[]
	let threadsWithPositions=[]
	let threadsWithPositionsAndIndent=[]
	messages.forEach((message)=>{
		if((message.selection_type=='docSelection') && message.in_doc_thread){
			const threadChildren=filter(messages,{thread_parent:message.message_id})
			const threadObj={
				threadParent:message,
				threadChildren:threadChildren
			}
			threads.push(threadObj)
		}
	})
	let scrollTop
	const scrollableEl=document.getElementById('scrollable_div')
	if(scrollableEl){
		scrollTop=scrollableEl.scrollTop
	}
	
	const topMenuOffset = 43 // this is height of top menu

	if(window.view && window.view.state){
		const pluginState=messageSelectionPluginKey.getState(window.view.state)
		if(pluginState){
			const selections=pluginState.selections
			threads.forEach((thread)=>{
				const threadParent=thread.threadParent
				if(threadParent.selection_type=='docSelection'){
					const messageSelectionObjId=thread.threadParent.message_selection
					const selection=find(selections,{id:messageSelectionObjId})
					if(selection && selection.isValid){
						const fromCoord=window.view.coordsAtPos(selection.from) //https://prosemirror.net/docs/ref/#view.EditorView.coordsAtPos
						const toCoord=window.view.coordsAtPos(selection.to)
						const markerStartY=fromCoord.top + scrollTop - topMenuOffset
						const markerEndY=toCoord.bottom + scrollTop - topMenuOffset
						let isSingleLine=(markerEndY - markerStartY) < 40						
						let threadWithPosition={
							...thread,
							selectionStart:selection.from,
							markerStartY:markerStartY,
							markerEndY:markerEndY,
							isSingleLine:isSingleLine
						}
					threadsWithPositions.push(threadWithPosition)
					}
				}

			})
		}
		
	}

	const sortedThreads=sortBy(threadsWithPositions,'selectionStart')


	let defaultIndentLevel=0
	sortedThreads.forEach((thread,i)=>{
		let threadWithIndentLevel={...thread}
		let overlapIndentLevel=null
		//check if the start pos of thread overlaps with the marker end of any previous threads
		//if so use highest indent level of overlaps +1
		//else 0
		threadsWithPositionsAndIndent.forEach((threadWithPos)=>{
			if(threadWithPos.markerEndY>thread.markerStartY){

				if(overlapIndentLevel==null || threadWithPos.indentLevel>overlapIndentLevel){
					overlapIndentLevel=threadWithPos.indentLevel
				}
			}
		})
		if(overlapIndentLevel==null){
			threadWithIndentLevel.indentLevel=0
		}else{
			threadWithIndentLevel.indentLevel=overlapIndentLevel+1
		}
		threadsWithPositionsAndIndent.push(threadWithIndentLevel)

	})


	return threadsWithPositionsAndIndent

}


class DocThreadGutterMarker extends React.Component{  
	
	constructor(props) {
    super(props);  
    this.handleMouseEnter=this.handleMouseEnter.bind(this) 
    this.handleMouseLeave=this.handleMouseLeave.bind(this) 
    this.openThread=this.openThread.bind(this) 
    this.state={
    	markerHover: false,
    }
    this.timeout=null
  }

  componentWillUnmount(){
		if(this.timeout){
			clearTimeout(this.timeout)
		}
  }

  handleMouseEnter(){
  	this.timeout=setTimeout(function() {
  		this.props.setActiveMessageSelection(this.props.thread.threadParent.message_selection)
			this.setState({markerHover:true})
		}.bind(this), OPEN_POPOVER_DELAY)
  }

  handleMouseLeave(){
  	if(this.timeout){
			clearTimeout(this.timeout)
		}
		if(this.state.markerHover){
			this.setState({markerHover:false})
			if(!this.props.activePanelThreadMessage){
				this.props.setActiveMessageSelection(null)
			}else{
				this.props.setActiveMessageSelection(this.props.activePanelThreadMessage.message_selection)
			}
		}
  }

  openThread(){
  	const threadParentId=this.props.thread.threadParent.message_id
  	this.props.openThread(threadParentId)
  }

	render(){	
		const {markerStartY, indentLevel, isSingleLine, markerEndY, totalMessageCount, unreadMessageCount, isBot,thread,isActive} = this.props
		const {markerHover} = this.state
		const height = markerEndY - markerStartY
		let hasUnreads = false
		let markerCount = totalMessageCount
		if(unreadMessageCount > 0){
			markerCount = unreadMessageCount
			hasUnreads = true
		}
		if(unreadMessageCount > 10){
			markerCount = '9+'
		}
		let right = 0
		const indentedWidth = 6
		if(indentLevel > 0){
			right = 52 + (indentLevel * indentedWidth)
		}
	  return (	  		  	
			<div onClick={this.openThread} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} style={{height: `${height}px`, right: `${right}px`, top: `${markerStartY}px`}} className={' doc-threadGutter-marker ' + (markerHover ? ' doc-threadGutter-marker--hovered ' : ' doc-threadGutter-marker--notHovered ')  + (isSingleLine ? ' doc-threadGutter-marker--singleLine ' : ' doc-threadGutter-marker--multiLine ')  + (indentLevel > 0 ? ' doc-threadGutter-marker--indented ' : ' doc-threadGutter-marker--notIndented ') + (isActive ? ' doc-threadGutter-marker--active ' : ' doc-threadGutter-marker--inactive ') + (hasUnreads ? ' doc-threadGutter-marker--hasUnreads ' : ' doc-threadGutter-marker--noUnreads ')}>				
				
				<div className='doc-threadGutter-marker-indicator'>
					<div className='doc-threadGutter-marker-indicator-iconContainer'>									
						<Icon name='messageOutlineMedium' />																		
					</div>						
					<div className='doc-threadGutter-marker-indicator-label'>
						
						{markerCount}
					</div>
				</div>		  			

	  		<div className='doc-threadGutter-marker-edge' />	  			

  			<CommentThreadMarkerPopover 								
					showPopover={markerHover}
					openPopover={()=>{this.setState({markerHover: true})}}
					closePopover={()=>{this.setState({markerHover: false})}}
					indentLevel={indentLevel}
					thread={thread}
					reactions={this.props.reactions}
				>
						
					<div className='doc-inlineLinearIssue-popoverTrigger'/>
							
				</CommentThreadMarkerPopover>
				
			</div>
	  )
	}
}




class DocThreadGutter extends React.Component{  
	static getDerivedStateFromProps(props, state) {
		if(props.recentActivities.length && !state.recentActivities.length){ //Prevent red unreads line showing when refresh on a doc
			return ({recentActivities:props.recentActivities})
		}
		if(props.threadParentMessage !=state.threadParentMessage){
			return({threadParentMessage:props.threadParentMessage,recentActivities:props.recentActivities})
		}
		if(props.threadParentMessage && props.recentActivities != state.recentActivities){
			//console.log('here in thread gutter!')
			//update the recent activity only for the current thread
			let newRecentActivities=[...state.recentActivities]
			let activityIndex=findIndex(state.recentActivities,(activityItem => {
				return activityItem.item_id == props.threadParentMessage
			}))
	

			let newActivityItem=find(props.recentActivities,(activityItem => {
				return activityItem.item_id == props.threadParentMessage
			}))
			// console.log('new recent activity item in thread!')
			// console.log('activity item for thread')
			// console.log(props.recentActivities)
			// console.log(props.threadParentMessage)
			// console.log(newActivityItem)

		//	console.log(`activity indx---- ${activityIndex}`)

			if(activityIndex == -1){
				//console.log('push it to array!')
				if(newActivityItem){
					newRecentActivities.push(newActivityItem)
				//	console.log('new recent activities')
				//	console.log(newRecentActivities)
				}
			}else{
				newRecentActivities= [
					...state.recentActivities.slice(0,activityIndex),
					newActivityItem,
					...state.recentActivities.slice(activityIndex + 1)
				]
			}
			return({recentActivities:newRecentActivities})

		}
		return null
	}

	constructor(props) {
    super(props);  
    this.state={
    	// recentActivityItem:props.recentActivityItem,
			recentActivities:props.recentActivities,
			threadParentMessage:props.threadParentMessage
    }
  }


	render(){	
		const {recentActivities}=this.state
		const {messages,messageSelectionPluginKey,docId,activePanelThreadMessage}=this.props
		const threads=calculateThreadsFromMessages(messages,messageSelectionPluginKey)

		const showGutter=threads.length>0
		if(showGutter){
			
	  	return (	  		  	
				<div className='doc-threadGutter'>
					{threads.map((thread)=>{
						const {threadParent,threadChildren}=thread
						const unreadMessageCount=calculateUnreadsForThread(docId,threadParent.message_id,recentActivities)
						let isActive=false
						if(activePanelThreadMessage && activePanelThreadMessage.message_id==threadParent.message_id){
							isActive=true
						}
	

						return(
							<DocThreadGutterMarker 	
								reactions={this.props.reactions}
								key={`thread_marker_${threadParent.message_id}`}	
								isActive={isActive}			
								markerStartY={thread.markerStartY}
								markerEndY={thread.markerEndY}
								isSingleLine={thread.isSingleLine}
								totalMessageCount={threadChildren.length+1}
								thread={thread}
								unreadMessageCount={unreadMessageCount}
								indentLevel={thread.indentLevel}
								openThread={this.props.openThread}
								setActiveMessageSelection={this.props.setActiveMessageSelection}
								activePanelThreadMessage={this.props.activePanelThreadMessage} //thread that is open in panel
							/>
						)
					})}
				</div>
	  	)
		}else return null
	}
}

export default DocThreadGutter

