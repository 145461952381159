import React from 'react'
import Icon from '../misc/Icon'
import { Spring, animated, config } from 'react-spring'

class ToDoItem extends React.Component{  	

	constructor(){
    super()    
    this.state={
      checked: false,
      mouseDown: false,
    }
  }

 //  handleMouseUp(e){				
	// 	this.setState({mouseDown:false})

	// 	// setTimeout(function() {
 //  //  		this.setState({mouseDown:false})
 // 	// 	}.bind(this), 500)
	// }

	render(){	

		const {label} = this.props
		const {checked, mouseDown} = this.state	



	  return (
	  	
	  		<div data-state={checked ? 'checked' : 'unchecked'} className={'toDoItemContainer ' + (mouseDown ? ' toDoItemContainer--mouseDownStatus--active' : ' toDoItemContainer--mouseDownStatus--inactive')}>
	  			<div className='toDoItem'>		  				
	  				<Spring transform={mouseDown ? 'scale(1.2)' : 'scale(1)'} >
              {checkboxContainerStyles =>
                <animated.div style={checkboxContainerStyles} onMouseDown={()=>{this.setState({mouseDown: true})}} onMouseUp={()=>{this.setState({mouseDown: false})}} onClick={()=>{this.setState({checked: !checked})}} className='toDoItem-checkboxContainer'>
				  				
							  		<div className='toDoItem-extraCheckMarkContainer'>			
							  			
            				</div>

				  				<Spring 
				  					backgroundColor={checked ? 'rgb(26, 129, 255)' : 'rgb(255,255,255)'}
				  					borderColor={checked ? 'rgb(0, 103, 230)' : 'rgb(191, 191, 197)'}
				  				>
			              {checkboxStyles => 
			                <animated.div style={checkboxStyles} className={'toDoItem-checkbox ' + (checked ? ' toDoItem-checkbox--state--checked ' : ' toDoItem-checkbox--state--unchecked ')}>
			                	<svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128">
							  					<polyline fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" points="36 66.4 57.12 89.92 91.6 38.476"/>
							  				</svg>
			                </animated.div>
			              }
			            </Spring>
                	

							  	<Spring 
							  			opacity={(!checked && mouseDown) ? 0.5 : 0} 
							  			transform={(!checked && mouseDown) ? 'scale(1.2)' : 'scale(1)'} 
							  		>
				              {checkboxOutlineStyles => 
				                <animated.div style={checkboxOutlineStyles} className='toDoItem-checkbox-outline' />				                	
				              }
				            </Spring>

					  		</animated.div>
              }
            </Spring>


	  				
					  
            <Spring opacity={checked ? 0.5 : 1} >
              {labelStyles => 
                <animated.div style={labelStyles} className='toDoItem-contents'>
                	<div className='toDoItem-contents-assignment'>
                		{this.props.J &&
	                		<div className='toDoItem-contents-assignment-userTag'>
	                			J
	                		</div>
                		}
                		{this.props.plus &&
	                		<div className='toDoItem-contents-assignment-operator'>
	                			+
	                		</div>
                		}
                		{this.props.slash &&
	                		<div className='toDoItem-contents-assignment-operator'>
	                			/
	                		</div>
                		}
                		{this.props.N &&
                		<div className='toDoItem-contents-assignment-userTag'>
                			N
                		</div>
                		}


                	</div>
                	<div className='toDoItem-contents-label'>
                		{label}
                	</div>
                </animated.div>
              }
            </Spring>

            <Spring opacity={mouseDown ? 1 : 0} >
              {labelStyles => 
                <animated.div style={labelStyles} className='toDoItem-mouseDownBG' />                                
              }
            </Spring>            

            <Spring opacity={checked ? 1 : 0} >
              {strikeThroughStyles => 
                <animated.div style={strikeThroughStyles} className='toDoItem-strikeThroughTest' />                                
              }
            </Spring>            

            <div className='toDoItem-shortId'>
            	DSK-231
            </div>
            
	  			</div>
	  		</div>
			
	  )
	}
}

export default ToDoItem
