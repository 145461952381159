import {Plugin} from "prosemirror-state"
import {Decoration, DecorationSet} from "prosemirror-view"
import {setSelectionToInvalid,addNewSelection} from '../../../utils/messageSelections'

//Keeps track of message selections and maps them through collab changes
export class MessageSelection {
	constructor(id,isValid) {
		this.id = id
		this.isValid=isValid
	}
}

function deco(from, to, messageSelection,activeSelectionId) { 
	const id=messageSelection.id
	return Decoration.inline(from, to, {class: "doc-span--comment--active",id:`deco`}, {messageSelection})	
}

function topDeco(from, id) { 
	const span=document.createElement("span")
	span.id=`deco-top-${id}`
	return Decoration.widget(from,span)	
}

function bottomDeco(from, id) { 
	const span=document.createElement("span")
	span.id=`deco-bottom-${id}`
	return Decoration.widget(from,span)	
}


export class MessageSelectionState {
	constructor(version,selections, decos, unsent,activeSelectionId) {
		this.version = version
		this.selections=selections
		this.decos = decos
		this.unsent = unsent
		this.activeSelectionId=activeSelectionId
	}

	findMessageSelection(id) {
		for (let i = 0; i < this.selections.length; i++){
			if (this.selections[i].id == id) return this.selections[i]
		}
	}

	mapThroughSelections(mapping,selections){
		let newSelections=[]
		selections.map((selection)=>{ //TODO maybe only do this for valid slections
			let newSelection={...selection}
			let from = mapping.map(selection.from, -1), to = mapping.map(selection.to, -1) //change anchor bias to -1 https://discuss.prosemirror.net/t/setting-the-cursor-bias-assoc-when-merging-changes-from-collab/611
			if (from >= to) {
        newSelection.isValid=false
        setSelectionToInvalid(selection.id)
      } else {
			newSelection.from = from
			newSelection.to = to
		}
			newSelections.push(newSelection)
		})
		return newSelections
	}


	apply(tr) { 
		let action = tr.getMeta('messageSelectionPlugin'), actionType = action && action.type
	 
		if(actionType && actionType==='setActiveSelection'){
			this.activeSelectionId=action.activeSelectionId
			let decos=[] //no decos if no active selection
			if(this.activeSelectionId){
				this.selections.forEach((selection)=>{
					if(selection.id==this.activeSelectionId){
						const activeDeco=deco(selection.from, selection.to, new MessageSelection(selection.id,selection.isValid))
						decos.push(activeDeco)
						//const decoTop=topDeco(selection.from,selection.id)
						//decos.push(decoTop)
						//const decoBottom=bottomDeco(selection.to,selection.id)
						//decos.push(decoBottom)
					}
				})
			}
			const selections=this.selections
			return new MessageSelectionState(this.version,selections, DecorationSet.create(tr.doc, decos), this.unsent,action.activeSelectionId)
		}
		else{
			if (!action && !tr.docChanged)return this
			
			let base = this

			if (actionType == "receive"){ //reieve events from collab
				base = base.receive(action, tr.doc)
			}
			let decos = base.decos, unsent = base.unsent
			decos = decos.map(tr.mapping, tr.doc)
			const mappedSelections=this.mapThroughSelections(tr.mapping,this.selections)
			base.selections=mappedSelections
			if (actionType == "newMessageSelection") {
				const obj={
					from:action.from,
					to:action.to,
					isValid:action.messageSelection.isValid,
					id:action.messageSelection.id
				}
				addNewSelection(obj)
				base.selections.push(obj)
				//decos = decos.add(tr.doc, [deco(action.from, action.to, action.messageSelection)])
				unsent = unsent.concat(action)
			} else if (actionType == "deleteMessageSelection"){ //TODO delete!
				let newSelections=[]
				base.selections.forEach((selection)=>{
					if(selection.id==action.id){
					}else{
						newSelections.push(selection)
					}
				})
				base.selections=newSelections
		//	decos = decos.remove([this.findMessageSelection(action.id)])
			unsent = unsent.concat(action)
		}
		return new MessageSelectionState(base.version,base.selections, decos, unsent)
	}
	}
	//todo add update to this!!
	receive({version, events, sent}, doc) {
		let set = this.decos
		let selections=this.selections
		for (let i = 0; i < events.length; i++) {
			let event = events[i]

			if (!this.findMessageSelection(event.id)){
				selections.push(event)
				addNewSelection(event)
				// set = set.add(doc, [deco(event.from, event.to, new MessageSelection(event.id,event.isValid))])
			}
		}
		return new MessageSelectionState(version, selections,set, this.unsent.slice(sent))
	}

	unsentEvents() {
		let result = []
		for (let i = 0; i < this.unsent.length; i++) {
			let action = this.unsent[i]
			if (action.type == "newMessageSelection") {
				let found = this.findMessageSelection(action.messageSelection.id)
				if (found){
					result.push({
						type: "create", 
						id: action.messageSelection.id,
						from: found.from, 
						to: found.to,
						isValid:action.messageSelection.isValid
					})
				}
			}else if(action.type=='deleteMessageSelection'){
				result.push({type: "delete", id: action.id})
			} 
		}
		return result
	}

	static init(config) {
		const selections=config.messageSelections.messageSelections
		let decos = []
		return new MessageSelectionState(config.messageSelections.version,selections, DecorationSet.create(config.doc, decos), [],null)
	}
}
