import store from '../store'
import find from 'lodash/find'

export function getDefaultTeamForOrg() {	
	//Find the team with the most projects and use that as default

	const state = store.getState()
	const projects=state.projects

	//Find the team that is the most common in projects 
	let teamCount = {};

	// Count the occurrence of each team in the project teams arrays
	projects.forEach((project) => {
  	project.teams.forEach((teamId) => {
    if(teamCount[teamId]) {
      teamCount[teamId]++;
    } else {
      teamCount[teamId] = 1;
    }
  });
	});
	

	let mostCommonTeamId;
	let highestCount = 0;
	for (const teamId in teamCount) {
  	if (teamCount[teamId] > highestCount) {
    	highestCount = teamCount[teamId];
    	mostCommonTeamId = teamId;
  	}
	}

//	console.log(`default team is ${mostCommonTeamId}`)
	// const teams=state.teams
	// const default_team=state.organization.default_team


	// let defaultTeam=teams[0]
	// let defaultTeamId
	// if(defaultTeam){
	// 	defaultTeamId=defaultTeam.team_id
	// 	defaultTeam=defaultTeamId
	// }


	// if(default_team){
	// 	const team=find(teams,{team_id:default_team})
	// 	if(team){
	// 		defaultTeam=default_team
	// 	}
	// }

	// // console.log('default team is--------')
	// // console.log(defaultTeam)
	return mostCommonTeamId
}



// export function getDefaultTeamForOrg() {	
	
// 	const state = store.getState()
// 	const teams=state.teams
// 	const default_team=state.organization.default_team


// 	let defaultTeam=teams[0]
// 	let defaultTeamId
// 	if(defaultTeam){
// 		defaultTeamId=defaultTeam.team_id
// 		defaultTeam=defaultTeamId
// 	}


// 	if(default_team){
// 		const team=find(teams,{team_id:default_team})
// 		if(team){
// 			defaultTeam=default_team
// 		}
// 	}

// 	// console.log('default team is--------')
// 	// console.log(defaultTeam)
// 	return defaultTeam
// }

