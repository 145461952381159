import {Plugin,PluginKey} from "prosemirror-state"
import {DocSearchState} from './inDocSearch'

const key = new PluginKey('inDocSearchPlugin')

export const inDocSearchPlugin = new Plugin({
  key:key,
  state: {
    init:DocSearchState.init,
    apply(tr, prev) { return prev.apply(tr) }
  },
  props: {
    decorations(state) { return this.getState(state).decos }
  }
})


 