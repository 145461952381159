import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import LinearMarker from '../misc/LinearMarker'
import LinearProjectStatusIcon from '../misc/LinearProjectStatusIcon'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getRoadmapForId} from '../../utils/getRoadmapForId'
import {getProjectsForRoadmap} from '../../utils/getProjectsForRoadmap'
import {getUserForId} from '../../utils/getUserForId'
import RoadmapsHomePageGridItemActivity from './RoadmapsHomePageGridItemActivity'
import {openRoadmap,backgroundOpenRoadmap} from '../../utils/openRoadmap'
import {openDoc,backgroundOpenDoc} from '../../utils/openDoc'
import {getRootDocIdForProject} from '../../utils/getRootDocIdForProject'
import {updateQuarterlyRoadmap} from '../../utils/roadmaps/updateQuarterlyRoadmap'
import moment from 'moment'

class RoadmapsHomePageGridItemSummaryChartPill extends React.PureComponent{
	render(){	
		const {status} = this.props
	  return (	  	
			<div className={'roadmapsHomePage-roadmapGrid-item-summaryChart-pill ' + (status ? `roadmapsHomePage-roadmapGrid-item-summaryChart-pill--status--${status}` : '')}>
			</div>  		
	  )
	}
}

class RoadmapsHomePageGridItemProject extends React.PureComponent{

	render(){	
		const {title, id, status} = this.props
		const leadUser = getUserForId(this.props.lead)
	  return (	  	
			<div onClick={(e)=>{this.props.handleProjectRowClick(e,id)}} className={'roadmapsHomePage-roadmapGrid-item-projectList-project ' + (status ? `roadmapsHomePage-roadmapGrid-item-projectList--status--${status}` : '')}>
				{/* <div className='roadmapsHomePage-roadmapGrid-item-statusProject-project-linearMarkerContainer'>
					<LinearProjectStatusIcon 
            projectStatus={status}
          />
				</div>
				*/}
						
				{/*} <div className='roadmapsHomePage-roadmapGrid-item-statusProject-project-emojiContainer'> <LinearMarker projectId={id} /> </div>	*/}

				
				{/*} <div className='roadmapsHomePage-roadmapGrid-item-statusProject-project-simpleMarker' />	*/}


				{/*
				{status === 'started' && 
					<div className='roadmapsHomePage-roadmapGrid-item-statusProject-project-lead'>
						<AvatarImage className='roadmapsHomePage-roadmapGrid-item-statusProject-project-lead-avatar' avatarUrl={leadUser.avatar} />
					</div>			
				}
				*/}
				<div className='roadmapsHomePage-roadmapGrid-item-projectList-project-label'>
					{title}
				</div>
			</div>  		
	  )
	}
}


function getCompletedProjectsInLast90Days(projects){
	let completedProjects=[]
	const cutOffDate = moment().subtract(90, 'days')
	projects.forEach((project)=>{
		if(project.state=='completed'){
			if(moment(project.completed_at).isAfter(cutOffDate)){
				completedProjects.push(project)
			}
		}
	})
	return completedProjects
}

class RoadmapsHomePageGridItem extends React.PureComponent{

	constructor(){
		super()		
		this.handleRoadmapClick=this.handleRoadmapClick.bind(this)
		this.handleProjectRowClick=this.handleProjectRowClick.bind(this)
	}

	handleRoadmapClick(e){
		const roadmapId=this.props.roadmap.roadmap_id
		if(e.metaKey){
			backgroundOpenRoadmap(roadmapId)
		}else{
			openRoadmap(this.props.history,roadmapId)
		}
	}

	handleProjectRowClick(e,projectId){
		e.stopPropagation()
		const projectDocId=getRootDocIdForProject(projectId)
		if(e.metaKey){
			backgroundOpenDoc(projectDocId)
		}else{
			openDoc(this.props.history,projectDocId)
		}
	}

	updateQuarterRoadmap(e,roadmap){
		e.stopPropagation()
		e.preventDefault()
		updateQuarterlyRoadmap(roadmap.roadmap_id)
	}

	render(){	
		const {roadmap,title,id,activeStatus}=this.props		
		const roadmapProjects = getProjectsForRoadmap(roadmap)

		const startedProjects = roadmapProjects.filter(project => project.state === 'started').sort((a, b) => a.name.localeCompare(b.name));
		const plannedProjects = roadmapProjects.filter(project => project.state === 'planned').sort((a, b) => a.name.localeCompare(b.name));
		const backlogProjects = roadmapProjects.filter(project => project.state === 'backlog').sort((a, b) => a.name.localeCompare(b.name));
		const completedProjects = getCompletedProjectsInLast90Days(roadmapProjects)
  
  	let activeProjects
  	let activeProjectsLabel
  	let noActiveProjectsLabel

  	if(activeStatus === 'started'){
  		activeProjects = startedProjects
  		activeProjectsLabel = 'In Progress'
  		noActiveProjectsLabel = 'None in progress'
  	}  		
  	if(activeStatus === 'planned'){
  		activeProjects = plannedProjects
  		activeProjectsLabel = 'Planned'
  		noActiveProjectsLabel = 'None planned'
  	}  		
  	if(activeStatus === 'backlog'){
  		activeProjects = backlogProjects
  		activeProjectsLabel = 'Backlog'
  		noActiveProjectsLabel = 'None in backlog'
  	}  		
  	if(activeStatus === 'completed'){
  		activeProjects = completedProjects
  		activeProjectsLabel = 'Completed'
  		noActiveProjectsLabel = 'None completed yet'
  	}  		


  	const noActiveStatus = !activeStatus
  	const currentQuarterLabel = 'Q3' // this would always be Q1/Q2/Q3/Q4
	  return (	  	
	  	<Tooltip.Provider>
	  		<div onClick={this.handleRoadmapClick} className={'roadmapsHomePage-roadmapGrid-item ' + (activeStatus === 'activity' ? ' roadmapsHomePage-roadmapGrid-item--activity ' : ' roadmapsHomePage-roadmapGrid-item--projectStatus ')}>  			
	  			<div className='roadmapsHomePage-roadmapGrid-item-navBtnContainer'>
	  				<div className='roadmapsHomePage-roadmapGrid-item-topline'>  					
		  				<div className='roadmapsHomePage-roadmapGrid-item-topline-iconContainer'>
		  					<Icon name='roadmapOutlineLight' />
		  				</div>
	  				</div>
		  			<div className='roadmapsHomePage-roadmapGrid-item-titleContainer'>
			  			<div className='roadmapsHomePage-roadmapGrid-item-title'>
			  				{roadmap.name}
			  			</div>	  			
		  			</div>
	  			</div>
	  			{activeStatus === 'activity' &&
		  			<div className='roadmapsHomePage-roadmapGrid-item-contentContainer'>
		  				<RoadmapsHomePageGridItemActivity
		  					roadmap={roadmap}
		  					roadmapId={id}
		  				/>	  					
		  			</div>
	  			}
	  			<div className={' roadmapsHomePage-roadmapGrid-item-contentContainer ' + (noActiveStatus ? ' roadmapsHomePage-roadmapGrid-item-contentContainer--noActive ' : ' ')}>

	  				{activeStatus !== 'activity' &&
	  				<div className='roadmapsHomePage-roadmapGrid-item-summaryChart'>
		  				
		  				{completedProjects.length > 0 &&
		  					<Tooltip.Root delayDuration={1000} skipDelayDuration={1000}>
									<Tooltip.Trigger asChild>
				  					<div className='roadmapsHomePage-roadmapGrid-item-summaryChart-group'>
						  				{completedProjects.map(project => (
								        <RoadmapsHomePageGridItemSummaryChartPill
								          key={project.project_id}			          
								          id={project.project_id}
								          status="completed"
								        />
								      ))}
				  					</div>
		  						</Tooltip.Trigger>				
									<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI">
										 {completedProjects.length} completed last 90 days
									</Tooltip.Content>
								</Tooltip.Root>
		  				}

		  				{startedProjects.length > 0 &&
		  					<Tooltip.Root delayDuration={200} skipDelayDuration={1000}>
									<Tooltip.Trigger asChild>
				  					<div className='roadmapsHomePage-roadmapGrid-item-summaryChart-group'>
						  				{startedProjects.map(project => (
								        <RoadmapsHomePageGridItemSummaryChartPill
								          key={project.project_id}			          
								          id={project.project_id}
								          status="started"
								        />
								      ))}
				  					</div>		  					  				
		  						</Tooltip.Trigger>				
									<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI">
										{startedProjects.length} in progress projects
									</Tooltip.Content>													
							</Tooltip.Root>
		  				}

		  				{plannedProjects.length > 0 &&
		  					<Tooltip.Root delayDuration={200} skipDelayDuration={1000}>
									<Tooltip.Trigger asChild>
				  					<div className='roadmapsHomePage-roadmapGrid-item-summaryChart-group'>
						  				{plannedProjects.map(project => (
								        <RoadmapsHomePageGridItemSummaryChartPill
								          key={project.project_id}			          
								          id={project.project_id}
								          status="planned"
								        />
								      ))}
				  					</div>		  			
				  				</Tooltip.Trigger>
		  						<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI">
								 		{plannedProjects.length} planned projects
									</Tooltip.Content>													
								</Tooltip.Root>		  				
		  				}

		  				{backlogProjects.length > 0 &&
		  				<Tooltip.Root delayDuration={200} skipDelayDuration={1000}>
								<Tooltip.Trigger asChild>
			  					<div className='roadmapsHomePage-roadmapGrid-item-summaryChart-group'>
					  				{backlogProjects.map(project => (
							        <RoadmapsHomePageGridItemSummaryChartPill
							          key={project.project_id}			          
							          id={project.project_id}
							          status="backlog"
							        />
							      ))}
			  					</div>		 
			  					</Tooltip.Trigger>
			  					<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI">
									 {backlogProjects.length} backlog projects
								</Tooltip.Content>													
							</Tooltip.Root>	
		  				}
		  			</div>
		  			}

		  			{(activeStatus === 'planned' || activeStatus === 'backlog' || activeStatus === 'started' || activeStatus === 'completed') &&
		  				<div className='roadmapsHomePage-roadmapGrid-item-projectList'>
		  					<div className='roadmapsHomePage-roadmapGrid-item-projectList-header'>
									{activeProjectsLabel}
		  					</div>
			  				 {activeProjects.map(project => (
						        <RoadmapsHomePageGridItemProject
						          key={project.project_id}
						          title={project.name}
						          id={project.project_id}
						          activeStatus={activeStatus}
						          handleProjectRowClick={this.handleProjectRowClick}
						        />
						      ))}
			  				 	{activeProjects.length < 1 &&
			  						<div className='roadmapsHomePage-roadmapGrid-item-projectList-project roadmapsHomePage-roadmapGrid-item-projectList-project--none'>			
											<div className='roadmapsHomePage-roadmapGrid-item-projectList-project-label'>
												None
											</div>
										</div>
			  					}  	
		  				</div>
	  				}
	  			</div>

	  			{/*}
	  			{!this.props.hasCurrentQuarterRoadmap && 
		  			<div className='roadmapsHomePage-roadmapGrid-item-currentQuarterBtnContainer'>
		  				<button onClick={(e)=>{this.updateQuarterRoadmap(e,roadmap)}} className='roadmapsHomePage-roadmapGrid-item-currentQuarterBtn'>
		  					<div className='roadmapsHomePage-roadmapGrid-item-currentQuarterBtn-iconContainer'>
		  						📍
		  					</div>
		  					<div className='roadmapsHomePage-roadmapGrid-item-currentQuarterBtn-label'>
		  						Mark as {currentQuarterLabel} Roadmap
		  					</div>
		  				</button>
		  			</div>
		  		}
		  		*/}
	  		</div>
  		</Tooltip.Provider>
	  )
	}
}

export default withRouter(RoadmapsHomePageGridItem)
