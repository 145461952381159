import React, { Component } from 'react'
import Icon from '../../components/misc/Icon'


class SideMenuOrgTabOrgLogo extends React.Component{  

	render(){	
	  return (			
			<div className={'sideMenu-tab--tabType--orgTab-orgLogo' + (this.props.isDemoAccount ? ` sideMenu-tab--tabType--orgTab-orgLogo--demoAccount ` : ' ')}>
				{!this.props.isDemoAccount && 
					<img className='sideMenu-tab--tabType--orgTab-orgLogo-image' src={this.props.orgLogo} />							
				}				
				{this.props.isDemoAccount && 
					<div className='sideMenu-tab--tabType--orgTab-orgLogo--demoAccount-avatar'>
						A
					</div>
				}
			</div>
	  )
	}
}



class SideMenuOrgTab extends React.Component{  

	render(){	
		const {navigateTo, showSearchBtn} = this.props				
		const {org} = this.props
		
		let orgName

		if(org){
			orgName = org.name
		} 
		let isDemoAccount
		if(org && org.name === 'Yarn'){
			orgName ='Acme Inc.' // for demos
			//orgName ='Your Drafts' // for demos
			isDemoAccount = false
		}
		let active = this.props.tabUrl && this.props.pathname==this.props.tabUrl
		//const orgLogoSrc = 'https://res.cloudinary.com/yarn/image/upload/v1657452947/dummydata/companyicons/linearappicon_eryfrp.png'
	  return (
			<div onClick={()=>{this.props.navigateTo(this.props.tabUrl)}} className={'sideMenu-tab sideMenu-tab--tabType--orgTab ' + (active ? ' sideMenu-tab--active ' : ' sideMenu-tab--inactive ') + (showSearchBtn ? ' sideMenu-tab--showSearchBtn ' : '')}>
				<div className='sideMenu-tab--tabType--orgTab-orgLogoContainer'>												
					{org && org.logo && 
		    		<SideMenuOrgTabOrgLogo 
		    			orgLogo={this.props.org.logo}
		    			isDemoAccount={isDemoAccount}
		    		/>				    					    		
					}				    	
				</div>
				<div className='sideMenu-tab-labelContainer'>
					<div className='sideMenu-tab-label'>
						{orgName}
					</div>
				</div>
			</div>					
	  )
	}
}

export default SideMenuOrgTab


