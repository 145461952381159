//bump this  when speaker assignment changes so rerenders

export function bumpTranscriptSpeakerVersion() {
	if(window.transcriptView && window.transcriptView.state){
		let tr = window.transcriptView.state.tr
		window.transcriptView.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.speakerVersion || node.attrs.speakerVersion===0){
				tr.setNodeMarkup(pos, null, {...node.attrs,speakerVersion:node.attrs.speakerVersion+1})
			}
		})
		tr.setMeta('addToHistory', false)  
		window.transcriptView.dispatch(tr)
	}
}