import React from 'react'
import {DOMSerializer} from "prosemirror-model"
import editorSchema from '../../prosemirror/schema/editorSchema'
import data from '../../emojiData'
import ReactDOM from 'react-dom'
import LinearProjectMentionReactComponent from '../nodeViews/linear/projects/LinearProjectMentionReactComponent'
import LinearIssueReactComponent from '../nodeViews/linear/LinearIssueReactComponent'
import InternalLinkReactComponent from '../nodeViews/links/InternalLinkReactComponent'

function createMarkup(input) {
  return {__html: input}
}

function getEmojiForString(string){
  const natives=data.natives
  const keys=Object.keys(natives)
  let emoji
  keys.map((key)=>{
    if(natives[key]==string && !emoji){
      emoji=key
    }
  })
  return emoji
}

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const alphabet=['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

const ALTERNATING_ROW_BG_CLASSNAME='doc-tableContainer--alternatingRowHighlight'
const HEADER_ROW_CLASSNAME="doc-tableContainer--hasHeaderRow"
const HEADER_COLUMN_CLASSNAME="doc-tableContainer--hasHeaderColumn"

// function createCheckboxSVG() {
//   let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
//   svg.setAttribute("class", "icon icon--name--toDoItemCheckbox");
//   svg.setAttribute("width", "72");
//   svg.setAttribute("height", "72");
//   svg.setAttribute("viewBox", "0 0 72 72");

//   let polyline = document.createElementNS("http://www.w3.org/2000/svg", "polyline");
//   polyline.setAttribute("fill", "none");
//   polyline.setAttribute("stroke-linecap", "round");
//   polyline.setAttribute("stroke-linejoin", "round");
//   polyline.setAttribute("points", "10 36.183 28.233 56.3 58 12.3");
  
//   svg.appendChild(polyline);
  
//   return svg;
// }


const CHECKED_CLASSNAME='doc-toDoItem--state--checked'
const UNCHECKED_CLASSNAME='doc-toDoItem--state--unchecked'




function createToDoItemNode(node) {
  const classList = ["doc-toDoItem"];
  const { isChecked, indentLevel } = node.attrs;
  if(!node.textContent ){
    classList.push("doc-toDoItem--empty")
  }
  if(isChecked){
    classList.push(CHECKED_CLASSNAME)
  }else{
    classList.push(UNCHECKED_CLASSNAME)
  }

  let checkboxClassname='doc-toDoItem-checkbox doc-toDoItem-checkbox--state--unchecked'
  let checkboxStyle="background-color: rgb(255, 255, 255); border-color: rgb(181, 181, 192)"
  if(isChecked){
    checkboxClassname='doc-toDoItem-checkbox doc-toDoItem-checkbox--state--checked'
    checkboxStyle="background-color: rgb(24, 122, 242); border-color: rgb(0, 103, 230)"
  }
      return [
        "div",
        {
          class: classList.join(' '),
          "data-is-checked": isChecked,
          "data-indent-level": indentLevel
        },
        [
          "div",
          {
            class: "doc-toDoItem-UIContainer",
            contenteditable: "false"
          },
          
            [
              "div",
              {
                class: "doc-toDoItem-checkboxContainer",
                style: "transform: scale(1)"
              },
              [
                "div",
                {
                  class: checkboxClassname,
                //  style: checkboxStyle
                },
                [
                  "svg",
                  {
                    class: "icon icon--name--toDoItemCheckbox",
                    width: "72",
                    height: "72",
                    viewBox: "0 0 72 72"
                  },
                  ["polyline", { fill: "none", "stroke-linecap": "round", "stroke-linejoin": "round", points: "10 36.183 28.233 56.3 58 12.3" }]
                ]
              ]
            ]
          ],
          ["div", { class: "doc-toDoItem-contents" }, 0]
        
      ];
    }



function convertNodeToHTML(node){
  let base = DOMSerializer.fromSchema(editorSchema)
  
  let customSerializer = new DOMSerializer(Object.assign({}, base.nodes, { //for copy/paste math nodes https://prosemirror.net/docs/ref/#model.DOMSerializer
    paragraph(node) { 
      const { indentLevel } = node.attrs;
      const content = node.content.size === 0 ? ["br", {class: "ProseMirror-trailingBreak"}] : 0;
        return ["div", { class: `doc-para`, "data-indent-level": indentLevel }, content];
    },
    toDoItem(node) { 
      return createToDoItemNode(node)
    },
    heading(node){
      let classList = [`doc-h${node.attrs.level}`];
      if(!node.textContent ){
        classList.push("doc-header--empty")
      }
     const content = node.content.size === 0 ? ["br", {class: "ProseMirror-trailingBreak"}] : 0;
      return ["div",{class:classList.join(' ')}, content] 
    },
    
    bulletListItem(node){
      const {indentLevel,markerType,positionInList, separatorType} = node.attrs;
      let markerDivContent=''
      if(markerType=='number'){
        markerDivContent=`${positionInList+1}`
      }else if(markerType=='letter'){
         markerDivContent=alphabet[positionInList%26]
      }
      else if(markerType=='letterLowerCase'){
         markerDivContent=alphabet[positionInList%26]
      }
        return ["div", {
          class:"doc-listItem", 
          "data-indent-level":indentLevel, 
          "data-marker-type":markerType,
          "data-position-in-list":positionInList, 
          "data-separator-type": separatorType
        }, 
        ["div", {class:"doc-listItem-markerContainer", contenteditable:"false"}, ["div", {class:"doc-listItem-marker"},markerDivContent]],
        ["div", {class:"doc-listItem-contents"}, ["div", {class:"doc-para", "data-indent-level":indentLevel}, ["span", {class:"doc-para-span"}, 0]]
    ]
    ] 
  },
   docTable(node){
    const { alternatingRowBG,headerRow,headerColumn } = node.attrs;
    const classList = ["doc-tableContainer"];
    if (alternatingRowBG) {
      classList.push(ALTERNATING_ROW_BG_CLASSNAME);
    }
    if(headerRow){
      classList.push(HEADER_ROW_CLASSNAME);
    }
    if(headerColumn){
      classList.push(HEADER_COLUMN_CLASSNAME);
    }
    return [
      "div",
      { class: classList.join(' ') },
      ["div",
        { class: "doc-tableInnerContainer" },
        ["div",
          { class: "doc-table" },
          ["div", { class: "tableWrapper" }, 0]
        ]
      ]
    ];
  },
  table_cell(node){
    const { background,colwidth } = node.attrs;
    return [
      "td",
      { class: `doc-table-cell doc-table-cell--bg--${background}`,style: `width: ${colwidth}px` },
      ["div", { class: "doc-table-cell-content" }, 0]
    ];
  },
  table_header(node){
      const { background } = node.attrs;
      return [
        "th",
        { class: `doc-table-cell doc-table-cell--bg--${background}` },
        ["div", { class: "doc-table-cell-content" }, 0]
      ];
    },
   emoji(node) {
    const { emojiString } = node.attrs;
    const emoji=getEmojiForString(emojiString)
    return [
      "span",
      { class: "doc-para-emoji", role: "img" },
      emoji
    ];
  },
  textLink(node){
    return [
      "span",
      { class: "doc-textLink" },
      0
    ];
  },
  internalLink(node){
    const {docId} = node.attrs;
    return [
      "span",
      { class: "doc-para-internalLinkContainer" ,"data-doc-id":docId}
    ];
  },
  linearProjectMention(node){
    const {projectId} = node.attrs;
    return ["div",{class:`doc-para-projectLinkContainer doc-para-span`,"data-project-id":projectId}]
    
  },
  linearIssueMention(node){
    const {issueId,
      showAssignee,
      showTitle,
      showIdentifier,
      showStatus,
      showPriority,
      layout
    } = node.attrs;
    return ["div",{class:`doc-para-linearIssueOuterContainer doc-para-linearIssueOuterContainer--inline doc-para-span`,"data-issue-id":issueId,"data-show-assignee":showAssignee,"data-show-title":showTitle,"data-identifier":showIdentifier,"data-show-status":showStatus,"data-show-priority":showPriority,"data-layout":layout}]
    
  },
  docDate(node) {
    const {date}=node.attrs
    const dateObj = new Date(date)
    const formattedDate = monthNames[dateObj.getMonth()] + ' ' + dateObj.getDate() + ', ' + dateObj.getFullYear();
  return [
    "span",
    {
      id: "test",
      class: "doc-para-dateContainer doc-para-span"
    },
    [
      "span",
      {
        class: "doc-para-date",
        type: "button",
        "aria-haspopup": "dialog",
        "aria-expanded": "false",
        "aria-controls": "radix-9",
        "data-state": "closed",
      },
      [
        "span",
        { 
          role: "img",
          class: "doc-para-date-spacer",
        }
      ],
      [
        "span",
        {
          role: "img",
          class: "doc-para-date-iconContainer"
        },
        [
          "svg",
          {
            class: "icon icon--name--calendarSimple",
            width: "32",
            height: "32",
            viewBox: "0 0 32 32"
          },
          ["path", { d: "M7.668 27.238h16.675c1.277 0 2.234-.315 2.868-.946.635-.63.953-1.577.953-2.838V8.549c0-1.261-.318-2.207-.953-2.838-.634-.63-1.59-.946-2.868-.946H7.668c-1.278 0-2.236.315-2.875.946-.639.63-.958 1.577-.958 2.838v14.905c0 1.261.32 2.207.958 2.838.64.63 1.597.946 2.875.946zm-.183-1.965c-.537 0-.952-.145-1.245-.434-.293-.288-.44-.714-.44-1.275V12.053c0-.562.147-.987.44-1.276.293-.289.708-.433 1.245-.433h17.017c.545 0 .964.144 1.257.433.293.289.44.714.44 1.276v11.51c0 .562-.147.988-.44 1.276-.293.29-.712.434-1.257.434H7.485z" }]
        ]
      ],
      ["span", { class: "doc-para-date-label" }, formattedDate]
    ]
  ];
},

  toggleListItem(node){
   return [
    "div",
    {
      class: "doc-toggleListItem",
      "data-state": 'closed',
    },
    [
      "div",
      { class: "doc-toggleListItem-markerContainer" },
      [
        "button",
        { class: "doc-toggleListItem-marker" },
        [
          "svg",
          {
            class: "icon icon--name--toggleArrow",
            width: "32",
            height: "32",
            viewBox: "0 0 32 32",
          },
          ["path", { d: "M24.382 16.082 7.908 25.823c-.6.355-1.375.158-1.731-.439A1.254 1.254 0 0 1 6 24.742V5.258a1.26 1.26 0 0 1 1.908-1.082l16.474 9.742a1.255 1.255 0 0 1 0 2.164z" }]
        ]
      ],
      ["button", { class: "doc-toggleListItem-marker-lineContainer" }, ["div", { class: "doc-toggleListItem-marker-line" }]]
    ],
    ["div", {class:"doc-toggleListItem-contents"}, 0]
  ];
  },

  editorPage(){
    return [
        "div",{ class: "ProseMirror staticHTML" },
          ["div",
            { class: "docInnerWrapper" },0]
          ]
        
    },
  

  }), base.marks)

  //let fragment = DOMSerializer.fromSchema(editorSchema).serializeFragment(node)
    let fragment = customSerializer.serializeFragment(node)
  let tmp = document.createElement("div")
  tmp.appendChild(fragment)
  let html=tmp.innerHTML
  return html
}




class DocEditorDevHTMLPanel extends React.Component{  



  constructor(props) {
    super(props)
    this.renderMentions=this.renderMentions.bind(this)
  }

  componentDidMount(){ //After mounted check for mention HTML and replace with react components
    this.renderMentions()
  }

  componentWillUnmount(){ //lets cleanup after ourselves
    this.els.forEach((el)=>{
      ReactDOM.unmountComponentAtNode(el)
    })
  }

  componentDidUpdate(){
    // this.els.forEach((el)=>{
    //   ReactDOM.unmountComponentAtNode(el)
    // })
    this.renderMentions()
  }

  renderMentions(){
    this.els=[]
    const projectElements=ReactDOM.findDOMNode(this).getElementsByClassName('doc-para-projectLinkContainer')
    for (let i = 0; i < projectElements.length; ++i) {
      const el = projectElements[i];  
      const projectId=el.getAttribute("data-project-id")
      ReactDOM.render(
        <LinearProjectMentionReactComponent 
          projectId={projectId}
        />, el)
      this.els.push(el)
      }


    const linkElements=ReactDOM.findDOMNode(this).getElementsByClassName('doc-para-internalLinkContainer')
    
    for (let i = 0; i < linkElements.length; ++i) {
      const el = linkElements[i];  
      const docId=el.getAttribute("data-doc-id")
      ReactDOM.render(
        <InternalLinkReactComponent 
          docId={docId}
        />, el)
      this.els.push(el)
      } 


    const issueElements=ReactDOM.findDOMNode(this).getElementsByClassName('doc-para-linearIssueOuterContainer')

    for (let i = 0; i < issueElements.length; ++i) {
      const el = issueElements[i];  
      const issueId=el.getAttribute("data-issue-id")
      const showAssigneeString = el.getAttribute("data-show-assignee")
      const showAssignee = showAssigneeString === "true";

      const showTitleString = el.getAttribute("data-show-title")
      const showTitle = showTitleString === "true";

      const showStatusString = el.getAttribute("data-show-status")
      const showStatus = showStatusString === "true";

      const showPriorityString = el.getAttribute("data-show-priority")
      const showPriority = showPriorityString === "true";

      ReactDOM.render(
        <LinearIssueReactComponent 
          issueId={issueId}
          showTitle={showTitle}
          issueLayout={'inline'}
          showAssignee={showAssignee}
          showIdentifier={true}
          showStatus={showStatus}
          showPriority={showPriority}
        />, el)
      this.els.push(el)
      }
  }


	render(){	
    let html=''
    if(this.props.pmDoc && Object.keys(this.props.pmDoc).length>0){
      html=convertNodeToHTML(this.props.pmDoc)
    }


    let element = document.getElementById('test');
    // console.log('here in html')
   // console.log(element)
    if(element){
      const htmlString= element.outerHTML
      console.log(htmlString)
    }
    //let elementHTML = element.innerHTML;


    // works at 2560 without sidepanel

    const formattedHtmlString = html.replace(/<\//g, '\n</')
                                        .replace(/></g, '>\n<')
                                        .replace(/  /g, ' ')
                                        .split('\n')
                                        .map((line, i) => line.trim())
                                        .join('\n');
	  return (	  	
  		<React.Fragment>
       <div className='docEditor-htmlOutputPanel'>
        
        <div className='docEditor-htmlOutputPanel-codeContainer'>
          <pre>
            <code>
              {formattedHtmlString}
            </code>
          </pre>
        </div>          
          <div className='docEditor-htmlOutputPanel-docContainer'>
            <div className='docEditor-htmlOutputPanel-doc' dangerouslySetInnerHTML={createMarkup(html)} />
          </div>
        </div>
      </React.Fragment>
	  )
	}
}

export default DocEditorDevHTMLPanel
