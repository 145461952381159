import findIndex from 'lodash/findIndex'
import moment from 'moment'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_A_RECORDING_SUCCESS':
		const recordingId = action.response.recording_id
		let indexOfRecording = findIndex(state,(recording => {
			return recording.recording_id === recordingId
		}))
		if(indexOfRecording === -1){
			indexOfRecording=state.length
		}
		return [
			...state.slice(0,indexOfRecording),
			action.response,
			...state.slice(indexOfRecording + 1)
		]


	case 'ARCHIVE_RECORDING_REQUEST':
		let indx = findIndex(state,(recording => {
			return recording.recording_id === action.recordingId
		}))
		if(indx > -1){
			return [
				...state.slice(0,indx),
				...state.slice(indx + 1)
			]
		}else{
			return state
		}


	case 'FETCH_RECORDINGS_SUCCESS':
		return action.response


	case 'ASSIGN_SPEAKER_REQUEST':
		let updatedIndex=findIndex(state,(recording => {
			return recording.recording_id === action.recordingId
		}))
		if(updatedIndex > -1){
			const recording=state[updatedIndex]
			let newRecording={...recording}
			let oldSpeakers=newRecording.speakers
			let newSpeakersArray=[]
			oldSpeakers.forEach((speaker)=>{
				if(speaker.speaker_id !== action.speakerId){
					newSpeakersArray.push(speaker)
				}else{
					let newSpeakerObj={...speaker}
					newSpeakerObj.user_id=action.userId
					newSpeakerObj.contact_id=action.contactId
					newSpeakersArray.push(newSpeakerObj)
				}
			})

			newRecording.speakers=newSpeakersArray
			newRecording.updated_at=action.updated_at


			return [
				...state.slice(0,updatedIndex),
				newRecording,
				...state.slice(updatedIndex + 1)
			]
		}else return state

	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
