import React from 'react'
import Icon from '../misc/Icon'
import { Spring, animated, config } from 'react-spring'

class RecordingPlaybackModalMediaPlaybackSpeed extends React.Component{  

  constructor(props) {
    super(props)
    
    this.state={      
      playbackSpeed: 1.0,      
      playbackSpeeds: [0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.5],
      currentSpeedIndex: 2,
      previousSpeedIndex: null,
      fasterButtonActive: false,
      slowerButtonActive: false
    }
  } 

    handleSpeedChangeFaster = () => {
    const { playbackSpeeds, currentSpeedIndex } = this.state;
    if (currentSpeedIndex < playbackSpeeds.length - 1) {
      clearTimeout(this.fasterTimeout);  // Clear any existing timeout
      const newSpeed = playbackSpeeds[currentSpeedIndex + 1];
      
      this.setState({
        previousSpeedIndex: currentSpeedIndex,
        currentSpeedIndex: currentSpeedIndex + 1,
        playbackSpeed: newSpeed,
        fasterButtonActive: true
      });

      this.fasterTimeout = setTimeout(() => {
        this.setState({ fasterButtonActive: false });
      }, 80);
      
      this.props.changePlaybackRate(newSpeed); // update playback rate in parent
    }
  }


  handleSpeedChangeSlower = () => {
    const { playbackSpeeds, currentSpeedIndex } = this.state;
    if (currentSpeedIndex > 0) {
      clearTimeout(this.slowerTimeout);  // Clear any existing timeout
      const newSpeed = playbackSpeeds[currentSpeedIndex - 1];

      this.setState({
        previousSpeedIndex: currentSpeedIndex,
        currentSpeedIndex: currentSpeedIndex - 1,
        playbackSpeed: newSpeed,
        slowerButtonActive: true
      });

      this.slowerTimeout = setTimeout(() => {
        this.setState({ slowerButtonActive: false });
      }, 80);

      this.props.changePlaybackRate(newSpeed); // update playback rate in parent
    }
  }
  
  handleResetSpeed = () => {
    const { playbackSpeeds, currentSpeedIndex, previousSpeedIndex } = this.state;
    if (currentSpeedIndex !== 2) {
      this.setState({
        previousSpeedIndex: currentSpeedIndex === 2 ? previousSpeedIndex : currentSpeedIndex, // Preserve the previous speed index unless it's 2
        currentSpeedIndex: 2,
        playbackSpeed: 1.0,
      });
      this.props.changePlaybackRate(1.0); // update playback rate in parent
    } else if (previousSpeedIndex != null && previousSpeedIndex !== 2) {
      const newSpeed = playbackSpeeds[previousSpeedIndex];
      this.setState({
        currentSpeedIndex: previousSpeedIndex,
        playbackSpeed: newSpeed,
      });
      this.props.changePlaybackRate(newSpeed); // update playback rate in parent
    }
  }


  render(){           
    const {activeSession} = this.props    

    const {currentSpeedIndex, fasterButtonActive, slowerButtonActive, previousSpeedIndex} = this.state

    const widthArrayItem = 70
    const arrayOffset = widthArrayItem * currentSpeedIndex * -1
    

		return (
	
      <div className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeedContainer'>
      <div className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed'>
          <Spring
            transform={slowerButtonActive ? `translateX(-6px)` : 'translateX(0px)'}         
            opacity={slowerButtonActive ? 1 : 0.5}         
            config={{
              tension: 500,
              friction: 34,
            }}
          >
            {styles => 
              <animated.button style={styles} onClick={this.handleSpeedChangeSlower} className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn--slower'>
                <Icon name='playbackRewind' />
                </animated.button>              
            }
          </Spring>   


        
        <button disabled={!previousSpeedIndex && currentSpeedIndex === 2} onClick={this.handleResetSpeed} className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn'>   
          <div className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-gradient recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-gradient--left'/>
          <div className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-gradient recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-gradient--right'/>
          <Spring
            transform={currentSpeedIndex ? `translateX(${arrayOffset}px)` : 'translateX(0px)'}         
            config={{
              tension: 500,
              friction: 34,
            }}
          >
            {styles => 
              <animated.div style={styles} className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-array'>
                {this.state.playbackSpeeds.map((speed, index) => 
                  <div className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-arrayItem' key={index}>              
                    <div className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-arrayItem-label'>
                      {speed.toFixed(1)}
                    </div>
                    <div className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-currentSpeedBtn-arrayItem-iconContainer'>
                      <Icon name='multiplyBold' />
                    </div>
                  </div>
                )}
              </animated.div>
            }
          </Spring>   

          
        </button>        

         <Spring
            transform={fasterButtonActive ? `translateX(6px)` : 'translateX(0px)'}         
            opacity={fasterButtonActive ? 1 : 0.5}         
            config={{
              tension: 500,
              friction: 34,
            }}
          >
            {styles => 
              <animated.button style={styles} onClick={this.handleSpeedChangeFaster} className='recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn recordingPlaybackModal-media-playbackControls-buttonRow-playbackSpeed-changeBtn--faster'>
                <Icon name='playbackRewind' />
                </animated.button>              
            }
          </Spring> 

      </div>
      </div>
         
		)
	}
}

export default RecordingPlaybackModalMediaPlaybackSpeed
