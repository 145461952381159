const languageObjects = [
    {label: 'ABAP', language: 'abap'},
    {label: 'Agda', language: 'agda'},
    {label: 'Arduino', language: 'arduino'},
    {label: 'Assembly', language: 'assembly'},
    {label: 'Bash', language: 'bash'},
    {label: 'BASIC', language: 'basic'},
    {label: 'BNF', language: 'bnf'},
    {label: 'C', language: 'c'},
    {label: 'C#', language: 'c#'},
    {label: 'C++', language: 'c++'},
    {label: 'Clojure', language: 'clojure'},
    {label: 'CoffeeScript', language: 'coffeescript'},
    {label: 'Coq', language: 'coq'},
    {label: 'CSS', language: 'css'},
    {label: 'Dart', language: 'dart'},
    {label: 'Dhall', language: 'dhall'},
    {label: 'Diff', language: 'diff'},
    {label: 'Docker', language: 'docker'},
    {label: 'EBNF', language: 'ebnf'},
    {label: 'Elixir', language: 'elixir'},
    {label: 'Elm', language: 'elm'},
    {label: 'Erlang', language: 'erlang'},
    {label: 'F#', language: 'f#'},
    {label: 'Flow', language: 'flow'},
    {label: 'Fortran', language: 'fortran'},
    {label: 'Gherkin', language: 'gherkin'},
    {label: 'GLSL', language: 'glsl'},
    {label: 'Go', language: 'go'},
    {label: 'GraphQL', language: 'graphql'},
    {label: 'Groovy', language: 'groovy'},
    {label: 'Haskell', language: 'haskell'},
    {label: 'HTML', language: 'html'},
    {label: 'Idris', language: 'idris'},
    {label: 'Java', language: 'java'},
    {label: 'JavaScript', language: 'javascript'},
    {label: 'JSON', language: 'json'},
    {label: 'Julia', language: 'julia'},
    {label: 'Kotlin', language: 'kotlin'},
    {label: 'LaTeX', language: 'latex'},
    {label: 'Less', language: 'less'},
    {label: 'Lisp', language: 'lisp'},
    {label: 'LiveScript', language: 'livescript'},
    {label: 'LLVM IR', language: 'llvm ir'},
    {label: 'Lua', language: 'lua'},
    {label: 'Makefile', language: 'makefile'},
    {label: 'Markdown', language: 'markdown'},
    {label: 'Markup', language: 'markup'},
    {label: 'MATLAB', language: 'matlab'},
    {label: 'Mathematica', language: 'mathematica'},
    {label: 'Mermaid', language: 'mermaid'},
    {label: 'Nix', language: 'nix'},
    {label: 'Objective-C', language: 'objective-c'},
    {label: 'OCaml', language: 'ocaml'},
    {label: 'Pascal', language: 'pascal'},
    {label: 'Perl', language: 'perl'},
    {label: 'PHP', language: 'php'},
    {label: 'Plain Text', language: 'plain text'},
    {label: 'PowerShell', language: 'powershell'},
    {label: 'Prolog', language: 'prolog'},
    {label: 'Protobuf', language: 'protobuf'},
    {label: 'PureScript', language: 'purescript'},
    {label: 'Python', language: 'python'},
    {label: 'R', language: 'r'},
    {label: 'Racket', language: 'racket'},
    {label: 'Reason', language: 'reason'},
    {label: 'Ruby', language: 'ruby'},
    {label: 'Rust', language: 'rust'},
    {label: 'Sass', language: 'sass'},
    {label: 'Scala', language: 'scala'},
    {label: 'Scheme', language: 'scheme'},
    {label: 'Scss', language: 'scss'},
    {label: 'Shell', language: 'shell'},
    {label: 'Solidity', language: 'solidity'},
    {label: 'SQL', language: 'sql'},
    {label: 'Swift', language: 'swift'},
    {label: 'TOML', language: 'toml'},
    {label: 'TypeScript', language: 'typescript'},
    {label: 'VB.Net', language: 'vb.net'},
    {label: 'Verilog', language: 'verilog'},
    {label: 'VHDL', language: 'vhdl'},
    {label: 'Visual Basic', language: 'visual basic'},
    {label: 'WebAssembly', language: 'webassembly'},
    {label: 'XML', language: 'xml'},
    {label: 'YAML', language: 'yaml'},
];
export default languageObjects;