import {fetch,put,post} from '../api'
import {bumpFigmaEmbedVersion} from '../prosemirror/utils/bumpFigmaEmbedVersion'
import { Mixpanel } from '../Mixpanel'
import filter from 'lodash/filter'

//const code='"eyJhbGciOiJSUzI1NiIsImtpZCI6IjYwODNkZDU5ODE2NzNmNjYxZmRlOWRhZTY0NmI2ZjAzODBhMDE0NWMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2ODU2MTU5MjcsImF1ZCI6IjUyNjI1OTY0NDE0OC0yaGhkZjV0ajEzM2RyazlrNzl1NHN2aXFtYmIxOWhzZi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwODU3ODcxNjgwMjU0NDA1MDAwNCIsImhkIjoieWFybi5zbyIsImVtYWlsIjoibmljb2xlQHlhcm4uc28iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYXpwIjoiNTI2MjU5NjQ0MTQ4LTJoaGRmNXRqMTMzZHJrOWs3OXU0c3ZpcW1iYjE5aHNmLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwibmFtZSI6Ik5pY29sZSBBdGFjayIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRkUlc5T1VvWWQtY3l4aTBHZVRwb3d4Um5pbVVGMUlYVkliclBSRz1zOTYtYyIsImdpdmVuX25hbWUiOiJOaWNvbGUiLCJmYW1pbHlfbmFtZSI6IkF0YWNrIiwiaWF0IjoxNjg1NjE2MjI3LCJleHAiOjE2ODU2MTk4MjcsImp0aSI6IjNiMmYxMTM0Nzc5MGU3YzA0MmY4N2E3M2E2YzZiMmM5ZWFmNThlOGEifQ.xF3ZmWj7g74znPvulL4USSb2Yzee362a2JEUE9qAkVqz0tr8JSe2bts-I1XxkdoSMnD49SQwGUPoVEo8Azskl_-S2C3dUd8tUp1VUlFQGZSIWvWRKr4H241yn22xS-HK5JHRBBc9b30ivymNsWcRp2o22rXsjkyefh8i4IC8VOJ0dgu7n143hKLgX9qGK4ZIf6Po6mzHy_fiOIkrNxRr4iVGCLyJfG3yl_oCViZlPL049M3GfZegUHoszcuCjsNW27B7qRs2S6doG1aPKYyjLKS17ZMNibUmwbcEEietMcvg5clapXhyoWxlKl4oloa-_c6btx9g_KZnVPo9bgpB0Q"'
export function exchangeGoogleCode(code) {
	let url = `/google/callback?code=${code}`
	return (dispatch) => {
		return fetch(url)
		.then((response) => {
			dispatch({ type: 'FETCH_GOOGLE_INTEGRATION_SUCCESS', response })
			dispatch(fetchGoogleCalEvents())
			return response
		})
		.catch((error) => {
			
		})
	}
}

export function removeGoogleIntegration() {
	let url = `/google-integration/archive`
	return (dispatch) => {
		return put(url)
		.then((response) => {
			dispatch({ type: 'REMOVE_GOOGLE_INTEGRATION_SUCCESS' })
			
		})
		.catch((error) => {
			//console.log('remove integration error')
			console.log(error)
		})
	}
}



export function fetchGoogleIntegration() {
	let url = `/google-integration`
	return (dispatch) => {
		return fetch(url)
		.then((response) => {
			dispatch({ type: 'FETCH_GOOGLE_INTEGRATION_SUCCESS', response })	

		})
		.catch((error) => {
			console.log(error)
		})
	}
}



export function fetchGoogleCalEvents() {
	return (dispatch) => {
		return fetch(`/google-cal/events`)
		.then((response) => {			
			dispatch({ type: 'FETCH_CALENDAR_EVENTS_SUCCESS', response })	
			const eventCount=response.length 
			const recurringEvents=filter(response,'recurringEventId')
			if(eventCount){	
				const recurringCount=recurringEvents.length
				Mixpanel.track('fetch_gcal_events',{count:eventCount,recurringCount:recurringCount})
			}				
		})
		.catch((error) => {
			console.log(error)
		})
	}
}
