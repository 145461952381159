import sortBy from 'lodash/sortBy'
import store from '../../../../../store'
import MiniSearch from 'minisearch'
import filter from 'lodash/filter'


//empty state show users
//when type show users, issues, projects and docs

//TODO extract common minisearch into a shared thing

let minisearchDocs = new MiniSearch({
	fields: ['name'],
	storeFields: ['name', 'project','team', 'doc_html', 'is_project_doc', 'doc_id','is_org_doc','roadmap'],
	idField: 'doc_id',
})

let minisearchIssues = new MiniSearch({
	fields: ['title','identifier'],
	storeFields: ['issue_id','title','priority','identifier','project','description','assignee'],
	idField: 'issue_id',
})

let minisearchProjects = new MiniSearch({
	fields: ['name'],
	storeFields: ['project_id','name', 'lead','description','icon','color','members','teams'],
	idField: 'project_id',
})

let minisearchUsers = new MiniSearch({
	fields: ['display_name'],
	storeFields: ['user_id','name', 'display_name','avatar'],
	idField: 'user_id',
})
 


function filterSuggestions(searchTerm,isToDo,assignees){
	const state = store.getState()
	const users=state.orgMembers
	let results

	if(minisearchDocs._documentCount == 0){
		// const docs=filter(state.docs,{is_project_doc:false})

   	const docs = filter(state.docs,function(doc) {
   		if(doc.is_project_doc==false){
   			if(doc.project || doc.team || doc.is_org_doc || doc.roadmap){
   				return doc
   			}
   		}
 		});

		minisearchDocs.addAll(docs)
	}
	if(minisearchIssues._documentCount == 0){
		const issues=state.issues
		minisearchIssues.addAll(issues)
	}
	if(minisearchProjects._documentCount == 0){
		const projects=state.projects
		minisearchProjects.addAll(projects)
	}
	if(minisearchUsers._documentCount == 0){
		minisearchUsers.addAll(users)
	}


	if(!searchTerm){ //users
		const sortedUsers = sortBy(users, function(user){
  		return user.display_name.toString().toLowerCase()
  	})
  	results=sortedUsers
	}else{
		const docResults=minisearchDocs.search(searchTerm, {prefix:true, fuzzy:0.01})
		const issueResults=minisearchIssues.search(searchTerm, {prefix:true, fuzzy:0.01})
		const projectResults=minisearchProjects.search(searchTerm, {prefix:true, fuzzy:0.1})
		const userResults=minisearchUsers.search(searchTerm, {prefix:true, fuzzy:0.1,boost:{display_name:100}})
		let combinedResults=docResults.concat(issueResults,projectResults,userResults)
		const sorted=sortBy(combinedResults,'score').reverse()
		results=sorted
	}
	
	return results.slice(0,10)
}

export function getSuggestions(text,done){
	const filteredSuggestions=filterSuggestions(text)
	done(filteredSuggestions)
}
