import React from 'react'

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

class AccentColorSwatch extends React.Component{  	

	

	render(){	

		const {color, weight} = this.props

		const colorLabel = capitalizeFirstLetter(color)

	  return (
	  	
	  		<div style={{backgroundColor: `var(--accent${colorLabel}${weight})` }}className='accentColorSwatch'>
	  			{color}: {weight}
	  		</div>
			
	  )
	}
}

export default AccentColorSwatch
