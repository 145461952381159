import {getProjectForId} from '../getProjectForId'
import {updateProject} from './updateProject'


export function updateProjectStatus(projectId,status){
	const oldProject=getProjectForId(projectId)
	let newProject={...oldProject}
	newProject.state=status
	updateProject(newProject)
	//store.dispatch(updateProjectStatusRequest(projectId,status))

}
