
const LinearIssueMentionNodeSpec ={
    attrs: {
      issueId:{default:''},
      showAssignee:{default:true},
      showTitle:{default:false},
      showIdentifier:{default:true},
      showStatus:{default:false},
      showPriority:{default:false},
      layout:{default:'block'}, //layout can be block or inline
      issueUpdateVersion:{default:0} //to bu
    },
    inline: true,
    group: "inline",
    draggable: true,
    toDOM(node) {
      const div = document.createElement('div');
      div.className = 'message-para-mention'
      div.innerHTML=`${node.attrs.text}`

      return div;
   },
   parseDOM: [
      {
        tag: "linearIssueMention",
        getAttrs: (dom) => ({
          issueId: dom.getAttribute("data-issue-id"),
          layout: dom.getAttribute("data-layout"),
          showAssignee:dom.getAttribute("data-show-assignee")=="true"?true:false,
          showTitle:dom.getAttribute("data-show-title")=="true"?true:false,
          showIdentifier:dom.getAttribute("data-show-identifier")=="true"?true:false,
          showPriority:dom.getAttribute("data-show-priority")=="true"?true:false,
          showStatus:dom.getAttribute("data-show-status")=="true"?true:false
        })}
    ],
    toDOM(node) {
      const {issueId,layout,showAssignee,showIdentifier,showTitle,showPriority,showStatus} = node.attrs;
      return ["linearIssueMention",
      {
        "data-issue-id":issueId,
        "data-layout":layout,
        "data-show-assignee":showAssignee,
        "data-show-title":showTitle,
        "data-show-assignee":showAssignee,
        "data-show-identifier":showIdentifier,
        "data-show-priority":showPriority,
        "data-show-status":showStatus

    }]
    }
}
    
   

  export default LinearIssueMentionNodeSpec


