import React from 'react'
import {DocFigmaEmbedZoomedModalCropboxCornerHandle, DocFigmaEmbedZoomedModalCropboxLeftRightEdgeHandle, DocFigmaEmbedZoomedModalCropboxTopBottomEdgeHandle} from './DocFigmaEmbedZoomedModalCropHandles'
import { Rnd } from "react-rnd"
import { Spring, animated, config } from 'react-spring'

const MINIMUM_CROP_SIDE = 60 // in points
const CROP_MODE_OFFSET = 24 // in pixels
const CROP_SNAP_MARGIN = 12


class DocFigmaEmbedZoomedModalCanvas extends React.Component{  
 
  constructor(props) {
    super(props)        
    this.canvasAnimateOnRest=this.canvasAnimateOnRest.bind(this)
    this.cropboxAnimateOnRest=this.cropboxAnimateOnRest.bind(this)
    
    this.state = {    	    	
    	showCropHandlesCanvas: false,
      showCropHandlesCropbox: true,
    	// cropboxWidth: props.frameWidth,
     //  cropboxHeight: props.frameHeight,
     //  cropboxX: 0,
     //  cropboxY: 0
   	};
  }

  canvasAnimateOnRest() {	
		if(this.props.cropModeActive){
			this.setState({showCropHandlesCanvas: true})
		}else{
			this.setState({showCropHandlesCanvas: false})
		}
	}

	cropboxAnimateOnRest() {	
		if(this.props.cropModeActive){
			this.setState({showCropHandlesCropbox: true})
		}else{
			this.setState({showCropHandlesCropbox: false})
		}
	}


	render(){ 
		const {frameWidth, frameHeight, frameImageUrl, cropModeActive, canvasWidth, canvasHeight}=this.props
		const {cropboxWidth, cropboxHeight, cropboxX, cropboxY,updateCropValues,updateCropPosition} = this.props
		const {showCropHandlesCanvas, showCropHandlesCropbox} = this.state

		//
		// INITIAL CANVAS SETUP
		const canvasOuterPixelWidth = canvasWidth
		const canvasOuterPixelHeight = canvasHeight

		const cropModeOffsetPercentage = 1 - ((CROP_MODE_OFFSET * 2) / canvasOuterPixelWidth)

		let canvasPixelWidth = canvasOuterPixelWidth * cropModeOffsetPercentage
		let canvasPixelHeight = canvasOuterPixelHeight * cropModeOffsetPercentage

		let canvasScalar = 1/cropModeOffsetPercentage



		//
		// CALCULATE SOME ASPECT RATIOS
		const canvasAspect = canvasPixelWidth / canvasPixelHeight
		const frameAspect = frameWidth / frameHeight	
		const cropAspect = cropboxWidth / cropboxHeight

		//
		// FIND HOW FULL FRAME FITS INTO CANVAS 
		let fitFramePixelWidth
		let fitFramePixelHeight

		if(canvasAspect >= frameAspect){
			// canvas is wider than image, so fits height wise			
			fitFramePixelHeight = canvasPixelHeight
			fitFramePixelWidth = fitFramePixelHeight * frameAspect
		}

		if(canvasAspect < frameAspect){			
			// canvas is narrower than image, so fits width wise
			fitFramePixelWidth = canvasPixelWidth
			fitFramePixelHeight = fitFramePixelWidth / frameAspect
		}		
		
		const fitFramePixelLeft = (canvasPixelWidth - fitFramePixelWidth) / 2
		const fitFramePixelTop = (canvasPixelHeight - fitFramePixelHeight) / 2
		const fitFrameScale = fitFramePixelWidth / frameWidth

		// 
		// FIND HOW CROPBOX FITS		
		// we also need to calculate to image, so that we can position image inside of cropbox

		const scaledCropXtoImage = cropboxX * fitFrameScale
		const scaledCropYtoImage = cropboxY * fitFrameScale
		const scaledCropWidth = cropboxWidth * fitFrameScale
		const scaledCropHeight = cropboxHeight * fitFrameScale

		const scaledCropXToCanvas = scaledCropXtoImage + fitFramePixelLeft
		const scaledCropYToCanvas = scaledCropYtoImage + fitFramePixelTop

		const inverseImageLeftToCanvas = scaledCropXtoImage * -1
		const inverseImageTopToCanvas = scaledCropYtoImage * -1

		let fitCropToCanvasWidth
		let fitCropToCanvasHeight		

		if(canvasAspect >= cropAspect){
			// canvas is wider than image, so fits height wise
			fitCropToCanvasHeight = canvasPixelHeight
			fitCropToCanvasWidth = fitCropToCanvasHeight * cropAspect
		}

		if(canvasAspect < cropAspect){
			// canvas is narrower than image, so fits width wise
			fitCropToCanvasWidth = canvasPixelWidth
			fitCropToCanvasHeight = fitCropToCanvasWidth / cropAspect
		}

		const fitCropToCanvasX = (canvasPixelWidth - fitCropToCanvasWidth) / 2
		const fitCropToCanvasY = (canvasPixelHeight - fitCropToCanvasHeight) / 2

		//
		// CALCULATE FIT CROP SCALE, AND ROUND IT
		const fitCropScalarRaw = fitCropToCanvasWidth / scaledCropWidth		
		
		const roundingIndex = 100000
		const roundingIndexedFitCropScalar = Math.round(fitCropScalarRaw * roundingIndex)

		const fitCropScalar = roundingIndexedFitCropScalar / roundingIndex // roundingIndex // rounded
	  
	 	//
		// FOR THE ZOOMING, CLEARER TO RENAME VALUES

		const fitCropStartWidth = scaledCropWidth
		const fitCropEndWidth = fitCropToCanvasWidth

		const fitCropStartHeight = scaledCropHeight
		const fitCropEndHeight = fitCropToCanvasHeight

		const fitCropStartX = scaledCropXToCanvas
		const fitCropEndX = fitCropToCanvasX

		const fitCropStartY = scaledCropYToCanvas
		const fitCropEndY = fitCropToCanvasY
	  
	  // TRANSFORM ORIGIN (this effectively translates position of slide)
	  // translating has the downside of causing swings in animation
	  const fitCropTransformOriginX = (fitCropStartX - fitCropEndX) / (fitCropEndWidth - fitCropStartWidth)
	  const fitCropTransformOriginY = (fitCropStartY - fitCropEndY) / (fitCropEndHeight - fitCropStartHeight)

	  //
	  // IF CROPPING DOESN'T RESCALE, THEN WE NEED TO TRANSLATE TO CENTER (AS TRASNFORM ORIGIN WON'T WORK)
	  let fitCropTranslateX = 0
	  let fitCropTranslateY = 0

	  if(fitCropScalar === 1){
	  	// this means that cropping doesn't change size of image on canvas
	  	// which also means that transformOrigin won't woprk
	  	fitCropTranslateX = fitCropEndX - fitCropStartX
	  	fitCropTranslateY = fitCropEndY - fitCropStartY
	  }

	  //
	  // ONLY SHOW CROP HANDLES ONCE ANIMATIONS ARE OVER AND EVERYTHING'S SETTLED IN PLACE
	  const showCropHandles = cropModeActive && this.state.showCropHandlesCanvas && this.state.showCropHandlesCropbox
	  const disableAnimations = showCropHandles || this.props.isChangingThreadPanelState || this.props.justOpenedModal // don't animate when e.g. resizing window	  
	  
	  //
	  // SETUP ANIMATION CONFIG AND DISABLED ANIMATIONS
	  let animationConfig = {
	  	tension: 480,
      friction: 45,
	  }	  

	  if(disableAnimations){
	  	animationConfig = {
	  		duration: 0
	  	}	  	
	  }

		return (
													
	  		<div className='doc-zoomedFigmaModal-canvasInnerContainer'>	  
					<div style={{width: `${canvasPixelWidth}px`, height: `${canvasPixelHeight}px`, ...canvasStyles}} className='doc-zoomedFigmaModal-canvas'>  																  	
						<div style={{top: `${scaledCropYToCanvas}px`, left: `${scaledCropXToCanvas}px`, width: `${scaledCropWidth}px`, height: `${scaledCropHeight}px`}} className='doc-zoomedFigmaModal-cropContainer'>

	  				
						  			
	  					<div style={{top: `${inverseImageTopToCanvas}px`, left: `${inverseImageLeftToCanvas}px`, width: `${fitFramePixelWidth}px`, height: `${fitFramePixelHeight}px`}} className='doc-zoomedFigmaModal-croppedImageContainer'>	  							  						
	  						<img src={frameImageUrl} className='doc-zoomedFigmaModal-image' />	  							  						
	  					</div>	 
	  					
	  					<div className={'doc-zoomedFigmaModal-cropOutline ' + (showCropHandles ? 'doc-zoomedFigmaModal-cropOutline--show' : 'doc-zoomedFigmaModal-cropOutline--hide' )}/> 

						</div>					  			
				  </div>
	  			
	  			<div style={{width: `${fitFramePixelWidth}px`, height: `${fitFramePixelHeight}px`}} className={'doc-zoomedFigmaModal-canvas-cropUIContainer ' + (showCropHandles ? 'doc-zoomedFigmaModal-canvas-cropUIContainer--show' : 'doc-zoomedFigmaModal-canvas-cropUIContainer--hide')}>
		  			<Rnd
						  className='doc-zoomedFigmaModal-canvas-cropUI'
						  size={{ 
						  	width: cropboxWidth * fitFrameScale,
						  	height: cropboxHeight * fitFrameScale
						  }}
						  position={{
						  	x: cropboxX * fitFrameScale,
						  	y: cropboxY * fitFrameScale
						  }}
						  onDrag={(e, d) => { 						  	
						  	updateCropPosition({
						  		cropboxX: d.x/fitFrameScale,
						  		cropboxY: d.y/fitFrameScale
						  	}) 
						 	}}
						  onDragStop={(e, d) => { 
						  	// not sure how to do move-box snapping, looks like delta based
						  	updateCropPosition({
						  		cropboxX: d.x/fitFrameScale,
						  		cropboxY: d.y/fitFrameScale
						  	}) 
						 	}}
						 	onResize={(e, direction, ref, delta, position) => {
	              updateCropValues({
	                cropboxWidth: ref.offsetWidth/fitFrameScale,
	                cropboxHeight: ref.offsetHeight/fitFrameScale,
	                cropboxX: position.x/fitFrameScale, 
	                cropboxY: position.y/fitFrameScale
	              })              
            	}}
						  onResizeStop={(e, direction, ref, delta, position) => {
						    let snappedWidth = ref.offsetWidth/fitFrameScale
                let snappedHeight = ref.offsetHeight/fitFrameScale
                let snappedX=position.x/fitFrameScale
                let snappedY=position.y/fitFrameScale

                // general full width/height snapping
                if((frameWidth - snappedWidth) < CROP_SNAP_MARGIN){
                	snappedWidth = frameWidth
                	snappedX = 0
                }
                if((frameHeight - snappedHeight) < CROP_SNAP_MARGIN){
                	snappedHeight = frameHeight
                	snappedY = 0
                }

                // far-edge snapping
                if((frameWidth - snappedX - snappedWidth) < CROP_SNAP_MARGIN){
                	snappedWidth = frameWidth - snappedX
                }

                if((frameHeight - snappedY - snappedHeight) < CROP_SNAP_MARGIN){
                	snappedHeight = frameHeight - snappedY
                }

                // near-edge snapping
                if(snappedX < CROP_SNAP_MARGIN){
                	snappedWidth += snappedX // need to add on the difference before setting to zero
                	snappedX = 0
                }
                if(snappedY < CROP_SNAP_MARGIN){
                	snappedHeight += snappedY
                	snappedY = 0
                }

						    updateCropValues({
						      cropboxWidth: snappedWidth,
						      cropboxHeight: snappedHeight,
						      cropboxX: snappedX, 
                	cropboxY: snappedY
						    });
						  }}				
		        	minWidth={MINIMUM_CROP_SIDE * fitFrameScale}
		        	minHeight={MINIMUM_CROP_SIDE * fitFrameScale}
		        	bounds='.doc-zoomedFigmaModal-canvas-cropUIContainer'				        	
		        	resizeHandleComponent={{ 
		        		left: <DocFigmaEmbedZoomedModalCropboxLeftRightEdgeHandle />,
		        		right: <DocFigmaEmbedZoomedModalCropboxLeftRightEdgeHandle />,
		        		topLeft: <DocFigmaEmbedZoomedModalCropboxCornerHandle />,
		        		top: <DocFigmaEmbedZoomedModalCropboxTopBottomEdgeHandle />,
		        		topRight: <DocFigmaEmbedZoomedModalCropboxCornerHandle />,
		        		bottom: <DocFigmaEmbedZoomedModalCropboxTopBottomEdgeHandle />,
		        		bottomLeft: <DocFigmaEmbedZoomedModalCropboxCornerHandle />,
		        		bottomRight: <DocFigmaEmbedZoomedModalCropboxCornerHandle />,			        		
		        	}}
						>
						</Rnd>
		  		</div>		  
		  		

	  		</div>



      
		)
	}
}

export default DocFigmaEmbedZoomedModalCanvas
