import React from 'react'
import Icon from '../../misc/Icon'
import VideoDuration from '../../../utils/videoDuration'
import SpritesheetImage from '../../misc/SpritesheetImage'
import ReactSlider from 'react-slider'
import {fetchStoryboardImageJSON} from '../../../actions/video'

class DocVideoZoomedUISeekBar extends React.Component{  
	 constructor(){
    super()
    this.calculateSpritesheetOffset=this.calculateSpritesheetOffset.bind(this)
    this.state={
      json:null
    }
  }

  componentDidMount(){
  	fetchStoryboardImageJSON(this.props.muxPlaybackID).then((response)=>{
  		this.setState({json:response})
  	})
  }


  calculateSpritesheetOffset(duration){
  	let x=0
  	let y=0
  	const {json}=this.state
  	let foundTile=false
  	if(json && json.tiles){
  		json.tiles.forEach((tile)=>{
  			if(tile.start>duration && !foundTile){
  				x=tile.x 
  				y=tile.y 
  				foundTile=true
  			}
  		})
  	}
  	return {x:x,y:y}
  }



  
	render(){ 
		const {playing, played, progressSeconds, videoTitle, handleZoomAndPlay, handleZoom, mouseMoved, duration, handleSeekMouseUp, handleSeekMouseDown, handleSeekChange, handlePlayPause, captionsActive, toggleCaptions} = this.props				

		const {		    
	    elementDimensions: {
	      width = 0,
	      height = 0
	    } = {},
	    isActive = false,
	    isPositionOutside = false,
	    position: {
	      x = 0,
	      y = 0
	    } = {}
	  } = this.props


	  let storyboardTileWidth = 256
	  let storyboardTileHeight = 160		 
	  if(this.state.json){
	  	storyboardTileWidth = this.state.json.tile_width
	  	storyboardTileHeight = this.state.json.tile_height	
	  }

	  const storyboardTileAspect = storyboardTileWidth / storyboardTileHeight
	 	const storyboardImageURL = `https://image.mux.com/${this.props.muxPlaybackID}/storyboard.png`

	  const widthHoverPreview = 160
	  const heightHoverPreview = widthHoverPreview / storyboardTileAspect

	  const storyboardOffsetX = 0
	  const storyboardOffsetY = 200

	  const minXHoverPreview = 5 // don't let preview overflow left edge
	  const maxXHoverPreview = width - widthHoverPreview - 5  // don't let preview overflow right edge		  
	  const standardXHoverPreview = x - (0.5 * widthHoverPreview)

	  const hoverPreviewPosition = Math.max(minXHoverPreview, Math.min(maxXHoverPreview, standardXHoverPreview));

	  const percentHoverX = x / width
	  const durationHoverX = percentHoverX * duration

	  let spriteSheetOffset={x:0,y:0}
	  if(!isPositionOutside){
			spriteSheetOffset=this.calculateSpritesheetOffset(durationHoverX)
		}
		
		return (	
				
			<div className='doc-video-zoomedUI-controlBar-seekBarInnerContainer'>
				<ReactSlider
          className="doc-video-zoomedUI-controlBar-seekBar"
          thumbClassName="doc-video-zoomedUI-controlBar-seekBar-thumb"
          trackClassName="doc-video-zoomedUI-controlBar-seekBar-track"                  
          renderThumb={(props, state) => <div className='doc-video-zoomedUI-controlBar-seekBar-thumb-thumb' {...props} />}

          value={played}
          min={0}
          max={0.999999}
          step={0.0001}
          onAfterChange={handleSeekMouseUp}
          onBeforeChange={handleSeekMouseDown}
          onChange={handleSeekChange}
        />              

				{!isPositionOutside &&
					<div style={{width: `${widthHoverPreview}px`, height: `${heightHoverPreview}px`, top: `${(heightHoverPreview * -1) + 5}px`,  left: `${hoverPreviewPosition}px`}} className='doc-video-zoomedUI-controlBar-seekBar-hoverPreview'>
						<SpritesheetImage 
							tileWidth={storyboardTileWidth}
							tileHeight={storyboardTileHeight}
							tileAspect={storyboardTileAspect}
							outputImageWidth={widthHoverPreview}
							outputImageHeight={heightHoverPreview}
							offsetX={spriteSheetOffset.x}
							offsetY={spriteSheetOffset.y}
							spriteSheetImageUrl={storyboardImageURL}
						/>

						<div className='doc-video-zoomedUI-controlBar-seekBar-hoverPreview-timestampContainer'>							
							<div className='doc-video-zoomedUI-controlBar-seekBar-hoverPreview-timestamp'>
								<VideoDuration seconds={durationHoverX} />								
							</div>
						</div>
					</div>
				}

				{!isPositionOutside &&
					<div style={{left: `${x}px`}} className='doc-video-zoomedUI-controlBar-seekBar-hoverMarker' />					
				}

			</div>
			
							
		)
	}
}

export default DocVideoZoomedUISeekBar
