import React, { Component } from 'react'
import Icon from '../../components/misc/Icon'
import * as ContextMenu from '@radix-ui/react-context-menu';
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'

class SideMenuRecentTab extends React.Component{  


	constructor(props){
		super(props)   		
		this.state={      
			showPopover: false
		}
	}

	render(){	
		const {showPopover} = this.state	
		let active = showPopover
	  return (

	  	<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>						
						<div onClick={()=>{this.setState({showPopover: !showPopover})}} className={'sideMenu-tab sideMenu-tab--tabType--appTab sideMenu-tab--tabType--recent ' + (active ? ' sideMenu-tab--active ' : ' sideMenu-tab--inactive ')}>
							
							<div className='sideMenu-tab-iconContainer'>		
								<div className='sideMenu-tab-iconInnerContainer'>
									<Icon name='clockRegular' />
									<div className='sideMenu-tab-iconInnerContainer-bg' />				
								</div>		
							</div>


							<div className='sideMenu-tab-labelContainer'>
								<div className='sideMenu-tab-label'>
									Updates
								</div>
							</div>

						</div>		
					</Popover.Trigger>					
					<Popover.Portal>
						<Popover.Content
							className={'sideMenu-recentPopoverContainer ' + (showPopover ? ' recentPopoverContainer--visible ' : ' recentPopoverContainer--hidden ')} 
							onEscapeKeyDown={()=>{this.setState({showPopover: false})}}
							onPointerDownOutside={()=>{this.setState({showPopover: false})}}
							onInteractOutside={()=>{this.setState({showPopover: false})}}
							//onInteractOutside={closePopover}
							forceMount				
							side="right"
							sideOffset={-10}
							align="start"
							alignOffset={-45}
							// sideOffset={popoverSideOffset}
							// align={popoverAlign}
							// alignOffset={popoverAlignOffset}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateX(-5px)` }}
								enter={{ opacity: 1, transform: 'translateY(0px)'  }}
								leave={{ opacity: 0, transform: `translateX(-5px)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 35}}
								// onRest={() =>
								// 	this.setState({
								// 		showPopover: !showPopover,
								// 	})
								// }
								>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="sideMenu-recentPopover popover"> 	
											Popover
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			
	  )
	}
}





export default SideMenuRecentTab

