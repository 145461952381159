import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import Icon from '../../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import {addMeetingToGCal} from '../../../../utils/meetings/addMeetingToGCal'
	


class DocEditorMeetingInfoAddToCalendarPopover extends React.Component{  

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)	
		this.addMeetingToGCal=this.addMeetingToGCal.bind(this)	
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  addMeetingToGCal(){
  	addMeetingToGCal(this.props.meeting)
  	this.props.closePopover()
  }

	render(){	
		const {showPopover, openPopover, closePopover,meeting} = this.props
		const {forceMount} = this.state
		const translateY = -8
		const popoverSide = "bottom"
		const popoverSideOffset= -38
		const popoverAlign = "start"				
		const popoverAlignOffset= -6

	  return (
	  	<React.Fragment>
		  	<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>						
						{this.props.children}
					</Popover.Trigger>					
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'addToCalendarPopoverContainer ' + (showPopover ? ' addToCalendarPopoverContainer--visible ' : ' addToCalendarPopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							onInteractOutside={closePopover}						
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `scale(0.9)` }}
								enter={{ opacity: 1, transform: 'scale(1)'  }}
								leave={{ opacity: 0, transform: `scale(0.9)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 30}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="addToCalendarPopover popover"> 	
											<div onClick={this.addMeetingToGCal} className='popoverMenu-item popoverMenu-item--addToCalendar'>												
												<div className='popoverMenu-item-label'>
													Send invite emails
												</div>
												<Tooltip.Provider>
													<Tooltip.Root delayDuration={0}>
														<Tooltip.Trigger asChild>																	
															<div className='popoverMenu-item--addToCalendar-iconContainer'>
																<Icon name='infoOutline' />
															</div>															
														</Tooltip.Trigger>				
														<Tooltip.Content side="right" className="tooltip tooltip--addToCalendarEmails">																																	
															Standard Google Calendar invitation emails will be sent to all participants.
														</Tooltip.Content>													
													</Tooltip.Root>
												</Tooltip.Provider>



												
											</div>
											{/*}
											<div className='popoverMenu-item popoverMenu-item--addToCalendar popoverMenu-item--addToCalendar--low'>
												
												<div className='popoverMenu-item-label'>
													Add to my calendar only
												</div>
											</div>
											*/}
											<div onClick={this.props.closePopover} className='popoverMenu-item popoverMenu-item--addToCalendar popoverMenu-item--addToCalendar--low'>
											
												<div className='popoverMenu-item-label'>
													Cancel
												</div>
											</div>
										 
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>
			</React.Fragment>
	  )
	}
}

export default DocEditorMeetingInfoAddToCalendarPopover
