import React from 'react'


class LinearPaletteItem extends React.Component {


	render(){  

		const {colorName} = this.props

		return (	  	

			<div className={'playgroundPage-linearPalette ' + (colorName ? `playgroundPage-linearPalette--color--${colorName}` : '')}>
				<div className='playgroundPage-linearPalette-title'>
					{colorName}
				</div>
				<div className='playgroundPage-linearPalette-swatchesContainer'>
					<div className='playgroundPage-linearPalette-swatch playgroundPage-linearPalette-swatch--linear' />				
					<div className='playgroundPage-linearPalette-swatchDash'>
						→
					</div>
					<div className='playgroundPage-linearPalette-swatch playgroundPage-linearPalette-swatch--yarn' />				
				</div>
			</div>   	
		)
	}
}

class LinearYarnPaletteMap extends React.Component{  	

	render(){	

		const {warp} = this.props

		

	  return (
	  	
	  	<div className='playground-linearYarnPaletteMap'>
	  		<LinearPaletteItem colorName='grey' />
	  		<LinearPaletteItem colorName='lightGrey' />
	  		<LinearPaletteItem colorName='darkGrey' />
	  		<LinearPaletteItem colorName='purple' />
	  		<LinearPaletteItem colorName='teal' />
	  		<LinearPaletteItem colorName='darkTeal' />
	  		<LinearPaletteItem colorName='green' />
	  		<LinearPaletteItem colorName='darkGreen' />
	  		<LinearPaletteItem colorName='yellow' />
	  		<LinearPaletteItem colorName='brown' />
	  		<LinearPaletteItem colorName='orange' />
	  		<LinearPaletteItem colorName='darkOrange' />
	  		<LinearPaletteItem colorName='pink' />
	  		<LinearPaletteItem colorName='red' />
	  		<LinearPaletteItem colorName='darkRed' />
      </div>
			
	  )
	}
}

export default LinearYarnPaletteMap
