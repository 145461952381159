import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

//add selected styling to nodes if contained in selection
export function mediaSelectionStylingPlugin() {
	return new Plugin({
		props: {
			decorations: ({ doc, plugins, selection }) => {
				let {from,to,empty} = selection
				let decorations=[]
				
				if(!empty){
          doc.nodesBetween(from, to, (node, pos) => {
            const nodeType = node.type;
            if (node.type.name==='docVideo') {
							const decoration = Decoration.node(pos, pos + node.nodeSize, {
								class: 'doc-videoWrapper--selected'
							})
							decorations.push(decoration)
							}
							else if (node.type.name==='docImage') {
								const decoration = Decoration.node(pos, pos + node.nodeSize, {
									class: 'doc-imageWrapper--selected'
							})
								decorations.push(decoration)
							}
							else if (node.type.name==='docTable') {
								if (pos >= from && pos + node.nodeSize <= to){
									const decoration = Decoration.node(pos, pos + node.nodeSize, {
									class: 'doc-tableContainer--selected'
									})
								decorations.push(decoration)
								}
								
							}

						})
          }
				return DecorationSet.create(doc, decorations)
			}
		}
	})
}