const EmojiNodeSpec = {
  attrs: {
    emojiString: {default: ''},
    emoji: {default: ''}
  },
  inline: true,
  group: "inline",
  draggable: false,
  selectable: false,
  parseDOM: [{
    tag: "span",
    getAttrs: dom => {

    let classname = dom.getAttribute("class")
      if(classname==='message-para-emoji'){
        return{
          emojiString: dom.getAttribute("data-emoji-string"),
          emoji: dom.getAttribute("data-emoji")
        }
        

        //return true
      }
      
    }
  }],
  toDOM(node) {
    
    const span = document.createElement('span');
    //span.className = 'message-para-emoji'
    span.className = 'message-para-emoji'
    span.setAttribute("role", "img");
    
    // If it's the only character in message, then...
    //span.classList.add("message-para-emoji--singleEmoji");    

    const {emojiString,emoji} = node.attrs;
   
    span.setAttribute('data-emoji',emoji)
    span.setAttribute('data-emoji-string',emojiString)
  
    span.innerHTML=node.attrs.emoji

    return span
  },
}
   

 export default EmojiNodeSpec


 // parseDOM: [
 //      {
 //        tag: "emoji",
 //        getAttrs: (dom) => ({
 //          emojiString: dom.getAttribute("data-emoji-string"),
 //        })}
 //    ],
 //    toDOM(node) {
 //      const {emojiString} = node.attrs;
 //      return ["emoji",
 //      {
 //        "data-emoji-string":emojiString,


 //    }]
 //    }