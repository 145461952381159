import React from 'react'
import StageIcon from '../../misc/StageIcon'
import Icon from '../../misc/Icon'
import DocBlockLinearIssue from './DocBlockLinearIssue'
import DocInlineLinearIssue from './DocInlineLinearIssue'
import {getIssueForId} from '../../../utils/getIssueForId'

class LinearIssueReactComponent extends React.Component{  
	
	render(){
		//const {issueTitle,issueStatus,issueID,issueAssignee, expanded}=this.props
		const {issueId,issueLayout}=this.props
		const issue=getIssueForId(issueId)


		let assignee
		let identifier
		let priority
		let workflowType
		let title
		let workflowColorHex
		let workflowStateName
		let workflowTypeStateIndex
		let workflowTypeStatesCount
		let description
		let projectId

		if(issue){
			title=issue.title
			assignee=issue.assignee
			identifier=issue.identifier
    	priority = issue.priority    	 
    	projectId = issue.project
    	if(issue.workflow_state){
    		workflowColorHex=issue.workflow_state.color
    		workflowType=issue.workflow_state.type
    		workflowStateName=issue.workflow_state.name
    		workflowTypeStateIndex=issue.workflow_state.workflowTypeStateIndex
    		workflowTypeStatesCount=issue.workflow_state.workflowTypeStatesCount
    	} 
    	description = issue.description
		}


		
    
	
		

		return (
				<React.Fragment>
					{ issue &&
						<DocInlineLinearIssue 
							assignee={assignee}							
							workflowType={workflowType}
							workflowColorHex={workflowColorHex}
							workflowTypeStateIndex={workflowTypeStateIndex}
							workflowTypeStatesCount={workflowTypeStatesCount}
							workflowStateName={workflowStateName}							
							title={title}
							identifier={identifier}
							priority={priority}
							description={description}
							projectId={projectId}
							showPopover={this.props.showPopover}
							openPopover={this.props.openPopover}
							closePopover={this.props.closePopover}
							issueId={this.props.issueId}
							issueLayout={this.props.layout}
							showTitle={this.props.showTitle}
							showIdentifier={this.props.showIdentifier}
							showStatus={this.props.showStatus}
							showPriority={this.props.showPriority}
							showAssignee={this.props.showAssignee}
							updateIssueAttribute={this.props.updateIssueAttribute}
							setAsDefaultMentionConfig={this.props.setAsDefaultMentionConfig}
							putCursorAfterIssue={this.props.putCursorAfterIssue}
						/>
					}
				</React.Fragment>
			)				
	}
}

export default LinearIssueReactComponent

{/*}

					{issueLayout === 'block' && 
						<DocBlockLinearIssue 
							assignee={assignee}							
							workflowType={workflowType}
							workflowColorHex={workflowColorHex}							
							workflowTypeStateIndex={workflowTypeStateIndex}
							workflowTypeStatesCount={workflowTypeStatesCount}
							title={title}
							identifier={identifier}
							priority={priority}

							expanded={this.props.expanded} // experimental modal
						/>
					}
		if(expanded){
			
			return (
				<div className="doc-para-linearIssueContainer">
				<input placeholder='title'/>
				<textarea placeholder='description'/>		
				{orgMembers.map((member)=>{
					return(
						<div>{member.name}</div>
						)
				})}
				
				<button onClick={()=>{this.setState({expanded:false})}}>collapse</button>
			</div>
			)
		}else{
			const assignedTo=orgMembers[0]
			return(
			<div className="doc-para-linearIssueContainer">
					{issueStatus !== "done" &&
						<div className="doc-para-checkbox doc-para-checkbox--state--off"/>
					}
					{issueStatus === "done" &&
						<div className="doc-para-checkbox doc-para-checkbox--state--on">
							<Icon name='checkmarkBlack' />
						</div>
					}
					<div className={"doc-para-linearIssue " + (issueStatus ? ` doc-para-linearIssue--status--${issueStatus} ` : '')}>
				{issueStatus === "inProgress" &&
					<div className='doc-para-linearIssue-status doc-para-linearIssue-status--inProgress'>
						<StageIcon stage='draft-alt' />
					</div>
				}
				{issueStatus === "backlog" &&
					<div className='doc-para-linearIssue-status doc-para-linearIssue-status--backlog'>
						<div className='doc-para-linearIssue-status--backlog-circle' />
					</div>
				}
				<div className="doc-para-linearIssue-title">
					{issueTitle}
				</div>
				<div className="doc-para-linearIssue-id">
				{issueID}
				</div>
			</div>
			assignedTo:{assignedTo?assignedTo.name:''}
			<button onClick={()=>{this.setState({expanded:true})}}>expand</button>
			</div>
			*/}