import React from 'react'
import {getUserForId} from '../../../../utils/getUserForId'
import Icon from '../../../../components/misc/Icon'

class HighlightFlagReactComponent extends React.Component{  	


	render(){	

		const {highlight}=this.props
		const {created_by}=highlight 
		const userObj=getUserForId(created_by)
		let userName 
		if(userObj){
			userName=userObj.display_name
		}
	
	  return (
	  	<React.Fragment>
	  		<span className='message-para-highlightStartMarker'>
	  			<span className='message-para-highlightStartMarker-stem'/>
	  			<span className='message-para-highlightStartMarker-flag'/>	  		
	  		</span>
			</React.Fragment>	  	
	  )
	}
}

export default HighlightFlagReactComponent