import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_ALL_MESSAGES_SUCCESS':
	 	return action.response

	case 'CREATE_MESSAGE_REQUEST':
		let newMessage=action.message

		let mIndex=findIndex(state,(message => {
			return newMessage['message_id'] === message['message_id']
		}))
		if(mIndex === -1){
			mIndex=state.length
		}

		// const {type,plain_text,html,source,media_url}=content
		// newMessage.type=type
		// newMessage.plain_text=plain_text
		// newMessage.html=html
		// newMessage.source=source
		// newMessage.media_url=media_url || null
			return [
			...state.slice(0,mIndex),
			newMessage,
			...state.slice(mIndex + 1)
			]

	case 'CREATE_MESSAGE_SUCCESS':
		const createdMessageId=action.response['message_id']
		let index=findIndex(state,(message => {
			return message['message_id'] === createdMessageId
		}))
		if(index === -1){
			index=state.length
		}
		return [
			...state.slice(0,index),
			action.response,
			...state.slice(index + 1)
			]

		case 'FETCH_A_MESSAGES_SUCCESS':
			const fetchedMessageId=action.response['message_id']
			let fetchedIndex=findIndex(state,(message => {
				return message['message_id'] === fetchedMessageId
			}))
			if(fetchedIndex != -1){
				return [
					...state.slice(0,fetchedIndex),
					action.response,
					...state.slice(fetchedIndex + 1)
				]
			}else return [...state,action.response]
	
		case 'DELETE_MESSAGE_REQUEST':
			const deleteRequestMessageId=action.messageId
			let deletedRequestIndex=findIndex(state,(message => {
				return message['message_id'] === deleteRequestMessageId
			}))
			if(deletedRequestIndex === -1){
				return state
			}else{
				return [
					...state.slice(0,deletedRequestIndex),
					...state.slice(deletedRequestIndex + 1)
				]
			}

		case 'DELETE_MESSAGE_SUCCESS':
			const deletedMessageId=action.messageId
			let deletedIndex=findIndex(state,(message => {
				return message['message_id'] === deletedMessageId
			}))
			if(deletedIndex === -1){
				return state
			}else{
				return [
					...state.slice(0,deletedIndex),
					...state.slice(deletedIndex + 1)
				]
			}

	case 'LOGOUT':
		return []
	
	default:
		return state
	
	}
}
