import store from '../store'
import find from 'lodash/find'



export function getMessageForId(messageId) {	
	let message={}
	const state = store.getState()
	const messageInState= find(state.messages, {'message_id':messageId})
	if(messageInState){
		message=messageInState
	}
	return message
}

