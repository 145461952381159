import store from '../../store'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'


//const {completedIssues,totalIssues,activeMilestone,completedMilestone} =
	//for active milestone
	//completed issues
	//total issues
	//isActive
	//isCompleted
	
function calcScope(issues){
	const issuesExcluCanceled=filter(issues,function(iss){
		if(iss.workflow_state){
			return iss.workflow_state.type !=="canceled"
		}
	})
	let scope=issuesExcluCanceled.length
	return scope
}
	
function calcCompletedCount(issues){
	const completedIssues=filter(issues,function(iss){
		if(iss.workflow_state){
			return iss.workflow_state.type =="completed"
		}
	})
	return completedIssues.length
}

function calcInProgressCount(issues){
	const inProgressIssues=filter(issues,function(iss){
		if(iss.workflow_state){
			return iss.workflow_state.type =="started"
		}
	})
	return inProgressIssues.length
}


export function recalcProjectMilestones() {	
	const state = store.getState()
	const projects=state.projects 
	let calcedMilestones=[]

	projects.forEach((project)=>{
		const projectMilestones=filter(state.milestones,{project:project.project_id}) 
		const sorted=sortBy(projectMilestones, function(milestone){
			return parseInt(milestone.sort_order,10)
		})

		let foundActiveMilestone=false
		sorted.forEach((milestone,i)=>{////for each milestone calc the stats
			const issues=filter(state.issues,{milestone:milestone.milestone_id})
			let issuesWithWorkflowState=[]
			issues.forEach((issue)=>{
				const workflow_state=find(state.workflowStates,{state_id:issue.workflow_state})
				const issueWithState= {...issue,workflow_state:workflow_state}
				issuesWithWorkflowState.push(issueWithState)
			})

			let isComplete=false
			let isActiveMilestone=false
			const scope=calcScope(issuesWithWorkflowState)

			const completedCount=calcCompletedCount(issuesWithWorkflowState)
			const inProgressCount=calcInProgressCount(issuesWithWorkflowState)
			let progressPercentage=0
			if(scope){
				progressPercentage=100*(completedCount + (0.25*inProgressCount))/scope
			}
			if(progressPercentage==100){
				isComplete=true
			}
			if(i==0 && !isComplete){

				isActiveMilestone=true
				foundActiveMilestone=true
			}
			if(foundActiveMilestone==false && !isComplete){

				isActiveMilestone=true
				foundActiveMilestone=true
			}
			const modifiedMilestone=	{...milestone,
					isComplete:isComplete,
					isActiveMilestone:isActiveMilestone,
					scope:scope,
					completedCount:completedCount,
					inProgressCount:inProgressCount,
					progressPercentage:progressPercentage
				}
			calcedMilestones.push(modifiedMilestone)
			isActiveMilestone=false
		})	
	})
	store.dispatch({type:'UPDATE_CALCED_MILESTONES',calcedMilestones:calcedMilestones})
}




