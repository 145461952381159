import React from 'react'
import MessageInsertMentionsMenuItem from './MessageInsertMentionsMenuItem'

function createMarkup(input) {
  return {__html: input}
}

const activeClass='suggestionMenu-item--active'

class MessageInsertMentionsMenuReactComponent extends React.Component{  

  constructor(){
    super()
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.handleMouseOver=this.handleMouseOver.bind(this)
    this.handleMouseOut=this.handleMouseOut.bind(this)
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.state = {
      activeIndex:0,
      mouseHasMoved:false
    };
  }

  componentDidUpdate(prevProps){
    if(prevProps.text!==this.props.text){ //reset selected index when text changes
      this.setState({activeIndex:0,mouseHasMoved:false})
    }
  } 

  handleMouseMove(index){
    if(!this.state.mouseHasMoved){
      this.setState({mouseHasMoved:true,activeIndex:index})
    } 
  }

  handleMouseOver(index){
    if(this.state.mouseHasMoved){
      this.setState({activeIndex:index})
    } 
  }

  handleMouseOut(index){
    this.setState({activeIndex:index})
  }

  componentDidMount(){
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount(){
   window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e){
    if(e.keyCode === 40) { //down arrow
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex+1
      if(newIndex==this.props.suggestions.length){
        newIndex=0
      }
      this.setState({activeIndex:newIndex})
    } else if (e.keyCode === 38) { //up arrow
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex-1
      if(newIndex<0){
        newIndex=this.props.suggestions.length-1
      }
      this.setState({activeIndex:newIndex})
      
    } else if( e.keyCode === 13) { //enter
      e.stopPropagation()
      e.preventDefault()
      this.props.selectIndex(this.state.activeIndex)
    } else if (e.keyCode === 27) { //escape
      this.props.hideList()
    } 
  }

  //Suggestions are user, project, issue or doc
  render(){
    const {activeIndex}=this.state
    const {suggestions}=this.props
    return (           
      <div className="messageInsertMentionsMenu">
        {suggestions.map((suggestion,index)=>{          
          let suggestionType='doc'
          if(suggestion.project_id){
            suggestionType='project'
          }else if(suggestion.issue_id){
            suggestionType='issue'
          }else if(suggestion.user_id){
            suggestionType='user'
          }
          return(
            <MessageInsertMentionsMenuItem
              key={`message_mention_${index}`}
              suggestion={suggestion}
              index={index}
              suggestionType={suggestionType}
              activeIndex={activeIndex}
              selectIndex={this.props.selectIndex}
              handleMouseMove={this.handleMouseMove}
              handleMouseOver={this.handleMouseOver}
            />
        )})}
      </div>
    )
  }
}

export default MessageInsertMentionsMenuReactComponent
