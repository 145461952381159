import React from 'react'
import {getContactForId} from '../utils/meetings/getContactForId'

const DEFAULT_AVATAR='https://res.cloudinary.com/yarn/image/upload/v1677065166/avatar4_x65s14.png'

const colors=['blue','purple','red','orange','green','sky', 'pink', 'teal']

function calcContactColorFromEmail(email){
	const firstLetter=email[0]
	const lowerLetter = firstLetter.toLowerCase();
  // Get ASCII code of the letter minus ASCII code of 'a'
  const index = lowerLetter.charCodeAt(0) - 'a'.charCodeAt(0);
  const colorIndex = index % colors.length;
  return colors[colorIndex];
}


class AvatarImage extends React.PureComponent{  	
	
	removeAfterUserAvatarsAndStripExtension(url) {
    // Split the URL by 'user_avatars/'
    const parts = url.split('user_avatars/');
    // If there's a part after 'user_avatars/', remove the file extension
    if (parts.length > 1) {
      const filename = parts[1];
      const filenameWithoutExtension = filename.replace(/\.[^/.]+$/, '');
      return filenameWithoutExtension;
    }
    // Return an empty string if there's no part after 'user_avatars/'
    return null;
  }

	render(){	
		const {avatarUrl, className, smallAvatar, largeAvatar, contactId, sideLength, preContactEmail} = this.props
		let userAvatarImageURL=avatarUrl || DEFAULT_AVATAR
		let avatarImageSrc = userAvatarImageURL
		let cloudinaryID = this.removeAfterUserAvatarsAndStripExtension(userAvatarImageURL)		

		const defaultImageDimension = 64 // 32 by 32 - e.g. messages
		const smallImageDimension = 48  // 24 by 24 - e.g. todos
		const largeImageDimension = 256  // 128 by 128 - e.g. settings page

		let responsiveAvatarImageSrc = userAvatarImageURL

		if(cloudinaryID){
			responsiveAvatarImageSrc = `http://res.cloudinary.com/yarn/image/upload/c_limit,q_auto,h_${defaultImageDimension},w_${defaultImageDimension}/v1673693315/user_avatars/${cloudinaryID}.webp`
			if(smallAvatar){
				responsiveAvatarImageSrc = `http://res.cloudinary.com/yarn/image/upload/c_limit,q_auto,h_${smallImageDimension},w_${smallImageDimension}/v1673693315/user_avatars/${cloudinaryID}.webp`
			}
			if(largeAvatar){
				responsiveAvatarImageSrc = `http://res.cloudinary.com/yarn/image/upload/c_limit,q_auto,h_${largeImageDimension},w_${largeImageDimension}/v1673693315/user_avatars/${cloudinaryID}.webp`
			}	
		}
		
		//
		// CONTACT STUFF

		// hardcoded

		let contactObj
		let contactName
		let contactEmail
		let contactBGColor	


		if(contactId){
			contactObj = getContactForId(contactId)
		}

		if(contactObj){
			contactName = contactObj.name
			contactEmail = contactObj.email
			contactBGColor = contactObj.color
		}
		
		// selecting initial letter
		let contactInitial

		if(contactName){
			contactInitial = contactName[0]
		}
		if(!contactName && contactEmail){
			contactInitial = contactEmail[0]	
		}		

		//
		// Pre contact
		if(preContactEmail && !contactId){
			contactInitial = preContactEmail[0] // here's precontact email
			contactBGColor = calcContactColorFromEmail(preContactEmail)
		}

		


		

		// scaling
		const fontSizeDefaultSide = 32 // default side
		const fontSizeDefault = 22
		const contactLetterScale = sideLength / fontSizeDefaultSide
		const scaledFontSize = fontSizeDefault * contactLetterScale
		
		return (
			<React.Fragment>
			{avatarUrl && 
				<img	  			
					className={'avatarImage ' + (className ? `${className}` : '')}
					src={responsiveAvatarImageSrc}
					draggable="false"
				/>
			}
			{contactInitial && contactBGColor && sideLength &&
				<div style={{width: `${sideLength}px`, height: `${sideLength}px`}} className={'contactAvatar ' + (contactBGColor ? ` contactAvatar--bgColor--${contactBGColor} ` : '') + (className ? `${className}` : '')}>
					<div style={{fontSize: `${scaledFontSize}px`}} className='contactAvatar-initial'>
						
							{contactInitial}
					</div>
				</div>
			}
			</React.Fragment>
		)
	}
}

export default AvatarImage

		// CUSTOM OVERRIDE FOR DOODLES
		//

		// const isAli = 		userAvatarImageURL ==='https://avatars.slack-edge.com/2022-04-30/3461081041877_4a9b9fda98979e3d5d52_192.jpg'
		// const isBook = 		userAvatarImageURL ==='https://avatars.slack-edge.com/2022-04-30/3463984178834_65087aa9d157b59d4c93_192.jpg'
		// const isJasper = 	userAvatarImageURL ==='https://avatars.slack-edge.com/2022-05-01/3458822711446_ee1425d0001dcd28cdda_192.jpg'
		// const isKaylee =	userAvatarImageURL ==='https://avatars.slack-edge.com/2022-04-30/3463983814083_f6f3fee8bf629a442691_192.png'
		// const isNicole = 	userAvatarImageURL ==='https://avatars.slack-edge.com/2022-04-30/3487731610128_921d75d2bd3937568a99_192.jpg'
		// const isSimon = 	userAvatarImageURL ==='https://avatars.slack-edge.com/2022-04-30/3457346142486_242039e655368239da20_192.jpg'
		// const isZoe = 		userAvatarImageURL ==='https://avatars.slack-edge.com/2022-04-30/3487736607024_cf8aab6f539aa2806fd8_192.png'
		
		// let doodleType

		// if(isNicole){
		// 	doodleType='sunglasses'
		// }
		// if(isJasper){
		// 	doodleType='postIt'
		// }
		// if(isBook){
		// 	doodleType='cat'
		// 	doodleType='heartEyes'
		// }
		// if(isAli){			
		// 	doodleType='popsicle'
		// }
		// if(isSimon){
		// 	doodleType='skull'
		// }
		// if(isZoe){
		// 	doodleType='alien'
		// }
		// if(isKaylee){
		// 	doodleType='balloon'
		// }	

	
		//http://res.cloudinary.com/yarn/image/upload/v1673693315/user_avatars/280983474.png

		
		// // // DARK MODE AVATARS
		// // NICOLE
		// if(userAvatarImageURL === 'https://avatars.slack-edge.com/2022-11-16/4377458634981_066e89e17f27ff3067fe_192.png'){
		// 	avatarImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1671071217/dummydata/darkavatars/A_vcfsf3.png'	
		// }

		// // ALI
		// if(userAvatarImageURL === 'https://avatars.slack-edge.com/2022-11-16/4404067187952_7d81af397620166edc86_192.png'){
		// 	avatarImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1671071217/dummydata/darkavatars/E_hsujts.png'	
		// }

		// // JASPER
		// if(userAvatarImageURL === 'https://avatars.slack-edge.com/2022-11-16/4380302157075_b6321a1c06e096a724f8_192.png'){
		// 	avatarImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1671071217/dummydata/darkavatars/B_fizgtc.png'	
		// }

		// // SIMON
		// if(userAvatarImageURL === 'https://avatars.slack-edge.com/2022-11-16/4380288347587_d2a5534467a421d38274_192.png'){
		// 	avatarImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1671071217/dummydata/darkavatars/D_v1dtda.png'	
		// }

		// // WALTER
		// if(userAvatarImageURL === 'https://avatars.slack-edge.com/2022-11-16/4393024527665_5389aaa30fba119aa4ee_192.png'){
		// 	avatarImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1671071211/dummydata/darkavatars/G_znnqci.png'	
		// }

		// // ZOE
		// if(userAvatarImageURL === 'https://avatars.slack-edge.com/2022-11-16/4382849720916_783c6feafd0e93aa409b_192.png'){
		// 	avatarImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1671071217/dummydata/darkavatars/C_vn1msu.png'	
		// }

		// // KAYLEE
		// if(userAvatarImageURL === 'https://avatars.slack-edge.com/2022-11-16/4377453091925_efc62580bfa96a571be5_192.png'){
		// 	avatarImageSrc = 'https://res.cloudinary.com/yarn/image/upload/v1671071217/dummydata/darkavatars/F_npcfrr.png'	
		// }


{/*}
				<div className={'avatarImage-doodleAvatarContainer ' + (className ? `${className}` : '')}>
					<DoodleAvatar 
						doodleType={doodleType}
						//doodleBG={doodleBG} only for overrides
					/>
				</div>
				*/}

