import React from 'react'
import Icon from '../../misc/Icon'
import VideoDuration from '../../../utils/videoDuration'
import DocVideoZoomedUITitleBar from './DocVideoZoomedUITitleBar'
import DocVideoCommentRow from './DocVideoCommentRow'
import DocVideoZoomedUISeekBar from './DocVideoZoomedUISeekBar'
import MediaModalCommentInput from '../../docEditor/mediaModals/MediaModalCommentInput'
import DocImageZoomedModalBtn from '../../docEditor/mediaModals/DocImageZoomedModalBtn'
import ReactCursorPosition from 'react-cursor-position'
import ReactSlider from 'react-slider'
import DocVideoCaptions from './DocVideoCaptions'

const TIMEOUT_INTERVAL=250 //for click/double click stuff

class DocVideoZoomedUIVolumeSlider extends React.Component{  
	 
	constructor(){
		super()
		this.onMuteClick=this.onMuteClick.bind(this)    
		this.state={
			preMuteVolume: 1,
		}
	}

	onMuteClick(){    
		if(this.props.volume > 0){
			this.setState({preMuteVolume:this.props.volume})
			this.props.handleVolumeChange(0)
		}
		if(this.props.volume === 0){      
			this.props.handleVolumeChange(this.state.preMuteVolume)
		}
	}
	
	render(){     

		const {handleVolumeChange, volume, handleVolumeMouseUp, handleVolumeMouseDown, adjustingVolume} = this.props

		const wave0limit = 0.25
		const wave1limit = 0.5
		const wave2limit = 0.75        
		
		return (  
								
				<div className={'doc-video-zoomedUI-controlBar-controlRow-volumeSlider' + (adjustingVolume ? ' doc-video-zoomedUI-controlBar-controlRow-volumeSlider--adjustingVolume ' : '') + (volume === 1 ? ' doc-video-zoomedUI-controlBar-controlRow-volumeSlider--maxVolume ' : ' ')}>
					<button onClick={this.onMuteClick} className='doc-video-zoomedUI-controlBar-controlRow-volumeSlider-speakerBtn'>
						
						{volume === 0 &&
							<Icon name='speaker-muted' />
						}
						{volume > 0 && volume <= wave0limit &&
							<Icon name='speaker-wave-0' />
						}
						{volume > wave0limit && volume <= wave1limit &&
							<Icon name='speaker-wave-1' />
						}
						{volume > wave1limit && volume <= wave2limit &&
							<Icon name='speaker-wave-2' />
						}
						{volume > wave2limit && volume <= 1 &&
							<Icon name='speaker-wave-3' />
						}
						
					</button>          
					<div className='doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBarContainer'>
						<ReactSlider
							className="doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar"
							thumbClassName="doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-thumb"
							trackClassName="doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-track"                  
							renderThumb={(props, state) => <div className='doc-video-zoomedUI-controlBar-controlRow-volumeSlider-seekBar-thumb-thumb' {...props} />}

							value={volume}
							min={0}
							max={1}
							step={0.01}
							onAfterChange={handleVolumeMouseUp}
							onBeforeChange={handleVolumeMouseDown}
							onChange={handleVolumeChange}                    
						/>
					</div>          
				</div>          
		)
	}
}





class DocVideoZoomedUI extends React.Component{  
	 constructor(){
		super()
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.onClick=this.onClick.bind(this)
    this.onDoubleClick=this.onDoubleClick.bind(this)
		this.state={
			messageWithTimestamp: true,
		}
		this.timer=null
	}

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
		if(this.timer){
			clearTimeout(this.timer)
		}
	}

	onClick(e){
		e.stopPropagation()
		e.preventDefault()
		if(this.timer){
			clearTimeout(this.timer)
		}
		this.timer=setTimeout(function() {
			this.props.handlePlayPause()
		}.bind(this), TIMEOUT_INTERVAL)
	}

	onDoubleClick(){
		if(this.timer){
			clearTimeout(this.timer)
		}
		this.props.handleZoom()
	}


	handleKeyDown(e){
		if(e.keyCode==27){ //escape
			this.props.handleZoom()
		}else if(e.keyCode==32){//space bar to toggle play/pause
			e.stopPropagation()
			e.preventDefault()
			this.props.handlePlayPause()
		}else if(e.keyCode==39){//right arrow
			this.props.handleSkip('forward')
		}else if(e.keyCode==37){//left arrow
			this.props.handleSkip('back')
		}
	}
	
	render(){ 
		const {playing, played, progressSeconds, videoTitle, handleZoom, mouseMovedShort, mouseMovedLong, duration, handleSeekMouseUp, handleSeekMouseDown, handleSeekChange, handlePlayPause, captionsActive, toggleCaptions, volume, handleVolumeMouseDown, handleVolumeChange, handleVolumeMouseUp, adjustingVolume} = this.props				
		
		const {timestampedMessages}=this.props

		let captionsButtonTooltip
		if(!this.props.subtitleUrl){
			captionsButtonTooltip = "No captions available"
		}
		if(this.props.subtitleUrl && captionsActive){
			captionsButtonTooltip = "Turn off captions"
		}
		if(this.props.subtitleUrl && !captionsActive){
			captionsButtonTooltip = "Turn on captions"
		} 		

		return (	
				<div draggable="false" className={'doc-video-zoomedUI ' + ((!mouseMovedLong && playing) ? ' doc-video-zoomedUI--cursor--hide ' : ' doc-video-zoomedUI--cursor--show ') + ((!mouseMovedLong && playing) ? ' doc-video-zoomedUI--fadeOut ' : ' ')}>

					{this.props.subtitleUrl && captionsActive &&
						<DocVideoCaptions
							zoomed
							subtitleUrl={this.props.subtitleUrl}
							progressSeconds={progressSeconds}
						/>
					}

					{progressSeconds > 0 &&
						<React.Fragment>
						<button onClick={()=>{this.props.handleSkip('back')}} className='doc-video-zoomedUI-hoverSeekButton doc-video-zoomedUI-hoverSeekButton--backwards'>
							<div className='doc-video-zoomedUI-hoverSeekButton-inner'>
								<Icon name='playbackSeekBack5' />
							</div>
						</button>
						<button onClick={()=>{this.props.handleSkip('forward')}} className='doc-video-zoomedUI-hoverSeekButton doc-video-zoomedUI-hoverSeekButton--forwards'>
							<div className='doc-video-zoomedUI-hoverSeekButton-inner'>
								<Icon name='playbackSeekForward5' />
							</div>
						</button>
						</React.Fragment>
					}



					<button onClick={this.onClick} onDoubleClick={this.onDoubleClick} className='doc-video-zoomedUI-backgroundPlayPauseBtn'/>

					<div className='doc-video-zoomedUI-controlBar'>
						<ReactCursorPosition className='doc-video-zoomedUI-controlBar-seekBarContainer'>
							<DocVideoZoomedUISeekBar 
								played={played}
								handleSeekChange={handleSeekChange}
								handleSeekMouseDown={handleSeekMouseDown}
								handleSeekMouseUp={handleSeekMouseUp}
								duration={duration}
								muxPlaybackID={this.props.muxPlaybackID}
							/>
						</ReactCursorPosition>
						<div className='doc-video-zoomedUI-controlBar-commentRowContainer'>
							<DocVideoCommentRow
								rowWidth={this.props.commentRowWidth}
								timestampedMessages={timestampedMessages}
								duration={duration}
								darkModeMessage
								seekToComment={this.props.seekToComment}
								progressSeconds={progressSeconds}
							/>
						</div>
						<div className='doc-video-zoomedUI-controlBar-buttonRow'>
							<div className='doc-video-zoomedUI-controlBar-buttonRow-leftContainer'>
								{/*
								ADD LATER
								<DocImageZoomedModalBtn
									iconName='broadcast'	
									tooltipLabel="Ping Video"
									tooltipAbove										
								/>
								*/}

								<div className='doc-video-zoomedUI-controlBar-buttonRow-playbackBtnContainer'>
									<DocImageZoomedModalBtn
										iconName={playing ? 'playbackPause' : 'playbackPlay'}									
										onClickFunction={handlePlayPause}								
									/>
								</div>

								{/*}
								<button onClick={handlePlayPause} className='doc-video-zoomedUI-controlBar-playPauseButton'>
									{playing ?
										<Icon name='playbackPause' /> 
										:
										<Icon name='playbackPlay' /> 
									 }
								</button>
								*/}

								<div className='doc-video-zoomedUI-controlBar-counterGroup'>
									<div className='doc-video-zoomedUI-controlBar-counterGroup-counter doc-video-zoomedUI-controlBar-counterGroup-counter--elapsed'>
										<VideoDuration seconds={progressSeconds} />
									</div>
									<div className='doc-video-zoomedUI-controlBar-counterGroup-counter doc-video-zoomedUI-controlBar-counterGroup-counter--duration'>
										<VideoDuration seconds={duration} />
									</div>
								</div>

								<DocVideoZoomedUIVolumeSlider
									handleVolumeChange={handleVolumeChange}
									handleVolumeMouseDown={handleVolumeMouseDown}
									handleVolumeMouseUp={handleVolumeMouseUp}
									volume={volume}
									adjustingVolume={adjustingVolume}
								/>

							</div>
							<div className='doc-video-zoomedUI-controlBar-buttonRow-centerContainer'>
								<MediaModalCommentInput 
									video
									unzoom={this.props.handleZoom}
									pauseVideo={this.props.pauseVideo}
									playing={playing}
									messageWithTimestamp={this.state.messageWithTimestamp}
									toggleMessageWithTimestamp={()=>(this.setState({messageWithTimestamp: !this.state.messageWithTimestamp}))}
									timestamp={progressSeconds?progressSeconds:0.0001}		
									sendMediaModalMessage={(text)=>{this.props.sendMediaModalMessage(text,progressSeconds)}}							
								/>

							</div>


							<div className='doc-video-zoomedUI-controlBar-buttonRow-rightContainer'>
								<DocImageZoomedModalBtn
									captionIcon									
									tooltipLabel={captionsButtonTooltip}
									tooltipAbove
									onClickFunction={toggleCaptions}
									active={captionsActive}
									disabled={!this.props.subtitleUrl}
									rightEdge									
								/>
								{/*}
								<DocImageZoomedModalBtn
									iconName='link'	
									tooltipLabel="Copy Link"
									tooltipAbove
									// tooltipLongDelay
								/>
								<DocImageZoomedModalBtn
									iconName='slackIcon'							
									tooltipLabel="Send via Slack..."
									tooltipAbove
								/>
								*/}
							
							</div>
						</div>
					</div>
				</div>
		)
	}
}

export default DocVideoZoomedUI

	{/*}
								<button className='doc-video-zoomedUI-controlBar-collabBtn doc-video-zoomedUI-controlBar-collabBtn--slack'>
									<div className='doc-video-zoomedUI-controlBar-collabBtn-iconContainer'>
										<Icon name='slackIcon' />
									</div>
								</button>
								<button className='doc-video-zoomedUI-controlBar-collabBtn doc-video-zoomedUI-controlBar-collabBtn--link'>
									<div className='doc-video-zoomedUI-controlBar-collabBtn-iconContainer'>
										<Icon name='link' />
									</div>
								</button>
								*/}
