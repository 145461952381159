import React from 'react'
import Icon from '../../../../misc/Icon'
import MessageInputBoxAddGIF from './MessageInputBoxAddGIF'
import MessageInputBoxAddEmoji from './MessageInputBoxAddEmoji'
import MessageInputBoxBtnRowInsertPopover from './MessageInputBoxBtnRowInsertPopover'
import * as Tooltip from '@radix-ui/react-tooltip';


class MessageInputBoxBtnRow extends React.Component{  

	render(){			
		return (	  	

					<div className='docEditor-messagePanel-inputBox-btnRow'>

						{/*}
						<MessageInputBoxBtnRowInsertPopover
							addVideo={this.props.addVideo}
							//addImage={this.props.addImage}
							addDrawing={this.props.addDrawing}
							addFigma={this.props.addFigma}
							handleSelectFile={this.props.handleSelectFile}
						 />

						<div className='docEditor-messagePanel-inputBox-btnRow-dividerContainer docEditor-messagePanel-inputBox-btnRow-dividerContainer--primary'>
							<div className='docEditor-messagePanel-inputBox-btnRow-divider' />
						</div>


						<Tooltip.Provider>
							<Tooltip.Root delayDuration={400}>
								<Tooltip.Trigger className='message-inputBox-addMediaBtn message-inputBox-addMediaBtn--screenRecording'>
									<Icon name='screenRecording' />
								</Tooltip.Trigger>								
								<Tooltip.Content side="top" className="tooltip">
									Screen Clip
								</Tooltip.Content>								
							</Tooltip.Root>
						</Tooltip.Provider>

						<Tooltip.Provider>
							<Tooltip.Root delayDuration={400}>
								<Tooltip.Trigger className='message-inputBox-addMediaBtn message-inputBox-addMediaBtn--codeBlock'>
									<Icon name='codeBlock' />
								</Tooltip.Trigger>								
								<Tooltip.Content side="top" className="tooltip">
									Code Block
								</Tooltip.Content>								
							</Tooltip.Root>
						</Tooltip.Provider>

						<div className='docEditor-messagePanel-inputBox-btnRow-dividerContainer docEditor-messagePanel-inputBox-btnRow-dividerContainer--secondary'>
							<div className='docEditor-messagePanel-inputBox-btnRow-divider' />
						</div>

						*/}


						<button className='message-inputBox-addMediaBtn message-inputBox-addMediaBtn--image'>
									
							<label htmlFor="file">
								<Icon name='insertBarImage' />
							</label>
							<input 
								id='file'
								type='file'
								className='message-inputBox-addMediaBtn--image-hiddenFileInput'
								value={''} 
								accept={'.png,.jpg,.jpeg,.bmp,.tif,.tiff,.webp,.gif'}
								onChange={(e) =>{this.props.handleSelectFile(e)}}
							/>
								
						</button>


						<MessageInputBoxAddGIF 
							addGif={this.props.addGif}							
						/>

						{/*
						<MessageInputBoxAddEmoji							
						
						/>
						*/}

						
						
						
						

						

						


						<button onClick={()=>{this.props.sendMessage()}} className='docEditor-messagePanel-inputBox-btnRow-sendBtn'>
							<Icon name='paperAirplane' />
						</button>		
						

					</div>  										
			
		)
	}
}


export default MessageInputBoxBtnRow