export function bumpMessageVersion(version) {

	if(version){ //TODO not sure we need this
		if(window.view && window.view.state){
		let tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.messageVersion || node.attrs.messageVersion===0){
				tr.setNodeMarkup(pos, null, {...node.attrs,messageVersion:version})
			}
		})
		tr.setMeta('addToHistory', false)  
		window.view.dispatch(tr)
	}
}else{
	if(window.view && window.view.state){

			let tr = window.view.state.tr
			window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
				if(node.attrs.messageVersion || node.attrs.messageVersion===0){
					const oldVersion=node.attrs.messageVersion
					const newVersion=oldVersion+1

					tr.setNodeMarkup(pos, null, {...node.attrs,messageVersion:newVersion})
				}
			})
			tr.setMeta('addToHistory', false)  
			window.view.dispatch(tr)
		}


}
	
}