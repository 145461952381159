import store from '../store'
import find from 'lodash/find'

//for now pass history as prop
//TODO think about pushing to history outside of a react component using BrowserRouter and HashRouter

//new url stuff

///:orgName/doc/docName-"
export function makeUrlForDoc(docId){
	const state = store.getState()
	const org=state.organization
	let url='/'
	if(org && org.name){
		const orgName=org.name.toLowerCase()
		const formattedOrgName=orgName.replace(/[º#&=:*·\\/?;|{}"<>%~[\]` ]/g,'-')
		url+=`${formattedOrgName}`
	}else{
		url+=`org`
	}
	const doc=find(state.docs,{doc_id:docId})
	if(doc && doc.name){
	
		const docName=doc.name.toLowerCase()
		const formattedDocName=docName.replace(/[º#&=:*·\\/?;|{}"<>%~[\]` ]/g,'-')

		url+=`/doc/${formattedDocName}-${docId}`
	}

	return url

}

export function makeUrlForThread(docId,threadId){
	let base=makeUrlForDoc(docId)
	const url=base+'?thread='+threadId
	return url

}


export function openDoc(history,docId) {
	if(docId){
		const url=makeUrlForDoc(docId)
		history.push(`${url}`)
		const state = store.getState()
		const openTabs=state.openTabs
		const {tabGroups,orderedTabGroups}=openTabs
		let tabAlreadyOpen=false
		Object.keys(tabGroups).forEach((groupId)=>{
			const tabs=tabGroups[groupId]
			if(find(tabs,{type:'doc',id:docId})){
				tabAlreadyOpen=true
			}
		})
		if(!tabAlreadyOpen){
			let newTabGroups=Object.assign({},tabGroups)
			let newDefaultGroup=newTabGroups.default
			newDefaultGroup.push({type:'doc',id:docId})
			newTabGroups.default=newDefaultGroup
			store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
		}		
	}
}

//opens without navigating to tab
export function backgroundOpenDoc(docId) {
	if(docId){
		const state = store.getState()
		const openTabs=state.openTabs
		const {tabGroups,orderedTabGroups}=openTabs
		let tabAlreadyOpen=false
		Object.keys(tabGroups).forEach((groupId)=>{
			const tabs=tabGroups[groupId]
			if(find(tabs,{type:'doc',id:docId})){
				tabAlreadyOpen=true
			}
		})
		if(!tabAlreadyOpen){
			let newTabGroups=Object.assign({},tabGroups)
			let newDefaultGroup=newTabGroups.default
			newDefaultGroup.push({type:'doc',id:docId})
			newTabGroups.default=newDefaultGroup
			store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
		}		
	}
}