import React from 'react'
import Icon from '../misc/Icon'
import {updateTemplateName} from '../../utils/templates/updateTemplateName'
import {updateTemplateDescription} from '../../utils/templates/updateTemplateDescription'
import throttle from 'lodash/throttle'
import CompanyIcon from '../misc/CompanyIcon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {getUserForId} from '../../utils/getUserForId'
import AvatarImage from '../AvatarImage'
import {isCurrentUser} from '../../utils/isCurrentUser'
import TemplateModalStarterTemplateDescriptions from './TemplateModalStarterTemplateDescriptions'

// const DEBOUNCE_INTERVAL=500

const templateCompany = [
  {title: "Competitor Battlecard", company: "yarn", companyLabel: "Yarn"},
  {title: "User Persona", company: "yarn", companyLabel: "Yarn"},
  {title: "PR/FAQ", company: "amazon", companyLabel: "Amazon"},
  {title: "Incident Postmortem", company: "pagerduty", companyLabel: "PagerDuty"},
  {title: "PRD", company: "square", companyLabel: "Square"},
  {title: "Product Brief", company: "linear", companyLabel: "Linear"},
  {title: "RFC", company: "react", companyLabel: "React.js Team"},
  {title: "Service RFC", company: "uber", companyLabel: "Uber"},
  {title: "Stripe's Operating Principles", company: "stripe", companyLabel: "Stripe"},
  {title: "Enterprise PRD", company: "figma", companyLabel: "Figma"},
  {title: "Technical Spec", company: "mixpanel", companyLabel: "Mixpanel"},
  {title: "\"We don't sell saddles here\"", company: "slack", companyLabel: "Slack"},
  {title: "\"Ten things we know to be true\"", company: "google", companyLabel: "Google"},
]


class TemplateModalTemplateDetails extends React.Component{  	
	

  constructor(props) {
    super(props)        
    this.handleNameChange=this.handleNameChange.bind(this)	
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
    this.saveTemplateName=this.saveTemplateName.bind(this)
    this.saveTemplateDescription=this.saveTemplateDescription.bind(this)
    this.saveChanges=this.saveChanges.bind(this)
    this.cancelChanges=this.cancelChanges.bind(this)
    let nameValue=''
    let descriptionValue=''
    if(props.template){
    	nameValue=props.template.name || ''
    	descriptionValue=props.template.description || ''
    }
    this.state = {
    	nameValue: nameValue,
    	descriptionValue: descriptionValue,
   	};
  }

  saveChanges(){
  	this.saveTemplateDescription()
  	this.saveTemplateName()
  	this.props.deactivateEditTemplateModel()
  }

  cancelChanges(){
  	let nameValue=''
    let descriptionValue=''
    if(this.props.template){
    	nameValue=this.props.template.name || ''
    	descriptionValue=this.props.template.description || ''
    }
    this.setState({nameValue:nameValue,descriptionValue:descriptionValue})
    this.props.deactivateEditTemplateModel()
  }

  saveTemplateName(){
  	updateTemplateName(this.props.template.template_id,this.state.nameValue)
  }

  saveTemplateDescription(){
  	updateTemplateDescription(this.props.template.template_id,this.state.descriptionValue)
  }

  handleNameChange(event) {    
    this.setState({nameValue: event.target.value})  
    //this.saveTemplateName(event.target.value)
    //updateTemplateName(this.props.template.template_id,event.target.value)
  }

  handleDescriptionChange(event) {    
    this.setState({descriptionValue: event.target.value})  
    //this.saveTemplateDescription(event.target.value) 
  }



	render(){	
		const {template, editTemplateMode,templateDocId} = this.props 		
		const isArchived=template.archived_at?true:false

		let readOnly = template.public || !isCurrentUser(template.created_by)
		if(isCurrentUser(template.created_by)){
			readOnly = false
		}

		const matchingItem = templateCompany.find(item => item.title === this.state.nameValue) || {};
    let company
    let companyLabel
    if(matchingItem){
      company = matchingItem.company
      companyLabel = matchingItem.companyLabel
    }

    const orgTemplate = !company
    let templateCreatorUserId
    let templateCreatorUser
    let templateCreatorUserName
    let templateCreatorUserAvatar

    if(orgTemplate){
    	templateCreatorUserId = template.created_by
    	if(templateCreatorUserId){
    		templateCreatorUser = getUserForId(templateCreatorUserId)
    		if(templateCreatorUser){
    			templateCreatorUserName = templateCreatorUser.display_name
    			templateCreatorUserAvatar = templateCreatorUser.avatar
    		}
    	}
    }
    const disableDoneButton=!this.state.nameValue 
    const isInDoc=(templateDocId) ?true:false
	  return (
		  <React.Fragment>	  
	  		<div className='templateModal-dropdownBtnContainer'>
					<DropdownMenu.Root>
				    <DropdownMenu.Trigger className='templateModal-dropdownBtn'>
				    	<Icon name='ellipses' />
				    </DropdownMenu.Trigger>   			 	
	   			 	<DropdownMenu.Content sideOffset={-1} align="end" alignOffset={-2} className='dropdownMenu dropdownMenu--templateModal'>							
	   			 			   			 		
	   			 		<DropdownMenu.Item onSelect={this.props.activateEditTemplateMode} className={((readOnly || isArchived || editTemplateMode) ? 'dropdownMenu-item--disabled' : '')}>
		        		Rename Template
		        	</DropdownMenu.Item>
		        	
		        	<DropdownMenu.Item onSelect={()=>{this.props.archiveTemplate(template.template_id)}} className={((readOnly || isArchived || editTemplateMode) ? 'dropdownMenu-item--disabled' : '')}>
		        		Archive Template
		        	</DropdownMenu.Item>			        

		        	<DropdownMenu.Separator />

		        	<DropdownMenu.Item onSelect={this.props.toggleShowArchived}>
		        		{this.props.showArchived ? 'Hide Archived Templates' : 'Show Archived Templates'}
		        	</DropdownMenu.Item>
		       	 	
				    </DropdownMenu.Content>
					</DropdownMenu.Root>	  
	  		</div>
	  		<div className='templateModal-detailsContainer'>
		  		<div className={'templateModal-details ' + (editTemplateMode ? ' templateModal-details--editTemplateMode ' : ' templateModal-details--readOnlyMode ')}>
		  			<div className='templateModal-details-primaryContainer'>
		  				<div className='templateModal-details-nameInputContainer'>
		  					<input 								
		  						disabled={!editTemplateMode}
		  						className='templateModal-details-nameInput'
		  						placeholder='Untitled Template'
		  						value={this.state.nameValue}
									onChange={this.handleNameChange} 				
									autoComplete="off"
									autoCorrect="off"
									spellCheck={false}
									maxLength={100}				
									id='templateTitleInput'
									onKeyDown={(e)=>{e.stopPropagation()}}
		  					/>
		  				</div>
		  				{orgTemplate && !editTemplateMode &&
			  				<div className='templateModal-details-creatorContainer'>
				  				<div className='templateModal-details-creator'>
				  					<div className='templateModal-details-creator-avatarContainer'>
				  						<AvatarImage 
				  							avatarUrl={templateCreatorUserAvatar}
				  							className='templateModal-details-creator-avatar'
				  						/>
				  					</div>
				  					<div className='templateModal-details-creator-label'>
				  						{templateCreatorUserName}
				  					</div>
				  				</div>		  				
				  			</div>
			  			}
			  			{editTemplateMode &&
			  				<div className='templateModal-details-editTemplateVSpacer' />
			  			}
			  			{company && 
			  				<div className='templateModal-details-creatorContainer'>
				  				<div className='templateModal-details-creator'>
				  					<div className='templateModal-details-creator-iconContainer'>
				  						<CompanyIcon company={company} />
				  					</div>
				  					<div className='templateModal-details-creator-label'>
				  						{companyLabel}
				  					</div>
				  				</div>		  				
				  			</div>
			  			}
		  				<div className='templateModal-details-descriptionTextAreaContainer'>
								{!company &&
									<textarea 
			      				className='templateModal-details-descriptionTextArea'
			      				disabled={!editTemplateMode}
			      				value={this.state.descriptionValue}								
										onChange={this.handleDescriptionChange}
										placeholder='Add a description'
										autoComplete="off"
										autoCorrect="off"
										spellCheck={false}
										maxLength={255}
										id='templateDescriptionInput'
										onKeyDown={(e)=>{e.stopPropagation()}}
			      			/>
		      			}
		  					{company && this.state.nameValue &&
		  						<TemplateModalStarterTemplateDescriptions 
		  							templateName={this.state.nameValue}
		  						/>			      			
		      			}			
		  				</div>
	  				</div>


	  				<div className='templateModal-details-hSpacer' />

	  				<div className='templateModal-details-rightContainer'>
		  				<div className='templateModal-details-useBtnContainer'>
			  				{!isArchived && !editTemplateMode &&
				  				<button onClick={this.props.applyTemplate} className='templateModal-details-useBtn'>
				  					{isInDoc?'Use template':'Try template'}
				  				</button>
				  			}
				  			{isArchived && !editTemplateMode &&
				  				<button onClick={()=>{this.props.restoreTemplate(template.template_id)}} className='templateModal-details-useBtn'>
				  					Restore Template
				  				</button>
				  			}
				  			{editTemplateMode &&
				  				<button disabled={disableDoneButton} onClick={this.saveChanges} className='templateModal-details-useBtn'>
				  					Done
				  				</button>
				  			}
		  				</div>


		  				<div className='templateModal-details-cancelBtnContainer'>
		  					{editTemplateMode &&
			  					<button onClick={this.cancelChanges} className='templateModal-details-cancelBtn'>
			  						Cancel
			  					</button>
		  					}
		  					{/*}
		  					{!readOnly && !isArchived &&
			  					<button onClick={()=>{this.props.archiveTemplate(template.template_id)}} className='templateModal-details-cancelBtn'>
			  						Archive Template
			  					</button>
		  					}
		  					*/}
		  				</div>
	  				</div>

	  				{/*}
		  			
		  			<div className='templateModal-details-useBtnContainer'>
		  				{!isArchived &&
		  				<button onClick={this.props.applyTemplate} className='templateModal-details-useBtn'>
		  					Try template
		  				</button>
		  			}
		  				{!readOnly && !isArchived &&
			  				<button  className='templateModal-details-archiveBtn'>
			  					Archive
			  				</button>
		  				}
		  				{isArchived &&
			  				<button onClick={()=>{this.props.restoreTemplate(template.template_id)}} className='templateModal-details-archiveBtn'>
			  					Restore Template
			  				</button>
		  				}
		  			</div>
		  			*/}

		  		</div>
	  		</div>
	  	</React.Fragment>
	  )	  
	}
}

export default TemplateModalTemplateDetails
