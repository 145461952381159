import React from 'react'
import Icon from './misc/Icon'
import KeyboardShortcutTag from './misc/KeyboardShortcutTag'

class KeyboardShortcutsPanelRow extends React.Component{  

  render(){ 
        
    return (            
      <div className='keyboardShortcutsPanel-list-row'>
        <div className='keyboardShortcutsPanel-list-row-label'>
          {this.props.label}
        </div>
        <div className='keyboardShortcutsPanel-list-row-shortcut'>
          {this.props.children}
        </div>        
      </div>          

    )
  }
}


class KeyboardShortcutsPanelContents extends React.Component{  

  render(){ 
        
    return (      

      <React.Fragment>
        <div  onClick={()=>{this.props.closeKeyboardShortcutsPanel()}} className='keyboardShortcutsPanel-header'>
          <div className='keyboardShortcutsPanel-header-label'>
            Keyboard Shortcuts
          </div>
          <button className='keyboardShortcutsPanel-header-closeBtn'>
            <div className='keyboardShortcutsPanel-header-inner'>
              <Icon name='cross-small'/>
            </div>      
          </button>
        </div>
        <div className='keyboardShortcutsPanel-list'>
          <div className='keyboardShortcutsPanel-list-groupHeader'>
            General
          </div>

          <KeyboardShortcutsPanelRow label="New doc">
            <KeyboardShortcutTag              
              command                  
              letter='N'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="New doc in view" >
            <KeyboardShortcutTag
              shiftOutline
              command                  
              letter='N'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="New meeting note" >
            <KeyboardShortcutTag
              shiftOutline
              command                  
              letter='M'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Quick open">
            <KeyboardShortcutTag              
              command             
              letter='T'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow 
            label="Close current tab"
          >
            <KeyboardShortcutTag                                
              command                  
              letter='W'
            /> 
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow 
            label="Toggle side nav"
          >
            <KeyboardShortcutTag                                
              command                  
              letter='S'
            /> 
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow 
            label="Toggle theme"
          >
            <KeyboardShortcutTag                                
              shiftOutline
              command                  
              letter='/'
            /> 
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Navigate back">
            <KeyboardShortcutTag                                          
              command              
              letter='['
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Navigate forward">
            <KeyboardShortcutTag                                          
              command              
              letter=']'
            />
          </KeyboardShortcutsPanelRow>

          <div className='keyboardShortcutsPanel-list-groupHeader'>
            Editor
          </div>

          <KeyboardShortcutsPanelRow label="Copy doc/project link">
            <KeyboardShortcutTag                                          
              command
              letter='L'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Move Doc">
            <KeyboardShortcutTag                                          
              command
              shiftOutline
              letter='P'
            />
          </KeyboardShortcutsPanelRow>

          <div className='keyboardShortcutsPanel-spacer' />
          
          <KeyboardShortcutsPanelRow label="Show insert menu">
            <KeyboardShortcutTag                                          
              letter='/'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Mention issues, projects, docs">
            <KeyboardShortcutTag                                          
              letter='@'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Insert emoji">
            <KeyboardShortcutTag                                          
              letter=':'
            />
          </KeyboardShortcutsPanelRow>
          
          <div className='keyboardShortcutsPanel-spacer' />

          <KeyboardShortcutsPanelRow label="Add comment">
            <KeyboardShortcutTag                                          
              shiftOutline
              command
              letter='C'
            />
          </KeyboardShortcutsPanelRow>
          
          <KeyboardShortcutsPanelRow label="Bold">
            <KeyboardShortcutTag                                          
              command
              letter='B'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Italic">
            <KeyboardShortcutTag                                          
              command
              letter='I'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Underline">
            <KeyboardShortcutTag                                          
              command
              letter='U'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Inline code">
            <KeyboardShortcutTag                                                        
              letter='`code`'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Lowlight">
            <KeyboardShortcutTag                                          
              command
              shiftOutline
              letter='K'
            />
          </KeyboardShortcutsPanelRow>          

          <KeyboardShortcutsPanelRow label="Content box">
            <KeyboardShortcutTag                                          
              command
              shiftOutline
              letter='B'
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Code block">
            <KeyboardShortcutTag                                          
              letter='```'            
            />
          </KeyboardShortcutsPanelRow>
          
          <div className='keyboardShortcutsPanel-spacer' />

          <KeyboardShortcutsPanelRow label="Large header">
            <KeyboardShortcutTag                                                        
              letter='#'
              space
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Medium header">
            <KeyboardShortcutTag                                          
              letter='##'
              space
            />
          </KeyboardShortcutsPanelRow>

          <div className='keyboardShortcutsPanel-spacer' />

          <KeyboardShortcutsPanelRow label="Bullet list">
            <KeyboardShortcutTag                                          
              letter='*'
              space
            />
          </KeyboardShortcutsPanelRow>

          
          <KeyboardShortcutsPanelRow label="Dash list">
            <KeyboardShortcutTag                                          
              letter='-'
              space
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Todo list">
            <KeyboardShortcutTag                                          
              command
              letter='.'              
            />
            <span className='keyboardShortcutsPanel-list-row-shortcut-orSpan'>or</span>
            <KeyboardShortcutTag                                          
              letter='[]'
              space       
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Toggle list">
            <KeyboardShortcutTag                                          
              letter='>'
              space       
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Numbered list">
            <KeyboardShortcutTag                                          
              letter='1.'
              space
            />
          </KeyboardShortcutsPanelRow>

          
          <KeyboardShortcutsPanelRow label="Letter list">
            <KeyboardShortcutTag                                          
              letter='A.'
              space
            />
          </KeyboardShortcutsPanelRow>

          <KeyboardShortcutsPanelRow label="Separator line">
            <KeyboardShortcutTag                                          
              letter='---'
              space
            />
          </KeyboardShortcutsPanelRow>


          <div className='keyboardShortcutsPanel-list-groupHeader'>
            Advanced Shortcuts
          </div>

          <KeyboardShortcutsPanelRow label="Open current view in Linear">
            <KeyboardShortcutTag                                          
              command
              shiftOutline
              letter='L'
            />
          </KeyboardShortcutsPanelRow>          

          <KeyboardShortcutsPanelRow label="Navigate up a level">
            <KeyboardShortcutTag                                          
              command
              shiftOutline
              letter='U'
            />
          </KeyboardShortcutsPanelRow>

          
          <div className='keyboardShortcutsPanel-list-bottomSpacer' />
        </div>
        
      </React.Fragment>


    )
  }
}

export default KeyboardShortcutsPanelContents
