import mixpanel from 'mixpanel-browser';

let MIXPANEL_TOKEN=process.env.REACT_APP_MIXPANEL_TOKEN  || window.envVars.reactAppMixpanelToken

mixpanel.init(MIXPANEL_TOKEN);



// mixpanel.init(MIXPANEL_TOKEN, {debug: true}); 
// mixpanel.track('test');

// let env_check = process.env.NODE_ENV === 'production';
// let env_check=true

// let actions = {
//   identify: (id) => {
//     if (env_check) mixpanel.identify(id);
//   },
//   alias: (id) => {
//     if (env_check) mixpanel.alias(id);
//   },
//   track: (name, props) => {
//     if (env_check) mixpanel.track(name, props);
//   },
//   people: {
//     set: (props) => {
//       if (env_check) mixpanel.people.set(props);
//     },
//   },
// };




// let env_check = process.env.NODE_ENV === 'production';
let env_check=true

let actions = {
  identify: (id) => {
    mixpanel.identify(id);
  },
  alias: (id) => { //only need to use alias to track users  is to connect a user’s anonymous events from before a signup (or other identification) event with the post-signup activity on your site or in your app.
    mixpanel.alias(id);
  },
  track: (name, props) => {
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props)
    },
  },
};

export let Mixpanel = actions;

  //https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices