import React from 'react'
import { connect } from 'react-redux'
import Icon from '../../../misc/Icon'
import AvatarImage from '../../../AvatarImage'
import { Spring, animated, config } from 'react-spring'

class MessagePanelHeaderDNDButton extends React.Component{  

  


  render(){ 
            
    const {theme,doNotDisturbUntil} = this.props
    const dndActive=doNotDisturbUntil?true:false

    const springConfig = this.props.springAnimationConfig    

    //const theme = 'light'
    // const theme = 'dark'


    let defaultButtonBGColor
    let activeButtonBGColor

    let defaultCircleBGColor
    let activeCircleBGColor


    if(theme === 'light'){
      defaultButtonBGColor = 'rgb(240,240,244)' // light gray
      activeButtonBGColor = 'rgb(121,121,130)' // dark grey

      defaultCircleBGColor = 'rgb(51,201,157)' // green
      activeCircleBGColor = 'rgb(255,255,255)' // white

    }
    

    if(theme === 'dark'){
      defaultButtonBGColor = 'rgb(44,72,68)' // light gray      
      activeButtonBGColor = 'rgb(60,60,50)' // dark grey

      defaultCircleBGColor = 'rgb(104,255,211)' // bright green
      activeCircleBGColor = 'rgb(240,240,245)' // white
    }


    return (      
       <React.Fragment>
        <Spring               
          backgroundColor={dndActive ? activeButtonBGColor : defaultButtonBGColor}
          config={{...springConfig, clamp: true}}
        >        
         {buttonStyles => 
         <animated.div style={buttonStyles} className='docEditor-messagePanel-header-dndButton'>
            <Spring               
              backgroundColor={dndActive ? activeCircleBGColor : defaultCircleBGColor}
              transform={dndActive ? ' translateX(0px) translateY(0px) scale(1) ' : 'translateX(0px) translateY(0px) scale(0.8)'}
              config={springConfig}   
            >
              {circleStyles => 
                <animated.div style={circleStyles} className='docEditor-messagePanel-header-dndButton-circle'>
                  <Spring 
                    transform={dndActive ? 'scale(1)' : 'scale(0.0002'} 
                    opacity={dndActive ? 1 : 0} 
                    backgroundColor={dndActive ? activeButtonBGColor : defaultButtonBGColor}    
                    config={springConfig}                 
                  >
                    {innerStyles => 
                      <animated.div style={innerStyles} className='docEditor-messagePanel-header-dndButton-circle-inner' />                    
                    }
                  </Spring>
                </animated.div>
              }
            </Spring>               
         </animated.div>
        }
         </Spring>
       </React.Fragment>
      
    )
  }
}

//export default MessagePanelHeaderDocGroupDNDButton

const mapStateToProps = (state) => ({
  theme:state.theme,
  doNotDisturbUntil:state.doNotDisturbUntil
});

export default connect(
  mapStateToProps,
  {

  })(MessagePanelHeaderDNDButton)
