import React from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from './misc/Icon'
import LinearMarker from './misc/LinearMarker'
import MeetingTypeIcon from './misc/MeetingTypeIcon'
import * as Dialog from '@radix-ui/react-dialog'
import {getProjectForId} from '../utils/getProjectForId'
import RoadmapPageContainer from '../containers/RoadmapPageContainer'
import {getRoadmapForId} from '../utils/getRoadmapForId'
import {getDocSearchResults,reindexSearch} from '../utils/search/getDocSearchResults'
import {openDoc} from '../utils/openDoc'
import {openRoadmap} from '../utils/openRoadmap'
import {getMeetingForId} from '../utils/meetings/getMeetingForId'
import {getOrg} from '../utils/getOrg'
import throttle from 'lodash/throttle'
import {getFormattedTimestampForMeeting} from '../utils/meetings/getFormattedTimestampForMeeting'

function createMarkup(input) {
  return {__html: input}
}

class OpenDocMenuRow extends React.Component{  	
	constructor(props) {
    super(props);
    this.openSuggestionFromMenu=this.openSuggestionFromMenu.bind(this)
  }

  openSuggestionFromMenu() {
		this.props.openSuggestionFromMenu(this.props.suggestion)
	}
 
	render(){			
		const {active,suggestionType,suggestion} = this.props
	 if(suggestionType=='roadmap'){
			return (		
				<div onClick={this.openSuggestionFromMenu} className={'openDocMenu-row ' + (active ? ' openDocMenu-row--active' : 'openDocMenu-row--inactive')} >
					<div className='openDocMenu-row-docTitle-iconContainer openDocMenu-row-docTitle-iconContainer--roadmapRow'>
						<Icon name='roadmapOutlineMedium' />
					</div>
					<div className='openDocMenu-row-docTitle'>
						{suggestion.name}
					</div>
				</div>
			)
		}else if(suggestionType=='meetingDoc'){
			const meeting=getMeetingForId(suggestion.meeting)

			let meetingType
			let meetingFormattedDateTime
			if(meeting){
				meetingType = meeting.type
				meetingFormattedDateTime = getFormattedTimestampForMeeting(meeting,'openDocMenu')
			}

			return (		
				<div onClick={this.openSuggestionFromMenu} className={'openDocMenu-row ' + (active ? ' openDocMenu-row--active' : 'openDocMenu-row--inactive')} >
					{meetingType && meetingType !== 'blank' &&
						<div className='openDocMenu-row-docTitle-marker'>
							<MeetingTypeIcon
								meetingType={meetingType}
							/>
						</div>
					}
		  		<div className='openDocMenu-row-docTitle'>
		  			{suggestion.name} {meetingFormattedDateTime && <span className='openDocMenu-row-docTitle-meetingDateTime'>{meetingFormattedDateTime}</span>}
		  		</div>					
			</div>
			)
		}
		else if(suggestionType=='roadmapDoc'){
			const roadmap=getRoadmapForId(suggestion.roadmap)
			return (		
				<div onClick={this.openSuggestionFromMenu} className={'openDocMenu-row ' + (active ? ' openDocMenu-row--active' : 'openDocMenu-row--inactive')} >
		  		<div className='openDocMenu-row-docTitle'>
		  			{suggestion.name}
		  		</div>
					<div className='libraryDocTable-table-row-title-roadmapTag'>
						<div className='libraryDocTable-table-row-title-roadmapTag-icon'>
							<Icon name='roadmapOutline' />							
						</div>
						<div className='libraryDocTable-table-row-title-roadmapTag-label'>
							{roadmap.name}
						</div>
					</div>
			</div>
			)
		}else if(suggestionType=='privateDoc'){
			return (		
				<div onClick={this.openSuggestionFromMenu} className={'openDocMenu-row ' + (active ? ' openDocMenu-row--active' : 'openDocMenu-row--inactive')} >
					<div className='openDocMenu-row-docTitle'>
						{suggestion.name}
					</div>
						<div className='libraryDocTable-table-row-title-privateTag'>
						<div className='libraryDocTable-table-row-title-privateTag-label'>	
							Draft
						</div>
					</div>
				</div>
			)
		}else if(suggestionType=='projectRootDoc'){
			return (		
				<div onClick={this.openSuggestionFromMenu} className={'openDocMenu-row ' + (active ? ' openDocMenu-row--active' : 'openDocMenu-row--inactive')} >
					<div className='openDocMenu-row-docTitle-marker'>
						<LinearMarker 
							projectId={suggestion.project}
						/>
					</div>
					<div className='openDocMenu-row-docTitle'>
						{suggestion.name}
					</div>
				</div>
			)
		}else if(suggestionType=='projectSubDoc'){
			const project=getProjectForId(suggestion.project)
			return (		
	  		<div onClick={this.openSuggestionFromMenu} className={'openDocMenu-row ' + (active ? ' openDocMenu-row--active' : 'openDocMenu-row--inactive')} >
					<div className='openDocMenu-row-docTitle-marker'>
						<LinearMarker 
							projectId={suggestion.project}
						/>
					</div>
					<div className='openDocMenu-row-docTitle-subdocSlash'>
						/
					</div>
		  		<div className='openDocMenu-row-docTitle'>
		  			{suggestion.name}
		  		</div>
					<div className='libraryDocTable-table-row-title-projectTag'>
						<div className='libraryDocTable-table-row-title-projectTag-marker'>
							<LinearMarker 
								projectId={project.project_id}
							/>
						</div>
						<div className='libraryDocTable-table-row-title-projectTag-label'>
							{project.name}
						</div>
					</div>
		  	</div>
				)
			}else if(suggestionType=='orgDoc'){
				const org = getOrg()
				let orgName
				let orgLogo
				if(org){
					orgName = org.name
					orgLogo = org.logo
					if(orgName==='Yarn'){
						orgName ='Acme Inc.'
					}
				}
				return(
				<div onClick={this.openSuggestionFromMenu} className={'openDocMenu-row ' + (active ? ' openDocMenu-row--active' : 'openDocMenu-row--inactive')} >
					<div className='openDocMenu-row-docTitle'>
						{suggestion.name}
					</div>
					<div className='libraryDocTable-table-row-title-orgTag'>
						<div className='libraryDocTable-table-row-title-orgTag-image'>
							<img src={orgLogo} className='libraryDocTable-table-row-title-orgTag-image-image' />	
						</div>
						<div className='libraryDocTable-table-row-title-orgTag-label'>
							{orgName}
						</div>
					</div>
				</div>
				)
			}else {
				return null
			}
	}
}

class OpenDocMenu extends React.Component{  

 	constructor(props) {
    super(props);
    this.getSearchResults=this.getSearchResults.bind(this)
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.updateSearchResults=this.updateSearchResults.bind(this)
    this.openSuggestionFromMenu=this.openSuggestionFromMenu.bind(this)
    this.handleChange = this.handleChange.bind(this)
    // this.reindexSearch=this.reindexSearch.bind(this)
    // this.reindexSearch=throttle(this.reindexSearch,5000)
    
    this.state = {
    	value: '',
    	activeSuggestion:null,
    	activeSuggestionIndex:null,
    	searchResults:[]
   	};
   	this.timeout=null
  }

  componentDidMount() {
  	reindexSearch(this.props.docs,this.props.roadmaps)
		window.addEventListener('keydown', this.handleKeyDown)
		this.updateSearchResults('')
		this.setState({value:''})
	}
	
	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
		if(this.timeout){
			clearTimeout(this.timeout)
		}
	}

	// componentDidUpdate(prevProps){
	// 	if(prevProps.docs !== this.props.docs || prevProps.roadmaps !== this.props.roadmaps){
	// 		this.reindexSearch(s)
	// 	}
	// }

	// reindexSearch(){
	// 	reindexSearch(this.props.docs,this.props.roadmaps)
	// }

	openSuggestionFromMenu(suggestion){
		if(suggestion.doc_id){
			openDoc(this.props.history,suggestion.doc_id)
			this.timeout = setTimeout(function() {
				this.props.hideOpenDocMenu()
			}.bind(this), 200)		
		}else if(suggestion.roadmap_id){
			openRoadmap(this.props.history,suggestion.roadmap_id)
			this.timeout = setTimeout(function() {
				this.props.hideOpenDocMenu()
			}.bind(this), 200)		
		}
	}

	handleKeyDown(e){
		if(this.props.showOpenDocMenu){
			if(e.keyCode==40){ //down arrow
				e.preventDefault()
				e.stopPropagation()
				const {activeSuggestionIndex,searchResults}=this.state
				let newActiveSuggestionIndex=0
				if(activeSuggestionIndex || activeSuggestionIndex==0){
					newActiveSuggestionIndex=Math.min(activeSuggestionIndex+1,searchResults.length-1)
				}
				const newActiveSuggestion=searchResults[newActiveSuggestionIndex]
				this.setState({activeSuggestion:newActiveSuggestion,activeSuggestionIndex:newActiveSuggestionIndex})
			}else if(e.keyCode==38){ //UP arrow
				e.preventDefault()
				e.stopPropagation()
				const {activeSuggestionIndex,searchResults}=this.state
				let newActiveSuggestionIndex=0
				if(activeSuggestionIndex || activeSuggestionIndex==0){
					newActiveSuggestionIndex=Math.max(activeSuggestionIndex-1,0)
				}
				const newActiveSuggestion=searchResults[newActiveSuggestionIndex]
				this.setState({activeSuggestion:newActiveSuggestion,activeSuggestionIndex:newActiveSuggestionIndex})
			}else if(e.keyCode==13){ //enterKey
				e.preventDefault()
				e.stopPropagation()
				const {activeSuggestion}=this.state
				if(activeSuggestion){
					this.openSuggestionFromMenu(activeSuggestion)
				}
			}
		}
	}

  handleChange(event) {
  	const searchTerm=event.target.value
  	this.setState({value:searchTerm});
  	this.updateSearchResults(searchTerm)    
  }

  updateSearchResults(searchTerm){
  	const searchResults=this.getSearchResults(searchTerm)
  	let activeSuggestion=null
  	let activeSuggestionIndex=null
  	if(searchTerm && searchResults && searchResults.length >0){
  		activeSuggestion=searchResults[0]
  		activeSuggestionIndex=0
  	}
    this.setState({activeSuggestion:activeSuggestion,activeSuggestionIndex:activeSuggestionIndex,searchResults:searchResults});
  }

  getSearchResults(searchTerm){ //styling gets messed up if have too many results
  	return getDocSearchResults(this.props.docs,this.props.roadmaps,searchTerm)
  }


	render(){			
		const {value,activeSuggestion} = this.state
		const {hideOpenDocMenu} = this.props
		let inputEmpty = true
		if(value && value.length > 0){
			inputEmpty = false
		}	
		// let html='<div>empty</div>'
		// let docTitle=''
		// if(activeDoc){
		// 	docTitle=activeDoc.name
		// }
		// if(activeDoc && activeDoc['doc_html']){
		// 	html=activeDoc['doc_html']
		// }

		const suggestions=this.state.searchResults

	  return (		
	  	<React.Fragment>
			  <Dialog.Root id='openDocMenu' open={this.props.showOpenDocMenu}>			    		   
			    <Dialog.Portal>
			      <Dialog.Overlay className='dialogOverlay dialogOverlay--invisible dialogOverlay--openDocMenu'>
				      <Dialog.Content 
				      	className='openDocMenu'
				      	onEscapeKeyDown={hideOpenDocMenu}
				      	onPointerDownOutside={hideOpenDocMenu}
				      	onInteractOutside={hideOpenDocMenu}
				      >								      					  	
					  		<div className={'openDocMenu-input ' + (inputEmpty ? ' openDocMenu-input--empty' : ' openDocMenu-input--notEmpty')}>
					  			<input 
					  				id='openDocMenu-input-input'
										placeholder='Search projects and docs'
										className='openDocMenu-input-input'
										value={this.state.value}
										onChange={this.handleChange}
										autoFocus
									/>
									{/*}
									<div className='openDocMenu-input-UI'>
										<div className='openDocMenu-input-UI-iconContainer'>
											<Icon name='magnifyingGlass' />
										</div>
									</div>
									*/}
					  		</div>	  	
					  		<div className='openDocMenu-resultsList'>			
					  			<div>		  			
									{suggestions.map((suggestion)=>{										
										let active=false
										let suggestionType
										let suggestionId
										if(suggestion.roadmap_id){
											suggestionType='roadmap'
											suggestionId=suggestion.roadmap_id
											active=activeSuggestion && activeSuggestion.roadmap_id==suggestion.roadmap_id
										}else{
											suggestionId=suggestion.doc_id
											active=activeSuggestion && activeSuggestion.doc_id==suggestion.doc_id
											suggestionType = 'privateDoc'
											if(suggestion.is_project_doc){
												suggestionType = 'projectRootDoc'	
											}else if(suggestion.project){
												suggestionType = 'projectSubDoc'			
											}else if(suggestion.is_org_doc){
												suggestionType = 'orgDoc'
											}else if(suggestion.roadmap){
												suggestionType = 'roadmapDoc'			
											}else if(suggestion.meeting){
												suggestionType = 'meetingDoc'			
											}
										}
										return(
											<OpenDocMenuRow 
												key={`suggestion_${suggestionId}`}										
												active={active}
												suggestionType={suggestionType}
												suggestion={suggestion}
												openSuggestionFromMenu={this.openSuggestionFromMenu}
											/>
										)
									}) }
									</div>
					  		</div>	
				      </Dialog.Content>

				      {activeSuggestion && activeSuggestion.doc_id &&
					      <div className={'openDocMenu-docPreview ' + (this.props.sideMenuVisible ? 'openDocMenu-docPreview--sideMenuState--visible' : ' openDocMenu-docPreview--sideMenuState--hidden ')}>					      	
									<div className='openDocMenu-docPreview-docContainer'>
										<div className='openDocMenu-docPreview-doc'>
											<div className='openDocMenu-docPreview-doc-titleContainer'>
												<div className='openDocMenu-docPreview-doc-title'>
													{activeSuggestion.name}
												</div>								
											</div>
											<div className='openDocMenu-docPreview-doc-innerWrapperContainer'>
												<div className='openDocMenu-docPreview-doc-innerWrapper' dangerouslySetInnerHTML={createMarkup(activeSuggestion.doc_html)} key={activeSuggestion?`${activeSuggestion['doc_id']}`:'empty'} />								
											</div>
										</div>
									</div>
									
									<div className='openDocMenu-docPreview-messagePanelSpacer'/>
									<div className='openDocMenu-docPreview-teamPanelSpacer'/>

								</div>
							}	
							{activeSuggestion && activeSuggestion.roadmap_id &&
					      <div className={'openDocMenu-pagePreview ' + (this.props.sideMenuVisible ? 'openDocMenu-pagePreview--sideMenuState--visible' : ' openDocMenu-pagePreview--sideMenuState--hidden ')}>					      										
									<RoadmapPageContainer roadmapId={activeSuggestion.roadmap_id} sideMenuVisible={this.props.sideMenuVisible} isSearchPreview={true}/>							
								</div>
							}				

			      </Dialog.Overlay>
			    </Dialog.Portal>
			  </Dialog.Root>				
			</React.Fragment>
	  )
	}
}

const mapStateToProps = (state) => ({
	docs: state.docs,
	roadmaps:state.roadmaps
});
export default withRouter(connect(
	mapStateToProps,
	{})(OpenDocMenu))
