import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../misc/Icon'
import TemplateModalList from './TemplateModalList'
import TemplateModalTemplateDetails from './TemplateModalTemplateDetails'
import {applyTemplate} from '../../utils/templates/applyTemplate'
import {createNewDocFromTemplate} from '../../utils/createNewDoc'
import find from 'lodash/find'
import {addDocLinkToLinear} from '../../utils/addDocLinkToLinear'
import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import { Mixpanel } from '../../Mixpanel'
import includes from 'lodash/includes'
import {createNewMeetingFromTemplate} from '../../utils/meetings/createNewMeeting'
import {applyTemplateToMeeting} from '../../utils/meetings/applyTemplateToMeeting'


function createMarkup(input) {
  return {__html: input}
}

const meetingTemplateNames=['Design Review','Discovery Call','Team Sync']

class TemplateModal extends React.Component{  	
		
	constructor(props) {
		super(props)
		this.applyTemplate=this.applyTemplate.bind(this)	
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.toggleShowArchived=this.toggleShowArchived.bind(this)
		this.restoreTemplate=this.restoreTemplate.bind(this)
		this.state = {
    	showArchived: false,
    	editTemplateMode: false,
    	docIsEmpty:false
   	};  
	} 

	componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)	
		Mixpanel.track('open_template_modal')
		//check if we are in an empty doc 
		let docIsEmpty=false
		if(window.view){
			const doc = window.view.state.doc 
			const nodeSize=doc.nodeSize
			if(doc.nodeSize<7){
				docIsEmpty=true
			}
		}    
		this.setState({docIsEmpty:docIsEmpty})

		this.props.fetchArchivedTemplates()
		this.props.fetchTemplates()
		if(this.props.newTemplateId){
			const newActiveTemplate=find(this.props.docTemplates,{template_id:this.props.newTemplateId})
			if(newActiveTemplate){
				this.props.setActiveTemplate(null,newActiveTemplate)
			}
		}else{
			const newActiveTemplate=this.props.docTemplates[0]
			if(newActiveTemplate){
				this.props.setActiveTemplate(null,newActiveTemplate)
			}
		}
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		e.stopPropagation()
		const {docTemplates,activeTemplate,archivedDocTemplates} = this.props
		let templates=docTemplates
		if(this.state.showArchived){
			templates=archivedDocTemplates
		}
		const activeIndex = findIndex(templates,{template_id:activeTemplate.template_id})
		if(e.keyCode==40){ //down arrow
			e.preventDefault()
			e.stopPropagation();
			let newIndex=null
			if(activeIndex==templates.length-1){
				newIndex=0
			}else{
				newIndex=activeIndex+1
			}
			const newActive=templates[newIndex]
			this.props.setActiveTemplate(null,newActive)
		}else if(e.keyCode==38){ //UP arrow
			e.preventDefault()
			e.stopPropagation();
			let newIndex=null
			if(activeIndex==0){
				newIndex=templates.length-1
			}else{
				newIndex=activeIndex-1
			}
				const newActive=templates[newIndex]
			this.props.setActiveTemplate(null,newActive)
		}else if(e.keyCode==13){ //enterKey
			e.preventDefault()
			e.stopPropagation();
			this.applyTemplate(e)
		}
	}

	applyTemplate(e){
		console.log('apply template-----------------------')
		e.stopPropagation()
		e.preventDefault()
		const {templateDocId,docId,addLinkToLinear,currentDocIsMeeting} = this.props
		const isMeetingTemplate=includes(meetingTemplateNames,this.props.activeTemplate.name)
		console.log(`is meeting template----- ${isMeetingTemplate}`)
		console.log(`currentDocIsMeeting---- ${currentDocIsMeeting}`)
		const template = this.props.activeTemplate
		//if it is a meeting template
		//if current doc is a meeting and it is empty doc then apply templaate and update meeting type
		//else create a new meeting
		if(isMeetingTemplate){
			if(docId && this.state.docIsEmpty && this.props.currentDocIsMeeting){
				let meetingType='blank'
				if(template.name=='Discovery Call'){
					meetingType='discovery'
				}else if(template.name=='Design Review'){
					meetingType='designReview'
				}else if(template.name=='Team Sync'){
					meetingType='teamSync'
				}
				applyTemplateToMeeting(template,docId,this.props.currentMeeting,meetingType)
				this.props.closeModal()
			}else{
				console.log('create new meeting!')
				createNewMeetingFromTemplate(this.props.activeTemplate,this.props.history)
				this.props.closeModal()
			}
		}
		else{
			if(this.state.docIsEmpty){
				applyTemplate(this.props.activeTemplate,this.props.docId)
				this.props.closeModal()
			}else{//create new private doc from template
				createNewDocFromTemplate(this.props.history,this.props.activeTemplate)
			}
		}



		//if its not a meeting template
		//apply or create doc as usual



		// if(templateDocId){
		// 	applyTemplate(this.props.activeTemplate,templateDocId)
		// 	this.props.closeModal()
		// 	if(addLinkToLinear){
		// 		addDocLinkToLinear(templateDocId)
		// 	}
		// }
		// else if(this.state.docIsEmpty){
		// 	applyTemplate(this.props.activeTemplate,this.props.docId)
		// 	this.props.closeModal()
		// }
		// else{
		// 	//create new private doc from template
		// 	createNewDocFromTemplate(this.props.history,this.props.activeTemplate)
		// }
	}

	restoreTemplate(templateId){
		this.props.restoreTemplate(templateId)
		this.setState({showArchived:false})
	}

	toggleShowArchived(){
		const newArchivedState=!this.state.showArchived
		this.setState({showArchived: newArchivedState})
		if(newArchivedState){
			const newActive=this.props.archivedDocTemplates[0]
			this.props.setActiveTemplate(null,newActive)
		}
		else{
			const newActive=this.props.docTemplates[0]
			this.props.setActiveTemplate(null,newActive)
		}
	}

	render(){	
		const {closeModal,docTemplates,activeTemplate,publicTemplates,orgTemplates} = this.props
		let html=''
		if(activeTemplate){
			html=activeTemplate.html
		}

		let templateName = 'Untitled Template'
		if(activeTemplate && activeTemplate.name){
			templateName = activeTemplate.name
		}
		const {docIsEmpty} = this.state
		//docIsEmpty-- if open modal and are in an empty doc then we show "use template" and replace current doc
		let templateDocId=this.props.templateDocId 
		if(!templateDocId && docIsEmpty){
			templateDocId=this.props.docId
		}
	  return (
	  	<React.Fragment>
	  		<TemplateModalList
	  			docTemplates={docTemplates}
	  			publicTemplates={publicTemplates}
	  			orgTemplates={orgTemplates}
	  			setActiveTemplate={this.props.setActiveTemplate}
	  			activeTemplate={activeTemplate}
	  			archivedDocTemplates={this.props.archivedDocTemplates}
	  			showArchived={this.state.showArchived}
	  			toggleShowArchived={this.toggleShowArchived}
	  			editTemplateMode={this.state.editTemplateMode}
	  			deactivateEditTemplateModel={()=>{this.setState({editTemplateMode: false})}}
	  		/>	  			

	  		<div className='templateModal-previewContainer' >	  			
	  			<div className='templateModal-previewGradient' />	  			
	  			<div className='templateModal-previewScrollContainer' id='template_modal_scrollable_div'>
		  			<div className='templateModal-preview'>	  	
		  				<div className='templateModal-preview-templateTitle'>
		  					{templateName}
		  				</div>
		  				<div className='templateModal-preview-htmlWrapper' dangerouslySetInnerHTML={createMarkup(html)} />
		  			</div>	  			  			
	  			</div>
	  			{activeTemplate &&
		  			<TemplateModalTemplateDetails 
		  				key={activeTemplate.template_id}
		  				template={activeTemplate}
		  				applyTemplate={this.applyTemplate}
		  				archiveTemplate={this.props.archiveTemplate}
		  				restoreTemplate={this.restoreTemplate}
		  				editTemplateMode={this.state.editTemplateMode}
		  				activateEditTemplateMode={()=>{this.setState({editTemplateMode: true})}}
		  				deactivateEditTemplateModel={()=>{this.setState({editTemplateMode: false})}}
		  				toggleShowArchived={this.toggleShowArchived}
		  				showArchived={this.state.showArchived}
		  				templateDocId={templateDocId}
		  				docIsEmpty={docIsEmpty}
		  			/>
  				}		
	  		</div>

	  		
	  		{/*}
	  		<button onClick={closeModal} className='templateModal-closeBtn'>
	  			<Icon name='crossSemibold' />
	  		</button>
				*/}

			</React.Fragment>
	  )
	}
}

export default withRouter(TemplateModal)
