import React from 'react'
import Icon from '../../../misc/Icon'
import store from '../../../../store'
import DocImageZoomedModalBtn from '../DocImageZoomedModalBtn'
import MediaModalCommentInput from '../MediaModalCommentInput'
import {showToastNotification} from '../../../../actions/toastNotification'
import DocFigmaEmbedEffectsToolbarContainer from '../../../nodeViews/figma/DocFigmaEmbedEffectsToolbarContainer'
import {isPrivateDoc} from '../../../../utils/isPrivateDoc'

const HIDE_COMMENT_INPUT_TIME=2000

class DocFigmaEmbedZoomedModalFooter extends React.Component{  	

  constructor(props) {
    super(props)    
    this.sendMediaModalMessage=this.sendMediaModalMessage.bind(this)        
    this.state = {
    	showCommentInput: true,
    	messageValue:''
   	};
    this.timeout=null
  }

  componentWillUnmount(){
  	if(this.timeout){
  		clearTimeout(this.timeout)
  	}
  }

  sendMediaModalMessage(messageText){
		const nodeType='figmaEmbed'
		const {embedId,embedObj}=this.props //nodeId and embedId are the same
		this.props.sendMediaModalMessage(embedId,nodeType,embedObj,messageText)
		//this.props.unzoom()
		// THIS IS FOR UNZOOM FLOW
		this.setState({showCommentInput:false,messageValue:''})
		if(this.timeout){
			clearTimeout(this.timeout)
		}
		this.timeout=setTimeout(() => {
   		this.setState({showCommentInput: true,messageValue:''});
  	}, HIDE_COMMENT_INPUT_TIME);    
		// set showCommentInput to false for 2 seconds, then true		
		//
		const userName=null
		const actionType="sentMediaModalComment"				
		store.dispatch(showToastNotification(actionType,userName))
	}

	

	render(){	
		const {cropModeActive,docId,showEffectsToolbar} = this.props
		const isPrivate=isPrivateDoc(docId)
		const showCommentInput = !showEffectsToolbar && !cropModeActive && this.state.showCommentInput
		return (
			<div className='doc-zoomedFigmaModal-footer'>
				<div className={'doc-zoomedFigmaModal-footer-leftContainer ' + (cropModeActive ? ' doc-zoomedFigmaModal-footer-leftContainer--fade ' : ' doc-zoomedFigmaModal-footer-leftContainer--visible ')}>
					{/*}							
					<DocImageZoomedModalBtn
						iconName='broadcast'
						label='Ping'							
					/>						
					*/}
				</div>
				<div className='doc-zoomedFigmaModal-footer-centerContainer'>
					{!isPrivate && !cropModeActive && 
							<div className={'doc-zoomedFigmaModal-footer-commentInputContainer ' + (showCommentInput ? ' doc-zoomedFigmaModal-footer-commentInputContainer--show ' : ' doc-zoomedFigmaModal-footer-commentInputContainer--hide ')}>						
								<MediaModalCommentInput 
									sendMediaModalMessage={this.sendMediaModalMessage}
									unzoom={this.props.unzoom}
									messageValue={this.state.messageValue}
									onMessageValueUpdate={(value)=>{this.setState({messageValue:value})}}
								/>
							</div>
						}
					<div className={'doc-zoomedFigmaModal-footer-activeCropBtnContainer ' + (cropModeActive ? ' doc-zoomedFigmaModal-footer-activeCropBtnContainer--show ' : ' doc-zoomedFigmaModal-footer-activeCropBtnContainer--hide ')}>
						<button onClick={this.props.disableCropMode} className='doc-zoomedFigmaModal-footer-activeCropBtn'>
							Done
						</button>
					</div>
					<div className='doc-zoomedFigmaModal-footer-effectsToolbarOuterContainer'>
						<DocFigmaEmbedEffectsToolbarContainer
							showEffectsToolbar={showEffectsToolbar}
							hideEffectsToolbar={this.props.hideEffectsToolbar}
							backgroundPadding={this.props.backgroundPadding}
							editBackgroundPadding={this.props.editBackgroundPadding}
							backgroundImage={this.props.backgroundImage}
							editBackgroundImage={this.props.editBackgroundImage}
							deviceFrame={this.props.deviceFrame}
							editDeviceFrame={this.props.editDeviceFrame}
							device={this.props.device}
							toggleShadow={this.props.toggleShadow}						
						/>
					</div>
				</div>
				<div className={'doc-zoomedFigmaModal-footer-rightContainer' + (cropModeActive ? ' doc-zoomedFigmaModal-footer-rightContainer--fade ' : ' doc-zoomedFigmaModal-footer-rightContainer--visible ')}>			
					{/*}							
					<DocImageZoomedModalBtn
						iconName='link'	
						tooltipLabel="Copy Link"
						tooltipAbove
						// tooltipLongDelay
					/>
					<DocImageZoomedModalBtn
						iconName='slackIcon'							
						tooltipLabel="Send via Slack..."
						tooltipAbove
					/>								
					*/}
				</div>
			</div>	
		)
	}
}

export default DocFigmaEmbedZoomedModalFooter
