import React from 'react'
import ReactPlayer from 'react-player/file'

class TemplateModalHelpGif extends React.PureComponent{  

	
	render(){		

		return (
			<ReactPlayer   			  
				width="100%"
				height="100%"
				url={"https://res.cloudinary.com/yarn/video/upload/c_fill,h_600,w_800,q_auto/v1689045817/helpvideos/NewTemplateTip_hkanm8.mp4"}
				autoPlay={true}	          		          
				playing={true}
				controls={false}		          
				loop={true}		          
				muted={true}
				config={{
				file: {
				attributes: {
				  style: { width: "100%", height: "100%", objectFit: "cover" },
				},
				},
				}}      
			/>  
		)
	}
}
 


export default TemplateModalHelpGif
