import React from 'react'
import NewTextLinkPopoverReactComponent from './NewTextLinkPopoverReactComponent'

class NewTextLinkAnchorReactComponent extends React.Component{  	

	constructor(props){
    super(props)    
    this.state={
      showPopover: false,      
    }
  }


  componentDidMount(){
  	this.setState({showPopover: true})
  }
  // componentWillUnmount(){
  // 	window.removeEventListener('keydown', this.handleKeyDown)		
  // }

  // handleKeyDown(e){
  // 	if(e.keyCode==39 || e.keyCode==37){ //on left/right arrow show modal
  // 		if(!this.state.showModal){
  // 			this.props.hideHoverMenu()
  // 			this.setState({showModal:true})
  // 		}
  // 	}
  // }


	render(){	
		
		const {showPopover} = this.state

					

			return (	  	
		  	<React.Fragment>	
		  		
		  		
		  		<NewTextLinkPopoverReactComponent
		  			showPopover={showPopover}		  			
		  		>
	  				<div className='doc-textLink-popoverAnchor-anchor'/>

		  		</NewTextLinkPopoverReactComponent>
		  				  		

		  	</React.Fragment>
	  	)
		}
	
}

export default NewTextLinkAnchorReactComponent