import React, { Component } from 'react'
import { Droppable,Draggable } from '@hello-pangea/dnd'
import DraggableTabGroup from './DraggableTabGroup'

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",  
});


const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",  

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});


class DroppablePageArea extends Component {
	render(){  
		const {tabGroups,orderedTabGroups}=this.props
		return (	
			<Droppable droppableId="droppable" type="TABGROUP">
				{(provided, snapshot) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						{orderedTabGroups.map((tabGroup, index) => { //2 tab groups- starred and default
							if(tabGroup.id !== "default"){
								return(
									<DraggableTabGroup 
										key={`tab_group-${tabGroup.id}`}
										tabGroup={tabGroup} 
										tabs={tabGroups[tabGroup.id]}
										index={index}
										deleteTab={this.props.deleteTab}
										moveToNewGroup={this.props.moveToNewGroup}
										renameTabGroup={this.props.renameTabGroup}
										deleteTabGroup={this.props.deleteTabGroup}
										newTabGroupId={this.props.newTabGroupId}
									/>
								)
							}
							else{return null} 
						})}
						
						{provided.placeholder}
					</div>
				)}
			</Droppable>  	
		)
	}
}
export default DroppablePageArea