import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'





class DocBlockLinearIssueMiniModal extends React.Component{    


  constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.state={
      forceMount: false,      
      value: props.title,
    }
  }

  onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  handleChange(event) {
    const inputValue=event.target.value
    
    this.setState({value: inputValue});
  }

  render(){ 
    const {showPopover, openPopover, closePopover, inverted} = this.props
    const {forceMount} = this.state
    

    const translateYDistance = 0
    let translateYDirection = 'down'
    if(inverted){
      translateYDirection = 'up'
    }

    let translateY
    if(translateYDirection === 'down'){
      translateY = translateYDistance * -1
    }else if(translateYDirection === 'up'){
      translateY = translateYDistance * 1
    }

    const priority = 'medium'

    return (
      <React.Fragment>
        <Popover.Root open={showPopover}>
          <Popover.Trigger asChild>
            {this.props.children}
          </Popover.Trigger>
          <Popover.Anchor asChild >
            <div className='doc-blockLinearIssue-modalAnchor' />
          </Popover.Anchor>
          <Popover.Portal>
          <Popover.Content
            className={'doc-blockIssueMiniPopoverContainer ' + (showPopover ? ' doc-blockLinearIssueMiniPopoverContainer--visible ' : ' doc-blockLinearIssueMiniPopoverContainer--hidden ')} 
            onEscapeKeyDown={closePopover}
            onPointerDownOutside={closePopover}
            onInteractOutside={closePopover}
            forceMount={showPopover ? true : forceMount}
            //side="bottom"
            //sideOffset={4}
            //asChild
          >
            <Transition
              items={showPopover}
              from={{ opacity: 0, transform: `translateY(${translateY}px) scale(1)` }}
              enter={{ opacity: 1, transform: 'translateY(0px) scale(1)'  }}
              leave={{ opacity: 0, transform: `translateY(${translateY}px) scale(1)`  }}
              reverse={showPopover}
              config={{tension: 600, friction: 30}}
              // config={{duration: 0}}
              onRest={this.onRest}
             >
              {(styles, item) =>
                item && 
                 <animated.div style={styles} className="doc-blockLinearIssueMiniPopover popover">  
                                   
                  <div className='doc-blockLinearIssueMiniPopover-inputContainer'>
                    <input
                      className='doc-blockLinearIssueMiniPopover-input'
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                  </div>
                  
                </animated.div>
              }
            </Transition>

                      
          </Popover.Content>
          </Popover.Portal>
        </Popover.Root>   
      </React.Fragment>
    )
  }
}

export default DocBlockLinearIssueMiniModal