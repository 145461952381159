import React, { Component } from 'react'
import Icon from '../../components/misc/Icon'
import {getCurrentUser} from '../../utils/getCurrentUser'

const NOTION_URL="https://yarnguide.notion.site/Yarn-Guide-a0ab2185b30e4c6e82059f95284cbb1f"

class SideMenuSlackTab extends React.Component{  

	openNotionUrl(){
    if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:NOTION_URL})     
    }else{
      window.open(NOTION_URL,'_blank')
    }
  }

	render(){	
	
	  return (
						
			<div onClick={this.openNotionUrl} className='sideMenu-tab sideMenu-tab--tabType--appTab sideMenu-tab--appTab--help sideMenu-tab--inactive'>
				
				<div className='sideMenu-tab-iconContainer'>		
					<div className='sideMenu-tab-iconInnerContainer'>
						<Icon name='helpDocsMedium' />
						<Icon name='linkArrow' />						
					</div>		
				</div>

				<div className='sideMenu-tab-labelContainer'>
					<div className='sideMenu-tab-label'>
						Help Docs
					</div>
				</div>	

				
			</div>				
		
	  )
	}
}





export default SideMenuSlackTab
