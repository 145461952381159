
//Save the active thread in the doc so can open on it when come back to doc


function getInitialState(){
	const activeThreads = JSON.parse(localStorage.getItem('activeThreads'))
	if(activeThreads){
		return activeThreads
	}else{
		return {}
	}

}


export default function (state = getInitialState(), action) {
	switch (action.type) {

	case 'UPDATE_DOC_ACTIVE_THREAD':
		const {docId,activeThread}=action	 //active thread is the message id of the thread parent
		let newState=Object.assign({},state)
		newState[docId]=activeThread
		localStorage.setItem('activeThreads',JSON.stringify(newState))
		return newState
	
	default:
		return state
	}
}

