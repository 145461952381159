import React from 'react'
import Icon from '../../misc/Icon'
import ReactSlider from 'react-slider'
import store from '../../../store'
import {generateDocSummary} from '../../../actions/docs'

class DocSummarizerProtoParameter extends React.Component{  

  constructor(props){
    super()
    this.handleParameterChange=this.handleParameterChange.bind(this)
    this.handleParameterMouseDown=this.handleParameterMouseDown.bind(this)    
    this.handleParameterMouseUp=this.handleParameterMouseUp.bind(this)    
    this.state={
    //  parameterValue: props.defaultValue, 
      adjustingParameter: false,     
    }
  }

  handleParameterMouseUp = e => {   
  	this.setState({ adjustingParameter: false })
    this.setState({ parameterValue: parseFloat(e) })
  }  

  handleParameterMouseDown = e => {
    this.setState({ adjustingParameter: true })   
  }

  handleParameterChange = e => {
    //this.setState({ parameterValue: parseFloat(e) })   
    this.props.onChange(parseFloat(e) ) 
  }


  render(){ 

  	//const {parameterValue, adjustingParameter} = this.state

  	const {adjustingParameter} = this.state
  	const {parameterValue}=this.props
        
    return (            
			<div className={'docEditor-summarizerProto-settings-parameter ' + (adjustingParameter ? ' docEditor-summarizerProto-settings-parameter--adjustingParameter ' : '')}>
				<div className='docEditor-summarizerProto-settings-parameter-header'>
					<div className='docEditor-summarizerProto-settings-parameter-header-label'>
						{this.props.title}
					</div>
					<div className='docEditor-summarizerProto-settings-parameter-header-value'>
						{parameterValue}
					</div>
				</div>
				<div className='docEditor-summarizerProto-settings-parameter-sliderContainer'>
					<ReactSlider
              className="docEditor-summarizerProto-settings-parameter-slider"
              thumbClassName="docEditor-summarizerProto-settings-parameter-slider-thumb"
              trackClassName="docEditor-summarizerProto-settings-parameter-slider-track"                  
              renderThumb={(props, state) => <div className='docEditor-summarizerProto-settings-parameter-slider-thumb-thumb' {...props} />}
              value={parameterValue}
              min={this.props.minValue}
              max={this.props.maxValue}
              step={this.props.step}
              onAfterChange={this.handleParameterMouseUp}              
              onBeforeChange={this.handleParameterMouseDown}              
              onChange={this.handleParameterChange}                    
            />          
				</div>
			</div>  
    )
  }
}



//Default tldr values
//https://beta.openai.com/examples/default-tldr-summary
// const completion = await openai.createCompletion({
//   model: "text-davinci-003",
//   prompt: generatePrompt(plainText),
//   temperature:0.7,
//   max_tokens:60,
//   top_p:1.0,
//   frequency_penalty:0.0,
//   presence_penalty:1
// });

const DEFAULT_TEMP=0.7
const DEFAULT_MAX_LENGTH=256
const DEFAULT_TOP_P=1
const DEFAULT_FREQUENCY_PENALTY=0
const DEFAULT_PRESENCE_PENALTY=1
const DEFAULT_BEST_OF=1



class DocSummarizerProto extends React.Component{  

  constructor(props){
    super(props)
    this.handlePromptInputChange = this.handlePromptInputChange.bind(this)
    this.generateSummary=this.generateSummary.bind(this)
   	let summaryText=null
   	if(props.doc && props.doc.summary){
   		summaryText=props.doc.summary
   	}

    this.state={
      isExpanded: false,
      promptInput: '${plain_text}\n\nTl;dr',
      isComputing: false,
      //summaryText: 'Suspendisse id aliquam orci, vitae faucibus elit. Integer placerat posuere convallis. Cras consequat nec neque in gravida. Maecenas sodales arcu ut urna viverra fermentum. Aenean at dolor at turpis finibus auctor. Nullam id velit ornare, sollicitudin velit non, faucibus nulla. Mauris ac nibh vel justo auctor tristique.'
      summaryText: summaryText,
      temperature:DEFAULT_TEMP,
      maxLength:DEFAULT_MAX_LENGTH,
      topP:DEFAULT_TOP_P,
      frequencyPenalty:DEFAULT_FREQUENCY_PENALTY,
      presencePenalty:DEFAULT_PRESENCE_PENALTY,
      bestOf:DEFAULT_BEST_OF
    }
  }

  componentDidMount(){
  	// store.dispatch(generateDocSummary(this.props.docId)).then((response)=>{
  	// 	this.setState({summaryText:response.result})
  	// })
  }

  generateSummary(){
  	this.setState({isComputing: true,summaryText:'....'})
  	let params={
  		prompt:this.state.promptInput,
  		temperature:this.state.temperature,
  		max_tokens:this.state.maxLength,
  		top_p:this.state.topP,
  		frequency_penalty:this.state.frequencyPenalty,
  		presence_penalty:this.state.presencePenalty
  	}
  	store.dispatch(generateDocSummary(this.props.docId,params)).then((response)=>{
  		this.setState({summaryText:response.result})
  		this.setState({isComputing: false,isExpanded:false})
  	})
  }


  handlePromptInputChange(e){
		let newPrompt=e.target.value
		this.setState({promptInput:newPrompt})
	}

  render(){ 

  	const {isExpanded, promptInput, isComputing, summaryText} = this.state
  	const hasText = summaryText && summaryText.length > 0   	

        
    return (      
      <div className='docEditor-summarizerProtoContainer'>
      	{!isExpanded && 
      	<div className={'docEditor-summarizerProto docEditor-summarizerProto--minimized ' + (hasText ? ' docEditor-summarizerProto--minimized--hasText ' : ' docEditor-summarizerProto--minimized--noText ')}>
      		<div onClick={()=>(this.setState({isExpanded: true}))} className='docEditor-summarizerProto-headerContainer'>
      			{!hasText && 
		      		<div className='docEditor-summarizerProto-header docEditor-summarizerProto-header--static'>
		      			<div className='docEditor-summarizerProto-header-iconContainer'>
		      				<Icon name='wand' />
		      			</div>
		      			<div className='docEditor-summarizerProto-header-label'>
		      				Add TLDR
		      			</div>      			
		      		</div>   
	      		} 
	      		{hasText &&
		      		<div className='docEditor-summarizerProto-header docEditor-summarizerProto-header--noHover'>
		      			<div className='docEditor-summarizerProto-header-iconContainer'>
		      				<Icon name='wand' />
		      			</div>
		      			<div className='docEditor-summarizerProto-header-label'>
		      				TLDR
		      			</div>      			
		      		</div>   
	      		} 
	      		{hasText && 
		      		<div className='docEditor-summarizerProto-header docEditor-summarizerProto-header--hover'>
		      			<div className='docEditor-summarizerProto-header-iconContainer'>
		      				<Icon name='wand' />
		      			</div>
		      			<div className='docEditor-summarizerProto-header-label'>
		      				REGEN
		      			</div>      			
		      		</div>   
	      		}   		      			      		
      		</div>
      		{hasText && 
	      		<div className='docEditor-summarizerProto-textContainer'>
      				{summaryText}
      			</div>      
    			}	

      	</div>
      	}

      	{isExpanded && 
      	<div className='docEditor-summarizerProto docEditor-summarizerProto--expanded'>
      		{hasText && 
      			<div onClick={()=>(this.setState({isExpanded: false}))} className='docEditor-summarizerProto-headerContainer'>
		      		<div className='docEditor-summarizerProto-header docEditor-summarizerProto-header--static'>
		      			<div className='docEditor-summarizerProto-header-iconContainer'>
		      				<Icon name='wand' />
		      			</div>
		      			<div className='docEditor-summarizerProto-header-label'>
		      				TLDR
		      			</div>      			
		      		</div>
	      		</div>
	      	}
	      	{!hasText && 
      			<div onClick={()=>(this.setState({isExpanded: false}))} className='docEditor-summarizerProto-headerContainer'>
		      		<div className='docEditor-summarizerProto-header docEditor-summarizerProto-header--static'>
		      			<div className='docEditor-summarizerProto-header-iconContainer'>
		      				<Icon name='wand' />
		      			</div>
		      			<div className='docEditor-summarizerProto-header-label'>
		      				ADD TLDR
		      			</div>      			
		      		</div>
	      		</div>
	      	}

	      	{hasText && 
	      		<div className='docEditor-summarizerProto-textContainer'>
      				{summaryText}
      			</div>   	      	
	      	}	      	

	      	<div className='docEditor-summarizerProto-divider' />

	      	<div className='docEditor-summarizerProto-settings'>
	      		<div className='docEditor-summarizerProto-settings-promptInput'>
	      			<div className='docEditor-summarizerProto-settings-promptInput-title'>
	      				Prompt Input
	      			</div>
	      			<textarea 
	      				className='docEditor-summarizerProto-settings-promptInput-input'
	      				value={promptInput}
								//onKeyDown={this.handleTitleInputKeyDown}
								onChange={this.handlePromptInputChange}
								placeholder='Input Prompt'
								autoComplete="off"
								autoCorrect="off"
								spellCheck={false}
	      			/>
	      		</div>

	      		<div className='docEditor-summarizerProto-settings-parameterContainer'>
	      			<div className='docEditor-summarizerProto-settings-parameterContainer-header'>
	      				<div className='docEditor-summarizerProto-settings-parameterContainer-header-label'>
									Parameters
								</div>
								<button className='docEditor-summarizerProto-settings-parameterContainer-header-reset'>
									Defaults
								</button>
							</div>
	      			<DocSummarizerProtoParameter
	      				title='Temperature'
	      				maxValue={1}
	      				minValue={0}
	      				step={0.01}
	      				parameterValue={this.state.temperature}
	      				onChange={(value)=>{this.setState({temperature:value})}}
	      			/>


	      			<DocSummarizerProtoParameter
	      				title='Max Length'
	      				maxValue={4000}
	      				minValue={1}
	      				step={1}
	      				//defaultValue={256}
	      				parameterValue={this.state.maxLength}
	      				onChange={(value)=>{this.setState({maxLength:value})}}
	      			/>
						
	      			<DocSummarizerProtoParameter
	      				title='Top P'
	      				maxValue={1}
	      				minValue={0}
	      				step={0.01}
	      				//defaultValue={1}
	      				parameterValue={this.state.topP}
	      				onChange={(value)=>{this.setState({topP:value})}}
	      			/>

	      			<DocSummarizerProtoParameter
	      				title='Frequency Penalty'
	      				maxValue={2}
	      				minValue={0}
	      				step={0.01}
	      				//defaultValue={0}
	      				parameterValue={this.state.frequencyPenalty}
	      				onChange={(value)=>{this.setState({frequencyPenalty:value})}}
	      			/>

	      			<DocSummarizerProtoParameter
	      				title='Presence Penalty'
	      				maxValue={2}
	      				minValue={0}
	      				step={0.01}
	      				//defaultValue={1}
	      				parameterValue={this.state.presencePenalty}
	      				onChange={(value)=>{this.setState({presencePenalty:value})}}
	      			/>

	      			<DocSummarizerProtoParameter
	      				title='Best of'
	      				maxValue={10}
	      				minValue={1}
	      				step={1}
	      				//defaultValue={1}
	      				parameterValue={this.state.bestOf}
	      				onChange={(value)=>{this.setState({bestOf:value})}}
	      			/>

	      		</div>

	      	</div>
	      	{!isComputing && 
		      	<button onClick={this.generateSummary} className='docEditor-summarizerProto-regenButton'>
		      		Regen
		      	</button>
	      	}
	      	{isComputing && 
		      	<button onClick={()=>(this.setState({isComputing: false}))} className='docEditor-summarizerProto-regenButton docEditor-summarizerProto-regenButton--computing'>
		      		Computing
		      	</button>
	      	}
      	</div>
      	}

      	

      </div>   
    )
  }
}

export default DocSummarizerProto
