import store from '../store'
import find from 'lodash/find'



export function userIsFollower(docId) {	
	const state = store.getState()
	const userId=state.user.user_id
	const doc= find(state.docs, {'doc_id':`${docId}`})
	let userIsFollower=false

	if(doc && doc.followers){
		doc.followers.forEach((flr)=>{
			if(flr.user_id==userId){
				userIsFollower=true
			}
		})
	}

	return userIsFollower
}

