import schema from '../schema/editorSchema'  
import {TextSelection} from "prosemirror-state"
import {getAnchorNode} from './getAnchorNode'
import {getNodePos} from './getNodePos'



function isFirstPara(anchor,doc){
	let isFirstPara=false
	let paraIndex=0
	doc.nodesBetween(0,doc.content.size, (node, pos) => {
		if(node.type.name=='paragraph'){
			const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize)
			if(hasAnchor && paraIndex==0){
				isFirstPara=true
			}
			paraIndex+=1
		}
	})
	return isFirstPara
}


function isSelectionAtStartOfFirstParagraph(selection, doc) {
  // Get the position and depth of the selection
  const {$from}=selection
  const parentOffset=$from.parentOffset //if this is 0 then you are at the start of a paragraph
  const isStartOfPara=parentOffset==0
  const isFirstParagraph=isFirstPara($from.pos,doc)
  const isSelectionAtStartOfFirstParagraph=isStartOfPara && isFirstParagraph
  return isSelectionAtStartOfFirstParagraph;
}

function isSelectionAtEndOfParagraph(selection) {
  // Get the parent node of the selection and its index
  const { parent, parentOffset } = selection.$to;
  // Check if the parent node is a paragraph
  if (parent.type.name !== 'paragraph') {
    return false;
  }
  // Check if the selection is at the end of the paragraph
  if (parentOffset === parent.content.size) {
    return true;
  }
  return false;
}

export function insertTextLink(type,docId,projectId,issueId,url) {
	const state=window.view.state
	let insertPos=state.selection.from
	let tr=state.tr
	const sel=state.selection
	const text=schema.text(state.doc.textBetween(sel.from,sel.to))

	let attrs={
		type:type,
		docId:docId,
		projectId:projectId,
		issueId:issueId,
		url:url
	}

	const atEndOfParagraph=isSelectionAtEndOfParagraph(sel)


	const atStartOfFirstParagraph=isSelectionAtStartOfFirstParagraph(sel,tr.doc)

	const linkNode=schema.nodes.textLink.create(attrs,text)
	tr.replaceWith(sel.from,sel.to,linkNode)
	
	let selOffset=1
	if(atEndOfParagraph){
		//selOffset+=1
		tr.insertText(`\u{00A0}`,sel.to+2) //blank space
		//tr.insertText(`!`,sel.to+2) //blank space
	}
	if(atStartOfFirstParagraph){
		tr.insertText(`\u{00A0}`,sel.from) //blank space
		//tr.insertText(`!`,sel.from) //blank space
		selOffset+=1
	}

	let selection=new TextSelection(tr.doc.resolve(sel.from+linkNode.nodeSize+selOffset))
	tr.setSelection(selection)

	window.view.focus()
	window.view.dispatch(tr)
}





// export function insertTextLink() {
// 	const state=window.view.state
// 	let insertPos=state.selection.from
// 	let tr=state.tr
// 	let {$from, $to} = state.selection
// 	const text=schema.text('title')
// 	const url='https://www.google.com'
// 	const type='weblink'
		

// 	const linkNode=schema.nodes.textLink.create({type:type,url:url},text)
// 	console.log('insert text link!!!')
// 	console.log(linkNode)

// 	tr.replaceWith(state.selection.from,state.selection.to,linkNode)
// 	let selection=new TextSelection(tr.doc.resolve(state.selection.from+linkNode.nodeSize-1))
// 	tr.setSelection(selection)
// 	window.view.dispatch(tr)
// }

