const emDOM = ["em", 0], strongDOM = ["strong", 0], codeDOM = ["code", 0]


export const marks = {
  link: {
    attrs: {
      href: {},
      title: {default: null}
    },
    inclusive: false,
    parseDOM: [{tag: "a[href]", getAttrs(dom) {
      return {href: dom.getAttribute("href"), title: dom.getAttribute("title")}
    }}],
    toDOM(node) { let {href, title} = node.attrs; return ["a", {href, title}, 0] }
  },
  // :: MarkSpec An emphasis mark. Rendered as an `<em>` element.
  // Has parse rules that also match `<i>` and `font-style: italic`.
  em: {
    parseDOM: [{tag: "i"}, {tag: "em"}, {style: "font-style=italic"}],
    toDOM() { return emDOM }
  },
  strong: {
    parseDOM: [{tag: "strong"},
               // This works around a Google Docs misbehavior where
               // pasted content will be inexplicably wrapped in `<b>`
               // tags with a font-weight normal.
               {tag: "b", getAttrs: node => node.style.fontWeight != "normal" && null},
               {style: "font-weight", getAttrs: value => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null}],
    toDOM() { return strongDOM }
  },
  strike:{
    parseDOM: [
      { tag: 's' },
    ],
    toDOM: () => ['s']
  },
  // :: MarkSpec Code font mark. Represented as a `<code>` element.
  code: {
    parseDOM: [{
      tag: "span",
      getAttrs: dom => {
        let classname = dom.getAttribute("class")
        return classname==='doc-span--style--code'
        }
    }],
    toDOM:()=>["span",{class:"doc-span--style--code"},0]
  },
  light: {
    parseDOM: [{
      tag: "span",
      getAttrs: dom => {
        let classname = dom.getAttribute("class")
        return classname==='doc-span--style--light'
        }
    }],
    //parseDOM: [{tag: "code"}],
    toDOM:()=>["span",{class:"doc-span--style--light"},0]
  },
  underline:{
    parseDOM: [
      { tag: 'u' },
    ],
    toDOM: () => ['u']
  }

}


export default marks