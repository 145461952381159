import findIndex from 'lodash/findIndex'

export default function (state = {}, action) {
	
	switch (action.type) {

		case 'PUSHER_PRESENCE_SUBSCRIBE_SUCCESS':
			return {
				...state,
				[action.docId]:action.members
			}

		case 'PUSHER_PRESENCE_REMOVED':				
			let members=state[`${action.docId}`]
			delete members[action.sessionId]
			return {...state,
				[`${action.docId}`]:members 
			}

		case 'PUSHER_PRESENCE_ADDED':		
			let docPresenceMembers=state[`${action.docId}`]
			docPresenceMembers[action.sessionId]=action.data.info
			return {...state,
				[`${action.docId}`]:docPresenceMembers 
			}
		
		case 'SIGN_OUT':
      return []

	default:
		return state
	}
}