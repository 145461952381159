import {put,fetch} from '../api'
import axios from 'axios'
import {bumpTranscriptSpeakerVersion} from '../utils/meetings/bumpTranscriptSpeakerVersion'
import {randomID} from '../utils/randomID'
import {updateUploadProgress} from '../utils/recordingUploads/updateUploadProgress'
import { Mixpanel } from '../Mixpanel'
// //create upload url for client to upload recoding to 
// export function createRecordingUpload() {

// 	return (dispatch) => put(`/recording/upload`)
// 		.then((response) => {
// 			return response
// 		})
// 		.catch((error) => {
// 			return error
// 		})
// }


export function archiveRecording(recordingId) {
	return (dispatch) =>{ 
		dispatch({ type: 'ARCHIVE_RECORDING_REQUEST', recordingId })
		// closeArchivedTab(docId)
		 return put(`/recording/${recordingId}/archive`)
		.then((response) => {
			Mixpanel.track('archive_recording')
			//dispatch({ type: 'ARCHIVE_MEETING_SUCCESS', meetingId})
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}


export function handleRecordingArchivedPusher(recordingId){
	return (dispatch) => {
		dispatch({ type: 'ARCHIVE_RECORDING_REQUEST', recordingId })
	}
}


export function createMeetingRecordingRequest(meetingId,fileName) {
	const uploadId=randomID()
	const recordingId=randomID()
	const requestBody={
		file_name:fileName,
		upload_id:uploadId,
		recording_id:recordingId
	}
	const tempRecordingObj={
		meeting:meetingId,
		recording_id:`${recordingId}`, 
		upload_id:`${uploadId}`,
		mux_status:'waiting_for_upload'
	}
	Mixpanel.track('create_recording')
	return (dispatch) => {
		updateUploadProgress(uploadId,0)
		dispatch({ type: 'FETCH_A_RECORDING_SUCCESS', response:tempRecordingObj })
		return put(`/meetings/${meetingId}/recording/upload`,requestBody)
			.then((response) => {
				const recording=response.recording 
				dispatch({ type: 'FETCH_A_RECORDING_SUCCESS', response:recording })
				return response
			})
			.catch((error) => {
				return error
			})
	 }
}



export function fetchRecording(recordingId) {
	return (dispatch) => fetch(`/recording/${recordingId}`)
		.then((response) => {
			if(!response.archived_at){
				dispatch({ type: 'FETCH_A_RECORDING_SUCCESS', response })
				bumpTranscriptSpeakerVersion()
			}
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
}

export function fetchRecordings() {
	return (dispatch) => fetch(`/recordings`)
		.then((response) => {
			dispatch({ type: 'FETCH_RECORDINGS_SUCCESS', response })
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
}


export function assignSpeakerRequest(recordingId,speakerId,userId,contactId){
	const timestamp=new Date()
	const requestBody={
		speaker_id:speakerId,
		user_id:userId,
		contact_id:contactId,
		updated_at:timestamp
	}
	return (dispatch) =>{
		dispatch({ type: 'ASSIGN_SPEAKER_REQUEST',recordingId,speakerId,userId,contactId,timestamp})
		bumpTranscriptSpeakerVersion()
		return put(`/recordings/${recordingId}/assign-speaker`,requestBody)
		.then((response) => {
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}


// export function fetchUpload(id) {
// 	return (dispatch) => fetch(`/upload/${id}`)
// 		.then((response) => {
// 			return response
// 		})
// 		.catch((error) => {
// 			return error
// 		})
// }
