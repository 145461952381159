import React from 'react'
import Icon from '../../../misc/Icon'
import {getUserForId} from '../../../../utils/getUserForId'

class MessageInputParentMessage extends React.Component{  
	
	render(){	
		
		const {message}=this.props
		const user=getUserForId(message['created_by'])
		let userName
		if(user){
			userName=user.display_name
		}

		return (	  											
					
			<div className='docEditor-messagePanel-inputBoxReplyPreviewContainer'>
				<div className='docEditor-messagePanel-inputBoxReplyPreview'>
					<span className='docEditor-messagePanel-inputBoxReplyPreview-username'>{userName}</span><span className='docEditor-messagePanel-inputBoxReplyPreview-messageContent'>{message['plain_text']}</span>
					<button className='docEditor-messagePanel-inputBoxReplyPreview-cancel' onClick={this.props.deleteParentMessage}>
						<div className='docEditor-messagePanel-inputBoxReplyPreview-cancel-inner'>
							<Icon name='cross-small' />
						</div>
					</button>
				</div>			
			
			
			
			</div>			
					
		)
	}
} 



export default MessageInputParentMessage
