import React from 'react'
import Icon from '../../misc/Icon'

class DocImageReactionBarBtn extends React.Component{  
	constructor(){
    super()
    this.state={      
      userHasReacted: false,      
    }
  }  

	render(){ 

		const {userHasReacted} = this.state
		const {initialReactionCount} = this.props

		let reactionCount = initialReactionCount
		if(userHasReacted){
			reactionCount = initialReactionCount + 1
		}
		
		return (			
			<button onClick={()=>{this.setState({userHasReacted: !userHasReacted})}} className={'doc-image-reactionBar-btn ' + (reactionCount > 0 ? ' doc-image-reactionBar-btn--hasReactions ' : ' doc-image-reactionBar-btn--noReactions ') + (userHasReacted ? ' doc-image-reactionBar-btn--userHasReacted ' : ' doc-image-reactionBar-btn--userNotReacted ')}>
				<div className='doc-image-reactionBar-btn-emojiContainer'>
					{this.props.reaction}
				</div>
				{reactionCount > 0 &&
					<div className='doc-image-reactionBar-btn-reactionCount'>
						{reactionCount}
					</div>
				}
			</button>						
		)
	}
}

class DocImageReactionBar extends React.Component{  
 

	render(){ 
		
		return (			
			<div className='doc-image-reactionBarContainer'>
				<div className='doc-image-reactionBar'>
					<DocImageReactionBarBtn 
						reaction='👍'
						//initialReactionCount={2}				
						initialReactionCount={0}				
					/>
					<DocImageReactionBarBtn 
						reaction='🙌'
						initialReactionCount={0}
					/>
					<DocImageReactionBarBtn 
						reaction='🔥'
						//initialReactionCount={1}
						initialReactionCount={0}
					/>				
					<DocImageReactionBarBtn 
						reaction='🙋‍♂️'
						initialReactionCount={0}
					/>
					<DocImageReactionBarBtn 
						reaction='😍'
						initialReactionCount={0}
					/>
				</div>						
			</div>
		)
	}
}

export default DocImageReactionBar
