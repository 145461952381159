import store from '../../store'
import {updateProjectRoadmapsRequest} from '../../actions/projects'

//Takes full project object

export function updateProjectRoadmaps(projectId,actions,showToast){


	store.dispatch(updateProjectRoadmapsRequest(projectId,actions,showToast))
}
