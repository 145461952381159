import schema from '../../schema/editorSchema'  
import {randomID} from '../../../utils/randomID'
import store from '../../../store'
import {TextSelection} from "prosemirror-state"
import {createWhiteboardEmbed} from '../../../actions/whiteboardEmbed'
import {getDocForId} from '../../../utils/getDocForId'

export function insertWhiteboardEmbed(snapshot,displayDimensions) {
	const embedId=`${randomID()}`
	const reduxState = store.getState()
	const docId=reduxState.whiteboardModal.docId
	const docObj=getDocForId(docId)
	let projectId
	if(docObj){
		projectId=docObj.project
	}

	store.dispatch(createWhiteboardEmbed(embedId,snapshot,displayDimensions,docId,projectId))

	const state=window.view.state
	let insertPos=state.selection.from
	let tr=state.tr
	const node=schema.nodes.docWhiteboardEmbed.createAndFill({embedId:embedId})
	tr.replaceWith(state.selection.from,state.selection.to,node)
	let selection=new TextSelection(tr.doc.resolve(state.selection.from+node.nodeSize+1))
	tr.setSelection(selection)
	window.view.dispatch(tr)
}

//Old
// export function insertWhiteboardEmbed(svgString,snapshot,blob,blobUrl) {
// 	const embedId=`${randomID()}`
// 	const reduxState = store.getState()
// 	const docId=reduxState.whiteboardModal.docId
// 	const docObj=getDocForId(docId)
// 	let projectId
// 	if(docObj){
// 		projectId=docObj.project
// 	}

// 	store.dispatch(createWhiteboardEmbed(embedId,svgString,snapshot,blob,blobUrl,docId,projectId))

// 	const state=window.view.state
// 	let insertPos=state.selection.from
// 	let tr=state.tr
// 	const node=schema.nodes.docWhiteboardEmbed.createAndFill({embedId:embedId})
// 	console.log(node)
// 	tr.replaceWith(state.selection.from,state.selection.to,node)
// 	let selection=new TextSelection(tr.doc.resolve(state.selection.from+node.nodeSize+1))
// 	tr.setSelection(selection)
// 	window.view.dispatch(tr)
// }
