import store from '../store'
import filter from 'lodash/filter'




export function getProjectSubDocs(projectId) {	
	const state = store.getState()
	//subDocs= filter(state.docs, {'project':projectId,is_project_doc:false})
	let subDocs = filter(state.docs, function(doc) { 
		if(doc.project==projectId && !doc.is_project_doc && !doc.milestone){
			return doc
		}
	})
	return subDocs
}

