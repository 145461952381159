import React from 'react'
import Icon from '../../../misc/Icon'
import Measure from 'react-measure'
import MessageMediaHighlightVideo from './MessageMediaHighlightVideo'

class MessageMediaHighlight extends React.Component{  

	constructor(props){
    super(props)    

    this.state={
      inlineAvailableWidth: -1,       
    }    
  }  
  

	render(){	
		const {tenorMp4Url,isUnread, muxPlaybackID,duration,description}=this.props
		const {inlineAvailableWidth}=this.state	
		return (
			<React.Fragment>
				<div className='message-para message-para--autogeneratedStyling'>
					added a highlight
				</div>
				<div className='message-content-highlight'>				
					<MessageMediaHighlightVideo 
						inlineAvailableWidth={inlineAvailableWidth}
						muxPlaybackID={muxPlaybackID}
						highlightId={this.props.highlightId}
					/>
					<div className='message-content-highlight-description'>
						<div className='message-para'>
							{this.props.description}
						</div>
					</div>

					{/*}
					<div className='message-content-highlight-actionRow'>
						{/*<button onClick={this.props.openRecordingModal}className='message-content-highlight-actionRow-btn'>
							<div className='message-content-highlight-actionRow-btn-iconContainer'>
								<Icon name='playRectangle' />
							</div>
							<div className='message-content-highlight-actionRow-btn-label'>
								Watch Full Recording
							</div>
						</button>
						{/*<button className='message-content-highlight-actionRow-btn'>
							<div className='message-content-highlight-actionRow-btn-iconContainer'>
								<Icon name='link' />
							</div>
							<div className='message-content-highlight-actionRow-btn-label'>
								Copy Link
							</div>
						</button>
						</div>
						*/}
					
				{/* INLINE WIDTH MEASURER */}
				<Measure
	        bounds	        
        	onResize={contentRect => {
          	this.setState({ inlineAvailableWidth: contentRect.bounds.width })
        	}}
	      >
	        {({ measureRef }) => (
  					<div ref={measureRef} className='message-content-highlightInlineMeasurer' /> 
  			 )}
	    	</Measure>				
			</div>
			</React.Fragment>
		)
	}
}
 


export default MessageMediaHighlight
