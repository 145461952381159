import React from 'react'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import {getTeams} from '../../../utils/getTeams'
import {getTeamForId} from '../../../utils/getTeamForId'
import {getProjectForId} from '../../../utils/getProjectForId'
import StaticCheckbox from '../../uiKit/StaticCheckbox'
import * as ContextMenu from '@radix-ui/react-context-menu';
import sortBy from 'lodash/sortBy'

class RoadmapsProjectRowEditTeamsContextSubMenuSingleSelectItem extends React.Component{  	
	
	constructor(props){
		super(props)   
		this.handleSelectSingleSelectTeam=this.handleSelectSingleSelectTeam.bind(this)		
	}

	handleSelectSingleSelectTeam(){
		let newTeamsArray=[]
		newTeamsArray.push(this.props.team.team_id)
		this.props.updateProjectTeams(newTeamsArray)		
	}

	render(){	
		const {active, team} = this.props			
		return (
			<ContextMenu.Item onSelect={this.handleSelectSingleSelectTeam} className={'popoverMenu-item popoverMenu-item--editTeams'  + (active ? ' popoverMenu-item--active ' : ' popoverMenu-item--inactive ')}>
				<div className='popoverMenu-item-markerContainer'>
					<LinearMarker teamId={team.team_id} />
				</div>
				<div className='popoverMenu-item-label'>
					{team.name}
				</div>													
			</ContextMenu.Item>
		)
	}
}


class RoadmapsProjectRowEditTeamsContextSubMenuMultiSelectItem extends React.Component{  	
	constructor(props){
		super(props)   
		this.handleSelectTeam=this.handleSelectTeam.bind(this)
		this.state={
      checked: this.props.active
    }
	}

	handleSelectTeam(){
		let newCheckedStatus=!this.state.checked
		let newTeamsArray=[]
		if(newCheckedStatus==true){
			newTeamsArray=this.props.projectTeams
			newTeamsArray.push(this.props.team.team_id)
		}else{
			if(this.props.projectTeams.length>1){ //dont allow unselect if only 1 team selected
				this.props.projectTeams.forEach((teamId)=>{
					if(teamId!=this.props.team.team_id){
						newTeamsArray.push(teamId)
					}
				})
			}else{
				newTeamsArray=this.props.projectTeams
				newCheckedStatus=true
			}
		}
		this.setState({checked: newCheckedStatus})		
		this.props.updateProjectTeams(newTeamsArray)
	}


	render(){	
		const {active, team} = this.props	
		const {checked} = this.state
		return (
			<div onClick={this.handleSelectTeam} className='popoverMenu-item popoverMenu-item--editTeams'>
				<div className='popoverMenu-item-markerContainer'>
					<LinearMarker teamId={team.team_id} />
				</div>
				<div className='popoverMenu-item-label'>
					{team.name}
				</div>				
				<div className='popoverMenu-item-checkboxContainer'>
					<StaticCheckbox 
						checked={checked}
					/>					
				</div>
			</div>
		)
	}
}


class RoadmapsProjectRowEditTeamsContextSubMenu extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSwitchSelectType=this.handleSwitchSelectType.bind(this)
		const teams = props.teams
		let multipleTeams = false
		if(teams && teams.length > 1){
			multipleTeams=true
		}
		this.state={
      multiSelect: multipleTeams
    }
	}

	handleSwitchSelectType(){
		this.setState({multiSelect: true})		
	}

	render(){	
		const {projectId} = this.props
		const {multiSelect} = this.state
		let projectTeams =this.props.teams
		const orgTeams = getTeams()
		const sortedTeams = sortBy(orgTeams, function(team){
   		return team.name.toString().toLowerCase()
   	})
		return (
			<React.Fragment>         	 
				{!multiSelect && sortedTeams &&					
					<React.Fragment>
						{sortedTeams.map((orgTeam) => {
							const isActive = projectTeams.includes(orgTeam.team_id);
							return (
								<RoadmapsProjectRowEditTeamsContextSubMenuSingleSelectItem
									key={orgTeam.team_id}									
									team={orgTeam}
									active={isActive}			
									updateProjectTeams={this.props.updateProjectTeams}	      				      
								/>
							);
						})
						}
					</React.Fragment>
				}			
				{multiSelect && sortedTeams &&			
					<React.Fragment>
						{sortedTeams.map((orgTeam) => {
							const isActive = projectTeams.includes(orgTeam.team_id);
						  return (
						    <RoadmapsProjectRowEditTeamsContextSubMenuMultiSelectItem
						      key={orgTeam.team_id}						      
						      team={orgTeam}
						      active={isActive}		
						      projectTeams={projectTeams}
						      updateProjectTeams={this.props.updateProjectTeams}		      				      
						    />
				  		);
						})
					}
					</React.Fragment>
				}			
				{!multiSelect && 
					<React.Fragment>
						<div className='popoverMenu-separator popoverMenu-separator--spacer' />
						<div onClick={this.handleSwitchSelectType} className='popoverMenu-item popoverMenu-item--multipleTeams'>					
							<div className='popoverMenu-item-label'>
								Select multiple teams
							</div>									
						</div>
					</React.Fragment>
				}

			</React.Fragment>
		)
	}
}

export default RoadmapsProjectRowEditTeamsContextSubMenu