import React from 'react'

class ElectronMacDots extends React.Component{  

  render(){ 

    const {sideMenuVisible}= this.props
    const displayMacDots = sideMenuVisible
        
    return (            
      <React.Fragment>

        {displayMacDots &&
          <div className={'electronMacDots ' + (this.props.apiErrorActive ? 'electronMacDots--apiErrorState' : '')}>
            <div className='electronMacDots-dot' />
            <div className='electronMacDots-dot' />
            <div className='electronMacDots-dot' />
          </div>      
        }

      </React.Fragment>
    )
  }
}

export default ElectronMacDots
