

const TranscriptWordNodeSpec ={
    attrs: {
      start:{default:''},
      end:{default:''}
    },
    inline: true,
    group: "inline",
    content:"text*",
    draggable: true,
    selectable:false,
    parseDOM: [
    {tag: 'span'},
   ],
    toDOM(node) {
      return ['span',{class:'message-para-transcriptWord'},0]
  }
  
}
    
  //  span className='message-para-transcriptWord

  export default TranscriptWordNodeSpec


// const TranscriptWordNodeSpec = {
//   attrs: {
//     start: { default: '' },
//     end: { default: '' }
//   },
//   inline: true,
//   group: "inline",
//   content: "text*",
//   draggable: true,
//   parseDOM: [
//     {
//       tag: 'span.transcriptWord',  // Instead of custom tag, use a span with a class
//       getAttrs: (node) => {  // Get the attributes from the DOM
//         return {
//           start: node.getAttribute('data-start') || '',
//           end: node.getAttribute('data-end') || ''
//         }
//       }
//     }
//   ],
//   toDOM(node) {
//     return ['span', { class: 'transcriptWord', 'data-start': node.attrs.start, 'data-end': node.attrs.end }, 0];
//   }
// }

// export default TranscriptWordNodeSpec;