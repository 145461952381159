import React from 'react'
import Icon from '../misc/Icon'
import { withRouter} from 'react-router-dom'
import moment from 'moment';
import {getCurrentUser} from '../../utils/getCurrentUser'
import SetupTeamSyncModal from './setupTeamSyncModal/SetupTeamSyncModal'
import MeetingsPageGCalIntegrationButton from './MeetingsPageGCalIntegrationButton'
import find from 'lodash/find'
import filter from 'lodash/filter'
import MeetingsPageTable from './MeetingsPageTable'
import MeetingsPageYourCalendar from './MeetingsPageYourCalendar'
import MeetingsPageYourCalendarProto from './MeetingsPageYourCalendarProto'
import MeetingsPageNewMeetingPopover from './MeetingsPageNewMeetingPopover'

function formatMeetingsAndEvents(meetings,calEvents,meetingsPageSettings){
	let meetingsAndEvents=meetings.slice()
	calEvents.forEach((event)=>{
		//check event has not been hidden
		if(!meetingsPageSettings[event.id]){
			const matchedMeeting=find(meetings,{event_id:event.id})
			if(!matchedMeeting){
				const startObj=event.start 
				let dateTimeISO = moment.parseZone(startObj.dateTime).utc().toISOString();
				let modifiedEvent={...event}
				modifiedEvent.meeting_start=dateTimeISO
				meetingsAndEvents.push(modifiedEvent)
			}
		}
	})
	return meetingsAndEvents
}



class MeetingsPage extends React.PureComponent{  
	
	constructor(props){
    super(props)
    this.closeSetupTeamSyncModal=this.closeSetupTeamSyncModal.bind(this)      
    this.state={
    //  meetingTypeFilter: null, // each type
      meetingParticipantsFilter: "orgMeetings", // yourMeetings or orgMeetings
      upcomingMeetingTab: "nextWeek", 
      showSetupTeamSyncModal: false,
      showGCalThisWeek: false,
      showGCalNext30Days: false,
      showNewMeetingPopover: false,
    }
  }

	closeSetupTeamSyncModal(){
		this.setState({showSetupTeamSyncModal: false})
	}

	render(){	
		const {meetings,calendarEvents,meetingsPageSettings}=this.props
		const {meetingTypeFilter,meetingParticipantsFilter,upcomingMeetingTab,showGCalThisWeek,showGCalNext30Days}=this.state
		const meetingsAndEvents=formatMeetingsAndEvents(meetings,calendarEvents,meetingsPageSettings)
	  return (	  		  
		  	<div className='meetingNotesPage'>
		  		<div className='meetingNotesPage-headerContainer'>
			  		<div className='meetingNotesPage-leftSpacer' />	 
						<div className='meetingNotesPage-center'>	 
							<div className='meetingNotesPage-header'>	  			  		
				  			<div className='meetingNotesPage-header-title'>	
				  				<div className='meetingNotesPage-header-title-iconContainer'>	
										<Icon name='calendarLight' />	
									</div>									
				  				<div className='meetingNotesPage-header-title-label'>	
				  					Meeting Notes
				  				</div>
				  			</div>	
				  			{/*}
				  			<div className='meetingNotesPage-header-hSpacer' />
         
			          <MeetingsPageNewMeetingPopover
			            showPopover={this.state.showNewMeetingPopover}
			            openPopover={()=>{this.setState({showNewMeetingPopover: true})}}
			            closePopover={()=>{this.setState({showNewMeetingPopover: false})}}
			            createMeeting={(value)=>{this.props.createMeeting(value)}}
			            location='meetingsHome'
			          >
						  		<button onClick={()=>{this.setState({showNewMeetingPopover: true})}}  className='meetingNotesPage-header-newMeetingBtn'>
						  			<div className='meetingNotesPage-header-newMeetingBtn-iconContainer'>
						  				<Icon name='plus' />
						  			</div>
						  			<div className='meetingNotesPage-header-newMeetingBtn-label'>
						  				New
						  			</div>
						  		</button>	  			
          			</MeetingsPageNewMeetingPopover>
          			*/}


				  		</div>	
				  	</div>				  
			  		<div className='meetingNotesPage-rightPanel' />								  	
			  	</div>
					
					
					<MeetingsPageGCalIntegrationButton/>										
			  	
			  	
			  	<div className='meetingNotesPage-yourCalendarOuterContainer'>		
			  		<div className='meetingNotesPage-leftSpacer' />	 	  	
			  		<div className='meetingNotesPage-center'>	 					  	
					  	<div className='meetingNotesPage-yourCalendarContainer'>			  					  			
					  		<MeetingsPageYourCalendar
						  		meetings={meetings}
						  		calendarEvents={calendarEvents}
						  	/>
							</div>						
							{/*I}
							<div className='meetingNotesPage-yourCalendarContainer'>			  					  			
					  		<MeetingsPageYourCalendarProto						  		
						  	/>
							</div>						
							*/}
						</div>
						<div className='meetingNotesPage-rightPanel' />
					</div>


					<div className='meetingNotesPage-tableContainer'>
						<div className='meetingNotesPage-leftSpacer' />	 
						<div className='meetingNotesPage-center'>	 					  		
					  		<MeetingsPageTable 
						  		meetings={meetings}
						  		createMeeting={this.props.createMeeting}
						  	/>						  					
						  </div>									
						<div className='meetingNotesPage-rightPanel' />		
					</div>		


		  	</div>	  	
	  )
	}
}

export default withRouter(MeetingsPage)



	{/*}
		  		<div className='meetingNotesPage-content'>

		  			<button className='meetingNotesPage-header-newMeetingBtn' onClick={()=>{this.props.createMeeting('teamSync')}}>
	  					<div className='meetingNotesPage-header-newMeetingBtn-iconContainer'>
	  						<Icon name='plus' />
	  					</div>
	  					<div className='meetingNotesPage-header-newMeetingBtn-label'>
	  						New Team Sync Meeting
	  					</div>
  					</button>

  					<button className='meetingNotesPage-header-newMeetingBtn' onClick={()=>{this.props.createMeeting('adhoc')}}>
	  					<div className='meetingNotesPage-header-newMeetingBtn-iconContainer'>
	  						<Icon name='plus' />
	  					</div>
	  					<div className='meetingNotesPage-header-newMeetingBtn-label'>
	  						New Adhoc Meeting
	  					</div>
  					</button>

  					<button className='meetingNotesPage-header-newMeetingBtn' >
	  					<div className='meetingNotesPage-header-newMeetingBtn-iconContainer'>
	  						<Icon name='plus' />
	  					</div>
	  					<div className='meetingNotesPage-header-newMeetingBtn-label'>
	  						New Design Review Meeting
	  					</div>
  					</button>

  					<button className='meetingNotesPage-header-newMeetingBtn' onClick={()=>{this.props.createMeeting('discovery')}}>
	  					<div className='meetingNotesPage-header-newMeetingBtn-iconContainer'>
	  						<Icon name='plus' />
	  					</div>
	  					<div className='meetingNotesPage-header-newMeetingBtn-label'>
	  						New Discovery Meeting
	  					</div>
  					</button>


  					{/*}

		  			CAL EVENTS 
		  		<div>	
		  		{calendarEvents.map((event)=>{
		  			return(
		  				<div>{event.summary} {event.start.dateTime}</div>
		  				)

		  		})}

		  		</div>
		  		<br/>
		  		<br/>
		  			*/}

	  			  		
		  			{/*}
		  		

					</div>
					*/}




	  		{/*
	  		<div className='meetingNotesPage'>
	  		<div className='meetingNotesPage-leftSpacer'/>

				<div className='meetingNotesPage-center'>	 
					<div className='meetingNotesPage-header'>	  			  		
		  			<div className='meetingNotesPage-header-title'>	
		  				<div className='meetingNotesPage-header-title-iconContainer'>	
								<Icon name='draftsLight' />	
							</div>
		  				<div className='meetingNotesPage-header-title-label'>	
		  					Meetings
		  				</div>
		  			</div>
		  			<div className='meetingNotesPage-header-rightContainer'>	
		  				<button className='meetingNotesPage-header-newDocBtn' onClick={this.props.createMeeting}>
		  					<div className='meetingNotesPage-header-newDocBtn-iconContainer'>
		  						<Icon name='plus' />
		  					</div>
		  					<div className='meetingNotesPage-header-newDocBtn-label'>
		  						New Meeting
		  					</div>
	  					</button>
		  			</div>
		  		</div>		
		  		
		  		<div className='meetingNotesPage-content'>	  			  		
		  			
					</div>
				</div>	
	  	</div>
	  	*/}



{/*}

	  					GRID APPROACH, NOT CONVINCED, MAYBE LATER 
		  			<div className='meetingNotesPage-upcomingGrid meetingNotesPage-upcomingGrid--thisWeek'>
		  				<div className='meetingNotesPage-upcomingGrid-header'>
		  					<div className='meetingNotesPage-upcomingGrid-header-title'>
		  						This Week
		  					</div>
		  				</div>
		  				<div className='meetingNotesPage-upcomingGrid-grid'>
		  					{meetings.map((meeting)=>{
				  				return(
				  					<MeetingsPageGridLargeItem
				  						key={meeting.meeting_id}
				  						meeting={meeting}
				  					/>
				  					)
					  		})}						  	
		  				</div>
		  				
		  			</div>

		  			<div className='meetingNotesPage-upcomingGrid meetingNotesPage-upcomingGrid--nextWeek'>
		  				<div className='meetingNotesPage-upcomingGrid-header'>
		  					<div className='meetingNotesPage-upcomingGrid-header-title'>
		  						Next Week
		  					</div>
		  				</div>
		  				<div className='meetingNotesPage-upcomingGrid-grid'>
		  					<div className='meetingNotesPage-upcomingGrid-smallItem' />
		  					<div className='meetingNotesPage-upcomingGrid-smallItem' />
		  					<div className='meetingNotesPage-upcomingGrid-smallItem' />
		  					<div className='meetingNotesPage-upcomingGrid-smallItem' />
		  					<div className='meetingNotesPage-upcomingGrid-smallItem' />
		  					<div className='meetingNotesPage-upcomingGrid-smallItem' />
		  					<div className='meetingNotesPage-upcomingGrid-smallItem' />
		  					<div className='meetingNotesPage-upcomingGrid-smallItem' />
		  				</div>
		  			</div>	
		  			*/}



				  	{/*}
			  		<MeetingsPageMenu 
	  					createMeeting={this.props.createMeeting}
	  					newBlankMeeting={()=>{this.props.createMeeting('empty')}}
	  					newDesignReview={()=>{this.props.createMeeting('designReview')}}
	  					newDiscoveryCall={()=>{this.props.createMeeting('discovery')}}
	  					newTeamSync={()=>{this.props.createMeeting('teamSync')}}
	  					meetingTypeFilter={meetingTypeFilter}
	  					setMeetingTypeFilter={(value)=>{this.setState({meetingTypeFilter: value})}}
	  					meetingParticipantsFilter={meetingParticipantsFilter}
	  					setMeetingParticipantsFilter={(value)=>{this.setState({meetingParticipantsFilter: value})}}
	  					openTeamSyncSetupModal={()=>{this.setState({showSetupTeamSyncModal: true})}}
				  	/>
			  		<div className='meetingNotesPage-content'>
			  			<MeetingsPageGCalIntegrationButton/>
			  			<div className='meetingNotesPage-upcoming'>
			  				<div className='meetingNotesPage-upcoming-pane'>
				  				<div className='meetingNotesPage-upcoming-pane-header'>
				  					<button className='meetingNotesPage-upcoming-pane-header-tab meetingNotesPage-upcoming-pane-header-tab--active'>
				  						This Week
				  					</button>
				  				</div>				  								  		
					  			<div className='meetingNotesPage-meetingList'>								  	
		
							  		{thisWeekMeetings.map((meeting)=>{
						  				if(meeting.meeting_id){
						  					return(
							  					<MeetingsPageMeetingRow
							  						key={meeting.meeting_id} 
							  						meeting={meeting}
							  						section="thisWeek"
							  					/>
							  				)
						  				}else{
						  					return(
							  					null
							  				)
						  				}
							  		})}		

							  		{thisWeekMeetings.length < 1 && 
								  		<div className='meetingNotesPage-meetingList-empty'>
								  			{thisWeekEmptyState}
								  		</div>
						  			}

						  			{!meetingTypeFilter && !showGCalThisWeek && 
						  				<button onClick={()=>{this.setState({showGCalThisWeek: true})}} className='meetingNotesPage-meetingList-showGCalEvents'>
								  			Show {thisWeekGCalOnlyCount} events from your calendar
								  		</button>
						  			}

						  			{!meetingTypeFilter && showGCalThisWeek &&
						  				<button onClick={()=>{this.setState({showGCalThisWeek: false})}} className='meetingNotesPage-meetingList-showGCalEvents'>
								  			Hide calendar events
								  		</button>
						  			}

						  			{!meetingTypeFilter && showGCalThisWeek && thisWeekMeetings.map((meeting)=>{
						  				if(meeting.meeting_id){
						  					return(
							  					null
							  				)
						  				}else{
						  					return(
							  					<MeetingsPageGCalEventRow key={meeting.id} event={meeting} />
							  				)
						  				}
							  		})}		


					  			</div>
				  			</div>
				  			<div className='meetingNotesPage-upcoming-pane'>
				  				<div className='meetingNotesPage-upcoming-pane-header'>
				  					<button className='meetingNotesPage-upcoming-pane-header-tab meetingNotesPage-upcoming-pane-header-tab--active'>
				  						Next 30 days
				  					</button>
				  					{/*}
				  					<button onClick={()=>{this.setState({upcomingMeetingTab: 'nextWeek'})}} className={'meetingNotesPage-upcoming-pane-header-tab ' + (upcomingMeetingTab === 'nextWeek' ? ' meetingNotesPage-upcoming-pane-header-tab--active ' : ' meetingNotesPage-upcoming-pane-header-tab--inactive ')}>
				  						Next Week
				  					</button>				  									  				
				  					<button onClick={()=>{this.setState({upcomingMeetingTab: 'afterNextWeek'})}} className={'meetingNotesPage-upcoming-pane-header-tab ' + (upcomingMeetingTab === 'afterNextWeek' ? ' meetingNotesPage-upcoming-pane-header-tab--active ' : ' meetingNotesPage-upcoming-pane-header-tab--inactive ')}>
				  						Scheduled
				  					</button>
				  					
				  					
				  				</div>
				  				<div className='meetingNotesPage-meetingList'>
				  					

								  	{next30DaysMeetings.length > 0 && next30DaysMeetings.map((meeting)=>{
						  				if(meeting.meeting_id){
						  					return(
							  					<MeetingsPageMeetingRow
						  						key={meeting.meeting_id} 
						  						meeting={meeting}
						  						section="nextWeek"
						  					/>
							  				)
						  				}else{
						  					return(
							  					null
							  				)
						  				}
							  		})}

							  		{next30DaysMeetings.length < 1 && 
								  		<div className='meetingNotesPage-meetingList-empty'>
								  			{scheduledEmptyState}
								  		</div>
							  		}

							  		{!meetingTypeFilter && !showGCalNext30Days &&
						  				<button onClick={()=>{this.setState({showGCalNext30Days: true})}} className='meetingNotesPage-meetingList-showGCalEvents'>
								  			Show {next30DaysGCalOnlyCount} events from your calendar
								  		</button>
						  			}

						  			{!meetingTypeFilter && showGCalNext30Days && 
						  				<button onClick={()=>{this.setState({showGCalNext30Days: false})}} className='meetingNotesPage-meetingList-showGCalEvents'>
								  			Hide calendar events
								  		</button>
						  			}


						  			{!meetingTypeFilter && showGCalNext30Days && next30DaysMeetings.map((meeting)=>{
						  				if(meeting.meeting_id){
						  					return(
							  					null
							  				)
						  				}else{
						  					return(
							  					<MeetingsPageGCalEventRow key={meeting.id} event={meeting} />
							  				)
						  				}
							  		})}		

				  					{/*}
				  					{upcomingMeetingTab === 'nextWeek' && nextWeekMeetings.length > 0 && nextWeekMeetings.map((meeting)=>{
						  				if(meeting.meeting_id){
						  					return(
							  					<MeetingsPageMeetingRow
							  						key={meeting.meeting_id} 
							  						meeting={meeting}
							  						section="nextWeek"
							  					/>
							  				)
						  				}else{
						  					return(
							  					<MeetingsPageGCalEventRow key={meeting.id} event={meeting} />
							  				)
						  				}
							  		})}

							  		{upcomingMeetingTab === 'nextWeek' && nextWeekMeetings.length < 1 && 
								  		<div className='meetingNotesPage-meetingList-empty'>
								  			No meetings next week.
								  		</div>
							  		}
							  		
								  	{upcomingMeetingTab === 'afterNextWeek' && afterNextWeekMeetings.length > 0 && afterNextWeekMeetings.map((meeting)=>{
						  				if(meeting.meeting_id){
						  					return(
							  					<MeetingsPageMeetingRow
						  						key={meeting.meeting_id} 
						  						meeting={meeting}
						  						section="nextWeek"
						  					/>
							  				)
						  				}else{
						  					return(
							  					<MeetingsPageGCalEventRow key={meeting.id} event={meeting} />
							  				)
						  				}
							  		})}

							  		{upcomingMeetingTab === 'afterNextWeek' && afterNextWeekMeetings.length < 1 && 
								  		<div className='meetingNotesPage-meetingList-empty'>
								  			No scheduled meetings.
								  		</div>
							  		}
							  		

				  				</div>				  					
				  			</div>
				  		</div>

				  		

			  			{/*{pastMeetings && 
				  			<div className='meetingNotesPage-pastMeetings'>
				  				<div className='meetingNotesPage-pastMeetings-header'>
				  					{pastMeetingsHeader}
				  				</div>
				  				<div className='meetingNotesPage-pastMeetings-list'>		  					
							  		{pastMeetings.map((meeting)=>{
						  				if(meeting.meeting_id){
						  					return(
							  					<MeetingsPageMeetingRow
						  						key={meeting.meeting_id} 
						  						meeting={meeting}
						  						section="allMeetings"
						  					/>
							  				)
						  				}else{
						  					return(
							  					<MeetingsPageGCalEventRow key={meeting.id} event={meeting} />
							  				)
						  				}
							  		})}		
							  		{pastMeetings.length < 1 && 
								  		<div className='meetingNotesPage-meetingList-empty'>
								  			{pastMeetingsEmptyState}
								  		</div>
							  		}			  		
				  				</div>
				  			</div>
			  			}
			  		</div>
			  		</div>
			  		*/}