//import {wrappingInputRule} from 'prosemirror-inputrules'
//import {modifiedWrappingInputRule} from './modifiedWrappingInputRule'
import schema from '../../schema/editorSchema'
import {getAnchorNode} from '../../utils/getAnchorNode'
import {wrappingInputRule} from 'prosemirror-inputrules'


export function numberListInputRule(){
	return new wrappingInputRule(/^1([\.|\)])\s/g, schema.nodes.bulletListItem,(match)=>{
		const state=window.view.state
		let {$from} = state.selection
		const anchorNode=getAnchorNode($from,'paragraph')
		let indentLevel=0
		if(anchorNode){ //Think this should always be true so might not need this logic
			if(anchorNode.attrs.indentLevel){
				indentLevel=anchorNode.attrs.indentLevel
			}
		}
		let separatorType
		if(match[1]=='.'){
			separatorType='period'
		}else if(match[1]==')'){
			separatorType='bracket'
		}
		return {indentLevel:indentLevel,markerType:'number',separatorType:separatorType}
	}
	)
}

