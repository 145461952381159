import React from 'react'
import Icon from '../misc/Icon'
import data from '../../emojiData'
import * as Tooltip from '@radix-ui/react-tooltip';
import { SearchIndex } from 'emoji-mart'



async function search(value) {
  const emojis = await SearchIndex.search(value)
  return emojis
}



class EditProjectIconPopoverEmoji extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSearchInputChange = this.handleSearchInputChange.bind(this)
		this.state={
      searchInputValue: '',
      hoverPreviewEmoji: null,
      searchResults:[]
    }
	}

	handleSelectEmoji(emoji){
		const code=emoji.skins[0].shortcodes
		this.props.updateProjectIcon(code)
		this.props.closePopover()
	}

	handleSearchInputChange(event) {  
    this.setState({searchInputValue: event.target.value})  
    if(event.target.value){
    	const searchPromise=search(event.target.value)
    	return Promise.resolve(searchPromise).then((results)=>{
    		this.setState({searchResults:results})
			})
    }  else{
    	this.setState({searchResults:[]})
    }
  }

	render(){	
		const {active, roadmap} = this.props	
		const {searchInputValue, hoverPreviewEmoji} = this.state
		return (
			<React.Fragment>
				<div className='editMetadataPopover--type--projectIcon-content-emojiSearchBarContainer'>
					<div className='editMetadataPopover--type--projectIcon-content--emoji-searchBar'>
						<input 
							onClick={()=>{console.log('click input')}}
							id='emoji-picker-search-input'
							className='editMetadataPopover--type--projectIcon-content--emoji-searchBar-input'
							placeholder='Search emoji'
							value={searchInputValue}					  						
							onChange={this.handleSearchInputChange} 				
							autoComplete="off"
							autoCorrect="off"
							autoFocus
							spellCheck={false}								
						/>
					</div>
					<div className='editMetadataPopover--type--projectIcon-content-switchBtnContainer'>
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>														
									<button onClick={this.props.switchToLinearIcons}  className='editMetadataPopover--type--projectIcon-content-switchBtn'>
										<Icon name='linearIcon' />
									</button>
								</Tooltip.Trigger>																 
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Switch to Linear Icons
								</Tooltip.Content>																														
							</Tooltip.Root>
						</Tooltip.Provider>						
					</div>
				</div>		
				<div className='editMetadataPopover--type--projectIcon-content-emojiGrid' onMouseLeave={()=>{this.setState({hoverPreviewEmoji: null})}} >
					{!searchInputValue && data.categories.map((category,index)=>{
						const categoryName = category.id;
						const emojiNames = category.emojis;
						let categoryLabel = categoryName
						
						// matching Slack's
						if(categoryName === 'people'){
							categoryLabel = 'Smileys & People'
						}
						if(categoryName === 'nature'){
							categoryLabel = 'Animals & Nature'
						}
						if(categoryName === 'foods'){
							categoryLabel = 'Food & Drink'
						}
						if(categoryName === 'activity'){
							categoryLabel = 'Activities'
						}
						if(categoryName === 'places'){
							categoryLabel = 'Travel & Places'
						}

						if(categoryName !== 'frequent'){
							return(
							 <div key={`category_${index}`} className="editMetadataPopover--type--projectIcon-content-emojiGrid-section">
			          <div className="editMetadataPopover--type--projectIcon-content-emojiGrid-section-header">
			          	{categoryLabel}
			          </div>
				          <div className="editMetadataPopover--type--projectIcon-content-emojiGrid-section-content">
				            {emojiNames.map((emojiName, i) => {
				              const emoji = data.emojis[emojiName];

				              if(emoji.skins[0].native && emoji.skins[0].native.length > 0){
				              	return (
													<button key={`${emojiName}_${i}`} onClick={()=>{this.handleSelectEmoji(emoji)}} onMouseEnter={()=>{this.setState({hoverPreviewEmoji: emoji})}} onMouseLeave={()=>{this.setState({hoverPreviewEmoji: null})}} className="editMetadataPopover--type--projectIcon-content-emojiBtn">
			                  		{emoji.skins[0].native}
			                		</button>															
					              );	
				              }            
            				})}
         				 </div>
       				 </div>
							)
					}
					})}

					{searchInputValue &&
						<div key={`category_search_results`} className="editMetadataPopover--type--projectIcon-content-emojiGrid-section">
							{this.state.searchResults.length > 0 &&
								<div className="editMetadataPopover--type--projectIcon-content-emojiGrid-section-header">
									Search Results
								</div>
							}
							{this.state.searchResults.length === 0 &&
								<div className="editMetadataPopover--type--projectIcon-content-emojiGrid-section-header">
									No results
								</div>
							}
							<div className="editMetadataPopover--type--projectIcon-content-emojiGrid-section-content">
								{this.state.searchResults.map((emoji, i) => {
									return (
										<button key={i} onClick={()=>{this.handleSelectEmoji(emoji)}} onMouseEnter={()=>{this.setState({hoverPreviewEmoji: emoji})}} onMouseLeave={()=>{this.setState({hoverPreviewEmoji: null})}} key={emoji.name} className="editMetadataPopover--type--projectIcon-content-emojiBtn">
											{emoji.skins[0].native}
										</button>															
									)	
								})}            
							</div>
						</div>
					}

				</div>
				
				{hoverPreviewEmoji &&
					<div className='editMetadataPopover--type--projectIcon-content-footer'>					
						<div className='editMetadataPopover--type--projectIcon-content-footer-hoverPreview'>					
							<React.Fragment>
								<div className='editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon'>
									{hoverPreviewEmoji.skins[0].native}
								</div>
								<div className='editMetadataPopover--type--projectIcon-content-footer-hoverPreview-label'>
									{hoverPreviewEmoji.id}
								</div>
							</React.Fragment>						
						</div>							
					</div>
				}

			</React.Fragment>
		)
	}
}

export default EditProjectIconPopoverEmoji