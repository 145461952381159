import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'

export default function (state = [], action) {
	switch (action.type) {
    case 'SIGN_OUT':
      return []

    case 'DELETE_REACTION_REQUEST':
    case 'REACTION_DELETED_PUSHER':
      const reactionId = action.reactionId
      const index = findIndex(state,(reaction => {
       return reaction['reaction_id'] == reactionId
      }))
      if(index>-1){
        return [
          ...state.slice(0,index),
          ...state.slice(index + 1)
        ]
      }else return state

    case 'NEW_REACTION_PUSHER':
      const reaction=action.reaction
      const newReactionId = reaction.reaction_id
      let reactionIndex = findIndex(state,(reaction => {
       return reaction['reaction_id'] == newReactionId
      }))
      if(reactionIndex==-1){
        reactionIndex=state.length
      }
      return [
        ...state.slice(0,reactionIndex),
        reaction,
        ...state.slice(reactionIndex + 1)
      ]
      
    case 'ADD_REACTION_REQUEST':
      return [...state,action.tempReaction]
   
    case 'FETCH_REACTIONS_SUCCESS':
      const reactions=action.response
      let newState=state.slice()
      forEach(reactions, function(reaction) {      
        let indexOfReaction = newState.findIndex(reac => {
          return reac.reaction_id == reaction.reaction_id
        })
        if(indexOfReaction !== -1){ 
          newState =[
          ...newState.slice(0,indexOfReaction),
          ...newState.slice(indexOfReaction + 1)
        ]
        } 
        newState=[reaction,...newState]
      }
      )

    return newState

    default:
      return state;
	}
}
 