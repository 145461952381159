import React from 'react'
import LinearMilestoneIcon from '../misc/LinearMilestoneIcon'
import {getMilestoneForId} from '../../utils/getMilestoneForId'

class DocEditorMilestoneTitle extends React.Component{  
	render(){	    
    const {milestoneId}=this.props
    const milestone=getMilestoneForId(milestoneId)
	  return (	  	
      <React.Fragment>
      <div className='docEditor-docTitle-milestoneDocTitle'>
        <div className='docEditor-milestoneDocMarker'>
          <LinearMilestoneIcon 
            milestone={milestone}
            largeIcon
          />
        </div>      
      	<div className='docEditor-docTitle-milestoneDocTitle-title'>
        	{milestone?milestone.name:''}
        </div>
        <div className='docEditor-docTitle-milestoneDocTitle-bg' />
      </div>
      </React.Fragment>
	  )
	}
}

export default DocEditorMilestoneTitle
