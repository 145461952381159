import {fetchTenor} from '../api'

export function searchTenorGifs(searchTerm,limit) {
	let searchLimit=50
	if(limit){
		searchLimit=limit
	}
	return (dispatch) => fetchTenor(searchTerm,searchLimit)
		.then((response) => {
			return response
		})
}



