import {Plugin,PluginKey} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import React from 'react'
import ReactDOM from 'react-dom'
import filter from 'lodash/filter'
import {CellSelection} from 'prosemirror-tables'
//Plugin to show hover menu above selection 
 

const key = new PluginKey('selectionMenuPlugin')

const PARAGRAPH_SPAN_CLASSNAME="doc-para-span"
const SELECTION_MENU_HEIGHT=29
const MENU_WIDTH=402 // maybe this can be dynamically calculated

//TODO do we want to add/remove menu or add styling
//If its always there need to make sure you cant e.g. tab into it and select when press enter or somethimg

function positionMenuOverlay(top,left) {
  const el = document.getElementById('hoverMenu')
  if(el){ 
    let scrollTop = 0    
    const scrollableEl=document.getElementById('scrollable_div')
    if(scrollableEl){
      scrollTop=scrollableEl.scrollTop      
    }
    el.style.top = `${top - 80 + scrollTop}px`; // the 80 is the fixed doc-top menu and height of selection menu height
    el.style.width = `${MENU_WIDTH}px`;
    el.style.height = `${SELECTION_MENU_HEIGHT}px`;

    // Adjust left to prevent overflow into sidemenu tabs, where z-index is really hard
    // we also want don't want to switch to fixed position, because that has performance problems on scroll
    // This solution seems quite nice for now, i.e. only affects behaviour when necessary (smaller screens)

    let docAreaBounding
    let docAreaWidth = 0

    if(scrollableEl){      
      docAreaBounding=scrollableEl.getBoundingClientRect()
      docAreaWidth=docAreaBounding.width      
    }
    
    const edgeBuffer = 8 // distance from edge of selection menu to scrollable div
    let minimumLeftRenderedPos = edgeBuffer
    let maximumLeftRenderedPos = docAreaWidth - MENU_WIDTH - edgeBuffer

    const adjustedLeft = Math.min(Math.max(minimumLeftRenderedPos, left), maximumLeftRenderedPos)

    el.style.left = `${adjustedLeft}px`;
    
    el.classList.remove("hoverMenu--hidden")
  }
}


function hideMenuOverlay() {
  const el = document.getElementById('hoverMenu') //New 24th Nov only add if it doesnt have the class-->try to stop flashing while typing
  if(el){ 
    if(el.classList.contains("hoverMenu--hidden")){
      //do nothing
    } 
    else el.classList.add("hoverMenu--hidden")
  }
}


function rectIsInSelection(rect,fromCoord,toCoord){
  let isInSelection=false
  if(rect.top>=fromCoord.top && toCoord.bottom>=rect.bottom){isInSelection=true}
  return isInSelection
}

//find the left and right coords for a single line
//If rect is not the same line as selection begining/end then coords are the same as the span rect
//If rect on the same line as selection start or end we "normalise"--> adjust so have a rect for partial width contained in selection
//e.g. if rect is on the same line as the selection start we "normalise left"--> left is fromCoord.left NOT rect.left
function getLineSelectionCoords(rect,fromCoord,toCoord){
  let left
  let right
  let normaliseLeft=false
  if(rect.top==fromCoord.top){
    normaliseLeft=true
  }
  if(!normaliseLeft){
    left=rect.left
  }else{
    left=fromCoord.left
  }
  let normaliseRight=false
  if(rect.bottom==toCoord.bottom){
   normaliseRight=true
  }
  if(!normaliseRight){
    right=rect.right
  }else{
    right=toCoord.right       
  }
  return {left:left,right:right}
}

//Find the mimunum left and maximum right accross selected lines
function calculateMaxAndMin(fromCoord,toCoord){
  const paragraphSpans = document.getElementsByClassName(PARAGRAPH_SPAN_CLASSNAME);
  let minimumLeft   
  let maximumRight
  for (const span of paragraphSpans) {
    const rects = span.getClientRects(); //https://developer.mozilla.org/en-US/docs/Web/API/Element/getClientRects
    for (const rect of rects) {//Check each rect to see if it is contained in the selection      
      const isInSelection=rectIsInSelection(rect,fromCoord,toCoord)
      if(isInSelection){
        const lineCoords=getLineSelectionCoords(rect,fromCoord,toCoord)
        if(!minimumLeft || lineCoords.left<minimumLeft){
          minimumLeft=lineCoords.left
        }
        if(!maximumRight || lineCoords.right>maximumRight){
          maximumRight=lineCoords.right
        }
      }
    }
  }
  return {minimumLeft:minimumLeft,maximumRight:maximumRight}
}

//DocEditorContainer is the whole page
//Editor is where the text starts
function getInsetLeft(){
  let docEditorContainerLeft=0  
  const docEditorContainerElement=document.getElementById('docEditorContainer_div')
  if(docEditorContainerElement){
    docEditorContainerLeft= docEditorContainerElement.getBoundingClientRect().left
  }
  let editorLeft=0
  const editorElement=document.getElementById('editor')
  if(editorElement){
    editorLeft= editorElement.getBoundingClientRect().left
  }
  const insetLeft = editorLeft - docEditorContainerLeft // distance from doc Container to Editor
  return insetLeft
}



function normaliseXToEditorLeft(x){
  let normalisedX
  let docEditorContainerLeft=0
  const docEditorContainerElement=document.getElementById('docEditorContainer_div')
  if(docEditorContainerElement){
    docEditorContainerLeft= docEditorContainerElement.getBoundingClientRect().left
  }
  let editorLeft=0
  const editorElement=document.getElementById('editor')
  if(editorElement){
    editorLeft= editorElement.getBoundingClientRect().left
  }
  const insetLeft = editorLeft - docEditorContainerLeft // distance from doc Container to Editor
  normalisedX = x - insetLeft - docEditorContainerLeft
  return normalisedX
}

//average thing 
//take the average of maxRight and minLeft to center the menu
function calculateMenuLeftOffset(fromCoord,toCoord){
  const maxAndMin=calculateMaxAndMin(fromCoord,toCoord)
  const {maximumRight,minimumLeft}=maxAndMin

  //normalise all numbers relative to editor left (i.e. where the lines start-->makes the start of the line left=0)
  const normMaxRight=normaliseXToEditorLeft(maximumRight)
  const normMinLeft=normaliseXToEditorLeft(minimumLeft)

  const diff=normMaxRight-normMinLeft

  const normalisedMenuLeftOffset= normMinLeft + (diff/2) - (MENU_WIDTH / 2) 

  //denormalise-->add back in the offset to get abs position to layout parent
  const insetLeft=getInsetLeft() 
  const leftOffsetToLayoutParent=normalisedMenuLeftOffset+insetLeft
  return leftOffsetToLayoutParent
}




export function selectionMenuPlugin(handleSelectionMenuUpdate) {
  return new Plugin({
    key,
    view(view) {
      return {
        update: (view) => {
        const state=view.state
        let showMenu=false
        const selection=state.selection
        if (selection instanceof CellSelection){
          showMenu=false
        }else{
         // console.log('here!!!!!')
          const {from,to,anchor,head}=selection 
          if(!selection.empty && !selection.node){ //dont show selection menu for node selections
            handleSelectionMenuUpdate(selection)
            showMenu=true
            state.doc.nodesBetween(from, to, (node, pos) => {
              const nodeType = node.type;
              if(nodeType.name === 'docVideo' || nodeType.name === 'docImage' || nodeType.name === 'docCodeMirror'){
         
                showMenu=false
              }
              if(nodeType.name=='docTable'){
                const nodeSize = node.nodeSize;
                const nodeFrom = pos;
                const nodeTo = nodeFrom + nodeSize;
              // Check if the selection covers the entire 'docTable' node
              if (from <= nodeFrom && to >= nodeTo) {
       
                showMenu=false
              }
             
              }
            })
          }
        }
       // console.log(`show menu is ---- ${showMenu}`)
        if(showMenu && window.view){
          const {from,to,anchor,head}=selection 
          const fromCoord=window.view.coordsAtPos(from) //https://prosemirror.net/docs/ref/#view.EditorView.coordsAtPos
          const toCoord=window.view.coordsAtPos(to)
          const menuLeftPos=calculateMenuLeftOffset(fromCoord,toCoord)
          positionMenuOverlay(fromCoord.top,menuLeftPos)
        }else hideMenuOverlay()
        
      },
    }
  }
})
}



//Other alignment tests
////. 1
// Left aligned to fromCoord left
// function calculateLeftOffset(fromCoord){
//   return fromCoord.left
// }

// 2
//center aligned to fromCoord left
// function calculateLeftOffset(fromCoord){
//   const width=400
//   return fromCoord.left-width/2
// }

// 3
//bounded
// function calculateLeftOffset(fromCoord){
//   let leftOffset=0
//    const scrollableEl=document.getElementById('scrollable_div')
//   if(scrollableEl){
//       leftOffset= scrollableEl.getBoundingClientRect().left
//   }
//   const boundedLeft=Math.min(500,fromCoord.left-leftOffset)


//   return boundedLeft
// }
