import React from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import { Rnd } from "react-rnd"
import Measure from 'react-measure'
import {Selection } from "prosemirror-state"
import throttle from 'lodash/throttle'
import RecordingPlaybackModalMedia from './RecordingPlaybackModalMedia'
import RecordingPlaybackModalTranscript from './RecordingPlaybackModalTranscript'
import RecordingPlaybackModalIdentifySpeakersPanel from './RecordingPlaybackModalIdentifySpeakersPanel'
import RecordingPlaybackModalHeader from './RecordingPlaybackModalHeader'
import deepgram_nova from '../../containers/recordingTestData/deepgram_nova.json'
import {EditorState} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import transcriptSchema from '../../prosemirror/transcripts/transcriptSchema'
import {activeWordPlugin} from '../../prosemirror/transcripts/transcriptPlugins/activeWord/activeWordPlugin'
//import {createHighlightPlugin} from '../../prosemirror/transcripts/transcriptPlugins/createHighlight/createHighlightPlugin'
//import TranscriptSpeakerHeaderNodeView from '../../prosemirror/transcripts/nodes/TranscriptSpeakerHeaderNodeView'
import TranscriptSpeakerChunkNodeView from '../../prosemirror/transcripts/nodes/TranscriptSpeakerChunkNodeView'
import clickOnWordPlugin from "../../prosemirror/transcripts/transcriptPlugins/clickOnWordPlugin"
import {wordSnapPlugin} from "../../prosemirror/transcripts/transcriptPlugins/wordSnapPlugin"
import {transcriptSearchPlugin} from "../../prosemirror/transcripts/transcriptPlugins/transcriptSearch/transcriptSearchPlugin"
//import highlightActiveWordPlugin from '../../prosemirror/transcripts/transcriptPlugins/highlightActiveWordPlugin'
//import transcriptHighlightsPlugin from "../../prosemirror/transcripts/transcriptPlugins/highlights/transcriptHighlightsPlugin"

import transcriptHighlightsPlugin, { transcriptHighlightPluginKey } from "../../prosemirror/transcripts/transcriptPlugins/highlights/transcriptHighlightsPlugin"
import {transcriptSelectionStylingPlugin} from "../../prosemirror/transcripts/transcriptPlugins/transcriptSelectionStylingPlugin"
import find from 'lodash/find'
import filter from 'lodash/filter'
import {createHighlight} from '../../actions/highlights'
import {fetchTranscript} from '../../actions/transcripts'
import {TextSelection} from "prosemirror-state"
import RecordingPlaybackModalTranscriptHighlightUI from './RecordingPlaybackModalTranscriptHighlightUI'
import {saveVideoProgress} from '../../utils/videoProgress'
import {getSavedVideoProgress} from '../../utils/videoProgress'
import {randomID} from '../../utils/randomID'

const maxTranscriptWidth = 900
const minTranscriptWidth = 300

const DEFAULT_SKIP_AMOUNT=15000

function getFirstAndLastWordNodes(state) {
  const { doc, selection } = state;
  let firstWordNode = null;
  let lastWordNode = null;
  if (selection && !selection.empty) {
    doc.nodesBetween(selection.from, selection.to, (node, pos) => {
      if (node.type.name === "transcriptWord") {
        if (!firstWordNode) {
          firstWordNode = { node, pos };
        }
        lastWordNode = { node, pos };
      }
    });
  }
  return { firstWordNode, lastWordNode };
}

function groupBySpeaker(paragraphs) {
	return paragraphs.reduce((chunks, paragraph) => {
		const lastChunk = chunks[chunks.length - 1];
		if (!lastChunk || lastChunk.speaker !== paragraph.speaker) {			// Start a new chunk
			chunks.push({
				speaker: paragraph.speaker,
				paragraphs: [paragraph],
				start: paragraph.start,
				end: paragraph.end,  // This will be updated later
				num_words: paragraph.num_words
			});
		} else {
			// Add to the existing chunk
			lastChunk.paragraphs.push(paragraph);
			lastChunk.end = paragraph.end;  // Update end time to the end of the current paragraph
			lastChunk.num_words += paragraph.num_words;
		}
		return chunks;
	}, []);
}

function roundToDecimalPlace(num, decimalPlaces) {
	let multiplier = Math.pow(10, decimalPlaces);
	return Math.round(num * multiplier) / multiplier;
}

function setHighlightSelection(highlightStartPos,highlightEndPos){
	const state= window.transcriptView.state
	const tr = state.tr.setSelection(TextSelection.create(state.doc, highlightStartPos, highlightEndPos));
	window.transcriptView.dispatch(tr);
}

function getNodePos(anchorNode){
	if(window.transcriptView && window.transcriptView.state){
		const state=window.transcriptView.state
		let nodePos
		state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
			if(anchorNode===node){
				nodePos=pos
			}
		})
		return nodePos
	}else return null
}

function resetSelection(){ //removes highlight selection
	const state=window.transcriptView.state
  let tr = state.tr.setSelection(Selection.near(state.tr.selection.$from, 0));
	window.transcriptView.dispatch(tr)
}

const SAVE_VIDEO_PROGRESS_THROTTLE_TIME=1000

class RecordingPlaybackModal extends React.Component{  	

	constructor(props){
    super(props) 
    this.loadTranscriptView=this.loadTranscriptView.bind(this)  
    this.handleClickOnWord=this.handleClickOnWord.bind(this)
    this.makeDocJson=this.makeDocJson.bind(this)
    this.setPlayerRef=this.setPlayerRef.bind(this)
    this.handleVideoProgress=this.handleVideoProgress.bind(this)
    this.updateTime=this.updateTime.bind(this)
    this.skipToSpeakerChunk=this.skipToSpeakerChunk.bind(this)
    this.createHighlight=this.createHighlight.bind(this)
    this.handleClickOnChunk=this.handleClickOnChunk.bind(this)
    this.startHighlightMode=this.startHighlightMode.bind(this)
    this.endHighlightMode=this.endHighlightMode.bind(this)
    this.toggleHighlightMode=this.toggleHighlightMode.bind(this)
    this.handlePlayerReady=this.handlePlayerReady.bind(this)
    this.handleDuration=this.handleDuration.bind(this)
    this.handleSkip=this.handleSkip.bind(this)
    //this.handleSelectionChange=this.handleSelectionChange.bind(this)
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.handleKeyUp=this.handleKeyUp.bind(this)
    this.handleMouseUp=this.handleMouseUp.bind(this)
    this.cancelCreateHighlight=this.cancelCreateHighlight.bind(this)
    this.handleHighlightInputKeyDown=this.handleHighlightInputKeyDown.bind(this)
    this.seekVideo=this.seekVideo.bind(this)
    this.handlePlayPause=this.handlePlayPause.bind(this)
    this.handlePlay=this.handlePlay.bind(this)
    this.handlePause=this.handlePause.bind(this)
    this.skipToHighlight=this.skipToHighlight.bind(this)
    this.handleTranscriptScroll=this.handleTranscriptScroll.bind(this)
    this.resetAutoScroll=this.resetAutoScroll.bind(this)
    this.handleWindowFocusBlur=this.handleWindowFocusBlur.bind(this)

    //this.saveVideoProgress=this.saveVideoProgress.bind(this)
    this.handleTranscriptScroll=throttle(this.handleTranscriptScroll,20)
    

    let showIdentifySpeakersPanel=true
    console.log(this.props.recording)
    if(this.props.recording.onboarding_recording){ //hacky for now we will just hardcode speaker for onboarding recording
    	showIdentifySpeakersPanel=false
    }else{
	    	const speakers=this.props.recording.speakers 
	    //check is all the speakers are not assigned, if so show identify panel
	    speakers.forEach((speaker)=>{
	    	if(speaker.user_id || speaker.contact_id){
	    		showIdentifySpeakersPanel=false
	    	}
	    })
    }

    

    this.state={
      transcriptWidth: props.initialTranscriptWidth,	    
      showTranscriptPanel: true,
      showIdentifySpeakersPanel: showIdentifySpeakersPanel,
      modalWidth: props.initialModalWidth,
      modalHeight: props.initialModalHeight,   
      doc:null,
      highlightTitle:'',
      highlightMode: false,
      highlightEdgeCount: 0,
      highlightStartNode:null,
      highlightEndNode:null,
      showNewHighlightPanel:false,
      playing: false,	 
      cmdKeyIsDown:false,
      videoCurrentTime:null,
      hasPlayed:false,
      isProgrammaticScroll:false, //set this to true when auto scroll
      userHasScrolled:false,
      videoCurrentTime:0,
      videoDuration:0,
      activeSpeaker:null

    }
    this.view=null
  }

	componentDidMount() {	
		if(this.props.transcript){
			this.loadTranscriptView()
		}else{
			const transcriptId=this.props.recording.transcript_id
			this.props.fetchTranscript(transcriptId).then((response)=>{
				if(response){
					this.loadTranscriptView()
				}
			})
			.catch((error)=>{
				console.log('fetch transcript error!!!')
				console.log(error)
			})
		}
  	const storyboardImage = new Image();	// try and preload storyboard image
  	storyboardImage.src = `https://image.mux.com/${this.props.playbackId}/storyboard.png`
  	window.addEventListener('keydown',this.handleKeyDown)
  	window.addEventListener('keyup',this.handleKeyUp)
  	window.addEventListener('mouseup',this.handleMouseUp)
  	window.addEventListener('focus',this.handleWindowFocusBlur)
  	window.addEventListener('blur',this.handleWindowFocusBlur)
	}

	 componentDidUpdate(prevProps) {
 		if (this.props.highlights && JSON.stringify(prevProps.highlights) !== JSON.stringify(this.props.highlights)) {
  		const tr = this.view.state.tr.setMeta(transcriptHighlightPluginKey, this.props.highlights)
  		this.view.dispatch(tr)
		}
  }

	componentWillUnmount(){
		if(this.view){
			this.view.destroy()
			this.view=null
			window.transcriptView=null
		}
		window.removeEventListener('keydown',this.handleKeyDown)
  	window.removeEventListener('keyup',this.handleKeyUp)
  	window.removeEventListener('mouseup',this.handleMouseUp)
  	window.removeEventListener('focus',this.handleWindowFocusBlur)
  	window.removeEventListener('blur',this.handleWindowFocusBlur)
  	if(this.state.videoCurrentTime){
  		saveVideoProgress(this.props.recording.recording_id,this.state.videoCurrentTime)
  	}
  	
	}

///Window focus/blur
	handleWindowFocusBlur(){ //cancel highlight mode so don't get stuck in highlight mode if command tab away/into
		this.cancelCreateHighlight()
	}


/////VIDEO CONTROLS//////

  handlePlayPause(){
    this.setState({playing:!this.state.playing})
  }

  handlePlay(){
    this.setState({playing:true,hasPlayed:true})
  }

  handlePause(){
    this.setState({playing:false})
  }

  handleDuration(duration){
  	this.setState({videoDuration:duration})
  }

	handleSkip(skipDirection,skipAmount){
		const videoCurrentTime=this.state.videoCurrentTime
		let newTime
		if(skipDirection==='forward'){
			newTime=Math.min(videoCurrentTime + (skipAmount / 1000),this.state.videoDuration)
			this.seekVideo(newTime)
		}else if(skipDirection==='backward'){
			newTime=Math.max(videoCurrentTime - (skipAmount / 1000),0)
			this.seekVideo(newTime)
		}
	}


  ///HIGHLIGHTMODE


  skipToHighlight(highlight){
		this.seekVideo(highlight.start_time)
		this.handlePlay()
		setTimeout(() => {
			this.scrollTranscript()
		}, 100);  	
  }


	handleKeyDown(e){
		if (e.key === 'Meta') {
			this.setState({cmdKeyIsDown:true})
			this.handlePause()
			if(!this.state.showNewHighlightPanel){
				this.setState({highlightMode:true,showNewHighlightPanel:false,highlightTitle:''})
		 	}
			// if(!this.state.highlightTitle){
			//	this.setState({highlightMode:true,showNewHighlightPanel:false,highlightTitle:''})
			// 	//this.setState({highlightMode:true})
			// }
			// resetSelection()
    }
    if(e.keyCode==32){ //space bar play/pause
    	if(!this.state.highlightMode){
    		e.preventDefault()
    		this.handlePlayPause()
    	}
    }
    if(e.keyCode==39){ //right arrow -- skip 15 sec
    	if(!this.state.highlightMode){
    		e.preventDefault()
    		this.handleSkip('forward', DEFAULT_SKIP_AMOUNT)
    	}
    }
    if(e.keyCode==37){ //LEFT arrow -- skip 15 sec
    	if(!this.state.highlightMode){
    		e.preventDefault()
    		this.handleSkip('backward', DEFAULT_SKIP_AMOUNT)
    	}
    }
	}

	handleKeyUp(e){
		 if (e.key === 'Meta') {
		 	this.setState({cmdKeyIsDown:false})
		 	if(!this.state.showNewHighlightPanel){
		 			this.setState({highlightMode:false})
		 	}
    }
	}


	handleMouseUp(){
		//console.log('handle mouse up!!! in playback modal')
		if(this.state.highlightMode){
			if(this.view && this.view.state){
				const selection = this.view.state.selection 
				if(!selection.empty){
					this.setState({showNewHighlightPanel:true})
					const firstAndLastWord=getFirstAndLastWordNodes(this.view.state)
					const firstWordNode=firstAndLastWord.firstWordNode.node 
					this.seekVideo(firstWordNode.attrs.start)
					//this.player.seekTo(roundToDecimalPlace(firstWordNode.attrs.start,6))
				}

			}
		}
	}

	cancelCreateHighlight(){
		this.setState({highlightTitle:'',showNewHighlightPanel:false,highlightMode:false})
		resetSelection()
	}

	seekVideo(time){
		this.setState({hasPlayed:true,userHasScrolled:false})
		this.player.seekTo(roundToDecimalPlace(time,6),'seconds')
		//this.player.seekTo(roundToDecimalPlace(time,6))
		//this.updateTime(time)
	}



	handleClickOnWord(node){
		if(!this.state.highlightMode){
			// this.seekVideo(0.5)
			this.seekVideo(node.attrs.start)
		}
	}

	handleClickOnChunk(node){
		this.seekVideo(node.attrs.start_time)
	}

	setPlayerRef(ref){
		this.player=ref
	}

	handlePlayerReady(){
    const videoProgress=getSavedVideoProgress(this.props.recording.recording_id)
    if(videoProgress){
      this.seekVideo(videoProgress)
      this.updateTime(videoProgress)
      this.scrollTranscript()
    }
	}

	handleVideoProgress(time){
		this.updateTime(time)
	}


/////SCROLLING

	handleTranscriptScroll(){
		const {isProgrammaticScroll}=this.state
		if(isProgrammaticScroll) {
			this.setState({isProgrammaticScroll:false})
		} else {
			this.setState({userHasScrolled:true})
		}
	}

	resetAutoScroll(){
		this.setState({userHasScrolled:false},() => {
     this.scrollTranscript()
    })
	}

	scrollTranscript(){
		if(!this.state.userHasScrolled){
			this.setState({isProgrammaticScroll:true})
			const id="active-word"
			const el=document.getElementById(id)
			if(el){
				const rect=el.getBoundingClientRect()
				const wordTopXPos = rect.top // relative to the viewport
				const wordBottomXPos = rect.bottom // relative to the viewport
				const scrollDiv=document.getElementById('transcript_scrollable_div') 
				if(scrollDiv){						
					const pageScroll = scrollDiv.scrollTop	
					const windowHeight = window.innerHeight // find height of window 	  				
					const topPageHeaderBuffer = 0 // buffer for the in doc top header
					const generalBuffer = 220 // nice to have a general buffer as well, so can see comment in context  				
					const wordBelow = wordBottomXPos + generalBuffer > windowHeight // comment is below window
					const wordAbove = wordTopXPos < (topPageHeaderBuffer + generalBuffer) // comment is above window	  				
					const wordAboveDeltaX = wordTopXPos - generalBuffer - topPageHeaderBuffer
					const wordBelowDeltaX = (wordBottomXPos + generalBuffer) - windowHeight
					if(wordAbove){	  					
						scrollDiv.scrollBy(0, wordAboveDeltaX); // scrollBy is relative
					}
					if(wordBelow){
						scrollDiv.scrollBy(0, wordAboveDeltaX); // scrollBy is relative
					}
				//	this.setState({isProgrammaticScroll:false})
				}
			}
		}
	}


	updateTime(newTime){
		//console.log(`update time------ ${newTime}`)
		this.setState({videoCurrentTime:newTime})
		//this.saveVideoProgress(newTime)
   	const view=this.view
		if(view){
			let tr = view.state.tr
			tr.setMeta("highlightActiveWordPlugin", {currentTime:newTime})
			view.dispatch(tr)
			// console.log('scroll from here')
			//this.setState({isProgrammaticScroll:true},() => {
     		this.scrollTranscript()
    	//})
			//this.scrollTranscript()
		}

		if(this.props.recording.is_audio){
			//find the activeSpeaker
			const {transcript}=this.props 
			let activeSpeaker
			transcript.paragraphs.forEach((paragraph)=>{
				if(paragraph.start<newTime || paragraph.start==newTime){
					activeSpeaker=paragraph.speaker
				}
			})
			// console.log(`active speaker is---${activeSpeaker}`)
			this.setState({activeSpeaker:activeSpeaker})
		}
		

	}

	
	makeDocJson(){
		const {paragraphs,words}=this.props.transcript
		let content=[]
		let currentWordCount=0
		let previousSpeaker=null
		const chunks = groupBySpeaker(paragraphs)
		chunks.forEach((chunk,i)=>{
			chunk.paragraphs.forEach((paragraph,i)=>{
				let headerNode
				//if(i==0){
				//	headerNode=transcriptSchema.nodes.transcriptSpeakerHeader.createAndFill({start_time:chunk.start,end_time:chunk.end,transcript_speaker:chunk.speaker,recording_id:this.props.recording.recording_id})
			//	}
				const wordCount=paragraph.num_words
				const wordSlice=words.slice(currentWordCount,currentWordCount+wordCount)
				let wordNodes=[]
				wordSlice.forEach((word)=>{
					const wordNode=transcriptSchema.nodes.transcriptWord.createAndFill({start:word.start,end:word.end},[transcriptSchema.text(`${word.word}`)])
					wordNodes.push(wordNode)
					const space=transcriptSchema.nodes.transcriptSpace.createAndFill(null,[transcriptSchema.text(` `)])
					wordNodes.push(space)
				})
				const paragraphNode=transcriptSchema.nodes.paragraph.createAndFill(null,wordNodes)
				let transcriptSpeakerChunkNode
				const attrs={
					start_time:paragraph.start,
					end_time:paragraph.end,
					transcript_speaker:chunk.speaker,
					recording_id:this.props.recording.recording_id
				}
				transcriptSpeakerChunkNode=transcriptSchema.nodes.transcriptSpeakerChunk.createAndFill(attrs,[paragraphNode])
				content.push(transcriptSpeakerChunkNode)
				currentWordCount+=wordCount
				previousSpeaker=paragraph.speaker
			})
		})
		const doc=transcriptSchema.nodes.doc.createAndFill(null,content)
		this.setState({doc:doc})
		return doc
	}


	loadTranscriptView(){
		if(this.view){
			this.view.destroy()
		}
		const handleClickOnWord=this.handleClickOnWord
		const handleClickOnChunk=this.handleClickOnChunk
		const seekVideo=this.seekVideo
		//const handleSelectionChange=this.handleSelectionChange
		this.view = new EditorView(document.querySelector("#transcript"), {
			attributes: {				
				spellCheck: false,
			},
			state: EditorState.create({
			doc: this.makeDocJson(),
			plugins:[
				activeWordPlugin,
				clickOnWordPlugin(handleClickOnWord),
				transcriptHighlightsPlugin(this.props.highlights),
				//createHighlightPlugin(),
				transcriptSearchPlugin,
				wordSnapPlugin,
				//highlightActiveWordPlugin,
				
				transcriptSelectionStylingPlugin()
			],	
		}),
		dispatchTransaction: transaction => { 
			if(this.view){
				const { state, transactions } = this.view.state.applyTransaction(transaction)
				this.view.updateState(state)
			}
		},
		editable: function(state) {
    	return false; // Make it read only
  	},
		nodeViews: { //custom node views
			//transcriptSpeakerHeader(node, view, getPos) { return new TranscriptSpeakerHeaderNodeView(node, view, getPos) },
			transcriptSpeakerChunk(node, view, getPos) { return new TranscriptSpeakerChunkNodeView(node, view, getPos,handleClickOnChunk) },
		},
		
	})
		window.transcriptView=this.view 
	}

  measureResizablePanelContainer(contentRect){
	  const prevModalWidth = this.state.modalWidth;
	  const modalWidth = contentRect.bounds.width;
	  const modalHeight = contentRect.bounds.height;
	  if(this.props.animationRest){
	    // Determine the proportion of the previous media and transcript widths.
	    const transcriptWidthProportion = this.state.transcriptWidth / prevModalWidth;
	    // Use this proportion to calculate the new transcript width.
	    const newTranscriptWidth = modalWidth * transcriptWidthProportion;
	    // Check the new transcript width against the min and max constraints.
	    let transcriptWidth;
	    if (newTranscriptWidth < minTranscriptWidth) {
	      // If the new transcript width would be too small, keep the transcriptWidth at the position where
	      // transcript width is at the minimum.
	      transcriptWidth = minTranscriptWidth;
	    } else if (newTranscriptWidth > maxTranscriptWidth) {
	      // If the new transcript width would be too large, keep the transcriptWidth at the position where
	      // transcript width is at the maximum.
	      transcriptWidth = maxTranscriptWidth;
	    } else {
	      // If the new transcript width is within the acceptable bounds, update the transcriptWidth position.
	      transcriptWidth = newTranscriptWidth;
	    }
	    this.setState({ transcriptWidth: transcriptWidth, modalWidth: modalWidth, modalHeight: modalHeight });
	  }	  
	}

////// highlights///////

	startHighlightMode(){
		this.setState({highlightMode: true})
	}
	endHighlightMode(){
		this.setState({highlightMode: false})
	}
	toggleHighlightMode(){
		this.setState({highlightMode: !this.state.highlightMode})
	}

	createHighlight(){ //here
		let title=this.state.highlightTitle
		const selection = this.view.state.selection 
		const start_pos=selection.from 
		const end_pos=selection.to 
		const firstAndLastWord=getFirstAndLastWordNodes(this.view.state)
		const {firstWordNode,lastWordNode}=firstAndLastWord
		const start_time = firstWordNode.node.attrs.start 
		const end_time = lastWordNode.node.attrs.end 
		const highlight_id=`${randomID()}`
		const requestBody={
			title:title,
			start_time:start_time,
			end_time:end_time,
			start_pos:start_pos,
			end_pos:end_pos,
			recording:this.props.recording.recording_id,
			highlight_id:highlight_id
		}
  	this.props.createHighlight(requestBody)
  	this.setState({highlightTitle:''})
  	this.cancelCreateHighlight()
	}


	handleHighlightInputKeyDown(e){		
		if(e.keyCode===13 && !e.shiftKey){ //update to use enter to submit
			if(this.state.highlightTitle){
				this.createHighlight()
			}
		}else if(e.keyCode==27){
			e.stopPropagation()
			e.preventDefault()
			this.cancelCreateHighlight()
		}
	}


	skipToSpeakerChunk(start){
		//this.player.seekTo(roundToDecimalPlace(start,6))
		this.seekVideo(start)
	}

	render(){	
		const {closeDialog, startResizeDragging, endResizeDragging} = this.props		
		const {transcriptWidth, showTranscriptPanel, modalWidth, modalHeight, showIdentifySpeakersPanel, highlightEdgeCount} = this.state	
		const gutterWidth = 12		
		let mediaContainerWidth = modalWidth - transcriptWidth		
		if(!showTranscriptPanel){
			mediaContainerWidth = modalWidth
		}

		let paragraphs=[]
		if(this.props.transcript){
			paragraphs=this.props.transcript.paragraphs
		}

		const hasHighlightSelection = false

		const {recording} = this.props 
		const aspectRatio=recording.aspect_ratio
		let videoAspect=2048/1152 
		if(aspectRatio){ //turn it from "16:9" into a number
			let aspectRatioArray = aspectRatio.split(":");
			videoAspect = parseInt(aspectRatioArray[0]) / parseInt(aspectRatioArray[1]);
		}
		//console.log(this.props.transcript)

	  return (
	  	<React.Fragment>
	  		<div id="splitPanelContainerID" className='recordingPlaybackModal-splitPanelContainer'>

	  			<div  style={{width: `${mediaContainerWidth - 1}px`}}  className='recordingPlaybackModal-headerContainer'>
		  			<RecordingPlaybackModalHeader 
		  				closeModal={this.props.closeModal}
		  				meeting={this.props.meeting}
		  				doc={this.props.doc}
		  				recording={this.props.recording}
		  				toggleTranscriptPanel={()=>{this.setState({showTranscriptPanel: !showTranscriptPanel})}}
		  				showTranscriptPanel={showTranscriptPanel}		  				
		  				transcriptWidth={transcriptWidth}
		  				modalWidth={modalWidth}
		  				showTranscriptPanel={showTranscriptPanel}
		  				toggleIdentifySpeakerPanel={()=>{this.setState({showIdentifySpeakersPanel: !showIdentifySpeakersPanel})}}
		  				
		  			/>

		  		</div>
	  			{showTranscriptPanel &&   		
		  			<div style={{width: `${this.state.transcriptWidth}px`}} className='recordingPlaybackModal-transcriptContainer'>
		  				<RecordingPlaybackModalTranscript
		  					highlights={this.props.highlights}
		  					transcriptSearchResults={this.props.transcriptSearchResults} 
		  					toggleTranscriptPanel={()=>{this.setState({showTranscriptPanel: !showTranscriptPanel})}}
		  					toggleIdentifySpeakerPanel={()=>{this.setState({showIdentifySpeakersPanel: !showIdentifySpeakersPanel})}}
		  					highlightMode={this.state.highlightMode}
		  					startHighlightMode={this.startHighlightMode}
		  					endHighlightMode={this.endHighlightMode}
		  					toggleHighlightMode={this.toggleHighlightMode}
		  					cancelCreateHighlight={this.cancelCreateHighlight}
		  					showNewHighlightPanel={this.state.showNewHighlightPanel}
		  					skipToHighlight={this.skipToHighlight}
		  					videoCurrentTime={this.state.videoCurrentTime}
		  					handleTranscriptScroll={this.handleTranscriptScroll}

		  					showResetAutoScrollButton={this.state.userHasScrolled && this.state.playing}
		  					resetAutoScroll={this.resetAutoScroll}
		  				/>

		  				<RecordingPlaybackModalTranscriptHighlightUI
		  					highlightMode={this.state.highlightMode}
		  					startHighlightMode={this.startHighlightMode}
		  					endHighlightMode={this.endHighlightMode}
		  					toggleHighlightMode={this.toggleHighlightMode}
		  					cancelCreateHighlight={this.cancelCreateHighlight}
		  					showNewHighlightPanel={this.state.showNewHighlightPanel}

		  					textAreaOnKeyDown={this.handleHighlightInputKeyDown}
		  					textAreaValue={this.state.highlightTitle}
		  					textAreaOnChange={(e)=>{this.setState({highlightTitle:e.target.value})}}
		  					createHighlight={this.createHighlight}
		  					cmdKeyIsDown={this.state.cmdKeyIsDown}

		  				
		  					//showResetAutoScrollButton={this.state.userHasScrolled}
		  					resetAutoScroll={this.resetAutoScroll}
		  				/>


		  			
		  			</div>
		  		}
		  		{showTranscriptPanel && this.props.animationRest && 
		  			<React.Fragment>
				  		{/* INVISIBLE HANDLE */}
				      <Rnd	       	
				        className='recordingPlaybackModal-splitPanelGutterInvisible'
				        dragHandlerClassName="recordingPlaybackModal-splitPanelGutterInvisible-handle"
				        size={{ width: gutterWidth, height: '100%'}}
				        position={{ x: transcriptWidth, y: 0}}
				        onDrag={(e, d) => {
								  const prospectiveTranscriptWidth = d.x;
								  // Check the prospective transcript width against the min and max constraints.
								  if (prospectiveTranscriptWidth < minTranscriptWidth) {
								    // If the new transcript width would be too small, keep the transcriptWidth at the position where
								    // transcript width is at the minimum.
								    this.setState({ transcriptWidth: minTranscriptWidth });
								  } else if (prospectiveTranscriptWidth > maxTranscriptWidth) {
								    // If the new transcript width would be too large, keep the transcriptWidth at the position where
								    // transcript width is at the maximum.
								    this.setState({ transcriptWidth: maxTranscriptWidth });
								  } else {
								    // If the new transcript width is within the acceptable bounds, update the transcriptWidth position.
								    this.setState({ transcriptWidth: prospectiveTranscriptWidth });
								  }
								}}
				        onDragStart={startResizeDragging}
  							onDragStop={endResizeDragging}
				      	enableResizing={false}
				      	dragAxis={'x'}
				      	bounds={'#splitPanelContainerID'}
				      >
				      	<div className='recordingPlaybackModal-splitPanelGutterInvisible-hoverCursor' />
				      </Rnd>

				      {/* VISIBLE HANDLE */}
				      <div
				      	className='recordingPlaybackModal-splitPanelGutterHandle'
		            style={{              
		              left: `${transcriptWidth}px`,              
		              width: `${gutterWidth}px`
		            }}
		          >
		          	<div className='recordingPlaybackModal-splitPanelGutterHandle-handle' />
		          </div>
          	</React.Fragment>
        	}
        	
		  		<div style={{width: `${mediaContainerWidth - 1}px`}} className='recordingPlaybackModal-mediaContainer'>
		  			<RecordingPlaybackModalMedia
		  				recording={this.props.recording}
		  				paragraphs={paragraphs}
		  				skipToSpeakerChunk={this.skipToSpeakerChunk}
		  				handleVideoProgress={this.handleVideoProgress} 
							playbackId={this.props.playbackId}
							setPlayerRef={this.setPlayerRef}
							videoAspect={videoAspect}							
							mediaContainerWidth={mediaContainerWidth - 64} // seems better with a bit of breathing room
							//mediaContainerWidth={mediaContainerWidth - 24} // seems better with a bit of breathing room
							mediaContainerHeight={modalHeight - 38} // tidy up, 36 is height of header
							playing={this.state.playing}
							highlightMode={this.state.highlightMode}
	  					startHighlightMode={()=>{this.setState({highlightMode: true})}}
	  					endHighlightMode={()=>{this.setState({highlightMode: false})}}
	  					toggleHighlightMode={()=>{this.setState({highlightMode: !this.state.highlightMode})}}
	  					handlePlayPause={this.handlePlayPause}
	  					handlePlay={this.handlePlay}
	  					handlePause={this.handlePause}
	  					highlights={this.props.highlights}
	  					skipToHighlight={this.skipToHighlight}
	  					handlePlayerReady={this.handlePlayerReady}
	  					hasPlayed={this.state.hasPlayed}
	  					cancelCreateHighlight={this.cancelCreateHighlight}
	  					cmdKeyIsDown={this.state.cmdKeyIsDown}
	  					showNewHighlightPanel={this.state.showNewHighlightPanel}
	  					handleDuration={this.handleDuration}
	  					handleSkip={this.handleSkip}
	  					activeSpeaker={this.state.activeSpeaker}
		  			/>
		  		</div>


		  		<RecordingPlaybackModalIdentifySpeakersPanel
	  				showIdentifySpeakersPanel={showIdentifySpeakersPanel}
	  				openIdentifySpeakersPanel={()=>{this.setState({showIdentifySpeakersPanel: true})}}
	  				closeIdentifySpeakersPanel={()=>{this.setState({showIdentifySpeakersPanel: false})}}	  				
	  				toggleIdentifySpeakersPanel={()=>{this.setState({showIdentifySpeakersPanel: !showIdentifySpeakersPanel})}}
	  				panelWidth={this.state.transcriptWidth}
	  				recording={this.props.recording}
	  				meeting={this.props.meeting}
	  			/>

		  		{/* MEASURER */}
			  	<Measure
		        bounds
		        onResize={contentRect => {
		          this.measureResizablePanelContainer(contentRect)
		        }}
		      >
		        {({ measureRef }) => (
	  					<div ref={measureRef} className='recordingPlaybackModal-splitPanelMeasurer' /> 
	  			 )}
		      </Measure>

		  	</div>
			</React.Fragment>
	  )
	}
}



function mapStateToProps(state,ownProps) {
	const recordingId=ownProps.recordingId 
	let recording=find(state.recordings,{recording_id:recordingId})
	let transcript 
	if(recording){
		transcript = find(state.transcripts,{transcript_id:recording.transcript_id})
	}
	let muxPlaybackId='CdZiPExv2cFGy3pxeE902y2h1X02K71vMSRT01veYQxcqA'
	let paragraphs=deepgram_nova.results.channels[0].alternatives[0].paragraphs.paragraphs
	let words=deepgram_nova.results.channels[0].alternatives[0].words
	if(recording){
		muxPlaybackId=recording.playback_id
		paragraphs=recording.paragraphs
		words=recording.words
	}
	let highlights=filter(state.highlights,{recording:ownProps.recordingId})

	return {
		playbackId:muxPlaybackId,
		paragraphs:paragraphs,
		words:words,
		transcriptSearchResults:state.transcriptSearchResults,
		transcript:transcript,
		highlights:highlights
	}
}


export default withRouter(connect(mapStateToProps,
	{fetchTranscript,createHighlight}
)(RecordingPlaybackModal))	


	// handleSelectionChange(selection){
	// 	// if(this.state.highlightMode){
	// 	// 	console.log('selection changed----------- here')
	// 	// 	const bookendWords=getFirstAndLastWordNodes(this.view.state)
	// 	// 	//console.log(bookendWords)
	// 	// 	//lets skip video to the first word in highlight
	// 	// 	const firstWordNode=bookendWords.firstWordNode.node
	// 	// 	console.log(firstWordNode)
	// 	// 	this.player.seekTo(roundToDecimalPlace(firstWordNode.attrs.start,6))
	// 	// }
	// 	// console.log(`from---- ${from}`)
	// 	// console.log(`to---- ${to}`)
	// 	// const {highlightMode} = this.state 
	// }
		// else{
		// 	const {highlightMode,highlightStartNode,highlightEndNode}=this.state 
		// 	// setHighlightSelection(46,51)
		// if(!highlightStartNode){
		// 		//console.log('11111')
		// 		this.setState({highlightStartNode:node})
		// 		const pos=getNodePos(node)
		// 		//console.log(pos)
		// 		//console.log(node.nodeSize)
		// 		setHighlightSelection(pos,pos+node.nodeSize)
		// 		this.player.seekTo(roundToDecimalPlace(node.attrs.start,6))
		// 	 }else{
		// 		console.log('22222')
		// 		let newHighlightStartNode
		// 		let newHighlightEndNode
		// 		let highlightStartPos
		// 		let highlightEndPos
		// 		if(!highlightEndNode){
		// 			console.log('33333333')
		// 			const node1Pos=getNodePos(highlightStartNode)
		// 			const node2Pos=getNodePos(node)
		// 			if(node1Pos<node2Pos){
		// 				newHighlightStartNode=highlightStartNode
		// 				newHighlightEndNode=node
		// 				highlightStartPos=node1Pos
		// 				highlightEndPos=node2Pos+node.nodeSize
		// 			}else{
		// 				newHighlightStartNode=node
		// 				newHighlightEndNode=highlightStartNode
		// 				highlightStartPos=node2Pos
		// 				highlightEndPos=node1Pos+highlightStartNode.nodeSize
		// 			}

		// 		}else{ //need to work out how to modify selection
		// 			//check if the new node is closer to current start or end and modify the start or end 
		// 			const currentStartNodePos=getNodePos(highlightStartNode)
		// 			const currentEndNodePos=getNodePos(highlightEndNode)
		// 			const newNodePos=getNodePos(node)
		// 			if(newNodePos<currentStartNodePos){
		// 				newHighlightStartNode=node
		// 				newHighlightEndNode=highlightEndPos
		// 				highlightStartPos=newNodePos
		// 				highlightEndPos=currentEndNodePos+highlightEndNode.nodeSize
		// 			}else if(newNodePos>currentEndNodePos){
		// 				newHighlightStartNode=highlightStartNode
		// 				newHighlightEndNode=node
		// 				highlightStartPos=currentStartNodePos
		// 				highlightEndPos=newNodePos+node.nodeSize+2
		// 			}else{
		// 			}
		// 			//this.player.seekTo(roundToDecimalPlace(newHighlightStartNode.attrs.start,6))
		// 		}
		// 		console.log('xxxxxxx')
		// 		console.log(`highlightStartPos---- ${highlightStartPos}`)
		// 		console.log(`highlightEndPos----${highlightEndPos}`)
		// 		setHighlightSelection(highlightStartPos,highlightEndPos)
		// 		this.setState({highlightStartNode:newHighlightStartNode,highlightEndNode:newHighlightEndNode})
		// 	}
		// }


	// updateTime(newTime){
 //   	const view=this.view
	// 	if(view){
	// 		let tr=this.view.state.tr
	// 		this.view.state.doc.descendants((node, pos) => {
	// 	    if(node.type.name === 'transcriptSpeakerChunk') { 
	//         let attrs = { ...node.attrs };
	//         let active=false 
	//         if(node.attrs.start_time<newTime || node.attrs.start_time==newTime){
	//         	if(node.attrs.end_time>newTime){
	//         		active=true
	//         	}
	//         }
	//         attrs.active = active; // setting the active attribute based on the condition
	//         tr.setNodeMarkup(pos, null, attrs); // updating the node
	// 	    }
	// 		});
	// 		 view.dispatch(tr)
	// 	}
	// }


// function groupBySpeaker(utterances) {
// 	return utterances.reduce((chunks, utterance) => {
// 		const lastChunk = chunks[chunks.length - 1];
// 		if (!lastChunk || lastChunk.speaker !== utterance.speaker) {
// 			console.log('********************** words length')
// 			console.log(utterance.words.length)
// 			// Start a new chunk
// 			chunks.push({
// 				speaker: utterance.speaker,
// 				utterances: [utterance],
// 				start: utterance.start,
// 				end: utterance.end,  // This will be updated later
// 				num_words: utterance.words.length
// 			});
// 		} else {
// 			// Add to the existing chunk
// 			lastChunk.utterances.push(utterance);
// 			lastChunk.end = utterance.end;  // Update end time to the end of the current paragraph
// 			lastChunk.num_words += utterance.num_words;
// 		}
// 		return chunks;
// 	}, []);
// }


//Utterances
// makeDocJson(){
	// 	const {paragraphs,words,utterances}=this.props.transcript
	// 	console.log(utterances)
	// 	let content=[]
	// 	let currentWordCount=0
	// 	let previousSpeaker=null
	// 	const chunks = groupBySpeaker(utterances)
	// 	console.log('chunks------')
	// 	console.log(chunks)
	// 	chunks.forEach((chunk,i)=>{
	// 		chunk.utterances.forEach((utterance,i)=>{
	// 			let headerNode
	// 			if(i==0){
	// 				headerNode=transcriptSchema.nodes.transcriptSpeakerHeader.createAndFill({start_time:chunk.start,end_time:chunk.end,transcript_speaker:chunk.speaker,recording_id:this.props.recording.recording_id})
	// 			}
	// 		//	const wordCount=utterance.num_words
	// 			const wordSlice=utterance.words
	// 			let wordNodes=[]
	// 			wordSlice.forEach((word)=>{
	// 				const wordNode=transcriptSchema.nodes.transcriptWord.createAndFill({start:word.start,end:word.end},[transcriptSchema.text(`${word.word}`)])
	// 				wordNodes.push(wordNode)
	// 				//console.log(transcriptSchema)
	// 				const space=transcriptSchema.nodes.transcriptSpace.createAndFill(null,[transcriptSchema.text(` `)])
	// 				//console.log(space)
	// 				wordNodes.push(space)
	// 			})
	// 			const paragraphNode=transcriptSchema.nodes.paragraph.createAndFill(null,wordNodes)
	// 			let transcriptSpeakerChunkNode
	// 			if(headerNode){
	// 				transcriptSpeakerChunkNode=transcriptSchema.nodes.transcriptSpeakerChunk.createAndFill(null,[headerNode,paragraphNode])
	// 			}else{
	// 				transcriptSpeakerChunkNode=transcriptSchema.nodes.transcriptSpeakerChunk.createAndFill(null,[paragraphNode])
	// 			}
	// 			content.push(transcriptSpeakerChunkNode)
	// 		//	currentWordCount+=wordCount
	// 			previousSpeaker=utterance.speaker
	// 		})
	// 	})
	// 	const doc=transcriptSchema.nodes.doc.createAndFill(null,content)
	// 	this.setState({doc:doc})
	// 	return doc
	// }





	// makeDocJson(){
	// 	const {paragraphs,words}=this.props
	// 	let content=[]
	// 	let currentWordCount=0
	// 	let previousSpeaker=null
	// 	const chunks = groupBySpeaker(paragraphs)
	// 	console.log('chunks---------')
	// 	console.log(chunks)
	// 	paragraphs.forEach((paragraph,i)=>{
	// 		//only show header if is a different user to the paragraph before 
	// 		let headerNode
	// 		if(paragraph.speaker!=previousSpeaker){
	// 			headerNode=transcriptSchema.nodes.transcriptSpeakerHeader.createAndFill({start_time:paragraph.start,transcript_speaker:paragraph.speaker,recording_id:this.props.recording.recording_id})
	// 		}
	// 		const wordCount=paragraph.num_words
	// 		const wordSlice=words.slice(currentWordCount,currentWordCount+wordCount)
	// 		let wordNodes=[]
	// 		wordSlice.forEach((word)=>{
	// 			const wordNode=transcriptSchema.nodes.transcriptWord.createAndFill({start:word.start,end:word.end},[transcriptSchema.text(`${word.word}`)])
	// 			wordNodes.push(wordNode)
	// 			const space=transcriptSchema.text(` `)
	// 			wordNodes.push(space)
	// 		})
	// 		const paragraphNode=transcriptSchema.nodes.paragraph.createAndFill(null,wordNodes)
	// 		let transcriptSpeakerChunkNode
	// 		if(headerNode){
	// 			transcriptSpeakerChunkNode=transcriptSchema.nodes.transcriptSpeakerChunk.createAndFill(null,[headerNode,paragraphNode])
	// 		}else{
	// 			transcriptSpeakerChunkNode=transcriptSchema.nodes.transcriptSpeakerChunk.createAndFill(null,[paragraphNode])
	// 		}
	// 		content.push(transcriptSpeakerChunkNode)
	// 		currentWordCount+=wordCount
	// 		previousSpeaker=paragraph.speaker
	// 	})
	// 	const doc=transcriptSchema.nodes.doc.createAndFill(null,content)
	// 	this.setState({doc:doc})
	// 	return doc
	// }

