import store from '../../store'
import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import {getMilestonesForProject} from '../getMilestonesForProject'
import {getProjectConnectionsForMeeting} from '../meetings/getProjectConnectionsForMeeting'
import {getIssuePanelIssuesForProject} from './getIssuePanelIssuesForProject'



export function getIssuePanelIssuesForUser(userId) {	 //ignores cancelled issues
	const state = store.getState()
	let userIssues={}
	const projects=state.projects

	projects.forEach((project)=>{
		const projectId=project.project_id
		const issues=getIssuePanelIssuesForProject(projectId,userId)
		if(issues[projectId].totalCount>0){
			userIssues[projectId]=issues[projectId]
		}
	})

	const issuesNotInProject=getIssuePanelIssuesForProject('none',userId)
	if(issuesNotInProject['none'].totalCount>0){
		userIssues['none']=issuesNotInProject['none']
	}
	
	return userIssues

}
