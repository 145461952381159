import React from 'react'
import Icon from '../misc/Icon'
import DocEditorProjectContentTabsTabBar from './DocEditorProjectContentTabsTabBar'
import DocProjectSubDocsTable from './DocProjectSubDocsTable'
import DocProjectFigmasGrid from './DocProjectFigmasGrid'

class DocEditorProjectContentTabs extends React.Component{  

  constructor(props) {
    super(props)   
    this.state={
      activeTab: 'docs',
    }    
  } 

  handleTabClick = (tab) => {
    this.setState({ activeTab: tab });
  };

  render(){ 

    const {activeTab} = this.state

    const figmaCount = 0
    const mentionCount = 0

    return (        
      
        <div className='docEditor-contentTabs'>
          
          {/*}
          <DocEditorProjectContentTabsTabBar 
            activeTab={activeTab}
            setActiveTab={(tab)=>{this.handleTabClick(tab)}}
            hasProjectDocs={this.props.projectSubDocs.length > 0}
            hasFigmas={figmaCount > 0}
            hasMentions={mentionCount > 0}
            openProjectMentionsSheet={this.props.openProjectMentionsSheet}
            showProjectMentionsSheet={this.props.showProjectMentionsSheet}
          /> 
          */}

          <div className='docEditor-contentTabs-contentContainer'>
            {activeTab === 'docs' && 
              <DocProjectSubDocsTable
                projectMilestoneDocs={this.props.projectMilestoneDocs}
                docName={this.props.docName}
                projectSubDocs={this.props.projectSubDocs}
                doc={this.props.doc}
                hasDescription={this.props.doc.description ? true : false}
              />
            }

            {activeTab === 'figma' && 
              <DocProjectFigmasGrid 

              />                            
            }

          </div>
                          
        </div>
      
    )
  }
}

export default DocEditorProjectContentTabs