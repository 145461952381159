import React from 'react'
import Icon from './Icon'
import {calcStatsForMilestone} from '../../utils/calcStatsForMilestone'


// calcStatsForMilestone={
//   completedCount,
//   inProgressCount,
//   isActiveMilestone,
//   isComplete,
//   progressPercentage,
//   scope
// }




class LinearMilestoneIcon extends React.Component{    
  
  constructor(){
    super()
    this.svgPath = React.createRef()
    this.updateProgress = this.updateProgress.bind(this)   
  }

  componentDidMount() {
    this.updateProgress();
  }

  componentDidUpdate() {
   this.updateProgress();
  }

  updateProgress(){
    const {milestone}=this.props
    if(milestone){
      const percentageComplete=milestone.progressPercentage
    if (this.svgPath.current) {
      const pathLength = this.svgPath.current.getTotalLength();  
      const dashLength = pathLength * percentageComplete/100;
      const gapLength = pathLength - dashLength;
      this.svgPath.current.style.strokeDasharray = `${dashLength} ${gapLength}`;
    }
    }
  }

  render(){ 
    const {milestone, unassignedToMilestone}=this.props   
    let isActiveMilestone=false
    let isCompleted=false
    let percentageComplete=0
    if(milestone){
      isActiveMilestone=milestone.isActiveMilestone
      isCompleted=milestone.isComplete 
      percentageComplete=milestone.progressPercentage
    }    
    const {largeIcon} = this.props

    return (
      <div className={'linearMilestoneIcon ' + (isCompleted ? ' linearMilestoneIcon--completed ' : ' linearMilestoneIcon--incomplete ') + (isActiveMilestone ? ' linearMilestoneIcon--activeMilestone ' : ' linearMilestoneIcon--inactiveMilestone ') + (unassignedToMilestone ? ' linearMilestoneIcon--unassignedToMilestone ' : ' ') + (largeIcon ? ' linearMilestoneIcon--largeIcon ' : ' ')}>
        <svg className='linearMilestoneIcon-icon' width="32" height="32" viewBox="0 0 32 32">
          <path 
            id="bg"
            d="M13.966 1.8a2.134 2.134 0 0 1 3.331 0l10.436 12.705c.707.86.707 2.13 0 2.99L17.297 30.2a2.134 2.134 0 0 1-3.331 0L3.53 17.495a2.374 2.374 0 0 1 0-2.99L13.966 1.8Z"                         
            strokeLinejoin="round"
          />          
          {percentageComplete > 0 && 
            <path
              ref={this.svgPath}
              id="progress"
              d="M13.966 1.8a2.134 2.134 0 0 1 3.331 0l10.436 12.705c.707.86.707 2.13 0 2.99L17.297 30.2a2.134 2.134 0 0 1-3.331 0L3.53 17.495a2.374 2.374 0 0 1 0-2.99L13.966 1.8Z"                         
              strokeLinecap="round"
              strokeDashoffset="-2.2"
            />
          }    
        </svg>    
      </div>
    )
  }
}

export default LinearMilestoneIcon




// class LinearMilestoneIcon extends React.PureComponent{    
  
//   svgPath = React.createRef()

//   componentDidMount() {
//     this.updateProgress();
//   }

//   componentDidUpdate() {
//     this.updateProgress();
//   }

//   updateProgress = () => {
//     if (this.svgPath.current) {
//       const pathLength = this.svgPath.current.getTotalLength();
//       const percentageComplete = this.props.completedIssues / (this.props.totalIssues + 1);      
//       const dashLength = pathLength * percentageComplete;
//       const gapLength = pathLength - dashLength;
//       this.svgPath.current.style.strokeDasharray = `${dashLength} ${gapLength}`;
//     }
//   }

//   render(){ 
//     const {milestoneId}
        
//     const {completedIssues,totalIssues,activeMilestone,unassignedToMilestone,largeIcon} = this.props

//     const completed = completedIssues === totalIssues    
          

//     return (
//       <div className={'linearMilestoneIcon ' + (completed ? ' linearMilestoneIcon--completed ' : ' linearMilestoneIcon--incomplete ') + (activeMilestone ? ' linearMilestoneIcon--activeMilestone ' : ' linearMilestoneIcon--inactiveMilestone ') + (unassignedToMilestone ? ' linearMilestoneIcon--unassignedToMilestone ' : ' ') + (largeIcon ? ' linearMilestoneIcon--largeIcon ' : ' ')}>
//         <svg className='linearMilestoneIcon-icon' width="32" height="32" viewBox="0 0 32 32">
//           <path 
//             id="bg"
//             d="M13.966 1.8a2.134 2.134 0 0 1 3.331 0l10.436 12.705c.707.86.707 2.13 0 2.99L17.297 30.2a2.134 2.134 0 0 1-3.331 0L3.53 17.495a2.374 2.374 0 0 1 0-2.99L13.966 1.8Z"                         
//             strokeLinejoin="round"
//           />          
//           {completedIssues > 0 && 
//             <path
//               ref={this.svgPath}
//               id="progress"
//               d="M13.966 1.8a2.134 2.134 0 0 1 3.331 0l10.436 12.705c.707.86.707 2.13 0 2.99L17.297 30.2a2.134 2.134 0 0 1-3.331 0L3.53 17.495a2.374 2.374 0 0 1 0-2.99L13.966 1.8Z"                         
//               strokeLinecap="round"
//               strokeDashoffset="-2.2"
//             />
//           }    
//         </svg>    
//       </div>
//     )
//   }
// }

// export default LinearMilestoneIcon
