import React from 'react'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import AvatarImage from '../../AvatarImage'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import {getUserForId} from '../../../utils/getUserForId'
import {getProjectForId} from '../../../utils/getProjectForId'
import {getPriorityLabel} from '../../../utils/getPriorityLabel'
import LinearIssueWorkflowStateIcon from '../../misc/LinearIssueWorkflowStateIcon'
import LinearIssuePriorityIcon from '../../misc/LinearIssuePriorityIcon'
import LinearIssuePreviewPopoverContent from './LinearIssuePreviewPopoverContent'


class LinearIssuePreviewPopover extends React.Component{  	
	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.state={
      forceMount: false,      
    }
  }

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

	render(){	
		const {showPopover, openPopover, closePopover, assignee, workflowType, workflowStateName, workflowColorHex, workflowTypeStateIndex, workflowTypeStatesCount, title, identifier, priority, description, projectId} = this.props
		const {forceMount} = this.state

		const translateX = -6		
	  
  	let project 
  	if(projectId){
  		project = getProjectForId(projectId)
  	}

		return (
			<React.Fragment>
				<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'linearIssuePreviewPopoverOuterContainer ' + (showPopover ? ' linearIssuePreviewPopoverOuterContainer--visible ' : ' linearIssuePreviewPopoverOuterContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							onInteractOutside={closePopover}							
							side="left"						
							align="center"
							sideOffset={8}
							onOpenAutoFocus={(e)=>{e.preventDefault()}}
							forceMount={showPopover ? true : forceMount}
							//asChild
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateX(${translateX}px)` }}
								enter={{ opacity: 1, transform: 'translateX(0px)'  }}
								leave={{ opacity: 0, transform: `translateX(${translateX}px)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 35}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="linearIssuePreviewPopoverInnerContainer"> 	
										<LinearIssuePreviewPopoverContent
											projectId={projectId}
											project={project}										
											identifier={identifier}
											title={title}
											assignee={assignee}
											workflowColorHex={workflowColorHex}
											workflowType={workflowType}
											workflowTypeStatesCount={workflowTypeStatesCount}
											workflowTypeStateIndex={workflowTypeStateIndex}
											workflowStateName={workflowStateName}
											priority={priority}										
											description={description}
										/>									
							  	</animated.div>								
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			</React.Fragment>
		)
	}
}

export default LinearIssuePreviewPopover