import {InputRule,textblockTypeInputRule} from 'prosemirror-inputrules'
import schema from '../../schema/editorSchema'
import {TextSelection} from "prosemirror-state"
import {getAnchorNode} from '../../utils/getAnchorNode'

//https://github.com/ProseMirror/prosemirror-inputrules/blob/master/src/rulebuilders.ts
const maxLevel=2
const regEx=new RegExp(`^(#{1,${maxLevel}})\\s$`) 

const nodeType=schema.nodes.heading

// export function textblockTypeInputRule(
//   regexp: RegExp,
//   nodeType: NodeType,
//   getAttrs: Attrs | null | ((match: RegExpMatchArray) => Attrs | null) = null
// ) {
//   return new InputRule(regexp, (state, match, start, end) => {
//     let $start = state.doc.resolve(start)
//     const headingLevel=match[1].length
    
//     if (!$start.node(-1).canReplaceWith($start.index(-1), $start.indexAfter(-1), nodeType)) return null
//     return state.tr
//       .delete(start, end)
//       .setBlockType(start, start, nodeType, attrs)
//   })
// }



export function headingInputRule(){
	return new textblockTypeInputRule(regEx, nodeType,(match)=>{
		const headingLevel=match[1].length
		return {level:headingLevel}
		}
	)
}





// export function headingInputRule(){
// 	return new InputRule(regEx, (state, match, start, end) => {
// 		console.log('heading match')
// 		console.log(match)
// 		let $start = state.doc.resolve(start)
// 		console.log($start)
// 		const headingLevel=match[1].length
//     let attrs = {level:headingLevel}
    
//     if (!$start.node(-1).canReplaceWith($start.index(-1), $start.indexAfter(-1), nodeType)) return null
//     console.log('here----%%')
//     return state.tr
//       .delete(start, end)
//       .setBlockType(start, start, nodeType, attrs)
//   })
// }


// export function headingInputRule(){

// 	return new InputRule(regEx, (state, match, start, end) => {
// 		let tr = state.tr
// 		const headingLevel=match[1].length
// 		let type = schema.nodes.heading	

// 		let {$from} = state.selection
// 		const anchorNode=getAnchorNode($from,'paragraph')
// 		let textContent=anchorNode.textContent
// 		let headingNode
// 		let headingText
		

// 		if(anchorNode.textContent){
// 			headingText=schema.text(textContent)
// 			headingNode = type.createAndFill({level:headingLevel},[headingText])
// 		}else{
// 			headingNode = type.createAndFill({level:headingLevel})
// 		}

// 		console.log('heading text is------------')
// 		console.log(headingText)
// 		//const headingNode = type.createAndFill({level:headingLevel},[test])
// 		let replaceStart=start-1
// 		let replaceEnd=end+1
// 		tr.replaceWith(replaceStart, replaceEnd, headingNode)
// 		let selection=new TextSelection(tr.doc.resolve(replaceStart +1))
// 		tr.setSelection(selection)
// 		return tr
// 	})
// }




// export function markdownBoldInputRule(){
//  return new InputRule(/\*(.*)\*$/, (state, match, start, end) => state.tr
//     .replaceRangeWith(start, end, schema.text(match[1], [schema.marks.strong.create()]))
//     .removeStoredMark(schema.marks.strong) // with or without doesn't change anything
//   )
// }



//this one from PM example doesnt seem to work because of empty requirement at the end of all docs not quite sure why
// function headingRule(nodeType, maxLevel) {
//   return textblockTypeInputRule(),
//                                 nodeType, match => ({level: match[1].length}))
// }