import React from 'react'


class DoodleAvatar extends React.Component{  	


	render(){	

		const {doodleType, doodleBG} = this.props
	
		let avatarSrc
		let defaultBG
		// purple
		// blue
		// green
		// red
		// orange
		// sky		

		if(doodleType === 'alien'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/Alien_eevbsb.png'
			defaultBG = 'green'
		}
		if(doodleType === 'skull'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/Skull_mc5j7l.png'
			defaultBG = 'red'
		}
		if(doodleType === 'popsicle'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/Popsicle_ini2xd.png'
			defaultBG = 'sky'
			defaultBG = 'purple'
		}
		if(doodleType === 'balloon'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/Balloon_rd1ton.png'
			defaultBG = 'orange'
		}
		if(doodleType === 'cat'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/Cat_cchrn1.png'
			defaultBG = 'green'
		}
		if(doodleType === 'sunglasses'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/Sunglasses_iiykai.png'
			defaultBG = 'purple'
			defaultBG = 'blue'
			// defaultBG = 'orange'
		}
		if(doodleType === 'postIt'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/PostIt_jqkcls.png'
			//defaultBG = 'purple'
			defaultBG = 'red'
			// defaultBG = 'orange'
		}
		if(doodleType === 'tache'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/Tache_gysuj0.png'
			defaultBG = 'purple'
		}
		if(doodleType === 'rainbowVom'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/Rainbow_hchna2.png'
			defaultBG = 'blue'
		}
		if(doodleType === 'heartEyes'){
			avatarSrc='https://res.cloudinary.com/yarn/image/upload/v1668426965/DoodleAvatars/TransparentPNG/HeartEyes_lal9q4.png'
			defaultBG = 'green'
		}


		let avatarBG = defaultBG
		if(doodleBG){
			avatarBG = doodleBG
		}
		

	  return (
	  	
	  		<div className='avatarImage-doodleAvatar'>
	  			<img src={avatarSrc} className='avatarImage-doodleAvatar-img' />
	  			<div className={'avatarImage-doodleAvatar-bg ' + (avatarBG ? `avatarImage-doodleAvatar-bg--${avatarBG}` : '')} /> 
	  		</div>
			
	  )
	}
}

export default DoodleAvatar
