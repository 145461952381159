const ContentBoxNodeSpec ={
	group: "block",
	content: "block*",
	parseDOM: [{
		tag: "div",
		getAttrs: dom => {
			let classname = dom.getAttribute("class")
			return classname==='doc-contentBox'
			}
		}],
	toDOM: () => ["div",{class:'doc-contentBox'}, 0]
}
export default ContentBoxNodeSpec