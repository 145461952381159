import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import MiniSearch from 'minisearch'
import store from '../../store'
import {getProjectConnectionsForMeeting} from './getProjectConnectionsForMeeting'


//TODO maybe add project description or status?
let minisearchProjects = new MiniSearch({
	fields: ['name'],
	storeFields: ['project_id','name', 'lead','description','icon','color','members','teams'],
	idField: 'project_id',
})


export function reindexSearch() {
	const state = store.getState()
	state.projects.forEach((project)=>{
		if(minisearchProjects.has(project.project_id)){
			minisearchProjects.replace(project)
		}else{
			minisearchProjects.add(project)
		}
	})
	return
}



export function getMeetingProjectSearchResults(searchTerm,meetingId) {
	const state = store.getState()
	const projects= state.projects
	//default results (empty search term) return most recent projects updated_at
	if(minisearchProjects._documentCount == 0 ){
		minisearchProjects.addAll(projects)
	}
	let results
	if(searchTerm){
		results=minisearchProjects.search(searchTerm, {prefix:true, fuzzy:0.2})
	}else{ 
		const sortedProjects=sortBy(projects,function(project){
			return project.updated_at
		}).reverse()
		results=sortedProjects
	}

	//remove projects that are already linked 
	const connections=getProjectConnectionsForMeeting(meetingId)
	let suggestions=[] 
	results.forEach((project)=>{
		if(find(connections,{project:project.project_id})){
			// console.log('found it--------')
		}else{
			suggestions.push(project)
		}
	})

	return suggestions.slice(0,12) // changed from 5
}


