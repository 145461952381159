import React from 'react'
import Icon from '../misc/Icon'
import WhiteboardHeader from './WhiteboardHeader'
import WhiteboardToolbar from './WhiteboardToolbar'
import WhiteboardZoomControls from './WhiteboardZoomControls'
import WhiteboardMultiplayer from './WhiteboardMultiplayer'
import { Tldraw ,TLEditorComponents,createTLStore,TldrawEditor,ContextMenu} from "@tldraw/tldraw"
import { useLayoutEffect, useState } from 'react'
import {Canvas,defaultShapes,defaultTools,TldrawUi} from '@tldraw/tldraw'
import {getWhiteboardEmbedForId} from '../../utils/whiteboards/getWhiteboardEmbedForId'


export default function Whiteboard({closeAndSave,handleMount,whiteboardModalEmbedId,theme,loading}){  
 
	const [store] = useState(() => createTLStore({shapes:defaultShapes,tools:defaultTools})) 
	
	const [loadingState, setLoadingState] = useState({
		status: 'loading',
	});


	useLayoutEffect(() => {
		if(whiteboardModalEmbedId){
			const embedObj=getWhiteboardEmbedForId(whiteboardModalEmbedId)
			let snapshot
			if(embedObj){
				snapshot=embedObj.snapshot
			}
			if (snapshot) {
				try {
					store.loadSnapshot(snapshot)
					setLoadingState({ status: 'ready' })
				} catch (error) {
					setLoadingState({ status: 'error', error: error.message }) // Something went wrong
				}
			} else {
				setLoadingState({ status: 'ready' }) // Nothing persisted, continue with the empty store
			}
		}else{
    	setLoadingState({ status: 'ready' })
		}
	}, [store])



		return (
			<div className='whiteboardContainer'>
				{loading && <div className='whiteboardContainer-loading' />}

				<TldrawEditor 
					key={`modal_whiteboard_${whiteboardModalEmbedId}`}
			 		store={store}
			 		shapes={defaultShapes} 
			 		tools={defaultTools}
			 		onMount={handleMount}
			 		autoFocus
			 	// 	onMount={(editor) => {
			 	// 		//if(theme=='dark'){
					// 		editor.setDarkMode(true)
			 	// 		//}
					// }}
			 	>		
			
				
			
					<WhiteboardHeader 
							// dropdown
						/>
					
					<WhiteboardToolbar
							// add shapes and bits
						/>
						
					<TldrawUi>
					 	<ContextMenu>
					 			<Canvas theme={theme}/>
					 	</ContextMenu>
					</TldrawUi>
						
					<WhiteboardZoomControls
						currentZoom={0.55}		
						// see current zoom level, and reset zoom to 100			
					/>

				</TldrawEditor>


				{/*
				<WhiteboardHeader 
					// title and some bits
				/>
				*/}



				{/*
				<WhiteboardMultiplayer
					// presence and pinging prototype	
				/>
				*/}

				<div className='whiteboardContainer-topRight'>					
					{/*
					<button className='whiteboardContainer-topRight-btn whiteboardContainer-topRight-btn--newWindow'>
						<Icon name='newWindow' />
					</button>
					*/}
					<button onClick={closeAndSave} className='whiteboardContainer-topRight-btn whiteboardContainer-topRight-btn--close'>
						<Icon name='crossMedium' />
					</button>
				</div>
			</div>
		)
	}


