import React from 'react'
import Icon from '../../misc/Icon'
import LinearProjectStatusIcon from '../../misc/LinearProjectStatusIcon'
import * as Tooltip from '@radix-ui/react-tooltip';
import Measure from 'react-measure'
import {getRoadmapForId} from '../../../utils/getRoadmapForId'
import {getProjectsForRoadmap} from '../../../utils/getProjectsForRoadmap'
import RoadmapsHomePageQuarterProjectRow from './RoadmapsHomePageQuarterProjectRow'
import RoadmapsHomePageQuarterTimeline from './RoadmapsHomePageQuarterTimeline'
import RoadmapsHomePageQuarterHeader from './RoadmapsHomePageQuarterHeader'
import RoadmapsHomePageQuarterOverdue from './RoadmapsHomePageQuarterOverdue'
import moment from 'moment'

function calcOverdueProjects(projects){
	let overdueProjects=[]
	const today = moment().startOf('day');  // Get today's date without the time component
	projects.forEach((project)=>{
		if(project.target_date){
			if(project.state =='started'){
				if(moment(project.target_date).isBefore(today)){
					overdueProjects.push(project)
				}
			}
		}
	})
	return overdueProjects
}

// function calcOverdueProjects(projects){
// 	let overdueProjects=[]
// 	projects.forEach((project)=>{
// 		if(project.target_date){
// 			if(project.state =='started'){
// 				if(moment(project.target_date).isBefore()){
// 					overdueProjects.push(project)
// 				}
// 			}
// 		}
// 	})
// 	return overdueProjects
// }

class RoadmapsHomePageQuarter extends React.Component{  	

	constructor(){
    super()    
    this.state={
    	activeStatus: 'planned',
    	lookback: 'short',
    	quarterPixelWidth: 0,
    	showBacklogProjects: false,
    	isDraggingTimeline: false,    	
    }
  }		


	measureQuarterPixelWidth(contentRect){
		const quarterPixelWidth = contentRect.bounds.width
		this.setState({ quarterPixelWidth })
	}

	render(){		
		const {quarterRoadmapId} = this.props
		const currentQuarterLabel = 'Q3' // this would always be Q1/Q2/Q3/Q4
		const {activeStatus, lookback, quarterPixelWidth,showBacklogProjects, isDraggingTimeline} = this.state 
		const roadmap = getRoadmapForId(quarterRoadmapId)
		const roadmapProjects = getProjectsForRoadmap(roadmap)
		const startedProjects = roadmapProjects.filter(project => project.state === 'started').sort((a, b) => a.name.localeCompare(b.name));
		const plannedProjects = roadmapProjects.filter(project => project.state === 'planned').sort((a, b) => a.name.localeCompare(b.name));
		const backlogProjects = roadmapProjects.filter(project => project.state === 'backlog').sort((a, b) => a.name.localeCompare(b.name));
		const completedProjects = roadmapProjects.filter(project => project.state === 'completed').sort((a, b) => a.name.localeCompare(b.name));		
		let activeProjects
		if(activeStatus === 'started'){
  		activeProjects = startedProjects  		
  	}else if(activeStatus === 'planned'){
  		activeProjects = plannedProjects  		
  	} else if(activeStatus === 'backlog'){
  		activeProjects = backlogProjects  		
  	}  

  	const overdueProjects=calcOverdueProjects(roadmapProjects)

	  return (	  	
  		<div className={'roadmapsHomePage-quarter ' + (isDraggingTimeline ? 'roadmapsHomePage-quarter--draggingTimeline' : '')}>	
	  		{roadmap &&
		      <RoadmapsHomePageQuarterHeader 
		      	roadmap={roadmap}
		      	startedProjects={startedProjects}
				  	plannedProjects={plannedProjects}
				  	backlogProjects={backlogProjects}
						completedProjects={completedProjects}
						quarterRoadmapId={quarterRoadmapId}
						roadmaps={this.props.roadmaps}
		      />  	
		    }
	    	{overdueProjects.length>0 &&
	  			<div className='roadmapsHomePage-quarter-overdueContainer'>  				
	  				<RoadmapsHomePageQuarterOverdue
		      		overdueProjects={overdueProjects} // projects not completed or cancelled or paused, whose due date is over 3 days in the past
		      	/>
	  			</div>
  			}
  			<div className='roadmapsHomePage-quarter-forwardContainer'>
  				<div className='roadmapsHomePage-quarter-timelineContainer'>
  					<div className='roadmapsHomePage-quarter-timelineLabel'>
  						In Progress
  					</div>
  					<div className='roadmapsHomePage-quarter-timeline'>  						  						
							<RoadmapsHomePageQuarterTimeline 
								roadmap={roadmap}
								quarterPixelWidth={quarterPixelWidth}
								setIsDraggingTimeline={()=>{this.setState({isDraggingTimeline: true})}}
								unsetIsDraggingTimeline={()=>{this.setState({isDraggingTimeline: false})}}
								isDraggingTimeline={isDraggingTimeline}
							/> 						
  					</div>
  				</div>
	  			<div className='roadmapsHomePage-quarter-upcomingContainer'>		  			
		  			<div className='roadmapsHomePage-quarter-plannedList'>		  				
		  				<div className='roadmapsHomePage-quarter-plannedList-title'>
		  					{currentQuarterLabel} Planned Projects
		  				</div>
		  				{plannedProjects.map(project => (
				        <RoadmapsHomePageQuarterProjectRow
				          key={project.project_id}
				          project={project}
				          title={project.name}		          
				          activeStatus={activeStatus}
				        />
				      ))}
		  			</div>
		  			<div className='roadmapsHomePage-quarter-backlogList'>
		  				{!showBacklogProjects && backlogProjects && backlogProjects.length > 0 &&
			  				<button onClick={()=>{this.setState({showBacklogProjects: true})}} className='roadmapsHomePage-quarter-backlogList-toggleBtn roadmapsHomePage-quarter-backlogList-toggleBtn--show'>
			  					Show {backlogProjects.length} backlog projects
			  				</button>
		  				}
		  				{showBacklogProjects &&
		  					<div onClick={()=>{this.setState({showBacklogProjects: false})}} className='roadmapsHomePage-quarter-backlogList-title'>
		  						Backlog
		  					</div>
		  				}

		  				{showBacklogProjects && backlogProjects.map(project => (
				        <RoadmapsHomePageQuarterProjectRow
				          key={project.project_id}
				          project={project}
				          title={project.name}		          
				          activeStatus={activeStatus}
				        />
				      ))}		  				
		  				
		  			</div>
		  		</div>
		  	</div>

		  	{/* MEASURER FOR TIMELINE */}
		  	<Measure
	        bounds
	        onResize={contentRect => {
	          this.measureQuarterPixelWidth(contentRect)
	        }}
	      >
	        {({ measureRef }) => (
  					<div ref={measureRef} className='roadmapsHomePage-quarter-measurer' /> 
  			 )}
	      </Measure>
  			
  		</div>
	  )
	}
}

export default RoadmapsHomePageQuarter
