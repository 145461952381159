import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {
	
   
    case 'SIGN_OUT':
      return []
   
    case 'FETCH_FIGMA_EMBEDS_SUCCESS':
      return action.response


    case 'CREATE_FIGMA_EMBED_SUCCESS':
			const newEmbed=action.embed
			let index=findIndex(state,(embed => {
				return embed.embed_id=== newEmbed.embed_id
			}))
			if(index === -1){
				index=state.length
			}
			return [
				...state.slice(0,index),
				newEmbed,
				...state.slice(index + 1)
			]


    default:
      return state;
	}
}

