import React from 'react'
import Icon from '../../misc/Icon'

class MessagePanelThreadHeader extends React.Component{  
		
	render(){			
		const {isNewDocThread}=this.props

		let threadHeaderLabel="Thread"
		if(this.props.isHighlightThread){
			threadHeaderLabel="Highlight"
		}


		if(isNewDocThread){
			return (	 
				<div className='docEditor-messagePanel-header docEditor-messagePanel-header--newThread '>										
					<div className='docEditor-messagePanel-header--newThread-title'>
						New Thread															
					</div>
					<button onClick={this.props.cancelNewDocThread} className='docEditor-messagePanel-header--newThread-closeBtn'>
            <div className='docEditor-messagePanel-header--newThread-closeBtn-inner'>
              <Icon name='cross-small'/>
            </div>                  
           </button>
				</div> 																
			)}else{
				return (	 
					<div onClick={this.props.backToMainThread} className='docEditor-messagePanel-header docEditor-messagePanel-header--thread '>					
						{/*}
						<div className='docEditor-messagePanel-header--thread-backBtn'>
							<Icon name='chevronRight' /> 
						</div>
						*/}
						<div className='docEditor-messagePanel-header--thread-title'>
							Thread															
						</div>						
					
					<button className='docEditor-messagePanel-header--thread-closeBtn'>	
						<div className='docEditor-messagePanel-header--thread-closeBtn-inner'>	
							<Icon name='cross-small'/>
						</div>
					</button>			
					
					</div> 															
				)
			}
		}
		
	}


export default MessagePanelThreadHeader

