import moment from 'moment'
import {formatMeetingDateTime} from './formatMeetingDateTime'
import {formatMeetingDateTimeShort} from './formatMeetingDateTimeShort'
import {formatMeetingDate} from './formatMeetingDate'
import {formatMeetingDateShort} from './formatMeetingDateShort'
//timestamp location is things like meetingRow


export function getFormattedTimestampForMeeting(meeting,timestampLocation) {
	let hasNoTimestamp=true
  let timestamp
  let date
  if(meeting.meeting_start){
    timestamp=meeting.meeting_start
    hasNoTimestamp=false
  }else if(meeting.meeting_date){
    date=meeting.meeting_date
    hasNoTimestamp=false
  }

  let formattedTimestamp=''
  if(timestamp){
    formattedTimestamp=formatMeetingDateTime(timestamp)
    if(timestampLocation==='sideMenu'){
      formattedTimestamp=formatMeetingDateTimeShort(timestamp)
    }
  }
  
  if(date){
    formattedTimestamp=formatMeetingDate(date)
    if(timestampLocation==='sideMenu'){
      formattedTimestamp=formatMeetingDate(date)

    }
    // there is no short for sidemenu
  }


  return formattedTimestamp


}