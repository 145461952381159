import {fetchUser} from './user'
import {fetchOrgMembers} from './orgMembers'
import {fetchDocs} from './docs'
import {fetchAllMessages} from './messages'
import {fetchOrg} from './user'
import {fetchRecentAcitivities} from './recentActivities'
import {fetchPageActivities} from './pageActivities'
import {fetchTeams,syncTeams} from './teams'
import {fetchProjects,syncProjects} from './projects'
import {fetchMilestones} from './milestones'
import {fetchIssues} from './issues'
import {fetchWorkflowStates} from './workflowStates'
import {fetchRoadmaps} from './roadmaps'
import {fetchFigmaIntegration,fetchFigmaEmbeds} from './figma'
import {fetchUserSettings} from './userSettings'
import {fetchMeetings,fetchMeetingProjectConnecions} from './meetings'
import {fetchRecordings} from './recording'
import {fetchGoogleIntegration,fetchGoogleCalEvents} from './googleIntegration'
import {fetchContacts} from './contacts'
import {fetchHighlights} from './highlights'
import {fetchTemplates} from './docTemplates'
import {fetchWhiteboardEmbeds} from './whiteboardEmbed'
import {fetchOnboardingStatus} from '../actions/onboarding'

export function loadInitialData(isLoadAfterLogin) {
	return (dispatch) => {
		dispatch(fetchOnboardingStatus())
		dispatch(fetchUserSettings())
		dispatch(fetchTeams())
		dispatch(fetchUser())
		const isInitialLoad=true
		dispatch(fetchDocs(isInitialLoad))
		dispatch(fetchRecordings())
		dispatch(fetchContacts())
		dispatch(fetchIssues())
		dispatch(fetchMilestones())
		dispatch(fetchOrg())
		dispatch(fetchRoadmaps())
		dispatch(fetchWorkflowStates())
		dispatch(fetchRecentAcitivities(isInitialLoad))	
		dispatch(fetchAllMessages(isInitialLoad))
		dispatch(fetchProjects())
		dispatch(fetchOrgMembers())
		dispatch(fetchMeetings())
		dispatch(fetchFigmaIntegration())
		dispatch(fetchGoogleIntegration())
		dispatch(fetchFigmaEmbeds())
		dispatch(fetchHighlights())
		dispatch(fetchGoogleCalEvents())
		dispatch(fetchMeetingProjectConnecions())
		dispatch(fetchTemplates())
		dispatch(fetchWhiteboardEmbeds())
		

	}
}

