import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import {getAnchorNode} from '../utils/getAnchorNode'
import {CellSelection} from 'prosemirror-tables'

//TODO don't parse whole doc?
//TODO clean up anchor thing!
//add selected styling to nodes if contained in selection
export function cursorInsideNodePlugin() {
	return new Plugin({
		props: {
			decorations: ({ doc, plugins, selection }) => {
				let {empty,from,to} = selection
				let decorations=[]
				if(empty){
					doc.nodesBetween(from,to,(node, pos) => {
						if(node.type.name=='toDoItem'){
							const decoration = Decoration.node(pos, pos + node.nodeSize, {
								class: 'doc-toDoItem--cursorInside'
							})
							decorations.push(decoration)
						}
						if(node.type.name=='docTable'){
							const decoration = Decoration.node(pos, pos + node.nodeSize, {
								class: 'doc-tableContainer--focused'
							})
							decorations.push(decoration)
						}
					})
				}else{
					doc.nodesBetween(from,to,(node, pos) => {
						if(node.type.name=='docTable'){
							const decoration = Decoration.node(pos, pos + node.nodeSize, {
								class: 'doc-tableContainer--focused'
							})
							decorations.push(decoration)
						}
					})
				}			
			return DecorationSet.create(doc, decorations)
		}
	}
	})
}
