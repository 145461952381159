import store from '../store'
import find from 'lodash/find'


export function getOrg() {	
	const state = store.getState()
	const org=state.organization

	return org 
}
