import React from 'react'
import Icon from '../misc/Icon'
import LinearNativeIcon from '../misc/LinearNativeIcon'
import linearIconNames from '../misc/LinearIconNames'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getColorForLinearHex} from '../../utils/getColorForLinearHex'


const linearIconColors = [
	// to save for Linear, we need to use the hex codes I think
  {name: "grey", 			hex: "#bec2c8"},
	{name: "lightGrey", hex: "#e2e2e2"},
	{name: "darkGrey", 	hex: "#95a2b3"},
	{name: "purple", 		hex: "#5e6ad2"},
	{name: "teal", 			hex: "#26b5ce"},
	{name: "darkTeal", 	hex: "#0f7488"},
	{name: "green", 		hex: "#4cb782"},
	{name: "darkGreen", hex: "#0f783c"},
	{name: "yellow", 		hex: "#f2c94c"},
	{name: "brown", 		hex: "#5a450d"},
	{name: "orange", 		hex: "#f2994a"},
	{name: "darkOrange", hex: "#db6e1f"},
	{name: "pink", 			hex: "#f7c8c1"},
	{name: "red", 			hex: "#eb5757"},
	{name: "darkRed", 	hex: "#c52828"},
];

class EditProjectIconPopoverLinearIcons extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectLinearColor=this.handleSelectLinearColor.bind(this)
		
		const {color}=props

		let activeColorName=null
		let activeColorHex=color
    const namedColor = getColorForLinearHex(color)
    if(namedColor){
    	activeColorName=namedColor
		}

		this.state={
      activeColorName: activeColorName,
      activeColorHex: activeColorHex,
      activeIcon: props.icon,
      hoverPreviewIcon: null,
    }
	}

	handleSelectLinearColor(color){
		this.setState({activeColorName: color.name})
		this.setState({activeColorHex: color.hex})	
		this.props.updateProjectIcon(this.props.icon,color.hex)	
	}

	handleSelectLinearIcon(icon){

		this.setState({activeIcon: icon})
		this.props.updateProjectIcon(icon,this.props.color)	
		//this.props.closePopover()
	}

	render(){	
		const {active, roadmap} = this.props	
		const {activeColorName,activeColorHex, activeIcon, hoverPreviewIcon} = this.state


		// need to go through this carefully
		const namedColor = activeColorName
		// const namedColor = null
		const customColorHex = activeColorName ? null : activeColorHex


		return (
			<React.Fragment>
								
				<div className='editMetadataPopover--type--projectIcon-content-colorRowContainer'>
					<div className='editMetadataPopover--type--projectIcon-content-colorRow'>						
						{linearIconColors.map((color, index) => (							
							<button key={index} onClick={()=>this.handleSelectLinearColor(color)} className={'editMetadataPopover--type--projectIcon-content-colorBtn ' + (color.name ? ` editMetadataPopover--type--projectIcon-content-colorBtn--color--${color.name}` : '' ) + (color.name === namedColor ? ' editMetadataPopover--type--projectIcon-content-colorBtn--active ' : ' editMetadataPopover--type--projectIcon-content-colorBtn--inactive ' )}>
								<div className='editMetadataPopover--type--projectIcon-content-colorBtn-tile' />
							</button>
				    ))}			    
					</div>
					
					<div className='editMetadataPopover--type--projectIcon-content-switchBtnContainer'>
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>														
									<button onClick={this.props.switchToEmoji}  className='editMetadataPopover--type--projectIcon-content-switchBtn'>
										<Icon name='emoji' />
									</button>
								</Tooltip.Trigger>																 
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Switch to Emoji
								</Tooltip.Content>																														
							</Tooltip.Root>
						</Tooltip.Provider>	
					</div>	

					
				</div>

				<div className={'editMetadataPopover--type--projectIcon-content-iconGrid ' + (namedColor ? ` editMetadataPopover--type--projectIcon-content-iconGrid--color--${namedColor} ` : ' ')}>
					
					{linearIconNames.map((icon, index) => (						
						<button key={index} onClick={()=>this.handleSelectLinearIcon(icon)} onMouseEnter={()=>{this.setState({hoverPreviewIcon: icon})}} onMouseLeave={()=>{this.setState({hoverPreviewIcon: null})}} className={'editMetadataPopover--type--projectIcon-content-iconBtn ' + (activeIcon && activeIcon.toLowerCase() == icon.toLowerCase() ? ' editMetadataPopover--type--projectIcon-content-iconBtn--active ' : ' editMetadataPopover--type--projectIcon-content-iconBtn--inactive ' )}>				        						      		
							<LinearNativeIcon fill={customColorHex} name={icon} />
						</button>								
			    ))}

				</div>				

				{hoverPreviewIcon &&
				<div className='editMetadataPopover--type--projectIcon-content-footer'>
					
					<div className='editMetadataPopover--type--projectIcon-content-footer-hoverPreview'>											
							<div className={'editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon ' + (namedColor ? ` editMetadataPopover--type--projectIcon-content-footer-hoverPreview-icon--color--${namedColor} ` : ' ')}>
								<LinearNativeIcon fill={customColorHex} name={hoverPreviewIcon} />
							</div>
							<div className='editMetadataPopover--type--projectIcon-content-footer-hoverPreview-label'>
								{hoverPreviewIcon}
							</div>						
					</div>				
				
				</div>
				}

			</React.Fragment>
		)
	}
}

export default EditProjectIconPopoverLinearIcons