import React from 'react'
import Icon from '../misc/Icon'
import DocEditorProjectContentTabsTabBar from './DocEditorProjectContentTabsTabBar'
import DocProjectSubDocsTable from './DocProjectSubDocsTable'
import { PhotoAlbum, RenderContainer, RenderPhoto, RenderRowContainer } from "react-photo-album";

const figmaEmbeds = [
    { src: "https://res.cloudinary.com/yarn/image/upload/v1683063741/dummydata/examplefigmaembeds/Artboard_c1yubo.png",                    id: "Yizrl9N_eDA",  title:'Results List',     width: 866,   height: 502 },

    
    { src: "https://res.cloudinary.com/yarn/image/upload/v1683063741/dummydata/examplefigmaembeds/Video_Example_Copy_299_lvjlce.png",     id: "RkBTPqPEGDo",  title:'Formatted Rows',   width: 6744,  height: 3680 },
    
    { src: "https://res.cloudinary.com/yarn/image/upload/v1683063741/dummydata/examplefigmaembeds/Video_Example_Copy_301_n7glxo.png",      id: "Yizxrl9N_eDA", title:'Results List',     width: 6744,  height: 3680 },
    { src: "https://res.cloudinary.com/yarn/image/upload/v1683063741/dummydata/examplefigmaembeds/another_f4gbob.png",                     id: "KG3TyFi0iTU", title:'Searchbar Details', width: 1020,  height: 1840 },
    { src: "https://res.cloudinary.com/yarn/image/upload/v1683063741/dummydata/examplefigmaembeds/another2_smefep.png",                    id: "Jztmx9yqjBw", title:'Alternative Search', width: 1052, height: 1212 },
    { src: "https://res.cloudinary.com/yarn/image/upload/v1683063741/dummydata/examplefigmaembeds/Video_Example_Copy_302_bdusw9.png",     id: "8gVv6nxq6gY",  title:'Search Mockup',    width: 6744,  height: 3680 },
    { src: "https://res.cloudinary.com/yarn/image/upload/v1683063741/dummydata/examplefigmaembeds/a_third_xf1tpy.png",                    id: "Dhmn6ete6g8",  title:'New Search Mock',  width: 3204,  height: 1840 },
    //{ id: "-heLWtuAN3c", title:'Primary Searchbar', width: 1080, height: 608 },
    //{ id: "xOigCUcFdA8", width: 1080, height: 720 },
    // { id: "1azAjl8FTnU", width: 1080, height: 1549 },
    // { id: "ALrCdq-ui_Q", width: 1080, height: 720 },
    // { id: "twukN12EN7c", width: 1080, height: 694 },
    // { id: "9UjEyzA6pP4", width: 1080, height: 1620 },
    // { id: "sEXGgun3ZiE", width: 1080, height: 720 },
    // { id: "S-cdwrx-YuQ", width: 1080, height: 1440 },
    // { id: "q-motCAvPBM", width: 1080, height: 1620 },
    // { id: "Xn4L310ztMU", width: 1080, height: 810 },
    // { id: "iMchCC-3_fE", width: 1080, height: 610 },
    // { id: "X48pUOPKf7A", width: 1080, height: 160 },
    // { id: "GbLS6YVXj0U", width: 1080, height: 810 },
    // { id: "9CRd1J1rEOM", width: 1080, height: 720 },
    // { id: "xKhtkhc9HbQ", width: 1080, height: 1440 },
];



class DocProjectFigmasGridCellTopBar extends React.Component{  

  constructor(props) {
    super(props)   
    this.state={
      
    }
  } 
  
  render(){       

    return (        
      
        <div className='docEditor-projectFigmasGrid-cell-topBar'>
          
          {/* <div className='docEditor-projectFigmasGrid-cell-topBar-label'>
            {this.props.title}
          </div>
          */}

          <div className='docEditor-projectFigmasGrid-cell-topBar-rightContainer'>            
            {/*}
            <button className='docEditor-projectFigmasGrid-cell-topBar-btn docEditor-projectFigmasGrid-cell-topBar-btn--source'>
              <Icon name='infoOutline' />
            </button>
            */}
            <button className='docEditor-projectFigmasGrid-cell-topBar-btn docEditor-projectFigmasGrid-cell-topBar-btn--dropdown'>
              <Icon name='ellipses' />
            </button>
          </div>


          
                          
        </div>
      
    )
  }
}




const renderPhoto: RenderPhoto = ({ layout, layoutOptions, imageProps: { alt, style, ...restImageProps }, photo }) => (
    <div key={photo.id} className='docEditor-projectFigmasGrid-cellContainer'
        style={{
            //border: "2px solid #eee",
            //borderRadius: "4px",
            boxSizing: "content-box",
            alignItems: "center",
            width: style?.width,
            padding: `${layoutOptions.padding - 2}px`,
            
        }}
    >
        
        
        <div className='docEditor-projectFigmasGrid-cell'>
          <DocProjectFigmasGridCellTopBar
            title={photo.title}
          />

        </div>

        <div className='docEditor-projectFigmasGrid-image' >        
            <img alt={alt} style={{ ...style, width: "100%", padding: 0 }} {...restImageProps} />
        </div>  
    </div>
);



class DocProjectFigmasGrid extends React.Component{  

  constructor(props) {
    super(props)   
    this.state={
      
    }
  } 
  
  render(){ 

    
    return (        
      
        <div className='docEditor-projectFigmasGrid'>

          <PhotoAlbum
            layout="rows"
            photos={figmaEmbeds}
            spacing={12}
            padding={0}
            targetRowHeight={300}            
            renderPhoto={renderPhoto}

          />

          {/*}

          <div className='docEditor-projectFigmasGrid-explanationLabel'>
            <div className='docEditor-projectFigmasGrid-explanationLabel-iconContainer'>
              <Icon name='infoOutline' />
            </div>
            <div className='docEditor-projectFigmasGrid-explanationLabel-label'>
              Found in relevant Yarn docs and Linear issues.
            </div>
          </div>
          
          */}
        

          
          
                          
        </div>
      
    )
  }
}

export default DocProjectFigmasGrid