import schema from '../schema/editorSchema'  
import {NodeSelection} from "prosemirror-state"
import {randomID} from '../../utils/randomID'




export function convertImageToThumbnail(node,startPos,endPos) {
	const state=window.view.state
	let type = schema.nodes.docThumbnail
	const attrs={
		deliveryUrl:node.attrs.deliveryUrl,
		nodeId:node.attrs.nodeId,
		height:node.attrs.height,
		width:node.attrs.width,
		title:node.attrs.title,
		description:node.attrs.description
	}
	const thumbnailNode=type.create(attrs)
	let insertPos=state.selection.from
	let tr=state.tr
	const selection = new NodeSelection(tr.doc.resolve(startPos))
	tr.setSelection(selection)
	tr.replaceSelectionWith(thumbnailNode)
	window.view.dispatch(tr)
}