import React from 'react'
import Icon from '../../misc/Icon'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import {getSuggestions,reindexSearch} from '../../../prosemirror/plugins/mentions/utils/getSuggestions'
import {isURL} from '../../../utils/isURL'
import {getRecentItems} from '../../../utils/search/getRecentItems'
import InsertMentionsMenuItem from '../../../prosemirror/plugins/mentions/InsertMentionsMenuItem'
import {insertTextLink} from '../../../prosemirror/utils/insertTextLink'

class NewTextLinkPopoverReactComponent extends React.Component{  	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.saveLinkValueChange = this.saveLinkValueChange.bind(this)		
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.handleMouseMove=this.handleMouseMove.bind(this)
    this.handleMouseOver=this.handleMouseOver.bind(this)
    this.handleMouseOut=this.handleMouseOut.bind(this)
 		this.selectIndex=this.selectIndex.bind(this)

    this.state={
      forceMount: false,  
      linkValue: '', 			
			searchResults:getRecentItems(),	
			isUrl:false	,
			activeIndex:null,
			mouseHasMoved:false       
    }    
    this.timeout=null
  }

  onRest() {
		if(!this.props.showPopover){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

	componentDidMount(){
  	reindexSearch()
  	window.addEventListener('keydown', this.handleKeyDown)		
  }
  
  componentWillUnmount(){
  	window.removeEventListener('keydown', this.handleKeyDown)		
  }

   handleMouseMove(index){
    if(!this.state.mouseHasMoved){
      this.setState({mouseHasMoved:true,activeIndex:index})
    } 
  }

  handleMouseOver(index){
    if(this.state.mouseHasMoved){
      this.setState({activeIndex:index})
    } 
  }

  handleMouseOut(index){
    this.setState({activeIndex:index})
  }

  closeModal(){
  // 	let tr=window.view.state.tr
		// tr.setMeta("newTextLinkPlugin", { deactivate: true})
		// window.view.dispatch(tr)
  }

  //insertTextLink(type,docId,projectId,issueId,url
  selectIndex(index){
  	const item=this.state.searchResults[index]
  	let type
  	let docId
  	let projectId 
  	let issueId 
  	let url
    if(item.type=='web'){
      type='weblink'
      url=item.url
    }
  	else if(item.doc_id){
  		type='doc'
  		docId=item.doc_id
  	}else if(item.project_id){
  		type='project'
  		projectId=item.project_id
  	}else if(item.issue_id){
  		type='issue'
  		issueId=item.issue_id
  	}
  	insertTextLink(type,docId,projectId,issueId,url)
  }


 
   handleKeyDown(e){
    if(e.keyCode === 40) { //down arrow
    	const {searchResults}=this.state
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex+1 || 0
      if(newIndex==searchResults.length){
        newIndex=0
      }
      if(oldIndex==null){
        newIndex=0
      }
      this.setState({activeIndex:newIndex})
    } else if (e.keyCode === 38) { //up arrow
      const {searchResults}=this.state
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex-1
      if(newIndex<0){
        newIndex=searchResults.length-1
      }
      this.setState({activeIndex:newIndex})
    } else if( e.keyCode === 13) { //enter
      e.stopPropagation()
      e.preventDefault()
      if(this.state.activeIndex || this.state.activeIndex==0){
        this.selectIndex(this.state.activeIndex)
      }
    } else if (e.keyCode === 27) { //escape
      this.closeModal()
    } 
  }

  saveLinkValueChange(event) {
    let searchResults=[]  
  	const value=event.target.value
  	let stringIsUrl=isURL(value)  
    let newActiveIndex=0
    if(stringIsUrl){
      const suggestion={
        type:'web',
        url:value
      }
      searchResults.push(suggestion)
    }else{
      if(value){
       searchResults=getSuggestions(value).slice(0,6)
      }else{
        searchResults=getRecentItems()
        newActiveIndex=null
      }
    }
    this.setState({
    	linkValue: value,
    	searchResults:searchResults,
    	isUrl:stringIsUrl,
    	mouseHasMoved:false,
    	activeIndex:newActiveIndex
    })    
  }



	

	render(){	
		const {showPopover, openPopover, closePopover, inverted} = this.props		
		const {forceMount} = this.state

		const {title,url}=this.props
		const {showEditTitleInput,showModal,searchResults,isUrl,linkValue}=this.state
		const hasSearchInput = this.state.linkValue
		const searchInputUrl = linkValue
		const searchInputIsUrl = isUrl

		// const {title,url,type,projectId,issueId,docId}=this.props
		// const {showEditTitleInput,showModal,searchMode,searchResults, showInputPreview}=this.state

		// //const searchMode = true // for adding new links or editing existing
		// const hasSearchInput = this.state.linkValue		
		

	  return (
	  	<React.Fragment>
	  		<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
					<Popover.Content
			    	className={'docTextLinkPopoverContainer docTextLinkPopoverContainer--new ' + (showPopover ? ' docTextLinkPopoverContainer--visible ' : ' docTextLinkPopoverContainer--hidden ')} 
			    	onEscapeKeyDown={closePopover}
				    onPointerDownOutside={closePopover}
				    onInteractOutside={closePopover}			    
				    side="bottom"
				    sideOffset={4}
				    forceMount={showPopover ? true : forceMount}
				    //asChild
			    >
				    <Transition
			        items={showPopover}
			        from={{ opacity: 0, transform: `translateY(2px)` }}
			        enter={{ opacity: 1, transform: 'translateY(0px)'  }}
			        leave={{ opacity: 0, transform: `translateY(2px)`  }}
			        reverse={showPopover}
			        config={{tension: 600, friction: 30}}
			        onRest={this.onRest}
			       >
			        {(styles, item) =>
			          item && 
			           <animated.div style={styles} className="doc-textLink-popover doc-textLink-popover--newLink">
									 <div className='doc-textLink-popover-inputContainer doc-textLink-popover-inputContainer--new doc-textLink-popover-inputContainer--link doc-textLink-popover-inputContainer--searchMode'>
										<input 						
											className='doc-textLink-popover-input'
											placeholder='Type to search or paste a link...'
											value={this.state.linkValue}
											onChange={this.saveLinkValueChange}
											autoComplete="off"
											autoCorrect="off"
											spellCheck={false}		
											autoFocus				
										/>
									</div>
									<div className='doc-textLink-popover-resultsContainer'>																					
										{searchResults.map((suggestion,index)=>{
											let suggestionType='doc'
                      if(suggestion.type=='web'){
                        suggestionType='web'
                      }
		            			else if(suggestion.project_id){
		              			suggestionType='project'
		            			}else if(suggestion.issue_id){
		              			suggestionType='issue'
		            			}
											return(
												<InsertMentionsMenuItem 
													key={`suggestion_${index}`}
													suggestion={suggestion}
		              				index={index}
		              				suggestionType={suggestionType}
		              				activeIndex={this.state.activeIndex}
		              				selectIndex={this.selectIndex}
		              				handleMouseMove={this.handleMouseMove}
		              				handleMouseOver={this.handleMouseOver}
		              				//mouseHasMoved={this.state.mouseHasMoved}
		              				hidePopover

												/>
											)
										})
									}
									</div>
			          </animated.div>
			        }
			      </Transition> 										
			    </Popover.Content>
			    </Popover.Portal>
			  </Popover.Root>		
			</React.Fragment>
	  )
	}
}

export default NewTextLinkPopoverReactComponent