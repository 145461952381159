import React from 'react'
import {DocFigmaEmbedZoomedModalCropboxCornerHandle, DocFigmaEmbedZoomedModalCropboxLeftRightEdgeHandle, DocFigmaEmbedZoomedModalCropboxTopBottomEdgeHandle} from '../docEditor/mediaModals/figma/DocFigmaEmbedZoomedModalCropHandles'
import { Rnd } from "react-rnd"

const MINIMUM_CROP_SIDE = 60 // in points
const CROP_MODE_OFFSET = 24 // in pixels
const CROP_SNAP_MARGIN = 12

class FigmaViewZoomedUICropCanvas extends React.Component{  
 
	render(){ 
		const {frameWidth, frameHeight, frameImageUrl, cropModeActive, canvasWidth, canvasHeight}=this.props
		const {cropboxWidth, cropboxHeight, cropboxX, cropboxY,updateCropValues,updateCropPosition,updateSnappedCropPosition} = this.props		

		//
		// INITIAL CANVAS SETUP
		const canvasOuterPixelWidth = canvasWidth
		const canvasOuterPixelHeight = canvasHeight

		const cropModeOffsetPercentage = 1 - ((CROP_MODE_OFFSET * 2) / canvasOuterPixelWidth)

		let canvasPixelWidth = canvasOuterPixelWidth * cropModeOffsetPercentage
		let canvasPixelHeight = canvasOuterPixelHeight * cropModeOffsetPercentage

		//
		// CALCULATE SOME ASPECT RATIOS
		const canvasAspect = canvasPixelWidth / canvasPixelHeight
		const frameAspect = frameWidth / frameHeight			

		//
		// FIND HOW FULL FRAME FITS INTO CANVAS 
		let fitFramePixelWidth
		let fitFramePixelHeight

		if(canvasAspect >= frameAspect){
			// canvas is wider than image, so fits height wise			
			fitFramePixelHeight = canvasPixelHeight
			fitFramePixelWidth = fitFramePixelHeight * frameAspect
		}

		if(canvasAspect < frameAspect){			
			// canvas is narrower than image, so fits width wise
			fitFramePixelWidth = canvasPixelWidth
			fitFramePixelHeight = fitFramePixelWidth / frameAspect
		}		
		
		const fitFramePixelLeft = (canvasPixelWidth - fitFramePixelWidth) / 2
		const fitFramePixelTop = (canvasPixelHeight - fitFramePixelHeight) / 2
		const fitFrameScale = fitFramePixelWidth / frameWidth

		// 
		// FIND HOW CROPBOX FITS		
		// we also need to calculate to image, so that we can position image inside of cropbox

		const scaledCropXtoImage = cropboxX * fitFrameScale
		const scaledCropYtoImage = cropboxY * fitFrameScale
		const scaledCropWidth = cropboxWidth * fitFrameScale
		const scaledCropHeight = cropboxHeight * fitFrameScale

		const scaledCropXToCanvas = scaledCropXtoImage + fitFramePixelLeft
		const scaledCropYToCanvas = scaledCropYtoImage + fitFramePixelTop

		const inverseImageLeftToCanvas = scaledCropXtoImage * -1
		const inverseImageTopToCanvas = scaledCropYtoImage * -1

		return (
													
	  		<div className='doc-zoomedFigmaModal-canvasInnerContainer'>	  		  		
					<div style={{width: `${canvasPixelWidth}px`, height: `${canvasPixelHeight}px`}} className='doc-zoomedFigmaModal-canvas'>  										
						<div style={{top: `${scaledCropYToCanvas}px`, left: `${scaledCropXToCanvas}px`, width: `${scaledCropWidth}px`, height: `${scaledCropHeight}px`}} className='doc-zoomedFigmaModal-cropContainer'>																															
	  					
							{/* Adds dark edges around cropbox */}
	  					<div className='doc-zoomedFigmaModal-cropContainer-imageBlockerContainer'>
		  					<div className='doc-zoomedFigmaModal-cropContainer-imageBlocker doc-zoomedFigmaModal-cropContainer-imageBlocker--top' />
		  					<div className='doc-zoomedFigmaModal-cropContainer-imageBlocker doc-zoomedFigmaModal-cropContainer-imageBlocker--centerLeft' />
		  					<div className='doc-zoomedFigmaModal-cropContainer-imageBlocker doc-zoomedFigmaModal-cropContainer-imageBlocker--centerRight' />
		  					<div className='doc-zoomedFigmaModal-cropContainer-imageBlocker doc-zoomedFigmaModal-cropContainer-imageBlocker--bottom' />
		  				</div>
				  									  				
				  		{/* The image itself */}
	  					<div style={{top: `${inverseImageTopToCanvas}px`, left: `${inverseImageLeftToCanvas}px`, width: `${fitFramePixelWidth}px`, height: `${fitFramePixelHeight}px`}} className='doc-zoomedFigmaModal-croppedImageContainer'>	  							  						
	  						<img src={frameImageUrl} className='doc-zoomedFigmaModal-image' />	  							  						
	  					</div> 

	  					{/* The dotted border to the cropbox */}				  					
			  			<div className='doc-zoomedFigmaModal-cropOutline' /> 

						</div>				  			  				  			  	
		  		</div>	  	
  			
	  			<div style={{width: `${fitFramePixelWidth}px`, height: `${fitFramePixelHeight}px`}} className='doc-zoomedFigmaModal-canvas-cropUIContainer doc-zoomedFigmaModal-canvas-cropUIContainer--show'>
		  			<Rnd
						  className='doc-zoomedFigmaModal-canvas-cropUI'
						  size={{ 
						  	width: cropboxWidth * fitFrameScale,
						  	height: cropboxHeight * fitFrameScale
						  }}
						  position={{
						  	x: cropboxX * fitFrameScale,
						  	y: cropboxY * fitFrameScale
						  }}
						  onDrag={(e, d) => { 						  	
						  	updateCropPosition({
						  		cropboxX: d.x/fitFrameScale,
						  		cropboxY: d.y/fitFrameScale
						  	}) 
						 	}}
						  onDragStop={(e, d, direction, ref, delta, position) => { 
						  	// not sure how to do move-box snapping, looks like delta based						  	
						  	updateSnappedCropPosition({
						  		cropboxX: d.x/fitFrameScale,
						  		cropboxY: d.y/fitFrameScale
						  	}) 						  	
						 	}}
						 	onResize={(e, direction, ref, delta, position) => {
	              updateCropValues({
	                cropboxWidth: ref.offsetWidth/fitFrameScale,
	                cropboxHeight: ref.offsetHeight/fitFrameScale,
	                cropboxX: position.x/fitFrameScale, 
	                cropboxY: position.y/fitFrameScale
	              })              
            	}}
						  onResizeStop={(e, direction, ref, delta, position) => {
						    let snappedWidth = ref.offsetWidth/fitFrameScale
                let snappedHeight = ref.offsetHeight/fitFrameScale
                let snappedX=position.x/fitFrameScale
                let snappedY=position.y/fitFrameScale

                // general full width/height snapping
                if((frameWidth - snappedWidth) < CROP_SNAP_MARGIN){
                	snappedWidth = frameWidth
                	snappedX = 0
                }
                if((frameHeight - snappedHeight) < CROP_SNAP_MARGIN){
                	snappedHeight = frameHeight
                	snappedY = 0
                }

                // far-edge snapping
                if((frameWidth - snappedX - snappedWidth) < CROP_SNAP_MARGIN){
                	snappedWidth = frameWidth - snappedX
                }

                if((frameHeight - snappedY - snappedHeight) < CROP_SNAP_MARGIN){
                	snappedHeight = frameHeight - snappedY
                }

                // near-edge snapping
                if(snappedX < CROP_SNAP_MARGIN){
                	snappedWidth += snappedX // need to add on the difference before setting to zero
                	snappedX = 0
                }
                if(snappedY < CROP_SNAP_MARGIN){
                	snappedHeight += snappedY
                	snappedY = 0
                }

						    updateCropValues({
						      cropboxWidth: snappedWidth,
						      cropboxHeight: snappedHeight,
						      cropboxX: snappedX, 
                	cropboxY: snappedY
						    });
						  }}				
		        	minWidth={MINIMUM_CROP_SIDE * fitFrameScale}
		        	minHeight={MINIMUM_CROP_SIDE * fitFrameScale}
		        	bounds='.doc-zoomedFigmaModal-canvas-cropUIContainer'				        	
		        	resizeHandleComponent={{ 
		        		left: <DocFigmaEmbedZoomedModalCropboxLeftRightEdgeHandle />,
		        		right: <DocFigmaEmbedZoomedModalCropboxLeftRightEdgeHandle />,
		        		topLeft: <DocFigmaEmbedZoomedModalCropboxCornerHandle />,
		        		top: <DocFigmaEmbedZoomedModalCropboxTopBottomEdgeHandle />,
		        		topRight: <DocFigmaEmbedZoomedModalCropboxCornerHandle />,
		        		bottom: <DocFigmaEmbedZoomedModalCropboxTopBottomEdgeHandle />,
		        		bottomLeft: <DocFigmaEmbedZoomedModalCropboxCornerHandle />,
		        		bottomRight: <DocFigmaEmbedZoomedModalCropboxCornerHandle />,			        		
		        	}}
						>
						</Rnd>
		  		</div>		  	

		  		<div onClick={this.props.disableCropMode} className='doc-zoomedFigmaModal-canvas-bgCloseCropBtn' />	  	
		  		
	  		</div>



      
		)
	}
}

export default FigmaViewZoomedUICropCanvas
