import {fetch,put} from '../api'
import {bumpUsersVersion} from '../prosemirror/utils/toDos/bumpUsersVersion'


export function fetchOrgMembers() {
	return (dispatch) => fetch(`/organization/members`)
		.then((response) => {
			dispatch({ type: 'FETCH_MEMBERS_SUCCESS', response })
			bumpUsersVersion()
			return response
		})
		.catch((error) => {
			return error
		})
}

export function syncMembers() {
	return (dispatch) => put(`/members/sync`)
		.then((response) => {
			dispatch({ type: 'FETCH_MEMBERS_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}


// export function syncMembers() {
// 	return (dispatch) => fetch(`/sync/members`)
// 		.then((response) => {
// 			dispatch(fetchChannels())
// 			dispatch(fetchOrgMembers())

// 		})
// 		.catch((error) => {
// 			return error
// 		})
// }


// export function syncOrg() {
// 	return (dispatch) => fetch(`/sync/organization`)
// 		.then((response) => {
// 			dispatch(fetchOrg())
// 		})
// 		.catch((error) => {
// 			return error
// 		})
// }