import {Schema} from 'prosemirror-model'
import EditorNodes from './nodes/EditorNodes'
import EditorMarks from './marks/EditorMarks'
import {addListNodes} from "prosemirror-schema-list"
import {tableNodes} from 'prosemirror-tables'
import DocTableNodeSpec from './nodes/DocTableNodeSpec'

//https://discuss.prosemirror.net/t/nodes-append-is-not-a-function/2216/2
const mySchema = new Schema({
  nodes:EditorNodes,
  marks: EditorMarks
})





const editorSchema = new Schema({
  nodes:addListNodes(mySchema.spec.nodes,"paragraph block*", "block").append(
    tableNodes({
      tableGroup: 'block',
      cellContent: 'block+',
      cellAttributes: {
        colwidth:{default:[140]},
        align:{default:'left'},
        background: {
          default: 'white',
          getFromDOM(dom) {
            return dom.style.backgroundColor || null;
          },
          setDOMAttr(value, attrs) {
            if (value)
              attrs.style = (attrs.style || '') + `background-color: ${value};`;
          },
        },
      },
    }),
  ).append({docTable:DocTableNodeSpec}),
  marks: EditorMarks
})



export default editorSchema