import store from '../store'
import {bumpWhiteboardEmbedVersion} from '../prosemirror/utils/whiteboards/bumpWhiteboardEmbedVersion'
import {bumpSystemThemeVersion} from '../prosemirror/utils/bumpSystemThemeVersion'

export function toggleTheme(){
	const state = store.getState()
	let newTheme='dark'
	if(state.theme==='dark'){
		newTheme='light'
	}
	store.dispatch({type:'SET_THEME',syncAction:true ,theme:newTheme})

	bumpWhiteboardEmbedVersion()
	bumpSystemThemeVersion()

	if(window.isElectron){
			const args={theme:newTheme}
			window.ipcRenderer.send('set-theme',args)
		}
}
