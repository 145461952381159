import React from 'react'
import { withRouter} from 'react-router-dom'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getRootDocIdForProject} from '../../../utils/getRootDocIdForProject'
import LinearMarker from '../../misc/LinearMarker'
import {openDoc} from '../../../utils/openDoc'

class RoadmapsHomePageQuarterProjectRow extends React.PureComponent{  	

	constructor(){
    super()  
    this.openProjectDoc=this.openProjectDoc.bind(this)    
  }		


  openProjectDoc(){
    const docId=getRootDocIdForProject(this.props.project.project_id)
    openDoc(this.props.history,docId)
  }

	render(){		
		const {project}=this.props  		
	  return (	  	
  		<div className='roadmapsHomePage-quarter-projectRow' onClick={this.openProjectDoc}>
  			<div className='roadmapsHomePage-quarter-projectRow-markerContainer'>
  				<LinearMarker projectId={project.project_id} />
  			</div>
  			<div className='roadmapsHomePage-quarter-projectRow-name'>  				
  				{project.name}  				
  			</div>
  		</div>
	  )
	}
}

export default withRouter(RoadmapsHomePageQuarterProjectRow)
