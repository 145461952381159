import React from 'react'
import debounce from 'lodash/debounce'
import DocFigmaEmbedPlaceholder from './DocFigmaEmbedPlaceholder'
import {getFigmaEmbedForId} from '../../../utils/getFigmaEmbedForId'
import {showDocMediaModal} from '../../../utils/showDocMediaModal'
import {hideDocMediaModal} from '../../../utils/showDocMediaModal'
import FigmaView from '../../figma/FigmaView'
import FigmaViewAnimatedZoomedModal from '../../figma/FigmaViewAnimatedZoomedModal'
import DocFigmaEmbedTopbar from './DocFigmaEmbedTopbar'
import DocFigmaEmbedEffectsToolbarContainer from './DocFigmaEmbedEffectsToolbarContainer'
import {getDeviceForFigmaFrame} from '../../../utils/getDeviceForFigmaFrame'
import {getDocThreadForNode} from '../../../utils/threads/getDocThreadForNode'

const boundingMaxContainerDimensions={
	width: 720,
	height: 500
}

class DocFigmaEmbedReactComponent extends React.Component{  
	static getDerivedStateFromProps(props, state) {
		if (props.backgroundPadding && props.backgroundPadding !== state.prevPadding) { //handle padding updated by another user
			return ({prevPadding:props.backgroundPadding,backgroundPadding:props.backgroundPadding }) // <- this is setState equivalent
		}
		return null
	}
 
	constructor(props){
    super(props)
    this.zoomFigma=this.zoomFigma.bind(this)
    this.unzoomFigma=this.unzoomFigma.bind(this)
    this.showEffectsToolbar=this.showEffectsToolbar.bind(this)
    this.hideEffectsToolbar=this.hideEffectsToolbar.bind(this)
    this.updateBackgroundPadding=this.updateBackgroundPadding.bind(this)
    this.saveBackgroundPadding=this.saveBackgroundPadding.bind(this)
    this.saveBackgroundPadding=debounce(this.saveBackgroundPadding,1000,{leading:false})
    
    this.state={
    	effectsToolbarVisible:false,
    	prevPadding:props.backgroundPadding,
     	backgroundPadding: props.backgroundPadding,
			zoomed: false,
			boundingContainerWidth: boundingMaxContainerDimensions.width,
    }
    this.timeout=null
  }

  componentWillUnmount(){
  	if(this.timeout){
  		clearTimeout(this.timeout)
  	}
  }

   saveBackgroundPadding(value){
  	this.props.updateNodeAttribute('backgroundPadding',this.state.backgroundPadding)
  }

  updateBackgroundPadding(value){ //do this so is more peformant
  	this.setState({backgroundPadding:value})
  	this.saveBackgroundPadding(value)
  }	

  showEffectsToolbar(){
  	this.setState({effectsToolbarVisible:true})
  }

  hideEffectsToolbar(){
  	this.setState({effectsToolbarVisible:false})
  }

  zoomFigma(){
  	if(this.timeout){
  		clearTimeout(this.timeout)
  	}
  	this.setState({zoomed:true})
  	const modalType="zoomedFigma"
  	const nodeId=this.props.embedId
  	showDocMediaModal(modalType,nodeId)
		const div=document.getElementById(`${this.props.embedId}`)	
		if(div){
			const rect=div.getBoundingClientRect()
			const commentTopXPos = rect.top // relative to the viewport
			const commentBottomXPos = rect.bottom // relative to the viewport
			const scrollDiv=document.getElementById('scrollable_div') // find scroll position of thing					
			if(scrollDiv){						
				const pageScroll = scrollDiv.scrollTop	
				const windowHeight = window.innerHeight // find height of window 	  				
				const topPageHeaderBuffer = 50 // buffer for the in doc top header
				const generalBuffer = 20 // nice to have a general buffer as well, so can see comment in context  				
				const commentBelow = commentBottomXPos + generalBuffer > windowHeight // comment is below window
				const commentAbove = commentTopXPos < (topPageHeaderBuffer + generalBuffer) // comment is above window	  				
				const commentAboveDeltaX = commentTopXPos - generalBuffer - topPageHeaderBuffer
				const commentBelowDeltaX = (commentBottomXPos + generalBuffer) - windowHeight
				if(commentAbove){	  					
				scrollDiv.scrollBy(0, commentAboveDeltaX); // scrollBy is relative
				}
				if(commentBelow){
					scrollDiv.scrollBy(0, commentBelowDeltaX); // scrollBy is relative
				}
			}
		}
  }

  unzoomFigma(){
  	this.setState({zoomed:false})
    this.timeout= setTimeout(() => {
 			hideDocMediaModal()
   	}, 350);   
  	
  }

	render(){ 
		const {effectsToolbarVisible,backgroundPadding} = this.state
		const {embedId,documentTitle,crop,backgroundImage,deviceFrame,nodeId}=this.props
		const embedObj = getFigmaEmbedForId(embedId)		
		
		let title,width,height,deliveryUrl,device
		let importComplete=false		
		if(embedObj){
			width = embedObj.absolute_render_bounds.width // could also be absolute_bounding_box
			height = embedObj.absolute_render_bounds.height // could also be absolute_bounding_box
			deliveryUrl = embedObj.image_url
			title = embedObj.node_title	
			importComplete=true
			device = getDeviceForFigmaFrame(embedObj.absolute_render_bounds.width, embedObj.absolute_render_bounds.height)	
		}
		// This is measured width of docEditor-docOuterContainer
		// I think have to measure because that's affected by if sidepanel is open, message panel is present, and screen width
		const docEditorDocOuterContainerWidth = 760 
		let docFigmaContainerWidth = 760
		if(docEditorDocOuterContainerWidth > 960 && docEditorDocOuterContainerWidth < 1090){
			docFigmaContainerWidth = 840
		}
		if(docEditorDocOuterContainerWidth >= 1090){
			docFigmaContainerWidth = 960
		}

		const {zoomed}=this.state
		const hasPresentationEffects = deviceFrame || backgroundImage

		const docThread=getDocThreadForNode(nodeId)


		return(

			<React.Fragment>


				{!importComplete &&
					<DocFigmaEmbedPlaceholder																	
						docFigmaContainerWidth={docFigmaContainerWidth}
					/>
				}

				{importComplete &&
				<div style={{width: `${boundingMaxContainerDimensions.width}px`}} className='doc-figmaEmbed'>
					
					<DocFigmaEmbedTopbar
						title={title}			
						docId={this.props.docId}
						docThread={docThread}		
						openThread={this.props.openThread}	
						openEffectsToolbar={this.showEffectsToolbar}
						hasPresentationEffects={hasPresentationEffects}
						width={this.state.boundingContainerWidth}				
						deleteFigmaEmbed={this.props.deleteFigmaEmbed}
						//leftMargin={topbarLeftMargin}
						// TO DO - ADD THESE BACK
						newMessage={this.props.newMessage}
						docId={this.props.docId}
						effectsToolbarVisible={this.state.effectsToolbarVisible}
						embedId={embedId}
					/>
					<div className='doc-figmaEmbed-figmaViewWrapper'>
						{embedObj &&
							<FigmaView 
								embedId={embedId}
								embedObj={embedObj}		
								backgroundPadding={backgroundPadding}				
								boundingMaxContainerDimensions={boundingMaxContainerDimensions}					
								backgroundMaxWidth // if not cropped, fit full width. useful for mobile/portrait images.						
								backgroundImage={backgroundImage}
								deviceFrame={deviceFrame}							
								zoomed={zoomed}	
								zoom={this.zoomFigma}
								unzoom={this.unzoomFigma}
								crop={crop}					
								openEffectsToolbar={this.showEffectsToolbar}
								showEffectsToolbar={effectsToolbarVisible}
								hideEffectsToolbar={this.hideEffectsToolbar}
								deleteFigmaEmbed={this.props.deleteFigmaEmbed}
								//setBoundingContainerWidth={(value)=>{this.setState({boundingContainerWidth: value})}}
								//want to set bounding container width so topbar is right width
							/>
						}
						<div className='doc-figma--selectionBG' />						
					</div>
						<DocFigmaEmbedEffectsToolbarContainer

							showEffectsToolbar={effectsToolbarVisible}
							hideEffectsToolbar={this.hideEffectsToolbar}
							backgroundPadding={backgroundPadding}
							editBackgroundPadding={(value)=>{this.updateBackgroundPadding(value)}}
							backgroundImage={backgroundImage}
							editBackgroundImage={(value)=>{this.props.updateNodeAttribute('backgroundImage',value)}}
							deviceFrame={deviceFrame}
							editDeviceFrame={(value)=>{this.props.updateNodeAttribute('deviceFrame',value)}}
							device={device}				
						/>

						{embedObj &&
							<FigmaViewAnimatedZoomedModal 
								embedId={embedId}
								embedObj={embedObj}						
								boundingMaxContainerDimensions={boundingMaxContainerDimensions}					
								backgroundMaxWidth // if not cropped, fit full width. useful for mobile/portrait images.				
								disableMotion={false} // if	true, all animations are instant. useful for thumbnails. also probs a user setting in future.		
								zoomed={zoomed}
								zoom={this.zoomFigma}
								unzoom={this.unzoomFigma}
								backgroundPadding={backgroundPadding}
								backgroundImage={backgroundImage}
								deviceFrame={deviceFrame}								
								crop={crop}
								saveCrop={(crop)=>this.props.updateNodeAttribute('crop',crop)}
									hideEffectsToolbar={this.hideEffectsToolbar}
								// for toolbar								
								backgroundPadding={backgroundPadding}
								editBackgroundPadding={(value)=>{this.updateBackgroundPadding(value)}}
								backgroundImage={backgroundImage}
								editBackgroundImage={(value)=>{this.props.updateNodeAttribute('backgroundImage',value)}}
								deviceFrame={deviceFrame}
								editDeviceFrame={(value)=>{this.props.updateNodeAttribute('deviceFrame',value)}}
								device={device}
								deleteFigmaEmbed={this.props.deleteFigmaEmbed}
								docId={this.props.docId}
								sendMediaModalMessage={this.props.sendMediaModalMessage}
							/>
						}

				</div>
			}

			</React.Fragment>
		)
	}
}

export default DocFigmaEmbedReactComponent

		