import React from 'react'
import Icon from '../../../misc/Icon'
import {showToastNotification} from '../../../../actions/toastNotification'
import moment from 'moment';
import { useDroppable, useDraggable, DndContext, DragOverlay} from '@dnd-kit/core';
import store from '../../../../store'


const ProjectComponent = React.forwardRef(({projectName, style, ...props}, ref) => (
  <div ref={ref} style={style} {...props} className='docEditor-meetingContent-activeProjects-calendar-project'>
    <div className="docEditor-meetingContent-activeProjects-calendar-project-markerContainer">
      {projectName ==='Onboarding' ? '🎫' : ''}
      {projectName ==='Claude API' ? '🧠' : ''}
      {projectName ==='Slack Alerts' ? '📬' : ''}
    </div>
    <div className="docEditor-meetingContent-activeProjects-calendar-project-label">
      {projectName}
    </div>
  </div>
))

const DraggableProject = ({ projectName, projectID }) => {
  const {attributes, listeners, setNodeRef, transform} = useDraggable({ id: projectID });  
  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <ProjectComponent 
      ref={setNodeRef} 
      style={style}
      {...listeners}
      {...attributes}
      projectName={projectName}      
    />
  )
}


const DroppableDay = ({ day, i, j, projects }) => {
  
  let isMonthStart = false

  if(day.date() === 1 || (i === 0 && j === 0)) {
    isMonthStart = true
  }
  
   const formatDayLabel = () => {
    if (day.date() === 1 || (i === 0 && j === 0)) {
      return day.format('MMM D');      
    }
    return day.date();
  }

  const isToday = moment().isSame(day, 'day');
  const isWeekendDay = [0, 6].includes(day.day());

  const { isOver, setNodeRef } = useDroppable({
    id: day.format('YYYY-MM-DD'),
  });

  const handleDrop = (projectID) => {
    moveProject(projectID, day);
  };


  
  return (
    <div ref={setNodeRef} className={'docEditor-meetingContent-activeProjects-calendar-dayCell' + (isMonthStart ? ' docEditor-meetingContent-activeProjects-calendar-dayCell--isMonthStart ' : '') + (isOver ? ' docEditor-meetingContent-activeProjects-calendar-dayCell--isOver ' : '') + (isToday ? ' docEditor-meetingContent-activeProjects-calendar-dayCell--isToday ' : '') + (isWeekendDay ? ' docEditor-meetingContent-activeProjects-calendar-dayCell--isWeekendDay ' : '')}>
      <div className="docEditor-meetingContent-activeProjects-calendar-dayCell-label">
        {formatDayLabel()}
      </div>
      {projects && projects.map((project, index) => (
        <DraggableProject 
          key={index} 
          projectName={project.projectName} 
          projectID={project.projectID} 
        />
      ))}
    </div>
  );
}




class DocEditorMeetingContentActiveProjectsCalendar extends React.PureComponent{    

   state = {
    weeks: this.getWeeks(),
    projects: [ 
      {projectID: 1, projectName: 'Claude API', target_date: Date.now() + 1000 * 60 * 60 * 24 * 3},
      {projectID: 2, projectName: 'Slack Alerts', target_date: Date.now() + 1000 * 60 * 60 * 24 * 12},
      {projectID: 3, projectName: 'Onboarding', target_date: Date.now() + 1000 * 60 * 60 * 24 * -4},
      // {projectID: 3, projectName: 'Project 3', target_date: moment().add(3, 'days').valueOf()},
      // {projectID: 4, projectName: 'Project 4', target_date: moment().add(4, 'days').valueOf()},
      // {projectID: 5, projectName: 'Project 5', target_date: moment().add(5, 'days').valueOf()}
      // Add more projects here...
    ],

    isDragging: false,
  };

   getWeeks() {
    let weeks = [];
    let startWeek = moment().subtract(1, 'weeks').startOf('week');

    const forwardWeeks = 5
    const totalWeeks = forwardWeeks + 1

    for(let i = 0; i < totalWeeks; i++) {
      let days = Array(7).fill(0).map((n, i) => startWeek.clone().add(n + i, 'days'));
      weeks.push(days);
      startWeek.add(1, 'week');
    }

    return weeks;
  }

  getProjectsForDay(day) {
    return this.state.projects.filter(project => moment(project.target_date).isSame(day, 'day'));
  }

  onDragStart = () => {
    this.setState({
      activeId: event.active.id,
    });
    document.body.style.setProperty('cursor', 'grabbing');
  }


  onDragEnd = (event) => {
    const { active, over } = event;
    this.setState({
      activeId: null,
    });
    if (active && over) {
      this.moveProject(active.id, moment(over.id, 'YYYY-MM-DD'));
    }
    document.body.style.setProperty('cursor', '');
    store.dispatch(showToastNotification('updatedTargetDateCalendar'))

  };


  moveProject = (projectId, newDate) => {
  this.setState(prevState => {
    const projects = prevState.projects.map(project => 
      project.projectID === projectId ? { ...project, target_date: newDate } : project
    );

    return { projects };
  });
};



  render(){ 
  

    const startMonth = moment().subtract(1, 'weeks').startOf('week');
    const startMonthLabel = startMonth.format('MMMM');

    const endMonth = moment().add(5, 'weeks').endOf('week');
    const endMonthLabel = endMonth.format('MMMM YYYY');
    
    const weeksWithProjects = this.state.weeks.map(week => 
    week.map(day => ({
      day,
      projects: this.getProjectsForDay(day)
    }))
  );
    
    const { activeId } = this.state;
    const activeProject = this.state.projects.find((project) => project.projectID.toString() === activeId);

    return (        
      <DndContext onDragStart={this.onDragStart} onDragEnd={this.onDragEnd}>
        
        <div className={'docEditor-meetingContent-activeProjects-calendar ' + (this.state.isDragging ? ' docEditor-meetingContent-activeProjects-calendar--isDragging ' : ' docEditor-meetingContent-activeProjects-calendar--notDragging ')}>
          {/*}
          <div className='docEditor-meetingContent-activeProjects-calendar-startMonth'>        
            {startMonthLabel} - {endMonthLabel}
          </div>
          */}
          <div className='docEditor-meetingContent-activeProjects-calendar-dayOfWeekRow'>        
            <div className='docEditor-meetingContent-activeProjects-calendar-dayOfWeekCell'>        
              Sun
            </div>
            <div className='docEditor-meetingContent-activeProjects-calendar-dayOfWeekCell'>        
              Mon
            </div>
            <div className='docEditor-meetingContent-activeProjects-calendar-dayOfWeekCell'>        
              Tue
            </div>
            <div className='docEditor-meetingContent-activeProjects-calendar-dayOfWeekCell'>        
              Wed
            </div>
            <div className='docEditor-meetingContent-activeProjects-calendar-dayOfWeekCell'>        
              Thu
            </div>
            <div className='docEditor-meetingContent-activeProjects-calendar-dayOfWeekCell'>        
              Fri
            </div>
            <div className='docEditor-meetingContent-activeProjects-calendar-dayOfWeekCell'>        
              Sat
            </div>
          </div>

          {weeksWithProjects.map((week, i) =>
            <div key={i} className="docEditor-meetingContent-activeProjects-calendar-weekRow">
              {week.map(({ day, projects }, j) =>
                <div className='docEditor-meetingContent-activeProjects-calendar-dayCellContainer' key={day.format('YYYY-MM-DD')}>
                  <DroppableDay
                    i={i}
                    j={j}
                    day={day}
                    projects={projects}
                  />
                </div>
              )}
            </div>
          )}   
        </div>     

         <DragOverlay>
          {activeId ? <ProjectComponent projectName={activeProject.projectName} /> : null}
        </DragOverlay>
      </DndContext>
    )
  }
}

export default DocEditorMeetingContentActiveProjectsCalendar
