export default function (state = false, action) {
	switch (action.type) {
    case 'ZOOM_MESSAGE_MEDIA_MODAL':
      return true

    case 'UNZOOM_MESSAGE_MEDIA_MODAL':
      return false
    default:
      return state;
	}
}
