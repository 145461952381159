import React from 'react'
import Icon from './Icon'

class LinearIssuePriorityIcon extends React.PureComponent{    
  render(){ 
    
    const {priority} = this.props

    return (
      <div className='linearIssuePriorityIconContainer'>
      
        {(priority === 2|| priority === 3 || priority === 4) &&
          <div className={'linearIssuePriorityIcon' + (priority ? ` linearIssuePriorityIcon--priority--${priority}` : '')}>
            <div className='linearIssuePriorityIcon-bars'>
              <div className='linearIssuePriorityIcon-bar' />
              <div className='linearIssuePriorityIcon-bar' />
              <div className='linearIssuePriorityIcon-bar' />
            </div>
          </div>
        }

        {priority === 0 &&
          <div className='linearIssuePriorityIcon linearIssuePriorityIcon--priority--0'>
            <div className='linearIssuePriorityIcon--priority--0-nullBar' />
          </div>
        }

        {priority === 1 &&
          <div className='linearIssuePriorityIcon linearIssuePriorityIcon--priority--1'>
            <Icon name='linearUrgentPriorityIcon' />
          </div>
        }

      
      </div>
    )
  }
}

export default LinearIssuePriorityIcon
