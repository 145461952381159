import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";

const key = new PluginKey('observeTableWidth');

const observeTableWidth = new Plugin({
  key: key,
  state: {
    init: () => {
      return { observers: new Map() };
    },
    apply: (tr, prev, oldState, newState) => {
      const { observers } = prev;
      const newObservers = new Map(observers);

      // Remove observers for any deleted nodes.
      observers.forEach((observer, pos) => {
        if (!newState.doc.nodeAt(pos)) {
          observer.disconnect();
          newObservers.delete(pos);
        }
      });

      // Add observers for any new nodes.
      newState.doc.descendants((node, pos) => {
        if (node.type.name === 'table' && !observers.has(pos)) {
          const domNode = oldState.domAtPos(pos).node;
          if (domNode) {
            const observer = new MutationObserver((mutations) => {
              mutations.forEach(mutation => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'width') {
                  console.log('Table width changed:', domNode.getBoundingClientRect().width);
                }
              });
            });
            observer.observe(domNode, { attributes: true });
            newObservers.set(pos, observer);
          }
        }
      });

      return { observers: newObservers };
    },
  },
  view(editorView) {
    return {
      update: (view, prevState) => {
        // Trigger a transaction to check for changes.
        view.dispatch(view.state.tr);
      },
    };
  },
});

export default observeTableWidth;
