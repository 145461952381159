
let initialState={
	zoomedImage:{
		visible:false,
		nodeId:null
	},
	zoomedFigma:{
		visible:false,
		nodeId:null
	}
}
//videos are in the video node view (so can continue playing when zoom)

export default function (state = initialState, action) {
	switch (action.type) {
	 
	 case 'SHOW_DOC_MEDIA_MODAL':
		const modalType=action.modalType
		const nodeId=action.nodeId
		return {
			...state,
			[`${modalType}`]:{
				visible:true,
				nodeId:nodeId
			}
		}
	
		case 'HIDE_DOC_MEDIA_MODAL':
			return initialState
	
		default:
			return state;
	}
}