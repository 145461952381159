import React from 'react'
import Icon from '../../misc/Icon'
import { Spring, animated, config } from 'react-spring'
import AvatarImage from '../../AvatarImage'
import {pingCursorToUser} from '../../../utils/pingCursorToUser'
import { withRouter} from 'react-router-dom'
import {isCurrentUser} from '../../../utils/isCurrentUser'
import sortBy from 'lodash/sortBy'
import SelectUserPopover from '../../uiKit/SelectUserPopover'
import * as Tooltip from '@radix-ui/react-tooltip';

class SideMenuFooterDocPresenceBG extends React.Component{  
		

	render(){						
		const {activeSession} = this.props		
		{/* <button className='sideMenu-tabList-footer-actionRow-toggleBtn' onClick={()=>{this.setState({toggle: !toggle})}}> */}
	  return (	 
			<Spring
				opacity={activeSession ? 1 : 0}					
				config={{
					tension: 600,
					friction: 40,
				}}
			>
  			{styles => 
					<animated.div style={styles} className='sideMenu-tabList-footer-bg' />
  			}
  		</Spring>	  		 			
	  )
	}
}



class SideMenuFooterDocPresenceToggle extends React.Component{  
		
	constructor(props) {
    super(props);  
    this.state={
    	toggle: false,
    }
  }

	render(){					
		
		const {activeSession} = this.props
		const {toggle} = this.state

		{/* <button className='sideMenu-tabList-footer-actionRow-toggleBtn' onClick={()=>{this.setState({toggle: !toggle})}}> */}

	  return (
	  	<button className={'sideMenu-tabList-footer-actionRow-toggleBtn' + (activeSession ? ' sideMenu-tabList-footer-actionRow-toggleBtn--active' : ' sideMenu-tabList-footer-actionRow-toggleBtn--inactive' )} onClick={this.props.toggleActiveSession}>


				<Spring
					transform={activeSession ? 'translateX(27px) scale(1.3)' : 'translateX(0px) scale(1)'}	
					backgroundColor={activeSession ? 'rgb(255,255,255)' : 'rgb(170,170,170)'}
					config={{
						tension: 600,
						friction: 40,
					}}
				>
	  			{styles => 
						<animated.div style={styles} className='sideMenu-tabList-footer-actionRow-toggleBtn-circle' />							  				
	  			}
	  		</Spring>

	  		<Spring
					opacity={activeSession ? '1' : '1'}						
					//transform={activeSession ? 'scale(1.05)' : 'scale(1)'}
					config={{
						tension: 600,
						friction: 40,
					}}
				>
	  			{styles => 
						<animated.div style={styles} className='sideMenu-tabList-footer-actionRow-toggleBtn-headphonesIconContainer'>
							<Icon name='headphones' />
						</animated.div>
	  			}
	  		</Spring>


				

				<Spring
					opacity={activeSession ? '1' : '0'}						
					config={{
						tension: 600,
						friction: 40,
					}}
				>
	  			{styles => 
						<animated.div style={styles} className='sideMenu-tabList-footer-actionRow-toggleBtn-activeBG' />							  				
	  			}
	  		</Spring>
				
			</button>		  				
	  )
	}
}







class SideMenuFooterDocPresence extends React.Component{  
	
	constructor(props) {
    super(props);  
    this.state={
    	activeSession: false,
    	muteMicrophone: false,
    	showNotes: false,
    	centerNotes: false,
    	showPingDocPopover: false,
    }
  }

	render(){					
		
		const {activeSession, muteMicrophone, showNotes, centerNotes, showPingDocPopover} = this.state
		const {docPresenceMembers}=this.props

		const {orgMembers} = this.props
    let membersArray=[]
    if(orgMembers){
      Object.keys(orgMembers).forEach((key)=>{
        let member=orgMembers[key]    
        if(!isCurrentUser(member.user_id)){
        	 membersArray.push(member)
        }        
      })
    }

    const sortedMembers = sortBy(membersArray, function(member){
      return member.name.toString().toLowerCase()
    })

    const userList = sortedMembers


	  return (
	  	<React.Fragment>
		  	<Spring
					height={activeSession ? 200 : 48}
					config={{
						tension: 300,
						friction: 35,
					}}
					>
		  			{styles => 

							<animated.div style={styles} className={'sideMenu-tabList-footer sideMenu-tabList-footer--docPresence ' + (activeSession ? ' sideMenu-tabList-footer--activeSession' : '' )} >
								<div className='sideMenu-tabList-footer-divider' />
								
								<div className='sideMenu-tabList-footer-avatarRow'>
								{Object.keys(docPresenceMembers).map((key)=>{
									let member=docPresenceMembers[key]	
									return(
										
										<div className='sideMenu-tabList-footer-avatarRow-avatar' key={`member_${member.user_id}`}>
											{/*{member.name}*/}
											<div className={'sideMenu-tabList-footer-avatarRow-avatar-statusIndicator ' + (member.color ? `sideMenu-tabList-footer-avatarRow-avatar-statusIndicator--color--${member.color}` : '')} />
											
											<AvatarImage
												className='sideMenu-tabList-footer-avatarRow-avatar-image'
												avatarUrl={member.avatar}						
											/>
										</div>
								
										
										)
								})}

								
									<SelectUserPopover
										actionType='pingDoc'											
										inverted				
										showPopover={showPingDocPopover}										
										openPopover={()=>{this.setState({showPingDocPopover: true})}}
										closePopover={()=>{this.setState({showPingDocPopover: false})}}
										onClickUserFunction={(userId)=>{pingCursorToUser(userId,this.props.doc.doc_id,this.props.history)}}
										userList={userList}
									>	
										<button disabled={showPingDocPopover} onClick={()=>{this.setState({showPingDocPopover: true})}}  className={'sideMenu-tabList-footer-avatarRow-addUser ' + (showPingDocPopover ? ' sideMenu-tabList-footer-avatarRow-addUser--menuOpen ' : ' sideMenu-tabList-footer-avatarRow-addUser--menuClosed ')}>								    	
											<Icon name='plus' />																			    
										</button>
									</SelectUserPopover>
									
								</div>

								
								<div className={'sideMenu-tabList-footer-tabList' + (activeSession ? ' sideMenu-tabList-footer-tabList--show' : ' sideMenu-tabList-footer-tabList--hide')}>
									<div className='sideMenu-tabList-footer-tabList-tab sideMenu-tabList-footer-tabList-tab--active'>
										{this.props.docName}
									</div>
								</div>
								
								{/*}
								<div className='sideMenu-tabList-footer-toggleContainer'>								
									<SideMenuFooterDocPresenceToggle									
										activeSession={activeSession}
										toggleActiveSession={()=>{this.setState({activeSession: !activeSession})}}
									/>
								</div>
								*/}

								{/*}
								<div className='sideMenu-tabList-footer-actionRow'>								

									<div className={'sideMenu-tabList-footer-actionRow-activeSet' + (activeSession ? ' sideMenu-tabList-footer-actionRow-activeSet--show' : ' sideMenu-tabList-footer-actionRow-activeSet--hide')}>

										<button onClick={()=>{this.setState({muteMicrophone: !muteMicrophone})}} className={'sideMenu-tabList-footer-actionRow-activeSet-btn'  + (muteMicrophone ? ' sideMenu-tabList-footer-actionRow-activeSet-btn--active ': ' sideMenu-tabList-footer-actionRow-activeSet-btn--inactive ')}>
											{!muteMicrophone &&
												<Icon name='micOutline' />
											}
											{muteMicrophone &&
												<Icon name='micMuteOutline' />
											}																			
										</button>
										
										<button className='sideMenu-tabList-footer-actionRow-activeSet-btn'>
											<Icon name='captionsOutlineRegular' />											
										</button>
										
										<button className='sideMenu-tabList-footer-actionRow-activeSet-btn'>
											<Icon name='drawingSquiggleRegular' />											
										</button>

									</div>
								
								</div>
								*/}

								<SideMenuFooterDocPresenceBG
									activeSession={activeSession}										
								/>

		  				</animated.div>
		  			}

		  		</Spring>

		  		<Spring
					transform={centerNotes ? (showNotes ? 'translateX(-20px) translateY(-12px)' : 'translateX(-20px) translateY(620px)') : (showNotes ? 'translateX(-20px) translateY(550px)' : 'translateX(-20px) translateY(620px)')}												
					config={{
						tension: 350,
						friction: 30,
					}}
					>
		  			{styles => 

							<animated.div style={styles} className='sessionNotes'>
								<div onClick={()=>{this.setState({centerNotes: !centerNotes})}} className='sessionNotes-header'>
									Notes
								</div>


							</animated.div>
						}
					</Spring>


			</React.Fragment>
	  )
	}
}

export default withRouter(SideMenuFooterDocPresence)