import React from 'react'
import Icon from '../misc/Icon'

class StaticCheckbox extends React.Component{  	

	
	render(){	
		const {checked} = this.props		

		return (			
			<div data-is-checked={checked} className='checkbox'>			
				<Icon name='toDoItemCheckmark' />			
			</div>
		)
	}
}

export default StaticCheckbox