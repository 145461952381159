import React from 'react'
import TextLinkHoverPopoverReactComponent from './TextLinkHoverPopoverReactComponent'
import TextLinkEditPopoverReactComponent from './TextLinkEditPopoverReactComponent'

class TextLinkDetailsAnchorReactComponent extends React.Component{  	

	render(){	
		const {hoverMenuIsVisible,editMenuIsVisible}=this.props	
		const {openHoverMenu,closeHoverMenu}=this.props
		return (	  	
	  	<React.Fragment>	
	  		<TextLinkHoverPopoverReactComponent
	  			showPopover={hoverMenuIsVisible && !this.props.editMenuIsVisible}		  			
	  			openPopover={this.props.openHoverMenu}
	  			openHoverMenu={this.props.openHoverMenu}
	  			closePopover={this.props.closeHoverMenu}
	  			closeHoverMenu={this.props.closeHoverMenu}	  			
	  			inverted={false}
	  			removeLink={this.props.removeLink}
	  			showEditMenuFromHoverMenu={this.props.showEditMenuFromHoverMenu}
	  			type={this.props.type}
	  			projectId={this.props.projectId}
	  			docId={this.props.docId}
	  			issueId={this.props.issueId}
	  			url={this.props.url}
	  			openLink={this.props.openLink}
	  		>
  				<div className='doc-textLink-popoverAnchor-anchor'/>
	  		</TextLinkHoverPopoverReactComponent>
	  		{this.props.editMenuIsVisible &&
		  		<TextLinkEditPopoverReactComponent
		  			showPopover={editMenuIsVisible}
		  			closePopover={this.props.closeEditMenu}			
		  			inverted={false}
		  			type={this.props.type}
	  				projectId={this.props.projectId}
	  				docId={this.props.docId}
	  				issueId={this.props.issueId}
	  				url={this.props.url}
	  				removeLink={this.props.removeLink}
	  				title={this.props.title}
	  				saveUpdatedLinkValues={this.props.saveUpdatedLinkValues}
	  				saveUpdatedTitle={this.props.saveUpdatedTitle}
		  		>
	  				<div className='doc-textLink-popoverAnchor-anchor'/>
		  		</TextLinkEditPopoverReactComponent>
	  		}
	  		
	  	</React.Fragment>
  	)
	}
}

export default TextLinkDetailsAnchorReactComponent