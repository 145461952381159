import {getProjectForId} from '../getProjectForId'
import {updateProject} from './updateProject'


export function updateProjectLead(projectId,lead){
	const oldProject=getProjectForId(projectId)
	let newProject={...oldProject}
	newProject.lead=lead
	updateProject(newProject)
	//store.dispatch(updateProjectStatusRequest(projectId,status))

}
