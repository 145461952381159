export const getNewState = function() {
  return {
    mouseHasMoved:false,
    active: false,
    suggestions: [],
    dateSuggestion:null,
    assignSuggestions:[],
    isToDo:false,
    assignees:[],
    index: null //23rd jan update to not auto select first result
  };
};
