import React from 'react'
import Icon from '../misc/Icon'
import { Transition, animated, config, Spring } from 'react-spring'
import OnboardingModalDevice from './OnboardingModalDevice'
import OnboardingModalSelectBtn from './OnboardingModalSelectBtn'


class OnboardingModalProjectStage extends React.Component {  	

  constructor(props) {
    super(props);   
    this.handleKeyDown=this.handleKeyDown.bind(this)   
    this.handleSelectTemplate=this.handleSelectTemplate.bind(this)
    this.state = {
      activeIndex:0
    }
  }

    componentDidMount() {
    if(this.props.stageVisible){
      window.addEventListener('keydown', this.handleKeyDown)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.stageVisible && this.props.stageVisible) {
      // component just became visible
      window.addEventListener('keydown', this.handleKeyDown)
    } else if (prevProps.stageVisible && !this.props.stageVisible) {
      // component just became invisible

      window.removeEventListener('keydown', this.handleKeyDown)
    }
  }


  // componentDidMount() {
  //   window.addEventListener('keydown', this.handleKeyDown)    
  // }
  
  // componentWillUnmount() {
  //   window.removeEventListener('keydown', this.handleKeyDown)
  // }


  handleSelectTemplate(template){

    const {activeIndex}=this.state
    let activeTemplate=template 
    if(!template){
      if(activeIndex==0){
        activeTemplate="linear"
      }else if(activeIndex==1){
        activeTemplate="mixpanel"
      }
      else if(activeIndex==2){
        activeTemplate="react"
      }
      else if(activeIndex==3){
        activeTemplate="skip"
      }
    }

    this.props.setOnboardingProjectTemplate(activeTemplate)
    this.props.advanceStage()
  }

  handleKeyDown(e){
    if(e.keyCode==13){ //enter

      this.handleSelectTemplate()
    }
    else if(e.keyCode==40){//down arrow
      const {activeIndex}=this.state
      let newIndex
      if(activeIndex<3){
        newIndex=activeIndex+1
      }else{
        newIndex=0
      }
      this.setState({activeIndex:newIndex})
    } 
    else if(e.keyCode==38){//up arrow
      const {activeIndex}=this.state
      let newIndex
      if(activeIndex==0){
        newIndex=3
      }else{
        newIndex=activeIndex-1
      }
      this.setState({activeIndex:newIndex})
    } 
  }




  render() {
    const orgName = 'Acme Inc'
    const {onboardingProject}=this.props
    let projectName 
    if(onboardingProject){
      projectName=onboardingProject.name
    }
    const {activeIndex}=this.state

    let activeTemplate
    if(activeIndex==0){
      activeTemplate="linear"
    }else if(activeIndex==1){
       activeTemplate="mixpanel"
    }
    else if(activeIndex==2){
       activeTemplate="react"
    }
    
    const {stageVisible} = this.props

    const deviceTransformFrom = 'translateX(0px) translateY(0px) scale(1)'
    const deviceTransformEnter = 'translateX(0px) translateY(0px) scale(1)'
    const deviceTransformLeave = 'ranslateX(20px)  translateY(0px) scale(1)'

    const contentTransformFrom = 'translateX(20px) translateY(0px) scale(1)'
    const contentTransformEnter = 'translateX(0px) translateY(0px) scale(1)'
    const contentTransformLeave = 'translateX(-20px) translateY(0px) scale(1)'
    
    return (      
       
      <React.Fragment>

        <Transition
          items={stageVisible}
          from={{ opacity: 0, transform: `${contentTransformFrom}` }}
          enter={{ opacity: 1, transform: `${contentTransformEnter}`  }}
          leave={{ opacity: 0, transform: `${contentTransformLeave}`  }}
          reverse={stageVisible}
          config={{tension: 200, friction: 26}}   
          delay={stageVisible ? 140 : 0}      
        >
          {(styles, item) =>
            item &&             
              <animated.div style={styles} className='onboardingModal-stage-contentContainer onboardingModal-stage-contentContainer--leftAlign'>
                <div className='onboardingModal-stage-topSpacer' />
                <div className='onboardingModal-stage-h1'>
                  Every project has a home doc for specs and ideas. <span className='onboardingModal-stage-h1-light'>Start with a template for <span className='onboardingModal-stage-h1-underline'>{projectName}</span></span>
                </div>


                <Spring
                  from={{ opacity: stageVisible ? 0 : 1, transform: stageVisible ? 'translateY(-5px)' : 'translateY(0px)' }}
                  to={{ opacity: stageVisible ? 1 : 0, transform: stageVisible ? 'translateY(0px)' : 'translateY(-5px)' }}
                  config={{tension: 200, friction: 26}}
                  delay={540}
                >
                  {selectBtnListStyles => 
                    <animated.div style={selectBtnListStyles} className='onboardingModal-stage-bottomContainer'>
                      <div className='onboardingModal-stage-keyHints'>
                        Use arrow keys ↑ ↓ and Enter
                      </div>
                      <div className='onboardingModal-stage--project-selectBtnContainer'>
                        <OnboardingModalSelectBtn
                          active={activeIndex==0}
                          companyIcon='linear'
                          onSelect={()=>{this.handleSelectTemplate('linear')}}
                          label="Linear's Product Brief"
                          template
                        />
                        <OnboardingModalSelectBtn  
                          active={activeIndex==1}            
                          companyIcon='mixpanel'
                          onSelect={()=>{this.handleSelectTemplate('mixpanel')}}
                          label="Mixpanel's Technical Spec"
                          template
                        />
                        <OnboardingModalSelectBtn    
                          active={activeIndex==2}          
                          companyIcon='react'
                            onSelect={()=>{this.handleSelectTemplate('react')}}
                          label="React's RFC"
                          template
                        />      
                         <OnboardingModalSelectBtn    
                          active={activeIndex==3}          
                          companyIcon=''
                          onSelect={()=>{this.handleSelectTemplate('skip')}}
                          label="Skip"
                          template
                        />            
                      </div>
                    </animated.div>
                  }
                </Spring>

            </animated.div>
          }
        </Transition>

        <Transition
          items={stageVisible}
          from={{ opacity: 1, transform: `${deviceTransformFrom}` }}
          enter={{ opacity: 1, transform: `${deviceTransformEnter}`  }}
          leave={{ opacity: 0, transform: `${deviceTransformLeave}`  }}
          reverse={stageVisible}
          delay={stageVisible ? 0 : 0}
          config={{
              tension: 170,
              friction: 26,              
            }}          
         >
          {(styles, item) =>
            item && 
             <animated.div style={styles} className='onboardingModal-stage-visualOuterContainer'>
              <div className='onboardingModal-stage-visualInnerContainer'>
                <OnboardingModalDevice 
                  stage="template"
                  projectName={projectName}
                  activeTemplate={activeTemplate}                          
                />
              </div>
            </animated.div>
          }
        </Transition>    


      </React.Fragment>
    )
  }
}

export default OnboardingModalProjectStage