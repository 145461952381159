import React from 'react'
import Icon from '../../../misc/Icon'
import DocEditorMeetingContentProjectListRow from './DocEditorMeetingContentProjectListRow'
import DocEditorMeetingContentAddProjectPopover from './DocEditorMeetingContentAddProjectPopover'
import {getProjectForId} from '../../../../utils/getProjectForId'
import {getProjectConnectionsForMeeting} from '../../../../utils/meetings/getProjectConnectionsForMeeting'
import * as Tooltip from '@radix-ui/react-tooltip';

class DocEditorMeetingContentLinkedProjects extends React.PureComponent{  

  constructor(props) {
    super(props)   
    this.state={      
      showAddProjectPopover: false,
    }    
  }   

  render(){ 
    const {meeting} = this.props
    const {showAddProjectPopover} = this.state
    
    let projectConnections=getProjectConnectionsForMeeting(meeting.meeting_id)

    const hasLinkedProjects = projectConnections.length>0

    
    let meetingType    
    if(meeting && meeting.type){
      meetingType = meeting.type
    }

    return (        
      <div className='docEditor-meetingContent-linkedProjects'>
        <div className='docEditor-meetingContent-menu'>
          
          {!hasLinkedProjects && 
            <DocEditorMeetingContentAddProjectPopover
              showPopover={showAddProjectPopover}
              closePopover={()=>{this.setState({showAddProjectPopover: false})}}
              meeting={meeting}
            >                              
              <button onClick={()=>{this.setState({showAddProjectPopover: true})}} className='docEditor-meetingContent-menu-primaryBtn'>
                <div className='docEditor-meetingContent-menu-primaryBtn-iconContainer'>
                  <Icon name='plus' />
                </div>
                <div className='docEditor-meetingContent-menu-primaryBtn-label'>
                  Add Linked Project
                </div>
              </button>
            </DocEditorMeetingContentAddProjectPopover>        
          }

        </div>

        {hasLinkedProjects && 
          <div className='docEditor-meetingContent-linkedProjects-list'>
            {projectConnections && projectConnections.map((connection) => {                
              let projectObj 
              const projectId=connection.project
              if(projectId){
                projectObj = getProjectForId(projectId)
              }
              if(projectObj){
              return (
                <DocEditorMeetingContentProjectListRow 
                  key={projectId}
                  project={projectObj}
                  connectionId={connection.connection_id}
                  openIssuePanel={this.props.openIssuePanel}
                  issuePanelIsVisible={this.props.issuePanelIsVisible}
                  issuePanelProject={this.props.issuePanelProject}  
                  closeIssuePanel={this.props.closeIssuePanel} 
                  linkedProject
            />
              )
            }
          })}  


              

            <DocEditorMeetingContentAddProjectPopover
              showPopover={showAddProjectPopover}
              closePopover={()=>{this.setState({showAddProjectPopover: false})}}
              meeting={meeting}
            >                   
              <div className='docEditor-meetingContent-linkedProjects-list-addBtnContainer'>
                <Tooltip.Provider>
                  <Tooltip.Root delayDuration={100}>
                    <Tooltip.Trigger asChild>       
                      <button onClick={()=>{this.setState({showAddProjectPopover: true})}} className='docEditor-meetingContent-linkedProjects-list-addBtn'>
                        <Icon name='plus' />              
                      </button>
                    </Tooltip.Trigger>                          
                    <Tooltip.Content side="left" sideOffset={4} className="tooltip tooltip--textSelectionUI">
                      Add linked project
                    </Tooltip.Content>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
            </DocEditorMeetingContentAddProjectPopover> 
            


                       
              
            
            


            {/*}
            <Tooltip.Provider>
              <Tooltip.Root delayDuration={250}>
                <Tooltip.Trigger asChild>       
                <button className='docEditor-meetingContent-linkedProjects-list-addBtn'>
                  <Icon name='plus' />              
                </button>
                </Tooltip.Trigger>                                  
                  <Tooltip.Content side="top" sideOffset={4} className="tooltip tooltip--textSelectionUI">                                                                  
                    Add Linked Project
                  </Tooltip.Content>                                            
              </Tooltip.Root>
            </Tooltip.Provider>
            */}

            

          </div>
        }

      </div>
    )
  }
}

export default DocEditorMeetingContentLinkedProjects
