import React from 'react'
import Icon from '../misc/Icon'

class DocH1 extends React.Component {   
  
  render() {        

    return (
     
      <div className='onboardingModal-device-page-h1'>
        {this.props.label}
      </div>

    )
  }
}

class TemplateExamples extends React.Component {   
  
  render() {        

    return (
     
      <div className='onboardingModal-device-page-h1'>
        {this.props.label}
      </div>

    )
  }
}


class OnboardingModalDevicePage extends React.Component {  	
  
  constructor(props) {
    super(props);    
    this.state = {
      
    }
  }

  render() {		
    const {stage, activeTemplate} = this.props

    const selectedProjectName = this.props.projectName

    let label = selectedProjectName
    if(stage === 'project'){
      label = 'Projects'
    }
    if(stage === 'figma'){
      label = 'Design Concept'
    }
    if(stage === 'docSearch'){
      label = 'Meeting Notes'
    }

    return (
      
      <div className='onboardingModal-device-page'>
        <div className='onboardingModal-device-page-innerPage'>

          <div className='onboardingModal-device-page-title'>
            {label}
          </div>
        

          {stage === 'figma' &&
            <React.Fragment>              
              
              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />                
              </div>

              <div className='onboardingModal-device-page-figma'>
                <div className='onboardingModal-device-page-figma-device'>

                </div>
                <div className='onboardingModal-device-page-figma-buttonRow'>

                </div>

              </div>

              <div className='onboardingModal-device-page-section'>                
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>
    

            </React.Fragment>
          }

          {stage === 'template' && activeTemplate === 'linear' &&
            <React.Fragment>

              <DocH1 label='Changelog Post' />            
              
              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>

              <DocH1 label='Brief/Why' />            

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />                
              </div>

              <DocH1 label='Current Problems' />            

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>

              <DocH1 label='Ideas' />            

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>          

            </React.Fragment>
          }

           {stage === 'template' && activeTemplate === 'mixpanel' &&
            <React.Fragment>

              <DocH1 label='Overview' />
              
              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>

              <DocH1 label='Objectives' />

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>

              <DocH1 label='Requirements' />

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>

              <DocH1 label='Architecture' />            

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>          

            </React.Fragment>
          }

           {stage === 'template' && activeTemplate === 'react' &&
            <React.Fragment>

              <DocH1 label='Summary' />
              
              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />                
              </div>

              <DocH1 label='Basic Example' />

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />                
              </div>

              <DocH1 label='Motivation' />

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />                
              </div>

              <DocH1 label='Detailed Design' />            

              <div className='onboardingModal-device-page-section'>
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
                <div className='onboardingModal-device-page-section-line' />
              </div>          

            </React.Fragment>
          }

        </div>
      </div>      
      
    )
  }
}

export default OnboardingModalDevicePage