import {fetch,put,post} from '../api'
import {recalcProjectMilestones} from '../utils/milestones/recalcProjectMilestones'


export function fetchWorkflowStates(){
	return (dispatch) => fetch(`/workflows`)
	.then((response) => {
		dispatch({ type: 'FETCH_WORKFLOWS_SUCCESS', response })
		recalcProjectMilestones()
		return response
	})
	.catch((error) => {
		return error
	})
}
