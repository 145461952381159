import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import sortBy from 'lodash/sortBy'
import {toggleShowNotionExportModal}  from '../../../utils/toggleShowNotionExportModal'

import * as Tooltip from '@radix-ui/react-tooltip';
import {getUserForId} from '../../../utils/getUserForId'
import {getTeamForId} from '../../../utils/getTeamForId'
import LinearMarker from '../../misc/LinearMarker'
import {getTabStarredStatus,starTab,unstarTab} from '../../../utils/starredTabs'
import MoveDocPopover from '../../MoveDocPopover'
import {deleteDoc} from '../../../utils/deleteDoc'
import KeyboardShortcutTag from '../../misc/KeyboardShortcutTag'
import {copyDocLinkToClipboard} from '../../../utils/copyDocLinkToClipboard'
import {getOrg} from '../../../utils/getOrg'
import {createTemplate} from '../../../utils/templates/createTemplate'
import DocEditorCreateTemplatePopover from '../DocEditorCreateTemplatePopover'


class DocEditorOrgDocTopMenu extends React.Component{  

	constructor(){
    super()  
    this.handleDropdownClick=this.handleDropdownClick.bind(this)
    this.handleMoveDocDropdownClick=this.handleMoveDocDropdownClick.bind(this)
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.handleTemplatePopoverClick=this.handleTemplatePopoverClick.bind(this)
    this.state={
      showMoveDocPopover: false,
      showDropdown: false,
      showCreateTemplatePopover: false,
    }
    this.timeout=null
  }

  componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)		
	}

	componentWillUnmount(){
		window.removeEventListener('keydown', this.handleKeyDown)		
		if(this.timeout){
			clearTimeout(this.timeout)
		}
	}

	handleTemplatePopoverClick(e){		
		this.setState({showCreateTemplatePopover: true})
		this.setState({showDropdown: false})		
	}

  handleDropdownClick(e){	
		this.setState({showMoveDocPopover: false})
		this.setState({showDropdown: true})		
	}

	handleMoveDocDropdownClick(e){
		this.setState({showDropdown: false})
		this.timeout=setTimeout(() => {
    	this.setState({showMoveDocPopover: true});
    }, 200);        
	}

	handleKeyDown(e){ // Open menu on shift command p		
		if(e.shiftKey && e.metaKey && e.keyCode==80){
			this.setState({showDropdown: false})
			this.setState({showMoveDocPopover:true})						
		}		
		if(e.metaKey && e.keyCode==76 && !e.shiftKey){ //command L
			e.preventDefault()
			e.stopPropagation()
			copyDocLinkToClipboard(this.props.docId)
		}
	}

	render(){	
		const {sideMenuVisible,docName,teamId,docId} = this.props
		const {showMoveDocPopover, showDropdown} = this.state		
		let org=getOrg()

		const starred = getTabStarredStatus('doc',docId)

		let orgName
		if(org){
			orgName = org.name
		}
		if(orgName === 'Yarn'){
			orgName = 'Acme Inc.'
		}

	  return (
	  	<div className={'docEditor-topMenuContainer docEditor-topMenuContainer--orgDoc ' + (sideMenuVisible ? ' docEditor-topMenuContainer--sideMenuState--visible ' : ' docEditor-topMenuContainer--sideMenuState--hidden ')}>
				<div className='docEditor-topMenu-center'>												
					{org && 
						<button className='docEditor-topMenu--orgDoc-orgLink ' onClick={()=>{this.props.history.push('/')}}>
							<div className='docEditor-topMenu--orgDoc-orgLink-image'>
								<img src={org.logo} className='docEditor-topMenu--orgDoc-orgLink-image-image' />
							</div>
							<div className='docEditor-topMenu--orgDoc-orgLink-label'>
								{orgName}
							</div>
						</button>
					}

					<div className='docEditor-newTemplatePopoverTriggerContainer'>
						<DocEditorCreateTemplatePopover
							showPopover={this.state.showCreateTemplatePopover}						
							openPopover={()=>{this.setState({showCreateTemplatePopover: true})}}
							closePopover={()=>{this.setState({showCreateTemplatePopover: false})}}
							docId={docId}												
						>
							<div className='docEditor-newTemplatePopoverTrigger'/>							
						</DocEditorCreateTemplatePopover>							
					</div>	

				</div>
				<div className='docEditor-topMenu-right'>
					<MoveDocPopover
						key={`popover_${docId}`}
						showPopover={showMoveDocPopover}						
						openPopover={()=>{this.setState({showMoveDocPopover: true})}}
						closePopover={()=>{this.setState({showMoveDocPopover: false})}}		
						docId={docId}		
						layout="topMenu"
					>
						<div className='docEditor-topMenu-movePopoverAnchor' /> 								
					</MoveDocPopover>	

					{!this.props.messagePanelIsVisible && 
						<button onClick={this.props.toggleShowMessagePanel} className={'docEditor-topMenu-button docEditor-topMenu-button--textAndIcon docEditor-topMenu-button--docChat ' + (this.props.showUnreadsIndicatorDocChatBtn ? ' docEditor-topMenu-button--docChat--hasUnreads ': ' ')}> 
							<div className='docEditor-topMenu-button-iconContainer'>
								{/*<Icon name='messageOutlineMedium' /> */}
								<Icon name={this.props.showUnreadsIndicatorDocChatBtn ? 'sideMenuClean' : 'sideMenu'} />
							</div>
							{this.props.showUnreadsIndicatorDocChatBtn &&
								<div className='docEditor-topMenu-button--docChat--hasUnreadsIndicator' />
							}
							<div className='docEditor-topMenu-button-label'>
								Doc Chat
							</div>						
						</button>
					}
					
						{!starred && 
							<Tooltip.Provider>
								<Tooltip.Root delayDuration={0}>
									<Tooltip.Trigger asChild>																	
										<button className='docEditor-topMenu-button docEditor-topMenu-button--inactiveStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{starTab('doc',docId)}}>
											<Icon name='star' />
										</button>																	
									</Tooltip.Trigger>				
									<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
										Star Doc
									</Tooltip.Content>													
								</Tooltip.Root>
							</Tooltip.Provider>
						}

						{starred && 
							<Tooltip.Provider>
								<Tooltip.Root delayDuration={0}>
									<Tooltip.Trigger asChild>																	
										<button className='docEditor-topMenu-button docEditor-topMenu-button--activeStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{unstarTab('doc',docId)}}>
											<Icon name='starFill' />
										</button>																	
									</Tooltip.Trigger>				
									<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
										Unstar Doc
									</Tooltip.Content>													
								</Tooltip.Root>
							</Tooltip.Provider>
						}


					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>
								<button onClick={()=>{copyDocLinkToClipboard(docId)}} className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--docLink'>
							    <Icon name='link' />
							  </button>
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Copy Link
								<KeyboardShortcutTag									
									command
									letter='L'
								/>									
								</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					


					<DropdownMenu.Root open={showDropdown}>
				    <DropdownMenu.Trigger asChild>
				    	<button onClick={()=>{this.handleDropdownClick()}} className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--dropdown'>
				    		<Icon name='ellipses' />
				    	</button>
				    </DropdownMenu.Trigger>   			 	
	   			 	<DropdownMenu.Content 
	   			 		sideOffset={1}
	   			 		align="end"
	   			 		alignOffset={-8}
	   			 		className='dropdownMenu'
	   			 		onEscapeKeyDown={()=>{this.setState({showDropdown: false})}}
							onPointerDownOutside={()=>{this.setState({showDropdown: false})}}
							onInteractOutside={()=>{this.setState({showDropdown: false})}}
						>							
		        	{/*}
		        	<DropdownMenu.Item onClick={()=>{copyDocLinkToClipboard(docId)}}>
		        		Copy Link
		        	</DropdownMenu.Item>
		        	*/}
							

		        	

		        	<DropdownMenu.Item onSelect={()=>{this.handleMoveDocDropdownClick()}}>
		        		Move Doc...
		        	</DropdownMenu.Item>

		        	<DropdownMenu.Separator />
		        	
		        	<DropdownMenu.Item onSelect={()=>{this.handleTemplatePopoverClick()}}>
		        		Turn into template...
		        	</DropdownMenu.Item>
		        	      			        	
		        	<DropdownMenu.Item onSelect={this.props.duplicateDoc}>		        	
		        		Duplicate Doc
		        	</DropdownMenu.Item>
		        	{/*}  
		        	<DropdownMenu.Separator />
		        	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
		        		Export to Notion
		        	</DropdownMenu.Item>
		        	*/}
		        	<DropdownMenu.Separator />				

		       	 	<DropdownMenu.Item  className='dropdownMenu-item--warning' onSelect={()=>{deleteDoc(docId,this.props.history)}}>
		        		Delete
		        	</DropdownMenu.Item>					        
				    </DropdownMenu.Content>
					</DropdownMenu.Root>	  
					 				
				</div>
				
			</div>		
			
	  )
	}
}

export default withRouter(DocEditorOrgDocTopMenu)
