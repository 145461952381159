import React from 'react'
import Icon from '../../misc/Icon'
import LinearIssuePriorityIcon from '../../misc/LinearIssuePriorityIcon'

class DocBlockLinearIssuePriority extends React.Component{  	

	render(){			

		const {priority} = this.props



	  return (

	  	
  		<div className='doc-blockLinearIssue-priorityContainer'>

  			{/* MIGHT ADD IN A DROPDOWN TO EDIT */}
  			<LinearIssuePriorityIcon 
  				priority={priority}
  			/>

  		</div>	  
			
	  )
	}
}

export default DocBlockLinearIssuePriority
