import React from 'react'
import Icon from '../misc/Icon'
import { Spring, animated, config } from 'react-spring'

class IssueInlineExpandPrototype extends React.Component{  	

	// constructor(props){
  //   super()    
  //   this.state={
  //     expanded: props.expanded,      
  //   }
  // }

	render(){	

		const {label} = this.props
		const {expanded, descriptionText} = this.props	

		let avatarUrl
		if(this.props.isJasper){
			avatarUrl = "https://avatars.slack-edge.com/2022-11-16/4380302157075_b6321a1c06e096a724f8_192.png"
		}
		if(this.props.isKaylee){
			avatarUrl = 'https://avatars.slack-edge.com/2022-11-16/4377453091925_efc62580bfa96a571be5_192.png'
		}
		if(this.props.isNicole){
			avatarUrl = "https://avatars.slack-edge.com/2022-11-16/4377458634981_066e89e17f27ff3067fe_192.png"
		}

		const heightIssueInline = 32
		const heightIssueExpanded = 190
		const heightIssueBGInline = 30
		const heightIssueBGExpanded = 160
		const translateYIssueBGExpanded = (heightIssueExpanded - heightIssueBGExpanded) / 2 
		const translateYItems = 27

		// const animationConfig = config.molasses
		//const animationConfig = config.default
		const animationConfig = {tension: 500, friction: 40}

	  return (
	  	
	  	<Spring config={animationConfig} height={expanded ? `${heightIssueExpanded}px` : `${heightIssueInline}px`} >
        {issueStyles =>
          <animated.div style={issueStyles} onClick={this.props.expand} className={'issue ' + (expanded ? 'issue--expanded' : 'issue--inline')}>	  		
	  			
	  			
	  				{/* INLINE CONTROLS */}
		  			<div className='issue--inline-priorityContainer'>
		  				<div className='issue--inline-priority'>
		  					<div className='issue--inline-priority-bar' />
		  					<div className='issue--inline-priority-bar' />
		  					<div className='issue--inline-priority-bar' />
		  				</div>
		  			</div>

		  			<div className='issue--inline-statusContainer'>
		  				<div className='issue--inline-status'>
		  					<div className='issue--inline-status-circle' />
		  				</div>
		  			</div>

		  			

		  			<div className='issue--inline-assigneeContainer'>
		  				<div className='issue--inline-assignee'>
		  					<img className='issue--inline-assignee-img' src={avatarUrl} />
		  				</div>
		  			</div>
		  		



  				<Spring config={animationConfig} transform={expanded ? `translateY(${translateYItems}px)`: 'translateY(0px)'}>
        		{issueIDInlineStyles =>
          		<animated.div style={issueIDInlineStyles} className='issue--inline-issueIDContainer'>          			
		  					YAR-239		  			
          		</animated.div>	  		
          	}
    			</Spring>
  				
  				<Spring config={animationConfig} transform={expanded ? `translateY(${translateYItems}px)`: 'translateY(0px)'}>
        		{issueTitleStyles =>
          		<animated.div style={issueTitleStyles} className='issue-title'>
          			{label}
          		</animated.div>	  		
          	}
    			</Spring>



  				<Spring config={animationConfig} transform={expanded ? `translateY(0px)`: `translateY(${-1 * translateYItems}px)`} opacity={expanded ? 1 : 0 }>
        		{issueIconStyles =>
          		<animated.div style={issueIconStyles} className='issue--expanded-issueIconContainer'>          		
		  					<div className='issue--expanded-issueIcon'>
		  						<div className='issue--expanded-issueIcon-circle' />
		  					</div>		  				
          		</animated.div>	  		
          	}
    			</Spring>

    			<Spring config={animationConfig} transform={expanded ? `translateY(0px)`: `translateY(${-1 * translateYItems}px)`} opacity={expanded ? 1 : 0 }>
        		{issueDescriptionStyles =>
          		<animated.div style={issueDescriptionStyles} className='issue--expanded-descriptionContainer'>          		
		  					{descriptionText ? descriptionText : <span className='issue--expanded-description-placeholder'>Add description...</span>}
          		</animated.div>	  		
          	}
    			</Spring>


    			<Spring config={animationConfig} transform={expanded ? `translateY(0px)`: `translateY(${-1 * translateYItems}px)`} opacity={expanded ? 1 : 0 }>
        		{topRightActionsStyles =>
          		<animated.div style={topRightActionsStyles} className='issue--expanded-topRightActions'>          		
		  					<div className='issue--expanded-topRightActions-btn'>
		  						<Icon name='ellipses' />		  					
		  					</div>
          		</animated.div>	  		
          	}
    			</Spring>

    			<Spring delay={expanded ? 50 : 0} config={animationConfig} transform={expanded ? `translateY(0px)`: `translateY(-12px)`}>
        		{actionRowStyles =>
          		<animated.div style={actionRowStyles} className='issue--expanded-actionRowContainer'>
		  					<button className='issue--expanded-actionRow-btn'>
		  						<div className='issue--expanded-actionRow-btn-iconContainer'>

		  						</div>
		  						<div className='issue--expanded-actionRow-btn-label'>
		  							Backlog
		  						</div>
		  					</button>
		  					<button className='issue--expanded-actionRow-btn'>
		  						<div className='issue--expanded-actionRow-btn-iconContainer'>

		  						</div>
		  						<div className='issue--expanded-actionRow-btn-label'>
		  							Priority
		  						</div>
		  					</button>
		  					<button className='issue--expanded-actionRow-btn'>
		  						<div className='issue--expanded-actionRow-btn-iconContainer'>

		  						</div>
		  						<div className='issue--expanded-actionRow-btn-label'>
		  							Assignee
		  						</div>
		  					</button>
		  					<button className='issue--expanded-actionRow-btn issue--expanded-actionRow-btn--iconOnly'>
		  						
		  					</button>
          		</animated.div>	  		
          	}
    			</Spring>


  				{/*}
	  			{expanded && 
	  				<React.Fragment>
	  					<div className=''>
		  					
		  				</div>

		  				

		  				<div className='issue--expanded-actionRowContainer'>
		  					Action Row
		  				</div>

		  				

	  				</React.Fragment>
	  			}
	  			*/}

	  			<Spring config={animationConfig} transform={expanded ? `translateY(${translateYIssueBGExpanded}px) translateX(-12px)`: 'translateY(0px) translateX(0px)'} height={expanded ? `${heightIssueBGExpanded}px` : `${heightIssueBGInline}px`} width={expanded ? '674px' : `650px`} >
        		{issueBGStyles =>
          		<animated.div style={issueBGStyles} className='issueBG' />	  		
          	}
    			</Spring>


	  		</animated.div>
	  	}
    </Spring>

			
	  )
	}
}

export default IssueInlineExpandPrototype
