import React from 'react'

// this is with full text

export default function VideoDurationFull ({ className, seconds }) {
  return (
    <time dateTime={`P${Math.floor(seconds)}S`} className={className}>
      {format(seconds)}
    </time>
  )
}

function format (seconds) {
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours()
  const mm = date.getUTCMinutes()
  const ss = pad(date.getUTCSeconds())
  if (hh) {
    return `${pad(hh)}:${pad(mm)}:${ss}`  
  }
  return `${pad(mm)}:${ss}`
}

function pad (string) {
  return ('0' + string).slice(-2)
}