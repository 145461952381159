import schema from '../../schema/editorSchema'  
import {TextSelection} from "prosemirror-state"
import {joinPoint, canJoin, findWrapping, liftTarget, canSplit,
        ReplaceStep, ReplaceAroundStep, replaceStep} from "prosemirror-transform"
import {wrapIn,lift,setBlockType,createParagraphNear,chainCommands} from 'prosemirror-commands'
import {getAnchorNode} from '../getAnchorNode'

//https://prosemirror.net/docs/ref/#commands.createParagraphNear


export function insertCodeBlockCommand() {
  return function(state, dispatch) {
    let { $from, $to } = state.selection;
    let range = $from.blockRange($to);
    if (!range) return false;
    
    const textContent = state.doc.textBetween($from.pos, $to.pos, "\n", "\n");
    if (!textContent.trim()) {
      return false;
    }
    const textNode = state.schema.text(textContent);
    const docCodeMirror = state.schema.nodes.docCodeMirror.create({}, textNode);
   // let endOfNodePos = $from.pos + docCodeMirror.nodeSize-2;

   // console.log(`end of node pos----- ${endOfNodePos}`)
    
    let tr = state.tr.replaceRangeWith($from.pos, $to.pos, docCodeMirror);

      // Check if the selection extends to the start or end of a block
    let atStartOfBlock = $from.parentOffset === 0;
    let atEndOfBlock = $from.parentOffset === $from.parent.content.size;

    // Compute the position at the end of the inserted node, adjusting for split paragraphs
     let adjustment = (atStartOfBlock) ? 0 : 2;
    let endOfNodePos = $from.pos + docCodeMirror.nodeSize - 2 - adjustment;


    // Update the transaction to move the cursor to the end of the inserted node
    tr = tr.setSelection(TextSelection.create(tr.doc, endOfNodePos, endOfNodePos));



    // Update the transaction to move the cursor to the end of the inserted node
    tr = tr.setSelection(TextSelection.create(tr.doc, endOfNodePos, endOfNodePos));



    //tr = tr.setSelection(TextSelection.create(tr.doc, endOfNodePos, endOfNodePos))
    if (dispatch) dispatch(tr.scrollIntoView());
    
    return true;
  };
}


// export function insertCodeBlockCommand() {
//   return function(state, dispatch) {
//     let { $from, $to } = state.selection;
//     let range = $from.blockRange($to);
//     if (!range) return false;
    
//     const textContent = state.doc.textBetween($from.pos, $to.pos, "\n", "\n");
//     const textNode = state.schema.text(textContent);
//     const docCodeMirror = state.schema.nodes.docCodeMirror.create({}, textNode);
    
//     const tr = state.tr.replaceRangeWith($from.pos, $to.pos, docCodeMirror);
//     if (dispatch) dispatch(tr.scrollIntoView());
    
//     return true;
//   };
// }


export function removeCodeBlockCommand() {
	return function(state, dispatch) {
		let {$from, $to} = state.selection
  	let range = $from.blockRange($to), target = range && liftTarget(range)
  	if (target == null){
  		return false
  	}else{
  		if (dispatch){
  			dispatch(state.tr.lift(range, target).scrollIntoView())
  		}
 			 return true
  	}
  	

  }
}



// export function insertCodeBlockCommand() {
//   return function(state, dispatch) {
//     const nodeType=schema.nodes.codeBlock
//     let {$from, $to} = state.selection
//     let range = $from.blockRange($to), wrapping = range && findWrapping(range, nodeType)
//     //check not already in a content box
//     const anchorNode=getAnchorNode($from,'codeBlock')
//     if(anchorNode){
//       return removeCodeBlockCommand()(state,dispatch)
//     }

//     if (!wrapping){
//       return false
//     }
//     else 
//       if (dispatch){
//         let tr=state.tr
//         tr.wrap(range, wrapping).scrollIntoView()
//         dispatch(tr)
//     }
//     return true
//   }
// }

// export function removeCodeBlockCommand() {
//   return function(state, dispatch) {
//     let {$from, $to} = state.selection
//     let range = $from.blockRange($to), target = range && liftTarget(range)
//     if (target == null){
//       return false
//     }else{
//       if (dispatch){
//         dispatch(state.tr.lift(range, target).scrollIntoView())
//       }
//        return true
//     }
    

//   }
// }

