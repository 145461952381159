import React from 'react'
import LinearNativeIcon from './LinearNativeIcon'
import {getColorForLinearHex} from '../../utils/getColorForLinearHex'
import data from '../../emojiData'
import {getTeamForId} from '../../utils/getTeamForId'
import {getProjectForId} from '../../utils/getProjectForId'

function getEmojiForString(string){
  const natives=data.natives
  const keys=Object.keys(natives)
  let emoji
  keys.map((key)=>{
    if(natives[key]==string && !emoji){
      emoji=key
    }
  })
  return emoji
}

const regExp = ':(.*):' //match :heart:

class LinearMarkerInline extends React.Component{    
  render(){ 
    const {teamId,projectId}=this.props

    let item //item is a team or project
    let itemType
    let color=''
    let icon=''
    if(teamId){
      item=getTeamForId(teamId)
      itemType='team'
    }else if(projectId){
      item=getProjectForId(projectId)
      itemType='project'
    }
    
    // no icon for project
    if(item && !item.icon && itemType==='project'){      
      icon='project'
    }
    // no icon for team
    if(item && !item.icon && itemType==='team'){      
      icon='team'
    }

    if(item && item.icon){
      icon=item.icon
    }

    if(item && item.color){
      color=item.color
    }
    

    
    //
    // Can be named color or custom color
    // if named color (e.g. 'dark red') then we switch to our palette
    // if custom color, then we apply that color
    let customColor
    const namedColor = getColorForLinearHex(color)
    if(!namedColor){
      customColor=color
    }

    // console.log(`color ${color}`)
    // console.log(`namedColor ${namedColor}`)


    //
    var match = icon.match(regExp)
    let isEmoji = false
    let emojiCharacter
    if(match && match[1]){
      isEmoji=true
      const emojiString=match[1]
      //const emojiString="hand"
      emojiCharacter=getEmojiForString(emojiString)
    }
   // console.log(data)



    return (
      <span className={'inlineLinearMarkerContainer ' + (isEmoji ? ' inlineLinearMarkerContainer--markerType--emoji ' : ' inlineLinearMarkerContainer--markerType--icon ') + (namedColor ? `inlineLinearMarkerContainer--color--${namedColor}` : '')}>
      
        {isEmoji &&
          <span className='inlineLinearMarker'>            
            {emojiCharacter}
          </span>
        }

        {!isEmoji &&
          <span className='inlineLinearMarker'>
            <LinearNativeIcon fill={customColor} name={icon.toLowerCase()} />
          </span>
        }        
      
      </span>
    )
  }
}

export default LinearMarkerInline

    