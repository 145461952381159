import React from 'react'
import Icon from '../misc/Icon'
import { Spring, animated, config } from 'react-spring'

class RecordingPlaybackModalTranscriptHighlightUI extends React.Component{  	

	 constructor(props) {
    super(props)    
   	  this.textAreaRef = React.createRef();

    this.state = {
    	showHighlightLoad: false
   	};    
  }
	
	componentDidMount() {
	  setTimeout(() => {
	    this.setState({showHighlightLoad: true});
	  }, 500);
	}

  componentDidUpdate(prevProps, prevState) {
	  if (!prevState.showNewHighlightPanel && this.props.showNewHighlightPanel) {
	    this.textAreaRef.current.focus();
	  }
	}

	render(){	
		const {highlightMode,showNewHighlightPanel, showResetAutoScrollButton}=this.props
		
		const showTextArea = showNewHighlightPanel
		const showHoldTooltip = !highlightMode &&  !showNewHighlightPanel && this.state.showHighlightLoad
		const showDragTooltip = highlightMode && !showNewHighlightPanel
		const showBottomGradient = !highlightMode || !showNewHighlightPanel

		const showHighlightLabel = this.props.textAreaValue.length > 0

	  return (	 
	  	<React.Fragment> 	

					
					<Spring
						opacity={showNewHighlightPanel ? 1 : 0}		
						transform={showNewHighlightPanel ? 'translateY(0px) translateX(0px) scale(1)' : 'translateY(0px) translateX(0px) scale(0.9)'}
						config={{
							tension: 400,
							friction: 24,
						}}
					>
		  			{styles => 
							<animated.div style={styles} className={'recordingPlaybackModal-transcript-highlightPanelContainer ' + (showNewHighlightPanel ? 'recordingPlaybackModal-transcript-highlightPanelContainer--show' : 'recordingPlaybackModal-transcript-highlightPanelContainer--hide')}>
		  					<div className='recordingPlaybackModal-transcript-highlightPanel'>
		  						<div className='recordingPlaybackModal-transcript-highlightPanel-textAreaContainer'>
			  						<textarea
			  							ref={this.textAreaRef}
			  							disabled={!showNewHighlightPanel}
			  							onKeyDown={this.props.textAreaOnKeyDown}
											value={this.props.textAreaValue}
											onChange={this.props.textAreaOnChange}
											maxLength={500}
											placeholder="Add highlight description..."
											className='recordingPlaybackModal-transcript-highlightPanel-textArea'
										/>
										<div className='recordingPlaybackModal-transcript-highlightPanel-textAreaGhost'>
											{this.props.textAreaValue}
										</div>
									</div>
									<div className='recordingPlaybackModal-transcript-highlightPanel-footer'>
										<div data-state={showHighlightLabel ? 'visible' : 'hidden'} className='recordingPlaybackModal-transcript-highlightPanel-footer-label'>
											<span className='recordingPlaybackModal-transcript-highlightPanel-footer-label-shortcut'>
												 Shift + Return
											</span> to add a new line
										</div>
										<button onClick={this.props.createHighlight} className='recordingPlaybackModal-transcript-highlightPanel-submitBtn'>
											{/*<div className='recordingPlaybackModal-transcript-highlightPanel-submitBtn-iconContainer'>
												<Icon name='plus' />
											</div>
											*/}
											<div className='recordingPlaybackModal-transcript-highlightPanel-submitBtn-label'>
												New Highlight
											</div>									
										</button>
									</div>
		  					</div>
							</animated.div>
		  			}
		  		</Spring>	

					<Spring
						opacity={showHoldTooltip ? 1 : 0}		
						//transform={showHoldTooltip ? 'translateY(0px) translateX(-50%) scale(1)' : 'translateY(20px) translateX(-50%) scale(1)'}
						transform={showHoldTooltip ? 'translateY(0px) translateX(-50%) scale(1)' : 'translateY(0px) translateX(-50%) scale(0.6)'}
						config={{
							tension: 400,
							friction: 24,
						}}
					>
		  			{styles => 
							<animated.div style={styles} className='recordingPlaybackModal-transcript-highlightModeTooltip recordingPlaybackModal-transcript-highlightModeTooltip--hold'>
								<div className='recordingPlaybackModal-transcript-highlightModeTooltip-label'>	
									Hold <span className='recordingPlaybackModal-transcript-highlightModeTooltip-label-icon'>Command <Icon name='keyCommandMedium'/></span> to highlight
								</div>
							</animated.div>
		  			}
		  		</Spring>	


		  		<Spring
						opacity={showDragTooltip ? 1 : 0}		
						//transform={showDragTooltip ? 'translateY(0px) translateX(-50%) scale(1)' : 'translateY(0px) translateX(-50%) scale(0.6)'}
						transform={showDragTooltip ? 'translateY(0px) translateX(-50%) scale(1)' : 'translateY(12px) translateX(-50%) scale(1)'}
						config={{
							tension: 400,
							friction: 24,
							delay: showDragTooltip ? 1 : 0
						}}
					>
		  			{styles => 
							<animated.div style={styles} className='recordingPlaybackModal-transcript-highlightModeTooltip recordingPlaybackModal-transcript-highlightModeTooltip--drag'>	
								{/*}
								<div className='recordingPlaybackModal-transcript-highlightModeTooltip-iconContainer'>
									<Icon name='cursorSelect'/>
								</div>
								*/}
								<div className='recordingPlaybackModal-transcript-highlightModeTooltip-label'>	
									Drag to select text
								</div>
							</animated.div>
		  			}
		  		</Spring>	


		  		{/*}
		  			<Spring
						opacity={this.props.showResetAutoScrollButton ? 1 : 0.25}		
						//transform={showHoldTooltip ? 'translateY(0px) translateX(-50%) scale(1)' : 'translateY(0px) translateX(-50%) scale(1)'}
						//transform={(this.props.showResetAutoScrollButton && !showDragTooltip && !showNewHighlightPanel) ? 'translateY(0px) scale(1)' : 'translateY(0px) scale(0.6)'}
						config={{
							tension: 400,
							friction: 24,
						}}
					>
		  			{styles => 
							<animated.div style={styles} onClick={this.props.resetAutoScroll} className='recordingPlaybackModal-transcript-highlightModeTooltip recordingPlaybackModal-transcript-highlightModeTooltip--resetAutoscroll'>
								<div className='recordingPlaybackModal-transcript-highlightModeTooltip-label'>	
									Auto-Scroll
								</div>
							</animated.div>
		  			}
		  		</Spring>	
		  	*/}



		  		

		  		<Spring
						opacity={showDragTooltip ? 1 : 1}		
						//transform={showDragTooltip ? 'translateY(32px) translateX(0px) scale(1)' : 'translateY(0px) translateX(0px) scale(1)'}
						config={{
							tension: 400,
							friction: 30,							
						}}
					>
		  			{styles => 
							<animated.div style={styles} className='recordingPlaybackModal-transcript-bottomGradient' />								
		  			}
		  		</Spring>	



	  				{showNewHighlightPanel && !this.props.cmdKeyIsDown &&
	  					<button onClick={this.props.cancelCreateHighlight} className='recordingPlaybackModal-transcript-highlightPanel-cancelButton'>
								
							</button>
	  				}

		  			

			</React.Fragment>
	  )
	}
}

export default RecordingPlaybackModalTranscriptHighlightUI