import {Plugin,PluginKey} from "prosemirror-state"
import {ActiveWordState} from './activeWord'
import {Decoration, DecorationSet} from "prosemirror-view"

const key = new PluginKey('highlightActiveWordPlugin')

 export const activeWordPlugin = new Plugin({
  key: key,
  state: {
    init: ActiveWordState.init,
    apply(tr, prev) { return prev.apply(tr) }
  },
  props: {
    decorations(state) { 
      let activeWordState = this.getState(state);

      // console.log('decos are-----------')
      // console.log(activeWordState.decos)
      let decos=activeWordState.decos 
      //TEMP fix for [null] decos thing
      //not sure how its being created with null deco so lets add this line for now
      // let filteredDecos = Array.isArray(decos) ? decos.filter(Boolean) : [];
      // console.log(filteredDecos)
      return decos;
    }
  }
})
