import {fetch,put,post,batchedFetch} from '../api'
import {showToastNotification} from './toastNotification'
import { Mixpanel } from '../Mixpanel'
import {recalcProjectMilestones} from '../utils/milestones/recalcProjectMilestones'
import {syncMilestones} from './milestones'

export function fetchProjects(){
	return (dispatch) => batchedFetch(`/projects`)
	.then((response) => {
		dispatch({ type: 'FETCH_PROJECTS_SUCCESS', response })
		recalcProjectMilestones()
		return response
	})
	.catch((error) => {
		return error
	})
}

//updated
//{roadmapId:id,action:add/remove}

export function updateProjectRoadmapsRequest(projectId,updates,showToast){
	const requestBody={
		updates:updates
	}
	Mixpanel.track('update_project_roadmaps')
	return (dispatch) =>{
		updates.forEach((update)=>{
			if(update.action=='add'){
				const roadmapId=update.roadmapId
				 dispatch({ type: 'ADD_PROJECT_TO_ROADMAP_REQUEST', projectId,roadmapId})
			}
			else if(update.action=='remove'){
				const roadmapId=update.roadmapId
				 dispatch({ type: 'REMOVE_PROJECT_FROM_ROADMAP_REQUEST', projectId,roadmapId})
			}
		})
		return put(`/projects/${projectId}/update-roadmaps`,requestBody)
			.then((response) => {
				if(showToast){
					if(updates.length==1){
						if(updates[0].action=='add'){
							const actionType='addSingleRoadmapToProject'
							const userName=projectId
							const actionButton=updates[0].roadmapId
							//dispatch(showToastNotification(actionType, userName,actionButton))
						}
						else if(updates[0].action=='remove'){
							const actionType='removeSingleRoadmapFromProject'
							const userName=projectId
							const actionButton=updates[0].roadmapId
							//dispatch(showToastNotification(actionType, userName,actionButton))
						}
					}else{
						const actionType="updatedRoadmaps"   
						const userName = projectId
						//dispatch(showToastNotification(actionType, userName))
					}
				}
				return response
		})
		.catch((error) => {
			return error
		})
	}
}


export function removeProjectFromRoadmapRequest(projectId,roadmapId){
	const requestBody={
		projectId:projectId
	}
	return (dispatch) =>{
		dispatch({ type: 'REMOVE_PROJECT_FROM_ROADMAP_REQUEST', projectId,roadmapId})
		return put(`/roadmaps/${roadmapId}/remove-project`,requestBody)
		.then((response) => {
			return response
		})
		.catch((error) => {
			return error
		})
	}
}



export function addProjectToRoadmapRequest(projectId,roadmapId){
	const requestBody={
		projectId:projectId
	}
	return (dispatch) =>{
		dispatch({ type: 'ADD_PROJECT_TO_ROADMAP_REQUEST', projectId,roadmapId})
		return put(`/roadmaps/${roadmapId}/add-project`,requestBody)
		.then((response) => {
			return response
		})
		.catch((error) => {
			return error
		})
	}
}




export function createProjectRequest(project){
	const requestBody={project:project}
		Mixpanel.track('create_project')
	return (dispatch) =>{
		dispatch({ type: 'CREATE_PROJECT_REQUEST', project})
		return post(`/projects`,requestBody)
		.then((response) => {
			dispatch({ type: 'CREATE_PROJECT_SUCCESS', project:response})
			const actionType="createdProject"   
			const userName = response.project_id
			dispatch(showToastNotification(actionType, userName))
			return response
		})
		.catch((error) => {
			const actionType="createdProjectError"   
			dispatch(showToastNotification(actionType))
			dispatch(fetchProjects())
			return error
		})
	}
}

export function updateProjectRequest(project,showToast){
	const projectId=project.project_id
	let updatedProject={...project}
	updatedProject.updated_at = new Date()
	const requestBody={project:updatedProject}
		Mixpanel.track('update_project')
	return (dispatch) =>{
		dispatch({ type: 'UPDATE_PROJECT_REQUEST', updatedProject})
		return put(`/projects/${projectId}`,requestBody)
		.then((response) => {
			if(showToast){
				const actionType="updatedProject"   
				dispatch(showToastNotification(actionType))
			}else{
				// console.log('dont show toast!')
			}
			recalcProjectMilestones()
			return response
		})
		.catch((error) => {
			const actionType="updatedProjectError"   
			dispatch(showToastNotification(actionType))
			dispatch(fetchProjects())
			return error
		})
	}
}


// export function updateProjectRoadmapsLocally(projectId,roadmaps){
// 	return (dispatch) =>{
// 		dispatch({ type: 'UPDATE_PROJECT_ROADMAPS_LOCALLY', projectId,roadmaps})
		
// 	}
// }





// export function updateProjectStatusRequest(projectId,status){
// 	const requestBody={status:status}
// 	return (dispatch) =>{
// 		dispatch({ type: 'UPDATE_PROJECT_STATUS_REQUEST', projectId,status})
// 		return put(`/projects/${projectId}/status`,requestBody)
// 		.then((response) => {
// 			console.log('update project status response')
// 			console.log(response)
// 			const actionType="updatedProjectStatus"   
// 			dispatch(showToastNotification(actionType))
// 			return response
// 		})
// 		.catch((error) => {
// 			console.log('update project status error-------------')
// 			const actionType="updatedProjectStatusError"   
// 			dispatch(showToastNotification(actionType))
// 			dispatch(fetchProjects())
// 			return error
// 		})
// 	}
// }


// export function updateProjectTargetDateRequest(projectId,targetDate){
// 	const requestBody={targetDate:targetDate}
// 	console.log('update project target date request------')
// 	console.log(targetDate)
// 	console.log(requestBody)


// 	return (dispatch) =>{
// 		dispatch({ type: 'UPDATE_PROJECT_TARGET_DATE_REQUEST', projectId,targetDate})
// 		return put(`/projects/${projectId}/target`,requestBody)
// 		.then((response) => {
// 			console.log('update project targetDate response')
// 			console.log(response)
// 			const actionType="updatedProjectTargetDate"   
// 			dispatch(showToastNotification(actionType))
// 			return response
// 		})
// 		.catch((error) => {
// 			console.log('update project status error-------------')
// 			const actionType="updatedProjectTargetDateError"   
// 			dispatch(showToastNotification(actionType))
// 			dispatch(fetchProjects())
// 			return error
// 		})
// 	}
// }



export function syncProjects(){
	return (dispatch) => put(`/projects/sync`)
	.then((response) => {
		dispatch({ type: 'FETCH_PROJECTS_SUCCESS', response })
		recalcProjectMilestones()
		return response
	})
	.catch((error) => {
		return error
	})
}


export function handleProjectCreatedPusher(project){
	return (dispatch) => {
		dispatch(syncMilestones())
		dispatch({ type: 'CREATE_PROJECT_SUCCESS', project})
	}
}


export function handleProjectDeletedPusher(projectId){
	return (dispatch) => {
		dispatch({ type: 'DELETE_PROJECT', projectId})
	}
}


export function handleProjectUpdatedPusher(project){
	return (dispatch) => {
		// console.log('project updated pusher--------')
		dispatch(syncMilestones())
		dispatch({ type: 'UPDATE_PROJECT_SUCCESS', project})
	}
}
