export function getAnchorNode($from,nodeType) {
	let anchorNode
	for (let i = $from.depth; i > 0; i--) {
		let node = $from.node(i)
		if (node.type.name == nodeType) {
			anchorNode = node
			break
		}
	}
	return anchorNode
}
