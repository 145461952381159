function getAttrs(dom) {
  return {
    docId: dom.getAttribute('data-docId'),
    docNameSnapshot: dom.getAttribute('data-docNameSnapshot')
    // isAdvancedInput: dom.getAttribute('data-isAdvancedInput') || null,
  }
}


const InternalLinkNodeSpec ={
    attrs: {
      docId:{default:''},
      docNameSnapshot:{default:''}
    },
    inline: true,
    group: "inline",
    draggable: true,
    parseDOM: [
    {tag: 'internalLink', getAttrs},
   ],
    toDOM(node) {
      return ['internalLink', {'data-docId':node.attrs.docId,'data-docNameSnapshot':node.attrs.docNameSnapshot}]
  }
   //  toDOM(node) {
   //    const div = document.createElement('div');
   //    div.className="doc-para-internalLinkContainer"

   //    const div2=document.createElement('div');
   //    div2.className = 'doc-para-internalLink'
      

   //    const div3=document.createElement('div');
   //    div3.className = 'doc-para-internalLink-title'
   //    div3.innerHTML=`${node.attrs.docNameSnapshot}`
   //    div2.appendChild(div3)
   //    div.appendChild(div2)

   //    return div;
   // },
}
    
   

  export default InternalLinkNodeSpec


