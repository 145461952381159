import React from 'react'
import Icon from '../misc/Icon'
import { Transition, animated, config } from 'react-spring'
import {addDocLinkToLinear} from '../../utils/addDocLinkToLinear'
import {getProjectForId} from '../../utils/getProjectForId'
import {openProjectInLinear} from '../../utils/openInLinear'
import * as Tooltip from '@radix-ui/react-tooltip';

const HIDE_SUCESS_TOAST_DELAY=5000 //hide sucess toast after 5 seconds

class DocEditorAddLinearLinkBtn extends React.Component{  

  constructor(props) {
    super(props)  
    this.handleClickAddLinkToLinearBtn=this.handleClickAddLinkToLinearBtn.bind(this) 
    this.openProjectInLinear=this.openProjectInLinear.bind(this)
    this.state={
      showAddLinearButton:!props.linkCreated,      
      showAddLinearSuccessToast:false,      
    }
    this.timeout=null
  } 

  componentWillUnmount() {
    if(this.timeout){
      clearTimeout(this.timeout)
    }
  }

  handleClickAddLinkToLinearBtn(){
    this.setState({showAddLinearButton:false})   
    addDocLinkToLinear(this.props.docId).then(()=>{
      this.setState({showAddLinearSuccessToast: true})
      this.timeout=setTimeout(function() {
        this.setState({showAddLinearSuccessToast: false})
      }.bind(this), HIDE_SUCESS_TOAST_DELAY)    
    })   
  }


  openProjectInLinear(){
    const {projectId}=this.props
    const project=getProjectForId(projectId)
    const slug=project.slug_id
    openProjectInLinear(slug)
  }

	render(){	
    const {showAddLinearButton, showAddLinearSuccessToast} = this.state
	  return (	  	
      <React.Fragment>
       <div className='docEditor-addLinearLinkBtnContainer'>  
        <Transition
          items={showAddLinearButton}
          from={{ opacity: 0, transform: `translateY(3px) scale(0.9)` }}
          enter={{ opacity: 1, transform: 'translateY(0px) scale(1)'  }}
          leave={{ opacity: 0, transform: `translateY(10px) scale(1)`  }}          
          config={{tension: 300, friction: 22}}
          >
          {(styles, item) =>
            item && 
             <animated.button style={styles} onClick={this.handleClickAddLinkToLinearBtn} className="docEditor-addLinearLinkBtn">  
                <div className='docEditor-addLinearLinkBtn-iconContainer'>
                  <Icon name='linearIcon' />
                </div>
                <div className='docEditor-addLinearLinkBtn-label'>
                  Add Link in Linear
                </div>                                  
                <div className='docEditor-addLinearLinkBtn-label-tooltipContainer'>
                  <div className='tooltip'>
                    Add to links section in Linear project page.
                  </div>
                </div>
            </animated.button>
          }
          </Transition>         
      </div>
      <div className='docEditor-addLinearLinkToastContainer'>
        <Transition
          items={showAddLinearSuccessToast}
          from={{ opacity: 0, transform: `translateY(0px) scale(0.9)` }}
          enter={{ opacity: 1, transform: 'translateY(0px) scale(1)'  }}
          leave={{ opacity: 0, transform: `translateY(10px) scale(1)`  }}          
          config={{tension: 300, friction: 22}}
          >
          {(styles, item) =>
            item && 
             <animated.div style={styles} className="docEditor-addLinearLinkToast toast">  
              {/*<div className='docEditor-addLinearLinkToast-checkbox'>
                <Icon name='toDoItemCheckmark' /> 
              </div>
              */}
              <div className='docEditor-addLinearLinkToast-label'>
                Added
              </div> 
              <button className='docEditor-addLinearLinkToast-linkBtn' onClick={this.openProjectInLinear}>
                Open Project in Linear
              </button>                                                                              
            </animated.div>
          }
          </Transition>              
       </div>
      </React.Fragment>
	  )
	}
}

export default DocEditorAddLinearLinkBtn
