import React from 'react'
import Icon from '../misc/Icon'
import DocImageZoomedModalBtn from '../docEditor/mediaModals/DocImageZoomedModalBtn'
import FigmaViewZoomedUIStatus from './FigmaViewZoomedUIStatus'

class FigmaViewZoomedUIHeader extends React.Component{  	
	
	render(){	
		const {cropModeActive,showEffectsToolbar,viewOnlyMode} = this.props
		return (
			<div className='doc-zoomedFigmaModal-header'>
				{cropModeActive && 
					<button onClick={this.props.disableCropMode} className='doc-zoomedFigmaModal-header--closeCropBtn' />
				}
				{showEffectsToolbar && 
					<button onClick={this.props.hideEffectsToolbar} className='doc-zoomedFigmaModal-header--closeCropBtn' />
				}
				<div className={'doc-zoomedFigmaModal-header-leftContainer ' + ((cropModeActive || showEffectsToolbar) ? ' doc-zoomedFigmaModal-header-leftContainer--fade ' : ' doc-zoomedFigmaModal-header-leftContainer--visible ')}>	
					<DocImageZoomedModalBtn
						iconName='insertBarFigma'
						label={this.props.title}
						name="figmaTitle"					
					/>
					
				</div>
					
				<div className='doc-zoomedFigmaModal-header-centerContainer'>
						
						
						
				</div>
				<div className={'doc-zoomedFigmaModal-header-rightContainer' + ((cropModeActive || showEffectsToolbar) ? ' doc-zoomedFigmaModal-header-rightContainer--fade ' : ' doc-zoomedFigmaModal-header-rightContainer--visible ')}>							
					
					{/*}
					{!viewOnlyMode && 
						<DocImageZoomedModalBtn							
							iconName='refreshSemibold'
							label='Refresh'
							//onClickFunction={this.props.enableCropMode}
							tooltipLabel="Paste a new Frame URL to replace."
						/>
					}
					*/}
					

					{!viewOnlyMode && 
						<DocImageZoomedModalBtn							
							iconName='effects'
							label='Effects'														
							onClickFunction={this.props.openEffectsToolbar}
						/>						
					}
					{!viewOnlyMode && 
						<DocImageZoomedModalBtn							
							iconName='imageCrop'
							label='Crop'														
							onClickFunction={this.props.enableCropMode}
						/>
					}

					<div onClick={this.props.unzoom} className='doc-zoomedFigmaModal-header-closeBtnOuterContainer'>
						<DocImageZoomedModalBtn
							iconName='cross'								
						/>
					</div>
						{/*} MAYBE ADD THREADING LATER
						{!this.props.showThreadPanel &&
							<DocImageZoomedModalBtn							
								iconName='rightMenu'
								label='Show Thread'
								name="thread"		
								onClickFunction={this.props.openThreadPanel}
							/>
						}
						*/}				
					</div>
			</div>
		)
	}
}

export default FigmaViewZoomedUIHeader
