import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import DocVideoCommentRowMessage from './DocVideoCommentRowMessage'
import {getUserName} from '../../../utils/getUserName'
import {getUserForId} from '../../../utils/getUserForId'
import {formatDate} from '../../../utils/formatDate'

class DocVideoCommentRowComment extends React.Component{  
	   
	constructor(props) {
		super(props)
		this.state={
			widthMessagePreview: null,
		}
		this.timeout=null
	} 

	componentDidMount(){
		this.timeout= setTimeout(() => {
			if(this.myRef){
				const rect = this.myRef.getBoundingClientRect()
				const width = rect.width	
				this.setState({widthMessagePreview: width})
			}
     },50)	
	}

	componentWillUnmount(){
		if(this.timeout){
			clearTimeout(this.timeout)
		}
	}


	render(){ 		
		const {forceShow, videoPosition, videoDuration, rowWidth,seekToComment} = this.props

		const percentagePosition = videoPosition / videoDuration
		const pixelPosition = rowWidth * percentagePosition

		//
		// COMMENT MARKER POSITION
		const widthMessageMarker = 20

		const minXPositionMessageMarker = 2 // don't let preview overflow left edge
	 	const maxXPositionMessageMarker = rowWidth - widthMessageMarker - 2  // don't let preview overflow right edge		  
	  const standardXPositionMessageMarker = pixelPosition - (0.5 * widthMessageMarker)

	 	const positionMessageMarker = Math.max(minXPositionMessageMarker, Math.min(maxXPositionMessageMarker, standardXPositionMessageMarker));


		//
		// MESSAGE PREVIEW POSITION
		// need to take message preview as an input
		//const widthMessagePreview = 332
		//const widthMessagePreview = 280
		const widthMessagePreview = this.state.widthMessagePreview

		const minXPositionMessagePreview = 5 // don't let preview overflow left edge
	 	const maxXPositionMessagePreview = rowWidth - widthMessagePreview - 5  // don't let preview overflow right edge		  
	  const standardXPositionMessagePreview = pixelPosition - (0.5 * widthMessagePreview)

	 	const positionMessagePreview = Math.max(minXPositionMessagePreview, Math.min(maxXPositionMessagePreview, standardXPositionMessagePreview));


	 	const positionDeltaMessagePreview = positionMessagePreview - positionMessageMarker

		return (			
			<div onClick={this.props.seekToComment} style={{width: `${widthMessageMarker}px` , left: `${positionMessageMarker}px`}} className={'doc-video-commentRow-commentContainer ' + (forceShow ? ' doc-video-commentRow-commentContainer--forceShow ' : ' doc-video-commentRow-commentContainer--notForceShow ')}>
				<div style={{width: `${widthMessageMarker}px`}} className='doc-video-commentRow-commentMarker'>
					<AvatarImage 
  					className='doc-video-commentRow-commentMarker-avatar' 
  					avatarUrl={this.props.avatarUrl}
  				/>
				</div>		
				<div style={{width: `${widthMessagePreview}px`,  transform: `translateX(${positionDeltaMessagePreview}px)`}} className='doc-video-commentRow-commentMessageOuterContainer'>
					<div className={'doc-video-commentRow-messageContainer' + (this.props.darkModeMessage ? ' forceDarkTheme ' : ' ')}>
						<DocVideoCommentRowMessage
							name={this.props.name}
							timestampLabel={this.props.timestampLabel}
							avatarUrl={this.props.avatarUrl}
							message={this.props.message}
							darkModeMessage={this.props.darkModeMessage}
						/>
					</div>
				</div>
				<div className='doc-video-commentRow-commentMessageOuterContainer doc-video-commentRow-commentMessageOuterContainer--ghost'>
					<div ref={(ref) => { this.myRef = ref }} className='doc-video-commentRow-messageContainer'>
						<DocVideoCommentRowMessage
							name={this.props.name}
							timestampLabel={this.props.timestampLabel}
							avatarUrl={this.props.avatarUrl}
							message={this.props.message}
							darkModeMessage={this.props.darkModeMessage}
						/>
					</div>
				</div>							
			</div>							
		)
	} 
}


class DocVideoCommentRow extends React.Component{  
	   
	render(){ 				
		const {rowWidth, progressSeconds, darkModeMessage,timestampedMessages,duration} = this.props		
		return (	
			<div className='doc-video-commentRow'>
				<div className='doc-video-commentRow-fadeOutCover' />				
				{timestampedMessages.map((message)=>{
					const author=getUserForId(message.created_by)
					let avatar=null
					if(author){
						avatar=author.slack_avatar
					}
					const messageTimestamp = message.selection_snapshot.timestampSeconds
					let forceShow = false
					if(progressSeconds){
						const deltaLowerBoundForceShow = 5 // force show X seconds before comment
						const deltaUpperBoundForceShow = 2 // force show X seconds after comment
						const lowerBoundForceShow = messageTimestamp - deltaLowerBoundForceShow						
						const upperBoundForceShow = messageTimestamp + deltaUpperBoundForceShow
						forceShow = progressSeconds >= lowerBoundForceShow && progressSeconds <= upperBoundForceShow	
					}					
					return(
						<DocVideoCommentRowComment
							name={getUserName(message.created_by)}
							timestampLabel={formatDate(message.created_at)}
							avatarUrl={avatar}
							message={message.plain_text}
							videoPosition={messageTimestamp}
							videoDuration={duration}
							rowWidth={rowWidth}
							darkModeMessage={darkModeMessage}
							seekToComment={(e)=>{this.props.seekToComment(e,message.selection_snapshot.timestampSeconds)}}
							forceShow={forceShow} // show if +/- 2 seconds or something around comment, i think later take into account high density (multiple comments)
						/>
					)
				})}
			</div>											
		)
	}
}

export default DocVideoCommentRow
