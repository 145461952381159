import React from 'react'
import Icon from './Icon'

class TooltipKeyboardShortcut extends React.Component{  	

	render(){	

	  return (
	  	
	  		<div className={'keyboardShortcutTagContainer ' + (this.props.keyboardShortcutOnly ? 'keyboardShortcutTagContainer--keyboardShortcutOnly': '')}>
		  		<div className='keyboardShortcutTag'>
		  			{this.props.command && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyCommand' />	  		
		  				</div>
		  			}

		  			{this.props.commandMedium && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyCommandMedium' />	  		
		  				</div>
		  			}

		  			{this.props.commandSemibold && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyCommandSemibold' />	  		
		  				</div>
		  			}

		  			{this.props.option && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyOption' />	  		
		  				</div>
		  			}
		  			{this.props.control && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyControl' />	  		
		  				</div>
		  			}

		  			{this.props.shift && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyShift' />	  		
		  				</div>
		  			}

		  			{this.props.shiftOutline && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyShiftOutline' />	  		
		  				</div>
		  			}

		  			{this.props.shiftOutlineMedium && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyShiftOutlineMedium' />	  		
		  					{this.props.keyLabel &&
									<span>				  			
				  					Shift
				  				</span>
				  			}		
		  				</div>
		  			}

		  			{this.props.shiftOutlineSemibold && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyShiftOutlineSemibold' />	  
		  					{this.props.keyLabel &&
									<span>				  			
				  					Shift
				  				</span>
				  			}		
		  				</div>
		  			}

		  			{this.props.backspace && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name='keyBackspace' />	  		
		  				</div>
		  			}

		  			{this.props.iconName && 
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--icon'>
		  					<Icon name={this.props.iconName} />	  		
		  				</div>
		  			}

		  			{this.props.letter &&
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--letter'>
		  					{this.props.letter}
		  				</div>
		  			}

		  			{this.props.click &&
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--click'>
		  					Click
		  				</div>
		  			}

		  			{this.props.space &&
		  				<div className='keyboardShortcutTag-item keyboardShortcutTag-item--space'>
		  					space
		  				</div>
		  			}

		  			

		  			


		  		</div>
		  	</div>
			
	  )
	}
}

export default TooltipKeyboardShortcut
