const DocVideoNodeSpec ={
    attrs: {
      muxId: {default:''},
      dimensions:{default:{width:0,height:0}},
      title:{default:''},
      subtitles:{default:''},
      nodeId:{default:''},
      messageVersion:{default:0} //to bump version and force node to check for new messages

    },
    // selectable:false,
    group: "block",
    draggable: false,
    parseDOM: [{tag: 'docVideo'}],
     toDOM(node) {
     return ['docVideo']
    },
    parseDOM: [
      {
        tag: "docVideo",
        getAttrs: (dom) => ({
          muxId: dom.getAttribute("data-mux-id"),
          dimensions: JSON.parse(dom.getAttribute("data-dimensions")),
          title: dom.getAttribute("data-title"),
          subtitles: dom.getAttribute("data-subtitles"),
          nodeId: dom.getAttribute("data-node-id")
        })}
    ],
    toDOM(node) {
      const {muxId,dimensions,title,subtitles,nodeId} = node.attrs;
      return ["docVideo",
      {"data-mux-id":muxId,
      "data-dimensions":JSON.stringify(dimensions),
      "data-title":title,
      "data-subtitles":subtitles,
      "data-node-id":nodeId,
    }]
    }
}
    
   

  export default DocVideoNodeSpec
 
