import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import {syncRoadmaps} from '../actions/roadmaps'
import {syncMilestones} from '../actions/milestones'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import groupBy from 'lodash/groupBy'
import {getUserForId} from '../utils/getUserForId'
import RoadmapsHomePage from '../components/roadmaps/RoadmapsHomePage'
import {updateProjectPageSetting} from '../utils/updateProjectPageSetting'
import moment from 'moment'
import ReactGA from 'react-ga4'

const PROJECTS_CUTOFF=15 //exclude projects completed more than 5 days ago

function filterProjects(projects,showAllCompleted){
	if(showAllCompleted){
		return projects
	}else{
		let filteredProjects=filter(projects,function(project){
			if(project.state=='completed'){
				let dur=moment.duration( moment().diff(moment(project.completed_at)))
				const days=dur._data.days
				if(days<PROJECTS_CUTOFF){
					return project
				}	
			}else return project
		})
		return filteredProjects 
	}		
}
 

function getSortedGroupedProjects(projects,grouping,sortOrder){
	let sortedAndGrouped=[]
	if(grouping=='none' || !grouping){
		if(sortOrder=='alphabetical'){
			sortedAndGrouped=sortBy(projects, function(project){
      	return project.name.toString().toLowerCase()
    	})
		}else if(sortOrder=='targetDate'){ //sort alphabeticalu and then by target date
			sortedAndGrouped = orderBy(projects, [function (project) {
       if(project.target_date){
					return (moment(project.target_date))
				}else return null
      }, function (project) {
        return project.name.toString().toLowerCase()
      }], ['asc', 'asc'])
		}
	}else if(grouping=='lead'){
		const grouped=groupBy(projects,function(project) {
			const projectLead=getUserForId(project.lead) //can be null			
			if(projectLead){
				return projectLead.display_name
			}
		})
 		const sortedKeys=sortBy(Object.keys(grouped),function(key){//put leads in alhabetical order
 			if(key && key!=='undefined'){
 				return key.toString().toLowerCase()
 			} 
 		})
 		sortedKeys.forEach((key)=>{
 			const projectsForKey=grouped[key]
 			let sortedProjectsForKey
 			if(sortOrder=='alphabetical'){
				sortedProjectsForKey=sortBy(projectsForKey, function(project){
    			return project.name.toString().toLowerCase()
  			})
			}else if(sortOrder=='targetDate'){
				sortedProjectsForKey=orderBy(projectsForKey, [function (project) {
					if(project.target_date){
						return (moment(project.target_date))
					}else return null
				}, function (project) {
					return project.name.toString().toLowerCase()
				}], ['asc', 'asc'])
			}
			sortedAndGrouped.push(...sortedProjectsForKey)
   		})
		}else if(grouping=='status'){//started,plannes,backlog,completed
			const projectStates=['started','planned','backlog','completed']
			projectStates.forEach((state)=>{
				const projectsForState=filter(projects,{state:state})
				let sortedProjectsForState=[]
				if(sortOrder=='alphabetical'){
					sortedProjectsForState=sortBy(projectsForState, function(project){
      			return project.name.toString().toLowerCase()
    			})
				}else if(sortOrder=='targetDate'){
					sortedProjectsForState=orderBy(projectsForState, [function (project) {
						if(project.target_date){
							return (moment(project.target_date))
						}else return null
					}, function (project) {
						return project.name.toString().toLowerCase()
					}], ['asc', 'asc'])
				}
				sortedAndGrouped.push(...sortedProjectsForState)
			})
		}
		return sortedAndGrouped
	}

class RoadmapsHomeContainer extends Component {


	constructor(){
		super()		
		this.updateProjectPageSetting=this.updateProjectPageSetting.bind(this)
		this.updateShowAllCompleted=this.updateShowAllCompleted.bind(this)
		this.onScroll=this.onScroll.bind(this)		
		this.state = {
			scrollTop: null
		}
	}

	componentDidMount(){
		this.props.syncRoadmaps()	
		this.props.syncMilestones()
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Roadmaps Home" });
		}
	}

	onScroll(e){
		const el=document.getElementById('roadmapsHomeScrollContainer')		
		if(el){			
			this.setState({scrollTop:el.scrollTop})						
		}
	}

	updateProjectPageSetting(value){
		updateProjectPageSetting('allProjects',value)
		//scroll into view
		const allProjectsHeaderEl = document.getElementById('roadmapsHomePageProjectListHeader')	
		if(allProjectsHeaderEl){
			const allProjectsHeaderRect = allProjectsHeaderEl.getBoundingClientRect()
			const allProjectsHeaderTop = allProjectsHeaderRect.y + this.state.scrollTop - 50			
			const scrollableDivEl = document.getElementById('roadmapsHomeScrollContainer')	
			if(scrollableDivEl && scrollableDivEl.scrollTop > allProjectsHeaderTop){
				scrollableDivEl.scrollTop=allProjectsHeaderTop
			}
		}
	}

	updateShowAllCompleted(){
		const {projectPageSettings}=this.props
		let sortOrder='alphabetical'
		let grouping='status'
		let showAllCompleted=false
		if(projectPageSettings.allProjects){
			sortOrder=projectPageSettings.allProjects.sortOrder||'alphabetical'
			grouping=projectPageSettings.allProjects.grouping||'status'
			showAllCompleted=projectPageSettings.allProjects.showAllCompleted||false
		}
		const newValues={
			sortOrder:sortOrder,
			grouping:grouping,
			showAllCompleted:!showAllCompleted
		}
		updateProjectPageSetting('allProjects',newValues)
	}


	render() {
		const {roadmaps,projects,projectPageSettings}=this.props
		let sortOrder='alphabetical'
		let grouping='status'
		let showAllCompleted=false
		if(projectPageSettings.allProjects){
			sortOrder=projectPageSettings.allProjects.sortOrder||'alphabetical'
			grouping=projectPageSettings.allProjects.grouping||'status'
			showAllCompleted=projectPageSettings.allProjects.showAllCompleted||false
		}
		const filteredProjects=filterProjects(projects,showAllCompleted)
		const sortedProjects=getSortedGroupedProjects(filteredProjects,grouping,sortOrder)
		return (				
			<div id="roadmapsHomeScrollContainer" onScroll={this.onScroll} className={'roadmapsHomePageContainer '  + (this.props.sideMenuVisible ? ' roadmapsHomePageContainer--sideMenuState--visible ' : ' roadmapsHomePageContainer--sideMenuState--hidden ') + (window.isElectron ? 'roadmapsHomePageContainer--electron' : 'roadmapsHomePageContainer--web')}>
				<RoadmapsHomePage 
					roadmaps={roadmaps}
					projects={sortedProjects}
					sortOrder={sortOrder}
					grouping={grouping}
					scrollTop={this.state.scrollTop}
					completedProjectsCount={this.props.completedProjectsCount}
					updateShowAllCompleted={this.updateShowAllCompleted}
					updateSettings={this.updateProjectPageSetting}
					showAllCompleted={showAllCompleted}
					quarterRoadmap={this.props.quarterRoadmap}
					activeStatus={this.props.activeStatus}
					oldCompletedProjectsCount={this.props.oldCompletedProjectsCount}
				/>
			</div>				
			)		
	}
}

function mapStateToProps(state,ownProps) {
	let alphabeticalRoadmaps=sortBy(state.roadmaps, function(roadmap){
  	if(roadmap && roadmap.name){
  		return roadmap.name.toString().toLowerCase()
  	}
  })
	const completedProjectsCount=filter(state.projects,{state:'completed'}).length
	let oldCompletedProjects=filter(state.projects,function(project){
		if(project.state=='completed'){
			let dur=moment.duration( moment().diff(moment(project.completed_at)))
			const days=dur._data.days
			if(days>PROJECTS_CUTOFF){
				return project
			}
		}	
	})


	let quarterRoadmap 
	if(state.organization){
		quarterRoadmap=state.organization.quarter_roadmap
	}
	let activeStatus='planned'
	if(state.roadmapsHomePageSettings && state.roadmapsHomePageSettings.status){
		activeStatus=state.roadmapsHomePageSettings.status
	}
	return {
		roadmaps:alphabeticalRoadmaps,
		projects:state.projects,
		projectPageSettings:state.projectPageSettings,
		completedProjectsCount:completedProjectsCount,
		quarterRoadmap:quarterRoadmap,
		activeStatus:activeStatus,
		oldCompletedProjectsCount:oldCompletedProjects.length
	}
}

export default withRouter(connect(mapStateToProps,
	{	syncRoadmaps,syncMilestones
	}
)(RoadmapsHomeContainer))