import React from 'react'
import Icon from '../misc/Icon'
import VideoDuration from '../../utils/videoDuration'
import SpritesheetImage from '../misc/SpritesheetImage'
import ReactSlider from 'react-slider'
import {fetchStoryboardImageJSON} from '../../actions/video'


const defaultColors={
  speaker0:'green',
  speaker1:'blue',
  speaker2:'purple',
  speaker3:'orange',
  speaker4:'sky'
}


function calculateSpeakerTimelines(paragraphs,duration){
  let chunks={}
  // chunks.speaker0=[]
  // chunks.speaker1=[]
  if(paragraphs){
    paragraphs.forEach((paragraph)=>{
      let {start,end}=paragraph 
      const paraDuration=end-start 
      let chunk={
        speaker:paragraph.speaker,
        progress:start/duration,
        duration:paraDuration/duration
      }
      if(chunks[`speaker${paragraph.speaker}`]){
       chunks[`speaker${paragraph.speaker}`].push(chunk)
      }else{
        chunks[`speaker${paragraph.speaker}`]=[chunk]
      }
    })
  }
  return chunks
}

// // utility function to discard small durations
// function discardSmallDurations(data, threshold) {
//   return data.filter(speaking => speaking.duration > threshold);
// }

function processDurations(data, discardThreshold, minDuration) {
  return data
    .filter(speaking => speaking.duration >= discardThreshold) // Discard durations less than discardThreshold
    .map(speaking => {
      // Set a minimum duration for remaining durations
      if (speaking.duration < minDuration) speaking.duration = minDuration;
      return speaking;
    });
}

// utility function to merge adjacent durations with a gap less than a certain threshold
function mergeAdjacentDurations(data, gapThreshold) {
  let smoothedData = [];

  for (let i = 0; i < data.length; i++) {
    if (
      i > 0 &&
      data[i].speaker === data[i - 1].speaker &&
      (data[i].progress - (data[i - 1].progress + data[i - 1].duration)) < gapThreshold
    ) {
      // Merge the two durations
      smoothedData[smoothedData.length - 1].duration += (data[i].duration + data[i].progress - (data[i - 1].progress + data[i - 1].duration));
    } else {
      smoothedData.push(data[i]);
    }
  }

  return smoothedData;
}


class RecordingPlaybackModalMediaPlaybackControlsSpeakerTimelines extends React.Component{  

	 constructor(props){
    super(props)
    this.handleClickOnSpeakerChunk=this.handleClickOnSpeakerChunk.bind(this)
    this.state={
    
    }
  }

  handleClickOnSpeakerChunk(chunk){
    console.log('clicked on')
    console.log(chunk)
    // const duration = 2593.387333
    const start=chunk.progress * this.props.duration
    this.props.skipToSpeakerChunk(start)
  }

	render(){ 
    const {speakers,paragraphs,duration}=this.props 
    // console.log('speakers are ')
    // console.log(speakers)
    let timelines={}
    if(paragraphs){
      timelines=calculateSpeakerTimelines(this.props.paragraphs,this.props.duration)
    }
    
    // console.log('timelines are---------')
    // console.log(timelines)
    // const speaker1Speakings = [
    //   { progress: 0.2, duration: 0.04 },
    //   { progress: 0.5, duration: 0.08 },
    //   { progress: 0.8, duration: 0.12 },
    // ];
    // const speaker2Speakings = [
    //   { progress: 0, duration: 0.12 },
    //   { progress: 0.3, duration: 0.08 },
    //   { progress: 0.6, duration: 0.12 },
    // ];

    const speaker1Speakings = timelines.speaker0
    const speaker2Speakings = timelines.speaker1
    // speaker colors for internal people can come from their colors,
    // and then i guess we could give a random good one to any external people


    const discardThreshold = 0.001;
    const minDuration = 0.002;
    const gapThreshold = 0.005;
    

      


		return (
      <React.Fragment>      
      {speakers.map((speaker)=>{
        const transcript_speaker = speaker.transcript_speaker
        let speakings = timelines[`speaker${transcript_speaker}`]
        if(speakings){
          // Discard small durations and then merge adjacent durations
          speakings = processDurations(speakings, discardThreshold, minDuration);
          // this didn't work that
          speakings = mergeAdjacentDurations(speakings, gapThreshold);
          timelines[`speaker${transcript_speaker}`] = speakings; // update the timelines with smoothed data
          const color=defaultColors[`speaker${transcript_speaker}`]
          return(
           <div className='recordingPlaybackModal-media-playbackControls-speakerTimelineContainer'>
             <div className={'recordingPlaybackModal-media-playbackControls-speakerTimeline ' + (color ? ` recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--${color} ` : '')}>
              {speakings.map((speaking, index) => (
                <button
                  key={index}
                  onClick={()=>{this.handleClickOnSpeakerChunk(speaking)}}
                  style={{
                    left: `${speaking.progress * 100}%`,
                    width: `${speaking.duration * 100}%`,
                  }}
                  className='recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn'
                >
                  <div className='recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn-inner' />
                </button>
              ))}           
            </div>
          </div>
          )
        }
      })}

      


        {/* TESTING FOR NUMBER OF SPEAKERS 
        {Array.from({ length: this.props.numberOfSpeakers }).map((_, index) => (
          <div 
            key={index} 
            style={{height: `${this.props.heightSpeakerTimeline}px`}} 
            className='recordingPlaybackModal-media-playbackControls-speakerTimelineContainer'
          >
            <div className='recordingPlaybackModal-media-playbackControls-speakerTimeline' />
          </div>
        ))}
        */}


			{/*<div className={'recordingPlaybackModal-media-playbackControls-speakerTimeline ' + (speaker1Color ? ` recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--${speaker1Color} ` : '')}>
        {speaker1Speakings.map((speaking, index) => (
          <button
            key={index}
            onClick={()=>{this.handleClickOnSpeakerChunk(speaking)}}
            //onClick seeks to start of this segment
            style={{
              left: `${speaking.progress * 100}%`,
              width: `${speaking.duration * 100}%`,
            }}
            className='recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn'
          />
        ))}			
			</div>
      <div className={'recordingPlaybackModal-media-playbackControls-speakerTimeline ' + (speaker1Color ? ` recordingPlaybackModal-media-playbackControls-speakerTimeline--speakerColor--${speaker2Color} ` : '')}>
        {speaker2Speakings.map((speaking, index) => (
          <button
            key={index}
            onClick={()=>{this.handleClickOnSpeakerChunk(speaking)}}
            //onClick seeks to start of this segment
            style={{
              left: `${speaking.progress * 100}%`,
              width: `${speaking.duration * 100}%`,
            }}
            className='recordingPlaybackModal-media-playbackControls-speakerTimeline-speakerBtn'
          />
        ))}           
      </div>*/}
      </React.Fragment>
		)
	}
}

export default RecordingPlaybackModalMediaPlaybackControlsSpeakerTimelines
