import React from 'react'
import Icon from '../../misc/Icon'

class DocMediaZoomedSidePanel extends React.Component{  
 
  constructor(){
    super()
    this.state={
      // zoomed: false,  
      // mouseMoved:false
    }
  }  

	render(){ 

		const {title, description} = this.props
		// const imageTitle = 'Tabs Mockup'
		// const description = 'Here are a few different states on the Tabs mockup that we’re looking at.'

		// const imageTitle = null
		// const description = null
		
		return (
			<div style={{width: `${this.props.width}px`}} className='doc-mediaContainer-zoomedSidePanel'>
				<button onClick={this.props.toggleZoom} className='doc-mediaContainer-zoomedSidePanel-closeBtn'>
					<Icon name='cross-small' /> 
				</button>

				{(title || description) &&
					<div className='doc-mediaContainer-zoomedSidePanel-mediaInfoContainer'>
						{title &&
							<div className='doc-mediaContainer-zoomedSidePanel-title'>
								{title}
							</div>
						}
						{description &&
							<div className='doc-mediaContainer-zoomedSidePanel-description'>
								{description}
							</div>
						}

					</div>
				}

				<div className='doc-mediaContainer-zoomedSidePanel-commentsContainer'>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
					<div className='doc-mediaContainer-zoomedSidePanel-commentDummy'>COMMENT</div>
				</div>
				<div className='doc-mediaContainer-zoomedSidePanel-newCommentContainer'>
					<div className='doc-mediaContainer-zoomedSidePanel-newCommentDummy' />
				</div>

				
			</div>
		)
	}
}

export default DocMediaZoomedSidePanel
