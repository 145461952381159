import React from 'react'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import AvatarImage from '../../AvatarImage'
import * as Tooltip from '@radix-ui/react-tooltip'
import {getDocForId} from '../../../utils/getDocForId'
import {getProjectForId} from '../../../utils/getProjectForId'
import {getIssueForId} from '../../../utils/getIssueForId'
import {getUserForId} from '../../../utils/getUserForId'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'

class TextLinkHoverPopoverReactComponent extends React.Component{  	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)
    this.state={
      forceMount: false,      
    }
  }

  onRest() {	
		if(!this.props.showPopover){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

	render(){	
		const {showPopover, openPopover, closePopover, inverted} = this.props
		const {type,projectId,docId,issueId,url}=this.props
		const {forceMount} = this.state

		const linkType=type

		//
		// Project Link		
		let projectObj
		let projectName

		if(linkType === 'project' && projectId){
			projectObj = getProjectForId(projectId)
		}
		if(projectObj){
			projectName = projectObj.name
		}

		//
		// Doc Link		
		let docObj
		let docName

		if(linkType === 'doc' && docId){
			docObj = getDocForId(docId)
		}
		if(docObj){
			docName = docObj.name
		}

		//
		// Issue Link
		let issueObj
		let issueIdentifier
		let issueAssigneeId
		let issueAssigneeObj
		let issueAssigneeAvatarUrl		

		if(linkType === 'issue' && issueId){
			issueObj = getIssueForId(issueId)			
		}

		if(issueObj){
			issueIdentifier = issueObj.identifier
			issueAssigneeId = issueObj.assignee			
		}
		if(issueAssigneeId){			
			issueAssigneeObj = getUserForId(issueAssigneeId)
			issueAssigneeAvatarUrl = issueAssigneeObj.avatar			
		}


		
		
	  return (
	  	<React.Fragment>
	  		<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
					<Popover.Content
			    	className={'selectUserPopoverContainer selectUserPopoverContainer--manageToDoAssignment ' + (showPopover ? ' selectUserPopoverContainer--visible ' : ' selectUserPopoverContainer--hidden ')} 
			    	onEscapeKeyDown={closePopover}
			    	onMouseOver={this.props.openHoverMenu}
						onMouseLeave={this.props.closeHoverMenu}	 
				    onPointerDownOutside={closePopover}
				    onInteractOutside={closePopover}				    
				    side="bottom"
				    sideOffset={2}
				    forceMount={showPopover ? true : forceMount}
				    //asChild
			    >
				    <Transition
			        items={showPopover}
			        from={{ opacity: 0, transform: `translateY(-1px)` }}
			        enter={{ opacity: 1, transform: 'translateY(0px)'  }}
			        leave={{ opacity: 0, transform: `translateY(-1px)`  }}
			        reverse={showPopover}
			        config={{tension: 600, friction: 30}}
			        onRest={this.onRest}
			       >
			        {(styles, item) =>
			          item && 
			           <animated.div style={styles} className="doc-textLink-hoverMenu">										
										{linkType === 'weblink' && 
											<div className="doc-textLink-hoverMenu-link doc-textLink-hoverMenu-link--web">
												<div className="doc-textLink-hoverMenu-link--web-iconContainer">
													<Icon name='globe' />
												</div>
												<div className="doc-textLink-hoverMenu-link--web-url">
													{url}
												</div>						
											</div>
										}

										{linkType === 'project' && projectId && projectName && 
											<div className="doc-textLink-hoverMenu-link doc-textLink-hoverMenu-link--project">
												<div className="doc-textLink-hoverMenu-link--project-markerContainer">
													<LinearMarker projectId={projectId} />
												</div>
												<div className="doc-textLink-hoverMenu-link--project-name">
													{projectName}
												</div>						
											</div>
										}

										{linkType === 'doc' && docName && 
											<div className="doc-textLink-hoverMenu-link doc-textLink-hoverMenu-link--doc">
												<div className="doc-textLink-hoverMenu-link--doc-iconContainer">
													<Icon name='docSimple' />
												</div>
												<div className="doc-textLink-hoverMenu-link--doc-name">
													{docName}
												</div>						
											</div>
										}

										{linkType === 'issue' && issueIdentifier &&
											<div className="doc-textLink-hoverMenu-link doc-textLink-hoverMenu-link--issue">
												{issueAssigneeAvatarUrl && 
													<div className="doc-textLink-hoverMenu-link--issue-avatarContainer">
														<AvatarImage
															className='doc-textLink-hoverMenu-link--issue-avatar'
															avatarUrl={issueAssigneeAvatarUrl}
															smallAvatar
														/>
													</div>
												}
												<div className="doc-textLink-hoverMenu-link--issue-identifier">
													{issueIdentifier}
												</div>						
											</div>
										}

										<Tooltip.Provider>
											<Tooltip.Root delayDuration={0}>
												<Tooltip.Trigger asChild>
													<button className="doc-textLink-hoverMenu-editBtn" onClick={this.props.showEditMenuFromHoverMenu}>
														<Icon name='ellipses' />
													</button>			
												</Tooltip.Trigger>				
												<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI">																																	
													Edit Link							
												</Tooltip.Content>													
											</Tooltip.Root>
										</Tooltip.Provider>	

			          </animated.div>
			        }
			      </Transition> 										
			    </Popover.Content>
			    </Popover.Portal>
			  </Popover.Root>		
			</React.Fragment>
	  )
	}
}

export default TextLinkHoverPopoverReactComponent


{/* 												<button>edit</button>	
												<button onClick={this.props.removeLink}>delete link</button>				
*/}