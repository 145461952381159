import React from 'react'
import Icon from '../../misc/Icon'

class DocDrawingTopLeftMenu extends React.Component{  
 
  constructor(){
    super()
    this.state={
      
    }
  }  

	render(){ 		
		
		return (						
			<div className='doc-drawing-topLeftMenu'>
				<button onClick={this.props.edit} className='doc-image-topRightMenu-btn doc-image-topRightMenu-btn--iconAndLabel'>
					<div className='doc-image-topRightMenu-btn-iconContainer'>
						<Icon name='insertBarDrawing' />
					</div>
					<div className='doc-image-topRightMenu-btn-label'>
						Edit Drawing
					</div>
				</button>				
			</div>			
		)
	}
}

export default DocDrawingTopLeftMenu
