import React from 'react'

const raycastAutumnalPeachUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899194/Devices/backgroundImages/autumnal-peach_gnq5oy.png'
const raycastBlossomUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899191/Devices/backgroundImages/blossom_fvmmn8.png'
const raycastBlushingFireUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899196/Devices/backgroundImages/blushing-fire_mi0upz.png'
const raycastBrightRainUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899194/Devices/backgroundImages/bright-rain_xffwm6.png'
const raycastFlossUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899193/Devices/backgroundImages/floss_s2sjef.png'
const raycastGlassRainbowUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899190/Devices/backgroundImages/glass-rainbow_b07rjr.png'
const raycastGoodVibesUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899197/Devices/backgroundImages/good-vibes_bo0afi.png'
const raycastMoonriseUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899193/Devices/backgroundImages/moonrise_nwxclz.png'
const raycastRayOfLightsUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899277/Devices/backgroundImages/ray-of-lights_dish81.png'
const raycastRoseThornUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899196/Devices/backgroundImages/rose-thorn_yep95q.png'
const meshGreenBlueUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676899884/Devices/backgroundImages/mesh-gradient_w6qmce.png'
const richBlueSpotlightUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676900723/Devices/backgroundImages/richBlueSpot_aktd8f.png'

const montereyLightUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899922/Devices/backgroundImages/montereyLight_jqm2vg.png'
const montereyDarkUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899918/Devices/backgroundImages/montereyDark_pyyaww.png'

const venturaLightUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675900095/Devices/backgroundImages/venturaLight_oy5ayc.png'
const venturaDarkUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675900095/Devices/backgroundImages/venturaDark_bwl2hy.png'


class FigmaViewBackgroundImage extends React.Component{  
	constructor(props){
    super(props)
    this.onStorage=this.onStorage.bind(this)
    let theme='light'
		const localStorageTheme = localStorage.getItem('theme')
		if(localStorageTheme){
			theme=localStorageTheme
		}
    this.state={
    	theme:theme
    }
  }

  componentDidMount(){
  	window.addEventListener('storage',this.onStorage)
  }

	componentWillUnmount(){
		window.removeEventListener('storage', this.onStorage)
	}
  
  onStorage(event){
  	let theme='light'
		const localStorageTheme = localStorage.getItem('theme')
		if(localStorageTheme){
			theme=localStorageTheme
		}
		this.setState({theme:theme})
  }



  
	render(){ 
		const {backgroundImage, scale}=this.props	
		const {theme} = this.state
		
		let montereyUrl = montereyLightUrl
		if(theme === 'dark'){
			montereyUrl = montereyDarkUrl
		}

		let venturaUrl = venturaLightUrl
		if(theme === 'dark'){
			venturaUrl = venturaDarkUrl
		}

		const borderRadius = 5

		return (			
			
			
			<div onClick={this.props.handleBackgroundImageClickOnView} className={'figmaView-backgroundImageContainer ' + (backgroundImage ? ' figmaView-backgroundImageContainer--hasBackground ' : ' figmaView-backgroundImageContainer--noBackground')}>
				<div className={'figmaView-backgroundImage ' + (backgroundImage ? `figmaView-backgroundImage--${backgroundImage}` : '')}>
					
					{backgroundImage === 'raycastAutumnalPeach' && 
						<img src={raycastAutumnalPeachUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastBlossom' && 
						<img src={raycastBlossomUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastBlushingFire' && 
						<img src={raycastBlushingFireUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastBrightRain' && 
						<img src={raycastBrightRainUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastFloss' && 
						<img src={raycastFlossUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastGlassRainbow' && 
						<img src={raycastGlassRainbowUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastGoodVibes' && 
						<img src={raycastGoodVibesUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastMoonrise' && 
						<img src={raycastMoonriseUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastRayOfLights' && 
						<img src={raycastRayOfLightsUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'raycastRoseThorn' && 
						<img src={raycastRoseThornUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'monterey' && 
						<img src={montereyUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'ventura' && 
						<img src={venturaUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'meshGreenBlue' && 
						<img src={meshGreenBlueUrl} className='figmaView-backgroundImage-img' />
					}

					{backgroundImage === 'richBlueSpotlight' && 
						<img src={richBlueSpotlightUrl} className='figmaView-backgroundImage-img' />
					}

				</div>	
			</div>


			
						
		)
	}
}

export default FigmaViewBackgroundImage
