import store from '../store'
import find from 'lodash/find'



export function getTeamForId(teamId) {	
	let team={}
	const state = store.getState()
	const reduxTeam= find(state.teams, {'team_id':teamId})
	if(reduxTeam){
		team=reduxTeam
	}
	return team
}

