import React from 'react'
import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import LinearMilestoneIcon from '../misc/LinearMilestoneIcon'
import LinearMarker from '../misc/LinearMarker'
import AvatarImage from '../AvatarImage'
import {getUserForId} from '../../utils/getUserForId'
import * as Tooltip from '@radix-ui/react-tooltip'
import LinearIssuePriorityIcon from '../misc/LinearIssuePriorityIcon'
import LinearIssueWorkflowStateIcon from '../misc/LinearIssueWorkflowStateIcon'
import {getProjectForId} from '../../utils/getProjectForId'
import {getProjectIssuesByType,getMeetingIssuesByType} from '../../utils/getTeamProjectIssues'
import {openIssueInLinear} from '../../utils/openInLinear'
import {openProjectInLinear} from '../../utils/openInLinear'
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import {getMilestoneForId} from '../../utils/getMilestoneForId'
import LinearIssuePreviewPopoverContent from '../docEditor/mentionPopovers/LinearIssuePreviewPopoverContent'
import {calcStatsForMilestone} from '../../utils/calcStatsForMilestone'
import {getIssuePanelIssuesForUser} from '../../utils/issues/getIssuePanelIssuesForUser'
import {getIssuePanelIssuesForProject} from '../../utils/issues/getIssuePanelIssuesForProject'
import {projectHasMilestones} from '../../utils/projectHasMilestones'
import {openUserProfileInLinear} from '../../utils/openInLinear'
import {getIssuesForUser} from '../../utils/issues/getIssuesForUser'

class DocIssuePanelNewIssue extends React.Component{  
  render(){  
    const {type} = this.props 
    return (            
      <div className='docEditor-issuePanel-newIssue'>
        <div className='docEditor-issuePanel-newIssue-iconContainer'>
          <Icon name='plus' />
        </div>
        {type === 'todo' &&
          <div className='docEditor-issuePanel-newIssue-label'>
            New To Do Issue
          </div>
        }
        {type === 'backlog' &&
          <div className='docEditor-issuePanel-newIssue-label'>
            New Backlog Issue
          </div>
        }
      </div>                  
    )
  }
}

class DocIssuePanelIssue extends React.Component{  
  render(){   
    const {statusType, priority, label, identifier, assigneeID,workflowState, issue} = this.props
    const user = getUserForId(`${assigneeID}`)   
    let assignee        
    let workflowType
    let title
    let workflowColorHex
    let workflowStateName
    let description
    let projectId
    if(issue){
      title=issue.title
      assignee=issue.assignee            
      projectId = issue.project
      if(issue.workflow_state){
        workflowColorHex=issue.workflow_state.color
        workflowType=issue.workflow_state.type
        workflowStateName=issue.workflow_state.name
      } 
      description = issue.description
    }
    const assigneeObj=getUserForId(assignee)
    let avatar
    if(assigneeObj){
      avatar=assigneeObj.avatar
    }
    return (            
      <Tooltip.Provider>
          <Tooltip.Root delayDuration={100}>
            <Tooltip.Trigger asChild>                                               
              <div onClick={()=>{openIssueInLinear(identifier)}} className={'docEditor-issuePanel-issue ' + (statusType ? `docEditor-issuePanel-issue--status--${statusType}` : '')}>
                                                     
                  {this.props.userIssuePanel && 
                    <div className='docEditor-issuePanel-issue-priorityContainer docEditor-issuePanel-issue-priorityContainer--user'>
                      <LinearIssuePriorityIcon 
                        priority={priority}          
                      />
                    </div>        
                  }
                  {!this.props.userIssuePanel && 
                    <div className='docEditor-issuePanel-issue-assigneeIDAvatarContainer'>          
                      <AvatarImage
                        className='docEditor-issuePanel-issue-assigneeIDAvatar'
                        avatarUrl={avatar}
                      />      
                    </div>
                  }
                  <div className='docEditor-issuePanel-issue-statusIconContainer'>
                    <LinearIssueWorkflowStateIcon 
                      workflowColorHex={workflowState.color} // need this but difficult to provide fake data
                      workflowType={workflowState.type}
                      workflowTypeStateIndex={workflowState.workflowTypeStateIndex}
                      workflowTypeStatesCount={workflowState.workflowTypeStatesCount}
                    />          
                  </div>
                  <div className='docEditor-issuePanel-issue-label'>
                    {title}
                  </div>                    
                  <div className='docEditor-issuePanel-issue-identifier'>                    
                    {identifier}
                  </div>

                  {!this.props.userIssuePanel && 
                    <div className='docEditor-issuePanel-issue-priorityContainer'>
                      <LinearIssuePriorityIcon 
                        priority={priority}          
                      />
                    </div>        
                  }
                </div>     
            </Tooltip.Trigger>        
            <Tooltip.Content side="left" sideOffset={5} align="start" className="docEditor-issuePanel-issue-tooltip">
            {issue && 
              <LinearIssuePreviewPopoverContent
                projectId={issue.projectId}
                //project={project}                   
                identifier={issue.identifier}
                title={issue.title}
                assignee={assigneeObj}
                workflowColorHex={workflowState.color} // need this but difficult to provide fake data
                workflowType={workflowState.type}
                workflowTypeStateIndex={workflowState.workflowTypeStateIndex}
                workflowTypeStatesCount={workflowState.workflowTypeStatesCount}
                workflowStateName={workflowState.name}
                priority={priority}                   
                description={issue.description}
              />          
            }        
            </Tooltip.Content>                          
          </Tooltip.Root>
        </Tooltip.Provider>
    )
  }
}


class DocIssuePanelStatusDivider extends React.Component{  
  render(){   
    return (            
      <div className='docEditor-issuePanel-statusDivider'>
        {this.props.label}
      </div>                  
    )
  }
}


class DocIssuePanelMilestoneDivider extends React.Component{  
  render(){   
    const {milestone,issueCount,isCollapsed} = this.props
    let unassignedToMilestone = false // only displayed here AFAIK

    const collapsed = isCollapsed 

    return (              
      <div onClick={this.props.toggleHideMilestone} className='docEditor-issuePanel-milestoneDivider'>
        <div className='docEditor-issuePanel-milestoneDivider-iconContainer'>
         {milestone &&
          <LinearMilestoneIcon
            milestone={milestone}
          />
        }
        </div>        
        <div className='docEditor-issuePanel-milestoneDivider-name'>
          {milestone.name}
        </div>              
        <div className='docEditor-issuePanel-milestoneDivider-totalIssueCount'>
          – {issueCount}
        </div>
        <div className={'docEditor-issuePanel-milestoneDivider-collapseStateLabelContainer ' + (collapsed ? 'docEditor-issuePanel-milestoneDivider-collapseStateLabelContainer--show' : ' docEditor-issuePanel-milestoneDivider-collapseStateLabelContainer--hide ')}>
          {collapsed && 
            <div className='docEditor-issuePanel-milestoneDivider-collapseStateLabel'>
              Show
            </div>
          }
          {!collapsed && 
            <div className='docEditor-issuePanel-milestoneDivider-collapseStateLabel'>
              Hide
            </div>
          }
        </div>
      </div>     
      
    )
  }
}


class DocIssuePanel extends React.Component{  

  constructor(props){
    super(props)
    this.toggleCollapseMilestone=this.toggleCollapseMilestone.bind(this)
    this.state={    
      collapsedMilestones:{}
    }
  }

  toggleCollapseMilestone(milestoneId){
    let newCollapsedMilestones={...this.state.collapsedMilestones}
    if(newCollapsedMilestones[milestoneId]){
      const newCollapsedState=!newCollapsedMilestones[milestoneId]
      newCollapsedMilestones[milestoneId]=newCollapsedState
    }else{
      newCollapsedMilestones[milestoneId]=true
    }
    this.setState({collapsedMilestones:newCollapsedMilestones})
  }

	render(){	
    const {project, milestoneId,meeting,issuePanelProject,issuePanelUser} = this.props
    const isMeetingDoc=meeting?true:false 
    const {collapsedMilestones} = this.state
    let hasMultipleProjects=false
    let projects=[project]
    //meetings and user panels have multiple projects
    let groupedIssuesAllProjects
    let urgentIssueCount //for user panels
    let highIssueCount 
    if(issuePanelUser){
      groupedIssuesAllProjects=getIssuePanelIssuesForUser(issuePanelUser)
      hasMultipleProjects=true
      projects=Object.keys(groupedIssuesAllProjects)
      const priorityIssues=getIssuesForUser(issuePanelUser)
      urgentIssueCount=priorityIssues.urgent.length 
      highIssueCount=priorityIssues.high.length

    }else{
      if(issuePanelProject){
        groupedIssuesAllProjects=getIssuePanelIssuesForProject(issuePanelProject)
        projects=[issuePanelProject]
        //hasMultipleProjects=true
      }else{
        groupedIssuesAllProjects=getIssuePanelIssuesForProject(project)
      }
    }
          
    const projectObj=getProjectForId(project)
    let projectSlug
    if(projectObj){
      projectSlug=projectObj.slug_id
    }
    // milestone-only issue panel, accessible from milestone subdoc header
    const isMilestoneDoc = milestoneId?true:false

    let milestone 
    if(isMilestoneDoc){
      milestone=getMilestoneForId(milestoneId)
    }

    //
    //
    const userIssuePanel = issuePanelUser
    let issuePanelUserObj
    let issuePanelUserDisplayName
    let issuePanelUserAvatar 
    if(issuePanelUser){
      issuePanelUserObj = getUserForId(issuePanelUser)
      if(issuePanelUserObj){
        issuePanelUserDisplayName = issuePanelUserObj.display_name
        issuePanelUserAvatar = issuePanelUserObj.avatar
      }
    }

    // Meeting Project for Issue Panel
    let isMeetingProject = false
    let meetingProjectObj
    let meetingProjectName
    if(issuePanelProject){
      isMeetingProject=true
      meetingProjectObj = getProjectForId(issuePanelProject)
      if(meetingProjectObj){
        meetingProjectName = meetingProjectObj.name
      }
    }

	  return (	  	
      <div className={'docEditor-issuePanelContainer ' + (this.props.independentLayout ? 'docEditor-issuePanelContainer--independentLayout' : '')}>  
  		  <div className='docEditor-issuePanel'>
          <div className='docEditor-issuePanel-header'>                      
            {!userIssuePanel &&
              <Tooltip.Provider>
                <Tooltip.Root delayDuration={0}>
                  <Tooltip.Trigger asChild>                                  
                    <button onClick={()=>{openProjectInLinear(projectSlug)}} className='docEditor-issuePanel-header-openLinearBtn'>
                      <Icon name='linkArrow' />
                    </button>
                  </Tooltip.Trigger>        
                  <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
                    Open Project in Linear
                    {!isMeetingDoc &&
                      <KeyboardShortcutTag                  
                        shift
                        command                  
                        letter='L'
                      />
                    }   
                    </Tooltip.Content>                          
                </Tooltip.Root>
              </Tooltip.Provider>
            }
            {userIssuePanel && issuePanelUserAvatar && issuePanelUserDisplayName &&
              <Tooltip.Provider>
                <Tooltip.Root delayDuration={0}>
                  <Tooltip.Trigger asChild>                                  
                    <button onClick={()=>{openUserProfileInLinear(issuePanelUserDisplayName)}} className='docEditor-issuePanel-header-openLinearBtn docEditor-issuePanel-header-openLinearBtn--avatar'>
                      <div className='docEditor-issuePanel-header-openLinearBtn-avatarContainer'>
                        <AvatarImage 
                          className='docEditor-issuePanel-header-openLinearBtn-avatar'
                          avatarUrl={issuePanelUserAvatar} 
                        />
                      </div>
                    </button>
                  </Tooltip.Trigger>        
                  <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
                    Open Profile in Linear                              
                    </Tooltip.Content>                          
                </Tooltip.Root>
              </Tooltip.Provider>
            }



            <div className='docEditor-issuePanel-header-label'>
              {!isMeetingProject && !userIssuePanel &&
                <div className='docEditor-issuePanel-header-label-primary'>
                  Issues
                </div>                
              }           
              {userIssuePanel && issuePanelUserDisplayName &&
                <React.Fragment>
                  <div className='docEditor-issuePanel-header-label-project'>                    
                    <div className='docEditor-issuePanel-header-label-project-title'>
                       <span className='docEditor-issuePanel-header-label-project-title-bold'>{issuePanelUserDisplayName}</span>
                    </div>                  
                  </div>       
                </React.Fragment>         
              }
              {isMeetingProject && meetingProjectObj &&
                <div className='docEditor-issuePanel-header-label-project'>
                  {/*
                  <div className='docEditor-issuePanel-header-label-primary-markerContainer'>
                    <LinearMarker projectId={meetingProjectId} />
                  </div>
                  */}
                  <div className='docEditor-issuePanel-header-label-project-title'>
                    {meetingProjectName} <span className='docEditor-issuePanel-header-label-project-title-light'>Issues</span>
                  </div>                  
                </div>                
              }           
              {isMilestoneDoc &&
                <div className='docEditor-issuePanel-header-label-milestone'>
                  <div className='docEditor-issuePanel-header-label-milestone-iconContainer'>
                    <LinearMilestoneIcon
                      milestone={milestone}
                    />
                  </div>                
                  <div className='docEditor-issuePanel-header-openLinearBtn-milestone-label'>        
                    {milestone?milestone.name:''}
                  </div>
                </div>
              }
            </div>

            {userIssuePanel &&
              <div className='docEditor-issuePanel-header-issueCounter'>
                <div className='docEditor-issuePanel-header-issueCounter-counter docEditor-issuePanel-header-issueCounter-counter--urgent'>
                  <div className='docEditor-issuePanel-header-issueCounter-counter-iconContainer'>
                    <LinearIssuePriorityIcon priority={1} />
                  </div>
                  <div className='docEditor-issuePanel-header-issueCounter-counter-label'>
                    {urgentIssueCount}
                  </div>
                </div>
                <div className='docEditor-issuePanel-header-issueCounter-counter docEditor-issuePanel-header-issueCounter-counter--urgent'>
                  <div className='docEditor-issuePanel-header-issueCounter-counter-iconContainer'>
                    <LinearIssuePriorityIcon priority={2} />
                  </div>
                  <div className='docEditor-issuePanel-header-issueCounter-counter-label'>
                    {highIssueCount}
                  </div>
                </div>
              </div>
            }

            <button onClick={()=>{this.props.closeIssuePanel()}} className='docEditor-issuePanel-header-closeBtn'>
              <div className='docEditor-issuePanel-header-closeBtn-inner'>
                <Icon name='cross-small'/>
              </div>                  
            </button>
          </div>                  
          <div className='docEditor-issuePanel-list'>

          {projects.map((projectId)=>{ //with user panel can have multiple projects so show a project name header
            const groupedIssues = groupedIssuesAllProjects[projectId]
            const {groupedIssuesArray,totalCount}=groupedIssues
            const noIssues = totalCount < 1  
            const showMilestoneLabels=projectHasMilestones(projectId)
            let title=''
            if(hasMultipleProjects){
              if(projectId=='none'){
                title='Issues Not In Project'
              }else{
                const projectObj=getProjectForId(projectId)
                if(projectObj){
                  title=projectObj.name
                }
              }
            }
            return(
              <React.Fragment>
                {hasMultipleProjects && projectId !== 'none' &&
                  <div className='docEditor-issuePanel-projectDivider'>
                    <div className='docEditor-issuePanel-projectDivider-markerContainer'>
                      <LinearMarker projectId={projectId} />
                    </div>
                    <div className='docEditor-issuePanel-projectDivider-label'>
                      {title}
                    </div>
                  </div>
                }
                {hasMultipleProjects && projectId=='none' &&
                  <div className='docEditor-issuePanel-projectDivider'>
                    <div className='docEditor-issuePanel-projectDivider-label docEditor-issuePanel-projectDivider-label--none'>
                      No Project
                    </div>
                  </div>
                }

                {noIssues && !isMilestoneDoc && 
                  <div className='docEditor-issuePanel-list-emptyState'>
                    No issues for this project yet
                  </div>
                }
                {noIssues && isMilestoneDoc && 
                  <div className='docEditor-issuePanel-list-emptyState'>
                    No issues for this milestone yet
                  </div>
                }
{/*              {noIssues && isMeetingDoc && 
                <div className='docEditor-issuePanel-list-emptyState'>
                  No issues for this meeting yet
                </div>
              }*/}
              {!noIssues && groupedIssuesArray.map((milestoneWithIssues)=>{
                if(!isMilestoneDoc || (isMilestoneDoc && milestoneWithIssues.milestone.milestone_id==milestoneId)){ 
                  const {milestone,issueCount}=milestoneWithIssues
                  const milestoneIssues=milestoneWithIssues.issues
                  const isCollapsed=collapsedMilestones[milestone.milestone_id]
                  //for user issue panel don't show milestone headers if there are no issues in them 
                  if(!issuePanelUser || issueCount>0 ){
                    return(
                      <React.Fragment>
                        {milestone!='none' && projectId !=='none' && showMilestoneLabels && !userIssuePanel &&
                          <DocIssuePanelMilestoneDivider milestone={milestone} issueCount={issueCount} isCollapsed={isCollapsed} toggleHideMilestone={()=>{this.toggleCollapseMilestone(milestone.milestone_id)}}/>
                        }
                        {milestone!='none' && issueCount  == 0 && !isCollapsed && showMilestoneLabels && !userIssuePanel &&
                          <div className='docEditor-issuePanel-noIssuesInMilestoneLabel'>
                            No issues in this milestone
                          </div>
                        }
                        {milestone=='none' && issueCount  > 0 && projectId!=='none' && showMilestoneLabels && !userIssuePanel &&
                          <div className='docEditor-issuePanel-milestoneDivider'>
                            <div className='docEditor-issuePanel-milestoneDivider-iconContainer'>
                             {milestone &&
                              <LinearMilestoneIcon
                                unassignedToMilestone
                              />
                            }
                            </div>        
                            <div className='docEditor-issuePanel-milestoneDivider-name'>
                              No milestone
                            </div>              
                            <div className='docEditor-issuePanel-milestoneDivider-totalIssueCount'>
                              – {issueCount}
                            </div>                    
                          </div>     
                        }
                        {Object.keys(milestoneIssues).map((workflowState)=>{
                          const issues=milestoneIssues[workflowState]   
                          if(!isCollapsed && issues.length>0){  //don't show header for empty workfow states
                            return(
                              <React.Fragment key={workflowState}>
                                {!milestone && <DocIssuePanelStatusDivider label={workflowState} issueCount={Object.keys(milestoneIssues).length}/> }
                                  {issues.map((issue)=>{
                                   return(
                                      <DocIssuePanelIssue 
                                        key={issue.issue_id}
                                        statusType={issue.state.type}
                                        priority={parseInt(issue.priority)}
                                        label={issue.title}
                                        identifier={issue.identifier}
                                        assigneeID={issue.assignee}
                                        workflowState={issue.state}
                                        projectSlug={projectSlug}
                                        issue={issue}
                                        userIssuePanel={userIssuePanel}
                                      />     
                                    )
                                  })
                                }
                            {!milestone && <div className='docEditor-issuePanel-list-lastIssueSpacer' /> }
                        </React.Fragment>
                      )   
                    }else return null          
                    })}
                </React.Fragment>
              )}
            }})}
              </React.Fragment>
              )
          })}
          </div>
        </div>            
      </div>
	  )
	}
}

function mapStateToProps(state,ownProps) {
  return {
    projectMilestones:state.projectMilestones,
  }
}

export default connect(
  mapStateToProps,
  {})(DocIssuePanel)


      