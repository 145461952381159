import {Plugin} from "prosemirror-state"
import {TextSelection} from "prosemirror-state"


function adjustSelectionToWordBoundaries(view, event) {
  const { state } = view;
  const { doc, selection } = state;
  if (selection.empty) return false;

  let { from, to } = selection;

  // Resolve the positions to handle nested nodes
  let $from = doc.resolve(from), $to = doc.resolve(to);

  // If the start of the selection is in a 'transcriptSpace', move it forward to the next 'transcriptWord'
  if ($from.node().type.name === 'transcriptSpace') {
    // Move the start of the selection forward until we hit a 'transcriptWord' or reach the end of the selection
    while ($from.pos < to && $from.node().type.name !== 'transcriptWord') {
      $from = doc.resolve($from.pos + 1);
    }
  } else {
    // Otherwise, move the start of the selection to the start of its 'transcriptWord'
    while ($from.nodeBefore && $from.nodeBefore.type.name !== 'transcriptSpace') {
      $from = doc.resolve($from.pos - 1);
    }
  }

  // If the end of the selection is in a 'transcriptSpace', move it backward to the previous 'transcriptWord'
  if ($to.nodeBefore && $to.nodeBefore.type.name === 'transcriptSpace') {
    // Move the end of the selection backward until we hit a 'transcriptWord' or reach the start of the selection
    while ($to.pos > $from.pos && $to.nodeBefore && $to.nodeBefore.type.name !== 'transcriptWord') {
      $to = doc.resolve($to.pos - 1);
    }
  } else {
    // Otherwise, move the end of the selection to the end of its 'transcriptWord'
    while ($to.nodeAfter && $to.nodeAfter.type.name !== 'transcriptSpace') {
      $to = doc.resolve($to.pos + 1);
    }
  }

  // Check if we need to adjust the selection
  if ($from.pos !== from || $to.pos !== to) {
    const tr = state.tr.setSelection(TextSelection.create(state.doc, $from.pos, $to.pos));
    view.dispatch(tr);
    return true;
  }

  return false;
}



// function adjustSelectionToWordBoundaries(view, event) {
//   const { state } = view;
//   const { doc, selection } = state;
//   if (selection.empty) return false;

//   let { from, to } = selection;


//   // Resolve the positions to handle nested nodes
//   let $from = doc.resolve(from), $to = doc.resolve(to);

//   console.log(`initial $from----- `)
//   console.log($from)


//   // Move the start of the selection to the start of its 'transcriptWord'
//   while ($from.nodeBefore && $from.nodeBefore.type.name !== 'transcriptSpace') {
//     $from = doc.resolve($from.pos - 1);
//   }

//   // Move the end of the selection to the end of its 'transcriptWord'
//   while ($to.nodeAfter && $to.nodeAfter.type.name !== 'transcriptSpace') {
//     $to = doc.resolve($to.pos + 1);
//   }


//   console.log(`new $from---- `)
//   console.log($from)
//   // Check if we need to adjust the selection
//   if ($from.pos !== from || $to.pos !== to) {
//     const tr = state.tr.setSelection(TextSelection.create(state.doc, $from.pos, $to.pos));
//     view.dispatch(tr);
//     return true;
//   }

//   return false;
// }


export const wordSnapPlugin = new Plugin({
  props: {
    handleDOMEvents: {
      mouseup: adjustSelectionToWordBoundaries
    }
  }
});


///Old approach using text (now use word nodes)
// function adjustSelectionToWordBoundaries(view, event) {
//   const { state } = view;
//   const { selection } = state;

//   if (selection && !selection.empty) {
//     let { from, to } = selection;

//     let fromIndex = Math.max(0, from - 50);
//     console.log(state.doc)
//     console.log(state.doc.nodeSize)

//     let toIndex = Math.min(to + 50, state.doc.nodeSize-2);
//     console.log(`toindex------- ${toIndex}`)
//     console.log('here####')

//     let beforeStart = state.doc.textBetween(fromIndex, from, ' ', '\n');
//     console.log('heres####2')
//     let afterEnd = state.doc.textBetween(to, toIndex, ' ', '\n');

//     console.log('here###3')
//     console.log(`before start---- ${beforeStart}`)
//     console.log(`after end---- ${afterEnd}`)

//     //let beforeStart = state.doc.textBetween(Math.max(0, from - 50), from, ' ', '\n');
//     //let afterEnd = state.doc.textBetween(to, to + 50, ' ', '\n');

//     let startDiff = beforeStart.length - beforeStart.lastIndexOf(' ') - 1;
//     let endDiff = afterEnd.indexOf(' ');

//     if (endDiff === -1) {
//       endDiff = afterEnd.length;
//     } else {
//       endDiff += 1; // include the space in the selection
//     }

//     if (startDiff !== 0 || endDiff !== 0) {
//       from -= startDiff;
//       to += endDiff;
//       if (from < 0) from = 0;
//       if (to > state.doc.content.size) to = state.doc.content.size;

//       const tr = state.tr.setSelection(TextSelection.create(state.doc, from, to));
//       view.dispatch(tr);
//       return true;
//     }
//   }

//   return false;
// }


// export const wordSnapPlugin = new Plugin({
//   props: {
//     handleDOMEvents: {
//       mouseup: adjustSelectionToWordBoundaries
//     }
//   }
// });

// export default wordSnapPlugin
// function snapSelectionToWords(view,event) {
//   const state=view.state
//   const dispatch=view.dispatch
//   const {selection} = state;
//   if (selection && !selection.empty) {
//     let selText = state.doc.textBetween(selection.from, selection.to);
//     // Adjust the start and end of the selection if they're in the middle of a word
//     let start = selection.from, end = selection.to;
//     if (!selText.startsWith(' ')) {
//       let startOfWord = selText.search(/\s/);
//       if (startOfWord !== -1) {
//         start += startOfWord;
//       }
//     }
//     if (!selText.endsWith(' ')) {
//       let endOfWord = selText.lastIndexOf(' ');
//       if (endOfWord !== -1) {
//         end = selection.from + endOfWord;
//       }
//     }
//     if (start !== selection.from || end !== selection.to) {
//       // Create a new selection that snaps to word boundaries
//       let newSelection = TextSelection.create(state.doc, start, end);
//       // Apply the new selection as a transaction
//       dispatch(state.tr.setSelection(newSelection));
//       return true;
//     }
//   }

//   return false;
// }