import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {
	
    case 'SIGN_OUT':
      return []
   
    case 'CREATE_WHITEBOARD_EMBED_REQUEST':
    	const newEmbed={
    		embed_id:action.embedId,
        snapshot:action.snapshot,
        display_dimensions:action.displayDimensions
    	}
      return [...state,newEmbed]

    case 'UPDATE_WHITEBOARD_EMBED_REQUEST':
      let index = findIndex(state,(embed => {
        return embed.embed_id === action.embedId
      }))
      if(index>-1){
        const oldEmbed=state[index]
        let updatedEmbed={...oldEmbed}
        updatedEmbed.display_dimensions=action.displayDimensions 
        updatedEmbed.snapshot=action.snapshot 
        return [
          ...state.slice(0,index),
            updatedEmbed,
          ...state.slice(index + 1)
        ]
      }else return state


    case 'FETCH_A_WHITEBOARD_EMBED_SUCCESS':
      let indx = findIndex(state,(embed => {
        return embed.embed_id === action.response.embed_id
      }))
      if(indx === -1){
        indx=state.length
      }
      return [
       ...state.slice(0,indx),
        action.response,
        ...state.slice(indx + 1)
      ]


    case 'FETCH_WHITEBOARD_EMBEDS_SUCCESS':
      return action.response

    default:
      return state;
	}
}

