import store from '../../store'
import { startOfDay,toISOString } from 'date-fns';
import {getProjectForId} from '../getProjectForId'
import {updateProject} from './updateProject'

function getDateWithoutTimezoneOffset(date) {
  const offset = date.getTimezoneOffset();
  const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
  return adjustedDate;
}

export function updateProjectTargetDate(projectId,targetDate){
	const oldProject=getProjectForId(projectId)
	let newProject={...oldProject}
	if(targetDate){
		const adjustedDate=getDateWithoutTimezoneOffset(targetDate)
		const formattedDate = adjustedDate.toISOString().split('T')[0];
		newProject.target_date=formattedDate
	}else{
		newProject.target_date=null
	}

	updateProject(newProject)
}


export function updateProjectTargetDateWithCb(projectId,startDate,targetDate){
	const oldProject=getProjectForId(projectId)
	let newProject={...oldProject}

	if(startDate){
		const adjustedStartDate=getDateWithoutTimezoneOffset(startDate)
		const formattedStartDate = adjustedStartDate.toISOString().split('T')[0];
		newProject.start_date=formattedStartDate
	}
	if(targetDate){
		const adjustedTargetDate=getDateWithoutTimezoneOffset(targetDate)
		const formattedTargetDate = adjustedTargetDate.toISOString().split('T')[0];
		newProject.target_date=formattedTargetDate
	}

	return updateProject(newProject)
}


