import React from 'react'
import * as Dialog from '@radix-ui/react-dialog';
import { Transition, animated, config } from 'react-spring'
import RecordingPlaybackModal from './RecordingPlaybackModal'

class RecordingPlaybackModalContainer extends React.Component{  	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this) 
    this.handleEscapeKey=this.handleEscapeKey.bind(this)   
    this.state={
      forceMount: false,
      isResizeDragging: false,
    }
  }

  onRest() {	
		if(!this.props.showModal){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

	handleEscapeKey(){
		const el=document.getElementById('speaker-identify-panel')
		//check if speaker identify panel is open.  if not then close modal
		if(!el){
			this.props.closeModal()
		}
	}

	render(){	
		const {showModal, openModal, closeModal} = this.props
		const {forceMount, isResizeDragging} = this.state		
		let modalTransformStart = 'scale(0.95)'
		// let modalTransformStart = 'scale(1)'
		let modalTransformEnd = 'scale(1)'		
		const sideMarginRecordingPlaybackModal = 20
		const initialModalWidth = Math.min((window.innerWidth - sideMarginRecordingPlaybackModal - sideMarginRecordingPlaybackModal), 1700)
		const maxTranscriptWidth = 840
		const minTranscriptWidth = 300
		const initialTranscriptWidthProportion = 0.45
		const initialTranscriptWidth = Math.min(Math.max(initialTranscriptWidthProportion * initialModalWidth, minTranscriptWidth), maxTranscriptWidth);

	  return (
	  	<React.Fragment>
	  		<Dialog.Root>
					<Dialog.Trigger asChild>
						{this.props.children}
					</Dialog.Trigger>
					<Dialog.Portal
						forceMount={showModal ? true : forceMount}						
					>											
						<Dialog.Content
				    	className={'forceDarkTheme recordingPlaybackModalContainer ' + (showModal ? ' recordingPlaybackModalContainer--visible ' : ' recordingPlaybackModalContainer--hidden ') + (isResizeDragging ? ' recordingPlaybackModalContainer--isResizeDragging ' : ' ')} 
				    	onEscapeKeyDown={this.handleEscapeKey}
					    onPointerDownOutside={closeModal}
					    onInteractOutside={closeModal}
					    forceMount={showModal ? true : forceMount}					    
				    >
					    <Transition
				        items={showModal}
				        from={{ opacity: 0, transform: `${modalTransformStart}` }}
				        enter={{ opacity: 1, transform: `${modalTransformEnd}`  }}
				        leave={{ opacity: 0, transform: `${modalTransformStart}`  }}
				        reverse={showModal}
				        //config={{tension: 700, friction: 36}}
				        config={{duration: 0}}
				        onRest={this.onRest}
				       >
				        {(styles, item) =>
				          item && 
				          <animated.div style={styles} className="recordingPlaybackModal"> 					           	
				           	<RecordingPlaybackModal
				           		recording={this.props.recording}
				           		meeting={this.props.meeting}
				           		closeModal={closeModal}
				           		doc={this.props.doc}
				           		recordingId={this.props.recordingId}
				           		startResizeDragging={()=>{this.setState({isResizeDragging: true})}}
				           		endResizeDragging={()=>{this.setState({isResizeDragging: false})}}
				           		animationRest={this.state.forceMount}
				           		initialModalWidth={initialModalWidth}
				           		initialModalHeight={window.innerHeight - 30 - 20 - 44}
				           		initialTranscriptWidth={initialTranscriptWidth}						           		
				           	/>
				          </animated.div>
				        }
							</Transition> 		
						<div onClick={this.props.closeModal} className={'recordingPlaybackModalUnderlay ' + (showModal ? ' recordingPlaybackModalUnderlay--show ': ' recordingPlaybackModalUnderlay--hide ')} />					      						    
					</Dialog.Content>				    
				</Dialog.Portal>
			</Dialog.Root>		
		</React.Fragment>
	  )
	}
}

export default RecordingPlaybackModalContainer