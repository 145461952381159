import React, { createContext } from 'react'
import { connect } from 'react-redux'
import {createMeetingRecordingRequest} from '../actions/recording'
import {startUpload} from '../utils/recordingUploads/startUpload'
import {uploadFinished} from '../utils/recordingUploads/uploadFinished'
import {updateUploadProgress} from '../utils/recordingUploads/updateUploadProgress'
import * as UpChunk from '@mux/upchunk'
 
// Create the context
export const RecordingsContext = createContext();

// Create a provider component
class RecordingsProvider extends React.Component {
 
  constructor(props) {
    super(props);
    this.uploadToMux=this.uploadToMux.bind(this)
    this.uploadFile=this.uploadFile.bind(this)
    this.cancelUpload=this.cancelUpload.bind(this)

    this.state = {
      files: [],
      uploads:{},
      uploadFile: this.uploadFile,
      cancelUpload:this.cancelUpload
    };
  }

  uploadToMux(file,uploadUrl,uploadId){
    // const upload = UpChunk.createUpload({
    //   endpoint: uploadUrl,
    //   file: file,
    //   chunkSize: 5120, // Uploads the file in ~5mb chunks
    // });
    
    const upload = UpChunk.createUpload({
      endpoint: uploadUrl,
      file: file,
      chunkSize: 5120, // Uploads the file in ~5mb chunks
    });

    this.setState(prevState => ({
      uploads: {
        ...prevState.uploads,
        [`${uploadId}`]: upload  // store the upload by ID
      }
    }));


    upload.on('error', err => {
      console.error('💥 🙀', err.detail);
    });

    upload.on('progress', progress => {
     // console.log('Uploaded', progress.detail, 'percent of this file.');
      updateUploadProgress(uploadId,progress.detail)
    });

    // subscribe to events
    upload.on('success', err => {
       uploadFinished(uploadId)
       this.setState(prevState => {
        const uploads = {...prevState.uploads};
        delete uploads[uploadId];
        return {uploads};
      });
    });  
  }

  uploadFile(meetingId,file){
    const fileName=file.name
    this.props.createMeetingRecordingRequest(meetingId,fileName).then((response)=>{
      const uploadUrl=response.upload.upload_url
      const uploadId=response.upload.upload_id
      startUpload(uploadId)
      this.uploadToMux(file,uploadUrl,uploadId)
    })
  };

  cancelUpload(uploadId){
    const upload = this.state.uploads[uploadId];
    if (upload) {
      upload.abort();
      uploadFinished(uploadId)
      // remove the upload from state on cancellation
      this.setState(prevState => {
        const uploads = {...prevState.uploads};
        delete uploads[uploadId];
        return {uploads};
      });
    } else {
     // console.log('No upload found with ID', uploadId);
    }
  }

  render() {
    return (
      <RecordingsContext.Provider value={this.state}>
        {this.props.children}
      </RecordingsContext.Provider>
    );
  }
}


function mapStateToProps(state,ownProps) {
  return {
    
  }
}


export default connect(mapStateToProps,
  {createMeetingRecordingRequest}
)(RecordingsProvider)
