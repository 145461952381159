
function getInitialState(){
	const messagePanels = JSON.parse(localStorage.getItem('messagePanels'))
	if(messagePanels){
		return messagePanels
	}else{
		return {}
	}
}



export default function (state = getInitialState(), action) {
	switch (action.type) {

	case 'UPDATE_MESSAGE_PANEL_VISIBILITY':
		const {docId,isVisible}=action	
		let newState=Object.assign({},state)
		newState[docId]=isVisible
		localStorage.setItem('messagePanels',JSON.stringify(newState))
		return newState
	
	default:
		return state
	}
}

