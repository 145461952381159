import {Plugin,PluginKey} from "prosemirror-state"
import {MessageSelectionState} from './messageSelection'

// const key = new PluginKey('messageSelectionPlugin')
export function messageSelectionPlugin(pluginKey) {
	return new Plugin({
  key:pluginKey,
  state: {
    init:MessageSelectionState.init,
    apply(tr, prev) { return prev.apply(tr) }
  },
  props: {
    decorations(state) { return this.getState(state).decos }
  }
})
}

 