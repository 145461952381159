import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import MessageMediaHighlightTranscript from './MessageMediaHighlightTranscript'

class MessageMediaHighlightInlineTranscriptPopover extends React.Component{  

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)		
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

	render(){	
		const {showPopover, openPopover, closePopover, immediateClose} = this.props
		const {forceMount} = this.state
		const translateX = 6
		const popoverSide = "left"
		const popoverSideOffset= 14
		const popoverAlign = "start"
		const popoverAlignOffset= -10

		let config = {
			tension: 600, friction: 35
		}
		if(immediateClose){
			config = {
				duration: 0
			}
		}

	  return (
	  	<React.Fragment>
		  	<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>						
						{this.props.children}
					</Popover.Trigger>					
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'messageHighlightTranscriptPopoverContainer ' + (showPopover ? ' messageHighlightTranscriptPopoverContainer--visible ' : ' messageHighlightTranscriptPopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							//onPointerDownOutside={closePopover}
							//onInteractOutside={closePopover}							
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateX(${translateX}px)` }}
								enter={{ opacity: 1, transform: 'translateX(0px)'  }}
								leave={{ opacity: 0, transform: `translateX(${translateX}px)`  }}
								reverse={showPopover}
								config={config}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="messageHighlightTranscriptPopover popover"> 	
										<MessageMediaHighlightTranscript 
										 	inline
										 	toggleTranscript={this.props.toggleTranscript}
										/>
									</animated.div>
								}
							</Transition>			
							
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>

			</React.Fragment>
	  )
	}
}

export default MessageMediaHighlightInlineTranscriptPopover
