import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'ORG_PRESENCE_SUBSCRIBE_SUCCESS':
		return action.members

	case 'ORG_PRESENCE_REMOVED':				
		let members=state
		delete members[action.userId]
		return members

	case 'ORG_PRESENCE_ADDED':		
		let orgPresenceMembers=state
		orgPresenceMembers[action.user]=action.data.info
		return orgPresenceMembers
			
	case 'SIGN_OUT':
      return []

	default:
		return state
	}
}