import React from 'react'
import Icon from '../../misc/Icon'
import { connect } from 'react-redux'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import DocDateDatePicker from './DocDateDatePicker'
import moment from 'moment'




export function formatDocDate(date) {
	const today = moment().startOf('day')
	const tomorrow = moment().add(1,'days');
	const yesterday = moment().add(-1,'days');
	const thisWeek = moment().startOf('week')
	const lastWeek=moment(thisWeek).subtract(7,'days');
	const nextWeek = moment(thisWeek).add(7,'days')
	
	let dateLabel
	let dur=moment().diff(moment(date))
	let days
	if(dur){
		days=dur/(1000*60*60*24)
	}
	if(days>0 && days<8){
		if (moment(date).isSame(today, 'd')) {
			dateLabel='Today'
		}else if (moment(date).isSame(yesterday, 'd')) {
			dateLabel='Yesterday'
		}else if(moment(date).isSame(thisWeek,'week')){ 
			dateLabel=`${moment(date).format('dddd')}`
		}else if(moment(date).isSame(lastWeek,'week')){ 
			dateLabel=`Last ${moment(date).format('dddd')}`
		}
	}else if(days<0 && days>-7){
		if (moment(date).isSame(today, 'd')) {
			dateLabel='Today'
		}else if (moment(date).isSame(tomorrow, 'd')) {
			dateLabel='Tomorrow'
		}else if(moment(date).isSame(thisWeek,'week')){ 
			dateLabel=`${moment(date).format('dddd')}`
		}else if(moment(date).isSame(nextWeek,'week')){ 
			dateLabel=`Next ${moment(date).format('dddd')}`
		}
	}else{
		dateLabel=`${moment(date).format("MMMM D, YYYY")}`
	}
	return dateLabel
}


class DocDateReactComponent extends React.PureComponent{  
	
	constructor(props){
    super(props)  
    this.onSelectDate=this.onSelectDate.bind(this)
    this.onRest=this.onRest.bind(this)
    this.state={
      showDatePopover: false,
      forceMount: true,
    }
  }

  onSelectDate(date){
  	this.setState({showDatePopover:false})
  	if(date){
  		this.props.onSelectDate(date)
  	}
  }

  onRest() {  
    if(!this.state.showDatePopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

	render(){
		const {showDatePopover, forceMount} = this.state
		const label=formatDocDate(this.props.date)
	  return (	  
	  	<Popover.Root open={showDatePopover}>
					<Popover.Trigger asChild>
						<span onClick={()=>{this.setState({showDatePopover:true})}} className="doc-para-date">	  			  	
				  		<span role="img" className='doc-para-date-spacer' />
  						<span role="img" className='doc-para-date-iconContainer'>
  							<Icon name='calendarSimple' />	  				  		
  						</span>
				  		<span className="doc-para-date-label">
					  		{label}
					  	</span>	  			 		  	
				  	</span>
					</Popover.Trigger>					
					<Popover.Portal forceMount={showDatePopover ? true : forceMount}>
						<Popover.Content
							className={'moveDocPopoverContainer ' + (showDatePopover ? ' moveDocPopoverContainer--visible ' : ' moveDocPopoverContainer--hidden ')} 
							onEscapeKeyDown={()=>{this.setState({showDatePopover:false})}}
							onPointerDownOutside={()=>{this.setState({showDatePopover:false})}}
							//onInteractOutside={()=>{this.setState({showDatePopover:false})}}							
							// side={popoverSide}
							sideOffset={8}
							// align={popoverAlign}
							// alignOffset={popoverAlignOffset}
							forceMount={showDatePopover ? true : forceMount}
						>
							<Transition
								items={showDatePopover}
								from={{ opacity: 0, transform: `scale(0.95) translateY(-8px)` }}
								enter={{ opacity: 1, transform: 'scale(1) translateY(0px)'  }}
								leave={{ opacity: 0, transform: `scale(0.95) translateY(-8px)`  }}
								reverse={showDatePopover}
								config={{tension: 600, friction: 35}}
								onRest={this.onRest}
								>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="docDatePopover popover"> 	
											<DocDateDatePicker 
												date={this.props.date}
												onSelect={this.onSelectDate}
											/>
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		

	  )
	}
}
export default DocDateReactComponent