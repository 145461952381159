import React from 'react'
import Icon from '../misc/Icon'
import LinearProjectStatusIcon from '../misc/LinearProjectStatusIcon'
//import {updateProjectStatus} from '../../utils/updateProject/updateProjectStatus'


class EditProjectStatusRow extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectProjectStatus=this.handleSelectProjectStatus.bind(this)

	}

	handleSelectProjectStatus(e){
		e.preventDefault()
		e.stopPropagation()
		this.props.closePopover()
		const {projectId,projectStatus}=this.props
		this.props.updateProjectStatus(projectStatus)
	}

	render(){	
		const {active, projectStatus} = this.props	
		let projectStatusLabel = projectStatus    
    if(projectStatus === 'started'){
      projectStatusLabel = 'in progress'
    }

		return (
			<div onClick={this.handleSelectProjectStatus} className={'popoverMenu-item popoverMenu-item--editProjectStatus ' + (active ? ' popoverMenu-item--active ' : ' popoverMenu-item--inactive ')}>
				<div className='popoverMenu-item-markerContainer'>
					<LinearProjectStatusIcon 
            projectStatus={projectStatus}
          />
				</div>
				<div className='popoverMenu-item-label'>
					{projectStatusLabel}
				</div>				
					
			</div>
		)
	}
}

class EditProjectStatusPopover extends React.Component{  	
	render(){	
		const {projectId, closePopover,currentStatus} = this.props
		return (
			<React.Fragment>         	
				<EditProjectStatusRow 				
					projectStatus="backlog"
					closePopover={closePopover}
					projectId={projectId}
					active={currentStatus=="backlog"}
					updateProjectStatus={this.props.updateProjectStatus}
				/>
				<div className='popoverMenu-separator' />
				<EditProjectStatusRow 				
					projectStatus="planned"
					closePopover={closePopover}
					projectId={projectId}
					active={currentStatus=="planned"}
					updateProjectStatus={this.props.updateProjectStatus}
				/>
				<EditProjectStatusRow 				
					projectStatus="started"
					closePopover={closePopover}
					projectId={projectId}
					active={currentStatus=="started"}
					updateProjectStatus={this.props.updateProjectStatus}
				/>
				<EditProjectStatusRow 				
					projectStatus="completed"
					closePopover={closePopover}
					projectId={projectId}
					active={currentStatus=="completed"}
					updateProjectStatus={this.props.updateProjectStatus}
				/>
				<div className='popoverMenu-separator' />
				<EditProjectStatusRow 				
					projectStatus="canceled"
					closePopover={closePopover}
					projectId={projectId}
					active={currentStatus=="canceled"}
					updateProjectStatus={this.props.updateProjectStatus}
				/>
				<EditProjectStatusRow 				
					projectStatus="paused"
					closePopover={closePopover}
					projectId={projectId}
					active={currentStatus=="paused"}
					updateProjectStatus={this.props.updateProjectStatus}
				/>
					
			</React.Fragment>
		)
	}
}

export default EditProjectStatusPopover