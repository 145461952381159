import findIndex from 'lodash/findIndex'

export default function (state = {}, action) {
	switch (action.type) {

	case 'SAVE_VIDEO_PROGRESS':
		return {...state,
			[`${action.nodeId}`]:{progress:action.progress,timestamp:new Date()}
		}

	case 'SIGN_OUT':
      return []
	
	default:
		return state
	}
}
