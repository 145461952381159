import React from 'react'
import ReactDOM from 'react-dom'
import DocDateReactComponent from './DocDateReactComponent'



class DocDateNodeView {
  
  constructor(node, view, getPos) {
    this.getPos=getPos
    this.node=node 
    this.dom = document.createElement("span")
    this.dom.className="doc-para-dateContainer"
    this.onSelectDate=this.onSelectDate.bind(this)
    this.renderReactComponent=this.renderReactComponent.bind(this)
    
    const {date}=node.attrs
    ReactDOM.render(
      <DocDateReactComponent    
        onSelectDate={this.onSelectDate}   
        date={date}  
        // docId={docId}
        // docNameSnapshot={docNameSnapshot}
      />, this.dom   
    )
  }



  renderReactComponent(){
    const {date}=this.node.attrs
     ReactDOM.render(
      <DocDateReactComponent    
        onSelectDate={this.onSelectDate}   
        date={date}  
      />, this.dom   
    )
  }

  
  onSelectDate(date){
    const node=this.getNode()
    const nodePos=this.getPos()
    let attributes={...node.attrs}
    attributes.date=`${date}`
    let tr = window.view.state.tr  
    tr.setNodeMarkup(nodePos, null, attributes)
    window.view.dispatch(tr) 
  }


  update(node) {
    if (node.type !== this.node.type) return false
    this.node=node
    this.renderReactComponent()
    return true
  }

  getNode(){
    return this.node
  }



  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom)
  }

}

export default DocDateNodeView