import store from '../store'
import find from 'lodash/find'

export function getIssueForIdentifier(issueIdentifier) {	
	let issue={}

	const state = store.getState()
	issue= find(state.issues, {'identifier':issueIdentifier})
	return issue

}




export function getIssueForId(issueId) {	
	let issue={}

	const state = store.getState()
	issue= find(state.issues, {'issue_id':issueId})
	if(issue){
		const workflow_state=find(state.workflowStates,{state_id:issue.workflow_state})
		return {...issue,workflow_state:workflow_state}
	}else return {}

}

