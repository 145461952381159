import {EditorView as CodeMirror, keymap as cmKeymap, 
  drawSelection,
  lineNumbers,
  highlightActiveLineGutter,
  highlightActiveLine,
  highlightSpecialChars,
  dropCursor
} from "@codemirror/view"
import {TextSelection} from "prosemirror-state"
import {defaultKeymap} from "@codemirror/commands"
import {exitCode} from "prosemirror-commands"
import {undo, redo} from "prosemirror-history"
import {autocompletion,completionKeymap,closeBrackets,closeBracketsKeymap,} from "@codemirror/autocomplete"
import {
  indentOnInput,
  foldGutter,
  foldKeymap,
  bracketMatching,
  defaultHighlightStyle,
  syntaxHighlighting,

  StreamLanguage} from "@codemirror/language";
import {Compartment} from "@codemirror/state"
import schema from '../../../prosemirror/schema/editorSchema'
import { createRoot } from "react-dom/client";
import CodeMirrorMenuReactComponent from './CodeMirrorMenuReactComponent'
import langs from './languages';
import themes from './themes/themes'
import {getTheme} from '../../../utils/getTheme'
import { Mixpanel } from '../../../Mixpanel'

class DocCodeMirrorNodeView {
  constructor(node, view, getPos) {
    this.node = node
    this.view = view
    this.getPos = getPos
    this.changeLanguage=this.changeLanguage.bind(this)
    this.changeTheme=this.changeTheme.bind(this)
    this.renderReactComponent=this.renderReactComponent.bind(this)
    this.deleteIfAtStartAndEmpty=this.deleteIfAtStartAndEmpty.bind(this)
    this.deleteCodemirror=this.deleteCodemirror.bind(this)
    this.currentLanguage = node.attrs.language; // initial language
    this.currentTheme=node.attrs.theme
    if(this.currentTheme=='artifact'){//get current theme and chose light/dark accordingly!
      const systemTheme=getTheme()
      if(systemTheme=='dark'){
        this.currentTheme='artifactDark'
      }else{
        this.currentTheme='artifactLight'
      }
    }

    this.language = new Compartment();
    this.themeCompartment = new Compartment()

    this.cm = new CodeMirror({
      doc: this.node.textContent,
      extensions: [
        cmKeymap.of([
          ...this.codeMirrorKeymap(),
          ...defaultKeymap
        ]),
        this.themeCompartment.of(themes[this.currentTheme]),
        lineNumbers(),
        foldGutter(),
        highlightActiveLineGutter(),
        highlightSpecialChars(),
        drawSelection(),
        dropCursor(),
        indentOnInput(),
        syntaxHighlighting(defaultHighlightStyle),
        bracketMatching(),
        closeBrackets(),
        autocompletion(),
       // highlightActiveLine(),
 
       // rectangularSelection(),
        //highlightSelectionMatches(),
       
        //
        drawSelection(),
        this.language.of(langs[this.currentLanguage]()),
        CodeMirror.updateListener.of(update => this.forwardUpdate(update))
      ]
    })


    this.wrapperDiv=document.createElement("div")
    this.wrapperDiv.className="doc-codeMirrorWrapper"
    if(this.currentTheme){
      this.wrapperDiv.classList.add(`doc-codeMirrorWrapper--theme--${this.currentTheme}`);
    }
    
    this.dom=this.wrapperDiv
    this.dom.appendChild(this.cm.dom)


    // The editor's outer node is our DOM representation
    //this.dom = this.cm.dom

    this.buttonDom=document.createElement("div")
    this.dom.appendChild(this.buttonDom)

    this.root = createRoot(this.buttonDom);
    this.renderReactComponent()
 

    // This flag is used to avoid an update loop between the outer and
    // inner editor
    this.updating = false
  }


  renderReactComponent(){
    this.root.render(
      <CodeMirrorMenuReactComponent 
        currentLanguage={this.currentLanguage} 
        changeLanguage={this.changeLanguage} 
        changeTheme={this.changeTheme}
        currentTheme={this.currentTheme}
        deleteCodemirror={this.deleteCodemirror}
      />)  
  }

  changeLanguage(languageObj) {
    if (langs[languageObj.language]) {
      this.cm.dispatch({
        effects: this.language.reconfigure(langs[languageObj.language]())
      });
      this.currentLanguage = languageObj.language;

      const node=this.node
      const nodePos=this.getPos(node)
      let attributes={...node.attrs}
      attributes.language=this.currentLanguage
      let tr = window.view.state.tr  
      tr.setNodeMarkup(nodePos, null, attributes)
      window.view.dispatch(tr)
    } else {
      //console.log('Language not supported')
    }
    this.renderReactComponent()
    Mixpanel.track('change_codemirror_language',{language:this.currentLanguage})
  }

  changeTheme(theme) {
    this.currentTheme=theme
     if(this.currentTheme=='artifact'){
      //get current theme and chose light/dark accordingly!
      const systemTheme=getTheme()
      if(systemTheme=='dark'){
        this.currentTheme='artifactDark'
      }else{
        this.currentTheme='artifactLight'
      }
    }
     this.cm.dispatch({
        effects: this.themeCompartment.reconfigure(themes[this.currentTheme])
      });
  
    const node=this.node
    const nodePos=this.getPos(node)
    let attributes={...node.attrs}
    attributes.theme=theme
    let tr = window.view.state.tr  
    tr.setNodeMarkup(nodePos, null, attributes)
    window.view.dispatch(tr)
    this.renderReactComponent()

    Mixpanel.track('change_codemirror_theme',{theme:this.currentTheme})
    
    this.wrapperDiv.className="doc-codeMirrorWrapper"
    if(this.currentTheme){
      this.wrapperDiv.classList.add(`doc-codeMirrorWrapper--theme--${this.currentTheme}`);
    }
  }

  deleteCodemirror(e){
    e.stopPropagation()
    e.preventDefault()
    let tr = window.view.state.tr
    const nodePos=this.getPos()
    tr.delete(nodePos,nodePos+this.node.nodeSize)
    window.view.dispatch(tr)
  }



// }
// nodeview_forwardUpdate{
  forwardUpdate(update) {
    if (this.updating || !this.cm.hasFocus) return
    let offset = this.getPos() + 1, {main} = update.state.selection
    let selFrom = offset + main.from, selTo = offset + main.to
    let pmSel = this.view.state.selection
    if (update.docChanged || pmSel.from != selFrom || pmSel.to != selTo) {
      let tr = this.view.state.tr
      update.changes.iterChanges((fromA, toA, fromB, toB, text) => {
        if (text.length)
          tr.replaceWith(offset + fromA, offset + toA,
                         schema.text(text.toString()))
        else
          tr.delete(offset + fromA, offset + toA)
        offset += (toB - fromB) - (toA - fromA)
      })
      tr.setSelection(TextSelection.create(tr.doc, selFrom, selTo))
      this.view.dispatch(tr)
    }
  }
// }
// nodeview_setSelection{
  setSelection(anchor, head) {
    this.cm.focus()
    this.updating = true
    this.cm.dispatch({selection: {anchor, head}})
    this.updating = false
  }
// }
// nodeview_keymap{
  codeMirrorKeymap() {
    let view = this.view
    return [
      {key: "ArrowUp", run: () => this.maybeEscape("line", -1)},
      {key: "Backspace", run: () => this.deleteIfAtStartAndEmpty()},
      {key: "ArrowLeft", run: () => this.maybeEscape("char", -1)},
      {key: "ArrowDown", run: () => this.maybeEscape("line", 1)},
      {key: "ArrowRight", run: () => this.maybeEscape("char", 1)},
      {key: "Ctrl-Enter", run: () => {
        if (!exitCode(view.state, view.dispatch)) return false
        view.focus()
        return true
      }},
      {key: "Ctrl-z", mac: "Cmd-z",
       run: () => undo(view.state, view.dispatch)},
      {key: "Shift-Ctrl-z", mac: "Shift-Cmd-z",
       run: () => redo(view.state, view.dispatch)},
      {key: "Ctrl-y", mac: "Cmd-y",
       run: () => redo(view.state, view.dispatch)}
    ]
  }

  deleteIfAtStartAndEmpty() {
    let {state} = this.cm, {main} = state.selection
    if (main.head !== 0 || state.doc.length > 0) return false // Not at start or doc is not empty
    let nodePos = this.getPos()
    let tr = this.view.state.tr.delete(nodePos, nodePos + this.node.nodeSize)
    this.view.dispatch(tr)
    this.view.focus()
    return true
  }

  maybeEscape(unit, dir) {
    let {state} = this.cm, {main} = state.selection
    if (!main.empty) return false
    if (unit == "line") main = state.doc.lineAt(main.head)
    if (dir < 0 ? main.from > 0 : main.to < state.doc.length) return false
    let targetPos = this.getPos() + (dir < 0 ? 0 : this.node.nodeSize)
    let selection = Selection.near(this.view.state.doc.resolve(targetPos), dir)
    let tr = this.view.state.tr.setSelection(selection).scrollIntoView()
    this.view.dispatch(tr)
    this.view.focus()
  }
// }
// nodeview_update{
  update(node) {
    if (node.type != this.node.type) return false

    const oldSystemThemeVersion=this.node.attrs.systemThemeVersion


    this.node = node
    
    if(node.attrs.systemThemeVersion !==oldSystemThemeVersion){
      this.changeTheme(node.attrs.theme)
    }
    if (this.updating) return true


    let newText = node.textContent, curText = this.cm.state.doc.toString()
    if (newText != curText) {
      let start = 0, curEnd = curText.length, newEnd = newText.length
      while (start < curEnd &&
             curText.charCodeAt(start) == newText.charCodeAt(start)) {
        ++start
      }
      while (curEnd > start && newEnd > start &&
             curText.charCodeAt(curEnd - 1) == newText.charCodeAt(newEnd - 1)) {
        curEnd--
        newEnd--
      }
      this.updating = true
      this.cm.dispatch({
        changes: {
          from: start, to: curEnd,
          insert: newText.slice(start, newEnd)
        }
      })
      this.updating = false
    }
    return true
  }
// }
// nodeview_end{

  selectNode() { this.cm.focus() }
  stopEvent() { return true }

  destroy() {
     this.root.unmount();
  }

}

export default DocCodeMirrorNodeView