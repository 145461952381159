import React,{ useState,useEffect}  from 'react'
import Icon from '../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import { track, useEditor,useValue } from "@tldraw/tldraw"
import { GeoShapeGeoStyle } from '@tldraw/editor'
import WhiteboardToolbarPropertiesRow from './WhiteboardToolbarPropertiesRow'
import {useInsertMedia} from "./useInsertMedia"

class StandardToolbarBtn extends React.Component{  
	
	

	render(){ 
		const {onClick,activeClick,active,action}=this.props
		//const {iconName, toolbarStyle} = this.state
		return (
			<Tooltip.Root delayDuration={this.props.expandedToolbar ? 700 : 0} skipDelayDuration={this.props.expandedToolbar ? 300 : 700}>
				<Tooltip.Trigger asChild>																										
					<button onClick={active ? activeClick : onClick} data-state={active ? 'active' : 'inactive'} className={'whiteboard-toolbar-btn ' + (action ? ` whiteboard-toolbar-btn--action--${action} ` : '')}>			 
						<Icon name={this.props.iconName} /> 			
						{/*}
						{!this.props.expandedToolbar && this.props.keyboardShortcutLabel &&
							<div className='whiteboard-toolbar-btn-simpleShortcut'>
								{this.props.keyboardShortcutLabel}
							</div>
						}
						*/}
						
					</button>
				</Tooltip.Trigger>				
				{this.props.tooltipLabel &&
					<Tooltip.Content side="top" sideOffset={4} className="tooltip tooltip--textSelectionUI">																																	
						{this.props.tooltipLabel} {this.props.keyboardShortcutLabel ? ' – ' : ''} {this.props.keyboardShortcutLabel}
					</Tooltip.Content>				
				}									
			</Tooltip.Root>
		)
	}
}


class ToolbarDivider extends React.Component{  
	render(){ 		
		return (
			<div className='whiteboard-toolbar-divider' />		
		)
	}
}


const WhiteboardToolbar = track(() => {
	 const editor = useEditor()

	 const insertMedia = useInsertMedia()

	 const geoState = useValue('geo', () => editor.sharedStyles.getAsKnownValue(GeoShapeGeoStyle), [
		editor,
	])


 function selectShape(shape) {
    editor.setSelectedTool('geo')
    editor.updateInstanceState({
			stylesForNextShape: {
				...editor.instanceState.stylesForNextShape,
				[GeoShapeGeoStyle.id]: shape,
				},
			})
  }



  const [expandedToolbar, setExpandedToolbar] = useState(false);

		return (
			<Tooltip.Provider>
				<div className='whiteboard-toolbarContainer'>
					
						<div className='whiteboard-toolbar'>
							<div className='whiteboard-toolbar-toolRow'>
								<StandardToolbarBtn 
									action='select'		
									iconName='drawingCursor'					
									tooltipLabel='Select'
									keyboardShortcutLabel='V'
									expandedToolbar
									active={editor.currentToolId === 'select'}	
									onClick={() => editor.setSelectedTool('select')}
									activeClick={() => editor.setSelectedTool('select')}
								/>
								<StandardToolbarBtn 
									action='hand'		
									iconName='drawingHandAlt'	
									tooltipLabel='Hand'
									keyboardShortcutLabel='H'	
									expandedToolbar				
									active={editor.currentToolId === 'hand'}
									onClick={() => editor.setSelectedTool('hand')}
									activeClick={() => editor.setSelectedTool('select')}
								/>
								<ToolbarDivider />
								<StandardToolbarBtn 
									action='square'	
									iconName='drawingSquare'							
									tooltipLabel='Rectangle'
									keyboardShortcutLabel='R'
									active={editor.currentToolId === 'geo'&& geoState=='rectangle'}
									onClick={() => selectShape('rectangle')}		
									activeClick={() => editor.setSelectedTool('select')}
								/>
								<StandardToolbarBtn 
									action='line'							
									iconName='drawingLine'							
									tooltipLabel='Line'
									keyboardShortcutLabel='L'
									active={editor.currentToolId === 'line'}
									onClick={() => editor.setSelectedTool('line')}
									activeClick={() => editor.setSelectedTool('select')}
								/>
								<StandardToolbarBtn 
									action='arrow'			
									iconName='drawingArrow'
									tooltipLabel='Arrow'
									keyboardShortcutLabel='A'
									active={editor.currentToolId === 'arrow'}
									onClick={() => editor.setSelectedTool('arrow')}
									activeClick={() => editor.setSelectedTool('select')}		
								/>							
								{/*}
								<StandardToolbarBtn 
									action='diamond'	
									iconName='drawingDiamond'		
									tooltipLabel='Diamond'	
									expandedToolbar	
									active={editor.currentToolId === 'geo' && geoState=='diamond'}
									onClick={() => selectShape('diamond')}				
								/>
								*/}
								<ToolbarDivider />
								
								<StandardToolbarBtn 
									action='text'														
									iconName='drawingText'		
									tooltipLabel='Text'
									keyboardShortcutLabel='T'
									active={editor.currentToolId === 'text'}
									onClick={() => editor.setSelectedTool('text')}
									activeClick={() => editor.setSelectedTool('select')}
	         				
								/>			
								<StandardToolbarBtn 
									action='sticky'														
									iconName='drawingStickyAlt'	
									tooltipLabel='Note'
									keyboardShortcutLabel='N'	
									active={editor.currentToolId === 'note'}
									onClick={() => editor.setSelectedTool('note')}	
									activeClick={() => editor.setSelectedTool('select')}
								/>
								{/*
								<StandardToolbarBtn 
									action='text'														
									iconName='code'		
									tooltipLabel='Code'
									keyboardShortcutLabel='T'
									active={editor.currentToolId === 'text'}
									onClick={() => editor.setSelectedTool('text')}	         				
								/> */}
								<StandardToolbarBtn 
									action='text'														
									iconName='insertBarImage'		
									tooltipLabel='Image'
									keyboardShortcutLabel='I'
									//active={editor.currentToolId === 'asset'}
									onClick={() => insertMedia()}				
									activeClick={() => editor.setSelectedTool('select')} // doesn't really make sense but whatevers
								/>
								<ToolbarDivider />
								{/*}
								<StandardToolbarBtn 
									action='add'	
									iconName='plusRegular'
									tooltipLabel='Add...'
									//keyboardShortcutLabel='E'
									
									active={editor.currentToolId === 'eraser'}
									onClick={() => editor.setSelectedTool('eraser')}	
								/>		
								*/}
								<StandardToolbarBtn 
									action='circle'	
									iconName='drawingCircle'	
									tooltipLabel='Ellipse'
									keyboardShortcutLabel='O'
									expandedToolbar					
									active={editor.currentToolId === 'geo' && geoState=='ellipse'}
									onClick={() => selectShape('ellipse')}
									activeClick={() => editor.setSelectedTool('select')}
									//onClick={() => editor.setSelectedTool('geo',{shape:'ellipse'})}								
								/>
								<StandardToolbarBtn 
									action='triangle'	
									iconName='drawingTriangle'		
									tooltipLabel='Triangle'		
									expandedToolbar			
									active={editor.currentToolId === 'geo' && geoState=='triangle'}
									onClick={() => selectShape('triangle')}	
									activeClick={() => editor.setSelectedTool('select')}		
								/>
								<StandardToolbarBtn 
									action='diamond'	
									iconName='drawingDiamond'		
									tooltipLabel='Diamond'	
									expandedToolbar	
									active={editor.currentToolId === 'geo' && geoState=='diamond'}
									onClick={() => selectShape('diamond')}		
									activeClick={() => editor.setSelectedTool('select')}		
								/>
												
									
								<ToolbarDivider />				
								<StandardToolbarBtn 
									action='eraser'	
									iconName='drawingEraser'
									tooltipLabel='Eraser'
									keyboardShortcutLabel='E'								
									active={editor.currentToolId === 'eraser'}
									onClick={() => editor.setSelectedTool('eraser')}
									activeClick={() => editor.setSelectedTool('select')}	
								/>

							</div>
							
							<WhiteboardToolbarPropertiesRow />

						

							{/*}
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>																	
									<button onClick={() => { setExpandedToolbar(true)}}className='whiteboard-toolbar-switchToolbarType'>
										<Icon name='drawingExpandToolbar' />
									</button>
								</Tooltip.Trigger>				
								<Tooltip.Content side="right" sideOffset={3} className="tooltip tooltip--textSelectionUI">																																	
									Show more
								</Tooltip.Content>													
							</Tooltip.Root>
							*/}

						</div>
					

					{/*}
						<div className='whiteboard-toolbar whiteboard-toolbar--expanded'>
							<StandardToolbarBtn 
								action='select'		
								iconName='drawingCursor'					
								tooltipLabel='Select'
								keyboardShortcutLabel='V'
								expandedToolbar
								active={editor.currentToolId === 'select'}	
								onClick={() => editor.setSelectedTool('select')}
							/>
							<StandardToolbarBtn 
								action='hand'		
								iconName='drawingHand'	
								tooltipLabel='Hand'
								keyboardShortcutLabel='H'	
								expandedToolbar				
								active={editor.currentToolId === 'hand'}
								onClick={() => editor.setSelectedTool('hand')}								
							/>
							<ToolbarDivider />
							<StandardToolbarBtn 
								action='pencil'	
								iconName='drawingPencil'
								tooltipLabel='Draw'
								keyboardShortcutLabel='D'
								expandedToolbar
								active={editor.currentToolId === 'draw'}
								onClick={() => editor.setSelectedTool('draw')}	
							/>
							<StandardToolbarBtn 
								action='eraser'	
								iconName='drawingEraser'
								tooltipLabel='Eraser'
								keyboardShortcutLabel='E'
								expandedToolbar
								active={editor.currentToolId === 'eraser'}
								onClick={() => editor.setSelectedTool('eraser')}	
							/>

							<ToolbarDivider />

							<StandardToolbarBtn 
								action='square'	
								iconName='drawingSquare'							
								tooltipLabel='Rectangle'
								keyboardShortcutLabel='R'	
								expandedToolbar			
								active={editor.currentToolId === 'geo'&& geoState=='rectangle'}
								onClick={() => selectShape('rectangle')}
								//onClick={() => editor.setSelectedTool('geo',{shape:'rectangle'})}			
							/>
							<StandardToolbarBtn 
								action='circle'	
								iconName='drawingCircle'	
								tooltipLabel='Ellipse'
								keyboardShortcutLabel='O'
								expandedToolbar					
								active={editor.currentToolId === 'geo' && geoState=='ellipse'}
								onClick={() => selectShape('ellipse')}
								//onClick={() => editor.setSelectedTool('geo',{shape:'ellipse'})}								
							/>
							<StandardToolbarBtn 
								action='triangle'	
								iconName='drawingTriangle'		
								tooltipLabel='Triangle'		
								expandedToolbar			
								active={editor.currentToolId === 'geo' && geoState=='triangle'}
								onClick={() => selectShape('triangle')}			
							/>
							<StandardToolbarBtn 
								action='cloud'	
								iconName='drawingCloud'							
								tooltipLabel='Cloud'
								expandedToolbar
								active={editor.currentToolId === 'geo' && geoState=='cloud'}
								onClick={() => selectShape('cloud')}	
							/>
							<StandardToolbarBtn 
								action='diamond'	
								iconName='drawingDiamond'		
								tooltipLabel='Diamond'	
								expandedToolbar	
								active={editor.currentToolId === 'geo' && geoState=='diamond'}
								onClick={() => selectShape('diamond')}				
							/>
							<StandardToolbarBtn 
								action='star'	
								iconName='drawingStar'	
								tooltipLabel='Star'		
								expandedToolbar		
								active={editor.currentToolId === 'geo' && geoState=='star'}
								onClick={() => selectShape('star')}								
							/>

							<ToolbarDivider />

							<StandardToolbarBtn 
								action='arrow'			
								iconName='drawingArrow'											
								tooltipLabel='Arrow'
								expandedToolbar
								active={editor.currentToolId === 'arrow'}
								onClick={() => editor.setSelectedTool('arrow')}		
							/>

							<StandardToolbarBtn 
								action='line'							
								iconName='drawingLine'	
								tooltipLabel='Line'
								keyboardShortcutLabel='L'
								expandedToolbar
								active={editor.currentToolId === 'line'}
								onClick={() => editor.setSelectedTool('line')}	
							/>						

							<ToolbarDivider />

							<StandardToolbarBtn 
								action='text'														
								iconName='drawingText'		
								tooltipLabel='Text'
								keyboardShortcutLabel='T'
								expandedToolbar	
								active={editor.currentToolId === 'text'}
         					onClick={() => editor.setSelectedTool('text')}
         				//onClick={() => console.log('click text')}
							/>
							
							<StandardToolbarBtn 
								action='stick'														
								iconName='drawingStickyAlt'		
								tooltipLabel='Note'
								keyboardShortcutLabel='N'	
								expandedToolbar
								active={editor.currentToolId === 'note'}
								onClick={() => editor.setSelectedTool('note')}	
							/>

							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>																	
									<button onClick={() => { setExpandedToolbar(false) }} className='whiteboard-toolbar-switchToolbarType'>
										<Icon name='drawingMinimizeToolbar' />
									</button>
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" sideOffset={3} className="tooltip tooltip--textSelectionUI">																																	
									Show fewer
								</Tooltip.Content>													
							</Tooltip.Root>



							
						</div>
					}
					*/}
				</div>
			</Tooltip.Provider>
			
		)
	})


export default WhiteboardToolbar