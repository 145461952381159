import store from '../store'
import find from 'lodash/find'


export function isCurrentUser(userId) {	
	const state = store.getState()
	const currentUser=state.user
	if(currentUser && userId==currentUser['user_id']){
		return true
	} else return false 
}
