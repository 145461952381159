import React from 'react'
import Icon from '../misc/Icon'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import DocImageZoomedModalBtn from '../docEditor/mediaModals/DocImageZoomedModalBtn'
import MeetingParticipantList from '../meetings/meetingParticipantList/MeetingParticipantList'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as Tooltip from '@radix-ui/react-tooltip';

class RecordingPlaybackModalHeader extends React.Component{  	

	render(){	
		const {meeting,doc}=this.props
		const meetingType = meeting.type
		const meetingTitle = doc.name

	  return (	  	
  		<div className='recordingPlaybackModal-header forceDarkTheme'>  			
  			<div className='recordingPlaybackModal-header-title'>
  				<div className='recordingPlaybackModal-header-title-iconContainer'>
  					<MeetingTypeIcon meetingType={meetingType} />
  				</div>
  				<div className='recordingPlaybackModal-header-title-meetingTitle'>
  					{meetingTitle}
  				</div>
  			</div>

  			<div className='recordingPlaybackModal-header-hSpacer' />

  			<div className='recordingPlaybackModal-header-rightContainer'>

        {!this.props.recording.onboarding_recording &&
					<button onClick={this.props.toggleIdentifySpeakerPanel} className='recordingPlaybackModal-header-identifySpeakersBtn'>
						<Icon name='personWave' />  							
						<div className='recordingPlaybackModal-header-identifySpeakersBtn-tooltipContainer'>
							<div className='tooltip'>
								Identify Speakers
							</div>
						</div>
					</button>
        }

					<MeetingParticipantList 
						meeting={meeting}								
						showDropdown
						forceDarkTheme
						//readOnly={meeting.event_id} // isGcal								
					/>

          <div onClick={this.props.closeModal} className='recordingPlaybackModal-header-closeBtnOuterContainer'>
  					<DocImageZoomedModalBtn
							iconName='cross'								
						/>
  				</div>
  			</div>
  		</div>
	  )
	}
}

export default RecordingPlaybackModalHeader
	

{/*}
<DropdownMenu.Root>
  <DropdownMenu.Trigger className='recordingPlaybackModal-header-dropdownBtn'>
  	<Icon name='ellipses' />
  </DropdownMenu.Trigger>   			 	
	 	<DropdownMenu.Content sideOffset={1} align="end" alignOffset={-8} className='dropdownMenu forceDarkTheme'>							
	 		
	 		
  	
  	<DropdownMenu.Item>
  		Identify Speakers
  	</DropdownMenu.Item>		        	

  	
  	
  	<DropdownMenu.Separator />
  			 
  					
 	 	<DropdownMenu.Item  className='dropdownMenu-item--warning'>
  		Delete Recording
  	</DropdownMenu.Item>					        
  </DropdownMenu.Content>
</DropdownMenu.Root>	  
*/}