import store from '../../store'

//if no saved user settings use this
const issueDefaults={
	showAssignee:true,
	showTitle:false,
	showIdentifier:true,
	showStatus:false,
	showPriority:false,
}



export function getDefaultIssueAttrs() {
	const state = store.getState()
	const userSettings=	state.userSettings

	let issueAttrs=issueDefaults
 	if(userSettings && userSettings.issue_mentions){
 		issueAttrs=userSettings.issue_mentions
 	}
 	return issueAttrs
}
