//check if in Do Not Disturb mode- if true don't show
import {doNotDisturbIsActive} from './doNotDisturbIsActive'


export function showElectronNotification(title,subtitle,body,sentBy) {	
	const isDoNotDisturb=doNotDisturbIsActive()
	if(isDoNotDisturb){
		
	}
	else{
		if(window.isElectron){
			const args={
				title:title,
				subtitle:subtitle,
				body:body,
				type:"pingCursorAlert",
				sentBy:sentBy,
			}
			window.ipcRenderer.send('show-notification',args)
		}
	}	
}