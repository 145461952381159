import React from 'react'
import { Transition, animated, config } from 'react-spring'
import KeyboardShortcutsPanelContents from './KeyboardShortcutsPanelContents'

class KeyboardShortcutsPanel extends React.Component{  

  constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.state={
      forceMount: false  
    }
  }

  onRest() {  
    if(!this.props.showDialog){
      this.setState({forceMount: false})
    }else{
      this.setState({forceMount: true})
    }
  }

  render(){ 

    const {showKeyboardShortcutsPanel, closeKeyboardShortcutsPanel} = this.props
    const {forceMount} = this.state

    let transformStart = 'translateX(40px)'
    let transformEnd = 'translateX(0px)'

        
    return (      
      
        <div className='keyboardShortcutsPanelContainer'>
          <Transition
            items={showKeyboardShortcutsPanel}
            from={{ opacity: 0, transform: `${transformStart}` }}
            enter={{ opacity: 1, transform: `${transformEnd}`  }}
            leave={{ opacity: 0, transform: `${transformStart}`  }}
            reverse={showKeyboardShortcutsPanel}
            config={{tension: 700, friction: 36}}
            // config={{tension: 600, friction: 24}}
            //config={config.molasses}
            onRest={this.onRest}
           >
            {(styles, item) =>
              item && 
               <animated.div style={styles} className="keyboardShortcutsPanel">                       
                  <KeyboardShortcutsPanelContents
                    closeKeyboardShortcutsPanel={closeKeyboardShortcutsPanel}
                  />
              </animated.div>
            }
          </Transition>                 
      </div>      


    )
  }
}

export default KeyboardShortcutsPanel
