import React from 'react'
import Icon from './Icon'

class MeetingTypeIcon extends React.Component{

  render(){ 
    const {meetingType, meetingId, flat, svgOnly}=this.props   

    let meetingIconType = meetingType

    // if(meetingId){
    //   get meeting type using id
    // }

    let meetingIconSlug

    if(meetingIconType === 'adhoc'){
      meetingIconSlug = 'meetingAdhocLayered'
    }

    if(meetingIconType === 'standup'){
      meetingIconSlug = 'meetingStandupLayered'
    }

    if(meetingIconType === 'teamSync'){
      meetingIconSlug = 'meetingTeamSyncLayered'
    }

    if(meetingIconType === 'discovery'){
      meetingIconSlug = 'meetingDiscoveryAltLayered'
    }

    if(meetingIconType === 'sprintPlanning'){
      meetingIconSlug = 'meetingSprintPlanningLayered'
    }

    if(meetingIconType === 'designReview'){
      meetingIconSlug = 'meetingDesignReviewAlt2Layered'
    }

    if(meetingIconType === 'blank'){
      meetingIconSlug = 'calendar'
    }

    if(meetingIconType === 'adhoc' && flat){
      meetingIconSlug = 'meetingAdhoc'
    }

    if(meetingIconType === 'standup' && flat){
      meetingIconSlug = 'meetingStandup'
    }

    if(meetingIconType === 'teamSync' && flat){
      meetingIconSlug = 'meetingTeamSync'
    }

    if(meetingIconType === 'discovery' && flat){
      meetingIconSlug = 'meetingDiscoveryAlt'
    }
    
    if(meetingIconType === 'sprintPlanning' && flat){
      meetingIconSlug = 'meetingSprintPlanning'
    }

    if(meetingIconType === 'designReview' && flat){
      meetingIconSlug = 'meetingDesignReviewAlt2'
    }

    if(meetingIconType === 'none' && flat){
      meetingIconSlug = 'meetingNoType'
    }


    return (
      <React.Fragment>
      {!svgOnly && 
        <div className='meetingTypeIconContainer'>
          <Icon name={meetingIconSlug} />
        </div>
      }
      {svgOnly &&
        <Icon name={meetingIconSlug} />
      }
      </React.Fragment>
    )
  }
}

export default MeetingTypeIcon



