import React from 'react';
import Icon from '../misc/Icon';
import * as Tooltip from '@radix-ui/react-tooltip';
import { track, useEditor } from "@tldraw/tldraw"
import { useValue } from "@tldraw/tldraw"

const WhiteboardZoomControls = () => {
    const editor = useEditor()

    const zoomLevel = useValue('zoom', () => editor.zoomLevel, [
        editor,
    ])

    function formatZoomLevel(zoomLevel){
        let percentage = Math.round(zoomLevel * 100) + "%";
        return percentage
    }
    return (
        <Tooltip.Provider>
            <div className='whiteboard-zoomControls'>
                {/*}
                <div className='whiteboard-zoomControls-zoomCounter'>
                    
                </div>
                */}

                 <button onClick={() => editor.resetZoom()} className={'whiteboard-zoomControls-resetZoomBtn ' + (zoomLevel === 1 ? ' whiteboard-zoomControls-resetZoomBtn--100 ' : ' whiteboard-zoomControls-resetZoomBtn--not100 ')}>
                    <div className='whiteboard-zoomControls-resetZoomBtn-span whiteboard-zoomControls-resetZoomBtn-span--counter'>
                        {formatZoomLevel(zoomLevel)}                
                    </div>
                    <div className='whiteboard-zoomControls-resetZoomBtn-span whiteboard-zoomControls-resetZoomBtn-span--tooltip'>
                        Reset Zoom
                    </div>
                </button>

                {/*<Tooltip.Root delayDuration={0}>
                    <Tooltip.Trigger asChild>																	
                       
                    </Tooltip.Trigger>				
                    {/*<Tooltip.Content side="top" sideOffset={3} align="end" alignOffset={-35} className="tooltip tooltip--textSelectionUI">																																	
                        Reset Zoom to 100%
                    </Tooltip.Content>													
                </Tooltip.Root>

                {/*}
                <Tooltip.Root delayDuration={0}>
                    <Tooltip.Trigger asChild>																	
                        <button className='whiteboard-zoomControls-openKeyboardShortcutPanelBtn'>
                            <Icon name='keyboard' />
                        </button>
                    </Tooltip.Trigger>				
                    <Tooltip.Content side="top" sideOffset={3} className="tooltip tooltip--textSelectionUI">																																	
                        Show Keyboard Shortcuts
                    </Tooltip.Content>													
                </Tooltip.Root>	
                */}
            </div>
        </Tooltip.Provider>
    );
};

export default WhiteboardZoomControls;
