import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const RedirectAuthenticated = ({
	path,
	location,
	isAuthenticated,
	willAuthenticate,
	component: Component,
}) =>
{
	if (isAuthenticated ){
		 return( 
			<Redirect to={{ pathname: '/' }} /> 
		)
	} 
	else if(!isAuthenticated && !willAuthenticate){
		return(	
			<Route
		 		exact
		 		path={path}
		 		render={(props) => {
		 			return <Component {...props} /> }}
			/>
			
		)
	}
	else return null
}

export default RedirectAuthenticated