import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import moment from 'moment'
import EditMetadataPopoverWrapper from '../../popovers/EditMetadataPopoverWrapper'
import {updateProjectStatus} from '../../../utils/updateProject/updateProjectStatus'
import {updateProjectTargetDate} from '../../../utils/updateProject/updateProjectTargetDate'

class RoadmapsHomePageQuarterOverdueProject extends React.Component{  		

	constructor(){
    super() 
    this.updateProjectTargetDate=this.updateProjectTargetDate.bind(this)
    this.handleUpdateProjectStatus=this.handleUpdateProjectStatus.bind(this)      
    this.state={
      showEditProjectStatusPopover: false,
      showEditProjectTargetDatePopover: false,            
    }
  }

  updateProjectTargetDate(targetDate,selcted,modifiers,e){
		e.preventDefault()
		e.stopPropagation()
		this.setState({showEditProjectTargetDatePopover:false})
		updateProjectTargetDate(this.props.project.project_id,targetDate)
	}

  handleUpdateProjectStatus(status){
		this.setState({showEditProjectStatusPopover:false})
		updateProjectStatus(this.props.project.project_id,status)
  }

	render(){		
		
		const {showEditProjectTargetDatePopover, showEditProjectStatusPopover} = this.state
		const {project} = this.props
		const formatedDate=moment(project.target_date).format('MMMM D')
		let diffDays = moment().diff(moment(project.target_date), 'days');


	  return (	  	
  		<div className='roadmapsHomePage-quarter-overdue-project'>
  			<div className='roadmapsHomePage-quarter-overdue-project-icon'>
  				🚩
  			</div>
  			<button className='roadmapsHomePage-quarter-overdue-project-name'>
  				{project.name}
  			</button>
  			<div className='roadmapsHomePage-quarter-overdue-project-dueDate'>
  				{diffDays} {diffDays==1?'day':'days'} past target date
  			</div>
  		  			
  		  <div className='roadmapsHomePage-quarter-overdue-project-vSpacer' />

  		 	<div className='roadmapsHomePage-quarter-overdue-project-buttonSet'>
	  			<div className='roadmapsHomePage-quarter-overdue-project-editBtnContainer'>
		  					  			 					
						<EditMetadataPopoverWrapper
							showPopover={showEditProjectTargetDatePopover}						
							openPopover={()=>{this.setState({showEditProjectTargetDatePopover: true})}}
							closePopover={()=>{this.setState({showEditProjectTargetDatePopover: false})}}		
							type="projectTargetDate"  // used to load in popover contents
							layout="overdueProjectCard" // used for positioning
							projectId={project.project_id}
							updateProjectTargetDate={this.updateProjectTargetDate}
							currentTargetDate={project.target_date}
							portalled
						>						
							<button onClick={()=>{this.setState({showEditProjectTargetDatePopover: true})}} className='roadmapsHomePage-quarter-overdue-project-editBtn'>
			  				<div className='roadmapsHomePage-quarter-overdue-project-editBtn-iconContainer'>
			  					<Icon name='calendarSimpleArrow' />
			  				</div>
			  				<div className='roadmapsHomePage-quarter-overdue-project-editBtn-label'>
			  					Update Target Date
			  				</div>
			  			</button>	  				  		
						</EditMetadataPopoverWrapper>			

	  			</div>



	  			<div className='roadmapsHomePage-quarter-overdue-project-editBtnContainer'>
	  				<EditMetadataPopoverWrapper
							showPopover={showEditProjectStatusPopover}						
							openPopover={()=>{this.setState({showEditProjectStatusPopover: true})}}
							closePopover={()=>{this.setState({showEditProjectStatusPopover: false})}}		
							type="projectStatus"  // used to load in popover contents
							layout="overdueProjectCard" // used for positioning
							projectId={project.project_id}
							projectStatus={project.state}	
							updateProjectStatus={this.handleUpdateProjectStatus}
							portalled
						>							
			  			<button onClick={()=>{this.setState({showEditProjectStatusPopover: true})}} className='roadmapsHomePage-quarter-overdue-project-editBtn'>
			  				<div className='roadmapsHomePage-quarter-overdue-project-editBtn-iconContainer'>
			  					<Icon name='groupStatus' />
			  				</div>
			  				<div className='roadmapsHomePage-quarter-overdue-project-editBtn-label'>
			  					Update Status
			  				</div>
			  			</button>

						</EditMetadataPopoverWrapper>													


	  			</div>
	  		</div>
	  		
  			
	  			
	  			
  		</div>
	  )
	}
}


class RoadmapsHomePageQuarterOverdue extends React.Component{  	

	render(){		
	  return (	  	
			<div className='roadmapsHomePage-quarter-overdue'>
				{this.props.overdueProjects.map(project => (
					<RoadmapsHomePageQuarterOverdueProject
						key={project.project_id}
						project={project}
					/>
				))}
			</div>
	  )
	}
}

export default RoadmapsHomePageQuarterOverdue
