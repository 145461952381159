import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import EditProjectStatusPopover from './EditProjectStatusPopover'
import EditProjectTargetDatePopover from './EditProjectTargetDatePopover'
import EditProjectRoadmapsPopover from './EditProjectRoadmapsPopover'
import EditProjectLeadPopover from './EditProjectLeadPopover'
import EditProjectMembersPopover from './EditProjectMembersPopover'
import EditProjectTeamsPopover from './EditProjectTeamsPopover'
import EditProjectIconPopover from './EditProjectIconPopover'

class EditMetadataPopoverWrapper extends React.Component{  	

  constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.state={
      forceMount: false,
    }
  }

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  handleClick(e){
  	e.stopPropagation()
  }

	render(){	
		const {type, layout, showPopover, openPopover, closePopover, portalled} = this.props		
		const {forceMount} = this.state
		let popoverSide = "bottom"
		let popoverSideOffset = 4
		let popoverAlign = "center"
		let popoverAlignOffset = 0

		if(type === 'projectStatus' && layout === "projectRootDocTopMenu" ){
			popoverSide = "bottom"
			popoverSideOffset= 2
			popoverAlign = "end"
			popoverAlignOffset= -12
		}
		if(type === 'projectStatus' && layout === "editProjectModal" ){
			popoverSide = "bottom"
			popoverSideOffset= 2
			popoverAlign = "start"
			popoverAlignOffset= -12
		}
		if(type === 'projectStatus' && layout === "overdueProjectCard" ){
			popoverSide = "bottom"
			popoverSideOffset= -100
			popoverAlign = "end"
			popoverAlignOffset= -90
		}
		if(type === 'projectLead' && layout === 'editProjectModal'){
			popoverSide = "bottom"
			//popoverSideOffset= -30
			popoverAlign = "start"
			popoverAlignOffset= -7 
		}
		if(type === 'projectTeams' && layout === 'editProjectModal'){
			popoverSide = "bottom"
			//popoverSideOffset= -30
			popoverAlign = "start"
			popoverAlignOffset= -20 
		}
		if(type === 'projectMembers' && layout === 'editProjectModal'){
			popoverSide = "bottom"
			//popoverSideOffset= -30
			popoverAlign = "start"
			popoverAlignOffset= -20 
		}
		if(type === 'projectRoadmaps' && layout === 'roadmapProjectRow'){
			popoverSide = "bottom"
			//popoverSideOffset= -30
			popoverAlign = "end"
			popoverAlignOffset= -40 
		}
		if(type === 'projectTargetDate' && layout === "projectRootDocTopMenu" ){
			popoverSide = "bottom"
			popoverSideOffset= 2
			popoverAlign = "end"
			popoverAlignOffset= -40
		}		
		if(type === 'projectTargetDate' && layout === "overdueProjectCard" ){
			popoverSide = "bottom"
			popoverSideOffset= 2
			popoverAlign = "end"
			popoverAlignOffset= 0
		}		
		if(layout === 'topMenuPrivate'){
			popoverSide = "bottom"
			popoverSideOffset= 4
			popoverAlign = "end"
			// popoverAlignOffset= -40
		}
		if(layout === 'sideMenu'){
			popoverSide = "right"
			popoverSideOffset= -30
			popoverAlign = "start"
			// popoverAlignOffset= -40 
		}
		if(layout === 'docTableRow'){
			popoverSide = "right"
			popoverSideOffset= -30
			popoverAlign = "start"
			popoverAlignOffset= -40 
		}

		const translateY = -8
		
		return (
			<React.Fragment>
				{portalled && 
					<Popover.Root open={showPopover} modal>
						<Popover.Trigger asChild>
							{this.props.children}
						</Popover.Trigger>					
						<Popover.Portal forceMount={showPopover ? true : forceMount}>
							<Popover.Content
								className={'editMetadataPopoverWrapperContainer ' + (layout ? ` editMetadataPopoverWrapperContainer--layout--${layout} ` : ' ') + (type ? ` editMetadataPopoverWrapperContainer--type--${type} ` : ' ') + (showPopover ? ' editMetadataPopoverWrapperContainer--visible ' : ' editMetadataPopoverWrapperContainer--hidden ')} 
								onEscapeKeyDown={closePopover}
								onPointerDownOutside={closePopover}
								onInteractOutside={(layout !== 'editProjectModal' && type !== 'projectRoadmaps') ? closePopover : null} // in a dialog component, it doesn't seem to like this
								side={popoverSide}
								sideOffset={popoverSideOffset}
								align={popoverAlign}
								alignOffset={popoverAlignOffset}
								forceMount={showPopover ? true : forceMount}
							>
								<Transition
									items={showPopover}
									from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
									enter={{ opacity: 1, transform: 'translateY(0px)'  }}
									leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
									reverse={showPopover}
									config={{tension: 600, friction: 35}}								
									onRest={this.onRest}
								>
									{(styles, item) =>
										item && 
										 <animated.div onClick={this.handleClick} style={styles} className={'editMetadataPopover popover ' + (type ? ` editMetadataPopover--type--${type} ` : ' ')  + (layout ? ` editMetadataPopover--layout--${layout} ` : ' ')}> 												
										 		{type === 'projectStatus' &&
										 			<EditProjectStatusPopover
										 				projectId={this.props.projectId}
										 				currentStatus={this.props.projectStatus}
										 				closePopover={this.props.closePopover}
										 				updateProjectStatus={this.props.updateProjectStatus}
													/>
										 		}
										 		{type === 'projectTargetDate' &&
										 			<EditProjectTargetDatePopover
										 				projectId={this.props.projectId}									 				
										 				closePopover={this.props.closePopover}
										 				updateProjectTargetDate={this.props.updateProjectTargetDate}
										 				currentTargetDate={this.props.currentTargetDate}
													/>
										 		}
										 		{type === 'projectRoadmaps' &&
										 			<EditProjectRoadmapsPopover
										 				projectId={this.props.projectId}									 				
										 				// closePopover={this.props.closePopover}
										 				updatedProjectRoadmaps={this.props.updatedProjectRoadmaps}
										 				updateProjectRoadmaps={this.props.updateProjectRoadmaps}
													/>
										 		}
										 		{type === 'projectTeams' &&									 			
										 			<EditProjectTeamsPopover
										 				teams={this.props.teams}
	        									updateProjectTeams={this.props.updateProjectTeams}
										 				closePopover={this.props.closePopover}							 				
													/>
										 		}		
										 		{type === 'projectLead' &&									 			
										 			<EditProjectLeadPopover
										 				leadUserId={this.props.leadUserId}
										 				closePopover={this.props.closePopover}	
										 				updateProjectLead={this.props.updateProjectLead}						 														 				
													/>
										 		}		
										 		{type === 'projectMembers' &&									 			
										 			<EditProjectMembersPopover
										 				projectId={this.props.projectId}
										 				closePopover={this.props.closePopover}	
										 				members={this.props.members}
										 				leadUserId={this.props.leadUserId}
										 				updateProjectMembers={this.props.updateProjectMembers}				 				
													/>
										 		}		

										 		{type === 'projectIcon' &&
										 			<EditProjectIconPopover
										 				//defaultIconType='linearIcon' // maybe we work this out from which their proposed, backlog, and in progress projects are like
										 				defaultIconType='emoji'
										 				projectId={this.props.projectId}									 				
										 				closePopover={this.props.closePopover}
										 				icon={this.props.icon}
	        									color={this.props.color}	    
	        									updateProjectIcon={this.props.updateProjectIcon}  
													/>
										 		}

										</animated.div>
									}
								</Transition>									
							</Popover.Content>
						</Popover.Portal>
					</Popover.Root>
				}

				{!portalled && 
					<Popover.Root open={showPopover} modal>
						<Popover.Trigger asChild>
							{this.props.children}
						</Popover.Trigger>											
							<Popover.Content
								className={'editMetadataPopoverWrapperContainer ' + (layout ? ` editMetadataPopoverWrapperContainer--layout--${layout} ` : ' ') + (type ? ` editMetadataPopoverWrapperContainer--type--${type} ` : ' ') + (showPopover ? ' editMetadataPopoverWrapperContainer--visible ' : ' editMetadataPopoverWrapperContainer--hidden ')} 
								onEscapeKeyDown={closePopover}
								onPointerDownOutside={closePopover}
								onInteractOutside={layout !== 'editProjectModal' ? closePopover : null} // in a dialog component, it doesn't seem to like this
								side={popoverSide}
								sideOffset={popoverSideOffset}
								align={popoverAlign}
								alignOffset={popoverAlignOffset}
								forceMount={showPopover ? true : forceMount}
							>
								<Transition
									items={showPopover}
									from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
									enter={{ opacity: 1, transform: 'translateY(0px)'  }}
									leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
									reverse={showPopover}
									config={{tension: 600, friction: 35}}								
									onRest={this.onRest}
								>
									{(styles, item) =>
										item && 
										 <animated.div  onClick={this.handleClick} style={styles} className={'editMetadataPopover popover ' + (type ? ` editMetadataPopover--type--${type} ` : ' ') + (layout ? ` editMetadataPopover--layout--${layout} ` : ' ')}>
										 											
											{type === 'projectStatus' &&
												<EditProjectStatusPopover
													projectId={this.props.projectId}
													currentStatus={this.props.projectStatus}
													closePopover={this.props.closePopover}
													updateProjectStatus={this.props.updateProjectStatus}
												/>
											}
										 		{type === 'projectTargetDate' &&
										 			<EditProjectTargetDatePopover
										 				projectId={this.props.projectId}									 				
										 				closePopover={this.props.closePopover}
										 				updateProjectTargetDate={this.props.updateProjectTargetDate}
										 				currentTargetDate={this.props.currentTargetDate}
													/>
										 		}
										 		{type === 'projectRoadmaps' &&
										 			<EditProjectRoadmapsPopover
										 				projectId={this.props.projectId}									 				
										 				//closePopover={this.props.closePopover}
										 				updatedProjectRoadmaps={this.props.updatedProjectRoadmaps}
										 				updateProjectRoadmaps={this.props.updateProjectRoadmaps}
													/>
										 		}
										 		{type === 'projectTeams' &&									 			
										 			<EditProjectTeamsPopover
										 				teams={this.props.teams}
	        									updateProjectTeams={this.props.updateProjectTeams}
										 				closePopover={this.props.closePopover}							 				
													/>
										 		}		
										 		{type === 'projectLead' &&									 			
										 			<EditProjectLeadPopover
										 				leadUserId={this.props.leadUserId}
										 				closePopover={this.props.closePopover}	
										 				updateProjectLead={this.props.updateProjectLead}						 				
													/>
										 		}		
										 		{type === 'projectMembers' &&									 			
										 			<EditProjectMembersPopover
										 				projectId={this.props.projectId}
										 				closePopover={this.props.closePopover}	
										 				members={this.props.members}
										 				leadUserId={this.props.leadUserId}
										 				updateProjectMembers={this.props.updateProjectMembers}				 				
													/>
										 		}		

										 		{type === 'projectIcon' &&
										 			<EditProjectIconPopover
										 				//defaultIconType='linearIcon' // maybe we work this out from which their proposed, backlog, and in progress projects are like
										 				defaultIconType='emoji'
										 				projectId={this.props.projectId}									 				
										 				closePopover={this.props.closePopover}
										 				icon={this.props.icon}
	        									color={this.props.color}	    
	        									updateProjectIcon={this.props.updateProjectIcon}  
													/>
										 		}

										</animated.div>
									}
								</Transition>									
							</Popover.Content>					
					</Popover.Root>
				}		
			</React.Fragment>
		)
	}
}

export default EditMetadataPopoverWrapper