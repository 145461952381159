import React from 'react'
import Icon from '../misc/Icon'

class RoadmapsHomePageProjectListControlsBtn extends React.Component{  
	render(){	
		const {iconName, tooltipLabel, label} = this.props
	
		// onClick={this.props.updateSettings} 
	  return (			
		  <button onClick={this.props.updateSettings} className={'roadmapsHomePage-projectList-sortOptions-btn ' + (this.props.active ? ' roadmapsHomePage-projectList-sortOptions-btn--active ' : ' roadmapsHomePage-projectList-sortOptions-btn--inactive ')}>
	  		{iconName && 
		  		<div className='roadmapsHomePage-projectList-sortOptions-btn-iconContainer'>
		  			<Icon name={iconName} />
		  		</div>
	  		}	  		
	  		<div className='roadmapsHomePage-projectList-sortOptions-btn-label'>
	  			{label}
	  		</div>
	  		{this.props.subLabel &&
		  		<div className='roadmapsHomePage-projectList-sortOptions-btn-subLabel'>
		  			In Progress	
		  		</div>
	  		}
	  		{tooltipLabel && 
	  			<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>																	
								<div className='roadmapsHomePage-projectList-sortOptions-btn-infoIconContainer'>
					  			<Icon name='infoOutline' />
					  		</div>
							</Tooltip.Trigger>				
							<Tooltip.Content side="top" align="start" className="tooltip tooltip--projectsSideControls">																																	
								{tooltipLabel}
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>		  		
	  		}
      </button>
	  )
	}
}


class RoadmapsHomePageProjectListControls extends React.Component{  

	render(){	
		const {sortOrder, grouping}=this.props

		const milestoneVisibility = 'startedProjects'
		// const milestoneVisibility = 'allProjects'
		// const milestoneVisibility = 'hidden'

	  return (			
		  <div className='roadmapsHomePage-projectList-sortOptions'>
		  	<div className='roadmapsHomePage-projectList-sortOptions-group roadmapsHomePage-projectList-sortOptions-group--sort'>
			  	<RoadmapsHomePageProjectListControlsBtn
	  				active={sortOrder=='alphabetical'}
	  				label='A – Z'
	  				iconName='sortAZ'
	  				updateSettings={()=>{this.props.updateSettings({sortOrder:'alphabetical',grouping:grouping})}}
	  			/>
	  			<RoadmapsHomePageProjectListControlsBtn
	  				active={sortOrder=='targetDate'}
	  				label='Target Date'
	  				iconName='calendarSimple'
	  				updateSettings={()=>{this.props.updateSettings({sortOrder:'targetDate',grouping:grouping})}}
	  			/>
			  </div>
			  <div className='roadmapsHomePage-projectList-sortOptions-group roadmapsHomePage-projectList-sortOptions-group--group'>
			  	
			  	<RoadmapsHomePageProjectListControlsBtn
	  				label='Group by Status'
	  				iconName='groupStatus'
	  				active={grouping=='status'}
	  				updateSettings={()=>{this.props.updateSettings({sortOrder:sortOrder,grouping:`${grouping=='status'?'none':'status'}`})}}
	  			/>
	  			<RoadmapsHomePageProjectListControlsBtn
	  				label='Group by Lead'
	  				iconName='groupLead'
	  				active={grouping=='lead'}
	  				updateSettings={()=>{this.props.updateSettings({sortOrder:sortOrder,grouping:`${grouping=='lead'?'none':'lead'}`})}}
	  			/>
	  			{/*}
	  			<RoadmapsHomePageProjectListControlsBtn
	  				label='Roadmap'
	  				iconName='sideMenuRoadmap'
	  				//active={grouping=='lead'}
	  				//updateSettings={()=>{this.props.updateSettings({sortOrder:sortOrder,grouping:`${grouping=='lead'?'none':'lead'}`})}}
	  			/>
	  			*/}
			  </div>

			  {/*}
			  <div className='roadmapsHomePage-projectList-sortOptions-group roadmapsHomePage-projectList-sortOptions-group--group'>
			  	
			  	{milestoneVisibility === 'startedProjects' &&
				  	<button className='roadmapsHomePage-projectList-sortOptions-btn roadmapsHomePage-projectList-sortOptions-btn--milestone'>	  		
				  		<div className='roadmapsHomePage-projectList-sortOptions-btn-iconContainer'>
				  			<Icon name="milestone" />
				  		</div>	  		
				  		<div className='roadmapsHomePage-projectList-sortOptions-btn-label'>
				  			Show All Milestones
				  		</div>	  		
	      		</button>
      		}

      		{milestoneVisibility === 'allProjects' &&
	      		<button className='roadmapsHomePage-projectList-sortOptions-btn roadmapsHomePage-projectList-sortOptions-btn--milestone'>	  		
				  		<div className='roadmapsHomePage-projectList-sortOptions-btn-iconContainer'>
				  			<Icon name="milestone" />
				  		</div>	  		
				  		<div className='roadmapsHomePage-projectList-sortOptions-btn-label'>
				  			Hide All Milestones
				  		</div>	  		
	      		</button>
      		}

      		{milestoneVisibility === 'hidden' &&
	      		<button className='roadmapsHomePage-projectList-sortOptions-btn roadmapsHomePage-projectList-sortOptions-btn--milestone'>	  		
				  		<div className='roadmapsHomePage-projectList-sortOptions-btn-iconContainer'>
				  			<Icon name="milestone" />
				  		</div>	  		
				  		<div className='roadmapsHomePage-projectList-sortOptions-btn-label'>
				  			In Progress Milestones
				  		</div>	  		
	      		</button>
      		}

	  			{/*}
	  			<RoadmapsHomePageProjectListControlsBtn
	  				label='Roadmap'
	  				iconName='sideMenuRoadmap'
	  				//active={grouping=='lead'}
	  				//updateSettings={()=>{this.props.updateSettings({sortOrder:sortOrder,grouping:`${grouping=='lead'?'none':'lead'}`})}}
	  			/>
	  			
			  </div>		  
			  */}
	  	</div>

	  )
	}
}

export default RoadmapsHomePageProjectListControls
