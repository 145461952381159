import {fetch,put,post,batchedFetch} from '../api'
import {closeArchivedTab} from '../utils/closeArchivedTab'
import store from '../store'
import { Mixpanel } from '../Mixpanel'
import {showToastNotification} from './toastNotification'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import {makeUrlForDoc} from '../utils/openDoc'
import {getDocForId} from '../utils/getDocForId'
import {backgroundOpenDoc} from '../utils/openDoc'
import {calculateDocNotificationCount} from '../utils/notifications/calculateNotificationCounts'
import {fetchFigmaEmbeds} from './figma'

export function backroundOpenDocsWithUnreads(){
	const state = store.getState()
	const docs=state.docs
	const messages=state.messages
	const recentActivities=state.recentActivities
	if(messages.length>0 && recentActivities.length>0){
		docs.forEach((doc)=>{
		const notificationCount = calculateDocNotificationCount(doc.doc_id,doc)
		if(notificationCount && notificationCount>0){
			backgroundOpenDoc(doc.doc_id)
		}
	})
	}
}

export function addLinkToLinear(docId,projectId) {
	let stem=`https://doc.yarn.so`
	const path=makeUrlForDoc(docId)  
	const url=stem+path
	const doc=getDocForId(docId)
	const requestBody={
		label:`Yarn - ${doc.name}`,
		url:url,
		projectId:projectId
	}
	return (dispatch) => post(`/docs/${docId}/create-linear-link`,requestBody)
		.then((response) => {
			dispatch({ type: 'LINK_ADDED_TO_LINEAR', docId })
			return response
		})
		.catch((error) => {
			return error
		})
}

export function createDocSnapshot(doc_id,snapshot) {
	const requestBody={snapshot:snapshot}
	return (dispatch) => post(`/docs/${doc_id}/snapshot`,requestBody)
		.then((response) => {
			return response
		})
		.catch((error) => {
			return error
		})
}

export function fetchADoc(doc_id) {
	return (dispatch) => fetch(`/docs/${doc_id}`)
		.then((response) => {
			dispatch({ type: 'FETCH_A_DOC_SUCCESS', response })
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
}

export function fetchADocHTML(doc_id) {
	return (dispatch) => fetch(`/docs/${doc_id}/static`)
		.then((response) => {
			dispatch({ type: 'FETCH_DOC_HTML_SUCCESS',docId:doc_id,html:response })
			return response
		})
		.catch((error) => {
			return error
		})
}

export function fetchDocs(isInitialLoad) {
	return (dispatch) => batchedFetch(`/docs`)
		.then((response) => {
			dispatch({ type: 'FETCH_DOCS_SUCCESS', response })
			const state = store.getState()
			if(isInitialLoad){ //check for any docs with unreads and open them
				if(state.messages && state.messages.length){
					backroundOpenDocsWithUnreads()
				}else{
					//console.log('messages havent loaded yet')
				}
			}
			return response
		})
		.catch((error) => {
			return error
		})
}

//////////// Creating docs/////////

export function createDoc() { //TODO check if we can get rid of this.  All client docs created with ID TODO check create link inline thing
	return (dispatch) => post(`/docs`)
		.then((response) => {
			dispatch({ type: 'CREATE_DOC_SUCCESS', response ,syncAction:true})
			Mixpanel.track('create_doc')
			return response
		})
		.catch((error) => {
			return error
		})
}

export function createDocWithId(doc) {
	const requestBody={
		doc:doc
	}
	return (dispatch) =>{ 
		dispatch({ type: 'CREATE_DOC_REQUEST', doc })
		 return post(`/docs`,requestBody)
			.then((response) => {
				dispatch({ type: 'CREATE_DOC_SUCCESS', response })
				Mixpanel.track('create_doc')
				return response
			})
			.catch((error) => {
				return error
			})
		 }
}

export function duplicateDocAction(docId) {
	const requestBody={
		docId:docId
	}
	return (dispatch) => post(`/docs/duplicate`,requestBody)
		.then((response) => {
			dispatch({ type: 'CREATE_DOC_SUCCESS', response ,syncAction:true})
			//refetch figma embeds so have any duplicated embeds
			dispatch(fetchFigmaEmbeds())
			Mixpanel.track('duplicate_doc',{docId:docId})
			return response
		})
		.catch((error) => {
			return error
		})
}

///Follower pusher

export function addFollowerToDoc(docId) {
	return (dispatch) => put(`/docs/${docId}/follow`)
		.then((response) => {
			const userId=response.user_id
			dispatch({ type: 'ADD_FOLLOWER_PUSHER', docId,userId})
			return response
		})
		.catch((error) => {
			return error
		})
}

export function handleNewDocFollowerPusher(docId,userId){
	return (dispatch) => {
		dispatch({ type: 'ADD_FOLLOWER_PUSHER', docId,userId})
	}
}

/////Timestamps

export function handleDocTimestampUpdatedPusher(docId,timestamp){
	return (dispatch) => {
		dispatch({ type: 'DOC_TIMESTAMP_UPDATED_PUSHER', docId,timestamp})
	}
}


//delete doc - remember to close from tabs
export function archiveDoc(docId) {
	return (dispatch) =>{ 
		dispatch({ type: 'ARCHIVE_DOC_REQUEST', docId })
		closeArchivedTab(docId)
		return put(`/docs/${docId}/archive`)
		.then((response) => {
			dispatch({ type: 'ARCHIVE_DOC_SUCCESS', docId})
			return response
		})
		.catch((error) => {
			return error
		})
	}
}


/////// Move doc //// TODO combine these

export function updateDocIsOrgDoc(docId) {
	return (dispatch) => put(`/docs/${docId}/org`)
		.then((response) => {
      const actionType="moveDocToOrg"   
      dispatch(showToastNotification(actionType))
			dispatch({ type: 'UPDATE_DOC_SUCCESS', response})
			return response
		})
}

export function updateDocRoadmap(docId,roadmapId) {
	const requestBody={
		roadmap:roadmapId
	}
	return (dispatch) => put(`/docs/${docId}/roadmap`,requestBody)
		.then((response) => {
			const userName=roadmapId
      const actionType="moveDocToRoadmap"   
      dispatch(showToastNotification(actionType,userName))
			dispatch({ type: 'UPDATE_DOC_SUCCESS', response})
			return response
		})
}



export function updateDocTeam(docId,teamId) {
	const requestBody={
		team:teamId
	}
	return (dispatch) => put(`/docs/${docId}/team`,requestBody)
		.then((response) => {
			const userName=teamId
      const actionType="moveDocToTeam"   
      dispatch(showToastNotification(actionType,userName))
			dispatch({ type: 'UPDATE_DOC_SUCCESS', response})
			const state = store.getState()
			const currentUser=state.user
			return response
		})
}

export function updateDocProject(docId,projectId) {
	const requestBody={
		project:projectId
	}
	return (dispatch) => put(`/docs/${docId}/project`,requestBody)
		.then((response) => {
			const userName=projectId
      const actionType="moveDocToProject"       
      dispatch(showToastNotification(actionType,userName))
			dispatch({ type: 'UPDATE_DOC_SUCCESS', response})
			const state = store.getState()
			const currentUser=state.user
			return response
		})
}



////// Message Typing Indicator /////////
export function sendUserTypingEvent(docId,userId) {
	const requestBody={
		userId:userId
	}
	return (dispatch) => post(`/docs/${docId}/userTyping`,requestBody)
		.then((response) => {
		})
}


/////// collab stuff
//get collab editing instance!!

///TODO manually update instance of current doc

export function updateDocInstance(docId,instance) {
	return (dispatch) =>{
		dispatch({ type: 'FETCH_COLLAB_INSTANCE_SUCCESS', instance ,docId})
	}
}



export function getDocInstance(docId) {
	return (dispatch) => fetch(`/docs/${docId}/instance`)
		.then((response) => {
			const instance=response
			dispatch({ type: 'FETCH_COLLAB_INSTANCE_SUCCESS', instance ,docId})
			return response
		})
		.catch((error) => {
			
		})
}

export function clearCollabInstanceFromState(docId) {
	return (dispatch) =>{
		dispatch({ type: 'CLEAR_COLLAB_INSTANCE',docId})

	} 
}


export function sendUserSelection(docId,data) {
	return (dispatch) => {
		return put(`/docs/${docId}/selection`,data)
		.then((response) => {
			return response
		})
	}
}


export function sendDocumentEvents(docId,data) {
	return (dispatch) => {
		return post(`/docs/${docId}/events`,data)
		.then((response) => {
			return response
		})
	}
}


export function fetchDocumentEvents(url) {
	return (dispatch) => {
		return fetch(url)
			.then((response) => {     
				return response
			})
			// .catch((error) => {
			// })
	}
}

///////// RENAMING/////// updated Feb
export function modifyDocNameLocally(docId,name) { //update doc name in redux before actually send request
	return (dispatch) =>{
		dispatch({ type: 'RENAME_DOC_LOCALLY', docId,name })
		debouncedRename(docId)
		return null
	}
}

const debouncedRename=debounce(renameDocFromState,500)

function renameDocFromState(docId) {
	const state = store.getState()
	const doc=find(state.docs,{doc_id:docId})
	let name=''
	if(doc){
		name=doc.name
	}
	const requestData={name:name}
	return put(`/docs/${docId}/name`,requestData)
		.then((response) => {
			Mixpanel.track('rename_doc',{doc_id:docId,name:name})
			return response
		})
		.catch((error) => {
			return error
		})   
}



/////templates
export function handleDocTemplatePusher(docId,template){
	return (dispatch) => {
		dispatch({ type: 'DOC_TEMPLATE_UPDATED_PUSHER', docId,template})
	}
}




//////////////////////


////////Dev ///////

export function generateDocSummary(docId,params) {
	const requestBody={
		params:params
	}
	return (dispatch) => {
		return put(`/docs/${docId}/summary`,requestBody)
		.then((response) => {
			dispatch(fetchADoc(docId))
			return response
		})
	}
}





