import store from '../store'
import includes from 'lodash/includes'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'

//Can be in multiple roadmaps
export function getIssuesForRoadmap(roadmap) {	
	
	const state = store.getState()

	let projects=roadmap.projects

	let issues=[]
	if(projects){
		projects.forEach((projectId)=>{
		const projectIssues=filter(state.issues,{project:projectId})
		// console.log(projectIssues)
		issues=issues.concat(projectIssues)


	})
	}
	

	// const roadmaps=state.roadmaps
	// roadmaps.forEach((roadmap)=>{
	// 	if(roadmap.projects){
	// 		if(includes(roadmap.projects,projectId)){
	// 			projectRoadmaps.push(roadmap)
	// 		}
	// 	}
	// })

	// 	const sortedRoadmaps = sortBy(projectRoadmaps, function(roadmap){
 //      return roadmap.name.toString().toLowerCase()
 //  })

	return issues
}

