import store from '../store'
import find from 'lodash/find'


export function getOrgMembers() {	
	
	const state = store.getState()
	// const orgMember= find(state.orgMembers, {'user_id':userId})
	// if(orgMember){
	// 	user=orgMember
	// }
	return state.orgMembers
}

