import React from 'react'
import Icon from '../../misc/Icon'
import sortBy from 'lodash/sortBy'
import languageOptions from './languageOptions'
import findIndex from 'lodash/findIndex'

class CodeMirrorLanguagePopoverRow extends React.Component{  	
	 myRef = React.createRef();

	 constructor(props){
		super(props)   
	//	this.scrollIntoViewIfNeeded = this.scrollIntoViewIfNeeded.bind(this)
	
		
	}

	 componentDidMount() {
        if (this.props.active && this.myRef.current) {
            this.myRef.current.scrollIntoView({ behavior: "instant",block:"center"});
        }
    }

     componentDidUpdate(prevProps) {
        if (this.props.active && !prevProps.active) {
             // this.myRef.current.scrollIntoView({ behavior: "instant"});
          //   this.scrollIntoViewIfNeeded()
        }
    }

    scrollIntoViewIfNeeded() {
        const node = this.myRef.current;
        if(node) {
            const rect = node.getBoundingClientRect();
            if (rect.bottom > window.innerHeight || rect.top < 0) {
                node.scrollIntoView({ behavior: "instant"});
            }
        }
    }


	render(){	
		const {active,language} = this.props		
		return (
			<div id={`language_row_${language.language}`} ref={this.myRef} onClick={()=>{this.props.changeLanguage(language)}} data-state={active ? "active" : "inactive"} className='popover-item popover-item--codeMirrorLanguageSelection'>
				<div className='popover-item-label'>
					{language.label}
				</div>
			</div>
			
		)
	}
}


class CodeMirrorLanguagePopoverContents extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleChange = this.handleChange.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)	
		const {currentLanguage}=props

		let activeIndex=findIndex(languageOptions,(option => {
  		return option.language == currentLanguage
  		}))
		console.log(`initial active index is---------`)
		console.log(activeIndex)
		this.state={      
			value: '',
			activeIndex:activeIndex,	
			filteredLanguageOptions:languageOptions
		}
		  this.refArray = [];
	}

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)	
	}
	
	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}


	// scrollIntoView(language){

	// }

	handleKeyDown(e){
		if(e.keyCode==40){ //down arrow
			e.preventDefault()
			const {activeIndex,filteredLanguageOptions,value}=this.state
			let newIndex=null
			if(filteredLanguageOptions.length>0){
				if(activeIndex==null || activeIndex==filteredLanguageOptions.length-1){
					newIndex=0
				}else{
					newIndex=activeIndex+1
				}
			}
			console.log('new index is-----------')
			console.log(newIndex)
			this.setState({activeIndex:newIndex})
		}else if(e.keyCode==38){ //UP arrow
			e.preventDefault()
			const {activeIndex,filteredLanguageOptions,value}=this.state
			let newIndex=null
			if(filteredLanguageOptions.length>0){
				if(activeIndex==null || activeIndex==0){
					newIndex=filteredLanguageOptions.length-1
				}else{
					newIndex=activeIndex-1
				}
			}
			this.setState({activeIndex:newIndex})
		}else if(e.keyCode==13){ //enterKey
			const {activeIndex,filteredLanguageOptions}=this.state
			const newLang=filteredLanguageOptions[activeIndex]
			this.props.changeLanguage(newLang)
		}
	}

	handleChange(event) {
		let activeIndex=null
		const value=event.target.value
		if(value){
			activeIndex=0
		}else{
			activeIndex=findIndex(languageOptions,(option => {
  		return option.language == this.props.currentLanguage
  		}))
		}
		this.setState({value: value,activeIndex:activeIndex});
		let filteredLanguageOptions = languageOptions.filter(language => {
    	return language.label.toLowerCase().includes(value.toLowerCase());
		});
		this.setState({filteredLanguageOptions:filteredLanguageOptions})
	}


	render(){	
		const {currentLanguage} = this.props
		const {value,suggestions,activeIndex,filteredLanguageOptions} = this.state
		let results=suggestions

		//const languageOptions = ['ABAP','Agda','Arduino','Assembly','Bash','BASIC','BNF','C','C#','C++','Clojure','CoffeeScript','Coq','CSS','Dart','Dhall','Diff','Docker','EBNF','Elixir','Elm','Erlang','F#','Flow','Fortran','Gherkin','GLSL','Go','GraphQL','Groovy','Haskell','HTML','Idris','Java','JavaScript','JSON','Julia','Kotlin','LaTeX','Less','Lisp','LiveScript','LLVM IR','Lua','Makefile','Markdown','Markup','MATLAB','Mathematica','Mermaid','Nix','Objective-C','OCaml','Pascal','Perl','PHP','Plain Text','PowerShell','Prolog','Protobuf','PureScript','Python','R','Racket','Reason','Ruby','Rust','Sass','Scala','Scheme','Scss','Shell','Solidity','SQL','Swift','TOML','TypeScript','VB.Net','Verilog','VHDL','Visual Basic','WebAssembly','XML','YAML'];

		return (
			<React.Fragment>
				<div className='codeMirrorLanguagePopover-search'>
					<input 													
						className='codeMirrorLanguagePopover-search-input'
						placeholder='Search Languages'
						value={this.state.value}
						onChange={this.handleChange} 				
						autoComplete="off"
						autoCorrect="off"
						spellCheck={false}
						autoFocus							
					/>					    
					<div className='codeMirrorLanguagePopover-search-ui'>
						<div className='codeMirrorLanguagePopover-search-ui-iconContainer'>
							<Icon name='magnifyingGlass' />
						</div>
					</div>
				</div>
				<div className='codeMirrorLanguagePopover-list'>
					{filteredLanguageOptions.map((language, index) =>{
					//	let active=language.language==currentLanguage 
					
						let	active=activeIndex==index
						
						return(
	          <CodeMirrorLanguagePopoverRow 
		        	key={index}
		        	language={language}
		        	changeLanguage={this.props.changeLanguage}
		        	active={active}
		        />
		        )

					}

						
	        )}
        </div>

        

			</React.Fragment>
		)
	}
}

export default CodeMirrorLanguagePopoverContents