import React from 'react'
import ReactDOM from 'react-dom'
import TextLinkDetailsAnchorReactComponent from './TextLinkDetailsAnchorReactComponent'
import {getRootDocIdForProject} from '../../../utils/getRootDocIdForProject'
import {openIssueInLinear} from '../../../utils/openInLinear'
import {getIssueForId} from '../../../utils/getIssueForId'

const OPEN_HOVER_MENU_DELAY=300
const CLOSE_HOVER_MENU_DELAY=300
const SET_DISPLAY_NONE_DELAY=500

class TextLinkNodeView {

	constructor(node, view, getPos,handleOpenDoc) {
		// this.handleClickLink=this.handleClickLink.bind(this)
		this.saveUpdatedLinkValues=this.saveUpdatedLinkValues.bind(this)
		this.updateView=this.updateView.bind(this)
		this.removeLink=this.removeLink.bind(this)
		this.closeEditMenu=this.closeEditMenu.bind(this)
		this.showEditMenuFromHoverMenu=this.showEditMenuFromHoverMenu.bind(this)
		this.openLink=this.openLink.bind(this)
		this.openHoverMenu=this.openHoverMenu.bind(this)
		this.closeHoverMenu=this.closeHoverMenu.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.saveUpdatedTitle=this.saveUpdatedTitle.bind(this)

		this.handleOpenDoc=handleOpenDoc

    this.node = node;
    this.view = view;
    this.getPos = getPos;

		this.dom = document.createElement("span")
		this.dom.classList.add('doc-textLink')
		this.dom.addEventListener('click',this.openLink)
		this.dom.addEventListener('mouseenter',this.openHoverMenu)
		this.dom.addEventListener('mouseleave',this.closeHoverMenu)

		this.popoverAnchorSpan = document.createElement('span')
		this.popoverAnchorSpan.contentEditable=false
		this.popoverAnchorSpan.classList.add('doc-textLink-popoverAnchor')
		this.popoverAnchorSpan.style.display='none'
		this.dom.appendChild(this.popoverAnchorSpan)

	
		const contentDOM = document.createElement("span")
		contentDOM.contentEditable = true
		// contentDOM.addEventListener('click',this.handleClickLink)
		this.contentDOM=contentDOM
		this.dom.appendChild(this.contentDOM);
		

		this.hoverMenuIsVisible=false
		this.forceHideHoverMenu=false //oveeride hover thing if e.g. open edit modal
		this.editMenuIsVisible=false

		this.closeHoverMenuTimeout=null
		this.openHoverMenuTimeout=null
		this.setVisibilityNonTimeout=null
		this.removedLink=false
		this.updateView()
	}


	handleKeyDown(e){
		// if(this.listenForKeyDown){
		// 	if(e.keyCode==39 || e.keyCode==37){ //on left/right arrow show modal
  // 		if(!this.editMenuIsVisible){
  // 			this.editMenuIsVisible=true 
		// 		this.updateView()
  // 		}
  // 	}
		// }
	}

	openHoverMenu(){
		if(this.setVisibilityNonTimeout){
			clearTimeout(this.setVisibilityNonTimeout)
		}
		this.popoverAnchorSpan.style.display='block'		
		if(!this.forceHideHoverMenu){
			if(this.closeHoverMenuTimeout){
				clearTimeout(this.closeHoverMenuTimeout)
			}
			this.openHoverMenuTimeout=setTimeout(function() {
				this.hoverMenuIsVisible=true 
				this.updateView()
			}.bind(this), OPEN_HOVER_MENU_DELAY)
		}
	}

	closeHoverMenu(e){
		if(this.openHoverMenuTimeout){
			clearTimeout(this.openHoverMenuTimeout)
		}
		this.closeHoverMenuTimeout=setTimeout(function() {
			this.hoverMenuIsVisible=false 
			this.updateView()
		}.bind(this), CLOSE_HOVER_MENU_DELAY)
	}

	closeEditMenu(){
		this.hoverMenuIsVisible=false
		this.editMenuIsVisible=false 
		this.forceHideHoverMenu=false
		this.updateView()
	}


	showEditMenuFromHoverMenu(e){
		e.stopPropagation()
		this.editMenuIsVisible=true 
		this.forceHideHoverMenu=true 
		this.hoverMenuIsVisible=false
		this.updateView()
	}

 	saveUpdatedLinkValues(title,type,url,docId,projectId,issueId){
 		let state=window.view.state
		let tr = state.tr
		const pos=this.getPos()
		const attrs={
			type:type,
			url:url,
			docId:docId,
			projectId:projectId,
			issueId:issueId
		}
		tr.setNodeMarkup(pos, null, attrs)
		if(title){
			tr.insertText(title,pos+1,pos+this.node.nodeSize)
		}
		window.view.dispatch(tr)
		this.closeEditMenu()
 	}

 	saveUpdatedTitle(title){
 		if(title && !this.removedLink){
 			let state=window.view.state
			let tr = state.tr
			const pos=this.getPos()
			tr.insertText(title,pos+1,pos+this.node.nodeSize)
			window.view.dispatch(tr)
			this.closeEditMenu()
 		}
 	}

 	removeLink(e){
 		this.removedLink=true
		e.stopPropagation()
		e.preventDefault()
		let tr = window.view.state.tr
		const pos=this.getPos()
		tr.replaceWith(pos,pos+this.node.nodeSize,this.node.content)
		window.view.dispatch(tr)
 	}

	updateView(){
		const {type,url,docId,projectId,issueId}=this.node.attrs
		ReactDOM.render(
				<TextLinkDetailsAnchorReactComponent 		
					removeLink={this.removeLink}
					hoverMenuIsVisible={this.hoverMenuIsVisible}	
					openHoverMenu={this.openHoverMenu}
					closeHoverMenu={this.closeHoverMenu}
					editMenuIsVisible={this.editMenuIsVisible}
					showEditMenuFromHoverMenu={this.showEditMenuFromHoverMenu}
					editMenuIsVisible={this.editMenuIsVisible}
					closeEditMenu={this.closeEditMenu}
					type={type}
					url={url}
					docId={docId}
					projectId={projectId}
					issueId={issueId}
					title={this.contentDOM.textContent}
					saveUpdatedLinkValues={this.saveUpdatedLinkValues}
					saveUpdatedTitle={this.saveUpdatedTitle}
				/>, 
			this.popoverAnchorSpan)

		if(!this.hoverMenuIsVisible && !this.editMenuIsVisible){
			if(this.setVisibilityNonTimeout){
				clearTimeout(this.setVisibilityNonTimeout)
			}
			this.setVisibilityNonTimeout=setTimeout(function() {
				this.popoverAnchorSpan.style.display='none'
				}.bind(this), SET_DISPLAY_NONE_DELAY
			)
		}
	}


	openLink(){
		const attributes=this.node.attrs
		if(attributes.type=='weblink'){
			const url=attributes.url
			if(window.isElectron){ 
				window.ipcRenderer.send('open-external-link',{url:url})     
			}
			else{
				window.open(url,'_blank')
			}
		}
		else if(attributes.type=='project'){
			const projectDocId=getRootDocIdForProject(attributes.projectId)
			this.handleOpenDoc(projectDocId)
		}
		else if(attributes.type=='doc'){
			this.handleOpenDoc(attributes.docId)
		}
		else if(attributes.type=='issue'){
			const issue=getIssueForId(attributes.issueId)
			if(issue){
				openIssueInLinear(issue.identifier)
			}
		}

	}

	update(node,decorations) {
		if (node.type !== this.node.type) return false
		this.node=node
		this.updateView()
		return true
	}
	
	destroy() {
		if(this.closeHoverMenuTimeout){
			clearTimeout(this.closeHoverMenuTimeout)
		}
		if(this.openHoverMenuTimeout){
			clearTimeout(this.openHoverMenuTimeout)
		}
		if(this.setVisibilityNonTimeout){
			clearTimeout(this.setVisibilityNonTimeout)
		}
		this.dom.removeEventListener('mouseenter',this.openHoverMenu)
		this.dom.removeEventListener('mouseleave',this.closeHoverMenu)
		this.dom.removeEventListener('click',this.openLink)
		ReactDOM.unmountComponentAtNode(this.popoverAnchorSpan)
	}

	ignoreMutation(mutation){
		// console.log(mutation)
		// console.log(mutation.type)
		// console.log('ignore mutation??')
		// console.log(mutation)
		return true
	}

	// stopEvent(event){ //try and stop click selecting doc

	// 	return false
 //  }

}

export default TextLinkNodeView