import store from '../../../../store'
import MiniSearch from 'minisearch'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
//If no search results return segmented results
//issues
//projects
//docs

let minisearchDocs = new MiniSearch({
	fields: ['name'],
	storeFields: ['name', 'project', 'doc_html', 'is_project_doc', 'doc_id','is_org_doc','roadmap'],
	idField: 'doc_id',
})

let minisearchIssues = new MiniSearch({
	fields: ['title','identifier'],
	storeFields: ['issue_id','title','priority','identifier','project','description','assignee'],
	idField: 'issue_id',
})

let minisearchProjects = new MiniSearch({
	fields: ['name'],
	storeFields: ['project_id','name', 'lead','description','icon','color','members','teams'],
	idField: 'project_id',
})


export function reindexSearch() {
	const state = store.getState()
	
	state.docs.forEach((doc)=>{
		if(!doc.is_project_doc){
			if(doc.roadmap || doc.project || doc.is_org_doc){ //i.e. not private docs
				if(minisearchDocs.has(doc.doc_id)){
					minisearchDocs.replace(doc)
				}else{
					minisearchDocs.add(doc)
				}
			}
		}
	})

	state.issues.forEach((issue)=>{
		if(minisearchIssues.has(issue.issue_id)){
			minisearchIssues.replace(issue)
		}else{
			minisearchIssues.add(issue)
		}
	})

	state.projects.forEach((project)=>{
		if(minisearchProjects.has(project.project_id)){
			minisearchProjects.replace(project)
		}else{
			minisearchProjects.add(project)
		}
	})
	return
}


// //if there is no search term return defaults
// function getDefaultSuggestions(){
// 	const state = store.getState()
// 	const docs=state.docs
// 	const issues=state.issues
// 	const projects=state.projects
// 	//////////default values
// 	let defaultIssues=[]
// 	let defaultProjects=[]
// 	let defaultDocs=[]
	

// 	let segmentedResults={
// 		issues:defaultIssues,
// 		projects:defaultProjects,
// 		docs:defaultDocs,
// 	}
// 	let combined=segmentedResults.issues.concat(segmentedResults.projects,segmentedResults.docs)
// 	return{
// 		segmented:segmentedResults,
// 		combined:combined
// 	}
// }

//TODO clean up combined/segmented stuff
///TODO reindex
function getSugestionsForSearchTerm(searchTerm){
//	const state = store.getState()
	// if(minisearchDocs._documentCount == 0){
 //   	const docs = filter(state.docs,function(doc) {
 //   		if(doc.is_project_doc==false){
 //   			if(doc.project || doc.roadmap || doc.is_org_doc){
 //   				return doc
 //   			}
 //   		}
 // 		});
	// 	// const docs=filter(state.docs,{is_project_doc:false})
	// 	minisearchDocs.addAll(docs)
	// }
	// if(minisearchIssues._documentCount == 0){
	// 	const issues=state.issues
	// 	minisearchIssues.addAll(issues)
	// }
	// if(minisearchProjects._documentCount == 0){
	// 	const projects=state.projects
	// 	minisearchProjects.addAll(projects)
	// }
	// console.log(searchTerm)
	// console.log(minisearchIssues)

/////////

	const docResults=minisearchDocs.search(searchTerm, {prefix:true, fuzzy:0.01,boost:{name:5}})
	const issueResults=minisearchIssues.search(searchTerm,{prefix:true, fuzzy:0.01,boost:{title:1}})
	const projectResults=minisearchProjects.search(searchTerm, {prefix:true, fuzzy:0.01,boost:{name:5}})




	//let combinedResults=issueResults

	let combinedResults=docResults.concat(issueResults,projectResults)
	const sorted=sortBy(combinedResults,'score').reverse()
	// let segmentedResults={
	// 	issues:[],
	// 	projects:[],
	// 	docs:[],
	// }
	// let results={
	// 	segmented:segmentedResults,
		//combined:sorted.slice(0,10)
	// }

	return sorted.slice(0,10)
}

export function getSuggestions(text,done){

	let suggestions 
	if(!text){
	//	suggestions=getDefaultSuggestions()
		suggestions=[]
	}else{
		suggestions=getSugestionsForSearchTerm(text)

	}
	if(done){
		done(suggestions)
	}else return suggestions
	
}