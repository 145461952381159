import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import RecentPage from '../components/recent/RecentPage'
import moment from 'moment'
import ReactGA from 'react-ga4'
import {fetchDocs} from '../actions/docs'



class RecentPageContainer extends Component {

	componentDidMount(){
		this.props.fetchDocs()
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Recent" });
		}
	}

	render() {
		return (				
				<div className={'recentPageContainer ' + (this.props.sideMenuVisible ? ' recentPageContainer--sideMenuState--visible ' : ' recentPageContainer--sideMenuState--hidden ') + (window.isElectron ? 'recentPageContainer--electron' : 'recentPageContainer--web')}>
					<RecentPage
						docs={this.props.docs}
					/>		
				</div>				
			)		
	}
}

function mapStateToProps(state,ownProps) {
	// const sortedDocs=sortBy(state.docs,function(doc){
	// 		return doc["updated_at"]
	// 	}).reverse()



	//1 sort alphabetically


	// //round to the nearest minute and then sort alphabetically
	// const sortedDocs=sortBy(state.docs,function(doc){
	// 		console.log(doc.updated_at)
	// 		const momentTime=moment(doc.updated_at)
	// 		const rounded=momentTime.startOf('minute')
	// 		console.log(rounded.toString())

	// 		return rounded
	// }).reverse()
	

	const sortedDocs= orderBy(state.docs, [function (doc) {
		// console.log(doc.updated_at)
		const momentTime=moment(doc.updated_at)
		const rounded=momentTime.startOf('minute')
		// console.log(rounded.toString())
		return (rounded)
      }, function (doc) {
        return doc.name.toString().toLowerCase()
      }], ['desc', 'asc'])



	return {
		docs:sortedDocs
	}
}

export default withRouter(connect(mapStateToProps,
	{	fetchDocs
	}
)(RecentPageContainer))


	