import store from '../store'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'
import {makeUrlForDoc} from './openDoc'
//if is active tab open tab above it in list else tab below else library

function maybeDeleteTranscript(docId){
	//check if have a transcript for the doc in redux and if so delete it to stop redux getting clogged up
	const state = store.getState()
	const doc = find(state.docs,{doc_id:docId})
	if(doc.meeting){
		const recording = find(state.recordings,{meeting:doc.meeting})
		if(recording){
			const transcriptId=recording.transcript_id
			store.dispatch({type:'DELETE_TRANSCRIPT_FROM_REDUX',transcriptId:transcriptId})
		}
	}
}


//open tab above in that tab group
//else open tab below in tab group
//else move to next tab group
//else if only tab then go to recent

export function closeTab(e,active,history,tabToClose) {
	e.stopPropagation()
	const state = store.getState()
	const openTabs=state.openTabs
	const {tabGroups,orderedTabGroups}=openTabs
	let newTabGroups=Object.assign({},tabGroups)
	let containingGroupId
	let inGroupIndex
	Object.keys(tabGroups).forEach((groupId,i)=>{
		const tabs=tabGroups[groupId]
		tabs.forEach((tab,index)=>{
			if(tab.id==tabToClose.id){
				inGroupIndex=index
				containingGroupId=groupId
			}			
		})
	})
		
	let newActiveTab
	const tabs=tabGroups[containingGroupId]
	if(inGroupIndex!=0){
		newActiveTab=tabs[inGroupIndex-1]
	}else if(tabs.length!=1){
		newActiveTab=tabs[inGroupIndex+1]
	}else{
		//no new active tab in this tab group- check the next one
		if(containingGroupId=='default'){
			const starredTabs=tabGroups['starred']
			if(starredTabs.length!=0){
				newActiveTab=starredTabs[starredTabs.length-1]
			}
		}else if(containingGroupId=='starred'){
			const defaultTabs=tabGroups['default']
			if(defaultTabs.length!==0){
				newActiveTab=defaultTabs[0]
			}
		}
	}

	let newTabGroup
	if(tabs.length===1){
		newTabGroup=[]
	}else{
		newTabGroup=[
			...tabs.slice(0,inGroupIndex),
			...tabs.slice(inGroupIndex + 1)
		]	
	}
	newTabGroups[containingGroupId]=newTabGroup
	store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
	if(active){
		if(newActiveTab){
			let url
			if(newActiveTab.type=='doc'){
				url=makeUrlForDoc(newActiveTab.id)
			}else{
				url=`/roadmaps/${newActiveTab.id}`
			}
			history.push(url)
		}else{
			history.push('/recent')
		}
	}
	if(tabToClose.type=='doc'){
		maybeDeleteTranscript(tabToClose.id)
	}

}
////TODO proper urls for previous