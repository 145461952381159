import React from 'react'
import Icon from '../../misc/Icon'
import sortBy from 'lodash/sortBy'
import AvatarImage from '../../AvatarImage'
import ToDoItemManageAssigmentPopover from './ToDoItemManageAssigmentPopover'
import {getUserForId} from '../../../utils/getUserForId'
import {getOrgMembers} from '../../../utils/getOrgMembers'
import ToDoItemAssigneeList from './ToDoItemAssigneeList'
import { Transition, Spring, animated, config } from 'react-spring'
//suppress annoying warning
//https://stackoverflow.com/questions/49582004/rendering-contenteditable-component-without-getting-contenteditablewarning


class ToDoItemAssigneesReactComponent extends React.PureComponent{  

		constructor(props){
	    super(props)
	    this.state={
	      showManageAssignmentPopover: false,
	      mouseOver: false,
	    }
	  }

	  onAddButtonClick(){
	  	if(!this.state.showManageAssignmentPopover){
	  		this.setState({showManageAssignmentPopover: true})
	  	}
	  }


		render(){ 
			// console.log('assignees react component render!!!')
			// console.log(this.props.usersVersion)

			const {assignees} = this.props
			
			const {showManageAssignmentPopover, mouseOver} = this.state

			const hasAssignees = assignees.length > 0
			const orgMembers = getOrgMembers()
			return (	
				<div contentEditable="false" suppressContentEditableWarning={true} className={'todoItem-assigned ' + (hasAssignees ? ' todoItem-assigned--hasAssignees ' : ' todoItem-assigned--noAssignees ')}>			
					<ToDoItemManageAssigmentPopover																									
						showPopover={showManageAssignmentPopover}						
						openPopover={()=>{this.setState({showManageAssignmentPopover: true})}}
						closePopover={()=>{this.setState({showManageAssignmentPopover: false})}}
						userList={orgMembers}					
						updateAssignees={this.props.updateAssignees}
						assignees={assignees}
					>		
						<div className='todoItem-assigned-popoverBtn' />								
					</ToDoItemManageAssigmentPopover>					
					<div onClick={()=>{this.setState({showManageAssignmentPopover: true})}} className={'todoItem-assigned-addButton ' + (showManageAssignmentPopover ? ' todoItem-assigned-addButton--menuOpen ' : ' todoItem-assigned-addButton--menuClosed ')}>
						<Icon name='plus' />
						<div className='todoItem-assigned-plus-tooltip tooltip'>								
							Assign
						</div>
					</div>						
					<div onMouseEnter={()=>{this.setState({mouseOver: true})}} onMouseLeave={()=>{this.setState({mouseOver: false})}} className={'todoItem-assigned-listContainer '  + (mouseOver ? ' todoItem-assigned-listContainer--mouseOver ' :'') + (showManageAssignmentPopover ? ' todoItem-assigned-listContainer--menuOpen ' : ' todoItem-assigned-listContainer--menuClosed ' )} >
						<ToDoItemAssigneeList			
							orgMembers={orgMembers}						
							assignees={assignees}
							mouseOver={mouseOver}
							isChecked={this.props.isCheckedProp}
							showManageAssignmentPopover={showManageAssignmentPopover}
							openPopover={()=>{this.setState({showManageAssignmentPopover: true})}}
						/>								
					</div>		
				</div>
			)		
	}
}

export default ToDoItemAssigneesReactComponent
