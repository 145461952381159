import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

class MessageHoverMenuDropdown extends React.Component{  

	render(){	

		const {showDropdown, closeDropdown, openDropdown} = this.props

		return (	  	
			<DropdownMenu.Root open={showDropdown}>		    
				<DropdownMenu.Trigger asChild>
					{this.props.children}						
		    </DropdownMenu.Trigger>
		    <DropdownMenu.Content 
		    	className='dropdownMenu dropdownMenu--commentReactionsMenu'
		    	onEscapeKeyDown={closeDropdown}
				  onPointerDownOutside={closeDropdown}
				  onInteractOutside={closeDropdown}
		    >
					<DropdownMenu.Item disabled className='dropdownMenu-item dropdownMenu-item--disabled'>
						Copy Link
					</DropdownMenu.Item>
					{/*{!this.props.isCurrentUser && 
						<DropdownMenu.Item disabled onClick={this.props.deleteMessage} className='dropdownMenu-item dropdownMenu-item--disabled'>
							Discuss in Slack
						</DropdownMenu.Item>
					}*/}

					{this.props.isCurrentUser &&      	
						<DropdownMenu.Separator />
					}

					{this.props.isCurrentUser &&      	
						<DropdownMenu.Item onClick={this.props.deleteMessage} className='dropdownMenu-item--warning'>
							Delete Message
						</DropdownMenu.Item>
					}	      	
		    </DropdownMenu.Content>
		  </DropdownMenu.Root>				
		)
	}
}

class MessageHoverMenuButton extends React.Component{  

	render(){	

		return (	  	
			<React.Fragment>
				<Tooltip.Provider>
					<Tooltip.Root delayDuration={100}>
						<Tooltip.Trigger onClick={this.props.onClickFunction} className='message-hoverMenu-btn'>
							{this.props.iconName && <Icon name={this.props.iconName} />	}
							{this.props.text && <span>{this.props.text}</span>	}
						</Tooltip.Trigger>
						{this.props.tooltipLabel &&
							<Tooltip.Content side="top" className="tooltip">
								{this.props.tooltipLabel}
							</Tooltip.Content>
						}
					</Tooltip.Root>
				</Tooltip.Provider>
			</React.Fragment> 	
		)
	}
}


class MessageHoverMenu extends React.Component{

	constructor(){
    super()
    this.state={
      showDropdown: false,
    }
  }

	render(){	
		const {channel, isCurrentUser,reactionDefinitions,autoCreated,threadsEnabled} = this.props
		const {showDropdown} = this.state

		return (	  	
			<div className={'message-hoverMenu ' + (showDropdown ? ' message-hoverMenu--menuOpen ' : ' message-hoverMenu--menuClosed ' )}>
				<div className='message-hoverMenu-inner'>	  										
					{reactionDefinitions.map((definition,i)=>{
						return(
							<MessageHoverMenuButton
								key={`hover-button-${i}`}
								text={definition.emoji}	  					  				
								onClickFunction={()=>{this.props.addReactionToMessage(definition.type,this.props.messageId,this.props.docId)}}				  				
							/>
							)
						})
					}
					
					{/*
					{!isCurrentUser &&		  			
						<MessageHoverMenuButton		  				
							iconName="slackIcon"
							tooltipLabel="Discuss in Slack"
							onClickFunction={this.props.startSlackConversation}
						/>
					}
					*/}

					{!autoCreated && 
						<MessageHoverMenuButton
							iconName='reply'
							tooltipLabel="Reply"
							onClickFunction={this.props.setNewMessageParentMessage}
						/>
					}
					
			
					{threadsEnabled && !this.props.isInThread && !this.props.isThreadParent && !this.props.isInDocThread &&
						<MessageHoverMenuButton
							iconName='threadRegular'
							tooltipLabel="Reply in Thread"
							onClickFunction={this.props.replyInThread}
						/>
					}
							{/* ADD THREADS SOON}
					*/}

					
					{!autoCreated && 
			    	<MessageHoverMenuDropdown
							showDropdown={showDropdown}
							openDropdown={()=>{this.setState({showDropdown: true})}}
							closeDropdown={()=>{this.setState({showDropdown: false})}}
							isCurrentUser={isCurrentUser}
							deleteMessage={this.props.deleteMessage}		
						>	  	
							<button onClick={()=>{this.setState({showDropdown: true})}} className='message-hoverMenu-btn'>
								<Icon name='ellipses' />								
			    		</button>
						</MessageHoverMenuDropdown>
		    	}



					
				</div>		
			</div>	  			  	
		)
	}
}

export default MessageHoverMenu