import store from '../store'
import find from 'lodash/find'


export function getOrgName() {	
	let name=''
	const state = store.getState()
	const org=state.organization
	if(org){
		name=org.name
	}
	return name 
}
