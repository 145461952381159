import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import {syncRoadmaps} from '../actions/roadmaps'
import {fetchDocs} from '../actions/docs'
import RoadmapPage from '../components/roadmaps/RoadmapPage'
import {updateProjectPageSetting} from '../utils/updateProjectPageSetting'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import ReactGA from 'react-ga4'



const PROJECTS_CUTOFF=15 //exclude projects completed more than 14 days ago

function filterProjects(projects){
	let filteredProjects=filter(projects,function(project){
		if(project.state=='completed'){
			let dur=moment.duration( moment().diff(moment(project.completed_at)))
			const days=dur._data.days
			if(days<PROJECTS_CUTOFF){
				return project
			}	
		}else return project
	})
	return filteredProjects	
} 


function getSortedGroupedProjects(projects){
	let sortedAndGrouped=[]
	const projectStates=['started','planned','backlog','completed']
	projectStates.forEach((state)=>{
		const projectsForState=filter(projects,{state:state})
		let sortedProjectsForState=[]
		sortedProjectsForState=sortBy(projectsForState, function(project){
			return project.name.toString().toLowerCase()
		})
		sortedAndGrouped.push(...sortedProjectsForState)
	})
	return sortedAndGrouped
}


class RoadmapPageContainer extends Component {


	constructor(){
		super()		
		this.updateShowAllCompleted=this.updateShowAllCompleted.bind(this)
	}

	componentDidMount(){
		this.props.syncRoadmaps()	
		this.props.fetchDocs()
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Roadmap Page" });
		}
	}



	updateShowAllCompleted(){
		const {projectPageSettings,roadmap}=this.props
		let showAllCompleted=false
		if(projectPageSettings[roadmap.roadmap_id]){
			showAllCompleted=projectPageSettings[roadmap.roadmap_id].showAllCompleted||false
		}
		const newValues={
			showAllCompleted:!showAllCompleted
		}
		updateProjectPageSetting(roadmap.roadmap_id,newValues)
	}

	render() {
		const {roadmap,projects,docs,projectPageSettings,completedProjectsCount}=this.props
		
		let showAllCompleted=false
		if(projectPageSettings[roadmap.roadmap_id]){
			showAllCompleted=projectPageSettings[roadmap.roadmap_id].showAllCompleted||false
		}
		let filteredProjects=filterProjects(projects)

		let sortedProjects
		if(showAllCompleted){
			sortedProjects=getSortedGroupedProjects(projects)
		}else{
			sortedProjects=getSortedGroupedProjects(filteredProjects)
		}
		return (				
				<div id="roadmapPageScrollContainer"  className={'roadmapPageContainer '  + (this.props.sideMenuVisible ? ' roadmapPageContainer--sideMenuState--visible ' : ' roadmapPageContainer--sideMenuState--hidden ') + (this.props.isSearchPreview ? ' roadmapPageContainer--searchPreview ' : '') + (window.isElectron ? 'roadmapPageContainer--electron' : 'roadmapPageContainer--web')}>			
					<RoadmapPage 
						roadmap={roadmap}
						projects={sortedProjects}
						docs={docs}
						completedProjectsCount={completedProjectsCount}
						showAllCompleted={showAllCompleted}
						updateShowAllCompleted={this.updateShowAllCompleted}
						hiddenProjectsCount={projects.length-filteredProjects.length}
						roadmapImageUploads={this.props.roadmapImageUploads}
					/>	
				</div>				
			)		
	}
}

function mapStateToProps(state,ownProps) {
	const roadmapId=ownProps.match.params.id || ownProps.roadmapId //for search preview
	const roadmap=find(state.roadmaps, {roadmap_id:roadmapId})

	let projects=[]
	// let docs=sortBy(filter(state.docs,{roadmap:roadmapId}),function(doc){
	// 		return doc["updated_at"]
	// 	}).reverse()

	let roadmapDocs=filter(state.docs,{roadmap:roadmapId})


	const sortedDocs= orderBy(roadmapDocs, [function (doc) {
		// console.log(doc.updated_at)
		const momentTime=moment(doc.updated_at)
		const rounded=momentTime.startOf('minute')
		// console.log(rounded.toString())
		return (rounded)
      }, function (doc) {
        return doc.name.toString().toLowerCase()
      }], ['desc', 'asc'])




	if(roadmap && roadmap.projects){
		roadmap.projects.forEach((roadmapProject)=>{
			const project=find(state.projects,{project_id:roadmapProject})
			if(project){
				projects.push(project)
			}
		})
	}

	const completedProjectsCount=filter(projects,{state:'completed'}).length
	const roadmapImageUploads=state.roadmapImageUploads[roadmapId]

	return {
		roadmap:roadmap||{},
		projects:projects,
		docs:sortedDocs,
		projectPageSettings:state.projectPageSettings,
		completedProjectsCount:completedProjectsCount,
		roadmapImageUploads:roadmapImageUploads
	}
}

export default withRouter(connect(mapStateToProps,
	{	syncRoadmaps,
		fetchDocs
	}
)(RoadmapPageContainer))