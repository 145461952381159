import React from 'react'
import LinearProjectStatusIcon from './LinearProjectStatusIcon'


class LinearProjectStatusTag extends React.PureComponent{    

  render(){ 
    const {projectStatus} = this.props

    let projectStatusLabel = projectStatus    
    if(projectStatus === 'started'){
      projectStatusLabel = 'in progress'
    }
        
    return (
      <div className={'linearProjectStatusTag ' + (projectStatus ? `linearProjectStatusTag--status--${projectStatus}` : '')}>
        <div className='linearProjectStatusTag-markerContainer'>
          <LinearProjectStatusIcon 
            projectStatus={projectStatus}
          />
        </div>
        <div className='linearProjectStatusTag-label'>
          {projectStatusLabel}
        </div>                
        <div className='linearProjectStatusTag-bg'/>
      </div>
    )
  }
}

export default LinearProjectStatusTag
