import React from 'react'
// import { withRouter} from 'react-router-dom'
import Message from './Message'
import { connect } from 'react-redux'
import {NodeSelection} from "prosemirror-state"
import MessageInputBox from './messageInputBox/MessageInputBox'
import MessagePanelDivider from './MessagePanelDivider'
import {getUserForId} from '../../../utils/getUserForId'
import {isCurrentUser} from '../../../utils/isCurrentUser'
import {formatDate} from '../../../utils/formatDate'
import Icon from '../../misc/Icon'
import find from 'lodash/find'
import moment from 'moment'
import MessagePanelHeader from './MessagePanelHeader/MessagePanelHeader'
import MessagePanelThreadHeader from './MessagePanelThreadHeader'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import MessageInputParentMessage from './messageInputBox/MessageInputParentMessage'
import MessagePanelMentionTipPreloadMessage from './MessagePanelMentionTipPreloadMessage'
import MessagePanelFooterFollowBox from './MessagePanelFooterFollowBox'
import {showToastNotification} from '../../../actions/toastNotification'
import store from '../../../store'
import {followDoc} from '../../../utils/followDoc'
import {getOrgThreadsEnabled} from '../../../utils/getOrgThreadsEnabled'
import {currentUserIsInThread} from '../../../utils/threads/currentUserIsInThread'
import { Mixpanel } from '../../../Mixpanel'
import {makeUrlForDoc,makeUrlForThread} from '../../../utils/openDoc'
import qs from 'qs'
import RecordingMessage from './RecordingMessage'

const MAX_TIME_BETWEEN_FOLLOWON_MESSAGES=120 //seconds

class MessagePanel extends React.PureComponent{  

	// static whyDidYouRender = true;

	static getDerivedStateFromProps(props, state) {
		if(props.recentActivityItem && !state.recentActivityItem && !state.recentActivities.length){ //Prevent red unreads line showing when refresh on a doc
			return ({recentActivityItem:props.recentActivityItem,recentActivities:props.recentActivities})
		}
		if(props.threadParentMessage !=state.threadParentMessage){
			return({threadParentMessage:props.threadParentMessage,recentActivities:props.recentActivities})
		}
		if(props.threadParentMessage && props.recentActivities != state.recentActivities){
			//update the recent activity only for the current thread
			let newRecentActivities=[...state.recentActivities]
			let activityIndex=findIndex(state.recentActivities,(activityItem => {
				return activityItem.item_id == props.threadParentMessage
			}))
			let newActivityItem=find(props.recentActivities,(activityItem => {
				return activityItem.item_id == props.threadParentMessage
			}))
			if(newActivityItem && newActivityItem.activity_type=='sentMessage'){ //only update if its sentMessage (not opened-so we still show read line when open thread)
				if(activityIndex == -1){
					if(newActivityItem){
						newRecentActivities.push(newActivityItem)
					}
				}else{
					newRecentActivities= [
						...state.recentActivities.slice(0,activityIndex),
						newActivityItem,
						...state.recentActivities.slice(activityIndex + 1)
					]
				}
			return({recentActivities:newRecentActivities,recentActivityItem:newActivityItem})
		}else return null
		}
		if(!props.threadParentMessage && props.recentActivities != state.recentActivities){
			let newRecentActivities=[...state.recentActivities]
			let activityIndex=findIndex(state.recentActivities,(activityItem => {
				return activityItem.item_id == props.docId
			}))
			let newActivityItem=find(props.recentActivities,(activityItem => {
				return activityItem.item_id == props.docId
			}))
			if(newActivityItem && newActivityItem.activity_type=='sentMessage'){ //only update if its sentMessage (not opened-so we still show read line when open thread)
				if(activityIndex == -1){
					if(newActivityItem){
						newRecentActivities.push(newActivityItem)
					}
				}else{
					newRecentActivities= [
						...state.recentActivities.slice(0,activityIndex),
						newActivityItem,
						...state.recentActivities.slice(activityIndex + 1)
					]
				}
			return({recentActivities:newRecentActivities,recentActivityItem:newActivityItem})
		}else return null

		}

		return null
	}

	constructor(props) {
		super(props)
		this.handleClickOnDocSelection=this.handleClickOnDocSelection.bind(this)
		this.highlightMessage=this.highlightMessage.bind(this)
		this.handleReplyInThread=this.handleReplyInThread.bind(this)
		this.handleOpenThread=this.handleOpenThread.bind(this)
		this.scrollableDivRef = React.createRef()
		this.state={
			highlightedMessage:null,
			recentActivityItem:props.recentActivityItem,
			recentActivities:props.recentActivities,
			threadParentMessage:props.threadParentMessage
		}
	} 


	componentDidMount(){
		this.props.updateRecentActivity()
	}

	handleReplyInThread(messageId){
		console.log('reply in thread')
		console.log(messageId)
  	this.props.setThreadParentMessage(messageId);
	}

handleOpenThread(messageId){
  this.props.setThreadParentMessage(messageId);
}


	getSnapshotBeforeUpdate(prevProps, prevState){
		if(prevProps.messages.length < this.props.messages.length){
			const el=this.scrollableDivRef.current
			const scrollTop=el.scrollTop
			return scrollTop
		}return null
	}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
    	if(Math.abs(snapshot)<100){
    		const el=this.scrollableDivRef.current
    		el.scrollTop = 0
    	}
    }
  }

	highlightMessage(messageId){ //for jumping to message in whatsapp reply
		this.setState({highlightedMessage:messageId})
		let div=document.getElementById(`message_${messageId}`)
		if(div){
			div.scrollIntoView()
		}
		setTimeout(() => {
    	this.setState({highlightedMessage: null});
    }, 900);    
	}

	handleClickOnDocSelection(selectionId){
		this.props.setActiveMessageSelection(selectionId)		
		const pluginState=this.props.messageSelectionPluginKey.getState(window.view.state)
		if(pluginState){
			const selections=pluginState.selections
			const selection=find(selections,{id:selectionId})
			if(selection){
				const topSpanRect=window.view.coordsAtPos(selection.from) //https://prosemirror.net/docs/ref/#view.EditorView.coordsAtPos
				const bottomSpanRect=window.view.coordsAtPos(selection.to)
				const commentTopXPos = topSpanRect.top // relative to the viewport
				const commentBottomXPos = bottomSpanRect.bottom // relative to the viewport
				const scrollDiv=document.getElementById('scrollable_div') // find scroll position of thing					
				if(scrollDiv){						
					const pageScroll = scrollDiv.scrollTop	
					const windowHeight = window.innerHeight // find height of window 	  				
					const topPageHeaderBuffer = 50 // buffer for the in doc top header
					const generalBuffer = 160 // nice to have a general buffer as well, so can see comment in context  				
					const commentBelow = commentBottomXPos + generalBuffer > windowHeight // comment is below window
					const commentAbove = commentTopXPos < (topPageHeaderBuffer + generalBuffer) // comment is above window	  				
					const commentAboveDeltaX = commentTopXPos - generalBuffer - topPageHeaderBuffer
					const commentBelowDeltaX = (commentBottomXPos + generalBuffer) - windowHeight
					if(commentAbove){	  					
					scrollDiv.scrollBy(0, commentAboveDeltaX); // scrollBy is relative
					}
					if(commentBelow){
						scrollDiv.scrollBy(0, commentBelowDeltaX); // scrollBy is relative
					}
				}
			}
		}
	}

	handleClickOnMediaNodeSelection(nodeType,nodeId){
		let node
		let tr=window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.nodeId==nodeId){
				const selection = new NodeSelection(tr.doc.resolve(pos))
				tr.setSelection(selection)
			}
		})
		window.view.dispatch(tr)
		//2. scroll it into view
		const div=document.getElementById(`${nodeId}`)	
		if(div){
			const rect=div.getBoundingClientRect()
			const commentTopXPos = rect.top // relative to the viewport
			const commentBottomXPos = rect.bottom // relative to the viewport
			const scrollDiv=document.getElementById('scrollable_div') // find scroll position of thing					
			if(scrollDiv){						
				const pageScroll = scrollDiv.scrollTop	
				const windowHeight = window.innerHeight // find height of window 	  				
				const topPageHeaderBuffer = 50 // buffer for the in doc top header
				const generalBuffer = 20 // nice to have a general buffer as well, so can see comment in context  				
				const commentBelow = commentBottomXPos + generalBuffer > windowHeight // comment is below window
				const commentAbove = commentTopXPos < (topPageHeaderBuffer + generalBuffer) // comment is above window	  				
				const commentAboveDeltaX = commentTopXPos - generalBuffer - topPageHeaderBuffer
				const commentBelowDeltaX = (commentBottomXPos + generalBuffer) - windowHeight
				if(commentAbove){	  					
				scrollDiv.scrollBy(0, commentAboveDeltaX); // scrollBy is relative
				}
				if(commentBelow){
					scrollDiv.scrollBy(0, commentBelowDeltaX); // scrollBy is relative
				}
			}
		}else{
			const userName=null
			let actionType
			if(nodeType=='video'){
				 actionType="clickDeletedMessageVideo"		
				  Mixpanel.track('clickDeletedMessageVideo')
			}else if(nodeType=='image'){
				 actionType="clickDeletedMessageImage"	
				 Mixpanel.track('clickDeletedMessageImage')
			}else if(nodeType=='figmaEmbed'){
				 actionType="clickDeletedMessageFigma"	
				 Mixpanel.track('clickDeletedMessageFigma')
			}
			store.dispatch(showToastNotification(actionType,userName))
		}
		
	}

	
	render(){	
		//console.log('message panel render!!!----------------------------------')
		const {recentActivityItem,recentActivities}=this.state
		const {isNewDocThread,messages,currentUser,docId,reactions,newMessageParentMessage,followers,userId,mentionDropdownOpen,threadParentMessage}=this.props
		const {newMessageParentNodeType,newMessageParentNodeSnapshot}=this.props
		const docMessages=sortBy(filter(this.props.messages,function(message){
			if(message.parent_doc==docId){
				return message
			}
		}),'created_at')

		let recentActivityTime //for the doc new 16th March-->also have thread recent activities
		if(recentActivityItem){
			recentActivityTime=recentActivityItem.activity_time
		}

		//should filter for thread before calc statuses 
		let messagesWithStatus=[]
		let filteredMessagesWithStatus=[] //for current thread
		let isFirstUnread=true
		docMessages.forEach((message,i)=>{
			let newMessageObj={...message}
			const createdAt=message.created_at
			const isCurrentUser=message.created_by===currentUser.user_id
			let isUnread
			if(!message.thread_parent){
				isUnread=(!isCurrentUser && (createdAt>recentActivityTime || !recentActivityTime))
			}else{
				const userIsInThread=currentUserIsInThread(message.thread_parent)
				if(userIsInThread){
					const threadActivityItem=find(recentActivities,{item_id:message.thread_parent})
					let threadActivityTime
					if(threadActivityItem){
						threadActivityTime=threadActivityItem.activity_time
					}
					isUnread=(!isCurrentUser && (createdAt>threadActivityTime || !threadActivityTime))
				}
			}
			newMessageObj.isUnread=isUnread
			let isInCurrentThread=false//check if is in thread
			if(threadParentMessage==message.thread_parent || message.message_id==threadParentMessage){
				isInCurrentThread=true
			}
			messagesWithStatus.push(newMessageObj)
			if(isInCurrentThread){
				newMessageObj.isFirstUnread=isFirstUnread && isUnread
				newMessageObj.isFirstInDateGroup=true //do this bit up here before reverse because its confusing!
				if(i==0 || filteredMessagesWithStatus.length==0){
					newMessageObj.isFirstInDateGroup=true
				}else{
					if(moment(filteredMessagesWithStatus[filteredMessagesWithStatus.length-1]['created_at']).isSame(moment(message['created_at']), 'd')){
						newMessageObj.isFirstInDateGroup=false
					}
				}
				if(isUnread){
					isFirstUnread=false
				}			
				filteredMessagesWithStatus.push(newMessageObj)
			}
		})
		const reverseArray=filteredMessagesWithStatus.reverse()
		let filteredMessages=reverseArray	
		//is currrent user a follower?  if not show follow doc panel
		let userIsFollower=false
		if(followers){
			followers.forEach((follower)=>{
				if(follower.user_id==userId){
					userIsFollower=true
				}
			})
		}
		
		const elevateZIndex = mentionDropdownOpen
		const threadsEnabled=getOrgThreadsEnabled()		


		return (	  							
			<div className={'docEditor-messagePanelContainer ' + (elevateZIndex ? ' docEditor-messagePanelContainer--elevateZIndex ' : '')}>
				<div className='docEditor-messagePanel' onClick={this.handleOnClick}>
					{!threadParentMessage && !isNewDocThread &&
						<MessagePanelHeader 							
							docTitle={this.props.docTitle}
							followers={this.props.followers}
							docId={this.props.docId}
							docType={this.props.docType}
							projectId={this.props.projectId}		
							toggleShowMessagePanel={this.props.toggleShowMessagePanel}					
						/>	
					}
					{(threadParentMessage || isNewDocThread) &&
						<MessagePanelThreadHeader 
							isNewDocThread={isNewDocThread}
							cancelNewDocThread={this.props.cancelNewDocThread}
							threadParentMessage={threadParentMessage}
							backToMainThread={()=>{this.props.setThreadParentMessage(null)}}
							isHighlightThread={false}
						/>	
					}

					<div className='docEditor-messagePanel-scrollContents' ref={this.scrollableDivRef}>
						{threadParentMessage &&
							<div className='docEditor-messagePanel-scrollContents-vSpacer'/>
						}

						{!isNewDocThread && filteredMessages.map((message,i)=>{
							const user=getUserForId(message['created_by'])
							let messageIsCurrentUser=isCurrentUser(message['created_by'])
							const messageReactions=filter(reactions,{message_id:message['message_id']})
							let followOnMessage=false
							if(i!==filteredMessages.length-1){
								const previousMessage=filteredMessages[i+1]
								if(!message.in_doc_thread 
									&& !message.recording
									&& previousMessage['created_by']==message['created_by'] 
									&& previousMessage['thread_parent']==message['thread_parent']
									&& !(message['parent_message'] && !message['is_thread'] )){
									const timeDiff=moment.duration(moment(message['created_at']).diff(moment(previousMessage['created_at']))).as('seconds')
									if(timeDiff<MAX_TIME_BETWEEN_FOLLOWON_MESSAGES){
										followOnMessage=true
									}
								}
							}
							const childMessages=filter(messagesWithStatus,{thread_parent:message['message_id']})						
							
								return(
								<React.Fragment key={message.message_id}>
									<Message
										key={message.message_id}
										openRecordingModal={this.props.openRecordingModal}
										message={message}
										userDisplayName={user.display_name}
										avatarUrl={user.avatar}
										isCurrentUser={messageIsCurrentUser}
										userId={this.props.userId}
										timestampLabel={formatDate(message.created_at)}								
										orgId={this.props.orgId}
										docId={this.props.docId}
										deleteMessage={this.props.deleteMessage}
										addReactionToMessage={this.props.addReactionToMessage}
										messageReactions={messageReactions}
										deleteReaction={this.props.deleteReaction}
										followOnMessage={followOnMessage}
										setNewMessageParentMessage={this.props.setNewMessageParentMessage}
										//replyInThread={()=> this.handleReplyInThread(message.message_id)}
										replyInThread={this.handleReplyInThread}
        						//openThread={()=> this.handleOpenThread(message.message_id)}
        						openThread={this.handleOpenThread}
										//replyInThread={()=>{this.props.setThreadParentMessage(message.message_id)}}
										isInThread={threadParentMessage?true:false}
										messageIsHighlighted={this.state.highlightedMessage==message.message_id}
										highlightMessage={this.highlightMessage}
										isThreadParent={childMessages.length>0}
										//openThread={()=>{this.props.setThreadParentMessage(message.message_id)}}
										threadChildMessages={childMessages}
										threadParentMessage={threadParentMessage}
										handleClickOnDocSelection={this.handleClickOnDocSelection}
										handleClickOnMediaNodeSelection={this.handleClickOnMediaNodeSelection}
										threadsEnabled={threadsEnabled}
									/>
									{message.isFirstUnread &&
										<MessagePanelDivider unreads />
									}

									{message.isFirstInDateGroup &&
										<MessagePanelDivider dateDivider date={message['created_at']}/>
									}

								</React.Fragment>
							)							
						})}						

						{/* always shows, just gets pushed out, like slack I think 
					}
						{/* not quite sure what happens when you switch channel... maybe this stays, dunno 
						<MessagePanelPreloadMessageChannel
							// channel={channel}
						/>
						*/}
						{!threadParentMessage && !isNewDocThread && userIsFollower &&
							<MessagePanelMentionTipPreloadMessage
								docType={this.props.docType}
							/>
						}
						{!threadParentMessage &&
							<div className='docEditor-messagePanel-scrollContents-vSpacer'/>
						}
					</div>

					
						<div className='docEditor-messagePanel-footer'>						
							
							{userIsFollower &&
								<MessageInputBox 
									sendDocMessage={this.props.sendDocMessage}
									sendUserTypingEvent={this.props.sendUserTypingEvent}
									docId={this.props.docId}
									userId={this.props.userId}
									userTyping={this.props.userTyping}
									messageTextSnapshot={this.props.messageTextSnapshot}
									messageSelectionId={this.props.messageSelectionId}
									deleteMessageSelection={this.props.deleteMessageSelection}
									threadParentMessage={threadParentMessage}
									newMessageParentMessage={newMessageParentMessage}
									uploadImageToCloudinary={this.props.uploadImageToCloudinary}
									newMessageParentMessage={newMessageParentMessage}
									deleteParentMessage={()=>{this.props.setNewMessageParentMessage(null)}}
									newMessageParentNodeType={this.props.newMessageParentNodeType}
									newMessageParentNodeSnapshot={this.props.newMessageParentNodeSnapshot}
									clearMessageSelection={this.props.clearMessageSelection}
									updateRecentActivity={this.props.updateRecentActivity}
									backToMainThread={()=>{this.props.setThreadParentMessage(null)}}	
								/>
							}

							{followers  && !userIsFollower &&
								<MessagePanelFooterFollowBox 
									followDoc={()=>{followDoc(docId)}}
									docTitle={this.props.docTitle}
									docType={this.props.docType}									
									projectId={this.props.projectId}
								/>
							}

						</div>																
				</div>
			</div>
		)
	}
} 

function mapStateToProps(state,ownProps) {
	let mentionDropdownOpen=false
	if(state.messagePluginStates && state.messagePluginStates.messageMentionActive){
		mentionDropdownOpen=true
	}
	return {
		mentionDropdownOpen:mentionDropdownOpen
	}
}

export default connect(mapStateToProps,
	{	

	}
)(MessagePanel)


