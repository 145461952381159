import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import EditMeetingParticipantListPopoverContents from './EditMeetingParticipantListPopoverContents'

class EditMeetingParticipantListPopover extends React.Component{  

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)		
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

	render(){	
		const {showPopover, openPopover, closePopover, portalled} = this.props
		const {forceMount} = this.state
		const translateY = -8
		const popoverSide = "bottom"
		let popoverSideOffset= 4
		let popoverAlign = "end"				
		let popoverAlignOffset= -40

		let portalledVal
		
		//console.log(`participants: ${this.props.participants}`)		

	  return (
	  	<React.Fragment>
		  	<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>						
						{this.props.children}
					</Popover.Trigger>					
					{portalled && 
						<Popover.Portal forceMount={showPopover ? true : forceMount}>
							<Popover.Content
								className={'editMeetingParticipantsPopoverContainer ' + (showPopover ? ' editMeetingParticipantsPopoverContainer--visible ' : ' editMeetingParticipantsPopoverContainer--hidden ') + (this.props.forceDarkTheme ? ' forceDarkTheme ' : '')} 
								onEscapeKeyDown={closePopover}
								onPointerDownOutside={closePopover}
								//onInteractOutside={closePopover}							
								side={popoverSide}
								sideOffset={popoverSideOffset}
								align={popoverAlign}
								alignOffset={popoverAlignOffset}
								forceMount={showPopover ? true : forceMount}
							>
								<Transition
									items={showPopover}
									from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
									enter={{ opacity: 1, transform: 'translateY(0px)'  }}
									leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
									reverse={showPopover}
									config={{tension: 600, friction: 35}}
									onRest={this.onRest}
								>
									{(styles, item) =>
										item && 
										 <animated.div style={styles} className="editMeetingParticipantsPopover popover"> 	
											<EditMeetingParticipantListPopoverContents 
											 	participants={this.props.participants || []}
											 	contactParticipants={this.props.contactParticipants || []} // will refactor later!
											 	updateMeetingParticipants={this.props.updateMeetingParticipants}
											/>
										</animated.div>
									}
								</Transition>									
							</Popover.Content>
						</Popover.Portal>
					}
					{!portalled && 
						
							<Popover.Content
								className={'editMeetingParticipantsPopoverContainer ' + (showPopover ? ' editMeetingParticipantsPopoverContainer--visible ' : ' editMeetingParticipantsPopoverContainer--hidden ')} 
								onEscapeKeyDown={closePopover}
								onPointerDownOutside={closePopover}
								//onInteractOutside={closePopover}							
								side={popoverSide}
								sideOffset={popoverSideOffset}
								align={popoverAlign}
								alignOffset={popoverAlignOffset}
								forceMount={showPopover ? true : forceMount}
							>
								<Transition
									items={showPopover}
									from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
									enter={{ opacity: 1, transform: 'translateY(0px)'  }}
									leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
									reverse={showPopover}
									config={{tension: 600, friction: 35}}
									onRest={this.onRest}
								>
									{(styles, item) =>
										item && 
										 <animated.div style={styles} className="editMeetingParticipantsPopover popover"> 	
											<EditMeetingParticipantListPopoverContents 
											 	participants={this.props.participants || []}
											 	contactParticipants={this.props.contactParticipants || []} // will refactor later!
											 	updateMeetingParticipants={this.props.updateMeetingParticipants}
											/>
										</animated.div>
									}
								</Transition>									
							</Popover.Content>						
					}
				</Popover.Root>
			</React.Fragment>
	  )
	}
}

export default EditMeetingParticipantListPopover
