import React from 'react'
import ReactDOM from 'react-dom';
import ParticipantHeaderReactComponent from './ParticipantHeaderReactComponent'


class ParticipantHeaderNodeView {

	constructor(node, view, getPos,openIssuePanelForUser) {
		this.node=node 
		this.getPos=getPos
		this.openIssuePanelForUser=openIssuePanelForUser
		this.renderReactComponent=this.renderReactComponent.bind(this)
		this.dom = document.createElement("span")
		this.dom.className="doc-h1 doc-participantHeader"
		this.renderReactComponent()
	}

	renderReactComponent(){
		const {
			userId,
      issueUpdateVersion}=this.node.attrs

		ReactDOM.render(
			<ParticipantHeaderReactComponent 
			userId={userId}
			openIssuePanelForUser={this.openIssuePanelForUser}
			/>, this.dom)
	}




	update(node) {
		if (node.type !== this.node.type) return false
		this.node=node
		this.renderReactComponent()
		return true
	}



	destroy() {
		if(this.timeout){
			clearTimeout(this.timeout)
		}
		ReactDOM.unmountComponentAtNode(this.dom)
	}


}

export default ParticipantHeaderNodeView