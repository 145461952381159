import React from 'react'
import Icon from '../../../misc/Icon'
import MessageMediaMainFeedHighlightVideoPlayer from './MessageMediaMainFeedHighlightVideoPlayer'
import VideoDuration from '../../../../utils/videoDuration'

class MessageMediaMainFeedHighlight extends React.Component{  

	constructor(props){
    super(props)    

    this.state={
      
    }    
  }  
  

	render(){	

		const {openThread, muxPlaybackID}=this.props
		
		return (
			<React.Fragment>
				<div className='message-para message-para--autogeneratedStyling'>
					added a highlight
				</div>
			<div className='message-content-mainFeedHighlight'>
				<button onClick={openThread} className='message-content-mainFeedHighlight-btn'>
					<div className='message-content-mainFeedHighlight-btn-previewContainer'>
						<MessageMediaMainFeedHighlightVideoPlayer
							muxPlaybackID={muxPlaybackID}
						/>						
					</div>
					<div className='message-content-mainFeedHighlight-btn-rightContainer'>
						<div className='message-content-mainFeedHighlight-btn-description message-para'>
							{this.props.description}
						</div>
						<div className='message-content-mainFeedHighlight-btn-duration'>
							<VideoDuration seconds={this.props.duration} />
						</div>
					</div>					
				</button>			
			</div>
			</React.Fragment>
		)
	}
}
 


export default MessageMediaMainFeedHighlight
