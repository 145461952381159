function getAttrs(dom) {
  return {
    projectId: dom.getAttribute('data-projectId'),
    docNameSnapshot: dom.getAttribute('data-docNameSnapshot')
  }
}


const LinearProjectMentionNodeSpec ={
    attrs: {
      projectId:{default:''}
    },
    inline: true,
    group: "inline",
    draggable: true,
    parseDOM: [
    {tag: 'linearProjectMention', getAttrs},
   ],
    toDOM(node) {
      return ['linearProjectMention', {'data-projectId':node.attrs.projectId}]
  }
   //  toDOM(node) {
   //    const div = document.createElement('div');
   //    div.className="doc-para-internalLinkContainer"

   //    const div2=document.createElement('div');
   //    div2.className = 'doc-para-internalLink'
      

   //    const div3=document.createElement('div');
   //    div3.className = 'doc-para-internalLink-title'
   //    div3.innerHTML=`${node.attrs.docNameSnapshot}`
   //    div2.appendChild(div3)
   //    div.appendChild(div2)

   //    return div;
   // },
}
    
   

  export default LinearProjectMentionNodeSpec


