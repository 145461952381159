import React from 'react'
import Icon from '../../../misc/Icon'
import MessagePanelHeaderDNDButtonContainer from './MessagePanelHeaderDNDButtonContainer'
import MessagePanelHeaderFollowerList from './MessagePanelHeaderFollowerList'
//import MessagePanelHeaderAnimatedFollowerListPrototype from './MessagePanelHeaderAnimatedFollowerListPrototype'
import {getProjectForId} from '../../../../utils/getProjectForId'
import MessagePanelHeaderFollower from './MessagePanelHeaderFollower'
import {getUserForId} from '../../../../utils/getUserForId'
import sortBy from 'lodash/sortBy'
import * as Tooltip from '@radix-ui/react-tooltip';
import KeyboardShortcutTag from '../../../misc/KeyboardShortcutTag'

class MessagePanelHeader extends React.Component{  

	constructor(props){
    super(props) 
   	this.state={
      dndActive: false,      
    }
  }

	render(){	
		const {threadParentMessage, projectId}=this.props
		const {dndActive} = this.state

		let project
		let projectLeadUserId

    if(projectId){
    	project = getProjectForId(projectId)
    }
    if(project){
    	projectLeadUserId = project.lead
    }
    let followersObjs=[]
    if(this.props.followers){
			this.props.followers.forEach((follower)=>{
				const userObj=getUserForId(follower.user_id)
				if(userObj){
					followersObjs.push(userObj)
				}
			})
    }


    let sortedFollowers = sortBy(followersObjs, function(user){
			if(user.display_name){ //when app is loading user obj is {}
				return user.display_name.toString().toLowerCase()
			}
  	})

  	const showAnimatedFollowerListPrototype = true
		
		return (	  																	
				
			<div className='docEditor-messagePanel-header'>
				
				<Tooltip.Provider>
					<Tooltip.Root delayDuration={0}>
						<Tooltip.Trigger asChild>																	
							<button onClick={this.props.toggleShowMessagePanel} className='docEditor-messagePanel-header-hideBtn'>
								{/* <Icon name='hideRightMedium' /> */}
								<Icon name='sideMenu' />
							</button>																	
						</Tooltip.Trigger>				
						<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
							Hide doc chat										
						</Tooltip.Content>							
					</Tooltip.Root>
				</Tooltip.Provider>

				

				<div className='docEditor-messagePanel-header-titleContainer'>
					<div className='docEditor-messagePanel-header-title'>
						{this.props.docTitle}
					</div>			
				</div>	
				
				<MessagePanelHeaderFollowerList
					followerList={sortedFollowers}									
					docId={this.props.docId}
				/>


				<MessagePanelHeaderDNDButtonContainer
					dndActive={dndActive}
					toggleDnD={()=>{this.setState({dndActive: !dndActive})}}
					springAnimationConfig={{tension: 310, friction: 23}}
				/>	

			</div>
				
				
			
		)
	}
} 
export default MessagePanelHeader

							{/*}

							constructor(props){
    super(props)
    this.createConversation=this.createConversation.bind(this)
    this.state={
      showNewDMPopover: false,
    }
  }  

							createConversation(userId){
  	this.props.createConversation(userId)
  	this.setState({showNewDMPopover:false})
  }

							<Popover.Root open={showNewDMPopover}>
								<Popover.Trigger asChild >									
									
								</Popover.Trigger>
								<Popover.Content
						    	className='dndSideMenu-tabList-tabGroup-header-editPopoverOuterContainer'
						    	onEscapeKeyDown={()=>{this.setState({showNewDMPopover:false})}}
							    onPointerDownOutside={()=>{this.setState({showNewDMPopover:false})}}
							    onInteractOutside={()=>{this.setState({showNewDMPopover:false})}}
						    >
						      <MessagePanelHeaderConversationPopover
						      	closePopover={()=>{this.setState({showNewDMPopover:false})}}
						      	orgMembers=
						      	createConversation={}
						      />
						    </Popover.Content>
						  </Popover.Root>				
						  */}			


							{/*
								{!threadParentMessage && !docView &&
					<div className={'docEditor-messagePanel-header ' + (multipleTabs ? ' docEditor-messagePanel-header--multipleTabs ' : ' docEditor-messagePanel-header--singleTab ')}>							
						{channelName && channelName!=='private' &&
							<MessagePanelHeaderTab
								channel
								channelName={channelName}
								active={!activeConversation}
								setActiveConversation={()=>{this.props.setActiveConversation(null)}}
							/>
						}
						{multipleTabs &&	
							<React.Fragment>
								{conversations.map((conversation)=>{
									const members=conversation.members
									let recipient
									members.forEach((memberId)=>{
										if(memberId!==currentUser['user_id']){
											recipient=memberId
										}
									})
									const unreadCount=calculateConversationNotificationCount(conversation['conversation_id'])
									return(
										<MessagePanelHeaderTab
											key={`conversation_${conversation.conversation_id}`}
											user
											active={activeConversation===conversation['conversation_id']}
											notificationCount={unreadCount}
											recipient={recipient}
											conversationId={conversation['conversation_id']}
											setActiveConversation={()=>{this.props.setActiveConversation(conversation['conversation_id'])}}
										/>
									)
								})
							}
							</React.Fragment>
						}
						<SelectUserPopover									
							actionType='directMessage'									
							showPopover={showNewDMPopover}
							openPopover={()=>{this.setState({showNewDMPopover: true})}}
							closePopover={()=>{this.setState({showNewDMPopover: false})}}
							onClickUserFunction={this.createConversation}
							userList={userList}									
						>	
							<button onClick={()=>{this.setState({showNewDMPopover: true})}} className='docEditor-messagePanel-header-DMButton'>	
								<div className='docEditor-messagePanel-header-DMButton-inner'>	
									<Icon name='plus' /> 
								</div>
							</button>			
						</SelectUserPopover>
					</div>
				}
				*/}


{/*
class MessagePanelHeaderConversationPopover extends React.Component {
	render(){  
		const {orgMembers} = this.props
    let membersArray=[]
    if(orgMembers){
      Object.keys(orgMembers).forEach((key)=>{
        let member=orgMembers[key]      
        membersArray.push(member)
      })
    }
    const sortedMembers = sortBy(membersArray, function(member){
      return member.name.toString().toLowerCase()
    })
		return (	  	
			<div className="dndSideMenu-tabList-tabGroup-header-editPopover popover popover--user" >								
				<div className='popover--user-title'>
					Discuss Doc
				</div>
				<div className='popover-itemList'>
					{orgMembers && sortedMembers.map((user)=>{              
						return(
							<button key={user['user_id']} onClick={()=>{this.props.createConversation(user['user_id'])}}>
								{user.display_name}
							</button>
						)
					})}
				</div>
			</div>			
		)
	}
}


class MessagePanelHeaderEditTabPopover extends React.PureComponent {
	render(){  
		return (	  	
			<div className="dndSideMenu-tabList-tabGroup-header-editPopover popover popover--user" >										
				<div className='popover-itemList'>					
					<PopoverItem label='Hide Conversation' />
				</div>
			</div>			
		)
	}
}


				<MessagePanelManageFollowersPopover
					showPopover={showManageFollowersPopover}						
					openPopover={()=>{this.setState({showManageFollowersPopover: true})}}
					closePopover={()=>{this.setState({showManageFollowersPopover: false})}}
					userList={orgMembers}				
					addFollowerToDoc={this.props.addFollowerToDoc}	
					removeFollowerFromDoc={this.props.removeFollowerFromDoc}
					followers={this.props.followers}
				>
					<React.Fragment>
						{showActiveNewButton &&
			        <button onClick={()=>{this.setState({showManageFollowersPopover: true})}} className={'docEditor-messagePanel-header-followerPopoverWideBtn ' + (showManageFollowersPopover ? ' docEditor-messagePanel-header-followerPopoverWideBtn--menuOpen ' : ' docEditor-messagePanel-header-followerPopoverWideBtn--menuClosed ')}>	              
								<div className='docEditor-messagePanel-header-followerPopoverWideBtn-iconContainer'>
									<Icon name='plus' />								
								</div>		
								<div className='docEditor-messagePanel-header-followerPopoverWideBtn-label'>
									Add to doc
								</div>			
							</button>					
							}
						{!showActiveNewButton &&					
			        <button onClick={()=>{this.setState({showManageFollowersPopover: true})}} className={'docEditor-messagePanel-header-followerPopoverBtn ' + (showManageFollowersPopover ? ' docEditor-messagePanel-header-followerPopoverBtn--menuOpen ' : ' docEditor-messagePanel-header-followerPopoverBtn--menuClosed ')}>	              
								<Icon name='plus' />														
								<div className='docEditor-messagePanel-header-followerPopoverBtn-tooltip tooltip'>
									Add to Doc
								</div>		
							</button>
						}
					</React.Fragment>
		
				</MessagePanelManageFollowersPopover>							

class MessagePanelHeaderTab extends React.Component{  
	
	constructor(props){
    super(props)       
    this.state={
      showEditHeaderTabPopover: false,
    }
  }

	render(){			
		const {channel, channelName, user, recipient, active, notificationCount}=this.props		
		const {showEditHeaderTabPopover} = this.state
		let label = channelName
		if(user){
			label = getUserName(recipient)
		}
		let notificationCountLabel = notificationCount
		if(notificationCount > 9){
			notificationCountLabel = '9+'
		}
		return (	  											
			<Popover.Root key={`${label}`} open={showEditHeaderTabPopover}>
				<Popover.Trigger asChild >					
					<div className='docEditor-messagePanel-header-tabContainer' onContextMenu={()=>(this.setState({showEditHeaderTabPopover:true}))} onClick={this.props.setActiveConversation}>
						<div className={'docEditor-messagePanel-header-tab ' + (channel && ' docEditor-messagePanel-header-tab--type--channel ') + (user && ' docEditor-messagePanel-header-tab--type--user ') + (active ? ' docEditor-messagePanel-header-tab--activeStatus--active ' : ' docEditor-messagePanel-header-tab--activeStatus--inactive ')}>	
							<div className='docEditor-messagePanel-header-tab-iconContainer'>
								{channel &&
									<Icon name='hash' />
								}
								{user &&
									<Icon name='mentionMedium' />
								}
							</div>
							<div className='docEditor-messagePanel-header-tab-label'>
								{label}
							</div>
							{notificationCount > 0 &&
								<div className='docEditor-messagePanel-header-tab-notificationContainer'>
									<div className='docEditor-messagePanel-header-tab-notification'>
										{notificationCountLabel}
									</div>
								</div>
							}
							<div className='docEditor-messagePanel-header-tab-bottomBorder'/>
						</div>			
					</div>
				</Popover.Trigger>
				<Popover.Content
		    	className='dndSideMenu-tabList-tabGroup-header-editPopoverOuterContainer'
		    	onEscapeKeyDown={()=>{this.setState({showEditHeaderTabPopover:false})}}
			    onPointerDownOutside={()=>{this.setState({showEditHeaderTabPopover:false})}}
			    onInteractOutside={()=>{this.setState({showEditHeaderTabPopover:false})}}
		    >
		      <MessagePanelHeaderEditTabPopover
		      	closePopover={()=>{this.setState({showEditHeaderTabPopover:false})}}	      			      	
		      />
		    </Popover.Content>
		  </Popover.Root>	
		)
	}
} 

*/}