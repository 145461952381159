import React from 'react'
import Icon from './Icon'

class LinearInlineIssuePriorityIcon extends React.PureComponent{    
  render(){ 
    
    const {priority} = this.props

    return (
      <React.Fragment>
      
        {(priority === 2|| priority === 3 || priority === 4) &&
          <span className={'linearInlineIssuePriorityIcon ' + (priority ? ` linearInlineIssuePriorityIcon--priority--${priority}` : '')}>
            <svg className='linearInlineIssuePriorityIcon-bars' width="13" height="12" viewBox="0 0 13 12">  
              <rect id="firstBar" y="6" width="3" height="6" rx="1"/>
              <rect id="secondBar" x="5" y="3" width="3" height="9" rx="1"/>
              <rect id="thirdBar" x="10" width="3" height="12" rx="1"/>
            </svg>
              
          </span>
        }

        {priority === 0 &&
          <span className='linearInlineIssuePriorityIcon linearInlineIssuePriorityIcon--priority--0'>
            <svg className='linearInlineIssuePriorityIcon-nullBar' width="13" height="12" viewBox="0 0 13 12">
              <rect x="2.5" y="5" width="8" height="1.5" rx=".75" />
            </svg>
          </span>
        }

        {priority === 1 &&
          <span className='linearInlineIssuePriorityIcon linearInlineIssuePriorityIcon--priority--1'>
            <Icon name='linearUrgentPriorityIcon' />
          </span>
        }

      
      </React.Fragment>
    )
  }
}

export default LinearInlineIssuePriorityIcon
