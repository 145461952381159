import {uploadRoadmapCoverImageToCloudinary} from '../actions/roadmaps'
import store from '../store'



export function uploadRoadmapCoverImage(file,roadmapId) {
	store.dispatch(uploadRoadmapCoverImageToCloudinary(file,roadmapId))
}



export function replaceRoadmapCoverImage(file,roadmapId) {
	store.dispatch({type:'REPLACE_COVER_IMAGE_REQUEST',roadmapId})
	store.dispatch(uploadRoadmapCoverImageToCloudinary(file,roadmapId))
}

