import React from 'react'
import ReactDOM from 'react-dom'
import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import DocPlaceholderReactComponent from '../../components/docEditor/DocPlaceholderReactComponent'

export function emptyDocPlaceholderPlugin(docId){
	return new Plugin({
		props: {
			decorations: ({ doc, plugins, selection }) => {
				const decorations = []
				const { anchor } = selection
				let focused=false
				if(window.view){
					focused=window.view.hasFocus()
				}
				doc.descendants((node, pos) => {
					const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize)
					if (node.type.name==='editorPage' && !node.textContent && (!hasAnchor||!focused)) {
					//if (node.type.name==='editorPage' && !node.textContent) {
						if(node.nodeSize===4){ //TEMP hack to handle non text child content e.g. todo
							const placeholderDiv=document.createElement("div")
							placeholderDiv.classList.add("doc-placeholder")						 
							 ReactDOM.render(<DocPlaceholderReactComponent docId={docId}
              />, placeholderDiv)

							 const widgetSpec={
							 	destroy(el){
							 		ReactDOM.unmountComponentAtNode(el)
							 	}
							 }

						decorations.push(Decoration.widget(pos+1, placeholderDiv,widgetSpec))
					}
				}
			})
		return DecorationSet.create(doc, decorations)
		}
	}
	})
}