import store from '../store'
import {getFigmaEmbedForId} from './getFigmaEmbedForId'

export function openInFigma(embedId) {
	const embed=getFigmaEmbedForId(embedId)
	if(embed){
		const url=embed.pasted_url
		const deeplink=`figma://${url.slice(21)}`
		if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:deeplink})     
    }
    else{
      window.open(deeplink,'_blank')
    }
	}
}