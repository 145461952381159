import store from '../store'
import find from 'lodash/find'

//TODO combine with opendoc into openTab or something


//for now pass history as prop
//TODO think about pushing to history outside of a react component using BrowserRouter and HashRouter

//new url stuff


export function makeUrlForRoadmap(roadmapId){
	let url=`/roadmaps/${roadmapId}`
	return url
}

export function openRoadmap(history,roadmapId) {
	if(roadmapId){
		const url=makeUrlForRoadmap(roadmapId)
		history.push(`${url}`)
		const state = store.getState()
		const openTabs=state.openTabs

		const {tabGroups,orderedTabGroups}=openTabs
		let tabAlreadyOpen=false
		Object.keys(tabGroups).forEach((groupId)=>{
			const tabs=tabGroups[groupId]
			if(find(tabs,{type:'roadmap',id:roadmapId})){
				tabAlreadyOpen=true
			}
		})
		if(!tabAlreadyOpen){
			let newTabGroups=Object.assign({},tabGroups)
			let newDefaultGroup=newTabGroups.default
			newDefaultGroup.push({type:'roadmap',id:roadmapId})
			newTabGroups.default=newDefaultGroup
			store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
		}		
	}
}

//opens without navigating to tab
export function backgroundOpenRoadmap(roadmapId) {
	if(roadmapId){
		const state = store.getState()
		const openTabs=state.openTabs
		const {tabGroups,orderedTabGroups}=openTabs
		let tabAlreadyOpen=false
		Object.keys(tabGroups).forEach((groupId)=>{
			const tabs=tabGroups[groupId]
			if(find(tabs,{type:'roadmap',id:roadmapId})){
				tabAlreadyOpen=true
			}
		})
		if(!tabAlreadyOpen){
			let newTabGroups=Object.assign({},tabGroups)
			let newDefaultGroup=newTabGroups.default
			newDefaultGroup.push({type:'roadmap',id:roadmapId})
			newTabGroups.default=newDefaultGroup
			store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
		}		
	}
}