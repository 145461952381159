import store from '../../store'
import find from 'lodash/find'



export function getMeetingForId(meetingId) {	
	const state = store.getState()
	const meeting= find(state.meetings, {'meeting_id':`${meetingId}`})
	return meeting
}

