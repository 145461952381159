import React from 'react'
import { Spring, animated, config } from 'react-spring'
import FigmaViewZoomedUIHeader from './FigmaViewZoomedUIHeader'
import FigmaViewZoomedUICropCanvas from './FigmaViewZoomedUICropCanvas'
import DocFigmaEmbedZoomedModalFooter from '../docEditor/mediaModals/figma/DocFigmaEmbedZoomedModalFooter'

class FigmaViewZoomedUI extends React.Component{  	


  constructor(props){
    super(props)  
    this.disableCropMode=this.disableCropMode.bind(this)
    this.updateCropValues=this.updateCropValues.bind(this)
    this.updateCropPosition=this.updateCropPosition.bind(this)
    this.updateSnappedCropPosition=this.updateSnappedCropPosition.bind(this)
    const {embedObj,crop}=this.props	
		let frameWidth=embedObj.absolute_render_bounds.width
		let frameHeight=embedObj.absolute_render_bounds.height
		let cropboxWidth = embedObj.absolute_render_bounds.width
    let cropboxHeight = embedObj.absolute_render_bounds.height
    let cropboxX = 0
    let cropboxY= 0   	
    if(crop){
    	cropboxWidth = crop.width
    	cropboxHeight = crop.height
    	cropboxX = crop.x
    	cropboxY= crop.y 
    }

    this.state = { 
    	frameWidth:frameWidth,
    	frameHeight:frameHeight,
   		cropboxWidth: cropboxWidth,
      cropboxHeight: cropboxHeight,
      cropboxX: cropboxX,
      cropboxY: cropboxY, 	
    	showThreadPanel: false,	    	
    	isChangingThreadPanelState: false,
    	justOpenedModal: true,
    	modalDimensions: {
    		width: -1,
    		height: -1,
    	}, 
    	rightPanelDimensions: {
    		width: -1,
    		height: -1,
    	},	    
   	};
  }

  updateCropValues(values){
		const { cropboxWidth,cropboxHeight,cropboxX,cropboxY}=values
		this.setState({cropboxWidth:cropboxWidth,cropboxHeight:cropboxHeight,cropboxX:cropboxX,cropboxY:cropboxY})
	}

	updateSnappedCropPosition(values){
		const margin=12
		const {frameWidth,frameHeight,cropboxWidth,cropboxHeight}=this.state
		const { cropboxX,cropboxY}=values
		let snappedX= cropboxX
		if(cropboxX < margin){
			snappedX=0
		}else if((frameWidth-(cropboxX+cropboxWidth))<margin){
			snappedX=frameWidth-cropboxWidth
		}
		let snappedY= cropboxY
		if(cropboxY < margin){
			snappedY=0
		}else if((frameHeight-(cropboxY+cropboxHeight))<margin){
			snappedY=frameHeight-cropboxHeight
		}
		this.setState({cropboxX:snappedX,cropboxY:snappedY})
	}

	updateCropPosition(values){
		const { cropboxX,cropboxY}=values
		this.setState({cropboxX:cropboxX,cropboxY:cropboxY})
	}


	disableCropMode(){ //TODO maybe rename from diasable to end or something
		const crop={
			width:this.state.cropboxWidth,
			height:this.state.cropboxHeight,
			x:this.state.cropboxX,
			y:this.state.cropboxY
		}
		//updateFigmaEmbedCropValues(this.props.embedId,crop) //updates node attrs
		if(this.props.saveUpdatedCropValues){ //for playground
			this.props.saveUpdatedCropValues(crop)
		}
	}

	render(){	
		const {cropModeActive,zoomed, zoomedFigmaModalOuterMargins, zoomedFigmaModalInnerMargins, embedObj, transformOrigin,disableMotion,viewOnlyMode,figmaNode}=this.props
		const {frameWidth,frameHeight,cropboxWidth,cropboxHeight,cropboxX,cropboxY,showEffectsToolbar} = this.state		
	  return (
	  	<React.Fragment>
		  	{zoomed && 	
		  		<div style={{top: `${zoomedFigmaModalInnerMargins.top}px`, bottom: `${zoomedFigmaModalInnerMargins.bottom}px`, left: `${zoomedFigmaModalInnerMargins.left}px`, right: `${zoomedFigmaModalInnerMargins.right}px`}}  className={'figmaView-modalCropCanvas ' + (zoomed ? ' figmaView-modalCropCanvas--zoomed ' : ' figmaView-modalCropCanvas--notZoomed ') + (cropModeActive ? 'figmaView-modalCropCanvas--cropModeActive' : '')}>
						<FigmaViewZoomedUICropCanvas 					
							frameWidth={frameWidth}
							frameHeight={frameHeight}
				   		cropboxWidth={cropboxWidth}
		     			cropboxHeight={cropboxHeight}
		     			cropboxX={cropboxX}
		      		cropboxY={cropboxY} 	
		      		updateCropValues={this.updateCropValues}
		      		updateCropPosition={this.updateCropPosition}
							cropModeActive={cropModeActive}
							frameImageUrl={embedObj.image_url}
							canvasWidth={this.props.canvasWidth}
							canvasHeight={this.props.canvasHeight}
							isChangingThreadPanelState={this.state.isChangingThreadPanelState}
							justOpenedModal={this.state.justOpenedModal}
							updateSnappedCropPosition={this.updateSnappedCropPosition}
							disableCropMode={this.disableCropMode}
						/>									
		      </div>	    	
	    	}

	    	<div onClick={this.props.handleClickOnModalCenterBG} style={{top: `${zoomedFigmaModalInnerMargins.top}px`, bottom: `${zoomedFigmaModalInnerMargins.bottom}px`, left: `${zoomedFigmaModalInnerMargins.left}px`, right: `${zoomedFigmaModalInnerMargins.right}px`}} className='doc-zoomedFigmaModal-centerBGClick'/>

				<div style={{height: `${zoomedFigmaModalInnerMargins.top}px`}}  className='doc-zoomedFigmaModal-headerContainer'>				
					<FigmaViewZoomedUIHeader
						title={embedObj.node_title	}		
						cropModeActive={cropModeActive}				
						enableCropMode={this.props.enableCropMode}
						disableCropMode={this.disableCropMode}
						viewOnlyMode={viewOnlyMode}
						unzoom={this.props.unzoom}
						
						showEffectsToolbar={this.props.showEffectsToolbar}
						hideEffectsToolbar={this.props.hideEffectsToolbar}
						openEffectsToolbar={this.props.openEffectsToolbar}
					/>
				</div>
				<div style={{height: `${zoomedFigmaModalInnerMargins.bottom}px`}} className='doc-zoomedFigmaModal-footerContainer'>				
					<DocFigmaEmbedZoomedModalFooter
						docId={this.props.docId}
						cropModeActive={cropModeActive}				
						disableCropMode={this.disableCropMode}
						sendMediaModalMessage={this.props.sendMediaModalMessage}
						embedId={embedObj.embed_id}
						embedObj={embedObj}
						unzoom={this.props.unzoom}
						showEffectsToolbar={this.props.showEffectsToolbar}
						hideEffectsToolbar={this.props.hideEffectsToolbar}						
						backgroundPadding={this.props.backgroundPadding}
						editBackgroundPadding={this.props.editBackgroundPadding}
						backgroundImage={this.props.backgroundImage}
						editBackgroundImage={this.props.editBackgroundImage}
						deviceFrame={this.props.deviceFrame}
						editDeviceFrame={this.props.editDeviceFrame}
						device={this.props.device}
						toggleShadow={this.props.toggleShadow}
					/>						
				</div>						
	  	</React.Fragment>
	  )
	}
}

export default FigmaViewZoomedUI

