import React, { Component } from "react";

class DocFigmaEmbedZoomedModalCropboxCornerHandle extends Component {
	render() {
		return (
			 <div
				style={{        
					height: "100%",
					width: "100%",        
				}}
				className="doc-zoomedFigmaModal-canvas-cropUI-cornerHandle"
			/>
		)
	}
}

class DocFigmaEmbedZoomedModalCropboxLeftRightEdgeHandle extends Component {
	render() {
		return (
			<div
				style={{                                      
					width: "100%",
					height: "100%",        
				}}
				className="doc-zoomedFigmaModal-canvas-cropUI-edgeContainer"
			>
				<div className="doc-zoomedFigmaModal-canvas-cropUI-edge doc-zoomedFigmaModal-canvas-cropUI-edge--leftRight">
					<div className='doc-zoomedFigmaModal-canvas-cropUI-edge-inner'/>
				</div>
			</div>
		)
	}
}

class DocFigmaEmbedZoomedModalCropboxTopBottomEdgeHandle extends Component {
	render() {
		return (
			<div
				style={{        
					height: "100%",
					width: "100%",         
				}}
				className="doc-zoomedFigmaModal-canvas-cropUI-edgeContainer"
			>
				<div className="doc-zoomedFigmaModal-canvas-cropUI-edge doc-zoomedFigmaModal-canvas-cropUI-edge--topBottom">
					<div className='doc-zoomedFigmaModal-canvas-cropUI-edge-inner'/>
				</div>

			</div>
		)
	}
}

export {	
	DocFigmaEmbedZoomedModalCropboxCornerHandle,
	DocFigmaEmbedZoomedModalCropboxLeftRightEdgeHandle,
	DocFigmaEmbedZoomedModalCropboxTopBottomEdgeHandle
}
