import React from 'react'
import Icon from '../../components/misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import KeyboardShortcutTag from '../../components/misc/KeyboardShortcutTag'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {setHeadingType} from '../../prosemirror/utils/setHeadingType'
// import {insertCodeBlock} from '../../prosemirror/utils/insertCodeBlock'
// import {addMarkToSelection,toggleMarkForSelection} from '../../prosemirror/utils/addMarkToSelection'
import {headerToParagraphCommand} from '../../prosemirror/utils/commands/insertHeaderCommand'
import {getOrgThreadsEnabled} from '../../utils/getOrgThreadsEnabled'
// import NewTextLinkReactComponent from '../../components/nodeViews/links/NewTextLinkReactComponent'
 //use mouseDown instead of onClick because mouseDown sets focus

class SelectionUIReactComponentButton extends React.PureComponent{  
	render(){			
		const {name, iconName, label, tooltipLabel, disabled, active, onClickFunction} = this.props
		// can have only icon, only label, both icon and label, optional tooltip
		// name is used for custom styling
		let buttonType
		if(iconName && label){
			buttonType = 'iconAndLabel'
		}else if(iconName && !label){
			buttonType = 'iconOnly'
		}else if(!iconName && label){
			buttonType = 'labelOnly'
		}		
		const {keyboardShortcutOption, keyboardShortcutCommand, keyboardShortcutControl, keyboardShortcutShift, keyboardShortcutBackspace, keyboardShortcutLetter, keyboardShortcutOnly} = this.props
		let hasKeyboardShortcut = false
		if(keyboardShortcutOption || keyboardShortcutCommand || keyboardShortcutControl || keyboardShortcutShift || keyboardShortcutBackspace || keyboardShortcutLetter){
			hasKeyboardShortcut = true
		}
		return (				

		<Tooltip.Provider>
						<Tooltip.Root delayDuration={this.props.longTooltipDelay ? 400 : 0}>
							<Tooltip.Trigger asChild>
								<button disabled={disabled} className={'docEditor-selectionMenu-btn ' + (buttonType ? ` docEditor-selectionMenu-btn--${buttonType} ` : '') + (name ? ` docEditor-selectionMenu-btn--${name} ` : '') + (active ? ' docEditor-selectionMenu-btn--active ' : '')} onMouseDown={this.props.onClickFunction}>
									{iconName && 
									<div className='docEditor-selectionMenu-btn-iconContainer'>
										<Icon name={iconName} />
									</div>
									}
									{label && 
										<div className='docEditor-selectionMenu-btn-label'>
											{label}
										</div>
									}
									{active && 
										<div className='docEditor-selectionMenu-btn-activeBG' />
									}
									
								</button>	
							</Tooltip.Trigger>				
							{(tooltipLabel || hasKeyboardShortcut) &&								
							<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI tooltip--inlineSelect">																																									
									<React.Fragment>		
									{tooltipLabel &&
										<span>
											{tooltipLabel}
										</span>
									}
									{hasKeyboardShortcut &&						
										<KeyboardShortcutTag
											command={keyboardShortcutCommand}
											shift={keyboardShortcutShift}
											option={keyboardShortcutOption}
											control={keyboardShortcutControl}
											backspace={keyboardShortcutBackspace}
											letter={keyboardShortcutLetter}
											keyboardShortcutOnly={keyboardShortcutOnly}
										/>								
									}						
									</React.Fragment>								
								</Tooltip.Content>													
								}
						</Tooltip.Root>
					</Tooltip.Provider>

			
		)	
	}
}

class SelectionUIReactComponentButtonGroupWrapper extends React.Component{  
	render(){			
		return (			
			<div className='docEditor-selectionMenu-btnGroup'>
				{this.props.children}
			</div>				
		)	
	}
}


class DocSelectionMenu extends React.PureComponent{  

	constructor(props) {
		super(props)
		this.handleToggleHeaderClick=this.handleToggleHeaderClick.bind(this)
		this.newThread=this.newThread.bind(this)
		this.state = {
			hideUI:false,
		}
	} 

	handleOnClick(e){ //use mousedown instead of click
		e.stopPropagation()
		e.preventDefault()
	}

	newThread(e){
		const isDocThreadParent=true
		this.props.newSelectionMessage(e,isDocThreadParent)
	}

	showNewTextLinkModal(){
		let tr=window.view.state.tr
		tr.setMeta("newTextLinkPlugin", { activate: true})
		window.view.dispatch(tr)
	}


	handleToggleHeaderClick(level){
		const {menuItems}=this.props
		if(level==1){
			if(menuItems.header1.isActive){
				headerToParagraphCommand()(window.view.state, window.view.dispatch)
			}else{
				menuItems.header1.command(window.view.state, window.view.dispatch)
			}
		}
		else if(level==2){
			if(menuItems.header2.isActive){
				headerToParagraphCommand()(window.view.state, window.view.dispatch)
			}else{
				menuItems.header2.command(window.view.state, window.view.dispatch)
			}
		}
	}

	render(){	
		const {isPrivateDoc,menuItems}=this.props 


		const threadsEnabled=getOrgThreadsEnabled()

		const textLinkAllowed = true

		if(!this.state.hideUI && !this.state.showNewLinkModal){
			return (
				<div className='docEditor-selectionMenu' onMouseDown={this.handleOnClick}>
					
					
						<SelectionUIReactComponentButtonGroupWrapper>		
							<SelectionUIReactComponentButton 
								name="block"
								iconName="blockDecoration"					
								tooltipLabel="Content Box"
								keyboardShortcutCommand
								keyboardShortcutShift
								keyboardShortcutLetter="B"
								disabled={!menuItems.contentBox.isAllowed}
								//active={menuItems.contentBox.active}
								active={menuItems.contentBox.isActive}
								onClickFunction={()=>{menuItems.contentBox.command(window.view.state, window.view.dispatch)}}						
							/>					
						</SelectionUIReactComponentButtonGroupWrapper>							
					
					
						<SelectionUIReactComponentButtonGroupWrapper>		
							<SelectionUIReactComponentButton 
								name="H1"
								iconName="formatH1"		
								tooltipLabel="Header 1"
								//keyboardShortcutOption
								//keyboardShortcutCommand
								keyboardShortcutLetter="#"
								active={menuItems.header1.isActive}
								onClickFunction={()=>{this.handleToggleHeaderClick(1)}}	
								disabled={!menuItems.header1.isAllowed}
								//onClickFunction={()=>{headerToParagraphCommand()(window.view.state, window.view.dispatch)}}	
								//onClickFunction={()=>{setHeadingType(1)}}						
							/>
							<SelectionUIReactComponentButton 
								name="H2"
								iconName="formatH2"	
								tooltipLabel="Header 2"	
								// keyboardShortcutOption
								// keyboardShortcutCommand
								keyboardShortcutLetter="##"
								//onClickFunction={()=>{setHeadingType(2)}}		
								active={menuItems.header2.isActive}
								onClickFunction={()=>{this.handleToggleHeaderClick(2)}}	
								disabled={!menuItems.header2.isAllowed}
								//onClickFunction={()=>{menuItems.header2.command(window.view.state, window.view.dispatch)}}					
							/>					
						</SelectionUIReactComponentButtonGroupWrapper>		
					
						<SelectionUIReactComponentButtonGroupWrapper>		
							<SelectionUIReactComponentButton 
								name="textLink"
								iconName="linkArrow"
								label="Link"
								tooltipLabel="Add link"
								keyboardShortcutCommand
								keyboardShortcutLetter="K"
								longTooltipDelay
								active={menuItems.textLink.isActive}
								onClickFunction={this.showNewTextLinkModal}
								disabled={!menuItems.textLink.isAllowed}
								//onClickFunction={()=>{menuItems.textLink.command(window.view.state, window.view.dispatch)}}		
								//onClickFunction={this.openNewTextLinkModal}		
								//active={menuItems.header1.isActive}
								//onClickFunction={()=>{this.handleToggleHeaderClick(1)}}									
							/>							
						</SelectionUIReactComponentButtonGroupWrapper>							

					
					
					{threadsEnabled && !isPrivateDoc &&
						<SelectionUIReactComponentButtonGroupWrapper>
							<SelectionUIReactComponentButton 
									name="newThread"
									iconName="threadRegular"
									onClickFunction={this.newThread}															
									label="New Thread"
									//tooltipLabel="New Thread"					
								/>
							
						</SelectionUIReactComponentButtonGroupWrapper>
					}

					<SelectionUIReactComponentButtonGroupWrapper>
						
						{!isPrivateDoc &&
							<SelectionUIReactComponentButton 
								name="addComment"
								iconName="commentLayered"
								//label="Comment"
								onClickFunction={this.props.newSelectionMessage}						
								tooltipLabel="Quote"
								longTooltipDelay
								keyboardShortcutCommand
								keyboardShortcutShift
								keyboardShortcutLetter="C"						
							/>
						}
					

					{/*}
					<SelectionUIReactComponentButtonGroupWrapper>
						<SelectionUIReactComponentButton 
							name="pingSelection"
							iconName="broadcast"			
							tooltipLabel="Ping Selection"
							keyboardShortcutCommand						
							keyboardShortcutLetter="J"					
						/>

						<SelectionUIReactComponentButton 
							name="discussSlack"
							iconName="slackIcon"			
							tooltipLabel="Discuss in Slack"
							//onClickFunction = not sure where happens, some selection, but basically deeplinks with quoted text into slack convo
							keyboardShortcutCommand
							keyboardShortcutShift
							keyboardShortcutLetter="S"
						/>
					</SelectionUIReactComponentButtonGroupWrapper>
					*/}

					
						<SelectionUIReactComponentButton 
							name="code"
							iconName="code"							
							tooltipLabel="Code"
							keyboardShortcutLetter="` `"
							active={menuItems.code.isActive}
							//onClickFunction={()=>{toggleMarkForSelection('code')}}
							onClickFunction={()=>{menuItems.code.command(window.view.state, window.view.dispatch)}}
							//onClickFunction = turns on/off code state, also displays state if code							
						/>
						
						<SelectionUIReactComponentButton 
							name="codeBlock"
							iconName="codeBlock"							
							tooltipLabel="Code Block"
							keyboardShortcutCommand
							keyboardShortcutShift
							keyboardShortcutLetter="G"
							//onClickFunction={()=>insertCodeBlock()}			
							disabled={!menuItems.codeBlock.isAllowed}
							active={menuItems.codeBlock.isActive}
							onClickFunction={()=>{menuItems.codeBlock.command(window.view.state, window.view.dispatch)}}									
						/>		
					
						<SelectionUIReactComponentButton 
							name="lightText"
							iconName="formatA"							
							tooltipLabel="Lowlight"
							keyboardShortcutCommand
							keyboardShortcutShift
							keyboardShortcutLetter="K"
							active={menuItems.light.isActive}
						//	active={lightMarkIsActive}
							onClickFunction={()=>{menuItems.light.command(window.view.state, window.view.dispatch)}}	
							//onClickFunction={()=>{toggleMarkForSelection('light')}}							
						/>
						{/*}
						<SelectionUIReactComponentButton 
							name="bulletList"
							iconName="bulletList"								
							tooltipLabel="Bullet List"
							keyboardShortcutOption
							keyboardShortcutCommand
							keyboardShortcutLetter="5"							
						/>
						*/}
				</SelectionUIReactComponentButtonGroupWrapper>
				{/*}
				<SelectionUIReactComponentButtonGroupWrapper>
					<SelectionUIReactComponentButton 
						name="stash"
						iconName="stashLayered"					
						tooltipLabel="Stash"						
						//onClickFunction = later, stash text (i.e. delete but keep a record of it)
						
						keyboardShortcutCommand						
						keyboardShortcutBackspace
					/>
				</SelectionUIReactComponentButtonGroupWrapper>			
				*/}	
			
			</div>		
		)
	}else if(this.state.showNewLinkModal){

		return(
			<NewTextLinkReactComponent
				closeNewTextLinkModal={this.closeNewTextLinkModal}
			/>

			)

	}else return null
	}
}
export default DocSelectionMenu

					{/*}
					<SelectionUIReactComponentButton 
						name="numberList"
						iconName="numberList"								
						tooltipLabel="Number List"
						//onClickFunction = puts whole para into bullet list node

						keyboardShortcutOption
						keyboardShortcutCommand
						keyboardShortcutLetter="6"
					/>

					<SelectionUIReactComponentButton 
						name="letterList"
						iconName="letterList"								
						tooltipLabel="Letter List"
						//onClickFunction = puts whole para into bullet list node

						keyboardShortcutOption
						keyboardShortcutCommand
						keyboardShortcutLetter="7"
					/>			
					*/}	
				


				{/* MIGHT LEAVE FOR this pass, TRICKY UI I THINK
				<SelectionUIReactComponentButtonGroupWrapper>
										 
					<SelectionUIReactComponentButton 
						name="addLink"
						iconName="linkArrow"
						label="Link"
						//onClickFunction={this.addComment}

						keyboardShortcutOnly						
						keyboardShortcutLetter="@"
					/>
										
				</SelectionUIReactComponentButtonGroupWrapper>
				*/}

				{/*

				{isReadOnly && 
					<SelectionUIReactComponentButtonGroupWrapper>
						<SelectionUIReactComponentButton 
							name="addSuggestions"
							iconName="suggest"
							label="Suggest"
							// onClickFunction= open suggestion menu in future							
						/>
						</SelectionUIReactComponentButtonGroupWrapper>
				}
				*/}

				
					
				

				




				{/* Probably too annoying with losing focus?
				<DropdownMenu.Root>
					<DropdownMenu.Trigger className='docEditor-selectionMenu-btn docEditor-selectionMenu-btn--iconOnly'>
						<div className='docEditor-selectionMenu-btn-iconContainer'>
							<Icon name='ellipses' />
						</div>
					</DropdownMenu.Trigger>   			 	
					<DropdownMenu.Content className='dropdownMenu'>	       	 	
						<DropdownMenu.Item>
							<Icon name='formatBold' />
						</DropdownMenu.Item>					        
						<DropdownMenu.Item>
							<Icon name='formatItalic' />
						</DropdownMenu.Item>				
						<DropdownMenu.Item >
							<Icon name='formatStrikethrough' />
						</DropdownMenu.Item>					        
						<DropdownMenu.Item >
							<Icon name='Learn more about Discuss in Slack' />
						</DropdownMenu.Item>					        
						<DropdownMenu.Item >
							<Icon name='Learn more about stash' />
						</DropdownMenu.Item>					        
					</DropdownMenu.Content>
				</DropdownMenu.Root>	  

			*/}