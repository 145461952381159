import React from 'react'
import { withRouter} from 'react-router-dom'
import DocTable from '../docTable/DocTable'
import Icon from '../misc/Icon'
import LibraryDocPreview from './LibraryDocPreview'
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import {createNewDoc} from '../../utils/createNewDoc'

class PrivateDocsPage extends React.Component{  
	
		constructor(){
		super()
		this.state={
			activeDocId:null,
		}
	}


	render(){	

		const {docs}=this.props
	  return (
	  	<div className='privateDocsPage'>
	  		<div className='privateDocsPage-leftSpacer'/>

				<div className='privateDocsPage-center'>	 
					<div className='privateDocsPage-header'>	  			  		
		  			<div className='privateDocsPage-header-title'>	
		  				<div className='privateDocsPage-header-title-iconContainer'>	
								<Icon name='draftsLight' />	
							</div>
		  				<div className='privateDocsPage-header-title-label'>	
		  					Private Drafts 
		  				</div>
		  			</div>
		  			<div className='privateDocsPage-header-rightContainer'>	
		  				<button className='privateDocsPage-header-newDocBtn' onClick={()=>{createNewDoc(this.props.history)}}>
		  					<div className='privateDocsPage-header-newDocBtn-iconContainer'>
		  						<Icon name='plus' />
		  					</div>
		  					<div className='privateDocsPage-header-newDocBtn-label'>
		  						New Private Draft
		  					</div>
	  					</button>
		  			</div>
		  		</div>		  		  	
		  		<div className='privateDocsPage-content'>	  			  		
		  			<DocTable 
		  				docs={this.props.docs}
		  				libraryView='private'
		  				setActiveDoc={(docId)=>{this.setState({activeDocId:docId})}}
		  			/>
					</div>
				</div>

				<div className='privateDocsPage-rightPanel'>
					<LibraryDocPreview
						docId={this.state.activeDocId}
					/>

					<div className='libraryPageTipContainer'>						
						<div className='libraryPageTip'>
							<div className='libraryPageTip-avatar'>
								🎒
							</div>
							<div className='libraryPageTip-title'>
								<div className='libraryPageTip-title-label'>
									Open in background with
								</div>
								<KeyboardShortcutTag commandSemibold click />
							</div>
							<div className='libraryPageTip-description'>
								<div className='libraryPageTip-description-para'>
									If you’re opening a few different docs at once, use Command Click to open them in background tabs.
								</div>								
							</div>
						</div>
					</div>


				</div>
	  		
	  	</div>
	  )
	}
}

export default withRouter(PrivateDocsPage)
