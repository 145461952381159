import store from '../../store'
import find from 'lodash/find'



export function getContactForId(contactId) {	
	let contact={}
	const state = store.getState()
	const contactObj= find(state.contacts, {'contact_id':contactId})
	if(contactObj){
		contact=contactObj
	}
	return contact
}

