import React from 'react'
import Icon from '../misc/Icon'
import DocImageZoomedModalBtn from '../docEditor/mediaModals/DocImageZoomedModalBtn'
import RecordingPlaybackModalHighlightGutter from './RecordingPlaybackModalHighlightGutter'
import PerfectScrollbar from 'react-perfect-scrollbar'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {changeTranscriptSearchActiveIndex,closeTranscriptSearch} from '../../utils/meetings/transcriptSearch'
import RecordingPlaybackModalTranscriptSearchBar from './RecordingPlaybackModalTranscriptSearchBar'

class RecordingPlaybackModalTranscript extends React.Component{  	

	 constructor(props) {
    super(props)    
   	this.handleChange = this.handleChange.bind(this);
   	this.changeTranscriptSearchActiveIndex=this.changeTranscriptSearchActiveIndex.bind(this)
   	this.handleTranscriptSearchDone=this.handleTranscriptSearchDone.bind(this)
   	this.handleInputKeyDown=this.handleInputKeyDown.bind(this)
    this.state = {
    	searchTerm: '',    	
    	showDropdown: false,
   	};    
  }

  componentWillUnmount(){
  	closeTranscriptSearch()
  }

  handleInputKeyDown(e){
		if(e.keyCode==27){ // close on escape
			e.preventDefault()
			e.stopPropagation()
			this.handleTranscriptSearchDone()
			// close on escape
		}	
    if(!e.shiftKey && e.keyCode==13){
      e.preventDefault()
      this.changeTranscriptSearchActiveIndex('down')
      // close on escape
    } 		
     if(e.shiftKey && e.keyCode==13){
      e.preventDefault()
      this.changeTranscriptSearchActiveIndex('up')
      // close on escape
    }     
	}

  handleChange(event) {  	
    this.setState({searchTerm: event.target.value});
    if(!event.target.value){
    	closeTranscriptSearch()
    }else{
			let view=window.transcriptView
			if(view){
				let tr = view.state.tr
				tr.setMeta("transcriptSearchPlugin", { activate: true,searchTerm:event.target.value,activeIndex:null})
				view.dispatch(tr)
        this.scrollToActiveSearchResult()
			}
    }
  }

  scrollToActiveSearchResult(){
    const id="search-result-active"
    const el=document.getElementById(id)
    if(el){
        const rect=el.getBoundingClientRect()
        const wordTopXPos = rect.top // relative to the viewport
        const wordBottomXPos = rect.bottom // relative to the viewport
        const scrollDiv=document.getElementById('transcript_scrollable_div') 
        if(scrollDiv){            
          const pageScroll = scrollDiv.scrollTop  
          const windowHeight = window.innerHeight // find height of window            
          const topPageHeaderBuffer = 0 // buffer for the in doc top header
          const generalBuffer = 250 // nice to have a general buffer as well, so can see comment in context         
          const wordBelow = wordBottomXPos + generalBuffer > windowHeight // comment is below window
          const wordAbove = wordTopXPos < (topPageHeaderBuffer + generalBuffer) // comment is above window            
          const wordAboveDeltaX = wordTopXPos - generalBuffer - topPageHeaderBuffer
          const wordBelowDeltaX = (wordBottomXPos + generalBuffer) - windowHeight
          if(wordAbove){              
            scrollDiv.scrollBy(0, wordAboveDeltaX); // scrollBy is relative
          }
          if(wordBelow){
            scrollDiv.scrollBy(0, wordAboveDeltaX); // scrollBy is relative
          }
        }
      }
  }

  changeTranscriptSearchActiveIndex(direction){
    const {transcriptSearchResults}=this.props
    const currentIndex=transcriptSearchResults.activeIndex
    const results=transcriptSearchResults.results
    const searchTerm=this.state.searchTerm
    let newIndex
    if(direction=='up'){
      if(currentIndex==0){
        newIndex=results.length-1
      }else{
        newIndex=currentIndex-1
      }
    }
    else if(direction=='down'){
      if(currentIndex==results.length-1){
        newIndex=0
      }else{
        newIndex=currentIndex+1
      }
    }
    changeTranscriptSearchActiveIndex(searchTerm,newIndex)
    this.scrollToActiveSearchResult()
  }

  handleTranscriptSearchDone(){
  	this.setState({searchTerm: ''})
  	closeTranscriptSearch()
  }


	render(){	
		const {transcriptSearchResults,highlightMode,showNewHighlightPanel}=this.props
		const {showDropdown, searchTerm} = this.state
		const hasSearchTerm = searchTerm.length > 0
		const searchResultsCount = transcriptSearchResults.results.length
		const currentActiveResult = transcriptSearchResults.activeIndex+1

    let searchInputResultsLabel = `${currentActiveResult} of ${searchResultsCount}`

    if(searchResultsCount === 0){
    	searchInputResultsLabel = 'No results'
    }

	  return (	 
	  	<React.Fragment> 	

	  		<div className={'recordingPlaybackModal-transcript ' + (this.props.highlightMode ? ' recordingPlaybackModal-transcript--highlightMode ' : ' recordingPlaybackModal-transcript--jumpMode ')}>
	  			

	  			<div className='recordingPlaybackModal-transcript-header'>
	  				<RecordingPlaybackModalTranscriptSearchBar 
	  					searchValue={this.state.searchTerm}
	  					searchOnChange={this.handleChange}
	  					searchOnKeyDown={this.handleInputKeyDown}
	  					changeTranscriptSearchIndexUp={()=>{this.changeTranscriptSearchActiveIndex('up')}}
	  					changeTranscriptSearchIndexDown={()=>{this.changeTranscriptSearchActiveIndex('up')}}
	  					searchInputResultsLabel={searchInputResultsLabel}
	  					searchResultsCount={searchResultsCount}
	  					handleTranscriptSearchDone={this.handleTranscriptSearchDone}
	  					hasSearchTerm={hasSearchTerm}

              showResetAutoScrollButton={this.props.showResetAutoScrollButton}
              resetAutoScroll={this.props.resetAutoScroll}
	  				/>
	  			</div>




		  		{/*}
	  			{highlightMode && 
	  			<div className='recordingPlaybackModal-transcript-header recordingPlaybackModal-transcript-header--highlight'>
	  				highlightMode
	  			</div>
	  			}
	  			*/}


	  			<div className='recordingPlaybackModal-transcript-contentsContainer'>	  			
	  				<div onScroll={this.props.handleTranscriptScroll} id="transcript_scrollable_div" className='recordingPlaybackModal-transcript-contentsInnerContainer'>	  					
							<div className='recordingPlaybackModal-transcript-contents' id='transcript' />
							<RecordingPlaybackModalHighlightGutter 
								highlights={this.props.highlights}
                skipToHighlight={this.props.skipToHighlight}
                videoCurrentTime={this.props.videoCurrentTime}
							/>
						</div>
	  			</div>
	  		</div>
			</React.Fragment>
	  )
	}
}

export default RecordingPlaybackModalTranscript