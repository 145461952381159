import {createTemplateRequest} from '../../actions/docTemplates'
import store from '../../store'
import editorSchema from '../../prosemirror/schema/editorSchema'
import {AllSelection} from "prosemirror-state"
import {setDocTemplate} from './setDocTemplate'

export function applyTemplate(template,docId){
	setDocTemplate(docId,template.template_id)
	let view = window.view;
	let tr = view.state.tr;
	tr.setSelection(new AllSelection(view.state.doc)); // Select the whole document
	const node = editorSchema.nodeFromJSON(template.source)
	tr.replaceSelectionWith(node, false); // Replace it with the template
	view.dispatch(tr); // Dispatch the transaction
}



