import React, { Component } from 'react'
import { Draggable } from '@hello-pangea/dnd'
import DraggableTab from './DraggableTab'
import DroppableTabList from './DroppableTabList'
import DraggableTabGroupHeader from './DraggableTabGroupHeader'


class DraggableTabGroup extends Component {
	render(){  
		const {tabGroup,index,tabs}=this.props
		return (	  	
				<div className='sideMenu-tabGroup'>
					<DraggableTabGroupHeader 
						key={tabGroup.id}
						tabGroup={tabGroup}
						renameTabGroup={this.props.renameTabGroup}
						newTabGroupId={this.props.newTabGroupId}
						deleteTabGroup={this.props.deleteTabGroup}
					/>					
					<DroppableTabList 
						tabs={tabs} 
						tabGroup={tabGroup}
						deleteTab={this.props.deleteTab}
						moveToNewGroup={this.props.moveToNewGroup}
					/>
				</div>			
				

		)
	}
}
export default DraggableTabGroup

{/*}
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",  
  // change background colour if dragging
  //background: isDragging ? "lightgreen" : "grey",
  // styles we need to apply on draggables
  ...draggableStyle
});



class DraggableTabGroup extends Component {
	render(){  
		const {tabGroup,index,tabs}=this.props
		return (	  	

			<Draggable key={tabGroup.id} draggableId={`${tabGroup.id}`} index={index}>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						style={getItemStyle(
							snapshot.isDragging,
							provided.draggableProps.style
						)}
						className='sideMenu-tabGroup'
					>
					
					<DraggableTabGroupHeader 
						key={tabGroup.id}
						tabGroup={tabGroup}
						renameTabGroup={this.props.renameTabGroup}
						newTabGroupId={this.props.newTabGroupId}
						deleteTabGroup={this.props.deleteTabGroup}

					/>
					
						<DroppableTabList 
							tabs={tabs} 
							tabGroup={tabGroup}
							deleteTab={this.props.deleteTab}
							moveToNewGroup={this.props.moveToNewGroup}
						/>
					</div>
				)}
			</Draggable>

		)
	}
}
export default DraggableTabGroup
*/}