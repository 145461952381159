import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'

class ToDoItemManageAssignmentPopoverRow extends React.PureComponent{  	
	render(){	
		const {user, onClickUserFunction,checked} = this.props		
	  return (	 	  	
			<button data-is-checked={checked ? true : false} onClick={this.props.toggleCheckbox} className="selectUserPopover-item selectUserPopover-item--assignUser" >
				<div className='selectUserPopover-item-avatarContainer'>
					<AvatarImage 
						className='selectUserPopover-item-avatar'
						avatarUrl={user.avatar}
					/>
				</div>
				<div className='selectUserPopover-item-username'>
					{user.display_name}			
				</div>
				<div className='selectUserPopover-item-checkboxContainer'>
					<div className='selectUserPopover-item-checkbox'>						
							<Icon name='toDoItemCheckmark' />						
					</div>
				</div>
			</button>
	  )
	}
}


class ToDoItemManageAssignmentPopover extends React.Component{  	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)
    this.state={
      forceMount: false,      
    }
  }

  onRest() {	
		if(!this.props.showPopover){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

	render(){	
		const {showPopover, openPopover, closePopover, userList, inverted,assignees} = this.props
		const {forceMount} = this.state
		let sortedUserList
		if(userList){
			sortedUserList = sortBy(userList, function(user){
				return user.display_name.toString().toLowerCase()
			})
		}

		const translateYDistance = 8
		let translateYDirection = 'down'
		if(inverted){
			translateYDirection = 'up'
		}
		let translateY
		if(translateYDirection === 'down'){
			translateY = translateYDistance * -1
		}
		if(translateYDirection === 'up'){
			translateY = translateYDistance * 1
		}

		
		// force mount is true
		// whenever show popover is true

		// when on rest
		// if show popover is false
		// set forcemount to be false

		// accept a button as a child

		// const pingType = doc
		// const pingType = media

		// const inviteDM = true/false

		// onClickUserFunction
	  return (
	  	<React.Fragment>
	  		<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
					<Popover.Content
			    	className={'selectUserPopoverContainer selectUserPopoverContainer--manageToDoAssignment ' + (showPopover ? ' selectUserPopoverContainer--visible ' : ' selectUserPopoverContainer--hidden ')} 
			    	onEscapeKeyDown={closePopover}
				    onPointerDownOutside={closePopover}
				    onInteractOutside={closePopover}				    
				    side="bottom"
				    sideOffset={4}
				    forceMount={showPopover ? true : forceMount}
				    //asChild
			    >
				    <Transition
			        items={showPopover}
			        from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
			        enter={{ opacity: 1, transform: 'translateY(0px)'  }}
			        leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
			        reverse={showPopover}
			        config={{tension: 600, friction: 60}}
			        onRest={this.onRest}
			       >
			        {(styles, item) =>
			          item && 
			           <animated.div style={styles} className="selectUserPopover popover"> 	
			          	<div onClick={closePopover} className='selectUserPopover-header'>
											<div className='selectUserPopover-header-leftContainer'>
												<div className='selectUserPopover-header-title'>
													Assign Todo Item
												</div>				
												{/*}								
												<div className='selectUserPopover-header-description'>
													Tip: assign inline with @teammate
												</div>				
												*/}								
			
											</div>
											<div className='selectUserPopover-header-rightContainer'>
												
											</div>
										</div>

										<div className='selectUserPopover-itemList'>

											
											{sortedUserList && sortedUserList.map((user)=>{                                
			                  const checked=find(assignees,{user_id:user.user_id})
			                  return(	                    
			                    <ToDoItemManageAssignmentPopoverRow			                    	
														key={user['user_id']}
			                    	user={user}
			                    	checked={checked}
			                    	toggleCheckbox={()=>{this.props.updateAssignees(user,!checked)}}
			                    />
			                  )
		               		 })}		               		 
										</div>
			          </animated.div>
			        }
			      </Transition> 										
			    </Popover.Content>
			    </Popover.Portal>
			  </Popover.Root>		
			</React.Fragment>
	  )
	}
}

export default ToDoItemManageAssignmentPopover