import schema from '../schema/editorSchema'  
import {randomID} from '../../utils/randomID'
import {createFigmaEmbed} from '../../actions/figma'
import store from '../../store'
import {TextSelection} from "prosemirror-state"
import { Mixpanel } from '../../Mixpanel'


//adapted from this stack overflow
//https://stackoverflow.com/questions/55429005/how-to-check-if-string-is-a-valid-figma-link
//https://regex101.com/r/MShZ8u/1
//https://www.figma.com/file/<fileKey>/<DocumentTitle>?node-id=<nodeId>&t=ULFdhdWJ0vrkPIAM-4
//https://www.figma.com/file/FbdT3m8aiE9KY4Zdc2DXTs/Jasper-Test-Project?node-id=2%3A2&t=ULFdhdWJ0vrkPIAM-4
//TODO test file names - we assume its just spaces replaced by - in the ur
const figmaRegex='(?:https:\/\/)?(?:www\.)?figma\.com\/(file|proto)\/([0-9a-zA-Z]{22,128})\/(.*)\?node-id=([^&]*)'

export function insertFigmaEmbed(figmaUrl,doc) {
	const state=window.view.state
	let insertPos=state.selection.from
	let tr=state.tr
	let {$from, $to} = state.selection
	const matched=figmaUrl.match(figmaRegex)
	const figmaNodeId=matched[4]
	const decodedNodeId=decodeURIComponent(figmaNodeId)

	const cleanedId=decodedNodeId.replace(/-/g, ':') //handle different format urls TODO why is this happening?
	const decodedTitle=matched[3].replace(/-/g, ' ')
	const documentKey=matched[2]
	const randID=randomID()
	const embedRequestBody={
		embed_id:randID,
		pasted_url:figmaUrl,
		document_key:documentKey,
		document_title:decodedTitle,
		figma_node_id:cleanedId,
		doc_id:doc.doc_id,
		project:doc.project,
		team:doc.team
	}
	Mixpanel.track('insert_figma')

	store.dispatch(createFigmaEmbed(embedRequestBody))
	const node=schema.nodes.docFigmaEmbed.createAndFill({embedId:randID,documentTitle:decodedTitle,nodeId:randID})
	tr.replaceWith(state.selection.from,state.selection.to,node)
	let selection=new TextSelection(tr.doc.resolve(state.selection.from+node.nodeSize+1))
	tr.setSelection(selection)
	window.view.dispatch(tr)
}




 //URL format 1
 //https://www.figma.com/file/6WuxIbuuYLbOAdjg6wkVEN/Examples-with-Devices?node-id=20%3A2&t=ZEQYwphL9et5tCuV-4
 //URL format 2
 //https://www.figma.com/file/6WuxIbuuYLbOAdjg6wkVEN/Examples-with-Devices?node-id=20-2&t=KlK9D3pKl9Dcv95G-4