import store from '../../store'
import find from 'lodash/find'




export function doNotDisturbIsActive() {	
	let doNotDisturbIsActive=false
	const state = store.getState()
	if(state.doNotDisturbUntil){
		doNotDisturbIsActive=true
	}
	return doNotDisturbIsActive
}

