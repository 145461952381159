//theme values
	//light,dark

function getInitialState(){
	let doNotDisturbUntil=null
	const localStorageTime = localStorage.getItem('doNotDisturbUntil')
	if(localStorageTime){
		doNotDisturbUntil=localStorageTime
	}
	return localStorageTime
}

export default function (state = getInitialState(), action) {
	switch (action.type) {  
 	 case 'SET_DO_NOT_DISTURB_UNTIL':
 	 		localStorage.setItem('doNotDisturbUntil',`${action.doNotDisturbUntil}`)
 	 		window.dispatchEvent( new Event('storage') );
			return action.doNotDisturbUntil
    default:
      return state;
	}
}


