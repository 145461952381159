import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'





class DocBlockLinearIssueModal extends React.Component{    


  constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.state={
      forceMount: false,      
      value: 'RGS Sync Test',
    }
  }

  onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  handleChange(event) {
    const inputValue=event.target.value
    
    this.setState({value: inputValue});
  }

  render(){ 
    const {showPopover, openPopover, closePopover, inverted} = this.props
    const {forceMount} = this.state
    

    const translateYDistance = 4
    let translateYDirection = 'down'
    if(inverted){
      translateYDirection = 'up'
    }

    let translateY
    if(translateYDirection === 'down'){
      translateY = translateYDistance * -1
    }else if(translateYDirection === 'up'){
      translateY = translateYDistance * 1
    }

    const priority = 'medium'

    return (
      <React.Fragment>
        <Popover.Root open={showPopover}>
          <Popover.Trigger asChild>
            {this.props.children}
          </Popover.Trigger>
          <Popover.Anchor asChild >
            <div className='docLinearIssue-modalAnchor' />
          </Popover.Anchor>
          <Popover.Portal>
          <Popover.Content
            className={'doc-blockLinearIssuePopoverContainer ' + (showPopover ? ' doc-blockLinearIssuePopoverContainer--visible ' : ' doc-blockLinearIssuePopoverContainer--hidden ')} 
            onEscapeKeyDown={closePopover}
            onPointerDownOutside={closePopover}
            onInteractOutside={closePopover}
            forceMount={showPopover ? true : forceMount}
            //side="bottom"
            //sideOffset={4}
            //asChild
          >
            <Transition
              items={showPopover}
              from={{ opacity: 0, transform: `translateY(${translateY}px) scale(0.95)` }}
              enter={{ opacity: 1, transform: 'translateY(0px) scale(1)'  }}
              leave={{ opacity: 0, transform: `translateY(${translateY}px) scale(0.95)`  }}
              reverse={showPopover}
              config={{tension: 600, friction: 30}}
              onRest={this.onRest}
             >
              {(styles, item) =>
                item && 
                 <animated.div style={styles} className="doc-blockLinearIssuePopover popover">  
                  
                 <div className='doc-blockLinearIssuePopover-header'>
                  <div className='doc-blockLinearIssuePopover-tag doc-blockLinearIssuePopover-tag--team'>
                    <div className='doc-blockLinearIssuePopover-tag--team-emojiContainer'>
                      🧭
                    </div>
                    <div className='doc-blockLinearIssuePopover-tag-label'>
                      DSK
                    </div>
                  </div>
                 </div>

                  <div className='doc-blockLinearIssuePopover-topRight'>
                    <button className='doc-blockLinearIssuePopover-topRight-linearLink'>
                      <div className='doc-blockLinearIssuePopover-topRight-linearLink-iconContainer'>
                        <Icon name='linkArrow' />
                      </div>
                      <div className='doc-blockLinearIssuePopover-topRight-linearLink-label'>
                        Open in Linear
                      </div>
                    </button>

                    <button onClick={closePopover} className='doc-blockLinearIssuePopover-topRight-closeBtn'>
                      <Icon name='cross-small' />
                    </button>

                  </div>
                  <div className='doc-blockLinearIssuePopover-title'>
                    <input
                      className='doc-blockLinearIssuePopover-title-input'                      
                      value={this.state.value}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className='doc-blockLinearIssuePopover-description'>
                    <textarea
                      className='doc-blockLinearIssuePopover-description-textarea'
                      placeholder="Add Description"
                    />
                  </div>

                  <div className='doc-blockLinearIssuePopover-footer'>
                    
                    <div className='doc-blockLinearIssuePopover-tag doc-blockLinearIssuePopover-tag--status'>
                      <div className='doc-blockLinearIssuePopover-tag--status-statusContainer'>
                        <div className='doc-blockLinearIssuePopover-tag--status-status doc-blockLinearIssuePopover-tag--status-status--todo' />
                      </div>
                      <div className='doc-blockLinearIssuePopover-tag-label'>
                        Todo
                      </div>                                        
                    </div>

                     <div className='doc-blockLinearIssuePopover-tag doc-blockLinearIssuePopover-tag--priority'>
                      <div className='doc-blockLinearIssuePopover-tag--priority-priorityContainer'>
                        <div className={'doc-blockLinearIssuePopover-tag--priority-bars ' + (priority ? ` doc-blockLinearIssuePopover-tag--priority-bars--priorityLevel--${priority} ` : ' ')}>
                          <div className='doc-blockLinearIssuePopover-tag--priority-bars-bar'/>
                          <div className='doc-blockLinearIssuePopover-tag--priority-bars-bar'/>
                          <div className='doc-blockLinearIssuePopover-tag--priority-bars-bar'/>
                        </div>
                      </div>                      
                    </div>

                    <div className='doc-blockLinearIssuePopover-tag doc-blockLinearIssuePopover-tag--assignee'>
                      <div className='doc-blockLinearIssuePopover-tag--assignee-avatar'>
                        <img className='doc-blockLinearIssuePopover-tag--assignee-avatar-img' src="https://avatars.slack-edge.com/2022-11-16/4380302157075_b6321a1c06e096a724f8_192.png" /> 
                      </div>                                          
                      <div className='doc-blockLinearIssuePopover-tag-label'>
                        Jasper Story
                      </div>                                        
                    </div>

                    <button className='doc-blockLinearIssuePopover-footer-button'>
                      <div className='doc-blockLinearIssuePopover-footer-button-label'>
                        Edit Issue
                      </div>
                    </button>

                  </div>
                  
                </animated.div>
              }
            </Transition>

                      
          </Popover.Content>
          </Popover.Portal>
        </Popover.Root>   
      </React.Fragment>
    )
  }
}

export default DocBlockLinearIssueModal