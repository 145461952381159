import React from 'react'
import StageIcon from '../../../misc/StageIcon'
import Icon from '../../../misc/Icon'
import MessageLinearIssueMentionReactComponent from '../messageInputBox/nodeViews/MessageLinearIssueMentionReactComponent'

const OPEN_POPOVER_DELAY=500

//Need a seperate component to handle all the popover bits.  In prosemirror its handled in the node view for static messages use this thing
class StaticMessageLinearIssueMention extends React.Component{  

	constructor(props){
		super(props)
		this.openPopover=this.openPopover.bind(this)
		this.closePopover=this.closePopover.bind(this)
		this.state = {
			showPopover:false
		}
		this.timeout=null
	} 

	componentWillUnmount(){
		if(this.timeout){
			clearTimeout(this.timeout)
		}
	}
	
	openPopover(){
		this.timeout=setTimeout(function() {
			this.setState({showPopover:true})
		}.bind(this), OPEN_POPOVER_DELAY)
	}

	closePopover(){
		if(this.timeout){
			clearTimeout(this.timeout)
		}
		if(this.state.showPopover){
			this.setState({showPopover:false})
		}
	}


	render(){
		const {issueId}=this.props
		const {showPopover}=this.state

			return (
				<MessageLinearIssueMentionReactComponent
					issueId={issueId}
					showPopover={showPopover}
					openPopover={this.openPopover}
					closePopover={this.closePopover}
				/>
			)
	}
}

export default StaticMessageLinearIssueMention
