import {fetch,fetchAuth} from '../api'
import {loadInitialData} from './initialData'
import { Mixpanel } from '../Mixpanel'



export function testApi() {
	const url = '/test'
	return (dispatch) => {
		return fetch(url)
			.then((response) => {
			})
			.catch(() => {
			})
		}
}

export function authenticateSession() {
	const url = '/auth/self'
	return (dispatch) => {
		dispatch({ type: 'AUTHENTICATION_REQUEST' })
		return fetchAuth(url)
			.then((response) => {
				Mixpanel.track('authenticate_session')
				dispatch({ type: 'AUTHENTICATION_SUCCESS'})
			})
			.catch((error) => {
				dispatch({ type: 'AUTHENTICATION_FAILURE'})
				throw error
			})
		}
}


    // if(process.env.REACT_APP_ENV=='development' && process.env.NODE_ENV=='development'){
    //   //local host with electron -->
    //   redirect_uri=process.env.REACT_APP_LINEAR_ELECTRON_REDIRECT_URI
    // }
        

export function authenticate(code) {
	let url = `/auth-web/linear/callback?code=${code}`
	if(window.isElectron){
		if(process.env.REACT_APP_ENV=='development' && process.env.NODE_ENV=='development'){
			url = `/auth-electron-local/linear/callback?code=${code}`
		}else{
			url = `/auth/linear/callback?code=${code}`
		}	
	}else if(process.env.NODE_ENV=='development'){
		url = `/auth-local/linear/callback?code=${code}`
	}	
	return (dispatch) => {
		dispatch({ type: 'AUTHENTICATION_REQUEST' })
		return fetchAuth(url)
		.then((response) => {
			const isLoadAfterLogin=true
			dispatch(loadInitialData(isLoadAfterLogin))
			dispatch({ type: 'AUTHENTICATION_SUCCESS' })
			Mixpanel.track('login')
			return response
		})
		.catch((error) => {
			dispatch({ type: 'AUTHENTICATION_FAILURE' })
			throw(error)
		})
	}
}


// export function authenticateJasper(code) { //temp hacky thing for jasper to log in on local electron
// 	let url = `/auth/jasper/linear/callback?code=${code}`
// 	return (dispatch) => {
// 		dispatch({ type: 'AUTHENTICATION_REQUEST' })
// 		return fetchAuth(url)
// 		.then((response) => {
// 			const isLoadAfterLogin=true
// 			dispatch(loadInitialData(isLoadAfterLogin))
// 			dispatch({ type: 'AUTHENTICATION_SUCCESS' })
// 			Mixpanel.track('login')
// 			return response
// 		})
// 		.catch((error) => {
// 			dispatch({ type: 'AUTHENTICATION_FAILURE' })
// 			throw(error)
// 		})
// 	}
// }



export function logout() {
	const url = `/auth/logout`
	return (dispatch) => {
		dispatch({ type: 'AUTHENTICATION_REQUEST'})
		return fetchAuth(url)
			.then((response) => {
				dispatch({ type: 'SIGN_OUT'})
				Mixpanel.track('logout')
			})
		.catch(() => {
			dispatch({ type: 'AUTHENTICATION_FAILURE'})
		})
	}
}
