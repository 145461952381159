//save and retreive progress through video

import store from '../store'
import find from 'lodash/find'
import moment from 'moment'

const MAX_TIME=60*10 //use video prgress if was in the last 10 mins


export function saveVideoProgress(nodeId,progress) {	
	store.dispatch({ type: 'SAVE_VIDEO_PROGRESS',nodeId:nodeId,progress:progress })
}


export function getSavedVideoProgress(nodeId) {	
	const state = store.getState()
	let progress=0
	let savedProgress=state.videoProgress[nodeId]
	if(savedProgress && savedProgress.timestamp){
		//check timestamp
		const timestamp=savedProgress.timestamp 
		let now = moment()
		let differenceInSeconds = now.diff(savedProgress.timestamp , 'seconds');
		if(differenceInSeconds<MAX_TIME){
			progress=savedProgress.progress
		}
	}
	return progress
}
