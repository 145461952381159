import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_ROADMAPS_SUCCESS':
		return action.response


// 	dispatch({ type: 'UPDATE_PROJECT_ROADMAPS_LOCALLY', projectId,roadmaps})


// case 'UPDATE_PROJECT_ROADMAPS_LOCALLY':
// 	const projectId=action.projectId
// 	const roadmaps=action.roadmaps
// 	//remove and add
// 	let newState=[]
// 	state.forEach((roadmap)=>{
// 		let newRoadmapProjects=[]
// 		roadmap.projects.forEach((id)=>{
// 			if(id!=projectId){
// 				newRoadmapProjects.push(id)
// 			}
// 		})
// 		if(roadmaps.includes(roadmap.roadmap_id)){
// 			newRoadmapProjects.push(projectId)
// 		}
// 		let newRoadmap={...roadmap}
// 		newRoadmap.projects=newRoadmapProjects
// 		console.log('new roadmap projects!')
// 		console.log(newRoadmapProjects)
// 		newState.push(newRoadmap)
// 	})
// 	return newState


case 'REMOVE_PROJECT_FROM_ROADMAP_REQUEST':
	const projectRemovedRoadmapId=action.roadmapId
	let removedIndex=findIndex(state,(roadmap => {
		return roadmap.roadmap_id === projectRemovedRoadmapId
	}))

	if(removedIndex > -1){
		let newRoadmap={...state[removedIndex]}
		let oldProjects=newRoadmap.projects
		let newProjectsArray=[]
		oldProjects.forEach((projId)=>{
			if(projId !== action.projectId){
				newProjectsArray.push(projId)
			}
		})
		newRoadmap.projects=newProjectsArray
		return [
			...state.slice(0,removedIndex),
			newRoadmap,
			...state.slice(removedIndex + 1)
			]
		}else return state


case 'ADD_PROJECT_TO_ROADMAP_REQUEST':
	const projectAddedRoadmapId=action.roadmapId
	let addedIndex=findIndex(state,(roadmap => {
		return roadmap.roadmap_id === projectAddedRoadmapId
	}))

	if(addedIndex > -1){
		let newRoadmap={...state[addedIndex]}
		let oldProjects=newRoadmap.projects
		let newProjectsArray=[...oldProjects]
		newProjectsArray.push(action.projectId)
		newRoadmap.projects=newProjectsArray
		return [
			...state.slice(0,addedIndex),
			newRoadmap,
			...state.slice(addedIndex + 1)
			]
		}else return state

case 'UPDATE_ROADMAP_PUSHER':
		const id=action.roadmap.roadmap_id
		let roadmapIndex=findIndex(state,(roadmap => {
			return roadmap.roadmap_id === id
		}))

		if(roadmapIndex === -1){
			roadmapIndex=state.length
		}

		return [
			...state.slice(0,roadmapIndex),
			action.roadmap,
			...state.slice(roadmapIndex + 1)
			]

	case 'UPDATE_ROADMAP_SUCCESS':
		const roadmapId=action.roadmapId
		let index=findIndex(state,(roadmap => {
			return roadmap.roadmap_id === roadmapId
		}))

		if(index === -1){
			index=state.length
		}

		return [
			...state.slice(0,index),
			action.response,
			...state.slice(index + 1)
			]

	case 'REPLACE_COVER_IMAGE_REQUEST':
		const requestRoadmapId=action.roadmapId

		let requestIndex=findIndex(state,(roadmap => {
			return roadmap.roadmap_id === requestRoadmapId
		}))

		let modified={...state[requestIndex]}
		modified.cover_image_cloudinary_id=null

		return [
			...state.slice(0,requestIndex),
			modified,
			...state.slice(requestIndex + 1)
			]



  //  case 'START_ROADMAP_IMAGE_UPLOAD':
		// const roadmapId=action.roadmapId
		// let index=findIndex(state,(roadmap => {
		// 		return roadmap.roadmap_id=== roadmapId
		// }))
		// if(index === -1){
		// 		return state
		// }else {
		// 	let modifiedRoadmap={...state[index]}
		// 	modifiedRoadmap.image_uploading=true
		// 	return [
		// 		...state.slice(0,index),
		// 		modifiedRoadmap,
		// 		...state.slice(index + 1)
		// 	]

		// }


	case 'SIGN_OUT':
      return []
	
	default:
		return state
	}
}



