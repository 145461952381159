import React, { useCallback, useState,useEffect } from 'react';
import Icon from '../misc/Icon';
import * as Dialog from '@radix-ui/react-dialog';
import Whiteboard from './Whiteboard';
import {closeWhiteboardModal} from '../../utils/whiteboards/closeWhiteboardModal';
import {insertWhiteboardEmbed} from '../../prosemirror/utils/whiteboards/insertWhiteboardEmbed'
import {updateWhiteboardEmbed} from '../../prosemirror/utils/whiteboards/updateWhiteboardEmbed'
import {Box2d,clamp} from '@tldraw/primitives'
import {compact} from '@tldraw/utils'

const maxDisplayWidth = 720 
const maxDisplayHeight = 800


function calcDisplayDimensions(editor){ //calculate display height and width
  let displayHeight
  let displayWidth
  const ids = [...editor.currentPageShapeIds]
  if (ids.length > 0){
    const opts=null
    const pageBounds = Box2d.Common(compact(ids.map((id) => editor.getPageBoundsById(id))))
    const width=pageBounds.width
    const height=pageBounds.height
    const maxDisplayAspect = maxDisplayWidth / maxDisplayHeight
    const pageBoundsAspect = width / height
    if(pageBoundsAspect > maxDisplayAspect){
      // page wider than max, so fits width
      displayWidth = maxDisplayWidth
      displayHeight = displayWidth / pageBoundsAspect
    }
    else{
      displayHeight = maxDisplayHeight
      displayWidth = displayHeight * pageBoundsAspect
    }
  }
  return {height:displayHeight,width:displayWidth}

}


const WhiteboardModal = ({ showModal,whiteboardModalEmbedId,theme,whiteboardSettings }) => {
	const [editor, setEditor] = useState(null);
	const [loading, setLoading] = useState(true)

	const handleMount = (editorInstance) => {
		setEditor(editorInstance);
		if(theme=='dark'){
			editorInstance.setDarkMode(true)
		}else{
			editorInstance.setDarkMode(false)
		}
		if(whiteboardSettings && whiteboardSettings.isGridMode){
			editorInstance.setGridMode(whiteboardSettings.isGridMode)
		}
		if(whiteboardSettings && whiteboardSettings.isToolLocked){
			editorInstance.setToolLocked(whiteboardSettings.isToolLocked)
		}
		if(whiteboardSettings && whiteboardSettings.isSnapMode){
			editorInstance.setSnapMode(whiteboardSettings.isSnapMode)
		}

		setTimeout(() => {
			editorInstance.zoomToFit()
			setTimeout(() => {
				setLoading(false);
			}, 20); 
		}, 50);    
	}	
	
	const closeAndSave = useCallback((e) => {
		const snapshot = editor.store.getSnapshot()
		const displayDimensions=calcDisplayDimensions(editor)
		if(whiteboardModalEmbedId){
			updateWhiteboardEmbed(whiteboardModalEmbedId,snapshot,displayDimensions)
		}else{
      //check if it is empty hacky way to see if has shapes
      if(Object.keys(snapshot.store).length>2){
        insertWhiteboardEmbed(snapshot,displayDimensions)
      }
		}

		closeWhiteboardModal();
    if(window.view){ //refocus editor
      window.view.focus()
    }

    setEditor(null)
    }, [editor]);

	useEffect(() => {
		setLoading(true); // set loading state to true whenever showModal changes
	}, [showModal]);	
 	
		useEffect(() => {
			if(editor){
				if(theme=='dark'){
					editor.setDarkMode(true)
				}else{
					editor.setDarkMode(false)
				}
			}
		}, [theme]);





  const handleEscape = useCallback((e) => {
    if(editor.selectedIds.length === 0){
      if(editor.currentToolId === 'select'){
        closeAndSave()
      }
    }else{
      // console.log(' dont close')
    }
  }, [editor]);




  useEffect(() => {
    const handleKeydown = (e) => {
      if (e.key === 'Escape') {

        handleEscape(e);
      }
    }

    window.addEventListener('keydown', handleKeydown,true);

    // cleanup function
    return () => {
      window.removeEventListener('keydown', handleKeydown,true  );
    }
  }, [handleEscape]);





    return (
        <React.Fragment>
            <Dialog.Root open={showModal}>
                <Dialog.Portal>
                    <Dialog.Overlay className='whiteboardModalContainer'>
                        <Dialog.Content 
                            className='whiteboardModal'
                           // onEscapeKeyDown={handleEscape}
                            onPointerDownOutside={closeAndSave}
                            onInteractOutside={closeAndSave}
                        >
                     
                            <Whiteboard 
                            loading={loading}
                            theme={theme}
                            whiteboardModalEmbedId={whiteboardModalEmbedId}
                            handleMount={handleMount}
                            closeAndSave={closeAndSave} />
                          
                        </Dialog.Content>
                    </Dialog.Overlay>
                </Dialog.Portal>
            </Dialog.Root>
        </React.Fragment>
    )
}

 export default WhiteboardModal;

//old close and save
 	// const closeAndSave = useCallback((e) => {
		// const snapshot = editor.store.getSnapshot()
  // //   editor.selectAll()
		// // editor.getSvg(editor.selectedIds).then((svg)=>{
		// // 	const snapshot = editor.store.getSnapshot()
		// // 	const svgString=getSvgAsString(svg)
  // //     getSvgAsImage(svg, imageOptions).then((image)=>{
  // //       let url = URL.createObjectURL(image);
  // //       console.log('blob url is----- ----')
  // //       console.log(url)
  // //       if(whiteboardModalEmbedId){
  // //         console.log('update current emved')
  // //         updateWhiteboardEmbed(whiteboardModalEmbedId,svgString,snapshot)
  // //       }else{
  // //         insertWhiteboardEmbed(svgString,snapshot,image,url)
  // //       }
  // //       closeWhiteboardModal();
  // //     })
		// // })
  //   }, [editor]);
