import store from '../store'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'



export function getMilestonesForProject(projectId) {	
	const state = store.getState()
	const projectMilestones=filter(state.projectMilestones,{project:projectId}) 
	const sorted=sortBy(projectMilestones, function(milestone){
  	return parseInt(milestone.sort_order,10)
  })
	return sorted
}

