// const defaultSettings={
// 	inProgressSortOrder: 'alphabetical',
// 	plannedSortOrder: 'roadmap',
// 	backlogSortOrder: 'alphabetical',
// 	completedSortOrder: 'completedAt' 
// }

  



function getInitialState(){
	const projectPageSettings = JSON.parse(localStorage.getItem('projectPageSettings'))
	if(projectPageSettings){
		return projectPageSettings
	}else{
		return {}
	}

}



export default function (state = getInitialState(), action) {
	switch (action.type) {

	case 'UPDATE_PROJECT_SETTING':
		const {setting,value}=action	
		let newState=Object.assign({},state)
		newState[setting]=value
		localStorage.setItem('projectPageSettings',JSON.stringify(newState))
		return newState
	
	default:
		return state
	}
}

