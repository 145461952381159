import React from 'react'
import { Transition, animated, config } from 'react-spring'
import AvatarImage from '../AvatarImage'
import Icon from '../misc/Icon'
import findIndex from 'lodash/findIndex'
import {getContactForId} from '../../utils/meetings/getContactForId'
import {getUserForId} from '../../utils/getUserForId'
import {assignSpeaker} from '../../utils/meetings/assignSpeaker'
import RecordingPlaybackModalIdentifySpeakersPanelVideoPlayer from './RecordingPlaybackModalIdentifySpeakersPanelVideoPlayer'


class RecordingPlaybackModalIdentifySpeakersPanelContentsSpeakerSelectBtn extends React.Component{  
  render(){ 
    const {id, isContact, isSelected, previouslySelected} = this.props
    let userObj
    let userDisplayName
    let contactId
    let avatarUrl
    if(!isContact){
      userObj = getUserForId(id)
    }else{
      userObj = getContactForId(id)
    }
    if(userObj && !isContact){
      userDisplayName = userObj.display_name
      avatarUrl = userObj.avatar
    }
    if(userObj && isContact){
      contactId=userObj.contact_id
      userDisplayName = userObj.name
      if(!userObj.name){
        userDisplayName = userObj.email
      }
    }
    return (            
      <button onClick={this.props.assignSpeaker} data-selected-state={isSelected ? true : false} data-previouslySelected-state={previouslySelected ? true : false} className='recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn'>
        <div className='recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn-avatarContainer'>
          <AvatarImage 
            className='recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn-avatar'
            avatarUrl={avatarUrl}
            contactId={contactId}
            sideLength={24}
          />
        </div>
        <div className='recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn-label'>
          {userDisplayName}
        </div>        
      </button>      
    )
  }
}

class RecordingPlaybackModalIdentifySpeakersPanelContentsSpeaker extends React.Component{  

  render(){ 
    const {speaker,speakers,isAudio}=this.props 
    const {showIdentifySpeakersPanel, closeIdentifySpeakersPanel, clipMuxPlaybackID} = this.props
    let user
    let contact
    let label=`Speaker ${speaker.transcript_speaker}`
    if(speaker.user_id){
      user=getUserForId(speaker.user_id)
      label=user.display_name 
    }else if(speaker.contact_id){
      contact=getContactForId(speaker.contact_id)
      label=contact.name || contact.email
    }
    return (      
      <div  id={'speaker-identify-panel'} className='recordingPlaybackModal-identifySpeakersPanel-speakerContainer'>
        <div className='recordingPlaybackModal-identifySpeakersPanel-speaker'>
          <div className='recordingPlaybackModal-identifySpeakersPanel-speaker-media'>
            <RecordingPlaybackModalIdentifySpeakersPanelVideoPlayer 
              muxPlaybackID={speaker.clip.playback_id}
              hasAssignment={speaker.contact_id || speaker.user_id}
              isAudio={isAudio}
            />          
          </div>          
          <div className='recordingPlaybackModal-identifySpeakersPanel-speaker-selection'>
            {this.props.participants.map(participant => {
              let id = participant.user_id ? participant.user_id : participant.contact_id;
              let isContact = !!participant.contact_id; // True if the item is a contact
              if(isContact){
                const isAlreadyAssigned=findIndex(speakers,{contact_id:participant.contact_id})>-1
                const isSelected=speaker.contact_id==participant.contact_id
                const previouslySelected=isAlreadyAssigned && !isSelected
                return (
                  <RecordingPlaybackModalIdentifySpeakersPanelContentsSpeakerSelectBtn 
                    id={id}
                    isContact={isContact}  
                    isSelected={isSelected} // selected for this speaker
                    previouslySelected={previouslySelected} // has been selected as a different speaker     
                    assignSpeaker={()=>{this.props.assignSpeakerToContact(speaker,participant.contact_id)}}     
                  />
                );
              }
              else{
                const isAlreadyAssigned=findIndex(speakers,{user_id:participant.user_id})>-1
                const isSelected=speaker.user_id==participant.user_id
                const previouslySelected=isAlreadyAssigned && !isSelected
                return (
                  <RecordingPlaybackModalIdentifySpeakersPanelContentsSpeakerSelectBtn 
                    id={id}
                    isContact={isContact}  
                    isSelected={isSelected} // selected for this speaker
                    previouslySelected={previouslySelected} // has been selected as a different speaker     
                    assignSpeaker={()=>{this.props.assignSpeakerToUser(speaker,participant.user_id)}}     
                  />
                );
              }
            })}   
            {/*         
            <button className='recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn recordingPlaybackModal-identifySpeakersPanel-speaker-selectSpeakerBtn--someoneElse'>
              Someone else
            </button>
            */}
          </div>
        </div>      
      </div>
    )
  }
}


class RecordingPlaybackModalIdentifySpeakersPanelContents extends React.Component{  

  constructor(props){
    super(props) 
    this.assignSpeakerToUser=this.assignSpeakerToUser.bind(this)
    this.assignSpeakerToContact=this.assignSpeakerToContact.bind(this)
    this.state={
      speakers: props.recording.speakers,      
    }
  }

  assignSpeakerToUser(speaker,userId){
    let newSpeakers=[]
    let newUserId=userId
    this.state.speakers.forEach((spk)=>{
      if(spk.speaker_id==speaker.speaker_id){
        let newSpeaker={...speaker}
        if(speaker.user_id==userId){ //Toggle
          newSpeaker.user_id=null
          newUserId=null
        }else{
          newSpeaker.user_id=userId
        }
        newSpeaker.contact_id=null
        newSpeakers.push(newSpeaker)
      }else{
        newSpeakers.push(spk)
      }
    })
    this.setState({speakers:newSpeakers})
    assignSpeaker(this.props.recording,speaker,newUserId)
  }

  assignSpeakerToContact(speaker,contactId){
    let newSpeakers=[]
    let newContactId=contactId
    this.state.speakers.forEach((spk)=>{
    if(spk.speaker_id==speaker.speaker_id){
      let newSpeaker={...speaker}
      if(speaker.contact_id==contactId){ //Toggle
          newSpeaker.contact_id=null
          newContactId=null
        }else{
          newSpeaker.contact_id=contactId
        }
      newSpeaker.user_id=null
      newSpeakers.push(newSpeaker)
    }else{
      newSpeakers.push(spk)
    }
    })
    this.setState({speakers:newSpeakers})
    const userId=null
    assignSpeaker(this.props.recording,speaker,userId,newContactId)
  }

  render(){ 
    const {meeting,recording}=this.props
    const {showIdentifySpeakersPanel, closeIdentifySpeakersPanel} = this.props
    const {speakers} = this.state
    const initialLoad = false
    let participants=[]
    meeting.participants.forEach((participant)=>{
      const obj={user_id:participant}
      participants.push(obj)
    })
    meeting.external_participants.forEach((participant)=>{
      const obj={contact_id:participant}
      participants.push(obj)
    })

    const isAudio = recording.is_audio

    return (      
      <React.Fragment>
        {/*}
        <div className='recordingPlaybackModal-identifySpeakersPanel-header'>
          <div className='recordingPlaybackModal-identifySpeakersPanel-header-tag'>
            <div className='recordingPlaybackModal-identifySpeakersPanel-header-tag-iconContainer'>
              <Icon name='waveform' />
            </div>
            <div className='recordingPlaybackModal-identifySpeakersPanel-header-tag-label'>
              Identify Speakers
            </div>
          </div>

        </div>        
        */}

        <div className='recordingPlaybackModal-identifySpeakersPanel-list'>
          {speakers.map((speaker=>{
            return(
              <RecordingPlaybackModalIdentifySpeakersPanelContentsSpeaker 
                key={`speaker_assignment_${speaker.speaker_id}`}
                speaker={speaker}
                assignSpeakerToUser={this.assignSpeakerToUser}
                assignSpeakerToContact={this.assignSpeakerToContact}
                participants={participants}
                speakers={speakers}      
                isAudio={isAudio}          
              />
              )
          }))}
        </div>
        <div className='recordingPlaybackModal-identifySpeakersPanel-doneBtnContainer'>
          <button onClick={this.props.closeIdentifySpeakersPanel} className='recordingPlaybackModal-identifySpeakersPanel-doneBtn'>
            Done
          </button>
        </div>
        <div onClick={this.props.closeIdentifySpeakersPanel} className='recordingPlaybackModal-identifySpeakersPanel-bg'/>
      </React.Fragment>
    )
  }
}

export default RecordingPlaybackModalIdentifySpeakersPanelContents