import store from '../store'

//update redux with isValid values in plugin
export function setInitialMessageSelections(messageSelections) {
	const state = store.getState()
	store.dispatch({type:'FETCH_MESSAGE_SELECTIONS',messageSelections})
}


export function setSelectionToInvalid(selectionId) {
	const state = store.getState()
	store.dispatch({type:'MESSAGE_SELECTION_INVALID',selectionId})
}



export function addNewSelection(selectionObj) {
	const state = store.getState()
	store.dispatch({type:'NEW_MESSAGE_SELECTION',selectionObj})
}
