import languageOptions from './languageOptions'
import find from 'lodash/find'

export function getLabelForLanguage(language) {
	let label='test'
	const languageObj=find(languageOptions,{language:language})
	if(languageObj){
		label=languageObj.label
	}

	return label
}