import React from 'react'
import SideMenuFooterDocPresence from './SideMenuFooterDocPresence'
import SideMenuFooterDocDefault from './SideMenuFooterDocDefault'
import Icon from '../../misc/Icon'
import {isPrivateDoc} from '../../../utils/isPrivateDoc'

function getActiveViewType(pathname){
	let viewType='allDocs'
  if (pathname.indexOf('/doc/') > -1){
  	viewType='doc'
	}else if (pathname.indexOf('/team/') > -1){
  	viewType='team'
	}else if (pathname.indexOf('/roadmaps') > -1){
  	viewType='roadmaps'	
  }else if (pathname.indexOf('/meetings') > -1){
  	viewType='meetingNotes'	
	}else if (pathname.indexOf('/recent') > -1){
  	viewType='recent'	
	}else if (pathname.indexOf('/private') > -1){
  	viewType='drafts'
	}
	return viewType
}


class SideMenuFooter extends React.Component{  	

	render(){	
		const {docPresenceMembers, pathname,orgMembers,org,roadmap} = this.props
		const activeViewType = getActiveViewType(pathname)
		let footerType = activeViewType
		if(activeViewType === 'doc' && docPresenceMembers && Object.keys(docPresenceMembers).length < 2){
			footerType = 'doc'
		}
		if(activeViewType === 'doc' && docPresenceMembers && Object.keys(docPresenceMembers).length > 1){
			footerType = 'docPresence'
		}
		let orgName
		if(org){
			orgName = org.name
			if(org.name === 'Yarn'){
				orgName ='Acme Inc.' // for demos		
			}
		} 	
		let docIsPrivate=false
		if(activeViewType=='doc'){
			if(this.props.doc){
				docIsPrivate=isPrivateDoc(this.props.doc.doc_id)
			}
		}
	  return (
			<React.Fragment>
			{footerType === 'roadmaps' && !roadmap &&
				<div className='sideMenu-tabList-footer sideMenu-tabList-footer--roadmaps'>
					<div className='sideMenu-tabList-footer-divider' />
					 Roadmaps
				</div>
			}		
			{footerType === 'roadmaps' && roadmap &&
				<div className='sideMenu-tabList-footer sideMenu-tabList-footer--roadmap'>
					<div className='sideMenu-tabList-footer-divider' />					
					<div className='sideMenu-tabList-footer--roadmap-iconContainer'>
						<Icon name='roadmapOutline' />
					</div>
					<div className='sideMenu-tabList-footer--roadmap-label'>
						{roadmap.name}
					</div>						
				</div>
			}		
			{footerType === 'recent' &&
				<div className='sideMenu-tabList-footer sideMenu-tabList-footer--allDocs'>
					<div className='sideMenu-tabList-footer-divider' />
					 Recent
				</div>
			}		
			{footerType === 'allDocs' && orgName &&
				<div className='sideMenu-tabList-footer sideMenu-tabList-footer--allDocs'>
					<div className='sideMenu-tabList-footer-divider' />
					 {orgName}
				</div>
			}		
			{footerType === 'meetingNotes' &&
				<div className='sideMenu-tabList-footer sideMenu-tabList-footer--privateDocs'>
					<div className='sideMenu-tabList-footer-divider' />
					 Meeting Notes
				</div>
			}
			{footerType === 'drafts' &&
				<div className='sideMenu-tabList-footer sideMenu-tabList-footer--privateDocs'>
					<div className='sideMenu-tabList-footer-divider' />
					 Your Private Drafts
				</div>
			}
			{footerType === 'doc' && docIsPrivate &&
				<div className='sideMenu-tabList-footer sideMenu-tabList-footer--privateDocs'>
					<div className='sideMenu-tabList-footer-divider' />					
					 Private Draft
				</div>
			}
			
			{footerType === 'doc' && !docIsPrivate &&
				<SideMenuFooterDocDefault 
					doc={this.props.doc}
					orgMembers={orgMembers}
				/>
			}
			{footerType === 'docPresence' && this.props.doc && 
				<SideMenuFooterDocPresence
					docPresenceMembers={this.props.docPresenceMembers}					
					docName={this.props.doc.name}
					doc={this.props.doc}
					orgMembers={orgMembers}
				/>
			}
			</React.Fragment>
	  )
	}
}

export default SideMenuFooter

								
			// {/* NEED TO PASS IN ACTIVE TEAM HERE */}
			// {footerType === 'team' && 
			// 	<div className='sideMenu-tabList-footer sideMenu-tabList-footer--allDocs'>
			// 		<div className='sideMenu-tabList-footer-divider' />					
			// 		Team					
			// 	</div>
			// }