import React from 'react'
import BlockquoteNodeSpec from './BlockquoteNodeSpec'
import UserMentionNodeSpec from './UserMentionNodeSpec'
import LinearIssueMentionNodeSpec from './LinearIssueMentionNodeSpec'
import MessageLinearProjectMentionNodeSpec from './MessageLinearProjectMentionNodeSpec'
import MessageInternalLinkNodeSpec from './MessageInternalLinkNodeSpec'
import EmojiNodeSpec from './EmojiNodeSpec'

//https://github.com/ProseMirror/prosemirror-schema-basic/blob/master/src/schema-basic.js
const pDOM = ["p", 0], blockquoteDOM = ["blockquote", 0], hrDOM = ["hr"],
      preDOM = ["pre", ["code", 0]], brDOM = ["br"]


// :: Object
// [Specs](#model.NodeSpec) for the nodes defined in this schema.
export const nodes = {
  doc: {
    content: "block+"
  },
  paragraph: {
    attrs:{
      indentLevel:{default:0},
    },
    content: "inline*",
    group: "block",
    parseDOM: [
      {
        tag: "p"
      },
    ],
    toDOM(node) {
      const {nodeId,indentLevel} = node.attrs;
      return ["div",{class:`message-para`}, 0]
    }},
  blockquote:BlockquoteNodeSpec,
  userMention:UserMentionNodeSpec,
  linearIssueMention:LinearIssueMentionNodeSpec,
  linearProjectMention:MessageLinearProjectMentionNodeSpec,
  internalLink:MessageInternalLinkNodeSpec,
  emoji:EmojiNodeSpec,
  text: {
    group: "inline"
  },
  image: {
    inline: true,
    attrs: {
      src: {},
      alt: {default: null},
      title: {default: null}
    },
    group: "inline",
    draggable: true,
    parseDOM: [{tag: "img[src]", getAttrs(dom) {
      return {
        src: dom.getAttribute("src"),
        title: dom.getAttribute("title"),
        alt: dom.getAttribute("alt")
      }
    }}],
    toDOM(node) { let {src, alt, title} = node.attrs; return ["img", {src, alt, title}] }
  },
  hard_break: {
    inline: true,
    group: "inline",
    selectable: false,
    parseDOM: [{tag: "br"}],
    toDOM() { return brDOM }
  }
}

export default nodes