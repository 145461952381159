
import schema from '../../schema/editorSchema'  
import {TextSelection} from "prosemirror-state"
import {joinPoint, canJoin, findWrapping, liftTarget, canSplit,
        ReplaceStep, ReplaceAroundStep, replaceStep} from "prosemirror-transform"
import {wrapIn,lift,setBlockType,createParagraphNear,chainCommands} from 'prosemirror-commands'
import {getAnchorNode} from '../getAnchorNode'

//https://prosemirror.net/docs/ref/#commands.createParagraphNear

export function insertTextLinkCommand() {
  return function(state, dispatch) {
    const nodeType = schema.nodes.textLink;
    const { from, to } = state.selection;
    let textNodeCount=0
    state.doc.nodesBetween(from, to, (node) => {
      if(node.type.name=='text'){
        //console.log(node)
        textNodeCount+=1
      }
    });
    if (textNodeCount !=1) {
      return false;
    }
    const textContent = state.doc.textBetween(from, to);
    const type='weblink'
    const url='www.google.com'
    const newNode = nodeType.create({type:type,url:url}, schema.text(textContent));


    if (dispatch) {
      const tr = state.tr;
      tr.replaceRangeWith(from, to, newNode);
      dispatch(tr);
    }

    return true;

  }
}

