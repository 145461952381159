import React from 'react'
import { Spring, animated, config } from 'react-spring'

class ResizablePanel extends React.Component{  	

	render(){	

		const {warp} = this.props

		const preX = 20
		const preY = 300
		const startWidth = 200

		const postX = 200
		const postY = 200		
		const endWidth= 50

		const transformOrigin = '0% 20%'

		const scaleEnd = endWidth / startWidth

		const translateX = (postX - preX) / scaleEnd
		const translateY = (postY - preY) / scaleEnd	

		

	  return (
	  	
	  	<React.Fragment>
	  	<Spring   			
	  		config={config.molasses}  			  			
					to={[ 
						{
							position: 'absolute',
							left: `${preX}px`,
							top: `${preY}px`,
							transform: warp ? 
									`scale(0.8) 	scaleY(1.4) 	translateY(-50px) translateX(40px) 	rotateX(-20deg)	 rotateY(-20deg)	 rotateZ(-20deg)` 
								: 'scale(1) 		scaleY(1) 		translateY(0px) 	translateX(0px) 		rotateX(0deg) 	 rotateY(0deg)	 rotateZ(0deg)'
							,
							transformOrigin: warp ? '100% 50%' : '30% 50%'
						},
						{																				
							transform: warp ? 
									`scale(1.8) 	scaleY(1.2) 	translateY(-200px) translateX(400px) 	rotateX(-40deg)	 rotateY(-50deg)	 rotateZ(-80deg)` 
								: 'scale(1) 		scaleY(1) 		translateY(0px) 	translateX(0px) 		rotateX(0deg) 	 rotateY(0deg)	 rotateZ(0deg)'
							,
							transformOrigin: warp ? '100% 50%' : '30% 50%'
						},
					]}                    
  		>
        {styles => 
          <animated.div style={styles} className='warpImage'>          	  		
		  			<div className='warpImageThird' />
		  			<div className='warpImageThird' />
		  			<div className='warpImageThird' />
		  			<div className='warpImageThird' />
		  			<div className='warpImageThird' />
		  			<div className='warpImageThird' />
		  			<div className='warpImageThird' />
		  			<div className='warpImageThird' />
		  			<div className='warpImageThird' />
	  		</animated.div>

        }
      </Spring>

      </React.Fragment>
			
	  )
	}
}

export default ResizablePanel
