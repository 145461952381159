import React from 'react'
import Icon from '../misc/Icon'
import VideoDuration from '../../utils/videoDuration'
import SpritesheetImage from '../misc/SpritesheetImage'
import ReactSlider from 'react-slider'
import {fetchStoryboardImageJSON} from '../../actions/video'

class RecordingPlaybackModalMediaPlaybackControlsSeekBar extends React.Component{  

	 constructor(props){
    super(props)
    this.calculateSpritesheetOffset=this.calculateSpritesheetOffset.bind(this)

    this.state={
      json:null
    }
  }

  componentDidMount(){
    fetchStoryboardImageJSON(this.props.muxPlaybackID).then((response)=>{
      this.setState({json:response})
    })    
  }

  calculateSpritesheetOffset(duration){
    let x=0
    let y=0
    const {json}=this.state
    let foundTile=false
    if(json && json.tiles){
      json.tiles.forEach((tile)=>{
        if(tile.start>duration && !foundTile){
          x=tile.x 
          y=tile.y 
          foundTile=true
        }
      })
    }
    return {x:x,y:y}
  }



	render(){ 
		
    const {mediaType, played, handleSeekChange, handleSeekMouseDown, handleSeekMouseUp, duration, muxPlaybackID} = this.props

    const {       
      elementDimensions: {
        width = 0,
        height = 0
      } = {},
      isActive = false,
      isPositionOutside = false,
      position: {
        x = 0,
        y = 0
      } = {}
    } = this.props


    let storyboardTileWidth = 256
    let storyboardTileHeight = 160     
    if(this.state.json){
      storyboardTileWidth = this.state.json.tile_width
      storyboardTileHeight = this.state.json.tile_height  
    }

    const storyboardTileAspect = storyboardTileWidth / storyboardTileHeight
    const storyboardImageURL = `https://image.mux.com/${this.props.muxPlaybackID}/storyboard.png`

    const widthHoverPreview = 160
    const heightHoverPreview = widthHoverPreview / storyboardTileAspect

    const storyboardOffsetX = 0
    const storyboardOffsetY = 200

    const minXHoverPreview = 5 // don't let preview overflow left edge
    const maxXHoverPreview = width - widthHoverPreview - 5  // don't let preview overflow right edge      
    const standardXHoverPreview = x - (0.5 * widthHoverPreview)

    const hoverPreviewPosition = Math.max(minXHoverPreview, Math.min(maxXHoverPreview, standardXHoverPreview));

    const percentHoverX = x / width
    const durationHoverX = percentHoverX * duration

    let spriteSheetOffset={x:0,y:0}
    if(!isPositionOutside){
      spriteSheetOffset=this.calculateSpritesheetOffset(durationHoverX)
    }

		return (
			<div className='recordingPlaybackModal-media-playbackControls-seekBarInnerContainer'>
        <ReactSlider
          className="recordingPlaybackModal-media-playbackControls-seekBar"
          thumbClassName="recordingPlaybackModal-media-playbackControls-seekBar-thumb"
          trackClassName="recordingPlaybackModal-media-playbackControls-seekBar-track"                  
          renderThumb={(props, state) => 
            <div className='recordingPlaybackModal-media-playbackControls-seekBar-thumb-thumb' {...props}>
              <div style={{height: `${this.props.speakerTimelinesContainerHeight + 24}px`}} className='recordingPlaybackModal-media-playbackControls-seekBar-thumb-thumb-line' />
            </div>
          }

          value={played}
          min={0}
          max={0.999999}
          step={0.0001}
          onAfterChange={handleSeekMouseUp}
          onBeforeChange={handleSeekMouseDown}
          onChange={handleSeekChange}
        />              
			
        {!isPositionOutside && mediaType === 'video' &&
          <div style={{width: `${widthHoverPreview}px`, height: `${heightHoverPreview}px`, top: `${(heightHoverPreview * -1) - 3}px`,  left: `${hoverPreviewPosition}px`}} className='recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview'>
            <SpritesheetImage 
              tileWidth={storyboardTileWidth}
              tileHeight={storyboardTileHeight}
              tileAspect={storyboardTileAspect}
              outputImageWidth={widthHoverPreview}
              outputImageHeight={heightHoverPreview}
              offsetX={spriteSheetOffset.x}
              offsetY={spriteSheetOffset.y}
              spriteSheetImageUrl={storyboardImageURL}
            />

            <div className='recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview-timestampContainer'>             
              <div className='recordingPlaybackModal-media-playbackControls-seekBar-hoverPreview-timestamp'>
                <VideoDuration seconds={durationHoverX} />                
              </div>
            </div>
          </div>
        }

        {!isPositionOutside && mediaType === 'video' &&
          <div style={{left: `${x}px`}} className='recordingPlaybackModal-media-playbackControls-seekBar-hoverMarker' />          
        }
        
			</div>
		)
	}
}

export default RecordingPlaybackModalMediaPlaybackControlsSeekBar
