import store from '../store'
//pass from node view into redux so can access in DocEditorContainer.js



export function toggleVideoZoomInRedux(isZoomed) {	
	if(isZoomed){
		store.dispatch({ type:'ZOOM_VIDEO_MODAL'})
	}else{
		store.dispatch({ type:'UNZOOM_VIDEO_MODAL'})
	}	
}



