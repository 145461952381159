import store from '../store'

export function updateProjectPageSetting(setting,value) {	

	store.dispatch({ type:'UPDATE_PROJECT_SETTING',setting,value})
	
}



