import findIndex from 'lodash/findIndex'


export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_CALENDAR_EVENTS_SUCCESS':
		return action.response

	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
 