import schema from '../../schema/editorSchema'  
import store from '../../../store'
import {TextSelection} from "prosemirror-state"
import {updateWhiteboardEmbedRequest} from '../../../actions/whiteboardEmbed'
import {bumpFigmaEmbedVersion} from '../bumpFigmaEmbedVersion'


export function updateWhiteboardEmbed(embedId,snapshot,displayDimensions) {
	
	store.dispatch(updateWhiteboardEmbedRequest(embedId,snapshot,displayDimensions))
	//bumpFigmaEmbedVersion()

	if(window.view && window.view.state){
		let tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.embedId==embedId){
				tr.setNodeMarkup(pos, null, {...node.attrs,whiteboardEmbedVersion:node.attrs.whiteboardEmbedVersion+1})
			}
		})
		tr.setMeta('addToHistory', false)  
		window.view.dispatch(tr)
	}

}
