import {Plugin, PluginKey} from 'prosemirror-state'
import {TextSelection} from "prosemirror-state"
import {getAnchorNode} from '../utils/getAnchorNode'

//If backspace at the begining of heading
	//heading is empty --> delete heading
	//heading has text --> turn from heading into normal text (a la notion)

const key = new PluginKey('headingBackspacePlugin')


function getNodePos(anchorNode){
	const state=window.view.state
	let nodePos
	state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
		if(anchorNode===node){
			nodePos=pos
		}
	})
	return nodePos
}

export function deleteHeadingPlugin(){
	return new Plugin({
		key,
		props: {
			handleDOMEvents: {
				keydown (view, event) {
					if(event.keyCode===8){//delete key
						let state = view.state
						let sel = state.selection
						const $from=state.doc.resolve(sel.anchor)
						const anchorNode=getAnchorNode($from,'heading')
						if(anchorNode){
							const anchorPos=getNodePos(anchorNode)							
							if(anchorNode.textContent && anchorNode.textContent.length>0){
								if($from.parentOffset==0){//cursor is at the begining of header --> turn into text
									event.preventDefault()
									const textNode = view.state.schema.text(anchorNode.textContent)
									const pos=getNodePos(anchorNode)
									let tr = state.tr
									tr.replaceWith(pos,pos+anchorNode.nodeSize,textNode)  
									let selection=new TextSelection(tr.doc.resolve(pos+1))
									tr.setSelection(selection)//put cursor at the begining of new text node
									window.view.dispatch(tr)
								}
							}else{
								event.preventDefault()
								const pos=getNodePos(anchorNode)
								let tr=state.tr
								tr.delete(pos,pos+anchorNode.nodeSize)    
								window.view.dispatch(tr)
							}
						}
					}
				}
			}
		}
	})
}