import React from 'react'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import AvatarImage from '../../AvatarImage'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import {getUserForId} from '../../../utils/getUserForId'
import {getProjectForId} from '../../../utils/getProjectForId'
import {getPriorityLabel} from '../../../utils/getPriorityLabel'
import LinearIssueWorkflowStateIcon from '../../misc/LinearIssueWorkflowStateIcon'
import LinearIssuePriorityIcon from '../../misc/LinearIssuePriorityIcon'


class LinearIssuePreviewPopoverContent extends React.Component{  	
	

	render(){	
		const {projectId, project, assignee, workflowType, workflowStateName, workflowColorHex, workflowTypeStateIndex, workflowTypeStatesCount, title, identifier, priority, description} = this.props

  	const priorityLabel = getPriorityLabel(priority)
	  	  
  	let projectName

  	if(project) {
  		projectName = project.name
  	}  	

		return (			
				<div className='linearIssuePreviewPopover popover'>
				{projectId && project && 
	  			<div className='linearIssuePreviewPopover-projectTagContainer'>
		  			<div className='linearIssuePreviewPopover-projectTag'>
		  				<div className='linearIssuePreviewPopover-projectTag-markerContainer'>
		  					<LinearMarker projectId={projectId} />
		  				</div>
		  				<div className='linearIssuePreviewPopover-projectTag-label'>
		  					{project.name}
		  				</div>
		  			</div>
		  		</div>
  			}

  			<div className='linearIssuePreviewPopover-identifier'>
  				{identifier}
  			</div>

  			<div className='linearIssuePreviewPopover-title'>
  				{title}
  			</div>
  			
  			<div className='linearIssuePreviewPopover-summaryRow'>
  				{assignee &&
	  				<div className='linearIssuePreviewPopover-summaryRow-assignee linearIssuePreviewPopover-summaryRow-assignee--assigned'>
	  					<div className='linearIssuePreviewPopover-summaryRow-assignee-avatar'>
	  						<AvatarImage
									className='linearIssuePreviewPopover-summaryRow-assignee-avatar-avatar'
									avatarUrl={assignee.avatar}
									smallAvatar
								/>
	  					</div>
	  					<div className='linearIssuePreviewPopover-summaryRow-assignee-name'>
	  						{assignee.display_name}
	  					</div>  					
	  				</div>
  				}
  				{!assignee &&
	  				<div className='linearIssuePreviewPopover-summaryRow-assignee linearIssuePreviewPopover-summaryRow-assignee--unassigned'>  					
	  					<div className='linearIssuePreviewPopover-summaryRow-assignee-avatar'>
	  						<Icon name='userCircle' />
	  					</div>							  					
	  					<div className='linearIssuePreviewPopover-summaryRow-assignee-name'>
	  						Unassigned
	  					</div>  					
	  				</div>
  				}
  				<div className='linearIssuePreviewPopover-summaryRow-status'>
  					<div className='linearIssuePreviewPopover-summaryRow-status-iconContainer'>
  						<LinearIssueWorkflowStateIcon
  							workflowColorHex={workflowColorHex}
  							workflowType={workflowType}
  							workflowTypeStateIndex={workflowTypeStateIndex}
  							workflowTypeStatesCount={workflowTypeStatesCount}
  						/>
  					</div>
  					<div className='linearIssuePreviewPopover-summaryRow-status-label'>
  						{workflowStateName}
  					</div>
  				</div>
  				<div className='linearIssuePreviewPopover-summaryRow-priority'>
  					<div className='linearIssuePreviewPopover-summaryRow-priority-iconContainer'>
  						<LinearIssuePriorityIcon
  							priority={priority}  							
  						/>
  					</div>
  					<div className='linearIssuePreviewPopover-summaryRow-priority-label'>
  						{priorityLabel}
  					</div>
  				</div>
  			</div>

  			<div className='linearIssuePreviewPopover-divider' />


  			{description && 
  			<div className='linearIssuePreviewPopover-descriptionContainer'>
	  			<div className='linearIssuePreviewPopover-description'>
	  				{description}
	  				{/*}
	  				<div className='linearIssuePreviewPopover-description-para'>
	  					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce nec ipsum tristique, malesuada diam sed, vehicula erat. Vestibulum sed imperdiet elit. 
	  				</div>
	  				<div className='linearIssuePreviewPopover-description-para'>
	  					Praesent facilisis quam nec commodo volutpat. Quisque viverra id nisi sit amet fermentum. Cras egestas tincidunt ipsum, vitae vehicula lectus tempor vel. Nunc ac finibus libero Cras egestas tincidunt ipsum, vitae vehicula lectus tempor vel.
	  				</div>
	  				*/}
	  			</div>
	  			<div className='linearIssuePreviewPopover-descriptionOverflowGradient'/>
  			</div>
  			}

  			{!description && 
	  			<div className='linearIssuePreviewPopover-descriptionContainer linearIssuePreviewPopover-descriptionContainer--noDescription'>
		  			<div className='linearIssuePreviewPopover-description'>
		  				No description		  				
		  			</div>							  			
	  			</div>
  			}
  			
  			{/* TO DO: ADD LABELS
	  				{label1Title &&
		  			<div className='linearIssuePreviewPopover-labelsContainer'>
		  				<div className={'linearIssuePreviewPopover-labels-label ' + (label1Color ? ` linearIssuePreviewPopover-labels-label--color--${label1Color}` : '')}>
		  					<div style={{background: `${label1Color}`}} className='linearIssuePreviewPopover-labels-label-dot' />
		  					<div className='linearIssuePreviewPopover-labels-label-name'>
		  						{label1Title}
		  					</div>  					
		  				</div>  				
		  			</div>
	  				}
  			*/}
  		</div>								
		)
	}
}

export default LinearIssuePreviewPopoverContent