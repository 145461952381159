import React from 'react'
import ReactDOM from 'react-dom'
import LinearProjectMentionReactComponent from './LinearProjectMentionReactComponent'


class LinearProjectMentionNodeView {
  
  constructor(node, view, getPos,handleOpenDoc) {
    this.dom = document.createElement("span")
    this.dom.className="doc-para-projectLinkContainer"
    const {projectId}=node.attrs
    
    ReactDOM.render(
      <LinearProjectMentionReactComponent 
        handleOpenDoc={handleOpenDoc}
        projectId={projectId}
      />, this.dom)   
  }
 
  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom)
  }
  
}

export default LinearProjectMentionNodeView