import React from 'react'
import Icon from '../../misc/Icon'
import VideoDuration from '../../../utils/videoDuration'


class DocVideoInlineUIHeader extends React.Component{  
	
	 static getDerivedStateFromProps(props, state) {
		if(props.title != state.value){
			const el= document.getElementById(`inline-input-${props.nodeId}`)
			if(el && el==document.activeElement){
					return null
			}else{
					return ({value:props.title})
				}
			}	else return null
		}



	 constructor(props){
    super(props)
    this.handleChange = this.handleChange.bind(this);
    let title=''
    if(props.title){
    	title=props.title
    }
    this.state={
      value: title,
    }
  }

  handleKeyDown(e){
  	if(e.keyCode==13){
  		e.preventDefault()
  		e.target.blur()
  	}
  }
  handleChange(event) {
    this.setState({value: event.target.value});
    this.props.updateTitle(event.target.value)
  }
  handleOnFocus(e){
  	//e.target.select()
  }

	render(){ 
		const {title, duration} = this.props				
		
		return (	
				<div className='doc-video-inlineUI-header'>					
					<input 
						className='doc-video-inlineUI-header-videoTitleInput'
						id={`inline-input-${this.props.nodeId}`}
						placeholder='Untitled Video'
						value={this.state.value}
						onChange={this.handleChange} 	
						onFocus={this.handleOnFocus}		
						onKeyDown={this.handleKeyDown}		
						autoComplete="off"
						autoCorrect="off"
						spellCheck={false}
					/>  				
				</div>
							
		)
	}
}

export default DocVideoInlineUIHeader
