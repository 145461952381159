import React from 'react'
import {openDoc,backgroundOpenDoc} from '../../utils/openDoc'
import { withRouter} from 'react-router-dom'
import * as ContextMenu from '@radix-ui/react-context-menu';
import LinearMarker from '../misc/LinearMarker'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import LinearProjectStatusTag from '../misc/LinearProjectStatusTag'
import Icon from '../misc/Icon'
import {getProjectForId} from '../../utils/getProjectForId'
import {getTeamForId} from '../../utils/getTeamForId'
import {getRoadmapForId} from '../../utils/getRoadmapForId'
import {getProjects} from '../../utils/getProjects'
import {getTeams} from '../../utils/getTeams'
import {getRoadmaps} from '../../utils/getRoadmaps'
import sortBy from 'lodash/sortBy'
import {moveDocToRoadmap,moveDocToProject,moveDocToOrg} from '../../utils/moveDoc'
import {formatDate} from '../../utils/formatDate'
import {deleteDoc} from '../../utils/deleteDoc'
import {calculateDocNotificationCount} from '../../utils/notifications/calculateNotificationCounts'
import {copyDocLinkToClipboard} from '../../utils/copyDocLinkToClipboard'
import {getOrg} from '../../utils/getOrg'
import {getMeetingForId} from '../../utils/meetings/getMeetingForId'
import {getFormattedTimestampForMeeting} from '../../utils/meetings/getFormattedTimestampForMeeting'

class DocTableRow extends React.PureComponent{  

	constructor(){
		super()
		this.handleClickRow=this.handleClickRow.bind(this)
	}

	handleClickRow(e){
		const {doc} = this.props
		if(e.metaKey){
			backgroundOpenDoc(doc.doc_id)
		}else{
			openDoc(this.props.history,doc.doc_id)
		}
	}

	render(){	
		const {doc, libraryView}=this.props
		let hasNotifications = false
		const notificationCount = calculateDocNotificationCount(doc.doc_id,doc)
		if(notificationCount && notificationCount>0){
			hasNotifications=true
		}
		let project
		let projectId
		if(doc && doc.project){
			project = getProjectForId(doc.project)
			projectId = doc.project
		}
		let team
		let teamId
		let roadmap
		let meeting
		let formattedMeetingDateTime

		if(doc && doc.team){
			team = getTeamForId(doc.team)
			teamId = doc.team
		}		
		let docType = 'privateDoc'
		if(doc && doc.project && doc.is_project_doc){
			docType = 'projectRootDoc'	
		}else if(doc && doc.project && !doc.is_project_doc){
			docType = 'projectSubDoc'			
		}else if(doc && doc.is_org_doc){
			docType = 'orgDoc'	
		}else if(doc && doc.roadmap){
			docType = 'roadmapDoc'	
			roadmap=getRoadmapForId(doc.roadmap)
		}		
		else if(doc && doc.meeting){
			docType = 'meetingDoc'	
			meeting=getMeetingForId(doc.meeting)
			if(meeting){
				formattedMeetingDateTime = getFormattedTimestampForMeeting(meeting,'docTableRow')
			}
		}

		//const lastEditedTimestamp = 'Today 2:06 PM'
		const lastEditedTimestamp = formatDate(doc.updated_at)

		let teamName
		let teamShortName		
		const projects = getProjects()
		const teams = getTeams()
		const sortedProjects = sortBy(projects, function(project){
      return project.name.toString().toLowerCase()
    })

		const roadmaps=getRoadmaps()
		const sortedRoadmaps = sortBy(roadmaps, function(roadmap){
      return roadmap.name.toString().toLowerCase()
    })


    const sortedTeams = sortBy(teams, function(team){
      return team.name.toString().toLowerCase()
    })

    const org = getOrg()

		let orgName
		let orgLogo

		if(org){
			orgName = org.name
			orgLogo = org.logo
		}
		
		if(org && org.name === 'Yarn'){
			orgName ='Acme Inc.'
		}		


	  return (
	  	<ContextMenu.Root key={doc.id}> 
				<ContextMenu.Trigger asChild>	
	  	 		<div key={doc.id} className='libraryDocTable-table-row' onClick={this.handleClickRow} onMouseOver={this.props.onMouseOver}>
	  				<div className='libraryDocTable-table-row-startSpacer' /> 
						<div className='libraryDocTable-table-row-title'>
							{hasNotifications &&
								<div className='libraryDocTable-table-row-title-notificationCountContainer'>
									<div className='libraryDocTable-table-row-title-notificationCount'>
										{notificationCount}
									</div>
								</div>
							}
							{/*}
							{libraryView === 'allDocs' && docType === 'projectSubDoc' && doc && doc.project &&
								<div className='libraryDocTable-table-row-title-marker libraryDocTable-table-row-title-marker--subdoc'>
									<Icon name='chevronRight' />
								</div>
							}							
							*/}
							{libraryView === 'recent' && docType === 'projectRootDoc' && doc && doc.project &&
								<div className='libraryDocTable-table-row-title-marker libraryDocTable-table-row-title-marker--project'>
									<LinearMarker 
										projectId={doc.project}
									/>
								</div>
							}


							{docType === 'meetingDoc' && doc && meeting && meeting.type !== 'blank' &&
								<div className='libraryDocTable-table-row-title-marker libraryDocTable-table-row-title-marker--meeting'>
									<MeetingTypeIcon 
										meetingType={meeting.type}
									/>
								</div>
							}

							{/*}
							{libraryView === 'allDocs' && docType === 'teamDoc' && doc && doc.team &&
								<div className='libraryDocTable-table-row-title-marker libraryDocTable-table-row-title-marker--team'>
									<LinearMarker 
										teamId={doc.team}
									/>
								</div>
							}
							{libraryView === 'allDocs' && docType === 'privateDoc' &&
								<div className='libraryDocTable-table-row-title-marker libraryDocTable-table-row-title-marker--private'>
									<Icon name='doc'/>
								</div>
							}
							*/}

							<div className='libraryDocTable-table-row-title-title'>
								{doc.name}

								{meeting && formattedMeetingDateTime &&
									<span className='libraryDocTable-table-row-title-title-meetingDateTime'>
										{formattedMeetingDateTime}
									</span>
								}
							</div>

							{libraryView === 'recent' && docType === 'privateDoc' &&
								<div className='libraryDocTable-table-row-title-privateTag'>
									<div className='libraryDocTable-table-row-title-privateTag-label'>	
										Draft
									</div>
								</div>
							}

							{/* Don't think we need this 
							{libraryView === 'recent' && docType === 'meetingDoc' && meeting &&
								<div className='libraryDocTable-table-row-title-meetingDateTimeTag'>
									<div className='libraryDocTable-table-row-title-meetingDateTimeTag-iconContainer'>	
										<Icon name='calendar' />
									</div>									
									<div className='libraryDocTable-table-row-title-meetingDateTimeTag-label'>											
										Today 4:30PM
									</div>									
								</div>
							}
							*/}

							{libraryView === 'recent' && docType === 'projectSubDoc' && doc && doc.project && project &&
								<div className='libraryDocTable-table-row-title-projectTag'>
									<div className='libraryDocTable-table-row-title-projectTag-marker'>
										<LinearMarker 
											projectId={doc.project}
										/>
									</div>
									<div className='libraryDocTable-table-row-title-projectTag-label'>
										{project.name}
									</div>
								</div>
							}							

							{libraryView === 'recent' && docType === 'roadmapDoc' && doc && doc.roadmap && roadmap &&
								<div className='libraryDocTable-table-row-title-roadmapTag'>									
									<div className='libraryDocTable-table-row-title-roadmapTag-icon'>
										<Icon name='roadmapOutline' />
									</div>
									<div className='libraryDocTable-table-row-title-roadmapTag-label'>
										{roadmap.name}
									</div>
								</div>
							}

							{libraryView === 'recent' && docType === 'orgDoc' && doc && orgLogo && orgName &&
								<div className='libraryDocTable-table-row-title-orgTag'>									
									<div className='libraryDocTable-table-row-title-orgTag-image'>
										<img src={orgLogo} className='libraryDocTable-table-row-title-orgTag-image-image' />
									</div>
									<div className='libraryDocTable-table-row-title-orgTag-label'>
										{orgName}
									</div>
								</div>
							}

							{/* NOT SURE THIS WORKS WELL 
							{libraryView === 'allDocs' && docType === 'projectRootDoc' && doc && project &&
								<div className='libraryDocTable-table-row-title-projectStatusTagContainer'>									
									<LinearProjectStatusTag 
										projectStatus={project.state}
									/>
								</div>
							}
							*/}

						</div>

						{libraryView !== 'meetingNotes' &&
							<div className='libraryDocTable-table-row-lastUpdated'>
								{lastEditedTimestamp}
							</div>
						}
						

					<div className='libraryDocTable-table-row-endSpacer' /> 
	  				
	  			</div>
	  		</ContextMenu.Trigger>
	  		<ContextMenu.Portal>
				<ContextMenu.Content collisionPadding={10} className='contextMenu'>	        	       	        
					
					{/*
					<ContextMenu.Item onSelect={()=>{backgroundOpenDoc(doc.doc_id)}} />
						Open in Background					
					</ContextMenu.Item>
					*/}
				
					{docType !== 'privateDoc' &&
						<ContextMenu.Item onSelect={()=>{copyDocLinkToClipboard(doc.doc_id)}}> {/* {onSelect={()=>{backgroundOpenDoc(doc.doc_id)}} */}
							Copy Link
						</ContextMenu.Item>
					}		
					{docType === 'projectRootDoc' &&
						<ContextMenu.Item> {/* {onSelect={()=>{backgroundOpenDoc(doc.doc_id)}} */}
							Open Project in Linear
						</ContextMenu.Item>
					}		
					
					{docType !== 'projectRootDoc' && docType !== 'privateDoc' && docType !== 'meetingDoc' &&
						<ContextMenu.Separator />		
					}
										
					{docType !== 'projectRootDoc' && docType !== 'meetingDoc' &&
						<ContextMenu.Sub>
							<ContextMenu.SubTrigger>
								Move Doc...
								<div className='contextMenu-item-subMenuArrow'>
									<Icon name='chevronRight' />
								</div>
							</ContextMenu.SubTrigger>

							<ContextMenu.Portal>
								<ContextMenu.SubContent collisionPadding={20} className='contextMenu contextMenu-subMenu contextMenu-subMenu--moveDoc'>								
									
									<ContextMenu.Item key={org.organization_id} onClick={()=>{moveDocToOrg(doc['doc_id'])}}>        	
										<div className='contextMenu-item-visual contextMenu-item-visual--orgLogo'>
											<img src={orgLogo} className='contextMenu-item-visual--orgLogo-image' />
										</div>
										{orgName}
									</ContextMenu.Item>

									<div className='contextMenu-subMenu--moveDoc-dividerContainer'>
										<div className='contextMenu-subMenu--moveDoc-divider'/>
									</div>
									<div className='contextMenu-subMenu--moveDoc-header'>
										Roadmaps				
									</div>
									
									{sortedRoadmaps.map((roadmap)=>{
										return(
											<ContextMenu.Item key={roadmap.roadmap_id} onClick={()=>{moveDocToRoadmap(doc['doc_id'],roadmap.roadmap_id)}}>        	
												<div className='contextMenu-item-visual contextMenu-item-visual--roadmap'>
													<Icon name='roadmapOutline' />
												</div>
												{roadmap.name}
											</ContextMenu.Item>
											)
											
										})}															
								
									<div className='contextMenu-subMenu--moveDoc-dividerContainer'>
										<div className='contextMenu-subMenu--moveDoc-divider'/>
									</div>
									<div className='contextMenu-subMenu--moveDoc-header'>
										Projects
									</div>

									{sortedProjects.map((project)=>{
										if(project['project_id']!=projectId){
											return(
												<ContextMenu.Item key={project['project_id']} onClick={()=>{moveDocToProject(doc['doc_id'],project['project_id'])}}>
													<div className='contextMenu-item-visual contextMenu-item-visual--linearMarker'>
														<LinearMarker projectId={project['project_id']} />
													</div>
													{project.name}
												</ContextMenu.Item>
												)
											}
										})}																							
								</ContextMenu.SubContent>
							</ContextMenu.Portal>
						</ContextMenu.Sub>
					}					
					
					
					{docType !== 'projectRootDoc' &&
						<ContextMenu.Separator />
					}
					{docType !== 'projectRootDoc' &&
						<ContextMenu.Item className='contextMenu-item--warning' onClick={()=>{deleteDoc(this.props.doc['doc_id'])}}>
							Delete
						</ContextMenu.Item>					
					}
					
				</ContextMenu.Content>
			</ContextMenu.Portal>
		</ContextMenu.Root>		  	
	  )
	 }
}

export default withRouter(DocTableRow)
