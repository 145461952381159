import React from 'react'
import DocTableRow from './DocTableRow'



class DocTable extends React.Component{  

	constructor(){
		super()
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.handleKeyUp=this.handleKeyUp.bind(this)
		this.handleMouseOver=this.handleMouseOver.bind(this)
		this.state={
			shiftKeyDown:false,
			activeDocId:null
		}
	}
	
	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)
		window.addEventListener('keyup', this.handleKeyUp)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
		window.removeEventListener('keyup', this.handleKeyUp)
	}

	handleKeyDown(e){
		const {shiftKeyDown,activeDocId}=this.state
		if(!shiftKeyDown && e.keyCode==16){ //shift key
			this.setState({shiftKeyDown:true})
			this.props.setActiveDoc(activeDocId)
		}
	}

	handleKeyUp(e){
		const {shiftKeyDown}=this.state
		if(shiftKeyDown && e.keyCode==16){ //shift key
			this.setState({shiftKeyDown:false})
			this.props.setActiveDoc(null)
		}
	}

	handleMouseOver(docId){
		this.setState({activeDocId:docId})
		if(this.state.shiftKeyDown){
			this.props.setActiveDoc(docId)
		}
	}

	render(){	
		const {docs, libraryView}=this.props
	  return (
	  	<div className={'libraryDocTable ' + (libraryView ? `libraryDocTable--${libraryView}` : '')}>
	  		<div className='libraryDocTable-tableBorder' />
	  		<div className='libraryDocTable-table'>
  			{docs.map((doc)=>{
  				return(
  					<DocTableRow 
	  					key={doc.doc_id} 
	  					doc={doc} 
	  					libraryView={this.props.libraryView} 
	  					onMouseOver={()=>{this.handleMouseOver(doc.doc_id)}}
	  				/>
  					)
  			})}
  			</div>
	  	</div>
	  )
	}
}

export default DocTable
