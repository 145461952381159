import store from '../store'
import filter from 'lodash/filter'




export function openDocSearch() {	
	store.dispatch({type:'OPEN_DOC_SEARCH'})
}



export function closeDocSearch() {	
	store.dispatch({type:'CLOSE_DOC_SEARCH'})
	const view=window.view
	if(view){
		let tr = view.state.tr
		tr.setMeta("inDocSearchPlugin", { deactivate: true})
		view.dispatch(tr)
	}
}


export function changeDocSearchActiveIndex(searchTerm,index) {	
	//store.dispatch({type:'CHANGE_DOC_SEARCH_ACTIVE_INDEX',index})
	const view=window.view
	if(view){
		let tr = view.state.tr
	//	tr.setMeta("inDocSearchPlugin", { activeIndex: index})
		 tr.setMeta("inDocSearchPlugin", { activate: true,searchTerm:searchTerm,activeIndex:index})
		view.dispatch(tr)
	}
}

export function updateDocSearchResults(results,activeIndex) {	
	store.dispatch({type:'UPDATE_DOC_SEARCH_RESULTS',results,activeIndex})
}



