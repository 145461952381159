import React from 'react'
import Icon from './Icon'

class LinearProjectStatusIconInline extends React.PureComponent{    
  render(){ 
    const {projectStatus} = this.props

    return (
      <span className='linearProjectStatusIconContainer'>
      
        
        {projectStatus === 'backlog' && // backlog
          <span className='linearProjectStatusIcon linearProjectStatusIcon--inline linearProjectStatusIcon--backlog' />
        }

        {projectStatus === 'planned' && 
          <span className='linearProjectStatusIcon linearProjectStatusIcon--inline linearProjectStatusIcon--planned' />
        }

        {/*}
        {projectStatus === 'started' && // aka in progress
          <span className='linearProjectStatusIcon linearProjectStatusIcon--inline linearProjectStatusIcon--started'>            
            <span className='linearProjectStatusIcon--started-dot'/>
          </span>
        }

        {projectStatus === 'completed' && // aka completed
          <span className='linearProjectStatusIcon linearProjectStatusIcon--inline linearProjectStatusIcon--completed'>
            <Icon name='toDoItemCheckmark' />
            <span className='linearProjectStatusIcon--completed--innerBorder' />
          </span>
        }
      
        {projectStatus === 'canceled' &&
          <span className='linearProjectStatusIcon linearProjectStatusIcon--inline linearProjectStatusIcon--canceled'>
            <Icon name='linearCancelledStateIcon' /> 
            <span className='linearProjectStatusIcon--canceled--innerBorder' />
          </span>
        }

        {projectStatus === 'paused' &&
          <span className='linearProjectStatusIcon linearProjectStatusIcon--inline linearProjectStatusIcon--paused'>
            <Icon name='linearPausedPriorityIcon' /> 
            <span className='linearProjectStatusIcon--paused--innerBorder' />
          </span>
        }
        */}
      
      </span>
    )
  }
}

export default LinearProjectStatusIconInline
