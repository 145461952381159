import React from 'react'
import Icon from '../../../misc/Icon'
import ReactPlayer from 'react-player/file'
import videoDuration from '../../../../utils/videoDuration'
import ReactSlider from 'react-slider'
import debounce from 'lodash/debounce'

class MessageMediaVideo extends React.Component{  

	constructor(){
    super()
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.hideMouseMoveControls=this.hideMouseMoveControls.bind(this)
    this.hideMouseMoveControls=debounce(this.hideMouseMoveControls,1000)
    this.state={
     	url: null,	    
	    playing: false,	    
	    played: 0,
	    loaded: 0,
	    duration: 0,
	    playbackRate: 1.0,
      mouseMoved:false   
    }

  }

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing })
  }

  handlePlay = () => {
    this.setState({ playing: true })
  }

  handleRestart = () => {
    this.setState({ seeking: true })
    this.player.seekTo(0)
    this.setState({ playing: true })
  }

  handlePause = () => {
    this.setState({ playing: false })
  }

   handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    this.setState({ played: parseFloat(e) })
  }



  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e))
  }

  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
    }
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  ref = player => {
    this.player = player
  }

  handleMouseMove(){    
    this.setState({mouseMoved:true})
    this.hideMouseMoveControls()
  }

  hideMouseMoveControls(){
    this.setState({mouseMoved:false})
  }

	render(){	
		const {playing, controls, played, loaded, duration, volume, mouseMoved} = this.state

		// butterfield
		// const cloudinaryID = "https://res.cloudinary.com/dedfh7fvu/video/upload/du_27,so_0,c_crop,g_center,h_960,q_100,w_1280,x_40/v1647033920/StewartButterfieldVideo_urwcbm.mp4"
		
		// commandemnu
		//const cloudinaryID = "https://res.cloudinary.com/yarn/video/upload/c_crop,g_center,h_960,q_100,w_1280,x_40/v1666611875/dummydata/examplecommandmenu_up22t9.mp4"		
		const cloudinaryID = "https://res.cloudinary.com/yarn/video/upload/v1666611875/dummydata/examplecommandmenu_up22t9.mp4"
				

		const progressSeconds = duration * played

		let showSeekbar = false		

		if(progressSeconds > 1 && !playing){
			showSeekbar = true
		}
		if(playing && progressSeconds > 1 && mouseMoved){
			showSeekbar = true	
		}

		const hidePlayButton = (playing || played > 0)
		const showRewindButton = !playing && played > 0

		return (
			<div className='message-content-videoContainer' onMouseMove={this.handleMouseMove}>
				<div className='message-content-video'>
					<div className='message-content-video-ui'>  				
		  				
		  				<button onClick={this.handlePlay} className={'message-content-video-ui-playBtn ' + (hidePlayButton ? ' message-content-video-ui-playBtn--hide ' : ' message-content-video-ui-playBtn--show ')}>
		  					<Icon name='playbackPlay' />
		  				</button> 

		  				<div className={'message-content-video-ui-duration ' + ((playing || played > 0) ? ' message-content-video-ui-duration--hide ' : ' message-content-video-ui-duration--show ')}>
		  					{/*<videoDuration seconds={duration} /> */}
		  					00:25
		  				</div>	  		
		  				  				
							<div className={'message-content-video-ui-seekBar ' + (showSeekbar ? 'message-content-video-ui-seekBar--show' : 'message-content-video-ui-seekBar--hide')}>
								
								<div className='message-content-video-ui-seekBar-seekBarContainer'>

									<ReactSlider
								    className="message-content-video-ui-seekBar-seekbar"
								    thumbClassName="message-content-video-ui-seekBar-seekbar-thumb"
								    trackClassName="message-content-video-ui-seekBar-seekbar-track"							    
								    renderThumb={(props, state) => <div className='message-content-video-ui-seekBar-seekbar-thumb-thumb' {...props} />}

								    value={played}
								    min={0}
								    max={0.999999}
								    step={0.01}
								    onAfterChange={this.handleSeekMouseUp}
								    onBeforeChange={this.handleSeekMouseDown}
								    onChange={this.handleSeekChange}
									/>
									
									<div className='message-content-video-ui-seekBar-seekBar-bg' />
								</div>							

							</div>


		  				<button onClick={this.handlePlayPause} className='message-content-video-ui-bgPlayPauseClick'/>
		  			</div>

		  			<ReactPlayer   			  
					    ref={this.ref}
		          className='message-content-video-video'
		          width='100%'
		          height='100%'
		          url={cloudinaryID}
		          
		          //pip={pip}
		          playing={playing}
		          //controls={controls}
		          //light={light}
		          //loop={loop}
		          //playbackRate={playbackRate}
		          volume={1}
		          //muted={muted}          
		          
		          // onReady={() => console.log('onReady')}
		          // onStart={() => console.log('onStart')}
		          
		          onPlay={this.handlePlay}
		          //onEnablePIP={this.handleEnablePIP}
		          //onDisablePIP={this.handleDisablePIP}
		          
		          onPause={this.handlePause}

		          onBuffer={() => console.log('onBuffer')}
		          
		          onPlaybackRateChange={this.handleOnPlaybackRateChange}
		          
		         // onSeek={e => console.log('onSeek', e)}
		          onEnded={this.handleEnded}
		         // onError={e => console.log('onError', e)}
		          onProgress={this.handleProgress}
		          onDuration={this.handleDuration}
		  			/>

					
				</div>
			</div>
		)
	}
}
 


export default MessageMediaVideo
