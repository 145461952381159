const DocFigmaEmbedNodeSpec ={
    attrs: {
      figmaEmbedVersion:{default:0}, //bump to force rerender 
      documentTitle:{default:''}, //e.g.Jasper Test Project --from url
      embedId:{default:''},//created on client
      crop:{default:null},
      backgroundImage:{default:null},
      backgroundPadding:{default:0.1},
      deviceFrame:{default:null},
      nodeId:{default:''},
      messageVersion:{default:0} //to bump version and force node to check for new messages
    },
    group: "block",
    parseDOM: [{tag: 'docFigmaEmbed'}],
     toDOM(node) {
      const div = document.createElement('div');
      div.className = 'doc-imageWrapper'

      const div2 = document.createElement('div');
      div2.className = 'doc-imageContainer'    
      
      // add width and height. width is maxed to 720
      const widthDocImage = 720
      const imageDisplayWidth = widthDocImage
      const imageDisplayHeight = (node.attrs.height / node.attrs.width) * imageDisplayWidth    
      
      div2.style.cssText = `width:${imageDisplayWidth}px;height:${imageDisplayHeight}px`



      div.appendChild(div2)

      

      const div3 = document.createElement('div');
      div3.className = 'doc-image'  

      div2.appendChild(div3)  

      // const img = document.createElement('img');
      // img.className='doc-image-image'
      // img.src=node.attrs.deliveryUrl

      // div3.appendChild(img)

      return div;

   },
}
    
  export default DocFigmaEmbedNodeSpec




//figmaembed object


      // nodeTitle:{default:''},//e.g. Test Frame XYZ --from api
      // pastedUrl:{defaut:''},//url that was pasted in 
      // importedAt:{default:''},//timestamp of last sync with figma
      // syncedAt:{default:''}
      // documentKey:{default:''},//e.g.FbdT3m8aiE9KY4Zdc2DXTs
      // figmaNodeId:{default:''},//e.g. 2:2






      // imageUrl:{default:''},
      
      // imageReady:{default:true}
      // nodeId:{default:''},
      // height:{default:''},
      // width:{default:''},
      // title:{default:''}, //same as name
      // description:{default:''},