import {Plugin, PluginKey} from 'prosemirror-state'
import {TextSelection} from "prosemirror-state"
import {getAnchorNode} from '../utils/getAnchorNode'
import { Fragment } from "prosemirror-model";
import { Decoration, DecorationSet } from "prosemirror-view"

const key = new PluginKey('toggleListItemPlugin')

function getNodePos(anchorNode){
	const state=window.view.state
	let nodePos
	state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
		if(anchorNode===node){
			nodePos=pos
		}
	})
	return nodePos
}

function isFirstPara($from,node){
	let isFirstPara=false
	node.forEach((childNode,offset,i)=>{
		const paraPos=getNodePos(childNode)
		if(i==0 && $from.pos==paraPos+1){
			isFirstPara=true
		}
	})
	return isFirstPara
}

function isLastPara($from,node){
	let isLastPara=false
	let paraIndex
	let paraCount=0
	node.forEach((childNode,offset,i)=>{
		const paraPos=getNodePos(childNode)
		paraCount+=1
		if($from.pos==paraPos+1){
			paraIndex=i
		}
	})
	if(paraIndex==paraCount-1){
		isLastPara=true
	}
	return isLastPara
}

function isEmptyLastPara($from,node){
	let isLastPara=false
	let isEmptyPara=true
	let paraIndex
	let paraCount=0
	node.forEach((childNode,offset,i)=>{
		const paraPos=getNodePos(childNode)
		paraCount+=1
		if($from.pos==paraPos+1){
			paraIndex=i
			if(childNode.textContent){
				isEmptyPara=false
			}
		}
	})
	if(paraIndex==paraCount-1){
		isLastPara=true
	}
	// console.log(`is last para  ${isLastPara}`)
	// console.log(`isEmptyPara  ${isEmptyPara}`)
	return isLastPara && isEmptyPara
}

function selectionIsAtEndOfNode(node,$from){
	return $from.parentOffset==node.textContent.length
}

function selectionIsAtBeginingOfNode($from){
	return $from.parentOffset==0
}

function isEmptyToggleList(node){
	const textContent=node.textContent
	const isEmpty=textContent.length==0
	return isEmpty
}

function replaceWithParagraph(view,anchorNode){
	const paragraphNode = view.state.schema.nodes.paragraph.create()
	const pos=getNodePos(anchorNode)
	let tr = view.state.tr
	tr.replaceWith(pos,pos+anchorNode.nodeSize,paragraphNode)  
	let selection=new TextSelection(tr.doc.resolve(pos+1))
	tr.setSelection(selection)//put cursor at the begining of new text node
	window.view.dispatch(tr)
}

function createNewToggleListItem(view,anchorNode){
	const toggleListNode = view.state.schema.nodes.toggleListItem.createAndFill()
	const pos=getNodePos(anchorNode)
	let tr = view.state.tr
	tr.replaceWith(pos+anchorNode.nodeSize,pos+anchorNode.nodeSize,toggleListNode)  
	let selection=new TextSelection(tr.doc.resolve(pos+anchorNode.nodeSize+2))
	tr.setSelection(selection)
	window.view.dispatch(tr)
}

function addParaToDetails(view,anchorNode){
	const paragraphNode = view.state.schema.nodes.paragraph.create()
	const pos=getNodePos(anchorNode)
	let tr = view.state.tr
	tr.replaceWith(pos+anchorNode.nodeSize+1,pos+anchorNode.nodeSize+1,paragraphNode)  
	let selection=new TextSelection(tr.doc.resolve(pos+anchorNode.nodeSize+1))
	tr.setSelection(selection)
	window.view.dispatch(tr)
}

function splitTitleIntoDetails($from,view,anchorNode){//move content after the cursor into a new paragraph in details
	const textContent=anchorNode.textContent
	const titleParaContent=textContent.slice(0,$from.parentOffset)
	const newDetailParaContent=textContent.slice($from.parentOffset)
	const paraAnchorNode=getAnchorNode($from,'paragraph')
	const newTitleParagraphNode = view.state.schema.nodes.paragraph.createAndFill(null,[view.state.schema.text(titleParaContent)])
	const newDetailParagraphNode=view.state.schema.nodes.paragraph.createAndFill(null,[view.state.schema.text(newDetailParaContent)])
	const pos=getNodePos(paraAnchorNode)
	const anchorPos=getNodePos(anchorNode)
	let tr = view.state.tr
	tr.insert(anchorPos+anchorNode.nodeSize+1,newDetailParagraphNode)  
	let selection=new TextSelection(tr.doc.resolve(anchorPos+anchorNode.nodeSize+1))
	tr.setSelection(selection)
	tr.replaceWith(pos,pos+paraAnchorNode.nodeSize+1,newTitleParagraphNode)  
	window.view.dispatch(tr)
}

function splitTitleIntoNewListItem($from,view,anchorNode,listAnchorNode){//move content after the cursor into a new paragraph in details
	const textContent=anchorNode.textContent
	const titleParaContent=textContent.slice(0,$from.parentOffset)
	const newListTitleParaContent=textContent.slice($from.parentOffset)
	const paraAnchorNode=getAnchorNode($from,'paragraph')
	const updatedTitleParagraphNode = view.state.schema.nodes.paragraph.createAndFill(null,[view.state.schema.text(titleParaContent)])
	const newTitleParagraphNode=view.state.schema.nodes.paragraph.createAndFill(null,[view.state.schema.text(newListTitleParaContent)])
	const newTitleNode=view.state.schema.nodes.toggleListItemTitle.createAndFill(null,[newTitleParagraphNode])
	const newListItemNode=view.state.schema.nodes.toggleListItem.createAndFill(null,[newTitleNode])
	const pos=getNodePos(paraAnchorNode)
	const anchorPos=getNodePos(anchorNode)
	let tr = view.state.tr
	tr.insert(getNodePos(listAnchorNode)+listAnchorNode.nodeSize,newListItemNode)  
	let selection=new TextSelection(tr.doc.resolve(getNodePos(listAnchorNode)+listAnchorNode.nodeSize+1))
	tr.setSelection(selection)
	tr.replaceWith(pos,pos+paraAnchorNode.nodeSize+1,updatedTitleParagraphNode)  
	window.view.dispatch(tr)
}

function flattenListItem($from,view,anchorNode){// turn list into paragraph and if has content turn that into indented para
	let newNodes=[]
	anchorNode.forEach((node)=>{
		if(node.type.name=='toggleListItemTitle'){
			node.forEach((childNode)=>{
				if(childNode.type.name=='paragraph'){
					newNodes.push(childNode)
				}
			})
		}
		else if(node.type.name=='toggleListItemDetails'){
			node.forEach((childNode)=>{
				if(childNode.type.name=='paragraph'){
					if(childNode.textContent){
						const newParagraphNode = view.state.schema.nodes.paragraph.create({indentLevel:1}, childNode.content)
						newNodes.push(newParagraphNode)
					}
				}
			})
		}
	})
	const pos=getNodePos(anchorNode)
	let tr = view.state.tr
	tr.replaceWith(pos,pos+anchorNode.nodeSize,newNodes)  
	let selection=new TextSelection(tr.doc.resolve(pos+1))
	tr.setSelection(selection)//put cursor at the begining of new text node
	window.view.dispatch(tr)
}

function addParaToTitle(view,anchorNode,$from){
	let newTitleContent=''
	let titleParaNode
	let nodeToDelete
	anchorNode.forEach((node)=>{
		if(node.type.name=='toggleListItemTitle'){
			node.forEach((childNode)=>{
				if(childNode.type.name=='paragraph'){
					titleParaNode=childNode
					newTitleContent+=childNode.textContent
				}
			})
		}else if(node.type.name=='toggleListItemDetails'){
			node.forEach((childNode,i)=>{
				if(i==0 && childNode.type.name=='paragraph'){
					nodeToDelete=childNode
					newTitleContent+=childNode.textContent
				}
			})
		}
	})

	let newNode
	if(newTitleContent){
		newNode = view.state.schema.nodes.paragraph.createAndFill(null,[view.state.schema.text(newTitleContent)])
	}else{
		newNode = view.state.schema.nodes.paragraph.createAndFill()
	}	
	let tr = view.state.tr
	const pos=getNodePos(titleParaNode)
	const deletePos=getNodePos(nodeToDelete)
	tr.delete(deletePos,deletePos+nodeToDelete.nodeSize+1)
	tr.replaceWith(pos,pos+titleParaNode.nodeSize,newNode) 
	let selection=new TextSelection(tr.doc.resolve(getNodePos(titleParaNode)+titleParaNode.nodeSize-1))
	tr.setSelection(selection)//put cursor at the begining of new text node
	window.view.dispatch(tr)

}

function flattenLastPara($from,view,anchorNode){// turn list into paragraph and if has content turn that into indented para
	let newNodes=[]
	let paraToMove
	anchorNode.forEach((node)=>{
		if(node.type.name=='paragraph'){
			newNodes.push(node.content)
			paraToMove=node
		}
	})
	let tr = view.state.tr
	const pos=getNodePos(anchorNode)
	tr.insert(pos+anchorNode.nodeSize+1,paraToMove) 
	const deletePos=getNodePos(paraToMove)
	tr.delete(deletePos,deletePos+paraToMove.nodeSize+1)
	window.view.dispatch(tr)
}

function toggleOpen(view,anchorNode){// turn list into paragraph and if has content turn that into indented para
	const {open} = anchorNode.attrs
	let newOpenState=!open
	var tr = view.state.tr
	const pos=getNodePos(anchorNode)
	tr.setNodeMarkup(pos, null, {...anchorNode.attrs,open:newOpenState})
	window.view.dispatch(tr)
}



export function toggleListItemPlugin(){
	return new Plugin({
		key,
		props: {
			decorations: ({ doc, plugins, selection }) => {
				const decorations = []
				const {anchor}=selection
				doc.descendants((node, pos) => { //placeholder plugin
					if (node.type.name==='toggleListItemTitle') {
						const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize)
						if(hasAnchor){
							decorations.push(Decoration.node(pos, pos + node.nodeSize, {class: 'doc-toggleListItem-contents-title--focused'}))
						}
						if(!node.textContent){
							decorations.push(Decoration.node(pos, pos + node.nodeSize, {class: 'doc-toggleListItem-contents-title--empty'}))
						}
					}else if(node.type.name==='toggleListItemDetails' && !node.textContent) {
						const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize)
						if(hasAnchor){
							decorations.push(Decoration.node(pos, pos + node.nodeSize, {class: 'doc-toggleListItem-contents-details--focused'}))
						}
						decorations.push(Decoration.node(pos, pos + node.nodeSize, {class: 'doc-toggleListItem-contents-details--empty'}))					
					}     		
				})
			return DecorationSet.create(doc, decorations)
		},
			handleDOMEvents: {
				keydown (view, event) {
					let state = view.state
					let sel = state.selection
					const $from=state.doc.resolve(sel.anchor)
					const anchorNode=getAnchorNode($from,'toggleListItem')
					if(anchorNode){
						if(event.keyCode===9){//tab key
							event.preventDefault()
						}
						else if(event.keyCode==13 && event.metaKey){ //command enter
							const titleAnchorNode=getAnchorNode($from,'toggleListItemTitle')
							if(titleAnchorNode){//toggle open status
								event.preventDefault()
								toggleOpen(view,anchorNode)
							}
						}
						else if(event.keyCode===8){//backspace
							const titleAnchorNode=getAnchorNode($from,'toggleListItemTitle')
							const detailsAnchorNode=getAnchorNode($from,'toggleListItemDetails')
							if(titleAnchorNode){
								const beginingOfTitle=selectionIsAtBeginingOfNode($from)
								if(beginingOfTitle){
									event.preventDefault()
									flattenListItem($from,view,anchorNode)
								}
							}else if(detailsAnchorNode){
								const beginingOfPara=selectionIsAtBeginingOfNode($from)
								if(beginingOfPara){
									const isFirstParagraph=isFirstPara($from,detailsAnchorNode)
									const isLastParagraph=isLastPara($from,detailsAnchorNode)
									if(isFirstParagraph){
										//if is an empty para then move cursor into the end of the title
										event.preventDefault()
										addParaToTitle(view,anchorNode,$from)
									}else if(isLastParagraph){
										event.preventDefault()
										flattenLastPara($from,view,detailsAnchorNode)
									}
								}
							}
						}
						else if(event.keyCode==13 && !event.metaKey){ //enter key
							const toggleIsOpen=anchorNode.attrs.open
							const isEmpty=isEmptyToggleList(anchorNode)
							const titleAnchorNode=getAnchorNode($from,'toggleListItemTitle')
							const detailsAnchorNode=getAnchorNode($from,'toggleListItemDetails')
							if(detailsAnchorNode){ //if press enter in the last empty para of details turn it into a normal para
								const beginingOfPara=selectionIsAtBeginingOfNode($from)
								if(beginingOfPara){
									const isEmptyLastParagraph=isEmptyLastPara($from,detailsAnchorNode)
									if(isEmptyLastParagraph){
										event.preventDefault()
										flattenLastPara($from,view,detailsAnchorNode)
									}
								}
							}
							else if(titleAnchorNode){	
								event.preventDefault()
								const beginingOfTitle=selectionIsAtBeginingOfNode($from)
								const endOfTitle=selectionIsAtEndOfNode(titleAnchorNode,$from)
								if(beginingOfTitle){
									if(isEmpty){//if the toggleListItemHeader and details are empty then becomes normal text
										replaceWithParagraph(view,anchorNode)
									}else{ //creates a new line above, keeps cursor at the begining of title and collapses toggle list
										const pos=getNodePos(anchorNode)
										let tr = view.state.tr
										tr.setNodeMarkup(pos, null, {...anchorNode.attrs,open:false})
										const paragraphNode = view.state.schema.nodes.paragraph.create()
										tr.replaceWith(pos, pos, Fragment.from(paragraphNode));
										window.view.dispatch(tr)
									}
								}else if(endOfTitle){
									if(toggleIsOpen){//Adds a new para to the top of the summary and cursors into it
										addParaToDetails(view,titleAnchorNode)
									}else{//creates a new empty toggle list item after and cursors into it (i.e. same as normal lists)
										createNewToggleListItem(view,anchorNode)
									}
								}
								else{ //is in the middle of title
									if(toggleIsOpen){//move content after the cursor into a new paragraph in details
										splitTitleIntoDetails($from,view,titleAnchorNode)
									}else{//creates a new empty toggle list item after and cursors into it (i.e. same as normal lists)
										splitTitleIntoNewListItem($from,view,titleAnchorNode,anchorNode)
									}
								}
							}
						}
					}
				}
			}
		}
	})
}