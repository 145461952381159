import React from 'react'
import StageIcon from '../../../misc/StageIcon'
import Icon from '../../../misc/Icon'
import MessageInternalLinkReactComponent from '../messageInputBox/nodeViews/MessageInternalLinkReactComponent'



class StaticMessageInternalLink extends React.Component{  


	render(){
		const {docId}=this.props


			return (
				<MessageInternalLinkReactComponent
					docId={docId}
					handleOpenDoc={this.props.handleOpenDoc}
				/>
			)
	}
}

export default StaticMessageInternalLink
