
export function getDeviceForFigmaFrame(frameWidth, frameHeight) {	

	let device = null
	//
	//
	// TABLETS
	
	if(frameWidth === 834 && frameHeight === 1194){
		device = 'iPadPro11Portrait'
	}

	if(frameWidth === 1024 && frameHeight === 1366){
		device = 'iPadPro129Portrait'
	}

	if(frameWidth === 1194 && frameHeight === 834){
		device = 'iPadPro11Landscape'
	}

	if(frameWidth === 1366 && frameHeight === 1024){
		device = 'iPadPro129Landscape'
	}

	//
	//
	// PHONES

	if(frameWidth === 390 && frameHeight === 844){
		device = 'iPhone14'
	}

	if(frameWidth === 393 && frameHeight === 852){
		device = 'iPhone14Pro'
	}

	if(frameWidth === 430 && frameHeight === 932){
		device = 'iPhone14ProMax'
	}		

	if(frameWidth === 428 && frameHeight === 926){
		device = 'iPhone13ProMax'
	}

	if(frameWidth === 375 && frameHeight === 812){
		device = 'iPhone13Mini'
	}

	if(frameWidth === 360 && frameHeight === 640){
		device = 'AndroidSmall'
	}

	if(frameWidth === 360 && frameHeight === 800){
		device = 'AndroidLarge'
	}

	//
	// GUESS IF FULL DESKTOP

	if(!device & frameWidth > 900 && frameWidth < 3000 && frameHeight > 550){ 
		device = 'desktop'
	} 

	
	return device
}
