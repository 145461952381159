import store from '../store'
import find from 'lodash/find'


export function isPrivateDoc(docId) {	
	let isPrivate=true
	const state = store.getState()
	const doc= find(state.docs, {'doc_id':`${docId}`})
	if(doc && (doc.project || doc.team || doc.roadmap || doc.meeting || doc.is_org_doc)){
		isPrivate=false
	}
	return isPrivate
}
