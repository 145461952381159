import store from '../../store'
import filter from 'lodash/filter'
import find from 'lodash/find'

//check if the current user is in the thread (wrote the parent message or a reply)
export function getDocThreadForNode(nodeId) {	
	const state = store.getState()
	const messages=state.messages
	let threadParent=find(messages,{in_doc_thread:true,selection_node_id:`${nodeId}`})
	let thread=null
	if(threadParent){
		thread={
			threadParent:threadParent,
			threadChildren:filter(messages,{thread_parent:threadParent.message_id})
		}
	}


	return thread
}

