import store from '../store'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'



export function projectHasMilestones(projectId) {	
	let hasMilestones=false
	const state = store.getState()
	const projectMilestones=filter(state.projectMilestones,{project:projectId}) 
	if(projectMilestones.length>0){
		hasMilestones=true
	}
	return hasMilestones
}

