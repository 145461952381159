import React from 'react'
import { connect } from 'react-redux'
import Icon from '../../../misc/Icon'
import DocEditorMeetingContentProjectListRow from './DocEditorMeetingContentProjectListRow'
import DocEditorMeetingContentAddProjectPopover from './DocEditorMeetingContentAddProjectPopover'
import {getProjectForId} from '../../../../utils/getProjectForId'
import {getProjectConnectionsForMeeting} from '../../../../utils/meetings/getProjectConnectionsForMeeting'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getProjects} from '../../../../utils/getProjects'
import DocEditorMeetingContentActiveProjectsCalendar from './DocEditorMeetingContentActiveProjectsCalendar'

class DocEditorMeetingContentActiveProjects extends React.Component{  

  constructor(props) {
    super(props)   
    this.state={      
      //view: 'calendar',
      view: 'list',
      showPlanned: false,
    }
  }   

  render(){ 
    const {meeting,projects} = this.props
    const {view, showPlanned} = this.state
    

    // Filter and sort the projects in 'started' state
    const startedProjects = projects
      .filter((project) => {
        const projectObj = getProjectForId(project.project_id);
        return projectObj && projectObj.state === 'started';
      })
      .sort((a, b) => {
        const projectObjA = getProjectForId(a.project_id);
        const projectObjB = getProjectForId(b.project_id);
        return projectObjA.name.localeCompare(projectObjB.name);
    });

    // Filter and sort the projects in 'planned' state
    const plannedProjects = projects
      .filter((project) => {
        const projectObj = getProjectForId(project.project_id);
        return projectObj && projectObj.state === 'planned';
      })
      .sort((a, b) => {
        const projectObjA = getProjectForId(a.project_id);
        const projectObjB = getProjectForId(b.project_id);
        return projectObjA.name.localeCompare(projectObjB.name);
    });

    
    return (        
       <div className='docEditor-meetingContent-activeProjects'>
          <div className='docEditor-meetingContent-menu'>
            <div className='docEditor-meetingContent-menu-headerLabel'>            
              Active Projects
            </div>


            {/*}
            <div className='docEditor-meetingContent-menu-btnToggleSet'>            
              <button onClick={()=>{this.setState({view: 'list'})}} data-state={view === 'list' ? 'active' : 'inactive'} className='docEditor-meetingContent-menu-btnToggle'>
                <Icon name='bulletList' />
              </button>            
              <button onClick={()=>{this.setState({view: 'calendar'})}} data-state={view === 'calendar' ? 'active' : 'inactive'} className='docEditor-meetingContent-menu-btnToggle'>
                <Icon name='calendar' />
              </button>            
            </div>
            */}
            
                  
          </div>

          
          {view === 'list' &&
            <div className='docEditor-meetingContent-activeProjects-list'>
              {/* Render started projects */}
                {startedProjects.map((project) => (
                  <DocEditorMeetingContentProjectListRow 
                    key={project.project_id}
                    project={getProjectForId(project.project_id)}                  
                    openIssuePanel={this.props.openIssuePanel}
                    issuePanelIsVisible={this.props.issuePanelIsVisible}  
                    issuePanelProject={this.props.issuePanelProject}    
                    closeIssuePanel={this.props.closeIssuePanel}            
                    isTeamSync
                  />
                ))}

                {showPlanned &&
                  <div className='docEditor-meetingContent-activeProjects-list-smallVSpacer' />
                }

                {showPlanned && plannedProjects.map((project) => (
                  <DocEditorMeetingContentProjectListRow 
                    key={project.project_id}
                    project={getProjectForId(project.project_id)}                  
                    openIssuePanel={this.props.openIssuePanel}
                    issuePanelIsVisible={this.props.issuePanelIsVisible} 
                    issuePanelProject={this.props.issuePanelProject}  
                    closeIssuePanel={this.props.closeIssuePanel}                  
                    isTeamSync
                  />
                ))}

                {plannedProjects && plannedProjects.length > 0 && 
                  <button onClick={()=>{this.setState({showPlanned: !showPlanned})}} className='docEditor-meetingContent-linkedProjects-plannedBtn'>
                    {showPlanned ? 'Hide planned' : `Show ${plannedProjects.length} planned`}
                  </button>
                }            
            </div>
          }


          {view === 'calendar' &&
            <div className='docEditor-meetingContent-activeProjects-calendarContainer'>
              <DocEditorMeetingContentActiveProjectsCalendar 

              />
            </div>
          }
          
        </div>

    )
  }
}


const mapStateToProps = (state) => ({
  projects:state.projects
});


export default connect(
  mapStateToProps,
  {
  })(DocEditorMeetingContentActiveProjects)

