import React from 'react'
import Icon from '../misc/Icon'
import { format,parseISO } from 'date-fns';
import { DayPicker } from 'react-day-picker';

class EditProjectTargetDatePopover extends React.Component{  	


	render(){	
		const {projectId, closePopover,currentStatus,currentTargetDate} = this.props
		let selected=null
		let defaultMonth=new Date()
		if(currentTargetDate){
			selected=parseISO(this.props.currentTargetDate)
			defaultMonth=parseISO(this.props.currentTargetDate)
		}

		return (
			<React.Fragment>         	
				<DayPicker
		      mode="single"
		      selected={selected}
		      //defaultMonth={parseISO(this.props.currentTargetDate)}
		      defaultMonth={defaultMonth}
		      onSelect={this.props.updateProjectTargetDate}
		      showOutsideDays
		    />
		    {currentTargetDate && 		
			   	<div className='editMetadataPopover--type--projectTargetDate-clearBtnContainer'>
			    	<button className='editMetadataPopover--type--projectTargetDate-clearBtn' onClick={(e)=>{this.props.updateProjectTargetDate(null,null,null,e)}}>
			    		Clear
			    	</button>
			    </div>    
		  	}

			</React.Fragment>
		)
	}
}

export default EditProjectTargetDatePopover