import React from 'react'
import Icon from '../misc/Icon'
import {getUserForId} from '../../utils/getUserForId'
import {getProjectForId} from '../../utils/getProjectForId'
import AvatarImage from '../AvatarImage'

class DocEditorProjectDescription extends React.Component{  

	render(){	

    let project
    let description
    let leadUserId
    let leadUser
    

    if(this.props.projectDocProjectId){
      project = getProjectForId(this.props.projectDocProjectId)
    }
    if(project){
      description = project.description
      leadUserId = project.lead    
    }
    if(leadUserId){
      leadUser = getUserForId(leadUserId)
    }
    

	  return (	  	
      <React.Fragment>
      {description &&
    		<div className='docEditor-projectDescriptionContainer'>
          <div onClick={this.props.openEditProjectModal} className='docEditor-projectDescription'>                    
            <div className='docEditor-projectDescription-textContainer'>                        
              <div className='docEditor-projectDescription-textContainer-label'> 
                {description}            
              </div>
            </div>
            <div className='docEditor-projectDescription-bg' />
          </div>
        </div>
      }
      {!description &&
        <div className='docEditor-projectDescription-addDescriptionBtnContainer'>
          <button onClick={this.props.openEditProjectModal} className='docEditor-projectDescription-addDescriptionBtn'> 
            <div className='docEditor-projectDescription-addDescriptionBtn-iconContainer'>
              <Icon name='paraBold' />              
            </div> 
            <div className='docEditor-projectDescription-addDescriptionBtn-label'> 
              Add Description
            </div>
          </button>
        </div>
      }
      </React.Fragment>
	  )
	}
}

export default DocEditorProjectDescription
