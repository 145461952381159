import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import {formatDate} from '../../../utils/formatDate'
import {getUserForId} from '../../../utils/getUserForId'
import filter from 'lodash/filter'


class FigmaEmbedTopBarOpenThreadBtn extends React.Component{  

	render(){
		const {threadChildMessages,messageId,isInDocThread,thread}=this.props 

		let messageCount 
		if(threadChildMessages){
			messageCount = threadChildMessages.length + 1
		}

		let messageCountLabel
				
		if(messageCount === 1){
			messageCountLabel = '1 message'
		}
		if(messageCount > 1){
			messageCountLabel = `${messageCount} messages`
		}
		

		//const unreadCount = filter(threadChildMessages,{isUnread:true}).length // number of messages new since user last visited thread?
		const unreadCount = this.props.unreadCount
		let hasUnreads = false

		if(unreadCount > 0){
			hasUnreads = true
		}
		let mostRecentMessageTimestampLabel
		if(messageCount === 1){
			mostRecentMessageTimestampLabel = formatDate(thread.threadParent['created_at'])
		}
		if(messageCount>1){
			mostRecentMessageTimestampLabel = formatDate(threadChildMessages[threadChildMessages.length-1]['created_at'])
		}

		let threadChildrenAuthors=[]

		const parentAuthor=thread.threadParent.created_by
		threadChildrenAuthors.push(parentAuthor)
		
		threadChildMessages.forEach((message)=>{
			const author=message['created_by']
			let index = threadChildrenAuthors.findIndex(user => {
				return user == author
			})
			if(index==-1){
				threadChildrenAuthors.push(author)
			}
		})


	
		return (
			
			<button onClick={this.props.openThread} className={'doc-figma-topbar-threadSummaryBtn' + (hasUnreads ? ' doc-figma-topbar-threadSummaryBtn--hasUnreads ' : ' doc-figma-topbar-threadSummaryBtn--noUnreads ')}>
				
				{hasUnreads &&
					<div className='doc-figma-topbar-threadSummaryBtn-unreadMarkerContainer'>
						<div className='doc-figma-topbar-threadSummaryBtn-unreadMarker' />
					</div>
				}
				

				<div className='doc-figma-topbar-threadSummaryBtn-labelRow'>
					
					{hasUnreads &&
						<div className='doc-figma-topbar-threadSummaryBtn-unreadCount'>
							{unreadCount} unread
						</div>
					}

					{!hasUnreads &&
						<div className='doc-figma-topbar-threadSummaryBtn-messageCount'>
							{messageCountLabel}
						</div>
					}
					{mostRecentMessageTimestampLabel && hasUnreads &&
						<div className='doc-figma-topbar-threadSummaryBtn-timestampLabel'>
							{mostRecentMessageTimestampLabel}
						</div>
					}
				</div>

				<div className='doc-figma-topbar-threadSummaryBtn-avatarRow'>

					{/* 
							For who's avatar image to show, I'd guess something like...								
							3 people who most recently posted
							With most recent at the end								
							All uniques (i.e. don't show same avatar multiple times)
					*/}

					{threadChildrenAuthors.length > 0 && threadChildrenAuthors.map((userId,i)=>{
						const userObj=getUserForId(userId)
						if(userObj){
							return(
								<div key={`${messageId}-i-${userId}`} className='doc-figma-topbar-threadSummaryBtn-avatarContainer'> 
									<AvatarImage 
										key={userId}
										avatarUrl={userObj.avatar}
										className='doc-figma-topbar-threadSummaryBtn-avatar'
									/>
								</div>
								)
						}
					})}
				
				</div>


				{/*}
				<div className='doc-figma-topbar-threadSummaryBtn-endIconContainer'>
					<Icon name='chevronRightBold' />
				</div>
				*/}

				
			</button>			
		)
	}
}
 


export default FigmaEmbedTopBarOpenThreadBtn
