const EmojiNodeSpec = {
  attrs: {
    emojiString: {default: ':heart:'}
  },
  inline: true,
  group: "inline",
  draggable: false,
  selectable: false,
  parseDOM: [
      {
        tag: "emoji",
        getAttrs: (dom) => ({
          emojiString: dom.getAttribute("data-emoji-string"),
        })}
    ],
    toDOM(node) {
      const {emojiString} = node.attrs;
      return ["emoji",
      {
        "data-emoji-string":emojiString,


    }]
    }
}
   

 export default EmojiNodeSpec