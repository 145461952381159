const defaultSettings={
	isGridMode:false,
	isToolLocked:false,
	isSnapMode:false
}



function getInitialState(){
	const whiteboardSettings = JSON.parse(localStorage.getItem('whiteboardSettings'))
	if(whiteboardSettings){
		return whiteboardSettings
	}else{
		return defaultSettings
	}

}



export default function (state = getInitialState(), action) {
	switch (action.type) {

	case 'UPDATE_WHITEBOARD_SETTING':
		const {setting,value}=action	
		let newState=Object.assign({},state)
		newState[setting]=value
		localStorage.setItem('whiteboardSettings',JSON.stringify(newState))
		return newState
	
	default:
		return state
	}
}

