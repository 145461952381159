import axios from 'axios'

//TODO look into headers, cache control and stuff
axios.defaults.withCredentials = true

let API=process.env.REACT_APP_API_ENDPOINT||window.envVars.reactAppApiEndpoint
let TENOR_API_KEY=process.env.REACT_APP_TENOR_API_KEY||process.env.REACT_APP_TENOR_API_KEY
let CLOUDINARY_API=process.env.REACT_APP_CLOUDINARY_API_ENDPOINT||window.envVars.reactAppCloudinaryApiEndpoint


const TENOR_API="https://tenor.googleapis.com/v2/search?q="
const clientkey = "yarn";
// const limit = 10;


axios.defaults.headers.common = {
	...axios.defaults.headers.common,
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
	// 'Access-Control-Allow-Origin': 'https://yarn.ngrok.io'
};


//special fetch for auth requests without the no cache headers--TODO not really sure why we need to do this
export const fetchAuth = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  delete axios.defaults.headers.common['Cache-Control'];
  delete axios.defaults.headers.common['Pragma'];
  delete axios.defaults.headers.common['Expires'];
  const result = await axios.get(requestUrl, {...params,  withCredentials: true})
    axios.defaults.headers.common['Cache-Control']='no-cache'
    axios.defaults.headers.common['Pragma']='no-cache'
    axios.defaults.headers.common['Expires']='0'
  return result.data;
};



const limitedFetch = async(url,params,limit,offset,fetchResponses)=>{
  const requestUrl=`${url}?limit=${limit}&&offset=${offset}`
  let response = await fetch(requestUrl,params)
  fetchResponses.push(...response)
  if(response.length==limit){
    const newOffset=offset+limit
   return await limitedFetch(url,params,limit,newOffset,fetchResponses)
  }else{
    return fetchResponses
  }
}



export const batchedFetch = async (
  url,
  params,
) => {
  let totalCount
  let offset=0
  let limit=100
  let fetchResponses=[]
  const responseArray = await limitedFetch(url,params,limit,offset,fetchResponses)
  return responseArray
};


export const fetch = async (
	url,
	params,
) => {
	const requestUrl=`${API}${url}`
	// delete axios.defaults.headers.common['Authorization']; //temp for double api thing --can get rid now have removed old api
	const result = await axios.get(requestUrl, {...params,  withCredentials: true})
	return result.data;
};


export const fetchTenor = async (
  searchTerm,
  limit
) => {
  delete axios.defaults.headers.common['Cache-Control'];
  delete axios.defaults.headers.common['Pragma'];
  delete axios.defaults.headers.common['Expires'];
  const requestUrl=`${TENOR_API}${searchTerm}&key=${TENOR_API_KEY}&client_key=${clientkey}&limit=${limit}`
 // delete axios.defaults.headers.common['Authorization']; //temp for double api thing --can get rid now have removed old api
  const result = await axios.get(requestUrl, {  withCredentials: false})

  axios.defaults.headers.common['Cache-Control']='no-cache'
  axios.defaults.headers.common['Pragma']='no-cache'
  axios.defaults.headers.common['Expires']='0'
  return result.data;
};


 export const put = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const result = await axios.put(requestUrl, {...params,  withCredentials: true})
  return result.data;
};

 export const post = async (
  url,
  params,
) => {
  const requestUrl=`${API}${url}`
  const result = await axios.post(requestUrl, {...params,  withCredentials: true})
  return result.data;
};



export const deleteRequest = async (
  url
) => {
  const requestUrl=`${API}${url}`
  const result = await axios.delete(requestUrl, {withCredentials: true})
  return result.data;
};





 export const postCloudinary = async (
  data
) => {
  const requestUrl=`${CLOUDINARY_API}`
  delete axios.defaults.headers.common['Cache-Control'];
  delete axios.defaults.headers.common['Pragma'];
  delete axios.defaults.headers.common['Expires'];

  const result = await axios.post(requestUrl,data,{ withCredentials: false })
      axios.defaults.headers.common['Cache-Control']='no-cache'
    axios.defaults.headers.common['Pragma']='no-cache'
    axios.defaults.headers.common['Expires']='0'
  return result.data;
};


// function postCloudinary(form) { // for image uploads
//     return fetch(`${APICloudinary}`, {
//       method: 'POST',
//       headers: {
//         Accept: 'application/json, text/plain, */*',
//       },
//       body: form,
//     })
//       .then(checkStatus)
//       .then(parseResponse)
//   }

