const BlockquoteNodeSpec ={
    group: "block",
    content: "block*",
    // toDOM: () => ["blockquote", 0],
  	parseDOM: [{tag: 'blockquote'}],

    // toDOM(node) {
    //   const div = document.createElement('div');
    //   div.className = 'message-blockQuote'

    //   const div2 = document.createElement('div');
    //   div2.className = 'message-blockQuote-divider'    
    //   div.appendChild(div2)

      
    //   const div3 = document.createElement('div');
    //   div3.className = 'message-blockQuote-content'  
    //   div3.innerHTML=node.textContent

    //   div.appendChild(div3)  

 
    //   return div;

  // },
  toDOM: () => ["div",{class:'message-blockQuote'},["div",{class:'message-blockQuote-divider'}],["div",{class:'message-blockQuote-content'},0]],
}
    
   

 export default BlockquoteNodeSpec
 
// const ContentBoxNodeSpec ={
//     group: "block",
//     content: "block*",
//  		parseDOM: [{
// 	    tag: "div",
// 	    getAttrs: dom => {
// 	      let classname = dom.getAttribute("class")
// 	      return classname==='doc-contentBox'
// 	    	}
//   	}],
//     toDOM: () => ["div",{class:'doc-contentBox'}, 0],
// }
    
   

//  export default ContentBoxNodeSpec
 
