import React from 'react'
import Icon from './misc/Icon'
import LinearMarker from './misc/LinearMarker'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import {searchRoadmapsAndProjects} from '../utils/search/searchRoadmapsAndProjects'
import {getProjects} from '../utils/getProjects'
import {getRoadmaps} from '../utils/getRoadmaps'
import {moveDocToProject,moveDocToRoadmap,moveDocToOrg} from '../utils/moveDoc'
import {getProjectsForCurrentUser} from '../utils/getProjectsForCurrentUser'
import {getOrg} from '../utils/getOrg'


function getSortedRoadmaps(){
	const roadmaps = getRoadmaps()
	const sortedRoadmaps = sortBy(roadmaps, function(roadmap){
		return roadmap.name.toString().toLowerCase()
	})
	return sortedRoadmaps
}		

function getSortedUserProjects(){
	const currentUserProjects = getProjectsForCurrentUser()
	const sortedUserProjects = sortBy(currentUserProjects, function(project){
		return project.name.toString().toLowerCase()
	})
	return sortedUserProjects
}		


class MoveDocPopoverItemRow extends React.PureComponent{ 
	render(){	
		const {type,active} = this.props		
		return (	 	  	
			<button className={'moveDocPopover-row ' + (type ? ` moveDocPopover-row--type ` : '') + (active ? ` moveDocPopover-row--active ` : '')} onClick={this.props.onSelect}>
				{type === 'project' && 
					<div className='moveDocPopover-row-markerContainer'>
						<LinearMarker projectId={this.props.itemId} />
					</div>
				}
				{type === 'org' && 
					<div className='moveDocPopover-row-markerContainer moveDocPopover-row-markerContainer--image'>
						<img className='moveDocPopover-row-markerContainer--image-image' src={this.props.orgLogo} />
					</div>
				}
				{type === 'team' && 
					<div className='moveDocPopover-row-markerContainer'>
						<LinearMarker teamId={this.props.itemId} />
					</div>
				}
				{type === 'roadmap' && 
					<div className='moveDocPopover-row-markerContainer moveDocPopover-row-markerContainer--icon'>
						<Icon name='roadmapOutline' />
					</div>
				}
				<div className='moveDocPopover-row-titleContainer'>
					<div className='moveDocPopover-row-title'>
						{this.props.itemName}
					</div>
				</div>

				{/* I THINK THIS IS TOO MUCH FOR NOW 
				{type === 'project' && this.props.project &&
					<div className='moveDocPopover-row--project-teamsContainer'>
						{this.props.project.teams.map((teamId)=>{
							const teamObj=getTeamForId(teamId)								
							return(
								<div key={teamId} className='moveDocPopover-row--project-teamTag'> 																	
									<div className='moveDocPopover-row--project-teamTag-label'>
										{teamObj.linear_key}
									</div>
								</div>
							)
						})}
					</div>
				}
				*/}
			</button>
		)
	}
}


class MoveDocPopoverContents extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleChange = this.handleChange.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.moveMenuDocToRoadmap=this.moveMenuDocToRoadmap.bind(this)
		this.moveMenuDocToProject=this.moveMenuDocToProject.bind(this)

		this.state={      
			value: '',
			suggestions:[],
			activeIndex:null
		}
	}

	componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)		
	}
	
	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		if(e.keyCode==40){ //down arrow
			e.preventDefault()
			const {activeIndex,suggestions,value}=this.state
			let newIndex=null
			if(value && suggestions.length>0){
				if(activeIndex==null || activeIndex==suggestions.length-1){
					newIndex=0
				}else{
					newIndex=activeIndex+1
				}
			}else if(!value){ //handle default segmented state //for now ignore value with no suggestions
				const sortedRoadmaps = getSortedRoadmaps()
				const sortedUserProjects = getSortedUserProjects()
				const combined=sortedRoadmaps.concat(sortedUserProjects)
				if(activeIndex==null || activeIndex==combined.length-1){
					newIndex=0
				}else{
					newIndex=activeIndex+1
				}
			}
			this.setState({activeIndex:newIndex})
		}else if(e.keyCode==38){ //UP arrow
			e.preventDefault()
			const {activeIndex,suggestions,value}=this.state
			let newIndex=null
			if(value && suggestions.length>0){
				if(activeIndex==null || activeIndex==0){
					newIndex=suggestions.length-1
				}else{
					newIndex=activeIndex-1
				}
			}else if(!value){ //handle default segmented state //for now ignore value with no suggestions
				const sortedRoadmaps = getSortedRoadmaps()
				const sortedUserProjects = getSortedUserProjects()
				const combined=sortedRoadmaps.concat(sortedUserProjects)
				if(activeIndex==null || activeIndex==0){
					newIndex=combined.length-1
				}else{
					newIndex=activeIndex-1
				}
			}
			this.setState({activeIndex:newIndex})
		}else if(e.keyCode==13){ //enterKey
			const {activeIndex,suggestions,value}=this.state
			if(activeIndex || activeIndex==0){
				const {docId}=this.props
				let selectedItem
				if(value && suggestions.length>0){
					selectedItem=suggestions[activeIndex]
				}else if(!value){
					//0 index is now org
					if(activeIndex==0){
						this.moveMenuDocToOrg(docId)
					}else{
						const sortedRoadmaps = getSortedRoadmaps()
						const sortedUserProjects = getSortedUserProjects()
						const combined=sortedRoadmaps.concat(sortedUserProjects)
						selectedItem=combined[activeIndex-1]
					}
				}
				if(selectedItem && selectedItem.roadmap_id){
					this.moveMenuDocToRoadmap(docId,selectedItem.roadmap_id)
				}else if(selectedItem && selectedItem.project_id){
					this.moveMenuDocToProject(docId,selectedItem.project_id)
				}
				else if(selectedItem && selectedItem.organization_id){
					this.moveMenuDocToOrg(docId)
				}
			}
		}
	}

	handleChange(event) {
		let activeIndex=null
		const value=event.target.value
		if(value){
			activeIndex=0
		}
		this.setState({value: value,activeIndex:activeIndex});
		const suggestions=searchRoadmapsAndProjects(value)
		this.setState({suggestions:suggestions})
	}

	moveMenuDocToRoadmap(docId,roadmapId){			
		this.props.handleClosePopover()
		moveDocToRoadmap(docId,roadmapId)
	}

	moveMenuDocToProject(docId,projectId){				
		this.props.handleClosePopover()
		moveDocToProject(docId,projectId)		
	}

	moveMenuDocToOrg(docId){
		this.props.handleClosePopover()
		moveDocToOrg(docId)		
	}


	render(){	
		const {docId} = this.props
		const {value,suggestions,activeIndex} = this.state
		let results=suggestions
		//default state show users roadmaps/projects
		const sortedRoadmaps = getSortedRoadmaps()
		const sortedUserProjects = getSortedUserProjects()
		const org = getOrg()
		let orgName
		let orgLogo
		if(org){
			orgName = org.name
			orgLogo = org.logo
		}
		if(org && org.name === 'Yarn'){
			orgName ='Acme Inc.'
		}

		return (
			<React.Fragment>         	
				<div className='moveDocPopover-search'>
					<input 													
						className='moveDocPopover-search-input'
						placeholder='Search Roadmaps and Projects'
						value={this.state.value}
						onChange={this.handleChange} 				
						autoComplete="off"
						autoCorrect="off"
						spellCheck={false}
						autoFocus							
					/>					    
					<div className='moveDocPopover-search-ui'>
						<div className='moveDocPopover-search-ui-iconContainer'>
							<Icon name='magnifyingGlass' />
						</div>
					</div>
				</div>

				{!value &&
					<div className='moveDocPopover-list moveDocPopover-list--empty'>
						<div className='moveDocPopover-list-group'>						
							<div className='moveDocPopover-list-list'>																											
									<MoveDocPopoverItemRow
										active={activeIndex==0}
										key={`org_row`}
										type='org'
										itemName={orgName}
										orgLogo={orgLogo}
										onSelect={()=>{this.moveMenuDocToOrg(docId)}}
									/>
							</div>	
						</div>
						<div className='moveDocPopover-list-groupDivider' />
						<div className='moveDocPopover-list-group'>
							<div className='moveDocPopover-list-group-header'>
								Roadmaps
							</div>
							<div className='moveDocPopover-list-list'>
								{sortedRoadmaps.map((roadmap,i)=>{		
									const isActive=activeIndex==(i+1)																
									return(																
										<MoveDocPopoverItemRow
											active={isActive}
											key={roadmap.roadmap_id}
											type='roadmap'
											itemId={roadmap.roadmap_id}
											itemName={roadmap.name}
											roadmap={roadmap}
											onSelect={()=>{this.moveMenuDocToRoadmap(docId,roadmap.roadmap_id)}}
										/>
									)
								})}
							</div>	
						</div>
						<div className='moveDocPopover-list-groupDivider' />
						<div className='moveDocPopover-list-group'>
							<div className='moveDocPopover-list-group-header'>
								Your Projects
							</div>
							<div className='moveDocPopover-list-list'>
								{sortedUserProjects.map((project,i)=>{																		
									const isActive=activeIndex==(i+sortedRoadmaps.length+1)			
									return(																
										<MoveDocPopoverItemRow
											active={isActive}
											key={project.project_id}
											type='project'
											itemId={project.project_id}
											itemName={project.name}
											project={project}
											onSelect={()=>{this.moveMenuDocToProject(docId,project.project_id)}}
										/>
									)
								})}
							</div>         		
						</div>
					</div>				
				}

				{value &&
					<div className='moveDocPopover-list moveDocPopover-list--results'>
						{results.map((result,i)=>{
							let type='project'
							if(result.roadmap_id){
								type='roadmap'
							}else if(result.organization_id){
								type='org'
							}
							const isActive=activeIndex==i	
							if(type==='roadmap'){
								return(
									<MoveDocPopoverItemRow
										active={isActive}
										key={result.roadmap_id}
										type={type}
										itemId={result.roadmap_id}
										itemName={result.name}
										onSelect={()=>{this.moveMenuDocToRoadmap(docId,result.roadmap_id)}}
									/>
								)
							}else if(type=='org'){
								return(
									<MoveDocPopoverItemRow
										active={isActive}
										key={`org_row`}
										type={type}
										itemName={result.name}
										onSelect={()=>{this.moveMenuDocToOrg(docId)}}
									/>
								)
							}
							else{
								return(
									<MoveDocPopoverItemRow
										active={isActive}
										key={result.project_id}
										type={type}
										itemId={result.project_id}
										itemName={result.name}
										onSelect={()=>{this.moveMenuDocToProject(docId,result.project_id)}}
									/>
								)
							}
						})
					}
					</div>		
				}																									
			</React.Fragment>
		)
	}
}

export default MoveDocPopoverContents