import React from 'react'
import ReactDOM from 'react-dom';
import DocDrawingReactComponent from './DocDrawingReactComponent'
import {updateDrawing} from '../../../prosemirror/utils/insertDrawing'



class DocDrawingNodeView {

  constructor(node, view, getPos,openNewCommentInput) {

    this.getNode=this.getNode.bind(this)
    this.deleteImage=this.deleteImage.bind(this)
    this.addImageComment=this.addImageComment.bind(this)
    this.node = node
    this.getPos=getPos
    this.openNewCommentInput=openNewCommentInput
    this.openEditDrawingModal=this.openEditDrawingModal.bind(this)
    this.updateDrawing=this.updateDrawing.bind(this)

    this.dom = document.createElement("div")
    // this.dom.addEventListener('mousedown',handleMouseDown)
    this.dom.className="doc-drawingWrapper"

   
    const {
    //  deliveryUrl,
      nodeId,
      svg,
      height,
      width,
      tlDrawDoc
      //title,
      //description
    } = node.attrs

     this.dom.id=nodeId

     this.key=0

    ReactDOM.render(
      <DocDrawingReactComponent 
        key={this.key}
        svg={svg}
        tlDrawDoc={tlDrawDoc}
        openEditDrawingModal={this.openEditDrawingModal}
       // deliveryUrl={deliveryUrl}
        //deleteImage={this.deleteImage}
        //addImageComment={this.addImageComment}
        height={height}
        width={width}
        updateDrawing={this.updateDrawing}
        //title={title}
        //description={description}
      />, this.dom)
    }


    openEditDrawingModal(){
      const tlDrawDoc=this.node.attrs.tlDrawDoc
    }


  addImageComment(e){
    //this.openNewCommentInput(e,this.node.attrs.nodeId)
  }

  getNode(){
    return this.node
  }

  deleteImage(){
    let tr = window.view.state.tr
    const imagePos=this.getPos()
    tr.delete(imagePos,imagePos+this.node.nodeSize)
    window.view.dispatch(tr)
  }

  updateDrawing(svg,tlDrawDoc){
    const node=this.getNode()
    const nodePos=this.getPos(node)
    updateDrawing(node,nodePos,svg,tlDrawDoc)
  }

 update(node) {
  if (node.type !== this.node.type) return false
   const {
 
      svg,
      height,
      width,
      tlDrawDoc
      
    } = node.attrs

    const oldSvg=this.node.attrs.svg
    const oldHeight=this.node.attrs.height
    const oldWidth=this.node.attrs.width
      
   if(

    oldSvg != svg ||
    oldHeight != height ||
    oldWidth != width

    ){
    this.key+=1

    ReactDOM.render(
      <DocDrawingReactComponent 
        key={this.key}
        svg={svg}
        tlDrawDoc={tlDrawDoc}
        openEditDrawingModal={this.openEditDrawingModal}
        height={height}
        width={width}
        updateDrawing={this.updateDrawing}

         />, this.dom)
        }
        
    this.node=node
    return true
  }

  destroy() {
     ReactDOM.unmountComponentAtNode(this.dom)
  }


}

export default DocDrawingNodeView