import React from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import Icon from './misc/Icon'
import {getDocForId} from '../utils/getDocForId'
import {createMarkup} from '../utils/createMarkup'
import {openDoc} from '../utils/openDoc'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import moment from 'moment'


const WINDOW_EDGE_PADDING = 24 // distance from window to menu 
const MENU_EDGE_PADDING = 20 // inside menu
const DOC_ASPECT = 16 / 20 // aspect ratio of doc preview



class TabSwitchMenuLibraryTab extends React.Component{  

	render(){	

		const {tabEdgePadding} = this.props
			
		
	  return (
			<div style={{width: `${this.props.tabWidth}px`}} className={'tabSwitchMenu-tab tabSwitchMenu-tab--channel ' + (this.props.active ? ' tabSwitchMenu-tab--active ' : ' tabSwitchMenu-tab--inactive ')}>
				<div style={{padding: `${tabEdgePadding}px`}}  className='tabSwitchMenu-tab-iconOuterContainer'>
					<div style={{height: `${this.props.iconContainerHeight}px`}} className='tabSwitchMenu-tab-iconInnerContainer'>
						<div className='tabSwitchMenu-tab-iconContainer'>
							<Icon name='folder' />
						</div>
					</div>				
				</div>
				<div style={{width: `${this.props.docWidth + tabEdgePadding + tabEdgePadding}px`}} className='tabSwitchMenu-tab-footerContainer'>
					<div className='tabSwitchMenu-tab-footerContainer-titleContainer'>
						<div className='tabSwitchMenu-tab-footerContainer-title'>
							Library
						</div>
					</div>
				</div>							
			</div>
	  )
	}
}


class TabSwitchMenuDraftsTab extends React.Component{  

	render(){	

		const {tabEdgePadding} = this.props
			
		
	  return (
			<div style={{width: `${this.props.tabWidth}px`}} className={'tabSwitchMenu-tab tabSwitchMenu-tab--channel ' + (this.props.active ? ' tabSwitchMenu-tab--active ' : ' tabSwitchMenu-tab--inactive ')}>
				<div style={{padding: `${tabEdgePadding}px`}}  className='tabSwitchMenu-tab-iconOuterContainer'>
					<div style={{height: `${this.props.iconContainerHeight}px`}} className='tabSwitchMenu-tab-iconInnerContainer'>
						<div className='tabSwitchMenu-tab-iconContainer'>
							<Icon name='sideNavPrivateDraft' />
						</div>
					</div>				
				</div>
				<div style={{width: `${this.props.docWidth + tabEdgePadding + tabEdgePadding}px`}} className='tabSwitchMenu-tab-footerContainer'>
					<div className='tabSwitchMenu-tab-footerContainer-titleContainer'>
						<div className='tabSwitchMenu-tab-footerContainer-title'>
							Drafts
						</div>
					</div>
				</div>							
			</div>
	  )
	}
}


class TabSwitchMenuChannelTab extends React.Component{  

	render(){	

		const {tabEdgePadding} = this.props
			
		
	  return (
			<div style={{width: `${this.props.tabWidth}px`}} className={'tabSwitchMenu-tab tabSwitchMenu-tab--channel ' + (this.props.active ? ' tabSwitchMenu-tab--active ' : ' tabSwitchMenu-tab--inactive ')}>
				<div style={{padding: `${tabEdgePadding}px`}}  className='tabSwitchMenu-tab-iconOuterContainer'>
					<div style={{height: `${this.props.iconContainerHeight}px`}} className='tabSwitchMenu-tab-iconInnerContainer'>
						<div className='tabSwitchMenu-tab-iconContainer'>
							<Icon name='hash' />
						</div>
					</div>				
				</div>
				<div style={{width: `${this.props.docWidth + tabEdgePadding + tabEdgePadding}px`}} className='tabSwitchMenu-tab-footerContainer'>
					<div className='tabSwitchMenu-tab-footerContainer-titleContainer'>
						<div className='tabSwitchMenu-tab-footerContainer-title'>
							{this.props.channelName}
						</div>
					</div>

				</div>
				
				
			</div>
	  )
	}
}


class TabSwitchMenuDocTab extends React.Component{  

	render(){	

		const {tabEdgePadding,docId,docWidth} = this.props
		const doc=getDocForId(docId)||{}
		const docName=doc.name
		let channelName=''
		if(doc.channel){
			const channel=getChannelForId(doc.channel)
			if(channel){
				channelName=channel.name
			}
		}

		const originalDocWidth = 920 // this is core width plus some padding
		const docScale = docWidth / originalDocWidth

	  return (
			<div className={'tabSwitchMenu-tab tabSwitchMenu-tab--doc ' + (this.props.active ? ' tabSwitchMenu-tab--active ' : ' tabSwitchMenu-tab--inactive ')}>
				<div style={{paddingLeft: `${tabEdgePadding}px`, paddingRight: `${tabEdgePadding}px`, paddingTop: `${tabEdgePadding / 1.3}px`, paddingBottom: `${tabEdgePadding / 1.3}px`}} className='tabSwitchMenu-tab-docContainer'>
					<div style={{width: `${docWidth}px`, height: `${this.props.docHeight}px`}} className='tabSwitchMenu-tab-doc'>
						<div style={{transform: `scale(${docScale})`, width: `${originalDocWidth}px`}} className='tabSwitchMenu-tab-doc-docPreview'>
							<div className='tabSwitchMenu-tab-doc-docPreview-doc'>
								<div className='tabSwitchMenu-tab-doc-docPreview-doc-title'>
									{docName}
								</div>
							
								<div className="tabSwitchMenu-tab-doc-docPreview-doc-title-innerWrapper" dangerouslySetInnerHTML={createMarkup(doc.doc_html)}/>
							
							</div>
            </div>
					</div>					
				</div>
				<div style={{width: `${docWidth + tabEdgePadding + tabEdgePadding}px`}} className='tabSwitchMenu-tab-footerContainer'>
					<div className='tabSwitchMenu-tab-footerContainer-titleContainer'>
						<div className='tabSwitchMenu-tab-footerContainer-title'>
							{docName}
						</div>
					</div>
					<div className='tabSwitchMenu-tab-footerContainer-channelContainer'>
						<div className='tabSwitchMenu-tab-footerContainer-channel'>
							{this.props.channelName}							
						</div>
					</div>
				</div>
				
				
			</div>
	  )
	}
}

class TabSwitchMenu extends React.Component{  

	constructor(){
		super()
		this.handleKeyDown=this.handleKeyDown.bind(this)	
		this.handleKeyUp=this.handleKeyUp.bind(this)
		this.cycleThroughTabs=this.cycleThroughTabs.bind(this)
		this.state={
			showTabSwitchMenu:false,
			activeIndex:1
		}
	}

	componentDidMount() {	
		window.addEventListener('keydown', this.handleKeyDown)
		window.addEventListener('keyup', this.handleKeyUp)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)	
		window.removeEventListener('keyup', this.handleKeyUp)
	}

	handleKeyDown(e){	
		if(e.metaKey && e.keyCode==69){//command + e
			e.preventDefault()
			e.stopPropagation()
			if(!this.state.showTabSwitchMenu){
				this.setState({showTabSwitchMenu:true})
			}else{
				this.cycleThroughTabs()
			}
		}
	}

	cycleThroughTabs(){
		const {activeIndex}=this.state
		const {openTabs}=this.props
		let newActiveIndex
		if(activeIndex==openTabs.length-1){
			newActiveIndex=0
		}else{
			newActiveIndex=activeIndex+1
		}
		this.setState({activeIndex:newActiveIndex})
	}

	handleKeyUp(e){
		if(this.state.showTabSwitchMenu){
			if(e.keyCode==91){//command
				const activeTab=this.props.openTabs[this.state.activeIndex]
				if(activeTab.docId){
					const activeDoc=activeTab.docId
					openDoc(this.props.history,activeDoc)
				}else if(activeTab.isLibrary){
					this.props.history.push(`/`)
				}else if(activeTab.isDrafts){
					this.props.history.push(`/private`)
				}
				else if(activeTab.isChannel){
					this.props.history.push(`/channel/${activeTab.channelId}`)
				}
				this.setState({showTabSwitchMenu:false,activeIndex:1})
			}
		}
	}


	render(){	
		const {showTabSwitchMenu,activeIndex}=this.state
		const { closeTabSwitchMenu,openTabs} = this.props
		// const tabItems=formatTabItems(openTabs)

		// we need to calculate doc width to scale the preview correctly
		const windowWidth = window.innerWidth

		const numberOfTabs = openTabs.length

		let tabEdgePadding = 20 // can be cleaner
		if(numberOfTabs > 10){
			tabEdgePadding = 10
		}

		const maxAvailableTabSpace = window.innerWidth - (WINDOW_EDGE_PADDING * 2) - (MENU_EDGE_PADDING * 2) - (tabEdgePadding * 2 * numberOfTabs)
		const proportionalDocWidth = Math.floor(maxAvailableTabSpace / numberOfTabs)
		const defaultDocWidth = 160

		const docWidth = Math.min(proportionalDocWidth, defaultDocWidth)
		const docHeight = docWidth / DOC_ASPECT

		const onlyDocs=true
	  if(showTabSwitchMenu){
		  return (
				<div style={{paddingLeft: `${MENU_EDGE_PADDING}px`, paddingRight: `${MENU_EDGE_PADDING}px`}} className={'tabSwitchMenu ' + (showTabSwitchMenu ? 'tabSwitchMenu--visible' : ' showTabSwitchMenu--hidden ')}>
					{openTabs.map((tab,i)=>{
						const active=activeIndex==i
						if(tab.docId){
							return(
								<TabSwitchMenuDocTab 
									key={tab.docId}
									docId={tab.docId}
									docWidth={docWidth}
									docHeight={docHeight}
									tabEdgePadding={tabEdgePadding}
									active={active}
									/>
								)
							}else if(tab.isLibrary){
									return(
										<TabSwitchMenuLibraryTab 	
											key='library'									
											tabWidth={docWidth + tabEdgePadding + tabEdgePadding}
											iconContainerHeight={docHeight}
											tabEdgePadding={tabEdgePadding}		
											active={active}			
										/>
									)
								}else if(tab.isDrafts){
									return(
										<TabSwitchMenuDraftsTab 
											key={'drafts'}										
											tabWidth={docWidth + tabEdgePadding + tabEdgePadding}
											iconContainerHeight={docHeight}
											tabEdgePadding={tabEdgePadding}		
											active={active}			
						 			/>
						 			)
								}else if(tab.isChannel){
									return(
										<TabSwitchMenuChannelTab 
											key={`channel_${tab.channelId}`}
											channelName={tab.channelName}
											tabWidth={docWidth + tabEdgePadding + tabEdgePadding}
							 				iconContainerHeight={docHeight}
											tabEdgePadding={tabEdgePadding}
											active={active}					
										/>
										)
								}
							})}
				</div>
		  )
		}else return null
	}
}


function mapStateToProps(state,ownProps) {
	let openTabs=[]
	if(state.docTabs && state.docTabs.tabGroups){
		Object.keys((state.docTabs.tabGroups)).forEach((tabGroupId)=>{
			const tabGroup=state.docTabs.tabGroups[tabGroupId]
			tabGroup.forEach((tab)=>{
				let tabObj={
					docId:tab,
					type:'doc'
				}
				const recentActivityItem=find(state.recentActivities,{item_id:tab})
				if(recentActivityItem){
					tabObj.timestamp=recentActivityItem.activity_time
				}
				openTabs.push(tabObj)
			})
		})
	}
	//check library, drafts and channel page times- if in the last 2 days then add to tab switch menu

	const pageActivities=state.pageActivities
	const MAX_TIME=1000*60*24*2 //2 days only show pages if opened in less than 2 days
	//const MAX_TIME=1000*6 //2 days only show pages if opened in less than 2 days

	//library
	const libActivity=find(pageActivities,{'page_type':'library'})
	if(libActivity){
		const activityTime=libActivity.activity_time
		let dur=moment.duration( moment().diff(moment(activityTime)))
		const milliseconds=dur._milliseconds
		if(milliseconds<MAX_TIME){
			let item={
				isLibrary:true,
				timestamp:activityTime
			}
			openTabs.push(item)
		}
	}

	const draftActivity= find(pageActivities,{'page_type':'drafts'})
	if(draftActivity){
		const activityTime=draftActivity.activity_time
		let dur=moment.duration( moment().diff(moment(activityTime)))
		const milliseconds=dur._milliseconds
		if(milliseconds<MAX_TIME){
			let item={
				isDrafts:true,
				timestamp:activityTime
			}
			openTabs.push(item)
		}
	}


	const sorted=sortBy(openTabs,'timestamp').reverse()
	return {
		openTabs:sorted,
		pageActivities:state.pageActivities
	}
}

export default withRouter(connect(mapStateToProps,
	{}
)(TabSwitchMenu))




