import {fetch,put} from '../api'
import store from '../store';
import find from 'lodash/find'
import {backroundOpenDocsWithUnreads} from './docs'
import { Mixpanel } from '../Mixpanel'
import {bumpMessageVersion} from '../prosemirror/utils/bumpMessageVersion'

export function fetchRecentAcitivities(isInitialLoad) {
	return (dispatch) => fetch(`/recentActivities`)
		.then((response) => {
			//bumpMessageVersion()
			dispatch({ type: 'FETCH_RECENT_ACTIVITIES_SUCCESS', response })
			if(isInitialLoad){
			//	console.log('recent activities is initial load!!')
				backroundOpenDocsWithUnreads()
			}
			return response
		})
		.catch((error) => { 
			return error
		})
}


export function updateRecentActivity(itemId,itemType) {
	const requestBody={
		item_type:itemType
	}

	return (dispatch) => put(`/recentActivities/${itemId}/update`,requestBody)
		.then((response) => {

			
			dispatch({ type: 'UPDATE_RECENT_ACTIVITY_SUCCESS', response })
			Mixpanel.track('update_recent_activity',{itemId:itemId,itemType:itemType})
			
			if(itemType=='thread'){
				// console.log('item type is thread so lets bump')
				bumpMessageVersion()
			}
			
			
			return response
		})
		.catch((error) => { 
			return error
		})
}



// export function updatePageRecentActivity(pageType,itemId) {
// 	const requestBody={
// 		page_type:pageType,
// 		item_id:itemId
// 	}
// 	return (dispatch) => put(`/recentActivities/page/update`,requestBody)
// 		.then((response) => {
// 			dispatch({ type: 'UPDATE_RECENT_ACTIVITY_SUCCESS', response })
// 			return response
// 		})
// 		.catch((error) => { 
// 			return error
// 		})
// }

