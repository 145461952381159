import React from 'react'
import Icon from '../misc/Icon'

class OnboardingModalProgressBar extends React.Component {  	
  
  constructor(props) {
    super(props);    
    this.state = {
      
    }
  }

  render() {		
    const {stage} = this.props

    const segment4Active = stage === 'workspaceReady'
    const segment3Active = stage === 'figma' || segment4Active
    const segment2Active = stage === 'template' || segment3Active  || segment4Active
    const segment1Active = stage === 'project' || segment2Active || segment3Active  || segment4Active



    return (      
       
      <div className={'onboardingModal-progressBar ' + (stage === 'workspaceReady' ? ' onboardingModal-progressBar--centerAlign onboardingModal-progressBar--workspaceReady ' : ' onboardingModal-progressBar--leftAlign ')}>
        {stage !== 'workspaceReady' &&
          <div className='onboardingModal-progressBar-leftSpacer'/>
        }
        {segment1Active && 
          <div className='onboardingModal-progressBar-bar'>
            <div data-state={segment1Active ? "active" : "inactive"} className='onboardingModal-progressBar-bar-segment' />
            <div data-state={segment2Active ? "active" : "inactive"} className='onboardingModal-progressBar-bar-segment' />
{/*            <div data-state={segment3Active ? "active" : "inactive"} className='onboardingModal-progressBar-bar-segment' />*/}
            <div data-state={segment4Active ? "active" : "inactive"} className='onboardingModal-progressBar-bar-segment' />
          </div>    
        }     
      </div>      
        
    )
  }
}

export default OnboardingModalProgressBar