import schema from '../schema/editorSchema'  
import {randomID} from '../../utils/randomID'

const deliveryUrl='https://dogtime.com/assets/uploads/2011/03/puppy-development.jpg'


export function insertDrawing(svg,tlDrawDoc) {
	const state=window.view.state
	let type = schema.nodes.docDrawing
	//extract height and width from svg
	//https://stackoverflow.com/questions/10628961/javascript-regex-need-to-extract-value-from-a-svg-string
	//modified to include decimal place (might need to test this)
	var width = svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+.\d+)\"?[^>]*>/)[1];
	var height = svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+.\d+)\"?[^>]*>/)[1];
	const attrs={
		//deliveryUrl:src||deliveryUrl,
		nodeId:randomID(),
		height:height,
		width:width,
		svg:svg,
		tlDrawDoc:tlDrawDoc,
	}

	const node=type.create(attrs)
	let insertPos=state.selection.from
	let tr=state.tr
	tr.replaceWith(state.selection.from,state.selection.to,node)
	window.view.dispatch(tr)
}

export function updateDrawing(drawingNode,nodePos,svg,tlDrawDoc) {
	const state=window.view.state
	let type = schema.nodes.docDrawing
	//extract height and width from svg
	//https://stackoverflow.com/questions/10628961/javascript-regex-need-to-extract-value-from-a-svg-string
	//modified to include decimal place (might need to test this)
	var width = svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+.\d+)\"?[^>]*>/)[1];
	var height = svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+.\d+)\"?[^>]*>/)[1];
	const attrs={
		//deliveryUrl:src||deliveryUrl,
		nodeId:randomID(),
		height:height,
		width:width,
		svg:svg,
		tlDrawDoc:tlDrawDoc,
	}
	let tr=state.tr
	tr.setNodeMarkup(nodePos, null, attrs)
	window.view.dispatch(tr)
}