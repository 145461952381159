import React from 'react'
import Icon from '../misc/Icon'
import EditProjectIconPopoverLinearIcons from './EditProjectIconPopoverLinearIcons'
import EditProjectIconPopoverEmoji from './EditProjectIconPopoverEmoji'

class EditProjectIconPopoverIcon extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectIcon=this.handleSelectIcon.bind(this)
		this.state={
      active: this.props.active
    }
	}

	handleSelectIcon(){
		this.setState({active: !this.state.active})
		//this.props.closePopover()
		//const {projectId,projectStatus}=this.props
		//updateProjectStatus(projectId,projectStatus)
	}

	render(){	
		const {active, roadmap} = this.props	
		const {checked} = this.state

		return (
			<div onClick={this.handleSelectRoadmap} className='popoverMenu-item popoverMenu-item--editProjectRoadmaps '>
								


			</div>
		)
	}
}


class EditProjectIconPopoverLinearIconContents extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectIconColor=this.handleSelectIconColor.bind(this)
		this.state={
      activeColor: "blue"
    }
	}

	handleSelectIcon(){
		this.setState({active: !this.state.active})
		//this.props.closePopover()
		//const {projectId,projectStatus}=this.props
		//updateProjectStatus(projectId,projectStatus)
	}

	render(){	
		const {active, roadmap} = this.props	
		const {checked} = this.state

		return (
			<div onClick={this.handleSelectRoadmap} className='popoverMenu-item popoverMenu-item--editProjectRoadmaps '>
								


			</div>
		)
	}
}



const regExp = ':(.*):' //match :heart:

class EditProjectIconPopover extends React.Component{  	

	constructor(props){
		super(props)   
		let activeIconPanel="linearIcon"
		let isEmoji = false
    if(props.icon){
     	const match = props.icon.match(regExp)
       if(match && match[1]){
      		isEmoji=true
      		activeIconPanel="emoji"
    	}
    }

		this.state={
      //activeIconPanel: props.defaultIconType
      activeIconPanel: activeIconPanel
    }
	}

	render(){	
		const {projectId, closePopover} = this.props
		const {activeIconPanel} = this.state

		return (
			<div className='editMetadataPopover--type--projectIcon-content'>
					{activeIconPanel === 'linearIcon' &&
						<EditProjectIconPopoverLinearIcons 
							closePopover={closePopover}				
							updateProjectIcon={this.props.updateProjectIcon}	
							icon={this.props.icon}
        			color={this.props.color}	
        			switchToEmoji={()=>{this.setState({activeIconPanel: "emoji"})}}		
						/>
					}

					{activeIconPanel === 'emoji' &&
						<EditProjectIconPopoverEmoji 
							showTabContents={activeIconPanel === 'emoji'}		
							updateProjectIcon={this.props.updateProjectIcon}	
							closePopover={closePopover}		
							switchToLinearIcons={()=>{this.setState({activeIconPanel: "linearIcon"})}}
						/>
					}

			</div>
		)
	}
}

export default EditProjectIconPopover