import React from 'react'
import Icon from '../../../components/misc/Icon'
import AvatarImage from '../../../components/AvatarImage'
import {getSpeakerForTranscript} from '../../../utils/meetings/getSpeakerForTranscript'
import * as Tooltip from '@radix-ui/react-tooltip';
import StaticCheckbox from '../../../components/uiKit/StaticCheckbox'


function formatTime(seconds){ // turn seconds into min:sec
		seconds = Math.round(seconds);
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    if (minutes < 10) { minutes = "0" + minutes; }
    if (remainingSeconds < 10) { remainingSeconds = "0" + remainingSeconds; }

    return minutes + ":" + remainingSeconds;
}


class TranscriptSpeakerHeaderReactComponent extends React.Component{  
	
	 // constructor(props){
  //   super(props)    
  //   this.state={
  //     checked: false
  //   }
  // }


	render(){
		//transcript_speaker is the speaker from deepgram e.g. 0,1 etc
		// const {checked}=this.state
		const {transcript_speaker,recording_id,startTime,endTime}=this.props
		const speakerObj=getSpeakerForTranscript(recording_id,transcript_speaker)
		let speakerName=`Speaker ${transcript_speaker}`
		// // let speakerName
		let contactId
		let avatarUrl
		if(speakerObj){
			if(speakerObj.user_id){
				speakerName = speakerObj.display_name
				avatarUrl=speakerObj.avatar
			}
			else if(speakerObj.contact_id){
				speakerName = speakerObj.name
				contactId=speakerObj.contact_id
				// avatarUrl=speakerObj.avatar
			}
		}
		const duration=endTime-startTime

		const activeHighlightMode = false		
		const isHighlightOrigin = false

		const showAddHighlightBtn = (!activeHighlightMode || (activeHighlightMode && isHighlightOrigin))
		const showCheckboxAppendHighlight = activeHighlightMode && !isHighlightOrigin


	  return (	  
	  	<React.Fragment>
	  		<div className='recordingPlaybackModal-transcript-speakerChunk-avatarContainer'>
	  			<AvatarImage 
	  				className='recordingPlaybackModal-transcript-speakerChunk-avatar'
	  				avatarUrl={avatarUrl}
	  				contactId={contactId}	  				
	  				sideLength={30}
	  			/>
	  		</div>
	  		<div className='recordingPlaybackModal-transcript-speakerChunk-header'>
	  			<div className='recordingPlaybackModal-transcript-speakerChunk-header-name'>
	  				{speakerName}
	  			</div>	  			
	  			<div className='recordingPlaybackModal-transcript-speakerChunk-header-subtitle'>
	  				{formatTime(startTime)}
	  			</div>	  			
	  		</div>

	  		{/*}
	  		{showAddHighlightBtn && 
	  		<div className='recordingPlaybackModal-transcript-speakerChunk-highlightButtonContainer'>
	  			<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>
								<button className='recordingPlaybackModal-transcript-speakerChunk-highlightButton'>
				  				<Icon name='bookmarkFill' />
				  			</button>
							</Tooltip.Trigger>				
							<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI">																																	
								Add Highlight					
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>				
	  		</div>
	  		}

	  		{showCheckboxAppendHighlight &&
		  		<div className='recordingPlaybackModal-transcript-speakerChunk-appendHighlightCheckboxContainer'>
		  			<StaticCheckbox checked={false} />
		  		</div>
	  		}

	  		{/*
	  		<button className='recordingPlaybackModal-transcript-speakerChunk-header-selectChunkText'>
	  			<div className='recordingPlaybackModal-transcript-speakerChunk-header-selectChunkText-inner' />
	  		</button>
	  		*/}
	  		{/*({formatTime(duration)}) */}
	  	</React.Fragment>

	  )
	}
}
export default TranscriptSpeakerHeaderReactComponent

