import {fetch,put,post,postCloudinary} from '../api'
import {bumpWhiteboardEmbedVersion} from '../prosemirror/utils/whiteboards/bumpWhiteboardEmbedVersion'
import {randomID} from '../utils/randomID'
import { Mixpanel } from '../Mixpanel'


export function fetchWhiteboardEmbeds() {
	let url = `/whiteboards`
	return (dispatch) => {
		return fetch(url)
		.then((response) => {
			dispatch({ type: 'FETCH_WHITEBOARD_EMBEDS_SUCCESS',response })
			bumpWhiteboardEmbedVersion()		
		})
		.catch((error) => {
			console.log(error)
		})
	}
}

export function fetchAWhiteboardEmbed(embedId) {
	let url = `/whiteboards/${embedId}`
	return (dispatch) => {
		return fetch(url)
		.then((response) => {
			dispatch({ type: 'FETCH_A_WHITEBOARD_EMBED_SUCCESS',response })
			bumpWhiteboardEmbedVersion()		
		})
		.catch((error) => {
			console.log(error)
		})
	}
}



export function uploadFileToCloudinary(form) {
	return (dispatch) => postCloudinary(form)
		.then((uploadResponse) => {
			return uploadResponse
		})
		.catch((error) => {
			return Promise.reject(error);
		})
}

export function createWhiteboardEmbed(embedId,snapshot,displayDimensions,docId,projectId) {
	let url = `/whiteboards`
	const requestBody={
		embed_id:embedId,
		snapshot:snapshot,
		doc_id:docId,
		project_id:projectId,
		display_dimensions:displayDimensions
	}

	return (dispatch) => {
		dispatch({ type: 'CREATE_WHITEBOARD_EMBED_REQUEST',embedId,snapshot,displayDimensions})
		return post(url,requestBody)
		.then((response) => {
			Mixpanel.track('create_whiteboard')
			
		})
		.catch((error) => {

			console.log(error)
		})
	}
}


export function updateWhiteboardEmbedRequest(embedId,snapshot,displayDimensions) {
	let url = `/whiteboards/${embedId}`
	const requestBody={
		display_dimensions:displayDimensions,
		snapshot:snapshot
	}
	return (dispatch) => {
		dispatch({ type: 'UPDATE_WHITEBOARD_EMBED_REQUEST',embedId,snapshot,displayDimensions})

		return put(url,requestBody)
		.then((response) => {

			Mixpanel.track('update_whiteboard')
		})
		.catch((error) => {
			console.log(error)
		})
	}
}






// export function uploadWhiteboardImageToCloudinary(blob,publicId) {
// 	console.log('upload to cloudinary')
// 	return (dispatch) => fetch(`/whiteboard/cloudinary?public_id=${publicId}`)
// 		.then((response) => {
// 			console.log('response')
// 			let form = new FormData()
// 			form.append('api_key', response.apiKey)
// 			form.append('timestamp', response.timestamp)
// 			form.append('signature',response.signature)
// 			form.append('file', blob)
// 			form.append('folder', 'whiteboard_images')
// 			form.append('public_id', publicId)		
// 			return dispatch(uploadFileToCloudinary(form))
// 				.then((uploadResponse) => {
// 					console.log('upload response!')
// 					console.log(uploadResponse)
// 					return uploadResponse
// 			  })
// 			  .catch((err) => {

// 				})
// 			})
// 	.catch((error) => {

// 	})		
// }
