import React from 'react'
import ReactDOM from 'react-dom';
import DocThumbnailReactComponent from './DocThumbnailReactComponent'
import {convertThumbnailToImage} from '../../../prosemirror/utils/convertThumbnailToImage'


class DocThumbnailNodeView {

	constructor(node, view, getPos,openNewCommentInput) {
		this.getNode=this.getNode.bind(this)
		this.addImageComment=this.addImageComment.bind(this)
		this.convertThumbnailToImage=this.convertThumbnailToImage.bind(this)
		this.node = node
		this.getPos=getPos
		this.openNewCommentInput=openNewCommentInput
		this.dom = document.createElement("div")
		this.dom.className="doc-imageThumbnail"
		const {
			deliveryUrl,
			nodeId,
			height,
			width,
			title,
			description,
		} = node.attrs

		this.dom.id=nodeId

		ReactDOM.render(
			<DocThumbnailReactComponent 
				nodeId={nodeId}
				deliveryUrl={deliveryUrl}
				deleteImage={this.deleteImage}
				addImageComment={this.addImageComment}
				height={height}
				width={width}
				title={title}
				description={description}
				convertThumbnailToImage={this.convertThumbnailToImage}
			/>, this.dom)

	 
		}


	convertThumbnailToImage(){
    const pos=this.getPos()
   	convertThumbnailToImage(this.node,pos,pos+this.node.nodeSize)
  }

	addImageComment(e){
		this.openNewCommentInput(e,this.node.attrs.nodeId)
	}

	getNode(){
		return this.node
	}


 update(node) {
	if (node.type !== this.node.type) return false
	 const {
		deliveryUrl,
		nodeId,
		height,
		width,
		title,
		description,
		} = node.attrs

		const oldDeliveryUrl=this.node.attrs.deliveryUrl
			
	 if(
		oldDeliveryUrl !==deliveryUrl
		){

	 		ReactDOM.render(
			<DocThumbnailReactComponent 
				nodeId={nodeId}
				deliveryUrl={deliveryUrl}
				deleteImage={this.deleteImage}
				addImageComment={this.addImageComment}
				height={height}
				width={width}
				title={title}
				description={description}
				convertThumbnailToImage={this.convertThumbnailToImage}
			/>, this.dom)


				}
				
		this.node=node
		return true
	}

	 destroy() {
     ReactDOM.unmountComponentAtNode(this.dom)
  }

}

export default DocThumbnailNodeView