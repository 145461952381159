
let initialState={
	visible:false,
	embedId:null,
	isNewWhiteboard:false,
	docId:null
}
//videos are in the video node view (so can continue playing when zoom)

export default function (state = initialState, action) {
	switch (action.type) {
	 
	 case 'SHOW_WHITEBOARD_MODAL':
		return {
			visible:true,
			embedId:action.embedId,
			isNewWhiteboard:action.isNewWhiteboard,
			docId:action.docId
		}
	
		case 'HIDE_WHITEBOARD_MEDIA_MODAL':
			return initialState
	
		default:
			return state;
	}
}