import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import KeyboardShortcutTag from '../../misc/KeyboardShortcutTag'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import sortBy from 'lodash/sortBy'
import {toggleShowNotionExportModal}  from '../../../utils/toggleShowNotionExportModal'
import {duplicateDoc} from '../../../utils/duplicateDoc'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getUserForId} from '../../../utils/getUserForId'
import {moveDocToTeam,moveDocToProject} from '../../../utils/moveDoc'
import {getTabStarredStatus,starTab,unstarTab} from '../../../utils/starredTabs'
import MoveDocPopover from '../../MoveDocPopover'
import {deleteMeeting} from '../../../utils/meetings/deleteMeeting'
import MeetingParticipantList from '../../meetings/meetingParticipantList/MeetingParticipantList'
import {getMeetingForId} from '../../../utils/meetings/getMeetingForId'
import {copyDocLinkToClipboard} from '../../../utils/copyDocLinkToClipboard'
import {createMeetingRecording} from '../../../utils/meetings/createMeetingRecording'
import {getRecordingForMeeting} from '../../../utils/meetings/getRecordingForMeeting'
import {deleteRecording} from '../../../utils/meetings/deleteRecording'
import { RecordingsContext } from '../../../containers/RecordingsProvider'

class DocEditorMeetingDocTopMenu extends React.Component{  

	constructor(props){
    super(props)     
    this.handleUploadRecording=this.handleUploadRecording.bind(this)   
    this.state={
      showAllMeetingsModal: false,
      privateMeeting: false,
    }
  }

  checkMeetingTime() {
    // from GTP, we show different things for past/present/future
    // for example, we prompt to upload a recording if meeting is in the past
    const { meeting } = this.props;
    const meetingStartTime = new Date(meeting.meeting_start);
    const meetingEndTime = new Date(meeting.meeting_end);
    const currentTime = new Date();
    if(currentTime < meetingStartTime) {
      return "future";
    } else if(currentTime > meetingEndTime) {
      return "past";
    } else {
      return "present";
    }   
  }

  handleUploadRecording(e){
  	const file=event.target.files[0]
  	createMeetingRecording(this.props.meeting.meeting_id,file,this.context)
  }

	render(){	
		const {meeting}=this.props
		const {sideMenuVisible,docName,projects,teams,docId,showDocTitle,showMeetingInfo} = this.props

		const {showMoveDocPopover, privateMeeting} = this.state
		const starred = getTabStarredStatus('doc',docId)
		let meetingType 
		if(meeting){
			meetingType=meeting.type
		}
		const meetingDateState = this.checkMeetingTime()
		let meetingTypeIcon
		if(meetingType === 'standup'){
			meetingTypeIcon = 'meetingStandupLayered'
		}else if(meetingType === 'discovery'){
			meetingTypeIcon = 'meetingDiscoveryLayered'
		}else if(meetingType === 'teamSync'){
			meetingTypeIcon = 'meetingTeamSyncLayered'
		}else if(meetingType === 'sprintPlanning'){
			meetingTypeIcon = 'meetingSprintPlanningLayered'
		}else if(meetingType === 'adhoc'){
			meetingTypeIcon = 'meetingAdhocLayered'
		}		
		const recording=getRecordingForMeeting(meeting.meeting_id)
		const hasRecording = recording?true:false
	  return (
	  	<div className={'docEditor-topMenuContainer docEditor-topMenuContainer--meetingDoc ' + (sideMenuVisible ? ' docEditor-topMenuContainer--sideMenuState--visible ' : ' docEditor-topMenuContainer--sideMenuState--hidden ')}>  												
				<div className='docEditor-topMenu-center'>						
					{meetingType && meetingType !== 'blank' &&
					<div className={'docEditor-topMenu--meetingDoc-meetingTypeIndicator ' + (meetingType ? ` docEditor-topMenu--meetingDoc-meetingTypeIndicator--type--${meetingType} ` : ' docEditor-topMenu--meetingDoc-meetingTypeIndicator--type--noType ') + (showMeetingInfo ? ' docEditor-topMenu--meetingDoc-meetingTypeIndicator--show ' : ' docEditor-topMenu--meetingDoc-meetingTypeIndicator--hidden ')}>							
						<Icon name={meetingTypeIcon} />									
					</div>
					}
					<div className={'docEditor-topMenu-center-docTitle' + (showDocTitle ? ' docEditor-topMenu-center-docTitle--show ' : ' docEditor-topMenu-center-docTitle--hidden ')}>
						{docName}
					</div>
					<div className='docEditor-topMenu-center-hSpacer' />
					<div className={'docEditor-topMenu--meetingDoc-participantIndicator ' + (showMeetingInfo ? ' docEditor-topMenu--meetingDoc-participantIndicator--show ' : ' docEditor-topMenu--meetingDoc-participantIndicator--hidden ')}>
						{meeting && meeting.meeting_id &&
							<MeetingParticipantList 
								meeting={meeting}								
								showDropdown
								readOnly={meeting.event_id} // isGcal								
							/>
						}
					</div>
				</div>
				<div className='docEditor-topMenu-right'>					
					{/*}
					<button onClick={()=>{this.setState({showAllMeetingsModal: true})}} className={'docEditor-topMenu-button docEditor-topMenu-button--textAndIcon docEditor-topMenu-button--allMeetings ' + (this.state.showAllMeetingsModal ? ' docEditor-topMenu-button--active ' : ' ')}> 
						<div className='docEditor-topMenu-button-iconContainer'>
							<Icon name='calendarSimple' />
						</div>
						<div className='docEditor-topMenu-button-label'>
							All Meetings
						</div>
					</button>
					*/}

				{/*}
					{!hasRecording && meetingDateState === 'past' &&
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={100}>
								<Tooltip.Trigger asChild>																										
									<button onClick={()=>{createMeetingRecording(meeting.meeting_id,this.context)}} className='docEditor-topMenu-button docEditor-topMenu-button--textOnly docEditor-topMenu-button--recordingUpload'> 						
										<div className='docEditor-topMenu-button-label'>
											<label htmlFor="recordingUpload">
												Add Recording
											</label>
											<input 
												id="recordingUpload"
												type="file"
												className='docEditor-topMenu-button--recordingUpload-hiddenFileInput'
												onChange={this.handleUploadRecording} 
												value={''} 
												accept={'video/*, audio/*'}
											/>
										</div>
									</button>														
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--uploadRecordingButton">																																	
									Share meeting highlights with a full searchable transcript.
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					}
					*/}


					{/*}
					<button onClick={this.props.toggleIssuePanel} className='docEditor-topMenu-button docEditor-topMenu-button--textAndIcon docEditor-topMenu-button--docChat'> 
						<div className='docEditor-topMenu-button-iconContainer'>
							
						</div>
						<div className='docEditor-topMenu-button-label'>
							Issue panel
						</div>
					</button>
					*/}

					{!this.props.messagePanelIsVisible && 
						<button onClick={this.props.toggleShowMessagePanel} className={'docEditor-topMenu-button docEditor-topMenu-button--textAndIcon docEditor-topMenu-button--docChat ' + (this.props.showUnreadsIndicatorDocChatBtn ? ' docEditor-topMenu-button--docChat--hasUnreads ': ' ')}> 
							<div className='docEditor-topMenu-button-iconContainer'>
								{/*<Icon name='messageOutlineMedium' /> */}
								<Icon name={this.props.showUnreadsIndicatorDocChatBtn ? 'sideMenuClean' : 'sideMenu'} />
							</div>
							{this.props.showUnreadsIndicatorDocChatBtn &&
								<div className='docEditor-topMenu-button--docChat--hasUnreadsIndicator' />
							}
							<div className='docEditor-topMenu-button-label'>
								Doc Chat
							</div>						
						</button>
					}


					{/* MAYBE ADD THIS LATER 
					{!privateMeeting && 
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>																	
									<button className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--unlocked' onClick={()=>{this.setState({privateMeeting: !privateMeeting})}}>
										<Icon name='locked' />
									</button>																	
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Make private
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					}
					{privateMeeting && 
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>																	
									<button className='docEditor-topMenu-button docEditor-topMenu-button--textAndIcon docEditor-topMenu-button--locked' onClick={()=>{this.setState({privateMeeting: !privateMeeting})}}>
										<div className='docEditor-topMenu-button-iconContainer'>
											<Icon name='lockedFill' />
										</div>
										<div className='docEditor-topMenu-button-label'>
											Private
										</div>
									</button>																	
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Make visible to Acme Inc.
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					}
					*/}
					{!starred && 
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>																	
									<button className='docEditor-topMenu-button docEditor-topMenu-button--inactiveStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{starTab('doc',docId)}}>
										<Icon name='star' />
									</button>																	
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Star Doc
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					}
					{starred && 
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>																	
									<button className='docEditor-topMenu-button docEditor-topMenu-button--activeStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{unstarTab('doc',docId)}}>
										<Icon name='starFill' />
									</button>																	
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Unstar Doc
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					}

					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>
								<button onClick={()=>{copyDocLinkToClipboard(docId)}} className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--docLink'>
							    <Icon name='link' />
							  </button>
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Copy Link
								<KeyboardShortcutTag									
									command
									letter='L'
								/>									
								</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>

					<DropdownMenu.Root>
				    <DropdownMenu.Trigger className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--dropdown'>
				    	<Icon name='ellipses' />
				    </DropdownMenu.Trigger>   			 	
	   			 	<DropdownMenu.Content sideOffset={1} align="end" alignOffset={-8} className='dropdownMenu'>							
		        	
		        	{/*}
		        	<DropdownMenu.Item >
		        		Upload Recording...
		        	</DropdownMenu.Item>		
		        	*/}
		        	{/*        
		        	<DropdownMenu.Item onSelect={this.props.duplicateDoc} >
		        		Duplicate Doc
		        	</DropdownMenu.Item>		   
		        	*/}     
		        	{hasRecording &&
		       	 		<DropdownMenu.Item onSelect={()=>{deleteRecording(recording.recording_id)}} className='dropdownMenu-item--warning' >
		        			Delete Recording
		        		</DropdownMenu.Item>	
		        	}				        
		       	 	<DropdownMenu.Item  className='dropdownMenu-item--warning' onSelect={()=>{deleteMeeting(meeting.meeting_id,docId,this.props.history)}}>
		        		Delete Meeting
		        	</DropdownMenu.Item>					        
				    </DropdownMenu.Content>
					</DropdownMenu.Root>	  	
				</div>
			</div>		
	  )
	}
}

DocEditorMeetingDocTopMenu.contextType = RecordingsContext;

export default withRouter(DocEditorMeetingDocTopMenu)
