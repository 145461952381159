import React from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import debounce from 'lodash/debounce'
import * as Dialog from '@radix-ui/react-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import {hideDocMediaModal} from '../../../../utils/showDocMediaModal'
import store from '../../../../store'
import {isPrivateDoc} from '../../../../utils/isPrivateDoc'
import DocFigmaEmbedZoomedModalContents from './DocFigmaEmbedZoomedModalContents'


class DocFigmaEmbedZoomedModal extends React.Component{  
 
  unzoomModal(){
  	hideDocMediaModal()
  }
 
	render(){ 
		let visible=false
		let nodeId
		if(this.props.docEditorMediaModals && this.props.docEditorMediaModals.zoomedFigma.visible){
			visible=true
			nodeId=this.props.docEditorMediaModals.zoomedFigma.nodeId
		}
		const appContainer = document.getElementById('app')

	
		const pathname=this.props.location.pathname
		let docId
		let isPrivate=false
		const signatureComponentsArray=pathname.split(/-/)
		if(signatureComponentsArray.length>1){
			docId=signatureComponentsArray[signatureComponentsArray.length-1]
		}
		if(docId){
			isPrivate=isPrivateDoc(docId)
		} 


		return (
			<React.Fragment>
				<Dialog.Root open={visible}>
			    <Dialog.Portal container={appContainer}>
			      <Dialog.Overlay className='doc-zoomedImageModalContainer'>
				      <Dialog.Content 
				      	className='doc-zoomedImageModal'
				      	onEscapeKeyDown={this.unzoomModal}
				      	onPointerDownOutside={this.unzoomModal}
				      	onInteractOutside={this.unzoomModal}
				      	>
				      		<DocFigmaEmbedZoomedModalContents
				      			embedId={nodeId}
				      			unzoom={this.unzoomModal}
				      			sendMediaModalMessage={this.props.sendMediaModalMessage}
				      			isPrivateDoc={isPrivate}		
				      			saveUpdatedCropValues={this.props.saveUpdatedCropValues}		      			
				      		/>
				      		
				      		{/*}
				      		<button onClick={unzoom} className='doc-zoomedImageModal-closeBtn'>
				      			<div className='doc-zoomedImageModal-closeBtn-inner'>										
											<Icon name='cross-small' />
										</div>
									</button>
								*/}
				      </Dialog.Content>
			      </Dialog.Overlay>
			    </Dialog.Portal>
			  </Dialog.Root>
			</React.Fragment>
			
		)
	}
}

const mapStateToProps = (state) => ({
	docEditorMediaModals: state.docEditorMediaModals
});


export default withRouter(connect(
	mapStateToProps,
	{	
	})(DocFigmaEmbedZoomedModal))