import React, { Component } from 'react'
import * as Popover from '@radix-ui/react-popover';
import PopoverItemDivider from '../../../components/uiKit/PopoverItemDivider'
import PopoverItem from '../../../components/uiKit/PopoverItem'
import Icon from '../../../components/misc/Icon'

class TabGroupHeaderEditPopover extends Component {
	constructor(props){
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.handleInputKeyDown=this.handleInputKeyDown.bind(this)

    let tabGroupName=''
    if(props.tabGroup){
    	tabGroupName=props.tabGroup.name
    }
    this.state={      
      value: tabGroupName, 
    }
  }

  handleInputKeyDown(e){
		if(e.keyCode===13){
			e.preventDefault()
			this.props.closePopover()
		}
	}


  handleChange(event) {
    this.setState({value: event.target.value});
    this.props.renameTabGroup(this.props.tabGroup.id,event.target.value)
  }

	render(){  
		
		return (	  	

			<div className="sideMenu-tabGroup-header-editPopover popover">
				<div className='sideMenu-tabGroup-header-editPopover-inputContainer'>
					<input 
						onKeyDown={this.handleInputKeyDown}
						className='sideMenu-tabGroup-header-editPopover-input'
						placeholder='Tab Group Name'
						value={this.state.value}
						onChange={this.handleChange} 						
						maxLength={20}
						autoFocus
					/>
					<div className='sideMenu-tabGroup-header-editPopover-inputContainer-ui'>						
						<div className='sideMenu-tabGroup-header-editPopover-inputContainer-ui-enterKeyTag'>
                <div className='sideMenu-tabGroup-header-editPopover-inputContainer-ui-enterKeyTag-label'>
                  Enter
                </div>
                <div className='sideMenu-tabGroup-header-editPopover-inputContainer-ui-iconContainer'>
                  <Icon name='enterKey' />
                </div>
              </div>						
					</div>

				</div>
				<div className='popover-itemList'>
					<PopoverItem iconName='doc' label='New doc in tab group' />
					<PopoverItemDivider />
					<PopoverItem iconName='upArrowMedium' label='Move group to top' />
					<PopoverItem iconName='downArrowMedium' label='Move group to bottom' />
					<PopoverItemDivider />
					<PopoverItem label='Delete Tab Group' onClickFunction={()=>{this.props.deleteTabGroup(this.props.tabGroup.id)}}/>
				</div>
			</div>			

		)
	}
}




class DraggableTabGroupHeader extends Component {
	constructor(props){
    super(props)
    this.handleContextMenu=this.handleContextMenu.bind(this)
    let showEditPopover=false
    if(props.newTabGroupId && props.newTabGroupId===props.tabGroup.id){
    	showEditPopover=true
    }

    this.state={
      showEditPopover: showEditPopover,
    }
  }

  // componentDidMount() {    
  //   document.addEventListener("contextmenu", this.handleContextMenu);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener("contextmenu", this.handleContextMenu);
  // }

  handleContextMenu(e){
    e.preventDefault();
    this.setState({
    	showEditPopover: true,
    });
  }



	render(){  
		const {tabGroup}=this.props
		const {showEditPopover}=this.state

		return (	  	

			<Popover.Root key={`popover_${tabGroup.id}`} open={showEditPopover}>

				<Popover.Trigger asChild >
					<div onContextMenu={this.handleContextMenu} className='sideMenu-tabGroup-header'>
						<div className='sideMenu-tabGroup-header-label'>
							{tabGroup.name}
						</div>
					</div>
				</Popover.Trigger>
				<Popover.Portal>
				<Popover.Content					
		    	className='sideMenu-tabGroup-header-editPopoverOuterContainer'
		    	onEscapeKeyDown={()=>{this.setState({showEditPopover:false})}}
			    onPointerDownOutside={()=>{this.setState({showEditPopover:false})}}
			    onInteractOutside={()=>{this.setState({showEditPopover:false})}}
		    >
		      <TabGroupHeaderEditPopover
		      	closePopover={()=>{this.setState({showEditPopover:false})}}	      	
		      	tabGroup={tabGroup}
		      	renameTabGroup={this.props.renameTabGroup}
		      	deleteTabGroup={this.props.deleteTabGroup}

		      />
		    </Popover.Content>
		    </Popover.Portal>
		  </Popover.Root>	

			

		)
	}
}


export default DraggableTabGroupHeader