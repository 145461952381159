import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import Icon from '../misc/Icon'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import * as Tooltip from '@radix-ui/react-tooltip';

class MeetingsPageNewMeetingPopover extends React.Component{  

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)	
		this.handleUpdateMeetingType=this.handleUpdateMeetingType.bind(this)	
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  handleUpdateMeetingType(meetingType){
  	const {meeting} = this.props 
  	setMeetingType(meeting.meeting_id,meetingType)
  	this.props.closePopover()
  }

	render(){	
		const {showPopover, openPopover, closePopover, location, top, left} = this.props
		const {forceMount} = this.state		


		let popoverSide = "bottom"
		let popoverSideOffset= -38
		let popoverAlign = "end"				
		let popoverAlignOffset= -24
		let transformOrigin = '80% 10%'

		if(location === 'calendarPlus'){
			popoverSide = "bottom"
			popoverSideOffset= -38
			popoverAlign = "center"				
			popoverAlignOffset= 0
			transformOrigin = '50% 30%'
		}

		if(location === 'GCalEvent'){
			popoverSide = "bottom"		
			//popoverSideOffset= -59 + top - 20	
			popoverSideOffset= -65
			popoverAlign = "end"				
			popoverAlignOffset= -180
			// popoverAlignOffset= 0 + left + 10
			transformOrigin = '0% 0%'
		}


	  return (
	  	<React.Fragment>
		  	<Popover.Root modal={false} open={showPopover}>
					<Popover.Trigger asChild>						
						{this.props.children}
					</Popover.Trigger>					
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'newMeetingPopoverContainer ' + (showPopover ? ' newMeetingPopoverContainer--visible ' : ' newMeetingPopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							onInteractOutside={closePopover}						
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `scale(0.9)` }}
								enter={{ opacity: 1, transform: 'scale(1)'  }}
								leave={{ opacity: 0, transform: `scale(0.9)`  }}
								reverse={showPopover}
								config={{tension: 1000, friction: 32}}
								// config={{duration: 0}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={{transformOrigin: `${transformOrigin}`, ...styles}} className="newMeetingPopover popover forceDarkTheme"> 	
											<div onClick={()=>{this.props.createMeeting('')}} className='popoverMenu-item popoverMenu-item--newMeeting popoverMenu-item--newMeeting--blank'>
												<div className='popoverMenu-item-label'>
													Empty Meeting
												</div>
											</div>
											<div onClick={()=>{this.props.createMeeting('designReview')}} className='popoverMenu-item popoverMenu-item--newMeeting'>
												<div className='popoverMenu-item-iconContainer'>
													<MeetingTypeIcon meetingType='designReview' /> 
												</div>
												<div className='popoverMenu-item-rightContainer'>
													<div className='popoverMenu-item-label'>
														Design Review 
													</div>
													<div className='popoverMenu-item-subLabel'>
														Link to projects
													</div>
												</div>
											</div>
											<div onClick={()=>{this.props.createMeeting('discovery')}} className='popoverMenu-item popoverMenu-item--newMeeting'>												
												<div className='popoverMenu-item-iconContainer'>
													<MeetingTypeIcon meetingType='discovery' /> 
												</div>
												<div className='popoverMenu-item-rightContainer'>
													<div className='popoverMenu-item-label'>
														Discovery Call
													</div>
													<div className='popoverMenu-item-subLabel'>
														Share video highlights
													</div>
												</div>
											</div>
											<div onClick={()=>{this.props.createMeeting('teamSync')}} className='popoverMenu-item popoverMenu-item--newMeeting'>	
												<div className='popoverMenu-item-iconContainer'>
													<MeetingTypeIcon meetingType='teamSync' /> 
												</div>											
												<div className='popoverMenu-item-rightContainer'>
													<div className='popoverMenu-item-label'>
														Team Sync
													</div>
													<div className='popoverMenu-item-subLabel'>
														Inline roadmap
													</div>
												</div>
											</div>
											
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>
			</React.Fragment>
	  )
	}
}

export default MeetingsPageNewMeetingPopover
