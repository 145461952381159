
export function getDeviceFramePadding(deviceFrame) {	

	let top = 25
	let bottom = 25
	let left = 25
	let right = 25

	//
	//
	// TABLETS
	
	
	if(deviceFrame === 'iPadPro11Portrait'){
		top = 50
		bottom = 48
		left = 48
		right = 50
	}

	if(deviceFrame === 'iPadPro129Portrait'){
		top = 53
		bottom = 51
		left = 48
		right = 49
	}

	if(deviceFrame === 'iPadPro11Landscape'){
		top = 50
		bottom = 47
		left = 49.5
		right = 47
	}

	if(deviceFrame === 'iPadPro129Landscape'){
		top = 51
		bottom = 48
		left = 51
		right = 48
	}

	//
	//
	// PHONES

	if(deviceFrame === 'iPhone14'){
		top = 20
		bottom = 21
		left = 24
		right = 21
	}

	if(deviceFrame === 'iPhone14Pro'){
		top = 20.25
		bottom = 19.75 
		left = 22.5
		right = 21
	}

	if(deviceFrame === 'iPhone14ProMax'){
		top = 18
		bottom = 19.25
		left = 22.25
		right = 21.5 
	
	}		

	if(deviceFrame === 'iPhone13ProMax'){
		top = 23.4
		bottom = 20.5
		left = 24.8
		right = 24.8
	}

	if(deviceFrame === 'iPhone13Mini'){		
		top = 22.5
		bottom = 22
		left = 25.5
		right = 23.5
	}


	if(deviceFrame === 'AndroidSmall'){
		// Google Pixel
		top = 84
		bottom = 95
		left = 20.5
		right = 22.5

	}

	if(deviceFrame === 'AndroidLarge'){
		// Samsung S21
		top = 13.75
		bottom = 19.25
		left = 15
		right = 18
	}

	//
	// GUESS IF FULL DESKTOP

	if(deviceFrame === 'desktop-arc'){ 
		// top = 10
		// bottom = 10
		// left = 10
		// right = 10
		top = 13
		bottom = 13
		left = 13
		right = 13
	} 

	if(deviceFrame === 'desktop-chrome'){ 
		top = 30
		bottom = 1
		left = 1
		right = 1
	} 

	if(deviceFrame === 'desktop-macOS'){ 
		top = 1
		bottom = 1
		left = 1
		right = 1
	}
	
	const deviceFrameBorder={
		
		'top': top,
		'bottom': bottom,
		'left': left,
		'right': right,		
		
	}

	return deviceFrameBorder
}
