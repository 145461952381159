import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import {getUserForId} from '../../../utils/getUserForId'
import LinearIssuePriorityIcon from '../../misc/LinearIssuePriorityIcon'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getIssuesForUser} from '../../../utils/issues/getIssuesForUser'

class ParticipantHeaderReactComponent extends React.Component{  
	
	render(){
		const {userId}=this.props
		const user=getUserForId(userId)

    let userAvatar
    let userDisplayName    
    
    if(user){
      userAvatar = user.avatar
      userDisplayName = user.display_name
    }

    // const urgentTasks = 12 // priority 1
    // const highPriorityTasks = 7 // priority 2

    const priorityIssues=getIssuesForUser(userId)
    const urgentIssues=priorityIssues.urgent
    const highPriorityIssues=priorityIssues.high 
    const urgentIssuesCount=urgentIssues.length 
    const highPriorityIssueCount=highPriorityIssues.length
    
	
		return (
				<React.Fragment>
					<div className='doc-participantHeader-avatarOuterContainer'>
						<div className='doc-participantHeader-avatarContainer'>
							<AvatarImage avatarUrl={userAvatar} className='doc-participantHeader-avatar' />
						</div>
					</div>
					<div className='doc-participantHeader-name'>
						{userDisplayName}
					</div>
					<div className='doc-participantHeader-rightContainer'>
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={400}>
								<Tooltip.Trigger asChild>								
									<button className='doc-participantHeader-priorityTagBtn' onClick={()=>{this.props.openIssuePanelForUser(userId)}}>
										{urgentIssuesCount > 0 &&
										<div className='doc-participantHeader-priorityTag'>
											<div className='doc-participantHeader-priorityTag-iconContainer'>
												<LinearIssuePriorityIcon 
						              priority={1}          
						            />
						          </div>
						          <div className='doc-participantHeader-priorityTag-label'>
						          	{urgentIssuesCount}
						          </div>
				            </div>
				          	}

				            <div className='doc-participantHeader-priorityTag'>
											<div className='doc-participantHeader-priorityTag-iconContainer'>
												<LinearIssuePriorityIcon 
						              priority={2}          
						            />
						          </div>
						          <div className='doc-participantHeader-priorityTag-label'>					          	
						          	{highPriorityIssueCount > 0 ? highPriorityIssueCount : 'None'}						          	
						          </div>
				            </div>
				        	</button>
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Preview Urgent and High Priority Issues
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					</div>

				</React.Fragment>
			)				
	}
}

export default ParticipantHeaderReactComponent

	