import {getAnchorNode} from '../getAnchorNode'
import {TextSelection} from "prosemirror-state"
import {CellSelection,isInTable,addColumnAfter,addColumnBefore,addRowBefore,addRowAfter,selectionCell,moveCellForward} from 'prosemirror-tables'
import { findParentNode } from 'prosemirror-utils'
import {getNodePos} from '../../utils/getNodePos'
import { Mixpanel } from '../../../Mixpanel'

export function maybeAddTableColumnAfter(state,dispatch,view) {
	if(isInTable(state)){
    Mixpanel.track('table_shortcut_new_column_after')
		return addColumnAfter(state,dispatch)
	}
	else return false
}


export function maybeAddTableColumnBefore(state,dispatch,view) {
	if(isInTable(state)){
    Mixpanel.track('table_shortcut_new_column_before')
		return addColumnBefore(state,dispatch)
	}
	else return false
}

export function maybeAddTableRowBefore(state,dispatch,view) {
	if(isInTable(state)){
    Mixpanel.track('table_shortcut_new_row_before')
		return addRowBefore(state,dispatch)
	}
	else return false
}

export function maybeAddTableRowAfter(state,dispatch,view) {
	if(isInTable(state)){
    Mixpanel.track('table_shortcut_new_row_after')
		return addRowAfter(state,dispatch)
	}
	else return false
}


//adapted from https://github.com/ProseMirror/prosemirror-tables/blob/master/src/commands.ts
function findNextCell($cell, dir) {
  if (dir < 0) {
    const before = $cell.nodeBefore;
    if (before) return $cell.pos - before.nodeSize;
    for (
      let row = $cell.index(-1) - 1, rowEnd = $cell.before();
      row >= 0;
      row--
    ) {
      const rowNode = $cell.node(-1).child(row);
      const lastChild = rowNode.lastChild;
      if (lastChild) {
        return rowEnd - 1 - lastChild.nodeSize;
      }
      rowEnd -= rowNode.nodeSize;
    }
  } else {
    if ($cell.index() < $cell.parent.childCount - 1) {
      return $cell.pos + $cell.nodeAfter.nodeSize;
    }
    const table = $cell.node(-1);
    for (
      let row = $cell.indexAfter(-1), rowStart = $cell.after();
      row < table.childCount;
      row++
    ) {
      const rowNode = table.child(row);
      if (rowNode.childCount) return rowStart + 1;
      rowStart += rowNode.nodeSize;
    }
  }
  return null;
}

function findVerticalCell($cell, dir) {
  const table = $cell.node(-1);
  const currentRowIndex = $cell.index(-1);
  const currentColumnIndex = $cell.index();
  // console.log(`current row index ${currentRowIndex}`)
  // console.log(`current column index ${currentColumnIndex}`)
  // console.log(table.childCount)
  // console.log(dir>0)
  // console.log( currentRowIndex < table.childCount - 1)


  if (dir > 0 && currentRowIndex > 0) {
  	const newRowIndex=currentRowIndex-1
  	const rowNode = table.child(newRowIndex)
  	const pos=getNodePos(rowNode.child(currentColumnIndex))
  	return pos
  }

  else if (dir < 0 && currentRowIndex < table.childCount - 1) {
  	const newRowIndex=currentRowIndex+1
  	const rowNode = table.child(newRowIndex)
  	const pos=getNodePos(rowNode.child(currentColumnIndex))
  	return pos
  }

  return null;
}

//adapted from https://github.com/ProseMirror/prosemirror-tables/blob/master/src/commands.ts
//changed it to make it a cell selection instead of text
function goToNextCell(direction){
  return function (state, dispatch) {
    if (!isInTable(state)) return false;
    const cell = findNextCell(selectionCell(state), direction);
    if (cell == null) return true;
    if (dispatch) {
      const $cell = state.doc.resolve(cell);
      dispatch(
        state.tr
          .setSelection(new CellSelection($cell))
          .scrollIntoView()
      );
    }
    return true;
  };
}

function goToNextVerticalCell(direction){
  return function (state, dispatch) {
    if (!isInTable(state)) return false;
    const cell = findVerticalCell(selectionCell(state), direction);
    console.log('next vertical cell')
    console.log(cell)
    if (cell == null) return true;
    if (dispatch) {
      const $cell = state.doc.resolve(cell);
      dispatch(
        state.tr
          .setSelection(new CellSelection($cell))
      );
    }
    return true;
  };
}


export function maybeSelectCellRight(state,dispatch,view) {
	if(isInTable(state)){
		return goToNextCell(1)(state,dispatch)
		//return addRowAfter(state,dispatch)
	}
	else return false
}

export function maybeSelectCellUp(state,dispatch,view) {
	if(isInTable(state)){
		return goToNextVerticalCell(1)(state,dispatch)
		//return addRowAfter(state,dispatch)
	}
	else return false
}

export function maybeSelectCellDown(state,dispatch,view) {
	if(isInTable(state)){
		return goToNextVerticalCell(-1)(state,dispatch)
		//return addRowAfter(state,dispatch)
	}
	else return false
}


export function maybeSelectCellLeft(state,dispatch,view) {
	if(isInTable(state)){
		return goToNextCell(-1)(state,dispatch)
		//return addRowAfter(state,dispatch)
	}
	else return false
}

