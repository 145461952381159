import store from '../store'
import find from 'lodash/find'
import {fetch,post} from '../api'
import {openDoc} from './openDoc'
import {showToastNotification} from '../actions/toastNotification'
import {getUserName} from '../utils/getUserName'

// data={
// 	sentBy,
// 	sentTo,
// 	docId
// }


export function pingCursorToUser(userId,docId,history){	
	let data={}
	const state = store.getState()
	const currentUser=state.user
	if(currentUser){
		data.sentBy=currentUser['user_id']
	}

	data.sentTo=userId
	data.docId=docId
	
	post(`/organization/ping-cursor`,data).then((response)=>{
		const actionType="pingCursorConfirmation"
		const userName=getUserName(userId)
		store.dispatch(showToastNotification(actionType,userName))
	})
}