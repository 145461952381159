function getAttrs(dom) {
  return {
    date: dom.getAttribute('data-date'),

  }
}


const DocDateNodeSpec ={
    attrs: {
      date:{default:''}
    },
    inline: true,
    group: "inline",
    draggable: true,
    parseDOM: [
    {tag: 'docDate', getAttrs},
   ],
    toDOM(node) {
      return ['docDate', {'data-date':node.attrs.date}]
  }
   //  toDOM(node) {
   //    const div = document.createElement('div');
   //    div.className="doc-para-internalLinkContainer"

   //    const div2=document.createElement('div');
   //    div2.className = 'doc-para-internalLink'
      

   //    const div3=document.createElement('div');
   //    div3.className = 'doc-para-internalLink-title'
   //    div3.innerHTML=`${node.attrs.docNameSnapshot}`
   //    div2.appendChild(div3)
   //    div.appendChild(div2)

   //    return div;
   // },
}
    
   

  export default DocDateNodeSpec


