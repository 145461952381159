import React from 'react'
import Icon from '../misc/Icon'
import { Rnd } from "react-rnd";


class ResizablePanel extends React.Component{  	

	constructor(){
    super()    
    this.state={
    	// x: null,
      // checked: false,
      // mouseDown: false,
    }
  }

 //  handleMouseUp(e){				
	// 	this.setState({mouseDown:false})

	// 	// setTimeout(function() {
 //  //  		this.setState({mouseDown:false})
 // 	// 	}.bind(this), 500)
	// }

	render(){	

		const {x} = this.state

		const innerContainerWidth = 1200
		const dragAreaMin = 0.55 * innerContainerWidth
		const dragAreaDefault = 0.75 * innerContainerWidth
		const dragAreaMax = innerContainerWidth

		const widthDragHandle = 20

		const popupNarrateTriggerWidth = 220
		const popoutNarrate = innerContainerWidth - x < popupNarrateTriggerWidth

		let primaryContainerWidth = x
		if(popoutNarrate){
			primaryContainerWidth = innerContainerWidth
		}

		
		



	  return (
	  	
	  		<div style={{width: `${innerContainerWidth}px`}} className='resizablePanel'>
	  			<div style={{width: `${innerContainerWidth - dragAreaMin}px`}} id='resizablePanel' className='resizablePanel-dragArea' />
	  				  			
	  			<Rnd
				    className='resizablePanel-resizeBar'
				    size={{ width: widthDragHandle, height: '100%'}}
				    enableResizing={false}
				    dragAxis='x'
				    //onDragStop={(e, d) => { this.setState({ x: d.x, y: d.y }) }}
				    onDrag={(e, d) => { this.setState({ x: d.x}) }}
				    bounds="#resizablePanel"
				    default={{
				      x: dragAreaDefault,
				      y: 0,
				    }}
				  >
				    



	  			<div style={{width: `${primaryContainerWidth}px`}} className='resizablePanel-primaryContainer'>
	  				<div className='resizablePanel-primaryVideo' />
	  			</div>
	  			{!popoutNarrate &&
	  				<div style={{width: `${widthDragHandle}px`}} className='resizablePanel-dragSpacer' />	  			
	  			}
	  			
	  			<div className={'resizablePanel-narrationContainer ' + (popoutNarrate && ' resizablePanel-narrationContainer--popout ')}>
	  				<div className='resizablePanel-narrationVideo'>

	  				</div>
	  			</div>

	  							  </Rnd>
	  		
	  		</div>
			
	  )
	}
}

export default ResizablePanel
