import React from 'react'
import ReactDOM from 'react-dom'
import { Plugin } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import {getNewState} from './utils/getNewState'
import {getSuggestions} from './utils/getSuggestions'
import {getMatch} from './utils/getMatch'
import schema from '../../schema/editorSchema'
import InsertDropdownReactComponent from './InsertDropdownReactComponent'
import {showInsertDrawingModal} from '../../../utils/toggleShowInsertDrawingModal'
import {createNewDocForLink} from '../../../utils/createNewDoc'
import {insertVideo} from '../../utils/insertVideo'
import {insertTable} from '../../utils/tables/insertTable'
import {insertContentBox} from '../../utils/insertContentBox'
import {insertCodeBlock} from '../../utils/insertCodeBlock'
import {TextSelection} from "prosemirror-state"
import {getAnchorNode} from '../../utils/getAnchorNode'
import { escapeRegExp } from "lodash";
import {isInTable} from 'prosemirror-tables'
import {openWhiteboardModal} from '../../../utils/whiteboards/openWhiteboardModal'
import {getCurrentUserId} from '../../../utils/getCurrentUser'
import { Mixpanel } from '../../../Mixpanel'

//Add an extra alt name of h to H1 so that it appears above H2 in search results

const MAX_TEXT_MATCH_LENGTH=8 //if the text after / is more than this length the plugin will close


const data=[	
	//{name:'Linear', 			iconName:'linear', 										label:'Issue Mention',		altNames:['linear','issue']},	
	
	{name:'Drawing', 			iconName:'drawingSquiggleRegular', 					label:'Whiteboard', 				altNames:['sketch', 'drawing']},
	//{name:'Figma', 				iconName:'insertBarFigma', 						label:'Figma', 					altNames:[]},
	//{name:'ScreenRec', 	iconName:'insertBarScreenRecording', 	label: name:'Screen Recording'},
	//{name:'Image', 				iconName:'insertBarImage', 						label:'Image...',				altNames:[]},
	//{name:'Video', 			iconName:'insertBarVideo', 							label:'Video Upload', 	altNames:[]},
	//{name:'VideoRec', 	iconName:'insertBarVideoRecording', 	label:'Camera Recording'},
	//{name:'New Doc', 		iconName:'doc', 											label:'New Doc'},	

	// {name:'Participant Header', 				iconName:'', 						label:'Participant Header',			altNames:[]},	

	{name:'CodeMirror', 				iconName:'codeBlock', 						label:'CodeMirror',			altNames:[]},	

	{name:'Table', 				iconName:'insertBarTable', 						label:'Table',			altNames:[]},	
	{name:'ToDo', 				iconName:'insertBarToDo', 						label:'Todo',			altNames:['checklist', 'list', 'checkbox', 'task', 'todo']},	
	//{name:'Code Block', 	iconName:'codeBlock', 								label:'Code Block',			altNames:[]},
	{name:'Content Box', 	iconName:'blockDecoration', 					label:'Content Box',		altNames:['callout']},
	{name:'ToggleList', 	iconName:'toggleArrow', 			label:'Toggle List',		altNames:['toggle', 'list', 'collapsible', 'details', 'fold']},
	//{name:'Link', 			iconName:'linkArrow', 								label:'Link'},	
	//{name:'Mention', 		iconName:'mentionMedium', 						label:'Mention'},	
	
	
	

	{name:'BulletList', 	iconName:'bulletList', 								label:'Bullet List',		altNames:['bullet', 'list']},
	{name:'NumberedList', 	iconName:'numberList', 								label:'Numbered List',		altNames:['number', 'list']},
	
	{name:'Header 1', 		iconName:'formatH1', 									label:'Header 1', 			altNames:['H1','h']},
	{name:'Header 2', 		iconName:'formatH2', 									label:'Header 2', 			altNames:['H2']},
	//{name:'LetteredList', 	iconName:'letterList', 								label:'Letter List',		altNames:['letter', 'list']},
	//{name:'DashList', 		iconName:'dashList', 									label:'Dash List',		altNames:['dash', 'list']},
	//{name:'Image', 				iconName:'insertBarImage', 						label:'Paste Image',				altNames:[]},

]

//https://github.com/joelewis/prosemirror-mentions
//const triggerRegex = new RegExp("[\\\\/]([\\w-\\+]*)$") //trigger with \ and /
//const triggerRegex = new RegExp("[\\\\/]([\\w-\\+]*)$") //trigger with \ and /


const char="/"
const triggerRegex = new RegExp(`${escapeRegExp(char)}([^${escapeRegExp(char)}]*)$`);
const suggestionTextClass="prosemirror-suggestion" //add a decoration around matched text- used to position the dropdown
const activeClass='suggestionMenu-item--active'

export function insertPlugin(docId,pluginKey) {
	let el = document.createElement("div")//dropdown element
	el.addEventListener("mousedown", function(e) { //prevent clicking defocusing selection item
		e.preventDefault()
		e.stopPropagation()
	})

	const showList = function(view, state, suggestions) {		
		
		function selectIndex(e,index){
			e.stopPropagation()
			e.preventDefault()
			select(view,state,suggestions,index)
			view.focus()
		}

		 ReactDOM.render(	
		 	<InsertDropdownReactComponent
		 		suggestions={suggestions}
		 		selectIndex={selectIndex}
		 		hideList={hideList}
		 		text={state.text}
		 	/>
  	, el);

		var node = view.domAtPos(view.state.selection.$from.pos)
		var paraDOM = node.node
		var textDOM = paraDOM.querySelector("." + suggestionTextClass)
		let offset=0
		if(textDOM){
			offset = textDOM.getBoundingClientRect()
			document.getElementById("editor").appendChild(el)
		}
		
		el.style.position = "fixed";
		el.style.left = offset.left + "px";
		el.style.zIndex = 5000;

		// VERTICAL OFFSET
		// ABOVE OR BELOW

		const heightSuggestionsDropdown = 280 // pretty hacky
		const flipBuffer = 28 // don't want to get close to overflowing bottom of screen
		let dropdownPosition

		if(offset.top > (window.innerHeight - heightSuggestionsDropdown - flipBuffer)){
			dropdownPosition = "above"
		}else{
			dropdownPosition = "below"
		}

		if(dropdownPosition === "above"){
			var bottom = window.innerHeight - offset.top + 4 // buffer get to the top, then add a bit of buffer      
			el.style.bottom = bottom + "px";
		}

		if(dropdownPosition === "below") {    
			if(textDOM){
				var top = (textDOM.offsetHeight) + offset.top + 2 // get height of text line then buffer      
				el.style.top = top + "px";
			}   
		}
		el.style.display = "block"
	} //end show list

	const hideList = function() {
		el.style.display = "none"
		el.style.bottom = null
		el.style.top = null
		ReactDOM.unmountComponentAtNode(el)
	}

	const select = function(view,state,suggestions,index) {
		const item = suggestions[index]
		let text=item.name		



		
		if(text=="Drawing"){
			const node = view.state.schema.text('\u00a0')
			let tr = view.state.tr
			tr.replaceWith(state.range.from, state.range.to, node)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			const isNewWhiteboard=true
			const embedId=null
			openWhiteboardModal(isNewWhiteboard,embedId,docId)
			hideList()
		}

		else if(text=="CodeMirror"){
			let type = schema.nodes.docCodeMirror	
			const headingNode = type.createAndFill()
			let tr = view.state.tr
			let replaceStart=state.range.from
			let replaceEnd=state.range.to
			tr.replaceWith(replaceStart, replaceEnd, headingNode)
			let selection=new TextSelection(tr.doc.resolve(replaceStart+2))
			tr.setSelection(selection)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			//console.log('insert codemirror')
			Mixpanel.track('insert_codemirror',{method:'insertMenu'})
			hideList()

		}


		else if(text=="Content Box"){
			const node = view.state.schema.text('\u00a0')
			let tr = view.state.tr
			tr.replaceWith(state.range.from, state.range.to, node)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			insertContentBox()
			hideList()
		}
		else if(text=="Code Block"){
			const node = view.state.schema.text('\u00a0')
			let tr = view.state.tr
			tr.replaceWith(state.range.from, state.range.to, node)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			insertCodeBlock()
			hideList()
		}else if(text=="Table"){
			const node = view.state.schema.text('\u00a0')
			let tr = view.state.tr
			tr.replaceWith(state.range.from, state.range.to, node)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			insertTable()
			hideList()
		}


		else if(text=="ToDo"){
			let editorState = view.state
			let sel = editorState.selection
			const $from=editorState.doc.resolve(sel.anchor)
			const anchorNode=getAnchorNode($from,'paragraph')
			let indentLevel=0
			if(anchorNode){
				indentLevel=anchorNode.attrs.indentLevel
			} 
			//check if anchor node (paragraph) is empty- if so replace it with todo
			//otherwise add todo after paragraph			
			const lengthOfMatch=state.range.to-state.range.from
			let isEmptyParagraph=false
			if(anchorNode && anchorNode.nodeSize==lengthOfMatch+2){ 
				isEmptyParagraph=true
			}
			let type = schema.nodes.toDoItem
			let attrs={	
				indentLevel:indentLevel
			}
			const node = type.createAndFill(attrs) 
			let tr = view.state.tr
			let replaceStart=state.range.from
			let replaceEnd=state.range.to
			let selectionEnd=replaceStart+3
			if(isEmptyParagraph){ //replace whole p node
				replaceStart=replaceStart-1
				replaceEnd=replaceEnd+1
			}
			tr.replaceWith(replaceStart, replaceEnd, node)
			let selection=new TextSelection(tr.doc.resolve(replaceStart+2))
			tr.setSelection(selection)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}else if(text=="ToggleList"){
			let editorState = view.state
			let sel = editorState.selection
			const $from=editorState.doc.resolve(sel.anchor)
			const anchorNode=getAnchorNode($from,'paragraph')
			let indentLevel=0
			if(anchorNode){
				indentLevel=anchorNode.attrs.indentLevel
			} 
			//check if anchor node (paragraph) is empty- if so replace it with todo
			//otherwise add todo after paragraph			
			const lengthOfMatch=state.range.to-state.range.from
			let isEmptyParagraph=false
			if(anchorNode && anchorNode.nodeSize==lengthOfMatch+2){ 
				isEmptyParagraph=true
			}
			let type = schema.nodes.toggleListItem

			const node = type.createAndFill() 
			let tr = view.state.tr
			let replaceStart=state.range.from
			let replaceEnd=state.range.to
			let selectionEnd=replaceStart+3
			if(isEmptyParagraph){ //replace whole p node
				replaceStart=replaceStart-1
				replaceEnd=replaceEnd+1
			}
			tr.replaceWith(replaceStart, replaceEnd, node)
			let selection=new TextSelection(tr.doc.resolve(replaceStart+2))
			tr.setSelection(selection)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()



			// let type = schema.nodes.toggleListItem
			// const node = type.createAndFill()
			// let tr = view.state.tr
			// let replaceStart=state.range.from
			// let replaceEnd=state.range.to
			// tr.replaceWith(replaceStart, replaceEnd, node)
			// let selection=new TextSelection(tr.doc.resolve(replaceStart+3))
			// tr.setSelection(selection)
			// tr.setMeta("insertPlugin", { deactivate: true })
			// view.dispatch(tr)
			// hideList()
		}
		
		else if(text=="BulletList" || text=="DashList" || text=='NumberedList' || text=='LetterList'){
			let editorState = view.state
			let sel = editorState.selection
			const $from=editorState.doc.resolve(sel.anchor)

			const anchorNode=getAnchorNode($from,'paragraph')
			let indentLevel=0
			if(anchorNode){
				indentLevel=anchorNode.attrs.indentLevel
			}
			//check if anchor node (paragraph) is empty- if so replace it with todo
			//otherwise add todo after paragraph			
			const lengthOfMatch=state.range.to-state.range.from
			let isEmptyParagraph=false
			if(anchorNode && anchorNode.nodeSize==lengthOfMatch+2){ 
				isEmptyParagraph=true
			}

			let markerType="bullet"
			if(text=="DashList"){
				markerType="dash"
			}
			if(text=="NumberedList"){
				markerType="number"
			}
			if(text=="LetterList"){
				markerType="letter"
			}
			let type = schema.nodes.bulletListItem
			const node = type.createAndFill({indentLevel:indentLevel,markerType:markerType})
			let tr = view.state.tr
			let replaceStart=state.range.from
			let replaceEnd=state.range.to
			if(isEmptyParagraph){ //replace whole p node
				replaceStart=replaceStart-1
				replaceEnd=replaceEnd+1
			}
			tr.replaceWith(replaceStart, replaceEnd, node)
			let selection=new TextSelection(tr.doc.resolve(replaceStart+2))
			tr.setSelection(selection)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}
		
		else if(text=="Header 1" || text==="Header 2"){ //for now just insert the text
			let headingLevel=1
			if(text==="Header 2"){
				headingLevel=2
			}
			let editorState = view.state
			let sel = editorState.selection
			const $from=editorState.doc.resolve(sel.anchor)
			const anchorNode=getAnchorNode($from,'paragraph')
			//check if anchor node (paragraph) is empty- if so replace it with heading otherwise add heading after paragraph	
			const lengthOfMatch=state.range.to-state.range.from
			let isEmptyParagraph=false
			if(anchorNode && anchorNode.nodeSize==lengthOfMatch+2){ 
				isEmptyParagraph=true
			}
			let type = schema.nodes.heading	
			const headingNode = type.createAndFill({level:headingLevel})
			let tr = view.state.tr
			let replaceStart=state.range.from
			let replaceEnd=state.range.to
			if(isEmptyParagraph){ //replace whole p node
				replaceStart=replaceStart-1
				replaceEnd=replaceEnd+1
			}
			tr.replaceWith(replaceStart, replaceEnd, headingNode)
			let selection=new TextSelection(tr.doc.resolve(replaceStart+1))
			tr.setSelection(selection)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}
		else if(text=="Participant Header"){ 
			console.log('here---------------@@@@')
			const userId=getCurrentUserId()
			let editorState = view.state
			let sel = editorState.selection
			let type = schema.nodes.participantHeader	
			const headingNode = type.createAndFill({userId:userId})
			let tr = view.state.tr
			let replaceStart=state.range.from
			let replaceEnd=state.range.to
			tr.replaceWith(replaceStart, replaceEnd, headingNode)
			let selection=new TextSelection(tr.doc.resolve(replaceStart+1))
			tr.setSelection(selection)
			tr.setMeta("insertPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}
	}

	return new Plugin({
		key: pluginKey,
		state: {
			init() {
				return getNewState();
			},
			apply(tr, prev,oldState,newState) { //TODO test this with colllab
				const next = { ...prev };
				const { selection } = tr
				if (
          selection.from === selection.to &&// only show popup if selection is a blinking cursor         
          !(tr.getMeta("insertPlugin") && tr.getMeta("insertPlugin").deactivate) &&// deactivate popup from view (e.g.: choice has been made or esc has been pressed)        
          !tr.getMeta("focus") &&// deactivate because a mouse event occurs (user clicks somewhere else in the document)
          !tr.getMeta("blur") &&
          !tr.getMeta("pointer")
        ) {
					// Reset active state if we just left the previous suggestion range (e.g.: key arrows moving before /)
          if (prev.active && selection.from <= prev.range.from) {
            next.active = false;
          } else if (tr.getMeta("insertPlugin") && tr.getMeta("insertPlugin").activate) {
            // Start showing suggestions. activate has been set after typing a "/" (or whatever the specified character is), so let's create the decoration and initialize
            next.range = {
              from: selection.from - 1,
              to: selection.to,
            };
            next.query = "";
            next.active = true;
          } else if (prev.active) {       			
            const $position = selection.$from;
						const match = getMatch($position,triggerRegex)
						if(match) {// if match found update state       
							next.range = match.range;
							next.active = true;
							next.text = match.queryText;
						}
          }
        } 
        else {
          next.active = false;
        }
        if(next.active){
        	return next
        }else{ //new March 8th
        	return getNewState()
        }
        
			}
		},
		props: {
   		handleKeyDown(view, e) {
				var state = this.getState(view.state)
				if(e.key==='/'){ // slash (/) key //a=65 use key not keycode so ? doesnt trigger it
					//check if inside toggleListItem
					let {$from} = view.state.selection
					const anchorNode=getAnchorNode($from,'toggleListItemTitle')
					const inTable = isInTable(view.state)
					if(anchorNode || inTable){
					
					}else{
						let tr=view.state.tr
						tr.setMeta("insertPlugin", { activate: true})
						view.dispatch(tr)
					}
				}
				//if (!state.active || !state.suggestions.length) { // don't handle if no suggestions or not in active mode
				if (!state.active) { // don't handle if no suggestions or not in active mode
					return false
				}else if (e.keyCode === 38 || e.keyCode === 40) { //enter key or arrow
					return true
				}else if(e.keyCode === 13){
					if(!state.suggestions.length){
						let tr=view.state.tr
						tr.setMeta("insertPlugin", { deactivate: true})
						view.dispatch(tr)
						// hideList()
					}
					return true
				}else if ( e.keyCode === 27) { //escape key
					let tr=view.state.tr
					tr.setMeta("insertPlugin", { deactivate: true})
					view.dispatch(tr)
					return true
				}
			},     
			decorations(editorState) {//to decorate the currently active suggestion text in ui (and used for positioning dropdown)
				const { active, range } = this.getState(editorState)
				if (!active) return null
				return DecorationSet.create(editorState.doc, [
					Decoration.inline(range.from, range.to, {
						nodeName: "span",
						class: suggestionTextClass
					})
				])
			}
		},
		view() {// To track down state mutations and update dropdown
			return {
				update: (view,prevState )=> {
					const state = this.key.getState(view.state)
					let focused=false
					if(window.view){
						focused=window.view.hasFocus()
					}
					if(!state.active || !focused){
						hideList()
					}else{
						getSuggestions(state.text,data,false, function(suggestions) {
							//we track how many characters you are past the last text that had suggestions
							// then if e.g. 3 characters after close the dropdown
							//this allows for e.g. typo-> no results then fix typo (don't want the suggestions thing to have closed)
							state.suggestions = suggestions
							state.index=0
							if(suggestions.length){
								state.textWithResults=state.text
							}
							const diff=state.text.length-state.textWithResults.length
							if(diff>2 || state.text.length>MAX_TEXT_MATCH_LENGTH){
								let tr=window.view.state.tr
								tr.setMeta("insertPlugin", { deactivate: true})
								window.view.dispatch(tr)
								hideList()
							}else{
								showList(view, state, suggestions)
							}
						})
					}
				},
				destroy: ()=>{
					hideList()
				}
			};
		}
	});
}