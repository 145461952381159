import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import PrivateDocsPage from '../components/library/PrivateDocsPage'
import ReactGA from 'react-ga4'

//Private shows all docs not in a team or project

class PrivateDocsContainer extends Component {
	componentDidMount(){
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Private Drafts" });
		}
	}

	render() {
		return (				
				<div className={'privateDocsPageContainer '  + (this.props.sideMenuVisible ? ' privateDocsPageContainer--sideMenuState--visible ' : ' privateDocsPageContainer--sideMenuState--hidden ') + (window.isElectron ? 'privateDocsPageContainer--electron' : 'privateDocsPageContainer--web')}>
					<PrivateDocsPage
						docs={this.props.docs}
					/>		
				</div>				
			)		
	}
}

function mapStateToProps(state,ownProps) {
	const privateDocs=filter(state.docs,{project:null,roadmap:null,is_org_doc:false,meeting:null})

	return {
		docs:sortBy(privateDocs,function(doc){
			return doc["updated_at"]
		}).reverse(),
	}
}

export default withRouter(connect(mapStateToProps,
	{	
	}
)(PrivateDocsContainer))


	