import filter from 'lodash/filter'
import store from '../../store';	
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import {isCurrentUser} from '../isCurrentUser'
import {userIsFollower} from '../userIsFollower'
import {currentUserIsInThread} from '../threads/currentUserIsInThread'

//for thread parent message use the doc recent activity time
//unless the thread children recent activity time is more recent in which case use that


export function calculateUnreadsForThread(docId,threadParent,recentActivitiesSnapshot) {
	const state = store.getState()
	let recentActivities=state.recentActivities
	if(recentActivitiesSnapshot){
		recentActivities=recentActivitiesSnapshot
	}

	let unreadCount=0

	if(userIsFollower(docId)){
		

   	const messages = filter(state.messages,function(message) {
   		if(message.message_id==threadParent || message.thread_parent==threadParent){
   			return message
   		}
   	});

		if(messages){
			messages.forEach((message)=>{
				let isUnread=false
				
				if(!message.thread_parent){
					let docRecentActivityItem = find(recentActivities,function(activity){
						return `${activity['item_id']}`==`${docId}`
					})
					let childRecentActivityItem = find(recentActivities,function(activity){
						return `${activity['item_id']}`==`${message.message_id}`
					})
					// console.log('doc recent activity')
					// console.log(docRecentActivityItem)
					// console.log('child recent activity')
					// console.log(childRecentActivityItem)

					let recentActivityItem=docRecentActivityItem
					if(docRecentActivityItem && childRecentActivityItem){
						if(childRecentActivityItem.activity_time>docRecentActivityItem.activity_time){
							recentActivityItem=childRecentActivityItem
						}

					}
					// console.log('activity item to use')
					// console.log(recentActivityItem)

					let lastActiveTime=null
					if(recentActivityItem){
						lastActiveTime=recentActivityItem['activity_time']
					}
					isUnread=message['created_at'] && (!lastActiveTime || message['created_at']>lastActiveTime)//if no lastActiveTime then all are unread
					if(isCurrentUser(message['created_by'])){
						isUnread=false
					}
				}
				else{
					const userIsInThread=currentUserIsInThread(message.thread_parent)
					if(userIsInThread){
						let recentActivityItem = find(recentActivities,function(activity){
							return `${activity['item_id']}`==`${message.thread_parent}`
						})
						let lastActiveTime=null
						if(recentActivityItem){
							lastActiveTime=recentActivityItem['activity_time']
						}
						isUnread=message['created_at'] && (!lastActiveTime || message['created_at']>lastActiveTime)//if no lastActiveTime then all are unread
						if(isCurrentUser(message['created_by'])){
							isUnread=false
						}
					}
				}
			if(isUnread){
				unreadCount+=1
			}				
			})
		}
	}

	return unreadCount	
}
