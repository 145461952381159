import store from '../../../../store'
import MiniSearch from 'minisearch'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import moment from 'moment'

//Returns max 1 date suggestion

//array in order
const namedDates=[
	'today',
	'tomorrow',
	'yesterday',
	'next',
	'last'
]

const namedDays=[
	'monday',
	'tuesday',
	'wednesday',
	'thursday',
	'friday',
	'saturday',
	'sunday'
]


function formattedDate(date){
	return date.format("MMMM D, YYYY")
}

function namedToSuggestion(namedDate,terms){
	let suggestion
	if(namedDate=='today' && terms.length==1){
		const date = moment().startOf('day')
		suggestion={
			label:'Today',
			formattedDate:formattedDate(date),
			date:date
		}
	}else if(namedDate=='tomorrow'&& terms.length==1){
		const date = moment().startOf('day').add(1, 'day');
		suggestion={
			label:'Tomorrow',
			formattedDate:formattedDate(date),
			date:date
		}
	}else if(namedDate=='yesterday'&& terms.length==1){
		const date = moment().startOf('day').subtract(1, 'day');
		suggestion={
			label:'Yesterday',
			formattedDate:formattedDate(date),
			date:date
		}
	}else if(namedDate=='next'){
		if(terms.length==1){ //do Next X where X is current day of the week
			const date = moment().startOf('day').add(1,'week')
			const label=`Next ${date.format('dddd')}`
			suggestion={
				label:label,
				formattedDate:formattedDate(date),
				date:date
			}
		}else if(terms.length==2){ 
			let matched
			namedDays.forEach((namedDay)=>{
				if(!matched && namedDay.startsWith(terms[1])){
					matched=namedDay
				}
			})
			if(matched){
				let date = moment().startOf('isoWeek').day(matched);
				if (date.isSameOrBefore(moment())) {
  				date.add(1, 'weeks');
				}
				let label=`Next ${date.format('dddd')}`
				//if it is tomorrow don't show label because "Next Thursday" is weird when its wednesday
				const isTomorrow = date.isSame(moment().add(1, 'day'), 'day');
				if(isTomorrow){
					label=''
				}

				suggestion={
					label:label,
					formattedDate:formattedDate(date),
					date:date
				}
			}
		}	
	}else if(namedDate=='last'){
		if(terms.length==1){ //do Last X where X is current day of the week
			const date = moment().startOf('day').subtract(1,'week')
			const label=`Last ${date.format('dddd')}`
			suggestion={
				label:label,
				formattedDate:formattedDate(date),
				date:date
			}
		}else if(terms.length==2){ 
			let matched
			namedDays.forEach((namedDay)=>{
				if(!matched && namedDay.startsWith(terms[1])){
					matched=namedDay
				}
			})
			if(matched){
				let date = moment().startOf('isoWeek').day(matched);
				if (date.isSameOrAfter(moment())) {
  				date.subtract(1, 'weeks');
				}
				let label=`Last ${date.format('dddd')}`
				//if it is tomorrow don't show label because "last tuesday"  is weird when its wednesday
				const isYesterday= date.isSame(moment().subtract(1, 'day'), 'day');
				if(isYesterday){
					label=''
				}
				suggestion={
					label:label,
					formattedDate:formattedDate(date),
					date:date
				}
			}
		}	
		}
	return suggestion
}

function autocompleteDate(input) {
  // Split the input into month, date, and year
  const [month, date, year] = input.split(/\s+/);
  // If only the month is being typed, match the closest month name
  if (month && !date && !year) {
    const monthName = moment.months().find((m) => m.toLowerCase().startsWith(month.toLowerCase()));
    if (monthName) {
      const currentYear = moment().year();
      //const currentDate = moment().date();
      // const currentDate = moment().date();
      const dateString = `${monthName} ${1} ${currentYear}`;
      const parsedDate = moment(dateString, "MMM D YYYY");
      if (parsedDate.isValid()) {
        return parsedDate;
      }
    }
  }

  // If the month and date are being typed, match the closest date
  if (month && date && !year) {
    const currentYear = moment().year();
    const dateString = `${month} ${date} ${currentYear}`;
    const parsedDate = moment(dateString, "MMM D YYYY");
    if (parsedDate.isValid()) {
      return parsedDate;
    }
  }

  // If all three parts are present, parse the date and return it
  if (month && date && year) {
  	let suggestedYear;
  	const currentYear = moment().year();
  	const currentYearString=currentYear.toString()
  	//console.log(`--------- currentYearString ${currentYearString}`)

  	if(currentYearString.startsWith(year)){
  		//console.log('1111')
  		suggestedYear=currentYearString
  	}else{
	  	//if (year.length <= 2) {// if user input is 1-2 characters long, suggest current year
	    	// console.log('222')
	    	//console.log(currentYearString.substring(0, 2))
	    	if(year.length==1){
	    		suggestedYear = currentYearString.substring(0, 3) + year;
	    	}else if(year.length==2){
	    		suggestedYear = currentYearString.substring(0, 2) + year;
	    	}
	    	else if(year.length==3){
	    		suggestedYear = currentYearString.substring(0, 1) + year;
	    	}
	    	
	   //  	console.log('here-------')
	   //  	console.log(suggestedYear)
	  	// } else if (year.length >= 3 && year.length <= 4) {
	   // 		// // if user input is 3-4 characters long, suggest a year from the current decade
	   //  	// const currentDecade = currentYearString.substring(0, 3);
	   //  	// const currentDecadeStart = parseInt(currentDecade + "0");
	   //  	// const currentDecadeEnd = parseInt(currentDecade + "9");
	   //  	// suggestedYear = parseInt(currentDecade + year);
		  //   // if (suggestedYear < currentDecadeStart || suggestedYear > currentYear) {
	  	    
	  	    
	  	//     suggestedYear = `${year}0`
	  	    
	   //  	//}
	  	// } else {
	  	 //   suggestedYear = currentYear;
	   //	 }

	  	}
	  	if(year.length>6){
	  		//console.log('year to long --> no suggestions')
	  		return null
	  	}else{
	    	//console.log(`year is------- ${year}`)
	    	//console.log(`suggestion is ------ ${suggestedYear}`)
		    const dateString = `${month} ${date} ${suggestedYear}`;
	  	  const parsedDate = moment(dateString, "MMM D YYYY");
	    	if (parsedDate.isValid()) {
	      	return parsedDate;
   	 		}
	  	}
   	
  	}

  // // If the input matches a month name, assume the current year and current date
  // const monthName = moment.months().find((m) => m.toLowerCase() === month.toLowerCase());
  // if (monthName) {
  //   const currentYear = moment().year();
  //   const currentDate = moment().date();
  //   const dateString = `${monthName} ${currentDate} ${currentYear}`;
  //   const parsedDate = moment(dateString, "MMM D YYYY");
  //   if (parsedDate.isValid()) {
  //     return parsedDate;
  //   }
  // }

  // // If the input matches a day of the month, assume the current year and month
  // const dayOfMonth = parseInt(input);
  // if (dayOfMonth && dayOfMonth >= 1 && dayOfMonth <= 31) {
  //   const currentYear = moment().year();
  //   const currentMonth = moment().month();
  //   const dateString = `${moment.months()[currentMonth]} ${dayOfMonth} ${currentYear}`;
  //   const parsedDate = moment(dateString, "MMM D YYYY");
  //   if (parsedDate.isValid()) {
  //     return parsedDate;
  //   }
  // }

  // If no valid date is found, return null
  return null;
}

export function getDateSuggestion(searchTerm){


	let dateSuggestion=null
	let matched=null

	if(searchTerm){
		const term=searchTerm.toLowerCase()
		const terms = searchTerm.match(/\S+/g)
			namedDates.forEach((namedDate)=>{
				if(!matched && namedDate.startsWith(terms[0])){
					matched=namedDate
				}
			})
			if(matched){
				dateSuggestion=namedToSuggestion(matched,terms)
			}else{
				namedDays.forEach((namedDay)=>{
					if(!matched && namedDay.startsWith(term)){
						matched=namedDay
					}
				})
				if(matched){
					let date = moment().startOf('isoWeek').day(matched);
					if (date.isSameOrBefore(moment())) {
  					date.add(1, 'weeks');
					}
					let label=`Next ${date.format('dddd')}`
					//if it is tomorrow don't show label because "Next Thursday" is weird when its wednesday
					const isTomorrow = date.isSame(moment().add(1, 'day'), 'day');
					if(isTomorrow){
						label=''
					}
					dateSuggestion={
						label:label,
						formattedDate:formattedDate(date),
						date:date
					}
			}else{
				const test=autocompleteDate(searchTerm)

				if(test){
					dateSuggestion={
						label:'',
						formattedDate:formattedDate(test),
						date:test
					}
				}
			}





				

			}



			
	}

	// const today = moment();
 //  const dayOfWeek = today.day();
 //  const searchTermLower = searchTerm.toLowerCase();



	return dateSuggestion


}

