import {post,fetch,postCloudinary,put} from '../api'
import {updateImageCloudinaryValues} from '../prosemirror/utils/updateImageCloudinaryValues'

export function uploadDocImage(file,nodeId) {
	const requestBody={
		file:file,
		node_id:nodeId
	}
	return post(`/image`,requestBody)
		.then((response) => {
		//	dispatch({ type: 'FETCH_A_DOC_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}


export function fetchDocImage(nodeId) {
	return (dispatch) => fetch(`/docimage/${nodeId}`)
		.then((response) => {
			const values={
				delivery_url:response.delivery_url,
				height:response.height,
				width:response.width
			}	
			updateImageCloudinaryValues(nodeId,values)
			return response
		})
		.catch((error) => {
			return error
		})
}






export function uploadDocImageToCloudinary(file,nodeId,docId) {
	return (dispatch) => fetch(`/docimage/cloudinary?type=doc_image&public_id=${nodeId}&doc_id=${docId}`)
		.then((response) => {
			let form = new FormData()
			form.append('api_key', response.apiKey)
			form.append('timestamp', response.timestamp)
			form.append('signature',response.signature)
			form.append('file', file)
			form.append('folder', 'doc_images')
			form.append('public_id', nodeId)		
			return dispatch(uploadFileToCloudinary(form))
				.then((uploadResponse) => {
					const requestBody={
						delivery_url:`http://res.cloudinary.com/yarn/image/upload/v1673693315/doc_images/${nodeId}.png`,
						height:uploadResponse.height,
						width:uploadResponse.width
					}	
					updateImageCloudinaryValues(nodeId,requestBody)
					return put(`/docimage/${nodeId}`,requestBody)
					.then(()=>{

					})

			  	//return uploadResponse
			  })
			  .catch((err) => {

				})
			})
	.catch((error) => {

	})		
}


export function uploadFileToCloudinary(form) {
	return (dispatch) => postCloudinary(form)
		.then((uploadResponse) => {

			return uploadResponse
		})
		.catch((error) => {
			// console.log('upload error----------')
			// console.log(error)
		})
}



