import React from 'react'
import { withRouter} from 'react-router-dom'
import DocTable from '../docTable/DocTable'
import Icon from '../misc/Icon'
import LibraryDocPreview from '../library/LibraryDocPreview'
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import {createNewDoc} from '../../utils/createNewDoc'

class RecentPage extends React.Component{  
	
	constructor(){
		super()
		this.state={
			activeDocId:null,
		}
	}


	render(){	
		const {docs}=this.props
	  return (
	  	<div className='recentPage'>
	  		<div className='recentPage-leftSpacer'/>

				<div className='recentPage-center'>	 
					<div className='recentPage-header'>	  			  		
		  			<div className='recentPage-header-title'>	
		  				<div className='recentPage-header-title-iconContainer'>	
								<Icon name='clockLight' />	
							</div>
		  				<div className='recentPage-header-title-label'>	
		  					Recent
		  				</div>
		  			</div> 
		  			<div className='recentPage-header-rightContainer'>	
		  				{/*
		  				<button className='recentPage-header-newDocBtn' onClick={()=>{createNewDoc(this.props.history)}}>
		  					<div className='recentPage-header-newDocBtn-iconContainer'>
		  						<Icon name='plus' />
		  					</div>
		  					<div className='recentPage-header-newDocBtn-label'>
		  						New Private Draft
		  					</div>
	  					</button>
	  				*/}
		  			</div>
		  		</div>		  		  	
		  		<div className='recentPage-content'>	  			  		
		  			<DocTable 
		  				docs={this.props.docs}
		  				libraryView='recent'
		  				setActiveDoc={(docId)=>{this.setState({activeDocId:docId})}}
		  			/>
					</div>
				</div>

				<div className='recentPage-rightPanel'>
					<LibraryDocPreview
						docId={this.state.activeDocId}
					/>

					<div className='libraryPageTipContainer'>						
						<div className='libraryPageTip'>
							<div className='libraryPageTip-avatar'>
								🔬
							</div>
							<div className='libraryPageTip-title'>
								<div className='libraryPageTip-title-label'>
									Preview docs with 
								</div>
								<KeyboardShortcutTag shiftOutlineMedium keyLabel />
							</div>
							<div className='libraryPageTip-description'>
								<div className='libraryPageTip-description-para'>
									Hold Shift and mouse over doc rows to see a preview before jumping in.
								</div>								
							</div>
						</div>
					</div>		


				</div>
	  		
	  	</div>
	  )
	}
}

export default withRouter(RecentPage)
