import findIndex from 'lodash/findIndex'


export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_HIGHLIGHTS_SUCCESS':
		return action.response

	case 'CREATE_HIGHLIGHT_SUCCESS':
	case 'CREATE_HIGHLIGHT_REQUEST':
	case 'UPDATE_HIGHLIGHT_PUSHER':
		let index=findIndex(state,(highlight => {
			return highlight['highlight_id'] === action.response.highlight_id
		}))
		if(index === -1){
			index=state.length
		}
		return [
			...state.slice(0,index),
			action.response,
			...state.slice(index + 1)
			]

	case 'ARCHIVE_HIGHLIGHT_REQUEST':
		let indx = findIndex(state,(highlight => {
			return highlight.highlight_id === action.highlightId
		}))
		if(indx > -1){
			return [
				...state.slice(0,indx),
				...state.slice(indx + 1)
			]
		}else{
			return state
		}


	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
 