import React from 'react'
import Icon from '../misc/Icon'

class WhiteboardMultiplayer extends React.Component{  
 
  constructor(){
    super()
    this.state={
      
    }
  }  

	render(){ 

		
		
		return (
			<div className='whiteboard-multiplayer'>
				<button className='whiteboard-multiplayer-pingBtn'>
					<Icon name='pingDoc' />
				</button>

				<button className='whiteboard-multiplayer-user whiteboard-multiplayer-user--currentUser'>
					<AvatarImage 
						className='whiteboard-multiplayer-user'
					/>
				</button>

				<button className='whiteboard-multiplayer-user'>
					<AvatarImage 
						className='whiteboard-multiplayer-user'
					/>
				</button>
				
			</div>
			
		)
	}
}

export default WhiteboardMultiplayer