const TextLinkNodeSpec ={
    group: "inline",
    inline:true,
    selectable:false,
    attrs: {
    	type:{default:''}, //weblink, doc, project, issue
      docId:{default:''},
      projectId:{default:''},
      issueId:{default:''},
      url:{default:''}
    },
    content: "text*",
    toDOM(node) {
      const {type,docId,projectId,issueId,url} = node.attrs;
      return ["textLink",
          {
            "data-type":type,
            "data-doc-id":docId,
            "data-project-id":projectId,
            "data-issue-id":issueId,
            "data-url":url
        },0
      ]
    },
   //	toDOM: () => ["textLink", 0],
  	// parseDOM: [{tag: "textLink"}]
    parseDOM: [
      {
        tag: "textLink",
        getAttrs: (dom) => ({
          type: dom.getAttribute("data-type"),
          docId: dom.getAttribute("data-doc-id"),
          projectId: dom.getAttribute("data-project-id"),
          issueId: dom.getAttribute("data-issue-id"),
          url: dom.getAttribute("data-url"),
        })}
    ],
}
    
 export default TextLinkNodeSpec
 