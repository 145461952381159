import React from 'react'
import ReactDOM from 'react-dom'
import MessageLinearProjectMentionReactComponent from './MessageLinearProjectMentionReactComponent'


class MessageLinearProjectMentionNodeView {
  
  constructor(node, view, getPos,handleOpenDoc) {
    this.dom = document.createElement("div")
    this.dom.className="message-para-projectLinkOuterContainer"
    const {projectId}=node.attrs
    
    ReactDOM.render(
      <MessageLinearProjectMentionReactComponent 
        projectId={projectId}
      />, this.dom)   
  }
 
  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom)
  }
  
}

export default MessageLinearProjectMentionNodeView