import React from 'react'
import ReactDOM from 'react-dom';
import DocVideoReactComponent from './DocVideoReactComponent'
import {getMessagesForMediaNode} from '../../../utils/getMessagesForMediaNode'


class DocVideoNodeView {

  constructor(node, view, getPos,newNodeMessage,sendMediaModalMessage) {
    this.getNode=this.getNode.bind(this)
    this.deleteVideo=this.deleteVideo.bind(this)
    this.node = node
    this.getPos=getPos
    this.dom = document.createElement("div")
    this.dom.className="doc-videoWrapper"

    this.newNodeMessage=newNodeMessage
    this.sendMediaModalMessage=sendMediaModalMessage
    this.createNewNodeMessage=this.createNewNodeMessage.bind(this)
    this.sendMessage=this.sendMessage.bind(this)
    this.updateTitle=this.updateTitle.bind(this)

    const {
      muxId,
      dimensions,
      title,
      subtitles,
      nodeId,
      messagesVersion
    } = node.attrs
    
    this.dom.id=nodeId
    
    const messages=getMessagesForMediaNode(nodeId)
    ReactDOM.render(
      <DocVideoReactComponent 
        muxId={muxId}
        dimensions={dimensions}
        title={title}
        subtitles={subtitles}
        deleteVideo={this.deleteVideo}
        newMessage={this.createNewNodeMessage}
        sendMediaModalMessage={this.sendMessage}
        messages={messages}
        messagesVersion={messagesVersion}
        nodeId={nodeId}
        updateTitle={this.updateTitle}
      />, this.dom)
    }

  selectNode() {
    this.dom.classList.add("doc-videoWrapper--selected")
  }

  deselectNode() {
    this.dom.classList.remove("doc-videoWrapper--selected")
  }

  updateTitle(title){
    const node=this.getNode()
    const nodePos=this.getPos(node)
    var tr = window.view.state.tr
    tr.setNodeMarkup(nodePos, null, {...node.attrs,title:title})
    window.view.dispatch(tr)
  }

  sendMessage(text,timestampSeconds){
    const {
      muxId,
      dimensions,
      title,
      subtitles
    } = this.node.attrs

    const snapshot={
      muxId:muxId,
      dimensions:dimensions,
      title:title,
      subtitles:subtitles,
      timestampSeconds:timestampSeconds||0.00001
    }

    this.sendMediaModalMessage(this.node.attrs.nodeId,'video',snapshot,text)
  }

  createNewNodeMessage(e){
    e.stopPropagation()
    const {
      muxId,
      dimensions,
      title,
      subtitles
    } = this.node.attrs

    const snapshot={
      muxId:muxId,
      dimensions:dimensions,
      title:title,
      subtitles:subtitles
    }
    this.newNodeMessage(this.node.attrs.nodeId,'video',snapshot)
  }


  getNode(){
    return this.node
  }

 update(node) {
  if (node.type !== this.node.type) return false
   const {
    muxId,
    dimensions,
    title,
    subtitles,
    nodeId,
    messagesVersion
    } = node.attrs
    const messages=getMessagesForMediaNode(nodeId)
   
    ReactDOM.render(
      <DocVideoReactComponent 
        muxId={muxId}
        dimensions={dimensions}
        title={title}
        subtitles={subtitles}
        deleteVideo={this.deleteVideo}
        newMessage={this.createNewNodeMessage}
        sendMediaModalMessage={this.sendMessage}
        messages={messages}
        nodeId={nodeId}
        updateTitle={this.updateTitle}
      />, this.dom)
    this.node=node
    return true
  }

  deleteVideo(e){
    e.stopPropagation()
    e.preventDefault()
    let tr = window.view.state.tr
    const videoPos=this.getPos()
    tr.delete(videoPos,videoPos+this.node.nodeSize)
    window.view.dispatch(tr)
    window.view.focus()
  }

  stopEvent(event){ //prevent typing in video node input from bubbling to editor so that e.g. backspace doesnt delete video
    return true
  }

  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom)
  }

}

export default DocVideoNodeView