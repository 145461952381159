import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { removeFigmaIntegration } from "../../actions/figma"
import throttle from 'lodash/throttle'
import qs from 'qs'
import {randomID} from '../../utils/randomID'
import {getUserForId} from '../../utils/getUserForId'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import {formatDate} from '../../utils/formatDate'


//TODO need to store and check state value


///Component shows integration details or button to auth with figma

//

const OAUTH_URL='https://www.figma.com/oauth'
const SCOPE="file_read"


const FIGMA_CLIENT_ID=process.env.REACT_APP_FIGMA_CLIENT_ID || window.envVars.reactAppFigmaClientId
const REDIRECT_URI=process.env.REACT_APP_FIGMA_REDIRECT_URI || window.envVars.reactAppFigmaRedirectUri
// 


//const REDIRECT_URI='http://localhost:3000/auth/figma/callback'


// const FIGMA_CLIENT_ID='DuV5HnFBtdBJT73xKZOKcn'
// const REDIRECT_URI='http://localhost:3000/auth/figma/callback'

class SettingsFigmaIntegration extends Component {

  figmaAuth(){
    const randID=randomID()
    if(window.isElectron){
      let url = `${OAUTH_URL}?response_type=code&scope=${SCOPE}&client_id=${FIGMA_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${randID}`
      window.ipcRenderer.send('open-oauth-window',{url:url})     
    }
    else{
      let url = `${OAUTH_URL}?response_type=code&scope=${SCOPE}&client_id=${FIGMA_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${randID}`
      window.location= url
    }
  }


  render(){  
    const {figmaIntegration}=this.props
    if(figmaIntegration){
      const createdByUser=getUserForId(figmaIntegration.created_by)
      return(
        <div className='settingsPage-content-section--figma-statusRow'>
          
          <div className='settingsPage-content-section--figma-statusRow-label'> 
            Enabled by {createdByUser?createdByUser.display_name:''} on {formatDate(figmaIntegration.created_at)}
          </div>            
          <button onClick={this.props.removeFigmaIntegration} className='settingsPage-content-section--figma-statusRow-btn'>
            Disconnect Figma
          </button>        
          <div className='settingsPage-content-section--figma-activeStatusTagContainer'>
            <div className='settingsPage-content-section--figma-activeStatusTag'>
              <div className='settingsPage-content-section--figma-activeStatusTag-dot' />
              Connected
            </div>            
          </div>
        </div>
      )
    }else{
      return (                      
        <button className='settingsPage-content-section--figma--connectBtn settingsPage-content-section--figma--connectBtn--connect' onClick={this.figmaAuth}>                      
          <div className='settingsPage-content-section--figma--connectBtn-iconContainer'>
            <Icon name='figmaColor' />
          </div>
          <div className='settingsPage-content-section--figma--connectBtn-label'>
            Connect to Figma
          </div>
        </button>          
      )
    } 
  }
}


function mapStateToProps(state,ownProps) {


  return {
      user:state.user,
      figmaIntegration:state.figmaIntegration
  }
}


export default withRouter(connect(mapStateToProps,
  {removeFigmaIntegration}
)(SettingsFigmaIntegration))  


