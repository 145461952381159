import React from 'react'
import Icon from '../../misc/Icon'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import {getSuggestions,reindexSearch} from '../../../prosemirror/plugins/mentions/utils/getSuggestions'
import {getDocForId} from '../../../utils/getDocForId'
import {getProjectForId} from '../../../utils/getProjectForId'
import {getIssueForId} from '../../../utils/getIssueForId'
import {getUserForId} from '../../../utils/getUserForId'
import EditTextLinkInputPreview from './EditTextLinkInputPreview'
import InsertMentionsMenuItem from '../../../prosemirror/plugins/mentions/InsertMentionsMenuItem'
import {isURL} from '../../../utils/isURL'

class TextLinkEditPopoverReactComponent extends React.Component{  	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)
    this.saveLinkValueChange = this.saveLinkValueChange.bind(this)
		this.saveLinkTitleChange = this.saveLinkTitleChange.bind(this)
		this.handleClickShowEditTitleBtn=this.handleClickShowEditTitleBtn.bind(this)
		this.handleClickInputContainer=this.handleClickInputContainer.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.handleMouseMove=this.handleMouseMove.bind(this)
    this.handleMouseOver=this.handleMouseOver.bind(this)
    this.handleMouseOut=this.handleMouseOut.bind(this)
 		this.selectIndex=this.selectIndex.bind(this)
 		this.onTitleInputKeyDown=this.onTitleInputKeyDown.bind(this)

    this.state={
      forceMount: false,   
      isUrl:false	,
			activeIndex:0	,
			mouseHasMoved:false,
			linkValue: '', 
			linkTitle: props.title,    	
			showEditTitleInput: false, 
			searchResults:[],
			showInputPreview: true,
			showModal:false //only show if arrow left/right   	
    }
    this.timeout=null
		this.linkInputRef = React.createRef(); // added with gtp, used to focus input field
		this.titleInputRef = React.createRef(); // used to focus title field
  }

  onRest() {
		if(!this.props.showPopover){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

	componentDidMount(){
  	reindexSearch()
  	window.addEventListener('keydown', this.handleKeyDown)		
  }
  
  componentWillUnmount(){
  	window.removeEventListener('keydown', this.handleKeyDown)	
  	//check if title has changed and has a value if so update the title
  	if(this.state.linkTitle){
  		this.props.saveUpdatedTitle(this.state.linkTitle)
  	}
  }

  onTitleInputKeyDown(e){
  	if(e.keyCode==13){
  		this.props.closePopover()
  	}
  }

  handleMouseMove(index){
    if(!this.state.mouseHasMoved){
      this.setState({mouseHasMoved:true,activeIndex:index})
    } 
  }

  handleMouseOver(index){
    if(this.state.mouseHasMoved){
      this.setState({activeIndex:index})
    } 
  }


  handleKeyDown(e){
    if(e.keyCode === 40) { //down arrow
    	const {searchResults}=this.state
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex+1
      if(newIndex==searchResults.length){
        newIndex=0
      }
      if(oldIndex==null){
        newIndex=0
      }
      this.setState({activeIndex:newIndex})
    } else if (e.keyCode === 38) { //up arrow
      const {searchResults}=this.state
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex-1
      if(newIndex<0){
        newIndex=searchResults.length-1
      }
      this.setState({activeIndex:newIndex})
    } else if( e.keyCode === 13) { //enter
      e.stopPropagation()
      e.preventDefault()
      this.selectIndex(this.state.activeIndex)
    } else if (e.keyCode === 27) { //escape
      this.closeModal()
    } 
  }


  handleMouseOut(index){
    this.setState({activeIndex:index})
  }

	handleClickInputContainer(e){
  	if(this.state.showInputPreview){ 
			// if input preview is displayed (true by default, clicking clears it and focuses the input field)
			this.setState({showInputPreview: false}, () => {
	  		if(this.linkInputRef.current) {
	    		this.linkInputRef.current.focus();
	  		} 
  		});  		
  	}
  }

  handleClickShowEditTitleBtn(e){  	
  	this.setState({showEditTitleInput: true}, () => {
  		if(this.titleInputRef.current) {
    		this.titleInputRef.current.focus();
  		} 
  	});
  }

  saveLinkValueChange(event) {  
  	let searchResults=[]  
  	const value=event.target.value
  	let stringIsUrl=isURL(value)  
    if(stringIsUrl){
      const suggestion={
        type:'web',
        url:value
      }
      searchResults.push(suggestion)
    }else{
      if(value){
       searchResults=getSuggestions(value).slice(0,6)
      }
    }
    this.setState({
    	linkValue: value,
    	searchResults:searchResults,
    	isUrl:stringIsUrl,
    	mouseHasMoved:false,
    	activeIndex:0
    })    
  }

  selectIndex(index){
  	const item=this.state.searchResults[index]
  	let type
  	let docId
  	let projectId 
  	let issueId 
  	let url
    if(item.type=='web'){
      type='weblink'
      url=item.url
    }else if(item.doc_id){
  		type='doc'
  		docId=item.doc_id
  	}else if(item.project_id){
  		type='project'
  		projectId=item.project_id
  	}else if(item.issue_id){
  		type='issue'
  		issueId=item.issue_id
  	}
  	this.props.saveUpdatedLinkValues(this.state.linkTitle,type,url,docId,projectId,issueId)
  }

  saveLinkTitleChange(event) {    
    this.setState({linkTitle: event.target.value})    
  }

	render(){	
		const {showPopover, openPopover, closePopover, inverted} = this.props		
		const {forceMount} = this.state

		const {title,url,type,projectId,issueId,docId}=this.props
		const {showEditTitleInput,showModal,searchResults, showInputPreview}=this.state

		//const searchMode = true // for adding new links or editing existing
		const hasSearchInput = this.state.linkValue		
	  return (
	  	<React.Fragment>
	  		<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
					<Popover.Content
			    	className={'docTextLinkPopoverContainer ' + (showPopover ? ' docTextLinkPopoverContainer--visible ' : ' docTextLinkPopoverContainer--hidden ')} 
			    	onEscapeKeyDown={closePopover}
				    onPointerDownOutside={closePopover}
				    onInteractOutside={closePopover}			    
				    side="bottom"
				    sideOffset={4}
				    forceMount={showPopover ? true : forceMount}
				    //asChild
			    >
				    <Transition
			        items={showPopover}
			        from={{ opacity: 0, transform: `translateY(5px)` }}
			        enter={{ opacity: 1, transform: 'translateY(0px)'  }}
			        leave={{ opacity: 0, transform: `translateY(5px)`  }}
			        reverse={showPopover}
			        config={{tension: 600, friction: 60}}
			        onRest={this.onRest}
			       >
			        {(styles, item) =>
			          item && 
			           <animated.div style={styles} className={"doc-textLink-popover doc-textLink-popover--editLink " + (hasSearchInput ? ' doc-textLink-popover--editLink--hasSearchInput ' : ' ' )}>
									<div onClick={()=>{this.handleClickInputContainer()}} className='doc-textLink-popover-inputContainer doc-textLink-popover-inputContainer--linkInput'>					
										{showInputPreview && 
											<EditTextLinkInputPreview 														
												linkType = {type}	
												url = {url}
												projectId={projectId}
												docId={docId} 
												issueId={issueId}
											/>
										}					
										{!showInputPreview && 
											<input 						
												ref={this.linkInputRef}
												className='doc-textLink-popover-input'
												placeholder='Type to search or paste a link...'
												value={this.state.linkValue}
												onChange={this.saveLinkValueChange}
												autoComplete="off"
												autoCorrect="off"
												spellCheck={false}									
											/>		
										}					
									</div>

									{showEditTitleInput && !hasSearchInput && 
										<div className='doc-textLink-popover-inputContainer doc-textLink-popover-inputContainer--title'>
											<input 						
												ref={this.titleInputRef}
												className='doc-textLink-popover-input'
												placeholder='Add'
												value={this.state.linkTitle}
												onChange={this.saveLinkTitleChange} 				
												autoComplete="off"
												autoCorrect="off"
												spellCheck={false}
												onKeyDown={this.onTitleInputKeyDown}
												onFocus={()=>{this.setState({showInputPreview: true})}}
											/>
											<div className='doc-textLink-popover-inputUI'>
												<div className='doc-textLink-popover-inputUI-frontIcon'>
													<Icon name='editText' />								
												</div>
											</div>
										</div>
									}

									{!hasSearchInput && 
									<div className='doc-textLink-popover-actionRow'>
										{!showEditTitleInput && 
											<button onClick={this.handleClickShowEditTitleBtn} className='doc-textLink-popover-actionRow-btn'>
												<div className='doc-textLink-popover-actionRow-btn-iconContainer'>
													<Icon name='editText' />
												</div>
												<div className='doc-textLink-popover-actionRow-btn-label'>
													Edit Title
												</div>
											</button>
										}
										<button className='doc-textLink-popover-actionRow-btn' onClick={this.props.removeLink}>
											<div className='doc-textLink-popover-actionRow-btn-iconContainer'>
												<Icon name='trash' />
											</div>
											<div className='doc-textLink-popover-actionRow-btn-label'>
												Remove Link
											</div>
										</button>
									</div>
									}

									{hasSearchInput && 
										<div className='doc-textLink-popover-resultsContainer'>
											{searchResults.map((suggestion,index)=>{
												let suggestionType='doc'
	                      if(suggestion.type=='web'){
	                        suggestionType='web'
	                      }
			            			else if(suggestion.project_id){
			              			suggestionType='project'
			            			}else if(suggestion.issue_id){
			              			suggestionType='issue'
			            			}
												return(
													<InsertMentionsMenuItem 
														key={`suggestion_${index}`}
														suggestion={suggestion}
			              				index={index}
			              				suggestionType={suggestionType}
			              				activeIndex={this.state.activeIndex}
			              				selectIndex={this.selectIndex}
			              				handleMouseMove={this.handleMouseMove}
			              				handleMouseOver={this.handleMouseOver}
			              				hidePopover
													/>
												)
											})
										}
										</div>
									}
									
			          </animated.div>
			        }
			      </Transition> 										
			    </Popover.Content>
			    </Popover.Portal>
			  </Popover.Root>		
			</React.Fragment>
	  )
	}
}

export default TextLinkEditPopoverReactComponent