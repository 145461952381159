import React from 'react'
import Icon from '../../misc/Icon'


class DocVideoInlineUIPreControls extends React.Component{  
	 constructor(){

    super()
    this.state={
      // mouseMoved:false
    }
  }

  
	render(){ 		
		
		return (	
				<React.Fragment>
					<button onClick={this.props.handleZoomAndPlay} className={'doc-video-inlineUI-zoomPlayBtn ' + (this.props.hideControls ? ' doc-video-inlineUI-zoomPlayBtn--hide ' : ' doc-video-inlineUI-zoomPlayBtn--show ')}>
  					<Icon name='playbackZoomPlay' />
  				</button>
				</React.Fragment>					
		)
	}
}

export default DocVideoInlineUIPreControls
