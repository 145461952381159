import React from 'react'
import Icon from '../misc/Icon'
import * as ContextMenu from '@radix-ui/react-context-menu';
import RoadmapsProjectRowEditStatusContextSubMenu from './contextSubMenus/RoadmapsProjectRowEditStatusContextSubMenu'
import RoadmapsProjectRowEditLeadContextSubMenu from './contextSubMenus/RoadmapsProjectRowEditLeadContextSubMenu'
import RoadmapsProjectRowEditTargetDateContextSubMenu from './contextSubMenus/RoadmapsProjectRowEditTargetDateContextSubMenu'
import RoadmapsProjectRowEditRoadmapsContextSubMenu from './contextSubMenus/RoadmapsProjectRowEditRoadmapsContextSubMenu'
import RoadmapsProjectRowEditMembersContextSubMenu from './contextSubMenus/RoadmapsProjectRowEditMembersContextSubMenu'
import RoadmapsProjectRowEditTeamsContextSubMenu from './contextSubMenus/RoadmapsProjectRowEditTeamsContextSubMenu'
import {openProjectInLinear} from '../../utils/openInLinear'



class RoadmapsProjectRowContextMenuContents extends React.Component{  	

	constructor(props) {
		super(props)
		this.handleOpenInLinear=this.handleOpenInLinear.bind(this)		
		this.state={			
			showEditProjectModal: false,
		}
	} 

	handleOpenInLinear(e){
		e.stopPropagation()
		openProjectInLinear(this.props.project.slug_id)
	}

	render(){	
		const {project, activeProject, lastItemInGroup, isMeetingContent}=this.props
		let teamsLabel = 'Team'
		if(project.teams.length > 1){
			teamsLabel = 'Teams'
		}
	  return (
	  	<React.Fragment>
	  		{/*{this.props.isTeamSync &&							
					<ContextMenu.Item onSelect={this.props.toggleMeetingIssuePanel}> 
						Preview Project Issues
					</ContextMenu.Item>					
				}*/}
				
				{!isMeetingContent && 
					<ContextMenu.Item onSelect={(e)=>{this.props.openEditProjectModal(project.project_id,e)}}> {/* open edit project modal {onSelect={()=>{backgroundOpenDoc(doc.doc_id)}} */}
						Edit Project...
					</ContextMenu.Item>
				}
				
			
				<ContextMenu.Item onSelect={this.handleOpenInLinear}> 
					Open in Linear
				</ContextMenu.Item>

				{this.props.unlinkFromMeeting && !this.props.isTeamSync &&
					<React.Fragment>
						<ContextMenu.Separator />	
						<ContextMenu.Item onSelect={this.props.unlinkFromMeeting}> 
							Unlink from meeting
						</ContextMenu.Item>
					</React.Fragment>
				}

				<ContextMenu.Separator />	
					{/* PROJECT STATUS */}
					<ContextMenu.Sub>
						<ContextMenu.SubTrigger>
							Status
							<div className='contextMenu-item-subMenuArrow'>
								<Icon name='chevronRight' />
							</div>
						</ContextMenu.SubTrigger>
						<ContextMenu.Portal>
							<ContextMenu.SubContent collisionPadding={20} className='contextMenu contextMenu-subMenu'>								
								<RoadmapsProjectRowEditStatusContextSubMenu
									projectId={project.project_id}
									currentStatus={project.state}
								/>
							</ContextMenu.SubContent>
						</ContextMenu.Portal>
					</ContextMenu.Sub>
					{/* ROADMAPS – Not sure what should happen if you uncheck for current roadmap... */}
					<ContextMenu.Sub>
						<ContextMenu.SubTrigger>
							Roadmaps
							<div className='contextMenu-item-subMenuArrow'>
								<Icon name='chevronRight' />
							</div>
						</ContextMenu.SubTrigger>
						<ContextMenu.Portal>
							<ContextMenu.SubContent collisionPadding={20} className='contextMenu contextMenu-subMenu'>								
								<RoadmapsProjectRowEditRoadmapsContextSubMenu 
									projectId={project.project_id}				
									updateProjectRoadmaps={this.props.handleUpdateProjectRoadmaps}
		  						projectRoadmaps={this.props.projectRoadmaps}				
								/>
							</ContextMenu.SubContent>
						</ContextMenu.Portal>
					</ContextMenu.Sub>
					<ContextMenu.Separator />	
					{/* PROJECT STATUS */}
					<ContextMenu.Sub>
						<ContextMenu.SubTrigger>
							Project Lead
							<div className='contextMenu-item-subMenuArrow'>
								<Icon name='chevronRight' />
							</div>
						</ContextMenu.SubTrigger>
						<ContextMenu.Portal>
							<ContextMenu.SubContent collisionPadding={20} className='contextMenu contextMenu-subMenu'>								
								<RoadmapsProjectRowEditLeadContextSubMenu 
									projectId={project.project_id}
									leadUserId={project.lead}
								/>
							</ContextMenu.SubContent>
						</ContextMenu.Portal>
					</ContextMenu.Sub>
					{/* PROJECT STATUS */}
					<ContextMenu.Sub>
						<ContextMenu.SubTrigger>
							Members
							<div className='contextMenu-item-subMenuArrow'>
								<Icon name='chevronRight' />
							</div>
						</ContextMenu.SubTrigger>

						<ContextMenu.Portal>
							<ContextMenu.SubContent collisionPadding={20} className='contextMenu contextMenu-subMenu'>								
								<RoadmapsProjectRowEditMembersContextSubMenu 
									project={project.project_id}
									leadUserId={project.lead}
									members={this.props.projectMembers}
									updateProjectMembers={this.props.handleUpdateProjectMembers}
								/>
							</ContextMenu.SubContent>
						</ContextMenu.Portal>
					</ContextMenu.Sub>

					{/* TEAMS STATUS */}
					<ContextMenu.Sub>
						<ContextMenu.SubTrigger>
							{teamsLabel}
							<div className='contextMenu-item-subMenuArrow'>
								<Icon name='chevronRight' />
							</div>
						</ContextMenu.SubTrigger>

						<ContextMenu.Portal>
							<ContextMenu.SubContent collisionPadding={20} className='contextMenu contextMenu-subMenu'>								
								<RoadmapsProjectRowEditTeamsContextSubMenu 
									project={project.project_id}		
									teams={this.props.projectTeams}			
									updateProjectTeams={this.props.handleUpdateProjectTeams}				
								/>
							</ContextMenu.SubContent>
						</ContextMenu.Portal>
					</ContextMenu.Sub>


					

					{/* Not sure we want to do target date in a submenu, let's see in a popover on the row first 
					<ContextMenu.Sub>
						<ContextMenu.SubTrigger>
							Target Date
							<div className='contextMenu-item-subMenuArrow'>
								<Icon name='chevronRight' />
							</div>
						</ContextMenu.SubTrigger>

						<ContextMenu.Portal>
							<ContextMenu.SubContent collisionPadding={20} className='contextMenu contextMenu-subMenu contextMenu-subMenu--targetDate'>
								<RoadmapsProjectRowEditTargetDateContextSubMenu 
									project={project.project_id}									
								/>
							</ContextMenu.SubContent>
						</ContextMenu.Portal>
					</ContextMenu.Sub>

					<ContextMenu.Separator />	
					*/}
					

					
					{/* I THINK LEAVE FOR NOW, REQUIRES A NEW UI
					<ContextMenu.Item className='contextMenu-item--warning'> 
						Delete Project
					</ContextMenu.Item>
					*/}
				
			</React.Fragment>

	  )
	}
}
export default RoadmapsProjectRowContextMenuContents