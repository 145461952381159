
export function toggleToDoCheckedCommand(state,dispatch,view) {
	const {selection, schema,doc} = state
	let {tr} = state
	const {from, to} = selection
	doc.nodesBetween(from, to, (node, pos) => {
		const nodeType = node.type;
		if(nodeType.name === 'toDoItem'){
			const nodeAttrs = {
				...node.attrs,
				isChecked:!node.attrs.isChecked,
			};
			tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
		}
	});
	if (tr.docChanged) {
		dispatch && dispatch(tr);
		return true;
		} else {
		return false;
	}
}

