import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import {createNewDoc,createNewProjectDoc,createNewRoadmapDoc,createNewOrgDoc} from '../utils/createNewDoc'
import {closeTab} from '../utils/closeTab'
import {changeTab} from '../utils/changeTab'
import {jumpToCursor} from '../utils/jumpToCursor'
import {pingCursorToUser} from '../utils/pingCursorToUser'
import {toggleTheme} from '../utils/toggleTheme'
import {getDocForId} from '../utils/getDocForId'
import {getDocIdFromSignature} from '../utils/getDocIdFromSignature'
import {getRootDocIdForProject} from '../utils/getRootDocIdForProject'
import {openProjectInLinear,openRoadmapInLinear} from '../utils/openInLinear'
import {getProjectForId} from '../utils/getProjectForId'
import {getRoadmapForId} from '../utils/getRoadmapForId'
import {openDocSearch,closeDocSearch} from '../utils/docSearch'
import {openDoc} from '../utils/openDoc'
import find from 'lodash/find'
import {createNewMeetingFromTemplate} from '../utils/meetings/createNewMeeting'

class AppShortcuts extends Component {

	constructor(){
		super()
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.closeDoc=this.closeDoc.bind(this)
	}

	componentDidMount() {	
		window.addEventListener('keydown', this.handleKeyDown)
	}
	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	closeDoc(e){
		if(this.props.docId){
			let active=true
			closeTab(e,active,this.props.history,{type:'doc',id:this.props.docId})
		}else if(this.props.roadmap){
			let active=true
			closeTab(e,active,this.props.history,{type:'roadmap',id:this.props.roadmap.roadmap_id})
		}
	}

	handleKeyDown(e){		

		if(e.metaKey && e.shiftKey && e.keyCode==77){//command shift m to make a new meeting
			e.preventDefault()
			e.stopPropagation()

			const template=null
			createNewMeetingFromTemplate(template,this.props.history)
		}



		if(e.metaKey && e.shiftKey && e.keyCode==220){//command shift \ to toggle theme
			toggleTheme()
		}
		if(e.metaKey && e.shiftKey && e.keyCode==84){//command shift t to go back inhistory (only works electron)
			e.preventDefault()
			e.stopPropagation()
			this.props.history.goBack()
		}
		if(window.isElectron){
			if(event.metaKey && event.keyCode==70){ //show doc search command + F
				const pathname=this.props.history.location.pathname
					if (pathname.indexOf('/doc/') > -1){
					event.preventDefault()
					if(this.props.docSearchResults.searchActive){
						//focus search
						let input=document.getElementById(`in-doc-search-input`)
						if(input){
							input.focus()
						}  
					}else{
						//check if recording playback if open and if so don't open indoc search
						const el=document.getElementById(`in-transcript-search-input`)
						if(!el){
							openDocSearch()
						}else{
							el.focus()
						}
					}
				}
			}
			if(this.props.docSearchResults && this.props.docSearchResults.searchActive){
				if(e.keyCode==27){ //escape to close search
					closeDocSearch()
				}
			}



			if(e.metaKey && e.shiftKey && e.keyCode===76){ //command + shift+ L opens in linear
				e.preventDefault()
				e.stopPropagation()
				const pathname=this.props.history.location.pathname
				if (pathname.indexOf('/doc/') > -1){
					let docId
					const signatureComponentsArray=pathname.split(/-/)
					if(signatureComponentsArray.length>1){
						docId=signatureComponentsArray[signatureComponentsArray.length-1]
					}
					const doc=getDocForId(docId)
					if(doc){
						if(doc.project){
							const project=getProjectForId(doc.project)
							if(project && project.slug_id){
								openProjectInLinear(project.slug_id)
							}						
						}else if(doc.roadmap){
							const roadmap=getRoadmapForId(doc.roadmap)
							if(roadmap){
								openRoadmapInLinear(roadmap.slug_id)
							}
						}
					}	
				}else if (pathname.indexOf('/roadmaps/') > -1){
					let roadmap
  				const segments = pathname.split("/")
  				if(segments[2]){
  					roadmap=getRoadmapForId(segments[2])
  				}
  				if(roadmap && roadmap.slug_id){
  					openRoadmapInLinear(roadmap.slug_id)
  				}
				}
			}
			else if(e.metaKey && e.shiftKey && e.keyCode===85){ //command + shift+ U
				e.preventDefault()
				e.stopPropagation()
				const pathname=this.props.history.location.pathname
				if (pathname.indexOf('/doc/') > -1){
					let docId
					const signatureComponentsArray=pathname.split(/-/)
					if(signatureComponentsArray.length>1){
						docId=signatureComponentsArray[signatureComponentsArray.length-1]
					}
					//check if its a project doc and then make it as a child
					const doc=getDocForId(docId)
					if(doc){
						if(doc.is_project_doc){
							const rootDoc= getRootDocIdForProject(doc.project)
							this.props.history.push(`/roadmaps`)
						}else if(doc.project){
							const rootDoc= getRootDocIdForProject(doc.project)
							openDoc(this.props.history,rootDoc)
						}else if(doc.roadmap){
							this.props.history.push(`/roadmaps/${doc.roadmap}`)
						}else if(doc.is_org_doc){
							this.props.history.push('/')
						}else {
							this.props.history.push('/private')
						}
					}	
				}
			}
			else if(e.metaKey && e.shiftKey && e.keyCode===78){ //command + shift+ n -->make a new project sub doc if you are inside project doc
				e.preventDefault()
				e.stopPropagation()
				let project=null
				const pathname=this.props.history.location.pathname
				if (pathname.indexOf('/doc/') > -1){
					let docId
					const signatureComponentsArray=pathname.split(/-/)
					if(signatureComponentsArray.length>1){
						docId=signatureComponentsArray[signatureComponentsArray.length-1]
					}
					const doc=getDocForId(docId)
					if(doc && doc.project){
						project=doc.project
					}
					if(project){
						createNewProjectDoc(this.props.history,project)
					}
				}else if(pathname.indexOf('/roadmaps/') > -1){
			  	const segments = pathname.split("/")
			  	if(segments[2]){
			  		const roadmap=getRoadmapForId(segments[2])
			  		if(roadmap && roadmap.roadmap_id){
			  			createNewRoadmapDoc(this.props.history,segments[2])
			  		}
			  	}
			  }else if(pathname=='/'){
			  	createNewOrgDoc(this.props.history)
			  }
			}else if(e.metaKey && e.keyCode===78){ //command + n
				e.preventDefault()
				e.stopPropagation()
				createNewDoc(this.props.history)
			}
			if(e.metaKey && e.keyCode===87){ //command +w
				e.preventDefault()
				e.stopPropagation()
				this.closeDoc(e)
			}
		}
		else{ //react
			if(e.metaKey && e.keyCode===74){ //command +j
				e.preventDefault()
				e.stopPropagation()
				createNewDoc(this.props.history)
			}
		}


	}

	render() {
		return null
	}
}

function mapStateToProps(state,ownProps) {
	const pathname=ownProps.location.pathname
	const signatureComponentsArray=pathname.split(/-/)
	let docId
	if(signatureComponentsArray.length>1){
		docId=signatureComponentsArray[signatureComponentsArray.length-1]
	}

	//if is/roadmaps/<id> find roadmap
	let roadmap
	if(pathname.indexOf('/roadmaps') > -1){
  	const segments = pathname.split("/")
  	if(segments[2]){
  		roadmap=find(state.roadmaps,{roadmap_id:segments[2]})
  	}
  }

	let currentUserId
	if(state.user){
		currentUserId=state.user['user_id']
	}
	return {
		docId:docId,
		roadmap:roadmap,
		currentUserId:currentUserId,
		docSearchResults:state.docSearchResults
	}
}

export default withRouter(connect(
	mapStateToProps,
	{})(AppShortcuts))

	