import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import AvatarImage from '../../AvatarImage'
import {getUserForId} from '../../../utils/getUserForId'
import {isCurrentUser} from '../../../utils/isCurrentUser'
import seedrandom from 'seedrandom'
import * as Tooltip from '@radix-ui/react-tooltip'
import sortBy from 'lodash/sortBy'
import EditMeetingParticipantListPopover from './EditMeetingParticipantListPopover'
import ViewMeetingParticipantListPopover from './ViewMeetingParticipantListPopover'
import {updateMeetingParticipants} from '../../../utils/meetings/updateMeetingParticipants'
import {getContactForId} from '../../../utils/meetings/getContactForId'


class MeetingParticipantListButton extends React.PureComponent{   
	render(){	
		const {tagOnly, shuffledAvatarListParticipants, onClickFunction, participants, contactParticipants, namesString, participantsIncludesEmail, meeting_id} = this.props
	  return (	  	
  		<React.Fragment>
				{shuffledAvatarListParticipants.map((participant,i) => {				      
		      if(participant.user_id){
						const userObj = getUserForId(participant.user_id)
						let avatarUrl
						if(userObj){
							avatarUrl = userObj.avatar
						}
						return (
	            <div key={`${meeting_id}_preview_participant_${i}`} className='meetingParticipantListBtn-avatarContainer'>
	            	<AvatarImage className='meetingParticipantListBtn-avatar' avatarUrl={avatarUrl} />
	            </div>
	          )
					}if(participant.contact_id){
						const contact=getContactForId(participant.contact_id)
							return(
							 <div key={`${meeting_id}_preview_participant_${i}`} className='meetingParticipantListBtn-avatarContainer'>
	            	<AvatarImage 
	            		className='meetingParticipantListBtn-avatar'
	            		contactId={participant.contact_id}
	            		sideLength={20}	            		
	            	/>
	            </div>
	            )
	          }else{ // precontact
	          	return(
							 <div key={`${meeting_id}_preview_participant_${i}`} className='meetingParticipantListBtn-avatarContainer'>
	            	<AvatarImage 
	            		className='meetingParticipantListBtn-avatar'	            		
	            		sideLength={20}
	            		preContactEmail={participant.email}
	            	/>
	            </div>
	            )
	          }
	        }
        )}
        <div className='meetingParticipantListBtn-count'>
        	{participants.length + contactParticipants.length} 	
        </div>
      	
        <div className={'meetingParticipantListBtn-tooltipContainer ' + (participantsIncludesEmail ? 'meetingParticipantListBtn-tooltipContainer--containsEmail' : '')}>
        	<div className='tooltip tooltip--meetingParticipantListBtn'>
        		{namesString}
        	</div>
        </div>
			</React.Fragment>
	  )
	}
}



class MeetingParticipantList extends React.PureComponent{  

	constructor(props){
    super(props)   
    this.updateMeetingParticipants=this.updateMeetingParticipants.bind(this)  
    this.onCloseEditPopover=this.onCloseEditPopover.bind(this)   
    this.state={
      showEditParticipantsPopover: false,
      showViewParticipantsPopover: false,
      participants:props.internalParticipants || props.meeting.participants,
      contactParticipants:props.externalParticipants || props.meeting.external_participants,
      newParticipants:null,
      newContactParticipants:null
    }
  }

  updateMeetingParticipants(participantsArray,contactParticipantsArray){
  	this.setState({newParticipants:participantsArray,newContactParticipants:contactParticipantsArray})
  }

  onCloseEditPopover(){
  	this.setState({showEditParticipantsPopover: false})
  	if(this.state.newParticipants || this.state.newContactParticipants){
  		updateMeetingParticipants(this.props.meeting.meeting_id,this.state.newParticipants,this.state.newContactParticipants)
  		this.setState({participants:this.state.newParticipants,contactParticipants:this.state.newContactParticipants})
  	}
  }

   shuffleArray = (array, seed) => {
    const rng = seedrandom(seed);
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(rng() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

	render(){	
		const {meeting,readOnly,showDropdown,event,forceDarkTheme} = this.props
		let meeting_id
		if(meeting){
			meeting_id=meeting.meeting_id
		}else{
			if(event){ //hacky- can refactor for when not a meeting (GCal event)
				meeting_id=event.id 
			}
		}
		const participants=this.state.newParticipants || this.props.internalParticipants || meeting.participants
		const contactParticipants = this.state.newContactParticipants || this.props.externalParticipants || meeting.external_participants
		const nonContactParticipants = this.props.nonContactParticipants || []

		let currentUserIsParticipant=false 
		let currentUser
		let otherParticipants=[]
		participants.forEach((participant)=>{
			if(isCurrentUser(participant)){
				currentUserIsParticipant=true
				currentUser=participant
			}else{
				otherParticipants.push({user_id:participant})
			}
		})
     // Put current user first, if they are a participant, and slice array to get top 3 participants
    let previewParticipants=[]
    if(currentUserIsParticipant){
    	previewParticipants.push({user_id:currentUser})
    }
    previewParticipants=previewParticipants.concat(otherParticipants)
    
    contactParticipants.forEach((contact)=>{
			previewParticipants.push({contact_id:contact})
		})

    nonContactParticipants.forEach((email)=>{
			previewParticipants.push({email:email})
		})

    let avatarListParticipants = otherParticipants
   
    contactParticipants.forEach((contact)=>{
			avatarListParticipants.push({contact_id:contact})
		})

    nonContactParticipants.forEach((email)=>{
			avatarListParticipants.push({email:email})
		})


    // Randomize order of other participants
    const shuffledParticipants = this.shuffleArray(avatarListParticipants, meeting_id)
    const currentShuffledUser = avatarListParticipants.find(isCurrentUser)  // Assuming isCurrentUser accepts a userId and returns a boolean
      // Put current user first, if they are a participant, and slice array to get top 3 participants
    const shuffledAvatarListParticipants = currentUserIsParticipant 
      ? [{user_id: currentUser}, ...shuffledParticipants].slice(0, 3)
      : shuffledParticipants.slice(0, 3)

      // console.log(previewParticipants)

    const tooltipParticipants = sortBy(previewParticipants, function(participant){
     	if(participant.user_id){
     		if(!isCurrentUser(participant.user_id)){
     			const userObj=getUserForId(participant.user_id)
     			if(userObj && userObj.display_name){
     				return userObj.display_name.toUpperCase()
     			}
     		}
     	}else if(participant.contact_id){
     		const contact=getContactForId(participant.contact_id)
     		if(contact && contact.name){
     			return contact.name.toUpperCase()
     		}
     		if(contact && !contact.name && contact.email){
     			return contact.email.toUpperCase()
     		}
     	}
     	else{
     		return participant.email
     	}
   	})
    // Create participants' names string for the tooltip
    const names = tooltipParticipants.map(participant => {
      const userObj = getUserForId(participant.user_id)
      if(participant.user_id){
      	if(isCurrentUser(participant.user_id)){
      		return "You"
      	}
     		else {
     			return userObj.display_name
     		}
     	}else if(participant.contact_id){
     		const contact=getContactForId(participant.contact_id)
     		if(contact && contact.name)
     			return contact.name
     		if(contact && !contact.name && contact.email)
     			return contact.email
     	}
     	else {
     		return participant.email
     	}
    })

    let namesString = ""
    if (names.length > 1) {
      const last = names.pop()
      namesString = names.join(", ") + " and " + last
    } else {
      namesString = names[0]
    }
    const participantsIncludesEmail = namesString && namesString.includes("@") // we make the tooltip wide if this is true 
	  return (
	  	<React.Fragment>
			{!readOnly && showDropdown &&
				<EditMeetingParticipantListPopover																
					showPopover={this.state.showEditParticipantsPopover}
					openPopover={()=>{this.setState({showEditParticipantsPopover: true})}}
					closePopover={this.onCloseEditPopover}				
					participants={this.state.newParticipants || this.state.participants}
					contactParticipants={this.state.newContactParticipants || this.state.contactParticipants}
					updateMeetingParticipants={this.updateMeetingParticipants}					
					forceDarkTheme={forceDarkTheme}
				>
					<div onClick={()=>{this.setState({showEditParticipantsPopover: true})}} className='meetingParticipantListBtn meetingParticipantListBtn--edit'>
						<MeetingParticipantListButton 							
							shuffledAvatarListParticipants={shuffledAvatarListParticipants}
							participants={participants}
							contactParticipants={contactParticipants}
							namesString={namesString}
							participantsIncludesEmail={participantsIncludesEmail}
							meeting_id={meeting_id}
						/>	
					</div>				

				</EditMeetingParticipantListPopover>
			}

			{readOnly && showDropdown &&
				<ViewMeetingParticipantListPopover																
					showPopover={this.state.showViewParticipantsPopover}
					openPopover={()=>{this.setState({showViewParticipantsPopover: true})}}
					closePopover={()=>{this.setState({showViewParticipantsPopover: false})}}
					participants={this.state.newParticipants||this.state.participants}
					contactParticipants={this.state.newContactParticipants || this.state.contactParticipants}									
					forceDarkTheme={forceDarkTheme}
				>
					<div onClick={()=>{this.setState({showViewParticipantsPopover: true})}} className='meetingParticipantListBtn meetingParticipantListBtn--view'>
						<MeetingParticipantListButton 						
							shuffledAvatarListParticipants={shuffledAvatarListParticipants}
							participants={participants}
							contactParticipants={contactParticipants}
							namesString={namesString}
							participantsIncludesEmail={participantsIncludesEmail}
							meeting_id={meeting_id}
						/>
					</div>

				</ViewMeetingParticipantListPopover>
			}


			{!showDropdown &&
				<div className='meetingParticipantListBtn meetingParticipantListBtn--tagOnly'>
					<MeetingParticipantListButton 
						tagOnly
						shuffledAvatarListParticipants={shuffledAvatarListParticipants}
						participants={participants}
						contactParticipants={contactParticipants}
						namesString={namesString}
						participantsIncludesEmail={participantsIncludesEmail}					
						meeting_id={meeting_id}					
					/>
				</div>
			}

			</React.Fragment>
	  )
	}
}

export default MeetingParticipantList
