import findIndex from 'lodash/findIndex'

//only save id and isValid so don't need to map through all changes!
export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_MESSAGE_SELECTIONS':
		const selections=action.messageSelections
		let newState=[]
		selections.forEach((selection)=>{
			const obj={id:selection.id,isValid:selection.isValid}
			newState.push(obj)
		})
		 return newState

	case 'MESSAGE_SELECTION_INVALID':
		const selectionId=action.selectionId
		let index=findIndex(state,(selection => {
			return selection.id === selectionId
		}))
		if(index>-1){
			return [
				...state.slice(0,index),
				{id:selectionId,isValid:false},
				...state.slice(index + 1)
			]
		}
		else return state

	case 'NEW_MESSAGE_SELECTION':
		const selectionObj=action.selectionObj
		const obj={id:selectionObj.id,isValid:selectionObj.isValid}
		let testIndex=findIndex(state,(selection => {
			return selection.id === selectionObj.id
		}))
		if(testIndex==-1){
			return [...state,obj]
		}
		else return state

	case 'LOGOUT':
		return []
	
	default:
		return state
	
	}
}
