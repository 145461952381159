import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../misc/Icon'
import LibraryDocPreview from '../library/LibraryDocPreview'
import * as Tooltip from '@radix-ui/react-tooltip';
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import RoadmapsProjectRow from './RoadmapsProjectRow'
import RoadmapPageHeader from './RoadmapPageHeader'
import DocTable from '../docTable/DocTable'
import {createNewRoadmapDoc} from '../../utils/createNewDoc'
import EditProjectModal from '../editProjectModal/EditProjectModal'
import {getMilestonesForProject} from '../../utils/getMilestonesForProject'


function hasStartedWithMilestones(projects){
	let hasStartedProjectWithMilestones=false
	projects.forEach((project)=>{
		if(project.state=='started'){
			const milestones=getMilestonesForProject(project.project_id)
			if(milestones.length>0){
				hasStartedProjectWithMilestones=true
			}
		}
	})
	return hasStartedProjectWithMilestones
}

class RoadmapPage extends React.Component{  
	
	constructor(){
		super()
		this.openEditProjectModal=this.openEditProjectModal.bind(this)
		this.closeModal=this.closeModal.bind(this)
		this.state={
			activeDocId:null,
			showEditProjectModal: false,
			editProjectId:null
		}
		this.timeout=null
	}

	componentWillUnmount(){
	 	if(this.timeout){
 			clearTimeout(this.timeout)
 		}
	}

	openEditProjectModal(projectId,e){
		this.timeout= setTimeout(() => { //had to add a small delay otherwise modal closes dunno why
 			this.setState({showEditProjectModal:true,editProjectId:projectId})
  	 }, 1);
	}


	closeModal(){
		this.setState({showEditProjectModal: false,editProjectId:null})
	}

	render(){	
		const {roadmap, projects, docs,completedProjectsCount,showAllCompleted,hiddenProjectsCount}=this.props
		const countCompletedProjects = completedProjectsCount // this is count of all completed projects in org		
		const countHiddenProjects = hiddenProjectsCount // completed projects before cutoff
		const showCompletedToggle = countHiddenProjects > 0 // only worth showing if we're hiding something

		let roadmapToggleLabel

		if(showAllCompleted && countHiddenProjects > 1){
			roadmapToggleLabel = `Hide ${countHiddenProjects} completed projects`
		}
		else if(showAllCompleted && countHiddenProjects == 1){
			roadmapToggleLabel = 'Hide one completed project'
		}
		else if(!showAllCompleted && countCompletedProjects > 1){
			roadmapToggleLabel = `Show all ${countCompletedProjects} completed projects`
		}
		else if(!showAllCompleted && countHiddenProjects == 1){
			roadmapToggleLabel = 'Show one completed project'
		}

		const hasStartedProjectsWithMilestones = hasStartedWithMilestones(projects)


	  return (
			<div className='roadmapPage'>
				<EditProjectModal 
					showModal={this.state.showEditProjectModal}
					openModal={()=>{this.setState({showEditProjectModal: true})}}
					closeModal={this.closeModal}					
					newProject={this.state.editProjectId?false:true}
					projectId={this.state.editProjectId}
					newProjectRoadmapId={roadmap.roadmap_id}
				/>


				<RoadmapPageHeader 
	  			roadmap={roadmap}
	  			roadmapImageUploads={this.props.roadmapImageUploads}
	  		/>
	  		<div className='roadmapPage-contentContainer'>
					<div className='roadmapPage-leftSpacer'/>
					<div className='roadmapPage-center'>	 
			  		<div className='roadmapPage-projectList'>	  			
			  			{/*}
			  			<div className='roadmapPage-docList-header'>
			  				<div className='roadmapPage-docList-header-label'>
			  					Projects
			  				</div>
			  				<div className='roadmapPage-docList-header-rightContainer'>
			  				</div>
			  			</div>
			  			*/}
			  			<div className='roadmapPage-projectList-list'>			  			
			  				{projects.map((project, i)=>{	  						
		  						let lastItemInGroup=false		  						
									if(projects.length == (i+1)){
											 lastItemInGroup=true
										}else if(project.state!=projects[i+1].state){
											lastItemInGroup=true
										}
									if(projects.length < 4){
										lastItemInGroup = false
									}
									return(
										<RoadmapsProjectRow										
											key={project.project_id}
											lastItemInGroup={lastItemInGroup}
											project={project}
											currentRoadmapId={roadmap.roadmap_id}
											openEditProjectModal={this.openEditProjectModal}
											hasStartedProjectsWithMilestones={hasStartedProjectsWithMilestones}
											//projectDocs={projectDocs} // not sure we still need this, maybe in dropdown or something
										/>
										)
								})}

			  				<div className='roadmapPage-projectList-list-footerRow'>
									<button onClick={()=>{this.setState({showEditProjectModal: true,editProjectId:null})}} className='roadmapPage-projectList-list-newProjectBtn'>
										<div className='roadmapPage-projectList-list-newProjectBtn-iconContainer'>
											<Icon name='plus' />
										</div>
										<div className='roadmapPage-projectList-list-newProjectBtn-label'>
				  						New Project										
										</div>
				  				</button>
									{showCompletedToggle &&
										<button className='roadmapPage-projectList-list-completedToggle' onClick={this.props.updateShowAllCompleted}>
											 {roadmapToggleLabel}
										</button>
									}
								</div>
							</div>
			  		</div>
			  		{docs && docs.length === 0 &&
			  			<div className='roadmapPage-docList roadmapPage-docList--empty'>
			  				<div className='roadmapPage-docList--empty-box'>
			  					<div className='roadmapPage-docList--empty-box-emojiContainer'>
			  						🗺️
			  					</div>
			  					<div className='roadmapPage-docList--empty-box-title'>
			  						Add the first doc to {roadmap.name}
			  					</div>
			  					<div className='roadmapPage-docList--empty-box-description'>
			  						Roadmap docs are great for drafting project plans, release notes, feature ideas, or meeting notes.
			  					</div>			  					
		  						<button className='roadmapPage-docList--empty-box-newDocBtn' onClick={()=>{createNewRoadmapDoc(this.props.history,roadmap.roadmap_id)}}>
				  					<div className='roadmapPage-docList--empty-box-newDocBtn-iconContainer'>
				  						<Icon name='plus' />
				  					</div>
				  					<div className='roadmapPage-docList--empty-box-newDocBtn-label'>
				  						New Roadmap Doc
				  					</div>
			  					</button>
			  				</div>
			  			</div>
			  		}
			  		{docs && docs.length !== 0 &&
				  		<div className='roadmapPage-docList'>			  			
				  			<div className='roadmapPage-docList-header'>
				  				<div className='roadmapPage-docList-header-label'>
				  					Docs
				  				</div>
				  				<div className='roadmapPage-docList-header-rightContainer'>
				  					<Tooltip.Provider>
											<Tooltip.Root delayDuration={300}>
												<Tooltip.Trigger asChild>
													<button className='roadmapPage-docList-header-newDocBtn' onClick={()=>{createNewRoadmapDoc(this.props.history,roadmap.roadmap_id)}}>
								  					<div className='roadmapPage-docList-header-newDocBtn-iconContainer'>
								  						<Icon name='plus' />
								  					</div>
								  					<div className='roadmapPage-docList-header-newDocBtn-label'>
								  						New Roadmap Doc
								  					</div>
							  					</button>
												</Tooltip.Trigger>				
												<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
													<KeyboardShortcutTag
														shift
														command
														letter='N'
														keyboardShortcutOnly
													/>									
													</Tooltip.Content>													
											</Tooltip.Root>
										</Tooltip.Provider>	  
				  				</div>
				  			</div>
								<div className='roadmapPage-docList-list'>
				  				<DocTable 
			  						docs={docs}
			  						libraryView='roadmap'
			  						setActiveDoc={(docId)=>{this.setState({activeDocId:docId})}}
			  					/>	
				  			</div>
				  		</div> 	
			  		}				  		
					</div>

					<div className='roadmapPage-rightPanel'>
						<LibraryDocPreview
							docId={this.state.activeDocId}
						/>
					</div>
				</div>
	  	</div>
	  )
	}
}

export default withRouter(RoadmapPage)
