import store from '../../store'
import filter from 'lodash/filter'

//check if the current user is in the thread (wrote the parent message or a reply)
export function currentUserIsInThread(threadParent) {	
	const state = store.getState()
	const user=state.user

	let currentUserIsInThread=false
	if(user){
		const userId=user.user_id
		state.messages.forEach((message)=>{
			if(message.created_by==userId){
				if(message.message_id==threadParent || message.thread_parent==threadParent){
					currentUserIsInThread=true
				}
			}
		})
	}


	return currentUserIsInThread
}

