import store from '../store'
import find from 'lodash/find'



export function getRoadmapForId(roadmapId) {	
	let roadmap={}
	const state = store.getState()
	const reduxTeam= find(state.roadmaps, {'roadmap_id':roadmapId})
	if(reduxTeam){
		roadmap=reduxTeam
	}
	return roadmap
}

