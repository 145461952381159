import React from 'react'
import Icon from '../misc/Icon'
import { Transition, animated, config, Spring } from 'react-spring'

class OnboardingModalWorkspaceReady extends React.Component {  	
  
  constructor(props) {
    super(props);  
    this.handleGoToWorkSpace=this.handleGoToWorkSpace.bind(this)  
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.state = {
      
    }
  }

    componentDidMount() {
    if(this.props.stageVisible){
      window.addEventListener('keydown', this.handleKeyDown)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.stageVisible && this.props.stageVisible) {
      // component just became visible
      window.addEventListener('keydown', this.handleKeyDown)
    } else if (prevProps.stageVisible && !this.props.stageVisible) {
      // component just became invisible
      window.removeEventListener('keydown', this.handleKeyDown)
    }
  }


  // componentDidMount() {
  //   window.addEventListener('keydown', this.handleKeyDown)    
  // }
  
  // componentWillUnmount() {
  //   window.removeEventListener('keydown', this.handleKeyDown)
  // }



  handleKeyDown(e){
    if(e.keyCode==13){ //enter
      this.handleGoToWorkSpace()
    }
  }

  handleGoToWorkSpace(){ //todo naviagate to project or getting started doc or whatever
    this.props.closeModal()
  }

  render() {		    

    const {stageVisible} = this.props

    const deviceTransformFrom = 'translateX(0px) translateY(0px) scale(0.9)'
    const deviceTransformEnter = 'translateX(0px) translateY(0px) scale(1)'
    const deviceTransformLeave = 'translateX(0px)  translateY(0px) scale(0.9)'

    return (

      <React.Fragment>
        <Transition
          items={stageVisible}
          from={{ opacity: 0, transform: `${deviceTransformFrom}` }}
          enter={{ opacity: 1, transform: `${deviceTransformEnter}`  }}
          leave={{ opacity: 0, transform: `${deviceTransformLeave}`  }}
          reverse={stageVisible}
          delay={stageVisible ? 200 : 0}          
          config={{
            tension: 170, 
            friction: 26,
          }}
         >
          {(styles, item) =>
            item && 
             <animated.div style={styles} className='onboardingModal-stage-contentContainer onboardingModal-stage-contentContainer--centerAlign'>      
              <div className='onboardingModal-stage--workspaceReady-halfVSpacer' />          
                <div className='onboardingModal-stage--workspaceReady-checkBadgeContainer'>
                  <div className='onboardingModal-stage--workspaceReady-checkBadge'>
                    <Icon name='toDoItemCheckmark' />
                  </div>
                </div>          

                <div className='onboardingModal-stage-h1'>
                  Your workspace is ready
                </div>
                <div className='onboardingModal-stage-h2'>
                  All projects, roadmaps, and teams synced. 
                </div>
                <div className='onboardingModal-stage-h2 onboardingModal-stage-h2--light'>
                  Syncing your issue data in progress.
                </div>

                <div className='onboardingModal-stage--workspaceReady-workspaceBtnContainer'>
                  <button onClick={this.handleGoToWorkSpace} className='onboardingModal-stage--workspaceReady-workspaceBtn'>
                    <div className='onboardingModal-stage--workspaceReady-workspaceBtn-primaryLabel'>
                      Go to your workspace
                    </div>
                    <div className='onboardingModal-stage--workspaceReady-workspaceBtn-sub'>                
                      <div className='onboardingModal-stage--workspaceReady-workspaceBtn-sub-label'>
                        Enter
                      </div>
                      <div className='onboardingModal-stage--workspaceReady-workspaceBtn-sub-iconContainer'>
                        <Icon name='enterKey' />
                      </div>                
                    </div>
                  </button>
                </div>

                <div className='onboardingModal-stage--workspaceReady-halfVSpacer' />
            </animated.div>
          }
        </Transition>       
      </React.Fragment>
      
    )
  }
}

export default OnboardingModalWorkspaceReady