import React from 'react'
import {openWhiteboardModal} from '../../../utils/whiteboards/openWhiteboardModal'
import DocImageTopRightMenu from '../docMediaUIComponents/DocImageTopRightMenu'
import DocWhiteboardReadOnlyTLDraw from './DocWhiteboardReadOnlyTLDraw'
import {getTheme} from '../../../utils/getTheme'

class DocWhiteboardEmbedReactComponent extends React.Component{  

	constructor(){
		super()
		this.handleWhiteboardReady=this.handleWhiteboardReady.bind(this)
		this.state={
			loading:true
		}
	}

  handleWhiteboardReady(){
  	this.setState({loading:false})
  }


	render(){ 
		const {embedId}=this.props
		const {loading} = this.state
		const isPrivateDoc = true
		const theme=getTheme()
		return(
			<div className='doc-whiteboardPreviewContainer' onClick={()=>{openWhiteboardModal(false,embedId)}}>
				<div className='doc-whiteboardPreview'>
					<DocImageTopRightMenu 
						//newMessage={this.props.newMessage}							
						deleteImage={this.props.deleteWhiteboardEmbed}
						isPrivateDoc={isPrivateDoc}
					/>					
				<div className='doc-whiteboardPreview-preview' >
				{loading &&
						<div style={{'zIndex':9999}} className='doc-whiteboardPreview-preview-loading' />
				}
					<DocWhiteboardReadOnlyTLDraw 
						theme={theme}
						handleWhiteboardReady={this.handleWhiteboardReady} 
						embedId={embedId}/>
				</div>
			</div>
			<div className='doc-whiteboardPreview--selectionBG'/>
			<div className='doc-whiteboardPreview--hoverBG'/>
			</div>
		)
	}
}

export default DocWhiteboardEmbedReactComponent

		{/*		<div className='doc-whiteboardPreview-preview' dangerouslySetInnerHTML={{__html: svg_string}} />*/}