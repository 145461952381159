import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

//s nodes to look like selected if node is contained in doc selection
export function selectedNodeStylingPlugin() {
	return new Plugin({
		props: {
			decorations: ({ doc, plugins, selection }) => {
				let {empty,$from, $to} = selection
				let decorations=[]
				if(!empty){
					doc.descendants((node, pos) => {
						if (node.type.name=='docWhiteboardEmbed'||node.type.name=='docCodeMirror' ||node.type.name==='internalLink' ||node.type.name==='linearIssueMention'  ||node.type.name==='linearProjectMention'||node.type.name==='docDate' ) {
							if((pos>$from.pos || pos===$from.pos) && (pos+node.nodeSize<$to.pos || pos+node.nodeSize===$to.pos )){
								const decoration = Decoration.node(pos, pos + node.nodeSize, {
									class: 'ProseMirror-nodeInSelection'
								})
								decorations.push(decoration)
							}
						}
					})
				}
			return DecorationSet.create(doc, decorations)
		}
	}
	})
}
