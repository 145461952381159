import React from 'react'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'

class CommandMenuRow extends React.Component{  

  render(){ 

    const {rowType, action, userName, active} = this.props
    
    let actionLabel
    let actionIcon

    // ACTION OPTIONS
    
    //action="pingCursor"
		//action="jumpToCursor"
		//action="sendLinkViaSlack"
		//action="newDiscussion"
    //action="directMessage"


    if(action && action === 'pingCursor'){
    	actionLabel = 'Ping doc'
    	actionIcon = 'broadcast'
    }

    if(action && action === 'jumpToCursor'){
    	actionLabel = 'Jump to cursor'
    	actionIcon = 'jump'
    }

    if(action && action === 'sendLinkViaSlack'){
    	actionLabel = 'Send link via Slack'
    	actionIcon = 'slackIcon'
    }

    if(action && action === 'roundRobin'){
    	actionLabel = 'Round robin'
    	actionIcon = 'reply'
    }

    if(action && action === 'directMessage'){
      actionLabel = 'Direct message'
      actionIcon = 'messageOutlineMedium'
    }


    return (      
      <React.Fragment>

        {rowType === 'generalAction' &&
          <div className={'commandMenu-row commandMenu-row--generalAction ' + (active ? ' commandMenu-row--activeStatus--active ' : ' commandMenu-row--activeStatus--inactive ') + (action ? ` commandMenu-row--userAction--actionType--${action} ` : '')}>
            <div className='commandMenu-row-visual commandMenu-row-visual--actionIcon'>
              <Icon name={actionIcon} />
            </div>
            <div className='commandMenu-row-label'>
              <div className='commandMenu-row-label-primary'>
                {actionLabel}
              </div>
              
            </div> 
            {active && 
              <div className='commandMenu-row-activeRightHint'>
                <div className='commandMenu-row-activeRightHint-label'>
                  {this.props.secondaryLabel}
                </div>
                <div className='commandMenu-row-activeRightHint-iconContainer'>
                  <Icon name='forwardArrow' />
                </div>
              </div>
            }
            {!active && 
              <div className='commandMenu-row-activeRightHint'>
                {action && action === 'jumpToCursor' &&
                <div className='commandMenu-row-activeRightHint-keyboardShortcut'>
                  <Icon name='keyShift'/>
                  <Icon name='keyCommand'/>
                  <div className='commandMenu-row-activeRightHint-keyboardShortcut-letter'>P</div>
                </div>                
                }
                {action && action === 'pingCursor' &&
                <div className='commandMenu-row-activeRightHint-keyboardShortcut'>
                  <Icon name='keyCommand'/>
                  <div className='commandMenu-row-activeRightHint-keyboardShortcut-letter'>P</div>
                </div>                
                }
              </div>
            }
          </div>
        }

        {rowType === 'generalUser' && this.props.user &&
          <div className={'commandMenu-row commandMenu-row--generalUser ' + (active ? ' commandMenu-row--activeStatus--active ' : ' commandMenu-row--activeStatus--inactive ') + (action ? ` commandMenu-row--userAction--actionType--${action} ` : '')}>
            <div className='commandMenu-row-visual commandMenu-row-visual--avatar'>
              <AvatarImage 
                avatarUrl={this.props.user.avatar}
                className='commandMenu-row-visual--avatar-avatar'
              />         
            </div>   
            <div className='commandMenu-row-label'>
              <div className='commandMenu-row-label-primary'>
                {this.props.user.display_name}
              </div>              
            </div> 
            {active && 
              <div className='commandMenu-row-activeRightHint'>
                <div className='commandMenu-row-activeRightHint-iconContainer'>
                  <Icon name='forwardArrow' />
                </div>
              </div>
            }
          </div>
        }

        {rowType === 'userMatchAction' &&
          <div className={'commandMenu-row commandMenu-row--generalAction ' + (this.props.activated ? ' commandMenu-row--activeStatus--activated ' : '  ') + (active ? ' commandMenu-row--activeStatus--active ' : ' commandMenu-row--activeStatus--inactive ') + (action ? ` commandMenu-row--userAction--actionType--${action} ` : '')}>
            <div className='commandMenu-row-visual commandMenu-row-visual--actionIcon'>
              <Icon name={actionIcon} />
            </div>
            <div className='commandMenu-row-label'>
              <div className='commandMenu-row-label-primary'>
                {actionLabel}
              </div>              
            </div> 
            {active && !this.props.activated &&
              <div className='commandMenu-row-activeRightHint'>                
                <div className='commandMenu-row-activeRightHint-iconContainer'>
                  <Icon name='forwardArrow' />
                </div>
              </div>
            }            
          </div>
        }


      	{rowType === 'userAction' &&
      		<div className={'commandMenu-row commandMenu-row--userAction ' + (active ? ' commandMenu-row--activeStatus--active ' : ' commandMenu-row--activeStatus--inactive ') + (action ? ` commandMenu-row--userAction--actionType--${action} ` : '')}>
      			<div className='commandMenu-row-visual commandMenu-row-visual--actionIcon'>
      				<Icon name={actionIcon} />
      			</div>
      			<div className='commandMenu-row-label'>
      				<div className='commandMenu-row-label-primary'>
      					{userName}
      				</div>
      				<div className='commandMenu-row-label-secondary'>
      					{actionLabel}
      				</div>
      			</div> 
      		</div>
      	}


      </React.Fragment>
    )
  }
}

export default CommandMenuRow
