import store from '../store'
import find from 'lodash/find'



export function getFigmaEmbedForId(embedId) {	
	const state = store.getState()
	const embed= find(state.figmaEmbeds, {'embed_id':`${embedId}`})
	return embed
}

