import React from 'react'
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Tooltip from '@radix-ui/react-tooltip';

class DocImageTopRightMenu extends React.Component{  
 
  constructor(){
    super()
    this.state={
      
    }
  }  

	render(){ 

		const {addComment,deleteImage,convertToThumbnail,isPrivateDoc}=this.props

		//const isPrivateDoc = false // TO DO: Be real
		
		return (			
			<React.Fragment>

			{this.props.figmaMode &&
				<div className='doc-media-topRightMenu doc-media-topRightMenu--static'>
					<button className='doc-media-topRightMenu-btn doc-media-topRightMenu-btn--figmaIcon'>
						<Icon name='figmaColor' />
					</button>							
				</div>
			}

			<div className='doc-media-topRightMenu doc-media-topRightMenu--hover'>				
				
				{!isPrivateDoc &&
					<button onClick={this.props.newMessage} className='doc-media-topRightMenu-btn doc-media-topRightMenu-btn--iconAndLabel'>
						<div className='doc-media-topRightMenu-btn-iconContainer'>
							<Icon name='messageOutlineMedium' />
						</div>
						<div className='doc-media-topRightMenu-btn-label'>
							Comment
						</div>	
					</button>	
				}		
				

				
				{/*}
				
				<Tooltip.Provider>
					<Tooltip.Root delayDuration={400}>
						<Tooltip.Trigger className='doc-media-topRightMenu-btn doc-media-topRightMenu-btn--iconOnly'>
							<div className='doc-media-topRightMenu-btn-iconContainer'>
								<Icon name='messageOutlineMedium' />
							</div>					
						</Tooltip.Trigger>						
						<Tooltip.Content side="top" className="tooltip tooltip--mediaHoverMenu">
							Comment
						</Tooltip.Content>					
					</Tooltip.Root>
				</Tooltip.Provider>				
			*/}

				{/*}
				<Tooltip.Provider>
					<Tooltip.Root delayDuration={50}>
						<Tooltip.Trigger onClick={this.props.convertToThumbnail} className='doc-media-topRightMenu-btn doc-media-topRightMenu-btn--iconOnly'>
							<div className='doc-media-topRightMenu-btn-iconContainer'>
								<Icon name='unZoomMedia' />
							</div>					
						</Tooltip.Trigger>						
						<Tooltip.Content side="top" className="tooltip tooltip--mediaHoverMenu">
							Turn into mention
						</Tooltip.Content>			
					</Tooltip.Root>
				</Tooltip.Provider>
				
							
				<Tooltip.Provider>
					<Tooltip.Root delayDuration={50}>
						<Tooltip.Trigger onClick={this.props.deleteImage} className='doc-media-topRightMenu-btn doc-media-topRightMenu-btn--iconOnly doc-media-topRightMenu-btn--stash'>
							<div className='doc-media-topRightMenu-btn-iconContainer'>
								<Icon name='stashOutlineLight' />
							</div>					
						</Tooltip.Trigger>						
						<Tooltip.Content side="top" className="tooltip tooltip--mediaHoverMenu">
							Stash
						</Tooltip.Content>					
					</Tooltip.Root>
				</Tooltip.Provider>
				*/}

				<Tooltip.Provider>
					<Tooltip.Root delayDuration={400}>
						<Tooltip.Trigger onClick={this.props.deleteImage} className='doc-media-topRightMenu-btn doc-media-topRightMenu-btn--iconOnly doc-media-topRightMenu-btn--delete'>
							<div className='doc-media-topRightMenu-btn-iconContainer'>
								<Icon name='trash' />
							</div>					
						</Tooltip.Trigger>						
						<Tooltip.Content side="right" className="tooltip tooltip--mediaHoverMenu">
							Delete
						</Tooltip.Content>					
					</Tooltip.Root>
				</Tooltip.Provider>
				

				


				</div>
			</React.Fragment>			
		)
	}
}

export default DocImageTopRightMenu
