import React from 'react'
import Icon from '../../../misc/Icon'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import DocEditorMeetingInfoEditDatePopover from './DocEditorMeetingInfoEditDatePopover'
import DocEditorMeetingInfoEditTimePopover from './DocEditorMeetingInfoEditTimePopover'
import moment from 'moment'
import {updateMeetingTimes} from '../../../../utils/meetings/updateMeetingTimes'
import {formatMeetingDateShort} from '../../../../utils/meetings/formatMeetingDateShort'
import {formatMeetingDate} from '../../../../utils/meetings/formatMeetingDate'

export function formatEditDateTimestamp(timestamp) {
	const nowDate =moment()
	const today = moment(nowDate).startOf('day')
	const tomorrow = moment(nowDate).add(1, 'days').startOf('day')
	const week = moment(nowDate).startOf('isoWeek')
	const year = moment(nowDate).startOf('isoYear')
 
	let formattedTimestamp
	if (moment(timestamp).isSame(today, 'd')) {
		formattedTimestamp = `Today`
	}else if (moment(timestamp).isSame(tomorrow, 'd')) {
  	formattedTimestamp = `Tomorrow`
  }else{
		//formattedTimestamp = moment(timestamp).format('ddd Do MMMM h:mm A ') // with time
		formattedTimestamp = moment(timestamp).format('ddd Do MMMM')
	}
	return formattedTimestamp
}


export function formatEditTimeTimestamp(timestamp) {
	const nowDate =moment()
	let formattedTimestamp 
	formattedTimestamp = moment(timestamp).format('h:mm A')
	return formattedTimestamp
}

class DocEditorMeetingInfoDateTime extends React.Component{  

	constructor(props){
    super(props)     
    this.closePopover=this.closePopover.bind(this)   
    this.selectNewMeetingDate=this.selectNewMeetingDate.bind(this)
    this.selectNewStartTime=this.selectNewStartTime.bind(this)
    this.selectNewEndTime=this.selectNewEndTime.bind(this)


    this.state={
      showEditDatePopover: false,
      showEditStartTimePopover: false,
      showEditEndTimePopover: false,
    }
  }

  closePopover(){
  	this.setState({showEditDatePopover: false})
  }



  selectNewMeetingDate(date){  	
  	const {meeting}=this.props
  	let startTime
  	let endTime 
  	let meetingDate=moment(date).format('YYYY-MM-DD')
  	if(meeting.meeting_start){
  		startTime = moment(this.props.meeting.meeting_start); 
			endTime = moment(this.props.meeting.meeting_end)
  	}
  	let newStartTime=null 
  	let newEndTime=null 
  	if(startTime){
  		newStartTime = moment(date); 
			newStartTime.hour(startTime.hour());
			newStartTime.minute(startTime.minute());
			newStartTime.second(startTime.second());
			newStartTime.millisecond(startTime.millisecond());
  	}
  	if(endTime){
  		newEndTime = moment(date)
			newEndTime.hour(endTime.hour());
			newEndTime.minute(endTime.minute());
			newEndTime.second(endTime.second());
			newEndTime.millisecond(endTime.millisecond());
  	}

		updateMeetingTimes(this.props.meeting.meeting_id,meetingDate,newStartTime,newEndTime) 
		this.closePopover()
  }

  selectNewStartTime(time){
  	this.setState({showEditStartTimePopover: false})
  	const {meeting} = this.props 
  	const meetingDate=meeting.meeting_date
  	const startTime = meeting.meeting_start 
  	const endTime = meeting.meeting_end
  	let meetingDuration = 30*60*1000
  	if(startTime && endTime){
  		meetingDuration= moment.duration(moment(endTime).diff(moment(startTime)));
  	}
    let [selectedHour, selectedMinute] = time.split(':').map(Number);
    let newStartTime = moment(meetingDate); 
    newStartTime.hour(selectedHour);
    newStartTime.minute(selectedMinute);
		let newEndTime = newStartTime.clone().add(meetingDuration)
		updateMeetingTimes(this.props.meeting.meeting_id,meetingDate,newStartTime,newEndTime)
  }

  selectNewEndTime(time){
  	this.setState({showEditEndTimePopover: false})
  	const {meeting} = this.props 
  	const meetingDate=meeting.meeting_date
  	const startTime = meeting.meeting_start 
  	const endTime = meeting.meeting_end
    let [selectedHour, selectedMinute] = time.split(':').map(Number);
    let newEndTime = moment(endTime); 
    newEndTime.hour(selectedHour);
    newEndTime.minute(selectedMinute);
    //console.log("After change: ", newEndTime.toString());
    updateMeetingTimes(this.props.meeting.meeting_id,meetingDate,startTime,newEndTime)
  }

	render(){	
		const {meeting, viewOnlyMode} = this.props		
		
		const {showEditDatePopover, showEditStartTimePopover, showEditEndTimePopover} = this.state
		

		let noTimestamp=true 
    if(meeting && meeting.meeting_start){
    	noTimestamp=false
    }
    let noDate=true 
    if(meeting && meeting.meeting_date){
    	noDate=false
    }

		//let formattedEditDateTimestamp=formatMeetingDateShort(meeting.meeting_date)
		let formattedEditDateTimestamp=formatMeetingDate(meeting.meeting_date)
		let formattedEditStartTimeTimestamp=formatEditTimeTimestamp(meeting.meeting_start)
		let formattedEditEndTimeTimestamp=formatEditTimeTimestamp(meeting.meeting_end)

		const anyPopoverOpen = showEditDatePopover || showEditStartTimePopover || showEditEndTimePopover

		// const timestampTouched = meeting.time_updated_at?true:false
		// const dateTouched = true // date is touched when user has ever edited the date
		// const timeTouched = true // time is touched when user has ever edited the startTime		
		// // could also try with a combined timestamp, that'll probs feel fine!

		// //const forceEditMode = !dateTouched || !timeTouched

		

		if(noDate){
			formattedEditDateTimestamp = 'Add Date'
		}
		if(!noDate && noTimestamp){
			formattedEditStartTimeTimestamp="Add Time"
			formattedEditEndTimeTimestamp="Add Time"
		}
	//	console.log(`nodate----- ${noDate}`)
//		console.log(`notimestamp----- ${noTimestamp}`)

	  return (
	  	<React.Fragment>

	  		
	  		{!viewOnlyMode &&
	  		<div onMouseEnter={()=>{this.handleMouseEnter}} onMouseLeave={()=>{this.handleMouseLeave}} className={'docEditor-meetingTopInfo-dateContainer ' + (anyPopoverOpen ? 'docEditor-meetingTopInfo-dateContainer--anyPopoverOpen' : 'docEditor-meetingTopInfo-dateContainer--noPopoverOpen') + (noTimestamp ? ' docEditor-meetingTopInfo-dateContainer--noTimestamp ' : ' docEditor-meetingTopInfo-dateContainer--hasTimestamp ') + ((noDate && noTimestamp) ? ' docEditor-meetingTopInfo-dateContainer--noDate ' : ' docEditor-meetingTopInfo-dateContainer--hasDate ')}>
					<div className='docEditor-meetingTopInfo-dynamicDateLabel'>			  																		
						<DocEditorMeetingInfoEditDatePopover
							showPopover={this.state.showEditDatePopover}
							meeting={meeting}
							selectNewMeetingDate={this.selectNewMeetingDate}
							openPopover={()=>{this.setState({showEditDatePopover: true})}}
							closePopover={this.closePopover}									
						>	
							<button onClick={()=>{this.setState({showEditDatePopover: true})}} className={'docEditor-meetingTopInfo-dynamicDateLabel-btn docEditor-meetingTopInfo-dynamicDateLabel-btn--date ' + (noDate ? ' docEditor-meetingTopInfo-dynamicDateLabel-btn--noDate ' : '')}>
								{noDate &&
								<div className='docEditor-meetingTopInfo-dynamicDateLabel-btn-iconContainer'>
									<Icon name='plus' />
								</div>
								}
								<div className='docEditor-meetingTopInfo-dynamicDateLabel-btn-label'>
									{formattedEditDateTimestamp}
								</div>
							</button>			 
						</DocEditorMeetingInfoEditDatePopover>

						{!noDate && 
						<React.Fragment>
				  		<DocEditorMeetingInfoEditTimePopover
								showPopover={this.state.showEditStartTimePopover}
								meeting={meeting}
								isStartTime={true}
								selectNewStartTime={this.selectNewStartTime}
								openPopover={()=>{this.setState({showEditStartTimePopover: true})}}
								closePopover={()=>{this.setState({showEditStartTimePopover: false})}}
								startTimePopover
							>	
				  			<button onClick={()=>{this.setState({showEditStartTimePopover: true})}} className={'docEditor-meetingTopInfo-dynamicDateLabel-btn docEditor-meetingTopInfo-dynamicDateLabel-btn--time docEditor-meetingTopInfo-dynamicDateLabel-btn--time--starttime' + (noTimestamp ? ' docEditor-meetingTopInfo-dynamicDateLabel-btn--noTime ' : '')}>
				  				{noTimestamp &&
									<div className='docEditor-meetingTopInfo-dynamicDateLabel-btn-iconContainer'>
										<Icon name='clockRegular' />
									</div>
									}
				  				<div className='docEditor-meetingTopInfo-dynamicDateLabel-btn-label'>
				  					{formattedEditStartTimeTimestamp}
				  				</div>
				  			</button>
			  			</DocEditorMeetingInfoEditTimePopover>

			  			{!noTimestamp &&
				  			<div className='docEditor-meetingTopInfo-dynamicDateLabel-arrow'>
				  				<Icon name='arrowRight' />
				  			</div>
			  			}

			  			{!noTimestamp &&
			  			<DocEditorMeetingInfoEditTimePopover
								showPopover={this.state.showEditEndTimePopover}
								meeting={meeting}
								isStartTime={false}
								selectNewEndTime={this.selectNewEndTime}
								openPopover={()=>{this.setState({showEditEndTimePopover: true})}}
								closePopover={()=>{this.setState({showEditEndTimePopover: false})}}				
								endTimePopover				
							>	
				  			<button onClick={()=>{this.setState({showEditEndTimePopover: true})}} className='docEditor-meetingTopInfo-dynamicDateLabel-btn docEditor-meetingTopInfo-dynamicDateLabel-btn--time docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime'>
				  				{formattedEditEndTimeTimestamp}
				  			</button>
			  			</DocEditorMeetingInfoEditTimePopover>
			  			}

		  			</React.Fragment>
		  			}
		  		</div>			  	
		  	</div>
		  	}
		  	{viewOnlyMode &&
	  		<div className='docEditor-meetingTopInfo-dateContainer docEditor-meetingTopInfo-dateContainer--viewOnlyMode'>	  			
					<div className='docEditor-meetingTopInfo-dynamicDateLabel'>			  																								
						<button className='docEditor-meetingTopInfo-dynamicDateLabel-btn docEditor-meetingTopInfo-dynamicDateLabel-btn--date'>
							{formattedEditDateTimestamp}
						</button>			 						
		  			<button className='docEditor-meetingTopInfo-dynamicDateLabel-btn docEditor-meetingTopInfo-dynamicDateLabel-btn--time docEditor-meetingTopInfo-dynamicDateLabel-btn--time--starttime'>
		  				{formattedEditStartTimeTimestamp}
		  			</button>		  			
		  			<div className='docEditor-meetingTopInfo-dynamicDateLabel-arrow'>
		  				<Icon name='arrowRight' />
		  			</div>		  			
		  			<button className='docEditor-meetingTopInfo-dynamicDateLabel-btn docEditor-meetingTopInfo-dynamicDateLabel-btn--time docEditor-meetingTopInfo-dynamicDateLabel-btn--time--endTime'>
		  				{formattedEditEndTimeTimestamp}
		  			</button>		  			
		  		</div>			  	
		  	</div>
		  	}

			</React.Fragment>
	  )
	}
}

export default DocEditorMeetingInfoDateTime
