import React from 'react'
import Icon from '../../../../misc/Icon'
import * as Popover from '@radix-ui/react-popover';

class MessageInputBoxBtnRowInsertPopover extends React.Component{  

	render(){			
		return (	  	

				<Popover.Root>
			    <Popover.Trigger className='message-inputBox-insertPopoverBtn'>
			    	<Icon name='plus' />
			    </Popover.Trigger>		
			    <Popover.Portal>			    
			    <Popover.Content side="top" sideOffset={6} className='popover message-inputBox-insertPopover'>
			      <button onClick={this.props.addVideo} className='message-inputBox-insertPopover-row'>
			      	Video Clip
			      </button>
			      <button className='message-inputBox-insertPopover-row'>
					      <input 
									id='file'
									type='file'
									value={''} 
									accept={'.png,.jpg,.jpeg,.bmp,.tif,.tiff,.webp,.gif'}
									onChange={(e) =>{this.props.handleSelectFile(e)}} />							     
			      	Image
			      </button>
			      <button onClick={this.props.addDrawing} className='message-inputBox-insertPopover-row'>
			      	Drawing
			      </button>
			      <button onClick={this.props.addFigma} className='message-inputBox-insertPopover-row'>
			      	Figma
			      </button>
			      <button className='message-inputBox-insertPopover-row'>
			      	Light Text
			      </button>
			      <button className='message-inputBox-insertPopover-row'>
			      	Strikethrough
			      </button>
			      <button className='message-inputBox-insertPopover-row'>
			      	Code
			      </button>
			      <button className='message-inputBox-insertPopover-row'>
			      	Code Block
			      </button>
			      <button className='message-inputBox-insertPopover-row'>
			      	Block Quote
			      </button>
			    </Popover.Content>
			    </Popover.Portal>
			  </Popover.Root>	   

					
			
		)
	}
}


export default MessageInputBoxBtnRowInsertPopover