import {MessageSelection} from './messageSelection'
import store from '../../../store'
import {PluginKey} from "prosemirror-state"

const key = new PluginKey('messageSelectionPlugin')
 
export const addMessageSelection = function(id) {
	const state = window.view.state
	const dispatch=window.view.dispatch
	const sel=state.selection
	if (sel.empty) return false
	if (dispatch) {
		let tr=state.tr
		tr.setMeta('messageSelectionPlugin', {type: "newMessageSelection", from: sel.from, to: sel.to, messageSelection: new MessageSelection(id,true)})
		dispatch(tr)
	}
	return true
}
