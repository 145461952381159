import findIndex from 'lodash/findIndex'


export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_MEETINGS_SUCCESS':
		return action.response

	case 'CREATE_MEETING_SUCCESS':
	case 'CREATE_MEETING_REQUEST':
	case 'FETCH_A_MEETING_SUCCESS':
	case 'UPDATE_MEETING_SUCCESS':
		const meetingId = action.meeting['meeting_id']
		let index = findIndex(state,(meeting => {
			return meeting['meeting_id'] === meetingId
		}))
		if (index !== -1) {
			// Get the meeting in the state with the meetingId
			const meetingInState = state[index]
			// If the meeting in the action has a more recent updated_at timestamp,
			// replace the meeting in the state
			if (!meetingInState.updated_at || new Date(action.meeting.updated_at) > new Date(meetingInState.updated_at)) {
			return [
			...state.slice(0, index),
				action.meeting,
			...state.slice(index + 1)
			]
		} else {
			//console.log('%%%%%%%%%%%%%%%%%%%%% timestamp is older than current one in state-->dont update')
			// If not, return the state as is
			return state
		}
	} else {
		// If the meeting does not exist in the state, add it
		return [
		...state,
		action.meeting
		]
	}


	// case 'CREATE_MEETING_SUCCESS':
	// case 'CREATE_MEETING_REQUEST':
	// case 'FETCH_A_MEETING_SUCCESS':
	// case 'UPDATE_MEETING_SUCCESS':
	// console.log('in reducer--------')
	// console.log(action)
	// 	const meetingId=action.meeting['meeting_id']
	// 	let index=findIndex(state,(meeting => {
	// 		return meeting['meeting_id'] === meetingId
	// 	}))
	// 	if(index === -1){
	// 		index=state.length
	// 	}
	// 	return [
	// 		...state.slice(0,index),
	// 		action.meeting,
	// 		...state.slice(index + 1)
	// 		]



	case 'ARCHIVE_MEETING_REQUEST':
	case 'ARCHIVE_MEETING_SUCCESS':
		const requestedId = action.meetingId
		let requestedIndex = findIndex(state,(meeting => {
			return meeting['meeting_id'] === requestedId
		}))
		if(requestedIndex>-1){
			return [
			...state.slice(0,requestedIndex),
			...state.slice(requestedIndex + 1)
			]
		}else return state

	// case 'UPDATE_MEETING_SUCCESS':
	// 	const id = action.response.meeting_id
	// 	let indexOfMeeting = findIndex(state,(meeting => {
	// 		return meeting.meeting_id === id
	// 	}))
	// 	if(indexOfMeeting === -1){
	// 		indexOfMeeting=state.length
	// 	}
	// 	return [
	// 		...state.slice(0,indexOfMeeting),
	// 		action.response,
	// 		...state.slice(indexOfMeeting + 1)
	// 	]

	case 'UPDATE_MEETING_PARTICIPANTS_REQUEST':
		const updatedMeetingId=action.meetingId
		let updatedIndex=findIndex(state,(meeting => {
			return meeting.meeting_id === updatedMeetingId
		}))
		
		if(updatedIndex > -1){
			const inState=state[updatedIndex]
			let updatedMeeting={...inState}
			updatedMeeting.participants=action.participants
			updatedMeeting.external_participants=action.externalParticipants

			return [
				...state.slice(0,updatedIndex),
				updatedMeeting,
				...state.slice(updatedIndex + 1)
			]
		}else return state

	case 'UPDATE_MEETING_TYPE_REQUEST':
		let indx=findIndex(state,(meeting => {
			return meeting.meeting_id === action.meetingId
		}))
		
		if(indx > -1){
			const inState=state[indx]
			let updatedMeeting={...inState}
			updatedMeeting.type=action.meetingType
			updatedMeeting.updated_at = new Date()
			return [
				...state.slice(0,indx),
				updatedMeeting,
				...state.slice(indx + 1)
			]
		}else return state



	case 'UPDATE_MEETING_TIMES_REQUEST':
		const updatedMeeting=action.meetingId
		let updatedMeetingIndex=findIndex(state,(meeting => {
			return meeting.meeting_id === updatedMeeting
		}))
		if(updatedMeetingIndex > -1){
			const inState=state[updatedMeetingIndex]
			let updatedMeeting={...inState}
			updatedMeeting.meeting_start=action.startTime
			updatedMeeting.meeting_end=action.endTime
			updatedMeeting.meeting_date=action.meetingDate
			updatedMeeting.time_updated_at = new Date()
			return [
				...state.slice(0,updatedMeetingIndex),
				updatedMeeting,
				...state.slice(updatedMeetingIndex + 1)
			]
		}else return state


	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
 