import React from 'react'
import DocPlaceholderReactComponent from './DocPlaceholderReactComponent'



class DocEditorFakePlaceholder extends React.Component{  

	render(){			

	  return (
			<div className="ProseMirror doc">
				<div className="docInnerWrapper">
					<div className="doc-placeholder">
						<DocPlaceholderReactComponent docId={this.props.docId}/>
					</div>
				</div>
			</div>		
	  )	
	}
}

export default DocEditorFakePlaceholder