function getInitialState(){
	const defaultOpenTabs={
		orderedTabGroups:[
			{name:"default",id:"default"},
			{name:"starred",id:"starred"}
		],
		tabGroups:{
			"default":[],
			"starred":[]
		}
	}
	let savedOpenTabs = JSON.parse(localStorage.getItem('openTabs'))
	if(savedOpenTabs){
		return savedOpenTabs
	}else{
		return defaultOpenTabs
	}
}

//when archive doc remove it from open docs
//TODO think about other user deleting doc you have open

export default function (state = getInitialState(), action) {
	switch (action.type) {
		case 'UPDATE_OPEN_TAB_GROUPS':
			let newState={
				tabGroups:action.tabGroups,
				orderedTabGroups:action.orderedTabGroups
			}
			localStorage.setItem('openTabs',JSON.stringify(newState))
		return newState

		case 'LOGOUT':
			const defaultState=getInitialState()
			localStorage.setItem('openTabs',JSON.stringify(defaultState))
			return defaultState
		
		default:
			return state
	}
}