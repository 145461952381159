import React from 'react'
import Icon from '../misc/Icon'
import {getOrg} from '../../utils/getOrg'
import {getUserForId} from '../../utils/getUserForId'
import {getCurrentUser} from '../../utils/getCurrentUser'
import AvatarImage from '../AvatarImage'
import CompanyIcon from '../misc/CompanyIcon'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import * as Tooltip from '@radix-ui/react-tooltip';
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import TemplateModalHelpGif from './TemplateModalHelpGif'

const templateCompany = [
  {title: "Competitor Battlecard", company: "yarn"},
  {title: "User Persona", company: "yarn"},
  {title: "Design Review", company: "designReview"},
  {title: "Team Sync", company: "teamSync"},
  {title: "Discovery Call", company: "discovery"},
  {title: "PR/FAQ", company: "amazon"},
  {title: "Incident Postmortem", company: "pagerduty"},
  {title: "PRD", company: "square"},
  {title: "Product Brief", company: "linear"},
  {title: "RFC", company: "react"},
  {title: "Service RFC", company: "uber"},
  {title: "Stripe's Operating Principles", company: "stripe"},
  {title: "Enterprise PRD", company: "figma"},
  {title: "Technical Spec", company: "mixpanel"},
  {title: "\"We don't sell saddles here\"", company: "slack"},
  {title: "\"Ten things we know to be true\"", company: "google"},
]

class TemplateModalListOrgTemplateBtn extends React.Component{  	
		
	render(){	
		const {active} = this.props

    let userObj
    let avatarUrl
    if(this.props.userId){
      userObj = getUserForId(this.props.userId)
      if(userObj){
        avatarUrl = userObj.avatar
      }
    }

	  return (	  	
  		<button id={this.props.template.template_id} data-state={active ? 'active': 'inactive'} onClick={(e)=>{this.props.setActiveTemplate(e,this.props.template)}} className='templateModal-list-orgTemplateBtn'>
  			<div className='templateModal-list-orgTemplateBtn-avatarContainer'>
          <AvatarImage 
            avatarUrl={avatarUrl}
            className='templateModal-list-orgTemplateBtn-avatar'
          />
        </div>        
        <div className='templateModal-list-orgTemplateBtn-title'>
  				{this.props.title}
  			</div>  			
  		</button>
	  )
	}
}

class TemplateModalListStarterTemplateBtn extends React.Component{    
    
  render(){ 
    const {active, title} = this.props

    const matchingItem = templateCompany.find(item => item.title === title) || {};
    let company
    if(matchingItem){
      company = matchingItem.company
    }

    let meetingType
    if(company === 'discovery'){
      meetingType = 'discovery'
    }else if(company === 'designReview'){
      meetingType = 'designReview'
    }else if(company === 'teamSync'){
      meetingType = 'teamSync'
    }

    return (      
      <button id={this.props.template.template_id} data-state={active ? 'active': 'inactive'} onClick={(e)=>{this.props.setActiveTemplate(e,this.props.template)}} className='templateModal-list-starterTemplateBtn'>
        <div className='templateModal-list-starterTemplateBtn-sourceLogoContainer'>
          {company && !meetingType &&
            <CompanyIcon company={company} />
          }
          {meetingType &&
            <MeetingTypeIcon meetingType={meetingType} />
          }
        </div>        
        <div className='templateModal-list-starterTemplateBtn-title'>
          {title}
        </div>        
      </button>
    )
  }
}


class TemplateModalListHeader extends React.Component{  		
	render(){	
	  return (	  	
  		<div className='templateModal-listContainer-list-header'>
  			<div className='templateModal-listContainer-list-header-label'>
  				{this.props.label}
  			</div>
  			<div className='templateModal-listContainer-list-header-border'/>
  		</div>
	  )
	}
}


class TemplateModalList extends React.Component{  	

	render(){	
		const {docTemplates,activeTemplate,archivedDocTemplates,showArchived,orgTemplates,publicTemplates} = this.props
    let orgName
    const org = getOrg()
    if(org){
      orgName = org.name
    }     
    if(org && org.name === 'Yarn'){
      orgName ='Acme'
    }    
    const currentUserObj = getCurrentUser()
    let noneCreatedByUser
    if(currentUserObj){
      noneCreatedByUser = !orgTemplates.some(template => template.created_by === currentUserObj.user_id);
    }
	  return (	  	
  		<div className='templateModal-listContainer'> 
        <button onClick={this.props.deactivateEditTemplateModel} className={'templateModal-cancelCreateModeBtn ' + (this.props.editTemplateMode ? ' templateModal-cancelCreateModeBtn--show ' : ' templateModal-cancelCreateModeBtn--hide ')} />        
        <div className='templateModal-list'>  			
    			<div className='templateModal-listHeader'>
            <div className='templateModal-listHeader-iconContainer'>
              <Icon name='template' />
            </div>
            <div className='templateModal-listHeader-label'>
    				  {showArchived?'Archived':'Templates'}
            </div>
            {showArchived &&
              <button onClick={this.props.toggleShowArchived} className='templateModal-listHeader-archiveBtn'>
                Done
              </button>
            }
    			</div>

          {noneCreatedByUser && !showArchived &&
            <div className='templateModal-listContainer-emptyOrgContainer'>     
              <div className='templateModal-listContainer-emptyOrg'>
                <div className='templateModal-listContainer-emptyOrg-iconContainer'>
                  <Icon name='infoOutline' />
                </div>
                <div className='templateModal-listContainer-emptyOrg-title'>
                  Add your first template
                </div>   
              </div>
              <div className='templateModal-listContainer-emptyOrg-helpPopover'>
                <TemplateModalHelpGif/>
              </div>               
            </div>            
          }

    			<div className='templateModal-listContainer-list' id={'modal_scrollable_list'}>
            {!showArchived &&
              <div className='templateModal-listContainer-orgBtns'>    	
              {orgTemplates.map((template)=>{
                const active = activeTemplate && template.template_id==activeTemplate.template_id
                return(
                  <TemplateModalListOrgTemplateBtn
                    key={template.template_id}
                    template={template}
                    setActiveTemplate={this.props.setActiveTemplate}
                    active={active}
                    title={template.name}
                    userId={template.created_by}
                  />
                  )
              })}
              </div>
            }
            {showArchived &&
              <div className='templateModal-listContainer-orgBtns'>     
              {archivedDocTemplates.map((template)=>{
                const active = activeTemplate && template.template_id==activeTemplate.template_id
                return(
                  <TemplateModalListOrgTemplateBtn
                    key={template.template_id}
                    template={template}
                    setActiveTemplate={this.props.setActiveTemplate}
                    active={active}
                    title={template.name}
                    userId={template.created_by}
                  />
                  )
              })}
              </div>
            }

            
             {!showArchived &&
              <React.Fragment>
                <TemplateModalListHeader
                 label='Meeting Notes'
                />

                 {
                  publicTemplates.filter(template => ['Design Review', 'Team Sync', 'Discovery Call'].includes(template.name))
                    .map((template) => {
                      const active = activeTemplate && template.template_id == activeTemplate.template_id;
                      return (
                        <TemplateModalListStarterTemplateBtn 
                          key={template.template_id}
                          title={template.name}
                          template={template}
                          setActiveTemplate={this.props.setActiveTemplate}
                          active={active}
                        />
                      )
                    })
                }
              </React.Fragment>
           }

            {!showArchived &&
              <React.Fragment>
      				  <TemplateModalListHeader
      					 label='Starter Templates'
      				  />

                 {
                  publicTemplates.filter(template => !['Design Review', 'Team Sync', 'Discovery Call'].includes(template.name))
                    .map((template) => {
                      const active = activeTemplate && template.template_id == activeTemplate.template_id;
                      return (
                        <TemplateModalListStarterTemplateBtn 
                          key={template.template_id}
                          title={template.name}
                          template={template}
                          setActiveTemplate={this.props.setActiveTemplate}
                          active={active}
                        />
                      )
                    })
                }
              </React.Fragment>
           }
    			</div>

          {/*
          <div className='templateModal-list-helpContainer'>
            <div className='templateModal-list-help'>
              <div className='templateModal-list-help-iconContainer'>
                <Icon name='infoOutline' />
              </div>

              <div className='templateModal-list-help-label'>
                How to create a template
              </div>
            </div>
            <div className='templateModal-list-helpPopover'>
              <TemplateModalHelpGif/>
            </div>
          </div>
          */}

        </div>        

  		</div>
	  )
	}
}

export default TemplateModalList
