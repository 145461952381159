import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {removeGoogleIntegration} from '../../actions/googleIntegration'
import {formatDate} from '../../utils/formatDate'

import Icon from '../misc/Icon'
import GoogleLoginButton from './GoogleLoginButton'


class SettingsGoogleCalendarIntegration extends Component {

  render(){  
    const {figmaIntegration}=this.props
    if(!this.props.googleIntegration){
      return ( 
        <GoogleLoginButton />
      )
    }else return (
       <div className='settingsPage-content-section--figma-statusRow'>
          
          <div className='settingsPage-content-section--figma-statusRow-label'> 
            Connected to {this.props.googleIntegration.email}
          </div>            
          <button className='settingsPage-content-section--figma-statusRow-btn' onClick={this.props.removeGoogleIntegration}>
            Disconnect Google Calendar
          </button>

          <div className='settingsPage-content-section--figma-activeStatusTagContainer'>
            <div className='settingsPage-content-section--figma-activeStatusTag'>
              <div className='settingsPage-content-section--figma-activeStatusTag-dot' />
              Connected
            </div>            
          </div>
          
        </div>
        )
  
    }
}
        // <button className='settingsPage-content-section--figma--connectBtn settingsPage-content-section--figma--connectBtn--connect' onClick={login}>                      
        // Connect     on {formatDate(this.props.googleIntegration.created_at)}.         
        // </button>   

function mapStateToProps(state,ownProps) {


  return {
      user:state.user,
      googleIntegration:state.googleIntegration
  }
}


export default withRouter(connect(mapStateToProps,
  {removeGoogleIntegration}
)(SettingsGoogleCalendarIntegration))  


