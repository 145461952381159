import React from 'react'
import Icon from '../../../components/misc/Icon'
import LinearMarker from '../../../components/misc/LinearMarker'
import AvatarImage from '../../../components/AvatarImage'
import {getDocForId} from '../../../utils/getDocForId'
import {getProjectForId} from '../../../utils/getProjectForId'
import {getIssueForId} from '../../../utils/getIssueForId'
import {getUserForId} from '../../../utils/getUserForId'

class EditTextLinkInputPreview extends React.Component{  
  
	render(){
		const {linkType,url,projectId,docId,issueId}=this.props

		//
		// Project Link
		let projectObj
		let projectName

		if(linkType === 'project' && projectId){
			projectObj = getProjectForId(projectId)
		}
		if(projectObj){
			projectName = projectObj.name
		}

		//
		// Doc Link		
		let docObj
		let docName

		if(linkType === 'doc' && docId){
			docObj = getDocForId(docId)
		}
		if(docObj){
			docName = docObj.name
		}

		//
		// Issue Link		
		let issueObj
		let issueIdentifier
		let issueAssigneeId
		let issueAssigneeObj
		let issueAssigneeAvatarUrl
		

		if(linkType === 'issue' && issueId){
			issueObj = getIssueForId(issueId)			
		}

		if(issueObj){
			issueIdentifier = issueObj.identifier
			issueAssigneeId = issueObj.assignee			
		}
		if(issueAssigneeId){			
			issueAssigneeObj = getUserForId(issueAssigneeId)
			issueAssigneeAvatarUrl = issueAssigneeObj.avatar			
		}


		
		return (            

			<div className='doc-textLink-popover-inputPreviewContainer'>
				

					{linkType === 'weblink' && 
						<div className="doc-textLink-popover-inputPreview doc-textLink-popover-inputPreview--web">
							<div className="doc-textLink-popover-inputPreview--web-iconContainer">
								<Icon name='globe' />
							</div>
							<div className="doc-textLink-popover-inputPreview--web-url">
								{url}
							</div>						
						</div>
					}

					{linkType === 'project' && projectId && projectName && 
						<div className="doc-textLink-popover-inputPreview doc-textLink-popover-inputPreview--project">
							<div className="doc-textLink-popover-inputPreview--project-markerContainer">
								<LinearMarker projectId={projectId} />
							</div>
							<div className="doc-textLink-popover-inputPreview--project-name">
								{projectName}
							</div>						
						</div>
					}

					{linkType === 'doc' && docName && 
						<div className="doc-textLink-popover-inputPreview doc-textLink-popover-inputPreview--doc">
							<div className="doc-textLink-popover-inputPreview--doc-iconContainer">
								<Icon name='docSimple' />
							</div>
							<div className="doc-textLink-popover-inputPreview--doc-name">
								{docName}
							</div>						
						</div>
					}

					{linkType === 'issue' && issueIdentifier &&
						<div className="doc-textLink-popover-inputPreview doc-textLink-popover-inputPreview--issue">
							{issueAssigneeAvatarUrl && 
								<div className="doc-textLink-popover-inputPreview--issue-avatarContainer">
									<AvatarImage
										className='doc-textLink-popover-inputPreview--issue-avatar'
										avatarUrl={issueAssigneeAvatarUrl}
										smallAvatar
									/>
								</div>
							}
							<div className="doc-textLink-popover-inputPreview--issue-identifier">
								{issueIdentifier}
							</div>						
						</div>
					}


			</div>
		)
	}
}

export default EditTextLinkInputPreview
