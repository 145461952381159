export function bumpWhiteboardEmbedVersion() {
	if(window.view && window.view.state){
		let tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.whiteboardEmbedVersion || node.attrs.whiteboardEmbedVersion===0){
				tr.setNodeMarkup(pos, null, {...node.attrs,whiteboardEmbedVersion:node.attrs.whiteboardEmbedVersion+1})
			}
		})
		tr.setMeta('addToHistory', false)  
		window.view.dispatch(tr)
	}
}