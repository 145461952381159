import store from '../../store'
import {fetchDocImage} from '../../actions/docImage'

export function updateImageCloudinaryValues(imageId,values) {
	if(window.view && window.view.state){
		let tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(`${node.attrs.nodeId}`==`${imageId}`){
				tr.setNodeMarkup(pos, null, {...node.attrs,deliveryUrl:values.delivery_url,height:values.height,width:values.width})
			}
		})
		tr.setMeta('addToHistory', false)  
		window.view.dispatch(tr)
	}
}


export function fetchImageForNode(nodeId) {

	store.dispatch(fetchDocImage(nodeId)).then(()=>{

	})


}




