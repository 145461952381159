import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import Icon from '../../../misc/Icon'
import LinearMarker from '../../../misc/LinearMarker'
import { Transition, animated, config } from 'react-spring'
// import DocEditorMeetingContentAddToGCalPopoverContents from './DocEditorMeetingContentAddToGCalPopoverContents'
import {getTeamForId} from '../../../../utils/getTeamForId'
import {getTeams} from '../../../../utils/getTeams'
import sortBy from 'lodash/sortBy'
import {updateTeamForRecurringEvent} from '../../../../utils/meetings/updateTeamForRecurringEvent'

class DocEditorMeetingContentNewTeamSyncTeamPopover extends React.Component{  

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)	
		this.handleSelectTeam=this.handleSelectTeam.bind(this)
		this.handleSelectNullTeam=this.handleSelectNullTeam.bind(this)
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  handleSelectTeam(teamId) {  
    if(teamId === this.props.selectedTeamId){
      // turn off
      this.props.selectTeam(null)
      this.props.closePopover()
    } else{
      this.props.selectTeam(teamId)
      this.props.closePopover()
    }
  }

  handleSelectNullTeam() {      
    this.props.selectTeam(null)
    this.props.closePopover()    
  }


	render(){	
		const {showPopover, openPopover, closePopover} = this.props
		const {forceMount} = this.state
		const translateY = -8
		const popoverSide = "bottom"
		let popoverSideOffset= 4
		let popoverAlign = "start"				
		let popoverAlignOffset= -4

		const orgTeams = getTeams()
		const sortedTeams = sortBy(orgTeams, function(team){
   		return team.name.toString().toLowerCase()
   	})

	  return (
	  	<React.Fragment>
		  	<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>						
						{this.props.children}
					</Popover.Trigger>					
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'newTeamSyncTeamPopoverContainer ' + (showPopover ? ' newTeamSyncTeamPopoverContainer--visible ' : ' newTeamSyncTeamPopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							//onInteractOutside={closePopover}							
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
								enter={{ opacity: 1, transform: 'translateY(0px)'  }}
								leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 35}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="newTeamSyncTeamPopover popover"> 	
											
										{sortedTeams.map((orgTeam) => {
											const selected = orgTeam.team_id === this.props.selectedTeamId
											return (
												<div data-state={selected ? "selected" : "notSelected"} onClick={()=>{this.handleSelectTeam(orgTeam.team_id)}} className='popoverMenu-item popoverMenu-item--selectTeam'>
													<div className='popoverMenu-item-markerContainer'>
														<LinearMarker teamId={orgTeam.team_id} />
													</div>
													<div className='popoverMenu-item-label'>
														{orgTeam.name}
													</div>
												</div>
											);
										})
										}

										<div onClick={this.handleSelectNullTeam} className='popoverMenu-item popoverMenu-item--selectTeam popoverMenu-item--selectTeam--none'>											
											<div className='popoverMenu-item-label'>
												Don't attach to team
											</div>
										</div>
											
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>
			</React.Fragment>
	  )
	}
}

class DocEditorMeetingContentNewTeamSyncTeamBtn extends React.Component{  

	constructor(props){
		super(props)
		this.handleSelectTeam=this.handleSelectTeam.bind(this)
		let selectedTeamId=null 
		if(props.meeting && props.meeting.team){
			selectedTeamId=props.meeting.team
		}
		this.state={
      showNewTeamSyncTeamPopover: false,      
      selectedTeamId: selectedTeamId,
      //selectedTeamId: '7'
    }
	}

	handleSelectTeam(selectedTeamId){
		console.log('select team-----------')
		this.setState({selectedTeamId: selectedTeamId})
		updateTeamForRecurringEvent(this.props.meeting.recurring_event,selectedTeamId)
	}


	render(){	
	
		const {showNewTeamSyncTeamPopover, selectedTeamId} = this.state
		

		let selectedTeamObj
		let selectedTeamName

		if(selectedTeamId){
			selectedTeamObj = getTeamForId(selectedTeamId)
			if(selectedTeamObj){
				selectedTeamName = selectedTeamObj.name
			}
		}

	  return (
	  	<React.Fragment>
		  	<DocEditorMeetingContentNewTeamSyncTeamPopover
            showPopover={showNewTeamSyncTeamPopover}
            closePopover={()=>{this.setState({showNewTeamSyncTeamPopover: false})}}
            selectedTeamId={selectedTeamId}
            selectTeam={this.handleSelectTeam}
            //meeting={meeting}
          >
            <button onClick={()=>{this.setState({showNewTeamSyncTeamPopover: true})}} className={'docEditor-meetingContent-menu-teamSelectBtn ' + (selectedTeamId ? ' docEditor-meetingContent-menu-teamSelectBtn--selected ' : ' docEditor-meetingContent-menu-teamSelectBtn--empty ')}>
              {!selectedTeamId && 
	              <React.Fragment>
		              <div className='docEditor-meetingContent-menu-teamSelectBtn-label'>
		                Select Team
		              </div>
		              <div className='docEditor-meetingContent-menu-teamSelectBtn-sub'>
		                Optional
		              </div>
		              <div className='docEditor-meetingContent-menu-teamSelectBtn-chevronContainer'>
		                <Icon name='chevronDownMedium' />
		              </div>
		            </React.Fragment>
            	}
            	{selectedTeamId && 
	              <React.Fragment>
		              <div className='docEditor-meetingContent-menu-teamSelectBtn-markerContainer'>
		                <LinearMarker teamId={selectedTeamId} />
		              </div>
		              <div className='docEditor-meetingContent-menu-teamSelectBtn-label'>
		                {selectedTeamName}
		              </div>		              
		              <div className='docEditor-meetingContent-menu-teamSelectBtn-chevronContainer'>
		                <Icon name='chevronDownMedium' />
		              </div>
		            </React.Fragment>
            	}

            	<div className='docEditor-meetingContent-menu-teamSelectBtn-tooltipContainer'>
            		<div className='tooltip tooltip--newTeamSyncTeamBtn'>
            			Filters the projects displayed inside the Team Sync meeting doc.
            		</div>
            	</div>
            </button>             
          </DocEditorMeetingContentNewTeamSyncTeamPopover>          

					
			</React.Fragment>
	  )
	}
}

export default DocEditorMeetingContentNewTeamSyncTeamBtn
