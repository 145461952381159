import React from 'react'
import Icon from '../../../components/misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import ReactPlayer from 'react-player/file'
import ReactSlider from 'react-slider'

const MOUSE_MOVE_SHORT_DEBOUNCE_TIME = 1800
const MOUSE_MOVE_LONG_DEBOUNCE_TIME = 4000


class TranscriptVideoPlayer extends React.Component{  

	 constructor(props){
    super(props)
    this.ref=this.ref.bind(this)
    this.handleProgress=this.handleProgress.bind(this)
    this.handlePlayPause=this.handlePlayPause.bind(this)
    this.handlePlay=this.handlePlay.bind(this)
    this.handlePause=this.handlePause.bind(this)    
    this.handleSeekChange=this.handleSeekChange.bind(this)
    this.handleSeekMouseUp=this.handleSeekMouseUp.bind(this)
    this.handleDoubleClick=this.handleDoubleClick.bind(this)
    this.handleSkip=this.handleSkip.bind(this)
    this.handleSeekMouseDown=this.handleSeekMouseDown.bind(this)
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.hideMouseMoveShort=this.hideMouseMoveShort.bind(this)    
    this.hideMouseMoveLong=this.hideMouseMoveLong.bind(this)    
    
    this.handleVolumeChange=this.handleVolumeChange.bind(this)
    this.handleVolumeMouseUp=this.handleVolumeMouseUp.bind(this)
    this.handleVolumeMouseDown=this.handleVolumeMouseDown.bind(this)  
   	this.handlePlayerReady=this.handlePlayerReady.bind(this)  

    this.hideMouseMoveShort=debounce(this.hideMouseMoveShort,MOUSE_MOVE_SHORT_DEBOUNCE_TIME)
    this.hideMouseMoveLong=debounce(this.hideMouseMoveLong,MOUSE_MOVE_LONG_DEBOUNCE_TIME)

    //this.saveVideoProgress=this.saveVideoProgress.bind(this)
    //this.saveVideoProgress=throttle(this.saveVideoProgress,SAVE_VIDEO_PROGRESS_THROTTLE_TIME)

    this.state={
      url: null,	    
	    playing: false,	    
	    played: 0,
	    loaded: 0,
	    duration: 0,	    
      
      seeking: false,
      mouseMovedShort:false,
      mouseMovedLong:false,
      captionsActive: false,           
      volume: 1,
      adjustingVolume: false,
      muted:false,
      playerLoaded:false,
      hasPlayed:false,
    }
  }

  componentDidMount(){
  	const storyboardImage = new Image();
  	storyboardImage.src = `https://image.mux.com/${this.props.playbackId}/storyboard.png`
  }

  handlePlayerReady(){
  	//this.player.seekTo(0.5)
  }

  componentWillUnmount(){
  //	const progressSeconds = this.state.duration * this.state.played
  	//saveVideoProgress(this.props.nodeId,progressSeconds)
  }

  handlePlayPause(){
    this.setState({playing:!this.state.playing})
  }

  handlePlay(){
    this.setState({playing:true,hasPlayed:true})
  }

  handlePause(){
    this.setState({playing:false})
  }

  handleSeekMouseDown(){
    this.setState({seeking:true,muted:true})
  }  
 
  handleSeekChange(e){
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
  	this.setState({playedSeconds:playedSeconds,played:e})
  }

  handleSeekMouseUp(e){
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
  	this.setState({playedSeconds:playedSeconds,played:e,muted:false})
  }

  // saveVideoProgress(seconds){
  // 	if(this.state.playerLoaded){
  // 		saveVideoProgress(this.props.nodeId,seconds)
  // 	}
  // }

  handleProgress(state){
  	// console.log('handle progress----------')
  	// console.log(state)
  	this.props.handleVideoProgress(state.playedSeconds)
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
     // this.saveVideoProgress(state.playedSeconds)
    }
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
    this.player.seekTo(0)
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  ref(player){
    console.log('set ref!!!!!')
    this.player = player
    this.props.setPlayerRef(player)
  }

  handleMouseMove(){    
    this.setState({mouseMovedShort:true})
    this.setState({mouseMovedLong:true})
    this.hideMouseMoveShort()
    this.hideMouseMoveLong()
  }

  hideMouseMoveShort(){
    this.setState({mouseMovedShort:false})
  }

  hideMouseMoveLong(){
    this.setState({mouseMovedLong:false})
  }


  handleVolumeMouseUp = e => {
    this.setState({ adjustingVolume: false })
    this.setState({ volume: parseFloat(e) })
  }

  handleVolumeMouseDown = e => {
    this.setState({ adjustingVolume: true })   
  }

  handleVolumeChange = e => {
    this.setState({ volume: parseFloat(e) })    
  }

  handleDoubleClick(e){
  	e.stopPropagation()
  }

  handleSkip(skipDirection,skipAmount){
    console.log('handle skip')
    console.log(this.player)
  	const playedSeconds=this.state.playedSeconds
  	let newProgress
  	if(skipDirection==='forward'){
  		newProgress=Math.min(playedSeconds + (skipAmount / 1000),this.state.duration)
  		this.player.seekTo(newProgress,'seconds')
  	}
  	else if(skipDirection==='backward'){
  		newProgress=Math.max(playedSeconds - (skipAmount / 1000),0)
  		this.player.seekTo(newProgress,'seconds')
  	}
  }


	render(){ 
		const { url, playing, controls, played, loaded, duration, volume, zoomed, mouseMovedShort, mouseMovedLong, hasPlayed, adjustingVolume} = this.state
		const progressSeconds = duration * played

		
		const videoTitle = this.props.title
		
		const muxPlaybackID = this.props.playbackId
		const deliveryUrl =  `https://stream.mux.com/${muxPlaybackID}/high.mp4`		


		return (
			<React.Fragment>
				<div >
					<div className='doc-videoInnerContainer'>
						<div onClick={this.handlePlayPause} className='doc-video'>											
							<ReactPlayer   	
                ref={this.ref}		  		  			    
		            className='doc-video-video'
		            width='100%'
		            height='100%'
		            url={deliveryUrl}
		            progressInterval={50} //TODO maybe make this dynamic depending on length of video
		            //pip={pip}
		            playing={playing}
		            muted={this.state.muted}
		            volume={volume}		                 
		            onPlay={this.handlePlay}
		            //onEnablePIP={this.handleEnablePIP}
		            //onDisablePIP={this.handleDisablePIP}
		            onPause={this.handlePause}
		            onEnded={this.handleEnded}
		            onProgress={this.handleProgress}
		            onDuration={this.handleDuration}
		            onEnded={this.handleEnded}
		            onReady={this.handlePlayerReady}
		    			/>
						</div>		
            <div className='playgroundtestvideoButtons'>
              <button>
                prev speaker
              </button>
              
              <button onClick={()=>{this.handleSkip('backward', 15000)}}>
                back 15 sec
              </button>              
              
              <button onClick={this.handlePlayPause}>
                {playing ? 'pause' : 'play'}
              </button>
              
              <button onClick={()=>{this.handleSkip('forward', 15000)}}>
                forward 15 sec
              </button>
              
              <button>
                next speaker
              </button>
            </div>
            <div className='playgroundtestvideoButtonsSeekBar'>
              <div className='doc-video-inlineUI-controlBar-seekBarContainer'>
                <ReactSlider
                  className="doc-video-inlineUI-controlBar-seekBar"
                  thumbClassName="doc-video-inlineUI-controlBar-seekBar-thumb"
                  trackClassName="doc-video-inlineUI-controlBar-seekBar-track"                  
                  renderThumb={(props, state) => <div className='doc-video-inlineUI-controlBar-seekBar-thumb-thumb' {...props} />}

                  value={played}
                  min={0}
                  max={0.999999}
                  step={0.0001}
                  onAfterChange={this.handleSeekMouseUp}
                  onBeforeChange={this.handleSeekMouseDown}
                  onChange={this.handleSeekChange}
                />              
              </div>
            </div>

					</div>					

			
			</div>
				
			</React.Fragment>
		)
	}
}

export default TranscriptVideoPlayer
