const colors = {
	'#bec2c8':'grey',
	'#e2e2e2':'lightGrey',
	'#95a2b3':'darkGrey',
	'#5e6ad2':'purple',
	'#26b5ce':'teal',
	'#0f7488':'darkTeal',
	'#4cb782':'green',
	'#0f783c':'darkGreen',
	'#f2c94c':'yellow',
	'#5a450d':'brown',
	'#f2994a':'orange',
	'#db6e1f':'darkOrange',
	'#f7c8c1':'pink',
	'#eb5757':'red',
	'#c52828':'darkRed',
}



export function getColorForLinearHex(linearHexColor) {	
	return colors[linearHexColor]
	
}
