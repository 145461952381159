import React from 'react'
import * as ContextMenu from '@radix-ui/react-context-menu';
import Icon from '../../../components/misc/Icon'
import {getDocForId} from '../../../utils/getDocForId'
import {openDoc} from '../../../utils/openDoc'
import {getTabStarredStatus,starTab,unstarTab} from '../../../utils/starredTabs'
import {openProjectInLinear,openRoadmapInLinear} from '../../../utils/openInLinear'
import {getProjectForId} from '../../../utils/getProjectForId'
import {copyDocLinkToClipboard,copyRoadmapLinkToClipboard} from '../../../utils/copyDocLinkToClipboard'

class DraggableTabContextMenuContents extends React.Component{  	

	render(){	
		const {
			tabType,
			itemId,
			docType,
			isProjectDoc} = this.props	
		const starred = getTabStarredStatus(tabType,itemId)
		let starLabel
		if(starred){
			if(tabType=='roadmap'){
				starLabel='Unstar Roadmap'
			}else{
				if(isProjectDoc){
					starLabel = 'Unstar Project'
				}else{
					starLabel = 'Unstar Doc'
				}
			}
		}else{
			if(tabType=='roadmap'){
				starLabel='Star Roadmap'
			}else{
				if(isProjectDoc){
					starLabel = 'Star Project'
				}else{
					starLabel = 'Star Doc'
				}
			}
		}
		let linearSlug
		if(isProjectDoc){
			const docObj = getDocForId(itemId)
			const projectId = docObj.project
			const projectObj = getProjectForId(projectId)
			if(projectObj){
				linearSlug = projectObj.slug_id
			}
		}else if(tabType=='roadmap'){
			linearSlug=this.props.roadmapSlug
		}
	
	  return (
			<React.Fragment>
				{tabType=='doc' && docType !== 'privateDoc' &&
					<React.Fragment>
					  <ContextMenu.Item onSelect={()=>{copyDocLinkToClipboard(itemId)}}>
				      Copy Link
				    </ContextMenu.Item>
				    <ContextMenu.Separator />
			  	</React.Fragment>
		  	}
		  	{/*}
		  	{tabType=='roadmap' && docType !== 'privateDoc' &&
					<React.Fragment>
					  <ContextMenu.Item onSelect={()=>{copyRoadmapLinkToClipboard(itemId)}}>
				      Copy Link
				    </ContextMenu.Item>
				    <ContextMenu.Separator />
			  	</React.Fragment>
		  	}
		  	*/}
        {starred &&
        <ContextMenu.Item onSelect={()=>{unstarTab(tabType,itemId)}}>        	
		      {starLabel}
		    </ContextMenu.Item>
		  	}
		  	{!starred &&
        <ContextMenu.Item onSelect={()=>{starTab(tabType,itemId)}}>        	
		      {starLabel}
		    </ContextMenu.Item>
		  	}
				{tabType=='doc' && linearSlug &&  	
		    	<ContextMenu.Item onSelect={()=>{openProjectInLinear(linearSlug)}}>        	
		      	Open Project in Linear
		    	</ContextMenu.Item>
		    }
		    {tabType=='roadmap' && linearSlug &&	
		    	<ContextMenu.Item onSelect={()=>{openRoadmapInLinear(linearSlug)}}>        	
		      	Open Roadmap in Linear
		    	</ContextMenu.Item>
		    }
	    </React.Fragment>
	  )
	}
}

export default DraggableTabContextMenuContents