import React from 'react'


const activeClass='suggestionMenu-item--active'

class InsertMessageEmojiDropdownReactComponent extends React.Component{  

	constructor(){
		super()
		this.handleMouseMove=this.handleMouseMove.bind(this)
		this.handleMouseOver=this.handleMouseOver.bind(this)
		this.handleMouseOut=this.handleMouseOut.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.state = {
			activeIndex:0,
			mouseHasMoved:false
		};
	}

	componentDidUpdate(prevProps){
		if(prevProps.text!==this.props.text){ //reset selected index when text changes
			this.setState({activeIndex:0})
		}
	}	

	handleMouseMove(index){
		if(!this.state.mouseHasMoved){
			this.setState({mouseHasMoved:true,activeIndex:index})
		} 
	}

	handleMouseOver(index){
		if(this.state.mouseHasMoved){
			this.setState({activeIndex:index})
		} 
	}

	handleMouseOut(index){
		this.setState({activeIndex:index})
	}

	componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount(){
	 window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){
		if(e.keyCode === 40) { //down arrow
			e.stopPropagation()
			e.preventDefault()
			const oldIndex=this.state.activeIndex
			let newIndex=oldIndex+1
			if(newIndex==this.props.suggestions.length){
				newIndex=0
			}
			this.setState({activeIndex:newIndex})
		} else if (e.keyCode === 38) { //up arrow
			e.stopPropagation()
			e.preventDefault()
			const oldIndex=this.state.activeIndex
			let newIndex=oldIndex-1
			if(newIndex<0){
				newIndex=this.props.suggestions.length-1
			}
			this.setState({activeIndex:newIndex})
			
		} else if( e.keyCode === 13) { //enter
			e.stopPropagation()
			e.preventDefault()
			if(this.props.suggestions.length){
				this.props.selectIndex(e,this.state.activeIndex)
			}
		} else if (e.keyCode === 27) { //escape
			this.props.hideList()
		} 
	}

	render(){
		const {activeIndex}=this.state
		const {suggestions}=this.props

		if(suggestions.length){
			return (            
				<div className="suggestionMenu suggestionMenu--emoji">					
					{suggestions.map((suggestion,index)=>{
						return(
							<div 
								key={index}
								onMouseMove={()=>{this.handleMouseMove(index)}} 
								onMouseOver={()=>{this.handleMouseOver(index)}} 
								className={"suggestionMenu-item suggestionMenu-item--emoji" + (activeIndex==index?' suggestionMenu-item--active':"")} onClick={(e)=>{this.props.selectIndex(e,activeIndex)}}
							>
								<div className='suggestionMenu-item--emoji-emojiContainer'>
									{suggestion.emoji} 
								</div>
								<div className='suggestionMenu-item-label'>
									<span className='suggestionMenu-item--emoji-label-light'>:</span>
										{suggestion.id}
									<span className='suggestionMenu-item--emoji-label-light'>:</span>									
								</div>
							
								
							</div>
						)
					})}
				</div>      
			)
		}else return (
				null 

		)
	}
}

export default InsertMessageEmojiDropdownReactComponent



				{/*}
				<div className="suggestionMenu suggestionMenu--insert">
					<div className='suggestionMenu-title'>
						No results
					</div>
				</div>      
				*/}