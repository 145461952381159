import store from '../store'
import find from 'lodash/find'

export function getProjectForId(projectId) {	
	let project={}

	const state = store.getState()
	project= find(state.projects, {'project_id':projectId})
		
	return project
}

