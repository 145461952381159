import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../misc/Icon'
import {getDocForId} from '../../utils/getDocForId'
import {createNewDoc} from '../../utils/createNewDoc'
import {calculateDocNotificationCount,calculateAppNotificationCount} from '../../utils/notifications/calculateNotificationCounts'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as Tooltip from '@radix-ui/react-tooltip'
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import DnDTabsList from './dnd/DnDTabsList'
import SideMenuAppTab from './SideMenuAppTab' // drafts and roadmap
import SideMenuOrgTab from './SideMenuOrgTab' // 
import SideMenuRecentTab from './SideMenuRecentTab' // 
import SideMenuSlackTab from './SideMenuSlackTab' // 
import SideMenuTemplateTab from './SideMenuTemplateTab' // 
import SideMenuHelpDocsTab from './SideMenuHelpDocsTab' // 
import sortBy from 'lodash/sortBy'



class SideMenuTabSections extends React.Component{  

	render(){	
		const {organization,pathname,teams}=this.props	
		let orgName=''
		let orgAvatar=''
		if(organization){
			orgName=organization.name
			orgAvatar=organization.logo
		}
		const appNotificationCount=calculateAppNotificationCount()	
		
	  return (
	  	<React.Fragment>
	  		<div className='sideMenu-appTabsContainer'> 		
					<SideMenuOrgTab
						tabUrl={'/'}
						pathname={pathname}
						navigateTo={this.props.navigateTo}
						org={this.props.org}
					/> 
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={300}>
							<Tooltip.Trigger className='sideMenu-searchBtn' onClick={this.props.openOpenDocMenu}>
								<Icon name='magnifyingGlass' />	
							</Tooltip.Trigger>				
							<Tooltip.Content side="right" className="tooltip tooltip--textSelectionUI">																									
								Open 
								<KeyboardShortcutTag
									command={true}											
									letter='T'											
								/>									
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					{/*}
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={300}>
							<Tooltip.Trigger className='sideMenu-newDraftBtn' onClick={this.props.openOpenDocMenu}>
								<Icon name='plus' />	
							</Tooltip.Trigger>				
							<Tooltip.Content side="right" className="tooltip tooltip--textSelectionUI">																																	
								New Draft
								<KeyboardShortcutTag
									command={true}											
									letter='N'											
								/>																													
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					*/}

					<SideMenuAppTab 								
						label="Projects"
						sectionIconName="sideMenuRoadmap"
						tabUrl={'/roadmaps'}
						navigateTo={this.props.navigateTo}
						pathname={pathname}
					/>

					

								
					<SideMenuAppTab 								
						label="Meeting Notes"
						sectionIconName="sideMenuMeetingNotes"
						tabUrl={'/meetings'}
						navigateTo={this.props.navigateTo}
						pathname={pathname}			
						//showNewDraftBtn
					/>

					{/*}
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={300}>
							<Tooltip.Trigger className='sideMenu-newMeetingBtn'>
								<Icon name='plus' />	
							</Tooltip.Trigger>				
							<Tooltip.Content side="right" className="tooltip tooltip--textSelectionUI">																									
								New Meeting
								<KeyboardShortcutTag
									command
									shift
									letter='M'
								/>									
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					*/}
				
					<SideMenuAppTab 								
						label="Your Drafts"
						sectionIconName="sideMenuDrafts"
						tabUrl={'/private'}
						navigateTo={this.props.navigateTo}
						pathname={pathname}			
						//showNewDraftBtn
					/>		

					<SideMenuAppTab 								
						label="Recent"
						sectionIconName="clockRegular"
						tabUrl={'/recent'}
						navigateTo={this.props.navigateTo}
						pathname={pathname}			
						//showNewDraftBtn
					/>		
					{/*
					<SideMenuRecentTab 

					/>
				*/}

	  	</div>

			<div className='sideMenu-scrollableTabContainer'>	
				
				<DnDTabsList 
					docs={this.props.docs}	
					meetings={this.props.meetings}
					recentActivities={this.props.recentActivities}
					messageCount={this.props.messageCount}
				/> {/* pass docs so rerenders when doc renames */}
				
				<div className='sideMenu-bottomActionsContainer'>



					{/*} FOR DEV
					<SideMenuAppTab 								
						label="Playground"
						sectionIconName="avSettings"
						tabUrl={'/playground'}
						navigateTo={this.props.navigateTo}
						pathname={pathname}			
						//showNewDraftBtn
					/>
					*/}
					

					

					<SideMenuTemplateTab />


					<SideMenuAppTab 								
						label="Settings"
						sectionIconName="avSettings"
						tabUrl={'/settings'}
						navigateTo={this.props.navigateTo}
						pathname={pathname}			
						//showNewDraftBtn
					/>	

				
					<SideMenuSlackTab />


					<SideMenuHelpDocsTab />


					<Tooltip.Provider>
						<Tooltip.Root delayDuration={300}>
							<Tooltip.Trigger onClick={this.props.toggleKeyboardShortcutsPanel} className='sideMenu-keyboardBtn' >
								<Icon name='keyboard' />	
							</Tooltip.Trigger>				
							<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI">																									
								Keyboard Shortcuts
								{/*<KeyboardShortcutTag
									command
									letter='T'											
								/>
								*/}									
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					
				</div>

			</div>
			</React.Fragment>		
	  )
	}
}

export default withRouter(SideMenuTabSections)