import store from '../../store'
import editorSchema from '../../prosemirror/schema/editorSchema'
import {AllSelection} from "prosemirror-state"
import {setDocTemplate} from '../templates/setDocTemplate'
import {updateMeetingTypeRequest} from '../../actions/meetings'
import {setMeetingType} from './setMeetingType'
import {getUserForId} from '../getUserForId'




// function addParticipants(doc, participants) {
//     // Deep clone the document to prevent mutating the original
//     let newDoc = JSON.parse(JSON.stringify(doc));

//     // Identify the participant section template
//     let participantSection = newDoc.content[0].content.find(section => section.type === "heading" && section.content[0].text === "[Participant]");
//     let participantSectionIndex = newDoc.content[0].content.indexOf(participantSection);
//     let nextSectionIndex = newDoc.content[0].content.findIndex((section, index) => index > participantSectionIndex && section.type === "heading");
//     let sectionTemplate = newDoc.content[0].content.slice(participantSectionIndex, nextSectionIndex);

//     // Remove the original participant section template
//     newDoc.content[0].content.splice(participantSectionIndex, sectionTemplate.length);

//     // Add new sections for each participant
//     participants.forEach((participantId, index) => {
//         let newSection = JSON.parse(JSON.stringify(sectionTemplate)); // Deep clone the section template
//         let participantName=''
//         const userObj=getUserForId(participantId)
//         if(userObj){
//         	participantName=userObj.display_name
//         }

//         newSection[0].content[0].text = participantName; // Replace the participant name in the heading
//         let insertionIndex = participantSectionIndex + index * newSection.length;
//         newDoc.content[0].content.splice(insertionIndex, 0, ...newSection);
//     });

//     return newDoc;
// }

function addParticipants(doc, participants) {
    let newDoc = JSON.parse(JSON.stringify(doc));

    let participantSection = newDoc.content[0].content.find(section => section.type === "heading" && section.content[0].text === "[Participant]");
    let participantSectionIndex = newDoc.content[0].content.indexOf(participantSection);
    let nextSectionIndex = newDoc.content[0].content.findIndex((section, index) => index > participantSectionIndex && section.type === "heading");
    let sectionTemplate = newDoc.content[0].content.slice(participantSectionIndex, nextSectionIndex);

    newDoc.content[0].content.splice(participantSectionIndex, sectionTemplate.length);

    participants.forEach((participantId, index) => {
        let newSection = JSON.parse(JSON.stringify(sectionTemplate));

        // Replace the heading node with ParticipantHeader node with user_id attribute
        newSection[0] = { type: "participantHeader", attrs: { userId: participantId } };

        let insertionIndex = participantSectionIndex + index * newSection.length;
        newDoc.content[0].content.splice(insertionIndex, 0, ...newSection);
    });

    return newDoc;
}


export function applyTemplateToMeeting(template,docId,meeting,meetingType){
	const meetingId=meeting.meeting_id
	setDocTemplate(docId,template.template_id)
	setMeetingType(meetingId,meetingType)
	if(meetingType!=='teamSync'){
		let view = window.view;
		let tr = view.state.tr;
		tr.setSelection(new AllSelection(view.state.doc)); // Select the whole document
		const node = editorSchema.nodeFromJSON(template.source)
		tr.replaceSelectionWith(node, false); // Replace it with the template
		view.dispatch(tr); // Dispatch the transaction
	}else{
		const participants = meeting.participants;
		let doc = template.source;
		let newDoc = addParticipants(doc, participants);
		let view = window.view;
		let tr = view.state.tr;
		tr.setSelection(new AllSelection(view.state.doc)); // Select the whole document
		const node = editorSchema.nodeFromJSON(newDoc)
		tr.replaceSelectionWith(node, false); // Replace it with the template
		view.dispatch(tr); // Dispatch the transaction
	}

}
