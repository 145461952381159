import React from 'react'
import ReactDOM from 'react-dom';
import LinearIssueReactComponent from './LinearIssueReactComponent'
import {saveUserSettings} from '../../../utils/saveUserSettings'
import {TextSelection} from "prosemirror-state"
import {randomID} from '../../../utils/randomID'

const OPEN_POPOVER_DELAY=500

class LinearIssueMentionNodeView {

	constructor(node, view, getPos) {
		this.node=node 
		this.getPos=getPos
		this.openPopover=this.openPopover.bind(this)
		this.closePopover=this.closePopover.bind(this)
		this.selectNode=this.selectNode.bind(this)
		this.deselectNode=this.deselectNode.bind(this)
		this.updateIssueAttribute=this.updateIssueAttribute.bind(this)
		this.renderReactComponent=this.renderReactComponent.bind(this)
		this.setAsDefaultMentionConfig=this.setAsDefaultMentionConfig.bind(this)
		this.putCursorAfterIssue=this.putCursorAfterIssue.bind(this)

		const randId=randomID()
		this.randId=randId

		this.dom = document.createElement("span")

		this.dom.className="doc-para-linearIssueOuterContainer "
		const {layout,issueId,issueUpdateVersion}=node.attrs //layout is block or inline
		// if(layout==='block'){
		// 	this.dom.classList.add('doc-para-linearIssueOuterContainer--block')
		// }else{
			this.dom.classList.add('doc-para-linearIssueOuterContainer--inline')
	//	}

		this.showPopover=false

		this.timeout=null
		this.renderReactComponent()
	}

	renderReactComponent(){
		// const {layout,issueId,issueUpdateVersion}=this.node.attrs
		const {
			issueId,
      showAssignee,
      showTitle,
      showIdentifier,
      showStatus,
      showPriority,
      layout, 
      issueUpdateVersion}=this.node.attrs

		ReactDOM.render(
			<LinearIssueReactComponent 
				issueId={issueId}
				issueLayout={layout}
				showTitle={showTitle}
				showAssignee={showAssignee}
				showIdentifier={showIdentifier}
				showStatus={showStatus}
				showPriority={showPriority}
				issueUpdateVersion={issueUpdateVersion}
				key={`${this.randId}_${issueUpdateVersion}`}
				updateIssueAttribute={this.updateIssueAttribute}
				showPopover={this.showPopover}
				openPopover={this.openPopover}
				closePopover={this.closePopover}
				setAsDefaultMentionConfig={this.setAsDefaultMentionConfig}
				putCursorAfterIssue={this.putCursorAfterIssue}
			/>, this.dom)
	}


	setAsDefaultMentionConfig(){
		const {issueId,
      showAssignee,
      showTitle,
      showIdentifier,
      showStatus,
      showPriority,
      layout, 
      issueUpdateVersion}=this.node.attrs
		let settings={
			showAssignee:showAssignee,
			showTitle:showTitle,
			showIdentifier:showIdentifier,
			showStatus:showStatus,
			showPriority:showPriority
		}

		saveUserSettings(settings)
	}


	putCursorAfterIssue(){
		let tr = window.view.state.tr
		const nodePos=this.getPos()
		const newSelPos=nodePos+this.node.nodeSize+2
		const selection = new TextSelection(tr.doc.resolve(this.getPos()+this.node.nodeSize))
		tr.setSelection(selection) 
		window.view.dispatch(tr)

	}



	updateIssueAttribute(attribute,value){
		const node=this.getNode()
		const nodePos=this.getPos(node)
		let attributes={...node.attrs}
		attributes[attribute]=value
		let tr = window.view.state.tr  
		tr.setNodeMarkup(nodePos, null, attributes)
		window.view.dispatch(tr)
		
	}

	getNode(){
		return this.node
	}

	update(node) {
		if (node.type !== this.node.type) return false
		const {layout,issueId,issueUpdateVersion}=node.attrs
		// if(layout==='block'){
		// 	this.dom.classList.add('doc-para-linearIssueOuterContainer--block')
		// }else{
			this.dom.classList.add('doc-para-linearIssueOuterContainer--inline')
		//}
		this.node=node
		this.renderReactComponent()
		return true
	}

	openPopover(){
		this.timeout=setTimeout(function() {
			this.showPopover=true
			this.renderReactComponent()
		}.bind(this), OPEN_POPOVER_DELAY)
	}

	closePopover(){
		if(this.timeout){
			clearTimeout(this.timeout)
		}
		if(this.showPopover){
			this.showPopover=false
			this.renderReactComponent()
		}
	}

	selectNode() {		
		this.openPopover()
		this.dom.classList.add('ProseMirror-selectednode')
  }

  deselectNode() {
  	this.closePopover()
  	this.dom.classList.remove('ProseMirror-selectednode')
  }

	destroy() {
		if(this.timeout){
			clearTimeout(this.timeout)
		}
		ReactDOM.unmountComponentAtNode(this.dom)
	}

	stopEvent(event){ 
		return true
	}


}

export default LinearIssueMentionNodeView