import store from '../store'
import includes from 'lodash/includes'
import find from 'lodash/find'
import {makeUrlForDoc} from './openDoc'
import {showToastNotification} from '../actions/toastNotification'


export function copyDocLinkToClipboard(docId) {
	let stem=`https://doc.yarn.so`
	const path=makeUrlForDoc(docId)  
	const url=stem+path
	navigator.clipboard.writeText(url).then(
  () => {
    const userName=docId
    const actionType="copiedDocLink"   
    store.dispatch(showToastNotification(actionType, userName))
  },
  () => {
    /* clipboard write failed */
  }
);
}


export function copyRoadmapLinkToClipboard(roadmapId) {
  let stem=`https://doc.yarn.so`
  const path=`/roadmap/${roadmapId}` 
  const url=stem+path
  navigator.clipboard.writeText(url).then(
  () => {
    const userName=roadmapId
    const actionType="copiedRoadmapLink"   
    store.dispatch(showToastNotification(actionType, userName))
  },
  () => {
    /* clipboard write failed */
  }
);
}

