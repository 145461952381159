import store from '../../store'

export function userHasGCalIntegration() {	
	const state = store.getState()
	let userHasIntegration=false
	if(state.googleIntegration){
		userHasIntegration=true
	}
	return userHasIntegration
}

