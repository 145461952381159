import React from 'react'
import Icon from '../../../misc/Icon'


function formatRecordingLength(duration){
  if (duration < 60) {
    return Math.floor(duration) + 'sec';
  } else if(duration < 60 * 60){
    return Math.floor(duration / 60) + ' min';
  }
  else {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    return `${hours}hr ${minutes} min`;
  }
}


class MessageMediaRecording extends React.Component{  

	constructor(props){
    super(props)    

    this.state={
      
    }    
  }  
  

	render(){	

		const {muxPlaybackID, duration, isAudio}=this.props
		
		const image1 = `https://image.mux.com/${muxPlaybackID}/thumbnail.webp?width=256&fit_mode=preserve&t=${Math.round(duration * 1 / 5)}`
		const image2 = `https://image.mux.com/${muxPlaybackID}/thumbnail.webp?width=64&fit_mode=preserve&t=${Math.round(duration * 2 / 5)}`
		const image3 = `https://image.mux.com/${muxPlaybackID}/thumbnail.webp?width=64&fit_mode=preserve&t=${Math.round(duration * 3 / 5)}`
		const image4 = `https://image.mux.com/${muxPlaybackID}/thumbnail.webp?width=64&fit_mode=preserve&t=${Math.round(duration * 4 / 5)}`

		const durationLabel = formatRecordingLength(duration)
		return (
			<React.Fragment>
				<div className='message-para message-para--autogeneratedStyling'>
					added a recording'
				</div>
			<div className='message-content-recording'>
				<button onClick={this.props.openRecordingModal} className='message-content-recording-btn'>
					<div className='message-content-recording-btn-previewContainer'>
						<div className='message-content-recording-btn-preview'>
							<div className='message-content-recording-btn-preview-iconContainer'>
								<Icon name='playbackPlay' />
							</div>

							{!isAudio && 
								<img src={image1} className='message-content-recording-btn-preview-img' />
							}
							{/* <img src={image2} className='message-content-recording-btn-preview-img' />
							<img src={image3} className='message-content-recording-btn-preview-img' />
							<img src={image4} className='message-content-recording-btn-preview-img' />
							*/}
						</div>
					</div>
					<div className='message-content-recording-btn-rightContainer'>
						<div className='message-content-recording-btn-title message-para'>
							{/*}
							
							*/}
							<div className='message-content-recording-btn-title-label'>
								Play Recording
							</div>							
						</div>
						<div className='message-content-recording-btn-duration'>
							{durationLabel}
						</div>
					</div>					
				</button>			
			</div>
			</React.Fragment>
		)
	}
}
 


export default MessageMediaRecording
