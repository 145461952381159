import React from 'react'
import Icon from '../../misc/Icon'

class DocTableNewRowButton extends React.Component{  
	render(){
	  return (
	  
		  	<button className='doc-table-newRowBtn' onClick={this.props.addNewRow} onMouseDown={(e)=>{e.stopPropagation()}}>
		  		<Icon name='plus' />
		  	</button>
		  	
	
	  )
	}
}

export default DocTableNewRowButton
