import React from 'react';
import { track, useEditor } from "@tldraw/tldraw"
import { useEffect } from 'react'
import { useCanUndo } from '@tldraw/tldraw'
import { useCanRedo } from '@tldraw/tldraw'
import { DefaultColorStyle } from '@tldraw/tldraw'
import { useValue } from '@tldraw/state'
import { getSvgAsImage,TLExportType} from "@tldraw/tldraw"

const TLDrawUI = track(() => {
 const editor = useEditor()
 const canUndo = useCanUndo()
 const canRedo = useCanRedo()


  const currentColor = useValue(
    'current color',
    () => {
      const color = editor.sharedStyles.get(DefaultColorStyle)
      console.log('here-----')
      console.log(color)
      return color
    },
    [editor]
  )

 useEffect(() => {
   const handleKeyUp = (e: KeyboardEvent) => {
     switch (e.key) {
       case 'Delete':
       case 'Backspace': {
         editor.deleteShapes()
       }
     }
   }

   window.addEventListener('keyup', handleKeyUp)
   return () => {
     window.removeEventListener('keyup', handleKeyUp)
   }
 })

const saveChanges = () => {
  const options={
    type:'png',
    quality: 1,
    scale: 2,
  }
  console.log(editor.selectedIds)
  editor.selectAll()
  console.log(editor.selectedIds)
  editor.getSvg(editor.selectedIds).then((svg)=>{
    console.log('svg')
    console.log(svg)
    getSvgAsImage(svg, options).then((image)=>{
      console.log(image)
    })
  })

  // getSvgAsImage(options).then((response)=>{
  //   console.log('svg is-----')
  //   console.log(response)
  // })

};

 console.log('here-------!!! custom ui')
 console.log(editor)
 console.log('edioooor')
 
 console.log(`can undo????---- ${canUndo}`)
 console.log(`current color----`)
 console.log(currentColor)

 // console.log(editor.tools)
 // console.log(editor.currentToolId)

 return (
   <div className="custom-layout">
     <div className="custom-toolbar">
       <button
         className="custom-button"
         data-isactive={editor.currentToolId === 'select'}
         onClick={() => editor.setSelectedTool('select')}
       >
         Select
       </button>
       <button
         className="custom-button"
         data-isactive={editor.currentToolId === 'draw'}
         onClick={() => editor.setSelectedTool('draw')}
       >
         Pencil
       </button>
       <button
         className="custom-button"
         data-isactive={editor.currentToolId === 'eraser'}
         onClick={() => editor.setSelectedTool('eraser')}
       >
         Eraser
       </button>


       <button
         className="custom-button"
         data-isactive={editor.currentToolId === 'text'}
         onClick={() => editor.setSelectedTool('text')}
       >
         text
       </button>

        <button
         className="custom-button"
         data-isactive={canUndo}
         onClick={() => editor.undo()}
         >
         undo
       </button>
       <button
         className="custom-button"
         data-isactive={canRedo}
         onClick={() => editor.redo()}
         >
         redo
       </button>

        <button
         className="custom-button"
         data-isactive={currentColor && currentColor.value=='blue'}
         onClick={() => editor.setStyle(DefaultColorStyle,'blue')}
         >
         blue
       </button>
       <button
         className="custom-button"
         //data-isactive={}
         data-isactive={currentColor && currentColor.value=='red'}
         onClick={() => editor.setStyle(DefaultColorStyle,'red')}
         >
         red
       </button>
        <button
         className="custom-button"
         onClick={saveChanges}
         >
         save
       </button>

     </div>
   </div>
 )
})
export default TLDrawUI
