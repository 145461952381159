import React from 'react'
import Icon from '../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as ContextMenu from '@radix-ui/react-context-menu';
import { withRouter} from 'react-router-dom'
import {openRoadmapInLinear} from '../../utils/openInLinear'
import {getTabStarredStatus,starTab,unstarTab} from '../../utils/starredTabs'
import {uploadRoadmapCoverImage,replaceRoadmapCoverImage} from '../../utils/uploadRoadmapCoverImage'
import {removeRoadmapCoverImage} from '../../utils/removeRoadmapCoverImage'

class RoadmapPageHeader extends React.Component{  

	constructor(props){
		super(props)
		this.handleSelectCoverImageFile=this.handleSelectCoverImageFile.bind(this)
		this.handleOpenInLinearClick=this.handleOpenInLinearClick.bind(this)
		this.removeCoverImage=this.removeCoverImage.bind(this)
		this.replaceCoverImage=this.replaceCoverImage.bind(this)
		this.state={      
      showDropdown: false,
    }
	} 

	handleSelectCoverImageFile(e){
		this.setState({showDropdown: false})
		const fileList = e.target.files
		const file = fileList[0]
		const src=null
		const thumbnail=null
		let fileName=file.name
		const extensions=['.png','.jpg','.jpeg','.tif','.tiff','.webp','.gif']
		//this.insertImage(file)		
		uploadRoadmapCoverImage(file,this.props.roadmap.roadmap_id)
	}

	handleOpenInLinearClick(e){
		this.setState({showDropdown: false})
		openRoadmapInLinear(this.props.roadmap.slug_id)
	}

	removeCoverImage(){
		this.setState({showDropdown: false})
		removeRoadmapCoverImage(this.props.roadmap.roadmap_id)
	}

	replaceCoverImage(e){
		this.setState({showDropdown: false})
		const fileList = e.target.files
		const file = fileList[0]
		const src=null
		const thumbnail=null
		let fileName=file.name
		const extensions=['.png','.jpg','.jpeg','.tif','.tiff','.webp','.gif']
		//this.insertImage(file)		
		replaceRoadmapCoverImage(file,this.props.roadmap.roadmap_id)
	}


	render(){	
		const {roadmap,roadmapImageUploads}=this.props	
		const {showDropdown}=this.state	
		const {cover_image_cloudinary_id} = roadmap	
		const uploading=roadmapImageUploads
		const hasCoverImage = cover_image_cloudinary_id?true:false
		const starred = getTabStarredStatus('roadmap',roadmap.roadmap_id)
		const coverImageSrc = `https://res.cloudinary.com/yarn/image/upload/q_auto/v1678410992/${cover_image_cloudinary_id}.png`

		//const coverImageSrc = https://res.cloudinary.com/yarn/image/upload/q_auto/v1678410992/dummydata/desktop_example_d0unpm.png'
		//const coverImageSrc = 'https://images.unsplash.com/photo-1589887305888-6254d60b5308?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2880&q=80'
		//const coverImageSrc = 'https://images.unsplash.com/photo-1620641788421-7a1c342ea42e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3474&q=80'
		//const coverImageSrc = 'https://images.unsplash.com/photo-1541701494587-cb58502866ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80'
		//const coverImageSrc= 'https://images.unsplash.com/photo-1506744038136-46273834b3fb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3540&q=80'
		//const coverImageSrc = 'https://storage.googleapis.com/pai-images/0e96459dd7ee401d985f3c3199ff0349.jpeg'

	  return (
			<div className='roadmapPage-headerContainer'>
				{hasCoverImage && 
					<ContextMenu.Root > 
						<ContextMenu.Trigger asChild>	
							<div className='roadmapPage-headerCoverImageContainer'>
								<div className='roadmapPage-headerCoverImage'>
									<img 															
										src={coverImageSrc}
										className='roadmapPage-headerCoverImage-image'																
									/>
								</div>
							</div>
						</ContextMenu.Trigger>
						<ContextMenu.Portal>
						<ContextMenu.Content collisionPadding={10} className='contextMenu'>																
				        	<div role='menuitem' className='contextMenu-item contextMenu-item--fileSelect'>						        	
				        		<input
											id='file'
											type='file'
											value={''} 
											className='dropdownMenu-item--fileSelect-input'
											accept={'.png,.jpg,.jpeg,.bmp,.tif,.tiff,.webp,.gif'}
											onChange={this.replaceCoverImage}							     
										/>
										<label htmlFor="file">
				        			Replace Cover Image
				        		</label>						        	
			        		</div>						        		        
				        	<ContextMenu.Item className='contextMenu-item--warning' onSelect={this.removeCoverImage}>
				        		Remove
				        	</ContextMenu.Item> 	
																		
							</ContextMenu.Content>
						</ContextMenu.Portal>
					</ContextMenu.Root>		  	
				}

				{uploading && !hasCoverImage &&
					<div className='roadmapPage-headerCoverImageContainer'>
						<div className='roadmapPage-headerCoverImage roadmapPage-headerCoverImage--placeholder'>
							
							<div className='roadmapPage-headerCoverImage-uploadingSpinnerContainer'>
								<div className='roadmapPage-headerCoverImage-uploadingSpinner'>
									<div className='roadmapPage-headerCoverImage-uploadingSpinner-spinner' />
								</div>
							</div>
						</div>
					</div>
				}

				
				{!hasCoverImage && !uploading &&
					<div className='roadmapPage-headerTopSpacer'>
						{/*}
						<button>upload image
								<input 
									id='file'
									type='file'
									className='message-inputBox-addMediaBtn--image-hiddenFileInput'
									value={''} 
									accept={'.png,.jpg,.jpeg,.bmp,.tif,.tiff,.webp,.gif'}
									onChange={this.handleSelectCoverImageFile}
								/>
						 </button>
					 	*/}
					</div>				
				}
				

				<div className='roadmapPage-header'>		
					<div className='roadmapPage-header-leftSpacer'>
						{/*}
						<div className='roadmapPage-header-backBtnContainer'>
							<button onClick={()=>{this.props.history.push('/roadmaps')}} className='roadmapPage-header-backBtn'>
								<div className='roadmapPage-header-backBtn-backIconContainer'>
									<Icon name='chevronRight' />
								</div>
										
							</button>
						</div>
						*/}
					</div>			
					<div className='roadmapPage-header-centerContainer'>					
						<div className='roadmapPage-header-topRow'>													
							<div className='roadmapPage-header-topRow-iconContainer'>													
								<Icon name='roadmapOutlineLight' />
							</div>
							<div className='roadmapPage-header-topRow-title'>
								{roadmap.name}
							</div>						
							<div className='roadmapPage-header-topRow-rightContainer'>		  					  				
		  					{starred && 
				  				<button onClick={()=>{unstarTab('roadmap',roadmap.roadmap_id)}} className='roadmapPage-header-topRow-btn roadmapPage-header-topRow-btn--star'>
										<Icon name='starFill' />
									</button>
								}
								{!starred && 
				  				<button onClick={()=>{starTab('roadmap',roadmap.roadmap_id)}}  className='roadmapPage-header-topRow-btn roadmapPage-header-topRow-btn--star'>
										<Icon name='star' />
									</button>
								}
			  				<DropdownMenu.Root open={this.state.showDropdown}>
							    <DropdownMenu.Trigger asChild>
							    	<button onClick={()=>{this.setState({showDropdown: !this.state.showDropdown})}} className='roadmapPage-header-topRow-btn roadmapPage-header-topRow-btn--dropdown'>
			  							<Icon name='ellipses' />
			  						</button>
							    </DropdownMenu.Trigger>   			 	
				   			 	<DropdownMenu.Content 
				   			 		sideOffset={1}
				   			 		align="end"
				   			 		alignOffset={-8}
				   			 		className='dropdownMenu dropdownMenu--roadmapHeader'
				   			 		onEscapeKeyDown={()=>{this.setState({showDropdown: false})}}
										onPointerDownOutside={()=>{this.setState({showDropdown: false})}}
										onInteractOutside={()=>{this.setState({showDropdown: false})}}
				   			 	> 
					        	<DropdownMenu.Item onSelect={this.handleOpenInLinearClick}>
					        		Open in Linear
					        	</DropdownMenu.Item> 					        	
					        	<DropdownMenu.Separator />	
					        	{!hasCoverImage && 					        		
						        	<div role='menuitem' className='dropdownMenu-item dropdownMenu-item--fileSelect'>						        	
						        		<input
													id='file'
													type='file'
													value={''} 
													className='dropdownMenu-item--fileSelect-input'
													accept={'.png,.jpg,.jpeg,.bmp,.tif,.tiff,.webp,.gif'}
													onChange={this.handleSelectCoverImageFile}							     
												/>
												<label htmlFor="file">
						        			Add Cover Image...
						        		</label>						        	
						        	</div>						        	
					        	}					        	
					        	{hasCoverImage && 
					        		<React.Fragment>
					        			<div className='contextMenu-subMenu--moveDoc-header'>
													Cover Image
												</div>
							        	<div role='menuitem' className='dropdownMenu-item dropdownMenu-item--fileSelect'>						        	
							        		<input
														id='file'
														type='file'
														value={''} 
														className='dropdownMenu-item--fileSelect-input'
														accept={'.png,.jpg,.jpeg,.bmp,.tif,.tiff,.webp,.gif'}
														onChange={this.replaceCoverImage}							     
													/>
													<label htmlFor="file">
							        			Replace...
							        		</label>						        	
						        		</div>						        		        
							        	<DropdownMenu.Item className='dropdownMenu-item--warning' onSelect={this.removeCoverImage}>
							        		Remove
							        	</DropdownMenu.Item> 			        	
					        		</React.Fragment>
					        	}
					        {/*}
				        	<DropdownMenu.Separator />	
					        	
					        	*/}
							    </DropdownMenu.Content>
								</DropdownMenu.Root>	  							
			  			</div>			
						</div>
						<div className='roadmapPage-header-subRow-description'>		  				
							{roadmap.description}
		  			</div>			
		  		</div>	
		  		<div className='roadmapPage-header-rightSpacer' />				  	
		  	</div>	
	  	</div>
	  )
	}
}

export default withRouter(RoadmapPageHeader)



					        	{/*} OTHER DROPDOWN MENU APPROACHES SHOW EVERYTHING APPROACH
					        	<DropdownMenu.Item>
					        		Sort A - Z
					        	</DropdownMenu.Item> 
					        	<DropdownMenu.Item>
					        		Sort by Target Date
					        	</DropdownMenu.Item> 					        	
					        	<DropdownMenu.Separator />
					        	<DropdownMenu.Item>
					        		Group by status
					        	</DropdownMenu.Item> 
					        	<DropdownMenu.Item>
					        		Group by lead
					        	</DropdownMenu.Item> 					        	
					        	<DropdownMenu.Separator />
					        	*/}

					        	{/* SUB MENU APPROACH 
					        	<div className='contextMenu-subMenu--moveDoc-header'>
											Roadmap
										</div>
										<DropdownMenu.Sub>
											<DropdownMenu.SubTrigger>
												Sort Projects
												<div className='dropdownMenu-item-subMenuArrow'>
													<Icon name='chevronRight' />
												</div>
											</DropdownMenu.SubTrigger>											
												<DropdownMenu.SubContent collisionPadding={20} className='dropdownMenu dropdownMenu-subMenu dropdownMenu-subMenu--roadmapSort'>																					
													<DropdownMenu.Item>
														A - Z
													</DropdownMenu.Item>
													<DropdownMenu.Item>
														Target Date
													</DropdownMenu.Item>
												</DropdownMenu.SubContent>											
										</DropdownMenu.Sub>  

										<DropdownMenu.Sub>
											<DropdownMenu.SubTrigger>
												Group Projects
												<div className='dropdownMenu-item-subMenuArrow'>
													<Icon name='chevronRight' />
												</div>
											</DropdownMenu.SubTrigger>											
												<DropdownMenu.SubContent collisionPadding={20} className='dropdownMenu dropdownMenu-subMenu dropdownMenu-subMenu--roadmapSort'>																					
													<DropdownMenu.Item>
														By Status
													</DropdownMenu.Item>
													<DropdownMenu.Item>
														By Lead
													</DropdownMenu.Item>
												</DropdownMenu.SubContent>											
										</DropdownMenu.Sub>  

										<DropdownMenu.Sub>
											<DropdownMenu.SubTrigger>
												Completed Projects
												<div className='dropdownMenu-item-subMenuArrow'>
													<Icon name='chevronRight' />
												</div>
											</DropdownMenu.SubTrigger>											
												<DropdownMenu.SubContent collisionPadding={20} className='dropdownMenu dropdownMenu-subMenu dropdownMenu-subMenu--roadmapSort'>																					
													<DropdownMenu.Item>
														Show all
													</DropdownMenu.Item>
													<DropdownMenu.Item>
														Show last 14 days
													</DropdownMenu.Item>
												</DropdownMenu.SubContent>											
										</DropdownMenu.Sub>  
										*/}
