import React from 'react'
import LinearNativeIcon from './LinearNativeIcon'
import {getColorForLinearHex} from '../../utils/getColorForLinearHex'
import data from '../../emojiData'
import {getTeamForId} from '../../utils/getTeamForId'
import {getProjectForId} from '../../utils/getProjectForId'

function getEmojiForString(string){
  const natives=data.natives
  const keys=Object.keys(natives)
  let emoji
  keys.map((key)=>{
    if(natives[key]==string && !emoji){
      emoji=key
    }
  })
  return emoji
}

const regExp = ':(.*):' //match :heart:

//teamId projectId
class LinearMarker extends React.Component{    
  render(){ 
    const {teamId,projectId}=this.props

    let item //item is a team or project
    let itemType
    let color=this.props.color //allow these to be passed as props for the edit project modal where show new icon before its saved
    let icon=this.props.icon
    if(teamId){
      item=getTeamForId(teamId)
      itemType='team'
    }else if(projectId){
      item=getProjectForId(projectId)
      itemType='project'
    }
    
    // no icon for project
    if(!icon){ //is passed as prop use that
      if(item && !item.icon && itemType==='project'){      
        icon='Project'
      }
    }
   
    // no icon for team
    if(item && !item.icon && itemType==='team'){      
      icon='Team'
    }

    if(item && item.icon){
      if(!icon){
        icon=item.icon
      }
      
    }

    if(item && item.color){
      if(!color){
        color=item.color
      }
      
    }
    
    //
    // Can be named color or custom color
    // if named color (e.g. 'dark red') then we switch to our palette
    // if custom color, then we apply that color
    let customColor
    const namedColor = getColorForLinearHex(color)
    if(!namedColor){
      customColor=color
    }



    //
    let isEmoji = false
    let emojiCharacter
    if(icon){
      var match = icon.match(regExp)
        if(match && match[1]){
      isEmoji=true
      const emojiString=match[1]
      //const emojiString="hand"
      emojiCharacter=getEmojiForString(emojiString)
    }
    }

  

    return (
      <div className={'linearMarkerContainer ' + (isEmoji ? ' linearMarkerContainer--markerType--emoji ' : ' linearMarkerContainer--markerType--icon ') + (namedColor ? `linearMarkerContainer--color--${namedColor}` : '')}>
      
        {isEmoji &&
          <div className='linearMarker'>            
            {emojiCharacter}
          </div>
        }

        {!isEmoji && icon && 
          <div className='linearMarker'>
            <LinearNativeIcon fill={customColor} name={icon} />
          </div>
        }        
      
      </div>
    )
  }
}

export default LinearMarker

    // console.log(data)
    // console.log(data.emojis.heart)


    // console.log(`test------------------`)
    // console.log(test)

    // const color = 'grey'
    // const color = 'lightGrey'
    // const color = 'darkGrey'
    //const color = 'purple'
    // const color = 'teal'
    // const color = 'darkTeal'
    // const color = 'green'
    // const color = 'darkGreen'
    // const color = 'yellow'
    // const color = 'brown'
    // const color = 'orange'
    // const color = 'darkOrange'
    // const color = 'pink'
    // const color = 'red'
    // const color = 'darkRed'
