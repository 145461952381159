import React from 'react'
import Icon from '../../../misc/Icon'
import StaticCheckbox from '../../../uiKit/StaticCheckbox'
import {addMeetingToGCal} from '../../../../utils/meetings/addMeetingToGCal' 

class DocEditorMeetingContentAddToGCalPopoverContents extends React.Component{  

	constructor(props) {
    super(props)       	
    this.state = {
    	
   	};    
  }

	render(){	
		const {meeting} = this.props	


	  return (
	  	<React.Fragment>	  		
	  		<div className='meetingContentAddToGCalPopover-notifications'>
	  			<button data-is-checked={true} className="selectUserPopover-item">						
						<div className='selectUserPopover-item-username'>
							Send notification emails to all participants
						</div>
						<div className='selectUserPopover-item-checkboxContainer'>
							<div className='selectUserPopover-item-checkbox'>						
								<Icon name='toDoItemCheckmark' />						
							</div>
						</div>
					</button>	
					<button data-is-checked={false} className="selectUserPopover-item">						
						<div className='selectUserPopover-item-username'>
							Create event without sending notification emails
						</div>
						<div className='selectUserPopover-item-checkboxContainer'>
							<div className='selectUserPopover-item-checkbox'>						
								<Icon name='toDoItemCheckmark' />						
							</div>
						</div>
					</button>	
	  		</div>
	  		<div className='meetingContentAddToGCalPopover-addDocToMeeting-border' />

	  		<div className='meetingContentAddToGCalPopover-addDocToMeeting'>
	  			<button data-is-checked={true} className="selectUserPopover-item">						
						<div className='selectUserPopover-item-username'>
							Add link to meeting doc
						</div>
						<div className='selectUserPopover-item-checkboxContainer'>
							<div className='selectUserPopover-item-checkbox'>						
									<Icon name='toDoItemCheckmark' />						
							</div>
						</div>
					</button>
	  		</div>
	  		<button  onClick={()=>{addMeetingToGCal(this.props.meeting)}} className='meetingContentAddToGCalPopover-submitBtn'>
	  				Create Event
	  		</button>
			</React.Fragment>
	  )
	}
}

export default DocEditorMeetingContentAddToGCalPopoverContents
