import {fetch,put,post,batchedFetch} from '../api'
import {showToastNotification} from './toastNotification'
import {closeArchivedTab} from '../utils/closeArchivedTab'


export function createContactRequest(name,company,email) {
	console.log('create contact----------')
	const requestBody={
		name:name,
		company:company,
		email:email
	}
	return (dispatch) => post(`/contacts`,requestBody)
		.then((response) => {
			console.log(response)
			dispatch({ type: 'CREATE_CONTACT_SUCCESS', response })
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
}


export function fetchContacts(){
	return (dispatch) => fetch(`/contacts`)
	.then((response) => {
		dispatch({ type: 'FETCH_CONTACTS_SUCCESS', response })
		return response
	})
	.catch((error) => {
		return error
	})
}


export function archiveContact(contactId) {
	console.log('archive contact')
	return (dispatch) =>{ 
		dispatch({ type: 'ARCHIVE_CONTACT_REQUEST', contactId })
		return put(`/contacts/${contactId}/archive`)
		.then((response) => {
			console.log('response---------------------')
			console.log(response)
			dispatch({ type: 'ARCHIVE_CONTACT_SUCCESS', contactId})
			return response
		})
		.catch((error) => {
			return error
		})
	}
}
