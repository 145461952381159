import React from 'react'
import ReactDOM from 'react-dom';
import DocTableNewColumnButton from './DocTableNewColumnButton'
import DocTableNewRowButton from './DocTableNewRowButton'
import DocTableHoverMenu from './DocTableHoverMenu'
import DocTableSelectionBox from './DocTableSelectionBox'
import DocTableToggleHeaderRowButton from './DocTableToggleHeaderRowButton'
import DocTableToggleHeaderColumnButton from './DocTableToggleHeaderColumnButton'
import {addColumnBefore,addRowBefore,toggleHeaderRow,toggleHeaderColumn,deleteTable,selectionCell,CellSelection} from 'prosemirror-tables'
import {addRow,addColumn,TableMap,deleteColumn,removeRow} from 'prosemirror-tables'
import {getAnchorNode} from '../../../prosemirror/utils/getAnchorNode' 
import {getNodePos} from '../../../prosemirror/utils/getNodePos' 
import { Mixpanel } from '../../../Mixpanel'

const INNER_DOC_WIDTH=650
const DOC_DIV_ID='scrollable_div'

function measureOuterDocWidth(){
  let width=1159
  const el=document.getElementById('scrollable_div')
    if(el){
      const rect=el.getBoundingClientRect()
      if(rect){
        width=rect.width
      }
    }
    return width
}

function measureInnerDocPageOffset(){
  // offset from doc to outer doc
  // normally should be half difference of widths (i.e. centered)
  // but in private docs and other situations, it might not be centered
  const outerDocEl=document.getElementById('scrollable_div')
  const innerDocEl=document.getElementById('docEdge_div')
  let outerDocX
  let innerDocX 
  if(outerDocEl){
    const outerDocRect=outerDocEl.getBoundingClientRect()
    if(outerDocRect){
      outerDocX=outerDocRect.x
    }
  }
  if(innerDocEl){
    const innerDocRect=innerDocEl.getBoundingClientRect()
    if(innerDocRect){
      innerDocX=innerDocRect.x
    }
  }
  let offset = 0
  if(outerDocX && innerDocX){
    offset = innerDocX - outerDocX
  }
  return offset
}

function measureInnerDocOffset(){
  // offset from doc to outer doc
  // normally should be half difference of widths (i.e. centered)
  // but in private docs and other situations, it might not be centered
  const innerDocEl=document.getElementById('docEdge_div')
  let innerDocX 
  if(innerDocEl){
    const innerDocRect=innerDocEl.getBoundingClientRect()
    if(innerDocRect){
      innerDocX=innerDocRect.x
    }
  }
  return innerDocX
}

function measureTableNode(dom){
  let tableContentWidth = 650
  const table = dom.querySelector("table");
  if(table){
    const rect=table.getBoundingClientRect()
    if(rect){
      tableContentWidth=rect.width
    }
  }
  return tableContentWidth
}


function calculateActiveCellAllignment(){
  let activeAlignment=null
  let hasMultipleAlignments=false
  if(window.view){
    let state=window.view.state
    const {selection}=state
    if(selection instanceof CellSelection){
      selection.forEachCell((cell, pos) => {
        if(!activeAlignment || activeAlignment==cell.attrs.align){
          activeAlignment=cell.attrs.align
        }else{
          hasMultipleAlignments=true
        }
      })
      if(hasMultipleAlignments){
        activeAlignment=null
      }
    }
    else{ //single cell
      const {$from}=selection
      const anchorNode=getAnchorNode($from,'table_cell')
      if(anchorNode){
        activeAlignment=anchorNode.attrs.align
      }
    }
  }

  return activeAlignment
}


function calculateActiveCellColor(){
  let activeColor=null
  // let hasMultipleColors=false
  if(window.view){
    let state=window.view.state
    const {selection}=state
    if(selection instanceof CellSelection){
      selection.forEachCell((cell, pos) => {
        if(!activeColor || activeColor==cell.attrs.background){
          activeColor=cell.attrs.background
        }else{
          activeColor='multi'
        }
      })
    }
    else{ //single cell
      const {$from}=selection
      const anchorNode=getAnchorNode($from,'table_cell')
      if(anchorNode){
        activeColor=anchorNode.attrs.background
      }
    }
  }
  return activeColor
}



function findVerticalCell($cell) {
  const table = $cell.node(-1);
  const currentRowIndex = $cell.index(-1);
  const currentColumnIndex = $cell.index();
  if (currentRowIndex > 0) {
    const newRowIndex=currentRowIndex-1
    const rowNode = table.child(newRowIndex)
    const pos=getNodePos(rowNode.child(currentColumnIndex))
    return pos
  }
  return null;
}

function findNextCell($cell) {
  const before = $cell.nodeBefore;
  if (before) return $cell.pos - before.nodeSize;
  for (
    let row = $cell.index(-1) - 1, rowEnd = $cell.before();
    row >= 0;
    row--
  ) {
    const rowNode = $cell.node(-1).child(row);
    const lastChild = rowNode.lastChild;
    if (lastChild) {
      return rowEnd - 1 - lastChild.nodeSize;
    }
    rowEnd -= rowNode.nodeSize;
  }
}




const ALTERNATING_ROW_BG_CLASSNAME='doc-tableContainer--alternatingRowHighlight'
const HEADER_ROW_CLASSNAME="doc-tableContainer--hasHeaderRow"
const HEADER_COLUMN_CLASSNAME="doc-tableContainer--hasHeaderColumn"

class DocTableNodeView {

  constructor(node, view, getPos,newNodeMessage,docId) {
    this.toggleAlternatingRowBG=this.toggleAlternatingRowBG.bind(this)
    this.renderHoverMenu=this.renderHoverMenu.bind(this)
    this.fitTableToMaxColWidthWidth=this.fitTableToMaxColWidthWidth.bind(this)
    this.alignSelectedCells=this.alignSelectedCells.bind(this)
    this.toggleHeaderRow=this.toggleHeaderRow.bind(this)
    this.fitTableToDocWidth=this.fitTableToDocWidth.bind(this)
    this.setSelectedCellsBackgroundColor=this.setSelectedCellsBackgroundColor.bind(this)
    this.toggleHeaderColumn=this.toggleHeaderColumn.bind(this)
    this.addClassnames=this.addClassnames.bind(this)
    this.addNewRow=this.addNewRow.bind(this)
    this.removeLastRow=this.removeLastRow.bind(this)
    this.removeLastColumn=this.removeLastColumn.bind(this)
    this.styleTable=this.styleTable.bind(this)
    this.addNewColumn=this.addNewColumn.bind(this)
    this.deleteTable=this.deleteTable.bind(this)
    this.createNewNodeMessage=this.createNewNodeMessage.bind(this)
    this.node = node
    this.getPos=getPos
    this.newNodeMessage=newNodeMessage
    this.docId=docId
    window.addEventListener('resize', ()=>{    
      requestAnimationFrame(() => {
        this.styleTable()
       })
    })
    //UI bits
    this.dom = document.createElement("div")
    this.dom.id=node.attrs.nodeId
    this.dom.className="doc-tableContainer"    
    if(node.attrs.alternatingRowBG){
      this.dom.classList.add(ALTERNATING_ROW_BG_CLASSNAME)
    }

    this.tableInnerContainer = document.createElement("div")
    this.tableInnerContainer.className="doc-tableInnerContainer"
    this.dom.appendChild(this.tableInnerContainer)
      
    this.columnButtonDOM = document.createElement("div")
    this.columnButtonDOM.className="doc-table-newColumnBtnContainer"
    ReactDOM.render(<DocTableNewColumnButton addNewColumn={this.addNewColumn}/>, this.columnButtonDOM)
    this.tableInnerContainer.appendChild(this.columnButtonDOM)

    this.rowButtonDOM = document.createElement("div")
    this.rowButtonDOM.className="doc-table-newRowBtnContainer"
    ReactDOM.render(<DocTableNewRowButton addNewRow={this.addNewRow}/>, this.rowButtonDOM)
    this.tableInnerContainer.appendChild(this.rowButtonDOM)

    this.hoverMenuDom = document.createElement("div")
    this.hoverMenuDom.className="doc-table-actionMenuContainer"
    this.hoverMenuDom.contentEditable = false
    // this.dom.appendChild(this.hoverMenuDom)


    this.tableContentDiv = document.createElement("div")
    this.tableContentDiv.className="doc-table"
    this.tableInnerContainer.appendChild(this.tableContentDiv)
    this.contentDOM=this.tableContentDiv

    this.sideMenuElement = document.getElementById('sideMenuContainerSpacer');
    this.resizeObserver = new ResizeObserver(entries => {      
      for(let entry of entries) {
         requestAnimationFrame(() => {
            this.styleTable()
           })
      }
    });
    this.resizeObserver.observe(this.sideMenuElement);

    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "style" && mutation.target.tagName === "TABLE") {
          const rect=mutation.target.getBoundingClientRect()
          this.styleTable(rect)
        }
      });
    });

    this.observer.observe(this.dom, {
      attributes: true,
      subtree: true,
      characterData: true,
      characterDataOldValue: true,
      childList: true,
    });

    requestAnimationFrame(() => {
      this.styleTable()
    })

    this.renderHoverMenu()
    this.addClassnames()
  }

  addClassnames(){
    const node=this.node 
    const attrs=node.attrs 
    const {headerRow,headerColumn}=attrs
    if(headerRow){
      this.dom.classList.add(HEADER_ROW_CLASSNAME)
    } else{
      this.dom.classList.remove(HEADER_ROW_CLASSNAME)
    }
    if(headerColumn){
      this.dom.classList.add(HEADER_COLUMN_CLASSNAME)
    } else{
      this.dom.classList.remove(HEADER_COLUMN_CLASSNAME)
    }

  }

  renderHoverMenu(){
    const {attrs}=this.node 
    const {headerRow,headerColumn,alternatingRowBG}=attrs
    let tableNode
    this.node.descendants((childNode, childPos) => {
      if (childNode.type.name === "table") {  
        tableNode=childNode  
      }  
    });
    let width 
    let height
    if(tableNode){
      const map=TableMap.get(tableNode)
      if(map){
        width=map.width 
        height=map.height
      } 
    }
    const activeCellAlignment=calculateActiveCellAllignment()
    const activeCellColor=calculateActiveCellColor()

    ReactDOM.render(
      <DocTableHoverMenu 
        alternatingRowBG={alternatingRowBG}
        fitTableToMaxColWidthWidth={this.fitTableToMaxColWidthWidth}
        toggleAlternatingRowBG={this.toggleAlternatingRowBG}
        fitTableToDocWidth={this.fitTableToDocWidth}
        alignSelectedCells={this.alignSelectedCells}
        activeCellAlignment={activeCellAlignment}
        activeCellColor={activeCellColor}
        deleteTable={this.deleteTable}
        width={width}
        height={height}
        toggleHeaderRow={this.toggleHeaderRow}
        toggleHeaderColumn={this.toggleHeaderColumn}
        headerRow={headerRow}
        headerColumn={headerColumn}
        addNewRow={this.addNewRow} 
        removeLastRow={this.removeLastRow}
        removeLastColumn={this.removeLastColumn}
        setSelectedCellsBackgroundColor={this.setSelectedCellsBackgroundColor}
        addNewColumn={this.addNewColumn}/>, 
      this.hoverMenuDom)
  }

  toggleHeaderRow(){//row or column
    const node=this.node
    const nodePos=this.getPos()
    let attributes={...node.attrs}
    attributes.headerRow=!node.attrs.headerRow
    let tr = window.view.state.tr  
    tr.setNodeMarkup(nodePos, null, attributes)
    window.view.dispatch(tr)
    Mixpanel.track('table_toggle_header_row',{value:attributes.headerRow})
  }

  toggleHeaderColumn(){//row or column
    const node=this.node
    const nodePos=this.getPos()
    let attributes={...node.attrs}
    attributes.headerColumn=!node.attrs.headerColumn
    let tr = window.view.state.tr  
    tr.setNodeMarkup(nodePos, null, attributes)
    window.view.dispatch(tr)
    Mixpanel.track('table_toggle_header_column',{value:attributes.headerColumn})
  }

  setSelectedCellsBackgroundColor(e,color){
    e.preventDefault()
    let state=window.view.state
    const {selection}=state
    let tr = window.view.state.tr  
      
    if(selection instanceof CellSelection){
      selection.forEachCell((cell, pos) => {
        let attributes={...cell.attrs}
        attributes.background=color
        tr.setNodeMarkup(pos, null, attributes)
      })
    }
    else{ //single cell
      const {$from}=selection
      const anchorNode=getAnchorNode($from,'table_cell')
      const cellPos=getNodePos(anchorNode)
      let attributes={...anchorNode.attrs}
      attributes.background=color
      tr.setNodeMarkup(cellPos, null, attributes)
    }
    window.view.dispatch(tr)
    Mixpanel.track('table_set_background_color',{value:color})
  }

  alignSelectedCells(e,alignment){
    e.preventDefault()
    let state=window.view.state
    const {selection}=state
    let tr = window.view.state.tr  
    
    if(selection instanceof CellSelection){
      selection.forEachCell((cell, pos) => {
        let attributes={...cell.attrs}
        attributes.align=alignment
        tr.setNodeMarkup(pos, null, attributes)
      })
    }
    else{ //single cell
      const {$from}=selection
      const anchorNode=getAnchorNode($from,'table_cell')
      const cellPos=getNodePos(anchorNode)
      let attributes={...anchorNode.attrs}
      attributes.align=alignment
      tr.setNodeMarkup(cellPos, null, attributes)
    }
    window.view.dispatch(tr)
    Mixpanel.track('table_align_cells',{value:alignment})
  }

  toggleAlternatingRowBG(){
    const node=this.node
    const nodePos=this.getPos()
    let attributes={...node.attrs}
    attributes.alternatingRowBG=!node.attrs.alternatingRowBG
    let tr = window.view.state.tr  
    tr.setNodeMarkup(nodePos, null, attributes)
    window.view.dispatch(tr)
    Mixpanel.track('table_toggle_alternating_background',{value:alignment})
  }

    createNewNodeMessage(e){
      e.stopPropagation()
      e.preventDefault()
      const snapshot={}
      this.newNodeMessage(this.node.attrs.nodeId,'table',snapshot)
    }

    deleteTable(e){
      e.preventDefault()
      e.stopPropagation()
      let tr = window.view.state.tr
      const pos=this.getPos()
      tr.delete(pos,pos+this.node.nodeSize)
      window.view.dispatch(tr)
    }

    addNewColumn(e){
      //e.preventDefault()
      //e.stopPropagation()
      const pos=this.getPos()
      let state=window.view.state
      const schema=state.schema
      let view=window.view
      let dispatch=window.view.dispatch
      let tr = window.view.state.tr
      const node=this.node
      let table 
      let newContent=[]

      let tableStart
      let firstRowNode
      node.descendants((childNode, childPos) => {
        if (childNode.type.name === "table") {  
          table=childNode  
          tableStart=pos+childPos+2
        }
        if (childNode.type.name === "table_row") {  
         if(!firstRowNode){
          firstRowNode=childNode
         }
        }
      });

      let columnCount=0
      firstRowNode.forEach((cellNode, cellOffset) => {
        columnCount+=1
      });

      const map=TableMap.get(table)
      const tableRect={
        tableStart: tableStart,
        map: map,
        table: table
      }
      tr=addColumn(tr,tableRect,columnCount)
      window.view.dispatch(tr)
      window.view.focus()
      Mixpanel.track('table_hover_menu_new_column')
    }

  addNewRow(e){
    //e.preventDefault()
    e.stopPropagation()
    const pos=this.getPos()
    let state=window.view.state
    const schema=state.schema
    let view=window.view
    let dispatch=window.view.dispatch
    let tr = window.view.state.tr
    const node=this.node
    let table 
    let newContent=[]
    let tableStart
    let rowCount=0
    node.descendants((childNode, childPos) => {
      if(childNode.type.name === "table") {  
        table=childNode  
        tableStart=pos+childPos+2
      }
      if (childNode.type.name === "table_row") {  
        rowCount+=1
      }
    });
    const map=TableMap.get(table)
    const tableRect={
      tableStart: tableStart,
      map: map,
      table: table
    }
    tr=addRow(tr,tableRect,rowCount)
    window.view.dispatch(tr)
    window.view.focus()
    Mixpanel.track('table_hover_menu_new_row')
  }


  removeLastRow(e) {
    e.stopPropagation()
    let state=window.view.state
    const {selection}=state
    const schema=state.schema
    let view=window.view
    let dispatch=window.view.dispatch
    let tr = window.view.state.tr
    const node=this.node
    let table 
    let tableStart
    let rowCount=0
    let lastRowNode
    let lastRowPos
    const tablePos=this.getPos()+2
    node.descendants((childNode, childPos) => {
      if(childNode.type.name === "table") {  
        table=childNode  
      }
      if (childNode.type.name === "table_row") {  
        lastRowPos=childPos
        lastRowNode=childNode
        rowCount+=1
      }
    });

    const map=TableMap.get(table)
    const cell=selectionCell(state)  
    const cellNode=state.doc.nodeAt(cell.pos)
    const cellRect = map.findCell(cell.pos-tablePos);
    //check if it is in the last row
    const isLastRow=cellRect.bottom==map.height 
    if(isLastRow){
      const cell = findVerticalCell(selectionCell(state));
      const $cell = state.doc.resolve(cell);
      tr.setSelection(new CellSelection($cell))
    }
    const deletePos=this.getPos()+lastRowPos+1
    tr.delete(deletePos,deletePos+lastRowNode.nodeSize)
    window.view.dispatch(tr)
    Mixpanel.track('table_hover_menu_delete_row')
  }

  fitTableToDocWidth(e){
    //make all cells the same colwidth
   // console.log('fit table to doc width!!!!')
    e.preventDefault()
    e.stopPropagation()

    const pos=this.getPos()
    let state=window.view.state
    const schema=state.schema
    let view=window.view
    let dispatch=window.view.dispatch
    let tr = window.view.state.tr
    const node=this.node

    // const colwidth=[100]
    let table 
    node.descendants((childNode, childPos) => {
      if(childNode.type.name === "table") {  
        table=childNode  
      }
    });
    const map=TableMap.get(table)
    const width=map.width 
    const newColWidth=Math.floor(650/width) //TODO check we want to round
    const colwidth=[newColWidth]

    node.descendants((childNode, childPos) => {
      if (childNode.type.name === "table_cell") { 
        let attributes={...childNode.attrs}
        attributes.colwidth=colwidth
        tr.setNodeMarkup(childPos+pos+1, null, attributes)
      }
    });
    window.view.dispatch(tr)
    window.view.focus()
     Mixpanel.track('table_hover_menu_fit_to_doc_width')
  }

  fitTableToMaxColWidthWidth(e){
    //make all cells the same colwidth
   // console.log('fit table to doc width!!!!')
    e.preventDefault()
    e.stopPropagation()

    const pos=this.getPos()
    let state=window.view.state
    const schema=state.schema
    let view=window.view
    let dispatch=window.view.dispatch
    let tr = window.view.state.tr
    const node=this.node

    // const colwidth=[100]
    let table 
    let maxColWidth
    node.descendants((childNode, childPos) => {
      if(childNode.type.name === "table") {  
        table=childNode  
      }
      if(childNode.type.name=='table_cell'){
        const width=childNode.attrs.colwidth[0]
        if(!maxColWidth || width>maxColWidth){
          maxColWidth=width
        }
      }
    });
   

    node.descendants((childNode, childPos) => {
      if (childNode.type.name === "table_cell") { 
        let attributes={...childNode.attrs}
        attributes.colwidth=[maxColWidth]
        tr.setNodeMarkup(childPos+pos+1, null, attributes)
      }
    });
    window.view.dispatch(tr)
    window.view.focus()
     Mixpanel.track('table_hover_menu_fit_to_max_col')
  }


removeLastColumn(e) {
  let state = window.view.state
  let tr = state.tr
  const { doc, schema } = state
  const cellNode = schema.nodes.table_cell
  let tableNode
  let tableStart
  const pos = this.getPos()

  this.node.descendants((childNode, childPos) => {
    if(childNode.type.name === "table") {  
      tableNode=childNode  
      tableStart=pos+childPos+2
    }
  });

  let positionsToDelete = []  
  tableNode.descendants((childNode, childPos) => {
    if(childNode.type.name === "table_row") {
      let lastCellInRowPos = null
      let lastCellInRowNode
      childNode.descendants((cellChildNode, cellChildPos) => {
        if (cellChildNode.type === cellNode) {
          lastCellInRowPos = cellChildPos + childPos + 1 + tableStart
          lastCellInRowNode=cellChildNode
        }
      })
      if (lastCellInRowPos !== null) {
        positionsToDelete.push({start: lastCellInRowPos, end: lastCellInRowPos + lastCellInRowNode.nodeSize})
      }
    }
  });

  //check if selection is in the last column
  const map=TableMap.get(tableNode)
  const cell=selectionCell(state)  
  const selectedCellNode=state.doc.nodeAt(cell.pos)
  const cellRect = map.findCell(cell.pos-tableStart);
  const isLastColumn=cellRect.right==map.width 
  if(isLastColumn){
    const cell = findNextCell(selectionCell(state));
    const $cell = state.doc.resolve(cell);
    tr.setSelection(new CellSelection($cell))
  }
  // Apply deletions
  for (let position of positionsToDelete) {
    let mappedPos = tr.mapping.map(position.start)
    tr.delete(mappedPos, tr.mapping.map(position.end))
  }
  window.view.dispatch(tr)
  Mixpanel.track('table_hover_menu_remove_column')
}


    //add styles for width etc
    styleTable(rect){
      const parentPos=this.getPos()
      let tableContentWidth
      if(rect){
        tableContentWidth=rect.width
      }else{
        tableContentWidth=measureTableNode(this.tableInnerContainer)
      }
      const outerDocWidth = measureOuterDocWidth() // measured
      const innerDocOffset = measureInnerDocOffset() // distance from inner doc to edge     
      const innerDocPageOffset = measureInnerDocPageOffset() // distance from inner doc to page   

      let tableContainerWidth // width of wrapping doc-table div
      let tableContainerLeftMargin = 0 // used for centering
      let tableContainerOverflowX = false // only overflow sometimes

      const wideTableClassName = 'doc-tableContainer--wide'
      
      // if table content is equal or narrower than width of doc
      // then table container is width of doc
      if(tableContentWidth <= INNER_DOC_WIDTH){          
        tableContainerWidth = tableContentWidth
        this.tableInnerContainer.style.overflowX = 'unset' // don't horizontal scroll        
        this.dom.classList.remove(wideTableClassName)
      }



      // if table content is wider than wider than doc, but less than outer doc width
      // than table container is width of table content
      // and centered using margin      
      if(tableContentWidth > INNER_DOC_WIDTH && tableContentWidth <= outerDocWidth){            
        tableContainerWidth = tableContentWidth      
        tableContainerLeftMargin = ((tableContentWidth - INNER_DOC_WIDTH) / 2) * -1      
        this.tableInnerContainer.style.overflowX = 'unset' // don't horizontal scroll
        this.dom.classList.add(wideTableClassName)
    
      
      }
      // if table content is wider than wider than doc, but less than outer doc width
      // than table container is width of outer doc    
      // and fixed to left edge using margin

      let tableContentSidePadding = 0 // when very wide, add some padding to the edges 
      const overflowSidePadding = 32 
      if(tableContentWidth > outerDocWidth){            
        tableContainerWidth = outerDocWidth
        //tableContainerLeftMargin = (((outerDocWidth - INNER_DOC_WIDTH) / 2) * -1) + 2 // plus a little bit on edge      
        
        tableContainerLeftMargin = innerDocPageOffset * -1
        this.tableInnerContainer.style.overflowX = 'scroll'
        tableContentSidePadding = overflowSidePadding // just want a bit of padding here on either side for buttons etc.
      }

      
      const widthActionMenu = 501

      const sideEdgeActionMenu = (INNER_DOC_WIDTH - widthActionMenu) / 2

      // find the translation if table was centered, then undo that
      // this is the maximum, it my overflow the left edge
      const originalActionMenuLeftMargin = ((INNER_DOC_WIDTH - tableContentWidth) / 2) * -1 
      const maxActionMenuLeftOffset = (innerDocPageOffset * -1) - sideEdgeActionMenu + 20 // edge margin

      // console.log(`originalActionMenuLeftMargin: ${originalActionMenuLeftMargin}`)
      // console.log(`maxActionMenuLeftOffset: ${maxActionMenuLeftOffset}`)
      // console.log(`innerDocPageOffset: ${innerDocPageOffset}`)
      // console.log(`sideEdgeActionMenu: ${sideEdgeActionMenu}`)

      let actionMenuLeftMargin = 0
      // action menu offset only, only happens if less than doc width
      if(tableContentWidth <= INNER_DOC_WIDTH){          
        actionMenuLeftMargin = Math.max(originalActionMenuLeftMargin, maxActionMenuLeftOffset)


      }

      this.tableInnerContainer.style.width = `${tableContainerWidth}px`
      this.tableInnerContainer.style.marginLeft = `${tableContainerLeftMargin}px`
      
      this.columnButtonDOM.style.left = `${tableContentWidth}px`
      this.columnButtonDOM.style.marginLeft = `${tableContentSidePadding}px`
      this.rowButtonDOM.style.width = `${tableContentWidth}px`
      this.rowButtonDOM.style.marginLeft = `${tableContentSidePadding}px`

      this.tableContentDiv.style.width= `${tableContentWidth}px`
      this.tableContentDiv.style.paddingLeft = `${tableContentSidePadding}px`
      this.tableContentDiv.style.paddingRight = `${tableContentSidePadding}px`

      this.hoverMenuDom.style.marginLeft= `${actionMenuLeftMargin}px`
      this.dom.appendChild(this.hoverMenuDom)
    }


  update(node,decorations){
    if (this.node.type !== node.type) {
      return false
    }
    this.node = node; 
    if(node.attrs.alternatingRowBG){
      this.dom.classList.add(ALTERNATING_ROW_BG_CLASSNAME)
    } else{
      this.dom.classList.remove(ALTERNATING_ROW_BG_CLASSNAME)
    }
    this.renderHoverMenu()
    this.addClassnames()
    return true;
  }

ignoreMutation(record) { //TODO check this
    // if (
    //   (record.type === 'attributes' &&
    //     record.target.classList.contains('doc-table-editRowColBtn'))
    // ) {
      return true;
    // }
    // return false;
  }


  destroy() {
    this.observer.disconnect();
    this.resizeObserver.unobserve(this.sideMenuElement)
    window.removeEventListener('resize', this.styleTable) 
    ReactDOM.unmountComponentAtNode(this.columnButtonDOM)
    ReactDOM.unmountComponentAtNode(this.rowButtonDOM)
    ReactDOM.unmountComponentAtNode(this.hoverMenuDom)
  }

}

export default DocTableNodeView