import React from 'react'
import { connect } from 'react-redux'
import { Transition, animated, config } from 'react-spring'
import ActiveRecordingUploadPanelContents from './ActiveRecordingUploadPanelContents'


class ActiveRecordingUploadPanel extends React.Component{  

  constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.state={
      forceMount: false,
     // showActiveRecordingUploadPanel:false
    }
  }

  onRest() {  
    if(!this.props.showActiveRecordingUploadPanel){
      this.setState({forceMount: false})
    }else{
      this.setState({forceMount: true})
    }
  }

  render(){ 
    // const {showActiveRecordingUploadPanel} = this.state
    const {activeUploads}=this.props
    let showActiveRecordingUploadPanel=false 
    let activeUploadsCount=Object.keys(activeUploads).length
    let totalProgress=0
    let combinedProgress=0
    if(activeUploadsCount>0){
      showActiveRecordingUploadPanel=true
      Object.keys(activeUploads).forEach((key)=>{
        const upload=activeUploads[key]
        const progress=upload.progress
        totalProgress+=progress
      })
      combinedProgress=(totalProgress/activeUploadsCount)/100
    }

    //const showActiveRecordingUploadPanel = true
    
    const {forceMount} = this.state
    let transformStart = 'scale(0.9)'
    let transformEnd = 'scale(1)'

    return (      
        <div className='activeRecordingUploadPanelContainer'>
          <Transition
            items={showActiveRecordingUploadPanel}
            from={{ opacity: 0, transform: `${transformStart}` }}
            enter={{ opacity: 1, transform: `${transformEnd}`  }}
            leave={{ opacity: 0, transform: `${transformStart}`  }}
            reverse={showActiveRecordingUploadPanel}
            config={{tension: 500, friction: 32}}
            // config={{tension: 600, friction: 24}}
            //config={config.molasses}
            onRest={this.onRest}
           >
            {(styles, item) =>
              item && 
               <animated.div style={styles} className="activeRecordingUploadPanel">                       
                  <ActiveRecordingUploadPanelContents
                    activeUploadsCount={activeUploadsCount}
                    combinedProgress={combinedProgress}
                  />
              </animated.div>
            }
          </Transition>                 
      </div>      


    )
  }
}



function mapStateToProps(state,ownProps) {
  return {
    activeUploads:state.activeUploads
  }
}


export default connect(mapStateToProps,
  {}
)(ActiveRecordingUploadPanel)

