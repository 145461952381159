import React, {Component} from 'react'
import {Switch,Route} from "react-router-dom"
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { createBrowserHistory } from 'history'
import {authenticateSession,authenticate} from '../actions/auth'
import {testApi} from '../actions/testApi'
import {loadInitialData} from '../actions/initialData'
import DocWrapperContainer from './DocWrapperContainer' //wrapper for DocEditorContainer that forces reload when route changes
import LibraryContainer from './LibraryContainer'
//import AccountPageContainer from './AccountPageContainer'

//Pusher
import CollabInstanceManager from './CollabInstanceManager'
import OrgPusherContainer from './OrgPusherContainer'

//Sync
import LinearSyncContainer from './LinearSyncContainer'
import OpenDocMenu from "../components/OpenDocMenu"
import AppBG from '../components/AppBG'
import ToastNotifications from '../components/ToastNotifications'
import SideMenuContainer from '../components/sideMenu/SideMenuContainer'
import AppShortcuts from './AppShortcuts'
import CommandMenu from '../components/commandMenu/CommandMenu'
import ActionCommandMenu from '../components/commandMenu/ActionCommandMenu'
import ElectronMacDots from '../components/ElectronMacDots'

//Routing
import MatchAuthenticated from '../components/routing/MatchAuthenticated'
import RedirectAuthenticated from '../components/routing/RedirectAuthenticated'

//Auth
import LoginContainer from './LoginContainer' 
import LinearAuthContainer from './LinearAuthContainer'
import FigmaIntegrationCallbackPage from './FigmaIntegrationCallbackPage'

//import NotionAuthContainer from './NotionAuthContainer'
// import ExportToNotionContainer from './ExportToNotionContainer'
// import ProjectsPageContainer from './ProjectsPageContainer'
import PrivateDocsContainer from './PrivateDocsContainer'
import RecentPageContainer from './RecentPageContainer'
// import TeamPageContainer from './TeamPageContainer'

import SettingsPageContainer from './SettingsPageContainer'
import RoadmapPageContainer from './RoadmapPageContainer'
import RoadmapsHomeContainer from './RoadmapsHomeContainer'

import MeetingsPageContainer from './MeetingsPageContainer'
import RecordingsProvider from './RecordingsProvider'

//Dev playgrounds
// import FigmaTestContainer from './dev/FigmaTestContainer'
// import VideoTestContainer from './dev/VideoTestContainer'
// import TLDrawTestContainer from './dev/TLDrawTestContainer'
// import TenorTestPage from './dev/TenorTestPage'
// import DnDTestPageContainer from './dev/dndTest/DnDTestPageContainer'
import PlaygroundTestPage from './dev/PlaygroundTestPage'
import {setDoNotDisturbUntil} from '../utils/notifications/setDoNotDisturbUntil'
import { Mixpanel } from '../Mixpanel'

import moment from 'moment'
const history = createBrowserHistory()

import TabSwitchMenu from '../components/TabSwitchMenu'
import KeyboardShortcutsPanel from '../components/KeyboardShortcutsPanel'
import ActiveRecordingUploadPanel from '../components/activeRecordingUploadPanel/ActiveRecordingUploadPanel'
// import DeepLinkRedirectContainer from './DeepLinkRedirectContainer'
import DeepLinkContainer from './DeepLinkContainer'
import ReactGA from 'react-ga4'

import {BrowserRouter as Router} from "react-router-dom"

import TemplateModalContainer from '../components/templateModal/TemplateModalContainer'
import {hideDocTemplatesModal} from '../utils/templates/hideDocTemplatesModal'

import OnboardingModalContainer from '../components/onboarding/OnboardingModalContainer'

//use HashRouter instead of BrowserRouter
//https://stackoverflow.com/questions/36505404/how-to-use-react-router-with-electron

const ENV=process.env.REACT_APP_ENV||window.envVars.reactAppEnv

//if(ENV !="local" && ENV!="production" && ENV !='development'){
	if( ENV !='local'&& ENV!="production" && ENV !='development'){
	const SENTRY_URL=process.env.REACT_APP_SENTRY_URL|| window.envVars.reactAppSentryUrl
	Sentry.init({ 
		dsn: `${SENTRY_URL}` 
		// replaysSessionSampleRate: 1.0,
  // 	// If the entire session is not sampled, use the below sample rate to sample
  // 	// sessions when an error occurs.
  // 	replaysOnErrorSampleRate: 1.0,
  // 	integrations: [new Sentry.Replay()],
	})
}
else if(ENV =='production' ){ //capture replays in prod
	const SENTRY_URL=process.env.REACT_APP_SENTRY_URL|| window.envVars.reactAppSentryUrl
	Sentry.init({ 
		dsn: `${SENTRY_URL}` 
		// replaysSessionSampleRate: 1.0,
  // 	// If the entire session is not sampled, use the below sample rate to sample
  // 	// sessions when an error occurs.
  // 	replaysOnErrorSampleRate: 1.0,
  // 	integrations: [new Sentry.Replay()],
	})
}


if(process.env.REACT_APP_GA_TRACKING_ID){
	ReactGA.initialize([
  {trackingId: process.env.REACT_APP_GA_TRACKING_ID},
]);
}



// if (process.env.NODE_ENV !== 'production') {
//   const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   console.log('HERE')
//   //whyDidYouRender(React);
//     whyDidYouRender(React, {
//     trackAllPureComponents: true,
//     trackHooks: true,
//     onlyLogs: true,
//     logOnDifferentValues: false,
//   });
// }

class AppContainer extends Component {

	constructor(){
		super()
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.calcDoNotDisturbUntilTimer=this.calcDoNotDisturbUntilTimer.bind(this)
		this.hideOpenDocMenu=this.hideOpenDocMenu.bind(this)
		this.openOpenDocMenu=this.openOpenDocMenu.bind(this)
		this.showActionCommandMenu=this.showActionCommandMenu.bind(this)
		this.hideActionCommandMenu=this.hideActionCommandMenu.bind(this)
		this.toggleSideMenuVisible=this.toggleSideMenuVisible.bind(this)

		this.pingApi=this.pingApi.bind(this)
		this.state={
			showOpenDocMenu:false,
			openDocMenuKey:0,
			sideMenuVisible: true,
			apiErrorActive:false,
			showCommandMenu: false,
			showActionCommandMenu: false,
			actionCommandMenuType: null,
			showKeyboardShortcutsPanel: false,
			// showOnboardingModal: false,
			// showTemplateModal: false,
		}
		this.doNotDisturbTimer=null
		Mixpanel.track('app_load')
		if(window.ipcRenderer){
			window.isElectron=true
		}

	}

	componentDidMount() {	
		if(process.env.REACT_APP_ENV !='production'){
			console.log(`--------------------- Node env: ${process.env.NODE_ENV}-------------`)
			console.log(`--------------------- API ENV: ${process.env.REACT_APP_ENV}-------------`)
		}
		this.calcDoNotDisturbUntilTimer()
		if(this.props.theme){
			document.documentElement.setAttribute('data-theme', this.props.theme);
		}
		if(!(window.location.pathname.indexOf('/auth/slack/callback') > -1) &&
			!(window.location.pathname.indexOf('/auth/slack/callback/addScopes') > -1)
		){
			this.props.authenticateSession().then(() => {
				this.props.loadInitialData()
			})
			.catch((error)=>{})
		}

		window.addEventListener('keydown', this.handleKeyDown)
		window.focus()
		this.pingApiInterval=setInterval(() => {
			this.pingApi()
  	},20000)
	}

	componentDidUpdate(prevProps) {
 		if(prevProps.theme !== this.props.theme){
 			document.documentElement.setAttribute('data-theme', this.props.theme);
 		}
 		if(prevProps.doNotDisturbUntil !== this.props.doNotDisturbUntil){
 			this.calcDoNotDisturbUntilTimer()
 		}
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
		if(this.pingApiInterval){
			clearInterval(this.pingApiInterval)
		}
		if(this.doNotDisturbTimer){
			clearTimeout(this.doNotDisturbTimer)
		}
	}

	calcDoNotDisturbUntilTimer(){ //timer to turn off do not disturb
		const {doNotDisturbUntil}=this.props
		if(this.doNotDisturbTimer){
			clearTimeout(this.doNotDisturbTimer)
		}
		if(doNotDisturbUntil){
			let dur=moment.duration( moment(doNotDisturbUntil).diff(moment()) )
			const milliseconds=dur._milliseconds
			if(milliseconds>0){
				//if already passed clear out DoNotDisturv
				this.doNotDisturbTimer=setTimeout(function() {
					setDoNotDisturbUntil(null)
				}.bind(this), milliseconds)
			}else{ //already expired
				setDoNotDisturbUntil(null)
			}
		}
	}


	pingApi(){ //check api is responsive
		this.props.testApi().then(() => {
  		this.setState({apiErrorActive:false})
  	})
  	.catch((error)=>{
  		if(process.env.REACT_APP_ENV !='production'){
  			this.setState({apiErrorActive:true})
  		}else{
  			//TODO catch error in prod
  		}
  	})
	}

				
	openOpenDocMenu(){
		//console.log('open doc-----menu')
		//shut template modal
		hideDocTemplatesModal()
		this.setState({showOpenDocMenu:true,openDocMenuKey:this.state.openDocMenuKey+1})
	}

	hideOpenDocMenu(){
		this.setState({showOpenDocMenu: false})
	}

	hideActionCommandMenu(){
		this.setState({showActionCommandMenu: false})
	}

	showActionCommandMenu(){
		this.setState({showActionCommandMenu: true})
	}

	toggleSideMenuVisible(){
		this.setState({sideMenuVisible: !this.state.sideMenuVisible})
	}


	handleKeyDown(e){		
		if(e.metaKey && e.keyCode===83){ //command + s to hide/show sidemenu
			e.preventDefault()
			this.setState({sideMenuVisible:!this.state.sideMenuVisible})			
		}
		// else if(e.metaKey && e.keyCode===80 && !e.shiftKey){ //command + P to hide/show open ping menu
		// 	e.preventDefault()
		// 	this.setState({showActionCommandMenu:!this.state.showActionCommandMenu})
		// 	this.setState({actionCommandMenuType:'ping'})
		// }
		// else if(e.metaKey && e.keyCode===74){ //command + J to hide/show jump menu
		// 	e.preventDefault()
		// 	this.setState({showActionCommandMenu:!this.state.showActionCommandMenu})
		// 	this.setState({actionCommandMenuType:'jumpToCursor'})
		// }

		if(window.isElectron){ /////// electron only keyboard shortcuts
			if(e.metaKey && e.keyCode===84 && !e.shiftKey ){ //command + t
				e.preventDefault()
				this.openOpenDocMenu()
				//this.setState({showOpenDocMenu:!this.state.showOpenDocMenu,openDocMenuKey:this.state.openDocMenuKey+1})			
			}
		}else{
			if(e.metaKey && e.keyCode===69){ //command + e
				e.preventDefault()
				this.openOpenDocMenu()
				//this.setState({showOpenDocMenu:!this.state.showOpenDocMenu,openDocMenuKey:this.state.openDocMenuKey+1})			
			}			
		}
	}

	render() {
		const { isAuthenticated, willAuthenticate } = this.props
		const authProps = { isAuthenticated, willAuthenticate }
		const loginProps = { isAuthenticated, willAuthenticate }
		const {sideMenuVisible, showTabSwitchMenu} = this.state
		const {apiErrorActive} = this.state // true when API failure state encountered		

		const hideOnboarding = false
		return (
			<div id="app" className={"app " + (window.isElectron ? ' app--electron ' : ' app--web ' )  + (!sideMenuVisible ? ' app--sideMenuHidden ': ' ')}>
	
				<ElectronMacDots 
					sideMenuVisible={sideMenuVisible}					
					apiErrorActive={apiErrorActive}
				/>
				
				{/* AREA TO ALLOW WINDOW TO BE DRAGGED TO REPOSITION */}
				{window.isElectron &&
					<div className='electronWindowDragArea' />					
				}

			 	<Router history={history}>

			 		<RecordingsProvider>
			 		<AppShortcuts/> {/*put this here so is underneath router*/}

				 	<OpenDocMenu 
				 		key={this.state.openDocMenuKey}
						showOpenDocMenu={this.state.showOpenDocMenu}
						hideOpenDocMenu={this.hideOpenDocMenu}
						sideMenuVisible={sideMenuVisible}
					/>	
							 	
				 	{isAuthenticated && 
					 	<SideMenuContainer
					 		sideMenuVisible={sideMenuVisible}
					 		toggleSideMenuVisible={this.toggleSideMenuVisible}
					 		openOpenDocMenu={this.openOpenDocMenu}				 
					 		toggleKeyboardShortcutsPanel={()=>{this.setState({showKeyboardShortcutsPanel: !this.state.showKeyboardShortcutsPanel})}}
					 		//toggleTemplateModal={()=>{this.setState({showTemplateModal: !this.state.showTemplateModal})}}
					 	/>
				 	}

					<ToastNotifications/>

					{isAuthenticated &&
						<ActionCommandMenu
							showActionCommandMenu={this.state.showActionCommandMenu}
							hideActionCommandMenu={this.hideActionCommandMenu}
							actionCommandMenuType={this.state.actionCommandMenuType}
						/>
					}

					{isAuthenticated &&
						<ActiveRecordingUploadPanel
							//showActiveRecordingUploadPanel={this.state.showActiveRecordingUploadPanel}
							//toggleActiveRecordingUploadPanel={()=>{this.setState({showActiveRecordingUploadPanel: !this.state.showActiveRecordingUploadPanel})}}
						/>
					}

					<KeyboardShortcutsPanel 
						showKeyboardShortcutsPanel={this.state.showKeyboardShortcutsPanel}
						closeKeyboardShortcutsPanel={()=>{this.setState({showKeyboardShortcutsPanel: false})}}
					/>
				
					{isAuthenticated &&
						<CollabInstanceManager/>
					}

					{isAuthenticated &&
						<OrgPusherContainer/>
					}

					{isAuthenticated &&
						<LinearSyncContainer/>
					}								

					{isAuthenticated && !hideOnboarding &&
						<OnboardingModalContainer 
							//showModal={showOnboardingModal}						
							//openModal={()=>{this.setState({showOnboardingModal: true})}}
							//closeModal={()=>{this.setState({showOnboardingModal: false})}}							
						/>
					}

					{isAuthenticated &&
						<TemplateModalContainer 
							//showModal={this.state.showTemplateModal}						
							//openModal={()=>{this.setState({showTemplateModal: true})}}
							//closeModal={()=>{this.setState({showTemplateModal: false})}}
							defaultType='project'
							//defaultType='doc'
						/>
					}

					{isAuthenticated &&
						<TemplateModalContainer 
							//showModal={this.state.showTemplateModal}						
							//openModal={()=>{this.setState({showTemplateModal: true})}}
							//closeModal={()=>{this.setState({showTemplateModal: false})}}
							defaultType='project'
							//defaultType='doc'
						/>
					}

					<DeepLinkContainer/>

					<Switch>
	
						{/*
						<MatchAuthenticated path="/projects"  component={ProjectsPageContainer} {...authProps}  sideMenuVisible={sideMenuVisible} />
						*/}
						<MatchAuthenticated path="/playground"  component={PlaygroundTestPage} {...authProps} sideMenuVisible={sideMenuVisible} />
						<MatchAuthenticated path="/meetings"  component={MeetingsPageContainer} {...authProps}  sideMenuVisible={sideMenuVisible} toggleSideMenuVisible={this.toggleSideMenuVisible} />
						<MatchAuthenticated path="/settings"  component={SettingsPageContainer} {...authProps} />
						<MatchAuthenticated path="/recent"  component={RecentPageContainer} {...authProps} sideMenuVisible={sideMenuVisible}/>
						
						<RedirectAuthenticated path='/login' component={LoginContainer} {...authProps}/>
						<Route path='/auth/figma/callback' component={FigmaIntegrationCallbackPage} {...authProps}/>
						<Route path='/auth/linear/callback/:addScopes?' component={LinearAuthContainer} {...authProps}/>
						
						<MatchAuthenticated path="/:orgName/doc/:docSignature"  component={DocWrapperContainer} {...authProps} sideMenuVisible={sideMenuVisible} toggleSideMenuVisible={this.toggleSideMenuVisible} />				
						
						<MatchAuthenticated path="/roadmaps/:id"  component={RoadmapPageContainer} {...authProps}  sideMenuVisible={sideMenuVisible} toggleSideMenuVisible={this.toggleSideMenuVisible} /> 
						<MatchAuthenticated path="/roadmaps"  component={RoadmapsHomeContainer} {...authProps}  sideMenuVisible={sideMenuVisible} toggleSideMenuVisible={this.toggleSideMenuVisible} /> 

		
						<MatchAuthenticated path="/private"  component={PrivateDocsContainer} {...authProps}  sideMenuVisible={sideMenuVisible} toggleSideMenuVisible={this.toggleSideMenuVisible} /> 
						<MatchAuthenticated path="/"  component={LibraryContainer} {...authProps}  sideMenuVisible={sideMenuVisible} toggleSideMenuVisible={this.toggleSideMenuVisible} />
					
					</Switch>
					{/*
					<ExportToNotionContainer/>
				*/}
				</RecordingsProvider>
	  		</Router>

	  		{isAuthenticated &&
	  			<AppBG />
	  		}

	  	</div>
		)
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.authenticationStatus.isAuthenticated,
	willAuthenticate: state.authenticationStatus.willAuthenticate,
	theme:state.theme,
	doNotDisturbUntil:state.doNotDisturbUntil
});

export default connect(
	mapStateToProps,
	{
		authenticateSession,
		loadInitialData,
		testApi,
		authenticate
	})(AppContainer)

						

						// 						{!isAuthenticated && !willAuthenticate &&  !window.todesktop &&
						// 	<Route path='/:orgName/doc/:docSignature' component={DeepLinkRedirectContainer} {...authProps}/>
						// }



