export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_MEMBERS_SUCCESS':
		return action.response


	case 'SIGN_OUT':
      return []
	

	default:
		return state
	}
}
