import React from 'react'
import Icon from '../../misc/Icon'
import * as Dialog from '@radix-ui/react-dialog';
import DrawingCanvasContainer from './DrawingCanvasContainer'

import DocImageReactionBar from '../../nodeViews/docMediaUIComponents/DocImageReactionBar'

class DocDrawingZoomedModalContents extends React.Component{  
 	render(){ 
		const showComments = true
		return (
			<React.Fragment>						
				<div onClick={()=>{console.log('testing')}} className='doc-zoomedDrawingModal-captureOutsideClick' />
				<div className='doc-zoomedDrawingModal-contentsContainer'>


						<div className='doc-zoomedDrawingModal-canvasContainer'>
																			
							<DrawingCanvasContainer                                         
		            isEdit={true} 
			      		tlDrawDoc={this.props.tlDrawDoc}
		    				insertImage={this.props.insertImage}
		    				closeModal={this.props.closeModal}
		    				updateDrawing={this.props.updateDrawing}
		          />
							
						</div>

						<div className='doc-zoomedDrawingModal-header'>
							{this.props.imageTitle &&
								<div className='doc-zoomedDrawingModal-header-title'>								
									<div className='doc-zoomedDrawingModal-header-title-title'>
										{this.props.imageTitle}
									</div>
									<div className='doc-zoomedDrawingModal-header-title-commentCount'>
										14 comments
									</div>
								</div>
							}

						</div>
						

					{/*}
						<div className='doc-zoomedDrawingModal-commentsContainer'>					

						</div>		
					*/}
						
				</div>

			</React.Fragment>
		)
	}
}



class DocDrawingZoomedModal extends React.Component{  
 
  constructor(){
    super()
    this.closeAndSave=this.closeAndSave.bind(this)
    this.state={
      // zoomed: false,  
      // mouseMoved:false
    }
  }  

  closeAndSave(e){    
    // this.props.updateDrawing()
    this.props.closeModal()
  }

	render(){ 

		const {unzoom} = this.props
		
		return (
			<React.Fragment>
				<Dialog.Root open={this.props.zoomed}>
			    <Dialog.Portal>
			      <Dialog.Overlay className='doc-zoomedDrawingModalContainer'>
				      <Dialog.Content 
				      	className='doc-zoomedDrawingModal'
				      	onEscapeKeyDown={this.closeAndSave}
				      	onPointerDownOutside={this.closeAndSave}
				      	onInteractOutside={this.closeAndSave}
				      >

				      	<DocDrawingZoomedModalContents			      			
			      			unzoom={unzoom}
			      			closeAndSave={this.closeAndSave}
			      			tlDrawDoc={this.props.tlDrawDoc}
		      				insertImage={this.props.insertImage}
		      				closeModal={this.props.closeModal}
		      				updateDrawing={this.props.updateDrawing}
				      	/>
				      		
				      		{/*}
				      		<button onClick={unzoom} className='doc-zoomedImageModal-closeBtn'>
				      			<div className='doc-zoomedImageModal-closeBtn-inner'>										
											<Icon name='cross-small' />
										</div>
									</button>
								*/}
				      </Dialog.Content>
			      </Dialog.Overlay>
			    </Dialog.Portal>
			  </Dialog.Root>
			</React.Fragment>
			
		)
	}
}

export default DocDrawingZoomedModal