import findIndex from 'lodash/findIndex'

export default function (state = {}, action) {
	switch (action.type) {

		case 'START_ROADMAP_IMAGE_UPLOAD':
			const roadmapId=action.roadmapId
			let uploads={...state}
			uploads[roadmapId]=true
			return uploads

		case 'START_DELETE_ROADMAP_IMAGE':
			const roadmapIdToDelete=action.roadmapId
			let updatedUploads={...state}
			updatedUploads[roadmapIdToDelete]=false
			return updatedUploads

		case 'SIGN_OUT':
			return []

		default:
			return state
	}
}



