import React,{ useState,useEffect}  from 'react'
import Icon from '../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import { track, useEditor,useValue } from "@tldraw/tldraw"
import {
	ArrowShapeArrowheadEndStyle,
	ArrowShapeArrowheadStartStyle,
	DefaultColorStyle,
	DefaultDashStyle,
	DefaultFillStyle,
	DefaultFontStyle,
	DefaultHorizontalAlignStyle,
	DefaultSizeStyle,
	DefaultVerticalAlignStyle,
	Editor,
	GeoShapeGeoStyle,
	LineShapeSplineStyle,
	ReadonlySharedStyleMap,
	SharedStyle,
	SharedStyleMap,
	StyleProp,
} from '@tldraw/editor'

class PropertiesRowBtn extends React.Component{  
	

	// constructor(props){
 //    super(props)         
 //    this.state={
 //      active: props.defaultActive,      
 //    }
 //  }


	render(){ 
		const {onClick,action,property, color,iconName,active}=this.props
		
		return (
			<Tooltip.Root delayDuration={0} skipDelayDuration={300}>
				<Tooltip.Trigger asChild>																										
					<button disabled={this.props.disabled} onClick={onClick} data-state={active ? 'active' : 'inactive'} className={'whiteboard-toolbar-propertiesRow-btn ' + (property ? ` whiteboard-toolbar-propertiesRow-btn--property--${property} ` : '')}>			 
						{iconName && 
							<Icon name={this.props.iconName} />
						}
						{color && 
							<div className={'whiteboard-toolbar-propertiesRow-btn-color ' + (color ? ` whiteboard-toolbar-propertiesRow-btn-color--${color} ` : '')} />
						}				
					</button>
				</Tooltip.Trigger>				
				{this.props.tooltipLabel &&
					<Tooltip.Content side="bottom" sideOffset={2} className="tooltip tooltip--textSelectionUI">																																	
						{this.props.tooltipLabel}
					</Tooltip.Content>				
				}									
			</Tooltip.Root>
		)
	}
}
 

class PropertiesRowDivider extends React.Component{  
	render(){ 		
		return (
			<div className='whiteboard-toolbar-propertiesRow-divider' />		
		)
	}
}


const selectToolStyles = [DefaultColorStyle, DefaultDashStyle, DefaultFillStyle, DefaultSizeStyle];

function getRelevantStyles(editor) {
    const styles = new SharedStyleMap(editor.sharedStyles);
    const hasShape = editor.selectedIds.length > 0 || !!editor.root.current.value?.shapeType;

    if (styles.size === 0 && editor.isIn('select') && editor.selectedIds.length === 0) {
        for (const style of selectToolStyles) {
            styles.applyValue(style, editor.getStyleForNextShape(style));
        }
    }

    if (styles.size === 0 && !hasShape) return null;
    return { styles, opacity: editor.sharedOpacity };
}



const WhiteboardToolbarPropertiesRow = track(() => {	 
	const editor = useEditor()
	const relevantStyles = useValue('getRelevantStyles', () => getRelevantStyles(editor), [editor])


	//const { styles, opacity } = relevantStyles
	let styles
	let opacity
	if(relevantStyles){
		styles=relevantStyles.styles 
		opacity=relevantStyles.opacity
	}

	const color = styles?styles.get(DefaultColorStyle):''
	const fill = styles?styles.get(DefaultFillStyle):''
	const dash = styles?styles.get(DefaultDashStyle):''
	const size = styles?styles.get(DefaultSizeStyle):''
	const align= styles?styles.get(DefaultHorizontalAlignStyle):''

	let activeColor
	if(color){
		activeColor=color.value
	}
	let activeFill
	if(fill){
		activeFill=fill.value
	}
	let activeDash
	if(dash){
		activeDash=dash.value
	}
	let activeAlign
	if(align){
		activeAlign=align.value
	}

	//fill values
	//'none' | 'pattern' | 'semi' | 'solid' 
	//dash values
	//'dashed' | 'dotted' | 'draw' | 'solid'
	//align values
	// 'end-legacy' | 'end' | 'middle-legacy' | 'middle' | 'start-legacy' | 'start'



		return (
		
			<div className='whiteboard-toolbar-propertiesRow'>
				<PropertiesRowBtn 
					property='fillTransparent'
					iconName='drawingFillTransparent'
					tooltipLabel='Transparent'					
					active={activeFill=='none'}
					onClick={() => editor.setStyle(DefaultFillStyle, 'none')}
					disabled={!activeFill}
				/>
				<PropertiesRowBtn 					
					property='fillSolid'
					iconName='drawingFillSolid'			
					tooltipLabel='Solid'	
					active={activeFill=='semi'}
					onClick={() => editor.setStyle(DefaultFillStyle, 'semi')}
					disabled={!activeFill}
				/>
				<PropertiesRowBtn 					
					property='fillFill'					
					iconName='drawingFillFill'	
					tooltipLabel='Color Fill'
					active={activeFill=='solid'}
					onClick={() => editor.setStyle(DefaultFillStyle, 'solid')}
					disabled={!activeFill}
				/>				
				<PropertiesRowBtn 					
					property='fillHatch'
					iconName='drawingFillHatch'
					tooltipLabel='Hatch Fill'
					active={activeFill=='pattern'}
					onClick={() => editor.setStyle(DefaultFillStyle, 'pattern')}
					disabled={!activeFill}
				/>				

				<PropertiesRowDivider />

				<PropertiesRowBtn 
					property='colorBlack'					
					color='black'
					active={activeColor=='black'}
					onClick={() => editor.setStyle(DefaultColorStyle, 'black')}
				//	disabled={!activeFill}
				/>				
				<PropertiesRowBtn 					
					property='colorGrey'
					color='grey'
					active={activeColor=='grey'}
					onClick={() => editor.setStyle(DefaultColorStyle, 'grey')}
				/>
				<PropertiesRowBtn 					
					property='colorBlue'					
					color='blue'
					active={activeColor=='blue'}
					onClick={() => editor.setStyle(DefaultColorStyle, 'blue')}
				/>
				<PropertiesRowBtn 					
					property='colorLightBlue'					
					color='lightBlue'
					active={activeColor=='light-blue'}
					onClick={() => editor.setStyle(DefaultColorStyle, 'light-blue')}
				/>
				<PropertiesRowBtn 					
					property='colorOrange'					
					color='orange'
					active={activeColor=='orange'}
					onClick={() => editor.setStyle(DefaultColorStyle, 'orange')}
				/>
				<PropertiesRowBtn 					
					property='colorRed'
					color='red'
					active={activeColor=='red'}
					onClick={() => editor.setStyle(DefaultColorStyle, 'red')}
				/>
				<PropertiesRowBtn 					
					property='colorGreen'					
					color='green'
					active={activeColor=='green'}
					onClick={() => editor.setStyle(DefaultColorStyle, 'green')}
				/>
				<PropertiesRowBtn 					
					property='colorLightViolet'
					color='lightViolet'
					active={activeColor=='light-violet'}
					onClick={() => editor.setStyle(DefaultColorStyle, 'light-violet')}
				/>

				<PropertiesRowDivider />

				<PropertiesRowBtn 					
					defaultActive
					property='lineInk'		
					iconName='drawingLineScribble'
					tooltipLabel='Scribble Line'	
					active={activeDash=='draw'}
					onClick={() => editor.setStyle(DefaultDashStyle, 'draw')}
					disabled={!activeDash}			
				/>
				<PropertiesRowBtn 					
					property='lineStraight'				
					iconName='drawingLineStraight'
					tooltipLabel='Straight Line'
					active={activeDash=='solid'}
					onClick={() => editor.setStyle(DefaultDashStyle, 'solid')}
					disabled={!activeDash}	
				/>
				<PropertiesRowBtn 					
					property='lineDashed'
					iconName='drawingLineDashed'
					tooltipLabel='Dashed Line'
					active={activeDash=='dashed'}
					onClick={() => editor.setStyle(DefaultDashStyle, 'dashed')}
					disabled={!activeDash}	
				/>
				<PropertiesRowBtn 					
					property='lineDotted'
					iconName='drawingLineDotted'
					tooltipLabel='Dotted Line'
					active={activeDash=='dotted'}
					onClick={() => editor.setStyle(DefaultDashStyle, 'dotted')}
					disabled={!activeDash}
				/>

				<PropertiesRowDivider />
				
				<PropertiesRowBtn 				
					disabled
					property='textAlignLeft'
					iconName='alignTextLeft'					
					tooltipLabel='Text Left Align'
					active={activeAlign=='start'}
					onClick={() => editor.setStyle(DefaultHorizontalAlignStyle, 'start')}
					disabled={!activeAlign}
				/>

				<PropertiesRowBtn 					
					disabled
					property='textAlignCenter'
					iconName='alignTextCenter'
					tooltipLabel='Text Center Align'
					active={activeAlign=='middle'}
					onClick={() => editor.setStyle(DefaultHorizontalAlignStyle, 'middle')}
					disabled={!activeAlign}
				/>

				<div className='whiteboard-toolbar-propertiesRow-topBorder' />
					
			</div>
			
		)
	})


export default WhiteboardToolbarPropertiesRow