import React from 'react'
import Icon from '../../misc/Icon'
import LinearIssueWorkflowStateIcon from '../../misc/LinearIssueWorkflowStateIcon'
import LinearInlineIssuePriorityIcon from '../../misc/LinearInlineIssuePriorityIcon'
import LinearIssuePreviewPopover from '../../docEditor/mentionPopovers/LinearIssuePreviewPopover'
import {getUserForId} from '../../../utils/getUserForId'
import {getIssueForId} from '../../../utils/getIssueForId'
import {openIssueInLinear} from '../../../utils/openInLinear'
import * as ContextMenu from '@radix-ui/react-context-menu';
import ContextMenuCheckboxItem from '../../misc/ContextMenuCheckboxItem'

const OPEN_POPOVER_DELAY=500

class DocInlineLinearIssue extends React.Component{  	

	// constructor(props){
	// 	super(props)
	// 	this.state = {			
	// 		displayIdentifier: true,
	// 		displayAssignee: true,
	// 		displayTitle: false,
	// 		displayStatus: false,
	// 		displayPriority: true,
	// 	}
	// } 

 // 	constructor(props) {
 //    super(); 
 //    // this.handleMouseEnter=this.handleMouseEnter.bind(this) 
 //    // this.handleMouseLeave=this.handleMouseLeave.bind(this)
 //    // this.state = {    	
 //    // 	showPopover:false,        	
 //   	// };
 //   	this.timeout=null
 //  }

	// componentWillUnmount(){
	// 	if(this.timeout){
	// 		clearTimeout(this.timeout)
	// 	}
 //  }

 //  handleMouseEnter(){
 //  	this.timeout=setTimeout(function() {
	// 		this.setState({showPopover:true})
	// 	}.bind(this), OPEN_POPOVER_DELAY)
 //  }

 //  handleMouseLeave(){
 //  	if(this.timeout){
	// 		clearTimeout(this.timeout)
	// 	}
	// 	if(this.state.showPopover){
	// 		this.setState({showPopover:false})
	// 	}
 //  }


	render(){						

		const {identifier,assignee,showPopover,title} = this.props
		const {showTitle,showIdentifier,showStatus,showAssignee,showPriority}=this.props

		//const {displayIdentifier, displayAssignee, displayTitle, displayStatus, displayPriority} = this.state
		let avatar
		const user=getUserForId(assignee)
		if(user){
			avatar=user.avatar
		}

		const topPx = showAssignee ? 3 : 0 // no idea why this is needed, try and fix at your peril

		let truncatedTitle = title

		let trunctatedTitleLength = 60
		if(title && (title.length > trunctatedTitleLength)){
			 truncatedTitle = title.slice(0, trunctatedTitleLength)
			 // can't do truncation in CSS
			 if(title.charAt(trunctatedTitleLength - 1) === ' '){			 	
			 	truncatedTitle = truncatedTitle.slice(0, -1)
			 	// if ends on space, remove that too
			 }
			 truncatedTitle += "..."
		}

	  return (
	  	
	  	<ContextMenu.Root key={identifier}> 
					<ContextMenu.Trigger asChild>	
			  		<span onMouseEnter={this.props.openPopover} onMouseLeave={this.props.closePopover} onClick={this.props.closePopover} className='doc-inlineLinearIssue'>			  			
			  			<span className='doc-inlineLinearIssue-linkBtn' onClick={()=>{openIssueInLinear(identifier)}}>
			  				{assignee && showAssignee &&			
			  					<span className='doc-inlineLinearIssue-assignee doc-inlineLinearIssue-assignee--assigned'>			  					
										<img className='doc-inlineLinearIssue-assignee-img' src={avatar} />											
			  						<Icon name='linkArrowBoxed' /> 
									</span>
			  				}
			  				{showStatus && 				  							  					
			  					<span className='doc-inlineLinearIssue-statusContainer'>
				  					<LinearIssueWorkflowStateIcon
						  				workflowColorHex={this.props.workflowColorHex}
						  				workflowType={this.props.workflowType}		  				
						  				workflowTypeStateIndex={this.props.workflowTypeStateIndex}
						  				workflowTypeStatesCount={this.props.workflowTypeStatesCount}
						  			/>	
					  			</span>	
			  				}			  				
			  				{showIdentifier && 
				  				<span className={'doc-inlineLinearIssue-identifer ' + (showTitle ? 'doc-inlineLinearIssue-identifer--light' : '')}>
				  					{identifier}
				  				</span>  
			  				}		  				
			  				{showPriority &&
				  				<LinearInlineIssuePriorityIcon 
				  					priority={this.props.priority}
				  				/>
			  				}		  				
			  		</span>

			  				{showTitle &&				  				
				  				<span onClick={this.props.putCursorAfterIssue} className={'doc-inlineLinearIssue-title ' + (showPriority ? ' doc-inlineLinearIssue-title--priorityBefore ' : ' doc-inlineLinearIssue-title--identifierBefore ')}>
				  					{truncatedTitle}
				  				</span>					  				
			  				}	
			  			

							<LinearIssuePreviewPopover 
								assignee={assignee ? user : null}
								workflowType={this.props.workflowType}
								workflowColorHex={this.props.workflowColorHex}
								workflowTypeStateIndex={this.props.workflowTypeStateIndex}
								workflowTypeStatesCount={this.props.workflowTypeStatesCount}
								workflowStateName={this.props.workflowStateName}
								title={this.props.title}
								identifier={identifier}
								priority={this.props.priority}
								description={this.props.description}
								projectId={this.props.projectId}
								showPopover={showPopover}
								openPopover={()=>{this.setState({showPopover: true})}}
								closePopover={()=>{this.setState({showPopover: false})}}
							>
						
								<div className='doc-inlineLinearIssue-popoverTrigger'/>
							
							</LinearIssuePreviewPopover>
							  			
			  		</span>	  
			</ContextMenu.Trigger>
	  		<ContextMenu.Portal>
				<ContextMenu.Content collisionPadding={10} className='contextMenu contextMenu--inlineIssueViewOptions'>	        	       	        
					<div className='contextMenu-subMenu--moveDoc-header'>
						Display Properties
					</div>
					<ContextMenuCheckboxItem 
						label='Assignee'
						onClickFunction={()=>{this.props.updateIssueAttribute('showAssignee', !showAssignee)}}
						checked={showAssignee}
					/>
					{/*} MAYBE ALWAYS DISPLAY
					<ContextMenuCheckboxItem 
						label='Identifier'
						onClickFunction={()=>{this.setState({displayIdentifier: !displayIdentifier})}}
						checked={displayIdentifier}
					/>
					*/}
					<ContextMenuCheckboxItem 
						label='Title'
						onClickFunction={()=>{this.props.updateIssueAttribute('showTitle', !showTitle)}}
						checked={showTitle}
					/>
					<ContextMenuCheckboxItem 
						label='Status'
						onClickFunction={()=>{this.props.updateIssueAttribute('showStatus', !showStatus)}}
						checked={showStatus}
					/>
					<ContextMenuCheckboxItem 
						label='Priority'
						onClickFunction={()=>{this.props.updateIssueAttribute('showPriority', !showPriority)}}
						checked={showPriority}
					/>					
					<ContextMenu.Separator />
					<ContextMenu.Item onSelect={this.props.setAsDefaultMentionConfig}>
						Set as your default
					</ContextMenu.Item>								
				</ContextMenu.Content>
			</ContextMenu.Portal>
		</ContextMenu.Root>	
	  )
	}
}

export default DocInlineLinearIssue
