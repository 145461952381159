import React from 'react'
import Icon from '../../../misc/Icon'

class MessageInputBoxPlaceholder extends React.Component{  

	render(){			

		const {type,hasComment,threadParentMessage} = this.props
		let placeholderLabel = 'Write a message...'
		
		if(!hasComment && threadParentMessage){
			placeholderLabel = 'Reply...'
		}
		let topMargin = 0
		if(this.props.hasDocSelection && this.props.heightGhostSelectionMeasurementDiv){
			topMargin = this.props.heightGhostSelectionMeasurementDiv + 4
		} 

		return (	  	

			<div style={{marginTop: `${topMargin}px`}} className='docEditor-messagePanel-inputBox-placeholder'>
				<div className='docEditor-messagePanel-inputBox-placeholderText'>
					{placeholderLabel}
				</div>
			</div>  										
			
		)
	}
}


export default MessageInputBoxPlaceholder