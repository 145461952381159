//theme values
	//light,dark

function getInitialState(){
	let theme='dark'
	const localStorageTheme = localStorage.getItem('theme')
	if(localStorageTheme){
		theme=localStorageTheme
	}
	return theme
}





export default function (state = getInitialState(), action) {
	switch (action.type) {  
 	 case 'SET_THEME':
 	 		localStorage.setItem('theme',`${action.theme}`)
 	 		window.dispatchEvent( new Event('storage') );
			return action.theme
    default:
      return state;
	}
}


