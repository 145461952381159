import React from 'react'
import Icon from '../misc/Icon'
import OnboardingModalDevicePage from './OnboardingModalDevicePage'
import {getOrgName} from '../../utils/getOrgName'

class SideMenuTab extends React.Component {   
  
  render() {    
    const {active,iconName,label} = this.props    

    return (
    
      <div data-state={active ? "active" : "inactive"} className={'onboardingModal-sideMenu-tab ' + (this.props.org ? ' onboardingModal-sideMenu-tab--org ' : ' ')}>
        {iconName &&
        <div className='onboardingModal-sideMenu-tab-iconContainer'>
          <Icon name={iconName} />
        </div>
        }
        <div className='onboardingModal-sideMenu-tab-label'>
          {label}
        </div>        
      </div>
        
    )
  }
}

class OnboardingModalDevice extends React.Component {  	
  
  constructor(props) {
    super(props);    
    this.state = {
      
    }
  }

  render() {		
    const {stage} = this.props
    const orgName = getOrgName()

    const showSideMenu = stage === 'project' || stage === 'template'

    return (
      <React.Fragment>
        <div className={'onboardingModal-device ' + (showSideMenu ? ' onboardingModal-device--hasSideMenu ' : ' onboardingModal-device--noSideMenu ')}>
          
          {showSideMenu && 
            <div className='onboardingModal-device-sideMenu'>
              <div className='onboardingModal-device-sideMenu-header'>
                <button className='onboardingModal-device-sideMenu-header-plusBtn'>
                  <Icon name='plus' />
                </button>
              </div>
              <SideMenuTab               
                label={orgName}
                org              
              />          
              <SideMenuTab 
                iconName="roadmapOutline"
                label="Projects"  
                active={stage === 'project'} 
              />
              <SideMenuTab 
                iconName="sideMenuMeetingNotes"
                label="Meeting Notes"              
              />
              <SideMenuTab 
                iconName="sideMenuDrafts"
                label="Your Drafts"              
              />
              <SideMenuTab 
                iconName="clockRegular"
                label="Recent"              
              />

              {stage === 'template' && 
                <React.Fragment>
                
                  <div className='onboardingModal-device-sideMenu-divider'>
                    <div className='onboardingModal-device-sideMenu-divider-divider' />
                  </div>

                  <SideMenuTab 
                    active
                    marker="📥"
                    label={this.props.projectName}             
                  />
                  
                </React.Fragment>
              }          
            </div>
          }
          <div className='onboardingModal-device-pageContainer'>

          <OnboardingModalDevicePage 
            stage={stage}
            projectName={this.props.projectName}
            activeTemplate={this.props.activeTemplate}
          />

          </div>
        </div>
      
      


      </React.Fragment>
    )
  }
}

export default OnboardingModalDevice