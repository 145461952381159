import React from 'react'
import { Tldraw } from "@tldraw/tldraw"



class DrawingCanvasContainer extends React.Component{  


 constructor(){
    super()
    this.insertDrawing=this.insertDrawing.bind(this)
    this.handleOnChange=this.handleOnChange.bind(this)
    this.updateDrawing=this.updateDrawing.bind(this)
    this.state = {
      TLDrawApp:null,
      // src:'https://res.cloudinary.com/dedfh7fvu/image/upload/v1648782527/benjamin-voros-phIFdC6lA4E-unsplash_ph0i1w.jpg',
      // width:'2400',
      // height:'1601',
      // title:'Starry Night',
      // description:'',
    }

  }


  handleOnChange(TLDrawApp){
    this.setState({TLDrawApp:TLDrawApp})
  }


  insertDrawing(){
    const {TLDrawApp} = this.state
    TLDrawApp.selectAll()
    const tlDrawDoc=TLDrawApp.state.document
    TLDrawApp.copySvg().then((svg)=>{
      this.props.insertDrawing(svg,tlDrawDoc)
      this.props.closeModal()
    })
  }
	
  updateDrawing(){
    const {TLDrawApp} = this.state
    TLDrawApp.selectAll()
    const tlDrawDoc=TLDrawApp.state.document
    TLDrawApp.copySvg().then((svg)=>{
      this.props.updateDrawing(svg,tlDrawDoc)
      this.props.closeModal()
    })
  }
  



  render(){	
    const {isEdit, closeModal}=this.props



	  return (	 
      
        <div className='docEditor-drawingCanvasContainer'>        
      
        {!isEdit &&
         <button className='docEditor-drawingCanvas-saveBtn' onClick={this.insertDrawing}>Insert Drawing</button>
        }
        {isEdit &&
         <button className='docEditor-drawingCanvas-saveBtn' onClick={this.updateDrawing}>Save Changes</button>
        }

          {isEdit &&
            <Tldraw
              document={this.props.tlDrawDoc}
              onChange={this.handleOnChange}
              showMenu={false}
              showPages={false}
            />
          }

          {!isEdit &&
           <Tldraw
              onChange={this.handleOnChange}
              showMenu={false}
              showPages={false}
            />
          }
                      
        
      </div>
    ) 	
  	
	}
}

export default DrawingCanvasContainer
