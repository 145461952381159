import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import deepgram_nova from '../../recordingTestData/deepgram_nova.json'
import {EditorState} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import transcriptSchema from '../../../prosemirror/transcripts/transcriptSchema'
import {activeWordPlugin} from '../../../prosemirror/transcripts/transcriptPlugins/activeWord/activeWordPlugin'
import applyDevTools from "prosemirror-dev-tools"
import TranscriptVideoPlayer from './TranscriptVideoPlayer'
//import TranscriptSpeakerHeaderNodeView from '../../../prosemirror/transcripts/nodes/TranscriptSpeakerHeaderNodeView'
import clickOnWordPlugin from "../../../prosemirror/transcripts/transcriptPlugins/clickOnWordPlugin";
import transcriptHighlightsPlugin from "../../../prosemirror/transcripts/transcriptPlugins/highlights/transcriptHighlightsPlugin";



const MUX_PLAYBACK_ID='CdZiPExv2cFGy3pxeE902y2h1X02K71vMSRT01veYQxcqA'
const test_text="orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum"


function roundToDecimalPlace(num, decimalPlaces) {
    let multiplier = Math.pow(10, decimalPlaces);
    return Math.round(num * multiplier) / multiplier;
}



const highlight1={
	id:1,
	text:'test',
	start:10, //time
	end:15, //time
	startPos:4,
	endPos:150,
	speakers:[0]
}

const highlights=[highlight1]



class TranscriptProto extends Component {

	constructor(props) {
		super(props)		
		this.loadView=this.loadView.bind(this)
		this.updateTime=this.updateTime.bind(this)
		this.handleVideoProgress=this.handleVideoProgress.bind(this)
		this.setPlayerRef=this.setPlayerRef.bind(this)
		this.handleClickOnWord=this.handleClickOnWord.bind(this)
		this.updateHighlights=this.updateHighlights.bind(this)

		this.state={			
			isExpandedIndex: null,		
			zoomed: false,
			time:0,
			activeParagraph:0,
			priorWordCount:0,
			doc:null
		}
		this.player=null
	} 


	componentDidMount() {	
		this.loadView()
	}

	updateHighlights(){
		// console.log('update highlights!!!!!')
		// const view=this.view
		// if(view){
		// 	let tr = view.state.tr
		// 	//tr.setMeta("highlightTextPlugin", {activeParagraph:activeParaIndex})
		// 	tr.setMeta("transciptHighlightsPlugin", {highlights:highlights})
		// 	view.dispatch(tr)
		// }

	}


	setPlayerRef(ref){
		this.player=ref
	}

	handleVideoProgress(time){
		this.updateTime(time)
	}

	updateTime(newTime){
		// console.log('update time!!!!!!!')
		//const newTime=this.state.time+0.1
		this.setState({time:newTime})
		// let activeParaIndex=null
		// let previousParaCount=0
		// let priorWordCount=0
		// const paragraphs=deepgram_nova.results.channels[0].alternatives[0].paragraphs.paragraphs

		// paragraphs.some(function(paragraph, index) {
		//    paragraph.sentences.map((sentence)=>{
		//    	if (sentence.start<newTime && sentence.end>newTime) {
		// 		activeParaIndex=previousParaCount
		//         return true;  // Break
		//     }else{
		// 	//	console.log('here-------')
		// 		priorWordCount+=sentence.num_words
		// 		previousParaCount+=1
		// 	}
		//    })

		    
		// });

   	const view=this.view
		if(view){
			let tr = view.state.tr
			//tr.setMeta("highlightTextPlugin", {activeParagraph:activeParaIndex})
			tr.setMeta("activeWordPlugin", {currentTime:newTime})
			view.dispatch(tr)
		}
		// this.setState({activeParagraph:activeParaIndex,priorWordCount:priorWordCount})

	}

	handleClickOnWord(node){
		console.log('click on word!!!!!')
		console.log(node)
		console.log(node.attrs.start)
		//this.player.seekTo(parseFloat(node.attrs.start))
		console.log(roundToDecimalPlace(node.attrs.start,6))
			this.player.seekTo(roundToDecimalPlace(node.attrs.start,6))
	}


	loadView(){
		if(this.view){
			this.view.destroy()
		}

		const handleClickOnWord=this.handleClickOnWord
		this.view = new EditorView(document.querySelector("#transcript"), {
			attributes: {				
				spellCheck: false,
			},

			state: EditorState.create({
			doc: this.makeDocJson(),
			plugins:[
				activeWordPlugin,
				clickOnWordPlugin(handleClickOnWord),
				transcriptHighlightsPlugin()
			],	
		}),
		dispatchTransaction: transaction => { //for resizing inputbox -- maybe throttle?
			const { state, transactions } = this.view.state.applyTransaction(transaction)
			this.view.updateState(state)
			// const doc=state.doc
			// const html=convertNodeToHTML(doc)
			// this.setState({messageHTML:html})
		},
		editable: function(state) {
    	return false; // Make it read only
  	},
		nodeViews: { //custom node views
		//	transcriptSpeakerHeader(node, view, getPos) { return new TranscriptSpeakerHeaderNodeView(node, view, getPos) },
		},
		
	})
		//applyDevTools(this.view)
		window.transcriptView=this.view 
		this.updateHighlights()
	}

	makeDocJson(){
		const paragraphs=deepgram_nova.results.channels[0].alternatives[0].paragraphs.paragraphs
		const words=deepgram_nova.results.channels[0].alternatives[0].words
		let content=[]
		let currentWordCount=0
		paragraphs.forEach((paragraph)=>{
			const headerNode=transcriptSchema.nodes.transcriptSpeakerHeader.createAndFill({speakerId:paragraph.speaker})
			content.push(headerNode)

			const wordCount=paragraph.num_words
			const wordSlice=words.slice(currentWordCount,currentWordCount+wordCount)
			let wordNodes=[]
			wordSlice.forEach((word)=>{
				const wordNode=transcriptSchema.nodes.transcriptWord.createAndFill({start:word.start,end:word.end},[transcriptSchema.text(`${word.word} `)])
				wordNodes.push(wordNode)
			})
			const paragraphNode=transcriptSchema.nodes.paragraph.createAndFill(null,wordNodes)
			content.push(paragraphNode)
			currentWordCount+=wordCount
			
			//alternative by sentence formating
			// const sentences=paragraph.sentences
			// sentences.forEach((sentence)=>{
			// 	let words=[]
			// 	const wordNode=transcriptSchema.nodes.transcriptWord.createAndFill(null,[transcriptSchema.text('hi')])
			// 	//const wordNode=transcriptSchema.nodes.transcriptWord.createAndFill(null,transcriptSchema.text('hi'))
			// 	//console.log(wordNode)

			// 	const paragraphNode=transcriptSchema.nodes.paragraph.createAndFill(null,[transcriptSchema.text(sentence.text)])
			// 	content.push(paragraphNode)
			// })


		})


		//const paragraphNode=transcriptSchema.nodes.paragraph.createAndFill(null,[transcriptSchema.text(sentence.text)])
		const doc=transcriptSchema.nodes.doc.createAndFill(null,content)
		this.setState({doc:doc})
		return doc
	}


	render(){  

		const {zoomed} = this.state

		// const embedId='1421484731' // apple music
		// //const embedId='281279025' // iphone 14
		// const embedObj = getFigmaEmbedForId(embedId)	
		// console.log(deepgram_nova)
		// const transcript=deepgram_nova.results.channels[0].alternatives[0].transcript
		// const paragraphs=deepgram_nova.results.channels[0].alternatives[0].paragraphs.paragraphs
		// console.log(paragraphs)

		return (	  	
			<div className='playgroundTranscriptProto'>
				<TranscriptVideoPlayer 
					handleVideoProgress={this.handleVideoProgress} 
					playbackId={MUX_PLAYBACK_ID}
					setPlayerRef={this.setPlayerRef}
				/>


			time: {this.state.time}
			<br/>
			activeParagraph:{this.state.activeParagraph}
					<br/>
			priorWordCount:{this.state.priorWordCount}
				<div id='transcript'/>
			

			
			{/*{paragraphs.map((paragraph)=>{

				return(
					<React.Fragment>
					<div>
					Nicole
						{{paragraph.sentences.map((sentence)=>{

							return(
								<div>{sentence.text}</div>

								)
						})}}


					</div>
			
					<br/>
					</React.Fragment>
					)
			})}


*/}

	<div className='docEditor-outputPanelOuterContainer'>
            <div className='docEditor-outputPanelInnerContainer'>            
              <div className='docEditor-outputPanel'>
          			<div className='docEditor-outputPanel-title'>
                  PM Doc
                </div>
          			 <pre>
                  <code>

                  {JSON.stringify(this.state.doc, null, 2)}
    
                  </code>
                </pre>
          		</div>
            </div>
          </div>


			</div>   	
		)
	}
}



function mapStateToProps(state,ownProps) {


	return {
			//figmaEmbeds:state.figmaEmbeds
	}
}


export default withRouter(connect(mapStateToProps,
	{}
)(TranscriptProto))	

