import React from 'react'
import DocFigmaEmbedZoomedModal from '../docEditor/mediaModals/figma/DocFigmaEmbedZoomedModal'
import {showDocMediaModal} from '../../utils/showDocMediaModal'
import FigmaViewBackgroundImage from './FigmaViewBackgroundImage'
import FigmaViewDeviceFrame from './FigmaViewDeviceFrame'
import {getDeviceForFigmaFrame} from '../../utils/getDeviceForFigmaFrame'
import {getDeviceFramePadding} from '../../utils/getDeviceFramePadding'
import FigmaViewFrameContents from './FigmaViewFrameContents'
import DocFigmaEmbedContextMenuContents from '../nodeViews/figma/DocFigmaEmbedContextMenuContents'
import * as ContextMenu from '@radix-ui/react-context-menu';


class FigmaView extends React.Component{  	
  constructor(props){
    super()  
    this.handleClickOnView=this.handleClickOnView.bind(this)
    this.handleBackgroundImageClickOnView=this.handleBackgroundImageClickOnView.bind(this)
  }

  handleClickOnView(e){
  	if(this.props.showEffectsToolbar){
  		this.props.hideEffectsToolbar()
  	}else{
  		if(this.props.zoomed){
  			this.props.unzoom()
  		}else{
  		this.props.zoom()  	
  		}
  	}
  }

  
	handleBackgroundImageClickOnView(e){
  	if(this.props.showEffectsToolbar){
  		this.props.hideEffectsToolbar()
  	}else{
  		if(this.props.zoomed){
  			this.props.unzoom()
  		}else{
  			this.props.zoom()  	
  		}
  	}
  }
  

	calculatedDeviceAugmentedFrame(frameDimensions,deviceFrameBorder,backgroundPadding){		
		const width = frameDimensions.width + deviceFrameBorder.left + deviceFrameBorder.right + backgroundPadding + backgroundPadding
		const height = frameDimensions.height + deviceFrameBorder.top + deviceFrameBorder.bottom + backgroundPadding + backgroundPadding
		return {
			width:width,
			height:height,
		}
	}

	deviceAugmentedCrop(frameDimensions,crop,deviceFrameBorder,backgroundPadding){	
		
		const croppedToTopEdge = (!crop || crop.y === 0)
		const croppedToLeftEdge = (!crop || crop.x === 0)
		const croppedToBottomEdge = (!crop || crop.y + crop.height === frameDimensions.height)
		const croppedToRightEdge = (!crop || crop.x + crop.width === frameDimensions.width)
		
		let width = crop ? crop.width : frameDimensions.width
		let height = crop ? crop.height : frameDimensions.height
		let cropX = crop ? crop.x : 0
		let cropY = crop ? crop.y : 0
		
		if(croppedToLeftEdge){
			width += deviceFrameBorder.left + backgroundPadding
		}		
		if(croppedToRightEdge){
			width += deviceFrameBorder.right + backgroundPadding
		}			
		if(croppedToTopEdge){
			height += deviceFrameBorder.top + backgroundPadding
		}			
		if(croppedToBottomEdge){
			height += deviceFrameBorder.bottom + backgroundPadding
		}
		if(!croppedToTopEdge){
			cropY += deviceFrameBorder.top + backgroundPadding
		}	
		if(!croppedToLeftEdge){
			cropX += deviceFrameBorder.left + backgroundPadding
		}
		return {
			width:width,
			height:height,
			x:cropX,
			y:cropY,
		}
	}

	calculateFitToBoundingContainerDimensions(preFitDimensions,boundingContainerDimensions){		
		const preFitAspectRatio = preFitDimensions.width / preFitDimensions.height
		const boundingContainerAspectRatio = boundingContainerDimensions.width / boundingContainerDimensions.height
		let width
		let height
		let heightConstrained = false		
		if(boundingContainerAspectRatio > preFitAspectRatio){// bounding is wider than figma, so figma fits bounding height
			height = boundingContainerDimensions.height
			width = boundingContainerDimensions.height * preFitAspectRatio
			heightConstrained = true
		}else if(boundingContainerAspectRatio <= preFitAspectRatio){// bounding is narrower than figma, so figma fits bounding width		
			width = boundingContainerDimensions.width
			height = boundingContainerDimensions.width / preFitAspectRatio		
		}
		const scale = width / preFitDimensions.width		
		return {
			width:width,
			height:height,
			scale:scale,
			heightConstrained: heightConstrained,
		}
	}


	render(){	
		const {crop}=this.props
		const {backgroundImage,device,deviceFrame,backgroundPadding,deviceShadow}=this.props
		const {embedId,embedObj, boundingMaxContainerDimensions,backgroundMaxWidth,disableMotion}=this.props
		const boundingContainerDimensions = boundingMaxContainerDimensions
		const absRenderBounds=embedObj.absolute_render_bounds

		const frameDimensions = {
			width: absRenderBounds.width,
			height: absRenderBounds.height,
		}

		let noCrop = false
		if(!crop){
			noCrop = true
		}
		if(crop && (crop.width === frameDimensions.width) && (crop.height === frameDimensions.height)){
			noCrop = true
		}

		//
		// If no device, then frame
		let deviceFrameBorder = {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
		}
		if(deviceFrame){
			deviceFrameBorder = getDeviceFramePadding(deviceFrame)	
		}

		// 
		// If no background image, then we don't calculate with background padding
		let backgroundPaddingLevel = backgroundPadding		
		if(!backgroundImage){
			backgroundPaddingLevel = 0
		}

		const backgroundPaddingAmount = backgroundPaddingLevel * (Math.max(frameDimensions.width,frameDimensions.height)) // this seems fair, it doesn't penalize against phones

		const deviceAugmentedFrame = this.calculatedDeviceAugmentedFrame(frameDimensions,deviceFrameBorder,backgroundPaddingAmount)
		const deviceAugmentedCrop = this.deviceAugmentedCrop(frameDimensions, crop, deviceFrameBorder,backgroundPaddingAmount)					

		// Calculate the scaled crop box (blue box is the crop window)
		const calculatedCropWindow = this.calculateFitToBoundingContainerDimensions(deviceAugmentedCrop,boundingContainerDimensions)

		
		//Find the ratio of scaled crop to origional (so we can scale the whole device by the same amount)
		const cropToFrameWidthRatio = deviceAugmentedCrop.width / deviceAugmentedFrame.width
		const cropToFrameHeightRatio = deviceAugmentedCrop.height / deviceAugmentedFrame.height
		//"Green box" is the full figma frame (as opposed to the crop window)
		

		const calculatedFrame = {
			width: calculatedCropWindow.width / cropToFrameWidthRatio,
			height: calculatedCropWindow.height / cropToFrameHeightRatio,
			translate: {
				x: (-1 * deviceAugmentedCrop.x * calculatedCropWindow.scale),
				y: (-1 * deviceAugmentedCrop.y * calculatedCropWindow.scale)
			}			
		}

		let backgroundBoxWidth = calculatedCropWindow.width

		let maxBackgroundWidthOffset = 0
		// This sets to full width if not cropped for e.g. mobiles
		if(backgroundMaxWidth && calculatedCropWindow.heightConstrained && noCrop){
			backgroundBoxWidth = boundingMaxContainerDimensions.width
			maxBackgroundWidthOffset = (backgroundBoxWidth - calculatedCropWindow.width) / 2
		}

		const augmentedFrameToCalculatedFrameRatio = calculatedFrame.width / deviceAugmentedFrame.width		

		let id = `figmaView--${embedObj.embed_id}`
		if(this.props.embeddedInZoomModal){
			id = null
		}		

	  return (	  	
			<ContextMenu.Root key={embedId}> 
				<ContextMenu.Trigger asChild>	
					<div id={id} style={{width: `${backgroundBoxWidth}px`, height: `${calculatedCropWindow.height}px`, borderRadius: '5px'}} className='figmaView'>															
						<div onClick={this.handleClickOnView} style={{transform: `translateX(${maxBackgroundWidthOffset}px)`, width: `${calculatedCropWindow.width}px`, height: `${calculatedCropWindow.height}px`}} className='figmaView-cropWindow'>	  		
							
							{/* FRAME aka GREEN BOX */}
							<div style={{transform: `translateX(${calculatedFrame.translate.x}px) translateY(${calculatedFrame.translate.y}px)`, width: `${calculatedFrame.width}px`, height: `${calculatedFrame.height}px`}} className='figmaView-frame'>		  			
								<FigmaViewFrameContents 
									calculatedFrame={calculatedFrame}
									deviceAugmentedFrame={deviceAugmentedFrame}		
									frameDimensions={frameDimensions}
									device={device}
									deviceFrame={deviceFrame}
									deviceFrameBorder={deviceFrameBorder}
									backgroundImage={backgroundImage}	
									backgroundPaddingAmount={backgroundPaddingAmount}
									embedObj={embedObj}
									noCrop={noCrop}
								/>
							</div>

						</div>

			
						<FigmaViewBackgroundImage handleBackgroundImageClickOnView={this.handleBackgroundImageClickOnView} backgroundImage={backgroundImage} scale={augmentedFrameToCalculatedFrameRatio} />
					</div>  

				</ContextMenu.Trigger>
				<ContextMenu.Portal>
					<ContextMenu.Content collisionPadding={10} className={'contextMenu ' + (this.props.showCropContextMenu ? 'forceDarkTheme' : '')}> 
						<DocFigmaEmbedContextMenuContents 
							openEffectsToolbar={this.props.openEffectsToolbar}
							showEffectsToolbar={this.props.showEffectsToolbar}
							deleteFigmaEmbed={this.props.deleteFigmaEmbed}
							embedId={embedId}
							hasPresentationEffects={backgroundImage || deviceFrame}							
							showCropContextMenu={this.props.showCropContextMenu}
							cropModeActive={this.props.cropModeActive}
							enableCropMode={this.props.enableCropMode}
							disableCropMode={this.props.disableCropMode}
						/>
					</ContextMenu.Content>
				</ContextMenu.Portal>
			</ContextMenu.Root>			
	  )
	}
}

export default FigmaView