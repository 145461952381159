
import schema from '../../schema/editorSchema'  
import {TextSelection} from "prosemirror-state"
import {joinPoint, canJoin, findWrapping, liftTarget, canSplit,
        ReplaceStep, ReplaceAroundStep, replaceStep} from "prosemirror-transform"
import {wrapIn,lift,setBlockType,createParagraphNear,chainCommands} from 'prosemirror-commands'
import {getAnchorNode} from '../getAnchorNode'

//https://prosemirror.net/docs/ref/#commands.createParagraphNear

export function insertHeaderCommand(level) {
	return function(state, dispatch) {
		const nodeType=schema.nodes.heading
		return setBlockType(nodeType,{level:level})(state,dispatch)
  }
}

export function headerToParagraphCommand() {
	return function(state, dispatch) {
		const nodeType=schema.nodes.paragraph
		return setBlockType(nodeType)(state,dispatch)
  }
}



// export function removeContentBoxCommand() {
// 	return function(state, dispatch) {
// 		let {$from, $to} = state.selection
//   	let range = $from.blockRange($to), target = range && liftTarget(range)
//   	if (target == null){
//   		return false
//   	}else{
//   		if (dispatch){
//   			dispatch(state.tr.lift(range, target).scrollIntoView())
//   		}
//  			 return true
//   	}
  	

//   }
// }


// export function setHeadingType(headingType) {
// 	const state=window.view.state
// 	const type=schema.nodes.heading
// 	let tr=state.tr
// 	tr.setBlockType(state.selection.from,state.selection.to,type,{level:headingType})
// 	window.view.dispatch(tr)
// }