import React from 'react'
import Icon from '../../misc/Icon'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import CodeMirrorMenuLanguagePopoverContents from './CodeMirrorMenuLanguagePopoverContents'

class CodeMirrorMenuLanguagePopover extends React.Component{  	

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)		
		this.handleChangeLanguage=this.handleChangeLanguage.bind(this)
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  handleChangeLanguage(language){
  	this.props.changeLanguage(language)
  	this.props.closePopover()
  }

	render(){	
		const {showPopover, openPopover, closePopover, currentTheme} = this.props
		const {forceMount} = this.state
	
		const popoverSide = "right"
		const popoverSideOffset= 12
		const popoverAlign = "middle"				
		const popoverAlignOffset= -18 // to centralize it

		//const translateY = -8
		const translateY = -4
		
		return (
			<React.Fragment>
				<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>					
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'codeMirrorMenuLanguagePopoverContainer ' + (this.props.darkUI ? ' forceDarkTheme ' : ' ' ) + (showPopover ? ' codeMirrorMenuLanguagePopoverContainer--visible ' : ' codeMirrorMenuLanguagePopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							//onInteractOutside={closePopover}
							collisionPadding={20}							
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `scale(0.9)` }}
								enter={{ opacity: 1, transform: 'scale(1)'  }}
								leave={{ opacity: 0, transform: `scale(0.9)`  }}
								reverse={showPopover}
								//config={{tension: 700, friction: 36}}
								config={{duration: 0}}

								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className={'codeMirrorLanguagePopover popover ' + (currentTheme ? ` codeMirrorLanguagePopover--theme--${currentTheme} ` : '')}> 	
										 <CodeMirrorMenuLanguagePopoverContents 
										 	changeLanguage={this.handleChangeLanguage}
										 	currentLanguage={this.props.currentLanguage}
										 	closePopover={this.props.closePopover}

										 />
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			</React.Fragment>
		)
	}
}

export default CodeMirrorMenuLanguagePopover