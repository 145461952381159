const BulletListItemNodeSpec = {
	attrs:{
    indentLevel:{default:0},
    markerType:{default:'dash'}, //dash or number
    positionInList:{default:0},
    separatorType:{default:'period'} //period or bracket
  },
	group: "block",
	content: "block",
  draggable:false,
  defining:true,
	parseDOM: [
      {
        tag: "div.doc-list-item",
        getAttrs: (dom) => ({
          indentLevel: parseInt(dom.getAttribute("data-indent-level"),10),
          markerType: dom.getAttribute("data-marker-type"),
          positionInList:parseInt(dom.getAttribute("data-position-in-list"),10)
      }),
      }
    ],
    toDOM(node) {
      const {indentLevel,markerType,positionInList} = node.attrs;
      return ["div",{class:`doc-list-item`,"data-indent-level":indentLevel,"data-marker-type":markerType,"data-position-in-list":positionInList}, 0]
    }

}

export default BulletListItemNodeSpec

//
// Possible node spec for bullet list item
// basically identcal to node view
//
// toDOM(node) {
//       const {indentLevel} = node.attrs;
//       const div = document.createElement("div");
//       div.classList.add('doc-listItem')
//       div.setAttribute('data-indent-level',indentLevel)

//       this.markerContainer= document.createElement("div");
//       this.markerContainer.classList.add('doc-listItem-markerContainer')    
//       this.markerContainer.contentEditable = 'false'

//       div.appendChild(this.markerContainer)

//       this.marker= document.createElement("div");
//       this.marker.classList.add('doc-listItem-marker')
//       this.markerContainer.appendChild(this.marker)

//       const listContentDom=document.createElement("div")
//       listContentDom.classList.add('doc-listItem-contents')    
//       div.appendChild(listContentDom)

//       this.contentDOM=listContentDom
    

//       return div 