//import './wdyr'
import React from 'react'
import ReactDOM from 'react-dom'
import AppContainer from './containers/AppContainer'
import { Provider } from 'react-redux'
import store from './store'
import "./styles/App.scss"
import { GoogleOAuthProvider } from '@react-oauth/google';
import LogRocket from 'logrocket';

// console.log('init logrocket')
// console.log(process.env.REACT_APP_LOGROCKET_APP_ID)
LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);


///the web one
const CLIENT_ID="1025713914072-8d8095iuglnd52cice440t0fvbi4ld8s.apps.googleusercontent.com"

////test
//const CLIENT_ID='1025713914072-nu5cu0o9km2843125nr0n9kf68lo6b3i.apps.googleusercontent.com'

//const onStorage = createOnStorage(store)
//window.addEventListener('storage', onStorage)// listen to local storage events for new actions TODO might be able to take this out as dont have proper tabs in electron any more

function render() {
	ReactDOM.render(
		<Provider store={store}>
		<GoogleOAuthProvider clientId={CLIENT_ID}>
			<AppContainer/>
		</GoogleOAuthProvider>
		</Provider>
	, document.getElementById('root'));
}
render();