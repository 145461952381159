import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {removeGoogleIntegration} from '../../actions/googleIntegration'
import {formatDate} from '../../utils/formatDate'
import Icon from '../misc/Icon'
import GoogleLoginButton from '../integrations/GoogleLoginButton'
import {hideMeetingsPageBanner} from '../../utils/meetings/hideMeetingsPageBanner'


class MeetingsPageGCalIntegrationButton extends Component {

  render(){  
    if(!this.props.googleIntegration && !this.props.bannerDismissed){
      return ( 
        <div className='meetingNotesPage-GCalBannerOuterContainer'>
            <div className='meetingNotesPage-leftSpacer' /> 
            <div className='meetingNotesPage-center'>        
            <div className='meetingNotesPage-GCalBanner'>
              <div className='meetingNotesPage-GCalBanner-content'>


                <div className='meetingNotesPage-GCalBanner-content-iconContainer'>
                  
                  <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 200 200">                  
                    <g fill="none" fillRule="nonzero">
                      {/* <path id="whiteBG" fill="#FFF" d="m152.632 47.368-47.368-5.263-57.895 5.263L42.105 100l5.263 52.632L100 159.211l52.632-6.579 5.263-53.947z"/> */}
                      {/* <path d="M68.961 129.026c-3.934-2.658-6.658-6.539-8.145-11.671l9.132-3.763c.829 3.158 2.276 5.605 4.342 7.342 2.053 1.737 4.553 2.592 7.474 2.592 2.987 0 5.553-.908 7.697-2.724 2.144-1.816 3.224-4.132 3.224-6.934 0-2.868-1.132-5.211-3.395-7.026-2.263-1.815-5.105-2.724-8.5-2.724h-5.276v-9.039h4.736c2.921 0 5.382-.789 7.382-2.368 2-1.579 3-3.737 3-6.487 0-2.447-.895-4.395-2.684-5.855-1.789-1.46-4.053-2.197-6.803-2.197-2.684 0-4.816.711-6.395 2.145-1.579 1.434-2.724 3.197-3.447 5.276l-9.039-3.763c1.197-3.395 3.395-6.395 6.618-8.987 3.224-2.592 7.342-3.895 12.342-3.895 3.697 0 7.026.711 9.974 2.145 2.947 1.434 5.263 3.421 6.934 5.947 1.671 2.539 2.5 5.382 2.5 8.539 0 3.224-.776 5.947-2.329 8.184-1.553 2.237-3.461 3.947-5.724 5.145v.539a17.379 17.379 0 0 1 7.342 5.724c1.908 2.566 2.868 5.632 2.868 9.211 0 3.579-.908 6.776-2.724 9.579-1.816 2.803-4.329 5.013-7.513 6.618-3.197 1.605-6.789 2.421-10.776 2.421-4.618.013-8.881-1.316-12.815-3.974ZM125 83.711l-9.974 7.25-5.013-7.605L128 70.382h6.895v61.197H125z" fill="#1A73E8"/> */}
                      <path fill="#EA4335" d="M152.632 200 200 152.632l-23.684-10.526-23.684 10.526-10.526 23.684z"/>
                      <path fill="#34A853" d="M36.842 176.316 47.368 200h105.263v-47.368H47.368z"/>
                      <path d="M15.789 0C7.066 0 0 7.066 0 15.789v136.842l23.684 10.526 23.684-10.526V47.368h105.263l10.526-23.684L152.632 0H15.789Z" fill="#4285F4"/>
                      <path d="M0 152.632v31.579C0 192.935 7.066 200 15.789 200h31.579v-47.368H0Z" fill="#188038"/>
                      <path fill="#FBBC04" d="M152.632 47.368v105.263H200V47.368l-23.684-10.526z"/>
                      <path d="M200 47.368V15.789C200 7.065 192.934 0 184.211 0h-31.579v47.368H200Z" fill="#1967D2"/>
                    </g>                  
                  </svg> 

                </div>
                          
                <div className='meetingNotesPage-GCalBanner-content-title'>
                  Connect Google Calendar
                </div>
                <div className='meetingNotesPage-GCalBanner-content-description'>              
                  <div className='meetingNotesPage-GCalBanner-content-description-para'>
                    Import your calendar to get setup instantly.
                  </div>
                  <div className='meetingNotesPage-GCalBanner-content-description-para'>
                    Share meeting notes with participants in one-click.
                  </div>
                  {/*}
                  <div className='meetingNotesPage-GCalBanner-content-description-para'>
                    Connect to recurring meetings.
                  </div>              
                  */}
                   
                </div>
                <div className='meetingNotesPage-GCalBanner-content-buttonContainer'>
                  <div className='meetingNotesPage-GCalBanner-content-buttonSideLine meetingNotesPage-GCalBanner-content-buttonSideLine--left' />
                  <GoogleLoginButton />
                  <div className='meetingNotesPage-GCalBanner-content-buttonSideLine meetingNotesPage-GCalBanner-content-buttonSideLine--right' />
                </div>
                
                {/*}
                <a href="www.yarn.so/privacy" className='meetingNotesPage-GCalBanner-content-description-para meetingNotesPage-GCalBanner-content-description-para--link'>
                    Yarn Privacy Policy
                </a>                       
                */}

              </div>



              <button onClick={hideMeetingsPageBanner} className='meetingNotesPage-GCalBanner-hideBtn'>
                <Icon name='crossCircleMedium' />
              </button>
                       
              <div className='meetingNotesPage-GCalBanner-innerBorder' /> 
            </div>
          </div>
          
          <div className='meetingNotesPage-rightPanel' />

        </div>
      )
    }
      else return null
    }
}

function mapStateToProps(state,ownProps) {
  const meetingsPageSettings=state.meetingsPageSettings
  let bannerDismissed=false 
  if(meetingsPageSettings && meetingsPageSettings.banner && meetingsPageSettings.banner.dismissed){
    bannerDismissed=true
  }
  return {
      user:state.user,
      googleIntegration:state.googleIntegration,
      bannerDismissed:bannerDismissed
  }
}


export default withRouter(connect(mapStateToProps,
  {removeGoogleIntegration}
)(MeetingsPageGCalIntegrationButton))  


