import {fetch,put} from '../api'
import {fetchADoc} from './docs'
import {starTab,getTabStarredStatus} from '../utils/starredTabs'
import {backgroundOpenDoc} from '../utils/openDoc'
import {fetchWhiteboardEmbeds} from './whiteboardEmbed'
import {fetchAMeeting} from './meetings'
import {fetchRecording} from './recording'
import {fetchTranscript} from './transcripts'

// export function fetchOnboardingStatus(){
// 	return (dispatch) => fetch(`/onboarding`)
// 		.then((response) => {
// 			dispatch({ type: 'FETCH_ONBOARDING_STATUS_SUCCESS', response })
// 			if(response.stage!=='complete'){
// 				if(response.onboarding_doc_id){
// 					//fetch it and then favorite it
// 					return dispatch(fetchADoc(response.onboarding_doc_id)).then(()=>{
// 						backgroundOpenDoc(response.onboarding_doc_id)
// 						dispatch(fetchWhiteboardEmbeds())
// 					})
// 				}
// 			}

// 			return response
// 		})
// 		.catch((error) => {
// 			console.log(error)
// 			return error
// 		})
// }

export function fetchOnboardingStatus(){
	return (dispatch) => fetch(`/onboarding`)
		.then((response) => {
			dispatch({ type: 'FETCH_ONBOARDING_STATUS_SUCCESS', response })
			if(response.stage!=='complete'){
				let promises = [];
				if(response.onboarding_doc_id){
					promises.push(
						dispatch(fetchADoc(response.onboarding_doc_id))
						.then(() => {
							backgroundOpenDoc(response.onboarding_doc_id)
							dispatch(fetchWhiteboardEmbeds())
						})
					);
				}

				if (response.discovery_meeting) {
					// fetch the discover_meeting
					promises.push(dispatch(fetchAMeeting(response.discovery_meeting.meetingId)));
					promises.push(dispatch(fetchADoc(response.discovery_meeting.docId)))
					promises.push(dispatch(fetchRecording(response.discovery_meeting.recordingId)))
				}

				// Use Promise.all to handle all the promises, it will wait until all the promises are settled.
				return Promise.all(promises).then(([onboardingDoc,meeting,doc,recording]) => {
					//open the meeting 
					if(response.discovery_meeting){
						backgroundOpenDoc(response.discovery_meeting.docId)
					}
					console.log(recording)
					console.log(recording.transcript_id)
					if(recording &&recording.transcript_id){
						dispatch(fetchTranscript(recording.transcript_id))
					}
					return response
				});
			}

			
		})
		.catch((error) => {
			console.log(error)
			return error
		})
}

export function updateOnboardingStage(stage) {
	const requestBody={
		stage:stage
	}
	return (dispatch) => {
		dispatch({ type: 'UPDATE_ONBOARDING_STAGE_REQUEST', stage })
		return put(`/onboarding`, requestBody)
			.then((response) => {
			//	dispatch({ type: 'FETCH_ONBOARDING_STATUS_SUCCESS', response })
			return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
	}
}


// export function updateOnboardingStage(stage){
// 	console.log('update onboarding stage---------- request')
// 	const requestBody={
// 		stage:stage
// 	}
// 	console.log(requestBody)
// 	return (dispatch) =>{ 
// 		dispatch({ type: 'UPDATE_ONBOARDING_STAGE_REQUEST', stage })
// 		return (dispatch) => put(`/onboarding`,requestBody)
// 			.then((response) => {
// 				console.log('onboarding update stage! result')
// 				console.log(response)
// 				dispatch({ type: 'FETCH_ONBOARDING_STATUS_SUCCESS', response })
// 				return response
// 			})
// 			.catch((error) => {
// 				console.log(error)
// 				return error
// 			})
// 		}
// 	}
