import store from '../store'
import filter from 'lodash/filter'
import find from 'lodash/find'

//scope
//started
//completed


//(completed estimate points + (0.25 * in progress estimate points)) / total estimate points.

export function calcProjectStats(projectId) {
	const state = store.getState()

	const project=find(state.projects,{project_id:projectId})
	let projectInProgress=project && (project.state=='started' || project.state=='paused')

	const issues= filter(state.issues, {project:`${projectId}`})
	let issuesWithWorkflowState=[]
	issues.forEach((issue)=>{
		const workflow_state=find(state.workflowStates,{state_id:issue.workflow_state})
		const issueWithState= {...issue,workflow_state:workflow_state}
		issuesWithWorkflowState.push(issueWithState)
	})


	const issuesExcluCanceled=filter(issuesWithWorkflowState,function(iss){
		if(iss.workflow_state){
			return iss.workflow_state.type !=="canceled"
		}
		
	})


	

	let scope=issuesExcluCanceled.length



	//if project is in progress the progress % is 
	//(completed estimate points + (0.25 * in progress estimate points)) / total estimate points

	const completedIssues=filter(issuesWithWorkflowState,function(iss){
		if(iss.workflow_state){
			return iss.workflow_state.type =="completed"
		}
	})

	const inProgressIssues=filter(issuesWithWorkflowState,function(iss){
		if(iss.workflow_state){
			return iss.workflow_state.type =="started"
		}
	})

	let progressPercentage=0
	if(scope){
		if(projectInProgress){ //if in progres add inprogress issues
			progressPercentage=100*(completedIssues.length + (0.25*inProgressIssues.length))/scope
		}else{ //otherwise is only completed issues
			progressPercentage=100*(completedIssues.length)/scope
		}
	}

	


	return{
		scope:scope,
		progressPercentage:progressPercentage

	}

}
