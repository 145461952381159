import React from 'react'
import DocFigmaEmbedEffectsToolbar from './DocFigmaEmbedEffectsToolbar'


class DocFigmaEmbedEffectsToolbarContainer extends React.Component{    
  
	render(){ 
		
		const {showEffectsToolbar} = this.props

		return (			
			<div className={'doc-figma-effectsToolbarContainer ' + (showEffectsToolbar ? ' doc-figma-effectsToolbarContainer--visible ' : ' doc-figma-effectsToolbarContainer--hidden ')}>				
				{showEffectsToolbar && 
					<DocFigmaEmbedEffectsToolbar 
					
						{...this.props}
					/>	
				}				
  		</div>			
						
		)
	}
}

export default DocFigmaEmbedEffectsToolbarContainer