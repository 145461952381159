import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import {getUserForId} from '../../../utils/getUserForId'
import { Transition, Spring, animated, config } from 'react-spring'
import findIndex from 'lodash/findIndex'
//suppress annoying warning
//https://stackoverflow.com/questions/49582004/rendering-contenteditable-component-without-getting-contenteditablewarning


class ToDoItemAssigneeAvatar extends React.Component {
	constructor() {
		super()
		this.onMouseClick=this.onMouseClick.bind(this)
		this.state = {
			show: true,
		}
	}

	onMouseClick(){
		if(!this.props.showManageAssignmentPopover){
			this.props.openPopover()
		}
	}

	render() {
		const {showAvatar,hasBeenRemoved,isInitialLoad,assignee,preRemovedIndex,assigneeCount,index,isChecked,mouseOver,showManageAssignmentPopover} = this.props
		
		let reverseIndex = 1
		if(showAvatar){
			reverseIndex = assigneeCount - index
		}
		if(this.props.hasBeenRemoved){
			reverseIndex = assigneeCount - this.props.preRemovedIndex + 1
		}
			 
		const avatarXOffset = -14
		const avatarXOffsetMouseOver = -24
		let avatarTranslate = (reverseIndex * avatarXOffset) - avatarXOffset - 2  // stacked on top of eachother     
		if(mouseOver || showManageAssignmentPopover) {
			avatarTranslate = (reverseIndex * avatarXOffsetMouseOver) - avatarXOffsetMouseOver - 2
		}

		const avatarTranslateBounded = Math.min(avatarTranslate, 5)
		const avatarTranslateStyle = `translateX(${avatarTranslateBounded}px)`
		let config = {
			tension: 180, 
			friction: 18 
		}

		let bounceConfig = {
			tension: 180, 
			friction: 14
		}
		if(isInitialLoad){
			config = { duration: 0.000001	}
			bounceConfig = { duration: 0.000001	}			
		}
		
		return (
			<Transition
				items={showAvatar}
				from={{ transform: `${avatarTranslateStyle}` }}
				enter={{ transform: `${avatarTranslateStyle}` }}
				update={{ transform: `${avatarTranslateStyle}` }}
				leave={{ transform: ` ${avatarTranslateStyle}` }}
				config={config}  
				// config={config.molasses}
				//onRest={this.onRest}
			 >
				{(styles, item) =>
					item && 
					 <animated.div onClick={this.onMouseClick} style={styles} className={'todoItem-assigned-user ' + (isInitialLoad ? ` todoItem-assigned-user--initialLoad ` : '') + (index ? `todoItem-assigned-user--index--${index} ` : '')  + (showAvatar ? ' todoItem-assigned-user--showAvatar ' : ' todoItem-assigned-user--hideAvatar ')}>							
							<Spring
								from={{
									opacity: 0,
									transform: 'scale(0)',
								}}
								to={{
									opacity: showAvatar ? 1 : 0,
									transform: showAvatar ? 'scale(1)' : 'scale(0)'
								}}
								//opacity={show ? 1 : 0}
								//transform=
								config={bounceConfig}
							>
								{scaleStyles => 									
									<animated.div style={scaleStyles} className='todoItem-assigned-user-avatarContainer'>
										<AvatarImage 
											avatarUrl={assignee.avatar}
											className='todoItem-assigned-user-avatar'					
											smallAvatar
										/>
										<div className='todoItem-assigned-user-tooltip tooltip'>
											{assignee.display_name}
										</div>
									</animated.div>
								}
							</Spring>	
					</animated.div>
				}
			</Transition>	 
		)
	}
}

class ToDoItemAssigneeList extends React.Component{  

	constructor(props){
		super(props)
		this.checkIfUserIsRemoved=this.checkIfUserIsRemoved.bind(this)
		this.state={
			prevAssignees:null,
			assignees:props.assignees,
			isInitialLoad:true
		}
		this.timer=null
	}

	componentDidMount(){	  	
		this.timer=setTimeout(() => {
			this.setState({isInitialLoad:false})
		}, 50);    
	}

	componentDidUpdate(prevProps) {
		if(prevProps.assignees !== this.props.assignees){
			this.setState({prevAssignees:prevProps.assignees,assignees:this.props.assignees})
		}
	}

	componentWillUnmount(){
		if(this.timer){
			clearTimeout(this.timer)
		}
	}

	checkIfUserIsRemoved(userId){
		let hasBeenRemoved=false
		const oldIndex=findIndex(this.state.prevAssignees,{user_id:userId})
		const newIndex=findIndex(this.state.assignees,{user_id:userId})
		let preRemovedIndex=oldIndex
		if(oldIndex !==-1 && newIndex==-1){
			hasBeenRemoved=true
		}else{
			preRemovedIndex=null
		}
		return {hasBeenRemoved:hasBeenRemoved,preRemovedIndex:preRemovedIndex}
	}	

	render(){ 
		const {isChecked, mouseOver,orgMembers}=this.props
		const {assignees,isInitialLoad}=this.state
		const showMinimal = isChecked && !mouseOver && !this.props.showManageAssignmentPopover
		return (	
			<Spring			
				to={{
					opacity: showMinimal ? 0.5 : 1,
					transform: showMinimal ? 'scale(0.8)' : 'scale(1)'
				}}
				config={{
					tension: 180, 
					friction: 12 
				}}
			>
			{containerStyles => 
				<animated.div style={containerStyles} contentEditable="false" suppressContentEditableWarning={true} className='todoItem-assigned-assigneeList'>							
					{orgMembers && orgMembers.map((member,i)=>{
						const assigneeIndex=findIndex(assignees,{user_id:member.user_id})
						const removalStatus=this.checkIfUserIsRemoved(member.user_id)
						const {hasBeenRemoved,preRemovedIndex}=removalStatus
						return(
							<ToDoItemAssigneeAvatar 
								key={i}
								index={assigneeIndex}
								assigneeCount={assignees.length}
								assignee={member}
								showAvatar={assigneeIndex>-1}									
								hasBeenRemoved={hasBeenRemoved}
								preRemovedIndex={hasBeenRemoved?preRemovedIndex:null}
								mouseOver={this.props.mouseOver}									
								showManageAssignmentPopover={this.props.showManageAssignmentPopover}
								isInitialLoad={isInitialLoad}									
								openPopover={this.props.openPopover}
							/>
						)
					})}
			</animated.div>
			}
		</Spring>	 
		)			
	}
}

export default ToDoItemAssigneeList