function getAttrs(dom) {
  return {
    embedId: dom.getAttribute('data-embed-id'),
  }
}

const DocWhiteboardEmbedNodeSpec ={
    attrs: {
      embedId:{default:''},//created on client
      whiteboardEmbedVersion:{default:0},
      messageVersion:{default:0} //to bump version and force node to check for new messages
    },
    group: "block",
    // toDOM(node) {
    //   return ['docWhiteboardEmbed']},
    // parseDOM: [{tag: 'docWhiteboardEmbed'}],

    parseDOM: [
    {tag: 'docWhiteboardEmbed', getAttrs},
   ],
    toDOM(node) {
      return ['docWhiteboardEmbed', {'data-embed-id':node.attrs.embedId}]
  }
}
    
  export default DocWhiteboardEmbedNodeSpec


