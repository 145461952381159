import {Plugin} from "prosemirror-state"
import {Decoration, DecorationSet} from "prosemirror-view"
import {updateTranscriptSearchResults} from '../../../../utils/meetings/transcriptSearch'

const SEARCH_RESULT_TEXT_CLASSNAME='doc-span--searchResult'
const SEARCH_RESULT_TEXT_ACTIVE_CLASSNAME='doc-span--searchResult--active'

const SEARCH_RESULT_NODE_CLASSNAME='ProseMirror-nodeSearchResult'
const SEARCH_RESULT_NODE_ACTIVE_CLASSNAME='ProseMirror-nodeSearchResult--active'

export class TranscriptSearchState {
  constructor(searchResults,activeSearchResultIndex,searchTerm,decos,searchActive) {
    this.searchResults = searchResults
    this.searchTerm=searchTerm
    this.activeSearchResultIndex=activeSearchResultIndex
    this.decos = decos
    this.searchActive=searchActive
  }

  apply(tr) { 
    let action = tr.getMeta('transcriptSearchPlugin')
    if(action){
      const results=[]
      let decos=[]
      if(action.activate ){
        let activeSearchResultIndex=action.activeIndex
        let decos=[] //no decos if no active selection
        if(action.searchTerm){
          const results=getResultsAndDecos(action.searchTerm,tr.doc,activeSearchResultIndex,tr)
          decos=results.decorations
          activeSearchResultIndex=results.activeIndex
        } 
        const results=decos
        updateTranscriptSearchResults(decos,activeSearchResultIndex)
        return new TranscriptSearchState(results,activeSearchResultIndex,action.searchTerm,DecorationSet.create(tr.doc, decos),true)
      }else if(action.deactivate ){
        let results=[]
        let activeSearchResultIndex=null
        let searchTerm=''
        let decos = []
        return new TranscriptSearchState(results,activeSearchResultIndex,DecorationSet.create(tr.doc, decos),false)
      }
      else return this
    }else if(this.searchActive && tr.docChanged){
      let activeSearchResultIndex=this.activeSearchResultIndex
      let decos=[] //no decos if no active selection
      if(this.searchTerm){
        const results=getResultsAndDecos(this.searchTerm,tr.doc,activeSearchResultIndex,tr)
        decos=results.decorations
        activeSearchResultIndex=results.activeIndex
      } 
      const results=decos
      updateTranscriptSearchResults(decos,activeSearchResultIndex)
      return new TranscriptSearchState(results,activeSearchResultIndex,this.searchTerm,DecorationSet.create(tr.doc, decos),true)
    }
   else return this
  }

  static init(config) {
    let results=[]
    let decos = []
    let searchTerm=''
    let searchActive=false
    const decoSet=DecorationSet.create(config.doc, decos)
    return new TranscriptSearchState(results,null,searchTerm,decoSet,searchActive)
  }
}



function getResultsAndDecos(searchTerm,doc,activeSearchResultIndex,tr){
  let decorations=[]
  let activePos
  let activeIndex=activeSearchResultIndex
  let isFirstAfterSelectectionFrom=false
  const selection=tr.selection
  const selectionFrom=selection.from
  //use active index if set otherwise select the first result after the selection
  if(searchTerm){
  const pattern=`(${searchTerm})`;
  const regex=new RegExp(pattern, 'ig')
  let searchResultIndex=-1
  doc.descendants((node, pos) => {
    if(node.isText){
      let m
      while (m = regex.exec(node.text)){
        const from=pos + m.index
        const to=pos + m.index + m[0].length
        searchResultIndex+=1
        let decoration
        if(activeSearchResultIndex || activeSearchResultIndex==0){
          if(searchResultIndex==activeSearchResultIndex){
            activePos=from
            decoration= Decoration.inline(from, to, {class: SEARCH_RESULT_TEXT_ACTIVE_CLASSNAME,id:'search-result-active'}) 
          }else{
            decoration= Decoration.inline(from, to, {class: SEARCH_RESULT_TEXT_CLASSNAME}) 
          }
        }else{
          if(from<selectionFrom){
            activeIndex=searchResultIndex
          }else if(!isFirstAfterSelectectionFrom){
            activeIndex=searchResultIndex
            isFirstAfterSelectectionFrom=true
          }
          decoration= Decoration.inline(from, to, {class: SEARCH_RESULT_TEXT_CLASSNAME}) 
        }
        decorations.push(decoration)
      }
    }
  })
}
  let modifiedDecorations=[]
  decorations.forEach((deco,i)=>{
    if(i!==activeIndex){
      modifiedDecorations.push(deco)
    }else{
      const classname=deco.type.attrs.class
      let modDecoration
      if(classname==SEARCH_RESULT_TEXT_CLASSNAME || classname==SEARCH_RESULT_TEXT_ACTIVE_CLASSNAME){
        modDecoration= Decoration.inline(deco.from, deco.to, {class: SEARCH_RESULT_TEXT_ACTIVE_CLASSNAME,id:'search-result-active'}) 
      }
      else if(classname==SEARCH_RESULT_NODE_CLASSNAME || classname==SEARCH_RESULT_NODE_ACTIVE_CLASSNAME){
        modDecoration= Decoration.inline(deco.from, deco.to, {class: SEARCH_RESULT_NODE_ACTIVE_CLASSNAME,id:'search-result-active'}) 
      }
      
      modifiedDecorations.push(modDecoration)
    }
  })
  return {
    decorations:modifiedDecorations,
    activeIndex:activeIndex
  }
}


