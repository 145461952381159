//check if in Do Not Disturb mode- if true don't show
import {doNotDisturbIsActive} from './doNotDisturbIsActive'

export function showNewMessageNotification(args) {	
	// console.log('show new message notification!!!!-----')
	// console.log(args)
//	console.log('show new message notifictation------')
	const isDoNotDisturb=doNotDisturbIsActive()
	if(isDoNotDisturb){

	}
	else{
		if(window.isElectron){
		//	console.log('SHOW NEW MESSAGE NOTIFICATION---------')
			window.ipcRenderer.send('show-new-message-notification',args)
		}
	}
}
