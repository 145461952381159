import React from 'react'
import ReactDOM from 'react-dom'
import ToDoListCheckboxReactComponent from './ToDoListCheckboxReactComponent'
import ToDoItemAssigneesReactComponent from './ToDoItemAssigneesReactComponent'
import {addAssigneeToToDo,removeAssigneeFromToDo} from '../../../prosemirror/utils/toDos/addAssigneeToToDo'
import { createRoot } from "react-dom/client";

const CHECKED_CLASSNAME='doc-toDoItem--state--checked'
const UNCHECKED_CLASSNAME='doc-toDoItem--state--unchecked'

class ToDoItemNodeView {

	constructor(node, view, getPos) {
		this.ignoreMutation=this.ignoreMutation.bind(this)
		this.toggleIsChecked=this.toggleIsChecked.bind(this)
		this.updateAssignees=this.updateAssignees.bind(this)
		this.renderReactComponents=this.renderReactComponents.bind(this)
		this.getPos=getPos

		const {isChecked,indentLevel,assignees}=node.attrs

		this.dom = document.createElement("div")
		this.dom.id='test'
		this.dom.classList.add('doc-toDoItem')


		this.checkBoxDom=document.createElement("div");
		this.checkBoxDom.classList.add('doc-toDoItem-UIContainer')
		this.checkBoxDom.contentEditable = 'false'
		this.dom.appendChild(this.checkBoxDom)
	
		this.checkboxRoot = createRoot(this.checkBoxDom);

		//Assignees
		this.assigneeDom=document.createElement("div");
		this.assigneeDom.contentEditable = 'false'
		this.assigneeDom.classList.add('doc-toDoItem-assignedContainer')	
		this.dom.appendChild(this.assigneeDom)
		this.assigneeRoot = createRoot(this.assigneeDom);
		
		this.contentDOM = document.createElement("div");
		this.contentDOM.classList.add('doc-toDoItem-contents')
		this.dom.appendChild(this.contentDOM)
		this.node=node
		this.renderReactComponents()
	}


	renderReactComponents(){
		const {isChecked,indentLevel,assignees,usersVersion}=this.node.attrs
		this.dom.setAttribute('data-is-checked',isChecked)
		this.dom.setAttribute('data-indent-level',indentLevel)
		this.dom.setAttribute('data-assignees',JSON.stringify(assignees))

		const textContent=this.node.textContent
		if (textContent.length == 0 && this.node.nodeSize==4){ //nodesize thing prevents placeholder showing if e.g. have only a link in todo
			this.dom.classList.add("doc-toDoItem--empty")
		}else{
			this.dom.classList.remove("doc-toDoItem--empty")
		}
		if(isChecked){
			this.dom.classList.add(CHECKED_CLASSNAME)
			this.dom.classList.remove(UNCHECKED_CLASSNAME)
		}else{
			this.dom.classList.remove(CHECKED_CLASSNAME)
			this.dom.classList.add(UNCHECKED_CLASSNAME)
		}
		if(assignees.length > 0){
			this.assigneeDom.classList.add('doc-toDoItem-assignedContainer--hasAssignees')
		}else{
			this.assigneeDom.classList.remove('doc-toDoItem-assignedContainer--hasAssignees')
		}
		
		 this.checkboxRoot.render(
			<ToDoListCheckboxReactComponent
				isCheckedProp={isChecked}
				toggleIsChecked={this.toggleIsChecked}
			/>)

		 this.assigneeRoot.render(
				<ToDoItemAssigneesReactComponent
					updateAssignees={this.updateAssignees}
					assignees={assignees}
					isCheckedProp={isChecked}
					usersVersion={usersVersion}/>)


	}


	updateAssignees(user,isAssigned){
		const node=this.getNode()
		if(isAssigned){
		 addAssigneeToToDo(user,node)
		}else{
			removeAssigneeFromToDo(user,node)
		}
	}


	toggleIsChecked(isChecked){
		if(window.view){
			const node=this.getNode()
			const nodePos=this.getPos(node)
			const attributes={...node.attrs,isChecked:isChecked}
			let tr = window.view.state.tr  
			tr.setNodeMarkup(nodePos, null, attributes)
			window.view.dispatch(tr)
		}
	}

	ignoreMutation(mutation){
		return !this.contentDOM.contains(mutation.target)
	}

	update(node,decorations){
		if (this.node.type !== node.type) {
			return false
		}
		const {isChecked,indentLevel,assignees,usersVersion}=node.attrs
		const oldIsChecked=this.node.attrs.isChecked
		const oldIndentLevel=this.node.attrs.indentLevel
		const oldAssignees=this.node.attrs.assignees 
		const oldUsersVersion=this.node.attrs.usersVersion
		const oldTextContent=this.node.textContent
		this.node = node;
		if(oldIsChecked != isChecked ||
			oldIndentLevel != indentLevel ||
			oldAssignees != assignees ||
			oldUsersVersion != usersVersion ||
			oldTextContent!= node.textContent
		){
			this.renderReactComponents()
		}
		
		return true;
	}


	getNode(){
		return this.node
	}

	destroy() {
		this.checkboxRoot.unmount();
		this.assigneeRoot.unmount();
	}

}

export default ToDoItemNodeView



// update(node,decorations){
// 		if (this.node.type !== node.type) {
// 			return false
// 		}
// 		const {isChecked,indentLevel,assignees,usersVersion}=node.attrs
// 		const oldIsChecked=this.node.attrs.isChecked
// 		const oldIndentLevel=this.node.attrs.indentLevel
// 		ReactDOM.render(
// 		 <ToDoListCheckboxReactComponent
// 				isCheckedProp={isChecked}
// 				toggleIsChecked={this.toggleIsChecked}
// 			/>, this.checkBoxDom)

// 		const textContent=node.textContent
// 		if(textContent.length == 0 && node.nodeSize==4){
// 			this.dom.classList.add("doc-toDoItem--empty")
// 		}else{
// 			this.dom.classList.remove("doc-toDoItem--empty")
// 		}
// 		if(isChecked){
// 			this.dom.classList.add(CHECKED_CLASSNAME)
// 			this.dom.classList.remove(UNCHECKED_CLASSNAME)
// 		}else{
// 			this.dom.classList.remove(CHECKED_CLASSNAME)
// 			this.dom.classList.add(UNCHECKED_CLASSNAME)
// 		}

// 		this.dom.setAttribute('data-indent-level',indentLevel)
// 		this.dom.setAttribute('data-is-checked',isChecked)
// 		this.dom.setAttribute('data-assignees',JSON.stringify(assignees))

// 		//Assignees
// 		this.assigneeDom.classList=''
// 		this.assigneeDom.classList.add('doc-toDoItem-assignedContainer')
// 		if(assignees.length > 0){
// 			this.assigneeDom.classList.add('doc-toDoItem-assignedContainer--hasAssignees')
// 		}

// 		ReactDOM.render(
// 			<ToDoItemAssigneesReactComponent
// 				updateAssignees={this.updateAssignees}
// 				assignees={assignees}
// 				isCheckedProp={isChecked}
// 			/>, this.assigneeDom)

// 		this.node = node;

// 		return true;
// 	}
