import React from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import debounce from 'lodash/debounce'
import DocImageReactionBar from '../../nodeViews/docMediaUIComponents/DocImageReactionBar'
import DocImageZoomedModalBtn from './DocImageZoomedModalBtn'
import DocImageZoomedModalImageCarousel from './DocImageZoomedModalImageCarousel'
import MediaModalCommentInput from './MediaModalCommentInput'
import * as Dialog from '@radix-ui/react-dialog';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {getAllImageNodes} from '../../../prosemirror/utils/getAllImageNodes'
import {hideDocMediaModal} from '../../../utils/showDocMediaModal'
import {updateImageTitle} from '../../../prosemirror/utils/updateImageTitle'
import {showToastNotification} from '../../../actions/toastNotification'
import store from '../../../store'
import {isPrivateDoc} from '../../../utils/isPrivateDoc'

const TITLE_DEBOUNCE_TIME=10
const HIDE_COMMENT_INPUT_TIME=2000


class DocImageZoomedModalMenuDropdown extends React.Component{  


	render(){ 

		const {unzoom,isThumbnail} = this.props
		
		return (
			<DropdownMenu.Root>
		    <DropdownMenu.Trigger className='doc-zoomedImageModal-header-menuBtn'>
					<Icon name='imageMenuBars' />								
		    </DropdownMenu.Trigger>
		    
		    <DropdownMenu.Content className='dropdownMenu dropdownMenu--commentReactionsMenu'>		    	
		    	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
		        Copy link
		       </DropdownMenu.Item> 	
		       <DropdownMenu.Separator />
		    	<DropdownMenu.Item onClick={this.props.convertThumbnailToImage} className='dropdownMenu-item--disabled'>
	        	Display as Thumbnail
	        </DropdownMenu.Item>	  
	          	        
	        {/*
	        	<DropdownMenu.Separator />  
	        	<DropdownMenu.Item onClick={this.props.deleteComment} className='dropdownMenu-item--warning'>
	        	Delete
	        </DropdownMenu.Item>	 	        
	        */}
		    </DropdownMenu.Content>
		    
		  </DropdownMenu.Root>			
		)
	}
} 




class DocImageZoomedModalContents extends React.Component{  
 

  constructor(props) {
    super(props)    
    this.sendMediaModalMessage=this.sendMediaModalMessage.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.changeActiveImage=this.changeActiveImage.bind(this)
    this.saveTitleChange=this.saveTitleChange.bind(this)
		this.saveTitleChange=debounce(this.saveTitleChange,TITLE_DEBOUNCE_TIME)

    const nodeId=props.nodeId
    const imageNodes=getAllImageNodes()
    let activeIndex
    let imageArray=[]
    imageNodes.forEach((node,i)=>{
    	const attrs=node.attrs
    	if(attrs.nodeId==nodeId){
    		activeIndex=i
    	}
    	imageArray.push(attrs)
    })

    let title=''
    if(imageArray[activeIndex] && imageArray[activeIndex].title ){
    	title=imageArray[activeIndex].title
    }

    this.state = {
    	value: title, 
    	imageArray:imageArray,
    	activeIndex:activeIndex,
    	showCommentInput: true,
    	messageValue:''
   	};
    this.timeout=null

  }

 componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
		if(this.timeout){
			clearTimeout(this.timeout)
		}
	}

	sendMediaModalMessage(messageText){
		const imageElement = document.getElementById("doc-zoomedImageModal-image")
			if(imageElement){
				imageElement.focus()				
			}
		const activeImage=this.state.imageArray[this.state.activeIndex]
		const nodeType='image'
		this.props.sendMediaModalMessage(activeImage.nodeId,nodeType,activeImage,messageText)
		
		this.props.unzoom()

		// for unzoom flow
		// this.setState({showCommentInput:false})
		// if(this.timeout){
		// 	clearTimeout(this.timeout)
		// }
		// this.timeout=setTimeout(() => {
   	// 	this.setState({showCommentInput: true,messageValue:''});
  	// }, HIDE_COMMENT_INPUT_TIME);    
      
		// // set showCommentInput to false for 5 seconds, then true		
		// //
		// const userName=null
		// const actionType="sentMediaModalComment"				
		// store.dispatch(showToastNotification(actionType,userName))
	}


	changeActiveImage(newActiveIndex){
		const activeImage=this.state.imageArray[this.state.activeIndex]
		//save any unchanged changes 
		updateImageTitle(activeImage.nodeId,this.state.value)
		const newActiveImage=this.state.imageArray[newActiveIndex]
		let title=''
		if(newActiveImage && newActiveImage.title){
			title=newActiveImage.title
		}
		this.setState({activeIndex:newActiveIndex,value:title,showCommentInput:true,messageValue:''})
	}

	handleKeyDown(e){		
		if(e.keyCode==39){ //right arrow
			const {imageArray,activeIndex}=this.state
			const newActiveIndex=Math.min(activeIndex+1,imageArray.length-1)
			this.changeActiveImage(newActiveIndex)
		}
		else if(e.keyCode==37){ //left arrow
			const {imageArray,activeIndex}=this.state
			const newActiveIndex=Math.max(activeIndex-1,0)
			this.changeActiveImage(newActiveIndex)
		}else if(e.keyCode==27){//escape
			this.props.unzoom()
		}
	}

	saveTitleChange(nodeId,value){
		updateImageTitle(nodeId,value)
	}

	handleInputKeyDown(e){
		if(e.keyCode==13){
			e.preventDefault()
			e.stopPropagation()
			// I think radix forces focus on something in the modal.
			// So the solution was to make something else focusable by adding tab index and then focusing that
			// ¯\_(ツ)_/¯
			const imageElement = document.getElementById("doc-zoomedImageModal-image")
			if(imageElement){
				imageElement.focus()				
			}
		}
		if(e.keyCode==39||e.keyCode==37){ //right arrow
			e.preventDefault()
			e.stopPropagation()
		}
		
	}

  handleChange(event) {
    const {imageArray,activeIndex}=this.state
    let activeImage=imageArray[activeIndex]
    let newImageArray=[
			...imageArray.slice(0,activeIndex),
			{...activeImage,title:event.target.value},
			...imageArray.slice(activeIndex + 1)
    ]
    this.setState({value: event.target.value,imageArray:newImageArray})
    this.saveTitleChange(activeImage.nodeId,event.target.value)
  }

	render(){ 
		const {imageArray,activeIndex,showCommentInput}=this.state
		const {isThumbnail,isPrivateDoc}=this.props
		const activeImage=imageArray[activeIndex]

		const thumbnailImage = false



		return (
			<React.Fragment>										
				<div className='doc-zoomedImageModal-header'>
					<div className='doc-zoomedImageModal-header-leftContainer'>
						<DocImageZoomedModalMenuDropdown
							convertThumbnailToImage={this.props.convertThumbnailToImage}
						 />
						<input 
							id={'image-title-input'}
  						className='doc-zoomedImageModal-header-imageTitleInput'
  						placeholder='Add a title...'
  						value={this.state.value}
  						onKeyDown={this.handleInputKeyDown}
							onChange={this.handleChange} 				
							autoComplete="off"
							autoCorrect="off"
							spellCheck={false}						
  					/>
					</div>
					<div className='doc-zoomedImageModal-header-centerContainer'>
						

						{/*}
						<DocImageZoomedModalBtn
							iconName='drawingArrow'
							tooltipLabel='Arrow'
						/>
						<DocImageZoomedModalBtn
							iconName='drawingSquare'
							tooltipLabel='Square'
						/>
						<DocImageZoomedModalBtn
							iconName='drawingText'
							tooltipLabel='Text'
						/>
						<DocImageZoomedModalBtn
							iconName='drawingStickyAlt'
							tooltipLabel='Sticky Note'
						/>
						<DocImageZoomedModalBtn
							iconName='plusRegular'
							tooltipLabel='Add...'
						/>			
						*/}			


					</div>
					<div className='doc-zoomedImageModal-header-rightContainer'>
						{/*}
						<DocImageZoomedModalBtn
							iconName='imageCrop'
							tooltipLabel='Crop'
						/>
						<DocImageZoomedModalBtn
							iconName='imageSettings'							
							tooltipLabel='Adjust Color'
						/>
						*/}
						
												{/*}
						{!thumbnailImage &&
							<DocImageZoomedModalBtn
								iconName='unZoomMedia'									
								//onClickFunction= convert into thumbnail
								tooltipLabel='Display as mention'			
							/>
						}
						{thumbnailImage &&
							<DocImageZoomedModalBtn
								iconName='expandVideo'									
								//onClickFunction= convert into image
								tooltipLabel='Display as image'			
							/>
						}
						*/}
						
						
						<div onClick={this.props.unzoom} className='doc-zoomedImageModal-header-closeBtnOuterContainer'>
							<DocImageZoomedModalBtn
								iconName='cross'								
							/>
						</div>
					</div>

				</div>
				<div className='doc-zoomedImageModal-imageContainer'>
					<img 
						onClick={this.props.unzoom} // just trying after a while feels right
						onDoubleClick={this.props.unzoom}
						alt=''
						draggable="false"
						src={activeImage.deliveryUrl}
						className='doc-zoomedImageModal-image'
						id='doc-zoomedImageModal-image'
						tabIndex={0}
					/>
				</div>
				<div className='doc-zoomedImageModal-footer'>
					<div className='doc-zoomedImageModal-footer-leftContainer'>
						{/*}
						<DocImageZoomedModalBtn
							iconName='broadcast'
							label='Ping'							
						/>
						*/}
					</div>
					<div className='doc-zoomedImageModal-footer-centerContainer'>
						{!isPrivateDoc &&
							<div className={'doc-zoomedImageModal-footer-commentInputContainer ' + (showCommentInput ? ' doc-zoomedImageModal-footer-commentInputContainer--show ' : ' doc-zoomedImageModal-footer-commentInputContainer--hide ')}>						
								<MediaModalCommentInput 
									sendMediaModalMessage={this.sendMediaModalMessage}
									unzoom={this.props.unzoom}
									messageValue={this.state.messageValue}
									onMessageValueUpdate={(value)=>{this.setState({messageValue:value})}}
								/>
							</div>
						}
						{/*}
						{!showCommentInput &&
							<DocImageZoomedModalBtn
								iconName='messageOutlineMedium'								
								label='Comment...'
								tooltipAbove
								onClickFunction={()=>{this.setState({showCommentInput: true})}}
							/>
						}
						*/}

						{/*
						{imageArray.length > 1 &&
							<DocImageZoomedModalImageCarousel 
								imageArray={imageArray}
								activeIndex={activeIndex}
								setActiveIndex={(i)=>{this.setState({activeIndex:i})}}
							/>
						}
						*/}

					</div>


					<div className='doc-zoomedImageModal-footer-rightContainer'>
					{/*}
						ADD SOON
						<DocImageZoomedModalBtn
							iconName='link'	
							tooltipLabel="Copy Link"
							tooltipAbove
							// tooltipLongDelay
						/>
						<DocImageZoomedModalBtn
							iconName='slackIcon'							
							tooltipLabel="Send via Slack..."
							tooltipAbove

						/>
					*/}						
					</div>
					

				</div>
						

						{/*}
						<div onClick={this.props.unzoom} className='doc-zoomedImageModal-image'>							
													
						</div>
						*/}

						{/*}
						<div className='doc-zoomedImageModal-header'>
							{this.props.imageTitle &&
								<div className='doc-zoomedImageModal-header-title'>								
									<div className='doc-zoomedImageModal-header-title-title'>
										{this.props.imageTitle}
									</div>
									<div className='doc-zoomedImageModal-header-title-commentCount'>
										14 comments
									</div>
								</div>
							}
							<DocImageReactionBar />

						</div>
						

						{isThumbnail &&
							<button onClick={this.props.convertThumbnailToImage}>convert to image</button>
						}
						*/}

						{/* 
						<div className='doc-zoomedImageModal-commentsContainer'>
							COMMENTS GO IN HERE
						</div>
						 */}
										

			</React.Fragment>
		)
	}
}




class DocImageZoomedModal extends React.Component{  
 
  unzoomModal(){
  	hideDocMediaModal()
  }
 
	render(){ 
		let visible=false
		let nodeId
		if(this.props.docEditorMediaModals && this.props.docEditorMediaModals.zoomedImage.visible){
			visible=true
			nodeId=this.props.docEditorMediaModals.zoomedImage.nodeId
		}
		const {isThumbnail} = this.props

		const appContainer = document.getElementById('app')
		
		const pathname=this.props.location.pathname
		let docId
		let isPrivate=false
		const signatureComponentsArray=pathname.split(/-/)
		if(signatureComponentsArray.length>1){
			docId=signatureComponentsArray[signatureComponentsArray.length-1]
		}
		if(docId){
			isPrivate=isPrivateDoc(docId)
		} 

		return ( 
			<React.Fragment>
				<Dialog.Root open={visible}>
			    <Dialog.Portal container={appContainer}>
			      <Dialog.Overlay className='doc-zoomedImageModalContainer'>
				      <Dialog.Content 
				      	className='doc-zoomedImageModa'
				      	onEscapeKeyDown={this.unzoomModal}
				      	onPointerDownOutside={this.unzoomModal}
				      	onInteractOutside={this.unzoomModal}
				      	>
				      		<DocImageZoomedModalContents
				      			nodeId={nodeId}
				      			unzoom={this.unzoomModal}
				      			sendMediaModalMessage={this.props.sendMediaModalMessage}
				      			isPrivateDoc={isPrivate}
				      		/>
				      		
				      		{/*}
				      		<button onClick={unzoom} className='doc-zoomedImageModal-closeBtn'>
				      			<div className='doc-zoomedImageModal-closeBtn-inner'>										
											<Icon name='cross-small' />
										</div>
									</button>
								*/}
				      </Dialog.Content>
			      </Dialog.Overlay>
			    </Dialog.Portal>
			  </Dialog.Root>
			</React.Fragment>
			
		)
	}
}

const mapStateToProps = (state) => ({
	docEditorMediaModals: state.docEditorMediaModals
});


export default withRouter(connect(
	mapStateToProps,
	{	
	})(DocImageZoomedModal))