import React from 'react'
import { withRouter} from 'react-router-dom'
import DocTable from '../docTable/DocTable'
import Icon from '../misc/Icon'
import LibraryDocPreview from './LibraryDocPreview'
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import {getUserForId} from '../../utils/getUserForId'
import {getOrgMembers} from '../../utils/getOrgMembers'
import {createNewOrgDoc} from '../../utils/createNewDoc'

import sortBy from 'lodash/sortBy'
import MemberGridMember from './MemberGridMember'
import * as Tooltip from '@radix-ui/react-tooltip';



class LibraryPage extends React.Component{  

	constructor(){
		super()
		this.state={
			activeDocId:null,
		}
	}
		
	render(){	
		const {docs,organization,orgMembers}=this.props
		let sortedOrgMembers=[]
		if(orgMembers){									
			sortedOrgMembers= sortBy(orgMembers, function(user){
				return user.display_name.toString().toLowerCase()
			})				
		}		
		let orgName
		if(organization){
			orgName=organization.name
			if(orgName === 'Yarn'){
				orgName = 'Acme Inc.'				
			}
		}

		
		const hasOrgDocs = this.props.docs && this.props.docs.length > 0


	  return (
	  	<div className='allDocsPage'>
	  		<div className='allDocsPage-leftSpacer'/>

				<div className='allDocsPage-center'>	 
					<div className='allDocsPage-header'>	  			  		
		  			<div className='allDocsPage-header-title'>	
		  				{/*}
		  				<div className='allDocsPage-header-title-iconContainer'>	
								<Icon name='folderLight' />	
							</div>
							*/}
		  				<div className='allDocsPage-header-title-label'>	
		  					{orgName}
		  				</div>
		  			</div>

		  			<div className='allDocsPage-header-rightContainer'>	
		  				{/*}
		  				<button className='allDocsPage-header-btn allDocsPage-header-btn--dropdown'>
		  					<Icon name='ellipses' />
		  				</button>
		  				*/}
		  			</div>
		  		</div>		  		  	
		  		<div className='allDocsPage-memberGrid'>
						{sortedOrgMembers.map((user)=>{							
							return(																
								<MemberGridMember 
									key={user.user_id}
									user={user}					  				
								/>
							)
						})}
		  		</div>
		  		<div className='allDocsPage-content'>
		  			{hasOrgDocs && 
		  			<div className='allDocsPage-content-tableHeader'>
		  				<div className='allDocsPage-content-tableHeader-label'>
		  					{orgName} Docs
		  				</div>
		  				<div className='allDocsPage-docList-header-rightContainer'>
			  					<Tooltip.Provider>
										<Tooltip.Root delayDuration={300}>
											<Tooltip.Trigger asChild>
												<button className='roadmapPage-docList-header-newDocBtn' onClick={()=>{createNewOrgDoc(this.props.history)}}>
							  					<div className='roadmapPage-docList-header-newDocBtn-iconContainer'>
							  						<Icon name='plus' />
							  					</div>
							  					<div className='roadmapPage-docList-header-newDocBtn-label'>
							  						New Doc
							  					</div>
						  					</button>
											</Tooltip.Trigger>				
											<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
												<KeyboardShortcutTag
													shift
													command
													letter='N'
													keyboardShortcutOnly
												/>									
												</Tooltip.Content>													
										</Tooltip.Root>
									</Tooltip.Provider>	  
			  				</div>
		  				</div>
		  				}

		  			{hasOrgDocs && 
			  			<DocTable 
			  				docs={this.props.docs}
			  				libraryView='allDocs'
			  				setActiveDoc={(docId)=>{this.setState({activeDocId:docId})}}
			  			/>
		  			}

		  			{!hasOrgDocs && 
			  			<div className='roadmapPage-docList roadmapPage-docList--empty'>
			  				<div className='roadmapPage-docList--empty-box'>
			  					<div className='roadmapPage-docList--empty-box-emojiContainer'>
			  						🗂️
			  					</div>
			  					<div className='roadmapPage-docList--empty-box-title'>
			  						Add a team doc
			  					</div>
			  					<div className='roadmapPage-docList--empty-box-description'>
			  						Team docs aren't attached to any project or roadmap.
			  					</div>			  					
		  						<button className='roadmapPage-docList--empty-box-newDocBtn' onClick={()=>{createNewOrgDoc(this.props.history)}}>
				  					<div className='roadmapPage-docList--empty-box-newDocBtn-iconContainer'>
				  						<Icon name='plus' />
				  					</div>
				  					<div className='roadmapPage-docList--empty-box-newDocBtn-label'>
				  						New {orgName} Doc
				  					</div>
			  					</button>
			  				</div>
			  			</div>
			  		}
			  		
		  			
					</div>
				</div>


				<div className='allDocsPage-rightPanel'>
					
					<LibraryDocPreview
						docId={this.state.activeDocId}
					/>
					

								

				</div>
	  		
	  	</div>
	  )
	}
}

export default withRouter(LibraryPage)
