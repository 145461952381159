import React from 'react'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import {getProjectForId} from '../../../utils/getProjectForId'

class MessagePanelFooterFollowBox extends React.Component{  
	

	render(){	
		const {projectId, docType} = this.props
		let project
		let projectName
		if(projectId){
			project = getProjectForId(projectId)
		}
		if(project){
			projectName = project.name
		}
		return (	  															
			<div className='messagePanel-footerFollowBoxContainer'>
				{docType !== 'projectRootDoc' &&
					<div className='messagePanel-footerFollowBox'>
						<div className='messagePanel-footerFollowBox-markerContainer'>
							📄
						</div>
						<div className='messagePanel-footerFollowBox-title'>
							Join {this.props.docTitle}?
						</div>
						<div className='messagePanel-footerFollowBox-description'>					
							<div className='messagePanel-footerFollowBox-description-para'>
								When you join the doc, you’ll be able to comment on the doc and you’ll be notified about new comments and updates to the doc.
							</div>						
						</div>	
						<div className='messagePanel-footerFollowBox-vSpacer' />
						<div className='messagePanel-footerFollowBox-joinBtnContainer'>
							<button className='messagePanel-footerFollowBox-joinBtn' onClick={this.props.followDoc}>
								Join Doc
							</button>
						</div>
					</div>
				}
				{docType === 'projectRootDoc' &&
					<div className='messagePanel-footerFollowBox'>
						<div className='messagePanel-footerFollowBox-markerContainer'>
							<LinearMarker projectId={projectId} />
						</div>
						<div className='messagePanel-footerFollowBox-title'>							
							Join {projectName}?
						</div>
						<div className='messagePanel-footerFollowBox-description'>					
							<div className='messagePanel-footerFollowBox-description-para'>
								When you join the project, you’ll be added to the project members and be notified about new comments and project updates.
							</div>							
						</div>
						<div className='messagePanel-footerFollowBox-vSpacer' />
						<div className='messagePanel-footerFollowBox-joinBtnContainer'>
							<button className='messagePanel-footerFollowBox-joinBtn' onClick={this.props.followDoc}>
								Join Project
							</button>
						</div>
					</div>
				}
			</div>
						
		)
	}
} 



export default MessagePanelFooterFollowBox
