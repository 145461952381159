//import Pusher from 'pusher-js'
import store from '../store'
import pusherInstance from '../pusherInstance'



export function docPresencePusherSetup(docId) {
  var context = { docId: docId }
	var presenceChannel = pusherInstance.subscribe(`presence-doc-${docId}`)
  presenceChannel.bind('pusher:subscription_succeeded', handlePresenceSubscriptionSucceeded,context)  
  presenceChannel.bind('pusher:member_removed', handlePresenceMemberRemoved,context)  
  presenceChannel.bind('pusher:member_added', handlePresenceMemberAdded,context)  
}
 
function handlePresenceSubscriptionSucceeded(data){
  const docId=this.docId
  const members=data.members
  store.dispatch({ type: 'PUSHER_PRESENCE_SUBSCRIBE_SUCCESS',docId,members})
}



function handlePresenceMemberRemoved(data){
  const docId=this.docId
  const sessionId=data.id
  store.dispatch({ type: 'PUSHER_PRESENCE_REMOVED',docId,sessionId})
}

function handlePresenceMemberAdded(data){
  const docId=this.docId
  const sessionId=data.id
  store.dispatch({ type: 'PUSHER_PRESENCE_ADDED',docId,sessionId,data}) 
}
