import React from 'react'
import applyDevTools from "prosemirror-dev-tools"
import {EditorState} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import {Schema} from "prosemirror-model"
import {history,undo,redo} from "prosemirror-history"
import {keymap} from "prosemirror-keymap"
import {baseKeymap} from "prosemirror-commands"
import {setBlockType, chainCommands, toggleMark} from "prosemirror-commands"
import { PluginKey } from "prosemirror-state";
import messageSchema from '../../../../prosemirror/messages/messageSchema'
import {DOMSerializer} from "prosemirror-model"
import throttle from 'lodash/throttle'
import {buildInputRules} from '../../../../prosemirror/messages/inputRules/messageInputRules'
import MessageInputBoxHintsBar from './MessageInputBoxHintsBar'
import { Selection } from 'prosemirror-state'
import MessageInputBoxBtnRow from './buttonRow/MessageInputBoxBtnRow'
import MessageInputBoxSelectionPreview from './MessageInputBoxSelectionPreview'
import codemark from 'prosemirror-codemark';
import 'prosemirror-codemark/dist/codemark.css';
import MessageInputBoxPlaceholder from './MessageInputBoxPlaceholder'
import {messageMentionsPlugin} from '../../../../prosemirror/messages/messagePlugins/mentions/messageMentionsPlugin'
import {insertMessageEmojiPlugin} from '../../../../prosemirror/messages/messagePlugins/emoji/insertMessageEmojiPlugin'
import MessageLinearIssueMentionNodeView from './nodeViews/MessageLinearIssueMentionNodeView'
import MessageLinearProjectMentionNodeView from './nodeViews/MessageLinearProjectMentionNodeView'
import MessageInternalLinkNodeView from './nodeViews/MessageInternalLinkNodeView'


const pluginKey=new PluginKey("messageMentionPlugin")
const messageEmojiPluginKey=new PluginKey("insertMessageEmojiPlugin")
const codeMarkPlugins=codemark({ markType: messageSchema.marks.code }); //https://github.com/curvenote/prosemirror-codemark

////TODO resizing might not work with 
function createMarkup(input) {
  return {__html: input}
}

function cleanDoc(){ //remove empty P at the end of doc
	const state=window.messageInputView.state
	let tr=state.tr
	state.doc.descendants((node, pos) => {
		if(node === state.doc.lastChild ){
			if(node.type.name=='paragraph' && node.nodeSize==2){
				tr.delete(pos,pos+node.nodeSize)
			}
		}
	})
	window.messageInputView.dispatch(tr)
}

function getMentions(){ //Get user mentions to add user to doc
	let userMentions=[]
	const state=window.messageInputView.state
	let tr=state.tr
	state.doc.descendants((node, pos) => {
		if(node.type.name=='userMention' ){
			const userId=node.attrs.userId
			userMentions.push(userId)
		}
	})
	return userMentions
}

function convertNodeToHTML(node){
	let fragment = DOMSerializer.fromSchema(messageSchema).serializeFragment(node)
	let tmp = document.createElement("div")
	tmp.appendChild(fragment)
	let html=tmp.innerHTML
	return html
}		

class MessageInputBox extends React.Component{  

	constructor(props){
		super(props)
		this.loadView=this.loadView.bind(this)
		this.sendDocMessage=this.sendDocMessage.bind(this)
		this.resetInput=this.resetInput.bind(this)
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.onKeyUp=this.onKeyUp.bind(this)
		this.insertGif=this.insertGif.bind(this)
		this.clickAddMedia=this.clickAddMedia.bind(this)
		this.clickAddVideo=this.clickAddVideo.bind(this)
		this.handlePaste=this.handlePaste.bind(this)
		this.insertImage=this.insertImage.bind(this)
		this.handleSelectFile=this.handleSelectFile.bind(this)
		this.handleFocusEvent=this.handleFocusEvent.bind(this)
		this.handleBlurEvent=this.handleBlurEvent.bind(this)
		this.onKeyUp=throttle(this.onKeyUp,200)
		this.state = {
			inputIsFocused:false,
			messageHTML:null
		}
	} 

	componentDidMount() {	
		this.loadView()
	}

	componentDidUpdate(prevProps){
		if(this.props.messageTextSnapshot && this.props.messageTextSnapshot!=prevProps.messageTextSnapshot){
			this.view.focus()
		}
		if(this.props.threadParentMessage !== prevProps.threadParentMessage){
			this.view.focus()
		}
		if(this.props.newMessageParentMessage && !prevProps.newMessageParentMessage){
			this.view.focus()
		}
		if(this.props.newMessageParentNodeSnapshot && !prevProps.newMessageParentNodeSnapshot){
			this.view.focus()
		}
		if(this.props.activeConversation !== prevProps.activeConversation){
			this.setState({messageHTML:null})
			this.loadView() //clear message when switch conversation
			this.view.focus()
		}	
	}

	componentWillUnmount(){
		if(this.view){
			this.view.destroy()
			this.view=null
			window.messageInputView=null
		}
	}


	makeDocJson(){
		const paragraphNode=messageSchema.nodes.paragraph.createAndFill({})
		const doc=messageSchema.nodes.doc.createAndFill({})
		return doc
	}

	loadView(){
		if(this.view){
			this.view.destroy()
		}
		const handleFocusEvent=this.handleFocusEvent
		const handleBlurEvent=this.handleBlurEvent
		this.view = new EditorView(document.querySelector("#message_input_box"), {
			attributes: {				
				spellCheck: false,
			},
			state: EditorState.create({
			doc: this.makeDocJson(),
			plugins:[
			...codeMarkPlugins,
				messageMentionsPlugin(pluginKey),
				insertMessageEmojiPlugin(messageEmojiPluginKey),
				history(),
				keymap({
				"Mod-z": undo,
				"Mod-y": redo,
				'Mod-b': toggleMark(messageSchema.marks.strong),
				'Mod-i': toggleMark(messageSchema.marks.em),
				'Mod-u': toggleMark(messageSchema.marks.underline),
				'Cmd-Shift-m': toggleMark(messageSchema.marks.light),
				'Shift-Mod-x': toggleMark(messageSchema.marks.strike),	
				'Shift-Enter':	baseKeymap.Enter,
				}),
				keymap(baseKeymap),
				buildInputRules(messageSchema)
			],	
		}),
		handleKeyDown:this.handleKeyDown,
		dispatchTransaction: transaction => { //for resizing inputbox -- maybe throttle?
			const { state, transactions } = this.view.state.applyTransaction(transaction)
			this.view.updateState(state)
			const doc=state.doc
			const html=convertNodeToHTML(doc)
			this.setState({messageHTML:html})
		},
		handlePaste:this.handlePaste,
		nodeViews: { //custom node views
			linearIssueMention(node, view, getPos) { return new MessageLinearIssueMentionNodeView(node, view, getPos) },
			linearProjectMention(node, view, getPos) { return new MessageLinearProjectMentionNodeView(node, view, getPos) },
			internalLink(node, view, getPos) { return new MessageInternalLinkNodeView(node, view, getPos) },
		},
		handleDOMEvents: { //we disable all of these events
			focus(view, event) { handleFocusEvent()},
			blur(view, event) { handleBlurEvent()},
		},
	})
		window.messageInputView=this.view 
	}

	handlePaste(view, event,slice) {
		var clipboardData = event.clipboardData
		var files = clipboardData.files
		if (!files || !files.length) {
			var html = event.clipboardData.getData('text/html')
			if(html){
				var regExp = '<img[^>]* src="([^"]*)"[^>]*>'
				var match = html.match(regExp)
				if(match && match[1]){ //handle google docs images
					const src=match[1]					
					let isThumbnail=false
					this.insertImage(src,isThumbnail)
					return true
				}
			}
		}
		if(files[0] && files[0].size !== 0){ //when copy paste from Mac PowerPoint clipboard data has an image file with size 0 (we want to ignore this)
			const text=event.clipboardData.getData('text')
			if(!text){ //for copy paste from windows PP (clipboard data has non zero image file) check if there if there is text before uploading
				let isThumbnail=false
				this.insertImage(files[0],isThumbnail)
				return true
			}
		}
		return false
	}

	handleFocusEvent(view,event){
		this.props.updateRecentActivity()
		this.setState({inputIsFocused:true})
	}

	handleBlurEvent(view,event){
		this.setState({inputIsFocused:false})
	}

	resetInput(){
		this.loadView()
		this.view.focus()
	}

	handleKeyDown(view,e){		
		if(e.keyCode===13 && !e.shiftKey){ //update to use enter to submit
			let isEmpty=false
			if(view.state && view.state.doc.nodeSize==4){ //TODO this hardcoded node size is a bit hacky
				isEmpty=true
			}
			if(!isEmpty){
				const mentionMenuState=pluginKey.getState(view.state)
				const emojiMenuState=messageEmojiPluginKey.getState(view.state)

				if(!mentionMenuState.active && !emojiMenuState.active){ 
				//if(!mentionMenuState.active){ //don't send if menu is open	
					this.sendDocMessage()
					return true
				}else{
					return false
				}
			}
		}else if(e.keyCode==9){ //tab key
			return true
		}else if(e.keyCode==27){
			let isEmpty=false
			if(view.state && view.state.doc.nodeSize==4){ //TODO this hardcoded node size is a bit hacky
				isEmpty=true
			}

			if(isEmpty){
				this.props.backToMainThread()
			}
		}
	}

	onKeyUp(e){
		this.props.sendUserTypingEvent(this.props.docId,this.props.userId)
	}

	sendDocMessage(){
		cleanDoc()//if last P in doc is empty then remove it
		const state=window.messageInputView.state
		const doc=state.doc
		const plainText=doc.textContent
		const html=convertNodeToHTML(doc)
		const source=doc
		let messageContent={}
		messageContent.type='richText'
		messageContent['plain_text']=plainText
		messageContent.source=source
		messageContent.html=html
		let mentions=getMentions()
		messageContent.mentions=mentions
		//Parse doc for mention nodes
		this.props.sendDocMessage(messageContent,this.props.threadParentMessage)
		this.resetInput()
	}

	insertImage(file,isThumbnail){
		return this.props.uploadImageToCloudinary(file).then((response)=>{
			let messageContent={}
			messageContent.type='image'
			messageContent['media_url']=response.url
			this.props.sendDocMessage(messageContent,this.props.threadParentMessage)
			this.resetInput()
		 })
	}
	insertGif(url){
		let messageContent={}
		messageContent.type='gif'
		messageContent['media_url']=url
		this.props.sendDocMessage(messageContent)
		this.resetInput()
	}

	clickAddVideo(){
		let messageContent={}
		messageContent.type='video'
		messageContent['media_url']=videoUrl
		this.props.sendDocMessage(messageContent)
		this.resetInput()
	}
	
	clickAddMedia(mediaType){
		let messageContent={}
		messageContent.type=mediaType
		this.props.sendDocMessage(messageContent)
		this.resetInput()
	}

	handleSelectFile(e){
		const fileList = e.target.files
		const file = fileList[0]
		const src=null
		const thumbnail=null
		let fileName=file.name
		const extensions=['.png','.jpg','.jpeg','.tif','.tiff','.webp','.gif']
		this.insertImage(file)
	}

	render(){	
		const {messageHTML}=this.state
		const {userTyping,userTypingConversation,activeConversation,newMessageParentMessage,messageTextSnapshot}=this.props
		const {newMessageParentNodeType,newMessageParentNodeSnapshot}=this.props
		const typingInCurrentConversation=userTypingConversation==activeConversation

		let selectionPreviewType
		let clearMessageSelection
		let hasDocSelection=false
		if(messageTextSnapshot){
			selectionPreviewType='docSelection'
			clearMessageSelection=this.props.clearMessageSelection
			hasDocSelection=true
		}else if(newMessageParentMessage){
			selectionPreviewType = 'message'	
			clearMessageSelection = this.props.deleteParentMessage
		}else if(newMessageParentNodeType=='video'){
			selectionPreviewType='video'
			clearMessageSelection=this.props.clearMessageSelection
		}else if(newMessageParentNodeType=='image'){
			selectionPreviewType='image'
			clearMessageSelection=this.props.clearMessageSelection
		}else if(newMessageParentNodeType=='figmaEmbed'){
			selectionPreviewType='figmaEmbed'
			clearMessageSelection=this.props.clearMessageSelection
		}
		else if(newMessageParentNodeType=='table'){
			selectionPreviewType='table'
			clearMessageSelection=this.props.clearMessageSelection
		}
	


		//
		// Placeholder

		// IF it has doc selection then we need to boost the min-height of the input box, like we do with other previews
		// however, unlike those previews we don't know doc selection height before it exists
		// so we have a second div just for the preview, measure it's height, then change the min-height on the input box like we do with other preview types		
		// I dunno how to refactor this really, like where to put it


		// Measure the Doc Selection
		let heightGhostSelectionMeasurementDiv // height of the ghost measurement div
		if(hasDocSelection){
			let ghostSelectionMeasurementDiv = document.getElementById('message_input_box_ghostSelectionMeasurement')
			if(ghostSelectionMeasurementDiv){
				heightGhostSelectionMeasurementDiv = ghostSelectionMeasurementDiv.getBoundingClientRect().height				
			}
		}

		// Make Min Height Taller
		const defaultMinHeightInputBox = 190 // this is bad. To do, update to CSS var and pull that directly
		let docSelectionInputMinHeight
		let docSelectionInputMinHeightStyle = {}		
		if(heightGhostSelectionMeasurementDiv){
			docSelectionInputMinHeight = defaultMinHeightInputBox + heightGhostSelectionMeasurementDiv			
			docSelectionInputMinHeightStyle = {
				height: `${docSelectionInputMinHeight}px`,			
			}						
		}

		// Add Padding to Input Box
		let docSelectionPaddingLines
		if(heightGhostSelectionMeasurementDiv){

			if(heightGhostSelectionMeasurementDiv < 30){
				docSelectionPaddingLines = 1
			}		
			if(heightGhostSelectionMeasurementDiv > 30 && heightGhostSelectionMeasurementDiv < 50){
				docSelectionPaddingLines = 2
			}		
			if(heightGhostSelectionMeasurementDiv > 50){
				docSelectionPaddingLines = 3
			}		
			//docSelectionPaddingLines = 1 if 20
			//docSelectionPaddingLines = 2 if 44
			//docSelectionPaddingLines = 3 if around 64
		}

		//
		// Placeholder

		let showPlaceholder = false
		if(this.view && this.view.state){
			const nodeSize=this.view.state.doc.nodeSize //use nodesize instead of text content so placeholder doesnt show when have mention
			if(nodeSize==4){ 
				showPlaceholder=true
			}
		}

		
		// if(this.view && this.view.state && (!this.view.state.doc.textContent || this.view.state.doc.textContent=='\u200b')){
		// 	showPlaceholder=true
		// }
		
		let placeholderType = 'defaultChannel'

	//	console.log(`selection preview type is----- ${selectionPreviewType}`)
		
		return (	  	
			<React.Fragment>
				<div style={docSelectionInputMinHeightStyle} onKeyDown={this.onKeyDown} onKeyUp={this.onKeyUp} className={'docEditor-messagePanel-inputBox ' + (selectionPreviewType ? ` docEditor-messagePanel-inputBox--selectionPreviewType--${selectionPreviewType} ` : '') + (docSelectionPaddingLines ? ` docEditor-messagePanel-inputBox--docSelectionTopPaddingLines--${docSelectionPaddingLines} ` : '')}>				
					<div className='docEditor-messagePanel-inputBox-inputContainer'>					
						{selectionPreviewType &&
							<MessageInputBoxSelectionPreview 
								selectionPreviewType={selectionPreviewType}
								messageTextSnapshot={messageTextSnapshot}
								newMessageParentMessage={newMessageParentMessage}
								clearMessageSelection={clearMessageSelection}
								newMessageParentNodeSnapshot={newMessageParentNodeSnapshot}
							/>
						}

						<div id='message_input_box' className='docEditor-messagePanel-inputBox-input'/>
						
						
						{selectionPreviewType === 'docSelection' && 
							<div id='message_input_box_ghostSelectionMeasurement' className='docEditor-messagePanel-inputBox-ghostSelectionMeasurement'>
								<div className='docEditor-messagePanel-inputBox-selectionPreview docEditor-messagePanel-inputBox-selectionPreview--docSelection'>
									<div className='docEditor-messagePanel-inputBox-selectionPreview-bar'/>
									<div className='docEditor-messagePanel-inputBox-selectionPreview-content '>
										{messageTextSnapshot}							
									</div>
								</div>
							</div>
						}
					

						{this.state.messageHTML && //for resizing as type														
							<div className='docEditor-messagePanel-inputBox-ghostSpacer' dangerouslySetInnerHTML={createMarkup(messageHTML)}/>							
						}
						{showPlaceholder && placeholderType &&
							<MessageInputBoxPlaceholder
								type={placeholderType}
								threadParentMessage={this.props.threadParentMessage}			
								hasComment={selectionPreviewType || this.props.messageTextSnapshot}
							/>
						}
					</div>
					<MessageInputBoxBtnRow 
						addGif={this.insertGif}
						addVideo={this.clickAddVideo}
						addImage={()=>{this.showImageFilePicker()}}
						addDrawing={()=>{this.clickAddMedia('drawing')}}
						addFigma={()=>{this.clickAddMedia('figma')}}
						sendMessage={this.sendDocMessage}
						handleSelectFile={this.handleSelectFile}
					/>
				</div>
				<MessageInputBoxHintsBar
					userTyping={typingInCurrentConversation?userTyping:null}
					inputBoxNonEmptyAndFocused={this.state.inputIsFocused} // used to show keyboard hint
				/>				
			</React.Fragment>		
		)
	}
}


export default MessageInputBox