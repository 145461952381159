import React from 'react'
import * as ContextMenu from '@radix-ui/react-context-menu';
import {openInFigma} from '../../../utils/openInFigma'

class DocFigmaEmbedContextMenuContents extends React.Component{    
	constructor() {
		super()
		this.openInFigma=this.openInFigma.bind(this)
	}
  
	openInFigma(){
		openInFigma(this.props.embedId)
	}


	render(){ 
		const {embedId}=this.props
		let presentationEffectsLabel = 'Add Presentation Effects'
		if(this.props.hasPresentationEffects){
			presentationEffectsLabel = 'Edit Presentation Effects'
		}
		let disableEffectsBtn = false
		if(this.props.fullyInternalCrop || this.props.showEffectsToolbar){
			disableEffectsBtn = true
		}

		const showPrototypes = false

		return (						
			<React.Fragment>		
					
					{/* LATER! 
					<ContextMenu.Separator />									
					<ContextMenu.Item>
						Ping...
					</ContextMenu.Item>
					<ContextMenu.Item>
						Send via Slack...
					</ContextMenu.Item>
					<ContextMenu.Item>
						Copy Link
					</ContextMenu.Item>					
					*/}									
					{/* NOT SURE WE NEED THIS 
					<ContextMenu.Item>
						Copy Figma URL
					<ContextMenu.Separator />
					*/}														
					{showPrototypes && 
						<React.Fragment>							
							<ContextMenu.Item>
								Refresh Frame
							</ContextMenu.Item>
							<ContextMenu.Item>
								Replace...
							</ContextMenu.Item>
							<ContextMenu.Separator />

								<ContextMenu.Item>
									Concept
								</ContextMenu.Item>
								<ContextMenu.Item>
									In Progress
								</ContextMenu.Item>
								<ContextMenu.Item>
									Proposed
								</ContextMenu.Item>
								<ContextMenu.Item>
									Done
								</ContextMenu.Item>
								<ContextMenu.Item>
									No Status
								</ContextMenu.Item>
							<ContextMenu.Separator />
						</React.Fragment>
					}

					

					<ContextMenu.Item onSelect={this.openInFigma}>
						Open in Figma
					</ContextMenu.Item>
					
					
					{!disableEffectsBtn && 
						<ContextMenu.Item onSelect={this.props.openEffectsToolbar}>
							{presentationEffectsLabel}
						</ContextMenu.Item>								
					}		
					{disableEffectsBtn && 						
						<ContextMenu.Item className='contextMenu-item--disabled'>
							{presentationEffectsLabel}
						</ContextMenu.Item>
					}		
					{this.props.showCropContextMenu && !this.props.cropModeActive &&
						<ContextMenu.Item onSelect={this.props.enableCropMode}>
							Crop...
						</ContextMenu.Item>
					}		

					

					<ContextMenu.Separator />
					<ContextMenu.Item onSelect={this.props.deleteFigmaEmbed}>
						Delete
					</ContextMenu.Item>	
			</React.Fragment>					
		)
	}
}

export default DocFigmaEmbedContextMenuContents
