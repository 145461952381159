import store from '../../store'
import find from 'lodash/find'


export function getTemplateForId(templateId) {	
	const state = store.getState()
	let template= find(state.docTemplates, {'template_id':templateId})
	if(!template){
		template=find(state.archivedDocTemplates, {'template_id':templateId})
	}
	return template

}

