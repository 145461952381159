import React from 'react'

class AppBG extends React.Component{  

  render(){ 

        
    return (            
      <div className='app-bg' />      
    )
  }
}

export default AppBG
