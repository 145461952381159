import React from 'react'
import { withRouter} from 'react-router-dom'
import moment from 'moment'
import Icon from '../misc/Icon'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import filter from 'lodash/filter'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import {getDocForMeeting} from '../../utils/meetings/getDocForMeeting'
import {openDoc} from '../../utils/openDoc'
import {createNewMeetingFromCalEvent} from '../../utils/meetings/createNewMeetingFromCalEvent'

import {createNewMeetingForDate} from '../../utils/meetings/createNewMeeting'


class YourCalendarGCalEvent extends React.Component{  

  render(){     
    return (              
      <button onClick={()=>{createNewMeetingFromCalEvent(this.props.event,this.props.history)}}className="meetingNotesPage-yourCalendar-day-list-meeting meetingNotesPage-yourCalendar-day-list-meeting--isGCal">        
        <div className='meetingNotesPage-yourCalendar-day-list-meeting-name'>          
          <div className='meetingNotesPage-yourCalendar-day-list-meeting-name-label'>
            {this.props.name}
          </div>
          {/*<div className='meetingNotesPage-yourCalendar-day-list-meeting-name-iconContainer'>
            <Icon name='plus' />
          </div>
          */}
        </div>
        <div className='meetingNotesPage-yourCalendar-day-list-meeting-timestamp'>
          <div className='meetingNotesPage-yourCalendar-day-list-meeting-timestamp-timestamp'>
            {this.props.startTimestampLabel} 
          </div>
          <div className='meetingNotesPage-yourCalendar-day-list-meeting-timestamp-label'>
            Add from GCal
          </div>          
        </div>          

      </button>
    )
  }
}

YourCalendarGCalEvent = withRouter(YourCalendarGCalEvent)

class YourCalendarMeeting extends React.Component{  

  render(){     

    const {meetingDoc}=this.props
    return (              
      <button onClick={()=>{openDoc(this.props.history,meetingDoc.doc_id)}} className={'meetingNotesPage-yourCalendar-day-list-meeting meetingNotesPage-yourCalendar-day-list-meeting--meeting ' + (this.props.meetingType ? ` meetingNotesPage-yourCalendar-day-list-meeting--type--${this.props.meetingType} ` : '')}>
        {this.props.meetingType && 
          <div className='meetingNotesPage-yourCalendar-day-list-meeting-iconContainer'>
            <MeetingTypeIcon meetingType={this.props.meetingType} />
          </div>
        }
        <div className='meetingNotesPage-yourCalendar-day-list-meeting-name'>
          {this.props.name}
        </div>
        <div className='meetingNotesPage-yourCalendar-day-list-meeting-timestamp'>
          {this.props.startTimestampLabel}
        </div>             
        <div className='meetingNotesPage-yourCalendar-day-list-meeting-bar' />        
      </button>
    )
  }
}

YourCalendarMeeting = withRouter(YourCalendarMeeting)


function formatStartTime(time){
  return moment(time).format('h:mmA')
}



class YourCalendarDay extends React.Component{  

  render(){     
    const {dayIsToday,dayOfWeekLabel,dateLabel,meetings}=this.props
    return (              
      <div className={'meetingNotesPage-yourCalendar-day ' + (dayIsToday ? ' meetingNotesPage-yourCalendar-day--today ' : '')}>
        <div className='meetingNotesPage-yourCalendar-day-header'>
          <div className='meetingNotesPage-yourCalendar-day-header-dayOfWeek'>
            {dayIsToday ? 'Today' : dayOfWeekLabel} 
          </div>
          <div className='meetingNotesPage-yourCalendar-day-header-date'>
           {dateLabel}
          </div>          
        </div>        
        <div className='meetingNotesPage-yourCalendar-day-list'>
          {meetings.map((meeting)=>{
            if(!meeting.meeting_id){

              return(
              <YourCalendarGCalEvent 
                  key={`cal_meeting_${meeting.id}`}
                  name={meeting.summary}
                  // meetingType={meeting.type}
                  startTimestampLabel={formatStartTime(meeting.meeting_start)}
                  gCalOnly={true}
                  event={meeting}
                  />
                )
            }else{
              const meetingDoc=getDocForMeeting(meeting.meeting_id)
              let name=''
              if(meetingDoc){
                name=meetingDoc.name
              }
              let startTimestampLabel=''
              if(meeting.meeting_start){
                startTimestampLabel=formatStartTime(meeting.meeting_start)
              }

               return(
                <YourCalendarMeeting 
                  key={`cal_meeting_${meeting.meeting_id}`}
                  name={name}
                  meetingType={meeting.type}
                  startTimestampLabel={startTimestampLabel}
                  meetingDoc={meetingDoc}
                />
              )
            }
          })}
      
          <button onClick={()=>{createNewMeetingForDate(this.props.dayDate,this.props.history)}} className='meetingNotesPage-yourCalendar-day-list-newBtn'>
            <Icon name='plus' />
          </button>        

        </div>
      </div>
    )
  }
}

YourCalendarDay = withRouter(YourCalendarDay)

function calcDatesArray(anchorDate){
  let datesArray=[]
  for (var i = 0; i < 5; i++) { 
    datesArray.push(moment(anchorDate).add(i, 'days'))
  }
  return datesArray
}


function formatMeetingsAndEvents(meetings,calEvents){
  let meetingsAndEvents=meetings.slice()
  calEvents.forEach((event)=>{
    const matchedMeeting=find(meetings,{event_id:event.id})
    if(!matchedMeeting){
      const startObj=event.start 
      let dateTimeISO = moment.parseZone(startObj.dateTime).utc().toISOString();
       let modifiedEvent={...event}
        modifiedEvent.meeting_start=dateTimeISO
        meetingsAndEvents.push(modifiedEvent)
      }
  })
  return meetingsAndEvents
}





function getMeetingsForDay(meetingsAndEvents,day){
  const dayMeetings=orderBy(filter(meetingsAndEvents,function(meeting) {
    const dateToCheck = meeting.meeting_start ? moment(meeting.meeting_start) : moment(meeting.meeting_date);
    return dateToCheck.isSame(day, 'day');
    // return moment(meeting.meeting_start).isSame(day,'day')
  }),[function (meeting) {
      const dateToCheck = meeting.meeting_start ? moment(meeting.meeting_start) : moment(meeting.meeting_date);

      return dateToCheck;
      }, function (meeting) {
        if(meeting.summary){
          return meeting.summary.toString().toLowerCase()
        }else{
          const doc = getDocForMeeting(meeting.meeting_id)
        if(doc){
           return doc.name.toString().toLowerCase()
        }
        }
        
      }], ['asc', 'asc'])
  return dayMeetings
}


class MeetingsPageYourCalendar extends React.PureComponent{  

  constructor(props){
    super(props)
    this.handleNavForward=this.handleNavForward.bind(this)
    this.handleNavBackwards=this.handleNavBackwards.bind(this)
    this.resetToToday=this.resetToToday.bind(this)
    const anchorDate=moment().startOf('day')
    const datesArray=calcDatesArray(anchorDate)
    this.state={
      anchorDate: anchorDate,
      datesArray:datesArray
    }
  }

  resetToToday(){
    const anchorDate=moment().startOf('day')
    const datesArray=calcDatesArray(anchorDate)
    this.setState({anchorDate:anchorDate,datesArray:datesArray})
  }

  handleNavForward(){
    let {anchorDate} = this.state 
    let newAnchorDate=anchorDate.add(5, 'days')
    let newDatesArray=calcDatesArray(newAnchorDate)
    this.setState({anchorDate:newAnchorDate,datesArray:newDatesArray})
  }

  handleNavBackwards(){
    let {anchorDate} = this.state 
    let newAnchorDate=anchorDate.subtract(5, 'days')
    let newDatesArray=calcDatesArray(newAnchorDate)
    this.setState({anchorDate:newAnchorDate,datesArray:newDatesArray})
  }

  render(){		
    const {datesArray,anchorDate} = this.state	
    const {meetings,calendarEvents}=this.props

    const meetingsAndEvents=formatMeetingsAndEvents(meetings,calendarEvents)

    const showReturnToTodayBtn = !moment().isSame(anchorDate, 'day')

    return (
      <div className='meetingNotesPage-yourCalendar'>
        {datesArray.map((date,i)=>{
          const isToday = date.isSame(moment(), 'day');
          let dateLabel=date.date()
          if (date.date() === 1 || date.date() === date.daysInMonth()){ //show month label for first/last days of month
           dateLabel=`${date.format('MMM')} ${date.date()}`
          }
          const dayEventsAndMeetings=getMeetingsForDay(meetingsAndEvents,date)
          return(
            <YourCalendarDay 
              dayIsToday={isToday}
              dayOfWeekLabel={date.format('dddd')}
              dayDate={date.format('YYYY-MM-DD')}
              dateLabel={dateLabel}
              meetings={dayEventsAndMeetings}
              key={`day_${i}`}
            />
            )
        })} 

        
        <button onClick={this.resetToToday} className={'meetingNotesPage-yourCalendar-returnToTodayBtn ' + (showReturnToTodayBtn ? ' meetingNotesPage-yourCalendar-returnToTodayBtn--visible ' : ' meetingNotesPage-yourCalendar-returnToTodayBtn--hidden ')}>
          Today
        </button>


        {/* Previous 5 day button */}
        <button onClick={this.handleNavBackwards}  className='meetingNotesPage-yourCalendar-navBtn meetingNotesPage-yourCalendar-navBtn--previous'>
        	<Icon name='chevronRight' />
        </button>
        
        {/* Next 5 day button */}
        <button onClick={this.handleNavForward} className='meetingNotesPage-yourCalendar-navBtn meetingNotesPage-yourCalendar-navBtn--next'>
        	<Icon name='chevronRight' />
        </button>
        
      </div>			 	
    )
  }
}

export default MeetingsPageYourCalendar
