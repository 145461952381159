import findIndex from 'lodash/findIndex'

const defaultState={
	results:[],
	activeIndex:null,
	searchActive:false
}

export default function (state = defaultState, action) {
	switch (action.type) {

	case 'UPDATE_DOC_SEARCH_RESULTS':
		let newSearchState={...defaultState}
		newSearchState.searchActive=true
		newSearchState.results=action.results
		newSearchState.activeIndex=action.activeIndex

		return newSearchState

	case 'CHANGE_DOC_SEARCH_ACTIVE_INDEX':

		let updatedSearchState={...state}
		updatedSearchState.activeIndex=action.index
		
		return updatedSearchState



		

	case 'CLOSE_DOC_SEARCH':
		return defaultState
	
	case 'OPEN_DOC_SEARCH':
	
		let newState={...defaultState}
		newState.searchActive=true

		return newState
	

	default:
		return state
	}
}
