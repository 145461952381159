import store from '../../store'
import find from 'lodash/find'
import {createMeetingRecordingRequest} from '../../actions/recording'

export function createMeetingRecording(meetingId,file,recordingContext) {	
	if(recordingContext && file){
		recordingContext.uploadFile(meetingId,file)
	}	
}

 