import React from 'react'
// import ReactDOM from 'react-dom';
import DocWhiteboardEmbedReactComponent from './DocWhiteboardEmbedReactComponent'
import {getWhiteboardEmbedForId} from '../../../utils/getFigmaEmbedForId'
import { createRoot } from "react-dom/client";

class DocWhiteboardEmbedNodeView {

	constructor(node, view, getPos,newNodeMessage,docId,sendMediaModalMessage,setThreadParentMessage) {
		this.getNode=this.getNode.bind(this)
		this.deleteWhiteboardEmbed=this.deleteWhiteboardEmbed.bind(this)
		// this.createNewNodeMessage=this.createNewNodeMessage.bind(this)
		// this.updateNodeAttribute=this.updateNodeAttribute.bind(this)
		// this.newNodeMessage=newNodeMessage
		// this.sendMediaModalMessage=sendMediaModalMessage
		// this.setThreadParentMessage=setThreadParentMessage
		this.node = node 
		this.getPos=getPos

		this.dom = document.createElement("div")
		this.dom.addEventListener("mousedown", this.handleDomClick)

		const {embedId}=node.attrs
		this.dom.id=embedId
		this.renderReactComponent=this.renderReactComponent.bind(this)
		this.dom.className="doc-whiteboardWrapper"

		this.root = createRoot(this.dom);

		this.renderReactComponent()
	}
 
	handleDomClick(e){
		e.stopPropagation()
	}

  renderReactComponent(){
    const {embedId,whiteboardEmbedVersion}=this.node.attrs 
    this.root.render(
      <DocWhiteboardEmbedReactComponent 
        key={whiteboardEmbedVersion}
        embedId={embedId}
        deleteWhiteboardEmbed={this.deleteWhiteboardEmbed}
      />
    );
  }

  update(node) {
    if (node.type !== this.node.type) return false
    
    const {whiteboardEmbedVersion} = node.attrs
    const oldWhiteboardEmbedVersion=this.node.attrs.whiteboardEmbedVersion
    this.node=node
    if(oldWhiteboardEmbedVersion !== whiteboardEmbedVersion){
      this.renderReactComponent()
    }
        
    return true
  }

  // createNewNodeMessage(e,isDocThreadParent){
  //   e.stopPropagation()
  //   const {embedId}=this.node.attrs
  //   const embedObj=getFigmaEmbedForId(embedId)
  //   const snapshot=embedObj
  //   this.newNodeMessage(this.node.attrs.nodeId,'figmaEmbed',snapshot,isDocThreadParent)
  // }

  // updateNodeAttribute(field,value){
  //   const node=this.getNode()
  //   const nodePos=this.getPos(node)
  //   let attributes={...node.attrs}
  //   attributes[field]=value
  //   let tr = window.view.state.tr  
  //   tr.setNodeMarkup(nodePos, null, attributes)
  //   window.view.dispatch(tr)
  // }

  // update(node) {
  //   if (node.type !== this.node.type) return false
  //   this.node=node
  //   this.renderReactComponent()
  //   return true
  // }

  // selectNode() {
  //   this.dom.classList.add("doc-figmaWrapper--selected")
  // }

  // deselectNode() {
  //   this.dom.classList.remove("doc-figmaWrapper--selected")
  // }

  getNode(){
    return this.node
  }

  deleteWhiteboardEmbed(e){
    e.stopPropagation()
    e.preventDefault()
    let tr = window.view.state.tr
    const nodePos=this.getPos()
    tr.delete(nodePos,nodePos+this.node.nodeSize)
    window.view.dispatch(tr)
  }

  stopEvent(event){ //prevent typing in modal input from bubbling to editor so that e.g. backspace doesnt delete 
    if(event.type=='keypress'){
       return true
    }
  }

  // destroy() {
  //   this.dom.removeEventListener("mousedown", this.handleDomClick)
  //    this.root.unmount();
  // }
  destroy() {
    this.dom.removeEventListener("mousedown", this.handleDomClick)
       setTimeout(() => {
      this.root.unmount();
    }, 0);
     //this.root.unmount();
  }


}

export default DocWhiteboardEmbedNodeView