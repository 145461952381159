import React from 'react'
import Icon from './Icon'

class LinearProjectStatusIcon extends React.PureComponent{    
  render(){ 
    const {projectStatus} = this.props
  
        

    return (
      <div className='linearProjectStatusIconContainer'>
      
        {projectStatus === 'backlog' && // backlog
          <div className='linearProjectStatusIcon linearProjectStatusIcon--backlog' />
        }

        {projectStatus === 'planned' && 
          <div className='linearProjectStatusIcon linearProjectStatusIcon--planned' />
        }

        {projectStatus === 'started' && // aka in progress
          <div className='linearProjectStatusIcon linearProjectStatusIcon--started'>            
            <div className='linearProjectStatusIcon--started-dot'/>
          </div>
        }

        {projectStatus === 'completed' && // aka completed
          <div className='linearProjectStatusIcon linearProjectStatusIcon--completed'>
            <Icon name='toDoItemCheckmark' />
            <div className='linearProjectStatusIcon--completed--innerBorder' />
          </div>
        }
      
        {projectStatus === 'canceled' &&
          <div className='linearProjectStatusIcon linearProjectStatusIcon--canceled'>
            <Icon name='linearCancelledStateIcon' /> 
            <div className='linearProjectStatusIcon--canceled--innerBorder' />
          </div>
        }

        {projectStatus === 'paused' &&
          <div className='linearProjectStatusIcon linearProjectStatusIcon--paused'>
            <Icon name='linearPausedPriorityIcon' /> 
            <div className='linearProjectStatusIcon--paused--innerBorder' />
          </div>
        }
      
      </div>
    )
  }
}

export default LinearProjectStatusIcon
