
function getInitialState(){
	const roadmapsHomePageSettings = JSON.parse(localStorage.getItem('roadmapsHomePageSettings'))
	if(roadmapsHomePageSettings){
		return roadmapsHomePageSettings
	}else{
		return {}
	}

}



export default function (state = getInitialState(), action) {
	switch (action.type) {

	case 'UPDATE_ROADMAPS_HOME_PAGE_SETTING':
		const {setting,value}=action	
		let newState=Object.assign({},state)
		newState[setting]=value
		localStorage.setItem('roadmapsHomePageSettings',JSON.stringify(newState))
		return newState
	
	default:
		return state
	}
}

