import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_ISSUES_SUCCESS':
		return action.response

	case 'CREATE_ISSUE_SUCCESS':
	case 'UPDATE_ISSUE_SUCCESS':
		const issue_id=action.issue['issue_id']
		let index=findIndex(state,(issue => {
			return issue['issue_id'] === issue_id
		}))
		if(index === -1){
			index=state.length
		}
		return [
			...state.slice(0,index),
			action.issue,
			...state.slice(index + 1)
			]


		case 'DELETE_ISSUE':
			const issueId=action.issueId
			let deletedIndex=findIndex(state,(issue => {
				return issue.issue_id == issueId
			}))
			if(deletedIndex === -1){
				return state
			}else{
				return [
					...state.slice(0,deletedIndex),
					...state.slice(deletedIndex + 1)
				]
			}


	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}

