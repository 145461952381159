//get message count and messageIdimport store from '../../store'
import find from 'lodash/find'
import store from '../../store'


export function getMessageForHighlight(highlightId) {	
	let messageObj={}
	const state = store.getState()
	const messages=state.messages 
	messages.forEach((message)=>{
		if(message.recording && message.recording.type=='highlight' && message.recording.highlight_id==highlightId){
			messageObj=message
		}
	})

return messageObj
}

