const DocCodeMirrorNodeSpec = {
  attrs: {
    language:{default:'javascript'},
    theme:{default:'artifact'},
    systemThemeVersion:{default:0} //for updating when theme light/dark changes
  },
  group: "block",
  content:"text*",
  defining: true,
  isolating:true,
  parseDOM: [
      {
        tag: "docCodeMirror",
        getAttrs: (dom) => ({
          // emojiString: dom.getAttribute("data-emoji-string"),
        }),  preserveWhitespace: 'full'}
       
    ],
    toDOM(node) {
      //const {emojiString} = node.attrs;
      return ["docCodeMirror",{},0]
    }
}


   

 export default DocCodeMirrorNodeSpec