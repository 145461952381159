import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'


//Add to each workflow state

//workflowTypeStatesCount - number of workflow states for that team with same type e.g. backlog
//workflowTypeStateIndex - index of current state in group of workflow states of that type


export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_WORKFLOWS_SUCCESS':
		const workflowStates=action.response
		let workflowStatesWithIndex=[]
		const teams = [...new Set(workflowStates.map(state => state.team))]

		teams.forEach((team)=>{
			const teamStates=filter(workflowStates,{team:team})
			const typeGroupedStates = groupBy(teamStates, function(state) {
				return state.type
			})
			const array=Object.keys(typeGroupedStates)
			array.forEach((key)=>{
				const groupedState=typeGroupedStates[key]
				const sorted=sortBy(groupedState,'position')
				sorted.forEach((state,i)=>{
					const workflowTypeStatesCount=groupedState.length
					const workflowTypeStateIndex=i
					const modifiedState={...state,workflowTypeStatesCount:workflowTypeStatesCount,workflowTypeStateIndex:workflowTypeStateIndex}
					workflowStatesWithIndex.push(modifiedState)
				})
			})
		})


		return workflowStatesWithIndex

	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
