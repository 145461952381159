import React from 'react'
import ReactDOM from 'react-dom'
import MessageInternalLinkReactComponent from './MessageInternalLinkReactComponent'


class MessageInternalLinkNodeView {
  
  constructor(node, view, getPos,handleOpenDoc) {
    this.dom = document.createElement("div")
    this.dom.className="message-para-internalLinkContainer"
    const {docId}=node.attrs
    ReactDOM.render(
      <MessageInternalLinkReactComponent 
        handleOpenDoc={handleOpenDoc}
        docId={docId}
      />, this.dom)
  }
  
  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom)
  }

}

export default MessageInternalLinkNodeView