import schema from '../schema/editorSchema'  
import {TextSelection} from "prosemirror-state"
import {getAnchorNode} from './getAnchorNode'
import {getDefaultIssueAttrs} from '../utils/getDefaultIssueAttrs'


export function insertIssueFromPaste(issueId) {
	const state=window.view.state
	let tr=state.tr
	let {$from, $to} = state.selection

	// let layout='block'
	let type = schema.nodes.linearIssueMention	
	const nodeTypeName='paragraph'
	// const anchorNode=getAnchorNode($from,nodeTypeName)
	// if(anchorNode && anchorNode.textContent.length>0){
	// 	layout='inline'
	// }
	let attrs=getDefaultIssueAttrs()
	const node = type.createAndFill({...attrs,issueId:issueId,layout:'inline'})

	
	//const node = type.createAndFill({issueId:issueId,layout:layout})



	//let insertPos=state.selection.from

	let content=tr.doc.slice($from.pos,$to.pos)
	tr.replaceWith(state.selection.from,state.selection.to,node)
	//let selection=new TextSelection(tr.doc.resolve(state.selection.from+node.nodeSize-1))
	//tr.setSelection(selection)
	window.view.dispatch(tr)
}






	// tr.replaceWith(state.range.from, state.range.to, node)
	// tr.setMeta("mentionPlugin", { deactivate: true })
	// view.dispatch(tr)
	// hideList()