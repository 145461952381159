import React from 'react'
import Icon from '../../misc/Icon'

class DocImageZoomedModalImageCarouselBtn extends React.Component{  
	render(){ 
		const {isActive,image} = this.props
		const deliveryUrl=image.deliveryUrl
		return (			
			<button onClick={this.props.onClickFunction} className={'doc-zoomedImageModal-imageCarousel-btn ' + (isActive ? ' doc-zoomedImageModal-imageCarousel-btn--isActive ' : ' doc-zoomedImageModal-imageCarousel-btn--isInactive ')}>
				<div className='doc-zoomedImageModal-imageCarousel-btn-frame'>
					<img 
						alt=''
						draggable="false"
						src={deliveryUrl}
						className='doc-zoomedImageModal-imageCarousel-btn-frame-image'
					/>
				</div>												 
			</button>			
		)
	}
}

class DocImageZoomedModalImageCarousel extends React.Component{  
  
	render(){ 
		const {imageArray,activeIndex} = this.props				
		return (
			<div className='doc-zoomedImageModal-imageCarousel'>
				{imageArray.map((image,i)=>{
					const isActive=activeIndex==i
					return(
						<DocImageZoomedModalImageCarouselBtn 
							key={i}
							image={image}
							onClickFunction={()=>{this.props.setActiveIndex(i)}}
							isActive={isActive}
						/>	
					)
				})}
			</div>
		)
	}
}

export default DocImageZoomedModalImageCarousel