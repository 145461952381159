import React from 'react'
import Icon from '../../../misc/Icon'

//const imgUrl="https://res.cloudinary.com/yarn/image/upload/c_scale,q_100,w_1000/v1663976324/matt-drenth-AzcJoHkGgx4-unsplash_ticmwg.jpg"
const imgUrl="https://res.cloudinary.com/yarn/image/upload/v1663981174/doprodn3_lprnaw.png"


class MessageMediaFigma extends React.Component{  

	render(){	
			
		return (
			<div className='message-content-figmaContainer'>
				<div className='message-content-figmaToolbar'>
					<Icon name='figmaColor' />
				</div>

				<img className='message-content-figma' src={imgUrl} />
		  	

        
			</div>
		)
	}
}
 


export default MessageMediaFigma
