import React from 'react'
import Icon from '../../../misc/Icon'
import DocImageZoomedModalBtn from '../DocImageZoomedModalBtn'

class DocFigmaEmbedZoomedModalHeader extends React.Component{  	
	
	render(){	
		const {cropModeActive} = this.props
		return (
			<div className='doc-zoomedFigmaModal-header'>
				{cropModeActive && 
					<button onClick={this.props.disableCropMode} className='doc-zoomedFigmaModal-header--closeCropBtn' />
				}
				<div className={'doc-zoomedFigmaModal-header-leftContainer ' + (cropModeActive ? ' doc-zoomedFigmaModal-header-leftContainer--fade ' : ' doc-zoomedFigmaModal-header-leftContainer--visible ')}>	
					<DocImageZoomedModalBtn
						iconName='insertBarFigma'
						label={this.props.title}
						name="figmaTitle"					
					/>	
				</div>
					
				<div className='doc-zoomedFigmaModal-header-centerContainer'>
						
						{/*}
						<DocImageZoomedModalBtn
							iconName='drawingArrow'
							tooltipLabel='Arrow'
						/>
						<DocImageZoomedModalBtn
							iconName='drawingSquare'
							tooltipLabel='Square'
						/>
						<DocImageZoomedModalBtn
							iconName='drawingText'
							tooltipLabel='Text'
						/>
						<DocImageZoomedModalBtn
							iconName='drawingStickyAlt'
							tooltipLabel='Sticky Note'
						/>
						<DocImageZoomedModalBtn
							iconName='plusRegular'
							tooltipLabel='Add...'
						/>			
						*/}			
				</div>
				<div className={'doc-zoomedFigmaModal-header-rightContainer' + (cropModeActive ? ' doc-zoomedFigmaModal-header-rightContainer--fade ' : ' doc-zoomedFigmaModal-header-rightContainer--visible ')}>							
					<DocImageZoomedModalBtn							
						iconName='imageCrop'
						label='Crop'														
						onClickFunction={this.props.enableCropMode}
					/>						
						{/*} MAYBE ADD THREADING LATER
						{!this.props.showThreadPanel &&
							<DocImageZoomedModalBtn							
								iconName='rightMenu'
								label='Show Thread'
								name="thread"		
								onClickFunction={this.props.openThreadPanel}
							/>
						}
						*/}				
					</div>
			</div>
		)
	}
}

export default DocFigmaEmbedZoomedModalHeader
