import React from 'react'
import Icon from '../../misc/Icon'

class DocTableToggleHeaderRowButton extends React.Component{  
	render(){
	  return (
	  
		  	<button onClick={this.props.toggleHeaderRow} >
		  		Toggle Header Row		
		  	</button>
		  	
	
	  )
	}
}

export default DocTableToggleHeaderRowButton
