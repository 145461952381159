import React from 'react'
import Icon from '../../misc/Icon'
import { Tldraw ,TLEditorComponents,createTLStore,TldrawEditor} from "@tldraw/tldraw"
import { useLayoutEffect, useState } from 'react'
import {Canvas,defaultShapes,defaultTools,TldrawUi} from '@tldraw/tldraw'
import {getWhiteboardEmbedForId} from '../../../utils/whiteboards/getWhiteboardEmbedForId'
import {Box2d,clamp} from '@tldraw/primitives'
import {compact} from '@tldraw/utils'

const PADDING=0.05
const MIN_ZOOM = 0.1
const MAX_ZOOM = 8

const maxDisplayWidth = 720 
const maxDisplayHeight = 800

function modifiedZoomToFit(editor){
	if (!editor.canMoveCamera) return editor
	const ids = [...editor.currentPageShapeIds]
	if (ids.length <= 0) return editor
	const opts=null
	const pageBounds = Box2d.Common(compact(ids.map((id) => editor.getPageBoundsById(id))))
	modifiedZoomToBounds(
		editor,
		pageBounds.minX,
		pageBounds.minY,
		pageBounds.width,
		pageBounds.height,
		undefined,
		opts
	)
	return editor
}

function modifiedZoomToBounds(editor,x, y, width, height, targetZoom, opts) {
    if (!editor.canMoveCamera) return editor
    let displayWidth
  	let displayHeight
  	const maxDisplayAspect = maxDisplayWidth / maxDisplayHeight

  	const pageBoundsAspect = width / height

  	if(pageBoundsAspect > maxDisplayAspect){
  		// page wider than max, so fits width
  		displayWidth = maxDisplayWidth
  		displayHeight = displayWidth / pageBoundsAspect
  	}
  	else{
  		displayHeight = maxDisplayHeight
  		displayWidth = displayHeight * pageBoundsAspect
  	}



   // const viewportScreenBounds = editor.viewportScreenBounds

    //const inset = Math.min(256, displayWidth * PADDING)
    const inset = 10 // a little bit for strokes on edge

    let zoom = clamp(
        Math.min(
            (displayWidth - inset) / width,
            (displayHeight - inset) / height
        ),
        MIN_ZOOM,
        MAX_ZOOM
    )

    if (targetZoom !== undefined) {
        zoom = Math.min(targetZoom, zoom)
    }
    if (opts && opts.duration) {
        editor.animateCamera(
            -x + (displayWidth - width * zoom) / 2 / zoom,
            -y + (displayHeight - height * zoom) / 2 / zoom,
            zoom,
            opts
        )
    } else {
        editor.setCamera(
            -x + (displayWidth - width * zoom) / 2 / zoom,
            -y + (displayHeight - height * zoom) / 2 / zoom,
            zoom
        )
    }
    return editor
}



export default function DocWhiteboardReadOnlyTLDraw({embedId,handleWhiteboardReady,theme}){  
 
	const [store] = useState(() => createTLStore({shapes:defaultShapes,tools:defaultTools})) 
	
	const [displayDimensions, setDisplayDimensions] = useState({
        width: 720,
        height: 500,
    });

	const [loadingState, setLoadingState] = useState({
		status: 'loading',
	});

	useLayoutEffect(() => {
		if(embedId){
			const embedObj=getWhiteboardEmbedForId(embedId)
			let snapshot
			if(embedObj){
				snapshot=embedObj.snapshot
				setDisplayDimensions({ width: embedObj.display_dimensions.width || 720, height: embedObj.display_dimensions.height || 500 })
			}
			if (snapshot) {
				try {

					store.loadSnapshot(snapshot)

					setLoadingState({ status: 'ready' })
				} catch (error) {

					setLoadingState({ status: 'error', error: error.message }) // Something went wrong
				}
			} else {
				setLoadingState({ status: 'ready' }) // Nothing persisted, continue with the empty store
			}
		}else{
    	setLoadingState({ status: 'ready' })
		}
	}, [store])

		return (	
			<div style={{width: `${displayDimensions.width}px`, height: `${displayDimensions.height}px`}} className='doc-whiteboardPreview-preview-inner'>
				<TldrawEditor 
					store={store}
					shapes={defaultShapes} 
					tools={defaultTools}
					onMount={(editor) => {
						setTimeout(() => {
							modifiedZoomToFit(editor)
							if(theme=='dark'){
								editor.setDarkMode(true)
							}else{
								editor.setDarkMode(false)
							}
							editor.setReadOnly(true)
								setTimeout(() => {
								handleWhiteboardReady()
							}, 20);   
						}, 0);   
				}}
				>		
				<Canvas />
				</TldrawEditor>
				</div>
		)
	}


