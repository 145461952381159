import React from 'react'
import Icon from '../misc/Icon'
import {getUserForId} from '../../utils/getUserForId'
import {getProjectForId} from '../../utils/getProjectForId'
import AvatarImage from '../AvatarImage'

class DocEditorProjectUpdates extends React.Component{  

	render(){	

    let project
    let leadUserId
    let leadUser
    
    if(this.props.projectDocProjectId){
      project = getProjectForId(this.props.projectDocProjectId)
    }
    if(project){      
      leadUserId = project.lead    
    }
    if(leadUserId){
      leadUser = getUserForId(leadUserId)
    }
    

	  return (	  	
      <React.Fragment>
      {leadUser && 
    		<div className='docEditor-projectUpdatesContainer'>
          <div className='docEditor-projectUpdate'>                    
            <div className='docEditor-projectUpdate-header'>
              <div className='docEditor-projectUpdate-header-avatarContainer'> 
                <AvatarImage className='docEditor-projectUpdate-header-avatar' avatarUrl={leadUser.avatar} />
              </div>
              <div className='docEditor-projectUpdate-header-name'> 
                {leadUser.display_name}
              </div>
              <div className='docEditor-projectUpdate-header-timestamp'> 
                2 days ago
              </div>
              <div className='docEditor-projectUpdate-header-historyTabs'>
                <div className='docEditor-projectUpdate-header-historyTabs-tab' />
                <div className='docEditor-projectUpdate-header-historyTabs-tab' />
                <div className='docEditor-projectUpdate-header-historyTabs-tab' />
                <div className='docEditor-projectUpdate-header-historyTabs-tab' />
              </div>
            </div>
            <div className='docEditor-projectUpdate-content'> 
              Making bold and remarkable Figma features will start discussions with users around how they collaborate on product development and make us stand out from Notion and others. Also, refreshing Figma files is kind of table-stakes!            
            </div>
          </div>
        </div>
      }
      
      </React.Fragment>
	  )
	}
}

export default DocEditorProjectUpdates
