import {Plugin,PluginKey} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

//Adds spans to all plugins
//used for selection magic

const key = new PluginKey('paragraphSpanPlugin')

const PARAGRAPH_SPAN_CLASSNAME="doc-para-span"

export function paragraphSpanPlugin() {
	return new Plugin({
		key,
		props: {
      decorations: ({doc}) => {
        let decos=[]
        if(doc){
          doc.descendants((node, pos) => {
            if(node.type.name=='paragraph'){
              decos.push(Decoration.inline(pos, pos+node.nodeSize, {class:PARAGRAPH_SPAN_CLASSNAME}))
            }
          })
        }
        return DecorationSet.create(doc, decos)
      }
    }
  })
}
