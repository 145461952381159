import React from 'react'
import Icon from '../misc/Icon'
import { withRouter} from 'react-router-dom'
import LinearMarker from '../misc/LinearMarker'
import LinearProjectStatusIcon from '../misc/LinearProjectStatusIcon'
import AvatarImage from '../AvatarImage'
import {getUserForId} from '../../utils/getUserForId'
import {getTeamForId} from '../../utils/getTeamForId'
import {getRootDocIdForProject} from '../../utils/getRootDocIdForProject'
import {openDoc,backgroundOpenDoc} from '../../utils/openDoc'
import moment from 'moment'
import {getRoadmapsForProject} from '../../utils/getRoadmapsForProject'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as ContextMenu from '@radix-ui/react-context-menu';
import RoadmapsProjectRowContextMenuContents from './RoadmapsProjectRowContextMenuContents'
import RoadmapsProjectRowMilestones from './RoadmapsProjectRowMilestones'
import EditMetadataPopoverWrapper from '../popovers/EditMetadataPopoverWrapper'
import {updateProjectLead} from '../../utils/updateProject/updateProjectLead'
import {updateProject} from '../../utils/updateProject/updateProject'
import {updateProjectRoadmaps} from '../../utils/updateProject/updateProjectRoadmaps'
import {updateProjectTargetDate} from '../../utils/updateProject/updateProjectTargetDate'
import includes from 'lodash/includes'
import {updateProjectStatus} from '../../utils/updateProject/updateProjectStatus'
import {getProjectMilestoneDocs} from '../../utils/getProjectMilestoneDocs'

function formatTargetDateLabel(targetDate){
	const year = moment().startOf('isoYear')
	let formatedTimestamp
	if (moment(targetDate).isSame(year, 'y')) {
		formatedTimestamp=moment(targetDate).format('DD MMM')
	}else{
		formatedTimestamp=moment(targetDate).format('MMM YY')
	}
	return formatedTimestamp
}

class RoadmapsProjectRow extends React.PureComponent{  	

	constructor(props){
		super(props)
		this.handleProjectRowClick=this.handleProjectRowClick.bind(this)
		this.handleProjectStatusClick=this.handleProjectStatusClick.bind(this)
		this.handleProjectLeadClick=this.handleProjectLeadClick.bind(this)
		this.handleProjectRoadmapsClick=this.handleProjectRoadmapsClick.bind(this)
		this.handleProjectTargetDateClick=this.handleProjectTargetDateClick.bind(this)
		this.updateProjectLead=this.updateProjectLead.bind(this)
		this.handleOnOpenChange=this.handleOnOpenChange.bind(this)
		this.handleUpdateProjectMembers=this.handleUpdateProjectMembers.bind(this)
		this.handleUpdateProjectTeams=this.handleUpdateProjectTeams.bind(this)
		this.handleUpdateProjectRoadmaps=this.handleUpdateProjectRoadmaps.bind(this)
		this.updateProjectTargetDate=this.updateProjectTargetDate.bind(this)
		this.handleUpdateProjectStatus=this.handleUpdateProjectStatus.bind(this)
		this.handleCloseEditProjectRoadmapsPopover=this.handleCloseEditProjectRoadmapsPopover.bind(this)
		
		const project = props.project
		const teams=project.teams
		const members=project.members

		const roadmaps=getRoadmapsForProject(project.project_id)
		let projectRoadmaps=[]
		roadmaps.forEach((roadmap)=>{
			projectRoadmaps.push(roadmap.roadmap_id)
		})

		this.state={		
		 	showEditProjectStatusPopover: false,
		 	showEditProjectTargetDatePopover: false,
		 	showEditProjectLeadPopover: false,
		 	showEditProjectRoadmapsPopover: false,
      teams:teams,
      members:members,
      roadmaps:projectRoadmaps,
      showToast:false
		}
	}

	handleProjectRowClick(e){
		const {project}=this.props
		const projectDocId=getRootDocIdForProject(project.project_id)
		if(e.metaKey){
			backgroundOpenDoc(projectDocId)
		}else{
			openDoc(this.props.history,projectDocId)
		}
	}

	handleProjectStatusClick(e){
		e.stopPropagation()
		this.setState({showEditProjectStatusPopover: true})
	}

	handleProjectLeadClick(e){
		e.stopPropagation()
		this.setState({showEditProjectLeadPopover: true})
	}

	updateProjectLead(lead){
		updateProjectLead(this.props.project.project_id,lead)
	}

	handleProjectRoadmapsClick(e){
		e.stopPropagation()
		this.setState({showEditProjectRoadmapsPopover: true})
	}

	handleProjectTargetDateClick(e){
		e.stopPropagation()
		this.setState({showEditProjectTargetDatePopover: true})
	}

	updateProjectTargetDate(targetDate,selcted,modifiers,e){
		e.preventDefault()
		e.stopPropagation()
		this.setState({showEditProjectTargetDatePopover:false})
		updateProjectTargetDate(this.props.project.project_id,targetDate)
	}

	handleUpdateProjectStatus(status){
		this.setState({showEditProjectStatusPopover:false})
		updateProjectStatus(this.props.project.project_id,status)
  }

	handleOnOpenChange(open){
		if(open){
			const {project}=this.props
			this.setState({teams:project.teams,members:project.members})
		}else{
  		const oldProject=this.props.project
  		if(oldProject.members != this.state.members || oldProject.teams!=this.state.teams){
				let newProject={...oldProject}
				newProject.members=this.state.members
				newProject.teams=this.state.teams
				const showToast=this.state.showToast
				updateProject(newProject,showToast)
  		}else{
  			//console.log('nothing changedd!')
  		}
  		//check roadmaps
			const {roadmaps}=this.state
			let roadmapsToRemove=[]
			let roadmapsToAdd=[]
			const origionalRoadmaps=getRoadmapsForProject(this.props.project.project_id)
			origionalRoadmaps.forEach((roadmap)=>{
				if(!includes(roadmaps,roadmap.roadmap_id)){
					roadmapsToRemove.push(roadmap.roadmap_id)
				}
			})
			roadmaps.forEach((roadmapId)=>{
				let isNewRoadmap=true
				origionalRoadmaps.forEach((roadmap)=>{
					if(roadmap.roadmap_id==roadmapId){
						isNewRoadmap=false
					}
				})
				if(isNewRoadmap){
					roadmapsToAdd.push(roadmapId)
				}
			})
			if(roadmapsToRemove.length || roadmapsToAdd.length){
				let actions=[]
				roadmapsToRemove.forEach((roadmapId)=>{
					actions.push({roadmapId:roadmapId,action:'remove'})
				})
				roadmapsToAdd.forEach((roadmapId)=>{
					actions.push({roadmapId:roadmapId,action:'add'})
				})
				const showToast=true
				updateProjectRoadmaps(this.props.project.project_id,actions,showToast)
				}
		}
	}

	handleUpdateProjectMembers(projectMembers){
  	this.setState({members:projectMembers,showToast:true})
  }

  handleUpdateProjectTeams(teams){
  	this.setState({teams:teams,showToast:true})
  }

  handleUpdateProjectRoadmaps(roadmaps){
  	this.setState({roadmaps:roadmaps})
  }

  handleCloseEditProjectRoadmapsPopover(){
  	this.setState({showEditProjectRoadmapsPopover:false})
  	this.handleOnOpenChange(false)
  }


	render(){	
		const {project, lastItemInGroup}=this.props
		const {showEditProjectStatusPopover, showEditProjectTargetDatePopover, showEditProjectLeadPopover, showEditProjectRoadmapsPopover} = this.state
		const {name,lead,teams,project_id,description,state}=project
		const user = getUserForId(lead)		
		let targetDateLabel
		let targetDateWarning = false // if target date is before today, and not completed
		if(project.target_date){
			targetDateLabel=formatTargetDateLabel(project.target_date)
			if(project.state !=='completed'){
				const today = moment().startOf('day'); 
				if(moment(project.target_date).isBefore(today)){
					targetDateWarning=true
				}
			}
		}

		//console.log(`lead: ${lead}`)
		const roadmaps = getRoadmapsForProject(project_id)
		let filteredRoadmaps=[]
		roadmaps.forEach((roadmap)=>{ //only show roadmap label for roadmaps that are not the current page
			if(roadmap.roadmap_id!=this.props.currentRoadmapId){
				filteredRoadmaps.push(roadmap)
			}
		})
		let showTargetDate = false
		if(state === 'started' || targetDateLabel){
			showTargetDate = true			
		}
		let projectStatusLabel = state    
    if(state === 'started'){
      projectStatusLabel = 'in progress'
    }
    const editMetadataPopoverOpen = showEditProjectStatusPopover || showEditProjectTargetDatePopover || showEditProjectLeadPopover || showEditProjectRoadmapsPopover

    let startedNoMilestones = false

    if(project && project.project_id && project.state === 'started' && this.props.hasStartedProjectsWithMilestones){
    	const projectMilestoneDocs=getProjectMilestoneDocs(project.project_id)
    	if(projectMilestoneDocs){
    		startedNoMilestones = projectMilestoneDocs.length < 1
    	}
    }
    


	  return (
	  	<React.Fragment>
	  	<div className='roadmapsProjectRowContainer'>
	  	<ContextMenu.Root key={project_id} onOpenChange={this.handleOnOpenChange}> 
				<ContextMenu.Trigger asChild>
				  <div onClick={this.handleProjectRowClick} className={'roadmapsProjectRow ' + (lastItemInGroup ? ' projectsPage-projectRow--lastItemInGroup ' : ' ') + (startedNoMilestones ? ' projectsPage-projectRow--startedNoMilestones ' : ' ') + (editMetadataPopoverOpen ? ' roadmapsProjectRow--popoverOpen ' : ' ')}>  		
					  	<EditMetadataPopoverWrapper
								showPopover={this.state.showEditProjectStatusPopover}						
								openPopover={()=>{this.setState({showEditProjectStatusPopover: true})}}
								closePopover={()=>{this.setState({showEditProjectStatusPopover: false})}}		
								type="projectStatus"  // used to load in popover contents
								layout="roadmapProjectRow" // used for positioning
								projectId={project_id}
								projectStatus={state}	
								updateProjectStatus={this.handleUpdateProjectStatus}
								portalled
							>
							<div className='roadmapsProjectRow-statusOuterContainer'>
								<Tooltip.Provider>
									<Tooltip.Root delayDuration={0}>
										<Tooltip.Trigger asChild>																																			
											<button onClick={this.handleProjectStatusClick} className='roadmapsProjectRow-statusContainer'>						
												<LinearProjectStatusIcon 
												projectStatus={state}
											/>												
											</button>								
										</Tooltip.Trigger>												 	
										<Tooltip.Content side="bottom" className="tooltip tooltip--projectStatus forceDarkTheme">																
											<div className="tooltip--projectStatus-title">
												{projectStatusLabel}
											</div>	
											<div className="tooltip--projectStatus-attribute">
												Edit Status
											</div>				
										</Tooltip.Content>	
									</Tooltip.Root>
								</Tooltip.Provider>
							</div>	
						</EditMetadataPopoverWrapper>
				  		<div className='roadmapsProjectRow-markerContainer'>
				  			<LinearMarker projectId={project_id} />
				  		</div>
				  		<div className='roadmapsProjectRow-name'>	  	
				  			<div className='roadmapsProjectRow-name-label'>	  			
				  				<div className='roadmapsProjectRow-name-label-label'>			
				  					{name}
				  				</div>
				  			</div>
						  		{showTargetDate &&   
							  		<EditMetadataPopoverWrapper
											showPopover={showEditProjectTargetDatePopover}						
											openPopover={()=>{this.setState({showEditProjectTargetDatePopover: true})}}
											closePopover={()=>{this.setState({showEditProjectTargetDatePopover: false})}}		
											type="projectTargetDate"  // used to load in popover contents
											layout="projectRootDocTopMenu" // used for positioning
											projectId={project_id}
											updateProjectTargetDate={this.updateProjectTargetDate}
											currentTargetDate={project.target_date}
											portalled
										>
											<div className='roadmapsProjectRow-targetDateOuterContainer'>
												<Tooltip.Provider>
													<Tooltip.Root delayDuration={0}>
														<Tooltip.Trigger asChild>				
															<div className={'roadmapsProjectRow-targetDateContainer ' + (targetDateLabel ? ' roadmapsProjectRow-targetDateContainer--edit ' : ' roadmapsProjectRow-targetDateContainer--add ' )}>		
																<div onClick={this.handleProjectTargetDateClick} className={'roadmapsProjectRow-targetDate ' + (targetDateWarning ? ' roadmapsProjectRow-targetDate--warning ' : ' ')}>
													  			<div className='roadmapsProjectRow-targetDate-iconContainer'>
													  				<Icon name='calendarSimple' />
													  			</div>
													  			{!targetDateLabel && 
														  			<div className='roadmapsProjectRow-targetDate-label'>
														  				Add Target
														  			</div>
													  			}
													  			{targetDateLabel && 
														  			<div className='roadmapsProjectRow-targetDate-label'>
														  				{targetDateLabel}
														  			</div>
													  			}
													  		</div>					 
													  	</div>	
														</Tooltip.Trigger>	
														{targetDateLabel &&											 	
															<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
																Edit Target Date
															</Tooltip.Content>
														}																							
													</Tooltip.Root>
												</Tooltip.Provider>
											</div>
										</EditMetadataPopoverWrapper>		
						  		}
				  		</div>
				  		<div className='roadmapsProjectRow-rightContainer'>	  											
								{filteredRoadmaps.length > 0 && 
									<EditMetadataPopoverWrapper
										showPopover={showEditProjectRoadmapsPopover}
										openPopover={()=>{this.setState({showEditProjectRoadmapsPopover: true})}}
										closePopover={this.handleCloseEditProjectRoadmapsPopover}
										updateProjectRoadmaps={this.handleUpdateProjectRoadmaps}	
										type="projectRoadmaps"  // used to load in popover contents
										updatedProjectRoadmaps={this.state.roadmaps}
										layout="roadmapProjectRow" // used for positioning
										portalled
										projectId={project_id}						
									>
										<div className='roadmapsProjectRow-roadmapTagContainer'>
											<Tooltip.Provider>
												<Tooltip.Root delayDuration={150}>
													<Tooltip.Trigger asChild>																																										
														<button onClick={this.handleProjectRoadmapsClick} className='roadmapsProjectRow-roadmapTag'>
															<div className='roadmapsProjectRow-roadmapTag-iconContainer'>
																<Icon name='roadmapOutline' />
															</div>
															{filteredRoadmaps.map((roadmap,i)=>{
																const showComma=i<filteredRoadmaps.length-1
																return(
																	<div className='roadmapsProjectRow-roadmapTag-label' key={`roadmap_label_${roadmap.roadmap_id}`}>
																		{roadmap.name}
																		{showComma?',':''}
																	</div>
																)
															})}									
													</button>
													</Tooltip.Trigger>							
													{roadmaps.length > 0 &&		 	
														<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
															Edit Roadmaps
														</Tooltip.Content>																							
													}
												</Tooltip.Root>
											</Tooltip.Provider>
										</div>	
									</EditMetadataPopoverWrapper>	
								}
								<EditMetadataPopoverWrapper
									showPopover={showEditProjectLeadPopover}						
									openPopover={()=>{this.setState({showEditProjectLeadPopover: true})}}
									closePopover={()=>{this.setState({showEditProjectLeadPopover: false})}}		
									type="projectLead"  
									updateProjectLead={this.updateProjectLead}
									leadUserId={user.user_id}
									layout="roadmapProjectRow"
									projectId={this.props.projectId}	
									portalled			
								>
									<div className='roadmapsProjectRow-leadAvatarOuterContainer'>
										<Tooltip.Provider>
											<Tooltip.Root delayDuration={lead ? 150 : 0}>
												<Tooltip.Trigger asChild>																								
													<div onClick={this.handleProjectLeadClick} className={'roadmapsProjectRow-leadAvatarContainer ' + (lead ? ' roadmapsProjectRow-leadAvatarContainer--hasLead ' : ' roadmapsProjectRow-leadAvatarContainer--noLead ' )}>
									  				{lead && 
										  				<AvatarImage
										  					className='roadmapsProjectRow-leadAvatar'
										 						avatarUrl={user.avatar}
										 						smallAvatar
										 					/>
									 					}
									 					{!lead &&
										  				<Icon name='userAdd' />
									 					}
									  			</div>
												</Tooltip.Trigger>		
												{lead && 		
													<Tooltip.Content side="bottom" className="tooltip tooltip--projectLead">
														<div className="tooltip--projectLead-avatarContainer">
															<AvatarImage
									  						className='tooltip--projectLead-avatar'
									 							avatarUrl={user.avatar}
									 						/>
														</div>
														<div className="tooltip--projectLead-rightContainer forceDarkTheme">
															<div className="tooltip--projectLead-displayName">
																{user.display_name ? `${user.display_name}` : 'Unassigned'}
															</div>
															<div className="tooltip--projectLead-attribute">
																Project Lead
															</div>
														</div>								
													</Tooltip.Content>	
												}		
												{!lead && 		
													<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">
														Add Project Lead
													</Tooltip.Content>	
												}												
											</Tooltip.Root>
										</Tooltip.Provider>
									</div>
								</EditMetadataPopoverWrapper>
			  		</div>
			     </div>
			  </ContextMenu.Trigger>
	  		<ContextMenu.Portal>
	  			<ContextMenu.Content collisionPadding={10} className='contextMenu'>	        	       	        
		  			<RoadmapsProjectRowContextMenuContents 
		  				openEditProjectModal={this.props.openEditProjectModal}
		  				handleUpdateProjectMembers={this.handleUpdateProjectMembers}
		  				handleUpdateProjectTeams={this.handleUpdateProjectTeams}
		  				projectMembers={this.state.members}
		  				projectTeams={this.state.teams}
		  				project={project}
		  				handleUpdateProjectRoadmaps={this.handleUpdateProjectRoadmaps}
		  				projectRoadmaps={this.state.roadmaps}
		  			/>					
		  		</ContextMenu.Content>
			</ContextMenu.Portal>
		</ContextMenu.Root>		 

		{state === 'started' && 
			<RoadmapsProjectRowMilestones 
				projectId={project.project_id}
			/>
		}

		</div>
		</React.Fragment>
	  )
	}
}
export default withRouter(RoadmapsProjectRow)