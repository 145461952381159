import store from '../store'
import {archiveDoc} from '../actions/docs'
import {getDocForId} from '../utils/getDocForId'
import {openDoc} from './openDoc'
import {getRootDocIdForProject} from './getRootDocIdForProject'
//if navigateAfter then go to home after deleting (this happens if delete doc from doc editor)
export function deleteDoc(docId,history) {
	const docObj=getDocForId(docId)
	if(history){
			if(docObj.project){
				const rootDoc= getRootDocIdForProject(docObj.project)
				openDoc(history,rootDoc)
			}else if(docObj.roadmap){
				history.push(`/roadmaps/${docObj.roadmap}`)
			}else if(docObj.is_org_doc){
				history.push('/')
			}else {
				history.push('/private')
			}
		}
	store.dispatch(archiveDoc(docId))
		



			
		

}
