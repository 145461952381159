//milestones with the calcs

import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'UPDATE_CALCED_MILESTONES':
		return action.calcedMilestones

	case 'SIGN_OUT':
      return []
	
	default:
		return state
	}
}




