import store from '../../store'
import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import {getMilestonesForProject} from '../getMilestonesForProject'
import {getProjectConnectionsForMeeting} from '../meetings/getProjectConnectionsForMeeting'

export function getTeamProjectIssues(projectId,teamId) {	
	const state = store.getState()
	const issues= filter(state.issues, {project:`${projectId}`,team:`${teamId}`})
	return issues
}



//if project has milestones then group by milestone
//then its sort → status →  priority → A-Z

function getIssuesGroupedAndOrdered(issues,workflowStates){
	let sortedWorkflowStates=[]
	const startedWorkflowStates=sortBy(filter(workflowStates,{type:'started'}),'position').reverse()
	sortedWorkflowStates.push(...startedWorkflowStates)

	const unstartedWorkflowStates=sortBy(filter(workflowStates,{type:'unstarted'}),'position').reverse()
	sortedWorkflowStates.push(...unstartedWorkflowStates)

	const backlogWorkflowStates=sortBy(filter(workflowStates,{type:'backlog'}),'position').reverse()
	sortedWorkflowStates.push(...backlogWorkflowStates)

	const completedWorkflowStates=sortBy(filter(workflowStates,{type:'completed'}),'position').reverse()
	sortedWorkflowStates.push(...completedWorkflowStates)
	let groupedIssues={}
	let issueCount=0
	sortedWorkflowStates.forEach((state)=>{
		const stateIssues=filter(issues,{workflow_state:state.state_id})
		if(stateIssues.length>0){
			issueCount+=stateIssues.length
			const sortedIssues=sortBy(sortBy(stateIssues,'linear_created_at').reverse(),'priority')
			let sortedWithState=[]
			sortedIssues.forEach((issue)=>{
				const issueWithState={...issue,state:state}
				sortedWithState.push(issueWithState)
			})
			if(groupedIssues[state.name]){
				let newArray=[...groupedIssues[state.name]]
				newArray=newArray.concat(sortedWithState)
				const newSortedIssues=sortBy(sortBy(newArray,'linear_created_at').reverse(),'priority')
				groupedIssues[state.name]=newSortedIssues

			}else{
				groupedIssues[state.name]=sortedWithState
			}
		}
	})
	return {groupedIssues:groupedIssues,issueCount:issueCount}
}

export function getIssuePanelIssuesForProject(projectId,userId) {	 //ignores cancelled issues
	const state = store.getState()
	let filteredIssues=filter(state.issues, {project:`${projectId}`})
	
	if(userId){		//for user issue panels we only show priority 0 and 1 issues 
		if(projectId=='none'){
			filteredIssues=filter(state.issues,(issue)=>{
			if(!issue.project && issue.assignee==userId){
				if(issue.priority==1 || issue.priority==2){
					return issue
				}
			}
		})
		}else{
			filteredIssues=filter(state.issues,(issue)=>{
			if(issue.project==`${projectId}` && issue.assignee==userId){
				if(issue.priority==1 || issue.priority==2){
					return issue
				}
			}
		})
		}	
	}

	let issues=[]
	filteredIssues.forEach((issue)=>{
		const workflow_state=find(state.workflowStates,{state_id:issue.workflow_state})
		if(userId){//don't show completed for user issue panels
			if(workflow_state.type!=='canceled' && workflow_state.type!=='completed' ){
			issues.push(issue)
		}
		}else{
			if(workflow_state.type!=='canceled'){
				issues.push(issue)
			}
		}
	})

	//const issues= filter(state.issues, {project:`${projectId}`})
	const workflowStates = state.workflowStates
	const milestones=getMilestonesForProject(projectId)
	let issuesByMilestone=[]
	milestones.forEach((milestone)=>{
		const milestoneIssues=filter(issues,{milestone:milestone.milestone_id})
		const groupedMilestoneIssuesAndCount=getIssuesGroupedAndOrdered(milestoneIssues,workflowStates)
		const groupedMilestoneIssues=groupedMilestoneIssuesAndCount.groupedIssues
		const count=groupedMilestoneIssuesAndCount.issueCount
		const obj={
			milestone:milestone,
			issues:groupedMilestoneIssues,
			issueCount:count
		}
		issuesByMilestone.push(obj)
	})
	const issuesNotInMilestones = filter(issues, function(issue) { 
		if(!issue.milestone){
			return issue
		}
	})
	 const groupedNonMilestoneIssues=getIssuesGroupedAndOrdered(issuesNotInMilestones,workflowStates)
	 const obj={
	 	milestone:'none',
	 	issues:groupedNonMilestoneIssues.groupedIssues,
	 	issueCount:groupedNonMilestoneIssues.issueCount
	 }
	issuesByMilestone.push(obj)
	return {[projectId]:{groupedIssuesArray:issuesByMilestone,totalCount:issues.length}}
}



export function getMeetingIssuesByType(meetingId) {	 //ignores cancelled issues
	console.log('get meeting issues by type!!!')
	console.log(meetingId)
	const connections = getProjectConnectionsForMeeting(meetingId)
	let projects=[]
	connections.forEach((connection)=>{
		projects.push(connection.project)
	})
	console.log(projects)
	let meetingIssues={}
	projects.forEach((projectId)=>{
		const issues=getProjectIssuesByType(projectId)
		meetingIssues[projectId]=issues
	})

	console.log(meetingIssues)
	return meetingIssues

	// const state = store.getState()
	// const issues= filter(state.issues, {project:`${projectId}`})
	// const workflowStates = state.workflowStates
	// const milestones=getMilestonesForProject(projectId)
	// let issuesByMilestone=[]
	// milestones.forEach((milestone)=>{
	// 	const milestoneIssues=filter(issues,{milestone:milestone.milestone_id})
	// 	const groupedMilestoneIssuesAndCount=getIssuesGroupedAndOrdered(milestoneIssues,workflowStates)
	// 	const groupedMilestoneIssues=groupedMilestoneIssuesAndCount.groupedIssues
	// 	const count=groupedMilestoneIssuesAndCount.issueCount
	// 	const obj={
	// 		milestone:milestone,
	// 		issues:groupedMilestoneIssues,
	// 		issueCount:count
	// 	}
	// 	issuesByMilestone.push(obj)
	// })
	// const issuesNotInMilestones = filter(issues, function(issue) { 
	// 	if(!issue.milestone){
	// 		return issue
	// 	}
	// })
	//  const groupedNonMilestoneIssues=getIssuesGroupedAndOrdered(issuesNotInMilestones,workflowStates)
	//  const obj={
	//  	milestone:'none',
	//  	issues:groupedNonMilestoneIssues.groupedIssues,
	//  	issueCount:groupedNonMilestoneIssues.issueCount
	//  }
	// issuesByMilestone.push(obj)
	// return {groupedIssuesArray:issuesByMilestone,totalCount:issues.length}
}




