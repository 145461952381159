import React from 'react'
import Icon from '../../misc/Icon'
import MeetingTypeIcon from '../../misc/MeetingTypeIcon'
import StaticCheckbox from '../../uiKit/StaticCheckbox'
import * as Tooltip from '@radix-ui/react-tooltip';
import {showDocTemplatesModal} from '../../../utils/templates/showDocTemplatesModal'
import {setDocTemplate} from '../../../utils/templates/setDocTemplate'
import {getInDocTemplateOptions} from '../../../utils/templates/getInDocTemplateOptions'
import {applyTemplate} from '../../../utils/templates/applyTemplate'
import {addDocLinkToLinear} from '../../../utils/addDocLinkToLinear'
import {applyTemplateToMeeting} from '../../../utils/meetings/applyTemplateToMeeting'
import findIndex from 'lodash/findIndex'
import DocEditorTemplatePreviewPopover from './DocEditorTemplatePreviewPopover'

class DocEditorDocTemplateSelectBtn extends React.Component{  
	
	constructor(props){
    super(props)       
    this.handleMouseOver=this.handleMouseOver.bind(this)
  }

  handleMouseOver() {
		if(this.props.mouseHasMoved){
			this.props.handleSetActiveTemplate(this.props.template)
		}
	}

	render(){	
		const {active, label,template,isMeeting, mouseHasMoved} = this.props		
		const emptyPageBtn = template.label === 'Blank'
		const openModalBtn = template.label === 'Modal'
		let btnLabel = label
		if(emptyPageBtn){
			if(isMeeting){
				btnLabel = 'Empty page'
			}else{
				btnLabel = 'Empty page'
			}
		}
		if(openModalBtn){
			btnLabel = 'All Templates'
		}
		let meetingType
		if(isMeeting){
			if(label === 'Discovery Call'){
				meetingType = 'discovery'
			}else if(label === 'Design Review'){
				meetingType = 'designReview'
			}else if(label === 'Team Sync'){
				meetingType = 'teamSync'
			}
		}
		const suggested = btnLabel === 'Team Sync' && this.props.index === 0
	  return (	  	
			<button onMouseOver={this.handleMouseOver} onClick={()=>{this.props.handleSelectTemplate(template)}} className={'docEditor-docTemplateSelect-button ' + (active ? ' docEditor-docTemplateSelect-button--active ' : ' docEditor-docTemplateSelect-button--inactive ') + (suggested ? ' docEditor-docTemplateSelect-button--suggested ' : ' ') + (openModalBtn ? ' docEditor-docTemplateSelect-button--openModalBtn ' : ' ') + (emptyPageBtn ? ' docEditor-docTemplateSelect-button--emptyPage ' : ' ')}>
				{openModalBtn && 
					<div className='docEditor-docTemplateSelect-button-iconContainer'>
						<Icon name='template' />
					</div>
				}
				{meetingType &&
					<div className='docEditor-docTemplateSelect-button-iconContainer'>
						<MeetingTypeIcon meetingType={meetingType} />
					</div>
				}
				{suggested && 
					<div className='docEditor-docTemplateSelect-button-suggestedIconContainer'>
						<Icon name='wand' />
					</div>
				}
				<div className='docEditor-docTemplateSelect-button-label'>
					{btnLabel}
				</div>				
				{active && 
					<div className='docEditor-docTemplateSelect-button-enterIcon'>
						<Icon name='enterKey' />
					</div>
				}
				{!isMeeting && mouseHasMoved &&
					<DocEditorTemplatePreviewPopover
						template={template}
						showPopover={active && !emptyPageBtn && !openModalBtn}
					>
					<div className='docEditor-docTemplateSelect-button-popoverTrigger' />
					</DocEditorTemplatePreviewPopover>
				}
			</button>		  				 
	  )
	}
}


class DocEditorTemplateSelection extends React.PureComponent{  

	constructor(props){
    super(props)   
    this.handleKeyDown=this.handleKeyDown.bind(this)    
    this.handleMouseMove=this.handleMouseMove.bind(this)    
    this.handleSelectTemplate=this.handleSelectTemplate.bind(this)   
    this.handleSetActiveTemplate=this.handleSetActiveTemplate.bind(this)  
    const isMeeting=props.meeting?true:false
    let isRecurring = false 
    if(props.meeting && props.meeting.recurring_event){
    	isRecurring=true
    }
    let templateOptions = getInDocTemplateOptions(props.docType,isMeeting,isRecurring)
    let activeIndex=0 
    if(isMeeting){
    	activeIndex=null
    }
    this.state={
      activeIndex:activeIndex,
      addLinkToLinear: true,
      templates:templateOptions,
      isMeeting:isMeeting,
      mouseHasMoved: false,
    }
  }

  componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)		
	}
	
	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleMouseMove() {
		this.setState({mouseHasMoved: true})
	}

	handleKeyDown(e){
		if(!this.props.templateModalVisible){
			let focused=false
			if(window.view){
				focused=window.view.hasFocus()
			}
			//check if doc title input is focused and if not then prevent default so doesnt type in doc
			const titleInput = document.getElementById('titleInput')
			const templateTitleInput = document.getElementById('templateTitleInput')
			const openDocMenuInput=document.getElementById('openDocMenu-input-input')
			const projectTitleInput=document.getElementById('project-title-input')
			const projectDescriptionInput=document.getElementById('project-description-input')
			const participantPopoverInput=document.getElementById('editMeetingParticipantsPopover-search')
			const templateDescriptionInput = document.getElementById('templateDescriptionInput')
			let isInProjectModal=false
			var classNames = document.activeElement.className.split(' ');
			if(classNames.includes('project')) { //HACKY do this properly
	  		isInProjectModal=true
			} 
			if(classNames.some(className => className.includes('Participants'))) {
	  		isInProjectModal = true;
			}
			if(!isInProjectModal && document.activeElement !== projectTitleInput && document.activeElement !== projectDescriptionInput && document.activeElement !== openDocMenuInput && document.activeElement !== titleInput && document.activeElement!== templateTitleInput && document.activeElement!== templateDescriptionInput) {
	    	e.preventDefault();
	    	e.stopPropagation();
		  	const {templates}=this.state
				if(e.keyCode==40){ //down arrow
					e.preventDefault()
					e.stopPropagation();
					const {activeIndex}=this.state
					let newIndex=null
					if(activeIndex || activeIndex==0){
						if(activeIndex==templates.length-1){
							newIndex=0
						}else{
							newIndex=activeIndex+1
						}
					}else{ //for null initial state on meetings
						newIndex=0
					}
				this.setState({activeIndex:newIndex})
			}else if(e.keyCode==38){ //UP arrow
				e.preventDefault()
				e.stopPropagation();
				const {activeIndex}=this.state
				let newIndex=null
				if(activeIndex){
					if(activeIndex==0){
						newIndex=templates.length-1
					}else{
						newIndex=activeIndex-1
					}
				}else{
					newIndex=templates.length-1
				}
				this.setState({activeIndex:newIndex})
			}else if(e.keyCode==13){ //enterKey
				e.preventDefault()
				e.stopPropagation();
				const {activeIndex}=this.state
				let activeTemplate
				if(!activeIndex && activeIndex!=0){
						activeTemplate=templates[3] //blank
				}else{
					activeTemplate=templates[activeIndex]
				}
				this.handleSelectTemplate(activeTemplate)
			}
		}
	}
	}

	handleSelectTemplate(template){
		const {isMeeting} = this.state
		if(!isMeeting){
			if(template.template=='modal'){
				let addLink=this.props.isProjectDoc && this.state.addLinkToLinear
				showDocTemplatesModal(this.props.docId,addLink)
			}else{
				applyTemplate(template,this.props.docId)
				if(this.state.addLinkToLinear && this.props.isProjectDoc){
					addDocLinkToLinear(this.props.docId)
				}
			}
		}else{
			let meetingType='blank'
			if(template && template.name=='Discovery Call'){
				meetingType='discovery'
			}else if(template && template.name=='Design Review'){
				meetingType='designReview'
			}else if(template && template.name=='Team Sync'){
				meetingType='teamSync'
			}
			applyTemplateToMeeting(template,this.props.docId,this.props.meeting,meetingType)
		}
		
	}

	handleSetActiveTemplate(template){
		const index = findIndex(this.state.templates,{template_id:template.template_id})
		this.setState({activeIndex:index})
	}

	render(){	
		const {meeting,docId,isProjectDoc} = this.props
		const {addLinkToLinear,templates} = this.state

		const hideMeetingInstruction = this.state.activeIndex > 0 || this.state.activeIndex === 0 // enter instructions gets weird

	  return (
	  	<div id={'template-selection'} onMouseMove={this.handleMouseMove} className='docEditor-docTemplateSelectContainer'>
		  	<div className={'docEditor-docTemplateSelect ' + (this.state.mouseHasMoved ? ' docEditor-docTemplateSelect--mouseHasMoved ' : '')}>
		  		{meeting &&
		  			<div className={'docEditor-docTemplateSelect-button docEditor-docTemplateSelect-button--instruction ' + (hideMeetingInstruction ? ' docEditor-docTemplateSelect-button--instruction--hide ' : ' docEditor-docTemplateSelect-button--instruction--show ')}>
		  				<div className='docEditor-docTemplateSelect-button-label'>
		  					Press Enter to continue with an empty page, or pick a template <span className='docEditor-docTemplateSelect-button-label-subIconContainer'><Icon name='upArrowMedium'/></span><span className='docEditor-docTemplateSelect-button-label-subIconContainer docEditor-docTemplateSelect-button-label-subIconContainer--flip'><Icon name='upArrowMedium'/></span>
		  				</div>
		  			</div>
		  		}


		  		{templates.map((template,i)=>{
		  			const active=this.state.activeIndex==i
		  			return(
			  			<DocEditorDocTemplateSelectBtn 	
			  				key={`template_${i}`}
			  				index={i}
			  				active={active}	  			
			  				label={template.label || template.name}  	
			  				template={template}		
			  				handleSelectTemplate={this.handleSelectTemplate}
			  				docId={docId}
			  				handleSetActiveTemplate={this.handleSetActiveTemplate}
			  				isMeeting={this.state.isMeeting}
			  				mouseHasMoved={this.state.mouseHasMoved}
			  			/>
		  				)
		  			})}		 		  	


		  		<div className='docEditor-docTemplateSelect-button-previewContainer'>
		  			<div className='docEditor-docTemplateSelect-button-preview'>

		  			</div>
					</div>

		  		<div className='docEditor-docTemplateSelect-vSpacer'/>
		  		{isProjectDoc && 
			  		<div className='docEditor-docTemplateSelect-addLinkToLinearBtnContainer'>
			  			<Tooltip.Provider>
								<Tooltip.Root delayDuration={200}>
									<Tooltip.Trigger asChild>								
						  			<button data-state={addLinkToLinear ? "checked" : "unchecked"} onClick={()=>{this.setState({addLinkToLinear: !addLinkToLinear})}} className='docEditor-docTemplateSelect-addLinkToLinearBtn'>
						  				<div className='docEditor-docTemplateSelect-addLinkToLinearBtn-checkBoxContainer'>
						  					<StaticCheckbox 
						  						checked={addLinkToLinear}
						  					/>
						  				</div>
						  				<div className='docEditor-docTemplateSelect-addLinkToLinearBtn-label'>
												Add link in Linear
						  				</div>
						  			</button>
									</Tooltip.Trigger>				
									<Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI tooltip--addToLinear">																																	
										Add project doc link to links section in Linear project page.								
									</Tooltip.Content>													
								</Tooltip.Root>
							</Tooltip.Provider>			
		  			</div>
		  		}
		  	</div>			
			</div>		
	  )
	}
}



export default DocEditorTemplateSelection
