import store from '../store'
import find from 'lodash/find'



export function getMilestoneForId(milestoneId) {	
	const state = store.getState()
	const milestone= find(state.projectMilestones, {'milestone_id':`${milestoneId}`})
	return milestone
}

