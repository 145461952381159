import React from 'react'
import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import moment from 'moment'
import {getIssuesForRoadmap} from '../../utils/getIssuesForRoadmap'
import {recalcHeatmaps} from '../../utils/calculateHeatmapForRoadmap'


class RoadmapsHomePageGridItemActivity extends React.PureComponent{

	componentDidMount(){
		//recalcHeatmaps() //recalc to make sure up to date
	}

	render(){	
		const {roadmap,id,activeStatus}=this.props	
		const {heatmap}=this.props
		const startDate=moment().subtract(6,'months')
		const endDate=moment()
	  return (	  	
			<div className='roadmapsHomePage-roadmapGrid-item-activityContainer'>  			
				{heatmap &&
					<CalendarHeatmap
						startDate={startDate}
						endDate={endDate}
						values={heatmap}
						gutterSize={2}
						classForValue={(value) => {
						if (!value) {
						return 'color-empty';
						}
						return `color-scale-${value.count}`;
					}}
					/>
			}
			</div>
  		
	  )
	}
}


function mapStateToProps(state,ownProps) {
	const heatmaps=state.heatmaps
	const roadmapHeatmap=heatmaps[ownProps.roadmap.roadmap_id]
	return {
		heatmap:roadmapHeatmap,
	}
}

export default connect(mapStateToProps,
	{	
	}
)(RoadmapsHomePageGridItemActivity)


