import React from 'react'
import ReactDOM from 'react-dom'
import MessageLinearIssueMentionReactComponent from './MessageLinearIssueMentionReactComponent'


const OPEN_POPOVER_DELAY=500

class MessageLinearIssueMentionNodeView {

	constructor(node, view, getPos) {
		this.node=node
		this.openPopover=this.openPopover.bind(this)
		this.closePopover=this.closePopover.bind(this)
		this.selectNode=this.selectNode.bind(this)
		this.deselectNode=this.deselectNode.bind(this)
		this.renderReactComponent=this.renderReactComponent.bind(this)

		this.dom = document.createElement("div")
		this.dom.className="message-para-linearIssueOuterContainer "
		const {layout,issueId,issueUpdateVersion}=node.attrs //layout is block or inline
		// if(layout==='block'){
		// 	this.dom.classList.add('doc-para-linearIssueOuterContainer--block')
		// }else{
		// 	this.dom.classList.add('doc-para-linearIssueOuterContainer--inline')
		// }

		this.showPopover=false

		this.timeout=null
		this.renderReactComponent()
	}

	renderReactComponent(){
		const {layout,issueId,issueUpdateVersion}=this.node.attrs
		ReactDOM.render(
			<MessageLinearIssueMentionReactComponent 
				issueId={issueId}
				issueLayout={layout}
				issueUpdateVersion={issueUpdateVersion}
				key={issueUpdateVersion}
				showPopover={this.showPopover}
				openPopover={this.openPopover}
				closePopover={this.closePopover}
			/>, this.dom)
	}

	update(node) {
		if (node.type !== this.node.type) return false
		const {layout,issueId,issueUpdateVersion}=node.attrs
		// if(layout==='block'){
		// 	this.dom.classList.add('doc-para-linearIssueOuterContainer--block')
		// }else{
		// 	this.dom.classList.add('doc-para-linearIssueOuterContainer--inline')
		// }
		this.node=node
		this.renderReactComponent()
		return true
	}

	openPopover(){
		this.timeout=setTimeout(function() {
			this.showPopover=true
			this.renderReactComponent()
		}.bind(this), OPEN_POPOVER_DELAY)
	}

	closePopover(){
		if(this.timeout){
			clearTimeout(this.timeout)
		}
		if(this.showPopover){
			this.showPopover=false
			this.renderReactComponent()
		}
	}

	selectNode() {		
		this.openPopover()
		this.dom.classList.add('ProseMirror-selectednode')
  }

  deselectNode() {
  	this.closePopover()
  	this.dom.classList.remove('ProseMirror-selectednode')
  }

	destroy() {
		if(this.timeout){
			clearTimeout(this.timeout)
		}
		ReactDOM.unmountComponentAtNode(this.dom)
	}

	stopEvent(event){ 
		return true
	}


}

export default MessageLinearIssueMentionNodeView