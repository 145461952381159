import React from 'react'
import Icon from '../../misc/Icon'
import filter from 'lodash/filter'
import find from 'lodash/find'

class MessageReactionsPanelReaction extends React.Component{  

	render(){			

		const {count,reactions,userId,docId,type} = this.props
		// example method for showing if userHasReacted (i.e. it goes blue)
		const userReaction = find(reactions,{created_by:userId})
		if(userReaction){
			return (	  				
				<button onClick={()=>this.props.deleteReaction(userReaction['reaction_id'],docId)} className={'message-reactionsPanel-reaction ' + (userReaction ? ' message-reactionsPanel-reaction--userHasReacted ' : '')}>
					<div className='message-reactionsPanel-reaction-emoji'>    
						{this.props.emoji}
					</div>
					<div className='message-reactionsPanel-reaction-count'>    
						{count}
					</div>							
				</button>
			)
		}else {
			return (	  				
				<button onClick={()=>{this.props.addReactionToMessage(type,this.props.messageId,docId)}} className={'message-reactionsPanel-reaction ' + (userReaction ? ' message-reactionsPanel-reaction--userHasReacted ' : '')}>
					<div className='message-reactionsPanel-reaction-emoji'>    
						{this.props.emoji}
					</div>
					<div className='message-reactionsPanel-reaction-count'>    
						{count}
					</div>							
				</button>
			)
		}
	}
}
 

class MessageReactionsPanel extends React.Component{  
	render(){
		const {messageReactions,userId,docId,messageId,reactionDefinitions}=this.props 
		const reactionTypeLike=filter(messageReactions,{reaction_type:'like'})
		const reactionTypeFire=filter(messageReactions,{reaction_type:'fire'})
		const reactionTypeBullseye=filter(messageReactions,{reaction_type:'bullseye'})
		return (	  	
			<div className='message-reactionsPanel'>
			{reactionDefinitions.map((definition)=>{
				const reactionsOfType=filter(messageReactions,{reaction_type:definition.type})
				if(reactionsOfType.length>0){
					return(
						<MessageReactionsPanelReaction 
							key={definition.emoji}
							emoji={definition.emoji}		
							count={reactionsOfType.length}
							reactions={reactionsOfType}
							userId={userId}
							docId={docId}
							deleteReaction={this.props.deleteReaction}
							type={definition.type}
							addReactionToMessage={this.props.addReactionToMessage}
							messageId={messageId}
						/>
						)
				}else return null
  			})}				
				<button onClick={this.props.hideReactions} className='message-reactionsPanel-addEmojiBtn'>
					<Icon name='insertEmoji' />
				</button>
			</div>
		)
	}
}
 
export default MessageReactionsPanel