import React from 'react'
import Icon from '../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import sortBy from 'lodash/sortBy'
import {updateRoadmapsHomePageSetting} from '../../utils/updateRoadmapsHomePageSetting'
import RoadmapsHomePageGridItem from './RoadmapsHomePageGridItem'
// import RoadmapsHomePageGridLargeItem from './RoadmapsHomePageGridLargeItem'
// import RoadmapsHomePageGridMediumItem from './RoadmapsHomePageGridMediumItem'
// import RoadmapsHomePageGridSmallItem from './RoadmapsHomePageGridSmallItem'

// // e.g. not quarterly roadmaps
// const areaRoadmaps = [  
//   { title: 'Collab',  		id:'3'},
//   // { title: 'Planning',  	id:'11'}, // not enough space 
//   { title: 'Core Editor', id:'4'},
//   { title: 'CX', 					id:'2'},
//   { title: 'GTM', 				id:'13'},
//   //{ title: 'Tech', 				id:'1'},    
// ];


class RoadmapsHomePageGridViewSelectButton extends React.Component{  	

	render(){		
		const {active, status} = this.props
		let label = status
		if(status === 'started'){
			label = 'In Progress'
		}
		   	
	  return (	  	
			<button onClick={this.props.makeActive} className={'roadmapsHomePage-roadmapGrid-header-viewSelect-btn ' + (active ? ' roadmapsHomePage-roadmapGrid-header-viewSelect-btn--active ' : ' roadmapsHomePage-roadmapGrid-header-viewSelect-btn--inactive ') + (status ? ` roadmapsHomePage-roadmapGrid-header-viewSelect-btn--status--${status} ` : '')}>			
				{status === 'activity' &&
					<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon'>
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
						<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
					</div>
				}
				<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-label'>
					{label}
				</div>
			</button>
		  		  	
	  )
	}
}


class RoadmapsHomePageGrid extends React.Component{  	


	updateActiveStatus(status){
		updateRoadmapsHomePageSetting('status',status)
	}

	render(){		
		const {roadmaps,quarterRoadmapId,activeStatus}=this.props		


		const sortedRoadmaps = sortBy(roadmaps, function(roadmap){
 			return roadmap.name.toString().toLowerCase()
  	})
		// const sortedAreaRoadmaps = areaRoadmaps.sort((a, b) => a.title.localeCompare(b.title));
	  return (
	  	<React.Fragment>									
	  		<div className='roadmapsHomePage-roadmapGrid'>	  			
	  			<div className='roadmapsHomePage-roadmapGrid-header'>
		  			<div className='roadmapsHomePage-roadmapGrid-header-label'>
		  				Roadmaps
		  			</div>
		  			<div className='roadmapsHomePage-roadmapGrid-header-viewSelect'>
		  				
		  				<RoadmapsHomePageGridViewSelectButton 
		  					status='completed'
		  					active={activeStatus === 'completed'}
		  					makeActive={()=>{this.updateActiveStatus('completed')}}		  				
		  				/>
		  				<RoadmapsHomePageGridViewSelectButton 
		  					status='started'
		  					active={activeStatus === 'started'}
		  					makeActive={()=>{this.updateActiveStatus('started')}}			  					  				
		  				/>
		  				
		  				<RoadmapsHomePageGridViewSelectButton 
		  					status='planned'
		  					active={activeStatus === 'planned'}
		  					makeActive={()=>{this.updateActiveStatus('planned')}}	  					
		  				/>
		  				<RoadmapsHomePageGridViewSelectButton 
		  					status='backlog'
		  					active={activeStatus === 'backlog'}
		  					makeActive={()=>{this.updateActiveStatus('backlog')}}	  	  					
		  				/>
		  				<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-spacer' />
		  				<Tooltip.Provider>
		  					<Tooltip.Root delayDuration={0}>
				  				<Tooltip.Trigger asChild>		  									  				
					  				<button onClick={()=>{this.updateActiveStatus('activity')}} className={'roadmapsHomePage-roadmapGrid-header-viewSelect-btn roadmapsHomePage-roadmapGrid-header-viewSelect-btn--status--activity ' + (activeStatus === 'activity' ? ' roadmapsHomePage-roadmapGrid-header-viewSelect-btn--active ' : ' roadmapsHomePage-roadmapGrid-header-viewSelect-btn--inactive ')}>														
											<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon'>
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
												<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-activityIcon-square' />
											</div>											
											<div className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn-label'>
												Activity
											</div>
										</button>
					  			</Tooltip.Trigger>
					  			<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI tooltip--activityRoadmap"> 
										Completed issues over last 6 months
									</Tooltip.Content>													
								</Tooltip.Root>
							</Tooltip.Provider>
			  			{/*}
		  				<button className='roadmapsHomePage-roadmapGrid-header-viewSelect-btn roadmapsHomePage-roadmapGrid-header-viewSelect-btn--iconOnly roadmapsHomePage-roadmapGrid-header-viewSelect-btn--inactive'>			
		  					<Icon name='ellipses' />
		  				</button>
		  				*/}
		  			</div>
	  			</div>

	  			<div className={'roadmapsHomePage-roadmapGrid-grid ' + (activeStatus === 'activity' ? ' roadmapsHomePage-roadmapGrid-grid--activity ' : ' roadmapsHomePage-roadmapGrid-grid--projectStatus ')}>  			
	  			 {sortedRoadmaps.map((roadmap) => {	  			 	
	  			 		return(
 							<RoadmapsHomePageGridItem
			          key={roadmap.roadmap_id}
			          roadmap={roadmap}
			          activeStatus={activeStatus}
			          //hasCurrentQuarterRoadmap={quarterRoadmapId ? true : false}
			        />
	  			 	)	  			 	
	  			 }
	  			 )}

	  			</div>
	  		</div>
	  	</React.Fragment>
	  )
	}
}

export default RoadmapsHomePageGrid


{/*
{roadmaps.map((roadmap)=>{

						return(																	
							<RoadmapsHomePageRoadmapGridItem 
								roadmap={roadmap}
								key={`roadmap_grid_${roadmap.roadmap_id}`}
								
							/>									
						)
					})}						

*/}