import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as Dialog from '@radix-ui/react-dialog';
import debounce from 'lodash/debounce'


import DocDrawingTopRightMenu from '../docMediaUIComponents/DocDrawingTopRightMenu'

import DocDrawingZoomedModal from '../../docEditor/mediaModals/DocDrawingZoomedModal'
import DocImageReactionBar from '../docMediaUIComponents/DocImageReactionBar'

import DocDrawingTopLeftMenu from '../docMediaUIComponents/DocDrawingTopLeftMenu'

import InsertDrawingModal from '../../docEditor/mediaModals/InsertDrawingModal'

const MOUSE_MOVE_DEBOUNCE_TIME = 3000


const DRAWING_DOC_WIDTH = 720 // refactor to centralized place


function createMarkup(input) {
  return {__html: input}
}

class DocDrawingReactComponent extends React.Component{  
 
  constructor(){

    super()
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.hideMouseMoveControls=this.hideMouseMoveControls.bind(this)
    this.handleImageClick=this.handleImageClick.bind(this)
    this.toggleZoom=this.toggleZoom.bind(this)
    this.openEditDrawingModal=this.openEditDrawingModal.bind(this)
    this.hideMouseMoveControls=debounce(this.hideMouseMoveControls,MOUSE_MOVE_DEBOUNCE_TIME)
    this.state={
      zoomed: false,  
      mouseMoved:false,
      showEditDrawingModal:false
    }
  }

  handleMouseMove(){    
    this.setState({mouseMoved:true})
    this.hideMouseMoveControls()
  }

  hideMouseMoveControls(){
    this.setState({mouseMoved:false})
  }

  toggleZoom(e){
    this.setState({ zoomed: !this.state.zoomed })
  }

  handleImageClick(e){
    if(draftMode === false){
    	this.toggleZoom()
    }
  }

	openEditDrawingModal(){
		this.setState({showEditDrawingModal:true})
	}


	render(){ 
		
		const {zoomed, mouseMoved,showEditDrawingModal} = this.state
		//const {deliveryUrl,height,width,title,description}=this.props
		const {svg,height,width}=this.props

		const drawingWidth = DRAWING_DOC_WIDTH
		const drawingHeight = DRAWING_DOC_WIDTH / (width / height) // fit

	

		return (
			<React.Fragment>
				{/*<button onClick={this.openEditDrawingModal}>edit drawing</button> */}
				<div onClick={this.openEditDrawingModal} onDoubleClick={this.openEditDrawingModal} onMouseMove={this.handleMouseMove} style={{width: `${drawingWidth}px`, height: `${drawingHeight}px`}} className={'doc-drawingContainer' + ' doc-drawingContainer--defaultMode ' + (mouseMoved ? ' doc-drawingContainer--mouseMoved ' : ' ') + (zoomed ? ' doc-drawingContainer--zoomed ' : ' doc-drawingContainer--inline ')}>
					<div className='doc-drawing'>
						<div dangerouslySetInnerHTML={createMarkup(svg)} className='doc-drawing-svgContainer' />
						<DocDrawingTopRightMenu 
							addComment={this.props.addImageComment}
							deleteImage={this.props.deleteImage}
						/>
					</div>
				</div>


				<DocDrawingZoomedModal 
					zoomed={showEditDrawingModal}
					unzoom={()=>{this.setState({showEditDrawingModal: false})}}
					isEdit={true} 
      		tlDrawDoc={this.props.tlDrawDoc}
      		insertImage={this.props.insertImage}
      		closeModal={()=>{this.setState({showEditDrawingModal:false})}}
      		updateDrawing={this.props.updateDrawing}
				/>



				{/*}
					<Dialog.Root id='insertImageModal' open={this.state.showEditDrawingModal}>
			    <Dialog.Portal>
			      <Dialog.Overlay className='dialogOverlay'>
				      <Dialog.Content 
				      	className='dialog'
				      	onEscapeKeyDown={()=>{this.setState({showEditDrawingModal:false})}}
				      	onPointerDownOutside={()=>{this.setState({showEditDrawingModal:false})}}
				      	onInteractOutside={()=>{this.setState({showEditDrawingModal:false})}}
				      	>
				      	<InsertDrawingModal
				      		isEdit={true} 
				      		tlDrawDoc={this.props.tlDrawDoc}
				      		insertImage={this.props.insertImage}
				      		closeModal={()=>{this.setState({showEditDrawingModal:false})}}
				      		updateDrawing={this.props.updateDrawing}
				      	/>					        
				      </Dialog.Content>
			      </Dialog.Overlay>
			    </Dialog.Portal>
			  </Dialog.Root>
				*/}
				


			</React.Fragment>
		)
	}
}

export default DocDrawingReactComponent


			// 	<div style={{width: `${imageWidth}px`, height: `${imageHeight}px`}} className={'doc-drawingContainer' + (readOnlyMode ? ' doc-drawingContainer--readOnlyMode ' : ' doc-drawingContainer--defaultMode ') + (mouseMoved ? ' doc-drawingContainer--mouseMoved ' : ' ') + (zoomed ? ' doc-drawingContainer--zoomed ' : ' doc-drawingContainer--inline ')} onMouseMove={this.handleMouseMove}>
			// 		<div onClick={()=>{console.log('focus image')}} onDoubleClick={this.toggleZoom} className='doc-image'>
			// 			{!readOnlyMode &&
			// 				<DocDrawingTopLeftMenu 
			// 					readOnlyMode={readOnlyMode}
			// 					edit={this.toggleZoom}	
			// 				/>
			// 			}
			// 			<DocImageTopRightMenu 
			// 				readOnlyMode={readOnlyMode}
			// 				addComment={this.props.addImageComment}
			// 				deleteImage={this.props.deleteImage}
			// 			/>
			// 			<img 
			// 				alt=''
			// 				draggable="false"
			// 				src={deliveryUrl}
			// 				className='doc-image-image'
			// 			/>											
			// 		</div>						
			// 		{!readOnlyMode &&
			// 			<DocImageReactionBar 
			// 				readOnlyMode={readOnlyMode}
			// 			/>
			// 		}
			// </div>

			// {readOnlyMode &&
			// 	<DocImageReactionBar 
			// 		readOnlyMode={readOnlyMode}
			// 	/>
			// }

			// <DocDrawingZoomedModal 
			// 	zoomed={zoomed}
			// 	toggleZoom={this.toggleZoom}
			// 	unzoom={()=>{this.setState({zoomed: false})}}
			// 	imageSrc={deliveryUrl}
			// 	imageTitle={title}
			// />
