import React from 'react'
import Icon from '../misc/Icon'
import { Spring, animated, config } from 'react-spring'

class ToDoItemAltCheckbox extends React.Component{  	

	constructor(){
    super()    
    this.state={      
      mouseDown: false,
    }
  }

 //  handleMouseUp(e){				
	// 	this.setState({mouseDown:false})

	// 	// setTimeout(function() {
 //  //  		this.setState({mouseDown:false})
 // 	// 	}.bind(this), 500)
	// }

	render(){	

		const {isChecked, toggleIsChecked} = this.props
		const {mouseDown} = this.state	



	  return (
	  		  			  			
				<React.Fragment>

  				<Spring transform={mouseDown ? 'scale(1.2)' : 'scale(1)'} >
            {checkboxContainerStyles =>
              <animated.div style={checkboxContainerStyles} onMouseDown={()=>{this.setState({mouseDown: true})}} onMouseUp={()=>{this.setState({mouseDown: false})}} onClick={this.props.toggleIsChecked} className='toDoItemAlt-checkboxContainer'>
			  				
			  				<Spring 
			  					backgroundColor={isChecked ? 'rgb(26, 129, 255)' : 'rgb(255,255,255)'}
			  					borderColor={isChecked ? 'rgb(0, 103, 230)' : 'rgb(191, 191, 197)'}
			  				>
		              {checkboxStyles => 
		                <animated.div style={checkboxStyles} className={'toDoItemAlt-checkbox ' + (isChecked ? ' toDoItemAlt-checkbox--state--checked ' : ' toDoItemAlt-checkbox--state--unchecked ')}>
		                	{isChecked && <Icon name='checkmarkBlack' /> }							  								  		
		                </animated.div>
		              }
		            </Spring>
              	

						  	<Spring 
						  			opacity={(!isChecked && mouseDown) ? 0.5 : 0} 
						  			transform={(!isChecked && mouseDown) ? 'scale(1.2)' : 'scale(1)'} 
						  		>
			              {checkboxOutlineStyles => 
			                <animated.div style={checkboxOutlineStyles} className='toDoItemAlt-checkbox-outline' />				                	
			              }
			            </Spring>
						  	
				  		</animated.div>
            }
          </Spring>

          <Spring background={mouseDown ? 'rgba(242,242,242,1)' :'rgba(242,242,242,0)'} >
            {labelStyles => 
              <animated.div style={labelStyles} className='toDoItemAlt-checkboxOuterContainer-mouseDownBG' />
            }
        	</Spring>

        	<Spring background={isChecked ? 'rgba(255,255,255,0.5)' : 'rgba(255,255,255,0)'} >
            {specialStyles => 
              <animated.div style={specialStyles} className='toDoItemAlt-checkboxOuterContainer-checkedOpacityFake' />
            }
        	</Spring>

				</React.Fragment>        
			
	  )
	}
}

export default ToDoItemAltCheckbox