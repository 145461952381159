import React from 'react'
import Icon from '../../misc/Icon'

class DocTableEditRowColBtn extends React.Component{  
	constructor(){
    super()       
    this.state={      
      
    }
  }
  


	render(){

	  return (
			    	
		
		  <div className='doc-table-editRowColBtn doc-table-editRowColBtn--col'>
		  	<div className='doc-table-editRowColBtn-dropdownBtnContainer'>
			  	<div onClick={this.props.selectAndDropdown} className='doc-table-editRowColBtn-dropdownBtn'>
			  		<Icon name='chevronDownCompact' />
			  	</div>			  
			  </div>
		  	<div onClick={this.props.select} className='doc-table-editRowColBtn-barContainer'>
		  		<div className='doc-table-editRowColBtn-bar' />
		  	</div>
		  </div>
				    
	  )
	}
}

export default DocTableEditRowColBtn
