import React from 'react'
import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import * as Dialog from '@radix-ui/react-dialog';
import { Transition, animated, config } from 'react-spring'
import {getProjectForId} from '../../utils/getProjectForId'
import EditProjectModalContents from './EditProjectModalContents'
import {getDefaultTeamForOrg} from '../../utils/getDefaultTeamForOrg'

class EditProjectModal extends React.Component{  	


	static getDerivedStateFromProps(props, state) { //Stuff to stop input resetting when renaming
		if(props.showModal != state.showModal){
			return ({showModal:props.showModal,hasChanges:false})
		}
		return null
	}


	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)  
    this.closeModal=this.closeModal.bind(this)  
    this.updateHasChanges=this.updateHasChanges.bind(this)
    this.state={
      forceMount: false,
      hasChanges:false,
      showModal:props.showModal
    }
  }

  closeModal(){
  	if(this.state.hasChanges){
  		// console.log('do nothing')
  	}else{
  		this.props.closeModal()
  	}
  	
  }

  updateHasChanges(hasChanges){
  	this.setState({hasChanges:hasChanges})
  }

  onRest() {	
		if(!this.props.showModal){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}	

	render(){	
		const {showModal, openModal,projectId,newProject} = this.props
		const {forceMount} = this.state		
		//let transformStart = 'scale(0.92)'
		let transformStart = 'translateY(4px) scale(0.95)'
		let transformEnd = 'translateY(0px) scale(1)'
		let transformLeave = 'translateY(10px) scale(1)'		
		if(newProject){
			transformLeave = 'translateY(0px) scale(1.05)'
		}
		let project
		if(projectId){
			project = getProjectForId(projectId)
		}
		let overlayConfig = {tension: 700, friction: 36}
		if(showModal){
			overlayConfig = {tension: 300, friction: 24}
		}

		const orgDefaultTeam=getDefaultTeamForOrg()
		let defaultTeams=[]
		defaultTeams.push(orgDefaultTeam)
	  return (
	  	<React.Fragment>
	  		<Dialog.Root open={showModal}>					
					<Dialog.Portal 
						forceMount={showModal ? true : forceMount}						
					>					
						<Dialog.Overlay asChild forceMount={showModal ? true : forceMount}>
							<Transition
				        items={showModal}
				        from={{ opacity: 0}}
				        enter={{ opacity: 1}}
				        leave={{ opacity: 0}}
				        reverse={showModal}
				        //delay={showModal ? 75 : 0}
				        //config={{tension: 700, friction: 36}}				        				        
				        config={overlayConfig}
				       >
				        {(styles, item) =>
				          item && 
				           <animated.div style={styles} className="editProjectModalOverlay" /> 					           				           	
				        }
				      </Transition> 			
						</Dialog.Overlay>
						<Dialog.Content
				    	className={'editProjectModalContainer ' + (showModal ? ' editProjectModalContainer--visible ' : ' editProjectModalContainer--hidden ')} 
				    	onEscapeKeyDown={this.props.closeModal}
					    onPointerDownOutside={this.closeModal}
					    onInteractOutside={this.closeModal}
					    forceMount={showModal ? true : forceMount}					    
				    >
					    <Transition
				        items={showModal}
				        from={{ opacity: 0, transform: `${transformStart}` }}
				        enter={{ opacity: 1, transform: `${transformEnd}`  }}
				        leave={{ opacity: 0, transform: `${transformLeave}`  }}
				        reverse={showModal}
				        config={{tension: 700, friction: 36}}				        
				        onRest={this.onRest}
				       >
				        {(styles, item) =>
				          item && 
				           <animated.div style={styles} className="editProjectModal"> 					           	
				           
				           	{!newProject && 
					           	<button onClick={this.props.closeModal} className='editProjectModal-cancelBtn'>
					           		Cancel
					           	</button>
				           	}

				           	{newProject && 
					           	<button onClick={this.props.closeModal} className='editProjectModal-closeBtn'>
					           		<Icon name='crossSemibold'/>
					           	</button>
				           	}

				           	{project &&
				           		<EditProjectModalContents				           			
				           			projectId={projectId}
				           			name={project.name}
				           			teams={project.teams}
				           			projectLead={project.lead}
				           			description={project.description}				           			
				           			leadUserId={project.lead}
				           			members={project.members}				           	
				           			newProject={newProject}
				           			icon={project.icon}
				           			color={project.color}
				           			status={project.state}
				           			closeModal={this.props.closeModal}
				           			updateHasChanges={this.updateHasChanges}
				           			editProjectModalFocusField={this.props.editProjectModalFocusField}	
				           		/>
				           	}

				           	{newProject &&
				           		<EditProjectModalContents				           			
				           			name={''}
				           			teams={defaultTeams}
				           			projectLead={null}
				           			description={''}				           			
				           			leadUserId={null}
				           			members={[]}				           	
				           			newProject={newProject}
				           			icon={null}
				           			color={'#bec2c8'}
				           			status={'backlog'}
				           			closeModal={this.props.closeModal}
				           			updateHasChanges={this.updateHasChanges}
				           			newProjectRoadmapId={this.props.newProjectRoadmapId}
				           		/>
				           	}
					           						          
				          </animated.div>
				        }
				      </Transition> 				


				    </Dialog.Content>
			    </Dialog.Portal>
			  </Dialog.Root>		

			  


			</React.Fragment>
	  )
	}
}
 
export default EditProjectModal