import React from 'react'

const alphabet=['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']

class BulletListItemNodeView {

  constructor(node, view, getPos) {
    this.getPos=getPos
    const {indentLevel,markerType,positionInList,separatorType}=node.attrs

    this.dom = document.createElement("div");
    this.dom.classList.add('doc-listItem')
    this.dom.setAttribute('data-indent-level',indentLevel)
    this.dom.setAttribute('data-marker-type',markerType)
    this.dom.setAttribute('data-position-in-list',positionInList)
    this.dom.setAttribute('data-separator-type',separatorType)
        //this.dom.setAttribute('data-marker-type','bullet')


    //marker
    this.markerContainer= document.createElement("div")
    this.markerContainer.classList.add('doc-listItem-markerContainer')

    this.markerContainer.contentEditable = 'false'
    this.dom.appendChild(this.markerContainer)

    this.marker= document.createElement("div");
    this.marker.classList.add('doc-listItem-marker')    
    this.markerContainer.appendChild(this.marker)
    if(markerType=='number'){
        this.marker.innerHTML = positionInList + 1
    }
    else if(markerType=='letter'){
        this.marker.innerHTML = alphabet[positionInList%26]
    }
     else if(markerType=='letterLowerCase'){
        this.marker.innerHTML = alphabet[positionInList%26]
    }
    
    

    const listContentDom=document.createElement("div")
    listContentDom.classList.add('doc-listItem-contents')    
    this.dom.appendChild(listContentDom)    
    this.contentDOM=listContentDom
    this.node=node
}

  getNode(){
    return this.node
  }


}

export default BulletListItemNodeView