import moment from 'moment'

export function formatMeetingDateTime(timestamp) {
	const nowDate = moment();
	const today = moment(nowDate).startOf('day');
	const yesterday = moment(nowDate).subtract(1, 'days').startOf('day');
	const dayBeforeYesterday = moment(nowDate).subtract(2, 'days').startOf('day');
	const tomorrow = moment(nowDate).add(1, 'days').startOf('day');
	const week = moment(nowDate).startOf('isoWeek');	
  const sevenDaysFromNow = moment(nowDate).add(7, 'days');
  const nextWeekButLessThan7Days = moment(nowDate).add(1, 'weeks').subtract(1, 'days');
	const nextWeek = moment(nowDate).add(1, 'weeks').startOf('isoWeek');
	const endOfNextWeek = moment(nowDate).add(1, 'weeks').endOf('isoWeek');
	const sixHoursFromNow = moment(nowDate).add(6, 'hours');

	const startOfThisWeek = moment().startOf('isoWeek');
  const endOfThisWeek = moment().endOf('isoWeek');
  const startOfNextWeek = moment().add(1, 'weeks').startOf('isoWeek');


 
	let formattedTimestamp;


   if (moment(timestamp).isBetween(nowDate, sixHoursFromNow)) {
    // in the next 6 hours
    const diff = moment(timestamp).diff(nowDate);
    const duration = moment.duration(diff);

    if (duration.asHours() < 1) {
      formattedTimestamp = `Today in ${duration.minutes()}m – ${moment(timestamp).format('h:mm A')}`;
    } else {
      formattedTimestamp = `Today in ${duration.hours()}h ${duration.minutes()}m – ${moment(timestamp).format('h:mm A')}`;
    }
  }
	else if (moment(timestamp).isSame(today, 'd')) {
		// today
		formattedTimestamp = `Today ${moment(timestamp).format('h:mm A')}`;
	}
	else if (moment(timestamp).isSame(yesterday, 'd')) {
  	// yesterday
  	formattedTimestamp = `Yesterday ${moment(timestamp).format('h:mm A')}`;
  }
	else if (moment(timestamp).isSame(tomorrow, 'd')) {
  	// tomorrow
  	formattedTimestamp = `Tomorrow ${moment(timestamp).format('h:mm A')}`;
  }	
  else if (moment(timestamp).isSameOrAfter(startOfThisWeek) && moment(timestamp).isBefore(yesterday)) {
    // earlier this week
    formattedTimestamp = `${moment(timestamp).format('ddd MMMM D')}`;
  }
	else if (moment(timestamp).isAfter(tomorrow) && moment(timestamp).isSameOrBefore(endOfThisWeek)) {
    // later this week but not today
    formattedTimestamp = `${moment(timestamp).format('dddd h:mm A')}`;
  } 
	else if (moment(timestamp).isBetween(nextWeek, nextWeekButLessThan7Days)) {
    // next week but less than 7 days
    formattedTimestamp = `${moment(timestamp).format('dddd h:mm A')}`;
  } 
  else if (moment(timestamp).isBetween(nextWeek, sevenDaysFromNow)) {
    // next week and 7 or more days away
    formattedTimestamp = `Next ${moment(timestamp).format('ddd MMMM D')}`;
  }
	else{
		// all others
		//formattedTimestamp = moment(timestamp).format('ddd MMMM D'); // with dayofweek
		formattedTimestamp = moment(timestamp).format('ddd MMMM D');
	}

	return formattedTimestamp;
}