import React from 'react'
import ReactDOM from 'react-dom'
import { Plugin } from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import {getNewState} from './utils/getNewState'
import {getSuggestions} from './utils/getSuggestions'
import {getMatch} from './utils/getMatch'
import schema from '../../schema/editorSchema'
import InsertEmojiDropdownReactComponent from './InsertEmojiDropdownReactComponent'
import {showInsertDrawingModal} from '../../../utils/toggleShowInsertDrawingModal'
import {createNewDocForLink} from '../../../utils/createNewDoc'
import {insertVideo} from '../../utils/insertVideo'
import {insertContentBox} from '../../utils/insertContentBox'
import {insertCodeBlock} from '../../utils/insertCodeBlock'
import {insertLinearIssue} from '../../utils/insertLinearIssue'
import {TextSelection} from "prosemirror-state"
import {getAnchorNode} from '../../utils/getAnchorNode'
import { escapeRegExp } from "lodash";

const char=":"
const triggerRegex = new RegExp(`${escapeRegExp(char)}([^${escapeRegExp(char)}]*)$`);
const suggestionTextClass="prosemirror-suggestion" //add a decoration around matched text- used to position the dropdown
const activeClass='suggestionMenu-item--active'

export function insertEmojiPlugin(pluginKey) {
	let el = document.createElement("div")//dropdown element
	el.addEventListener("mousedown", function(e) { //prevent clicking defocusing selection item
		e.preventDefault()
		e.stopPropagation()
	})

	const showList = function(view, state, suggestions) {		
		
		function selectIndex(e,index){
			e.stopPropagation()
			e.preventDefault()
			select(view,state,suggestions,index)
			view.focus()
		}

		 ReactDOM.render(	
		 	<InsertEmojiDropdownReactComponent
		 		suggestions={suggestions}
		 		selectIndex={selectIndex}
		 		hideList={hideList}
		 		text={state.text}
		 	/>
  	, el);

		var node = view.domAtPos(view.state.selection.$from.pos)
		var paraDOM = node.node
		var textDOM = paraDOM.querySelector("." + suggestionTextClass)
		let offset=0
		if(textDOM){
			offset = textDOM.getBoundingClientRect()
			document.getElementById("editor").appendChild(el)
		}
		
		el.style.position = "fixed";
		el.style.zIndex = 5000;


		const leftOverhangToCursor = 20 // we want to overhang cursor a little bit so text is aligned
		const leftOffset = offset.left - leftOverhangToCursor
		el.style.left = leftOffset + "px"

		// VERTICAL OFFSET
		// ABOVE OR BELOW

		const heightSuggestionsDropdown = 350 // pretty hacky
		const flipBuffer = 20 // don't want to get close to overflowing bottom of screen
		let dropdownPosition

		if(offset.top > (window.innerHeight - heightSuggestionsDropdown - flipBuffer)){
			dropdownPosition = "above"
		}else{
			dropdownPosition = "below"
		}

		if(dropdownPosition === "above"){
			var bottom = window.innerHeight - offset.top + 4 // buffer get to the top, then add a bit of buffer      
			el.style.bottom = bottom + "px";
		}

		if(dropdownPosition === "below") {    
			if(textDOM){
				var top = (textDOM.offsetHeight) + offset.top + 2 // get height of text line then buffer      
				el.style.top = top + "px";
			}   
		}
		el.style.display = "block"
	} //end show list

	const hideList = function() {
		el.style.display = "none"
		el.style.bottom = null
		el.style.top = null
		ReactDOM.unmountComponentAtNode(el)
	}

	const select = function(view,state,suggestions,index) {

		const item = suggestions[index]


			const node=view.state.schema.nodes.emoji.create({emojiString:item.id})
			let tr = view.state.tr
			//tr.replaceWith(state.range.from, state.range.to, node)

			tr.replaceWith(state.range.from, state.range.to, node)
			//tr.insertText(`\u{00A0}`) //blank space

			tr.setMeta("insertEmojiPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()



	// 	}


	}

	return new Plugin({
		key: pluginKey,
		state: {
			init() {
				return getNewState();
			},
			apply(tr, prev,oldState,newState) { //TODO test this with colllab
				const next = { ...prev };
				const { selection } = tr
				if (
          selection.from === selection.to &&// only show popup if selection is a blinking cursor         
          !(tr.getMeta("insertEmojiPlugin") && tr.getMeta("insertEmojiPlugin").deactivate) &&// deactivate popup from view (e.g.: choice has been made or esc has been pressed)        
          !tr.getMeta("focus") &&// deactivate because a mouse event occurs (user clicks somewhere else in the document)
          !tr.getMeta("blur") &&
          !tr.getMeta("pointer")
        ) {
					// Reset active state if we just left the previous suggestion range (e.g.: key arrows moving before /)
          if (prev.active && selection.from <= prev.range.from) {
            next.active = false;
          } else if (tr.getMeta("insertEmojiPlugin") && tr.getMeta("insertEmojiPlugin").activate) {
            // Start showing suggestions. activate has been set after typing a "/" (or whatever the specified character is), so let's create the decoration and initialize
            next.range = {
              from: selection.from - 1,
              to: selection.to,
            };
            next.query = "";
            next.active = true;
          } else if (prev.active) {       			
            const $position = selection.$from;
						const match = getMatch($position,triggerRegex)
						if(match) {// if match found update state       
							next.range = match.range;
							next.active = true;
							next.text = match.queryText;
						}
          }
        } 
        else {
          next.active = false;
        }
        if(next.active){
        	return next
        }else{ //new March 8th
        	return getNewState()
        }
        
			}
		},
		props: {
   		handleKeyDown(view, e) {
				var state = this.getState(view.state)
				if(e.key===':'){ // slash (/) key //a=65 use key not keycode so ? doesnt trigger it
					let tr=view.state.tr
					tr.setMeta("insertEmojiPlugin", { activate: true})
					view.dispatch(tr)
				}
				//if (!state.active || !state.suggestions.length) { // don't handle if no suggestions or not in active mode
				if (!state.active) { // don't handle if no suggestions or not in active mode
					return false
				}else if (e.keyCode === 38 || e.keyCode === 40) { //enter key or arrow
					return true
				}else if(e.keyCode === 13){
					if(!state.suggestions.length){
						let tr=view.state.tr
						tr.setMeta("insertEmojiPlugin", { deactivate: true})
						view.dispatch(tr)
						// hideList()
					}
					return true
				}else if ( e.keyCode === 27) { //escape key
					let tr=view.state.tr
					tr.setMeta("insertEmojiPlugin", { deactivate: true})
					view.dispatch(tr)
					return true
				}
			},     
			decorations(editorState) {//to decorate the currently active suggestion text in ui (and used for positioning dropdown)
				const { active, range } = this.getState(editorState)
				if (!active) return null
				return DecorationSet.create(editorState.doc, [
					Decoration.inline(range.from, range.to, {
						nodeName: "span",
						class: suggestionTextClass
					})
				])
			}
		},
		view() {// To track down state mutations and update dropdown
			return {
				update: (view,prevState )=> {
					const state = this.key.getState(view.state)
					let focused=false
					if(window.view){
						focused=window.view.hasFocus()
					}
					if(!state.active || !focused){
						hideList()
					}else{
						const getSuggestionsPromise=getSuggestions(state.text)
						Promise.resolve(getSuggestionsPromise).then((suggestions)=>{

							//we track how many characters you are past the last text that had suggestions
							// then if e.g. 3 characters after close the dropdown
							//this allows for e.g. typo-> no results then fix typo (don't want the suggestions thing to have closed)
							state.suggestions = suggestions
							state.index=0
							if(suggestions.length){
								state.textWithResults=state.text
							}
							const diff=state.text.length-state.textWithResults.length
							if(diff>2){
								let tr=window.view.state.tr
								tr.setMeta("insertEmojiPlugin", { deactivate: true})
								window.view.dispatch(tr)
								hideList()
							}else{
								showList(view, state, suggestions)
							}
							})
						
					}
				},
				destroy: ()=>{
					hideList()
				}
			};
		}
	});
}