const linearIconNames=[
	'Project',
	'Radar',
	'Chip',
	'Joystick',
	'Battery',
	'Sound',
	'Cone',
	'Database',
	'Terminal',
	'Watch',
	'Clock',
	'Lock',
	'Compass',
	'Server',
	'Calendar',
	'Apple',
	'Android',
	'Page',
	'Robot',
	'Chat',
	'LightBulb',
	'Alert',
	'Mac',
	'MobilePhone',
	'Computer',
	'Tablet',
	'Cube',
	'Bookmark',
	'Video',
	'Sign',
	'Inbox',
	'Subscribe',
	'Bucket',
	'Label',
	'Brush',
	'Briefcase',
	'Home',
	'Camera',
	'Trash',
	'GitHub',
	'GitLab',
	'Book',
	'Megaphone',
	'Education',
	'Present',
	'Umbrella',
	'Feather',
	'Cross',
	'Gears',
	'Phone',
	'Image',
	'Basket',
	'DesignTools',
	'TeeShirt',
	'Link',
	'Dashboard',
	'CreditCard',
	'Bank',
	'Dollar',
	'Euro',
	'Bitcoin',
	'Etherum',
	'Cart',
	'Shop',
	'Shield',
	'PieChart',
	'BarChart',
	'Chart',
	'Crown',
	'Airplane',
	'Bike',
	'Car',
	'Ship',
	'Rocket',
	'Anchor',
	'World',
	'Asia',
	'Australia',
	'Europe',
	'Africa',
	'SouthAmerica',
	'NorthAmerica',
	'Pin',
	'Face',
	'UnhappyFace',
	'ThumbsUp',
	'ThumbsDown',
	'Skull',
	'FaceHeartEyes',
	'FaceFlatSmile',
	'FaceMask',
	'FaceSurprise',
	'FaceMonocle',
	'FaceSunglasses',
	'FaceStarEyes',
	'FaceTongue',
	'FaceTired',
	'Cloud',
	'Leaf',
	'Tree',
	'Mountain',
	'Sun',
	'Flower',
	'Heart',
	'Fire',
	'Users',
	'Bug',
	'Recycle',
	'Dna',
	'Bolt',
	'Favorite',
	'Coffee',
	'Accessibility',
	'Burger',
	'FaceId',
	'FootPrint',
	'Mic',
	'MusicKey',
	'Paint',
	'Pizza',
	'Ramen',
	'WindTurbine',
	'Modem',
	'Jersey',
	'Moon',
	'Scissors',
	'Team'
]

export default linearIconNames;