import React from 'react'
import ReactDOM from 'react-dom';
import schema from '../../../prosemirror/schema/editorSchema'  
import ToggleListItemExpandArrowButton from './ToggleListItemExpandArrowButton'

class ToggleListItemNodeView {

  constructor(node, view, getPos) {
    this.toggleOpenState=this.toggleOpenState.bind(this)
    this.updateView=this.updateView.bind(this)
    this.node = node
    this.getPos=getPos
    this.dom = document.createElement("div")
    this.dom.className="doc-toggleListItem"

    const {open}=node.attrs
    if(open){
      this.dom.setAttribute('data-state','open') //open or closed
    }else{
      this.dom.setAttribute('data-state','closed') //open or closed
    }
    //expand button
    this.buttonDOM = document.createElement("div")
    this.buttonDOM.className="doc-toggleListItem-markerContainer"
    
    ReactDOM.render(
      <ToggleListItemExpandArrowButton 
      toggleOpenState={this.toggleOpenState}
      />, this.buttonDOM)

    this.dom.appendChild(this.buttonDOM)


    this.toggleListContentDom=document.createElement("div")
    this.toggleListContentDom.classList.add('doc-toggleListItem-contents')    
    this.dom.appendChild(this.toggleListContentDom)    
    this.contentDOM=this.toggleListContentDom
  }

  updateView(){
    const {open}=this.node.attrs
    if(open){
      this.dom.setAttribute('data-state','open') //open or closed
    }else{
      this.dom.setAttribute('data-state','closed') //open or closed
    }
  }




  toggleOpenState(){
    const {open} = this.node.attrs
    let newOpenState=!open
    var tr = window.view.state.tr
    tr.setNodeMarkup(this.getPos(), null, {...this.node.attrs,open:newOpenState})
    window.view.dispatch(tr)
  }




  update(node) {
    if (node.type !== this.node.type) return false
    const oldOpenState=this.node.attrs.open
    this.node=node
    if(oldOpenState!=node.attrs.open){
      this.updateView()
    }
    return true
  }


  destroy() {
    ReactDOM.unmountComponentAtNode(this.buttonDOM)
  }


}

export default ToggleListItemNodeView