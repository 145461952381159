import React from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import LoginProto from './LoginProto'
import LoadingProto from './LoadingProto'
import OnboardingModalProgressBar from './OnboardingModalProgressBar'
import OnboardingModalWorkspaceReady from './OnboardingModalWorkspaceReady'
import OnboardingModalDevice from './OnboardingModalDevice'
import OnboardingModalTheme from './OnboardingModalTheme'
import OnboardingModalProjectStage from './OnboardingModalProjectStage'
import OnboardingModalTemplateStage from './OnboardingModalTemplateStage'
import OnboardingModalFigmaStage from './OnboardingModalFigmaStage'
import OnboardingModalDocSearchStage from './OnboardingModalDocSearchStage'
import {toggleTheme} from '../../utils/toggleTheme'
import filter from 'lodash/filter'
import find from 'lodash/find'
import {updateOnboardingStage} from '../../actions/onboarding'
import {starTab,getTabStarredStatus} from '../../utils/starredTabs'
import {getRootDocIdForProject} from '../../utils/getRootDocIdForProject'                
import {applyTemplate} from '../../utils/templates/applyTemplate'
import {openDoc} from '../../utils/openDoc'

class OnboardingModal extends React.Component {  	
  
  constructor(props) {
    super(props);  
    //this.handleKeyDown=this.handleKeyDown.bind(this)  
    this.updateOnboardingStage=this.updateOnboardingStage.bind(this) 
    this.setOnboardingProject=this.setOnboardingProject.bind(this)
    this.setOnboardingProjectTemplate=this.setOnboardingProjectTemplate.bind(this)
    this.completeOnbaording=this.completeOnbaording.bind(this)
    this.skipProjectStage=this.skipProjectStage.bind(this)
   
    this.state = {
      //stage: "theme", 
      onboardingProject:null,
      onboardingProjectDocId:null,
      //stageOrder: ["login","loading","theme", "project", "template", "figma", "workspaceReady"],  
      stageOrder: ["login","loading","theme", "project", "template", "workspaceReady"],      
      //stageOrder: ["login","loading","theme", "project", "template", "figma", "docSearch", "workspaceReady"],      
    }
  }


  skipProjectStage(){ //takes you straight to workspace page
    const newStage='workspaceReady'
    this.updateOnboardingStage(newStage)
  }

  completeOnbaording(){ //set status to complete an navigate to onboarding doc
    openDoc(this.props.history,this.props.onboarding.onboarding_doc_id)
    this.updateOnboardingStage('complete')
  }

  setOnboardingProject(project){
    this.setState({onboardingProject:project})
    const docId=getRootDocIdForProject(project.project_id)
    this.setState({onboardingProjectDocId:docId})
    openDoc(this.props.history,docId)
  }

 // projectRootDoc:['Product Brief','Technical Spec','RFC'],
  setOnboardingProjectTemplate(templateName){ //linear,mixpanel or react
    const {docTemplates}=this.props
    let template

    if(templateName=='skip'){

    }
    else if(templateName=='linear'){
      template = find(docTemplates,{name:'Product Brief'})
    }else if(templateName=='mixpanel'){
      template = find(docTemplates,{name:'Technical Spec'})
    }else if(templateName=='react'){
      template = find(docTemplates,{name:'RFC'})
    }
    if(template){
      applyTemplate(template,this.state.onboardingProjectDocId)
    }
  }

  updateOnboardingStage(stage){
    this.props.updateOnboardingStage(stage)
    //this.setState({stage: stage});
  }

  advanceStage = () => {
    const {stage,path} = this.props    
    if(path=='full'){
      const {stageOrder} = this.state;
      const currentIndex = stageOrder.indexOf(stage);
      if(currentIndex < stageOrder.length - 1) {
        // this.setState({stage: stageOrder[currentIndex + 1]});
        this.updateOnboardingStage(stageOrder[currentIndex + 1]);
      }
    }else{//for short path go straight to workspace ready
      const {stageOrder} = this.state;
      this.updateOnboardingStage(stageOrder[stageOrder.length-1]);
      
    }
  }

  previousStage = () => {
    const {stage}=this.props
    const {stageOrder} = this.state;
    const currentIndex = stageOrder.indexOf(stage);
    if(currentIndex > 0) {
      //this.setState({stage: stageOrder[currentIndex - 1]});
      this.updateOnboardingStage(stageOrder[currentIndex - 1]);
    }
  }

  currentStageIsBefore = (comparisonStage) => {
    const {stageOrder} = this.state;
    const {stage}=this.props
    return stageOrder.indexOf(stage) < stageOrder.indexOf(comparisonStage);
  }

  currentStageIsAfter = (comparisonStage) => {
    const { stageOrder} = this.state;
    const {stage}=this.props
    return stageOrder.indexOf(stage) > stageOrder.indexOf(comparisonStage);
  }

  render() {		
    const {path,stage} = this.props;    
    const {theme}=this.props

    let stageAlign = 'leftAlign'
    if(stage === 'theme' || stage === 'workspaceReady'){
      stageAlign = 'centerAlign'
    }

    return (
      <React.Fragment>

        <div className='onboardingModal-topBar'>
          <div className='onboardingModal-topBar-macDots'>
            <div className='onboardingModal-topBar-macDots-dot' />
            <div className='onboardingModal-topBar-macDots-dot' />
            <div className='onboardingModal-topBar-macDots-dot' />
          </div>
        </div>

      	{stage === "login" && 
	      	<LoginProto
	      		signIn={this.advanceStage}
	      	/>
      	}
      	{stage === "loading" && 
	      	<LoadingProto
	      		setLoadingComplete={this.advanceStage}
	      	/>
      	}

        

          {path=='full' &&
            <OnboardingModalProgressBar
              stage={stage}
            />
          }


          <OnboardingModalTheme
            advanceStage={this.advanceStage}
            theme={this.props.theme}
            stageVisible={stage === "theme"}            
          />

          <OnboardingModalProjectStage 
            advanceStage={this.advanceStage}
            skipProjectStage={this.skipProjectStage}
            projects={this.props.projects} //to force rerender when projects load
            setOnboardingProject={this.setOnboardingProject}
            stageVisible={stage === "project"}            
          />      
              
          <OnboardingModalTemplateStage 
            advanceStage={this.advanceStage}
            onboardingProject={this.state.onboardingProject}
            setOnboardingProjectTemplate={this.setOnboardingProjectTemplate}
            stageVisible={stage === "template"}            
          />          

          {/*}
          {stage === "figma" &&          
            <OnboardingModalFigmaStage 
              advanceStage={this.advanceStage}
              //figmaIntegration={figmaIntegration}
            />
          }
          */}

          {/*}
          {stage === "docSearch" &&          
            <OnboardingModalDocSearchStage 
              advanceStage={this.advanceStage}
            />
          }
          */}

          

        	
	      	<OnboardingModalWorkspaceReady	      		
	      		backToStart={()=>{this.setState({stage: "login"})}}
            closeModal={this.completeOnbaording}
            stageVisible={stage === "workspaceReady"}  
	      	/>
        	


        





        
        <div className='onboardingModal-devControls'>
          <button onClick={this.previousStage}>
            Previous
          </button>
          <div className='onboardingModal-devControls-label'>
            {stage}
          </div>
          <button onClick={this.advanceStage}>
            Next
          </button>
        </div>
        

      </React.Fragment>
    )
  }
}



function mapStateToProps(state,ownProps) {


  return {
    theme:state.theme,
    projects:state.projects,
    figmaIntegration:state.figmaIntegration,
    docTemplates:state.docTemplates,
    onboarding:state.onboarding
  }
}



export default withRouter(connect(
  mapStateToProps,
  {
  updateOnboardingStage
  })(OnboardingModal))

            

// export default OnboardingModal
