import findIndex from 'lodash/findIndex'
import moment from 'moment'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_A_TRANSCRIPT_SUCCESS':
		const transcriptId = action.response.transcript_id
		let indexOfTranscript = findIndex(state,(transcript => {
			return transcript.transcript_id === transcriptId
		}))
		if(indexOfTranscript === -1){
			indexOfTranscript=state.length
		}
		return [
			...state.slice(0,indexOfTranscript),
			action.response,
			...state.slice(indexOfTranscript + 1)
		]


	case 'DELETE_TRANSCRIPT_FROM_REDUX':
		let index = findIndex(state,(transcript => {
			return transcript['transcript_id'] === action.transcriptId
		}))
		if(index>-1){
			return [
			...state.slice(0,index),
			...state.slice(index + 1)
			]
		}else return state

	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
