import React from 'react'
import Icon from '../../misc/Icon'
import DocBlockLinearIssueAssignee from './DocBlockLinearIssueAssignee'
import DocBlockLinearIssuePriority from './DocBlockLinearIssuePriority'
import DocBlockLinearIssueStatus from './DocBlockLinearIssueStatus'
import {openIssueInLinear} from '../../../utils/openInLinear'

// experiments
import DocBlockLinearIssueModal from './DocBlockLinearIssueModal'
import DocBlockLinearIssueMinimodal from './DocBlockLinearIssueMinimodal'

class DocBlockLinearIssue extends React.Component{  	
	
	constructor(props) {
		super(props)
		let expanded=false
		if(props.expanded){
			if(props.expanded==false || props.expanded=='false'){
				expanded=false
			}
		}
		this.state={
			expanded: expanded,
			title:props.title,
			//interactionType: 'minimodal'
			interactionType: null
		}

	} 

	render(){						

		const {assignee,workflowType,workflowColorHex,workflowTypeStateIndex,workflowTypeStatesCount,identifier,priority}=this.props
		const {interactionType, expanded}=this.state

	  return (	  	
	  	
  		<div className={"doc-blockLinearIssueContainer " + (interactionType ? ` docLinearIssueContainer--interactionType--${interactionType} ` : '')}>
					<div className='doc-blockLinearIssue'>
						{interactionType === 'modal' &&
							<div onClick={()=>{this.setState({interactionType: 'input'})}} className='doc-blockLinearIssue-hiddenSwitchInteractionTypeBtn' />
						}
						{interactionType === 'input' &&
							<div onClick={()=>{this.setState({interactionType: 'minimodal'})}} className='doc-blockLinearIssue-hiddenSwitchInteractionTypeBtn' />
						}
						{interactionType === 'minimodal' &&
							<div onClick={()=>{this.setState({interactionType: 'modal'})}} className='doc-blockLinearIssue-hiddenSwitchInteractionTypeBtn' />
						}

						<div className='doc-blockLinearIssue-startSpacer'/>
						
						<DocBlockLinearIssueAssignee
			  			assignee={assignee}
			  		/>
			  		
			  		<DocBlockLinearIssueStatus
			  			workflowType={workflowType}
			  			workflowTypeStateIndex={workflowTypeStateIndex}
			  			workflowTypeStatesCount={workflowTypeStatesCount} 
			  			workflowColorHex={workflowColorHex}
			  		/>
						
						<div contentEditable="false" className='doc-blockLinearIssue-labelContainer'>							
							<div className='doc-blockLinearIssue-label'>
								<input									
									disabled
									placeholder='Issue Title'
									className='doc-blockLinearIssue-label-input'
									value={this.state.title}
									onChange={(e)=>{this.setState({title:e.target.value})}}
								/>
							</div>							
						</div>

						{/* CLICKING OPENS LINK TO LINEAR */}
						<div onClick={()=>{openIssueInLinear(identifier)}} className='doc-blockLinearIssue-shortIDContainer'>
			  			<div className='doc-blockLinearIssue-shortID'>
			  				<div className='doc-blockLinearIssue-shortID-label'>
			  					{identifier}
			  				</div>
			  			</div>			  			
			  		</div>

			  		<DocBlockLinearIssuePriority
			  			priority={priority}
			  		/>

			  		<div className='doc-blockLinearIssue-endSpacer'/>
			  	</div>


			  	{interactionType === 'modal' &&
					  <DocBlockLinearIssueModal
							showPopover={expanded}						
							openPopover={()=>{this.setState({expanded: true})}}
							closePopover={()=>{this.setState({expanded: false})}}
						>
					
						<button onClick={()=>{this.setState({expanded: true})}} className='doc-blockLinearIssue-modalTriggerButton' />

					</DocBlockLinearIssueModal>		
					}		

					{interactionType === 'minimodal' &&
					  <DocBlockLinearIssueMinimodal
							showPopover={expanded}						
							openPopover={()=>{this.setState({expanded: true})}}
							closePopover={()=>{this.setState({expanded: false})}}
							title={this.state.title}
						>
					
						{/*
						<button onClick={()=>{this.setState({expanded: true})}} className='doc-blockLinearIssue-modalTriggerButton' />
						*/}

					</DocBlockLinearIssueMinimodal>		
					}		
			
				
			</div>	  
			
	  )
	}
}

export default DocBlockLinearIssue
