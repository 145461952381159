import React from 'react'
import Icon from '../../misc/Icon'


class DocTableSelectionBox extends React.Component{  
	constructor(props){
		super(props)	
		this.handleClickDot=this.handleClickDot.bind(this)	
		this.state={      
	   
    }
	} 

	handleClickDot(e){

		//console.log('test')
		e.stopPropagation()
		// e.preventDefault()

	}

	render(){

	  return (

	  	<React.Fragment>	  		
       
       	<div        		
       		onClick={this.handleClickDot}
       		className='doc-table-selectionBox-dot doc-table-selectionBox-dot--topLeft' 
       	/>
       	<div 
       		onClick={this.handleClickDot}
       		className='doc-table-selectionBox-dot doc-table-selectionBox-dot--bottomRight'
       	/>      

       	<div 
       		//onMouseDown={this.handleClickDot}
       		
       	/>       
	  		
	  	</React.Fragment>

	  )
	}
}

export default DocTableSelectionBox
