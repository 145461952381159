import findIndex from 'lodash/findIndex'
import sortBy from 'lodash/sortBy'
import MiniSearch from 'minisearch'
import store from '../../store'


let minisearchDoc = new MiniSearch({
	fields: ['name','plain_text'],
	storeFields: ['name', 'project', 'doc_html', 'is_project_doc', 'doc_id','is_org_doc','roadmap','meeting'],
	idField: 'doc_id',
})

let minisearchRoadmap = new MiniSearch({
	fields: ['name'],
	storeFields: ['name','roadmap_id'],
	idField: 'roadmap_id',
})




export function reindexSearch(docs,roadmaps) {
	docs.forEach((doc)=>{
		if(minisearchDoc.has(doc.doc_id)){
			minisearchDoc.replace(doc)
		}else{
			minisearchDoc.add(doc)
		}
	})
	roadmaps.forEach((roadmap)=>{
		if(minisearchRoadmap.has(roadmap.roadmap_id)){
		minisearchRoadmap.replace(roadmap)
		}else{
		minisearchRoadmap.add(roadmap)
		}
	})
	return
}


export function getDocSearchResults(docs,roadmaps,searchTerm) {
	if(minisearchDoc._documentCount == 0 ){
		minisearchDoc.addAll(docs)
	}
	if(minisearchRoadmap._documentCount == 0 ){
		minisearchRoadmap.addAll(roadmaps)
	}
	let results
	if(searchTerm){
		const docResults=minisearchDoc.search(searchTerm, {prefix:true, fuzzy:0.2})
		const roadmapResults=minisearchRoadmap.search(searchTerm, {prefix:true, fuzzy:0.2,boost:{name:50}}) //boost roadmaps
		const combinedResults = docResults.concat(roadmapResults)
		const sorted=sortBy(combinedResults,'score').reverse()
		results=sorted
	}else{ //default state
		// const sortedRoadmaps=sortBy(roadmaps,function(roadmap){
		// 		return roadmap.name.toString().toLowerCase()
		// })
		const sortedDocs=sortBy(docs,function(doc){
			return doc.updated_at
		}).reverse()
		
		results=sortedDocs
	}
	return results.slice(0,10)
}


