import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import store from '../store'
//PM
import {Mapping} from "prosemirror-transform"
import {Step} from "prosemirror-transform"
import {history,undo,redo} from "prosemirror-history"
import {EditorState,Selection,PluginKey,NodeSelection,TextSelection} from "prosemirror-state"
import {EditorView} from "prosemirror-view"
import {Schema, DOMParser,Node,DOMSerializer,Slice,Fragment} from "prosemirror-model"
import {collab,sendableSteps,getVersion,receiveTransaction} from "prosemirror-collab"
import {keymap} from "prosemirror-keymap"
import {baseKeymap} from "prosemirror-commands"
import {setBlockType, chainCommands, toggleMark,wrapIn} from "prosemirror-commands"
import {splitListItem, liftListItem, sinkListItem} from "prosemirror-schema-list"
import applyDevTools from "prosemirror-dev-tools"


//Tables
import {tableEditing,goToNextCell,isInTable} from 'prosemirror-tables'
import {columnResizing} from '../prosemirror/plugins/tables/columnResizing'
import observeTableWidth from '../prosemirror/plugins/tables/observeTableWidth'

import {mentionsPlugin} from '../prosemirror/plugins/mentions/mentionsPlugin'
import {insertPlugin} from '../prosemirror/plugins/insert/insertPlugin'
import {insertEmojiPlugin} from '../prosemirror/plugins/emoji/insertEmojiPlugin'
import {emptyDocPlaceholderPlugin} from '../prosemirror/plugins/emptyDocPlaceholderPlugin'
import {mediaSelectionStylingPlugin} from '../prosemirror/plugins/mediaSelectionStylingPlugin'
import {cursorInsideNodePlugin} from '../prosemirror/plugins/cursorInsideNodePlugin'
import {splitBulletListCommand} from '../prosemirror/utils/commands/splitBulletListCommand'
import {insertLineBreakCommand} from '../prosemirror/utils/commands/insertLineBreakCommand'
import {toggleToDoCheckedCommand} from '../prosemirror/utils/commands/toggleToDoCheckedCommand'
import {toggleParagraphToToDoItem} from '../prosemirror/utils/commands/toggleParagraphToToDoItem'
import {bumpMessageVersion} from '../prosemirror/utils/bumpMessageVersion'
import {insertFigmaEmbed} from '../prosemirror/utils/insertFigmaEmbed'
import {selectionMenuPlugin} from '../prosemirror/plugins/selectionMenuPlugin'
import {orderedListPlugin} from '../prosemirror/plugins/orderedListPlugin'
import {selectedNodeStylingPlugin} from '../prosemirror/plugins/selectedNodeStylingPlugin'
import {inDocSearchPlugin} from '../prosemirror/plugins/docSearch/inDocSearchPlugin'
import {toggleListItemPlugin} from '../prosemirror/plugins/toggleListItemPlugin'
import {insertHeaderCommand} from '../prosemirror/utils/commands/insertHeaderCommand'
import {insertTextLinkCommand} from '../prosemirror/utils/commands/insertTextLinkCommand'


import editorSchema from '../prosemirror/schema/editorSchema'
//Lodash
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import find from 'lodash/find'

//plugins
import {messageSelectionPlugin} from '../prosemirror/plugins/messageSelections/messageSelectionPlugin'
import {collabSelectionPlugin} from '../prosemirror/plugins/collabSelection/collabSelectionPlugin'
import {buildInputRules} from '../prosemirror/plugins/inputRules/inputRules'
import {addMessageSelection} from '../prosemirror/plugins/messageSelections/addMessageSelection'
import {headingPlaceholderPlugin} from '../prosemirror/plugins/headingPlaceholderPlugin'
import {selectionStylingPlugin} from '../prosemirror/plugins/selectionStylingPlugin'
import {deleteHeadingPlugin} from '../prosemirror/plugins/deleteHeadingPlugin'
import {paragraphSpanPlugin} from '../prosemirror/plugins/paragraphSpanPlugin' //for spans used to measure selection styling
import {textLinkPlugin} from '../prosemirror/plugins/textLinks/textLinkPlugin'
// import {newTextLinkPlugin} from '../prosemirror/plugins/newTextLinkPlugin'
import {toDoItemPlugin} from '../prosemirror/plugins/toDoItemPlugin'
import {tableCellPlugin} from '../prosemirror/plugins/tables/tableCellPlugin'
// import {tableCellUIPlugin} from '../prosemirror/plugins/tables/tableCellUIPlugin'
import {tablePastingPlugin} from '../prosemirror/plugins/tables/tablePastingPlugin'
import {columnHandles} from '../prosemirror/plugins/tables/columnHandles'



//Actions
import {deleteMessage} from '../actions/messages'
import {fetchTranscript} from '../actions/transcripts'
import {fetchRecentAcitivities} from '../actions/recentActivities'
import {saveDocScrollPosition} from '../actions/docScroll'
import {
	archiveDoc,
	getDocInstance,
	sendDocumentEvents,
	fetchDocumentEvents,
	sendUserSelection,
	modifyDocNameLocally,
	handleDocTimestampUpdatedPusher,
	createDocSnapshot
} from '../actions/docs'
import {fetchReactions,addReactionToMessage,deleteReaction,handleNewReactionPusher,handleReactionDeletedPusher} from '../actions/reaction'
import {updateRecentActivity} from '../actions/recentActivities'
import {uploadImageToCloudinary} from '../actions/cloudinary'
import {createMessage} from '../actions/messages'
import {sendUserTypingEvent} from '../actions/docs'
import {insertImage} from '../prosemirror/utils/insertImage'
import {insertVideo} from '../prosemirror/utils/insertVideo'
import {insertThumbnail} from '../prosemirror/utils/insertThumbnail'
import {insertDrawing} from '../prosemirror/utils/insertDrawing'
import {insertIssueFromPaste} from '../prosemirror/utils/insertIssueFromPaste'

import DocEditor from '../components/docEditor/DocEditor'

import ToDoItemNodeView from '../components/nodeViews/toDo/ToDoItemNodeView'
import BulletListItemNodeView from '../components/nodeViews/lists/BulletListItemNodeView'
import DocImageNodeView from '../components/nodeViews/image/DocImageNodeView'
import DocThumbnailNodeView from '../components/nodeViews/image/DocThumbnailNodeView'
import DocVideoNodeView from '../components/nodeViews/video/DocVideoNodeView'
import DocDrawingNodeView from '../components/nodeViews/drawing/DocDrawingNodeView'
import DocDateNodeView from '../components/nodeViews/docDate/DocDateNodeView'
import EmojiNodeView from '../components/nodeViews/emoji/EmojiNodeView'

import DocWhiteboardEmbedNodeView from '../components/nodeViews/whiteboard/DocWhiteboardEmbedNodeView'
import ParticipantHeaderNodeView from '../components/nodeViews/participantHeader/ParticipantHeaderNodeView'


import DocCodeMirrorNodeView from '../components/nodeViews/docCodeMirror/DocCodeMirrorNodeView'



import DocTableNodeView from '../components/nodeViews/tables/DocTableNodeView'

import TextLinkNodeView from '../components/nodeViews/links/TextLinkNodeView'

import ToggleListItemNodeView from '../components/nodeViews/toggleList/ToggleListItemNodeView'


//linear
import LinearIssueMentionNodeView from '../components/nodeViews/linear/LinearIssueMentionNodeView'
import LinearProjectMentionNodeView from '../components/nodeViews/linear/projects/LinearProjectMentionNodeView'
import DocFigmaEmbedNodeView from '../components/nodeViews/figma/DocFigmaEmbedNodeView'
import InternalLinkNodeView from '../components/nodeViews/links/InternalLinkNodeView'
import UnauthErrorPage from '../components/UnauthErrorPage'
import {docPresencePusherSetup} from '../utils/docPresencePusherSetup'
import DocumentTitle from 'react-document-title'
import pusherInstance from '../pusherInstance'
import codemark from 'prosemirror-codemark';
import 'prosemirror-codemark/dist/codemark.css';
import {insertContentBoxCommand} from '../prosemirror/utils/insertContentBox'
import {insertCodeBlockCommand} from '../prosemirror/utils/commands/insertCodeBlockCommand'
// import {insertTextLinkCommand} from '../prosemirror/utils/commands/insertTextLinkCommand'
import {insertLoremIpsum} from '../prosemirror/utils/insertLoremIpsum'
// import {insertFigma} from '../prosemirror/utils/insertFigma'
import {getAnchorNode} from '../prosemirror/utils/getAnchorNode'
import {getNodePos} from '../prosemirror/utils/getNodePos'
import {isCurrentUser} from '../utils/isCurrentUser'
import {openDoc} from '../utils/openDoc'
import {indentMoreCommand,indentLessCommand,maybeIndentLessCommand} from '../prosemirror/utils/commands/indentCommand'
import {maybeSelectCellDown,maybeSelectCellUp,maybeSelectCellLeft,maybeSelectCellRight,maybeAddTableColumnAfter,maybeAddTableColumnBefore,maybeAddTableRowBefore,maybeAddTableRowAfter} from '../prosemirror/utils/commands/tableCommands'
import {randomID} from '../utils/randomID'
import {setInitialMessageSelections} from '../utils/messageSelections'
import {getIssueForIdentifier} from '../utils/getIssueForId'
import {getIssueForId} from '../utils/getIssueForId'
import {getProjectForId} from '../utils/getProjectForId'
import {updateMessagePanelVisibility} from '../utils/updateMessagePanelVisibility'

import {calculateDocNotificationCount} from '../utils/notifications/calculateNotificationCounts'

import ReactGA from 'react-ga4'
import {openDocSearch,closeDocSearch} from '../utils/docSearch'
import {updateIssuePanelVisibility} from '../utils/updateIssuePanelVisibility'
import * as Sentry from '@sentry/browser'
import { deleteSelection, joinBackward, selectNodeBackward,undoInputRule } from "prosemirror-commands";

//https://discuss.prosemirror.net/t/how-to-handle-edge-case-with-collab-module/288/25
const codeMarkPlugins=codemark({ markType: editorSchema.marks.code }); //https://github.com/curvenote/prosemirror-codemark

//Get events returns 400 error if local version is ahead of server version 
function badVersion(err) {
	if(err && err.response){
		return err.response.status == 400 && err.response.data=="invalid version"
	}else return false //TODO test this- added it to handle offline
}

function repeat(val, n) {
	let result = []
	for (let i = 0; i < n; i++) result.push(val)
	return result
}

//temp for pasting in figma and video links
function isFigmaLink(text){
	var regExp = 'www\.figma\.com'
	var match = text.match(regExp)
	return match
}

function isLinearIssueIdentifier(text,orgUrlKey){
	let match
	var regExp1 = '\\S{2,4}\-\\d{1,4}'
	const regExp2='https:\/\/linear\.app\/\\S{1,15}\/issue\/(\\S{2,4}\-\\d{1,4})'
	let identifier=null
	if(text.match(regExp2)){
		identifier=text.match(regExp2)[1]
	}else if(text.match(regExp1)){
		identifier=text.match(regExp1)[0]
	}
	return identifier
}


function isVideo(text){
	var regExp = 'https://res.cloudinary.com/yarn/video/'
	var match = text.match(regExp)
	return match
}

const consoleLogCollabStuff=false
const consoleLogSelectionStuff=false
const broadcastSelections=true
//const SEND_EVENTS_REQUEST_TIMEOUT=2000
const SEND_EVENTS_REQUEST_TIMEOUT=3000
const THROTTLE_TIME=1000
const RECENT_ACTIVITY_TIMEOUT=3000

const USER_TYPING_TIMEOUT=900
//TODO rename docUpdatedPusher to newVersion or something like that 
//TODO rename poll request to getSteps

function handleDragAndDropEvent(e){ //Disable node drag and drop
	e.preventDefault()
	return true
}

const insertPluginKey=new PluginKey("insertPlugin")
const insertEmojiPluginKey=new PluginKey("insertEmojiPlugin")
const mentionPluginKey=new PluginKey("mentionPlugin")
const messageSelectionPluginKey = new PluginKey('messageSelectionPlugin')


let menuItems = {
	contentBox:{command: insertContentBoxCommand(),nodeType:'contentBox',isActive:false,isAllowed:true},
	codeBlock:{command: insertCodeBlockCommand(),nodeType:'codeBlock',isActive:false,isAllowed:true},
	header1:{command: insertHeaderCommand(1),nodeType:'heading',attrs:{level:1},isActive:false,isAllowed:true},
	header2:{command: insertHeaderCommand(2),nodeType:'heading',attrs:{level:2},isActive:false,isAllowed:true},
	light:{command: toggleMark(editorSchema.marks.light),mark:'light',isActive:false,isAllowed:true},
	code:{command: toggleMark(editorSchema.marks.code),mark:'code',isActive:false,isAllowed:true},
	textLink:{command: insertTextLinkCommand(),nodeType:'textLink',isActive:false,isAllowed:true},
}

function nodeActive(state,type,attrs) {
	let {$from} = state.selection
	const anchorNode=getAnchorNode($from,type)
	if(anchorNode){
		if(attrs){
			if(attrs.level==anchorNode.attrs.level){
				return true
			}
			else return false
		}else return true
	}else return false
 }

function markActive(state, type) {
	let {from, $from, to, empty} = state.selection
	if (empty) return type.isInSet(state.storedMarks || $from.marks())
	else return state.doc.rangeHasMark(from, to, type)
}


function showNewTextLinkModal(){
	//check if can show it
	const canInsert=insertTextLinkCommand()(window.view.state)
	if(canInsert){
		let tr=window.view.state.tr
		tr.setMeta("newTextLinkPlugin", { activate: true})
		window.view.dispatch(tr)
	}
}

function transformPasted(slice) { //This handles pasting tables from other places e.g. notion
    let {content} = slice
    let newNodes = []
    slice.content.forEach((node, i) => {
        //console.log(node.type.name)
        if (node.type.name === 'table') {
        	const nodeId=randomID()
            const newNode = editorSchema.nodes.docTable.create({nodeId:nodeId}, node)
            console.log(newNode)
            newNodes.push(newNode)
        } else {
            newNodes.push(node)
        }
    })
    const newContent = Fragment.from(newNodes)
    return new Slice(newContent, slice.openStart, slice.openEnd)
}


function deleteWithClearingMarks(state, dispatch){
  let marksInSelection = state.selection.$from.marks();
  // Create a new transaction to perform the deletion
  let tr = state.tr.deleteSelection();
  // Check if the selection was successfully deleted
  if (tr.doc.eq(state.doc)) return false;  // No deletion was made
  // Get the marks at the cursor after deletion
  let marksAtCursor = tr.selection.$head.marks();

  // Check whether any of the marks from the selection still exist at the cursor.
  // If they don't, remove them from the stored marks.
  let newStoredMarks = marksAtCursor.filter(mark => {
    return !marksInSelection.some(selectionMark => selectionMark.type.name === mark.type.name);
  });

  // Set the new stored marks in the transaction
  tr.setStoredMarks(newStoredMarks);
  // If dispatch is not null, apply the transaction
  if (dispatch) dispatch(tr);
  return true;
}


class DocEditorContainer extends Component {	
	static getDerivedStateFromProps(props, state) {
		if (props.doc && props.doc['doc_id'] !== state.prevId) {
			const doc=props.doc
			return ({docName: doc.name,prevId:doc['doc_id'] }) // <- this is setState equivalent
		}
		return null
	}

	constructor(props) {
		super(props)
		this.dispatch=this.dispatch.bind(this)
		this.poll=this.poll.bind(this)
		this.closeRequest=this.closeRequest.bind(this)
		this.onDocNameUpdate=this.onDocNameUpdate.bind(this)
		this.archiveDoc=this.archiveDoc.bind(this)
		this.updateRecentActivity=this.updateRecentActivity.bind(this)
		this.updateThreadRecentActivity=this.updateThreadRecentActivity.bind(this)

		this.handleSelectionMenuUpdate=this.handleSelectionMenuUpdate.bind(this)

		//PM
		this.setView=this.setView.bind(this)
		this.startEditingInstance=this.startEditingInstance.bind(this)		
		//Pusher
		this.docPusherSetup=this.docPusherSetup.bind(this)
		this.handleDocUpdatedPusher=this.handleDocUpdatedPusher.bind(this)
		this.disconnectPusher=this.disconnectPusher.bind(this)
		this.handleDocRenamedPusher=this.handleDocRenamedPusher.bind(this)
		this.handleSelectionUpdatedPusher=this.handleSelectionUpdatedPusher.bind(this)
		this.handleUserTypingPusher=this.handleUserTypingPusher.bind(this)
		this.handleNewReactionPusher=this.handleNewReactionPusher.bind(this)
		this.handleReactionDeletedPusher=this.handleReactionDeletedPusher.bind(this)
		//Messages
		this.cancelNewDocThread=this.cancelNewDocThread.bind(this)
		this.setNewMessageParentMessage=this.setNewMessageParentMessage.bind(this)
		this.sendDocMessage=this.sendDocMessage.bind(this)
		this.newSelectionMessage=this.newSelectionMessage.bind(this)
		this.newNodeMessage=this.newNodeMessage.bind(this)
		this.clearMessageSelection=this.clearMessageSelection.bind(this)
		this.sendMediaModalMessage=this.sendMediaModalMessage.bind(this)
		this.onCreateMessage=this.onCreateMessage.bind(this)
		this.addReactionToMessage=this.addReactionToMessage.bind(this)
		//Selection
		this.broadcastSelection=this.broadcastSelection.bind(this)
		this.sendSelection=this.sendSelection.bind(this)
		this.mapThroughSelections=this.mapThroughSelections.bind(this)		
		//MISC
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.insertImage=this.insertImage.bind(this)
		this.onScroll=this.onScroll.bind(this)		
		this.handlePaste=this.handlePaste.bind(this)
		this.saveScrollAmount=this.saveScrollAmount.bind(this)
		this.saveScrollAmount=throttle(this.saveScrollAmount,THROTTLE_TIME,{leading:true,trailing:true})
		this.setScrollAmount=this.setScrollAmount.bind(this)
		this.clearUserTyping=this.clearUserTyping.bind(this)
		this.deleteMessageSelection=this.deleteMessageSelection.bind(this)
		this.setActiveMessageSelection=this.setActiveMessageSelection.bind(this)
		this.handleOpenDoc=this.handleOpenDoc.bind(this)
		this.sendUserTypingEvent=this.sendUserTypingEvent.bind(this)
		this.closeIssuePanel=this.closeIssuePanel.bind(this)
		this.openIssuePanelForUser=this.openIssuePanelForUser.bind(this)
		this.showMessagePanel=this.showMessagePanel.bind(this)
		this.broadcastSelection=throttle(this.broadcastSelection,300) //changed from debounce

		this.setThreadParentMessage=this.setThreadParentMessage.bind(this)
		this.scrollTimeout=null

		this.handleEditorClick=this.handleEditorClick.bind(this)
		let docName=''
		if(props.doc){
			docName=props.doc.name
		}
		
		this.requestNumber=1 //for dev testing
		this.backOff = 0 //TODO 
		this.edit = null
		this.comm = null //"poll","send","start"
		this.pendingPollRequestVersion=null //don't poll while POST events is sending (if receive docUpdatedPusher)
		this.activeSendRequestId=null //give send events request an id, only apply steps if request id is equal to active id (for timeout)
		this.state = {
			menuKey:0,
			docName:docName,
			refetchCount:0, //to keep track  of refetch instance requests
			docRenamedBy:null,
			unauthError:false,
			restartCount:0, //keep track of restart count (use this to refresh comments pannel on restart)
			shiftKeyDown:false,
			userTyping:null,
			newMessageParentNodeId:null, //TODO some of this message stuff is messy
			newMessageParentNodeType:null,
			newMessageParentNodeSnapshot:null,
			newMessageParentMessage:null,
			messageTextSnapshot:null,
			messageSelectionId:null,//when create a selection assign it an id
			isNewDocThread:false,
			messagesVersion:0 ,//use this to bump version on media nodes so they know to check for new messages
			threadParentMessage:props.activeThread||null
		}
		this.userTypingTimeout=null
		this.collabCursorTimeout=null
		this.updateRecentActivityTimeout=null
		this.menuItems=menuItems
	} 

	componentDidMount(){
		if(this.props.doc){//check if have notifications and if so open message panel
			const notificationCount = calculateDocNotificationCount(this.props.doc.doc_id,this.props.doc)
			if(notificationCount>0){
				this.showMessagePanel()
			}
		}
		this.mounted=true
		this.docPusherSetup()
		this.props.fetchReactions(this.props.docId)
		this.startEditingInstance()		
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Doc Editor" });
		}
		if(this.props.recording){
			if(!this.props.transcript){
				//console.log('already have transcript')
			}else{
				//console.log('no transcript so lets load it!!!')
				this.props.fetchTranscript(this.props.recording.transcript_id)
			}
		}
	}

	componentDidUpdate(prevProps){
		if(prevProps.docMessages.length != this.props.docMessages.length){
			const newMessagesVersion=this.state.messagesVersion+1
			bumpMessageVersion(newMessagesVersion)
			this.setState({messagesVersion:newMessagesVersion})
		}
	}

	componentWillUnmount(){
		if(this.edit){//check for unsent steps!!!
			let sendable = sendableSteps(this.edit)
			if(sendable){
				Sentry.withScope(scope => {
	   			scope.setExtra('docId', this.props.docId)
	  			scope.setExtra('userId', this.props.userId)
	  			Sentry.captureException('Pending sendable steps on unmount')
	   		})
				this.props.createDocSnapshot(this.props.docId,this.edit)
			}
		}
		this.mounted=false
		if(this.view){
			this.edit=null
			this.view.destroy()
			this.view=null
			window.view=null
		}
		///////////////////
		if(this.refetchTimout){
			clearTimeout(this.refetchTimout)
		}
		if(this.updateRecentActivityTimeout){
			clearTimeout(this.updateRecentActivityTimeout)
		}
		if(this.sendRequestTimeout){
			clearTimeout(this.sendRequestTimeout)
			this.sendRequestTimeout=null
			this.activeSendRequestId=null
		}
		if(this.scrollTimeout){
			clearTimeout(this.scrollTimeout)
			this.scrollTimeout=null
		}
		this.disconnectPusher()
		if(this.userTypingTimeout){
			clearTimeout(this.userTypingTimeout)
		}
		if(this.collabCursorTimeout){
			clearTimeout(this.collabCursorTimeout)
		}	
		closeDocSearch()
	}

	showMessagePanel(){
		updateMessagePanelVisibility(this.props.docId,true)
	}


///////ISSUE PANEL
	closeIssuePanel(){
		updateIssuePanelVisibility(this.props.docId,false)
	}

	openIssuePanelForUser(userId){
		const isVisible=true
		const projectId=null
		updateIssuePanelVisibility(this.props.docId,isVisible,projectId,userId)
	}

	updateRecentActivity(){
		const itemType='doc'
		this.props.updateRecentActivity(this.props.docId,itemType)
	}

	updateThreadRecentActivity(parentMessage){
		const itemType='thread'
		this.props.updateRecentActivity(parentMessage,itemType)
	}

	handleKeyDown(view,event){ // for shift paste thumbnail image
		if(!(event.metaKey || event.keyCode==70)){
			if(this.props.docSearchResults && this.props.docSearchResults.searchActive){
				closeDocSearch()
			}
		}
		if(event.shiftKey){
			this.setState({shiftKeyDown:true})
		}else{
			if(this.state.shiftKeyDown){
				this.setState({shiftKeyDown:false})
			}
		}
		//shift up arrow key, if you are below a table then node select the table
		if((event.keyCode==38 || event.keyCode==37)&& event.shiftKey){ //up or right
			const state=view.state
			const {selection}=state
			const inTable=isInTable(state)
			if(!inTable){
				const {$anchor,$head}=selection
				let nextPos 
				if(event.keyCode==38){
					nextPos = Selection.near(state.doc.resolve($head.before()), -1)
				}else{
					nextPos = Selection.near(state.doc.resolve($head.pos-1),-1)
				}	
				const nextAnchorNode=getAnchorNode(nextPos.$head,'docTable')
				if(nextAnchorNode){
					const anchorNodePos = getNodePos(nextAnchorNode)
					const selectionEnd=state.doc.resolve(anchorNodePos-1)
					const selection = new TextSelection($anchor,selectionEnd)
					window.view.dispatch(state.tr.setSelection(selection));
					return true
				}
			}
		}
		else if((event.keyCode==40 || event.keyCode==39)&& event.shiftKey){ //down
			const state=view.state
			const inTable=isInTable(state)
			const {selection}=state
			if(!inTable){
				const {$anchor,$head}=selection
				let nextPos 
				if(event.keyCode==40){
					nextPos = Selection.near(state.doc.resolve($head.after()), +1)
				}else{
						nextPos = Selection.near(state.doc.resolve($head.pos+1))
				}				
				const nextAnchorNode=getAnchorNode(nextPos.$head,'docTable')
				if(nextAnchorNode){
					const anchorNodePos = getNodePos(nextAnchorNode)
					const selectionEnd=state.doc.resolve(anchorNodePos+nextAnchorNode.nodeSize+1)
					const selection = new TextSelection($anchor,selectionEnd)
					window.view.dispatch(state.tr.setSelection(selection));
					return true
				}
			}
		}
		else if(event.keyCode==38){ //up arrow --> check if should move cursor in to the title
			const state=view.state
			const dir="up"
			if (state.selection.empty && view.endOfTextblock(dir)) {//check if it is the first textblock in doc- if true then up arrow is out of doc	
				let side =  -1 , $head = state.selection.$head
				let nextPos
				let currentAnchorNode
				let nextAnchorNode
				if($head.before()){
					nextPos = Selection.near(state.doc.resolve($head.before()), -1)
					currentAnchorNode=getAnchorNode($head,'textLink') || getAnchorNode($head,'paragraph')
					nextAnchorNode=getAnchorNode(nextPos.$head,'paragraph')
				}
				if(!nextPos || nextAnchorNode==currentAnchorNode){ //is at the top of the doc!!
					//check insert/mention menus are not open
					const insertMenuState=insertPluginKey.getState(state)
					const mentionMenuState=mentionPluginKey.getState(state)
					const emojiMenuState=insertEmojiPluginKey.getState(state)
					const docTemplateSelectionEl = document.getElementById('template-selection')
					if(!insertMenuState.active && !mentionMenuState.active && !emojiMenuState.active && !docTemplateSelectionEl){
						event.preventDefault()
						const input=document.getElementById("titleInput")
						if(input){
							input.focus()
							input.setSelectionRange(input.value.length,input.value.length);
						}
					}
				}
			}
		}
	}

	//////PUSHER STUFF/////
	disconnectPusher(){
		//temp do not unsubscribe to doc pusher as used in collab instance manager
		//pusherInstance.unsubscribe(`document-${this.props.match.params.id}`);
		this.channel.unbind('user-typing',this.handleUserTypingPusher)  
		this.channel.unbind('doc-updated', this.handleDocUpdatedPusher) 
		this.channel.unbind('new-reaction', this.handleNewReactionPusher)  
		this.channel.unbind('reaction-deleted', this.handleReactionDeletedPusher)  
		this.channel.unbind('selection-updated', this.handleSelectionUpdatedPusher)  
		this.channel.unbind('doc-renamed',this.handleDocRenamedPusher)  
		this.channel.unbind('save-error',this.handleSaveError)  
		this.channel.unbind('user-typing',this.handleUserTypingPusher)  
		pusherInstance.unsubscribe(`presence-doc-${this.props.docId}`); //TODO figure this out with collab cursors and open tabs
	}
	
	docPusherSetup() {
		this.channel = pusherInstance.subscribe(`document-${this.props.docId}`);
		this.channel.bind('doc-updated', this.handleDocUpdatedPusher) 
		this.channel.bind('new-reaction', this.handleNewReactionPusher)  
		this.channel.bind('reaction-deleted', this.handleReactionDeletedPusher)  
		this.channel.bind('selection-updated', this.handleSelectionUpdatedPusher)  
		this.channel.bind('doc-renamed',this.handleDocRenamedPusher)  
		this.channel.bind('save-error',this.handleSaveError)  
		this.channel.bind('user-typing',this.handleUserTypingPusher)  
		docPresencePusherSetup(this.props.docId)
	}

	handleReactionDeletedPusher(pusherData){
		if(!isCurrentUser(pusherData.userId)){
			this.props.handleReactionDeletedPusher(pusherData.reactionId)
		}
	}

	handleNewReactionPusher(pusherData){
		if(!isCurrentUser(pusherData.reaction.created_by)){
			this.props.handleNewReactionPusher(pusherData.reaction)
		}
	}	

	addReactionToMessage(type,messageId,docId){
		//check if user has already reacted
		const userReaction = find(this.props.reactions,{created_by:this.props.userId,reaction_type:type,message_id:messageId})
		if(userReaction){
			this.props.deleteReaction(userReaction['reaction_id'],this.props.docId)
		}else{
			this.props.addReactionToMessage(type,messageId,docId)
		}
	}

	handleUserTypingPusher(pusherData){ //TODO handle multiple users typing at the same time
		const userId=pusherData.userId
		if(userId!==this.props.userId){
			if(this.userTypingTimeout){
				clearTimeout(this.userTypingTimeout)
			}
			this.setState({userTyping:userId})
			this.userTypingTimeout=setTimeout(function() { 	
				this.clearUserTyping()
			}.bind(this), USER_TYPING_TIMEOUT)
		}
	}

	clearUserTyping(){
		this.setState({userTyping:null})
	}

	handleSaveError(pusherData){
		if(process.env.REACT_APP_ENV !='production'){
			alert(`SAVE ERROR GET NICOLE: ${pusherData.errorType}`)
		}
	}

	handleDocRenamedPusher(pusherData){
		const newName=pusherData.name
		const userId=pusherData.userId
		const updatedAt=pusherData.updatedAt
		this.props.handleDocTimestampUpdatedPusher(this.props.docId,updatedAt)
		if(!isCurrentUser(userId)){
			this.setState({docName:newName,docRenamedBy:userId})
		}
	}

	handleDocUpdatedPusher(pusherData){//Do not poll for updates while this.comm=="send"
		const data=pusherData.data
		if(consoleLogCollabStuff && this.edit){
			console.log('DOC UPDATED PUSHER RECIEVED!')
			console.log(`pusher version: ${data.version} local version:${getVersion(this.edit)}`)
			console.log(`pusher message selection version: ${data.messageSelectionVersion} local version:${messageSelectionPluginKey.getState(this.edit).version}`)
		}
		//TODO handle plugin not loaded yet
		if(this.edit && (data.version>getVersion(this.edit) || data.messageSelectionVersion>messageSelectionPluginKey.getState(this.edit).version)){
			if(this.comm=="send"){
				if(consoleLogCollabStuff){
					console.log('currently sending events --> wait to poll')
				}
				this.pendingPollRequestVersion=data.version
			}else{
				if(consoleLogCollabStuff){
					console.log('not currently sending POST request --> poll for changes')
				}
				this.dispatch({type: "poll"})
			}
		}else{
			if(consoleLogCollabStuff && this.edit){
				console.log('-----------------------------VERSION NOT AHEAD DONT poll for steps---------------')
			}
		}
	}

	////SELECTION//////
	updateCollabSelectionPlugin(selectionData){
		if(window.view && window.view.state && window.view.state.tr){
			if(this.collabCursorTimeout){
				clearTimeout(this.collabCursorTimeout)
			}
			const state=window.view.state
			let tr=state.tr
			tr.setMeta('collabSelectionPlugin', {type: "selectionUpdated",selectionData})
			window.view.dispatch(tr)
			//TEMP fix to hide flags after timeout
			this.collabCursorTimeout=setTimeout(function() {
				if(window.view && window.view.state){
					const state=window.view.state
					let tr=state.tr
					tr.setMeta('collabSelectionPlugin', {type: "timeout"})
					window.view.dispatch(tr)
				}
			},1000)	
		}         
	}

	mapThroughSelections(selections){
		let sendable = sendableSteps(this.edit)
		const unmappedSelections=selections
		let mappedSelections=unmappedSelections
		if(sendable){
			const steps=sendable.steps
			let maps = []
			for (let i = 0; i < steps.length; i++) {
				maps.push(steps[i].getMap())
			}
			const mapping=new Mapping(maps)
			let selectionsArray=Object.keys(selections)
			selectionsArray.map((key)=>{
				let selection={...selections[key]}
				//let anchor = mapping.map(selection.anchor, 1), head = mapping.map(selection.head, -1)
				let anchor = mapping.map(selection.anchor, -1), head = mapping.map(selection.head, -1)
				selection.anchor = anchor
				selection.head = head
				mappedSelections[selection.userId]=selection
			})
		}
		return selections
	}

	handleSelectionUpdatedPusher(pusherData){
		if(`${pusherData.docId}` == `${this.props.docId}` && this.edit){
			const version=getVersion(this.edit) 
			if(consoleLogSelectionStuff){
				console.log(`selection updated pusher currently on version ${version}`)
			}
			const pusherVersion=pusherData.version
			if(consoleLogSelectionStuff){	
				console.log(`selection updated pusherVersion ${pusherData.version}`)
			}
			if(version>pusherVersion){
				if(consoleLogSelectionStuff){	
					console.log('dont update selection!!!!!')
				}
			}else{
				if(consoleLogSelectionStuff){	
					console.log('selection updated!!!!!')
				}
				//need to map server selections through any pending changes on the client that havent been synced yet
				let sendable = sendableSteps(this.edit)
				const unmappedSelections=pusherData.userSelections
				let mappedSelections=this.mapThroughSelections(pusherData.userSelections)
				this.updateCollabSelectionPlugin(pusherData.userSelections)
			}		
		}
	}

	broadcastSelection(editState){
		if(editState && broadcastSelections){  //broadcastSelections is const to switch on/off for dev purposes
			this.sendSelection(editState)
		}
	}

	sendSelection(editState) { //TODO only want to send if is caused by user editing not by their selection being mapped through steps
		const selection=editState.selection
		const { anchor,head } = selection
		const userId=this.props.user['user_id']
		const selectionObj={
			userId:userId,
			type:'text',
			anchor: anchor,
			head: head,
			updatedAt: new Date()
		}
		let json = {
			version: getVersion(editState),
			userId:userId,
			userSelection:selectionObj 
		}
		this.props.sendUserSelection(this.props.docId,json).then(data => {
		}, err => { 
			//console.log(err)
			if (err.response && err.response.status == 409) {
				this.poll()
			}else{
				Sentry.withScope(scope => {
   				scope.setExtra('docId', this.props.docId)
  				scope.setExtra('userId', this.props.userId)
  				scope.setExtra('response',err.response)
  				Sentry.captureException('non 409 error in send selection')
   			})
			}
		})
	}

///////MESSAGES////////

	setThreadParentMessage(parentMessage){
		this.showMessagePanel()
		this.closeIssuePanel()
		this.setState({threadParentMessage:parentMessage})
		store.dispatch({type:'UPDATE_DOC_ACTIVE_THREAD',docId:this.props.docId,activeThread:parentMessage})
		if(parentMessage){
			this.updateThreadRecentActivity(parentMessage)
			const messageObj=find(this.props.messages,{message_id:parentMessage})
			if(messageObj && messageObj.message_selection){
				this.setActiveMessageSelection(messageObj.message_selection)
			}
		}else{
			this.setActiveMessageSelection(null)
		}
	}

	setNewMessageParentMessage(messageId){
		this.closeIssuePanel()
		this.showMessagePanel()
		this.setState({newMessageParentMessage:messageId})
	}
	
	sendUserTypingEvent(docId,userId){
		this.props.sendUserTypingEvent(docId,userId)
	}

	newNodeMessage(nodeId,nodeType,snapshot,isDocThreadParent){ //comments on videos/images
		this.closeIssuePanel()
		this.showMessagePanel()
		if(isDocThreadParent){
			this.setState({isNewDocThread:true,threadParentMessage:null})
		}else{
			this.setState({isNewDocThread:false})
		}

		this.setState({
			newMessageParentNodeId:nodeId,
			newMessageParentNodeType:nodeType,
			newMessageParentNodeSnapshot:snapshot,
			messageTextSnapshot:null,
			messageSelectionId:null
		})
	}

	clearMessageSelection(e){
		if(e){
			e.preventDefault()//dont defocus
		}
		this.setState({
			newMessageParentNodeId:null,
			newMessageParentNodeType:null,
			newMessageParentNodeSnapshot:null,
			messageTextSnapshot:null,
			messageSelectionId:null
		})
		this.setActiveMessageSelection(null)
	}

	// Selection messages 
	// on insert new selection message add messageSelection to plugin (to map through collab stuff)
	// client assigns it an ID (gets created by collab instance on server)
	// set state with text snapshot and id
	// then when create message add snapshot and selection ID

	//Update 21st Nov dont have doc quotes as PM nodes

	//TODO maybe handle multiple quotes?
	deleteMessageSelection(selectionId){ //delete quote from message input box
		this.setState({messageTextSnapshot:null,messageSelectionId:null})
		const state = window.view.state
		const dispatch=window.view.dispatch
		let tr=state.tr
		tr.setMeta('messageSelectionPlugin', {type: "deleteMessageSelection",id:selectionId})
		dispatch(tr)
	}

	cancelNewDocThread(){
		this.setState({isNewDocThread:false,threadParentMessage:null})
		this.clearMessageSelection()
	}

	newSelectionMessage(e,isDocThreadParent){ //this doesn't actually create the message it just adds the selection to the input box
		this.closeIssuePanel()
		this.showMessagePanel()
		if(isDocThreadParent){
			this.setState({isNewDocThread:true,threadParentMessage:null})
		}else{
			this.setState({isNewDocThread:false})
		}

		if(this.props.docType!='privateDoc'){
			const id=`${randomID()}` //so is a string the same as when pulled from DB (bigint-->string)
			addMessageSelection(id)
			let state = window.view.state
			let dispatch=window.view.dispatch
			let {$from, $to,from,to} = state.selection
			const {tr}=state
			let slice=tr.doc.slice($from.pos,$to.pos)
			let plainText=''
			const content = slice.content
			content.descendants((node)=>{
				if(node.type.name=='linearProjectMention'){
					const {projectId}=node.attrs
					const project=getProjectForId(projectId)
					if(project){
						plainText+=project.name
					}
				}else if(node.type.name=='linearIssueMention'){
					const {issueId,showTitle,showIdentifier}=node.attrs
					const issue=getIssueForId(issueId)
					if(issue){					
						if(!showTitle){
							plainText+=`[${issue.identifier}]`
						}
						if(showTitle){
							plainText+=`[${issue.identifier}] ${issue.title}`
						}						
					}		
				}
				else if(node.type.name=='text'){
					plainText+=node.textContent
				}
			})
			this.setState({
				messageTextSnapshot:plainText,
				messageSelectionId:id,
				newMessageParentNodeId:null,
				newMessageParentNodeType:null,
				newMessageParentNodeSnapshot:null
			})
			this.setActiveMessageSelection(id)
		}
	}

	
	setActiveMessageSelection(selectionId){
		if(this.mounted && window.view && window.view.state){
			let tr=window.view.state.tr
			const dispatch=window.view.dispatch
			tr.setMeta('messageSelectionPlugin', {type: "setActiveSelection",activeSelectionId:selectionId})
			dispatch(tr)
		}
	} 

	//plain text so need to convert to html etc TODO Think about threads
	sendMediaModalMessage(nodeId,nodeType,snapshot,messageText){
		this.closeIssuePanel()
		const {docId}=this.props
		const project = this.props.doc.project
		let orgId
		if(this.props.organization){
			orgId=this.props.organization['organization_id']
		}
		const id=`${randomID()}`
		const timestamp=new Date()
		const plain_text = messageText
		const html=`<div class=\"message-para\" data-indent-level=\"0\">${messageText}</div`
		let newMessage={
			message_id:`${id}`,
			parent_doc:docId,
			organization:orgId,
			project:project,
			parent_message:null, 
			thread_parent:null,
			created_at:timestamp,
			created_by:this.props.userId,
			message_type:'richText',
			message_media_url:null,
			plain_text:plain_text,
			source:null,
			html:html,
			selection_type:nodeType,
			selection_node_id:`${nodeId}`,
			selection_snapshot:snapshot,
			message_selection:null,
			mentions:[],
			auto_created:false
		}
		this.props.createMessage(newMessage,orgId)
		this.onCreateMessage(id)
	}

	sendDocMessage(messageContent,threadParentMessage){
		let orgId
		let project = this.props.doc.project
		if(this.props.organization){
			orgId=this.props.organization.organization_id
		}
		const id=randomID() //client generated message id (so can update redux before api creates message)
		const timestamp=new Date()
		const {docId}=this.props
		let newMessage={
			message_id:`${id}`,
			parent_doc:docId,
			organization:orgId,
			project:project,
			parent_message:null, //for whatsapp replies
			thread_parent:null, //for threaded replies
			created_at:timestamp,
			created_by:this.props.userId,
			message_type:messageContent.type,
			message_media_url:messageContent.media_url,
			plain_text:messageContent.plain_text,
			source:messageContent.source,
			html:messageContent.html,
			selection_type:null,
			selection_node_id:null,
			selection_snapshot:null,
			message_selection:null,
			in_doc_thread:false,
			mentions:messageContent.mentions,
			auto_created:false
		}
		if(this.state.isNewDocThread){
			newMessage.in_doc_thread=true
		}

		if(this.state.newMessageParentNodeId){ //Node selection message
			newMessage.selection_node_id=`${this.state.newMessageParentNodeId}`
			newMessage.selection_type=this.state.newMessageParentNodeType
			newMessage.selection_snapshot=this.state.newMessageParentNodeSnapshot
		}else if(this.state.messageTextSnapshot){
			newMessage.selection_type='docSelection'
			newMessage.selection_snapshot={text:this.state.messageTextSnapshot}
			newMessage.message_selection=this.state.messageSelectionId
		}
		if(this.state.newMessageParentMessage){
			newMessage.parent_message=this.state.newMessageParentMessage.message_id
		}
		if(threadParentMessage){
			newMessage.thread_parent=threadParentMessage
		}
		this.props.createMessage(newMessage,orgId)
		if(newMessage.in_doc_thread){
			this.setState({threadParentMessage:newMessage.message_id})
		}
		this.onCreateMessage(id)
	}

	onCreateMessage(messageId){//scroll into view and reset state
		this.setState({
			messageTextSnapshot:null,
			messageSelectionId:null,
			newMessageParentMessage:null,
			newMessageParentNodeId:null,
			newMessageParentNodeType:null,
			newMessageParentNodeSnapshot:null,
			isNewDocThread:false
		})
		this.scrollTimeout=setTimeout(function() { //THis is for testing it breaking
			let div=document.getElementById(`message_${messageId}`)
			if(div){
				div.scrollIntoView()
			}
		}.bind(this), 50)	
	}

///////MISC///////
	handleEditorClick(){
		this.setActiveMessageSelection(null)
	}

	onDocNameUpdate(value){ 
		this.setState({docName:value})
		this.props.modifyDocNameLocally(this.props.docId,value)
	}
	
	insertImage(file,isThumbnail){
		// return this.props.uploadImageToCloudinary(file).then((response)=>{
		// 	const src=response.url
		// 	const height=response.height
		// 	const width=response.width
		// 	const title=''
		// 	const description=''
		// 	if(isThumbnail){
		// 		insertThumbnail(src,height,width,title,description)
		// 	}else{
			insertImage(file,this.props.docId)
		// 	}
		// })
	}

	insertDrawing(src,height,width,title,description){
		insertDrawing(src,height,width,title,description)
	}

	archiveDoc(){
		this.props.archiveDoc(this.props.docId).then(()=>{
			this.props.history.push('/')
		})
	}

	onScroll(e){ //save scroll amount so when come back to doc is scrolled the same amount
		const el=document.getElementById('scrollable_div')
		if(el){
			this.setState({scrollTop:el.scrollTop})
			this.saveScrollAmount(el.scrollTop)
		}
	}
	
	saveScrollAmount(scrollTop){//TODO maybe only need to save scroll on unmount
		this.props.saveDocScrollPosition(this.props.docId,scrollTop)
	}

	setScrollAmount(){
		if(this.props.savedDocScrollAmount){
			const el=document.getElementById('scrollable_div')
			if(el){
				el.scrollTop=this.props.savedDocScrollAmount
			}
		}
	}

/////EDITOR////////
	startEditingInstance(isRestart){ //seperated this out so can restart when shit gets messed up
		const {refetchCount}=this.state 
		if(refetchCount<4){ //try to fetch instance max 3 times
			const docId=this.props.docId
			if(!isRestart && this.props.collabInstance){ //use collab instance in redux
				const data=this.props.collabInstance
				if(this.mounted){ //ignore if component no longer mounted
					this.backOff = 0
					this.dispatch({type: "loaded",
						doc: editorSchema.nodeFromJSON(data.doc),
						version: data.version,
						messageSelections:{version: data.messageSelectionVersion, messageSelections: data.messageSelections},
						userSelections:data.userSelections
					})	
				}
			}else{
				this.props.getDocInstance(this.props.docId).then((data)=>{
					if(this.mounted){ //ignore if component no longer mounted!
						this.backOff = 0
						this.dispatch({type: "loaded",
							doc: editorSchema.nodeFromJSON(data.doc),
							version: data.version,
							messageSelections:{version: data.messageSelectionVersion, messageSelections: data.messageSelections},
							userSelections:data.userSelections
						})
						if(isRestart){
							this.setState({restartCount:this.state.restartCount+1})
						}
					}
				})
			.catch((error)=>{
				this.setState({refetchCount:refetchCount+1})
				this.refetchTimout= setTimeout(() => { 
					this.startEditingInstance()
				},500)
			})
		}
	}
	}

	handlePaste(view, event,slice) {
		var text = event.clipboardData.getData('text')
		const inTable = isInTable(view.state)
		if(isFigmaLink(text,this.props.orgUrlKey) ){ //TODO update isFigmaLink to use the reged in insert figma embed thing
			if(inTable){
				const nodeType = editorSchema.nodes.textLink;
				const textContent = text
    		const type='weblink'
    		const url=text
    		const newNode = nodeType.create({type:type,url:url}, editorSchema.text(textContent));
      	const state = window.view.state 
      	const tr = state.tr;
      	tr.replaceWith(state.selection.from,state.selection.to,newNode)
				window.view.dispatch(tr)
    		return true

			}else{
				insertFigmaEmbed(text,this.props.doc)
				// insertVideo(text)
				return true
			}
		}
		else if(text && isLinearIssueIdentifier(text)){
			const issueIdentifier=isLinearIssueIdentifier(text)
			const issueObj=getIssueForIdentifier(issueIdentifier)
			if(issueObj){
				insertIssueFromPaste(issueObj.issue_id)
				return true
			}
		}
		else{
			var clipboardData = event.clipboardData
			var files = clipboardData.files
			if(!files || !files.length) {
				var html = event.clipboardData.getData('text/html')
				if(html){
					var regExp = '<img[^>]* src="([^"]*)"[^>]*>'
					var match = html.match(regExp)
					//check if has an image in it
					if(match && match[1]){ //handle google docs images
						const src=match[1]
						let isThumbnail=false
						if(this.state.shiftKeyDown){
							isThumbnail=true
						}
						this.insertImage(src,isThumbnail)
					return true
				}
			}
		}
		if(files[0] && files[0].size !== 0){ //when copy paste from Mac PowerPoint clipboard data has an image file with size 0 (we want to ignore this)
			const text=event.clipboardData.getData('text')
			if(!text){ //for copy paste from windows PP (clipboard data has non zero image file) check if there if there is text before uploading
				let isThumbnail=false
				if(this.state.shiftKeyDown){
					isThumbnail=true
				}
				this.insertImage(files[0],isThumbnail)
				return true
				}
			}
		}
		return false
	}

	handleOpenDoc(docId){
		openDoc(this.props.history,docId)
	}

	handleSelectionMenuUpdate(){
		let newItems=Object.assign({},menuItems)
		Object.keys(menuItems).forEach((key)=>{
			const menuItem=newItems[key]
			const command=menuItem.command
			let isActive=false
			let isAllowed=false
			if(menuItem.mark && markActive(this.edit, editorSchema.marks[menuItem.mark]) ){
				isActive=true
			}else if(menuItem.nodeType && nodeActive(this.edit,menuItem.nodeType,menuItem.attrs) ){
				isActive=true
			}
			isAllowed = command(this.view.state, null, this.view)
			newItems[key].isActive=isActive
			newItems[key].isAllowed=isAllowed
		})
		this.menuItems=newItems
		this.setState({menuKey:this.state.menuKey+1})
	}

	


	dispatch(action) {// All PM state changes go through this
		let newEditState = null
		const handleOpenDoc=this.handleOpenDoc
		const handleSelectionMenuUpdate=this.handleSelectionMenuUpdate
		const setThreadParentMessage=this.setThreadParentMessage
		const openIssuePanelForUser = this.openIssuePanelForUser
		const docId=this.props.docId
		if (action.type == "loaded") { //make editor after load doc from API
			setInitialMessageSelections(action.messageSelections.messageSelections)
			const newSelectionMessage=this.newSelectionMessage

			let editState
				editState = EditorState.create({
					doc: action.doc,
					plugins:[
						insertPlugin(docId,insertPluginKey), //put this at the top otherwise enter key seems to be blocked by other plugins
						mentionsPlugin(mentionPluginKey),
						insertEmojiPlugin(insertEmojiPluginKey),
						...codeMarkPlugins,
						history(),
						keymap({
						//"Backspace":maybeIndentLessCommand,

						"Backspace": chainCommands(maybeIndentLessCommand,deleteWithClearingMarks),
  					"Delete": deleteWithClearingMarks,
						"Alt-ArrowRight":maybeAddTableColumnAfter,
						"Alt-ArrowLeft":maybeAddTableColumnBefore,
						"Alt-ArrowUp":maybeAddTableRowBefore,
						"Alt-ArrowDown":maybeAddTableRowAfter,
						"Tab":chainCommands(goToNextCell(1),indentMoreCommand),
						"Shift-Tab":chainCommands(goToNextCell(-1),indentLessCommand),
						"Mod-z": undo,
						"Mod-y": redo,
						'Mod-b': toggleMark(editorSchema.marks.strong),
						'Mod-i': toggleMark(editorSchema.marks.em),
						'Mod-u': toggleMark(editorSchema.marks.underline),
						'Cmd-Shift-k': toggleMark(editorSchema.marks.light),
						'Cmd-Shift-b':insertContentBoxCommand(),
						'Cmd-Shift-g':insertCodeBlockCommand(),
						// 'Cmd-Shift-l':insertLoremIpsum,
						'Cmd-Alt-1':setBlockType(editorSchema.nodes.heading,{level:1}),
						'Cmd-Alt-2':setBlockType(editorSchema.nodes.heading,{level:2}),
						'Shift-Mod-x': toggleMark(editorSchema.marks.strike),
						"Shift-Enter":chainCommands(insertLineBreakCommand()),
						"Enter":chainCommands(splitBulletListCommand(editorSchema.nodes.bulletListItem,insertPluginKey,mentionPluginKey),splitBulletListCommand(editorSchema.nodes.toDoItem,insertPluginKey,mentionPluginKey)), //https://discuss.prosemirror.net/t/addlistnodes/684/4					
						"Cmd-Enter":toggleToDoCheckedCommand,
						"Cmd-.":toggleParagraphToToDoItem,
						"Cmd-Shift-c":newSelectionMessage		,
						"Cmd-k":showNewTextLinkModal,
						}),
						keymap(baseKeymap),
						buildInputRules(editorSchema),
						collab({version: action.version}),
						paragraphSpanPlugin(),
						selectionMenuPlugin(handleSelectionMenuUpdate),
						collabSelectionPlugin(action.userSelections),
						messageSelectionPlugin(messageSelectionPluginKey),
						headingPlaceholderPlugin(),
						deleteHeadingPlugin(),
						emptyDocPlaceholderPlugin(docId),
						mediaSelectionStylingPlugin(),
						cursorInsideNodePlugin(),
						orderedListPlugin(),
						selectedNodeStylingPlugin(),
						textLinkPlugin(),
						toggleListItemPlugin(),
						inDocSearchPlugin,
						columnResizing(),
						tableCellPlugin(mentionPluginKey),
						columnHandles(),
						//tableCellUIPlugin(),
						tableEditing(),
						tablePastingPlugin(),
						//observeTableWidth
						],
						messageSelections: action.messageSelections,
					})
				this.edit=editState
				this.comm="poll" //TODO check we can remove this initial poll request (PM example has it because keeps connection open)
			}else if (action.type == "restart") {
				this.edit=null
				this.comm="start"
				this.startEditingInstance(true)
			} else if(action.type == "poll"){
				this.comm="poll"
				this.poll()
			} else if (action.type == "recover") { //TODO do recover bit properly
				if(action.error.response && action.error.response.status < 500) {	//tested this and it seems to work and restart the instance
					this.edit=null
					this.comm="start"
					this.startEditingInstance()
				} else {
					console.log('it was a >500 error lets recover')
					this.comm="recover"
					this.recover(action.error)
				}
			} else if (action.type == "transaction") {
				newEditState = this.edit.apply(action.transaction)
			}else if (action.type == "sendRequestTimeout") {
				if(this.comm=="send"){
					this.sendRequestTimeout=null
					this.activeSendRequestId=null
					newEditState = this.edit
					this.comm="poll"
				}
			}

			if(newEditState){ //TODO add max doc size like in the PM example?
				let sendable= this.sendable(newEditState)
				if(!sendable && newEditState.selection!=this.edit.selection){ //TODO only send if not sendable
					if(!action.requestDone){ //don't broadcast selection after applying steps from another client
						this.broadcastSelection(newEditState)
					}				
				}
				if((this.comm=="poll" || action.requestDone) && sendable) {
					this.edit=newEditState
					this.comm="send"
					this.send(newEditState, sendable)
				}else if (action.requestDone) {
					this.edit=newEditState
					this.comm="poll" //release "lock" on new request once current requests finishes TODO: add timeout
					const newVersion=getVersion(newEditState)			
					if(this.pendingPollRequestVersion ){
						if(this.pendingPollRequestVersion >newVersion){
							this.comm="poll"
							this.poll() //TODO maybe want to dispatch but want the tr to go through first i dunno
						}
						this.pendingPollRequestVersion=null
					}
				} else {
					this.edit=newEditState
				}
			}
			// Sync the editor with this.state.edit
			if(this.edit) {
				if(this.view){
					this.view.updateState(this.edit)
				}else{
					const newNodeMessage=this.newNodeMessage
					const sendMediaModalMessage=this.sendMediaModalMessage
					this.setView(new EditorView(document.querySelector("#editor"), {
						state: this.edit,
						attributes: {
							class: "doc",
							spellCheck: false,
						},
						handlePaste:this.handlePaste,
						transformPasted:transformPasted,
						// transformCopied:transformCopied,
						nodeViews: {
							docImage(node, view, getPos) { return new DocImageNodeView(node, view, getPos,newNodeMessage,docId) },
							docFigmaEmbed(node, view, getPos) { return new DocFigmaEmbedNodeView(node, view, getPos,newNodeMessage,docId,sendMediaModalMessage,setThreadParentMessage) },
							docVideo(node, view, getPos) { return new DocVideoNodeView(node, view, getPos,newNodeMessage,sendMediaModalMessage) },						
							docDrawing(node, view, getPos) { return new DocDrawingNodeView(node, view, getPos) },
							docThumbnail(node, view, getPos) { return new DocThumbnailNodeView(node, view, getPos) },
							linearIssueMention(node, view, getPos) { return new LinearIssueMentionNodeView(node, view, getPos) },
							linearProjectMention(node, view, getPos) { return new LinearProjectMentionNodeView(node, view, getPos,handleOpenDoc) },
							internalLink(node, view, getPos) { return new InternalLinkNodeView(node, view, getPos,handleOpenDoc) },		
							toDoItem(node, view, getPos) { return new ToDoItemNodeView(node, view, getPos) },	
							bulletListItem(node, view, getPos) { return new BulletListItemNodeView(node, view, getPos) },		
							docDate(node, view, getPos) { return new DocDateNodeView(node, view, getPos) },		
							emoji(node, view, getPos) { return new EmojiNodeView(node, view, getPos) },	
							textLink(node, view, getPos) { return new TextLinkNodeView(node, view, getPos,handleOpenDoc) },			
							toggleListItem(node, view, getPos) { return new ToggleListItemNodeView(node, view, getPos) },		
							docTable(node, view, getPos) { return new DocTableNodeView(node, view, getPos,newNodeMessage,docId) },	
							docWhiteboardEmbed(node, view, getPos) { return new DocWhiteboardEmbedNodeView(node, view, getPos,newNodeMessage,docId) },				
							participantHeader(node, view, getPos) { return new ParticipantHeaderNodeView(node, view, getPos,openIssuePanelForUser) },		
							docCodeMirror(node, view, getPos) { return new DocCodeMirrorNodeView(node, view, getPos) },								
						},
						handleDOMEvents: { //we disable all of these events
							drop(view, event) { handleDragAndDropEvent(event)},
							dragstart(view, event) { handleDragAndDropEvent(event) },
							dragend(view, event) { handleDragAndDropEvent(event) },
							dragover(view, event) { handleDragAndDropEvent(event) }
						},
						handleKeyDown:this.handleKeyDown,
						handleClick:this.handleEditorClick,
						handleTripleClickOn:this.handleTripleClickOn,
						dispatchTransaction: transaction => this.dispatch({type: "transaction", transaction})
					}))
				//applyDevTools(this.view) //this can cause unmount react component error when uncommented
				window.requestAnimationFrame(this.setScrollAmount)
				//set active message selection to thread 
				if(this.props.activeThread){
				const messageObj=find(this.props.messages,{message_id:this.props.activeThread})
				if(messageObj && messageObj.message_selection){
					this.setActiveMessageSelection(messageObj.message_selection)
				}
			}
			}
		} else this.setView(null)
	}


  handleTripleClickOn(view, pos,node){
    if(node.type.name=='docTable'){
    	return true
    }
    return false
  }


	setView(view) {
		if(this.view){
			this.view.destroy()
		}
		this.view = window.view = view
	}
		
	//TODO check its ok to have a poll and post events request at the same time (maybe look into aborting requests)
	// Send a request for events that have happened since the version
	// of the document that the client knows about.
	poll() {
		const requestVersion=getVersion(this.edit)
		//test breaking!!!const requestVersion=getVersion(this.edit)-1
		const messageSelectionVersion=messageSelectionPluginKey.getState(this.edit).version
		let query = "version=" + getVersion(this.edit) + "&messageSelectionVersion=" + messageSelectionVersion
		const url=`/docs/${this.props.docId}`
		if(consoleLogCollabStuff){
			console.log(`poll--------  REQUEST FOR LOCAL VERSION ${requestVersion}`)
		}
		this.props.fetchDocumentEvents((url + "/events?" + query)).then(data => {
			if(data && data.requestVersion != requestVersion){
				if(consoleLogCollabStuff){
					console.log(data)
					console.log(data.requestVersion)
					console.log(requestVersion)
				}
				Sentry.withScope(scope => {
					scope.setExtra('docId', this.props.docId)
					scope.setExtra('userId', this.props.userId)
					scope.setExtra('data',data)
					scope.setExtra('this.requestVersion',requestVersion)
					Sentry.captureException('this.requestVersion different from data.requestVersion')
				})
			}
			this.backOff = 0
			if (data.steps && (data.steps.length || data.messageSelection.length)) {
				const currentVersion=getVersion(this.edit)
				const currentMessageSelectionVersion=messageSelectionPluginKey.getState(this.edit).version
				if(requestVersion!==currentVersion || messageSelectionVersion!==currentMessageSelectionVersion || this.comm=="send"){ //edits have been made while polling so poll again
					if(consoleLogCollabStuff){
						console.log(`call this.poll from 11111111 request version ${requestVersion} currentVersion${currentVersion}`)
						console.log(requestVersion!==currentVersion)
						Sentry.withScope(scope => {
   						scope.setExtra('docId', this.props.docId)
  						scope.setExtra('userId', this.props.userId)
  						scope.setExtra('response',err.response)
  						Sentry.captureException('poll error current version different to request version')
   					})
					}
					this.dispatch({type: "poll"})
				}else{
					if(data.requestVersion != requestVersion){
						Sentry.withScope(scope => {
   						scope.setExtra('docId', this.props.docId)
  						scope.setExtra('userId', this.props.userId)
  						scope.setExtra('data',data)
							scope.setExtra('this.requestVersion',requestVersion)
  						Sentry.captureException('APPLYING THE WRONG POLL STEPS!!!!!!!')
   					})
					}
					let tr = receiveTransaction(this.edit, data.steps.map(j => Step.fromJSON(editorSchema, j)), data.clientIDs)
					tr.setMeta('messageSelectionPlugin', {type: "receive", version: data.messageSelectionVersion, events: data.messageSelection, sent: 0})
					tr.setMeta("isSyncTransaction",true) //for collab selection plugin
					let mappedSelections=this.mapThroughSelections(data.userSelections)
					tr.setMeta("userSelections",data.userSelections) //TODO need to map these through pending steps
					this.dispatch({type: "transaction", transaction: tr, requestDone: true,isSyncTransaction:true})
				}
			} else {
				if(consoleLogCollabStuff){
					console.log('no steps in response for poll request')
				}
			}

		}, err => {
			if ((err && err.response && err.response.status == 410) || badVersion(err)) {  // Too far behind (server stores max number of steps). Revert to server state
				console.log('410 or bad version in poll request------------- RESTART')
				// Too far behind. Revert to server state
				Sentry.withScope(scope => {
   				scope.setExtra('docId', this.props.docId)
  				scope.setExtra('userId', this.props.userId)
  				scope.setExtra('response',err.response)
  				Sentry.captureException('Restart editor- invalid version in poll request')
   			})
				if(process.env.REACT_APP_ENV !='production'){
						alert(`restart: get nicole`)
					}
				this.dispatch({type: "restart"})
			} else if (err) {
				Sentry.withScope(scope => {
   				scope.setExtra('docId', this.props.docId)
  				scope.setExtra('userId', this.props.userId)
  				scope.setExtra('response',err.response)
  				Sentry.captureException('Editor recover from poll')
   			})

				this.dispatch({type: "recover", error: err})
			}
		})
	}


	sendable(editState) {//https://prosemirror.net/docs/ref/#collab.sendableSteps
		let steps = sendableSteps(editState) //returns unconfirmed steps that need to be sent to central authority
		let messageSelections = messageSelectionPluginKey.getState(editState).unsentEvents()
		if (steps || messageSelections.length) return {steps, messageSelections}
	}

	//Send steps requests timed out (otherwise if miss response client will get stuck not sending events to server) 
	//give each request a randID and start a timer with that ID
	//when request resolves cancel timer
	//if timer is not cancelled then dispatch timeout action
	//if response is received after timout then do not apply steps in response 
	send(editState, {steps, messageSelections}) {
		const {docId}=this.props
		const requestNumber=this.requestNumber
		this.requestNumber+=1 //this is just for dev testing	
		const messageSelectionVersion=messageSelectionPluginKey.getState(this.edit).version
		let json = {
			version: getVersion(editState),
			messageSelectionVersion:messageSelectionVersion,
			steps: steps ? steps.steps.map(s => s.toJSON()) : [],
			clientID: steps ? steps.clientID : 0,
			messageSelection: messageSelections || []
		}
		if(consoleLogCollabStuff){
			console.log(`--------------------------req num ${requestNumber} send steps to server------------------------`)
			console.log(json)
		}
		const selection=editState.selection
		const { anchor,head } = selection
		const userId=this.props.user['user_id']
		const selectionObj={
			userId:userId,
			type:'text',
			anchor: anchor,
			head: head,
			updatedAt: new Date()
		}
		json.userSelection=selectionObj
		const version=getVersion(editState)
		const requestId=randomID()
		this.activeSendRequestId=requestId
		this.sendRequestTimeout=setTimeout(function() { //THis is for testing it breaking
			console.log(`---------------------timeout after send events request ${requestId}`)
			this.dispatch({type: "sendRequestTimeout"}) 
			Sentry.withScope(scope => {
   			scope.setExtra('docId', this.props.docId)
  			scope.setExtra('userId', this.props.userId)
  			//scope.setExtra('steps',steps)
  			Sentry.captureException('send events request timeout')
   	})
		}.bind(this), SEND_EVENTS_REQUEST_TIMEOUT)

		this.props.sendDocumentEvents(docId,json).then(data => {		
			if(this.activeSendRequestId==requestId && version==getVersion(this.edit)){
				if(this.sendRequestTimeout){
					clearTimeout(this.sendRequestTimeout)
					this.sendRequestTimeout=null
					this.activeSendRequestId=null
				}
				//this.backOff=0
			if(consoleLogCollabStuff){
					console.log(	`req num ${requestNumber} document events accepted!!!!! response is`)
					console.log(data)
				}
				let tr = steps
						? receiveTransaction(this.edit, steps.steps, repeat(steps.clientID, steps.steps.length))
						: this.edit.tr

				tr.setMeta('messageSelectionPlugin', {type: "receive", version: data.messageSelectionVersion, events: [], sent: messageSelections.length})
				this.dispatch({type: "transaction", transaction: tr, requestDone: true})
			}else{
				//console.log(`response is for an old request------> ignore response ${requestId}`)
		}

		}, err => {
			if(this.activeSendRequestId==requestId){
				if(this.sendRequestTimeout){
					clearTimeout(this.sendRequestTimeout)
					this.sendRequestTimeout=null
					this.activeSendRequestId=null
				}else{
				//	console.log('response received too late timout already cleared')
				}

				if (err.response && err.response.status == 409) {
					this.backOff=0
					if(consoleLogCollabStuff){
						console.log(`request number ${requestNumber} 409==============conflict---- poll for changes for version ${version}`)
					}
					
					// The client's document conflicts with the server's version.
					// Poll for changes and then try again.
					//this.backOff = 0
					//this.dispatch({type: "poll"})
					if(consoleLogCollabStuff){
						console.log(`request number ${requestNumber} call this.poll from 222222222 409 in send events!!!`)
					}
					
					this.dispatch({type: "poll"}) //TODO check if should do dispatch or call directly
					// this.poll()
				} else if (badVersion(err)) {
						Sentry.withScope(scope => {
   						scope.setExtra('docId', this.props.docId)
  						scope.setExtra('userId', this.props.userId)
  						scope.setExtra('response',err.response)
  						Sentry.captureException('Send Events Restart editor- invalid version')
   					})
					this.dispatch({type: "restart"})
					if(process.env.REACT_APP_ENV !='production'){
						alert(`restart: get nicole`)
					}
						
				} else {
					// console.log('^^^^^^^^^^^ important')
					// console.log('recover from send events error')
					//console.log(err)
						Sentry.withScope(scope => {
	   				scope.setExtra('docId', this.props.docId)
	  				scope.setExtra('userId', this.props.userId)
	  				scope.setExtra('response',err.response)
	  				Sentry.captureException('Send events recover editor- invalid version')
	   			})
					this.dispatch({type: "recover", error: err})
				}
			}else{
				console.log('error for expired send request')
				Sentry.withScope(scope => {
   				scope.setExtra('docId', this.props.docId)
  				scope.setExtra('userId', this.props.userId)
  				scope.setExtra('response',err.response)
  				Sentry.captureException('Error for expired send request')
   			})
			}
		})
	}

	 // Try to recover from an error
	 //TODO figureout what all the backoff stuff is about- can we just have a constant
	 //wait increasingly longer amount of time between recover requests?
	recover(err) {
		let newBackOff = this.backOff ? Math.min(this.backOff * 2, 6e4) : 200
		this.backOff = newBackOff
		setTimeout(() => {
			if (this.comm == "recover"){
				//console.log('in this bit here---- do a poll request')
				this.dispatch({type: "poll"})
			}
		}, this.backOff)
	}

	closeRequest() {
		if(consoleLogCollabStuff){console.log('----------close request--------')}
		if (this.request) { //not sure if need to close this because not keeping it open (like the do in PM example)
			this.request.abort()
			this.request = null
		}
	}

	/// Doc types
	// Private (doc.team==null && doc.project==null) - don't show message panel, show UI to move to project or team
	// Project Doc (doc.project==X && doc.is_project_doc==true)- parent doc, show TLDR and project sub docs
	// Project Sub Doc (doc.project==X && doc.is_project_doc==false)- Manual followers stuff
	// Team Doc (doc.team==X)
	render() {
		let pmDoc={}
		let docIsEmpty=false
		if(this.edit){
			pmDoc=this.edit.doc
			if(pmDoc.nodeSize<7){
				docIsEmpty=true
			}
		}    
		if(!this.state.unauthError){
			let docTitle='Yarn'
			if(this.props.doc){
				docTitle=`Yarn - ${this.props.doc.name}`
			}
		const {docId,doc,docType}=this.props
		const videoPlayerZoomed = this.props.videoModalIsZoomed


		let notificationCount = 0 
		let showUnreadsIndicatorDocChatBtn = false
		if(this.props.doc){
			notificationCount = calculateDocNotificationCount(this.props.doc.doc_id,this.props.doc)
			if(notificationCount>0){
				showUnreadsIndicatorDocChatBtn=true
			}
		}
		

		return (
		<DocumentTitle title={docTitle}>
			<div id="docEditorContainer_div" className={'docEditorContainer ' + (this.props.sideMenuVisible ? ' docEditorContainer--sideMenuState--visible ' : ' docEditorContainer--sideMenuState--hidden ') + (window.isElectron ? ' docEditorContainer--electron ' : ' docEditorContainer--web ') + (this.props.figmaModalIsZoomed && ' docEditorContainer--figmaModalIsZoomed ') + (this.props.messageMediaIsZoomed && ' docEditorContainer--messageMediaIsZoomed ')}>
				<DocEditor  
					docType={docType}
					user={this.props.user}
					userId={this.props.userId}
					pmDoc={pmDoc} 
					doc={this.props.doc} 
					docId={this.props.docId}
					docName={this.state.docName}
					docRenamedBy={this.state.docRenamedBy}
					onDocNameUpdate={this.onDocNameUpdate}
					archiveDoc={this.archiveDoc}
					sendDocMessage={this.sendDocMessage}
					messages={this.props.messages}
					newMessageParentMessage={this.state.newMessageParentMessage}
					setNewMessageParentMessage={this.setNewMessageParentMessage}
					//showInsertDrawingModal={this.props.showInsertDrawingModal}			
					reactions={this.props.reactions}
					addReactionToMessage={this.addReactionToMessage}
					deleteReaction={this.props.deleteReaction}
					insertImage={this.insertImage}
					insertDrawing={this.insertDrawing}
					docPresenceMembers={this.props.docPresenceMembers}
					scrollTop={this.state.scrollTop}
					restartCount={this.state.restartCount}					
					sideMenuVisible={this.props.sideMenuVisible}
					orgMembers={this.props.orgMembers}
					orgPresenceMembers={this.props.orgPresenceMembers}
					sendUserTypingEvent={this.sendUserTypingEvent}
					userTyping={this.state.userTyping}
					deleteMessage={this.props.deleteMessage}
					orgId={this.props.orgId}					
					messageTextSnapshot={this.state.messageTextSnapshot}
					messageSelectionId={this.state.messageSelectionId}
					clearMessageSelection={this.clearMessageSelection}
					newMessageParentNodeType={this.state.newMessageParentNodeType}
					newMessageParentNodeSnapshot={this.state.newMessageParentNodeSnapshot}
					recentActivities={this.props.recentActivities} //move filtering down a level to prevent rerender
					deleteMessageSelection={this.deleteMessageSelection}
					setActiveMessageSelection={this.setActiveMessageSelection}
					uploadImageToCloudinary={this.props.uploadImageToCloudinary}
					onScroll={this.onScroll}
					sendMediaModalMessage={this.sendMediaModalMessage}
					newSelectionMessage={this.newSelectionMessage}
					projects={this.props.projects}
					docTabs={this.props.docTabs} //to force rerender when starred tabs changes
					updateRecentActivity={this.updateRecentActivity}
					updateThreadRecentActivity={this.updateThreadRecentActivity}
					threadId={this.props.threadId}
					menuItems={this.menuItems}
					issuePanelIsVisible={this.props.issuePanelIsVisible}
					issuePanelUser={this.props.issuePanelUser}
					issuePanelProject={this.props.issuePanelProject}
					updatedAtTimestamp={this.props.updatedAtTimestamp}
					docSearchResults={this.props.docSearchResults}
					messageSelectionPluginKey={messageSelectionPluginKey}
					threadParentMessage={this.state.threadParentMessage}
					isNewDocThread={this.state.isNewDocThread}
					setThreadParentMessage={this.setThreadParentMessage}
					cancelNewDocThread={this.cancelNewDocThread}
					meeting={this.props.meeting}
					messagePanelIsVisible={this.props.messagePanelIsVisible}
					highlights={this.props.highlights}
					projectConnections={this.props.projectConnections}
					docMessages={this.props.docMessages}
					showUnreadsIndicatorDocChatBtn={showUnreadsIndicatorDocChatBtn}
					updateRecentActivity={this.updateRecentActivity}
					docIsEmpty={docIsEmpty}
					templateModalVisible={this.props.templateModalVisible}
					whiteboardModalIsVisible={this.props.whiteboardModalIsVisible}
					whiteboardModalEmbedId={this.props.whiteboardModalEmbedId}
					theme={this.props.theme}
					whiteboardSettings={this.props.whiteboardSettings}
				/>        
			</div>
			</DocumentTitle>
		)   
	}else return <UnauthErrorPage/> 
	}
}

function mapStateToProps(state,ownProps) {
	const docId=ownProps.docId
	const doc = find(state.docs, {'doc_id':docId})
	const activeThread=state.activeThreads[docId]
	let updatedAtTimestamp
	let meeting
	let recording
	let transcript
	let highlights=[]
	let projectConnections=[]
	if(doc){
		updatedAtTimestamp=doc.updated_at
		if(doc.meeting){
			meeting = find(state.meetings, {'meeting_id':doc.meeting})
			recording = find(state.recordings, {'meeting':doc.meeting})
			projectConnections = filter(state.meetingProjectConnections,{meeting:doc.meeting})
			if(recording){
				transcript = find(state.transcripts,{transcript_id:recording.transcript_id})
				highlights = filter(state.highlights,{recording:recording.recording_id})
			}
		}
	}
	let docType 
	if(doc){
		if(doc.meeting){
			docType='meetingDoc'
		}else if(doc.is_org_doc){
			docType='orgDoc'
		}
		else if(doc.roadmap){
			docType='roadmapDoc'
		}
		else if(doc.project){
			if(doc.is_project_doc){
				docType='projectRootDoc'
			}else{
				docType='projectSubDoc'
			}
		}else{
			docType='privateDoc'
		}
	}


	const docMessages=filter(state.messages,function(message){
		if(message['parent_doc']==docId){
			return message
		}
	})

	const sortedDocMessages=sortBy(docMessages,'created_at')
	const collabInstance=state.collabInstances[docId]

	let savedDocScrollAmount=0
	if(state.docScrollPositions[docId]){
		savedDocScrollAmount=state.docScrollPositions[docId]
	}
	let userId
	if(state.user){
		userId=state.user['user_id']
	}
	let orgId
	let orgUrlKey
	if(state.organization){
		orgId=state.organization['organization_id']
		orgUrlKey=state.organization.url_key
	}

	let figmaModalIsZoomed=false
	if(state.docEditorMediaModals && state.docEditorMediaModals.zoomedFigma && state.docEditorMediaModals.zoomedFigma.visible){
		figmaModalIsZoomed=true
	}
	let issuePanelIsVisible=false
	let issuePanelProject
	let issuePanelUser
	if(state.issuePanels && state.issuePanels[docId]){
		issuePanelIsVisible=state.issuePanels[docId].isVisible
		issuePanelProject = state.issuePanels[docId].project
		issuePanelUser = state.issuePanels[docId].userId
	}
	let messagePanelIsVisible = true 
	if(docType=='meetingDoc'){
		messagePanelIsVisible = false
	}
	if(state.messagePanels[docId] || state.messagePanels[docId]==false){
		messagePanelIsVisible=state.messagePanels[docId]
	}
	//console.log(`docType----- ${docType}`)
	return {
		meeting:meeting,
		highlights:highlights,
		recording:recording,
		transcript:transcript,
		projectConnections:projectConnections,
		templateModalVisible:state.docTemplatesModal.visible,
		figmaModalIsZoomed:figmaModalIsZoomed,
		orgUrlKey:orgUrlKey,
		docType:docType,
		messages:state.messages,
		docMessages:sortedDocMessages,
		orgPresenceMembers:state.orgPresenceMembers,
		collabInstance:collabInstance,
		savedDocScrollAmount:savedDocScrollAmount,
		organization:state.organization,
		orgMembers:state.orgMembers,
		orgId:orgId,
		doc:doc,
		user:state.user,
		userId:userId,
		docId:docId,
		reactions:state.reactions,
		docPresenceMembers:state.docPresenceMembers[docId]||{},
		recentActivities:state.recentActivities,
		whiteboardModalIsVisible:state.whiteboardModal.visible,
		whiteboardModalEmbedId:state.whiteboardModal.embedId,
		//showInsertDrawingModal:state.showInsertDrawingModal,
		videoModalIsZoomed:state.videoModalIsZoomed,
		projects:state.projects,
		issuePanelIsVisible:issuePanelIsVisible,
		issuePanelProject:issuePanelProject,
		issuePanelUser:issuePanelUser,
		messagePanelIsVisible:messagePanelIsVisible,
		updatedAtTimestamp:updatedAtTimestamp,
		docSearchResults:state.docSearchResults,
		activeThread:activeThread,
		messageMediaIsZoomed:state.messageMediaIsZoomed,
		openTabs:state.openTabs,//to force update
		theme:state.theme,
		whiteboardSettings:state.whiteboardSettings
	}
}

export default withRouter(connect(mapStateToProps,
	{	
		createMessage,
		fetchTranscript,
		fetchRecentAcitivities,
		updateRecentActivity,
		getDocInstance,
		sendUserSelection,
		uploadImageToCloudinary,
		archiveDoc,
		saveDocScrollPosition,
		sendDocumentEvents,
		fetchDocumentEvents,
		fetchReactions,
		addReactionToMessage,
		deleteReaction,
		sendUserTypingEvent,
		deleteMessage,
		handleNewReactionPusher,
		handleReactionDeletedPusher,
		modifyDocNameLocally,
		handleDocTimestampUpdatedPusher,
		createDocSnapshot
	}
)(DocEditorContainer))