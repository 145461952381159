import React, { Component } from 'react'
import Icon from '../../components/misc/Icon'
import {getCurrentUser} from '../../utils/getCurrentUser'

const JOIN_SLACK_URL="https://join.slack.com/t/yarncustomers/shared_invite/zt-1pi4wxy3l-Hjbv1loRn8cXpNA07CeGjw"
//const JOIN_SLACK_URL="slack://slack.com/app_redirect?team=T04J0149YG1&&channel=C04H6RFJM47"

class SideMenuSlackTab extends React.Component{  

	openSlackLink(){
    if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:JOIN_SLACK_URL})     
    }else{
      window.open(JOIN_SLACK_URL,'_blank')
    }
  }

	render(){	
		

		let userIsSlackMember = false

		const currentUser = getCurrentUser()
		if(currentUser && currentUser.user_id){
			// i guess some array of user ids 
		}	

		// Join Slack URL – "https://join.slack.com/t/yarncustomers/shared_invite/zt-1pi4wxy3l-Hjbv1loRn8cXpNA07CeGjw" (can this be a deeplink, dunno)
		// Slack URL – //slack://yarncustomers.slack.com/archives/C04H6RFJM47

		
		let slackLabel = 'Feedback and Support'
		//slackLabel = 'Support'

	  return (
						
			<div onClick={this.openSlackLink} className='sideMenu-tab sideMenu-tab--tabType--appTab sideMenu-tab--appTab--support sideMenu-tab--inactive'>
				
				<div className='sideMenu-tab-iconContainer'>		
					<div className='sideMenu-tab-iconInnerContainer'>
						<Icon name='slackIcon' />						
						<Icon name='linkArrow' />						
					</div>		
				</div>

				<div className='sideMenu-tab-labelContainer'>
					<div className='sideMenu-tab-label'>
						Support
					</div>
				</div>	

				
			</div>				
		
	  )
	}
}





export default SideMenuSlackTab
