const CodeBlockNodeSpec ={
    group: "block",
    content: "block+",
    parseDOM: [{
	    tag: "div",
	    getAttrs: dom => {
	      let classname = dom.getAttribute("class")
	      return classname==='doc-codeBlock'
	    	}
  	}],
    toDOM: () => ["div",{class:'doc-codeBlock'}, 0],
}
    
   

 export default CodeBlockNodeSpec
 
