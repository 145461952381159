import { Plugin, PluginKey } from "prosemirror-state";
import {getAnchorNode} from '../../utils/getAnchorNode' 

// Define the key for the plugin.
const clickOnWordPluginKey = new PluginKey('clickOnWord');

function clickOnWordPlugin(handleClickOnWord) {
  return new Plugin({
    key: clickOnWordPluginKey,
  props: {
    handleDOMEvents: {
      mousedown: (view,event) => {
        const state=view.state 
        const selection=state.selection
        if(selection.empty){
          const pos = view.posAtCoords({ left: event.clientX, top: event.clientY });
          if(pos){
            let resolvedPos = view.state.doc.resolve(pos.pos)
            if (resolvedPos.node().type.name === "text") {// If a text node is encountered, check the parent node.
              resolvedPos = view.state.doc.resolve(resolvedPos.before())
            }
            const node = resolvedPos.node();
            if(node && node.type.name === 'transcriptWord'){
              //console.log(`You clicked on a transcriptWord node with start: ${node.attrs.start} and end: ${node.attrs.end}`);
              handleClickOnWord(node)
              return true;
            }
          }else return false
      }else return false
    }  
  }
}
  })
}



export default clickOnWordPlugin;


// // Define the plugin.
// const clickOnWordPlugin = new Plugin({
//   key: clickOnWordPluginKey,
//   props: {
//     handleDOMEvents: {
//       mousedown: (view, event) => {
//        const pos = view.posAtCoords({ left: event.clientX, top: event.clientY });

//         if (pos) {
//           let resolvedPos = view.state.doc.resolve(pos.pos);

//           // If a text node is encountered, check the parent node.
//           if (resolvedPos.node().type.name === "text") {
//             resolvedPos = view.state.doc.resolve(resolvedPos.before());
//           }

//           const node = resolvedPos.node();

//           if (node && node.type.name === 'transcriptWord') {
//             console.log(`You clicked on a transcriptWord node with start: ${node.attrs.start} and end: ${node.attrs.end}`);
//             // More custom logic here.

//             // Prevent default event handling.
//             return true;
//           }
//         }

//         // Fallthrough to default event handling.
//         return false;
//       }
//     }
//   }
// });
