import React from 'react'
import Icon from '../../misc/Icon'
import VideoDuration from '../../../utils/videoDuration'
import DocVideoInlineUIHeader from './DocVideoInlineUIHeader'
import DocVideoInlineUIPreControls from './DocVideoInlineUIPreControls'
import DocVideoInlineUIStartedControls from './DocVideoInlineUIStartedControls'
import DocVideoInlineTopRightMenu from './DocVideoInlineTopRightMenu'
import DocVideoCaptions from './DocVideoCaptions'

const TIMEOUT_INTERVAL=250

class DocVideoInlineUI extends React.Component{  
	 constructor(){
    super()
    this.onClick=this.onClick.bind(this)
    this.onDoubleClick=this.onDoubleClick.bind(this)
    this.timer=null
  }

	componentWillUnmount(){
		if(this.timer){
			clearTimeout(this.timer)
		}
	}

	onClick(e){
		e.stopPropagation()
		e.preventDefault()
		if(this.timer){
			clearTimeout(this.timer)
		}
		this.timer=setTimeout(function() {
			this.props.handlePlayPause()
		}.bind(this), TIMEOUT_INTERVAL)
	}

	onDoubleClick(){
		if(this.timer){
			clearTimeout(this.timer)
		}
		this.props.handleZoom()
	}

	render(){ 
		const {playing, hasPlayed, played, progressSeconds, videoTitle, handleZoomAndPlay, handleZoom, mouseMovedShort, mouseMovedLong, duration, handleSeekMouseUp, handleSeekMouseDown, handleSeekChange, handlePlayPause, captionsActive, toggleCaptions} = this.props				
		let showControlBar = false
		if(!hasPlayed && mouseMovedLong){
			showControlBar = true
		}
		if(!playing && hasPlayed){
			showControlBar = true
		}
		if(playing && mouseMovedLong){
			showControlBar = true
		}

		return (	
			<div className={'doc-video-inlineUI ' + (mouseMovedShort ? ' doc-video-inlineUI--mouseMovedShort--active ' : ' doc-video-inlineUI--mouseMovedShort--inactive ') + ((!mouseMovedLong && playing) ? ' doc-video-inlineUI--cursor--hide ' : ' doc-video-inlineUI--cursor--show ')}>
				<DocVideoInlineUIHeader 
					title={this.props.title}
					duration={duration}					
					updateTitle={this.props.updateTitle}
					nodeId={this.props.nodeId}
				/>
				<DocVideoInlineTopRightMenu 
					newMessage={this.props.newMessage}
					deleteVideo={this.props.deleteVideo}
				/>
				<DocVideoInlineUIStartedControls
					played={played}
					playing={playing}
					duration={duration}
					showControlBar={showControlBar}
					handlePlayPause={handlePlayPause}
					progressSeconds={progressSeconds}
					handleSeekMouseUp={handleSeekMouseUp}
					handleSeekMouseDown={handleSeekMouseDown}
					handleSeekChange={handleSeekChange}
					captionsActive={captionsActive}
					toggleCaptions={toggleCaptions}
					handleZoom={handleZoom}
					handleSkip={this.props.handleSkip}
					handleVolumeChange={this.props.handleVolumeChange}
					volume={this.props.volume}
					handleVolumeMouseDown={this.props.handleVolumeMouseDown}
					handleVolumeMouseUp={this.props.handleVolumeMouseUp}
					adjustingVolume={this.props.adjustingVolume}
					timestampedMessages={this.props.timestampedMessages}
					seekToComment={this.props.seekToComment}
					mouseMovedShort={mouseMovedShort}
				/>					
				{this.props.subtitleUrl && captionsActive &&
					<DocVideoCaptions
						inline
						showInlineControlBar={showControlBar}
						subtitleUrl={this.props.subtitleUrl}
						progressSeconds={progressSeconds}
						hasTimestampedComments={this.props.timestampedMessages && this.props.timestampedMessages.length > 0}
					/>
				}					
			<button onClick={this.onClick} onDoubleClick={this.onDoubleClick} className='doc-video-inlineUI-bgPlayPauseClick'/>
		</div>							
		)
	}
}

export default DocVideoInlineUI
