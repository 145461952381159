import React from 'react'
import Icon from '../../../components/misc/Icon'
import LinearIssueWorkflowStateIcon from '../../../components/misc/LinearIssueWorkflowStateIcon'
import LinearMarker from '../../../components/misc/LinearMarker'
import AvatarImage from '../../../components/AvatarImage'
import LinearIssuePreviewPopoverContent from '../../../components/docEditor/mentionPopovers/LinearIssuePreviewPopoverContent'
import LinearProjectPreviewPopoverContent from '../../../components/docEditor/mentionPopovers/LinearProjectPreviewPopoverContent'
import {getDocForId} from '../../../utils/getDocForId'
import {getIssueForId} from '../../../utils/getIssueForId'
import {getProjectForId} from '../../../utils/getProjectForId'
import {getUserForId} from '../../../utils/getUserForId'
import {getRoadmapForId} from '../../../utils/getRoadmapForId'
import {getOrg} from '../../../utils/getOrg'

function createMarkup(input) {
  return {__html: input}
}

const activeClass='insertMentionsMenu-item--active'


class InsertMentionsMenuItem extends React.Component{  
  
  render(){ 

    const {index,suggestion,activeIndex,suggestionType,mouseHasMoved}=this.props
    if(suggestionType=='doc'){ 
      const doc=getDocForId(suggestion.doc_id)||{}
      let docType
      let project
      let roadmap
      let org
      if(suggestion.project){
        project = getProjectForId(suggestion.project)
        docType = 'projectSubDoc'
      }else if(suggestion.roadmap){
        roadmap = getRoadmapForId(suggestion.roadmap)
        docType = 'roadmapDoc'
      }else if(suggestion.is_org_doc){
        org = getOrg()
        docType = 'orgDoc'
      }
      return (
        <div 
          key={index}
          onClick={(e)=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'insertMentionsMenu-item ' + (suggestionType ? ` insertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' insertMentionsMenu-item--active ': ' ') + (mouseHasMoved ? ' insertMentionsMenu-item--mouseMoved ' : ' insertMentionsMenu-item--mouseNotMoved ')}         
        >

        <div className='insertMentionsMenu-item--doc-iconContainer'>
          <Icon name='docSimple' />
        </div>
        <div className='insertMentionsMenu-item-label'>
         {doc.name}
        </div>
        {docType === 'projectSubDoc' && project &&
          <div className='insertMentionsMenu-item--doc-projectTag'>
            <div className='insertMentionsMenu-item--doc-projectTag-marker'>
              <LinearMarker 
                projectId={suggestion.project}
              />
            </div>
            <div className='insertMentionsMenu-item--doc-projectTag-label'>
              {project.name}
            </div>
          </div>
        }
        {docType === 'roadmapDoc' && roadmap &&
          <div className='insertMentionsMenu-item--doc-roadmapTag'>
            <div className='insertMentionsMenu-item--doc-roadmapTag-icon'>
              <Icon name='roadmapOutline' />
            </div>
            <div className='insertMentionsMenu-item--doc-roadmapTag-label'>
              {roadmap.name}
            </div>
          </div>
        }
        {docType === 'orgDoc' && org &&
          <div className='insertMentionsMenu-item--doc-orgTag'>
            <div className='insertMentionsMenu-item--doc-orgTag-image'>
              <img src={org.logo} className='insertMentionsMenu-item--doc-orgTag-image-image' />                        
            </div>
            <div className='insertMentionsMenu-item--doc-orgTag-label'>
              {org.name}
            </div>
          </div>
        }
        {doc && !this.props.hidePopover &&
          <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='insertMentionsMenu-item-hoverPreviewContainer'>                       
         
            <div className='insertMentionsMenu-item--doc-previewInnerContainer popover'>
              <div className='insertMentionsMenu-item--doc-preview'>
                <div className='insertMentionsMenu-item--doc-preview-doc'>
                  <div className='insertMentionsMenu-item--doc-preview-doc-title'>
                    {doc.name}
                  </div>   
                  <div 
                    key={doc.doc_id} 
                    className='insertMentionsMenu-item--doc-preview-doc-innerWrapper' 
                    dangerouslySetInnerHTML={createMarkup(doc.doc_html)}                
                  />
                </div>
              </div>
            </div>
          
          </div>
        }
      </div>            
    )
    }
    else if(suggestionType=='web'){ 
      const url=suggestion.url     
      return (
        <div 
          key={index}
          onClick={()=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'insertMentionsMenu-item insertMentionsMenu-item--web ' + (activeIndex == index ? ' insertMentionsMenu-item--active ': ' ') + (mouseHasMoved ? ' insertMentionsMenu-item--mouseMoved ' : ' insertMentionsMenu-item--mouseNotMoved ')}         
        >
        <div className='insertMentionsMenu-item--web-iconContainer'>
          <Icon name='globe' />
        </div>
        <div className='insertMentionsMenu-item-label'>
         {url}
        </div>
        <div className='insertMentionsMenu-item--web-hint'>
          Link to web page
        </div>
      </div>            
    )
    }

    else if(suggestionType=='project'){ 
      return (
        <div 
          key={index}
          onClick={(e)=>{this.props.selectIndex(activeIndex,e)}} //e is to stop input bluring in meetings linked project thing
          onMouseDown={(e)=>{e.preventDefault()}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'insertMentionsMenu-item ' + (suggestionType ? ` insertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' insertMentionsMenu-item--active ': ' ')}         
        >
          <div className='insertMentionsMenu-item--project-markerContainer'>
            <LinearMarker projectId={suggestion.project_id} />
          </div>
        <div className='insertMentionsMenu-item-label'>
         {suggestion.name}
        </div>
        {!this.props.hidePopover &&
          <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='insertMentionsMenu-item-hoverPreviewContainer'>                      
            <LinearProjectPreviewPopoverContent
              name={suggestion.name}
              leadUserId={suggestion.lead}
              description={suggestion.description}
              memberIds={suggestion.members}
              teamIds={suggestion.teams}
              status={suggestion.state}
              projectId={suggestion.project_id}
            />        
          </div>
        }
      </div>   
      )         
    }else if(suggestionType=='issue'){
      const issue= getIssueForId(suggestion.issue_id) 
      const assigneeObj = getUserForId(suggestion.assignee)  
      const projectObj = getProjectForId(issue.project)          
      return (
        <div 
          key={index}
          onClick={()=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'insertMentionsMenu-item ' + (suggestionType ? ` insertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' insertMentionsMenu-item--active ': ' ')}
        >
          <div className='insertMentionsMenu-item--issue-workflowStateIconContainer'>
           {issue.workflow_state &&
              <LinearIssueWorkflowStateIcon
                workflowColorHex={issue.workflow_state.color}
                workflowType={issue.workflow_state.type}
                workflowTypeStateIndex={issue.workflow_state.workflowTypeStateIndex}
                workflowTypeStatesCount={issue.workflow_state.workflowTypeStatesCount }         
              />
           }
          </div>
          <div className='insertMentionsMenu-item-label'>
           {suggestion.title}
          </div>
          <div className='insertMentionsMenu-item--issue-identifierContainer'>
            {suggestion.identifier}
          </div>
          {!this.props.hidePopover &&
            <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='insertMentionsMenu-item-hoverPreviewContainer'>           
              {projectObj &&
                <LinearIssuePreviewPopoverContent
                  projectId={projectObj.project_id}
                  project={projectObj}               
                  identifier={suggestion.identifier}
                  title={suggestion.title}
                  assignee={assigneeObj}
                  workflowColorHex={issue.workflow_state.color}
                  workflowType={issue.workflow_state.type}
                  workflowTypeStatesCount={issue.workflow_state.workflowTypeStatesCount}
                  workflowTypeStateIndex={issue.workflow_state.workflowTypeStateIndex}
                  workflowStateName={issue.workflow_state.name}
                  priority={suggestion.priority}                   
                  description={suggestion.description}
                />
              }
            </div>
          }
        
      </div>            
    )
    }
    else if(suggestionType=='date'){     
      return (
        <div 
          key={index}
          onClick={()=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'insertMentionsMenu-item ' + (suggestion.label ? ' insertMentionsMenu-item--date--hasLabel ' : ' insertMentionsMenu-item--date--dateOnly ') + (suggestionType ? ` insertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' insertMentionsMenu-item--active ': ' ')}         
        >
          <span className='insertMentionsMenu-item-label'>
            {suggestion.label}
          </span>
          <span className='insertMentionsMenu-item-label insertMentionsMenu-item-label--date'>
            {suggestion.formattedDate}
          </span>
        
      </div>            
    )
    }
    else if(suggestionType=='assign'){     
      const active=activeIndex==index
      return (
        <div 
          key={index}
          onClick={()=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'insertMentionsMenu-item ' + (suggestionType ? ` insertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' insertMentionsMenu-item--active ': ' ')}
        >
          
            <div className='insertMentionsMenu-item--user-avatarOuterContainer'>
              <div className='insertMentionsMenu-item--user-avatarInnerContainer'>
                 <AvatarImage 
                  avatarUrl={suggestion.avatar}
                  className='insertMentionsMenu-item--user-avatar'
                />
              </div>
              {/*{active &&
                <div className='suggestionMenu-item--mention--user-avatarInnerBorder' />
              */}

            </div>          
          <div className='insertMentionsMenu-item-label'>
            {suggestion.display_name}
          </div>
          {/*{active &&
            <div className='suggestionMenu-item-assignHint'>
              assign
            </div>
          */}
        </div>            
    )
    }
  }
}



export default InsertMentionsMenuItem




        
        {/*}
        <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='insertMentionsMenu-item--doc-previewContainer'>       
          <div className='insertMentionsMenu-item--doc-preview'>
            <div className='insertMentionsMenu-item--doc-preview-doc'>
              {suggestionType=='doc' &&
                <div className='insertMentionsMenu-item--doc-preview-doc-title'>
                  {suggestion.name}
                 </div>   
              }
              {suggestionType=='doc' &&
                <div className='insertMentionsMenu-item--doc-preview-doc-innerWrapper' dangerouslySetInnerHTML={createMarkup(suggestion.html)} key={suggestion.doc_id} /> 
              }    
              {suggestionType=='issue' &&
                <div className='insertMentionsMenu-item--doc-preview-doc-innerWrapper' key={suggestion.issue_id}>
                  details of issue! 
                </div> 
              }    
              {suggestionType=='project' &&
                <div className='insertMentionsMenu-item--doc-preview-doc-innerWrapper' key={suggestion.project_id}>
                details of project 
              </div> 
              }
           </div>               
        </div>

      </div>
      */}
