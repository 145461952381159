import React from 'react'
import Icon from '../../misc/Icon'
import LinearIssueWorkflowStateIcon from '../../misc/LinearIssueWorkflowStateIcon'

class DocBlockLinearIssueStatus extends React.Component{  	

	render(){			
		
		const {workflowColorHex, workflowType, workflowTypeStateIndex, workflowTypeStatesCount} = this.props

	  return (
	  	
	  	
  		<div className='doc-blockLinearIssue-statusContainer'>
  			{/* MIGHT ADD IN A DROPDOWN TO EDIT */}

  			<LinearIssueWorkflowStateIcon
  				workflowColorHex={workflowColorHex}
  				workflowType={workflowType}

  				// type started
  				workflowTypeStateIndex={workflowTypeStateIndex}
  				workflowTypeStatesCount={workflowTypeStatesCount}  				
  			/>
  			
  		</div>	  
			
	  )
	}
}

export default DocBlockLinearIssueStatus
