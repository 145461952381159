import React from 'react'
import Icon from '../../../misc/Icon'
import DocEditorMeetingContentProjectList from './DocEditorMeetingContentProjectList'
import DocEditorMeetingHighlightList from './DocEditorMeetingHighlightList'
import { getRecordingForMeeting } from '../../../../utils/meetings/getRecordingForMeeting'
import { CircularProgressbar } from 'react-circular-progressbar';
import DocEditorMeetingContentPlayRecordingButton from './DocEditorMeetingContentPlayRecordingButton'
import DocEditorMeetingContentAddRecordingButton from './DocEditorMeetingContentAddRecordingButton'
import DocEditorMeetingContentAddProjectPopover from './DocEditorMeetingContentAddProjectPopover'
import DocEditorMeetingContentAddToGCalPopover from './DocEditorMeetingContentAddToGCalPopover'
import DocEditorMeetingContentNewTeamSyncTeamBtn from './DocEditorMeetingContentNewTeamSyncTeamBtn'

import DocEditorMeetingContentLinkedProjects from './DocEditorMeetingContentLinkedProjects'
import DocEditorMeetingContentActiveProjects from './DocEditorMeetingContentActiveProjects'
import DocEditorMeetingContentHighlights from './DocEditorMeetingContentHighlights'


class DocEditorMeetingContent extends React.PureComponent{  

  constructor(props) {
    super(props)   
    this.state={
      showStandupProjects: 'docs',
      showAddProjectPopover: false,
      showAddToGCalPopover: false,      
    }    
  }   

  checkMeetingTime() {
    // from GTP, we show different things for past/present/future
    // for example, we prompt to upload a recording if meeting is in the past
    const { meeting } = this.props;
    const meetingStartTime = new Date(meeting.meeting_start);
    const meetingEndTime = new Date(meeting.meeting_end);
    const currentTime = new Date();

    if(currentTime < meetingStartTime) {
      return "future";
    } else if(currentTime > meetingEndTime) {
      return "past";
    } else {
      return "present";
    }
  }


  render(){ 
    const {meeting, recording, openRecordingModal,highlights,projectConnections} = this.props
    const {showAddProjectPopover,showAddToGCalPopover, showNewTeamSyncTeamPopover} = this.state
    const meetingType = meeting.type
    const meetingDateState = this.checkMeetingTime()
    const hasRecording = recording
    const hasHighlights = highlights.length>0
    const hasLinkedProjects = projectConnections.length>0
    const showAddLinkedProject = false
    let showAddRecordingBtn = !hasRecording && !hasHighlights
    const showPlaybackButton = hasRecording
    let recordingBtnLocation = 'mainMenu'
    if(hasLinkedProjects && !hasHighlights){
      recordingBtnLocation = 'projects'      
    }

    const showPlaybackButtonStrong = !hasHighlights && recordingBtnLocation === 'mainMenu'
    const showAddLinkBtn = !hasLinkedProjects && !showAddLinkedProject
    const showHighlightsGrid = hasHighlights
    const showProjectList = meetingType && meetingType !== 'discovery'
    

    // GOOGLE CALENDAR
    const isGCalEvent = meeting.event_id //

    const isNewMeeting = !meeting.type // new meeting page
    const isNewTeamSync = meeting.recurring_event // new meeting page

    const showMenu = !isNewMeeting && !isNewTeamSync && (hasHighlights || recordingBtnLocation ==='mainMenu' || !hasLinkedProjects)


    let showLinkedProjects = meeting && meeting.type === 'designReview'

    let showTeamSyncSummary = meeting && meeting.type === 'teamSync'

    let showRecordingMenu = meeting && meeting.type === 'discovery'


    return (        
      <div className={'docEditor-meetingContent ' + (meetingType ? ` docEditor-meetingContent--type--${meetingType} ` : ' docEditor-meetingContent--type--noType' )}>

        {/* LINKED PROJECTS – DESIGN REVIEW */}
        {showLinkedProjects && 
          <DocEditorMeetingContentLinkedProjects
            projectConnections={projectConnections}
            meeting={meeting}
            openIssuePanel={this.props.openIssuePanel}
            issuePanelIsVisible={this.props.issuePanelIsVisible}
            issuePanelProject={this.props.issuePanelProject}
            closeIssuePanel={this.props.closeIssuePanel}
          />
        }   

        {/* SHOW TEAMSYNC SUMMARY */}
        {showTeamSyncSummary && 
          <DocEditorMeetingContentActiveProjects
            meeting={meeting}
            openIssuePanel={this.props.openIssuePanel}
            issuePanelIsVisible={this.props.issuePanelIsVisible}
            issuePanelProject={this.props.issuePanelProject}
            closeIssuePanel={this.props.closeIssuePanel}
          />
        }

         {showRecordingMenu && 
          <DocEditorMeetingContentHighlights
            meeting={meeting}            
            recording={recording}
            openRecordingModal={openRecordingModal}
            highlights={highlights}
            openThread={this.props.openThread}
            docMessages={this.props.docMessages}            
            meetingInPast={meetingDateState === 'past'}
          />
        }


        {/*}
        {hasHighlights && 
            <DocEditorMeetingHighlightList
              highlights={highlights}
              openThread={this.props.openThread}
              docMessages={this.props.docMessages}
            />
          }
        */}


        
        {/*}
        {recording &&                 
            <DocEditorMeetingContentPlayRecordingButton
              recording={recording}
              openRecordingModal={openRecordingModal}
              light
            />
          }
        */}


        

        {/* LINKED PROJECTS MENU 
        {showRecordingMenu && 
          <div>
            Linked projects
          </div>
        }

        {recording &&                 
            <DocEditorMeetingContentPlayRecordingButton
              recording={recording}
              openRecordingModal={openRecordingModal}
              light
            />
          }

          {!recording &&
          <DocEditorMeetingContentAddRecordingButton 
                strong
                meeting={meeting}
              />
            }
*/}



        {/*}
        {isNewTeamSync && 
        <div className='docEditor-meetingContent-menu'>
          <DocEditorMeetingContentNewTeamSyncTeamBtn 
            meeting={meeting}
          />          
        </div>
        }

        {(hasLinkedProjects || showAddLinkedProject) &&  
          <div className='docEditor-meetingContent-linkedProjectsList'>
            <div className='docEditor-meetingContent-linkedProjectsList-header'>
              <button className='docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--sectionHeader'>
            <div className='docEditor-meetingContent-menu-btn-iconContainer'>
              <Icon name='link' />
            </div>
            <div className='docEditor-meetingContent-menu-btn-label'>
              Linked Projects
            </div>
          </button>
          {showAddRecordingBtn && recordingBtnLocation ==='projects' &&
            <DocEditorMeetingContentAddRecordingButton 
              light
              meeting={meeting}
            />
          }
          
          <DocEditorMeetingContentAddProjectPopover
            showPopover={showAddProjectPopover}
            closePopover={()=>{this.setState({showAddProjectPopover: false})}}
            meeting={meeting}
          >
            <button onClick={()=>{this.setState({showAddProjectPopover: true})}} className='docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--addProjectLinkFromProjects docEditor-meetingContent-menu-btn--light'>
              <div className='docEditor-meetingContent-menu-btn-iconContainer'>
                <Icon name='plus' />
              </div>
            </button>                
          </DocEditorMeetingContentAddProjectPopover>
        </div>
          <DocEditorMeetingContentProjectList  
            meeting={meeting}
            openIssuePanel={this.props.openIssuePanel}
          />
        </div>
        }
        {showMenu &&
          <div className='docEditor-meetingContent-menu'>   
            {hasHighlights &&
              <button className='docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--sectionHeader'>
                <div className='docEditor-meetingContent-menu-btn-iconContainer'>
                  <Icon name='effectsMedium' />
                </div>
                <div className='docEditor-meetingContent-menu-btn-label'>
                  Highlights
                </div>
              </button>
            }

            {!isGCalEvent &&
              <DocEditorMeetingContentAddToGCalPopover
                showPopover={showAddToGCalPopover}
                closePopover={()=>{this.setState({showAddToGCalPopover: false})}}
                meeting={meeting}
              >
                <button onClick={()=>{this.setState({showAddToGCalPopover: true})}} className='docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--strong docEditor-meetingContent-menu-btn--addToGCal'>                
                  <div className='docEditor-meetingContent-menu-btn-iconContainer'>
                    <Icon name='calendar' />
                  </div>                
                  <div className='docEditor-meetingContent-menu-btn-label'>
                    Add to Google Calendar
                  </div>
                </button>             
              </DocEditorMeetingContentAddToGCalPopover>              
            }

            {showAddLinkBtn && meetingType !== 'discovery' &&
              <DocEditorMeetingContentAddProjectPopover
                showPopover={showAddProjectPopover}
                closePopover={()=>{this.setState({showAddProjectPopover: false})}}
                meeting={meeting}
              >                              
                <button onClick={()=>{this.setState({showAddProjectPopover: true})}} className='docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--strong docEditor-meetingContent-menu-btn--linkToProject'>
                  <div className='docEditor-meetingContent-menu-btn-iconContainer'>
                    <Icon name='link' />
                  </div>
                  <div className='docEditor-meetingContent-menu-btn-label'>
                    Link to Project
                  </div>
                </button>
              </DocEditorMeetingContentAddProjectPopover>
            }
            {showPlaybackButton && recordingBtnLocation ==='mainMenu' && recording &&              
              <DocEditorMeetingContentPlayRecordingButton
                light={!showPlaybackButtonStrong}
                strong={showPlaybackButtonStrong}
                recording={recording}
                openRecordingModal={openRecordingModal}
              />
            }
            {showAddRecordingBtn && recordingBtnLocation ==='mainMenu' && 
              <DocEditorMeetingContentAddRecordingButton 
                strong
                meeting={meeting}
              />
            }
            {showAddLinkBtn && meetingType === 'discovery' &&
              <DocEditorMeetingContentAddProjectPopover
                showPopover={showAddProjectPopover}
                closePopover={()=>{this.setState({showAddProjectPopover: false})}}
                meeting={meeting}
              >                              
                <button onClick={()=>{this.setState({showAddProjectPopover: true})}} className='docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--light docEditor-meetingContent-menu-btn--linkToProject'>
                  <div className='docEditor-meetingContent-menu-btn-iconContainer'>
                    <Icon name='link' />
                  </div>
                  <div className='docEditor-meetingContent-menu-btn-label'>
                    Link to Project
                  </div>
                </button>
              </DocEditorMeetingContentAddProjectPopover>
            }


            </div>
          }
          
          {/*}
            {showProjectList && 
              <DocEditorMeetingProjectList 
                meeting={meeting}
              />       
            }

            {showHighlightsGrid && 
              <DocEditorMeetingHighlightGrid
                meeting={meeting}
                hasHighlights={hasHighlights}
                hasRecording={hasRecording}
                meetingType={meetingType}
                showUploadRecordingPrompt={showUploadRecordingPrompt}
              />     
            }
          */}       
        </div>
    )
  }
}

export default DocEditorMeetingContent
