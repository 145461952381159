import store from '../../store'
import find from 'lodash/find'



export function getWhiteboardEmbedForId(embedId) {	
	const state = store.getState()
	const embed= find(state.whiteboardEmbeds, {'embed_id':embedId})
	return embed
}


