import React from 'react'
import DocFigmaEmbedEffectsToolbarBGBtnCarousel from './DocFigmaEmbedEffectsToolbarBGBtnCarousel'
import DocFigmaEmbedEffectsToolbarPaddingSlider from './DocFigmaEmbedEffectsToolbarPaddingSlider'
import DocFigmaEmbedEffectsToolbarSelectDevice from './DocFigmaEmbedEffectsToolbarSelectDevice'
// import DocFigmaEmbedEffectsToolbarShadowToggle from './DocFigmaEmbedEffectsToolbarShadowToggle'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';

const DEFAULT_BG_PADDING = 0.1 

class DocFigmaEmbedEffectsToolbar extends React.Component{  
 
  constructor(){
    super()    
    this.resetPresentationEffects=this.resetPresentationEffects.bind(this)
  }

  resetPresentationEffects(){
    this.props.editBackgroundPadding(parseFloat(DEFAULT_BG_PADDING))
    this.props.editBackgroundImage(null)
    this.props.editDeviceFrame(null)
  }

  
	render(){ 
		const {fullyInternalCrop} = this.props
		const maxBackgroundPadding = 0.15
		const hasPresentationEffects = this.props.deviceFrame || this.props.backgroundImage
		let showSelectDevice = this.props.device 
		if(this.props.device === 'desktop' && !this.props.backgroundImage){
			showSelectDevice = false
		}
		
		return (			
			<React.Fragment>		
				<div className='doc-figma-effectsToolbar forceDarkTheme'>

					<Tooltip.Provider>
	          <Tooltip.Root delayDuration={0}>
	            <Tooltip.Trigger asChild>                  						
								<button onClick={this.resetPresentationEffects} className={'doc-figma-effectsToolbar-resetBtn ' + (hasPresentationEffects ? ' doc-figma-effectsToolbar-resetBtn--enabled ' : ' doc-figma-effectsToolbar-resetBtn--disabled ')}>
									<Icon name='squareSlash' />
								</button>							
	            </Tooltip.Trigger>        
	            <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
	              Reset Effects
	            </Tooltip.Content>                          
	          </Tooltip.Root>
	        </Tooltip.Provider>


					<div className='doc-figma-effectsToolbar-separator' />
					<DocFigmaEmbedEffectsToolbarBGBtnCarousel  
						backgroundImage={this.props.backgroundImage}
						editBackgroundImage={this.props.editBackgroundImage}
						device={this.props.device}
					/>
					{this.props.backgroundImage && 
						<React.Fragment>
							<div className='doc-figma-effectsToolbar-separator' />				
							<DocFigmaEmbedEffectsToolbarPaddingSlider
								backgroundPadding={this.props.backgroundPadding}
								editBackgroundPadding={this.props.editBackgroundPadding}
								maxBackgroundPadding={maxBackgroundPadding}
							/>
						</React.Fragment>
					}
					{showSelectDevice && 
						<React.Fragment>
							<div className='doc-figma-effectsToolbar-separator' />
							<DocFigmaEmbedEffectsToolbarSelectDevice
								deviceFrame={this.props.deviceFrame}
								editDeviceFrame={this.props.editDeviceFrame}
								device={this.props.device}
							/>
							{/*
								{this.props.backgroundImage && 
									<DocFigmaEmbedEffectsToolbarShadowToggle
										toggleShadow={this.props.toggleShadow}
										deviceShadow={this.props.deviceShadow}
									/>
								}
							*/}
						</React.Fragment>
					}

					
					<div className='doc-figma-effectsToolbar-separator' />
					<div className='doc-figma-effectsToolbar-doneBtnContainer'>
						<button onClick={this.props.hideEffectsToolbar} className='doc-figma-effectsToolbar-doneBtn'>
							Done
						</button>						
					</div>
					
				</div>			
			
						
			</React.Fragment>
		)
	}
}

export default DocFigmaEmbedEffectsToolbar
