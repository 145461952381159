import moment from 'moment'

export function formatMeetingDateTimeShort(timestamp) {
	const nowDate = moment();
	const today = moment(nowDate).startOf('day');
	const yesterday = moment(nowDate).subtract(1, 'days').startOf('day');
	const dayBeforeYesterday = moment(nowDate).subtract(2, 'days').startOf('day');
	const tomorrow = moment(nowDate).add(1, 'days').startOf('day');
	const week = moment(nowDate).startOf('isoWeek');	
  const sevenDaysFromNow = moment(nowDate).add(7, 'days');
  const nextWeekButLessThan7Days = moment(nowDate).add(1, 'weeks').subtract(1, 'days');
	const nextWeek = moment(nowDate).add(1, 'weeks').startOf('isoWeek');
	const endOfNextWeek = moment(nowDate).add(1, 'weeks').endOf('isoWeek');
	const sixHoursFromNow = moment(nowDate).add(6, 'hours');
 
	let formattedTimestamp;


   if (moment(timestamp).isBetween(nowDate, sixHoursFromNow)) {
    // in the next 6 hours
    const diff = moment(timestamp).diff(nowDate);
    const duration = moment.duration(diff);

    if (duration.asHours() < 1) {
      formattedTimestamp = `in ${duration.minutes()}m`;
    } else {
      formattedTimestamp = `in ${duration.hours()}h ${duration.minutes()}m`;
    }
  }
	else if (moment(timestamp).isSame(today, 'd')) {
		// today
		formattedTimestamp = `${moment(timestamp).format('h:mm A')}`;
	}
	else if (moment(timestamp).isSame(yesterday, 'd')) {
  	// yesterday
  	formattedTimestamp = `Yesterday`;
  }
	else if (moment(timestamp).isSame(tomorrow, 'd')) {
  	// tomorrow
  	formattedTimestamp = `Tomorrow`;
  }	
   else if (moment(timestamp).isBetween(week, dayBeforeYesterday)) {
    // day before yesterday but within this week
    formattedTimestamp = `${moment(timestamp).format('ddd MMMM D')}`;
    // formattedTimestamp = 'TEST'
  }
	else if (moment(timestamp).isBetween(week, today)) {
		// this week, after today
		formattedTimestamp = moment(timestamp).format('dddd');
	}		
	else if (moment(timestamp).isBetween(nextWeek, nextWeekButLessThan7Days)) {
    // next week but less than 7 days
    formattedTimestamp = `${moment(timestamp).format('dddd')}`;
  } 
  else if (moment(timestamp).isBetween(nextWeek, sevenDaysFromNow)) {
    // next week and 7 or more days away
    formattedTimestamp = `Next ${moment(timestamp).format('ddd MMMM D')}`;
  }
	else{
		// all others
		//formattedTimestamp = moment(timestamp).format('ddd MMMM D'); // with dayofweek
		formattedTimestamp = moment(timestamp).format('MMMM D');
	}

	return formattedTimestamp;
}