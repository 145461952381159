export default function (state = {}, action) {
	
	switch (action.type) {

		case 'SET_DOC_SCROLL_POSITION':
			return {...state,
				[`${action.docId}`]:action.scrollTop
			}

		case 'SIGN_OUT':
	      return []
		
		default:
			return state
	}
}