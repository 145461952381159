import {Plugin} from 'prosemirror-state'
import schema from '../schema/editorSchema'  


// if(node.type.name=='bullet_list'){
//   console.log('bullet list node')
//   console.log(node)
//   console.log(node.content)
//   //for each list item make a new bullet list

//   let newNodes=[]
//   node.descendents((listNode)=>{
//     console.log('descendent!!!')
//     console.log(listNode)
//     if(listNode.type.name=='list_item'){
//       //console.log(listNode)
//       console.log(listNode)
//         let type = schema.nodes.bulletListItem
//         console.log(listNode.content)
//         const textNode=schema.text(listNode.textContent)
//         const paragraph=schema.nodes.paragraph.createAndFill(null,textNode)

//         const newNode = type.createAndFill({indentLevel:0,markerType:'bullet'},paragraph)
//         console.log(newNode)
//         newNodes.push(newNode)

//     }else if(listNode.type.name=='bulletListItem'){
//       console.log('alreadt a bulletlistitem')
//       newNodes.push(listNode)
//     }else{
//       console.log('heeeeere---------')
//       console.log(listNode)
//     }
    
//   })
//   console.log('******** new nodes')
//   console.log(newNodes)
// }
function flattenBulletList(tr, schema) {
  const { bullet_list, bulletListItem, list_item,paragraph } = schema.nodes;
  let hasChanged = false;

  // Step through the document
  tr.doc.descendants((node, pos) => {
    // If the node is a bullet_list, process its children
    if (node.type.name === 'bullet_list') {
      let newChildNodes=[]
      //add empty paragraph so the formatting looks the same
      const emptyParagraphNode = schema.nodes.paragraph.create();
      newChildNodes.push(emptyParagraphNode)
      node.forEach((child, childOffset) => {
          // If the child is a list_item or bulletListItem, change its type to bulletListItem
        if (child.type === list_item) {
          let previousChildType=null
          child.forEach((listItemChild,listChildOffset)=>{
            if(listItemChild.type==paragraph){
             if(previousChildType=='bullet'){ //dont convert empty paragraphs into bullets
              newChildNodes.push(listItemChild)
              //previousChildType='paragraph'
             }else{
              let markerType="bullet"
              const newNode = bulletListItem.create({markerType:markerType}, listItemChild);
              newChildNodes.push(newNode)
              previousChildType='bullet'
             }
    
            }else if(listItemChild.type==bulletListItem){
              let markerType="bullet"
              let indentLevel=1
              const newNode = bulletListItem.create({markerType:markerType,indentLevel:indentLevel}, listItemChild.content);
              newChildNodes.push(newNode)
              previousChildType='bullet'
            }
          })
        }else if (child.type === bulletListItem) {
          let previousChildType='bullet'
          newChildNodes.push(child)
        }
      });
      const emptyParagraphNode2 = schema.nodes.paragraph.create();
      newChildNodes.push(emptyParagraphNode2)
      tr.replaceWith(pos,pos+node.nodeSize,newChildNodes)

    }
  });

  return tr
}



function calcOrderedLists(state){
  const doc=state.doc
  let tr=state.tr
  let listItemIndex={}
  let lastIndentLevel=0
  let listMarkerType={}
  let insideList = false;

  tr=flattenBulletList(tr,schema)

  // Helper function to traverse and process nodes
  function traverseAndProcess(node, offset = 0) {
    node.forEach((childNode, childOffset) => {
      const absoluteOffset = offset + childOffset + 1;
      if (childNode.type.name === 'bulletListItem') {
        insideList = true;
        // process bulletListItem...
        const indentLevel=childNode.attrs.indentLevel          
        if(indentLevel<lastIndentLevel){ //reset nested lists
          listItemIndex[lastIndentLevel]=null
        }
        if(!listItemIndex[indentLevel] && listItemIndex[indentLevel]!=0){
          listItemIndex[indentLevel]=0
          lastIndentLevel=0
        } else {
          listItemIndex[indentLevel]+=1
          lastIndentLevel=indentLevel
        }
        let markerType=childNode.attrs.markerType
        if(listMarkerType[indentLevel-1]){
          const parentListMarkerType=listMarkerType[indentLevel-1]
          if(parentListMarkerType=='number'){
            markerType='letterLowerCase'
          } else if(parentListMarkerType=='letterLowerCase' || parentListMarkerType=='letter'){
            markerType='number'
          }
        } else if(listMarkerType[indentLevel]){
          markerType=listMarkerType[indentLevel]
        }
        listMarkerType[indentLevel]=markerType
        if(listItemIndex[indentLevel] != childNode.attrs.positionInList || markerType !== childNode.attrs.markerType){
          tr.setNodeMarkup(absoluteOffset, null, {...childNode.attrs,positionInList:listItemIndex[indentLevel],markerType:markerType})
        }
      } else if (childNode.isBlock) {
        // If this is a block node, traverse its children
        if (insideList) {
          // We've moved from a list to a non-list-item block, so reset counters
          listItemIndex = {}
          listMarkerType = {}
          insideList = false;
        }
        traverseAndProcess(childNode, absoluteOffset);
      } else {
        // We're not inside a list, so reset counters
        listItemIndex = {}
        listMarkerType = {}
      }
    });
  }

  // Start processing from root
  tr.doc.forEach((docNode, docOffset) => {
    if (docNode.type.name === 'editorPage') {
      traverseAndProcess(docNode, docOffset);
    }
  });
  
  return tr
}



export function orderedListPlugin() {
  return new Plugin({
    appendTransaction(transactions, oldState, state) {
      if (transactions.find(tr => tr.docChanged )){
        return calcOrderedLists(state)
      }
    }
})
}



// export function bumpMessageVersion(version) {
//   if(window.view && window.view.state){
//     let tr = window.view.state.tr
//     window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
//       if(node.attrs.messageVersion || node.attrs.messageVersion===0){
//         tr.setNodeMarkup(pos, null, {...node.attrs,messageVersion:version})
//       }
//     })
//     tr.setMeta('addToHistory', false)  
//     window.view.dispatch(tr)
//   }
// }