import React from 'react'
import Icon from '../../misc/Icon'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'

function createMarkup(input) {
  return {__html: input}
}

class DocEditorTemplatePreviewPopover extends React.Component{  	

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)		
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

	render(){	
		const {layout, showPopover, openPopover, closePopover,template} = this.props
		const {forceMount} = this.state
		
		const	popoverSide = "right"
		const popoverSideOffset= 14
		const	popoverAlign = "top"
		const	popoverAlignOffset = 40
		
		const translateY = -8

		const html=template.html
		
		return (
			<React.Fragment>
				<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>					
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'docEditorPreviewPopoverContainer ' + (layout ? ` docEditorPreviewPopoverContainer--layout--${layout} ` : ' ') + (showPopover ? ' docEditorPreviewPopoverContainer--visible ' : ' docEditorPreviewPopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							//onInteractOutside={closePopover}							
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
								enter={{ opacity: 1, transform: 'translateY(0px)'  }}
								leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
								reverse={showPopover}
								config={{tension: 1000, friction: 36}}
								//config={{duration: 0}}
								
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="docEditorPreviewPopover popover"> 	

										<div className='docEditorPreviewPopover-docContainer'>
											<div className='docEditorPreviewPopover-doc'>
												{template && template.name && 
													<div className='docEditorPreviewPopover-doc-title'>
														{template.name}
													</div>
												}
												<div 
													className="docEditorPreviewPopover-doc-innerWrapper"
													dangerouslySetInnerHTML={createMarkup(html)}
												/>
											</div>
										</div>
										<div className='docEditorPreviewPopover-bottomGradient' />

											
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			</React.Fragment>
		)
	}
}

export default DocEditorTemplatePreviewPopover