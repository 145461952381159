import React from 'react'
import Icon from '../../misc/Icon'
import LinearProjectStatusIcon from '../../misc/LinearProjectStatusIcon'
import * as ContextMenu from '@radix-ui/react-context-menu';
import {updateProjectStatus} from '../../../utils/updateProject/updateProjectStatus'


class RoadmapsProjectRowEditStatusContextSubMenuItem extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectProjectStatus=this.handleSelectProjectStatus.bind(this)
	}

	handleSelectProjectStatus(e){
		const {projectId,projectStatus}=this.props
		updateProjectStatus(projectId,projectStatus)
	}

	render(){	
		const {active, projectStatus} = this.props	
		let projectStatusLabel = projectStatus    
    if(projectStatus === 'started'){
      projectStatusLabel = 'in progress'
    }
		return (
			<ContextMenu.Item onSelect={this.handleSelectProjectStatus} className={'popoverMenu-item popoverMenu-item--editProjectStatus ' + (active ? ' popoverMenu-item--active ' : ' popoverMenu-item--inactive ')}>
				<div className='popoverMenu-item-markerContainer'>
					<LinearProjectStatusIcon 
						projectStatus={projectStatus}
					/>
				</div>
				<div className='popoverMenu-item-label'>
					{projectStatusLabel}
				</div>
			</ContextMenu.Item>
		)
	}
}


class RoadmapsProjectRowEditStatusContextSubMenu extends React.Component{  	

	render(){	
		const {projectId,currentStatus} = this.props
		return (
			<React.Fragment>         	
				<RoadmapsProjectRowEditStatusContextSubMenuItem 				
					projectStatus="backlog"	
					projectId={projectId}
					active={currentStatus=="backlog"}					
				/>
				<div className='popoverMenu-separator' />
				<RoadmapsProjectRowEditStatusContextSubMenuItem 				
					projectStatus="planned"					
					projectId={projectId}
					active={currentStatus=="planned"}					
				/>
				<RoadmapsProjectRowEditStatusContextSubMenuItem 				
					projectStatus="started"					
					projectId={projectId}
					active={currentStatus=="started"}					
				/>
				<RoadmapsProjectRowEditStatusContextSubMenuItem 				
					projectStatus="completed"					
					projectId={projectId}
					active={currentStatus=="completed"}					
				/>
				<div className='popoverMenu-separator' />
				<RoadmapsProjectRowEditStatusContextSubMenuItem 				
					projectStatus="canceled"					
					projectId={projectId}
					active={currentStatus=="canceled"}					
				/>
				<RoadmapsProjectRowEditStatusContextSubMenuItem 				
					projectStatus="paused"					
					projectId={projectId}
					active={currentStatus=="paused"}					
				/>
					
			</React.Fragment>
		)
	}
}

export default RoadmapsProjectRowEditStatusContextSubMenu