import {fetch,postCloudinary} from '../api'




export function uploadImageToCloudinary(file) {
	return (dispatch) => fetch(`/cloudinary`)
			.then((response) => {
				let form = new FormData()
				form.append('api_key', response.apiKey)
				form.append('timestamp', response.timestamp)
				form.append('signature',response.signature)
				form.append('file', file)
				return dispatch(uploadFileToCloudinary(form))
				.then((response) => {
			  	return response
			  })
			  .catch((err) => {
				})
			})
			.catch((err) => {
			})		
}


export function uploadFileToCloudinary(form) {
	return (dispatch) => postCloudinary(form)
		.then((uploadResponse) => {
			return uploadResponse
		})
		.catch((err) => {
		})
		.catch((err) => {
		})
}






// export function uploadImageToCloudinary(file) {
// 	console.log('upload image to cloudinary ACTION=------')
// 	console.log('file')
// 	console.log(file)
// 	//1. fetch signature from api



// 				let form = new FormData()
// 				form.append('file', file)
// 				console.log(form)
// 				form.append('api_key', "299812761842342")
// 				 //form.append('timestamp', 1651097080)
// 				//form.append('signature',"995e61cbe3259d0e39840fdfe12b492a3afb058e")
// 				// form.append('public_id', public_id)
// 				// if(upload_preset){
// 				 form.append('upload_preset', 'dc51tawn')
// 				// }

// 				console.log(form)
// 				return (dispatch)=>postCloudinary(form)
// 				.then((response) => {
// 					console.log('yooooooo')
// 					console.log(response)
// 			  	return response
// 			  })
// 			  .catch((err) => {
// 				console.log('ERRRROR')
// 				console.log(err)
// 			})


// 			//})
// 			// .catch((err) => {
// 			// 	console.log('ERRRROR')
// 			// 	console.log(err)
// 			// })
		
// }
