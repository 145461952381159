import schema from '../schema/editorSchema'  


export function updateImageTitle(nodeId,title) {
	if(window.view){
		const state=window.view.state
		let tr=state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.nodeId==nodeId){
				tr.setNodeMarkup(pos, null, {...node.attrs,title:title})
			}
		})
		window.view.dispatch(tr)
	}
}