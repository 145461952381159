import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import {fetchRecording} from '../../../actions/recording'
import * as UpChunk from '@mux/upchunk';
import find from 'lodash/find'
import RecordingPlaybackModalContainer from '../../../components/recordingPlaybackModal/RecordingPlaybackModalContainer'

class TranscriptTest extends Component {

	constructor(props) {
		super(props)		
		this.handleVideoUpload=this.handleVideoUpload.bind(this)
		this.uploadToMux=this.uploadToMux.bind(this)
		this.fetchRecording=this.fetchRecording.bind(this)
		this.state={	
			recordingId:'2',		
			uploading:false,
			uploadFinished:false,
			processing:false,
			progress:0,
			showRecordingPlaybackModal:false
		}
		
	} 

	componentDidMount(){
		this.props.fetchRecording(2)
	}

	fetchRecording(){
		console.log('fetch recording')
		this.props.fetchRecording(2)
	}

	uploadToMux(file,uploadUrl){
		console.log('upload to mux')
		console.log(file)
		console.log(uploadUrl)
		const upload = UpChunk.createUpload({
			// getUploadUrl is a function that resolves with the upload URL generated
			// on the server-side
			endpoint: uploadUrl,
			// picker here is a file picker HTML element
			file: file,
			chunkSize: 5120, // Uploads the file in ~5mb chunks
		});
		
		upload.on('error', err => {
			console.error('💥 🙀', err.detail);
		});

		upload.on('progress', progress => {
			console.log('Uploaded', progress.detail, 'percent of this file.');
			this.setState({progress:progress.detail})
		});

		// subscribe to events
		upload.on('success', err => {
			console.log("Wrap it up, we're done here. 👋");
			this.setState({uploading:false,uploadFinished:true,processing:true})
			//this.fetchUpload()
		});  
	}



	handleVideoUpload(e){
		console.log('upload video')
		const file=event.target.files[0]
		console.log('file')
		
		this.setState({uploading:true})
		
		this.props.createRecordingUpload().then((response)=>{
			console.log('upload thing')
			console.log(response)
			const uploadUrl=response.upload.url
			this.setState({recordingId:response.recording.recording_id})
			this.uploadToMux(file,uploadUrl)
		})
	}


	render(){  
		const {uploading,uploadFinished,progress,processing,recordingId} = this.state

		let recordingReady=false 
		if(this.props.recordings && find(this.props.recordings, {'recording_id':recordingId})){
			recordingReady=true
		}
		console.log(`recording ready---- ${recordingReady}`)
		return (	  	
			<div className='playgroundTranscriptProto'>
				transcript test

				<input type="file"  onChange={this.handleVideoUpload} ></input>
				<br/>
				{recordingId && 
					<div> recording ID: {recordingId}</div>
				}
				{uploading && 
					<div> uploading...... progress:{progress}</div>
				}
				{uploadFinished && 
					<div> UPLOAD COMPLETE</div>
				}
				{processing && !recordingReady &&
					<div> processing media </div>
				}
				{recordingReady &&
					<div> READY!!! <br/>
					<button onClick={()=>{this.setState({showRecordingPlaybackModal: !this.state.showRecordingPlaybackModal})}}>
						Open Recording Modal
					</button>

					<RecordingPlaybackModalContainer 
						recordingId={recordingId}
						showModal={this.state.showRecordingPlaybackModal}
						openModal={()=>{this.setState({showRecordingPlaybackModal: true})}}
						closeModal={()=>{this.setState({showRecordingPlaybackModal: false})}}
					/>
					</div>
				}

			</div>   	
		)
	}
}



function mapStateToProps(state,ownProps) {


	return {
		recordings:state.recordings
			//figmaEmbeds:state.figmaEmbeds
	}
}


export default withRouter(connect(mapStateToProps,
	{fetchRecording}
)(TranscriptTest))	

