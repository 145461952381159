import { ACCEPTED_ASSET_TYPE, useEditor } from '@tldraw/editor'
import { useCallback, useEffect, useRef } from 'react'

export function useInsertMedia() {
	const editor = useEditor()
	const inputRef = useRef(null)

	useEffect(() => {
		const input = window.document.createElement('input')
		input.type = 'file'
		input.accept = ACCEPTED_ASSET_TYPE
		input.multiple = true
		inputRef.current = input
		function onchange(e) {
			const fileList = e.target.files
			if (!fileList || fileList.length === 0) return
			editor.putExternalContent({
				type: 'files',
				files: Array.from(fileList),
				point: editor.viewportPageBounds.center,
				ignoreParent: false,
			})
			.then(() => input.value = '')
		}
		input.addEventListener('change', onchange)
		return () => {
			inputRef.current = null
			input.removeEventListener('change', onchange)
		}
	}, [editor])

	return useCallback(() => {
		if (inputRef.current) inputRef.current.click()
	}, [inputRef])
}
