import store from '../../store'
import {randomID} from '../randomID'
import {getCurrentUser} from '../getCurrentUser'
import {createNewMeetingFromCalEventRequest} from '../../actions/meetings'


const template={
	version:0,
	name:'Untitled Doc',
	source:{
		"type": "doc", "content": [
			{"type": "editorPage", "content": [
				{"type": "paragraph"}
			]}
		]}
}

function getNewDocTemplate(){
	const currentUser=getCurrentUser()
	let doc={}
	const nowDate=new Date()
	if(currentUser){
		doc.created_by=currentUser.user_id
	}
	doc.created_at=nowDate
	doc.updated_at=nowDate
	doc.name=template.name
	doc.source=template.source
	doc.version=1
	doc.team=null
	doc.roadmap=null
	doc.project=null
	doc.is_org_doc=false
	const docId=`${randomID()}`
	doc.doc_id=docId
	return doc
}




export function createNewMeetingFromCalEvent(event,history) {
	const title = event.summary
	const meetingId=`${randomID()}`
	const docId=`${randomID()}`
	let meetingDoc=getNewDocTemplate()
	meetingDoc.meeting=meetingId
	meetingDoc.followers=[]
	meetingDoc.doc_id=docId
	meetingDoc.name=title||'Untitled'
	store.dispatch(createNewMeetingFromCalEventRequest(meetingId,docId,meetingDoc,event,history))
}




