import { Plugin, PluginKey } from 'prosemirror-state'
import { Decoration, DecorationSet } from "prosemirror-view"
//import EditTextLinkReactComponent from './EditTextLinkReactComponent'
import ReactDOM from 'react-dom'
import { Slice, Fragment } from 'prosemirror-model';
import schema from '../../schema/editorSchema'  
//import NewTextLinkReactComponent from '../../../components/docEditor/NewTextLinkReactComponent'
import NewTextLinkAnchorReactComponent from '../../../components/nodeViews/links/NewTextLinkAnchorReactComponent'

import {getAnchorNode} from '../../utils/getAnchorNode'
import {isInTable} from 'prosemirror-tables'


const PARAGRAPH_SPAN_CLASSNAME="doc-para-span"
const SELECTION_MENU_MAX_HEIGHT=225
const MENU_WIDTH=402 // maybe this can be dynamically calculated

function getNodePos(anchorNode){
  const state=window.view.state
  let nodePos
  state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
    if(anchorNode===node){
      nodePos=pos
    }
  })
  return nodePos
}


//TODO do we want to add/remove menu or add styling
//If its always there need to make sure you cant e.g. tab into it and select when press enter or somethimg
function positionMenuOverlay(top,bottom,left,width) {
  const el = document.getElementById('textLinkHoverMenu')
  
  ReactDOM.render(<NewTextLinkAnchorReactComponent
      openPopover={true}
    />,el)
  
  const popoverEl = document.getElementById('newTextLinkPopover')
  if(el){ 
    let scrollTop = 0    
    const scrollableEl=document.getElementById('scrollable_div')
    if(scrollableEl){
      scrollTop=scrollableEl.scrollTop      
    }


    el.style.top = `${top - 44 + scrollTop}px`; // 44 is the top menu height

    // el.style.bottom = `${bottom + scrollTop}px`;
    el.style.left = `${left}px`;
    // el.style.right = `${right}px`;


    el.style.height = `${bottom - top}px`;
    el.style.width = `${width}px`;

    // // VERTICAL OFFSET
    // // ABOVE OR BELOW
    // const flipBuffer = 20 // don't want to get close to overflowing bottom of screen
    // let popoverPosition
    // if(top > (window.innerHeight - SELECTION_MENU_MAX_HEIGHT - flipBuffer)){
    //   popoverPosition = "above"
    // }else{
    //   popoverPosition = "below"
    // }
    // if(popoverPosition === "above" && popoverEl){
    //   el.style.top = `${top - SELECTION_MENU_MAX_HEIGHT - 49 + scrollTop}px`;
    //   popoverEl.style.justifyContent = "flex-end"; // popover inner content vertically aligns to bottom
    // }
    // if(popoverPosition === "below") {    
    //   el.style.top = `${bottom - 38 + scrollTop}px`;// not sure where the 38 comes from
    //   popoverEl.style.justifyContent = "flex-start"; // popover inner content vertically aligns to bottom
    // }
    // el.style.width = `${MENU_WIDTH}px`;
    // el.style.height = `${SELECTION_MENU_MAX_HEIGHT}px`;
    // // Adjust left to prevent overflow into sidemenu tabs, where z-index is really hard
    // // we also want don't want to switch to fixed position, because that has performance problems on scroll
    // // This solution seems quite nice for now, i.e. only affects behaviour when necessary (smaller screens)
    // let docAreaBounding
    // let docAreaWidth = 0
    // if(scrollableEl){      
    //   docAreaBounding=scrollableEl.getBoundingClientRect()
    //   docAreaWidth=docAreaBounding.width      
    // }
    // const edgeBuffer = 8 // distance from edge of selection menu to scrollable div
    // let minimumLeftRenderedPos = edgeBuffer
    // let maximumLeftRenderedPos = docAreaWidth - MENU_WIDTH - edgeBuffer
    // const adjustedLeft = Math.min(Math.max(minimumLeftRenderedPos, left), maximumLeftRenderedPos)
    // el.style.left = `${adjustedLeft}px`;
    // el.classList.remove("hoverMenu--hidden")
  }
}

function hideMenuOverlay() {
  const el = document.getElementById('textLinkHoverMenu') //New 24th Nov only add if it doesnt have the class-->try to stop flashing while typing
  ReactDOM.unmountComponentAtNode(el)
  if(el){ 
    if(el.classList.contains("doc-textLink-newPopoverAnchor--hidden")){
      //do nothing
    } 
    else el.classList.add("doc-textLink-newPopoverAnchor--hidden")
  }
}



function rectIsInSelection(rect,fromCoord,toCoord){
  let isInSelection=false
  if(rect.top>=fromCoord.top && toCoord.bottom>=rect.bottom){isInSelection=true}
  return isInSelection
}

//find the left and right coords for a single line
//If rect is not the same line as selection begining/end then coords are the same as the span rect
//If rect on the same line as selection start or end we "normalise"--> adjust so have a rect for partial width contained in selection
//e.g. if rect is on the same line as the selection start we "normalise left"--> left is fromCoord.left NOT rect.left
function getLineSelectionCoords(rect,fromCoord,toCoord){
  let left
  let right
  let normaliseLeft=false
  if(rect.top==fromCoord.top){
    normaliseLeft=true
  }
  if(!normaliseLeft){
    left=rect.left
  }else{
    left=fromCoord.left
  }
  let normaliseRight=false
  if(rect.bottom==toCoord.bottom){
   normaliseRight=true
  }
  if(!normaliseRight){
    right=rect.right
  }else{
    right=toCoord.right       
  }
  return {left:left,right:right}
}




//DocEditorContainer is the whole page
//Editor is where the text starts
function getInsetLeft(){
  let docEditorContainerLeft=0  
  const docEditorContainerElement=document.getElementById('docEditorContainer_div')
  if(docEditorContainerElement){
    docEditorContainerLeft= docEditorContainerElement.getBoundingClientRect().left
  }
  let editorLeft=0
  const editorElement=document.getElementById('editor')
  if(editorElement){
    editorLeft= editorElement.getBoundingClientRect().left
  }
  const insetLeft = editorLeft - docEditorContainerLeft // distance from doc Container to Editor
  return insetLeft
}


//Find the mimunum left and maximum right accross selected lines
function calculateMaxAndMin(fromCoord,toCoord){
  const paragraphSpans = document.getElementsByClassName(PARAGRAPH_SPAN_CLASSNAME);
  let minimumLeft   
  let maximumRight
  for (const span of paragraphSpans) {
    const rects = span.getClientRects(); //https://developer.mozilla.org/en-US/docs/Web/API/Element/getClientRects
    for (const rect of rects) {//Check each rect to see if it is contained in the selection      
      const isInSelection=rectIsInSelection(rect,fromCoord,toCoord)
      if(isInSelection){
        const lineCoords=getLineSelectionCoords(rect,fromCoord,toCoord)
        if(!minimumLeft || lineCoords.left<minimumLeft){
          minimumLeft=lineCoords.left
        }
        if(!maximumRight || lineCoords.right>maximumRight){
          maximumRight=lineCoords.right
        }
      }
    }
  }
  return {minimumLeft:minimumLeft,maximumRight:maximumRight}
}




//average thing 
//take the average of maxRight and minLeft to center the menu
function calculateMenuLeftOffset(fromCoord,toCoord){
  const maxAndMin=calculateMaxAndMin(fromCoord,toCoord)
  const {maximumRight,minimumLeft}=maxAndMin

  //normalise all numbers relative to editor left (i.e. where the lines start-->makes the start of the line left=0)
  const normMaxRight=normaliseXToEditorLeft(maximumRight)
  const normMinLeft=normaliseXToEditorLeft(minimumLeft)

  const diff=normMaxRight-normMinLeft

 // const normalisedMenuLeftOffset= normMinLeft + (diff/2) - (MENU_WIDTH / 2) 

  const normalisedMenuLeftOffset= normMinLeft + (diff/2) - ((maximumRight - minimumLeft) / 2) 

  //denormalise-->add back in the offset to get abs position to layout parent
  const insetLeft=getInsetLeft() 
  const leftOffsetToLayoutParent=normalisedMenuLeftOffset+insetLeft
  return leftOffsetToLayoutParent
}

function normaliseXToEditorLeft(x){
  let normalisedX
  let docEditorContainerLeft=0
  const docEditorContainerElement=document.getElementById('docEditorContainer_div')
  if(docEditorContainerElement){
    docEditorContainerLeft= docEditorContainerElement.getBoundingClientRect().left
  }
  let editorLeft=0
  const editorElement=document.getElementById('editor')
  if(editorElement){
    editorLeft= editorElement.getBoundingClientRect().left
  }
  const insetLeft = editorLeft - docEditorContainerLeft // distance from doc Container to Editor
  normalisedX = x - insetLeft - docEditorContainerLeft
  return normalisedX
}

function deco(from, to) {
  return Decoration.inline(from, to, {class:"doc-para-newTextLinkSelection"})
}





export function textLinkPlugin() {
  return new Plugin({
    key: new PluginKey('textLinkPlugin'),
     view(view) {
      return {
        update: (view) => {
          const state=view.state
          const selection=state.selection
          const {from,to,anchor,head}=selection 
          if(selection.empty){ //dont show selection menu for node selections
            hideMenuOverlay()
          }
        },
        destroy:()=>{
         // console.log('destroy plugin!!!!!!!!')
          const el = document.getElementById('textLinkHoverMenu') //New 24th Nov only add if it doesnt have the class-->try to stop flashing while typing
          if(el){
            ReactDOM.unmountComponentAtNode(el)
          }
        }
      }
    },
    state: {
      init() {
         return {active: false}
      },
      apply(tr, prev,oldState,newState) { //TODO test this with colllab
        const next = { ...prev };
        const { selection } = tr
        if(tr.getMeta('newTextLinkPlugin')){
          if(tr.getMeta('newTextLinkPlugin').activate){
            const {from,to,anchor,head}=selection 
            const fromCoord=window.view.coordsAtPos(from) //https://prosemirror.net/docs/ref/#view.EditorView.coordsAtPos
            const toCoord=window.view.coordsAtPos(to)
            
            const menuLeftPos=calculateMenuLeftOffset(fromCoord,toCoord)

            const left=menuLeftPos

            // find width            
            const maxAndMin=calculateMaxAndMin(fromCoord,toCoord)
            const {maximumRight,minimumLeft}=maxAndMin
            const width=maximumRight - minimumLeft

            positionMenuOverlay(fromCoord.top,toCoord.bottom,left,width)
            return {active:true}
          }else if(tr.getMeta('newTextLinkPlugin').deactivate){
            hideMenuOverlay()
            return {active:false}
          }
        }
        return next
        //return oldState
      }
    },
    props: {
      handlePaste(view, event, slice){
        if(isInTable(view.state)){
          console.log('is in table do nothing!!!')
          return false
        }else{
        let state = view.state
        let sel = state.selection
        let plainTextContent
        let pasteIsOnlyUrl
        if(event.clipboardData && event.clipboardData.types.includes("text/plain")) {
          plainTextContent = event.clipboardData.getData("text/plain");
          pasteIsOnlyUrl=onlyContainsURL(plainTextContent)
        }
        const maxOneTextNode = selectionContainsAtMostOneTextNode(state);
        if(!sel.empty && maxOneTextNode && pasteIsOnlyUrl){ //TODO check if is text selection and only 1 para
          let tr=view.state.tr
          const schema=view.state.schema
          const newNode=schema.nodes.textLink.create({ type:'weblink',url: plainTextContent }, schema.text(state.doc.textBetween(sel.from,sel.to)))
          tr.replaceSelectionWith(newNode);
          view.dispatch(tr);
          return true;
        }
        else{
          const modifiedContent = replaceUrlsInFragment(slice.content, schema);
          const modifiedSlice = new Slice(Fragment.fromArray(modifiedContent), slice.openStart, slice.openEnd);
          const transaction = view.state.tr.replaceSelection(modifiedSlice);
          view.dispatch(transaction);
          return true;
        }
      }
      },
      decorations(editorState){
        const selection=editorState.selection
        const state=this.getState(editorState)
        if(!selection.empty && state.active){
          let decoArray=[]
          const selectionDeco=deco(selection.from,selection.to)
          decoArray.push(selectionDeco)
          return DecorationSet.create(editorState.doc, decoArray)
        }else return null
      }
    }
  })
}





function urlRegex() {
  return /(?:^|\s)((https?:\/\/[^\s]+))/g;
}

function isURL(str) {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}

function onlyContainsURL(text) {
  const parts = text.trim().split(/\s+/);
  return parts.length === 1 && isURL(parts[0]);
}

const textLinkClassSpanClass="doc-para-inlineMentionSearchBar"

function replaceUrlsInFragment(fragment, schema) {
  const result = [];
  fragment.forEach(node => {
    if (node.isText && node.type.name!='textLink') {
      const textContent = node.text;
      const matches = Array.from(textContent.matchAll(urlRegex()));
      if (matches.length > 0) {
        let lastIndex = 0;
        for (const match of matches) {
          const [fullMatch, url] = match;
          const matchIndex = textContent.indexOf(fullMatch, lastIndex);
          if (matchIndex > lastIndex) {
            result.push(schema.text(textContent.slice(lastIndex, matchIndex)));
          }
          result.push(schema.nodes.textLink.create({ type:'weblink',url: url }, schema.text(url)));
          result.push(schema.text('\u00A0'))
          lastIndex = matchIndex + fullMatch.length;
        }
        if (lastIndex < textContent.length) {
          result.push(schema.text(textContent.slice(lastIndex)));
        }
        return;
      }
    }
    if (node.childCount > 0 || node.type.name=='textLink') {
      const newContent = replaceUrlsInFragment(node.content, schema);
      result.push(node.copy(Fragment.fromArray(newContent)));
    } else {
      result.push(node);
    }
  });

  return result;
}

function selectionContainsAtMostOneTextNode(editorState) {
  const { from, to } = editorState.selection;
  let textNodeCount = 0;
  editorState.doc.nodesBetween(from, to, node => {
    if (node.isText) {
      textNodeCount += 1;
    }
    // Stop iterating if there are more than one text node
    return textNodeCount <= 1;
  });
  return textNodeCount <= 1;
}



