import React from 'react'
import { connect } from 'react-redux'
import Icon from './misc/Icon'
import AvatarImage from './AvatarImage'
import * as Dialog from '@radix-ui/react-dialog';
import { Transition, animated, config } from 'react-spring'
import sortBy from 'lodash/sortBy'
import {toggleTheme} from '../utils/toggleTheme'
import {logout} from '../actions/auth'
import {isCurrentUser} from '../utils/isCurrentUser'
import {getVersionNumber} from '../utils/getVersionNumber'
import SettingsFigmaIntegration from './integrations/SettingsFigmaIntegration'

const JOIN_SLACK_URL="https://join.slack.com/t/yarncustomers/shared_invite/zt-1pi4wxy3l-Hjbv1loRn8cXpNA07CeGjw"

class SettingsDialogTeammateRow extends React.PureComponent{  	
	render(){	
		const {user} = this.props		
	  return (	 	  	
			<button  className="settingsDialog-teammateRow" >
				<div className='settingsDialog-teammateRow-avatarContainer'>
					<AvatarImage 
						className='settingsDialog-teammateRow-avatar'
						avatarUrl={user.avatar}
					/>
				</div>
				<div className='settingsDialog-teammateRow-username'>
					{user.display_name}			
				</div>
				<div className='settingsDialog-teammateRow-email'>
					{user.email}
				</div>
			</button>
	  )
	}
}

class SettingsDialogThemeButton extends React.PureComponent{  	
	render(){	
		const {active, theme} = this.props		
		const themeLabel = theme
	  return (	 	  	
			<button onClick={this.props.setTheme} className={'settingsDialog-theme-btn ' + (active ? ' settingsDialog-theme-btn--active ' : ' settingsDialog-theme-btn--inactive ')}>
				<div className='settingsDialog-theme-btn-radioContainer'>
					<div className='settingsDialog-theme-btn-radio'>
						{active && <div className='settingsDialog-theme-btn-radio-inner' /> }
					</div>
				</div>
				<div onClick={this.props.toggleTheme} className='settingsDialog-theme-btn-label'>
					{themeLabel}
				</div>
			</button>
	  )
	}
}

class SettingsDialogTheme extends React.PureComponent{  	
	render(){	
		const {currentTheme} = this.props		
	  return (	 	  	
			<div className='settingsDialog-toggleTheme'>
				<div onClick={this.toggleTheme} className='settingsDialog-toggleTheme-header'>
					Appearance
				</div>
				<div className='settingsDialog-toggleTheme-description'>
					Switch with Shift + Command + \
				</div>			
				<div className='settingsDialog-toggleTheme-btnContainer'>
					<SettingsDialogThemeButton 
						theme='light'
						active={currentTheme === 'light'}
						setTheme={this.props.makeLightTheme}
					/>
					<SettingsDialogThemeButton 
						theme='dark'
						active={currentTheme === 'dark'}
						setTheme={this.props.makeDarkTheme}
					/>
				</div>
			</div>
	  )
	}
}

class SettingsDialogProfile extends React.PureComponent{  	
	render(){	
		const {userName, orgName,userEmail,avatarUrl} = this.props		
	  return (	 	  	
			<div className='settingsDialog-profile'>
				<div className='settingsDialog-profile-avatarContainer'>
					<AvatarImage 
						className='settingsDialog-profile-avatar'	
						avatarUrl={avatarUrl}						
					/>
				</div>
				<div className='settingsDialog-profile-rightContainer'>
					<div className='settingsDialog-profile-name'>
						{userName}
					</div>
					<div className='settingsDialog-profile-org'>
						{orgName}
					</div>
					<div className='settingsDialog-profile-email'>
						{userEmail}
					</div>
				</div>												
			</div>
	  )
	}
}

class SettingsDialog extends React.Component{  	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)
    this.switchTheme=this.switchTheme.bind(this)
    this.state={
      forceMount: false  
    }
  }

  onRest() {	
		if(!this.props.showDialog){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

	switchTheme(newTheme){
		if(newTheme!==this.props.theme){
			toggleTheme()
		}
	}

	openSlackLink(){
    if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:JOIN_SLACK_URL})     
    }
    else{
      window.open(JOIN_SLACK_URL,'_blank')
    }
  }

	render(){	
		const {showDialog, openDialog, closeDialog,organization,currentUser,orgMembers,theme} = this.props
		const {forceMount} = this.state
		let membersArray=[]
    if(orgMembers){
      Object.keys(orgMembers).forEach((key)=>{
        let member=orgMembers[key]    
        if(!isCurrentUser(member.user_id)){
        	 membersArray.push(member)
        }   
      })
    }
		const sortedMembers = sortBy(membersArray, function(member){
      return member.name.toString().toLowerCase()
    })

		let transformStart = 'scale(0.92)'
		let transformEnd = 'scale(1)'
		let userName
		let userEmail
		let avatarUrl
		if(currentUser){
			userName=currentUser.display_name
			userEmail=currentUser.email
			avatarUrl=currentUser.avatar
		}		
		let orgName = ''
		if(organization){
			orgName=organization.name
		}

	  return (
	  	<React.Fragment>
	  		<Dialog.Root>
					<Dialog.Trigger asChild>
						{this.props.children}
					</Dialog.Trigger>
					<Dialog.Portal 
						forceMount={showDialog ? true : forceMount}						
					>					
						<Dialog.Content
				    	className={'settingsDialogContainer ' + (showDialog ? ' settingsDialogContainer--visible ' : ' settingsDialogContainer--hidden ')} 
				    	onEscapeKeyDown={closeDialog}
					    onPointerDownOutside={closeDialog}
					    onInteractOutside={closeDialog}
					    forceMount={showDialog ? true : forceMount}
					    //side="bottom"
					    //sideOffset={4}
					    //asChild
				    >
					    <Transition
				        items={showDialog}
				        from={{ opacity: 0, transform: `${transformStart}` }}
				        enter={{ opacity: 1, transform: `${transformEnd}`  }}
				        leave={{ opacity: 0, transform: `${transformStart}`  }}
				        reverse={showDialog}
				        config={{tension: 700, friction: 36}}
				        // config={{tension: 600, friction: 24}}
				        // config={config.molasses}
				        onRest={this.onRest}
				       >
				        {(styles, item) =>
				          item && 
				           <animated.div style={styles} className="settingsDialog"> 					           	
					           	<button onClick={closeDialog} className='settingsDialog-closeBtn'>
					           		<Icon name='crossSemibold'/>
					           	</button>
					           	<SettingsDialogProfile 
					           		userName={userName}
					           		orgName={orgName}
					           		userEmail={userEmail}
					           		avatarUrl={avatarUrl}
					           	/>					          	
					          	<div className='settingsDialog-divider' />
					          	<SettingsDialogTheme
					           		currentTheme={theme}
					           		makeDarkTheme={()=>{this.switchTheme('dark')}}
					           		makeLightTheme={()=>{this.switchTheme('light')}}					           		
					           	/>
					           	<div className='settingsDialog-divider' />
					           	<SettingsFigmaIntegration/>
											<div className='settingsDialog-teammates'>
												<div className='settingsDialog-teammates-header'>
													Teammates													
												</div>
												<div className='settingsDialog-teammates-list'>																
													{sortedMembers && sortedMembers.map((user)=>{                                						                  
														return(	                    
															<SettingsDialogTeammateRow			                    	
																key={user['user_id']}
																user={user}						                    	
															/>
														)
													})}							               		
												</div>																									          		 
											</div>											
											<div className='settingsDialog-vSpacer' />
											<div className='settingsDialog-bottom'>
												<button className='settingsDialog-bottom-label settingsDialog-bottom-label--joinSlack' onClick={this.openSlackLink}>
													Join Slack Community
												</button>
												<div className='settingsDialog-bottom-label settingsDialog-bottom-label--version'>
													Version {getVersionNumber()}
												</div>
												<button className='settingsDialog-bottom-label settingsDialog-bottom-label--signOut' onClick={this.props.logout}>
													Sign Out
												</button>
											</div>
				          </animated.div>
				        }
				      </Transition> 										
				    </Dialog.Content>
			    </Dialog.Portal>
			  </Dialog.Root>		
			</React.Fragment>
	  )
	}
}
 
function mapStateToProps(state,ownProps) {	
	return {
		organization:state.organization,	
		currentUser:state.user,
		orgMembers:state.orgMembers,
		theme:state.theme
	}
}
export default connect(mapStateToProps,
	{logout}
)(SettingsDialog)

//export default SettingsDialog