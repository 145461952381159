import store from '../store'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'




export function getProjectMilestoneDocs(projectId) {	
	let subDocs=[]
	const state = store.getState()
	const projectMilestones=filter(state.projectMilestones,{project:projectId}) 
	const sorted=sortBy(projectMilestones, function(milestone){
  	return parseInt(milestone.sort_order,10)
  })
	sorted.forEach((milestone)=>{
		const doc=find(state.docs,{milestone:milestone.milestone_id})
		let obj={
			...doc,
			milestoneObj:milestone
		}
		subDocs.push(obj)
	})
	return subDocs
}

