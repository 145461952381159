import {InputRule} from 'prosemirror-inputrules'
import editorSchema from '../../schema/editorSchema'
import {getAnchorNode} from '../../utils/getAnchorNode'
import {TextSelection} from "prosemirror-state"
import { Mixpanel } from '../../../Mixpanel'

export function codeBlockInputRule(){
	return new InputRule(/^\`\`\`/g, (state, match, start, end) => {
		let tr = state.tr
		let sel = state.selection
		const $from=state.doc.resolve(sel.anchor)
		const anchorNode=getAnchorNode($from,'docCodeMirror')
		if(!anchorNode){
			let indentLevel=0
			// if(anchorNode){
			// 	indentLevel=anchorNode.attrs.indentLevel
			// }	
			let type = editorSchema.nodes.docCodeMirror
			const node = type.createAndFill() 
			tr.replaceWith(start-1, end+1, node)
			let selection=new TextSelection(tr.doc.resolve(start))
			tr.setSelection(selection)
			Mixpanel.track('insert_codemirror',{method:'inputRule'})
			return tr
	}
		else return false
	})
}

//Old pre codemirror input rule
// export function codeBlockInputRule(){
// 	return new InputRule(/^\`\`\`/g, (state, match, start, end) => {
// 		console.log('code block input rule')
// 		console.log(match)

// 		let tr = state.tr
// 		let sel = state.selection
// 		const $from=state.doc.resolve(sel.anchor)
// 		const anchorNode=getAnchorNode($from,'codeBlock')
// 		if(!anchorNode){
// 			let indentLevel=0
// 			if(anchorNode){
// 				indentLevel=anchorNode.attrs.indentLevel
// 			}	
// 			let type = editorSchema.nodes.codeBlock
// 			const node = type.createAndFill() 
// 			tr.replaceWith(start-1, end+1, node)
// 			let selection=new TextSelection(tr.doc.resolve(start+2))
// 			tr.setSelection(selection)
// 			return tr
// 	}
// 		else return false
// 	})
// }






	// let type = schema.nodes.docCodeMirror	
	// 		const headingNode = type.createAndFill()
	// 		let tr = view.state.tr
	// 		let replaceStart=state.range.from
	// 		let replaceEnd=state.range.to
	// 		tr.replaceWith(replaceStart, replaceEnd, headingNode)
	// 		let selection=new TextSelection(tr.doc.resolve(replaceStart+2))
	// 		tr.setSelection(selection)
	// 		tr.setMeta("insertPlugin", { deactivate: true })
	// 		view.dispatch(tr)
	// 		hideList()
