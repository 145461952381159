import {getAnchorNode} from '../getAnchorNode'

export function indentMoreCommand(state,dispatch,view) {
	//console.log('indent more command 111111')
	const delta=1
	return indentCommand(state,dispatch,delta)
}

export function indentLessCommand(state,dispatch,view) {
	//console.log('indent less command')
	const delta=-1
	return indentCommand(state,dispatch,delta)
}

export function maybeIndentLessCommand(state,dispatch,view) {
	//console.log('------------------------------maybe indent less command-----------------------')
	const {selection, schema,doc} = state
	let {tr} = state
	const {from, to} = selection
	if(selection.empty){
		tr.doc.nodesBetween(from, to, (node, pos) => {
			const nodeType = node.type;
			//if(nodeType.name === 'paragraph' || nodeType.name === 'bulletListItem' || nodeType.name === 'toDoItem'){
				if(nodeType.name === 'paragraph' || nodeType.name === 'bulletListItem' || nodeType.name === 'toDoItem'){
					if(nodeType.name === 'toDoItem' && pos+2===from && node.attrs.assignees.length>0){	//if is a todo item check if there are assignees and clear them instead of unindent
						const nodeAttrs = {
							...node.attrs,
							assignees:[],
						};
						//console.log('1')
						tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
					}else if((nodeType.name === 'paragraph' && pos+1===from)
						||(nodeType.name === 'bulletListItem' && pos+2===from)
						||(nodeType.name === 'toDoItem' && pos+2===from)){
						if(node.attrs.indentLevel!==0){
							const nodeAttrs = {
								...node.attrs,
								indentLevel:Math.max(node.attrs.indentLevel-1,0),
							};
							// console.log(nodeType.name)
							// console.log(nodeAttrs)
							// console.log('2222')
							tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
					}
				}
			}
		});
	}
	if (tr.docChanged) {
		dispatch && dispatch(tr);
		//console.log('maybe indent less teturn true!!!')
		return true;
	} else {
		//console.log('maybe indent lessreturn dalse')
		return false;
	}
	//console.log('maybe indent less false---')
	return false
}

function indentCommand(state,dispatch,delta){
	const {selection, schema,doc} = state
	let {tr} = state
	const {from, to,$from} = selection
	let isInTable=false
	tr.doc.nodesBetween(from, to, (node, pos) => {
		const nodeType = node.type;
		let newListIndentLevel
		if(nodeType.name == 'paragraph'|| nodeType.name == 'bulletListItem'|| nodeType.name == 'toDoItem'){
			const tableAnchor=getAnchorNode($from,'table') //dont allow indenting in tables
			console.log('table anchor-----')
			console.log(tableAnchor)
			if(tableAnchor){
				isInTable=true
			}
			else{
				let nodeAttrs = {
					...node.attrs,
					indentLevel:Math.max(node.attrs.indentLevel+delta,0),
				};
				tr.setNodeMarkup(pos, node.type, nodeAttrs, node.marks)
			}			
		}
	});
	if (tr.docChanged) {
		//console.log('indent command return true')
		//tr.set
		dispatch && dispatch(tr);
		return true;
		} else {
			if(isInTable){
				return true;
			}else{
				return false;
			}
		
	}
}