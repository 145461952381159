import React from 'react'
import Icon from '../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import ReactPlayer from 'react-player/file'
import ReactSlider from 'react-slider'
import DocImageZoomedModalBtn from '../docEditor/mediaModals/DocImageZoomedModalBtn'
import VideoDurationFull from '../../utils/videoDurationFull'
import ReactCursorPosition from 'react-cursor-position'
import RecordingPlaybackModalMediaPlaybackControlsSeekBar from './RecordingPlaybackModalMediaPlaybackControlsSeekBar'
import RecordingPlaybackModalMediaPlaybackControlsSpeakerTimelines from './RecordingPlaybackModalMediaPlaybackControlsSpeakerTimelines'
import RecordingPlaybackModalMediaPlaybackControlsHighlights from './RecordingPlaybackModalMediaPlaybackControlsHighlights'
import RecordingPlaybackModalMediaPlaybackSpeed from './RecordingPlaybackModalMediaPlaybackSpeed'

const MOUSE_MOVE_SHORT_DEBOUNCE_TIME = 1800
const MOUSE_MOVE_LONG_DEBOUNCE_TIME = 4000


class RecordingPlaybackModalMediaPlaybackControls extends React.Component{  

	render(){ 
    const {playing, handlePlayPause, skipBack15, skipForward15, progressSeconds, duration, played, muxPlaybackID, handleSeekChange, handleSeekMouseDown, handleSeekMouseUp} = this.props
    const hasPreviousSpeaker = true // only not true if in time before first person starts speaking
    const hasNextSpeaker = true

    //const showCancelHighlightButton = this.props.showNewHighlightPanel && !this.props.cmdKeyIsDown
    const showCancelHighlightButton = this.props.showNewHighlightPanel

		return (
				<div className='recordingPlaybackModal-media-playbackControls'>

          
          <button data-state={showCancelHighlightButton ? 'visible' : 'hidden'} onClick={this.props.cancelCreateHighlight} className='recordingPlaybackModal-media-playbackControls-cancelHighlightBtn' />
          

          <div className='recordingPlaybackModal-media-playbackControls-buttonRow'>
            <div className='recordingPlaybackModal-media-playbackControls-buttonRow-farEdgeSpacer' />
           

            <div className='recordingPlaybackModal-media-playbackControls-buttonRow-timestamps'>              
              <div className='recordingPlaybackModal-media-playbackControls-buttonRow-timestamps-played'>
                <VideoDurationFull seconds={progressSeconds} />
              </div>
              <div className='recordingPlaybackModal-media-playbackControls-buttonRow-timestamps-dash'>
                /
              </div>
              <div className='recordingPlaybackModal-media-playbackControls-buttonRow-timestamps-total'>
                <VideoDurationFull seconds={duration} />
              </div>
            </div>
              


            <div className='recordingPlaybackModal-media-playbackControls-buttonRow-innerEdgeSpacer' />
            <div className='recordingPlaybackModal-media-playbackControls-buttonRow-skipBtnContainer'>
              <DocImageZoomedModalBtn
                iconName='playbackSeekBack15'
                onClickFunction={skipBack15}               
              />
            </div>
            <div className='recordingPlaybackModal-media-playbackControls-buttonRow-playPauseBtnContainer'>
              <DocImageZoomedModalBtn
                iconName={playing ? 'playbackPause' : 'playbackPlay'}                 
                onClickFunction={handlePlayPause}               
              />
            </div>
            <div className='recordingPlaybackModal-media-playbackControls-buttonRow-skipBtnContainer'>
              <DocImageZoomedModalBtn
                iconName='playbackSeekForward15'
                onClickFunction={skipForward15}               
              />
            </div>
            <div className='recordingPlaybackModal-media-playbackControls-buttonRow-innerEdgeSpacer' />            

            <RecordingPlaybackModalMediaPlaybackSpeed 
              changePlaybackRate={this.props.changePlaybackRate}
              playbackRate={this.props.playbackRate}
            />            

            <div className='recordingPlaybackModal-media-playbackControls-buttonRow-farEdgeSpacer' />
          </div>    
        
        <RecordingPlaybackModalMediaPlaybackControlsHighlights
          highlights={this.props.highlights}
          duration={duration}
          skipToHighlight={this.props.skipToHighlight}
        />

        <ReactCursorPosition className='recordingPlaybackModal-media-playbackControls-seekBarContainer'>
          <RecordingPlaybackModalMediaPlaybackControlsSeekBar 
            mediaType={this.props.mediaType}
            played={played}
            handleSeekChange={handleSeekChange}
            handleSeekMouseDown={handleSeekMouseDown}
            handleSeekMouseUp={handleSeekMouseUp}
            duration={duration}
            muxPlaybackID={muxPlaybackID}
            speakerTimelinesContainerHeight={this.props.speakerTimelinesContainerHeight}
          />
        </ReactCursorPosition>

        <div style={{height: `${this.props.speakerTimelinesContainerHeight}px`}} className='recordingPlaybackModal-media-playbackControls-speakerTimelinesContainer'>
          <RecordingPlaybackModalMediaPlaybackControlsSpeakerTimelines
            paragraphs={this.props.paragraphs}
            skipToSpeakerChunk={this.props.skipToSpeakerChunk}
            duration={duration}
            speakers={this.props.speakers} 
            heightSpeakerTimeline={this.props.speakerTimelineHeight}           
            numberOfSpeakers={this.props.numberOfSpeakers} // only for prototyping
          />
        </div>

				<div className='recordingPlaybackModal-media-playbackControls-bottom'>
          
        </div>		
			</div>
		)
	}
}

export default RecordingPlaybackModalMediaPlaybackControls

 {/*} 
            <div className='playgroundtestvideoButtons'>
              <button>
                prev speaker
              </button>
              
              <button onClick={()=>{this.handleSkip('backward', 15000)}}>
                back 15 sec
              </button>              
              
              <button onClick={this.handlePlayPause}>
                {playing ? 'pause' : 'play'}
              </button>
              
              <button onClick={()=>{this.handleSkip('forward', 15000)}}>
                forward 15 sec
              </button>
              
              <button>
                next speaker
              </button>
            </div>
            */}
            {/*}
            <div className='playgroundtestvideoButtonsSeekBar'>
              <div className='doc-video-inlineUI-controlBar-seekBarContainer'>
                <ReactSlider
                  className="doc-video-inlineUI-controlBar-seekBar"
                  thumbClassName="doc-video-inlineUI-controlBar-seekBar-thumb"
                  trackClassName="doc-video-inlineUI-controlBar-seekBar-track"                  
                  renderThumb={(props, state) => <div className='doc-video-inlineUI-controlBar-seekBar-thumb-thumb' {...props} />}

                  value={played}
                  min={0}
                  max={0.999999}
                  step={0.0001}
                  onAfterChange={this.handleSeekMouseUp}
                  onBeforeChange={this.handleSeekMouseDown}
                  onChange={this.handleSeekChange}
                />              
              </div>
            </div>
            </div>  
            */}
