const ToggleListItemNodeSpec = {
	attrs: {
    open:{default:false}
	},
	group: "block",
	defining:true,
   // content: "block*",
	content: "toggleListItemTitle toggleListItemDetails",
	isolating: true,
 	toDOM(node) {
    return ['toggleListItem',0]},
  parseDOM: [{tag: 'toggleListItem'}],

}
export default ToggleListItemNodeSpec


