import schema from '../schema/editorSchema'  
import {randomID} from '../../utils/randomID'
import {uploadDocImage,uploadDocImageToCloudinary} from '../../actions/docImage'
import store from '../../store'

import {TextSelection} from "prosemirror-state"


//updload to folder doc_images



export function insertImage(file,docId) {
	const state=window.view.state
	let type = schema.nodes.docImage
	const nodeId=randomID() //also used as public id
	//const deliveryUrl=`http://res.cloudinary.com/yarn/image/upload/v1673693315/doc_images/${nodeId}.png`
	const attrs={
		deliveryUrl:null,
		nodeId:nodeId,
		height:null,
		width:null,
		title:'',
		description:''
	}
	const node=type.create(attrs)
	let insertPos=state.selection.from
	let tr=state.tr
	tr.replaceWith(state.selection.from,state.selection.to,node)
	let selection=new TextSelection(tr.doc.resolve(state.selection.from+node.nodeSize+1))
	tr.setSelection(selection)
	window.view.dispatch(tr)
	store.dispatch(uploadDocImageToCloudinary(file,nodeId,docId))
}



// return this.props.uploadImageToCloudinary(file).then((response)=>{
// 			const src=response.url
// 			const height=response.height
// 			const width=response.width
// 			const title=''
// 			const description=''
// 			if(isThumbnail){
// 				insertThumbnail(src,height,width,title,description)
// 			}else{
// 				insertImage(src,height,width,title,description)
// 			}
// 		})




// 		export function insertImage(src,height,width,title,description) {
// 	const state=window.view.state
// 	let type = schema.nodes.docImage
// 	const attrs={
// 		deliveryUrl:src||deliveryUrl,
// 		nodeId:randomID(),
// 		height:height,
// 		width:width,
// 		title:title,
// 		description:description
// 	}

// 	const node=type.create(attrs)
// 	let insertPos=state.selection.from
// 	let tr=state.tr
// 	tr.replaceWith(state.selection.from,state.selection.to,node)
// 	window.view.dispatch(tr)
// }
