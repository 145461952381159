export function updateFigmaEmbedCropValues(embedId,crop) {
	if(window.view && window.view.state){
		let tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.embedId==embedId){
				tr.setNodeMarkup(pos, null, {...node.attrs,crop:crop})
			}
		})
		tr.setMeta('addToHistory', false)  
		window.view.dispatch(tr)
	}
}