import React from 'react'
import ToDoItemAltCheckbox from './ToDoItemAltCheckbox'

class ToDoItemAlt extends React.Component{  	

	constructor(){
    super()    
    this.state={
      isChecked: false,      
    }
  }


	render(){	

		const {label} = this.props
		const {isChecked} = this.state	

	  return (
	  		  		
  			<div className='toDoItemAlt'>
  				<div className='toDoItemAlt-checkboxOuterContainer'>		  				
	  				<ToDoItemAltCheckbox 
	  				 isChecked={isChecked}
	  				 toggleIsChecked={()=>(this.setState({isChecked: !isChecked}))}
	  				/>	  				            
  				</div>
  				<div className='toDoItemAlt-contents'>
  					{label}
  				</div>
  			</div>	  		
			
	  )
	}
}

export default ToDoItemAlt