import React from 'react'
import Icon from '../../../misc/Icon'
import { format,parseISO } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import * as Select from '@radix-ui/react-select';
import * as Popover from '@radix-ui/react-popover';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import moment from 'moment'

class DocEditorMeetingInfoEditDatePopoverContents extends React.Component{  

	constructor(props){
    super(props)      
    console.log(props.meeting.meeting_date)  
    this.state={
    	selected:new Date(props.meeting.meeting_date)    
    }
    this.handleSelectDate=this.handleSelectDate.bind(this)
  }

  handleSelectDate(date){
  	this.setState({selected:date})
  	this.props.selectNewMeetingDate(date)
  }
  
	render(){	
		const toMonth = moment().add(1, 'months').toDate()

	  return (
  		<div className='editMeetingDateTimePopover-calendarContainer'>
	  	 	<DayPicker
	      	mode="single"
	      	selected={this.state.selected}
	      	onSelect={this.handleSelectDate}
	      	showOutsideDays
	      	fixedWeeks
	      	//disabled={day => moment(day).isAfter(moment().add(30, 'days'))} // don't allow create far away because don't show in meeting page
	      	//toMonth={toMonth}
	    	/>
	    </div>		    
	  )
	}
}

export default DocEditorMeetingInfoEditDatePopoverContents
