import React from 'react'

//const imgUrl="https://res.cloudinary.com/yarn/image/upload/v1663977435/collabdrawing2_acqr5o.png"
const imgUrl="https://res.cloudinary.com/yarn/image/upload/v1664232376/Screen_Shot_2022-09-26_at_11.44.54_PM_acb9st.png"


class MessageMediaDrawing extends React.Component{  

	render(){	
			
		return (
			<div className='message-content-drawingContainer'>
				
		  	<img className='message-content-drawing' src={imgUrl}/>	
        
			</div>
		)
	}
}
 


export default MessageMediaDrawing
