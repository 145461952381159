import React from 'react'
import Icon from '../../../misc/Icon'
import {getMessageForHighlight} from '../../../../utils/meetings/getMessageForHighlight'
import DocEditorMeetingContentHighlightGridHighlightVideoPlayer from './DocEditorMeetingContentHighlightGridHighlightVideoPlayer'
import {getUserForId} from '../../../../utils/getUserForId'
import {formatDate} from '../../../../utils/formatDate'
import {autoplayHighlight} from '../../../../utils/meetings/autoplayHighlight'
import VideoDuration from '../../../../utils/videoDuration'

class DocEditorMeetingContentHighlightGridHighlight extends React.Component{  


  constructor(props) {
    super(props)
    this.handleClickOnHighlight=this.handleClickOnHighlight.bind(this)
  } 


  handleClickOnHighlight(){
    autoplayHighlight()
    const message = getMessageForHighlight(this.props.highlight.highlight_id)
    this.props.openThread(message.message_id)
  }

  render(){ 
    const {highlight, highlightMessages, creator} = this.props
    let creatorUserObj
    let creatorDisplayName    
    if(creator){
      creatorUserObj = getUserForId(creator)
      if(creatorUserObj){
        creatorDisplayName = creatorUserObj.display_name
      }
    }

    const isAudio = highlight.is_audio

    const createdAt = highlight.created_at
    const timestampLabel = formatDate(createdAt)

    const mainRecordingPlaytbackID = this.props.mainRecording.playback_id 
    const processingHighlightStartTime = highlight.start_time
    const processingBackgroundImage = `https://image.mux.com/${mainRecordingPlaytbackID}/thumbnail.webp?width=256&fit_mode=preserve&&time=${Math.round(processingHighlightStartTime)}`

    const isProcessingHighlight = highlight.status!=='ready'
    return (        
              
      <div className='docEditor-meetingContent-highlights-grid-highlight' onClick={this.handleClickOnHighlight}>
        {!isProcessingHighlight && !isAudio &&
          <div className='docEditor-meetingContent-highlights-grid-highlight-videoContainer'>
            <DocEditorMeetingContentHighlightGridHighlightVideoPlayer 
              muxPlaybackID={this.props.muxPlaybackID}
            />
          </div>        
        }

        {!isProcessingHighlight && isAudio && highlight &&
          <div className='docEditor-meetingContent-highlights-grid-highlight-videoContainer docEditor-meetingContent-highlights-grid-highlight-videoContainer--audio'>
            <Icon name='waveform' />

            <div className='docEditor-meetingContent-highlights-grid-highlight-videoContainer-video-duration'>
              <VideoDuration seconds={highlight.duration} />
            </div>

          </div>                    
        }

        {isProcessingHighlight && 
          <div className={'docEditor-meetingContent-highlights-grid-highlight-processingPreview ' + (isAudio ? ' docEditor-meetingContent-highlights-grid-highlight-processingPreview--audio ': ' ' )}>
            <div className='docEditor-meetingContent-highlights-grid-highlight-processingPreview-spinnerOuterContainer'>  
              <div className='docEditor-meetingContent-highlights-grid-highlight-processingPreview-spinnerContainer'>
                <div className='docEditor-meetingContent-highlights-grid-highlight-processingPreview-spinner' />
              </div>
            </div>
            {!isAudio && 
              <img src={processingBackgroundImage} className='docEditor-meetingContent-highlights-grid-highlight-processingPreview-image' />            
            }
          </div>
        }

        <div className='docEditor-meetingContent-highlights-grid-highlight-contentContainer'>
          <div className='docEditor-meetingContent-highlights-grid-highlight-description'>
            {this.props.description}
          </div>          
          <div className='docEditor-meetingContent-highlights-grid-highlight-footer'>
            {creatorDisplayName} · {timestampLabel}
          </div>                  
        </div>
      </div>

    )
  }
}

export default DocEditorMeetingContentHighlightGridHighlight