import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {authenticate,authenticateSession} from '../actions/auth'
import qs from 'qs'
import {Mixpanel} from '../Mixpanel'
import LoadingPage from '../components/LoadingPage'
import {fetchOnboardingStatus} from '../actions/onboarding'

class LinearAuthContainer extends Component {

	componentDidMount(){
		const query=qs.parse(this.props.location.search.slice(1))
		const code=query.code
		this.props.authenticate(code).then((response)=>{
			this.props.authenticateSession().then(()=>{
				//lets fetch onboarding status
				// console.log('fetch onboarding status here---------')
				// this.props.fetchOnboardingStatus().then((status)=>{
				// 	console.log('onboarding status is-------------')
				// 	console.log(status)
					this.props.history.push('/roadmaps')
			//	})

				//this.props.history.push('/roadmaps')
			})
		})
		.catch((error)=>{
			this.props.history.push('/login')
			Mixpanel.track('code_exchange_failed',{error:error.message})
		})

	}

	render() {
		return (
			 <LoadingPage />
		)
	}
}


const mapStateToProps = (state) => ({

});

export default withRouter(connect(
	mapStateToProps,
	{
		authenticate,
		authenticateSession,
		fetchOnboardingStatus
	})(LinearAuthContainer))

