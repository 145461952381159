export default function (state = {}, action) {
	switch (action.type) {
	
   
    case 'SIGN_OUT':
      return {}
   
    case 'FETCH_USER_SUCCESS':
      return action.response

    default:
      return state;
	}
}
