

const TranscriptSpeakerChunkNodeSpec ={
  group: "block",
  content: "block+",
  attrs: {
    start_time:{default:''},
    end_time:{default:''},
    transcript_speaker:{default:''},
    recording_id:{default:''},
    transcriptSpeakerVersion:{default:0},
    active:{default:false},
    speakerVersion:{default:0} //bump this when speaker assignment changes so can rerender
  },
  toDOM: () => ["div",{class:'recordingPlaybackModal-transcript-speakerChunk'}, 0]
}
    
   

  export default TranscriptSpeakerChunkNodeSpec


 