import {fetch,put,post} from '../api'
import {uploadFileToCloudinary} from './cloudinary'
import {recalcHeatmaps} from '../utils/calculateHeatmapForRoadmap'

export function fetchRoadmaps(){
	return (dispatch) => fetch(`/roadmaps`)
	.then((response) => {
		dispatch({ type: 'FETCH_ROADMAPS_SUCCESS', response })
		recalcHeatmaps()
		return response
	})
	.catch((error) => {
		return error
	})
}

export function syncRoadmaps(){
	return (dispatch) => put(`/roadmaps/sync`)
	.then((response) => {
		dispatch({ type: 'FETCH_ROADMAPS_SUCCESS', response })
		recalcHeatmaps()
		return response
	})
	.catch((error) => {
		return error
	})
}

export function handleRoadmapUpdatedPusher(roadmap){
	return (dispatch) => {
		dispatch({ type: 'UPDATE_ROADMAP_PUSHER', roadmap })

	}
}


export function updateRoadmapCoverImage(roadmapId,requestBody){
	return (dispatch) => put(`/roadmaps/${roadmapId}/image`,requestBody)
	.then((response) => {
		dispatch({ type: 'UPDATE_ROADMAP_SUCCESS', response,roadmapId })
		return response
	})
	.catch((error) => {
		return error
	})
}

export function deleteRoadmapCoverImage(roadmapId){
	return (dispatch) => {
		dispatch({ type: 'START_DELETE_ROADMAP_IMAGE', roadmapId})
		return put(`/roadmaps/${roadmapId}/remove-image`)
			.then((response) => {
				dispatch({ type: 'UPDATE_ROADMAP_SUCCESS', response,roadmapId })
				return response
			})
			.catch((error) => {
				return error
			})
	}
}




export function uploadRoadmapCoverImageToCloudinary(file,roadmapId) {
	return (dispatch) => {
		dispatch({ type: 'START_ROADMAP_IMAGE_UPLOAD', roadmapId})
		return fetch(`/roadmaps/cloudinary-upload`)
		.then((response) => {
			let form = new FormData()
			form.append('api_key', response.apiKey)
			form.append('timestamp', response.timestamp)
			form.append('signature',response.signature)
			form.append('file', file)
			form.append('folder', 'cover_images')
	// 		// form.append('public_id', roadmapId)		
			return dispatch(uploadFileToCloudinary(form))
				.then((uploadResponse) => {
					const requestBody={
						public_id:uploadResponse.public_id,
						width:uploadResponse.width,
						height:uploadResponse.height
					}

					dispatch(updateRoadmapCoverImage(roadmapId,requestBody))
					
			  })
			  .catch((err) => {

				})
	 		})

}
}
