import React from 'react'
import Icon from '../../misc/Icon'
import ReactSlider from 'react-slider'
import * as Tooltip from '@radix-ui/react-tooltip';

class DocFigmaEmbedEffectsToolbarPaddingSlider extends React.Component{  
  
  constructor(props){
    super()
    this.handleSliderChange=this.handleSliderChange.bind(this)
    this.handleSliderMouseDown=this.handleSliderMouseDown.bind(this)    
    this.handleSliderMouseUp=this.handleSliderMouseUp.bind(this)    
    this.state={    
      adjustingPadding: false   
    }
  }

  handleSliderMouseUp(e){   
  	this.setState({ adjustingPadding: false })
  	let paddingLevel = e  	  
  	if(paddingLevel > 0.08 && paddingLevel < 0.12){
  		paddingLevel = 0.1  		
  	}
    this.props.editBackgroundPadding(paddingLevel) 
  }  

  handleSliderMouseDown(e){
    this.setState({ adjustingPadding: true })   
  }

  handleSliderChange(e){    
    this.props.editBackgroundPadding(parseFloat(e) ) 
  }
	render(){ 
		const {backgroundPadding} = this.props
		const {adjustingPadding} = this.state
		return (				
      <React.Fragment>				
        <Tooltip.Provider>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger asChild>    
              <div className={'doc-figma-effectsToolbar-paddingSliderContainer ' + (adjustingPadding ? ' doc-figma-effectsToolbar-paddingSliderContainer--adjustingPadding ' : '')}>                             
                <div className='doc-figma-effectsToolbar-paddingSlider'>
                  <div className='doc-figma-effectsToolbar-paddingSlider-iconContainer'>
                    <Icon name='minimize' />
                  </div>
                  <div className='doc-figma-effectsToolbar-paddingSlider-slider'>
                    <ReactSlider
                      className="doc-figma-effectsToolbar-paddingSlider-slider-slider"
                      thumbClassName="doc-figma-effectsToolbar-paddingSlider-slider-slider-thumb"
                      trackClassName="doc-figma-effectsToolbar-paddingSlider-slider-slider-track"                  
                      renderThumb={(props, state) => <div className='doc-figma-effectsToolbar-paddingSlider-slider-slider-thumb-thumb' {...props} />}
                      value={backgroundPadding}
                      min={0.015}
                      defaultValue={0.1}
                      max={this.props.maxBackgroundPadding}
                      step={0.001}
                      onAfterChange={this.handleSliderMouseUp}              
                      onBeforeChange={this.handleSliderMouseDown}              
                      onChange={this.handleSliderChange}                    
                    />          
                  </div>
                </div>        
              </div>      
            </Tooltip.Trigger>        
            <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
              Background Padding
            </Tooltip.Content>                          
          </Tooltip.Root>
        </Tooltip.Provider>
      </React.Fragment>
						
		)
	}
}


export default DocFigmaEmbedEffectsToolbarPaddingSlider
