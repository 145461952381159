import React from 'react'
import Icon from '../../../misc/Icon'
import DocEditorMeetingContentProjectListRow from './DocEditorMeetingContentProjectListRow'
import {getProjectForId} from '../../../../utils/getProjectForId'
import {getProjectConnectionsForMeeting} from '../../../../utils/meetings/getProjectConnectionsForMeeting'

class DocEditorMeetingContentProjectList extends React.Component{  

  render(){ 
    const {meeting} = this.props
    let projectConnections=getProjectConnectionsForMeeting(meeting.meeting_id)
    let meetingType    
    if(meeting && meeting.type){
      meetingType = meeting.type
    }
    return (        
      <div className='docEditor-meetingContent-projectList'>
        {meetingType === 'teamSync' && 
          <div className='docEditor-meetingContent-projectList-headerRow'>            
            <div className='docEditor-meetingContent-projectList-headerRow-title'>
              Active Projects
            </div>          
          </div>
        }
        {projectConnections && projectConnections.map((connection) => {                
          let projectObj 
          const projectId=connection.project
          if(projectId){
            projectObj = getProjectForId(projectId)
          }
          if(projectObj){
          return (
            <DocEditorMeetingContentProjectListRow 
              key={projectId}
              project={projectObj}
              connectionId={connection.connection_id}
              openIssuePanel={this.props.openIssuePanel}
          />              
          )
        }
      })}
      </div>
    )
  }
}

export default DocEditorMeetingContentProjectList

 {/*}
      <DocEditorMeetingProjectListAddProject 
        meeting={meeting}
        hasProjects={true} // affects placeholder
      />
      */}