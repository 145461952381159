import React, { Component } from 'react'
import Icon from '../../components/misc/Icon'
import * as ContextMenu from '@radix-ui/react-context-menu';


class SideMenuAppTab extends React.Component{  

	render(){	
		const {privateOpenTab, openTabBoardEmoji, sectionIconName, label, notificationCount,navigateTo, showSearchBtn} = this.props				
		const {commentNotificationCount, discussionNotificationCount, docThreadNotificationCount} = this.props
		const showDevNotificationCounters = false
		const totalNotificationCount = commentNotificationCount + discussionNotificationCount + docThreadNotificationCount
		const hasNotifications = totalNotificationCount > 0
		let notificationCountLabel = totalNotificationCount
		if(totalNotificationCount > 9){
			notificationCountLabel = '9+'
		}
		let active = this.props.tabUrl && this.props.pathname==this.props.tabUrl
	  return (
			<div onClick={()=>{this.props.navigateTo(this.props.tabUrl)}} className={'sideMenu-tab sideMenu-tab--tabType--appTab ' + (sectionIconName ? ` sideMenu-tab--appTab--${sectionIconName} ` : '') + (active ? ' sideMenu-tab--active ' : ' sideMenu-tab--inactive ') + (showSearchBtn ? ' sideMenu-tab--showSearchBtn ' : '') + (this.props.showNewDocBtn ? ' sideMenu-tab--showNewDocBtn ' : '')}>
				<div className='sideMenu-tab-iconContainer'>		
					<div className='sideMenu-tab-iconInnerContainer'>
						<Icon name={`${this.props.sectionIconName}`} />		
						<div className='sideMenu-tab-iconInnerContainer-bg' />				
					</div>		
				</div>
				<div className='sideMenu-tab-labelContainer'>
					<div className='sideMenu-tab-label'>
						{label}						
					</div>
				</div>
			</div>					
	  )
	}
}





export default SideMenuAppTab
		{/*}
					<ContextMenu.Root> 
	    			<ContextMenu.Trigger asChild>	
	    				<div className='sideMenu-tab-contextMenuAnchor' />
						</ContextMenu.Trigger>

				 		<ContextMenu.Portal>
					 		<ContextMenu.Content collisionPadding={10} className='contextMenu contextMenu--tab contextMenu--tab--app'>
					 							
					 			{sortedUserProjects.map((project,i)=>{																											
										return(																
											<ContextMenu.Item key={i}>        	
								    		{project.name}
								  		</ContextMenu.Item>
										)
									})}
					 			}

				       	
					 			
					 		</ContextMenu.Content>	 		
				 		</ContextMenu.Portal>
				 	</ContextMenu.Root>		
				 	*/}
			


{/*

				{hasNotifications && showDevNotificationCounters &&
					<div className='sideMenu-tab-devNotificationContainer'>
						{docThreadNotificationCount > 0 &&
							<div className='sideMenu-tab-devNotification'>
								W{docThreadNotificationCount}
							</div>
						}	
						{discussionNotificationCount > 0 &&
							<div className='sideMenu-tab-devNotification'>
								D{discussionNotificationCount}
							</div>
						}
						{commentNotificationCount > 0 &&
							<div className='sideMenu-tab-devNotification'>
								C{commentNotificationCount}
							</div>
						}
					</div>
				}

				{hasNotifications && !showDevNotificationCounters &&
					<div className='sideMenu-tab-notificationContainer'>
						<div className='sideMenu-tab-notification'>
							{notificationCountLabel}
						</div>
					</div>
				}
*/}