import store from '../../../store'
import find from 'lodash/find'


export function getCurrentUserAsAssigneeObj() {	
	return null
	// const state = store.getState()
	// const currentUser=state.user
	// let assignee=null
	// if(currentUser){
	// 	assignee={}
	// 	assignee['user_id']=currentUser['user_id']
	// 	assignee.name=currentUser.name
	// 	assignee.color=currentUser.color
	// 	assignee['slack_avatar']=currentUser['slack_avatar']
	// }
	// return assignee
}
