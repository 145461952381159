import React from 'react'
import Icon from '../misc/Icon'
import {changeDocSearchActiveIndex,closeDocSearch} from '../../utils/docSearch'


class DocEditorInDocSearch extends React.Component{  


	constructor(){
		super()
		this.handleChange = this.handleChange.bind(this)
		this.handleInputKeyDown = this.handleInputKeyDown.bind(this)
    this.changeDocSearchActiveIndex=this.changeDocSearchActiveIndex.bind(this)
		this.state={
			searchTerm: '', 
		}			
	}

  componentDidUpdate(prevProps){

    if(prevProps.docSearchResults!==this.props.docSearchResults){
    //console.log('did update!!!!')
    //find active one and scroll into view
    let resultSpan=document.getElementById(`search-result-active`)  
    //console.log(resultSpan)
    if(resultSpan){
      const activeSpanRect=resultSpan.getBoundingClientRect()
      const activeTopXPos = activeSpanRect.top // relative to the viewport
      const activeBottomXPos = activeSpanRect.bottom // relative to the viewport
      const scrollDiv=document.getElementById('scrollable_div') // find scroll position of thing          
      if(scrollDiv){            
        const pageScroll = scrollDiv.scrollTop  
        const windowHeight = window.innerHeight // find height of window            
        const topPageHeaderBuffer = 50 // buffer for the in doc top header
        const generalBuffer = 20 // nice to have a general buffer as well, so can see comment in context          
        const activeBelow = activeBottomXPos + generalBuffer > windowHeight // comment is below window
        const activeAbove = activeTopXPos < (topPageHeaderBuffer + generalBuffer) // comment is above window            
        const activeAboveDeltaX = activeTopXPos - generalBuffer - topPageHeaderBuffer
        const activeBelowDeltaX = (activeBottomXPos + generalBuffer) - windowHeight
        if(activeAbove){             
        scrollDiv.scrollBy(0, activeAboveDeltaX); // scrollBy is relative
        }
        if(activeBelow){
          scrollDiv.scrollBy(0, activeBelowDeltaX); // scrollBy is relative
        }
      }
    }
  }
  }


	handleInputKeyDown(e){
		if(e.keyCode==27){ // close on escape
			e.preventDefault()
			// close on escape
		}	
    if(!e.shiftKey && e.keyCode==13){
      e.preventDefault()
      this.changeDocSearchActiveIndex('down')
      // close on escape
    } 		
     if(e.shiftKey && e.keyCode==13){
      e.preventDefault()
      this.changeDocSearchActiveIndex('up')
      // close on escape
    }     
	}

  handleChange(event) {    
    this.setState({searchTerm: event.target.value})   
    let view=window.view
    if(view){
      let tr = view.state.tr
      tr.setMeta("inDocSearchPlugin", { activate: true,searchTerm:event.target.value,activeIndex:null})
      view.dispatch(tr)
    }
  }

  changeDocSearchActiveIndex(direction){
   
    const {docSearchResults}=this.props
    const currentIndex=docSearchResults.activeIndex
    const results=docSearchResults.results
    const searchTerm=this.state.searchTerm
    let newIndex
    if(direction=='up'){
      if(currentIndex==0){
        
        newIndex=results.length-1
      }else{
        newIndex=currentIndex-1
      }
    }
    else if(direction=='down'){
      if(currentIndex==results.length-1){
        newIndex=0
      }else{
        newIndex=currentIndex+1
      }
    }
 
    changeDocSearchActiveIndex(searchTerm,newIndex)
  }

  render(){ 
    const {docSearchResults}=this.props
    // const {}=this.props
    const {searchTerm} = this.state

  	const hasSearchTerm = searchTerm.length > 0

  	const searchResultsCount = docSearchResults.results.length
  	const currentActiveResult = docSearchResults.activeIndex+1

    let searchInputResultsLabel = `${currentActiveResult} of ${searchResultsCount}`

    if(searchResultsCount === 0){
    	searchInputResultsLabel = 'No results'
    }
       
    return (      
      <div className='docEditor-inDocSearch'>

      	<div className='docEditor-inDocSearch-inputContainer'>

      		<input 			
            id='in-doc-search-input'			
						className='docEditor-inDocSearch-input'
						placeholder='Find in doc'
						value={this.state.searchTerm}
						onKeyDown={this.handleInputKeyDown}
						onChange={this.handleChange} 				
						autoComplete="off"
						autoCorrect="off"						
						spellCheck={false}		
            autoFocus				
					/>

      		<div className='docEditor-inDocSearch-inputUI'>
      			{hasSearchTerm && 
	      			<div className={'docEditor-inDocSearch-inputUI-resultsLabel ' + (searchResultsCount === 0 ? ' docEditor-inDocSearch-inputUI-resultsLabel--noResults ' : '')}>
	      				{searchInputResultsLabel}
	      			</div>
      			}
      		</div>

      	</div>

      	<button disabled={!hasSearchTerm || searchResultsCount === 0} className={'docEditor-inDocSearch-navigateBtn docEditor-inDocSearch-navigateBtn--up ' + (!hasSearchTerm ? ' docEditor-inDocSearch-navigateBtn--hide' : '')} onClick={()=>{this.changeDocSearchActiveIndex('up')}}>
      		<Icon name='chevronUpMedium'/>
      	</button>

      	<button disabled={!hasSearchTerm || searchResultsCount === 0} className={'docEditor-inDocSearch-navigateBtn docEditor-inDocSearch-navigateBtn--down' + (!hasSearchTerm ? ' docEditor-inDocSearch-navigateBtn--hide' : '')} onClick={()=>{this.changeDocSearchActiveIndex('down')}}>
      		<Icon name='chevronDownMedium'/>
      	</button>      	


      	<button className='docEditor-inDocSearch-doneBtn' onClick={closeDocSearch}>
      		Done
      	</button>
				
      </div>   
    )
  }
}

export default DocEditorInDocSearch
