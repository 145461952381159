import React from 'react'
import Icon from '../../../misc/Icon'
import MessageMediaHighlightVideoPlaybackControls from './MessageMediaHighlightVideoPlaybackControls'
import {toggleMessageMediaZoomInRedux} from '../../../../utils/toggleMessageMediaZoomInRedux'
import ReactPlayer from 'react-player/file'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import MessageMediaHighlightInlineTranscriptPopover from './MessageMediaHighlightInlineTranscriptPopover'
import MessageMediaHighlightTranscript from './MessageMediaHighlightTranscript'
import {getHighlightForId} from '../../../../utils/meetings/getHighlightForId'
import { connect } from 'react-redux'
import {clearAutoplayHighlight} from '../../../../utils/meetings/autoplayHighlight'

const MOUSE_MOVE_SHORT_DEBOUNCE_TIME = 1800
const MOUSE_MOVE_LONG_DEBOUNCE_TIME = 4000


class MessageMediaHighlightVideo extends React.PureComponent{  

	constructor(props){
    super(props)        

    this.handlePlayPause=this.handlePlayPause.bind(this)    
    this.handlePlay=this.handlePlay.bind(this)
    this.handlePause=this.handlePause.bind(this)    
    this.handleZoomAndPlay=this.handleZoomAndPlay.bind(this)
    this.handleSeekChange=this.handleSeekChange.bind(this)
    this.handleSeekMouseUp=this.handleSeekMouseUp.bind(this)
    this.handleDoubleClick=this.handleDoubleClick.bind(this)    
    this.handleSeekMouseDown=this.handleSeekMouseDown.bind(this)
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.hideMouseMoveShort=this.hideMouseMoveShort.bind(this)    
    this.hideMouseMoveLong=this.hideMouseMoveLong.bind(this)    
    this.toggleZoom=this.toggleZoom.bind(this)    
   	this.handlePlayerReady=this.handlePlayerReady.bind(this)  
   	this.toggleTranscript=this.toggleTranscript.bind(this)
   	this.handleTranscriptAndPlay=this.handleTranscriptAndPlay.bind(this)
   	this.handleTranscriptAndZoomAndPlay=this.handleTranscriptAndZoomAndPlay.bind(this)

    this.hideMouseMoveShort=debounce(this.hideMouseMoveShort,MOUSE_MOVE_SHORT_DEBOUNCE_TIME)
    this.hideMouseMoveLong=debounce(this.hideMouseMoveLong,MOUSE_MOVE_LONG_DEBOUNCE_TIME)

    let playing=false
    if(this.props.autoplayHighlight){
    	playing=true
    }

    this.state={      
      zoomed: false,  
      transcriptActive: false,
      url: null,	    
	    playing: playing,	    
	    played: 0,
	    loaded: 0,
	    duration: 0,	          
      mouseMovedShort:false,
      mouseMovedLong:false,
      captionsActive: false,           
      volume: 1,
      adjustingVolume: false,
      muted:false,
      playerLoaded:false,
      hasPlayed:false,

    }    
  }  

  componentDidMount(){  
  	clearAutoplayHighlight()	  
  	if(!this.state.playerLoaded){ //otherwise calls multiple times e.g. after seek
  		const progress=0.5
  	}
  }

  handlePlayerReady(){
  	//this.player.seekTo(0.5)
  }

   handlePlayPause(){
    this.setState({playing:!this.state.playing})
  }

  handlePlay(){
    this.setState({playing:true,hasPlayed:true})
  }

  handlePause(){
    this.setState({playing:false})
  }

  handleSeekMouseDown(){
    this.setState({seeking:true,muted:true})
  }

  

  handleZoomAndPlay(){
  	toggleMessageMediaZoomInRedux(true)
    this.setState({zoomed:true,playing:true})
  }

  handleTranscriptAndPlay(){  	
    this.setState({transcriptActive:true,playing:true})
  }

  handleTranscriptAndZoomAndPlay(){  	
    this.setState({transcriptActive:true,playing:true,zoomed:true})
    toggleMessageMediaZoomInRedux(true)
  }
 
  handleSeekChange(e){
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
  	this.setState({playedSeconds:playedSeconds,played:e})
  }

  handleSeekMouseUp(e){
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
  	this.setState({playedSeconds:playedSeconds,played:e,muted:false})
  }

  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
     // this.saveVideoProgress(state.playedSeconds)
    }
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
    this.player.seekTo(0)
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  ref = player => {
    this.player = player
  }

  handleMouseMove(){    
    this.setState({mouseMovedShort:true})
    this.setState({mouseMovedLong:true})
    this.hideMouseMoveShort()
    this.hideMouseMoveLong()
  }

  hideMouseMoveShort(){
    this.setState({mouseMovedShort:false})
  }

  hideMouseMoveLong(){
    this.setState({mouseMovedLong:false})
  }

  toggleTranscript(){
    this.setState({transcriptActive:!this.state.transcriptActive})
  }

  toggleZoom(e){
  	if(!this.state.zoomed){
  		toggleMessageMediaZoomInRedux(true)
  		this.setState({ zoomed: true })
  	}else{
  		toggleMessageMediaZoomInRedux(false)
  		this.setState({ zoomed: false})
  	}
  } 


  handleDoubleClick(e){
  	e.stopPropagation()
  }  
  

	render(){	
		const {muxPlaybackID, inlineAvailableWidth,highlightId}=this.props		
		const {transcriptActive, url, playing, played, loaded, duration, zoomed, mouseMovedShort, mouseMovedLong, hasPlayed} = this.state
		
		const highlightObj=getHighlightForId(highlightId)

		let isAudio = false 
		if(highlightObj){
			isAudio=highlightObj.is_audio
		}

		// console.log('////')
		// console.log(highlightObj)
		// console.log('////')



		let videoAspect=2048/1152 
		if(highlightObj && highlightObj.aspect_ratio){ //turn it from "16:9" into a number
			let aspectRatioArray = highlightObj.aspect_ratio.split(":");
			videoAspect = parseInt(aspectRatioArray[0]) / parseInt(aspectRatioArray[1]);
		}
		if(isAudio){
			videoAspect = 16/8
		}



		const progressSeconds = duration * played
		let deliveryUrl =  `https://stream.mux.com/${muxPlaybackID}/high.mp4`
		if(isAudio){
			deliveryUrl =  `https://stream.mux.com/${muxPlaybackID}.m3u8`
		}
		
		//const videoAspect = 2048/1152 // real aspect ratio here


		// These are styling
		let videoWidth
		let videoHeight

		let videoContainerWidth
		let videoContainerHeight
		let videoContainerTop
		let videoContainerLeft

		let maxVideoHeight = 600		

		let videoContainerStyle = {}

		//
		// Inline Video Size

		// Fit
		// we don't do anything fancy for fit – we can solve extremely portait videos in the future
		const inlineVideoDocHeight = Math.min((inlineAvailableWidth / videoAspect), maxVideoHeight)
		const inlineVideoDocWidth = inlineVideoDocHeight * videoAspect

		if(!zoomed){			
			videoWidth = inlineVideoDocWidth
			videoHeight = inlineVideoDocHeight			

			// don't need to set video container width
			videoContainerWidth = videoWidth
			videoContainerHeight = videoHeight
			videoContainerTop = ''
			videoContainerLeft = ''

		
			videoContainerStyle = {				
				width: `${videoContainerWidth}px`,
				height: `${videoContainerHeight}px`,
				marginLeft: `${(inlineAvailableWidth - inlineVideoDocWidth)/2}px`
			}
		}

		




		//
		// Zoomed Video Size

		// this is calculated screen size
		const windowWidth = window.innerWidth
		const windowHeight = window.innerHeight
	
		// Hardcoded Zoom Layout Values
		// this is the distance from the window to the modal edge
		const windowInnerLeftRightMargin = 20
		const windowInnerTopMargin = 30
		const windowInnerBottomMargin = 20

		const zoomedVideoModalTopPadding = 0 // from the edge to the video
		const zoomedVideoModalBottomPadding = 0 // from the edge to the video

		const widthTranscriptContainer = 380 // todo-make this fancier

		const zoomedVideoModalLeftPadding = widthTranscriptContainer // from the edge to the video

		const maxVideoWidth = 1200



		const maxAvailableZoomedVideoWidth = Math.min(maxVideoWidth, (windowWidth - (windowInnerLeftRightMargin * 2) - zoomedVideoModalLeftPadding))
		const maxAvailableZoomedVideoHeight = windowHeight - (windowInnerTopMargin + windowInnerBottomMargin) - zoomedVideoModalTopPadding - zoomedVideoModalBottomPadding
		const maxAvailableZoomedVideoAspect = maxAvailableZoomedVideoWidth / maxAvailableZoomedVideoHeight



		let zoomedVideoFitWidth = maxAvailableZoomedVideoWidth
		let zoomedVideoFitHeight = maxAvailableZoomedVideoWidth / videoAspect

		if(maxAvailableZoomedVideoAspect > videoAspect){
			zoomedVideoFitHeight = maxAvailableZoomedVideoHeight
			zoomedVideoFitWidth = maxAvailableZoomedVideoHeight * videoAspect
		}

		if(zoomed){
			//videoWidth = Math.min(zoomedVideoFitWidth, originalVideoWidth) // don't make bigger than original dimensions			
			videoWidth = zoomedVideoFitWidth
			videoHeight = videoWidth / videoAspect

			videoContainerWidth = videoWidth
			videoContainerHeight= videoHeight + zoomedVideoModalTopPadding + zoomedVideoModalBottomPadding
			videoContainerTop = Math.max(((windowHeight - videoContainerHeight) / 2), windowInnerTopMargin)
			videoContainerLeft = (windowWidth - videoContainerWidth) / 2
		}

		if(zoomed){
			videoContainerStyle = {
				top: `${videoContainerTop}px`,
				left: `${videoContainerLeft}px`,
				width: `${videoContainerWidth}px`,
				height: `${videoContainerHeight}px`
			}
		}
		
		let videoStyle = {
			width: `${videoWidth}px`,
			height: `${videoHeight}px`
		}

		if(zoomed && transcriptActive){
    	videoStyle.transform = `translateX(${widthTranscriptContainer * 0.5}px)`;
		}

		//
		// Transcript

		const zoomedTranscriptStyle = {
			//left: `${videoContainerLeft}px`,
			left: '0px',
			transform: `translateX(${widthTranscriptContainer * 0.5 * -1}px)`,
			height: `${videoHeight}px`,
			width: `${widthTranscriptContainer}px`
		}

		return (
			<React.Fragment>
				<div style={videoContainerStyle} className={'message-content-highlight-videoContainer ' + (zoomed ? ' message-content-highlight-videoContainer--audio ' : ' ') + (zoomed ? 'message-content-highlight-videoContainer--zoomed' : 'message-content-highlight-videoContainer--inline')} onMouseMove={this.handleMouseMove}>

					<div style={videoStyle} className={'message-content-highlight-video ' + (isAudio ? 'message-content-highlight-video--audio' : '')}>
						<MessageMediaHighlightVideoPlaybackControls							
							playing={playing}
							played={played}							
							hasPlayed={hasPlayed}
							progressSeconds={progressSeconds}
							duration={duration}		
							handlePlay={this.handlePlay}					

							zoomed={zoomed}
							handleZoomAndPlay={this.handleZoomAndPlay}
							toggleZoom={this.toggleZoom}

							mouseMovedShort={mouseMovedShort}
							mouseMovedLong={mouseMovedLong}
							
							handleSeekMouseUp={this.handleSeekMouseUp}
							handleSeekMouseDown={this.handleSeekMouseDown}
							handleSeekChange={this.handleSeekChange}
							handlePlayPause={this.handlePlayPause}

							transcriptActive={transcriptActive}						
							toggleTranscript={this.toggleTranscript}
							handleTranscriptAndPlay={this.handleTranscriptAndPlay}
							handleTranscriptAndZoomAndPlay={this.handleTranscriptAndZoomAndPlay}

							isAudio={isAudio}
						/>

						{isAudio && 
							<div className='message-content-highlight-video-audioIcon'>
								<Icon name='waveform' />
							</div>
						}

						<ReactPlayer   			  
	  			    ref={this.ref}
	            className='message-content-highlight-video-video'
	            width='100%'
	            height='100%'
	            url={deliveryUrl}
	            //progressInterval={50} //TODO maybe make this dynamic depending on length of video
	            progressInterval={5} // made shorter because highlights are shorter
	            //pip={pip}
	            playing={playing}
	            muted={this.state.muted}
	            volume={1}
	            onPlay={this.handlePlay}

	            onPause={this.handlePause}
	            onEnded={this.handleEnded}
	            onProgress={this.handleProgress}
	            onDuration={this.handleDuration}
	            onEnded={this.handleEnded}
	            onReady={this.handlePlayerReady}
	    			/>

	    			<MessageMediaHighlightInlineTranscriptPopover																
							showPopover={!zoomed && transcriptActive}
							openPopover={()=>{this.setState({transcriptActive: true})}}
							closePopover={()=>{this.setState({transcriptActive: false})}}
							toggleTranscript={this.toggleTranscript}
							immediateClose={zoomed}
						>			  																		
							<div className='message-content-highlight-video-transcriptPopoverTrigger' />
						</MessageMediaHighlightInlineTranscriptPopover>	    			

					</div>			
					
					{transcriptActive && zoomed &&
						<div style={zoomedTranscriptStyle} className='message-content-highlight-zoomedTranscriptContainer'>
							<div className='message-content-highlight-zoomedTranscriptInnerContainer forceDarkTheme'>
								<MessageMediaHighlightTranscript 
									toggleTranscript={this.toggleTranscript}
								/>
							</div>
						</div>					
					}
					

				</div>
				
				{zoomed && 
					<div onClick={this.toggleZoom} className='message-content-highlight-zoomedBG' />
				}

				{zoomed && 
					<div style={{width: `${inlineVideoDocWidth}px`, height: `${inlineVideoDocHeight}px`}} className='message-content-highlight-videoSpacer'>

					</div>
				}

			</React.Fragment>
		)
	}
}
 

function mapStateToProps(state,ownProps) {
	return {
		autoplayHighlight:state.autoplayHighlight
	}
}

export default connect(mapStateToProps,
	{	}
)(MessageMediaHighlightVideo)

// export default MessageMediaHighlightVideo
