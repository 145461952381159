import React from 'react'
import ReactDOM from 'react-dom';
import DocImageReactComponent from './DocImageReactComponent'
import {convertImageToThumbnail} from '../../../prosemirror/utils/convertImageToThumbnail'
import {fetchImageForNode} from '../../../prosemirror/utils/updateImageCloudinaryValues'

class DocImageNodeView {

	constructor(node, view, getPos,newNodeMessage,docId) {
		this.getNode=this.getNode.bind(this)
		this.deleteImage=this.deleteImage.bind(this)
		this.convertToThumbnail=this.convertToThumbnail.bind(this)
		this.createNewNodeMessage=this.createNewNodeMessage.bind(this)
		this.node = node
		this.getPos=getPos
		this.newNodeMessage=newNodeMessage

		this.dom = document.createElement("div")
		this.dom.className="doc-imageWrapper"
		this.docId=docId
	
	 
		const {
			deliveryUrl,
			nodeId,
			height,
			width,
			title
		} = node.attrs

		if(!deliveryUrl){
			fetchImageForNode(nodeId)
		}


		this.dom.id=nodeId

		ReactDOM.render(
			<DocImageReactComponent 
				nodeId={nodeId}
				deliveryUrl={deliveryUrl}
				deleteImage={this.deleteImage}
				newMessage={this.createNewNodeMessage}
				height={height}
				width={width}
				title={title}
				convertToThumbnail={this.convertToThumbnail}
				docId={docId}
			/>, this.dom)
		}

	createNewNodeMessage(e){
		e.stopPropagation()
		const {
			deliveryUrl,
			height,
			width,
			title,
		} = this.node.attrs
		const snapshot={
			deliveryUrl:deliveryUrl,
			height:height,
			title:title,
			width:width
		}
		this.newNodeMessage(this.node.attrs.nodeId,'image',snapshot)
	}

	convertToThumbnail(e){
		e.stopPropagation()
		const pos=this.getPos()
		convertImageToThumbnail(this.node,pos,pos+this.node.nodeSize)

	}

	selectNode() {
		this.dom.classList.add("doc-imageWrapper--selected")
	}

	deselectNode() {
		this.dom.classList.remove("doc-imageWrapper--selected")
	}

	getNode(){
		return this.node
	}

	deleteImage(e){
		e.stopPropagation()
		let tr = window.view.state.tr
		const imagePos=this.getPos()
		tr.delete(imagePos,imagePos+this.node.nodeSize)
		window.view.dispatch(tr)
	}


 update(node) {
	if (node.type !== this.node.type) return false
	 const {
			deliveryUrl,
			nodeId,
			height,
			width,
			title,
		} = node.attrs
		const oldDeliveryUrl=this.node.attrs.deliveryUrl
	 if(oldDeliveryUrl !==deliveryUrl){
		ReactDOM.render(
			<DocImageReactComponent 
				nodeId={nodeId}
				deliveryUrl={deliveryUrl}
				deleteImage={this.deleteImage}
				newMessage={this.createNewNodeMessage}
				height={height}
				width={width}
				title={title}
				convertToThumbnail={this.convertToThumbnail}
				docId={this.docId}
				/>, this.dom
			)
		}
				
		this.node=node
		return true
	}

	stopEvent(event){ //prevent typing in video node input from bubbling to editor so that e.g. backspace doesnt delete video
    return true
  }

	destroy() {
		ReactDOM.unmountComponentAtNode(this.dom)
	}
}

export default DocImageNodeView