const DocThumbnailNodeSpec ={
	attrs: {
		deliveryUrl: {default:''},
		nodeId:{default:''},
		height:{default:''},
		width:{default:''},
		title:{default:''},
	},
	inline: true,
	group: "inline",
	draggable: true,
	parseDOM: [{
		tag: "div",
		getAttrs: dom => {
			let classname = dom.getAttribute("class")
			return classname==='doc-imageThumbnail'
		}
	}],
	toDOM(node) {
		const div = document.createElement('div')
		div.className = 'doc-imageThumbnail'
		const img = document.createElement('img')
		img.src=node.attrs.deliveryUrl
		div.appendChild(img)
		return div
	}
}
export default DocThumbnailNodeSpec