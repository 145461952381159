import store from '../store'
import {updateDocTeam,updateDocProject,updateDocRoadmap,updateDocIsOrgDoc} from '../actions/docs'



export function moveDocToRoadmap(docId,roadmapId) {
	store.dispatch(updateDocRoadmap(docId,roadmapId))
}

export function moveDocToOrg(docId) {
	store.dispatch(updateDocIsOrgDoc(docId))
}


export function moveDocToTeam(docId,teamId) {
	store.dispatch(updateDocTeam(docId,teamId))
}

export function moveDocToProject(docId,projectId) {
	store.dispatch(updateDocProject(docId,projectId))
}

