import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import KeyboardShortcutTag from '../../misc/KeyboardShortcutTag'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as Tooltip from '@radix-ui/react-tooltip';
import moment from 'moment'
import {getProjectForId} from '../../../utils/getProjectForId'
import {getTabStarredStatus,starTab,unstarTab} from '../../../utils/starredTabs'
import {calcProjectStats} from '../../../utils/calcProjectStats'
import LinearProjectStatusTag from '../../misc/LinearProjectStatusTag'
import { CircularProgressbar } from 'react-circular-progressbar';
import {copyDocLinkToClipboard} from '../../../utils/copyDocLinkToClipboard'
import {getRoadmapsForProject} from '../../../utils/getRoadmapsForProject'
import {updateProjectTargetDate} from '../../../utils/updateProject/updateProjectTargetDate'
import EditMetadataPopoverWrapper from '../../popovers/EditMetadataPopoverWrapper'
import includes from 'lodash/includes'
import {updateProjectRoadmaps} from '../../../utils/updateProject/updateProjectRoadmaps'
import {updateProjectStatus} from '../../../utils/updateProject/updateProjectStatus'
import {openProjectInLinear} from '../../../utils/openInLinear'
import {createTemplate} from '../../../utils/templates/createTemplate'
import DocEditorCreateTemplatePopover from '../DocEditorCreateTemplatePopover'

function formatTargetDateLabel(targetDate){
	const year = moment().startOf('isoYear')
	let formatedTimestamp
	if (moment(targetDate).isSame(year, 'y')) {
		formatedTimestamp=moment(targetDate).format('DD MMM')
	}else{
		formatedTimestamp=moment(targetDate).format('MMM YY')
	}
	return formatedTimestamp
}

class DocEditorProjectRootDocTopMenu extends React.Component{  

	constructor(){
    super()   
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.updateProjectTargetDate=this.updateProjectTargetDate.bind(this)
    this.closeRoadmapsPopover=this.closeRoadmapsPopover.bind(this)
    this.showRoadmapsPopover=this.showRoadmapsPopover.bind(this)
    this.updateProjectRoadmaps=this.updateProjectRoadmaps.bind(this)
    this.handleUpdateProjectStatus=this.handleUpdateProjectStatus.bind(this)
    this.handleTemplatePopoverClick=this.handleTemplatePopoverClick.bind(this)
    this.state={
      showEditProjectStatusPopover: false,
      showEditProjectTargetDatePopover: false,
      showEditRoadmapsPopover: false,
      updatedProjectRoadmaps:[],
      showDropdown: false,
      showCreateTemplatePopover: false,
    }
  }

  componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)		
	}

	componentWillUnmount(){
		window.removeEventListener('keydown', this.handleKeyDown)		
	}

	handleTemplatePopoverClick(e){		
		this.setState({showCreateTemplatePopover: true})
		this.setState({showDropdown: false})		
	}

	showRoadmapsPopover(){
		const origionalRoadmaps=getRoadmapsForProject(this.props.projectId)
		let roadmapsArray=[]
		origionalRoadmaps.forEach((roadmap)=>{
			roadmapsArray.push(roadmap.roadmap_id)
		})
		this.setState({updatedProjectRoadmaps:roadmapsArray,showEditRoadmapsPopover: true})
	}

	closeRoadmapsPopover(e){
		if(this.state.showEditRoadmapsPopover){
		this.setState({showEditRoadmapsPopover: false})
		const {updatedProjectRoadmaps}=this.state
		//compare updated roadmaps to roadmaps
		let roadmapsToRemove=[]
		let roadmapsToAdd=[]
		const origionalRoadmaps=getRoadmapsForProject(this.props.projectId)
		
		origionalRoadmaps.forEach((roadmap)=>{
			if(!includes(updatedProjectRoadmaps,roadmap.roadmap_id)){

				roadmapsToRemove.push(roadmap.roadmap_id)
			}
		})

		updatedProjectRoadmaps.forEach((roadmapId)=>{
			let isNewRoadmap=true
			origionalRoadmaps.forEach((roadmap)=>{
				if(roadmap.roadmap_id==roadmapId){
					isNewRoadmap=false
				}
			})

			if(isNewRoadmap){
				roadmapsToAdd.push(roadmapId)
			}
		})

		if(roadmapsToRemove.length || roadmapsToAdd.length){
			let actions=[]
			roadmapsToRemove.forEach((roadmapId)=>{
				actions.push({roadmapId:roadmapId,action:'remove'})
			})
			roadmapsToAdd.forEach((roadmapId)=>{
				actions.push({roadmapId:roadmapId,action:'add'})
			})
			
			updateProjectRoadmaps(this.props.projectId,actions)
		}
		}
	}

	updateProjectRoadmaps(updatedRoadmaps){
		this.setState({updatedProjectRoadmaps:updatedRoadmaps})
	}

	updateProjectTargetDate(targetDate){
		this.setState({showEditProjectTargetDatePopover:false})
		updateProjectTargetDate(this.props.projectId,targetDate)
	}


	handleUpdateProjectStatus(status){
		this.setState({showEditProjectStatusPopover:false})
		updateProjectStatus(this.props.projectId,status)
  }

	handleKeyDown(e){ // Open menu on shift command p		
		if(e.metaKey && e.keyCode==76 && !e.shiftKey){ //command L
			e.preventDefault()
			e.stopPropagation()
			copyDocLinkToClipboard(this.props.docId)
		}
	}

	render(){	
		const {sideMenuVisible,docName,doc,docId,projectId} = this.props
		const {showEditProjectStatusPopover, showEditProjectTargetDatePopover, showEditRoadmapsPopover, showDropdown} = this.state	
		let project
		let projectSlug

		if(projectId){
			project = getProjectForId(projectId)
		}
		let projectStatus
		if(project){
			projectStatus=project.state
			projectSlug=project.slug_id
		}
		const starred = getTabStarredStatus('doc',doc.doc_id)
		let projectStatusLabel = projectStatus		
		if(projectStatus === 'started'){
			projectStatusLabel = 'in progress'
		}

		const projectStats=calcProjectStats(projectId)
		// new Linear data 
		const linearProjectProgressPercentage = Math.floor(projectStats.progressPercentage)// rounding down to nearest % --TODO test against linear
		const linearProjectScope = projectStats.scope

		// const targetDateLabel = 'Jan 27'
		// const targetDateWarning = false // same logic as in roadmaps. i don't really like it but people will.
		let targetDateLabel
		let targetDateWarning = false // if target date is before today, and not completed
		if(project && project.target_date){
			targetDateLabel=formatTargetDateLabel(project.target_date)
			if(project.state !=='completed'){
				const today = moment().startOf('day'); 
				if(moment(project.target_date).isBefore(today)){
					targetDateWarning=true
				}
			}
		}

		const showTargetDateButton = targetDateLabel || projectStatus === 'started'

		const issuePanel = this.props.showIssuePanel // if issue panel is showing
		const roadmaps=getRoadmapsForProject(projectId)



	  return (
	  	<div className={'docEditor-topMenuContainer docEditor-topMenuContainer--rootDoc ' + (projectStatus ? ` docEditor-topMenuContainer--status--${projectStatus} ` : '') + (this.props.hideMenu ? ' docEditor-topMenuContainer--hide ' : ' docEditor-topMenuContainer--show') + (sideMenuVisible ? ' docEditor-topMenuContainer--sideMenuState--visible ' : ' docEditor-topMenuContainer--sideMenuState--hidden ')}>
				<div className='docEditor-topMenu-center'>	
					<div className={'docEditor-topMenu-roadmapsContainer ' + (showEditRoadmapsPopover ? ' docEditor-topMenu-roadmapsContainer--popoverOpen ' : ' docEditor-topMenu-roadmapsContainer--popoverClosed ')}>						
						{roadmaps.map((roadmap)=>{
							return(
								<button key={`header_roadmap_${roadmap.roadmap_id}`} className='docEditor-topMenu--rootDoc-parentRoadmapLink' onClick={()=>{this.props.history.push(`/roadmaps/${roadmap.roadmap_id}`)}}>
									<div className='docEditor-topMenu--rootDoc-parentRoadmapLink-iconContainer'>
										<Icon name='roadmapOutline' />
										<Icon name='linkArrow' />
									</div>
									<div className='docEditor-topMenu--rootDoc-parentRoadmapLink-label'>
										{roadmap.name}
									</div>
								</button>
							)
						})}

						<EditMetadataPopoverWrapper
							showPopover={showEditRoadmapsPopover}												
							openPopover={this.showRoadmapsPopover}
							closePopover={this.closeRoadmapsPopover}	
							updateProjectRoadmaps={this.updateProjectRoadmaps}	
							type="projectRoadmaps"  // used to load in popover contents
							updatedProjectRoadmaps={this.state.updatedProjectRoadmaps}
							layout="projectRootDocTopMenu" // used for positioning
							portalled
							projectId={projectId}						
						>
							<div className={'docEditor-topMenu--rootDoc-editRoadmapBtnContainer ' + (roadmaps.length > 0 ? ' docEditor-topMenu--rootDoc-editRoadmapBtnContainer--edit ':' docEditor-topMenu--rootDoc-editRoadmapBtnContainer--add ')}>
								<Tooltip.Provider>
									<Tooltip.Root delayDuration={300}>
										<Tooltip.Trigger asChild>																																										
											<button onClick={this.showRoadmapsPopover} className='docEditor-topMenu--rootDoc-editRoadmapBtn'>
												{roadmaps.length > 0 && 																	
													<div className='docEditor-topMenu--rootDoc-editRoadmapBtn-label'>
														Edit
													</div>										
												}
												{roadmaps.length < 1 && 					
													<React.Fragment>
														<div className='docEditor-topMenu--rootDoc-editRoadmapBtn-iconContainer'>
															<Icon name='roadmapOutline' />
														</div>
														<div className='docEditor-topMenu--rootDoc-editRoadmapBtn-label'>
															Add to Roadmap
														</div>
													</React.Fragment>
												}
											</button>				
										</Tooltip.Trigger>							
										{roadmaps.length > 0 &&		 	
											<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
												Edit Roadmaps
											</Tooltip.Content>																							
										}
									</Tooltip.Root>
								</Tooltip.Provider>
							</div>	
						</EditMetadataPopoverWrapper>	
					</div>
				</div>

				<div className='docEditor-newTemplatePopoverTriggerContainer'>
					<DocEditorCreateTemplatePopover
						showPopover={this.state.showCreateTemplatePopover}						
						openPopover={()=>{this.setState({showCreateTemplatePopover: true})}}
						closePopover={()=>{this.setState({showCreateTemplatePopover: false})}}
						docId={docId}												
					>
						<div className='docEditor-newTemplatePopoverTrigger'/>							
					</DocEditorCreateTemplatePopover>							
				</div>	

				<div className='docEditor-topMenu-right'>				
					{project &&					
						<EditMetadataPopoverWrapper
							showPopover={showEditProjectStatusPopover}						
							openPopover={()=>{this.setState({showEditProjectStatusPopover: true})}}
							closePopover={()=>{this.setState({showEditProjectStatusPopover: false})}}		
							type="projectStatus"  // used to load in popover contents
							layout="projectRootDocTopMenu" // used for positioning
							projectId={projectId}
							projectStatus={projectStatus}	
							updateProjectStatus={this.handleUpdateProjectStatus}
							portalled
						>
							<div className='docEditor-topMenu-projectStatusTagContainer'>
								<Tooltip.Provider>
									<Tooltip.Root delayDuration={0}>
										<Tooltip.Trigger asChild>																																			
											<button onClick={()=>{this.setState({showEditProjectStatusPopover: true})}} className='docEditor-topMenu-projectStatusTag'>						
												<LinearProjectStatusTag 
													projectStatus={projectStatus}									
												/>
											</button>								
										</Tooltip.Trigger>												 	
										<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
											Edit Project Status
										</Tooltip.Content>																						
									</Tooltip.Root>
								</Tooltip.Provider>
							</div>	
						</EditMetadataPopoverWrapper>													
					}	
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>																								
								<button onClick={this.props.toggleIssuePanel} className={'docEditor-topMenuContainer--rootDoc-linearSummary ' + (issuePanel ? ' docEditor-topMenuContainer--rootDoc-linearSummary--active ' : ' docEditor-topMenuContainer--rootDoc-linearSummary--inactive ')}>
									<div className='docEditor-topMenuContainer--rootDoc-linearSummary-progressCircleContainer'>
										<CircularProgressbar 
											value={linearProjectProgressPercentage} 
											strokeWidth={13}									
										/>
									</div>
									<div className='docEditor-topMenuContainer--rootDoc-linearSummary-progressLabel'>
										{linearProjectProgressPercentage}% <span>- {linearProjectScope}</span>
									</div>							
								</button>																		
							</Tooltip.Trigger>		
							{!issuePanel && 		
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Preview Project Issues
								</Tooltip.Content>													
							}
						</Tooltip.Root>
					</Tooltip.Provider>

				{showTargetDateButton && 					
					<EditMetadataPopoverWrapper
						showPopover={showEditProjectTargetDatePopover}						
						openPopover={()=>{this.setState({showEditProjectTargetDatePopover: true})}}
						closePopover={()=>{this.setState({showEditProjectTargetDatePopover: false})}}		
						type="projectTargetDate"  // used to load in popover contents
						layout="projectRootDocTopMenu" // used for positioning
						projectId={projectId}
						updateProjectTargetDate={this.updateProjectTargetDate}
						currentTargetDate={project.target_date}
						portalled
					>
						
						<div className='docEditor-topMenuContainer--rootDoc-targetDateBtnContainer'>
							<Tooltip.Provider>
								<Tooltip.Root delayDuration={0}>
									<Tooltip.Trigger asChild>																																										
										<button onClick={()=>{this.setState({showEditProjectTargetDatePopover: true})}} className={'docEditor-topMenuContainer--rootDoc-targetDateBtn ' + (targetDateWarning ? ' docEditor-topMenuContainer--rootDoc-targetDateBtn--warning ':'')}>
											<div className='docEditor-topMenuContainer--rootDoc-targetDateBtn-iconContainer'>
												<Icon name='calendarSimple' />
											</div>
											{targetDateLabel && 
											<div className='docEditor-topMenuContainer--rootDoc-targetDateBtn-label'>
												{targetDateLabel}
											</div>
											}
											{!targetDateLabel && 
											<div className='docEditor-topMenuContainer--rootDoc-targetDateBtn-label'>
												Add Target Date
											</div>
											}
										</button>				
									</Tooltip.Trigger>	
									{targetDateLabel &&									 	
									<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
										Edit Target Date
									</Tooltip.Content>																							
									}
								</Tooltip.Root>
							</Tooltip.Provider>
						</div>	
					</EditMetadataPopoverWrapper>		
				}	

					{!this.props.messagePanelIsVisible && 
						<button onClick={this.props.toggleShowMessagePanel} className={'docEditor-topMenu-button docEditor-topMenu-button--textAndIcon docEditor-topMenu-button--docChat ' + (this.props.showUnreadsIndicatorDocChatBtn ? ' docEditor-topMenu-button--docChat--hasUnreads ': ' ')}> 
							<div className='docEditor-topMenu-button-iconContainer'>
								{/*<Icon name='messageOutlineMedium' /> */}
								<Icon name={this.props.showUnreadsIndicatorDocChatBtn ? 'sideMenuClean' : 'sideMenu'} />
							</div>
							{this.props.showUnreadsIndicatorDocChatBtn &&
								<div className='docEditor-topMenu-button--docChat--hasUnreadsIndicator' />
							}
							<div className='docEditor-topMenu-button-label'>
								Doc Chat
							</div>						
						</button>
					}

					{!starred && 
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>																	
								<button className='docEditor-topMenu-button docEditor-topMenu-button--inactiveStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{starTab('doc',docId)}}>
									<Icon name='star' />
								</button>																	
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Star Project
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					}
					{starred && 
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>																	
								<button className='docEditor-topMenu-button docEditor-topMenu-button--activeStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{unstarTab('doc',docId)}}>
									<Icon name='starFill' />
								</button>																	
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Unstar Project
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					}



					<Tooltip.Provider>
						<Tooltip.Root delayDuration={300}>
							<Tooltip.Trigger asChild>
								<button onClick={()=>{copyDocLinkToClipboard(docId)}} className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--docLink'>
							    <Icon name='link' />
							  </button>
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Copy Link
								<KeyboardShortcutTag									
									command
									letter='L'
								/>									
								</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>		

					<DropdownMenu.Root open={showDropdown}>
				    <DropdownMenu.Trigger asChild>
				    	<button onClick={()=>{this.setState({showDropdown: true})}} className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--dropdown'>
				    		<Icon name='ellipses' />
				    	</button>
				    </DropdownMenu.Trigger>   			 	
	   			 	<DropdownMenu.Content 
	   			 		sideOffset={1}
	   			 		align="end"
	   			 		alignOffset={-8}
	   			 		className='dropdownMenu'
	   			 		onEscapeKeyDown={()=>{this.setState({showDropdown: false})}}
							onPointerDownOutside={()=>{this.setState({showDropdown: false})}}
							onInteractOutside={()=>{this.setState({showDropdown: false})}}
						>							
	   			 		{/*
	   			 		<DropdownMenu.Item onSelect={this.integrationStuff}>
		        		Start Notion Integration
		        	</DropdownMenu.Item>
		        	
		        	
		        	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
		        		Copy Link
		        	</DropdownMenu.Item>
		        	*/}		        	

							{/*}
							{!starred &&
			        	<DropdownMenu.Item onSelect={()=>{starTab('doc',docId)}}>
			        		Star Project
			        	</DropdownMenu.Item>		
		        	}

		        	{starred &&
			        	<DropdownMenu.Item onSelect={()=>{unstarTab('doc',docId)}}>
			        		Unstar Project
			        	</DropdownMenu.Item>		
		        	}
		        	*/}

		        	<DropdownMenu.Item onSelect={()=>{openProjectInLinear(projectSlug)}}>
		        		Open Project in Linear
		        	</DropdownMenu.Item>		

		        	<DropdownMenu.Item onSelect={()=>{this.props.showEditProjectModal()}}>
		        		Edit Project...
		        	</DropdownMenu.Item>		

		        	<DropdownMenu.Separator />

		        	{/*}
		        	<DropdownMenu.Item onSelect={()=>{createTemplate(docId)}}>
		        		Turn into template...
		        	</DropdownMenu.Item>
		        	*/}

		        	<DropdownMenu.Item onSelect={()=>{this.handleTemplatePopoverClick()}}>
		        		Turn into template...
		        	</DropdownMenu.Item>

		        	
		        	<DropdownMenu.Item onSelect={this.props.duplicateDoc}>
		        		Duplicate Doc
		        	</DropdownMenu.Item>

		        			        	  
				    </DropdownMenu.Content>
					</DropdownMenu.Root>	 	 				
				</div>
			</div>		
	  )
	}
}

export default withRouter(DocEditorProjectRootDocTopMenu)
		{/*}
					<DropdownMenu.Root>
				    <DropdownMenu.Trigger className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--dropdown'>
				    	<Icon name='ellipses' />
				    </DropdownMenu.Trigger>   			 	
	   			 	<DropdownMenu.Content sideOffset={1} align="end" alignOffset={-8} className='dropdownMenu'>							
	   			 		<DropdownMenu.Item>
		        		Open Project in Linear
		        	</DropdownMenu.Item>        			        		   
	   			 		
	   			 		<DropdownMenu.Item onSelect={this.integrationStuff}>
		        		Start Notion Integration
		        	</DropdownMenu.Item>		        	
		        	
		        	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
		        		Copy Link
		        	</DropdownMenu.Item>        			        	
		        	<DropdownMenu.Item >
		        		Duplicate Doc
		        	</DropdownMenu.Item>
		        	<DropdownMenu.Separator />
		        	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
		        		Export to Notion
		        	</DropdownMenu.Item>
		        	<DropdownMenu.Separator />				
		       	 	<DropdownMenu.Item  className='dropdownMenu-item--warning'>
		        		Delete
		        	</DropdownMenu.Item>					        
		        	
				    </DropdownMenu.Content>
				    
					</DropdownMenu.Root>	  
					*/}
					
					{/*}

					{teams.map((teamId)=>{
						const teamObj=getTeamForId(teamId)
						const teamIssues=getTeamProjectIssues(doc.project,teamId)
						const issueCount=teamIssues.length
						const isActive=issuePanelTeam==teamId


						return(
							<Tooltip.Provider key={teamId} >
								<Tooltip.Root delayDuration={0}>
									<Tooltip.Trigger asChild>
										<button onClick={()=>{this.props.setIssuePanelTeam(teamId)}} className={'docEditor-topMenuContainer--rootDoc-teamBtn ' + (isActive ? ' docEditor-topMenuContainer--rootDoc-teamBtn--active ' : ' docEditor-topMenuContainer--rootDoc-teamBtn--inactive ')}>
											<div className='docEditor-topMenuContainer--rootDoc-teamBtn-markerContainer'>
												<LinearMarker teamId={teamId} />
											</div>
											<div className='docEditor-topMenuContainer--rootDoc-teamBtn-label'>
											{teamObj.name}
											
											
										</div>
									</button>
								</Tooltip.Trigger>											
								<Tooltip.Content side="bottom" className="tooltip">
								View Issues
								</Tooltip.Content>																
								</Tooltip.Root>
							</Tooltip.Provider>
							)
					})}
						*/}
