import React from 'react'
import Icon from '../misc/Icon'

class PopoverItem extends React.Component{  	

	render(){	

		const {disabled, iconName, label, warning} = this.props

	  return (
	  	
	  		<button disabled={disabled} onClick={this.props.onClickFunction} className={'popover-item' + (warning ? ' popover-item--warning' : '')}>
	  			{iconName &&
	  				<div className='popover-item-iconContainer'>
		          <Icon name={iconName} />
		        </div>
	  			}

	  			{!iconName &&
	  				<div className='popover-item-noIconSpacer' />		         		       
	  			}

	  			{label &&
	  				<div className='popover-item-label'>
		          {label}
		        </div>
	  			}

	  		</button>
			
	  )
	}
}

export default PopoverItem
