//shows if mention menues are open


const initialState = {
	messageMentionActive: false
};

export default function (state = initialState, action) {
	switch (action.type) {
		case 'SHOW_MESSAGE_MENTION_DROPDOWN':
			return {
				...state,
				messageMentionActive: true,
			};
	case 'HIDE_MESSAGE_MENTION_DROPDOWN':
			return {
				...state,
				messageMentionActive: false,
			};
		case 'SIGN_OUT':
			return initialState
		default:
			return state;
	}
}