import React from 'react'
import Icon from '../misc/Icon'
import CompanyIcon from '../misc/CompanyIcon'
import LinearMarker from '../misc/LinearMarker'

class OnboardingModalSelectBtn extends React.Component {   
  
  constructor(props) {
    super(props);    
    this.state = {
      
    }
  }

  render() {
    const {active,emoji,label,companyIcon,projectId} = this.props    

    return (      

        <button onClick={this.props.onSelect} data-state={active ? "active" : "inactive"} className={'onboardingModal-stage-selectBtn ' + (this.props.template ? ' onboardingModal-stage-selectBtn--template ' : ' onboardingModal-stage-selectBtn--project ' )}>
          
          {projectId &&
            <div className='onboardingModal-stage-selectBtn-iconContainer onboardingModal-stage-selectBtn-iconContainer--emoji'>
              <LinearMarker projectId={projectId} />
            </div>
          }

          {companyIcon &&
            <div className='onboardingModal-stage-selectBtn-iconContainer onboardingModal-stage-selectBtn-iconContainer--companyIcon'>
              <CompanyIcon company={companyIcon} />
            </div>
          }

          <div className='onboardingModal-stage-selectBtn-label'>
            {label}
          </div>   

          {active && 
            <div className='onboardingModal-stage-selectBtn-enterKeyContainer'>
              <Icon name='enterKey' />
            </div> 
          }        

        </button>      
              
    )
  }
}



export default OnboardingModalSelectBtn