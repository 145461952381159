import store from '../store'
import find from 'lodash/find'
import includes from 'lodash/includes'

export function getProjectsForCurrentUser(){
	const state = store.getState()
	const projects = state.projects
	let myProjects=[]
	projects.forEach((project)=>{
		if(isUserProjectMember(project.project_id)){
			myProjects.push(project)
		}
	})
	return myProjects
}


function isUserProjectMember(projectId) {
	let userIsMember=false
	const state = store.getState()
	const project= find(state.projects, {'project_id':`${projectId}`})
	const user = state.user
	if(project && user){
		if(includes(project.members,user.user_id)){
			userIsMember=true
		}
	}
	return userIsMember
}

