import React from 'react'
import Icon from '../../../misc/Icon'

class MessageMediaHighlightInlineTranscript extends React.Component{  

	constructor(){
		super()
		this.state={
     
    }
	}	

	render(){	
		const {inline} = this.props
		
	  return (
	  	<div className={'message-content-highlight-transcript ' + (inline ? ' message-content-highlight-transcript--inline ' : ' message-content-highlight-transcript--zoomed ')}>
		  	<button onClick={this.props.toggleTranscript} className='message-content-highlight-transcript-header'>
		  		<div className='message-content-highlight-transcript-header-iconContainer'>
		  			<Icon name='transcript' />
		  		</div>
		  		<div className='message-content-highlight-transcript-header-label'>
		  			Transcript
		  		</div>

		  		<div className='message-content-highlight-transcript-header-hide'>
		  			Hide
		  		</div>
		  	</button>
		  	<div className='message-content-highlight-transcript-contentsContainer'>
		  		<div className='message-content-highlight-transcript-chunkExample' />
		  		<div className='message-content-highlight-transcript-chunkExample' />
		  		<div className='message-content-highlight-transcript-chunkExample' />		  		

		  	</div>
			</div>
	  )
	}
}

export default MessageMediaHighlightInlineTranscript
