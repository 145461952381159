import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../../misc/Icon'
import MeetingTypeIcon from '../../../misc/MeetingTypeIcon'
import MeetingParticipantList from '../../../meetings/meetingParticipantList/MeetingParticipantList'
import DocEditorMeetingInfoDateTime from './DocEditorMeetingInfoDateTime'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getRecordingForMeeting} from '../../../../utils/meetings/getRecordingForMeeting'
import {createMeetingRecording} from '../../../../utils/meetings/createMeetingRecording'
import { RecordingsContext } from '../../../../containers/RecordingsProvider'
import DocEditorMeetingInfoMeetingTypePopover from './DocEditorMeetingInfoMeetingTypePopover'
import DocEditorMeetingInfoAddToCalendarPopover from './DocEditorMeetingInfoAddToCalendarPopover'
import {userHasGCalIntegration} from '../../../../utils/meetings/userHasGCalIntegration'

class DocEditorMeetingInfoWatchRecordingBtn extends React.Component{  

	render(){	
		const {mediaType,recording} = this.props
		const {mux_status,transcript_status}=recording
		//const mediaType = 'video'
		//const mediaType = 'audio'
		let label = 'Watch Recording'
		let iconName = 'watchRecording'
		if(mux_status=='waiting_for_upload'){
			label='uploading'
		}else if(mux_status=='created'){
			label='Processing'
		}else if(mux_status=='ready'){
			if(transcript_status=='ready'){
				label='Watch Recording'
			}else{
				label='Processing'
			}
		}

		if(mediaType === 'audio'){
			label = 'Play Recording'
			//iconName = 'watchRecordingAudio'
		}
	  return (
	  	<div onClick={this.props.openRecordingModal} className='docEditor-meetingTopInfo-watchRecordingBtn'>
	  		<div className='docEditor-meetingTopInfo-watchRecordingBtn-iconContainer'>
	  			<Icon name={iconName} />
	  		</div>
	  		<div className='docEditor-meetingTopInfo-watchRecordingBtn-label'>
	  			{label}
	  		</div>
				
			</div>		
	  )
	}
}



class DocEditorMeetingInfo extends React.Component{  

	constructor(props){
    super(props)        
    this.handleUploadRecording=this.handleUploadRecording.bind(this)   
    this.state={
      showAllMeetingsModal: false,
      showMeetingTypePopover: false,
      showAddToCalendarPopover: false,
    }
  }

  handleUploadRecording(e){
  	const file=event.target.files[0]
  	createMeetingRecording(this.props.meeting.meeting_id,file,this.context)
  }

	openCalendarLink(url){
    if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:url})     
    }else{
      window.open(url,'_blank')
    }
  }

  checkMeetingTime() {
    // from GTP, we show different things for past/present/future
    // for example, we prompt to upload a recording if meeting is in the past
    const { meeting } = this.props;
    const meetingStartTime = new Date(meeting.meeting_start);
    const meetingEndTime = new Date(meeting.meeting_end);
    const currentTime = new Date();
    if(currentTime < meetingStartTime) {
      return "future";
    } else if(currentTime > meetingEndTime) {
      return "past";
    } else {
      return "present";
    }   
  }


	render(){	
		const {meeting} = this.props
		const meetingType=meeting.type
		const {showMoveDocPopover} = this.state
		const recording=getRecordingForMeeting(meeting.meeting_id)
		const hasRecording = recording?true:false
		const hasHighlights = false
		//const showWatchRecordingButton = hasRecording && !hasHighlights
		const showWatchRecordingButton = false // temp comment out

		const meetingDateTimeState = this.checkMeetingTime()

		let meetingTypeLabel
		
		if(meetingType === 'discovery'){
			meetingTypeLabel = 'Discovery Call'
		}else if(meetingType === 'teamSync'){
			meetingTypeLabel = 'Team Sync'
		}else if(meetingType === 'designReview'){
			meetingTypeLabel = 'Design Review'
		}

		// GOOGLE CALENDAR
		const isGCalEvent = meeting.event_id
		const hasGCalIntegration = userHasGCalIntegration()
		const hasDateTime = meeting.meeting_start?true:false
		const hasExternalContacts = meeting.external_participants.length>0
		//console.log(meeting)

		const hasTypeTag = meetingType && meetingType !=='blank'

	  return (
	  	<div className='docEditor-meetingTopInfoContainer'>

	  		<div className={'docEditor-meetingTopInfo ' + (hasTypeTag ? ' docEditor-meetingTopInfo--hasTypeTag ' : ' docEditor-meetingTopInfo--noTypeTag ') + (meetingType ? ` docEditor-meetingTopInfo--type--${meetingType} ` : '') + (meetingType ? ' docEditor-meetingTopInfo--hasType ' : ' docEditor-meetingTopInfo--noType ')}>
	  			
	  			{/*}
	  			{meetingType && 
						<DocEditorMeetingInfoMeetingTypePopover
							showPopover={this.state.showMeetingTypePopover}
							closePopover={()=>{this.setState({showMeetingTypePopover: false})}}
							activeMeetingType={meetingType}
							meeting={meeting}
						>	
							<div onClick={()=>{this.setState({showMeetingTypePopover: true})}} className={'docEditor-meetingTopInfo-typeTag ' + (meetingType ? ` docEditor-meetingTopInfo-typeTag--type--${meetingType} ` : '')}>								
								{meetingType !=='blank' && 
								<div className='docEditor-meetingTopInfo-typeTag-iconContainer'>	
									<MeetingTypeIcon meetingType={meetingType} />
								</div>
								}
								{meetingType ==='blank' && 
								<div className='docEditor-meetingTopInfo-typeTag-iconContainer'>	
									<Icon name='calendar' />
								</div>
								}
								{meetingType !=='blank' && 
								<div className='docEditor-meetingTopInfo-typeTag-label'>	
									{meetingTypeLabel} {this.state.showMeetingTypePopover}
								</div>							
								}
							</div>
						</DocEditorMeetingInfoMeetingTypePopover>
					}
					*/}

	  			{hasTypeTag &&
		  			<div className={'docEditor-meetingTopInfo-typeTag ' + (meetingType ? ` docEditor-meetingTopInfo-typeTag--type--${meetingType} ` : '')}>								
							
							<div className='docEditor-meetingTopInfo-typeTag-iconContainer'>	
								<MeetingTypeIcon meetingType={meetingType} />
							</div>
						
							<div className='docEditor-meetingTopInfo-typeTag-label'>	
								{meetingTypeLabel}
							</div>							
							
						</div>
					}

					<DocEditorMeetingInfoDateTime
						meeting={meeting}
						viewOnlyMode={isGCalEvent}
					/>					

					

					<div className='docEditor-meetingTopInfo-hSpacer' />					

					
					
					{hasGCalIntegration && hasDateTime && !isGCalEvent && !hasExternalContacts &&			
						<DocEditorMeetingInfoAddToCalendarPopover
							showPopover={this.state.showAddToCalendarPopover}
							closePopover={()=>{this.setState({showAddToCalendarPopover: false})}}
							//activeMeetingType={meetingType}
							meeting={meeting}
						>							
							<button onClick={()=>{this.setState({showAddToCalendarPopover: true})}} className='docEditor-meetingTopInfo-addToCalendarBtn'>
								<div className='docEditor-meetingTopInfo-addToCalendarBtn-iconContainer'>
									<Icon name='googleIconLarge' />
								</div>
								<div className='docEditor-meetingTopInfo-addToCalendarBtn-label'>
									Add to GCal
								</div>
							</button>
						</DocEditorMeetingInfoAddToCalendarPopover>
					}
					
					


					{/*}
					{isGCalEvent && 
						<button onClick={()=>{this.openCalendarLink('https://www.youtube.com/watch?v=dQw4w9WgXcQ')}} className='docEditor-meetingTopInfo-openGCalEventBtn'>
							<div className='docEditor-meetingTopInfo-openGCalEventBtn-iconContainer'>
								<Icon name='linkArrow' />
							</div>
							<div className='docEditor-meetingTopInfo-openGCalEventBtn-label'>
								Calendar Link
							</div>
						</button>
					}
					*/}

					<MeetingParticipantList
						meeting={meeting}
						showDropdown
						readOnly={isGCalEvent}
					/>

	  		</div>				
				
			</div>		
	  )
	}
}

DocEditorMeetingInfo.contextType = RecordingsContext;

export default withRouter(DocEditorMeetingInfo)







						{/*}
						<div className='docEditor-meetingTopInfo-recordBtn'>	
							<div className='docEditor-meetingTopInfo-recordBtn-iconContainer'>	
								<Icon name='recordCircle' />
							</div>
							<div className='docEditor-meetingTopInfo-recordBtn-label'>	
								Record Audio
							</div>							
						</div>
						*/}


{/*

		
					{!hasRecording && meetingDateTimeState === 'past' &&
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={100}>
								<Tooltip.Trigger asChild>																										
									<button onClick={()=>{createMeetingRecording(meeting.meeting_id,this.context)}} className='docEditor-meetingTopInfo-addRecordingBtn'>
										
										<div className='docEditor-meetingTopInfo-addRecordingBtn-label'>
											<label htmlFor="recordingUpload">												
												<div className='docEditor-meetingTopInfo-addRecordingBtn-iconContainer'>
													
													<Icon name='transcript' />
												</div>
												<div className='docEditor-meetingTopInfo-addRecordingBtn-label-label'>
													Add Recording
												</div>
											</label>
											<input 
												id="recordingUpload"
												type="file"
												className='docEditor-meetingTopInfo-addRecordingBtn-hiddenFileInput'
												onChange={this.handleUploadRecording} 
												value={''} 
												accept={'video/*, audio/*'}
											/>
										</div>
									</button>														
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--uploadRecordingButton">																																	
									Share meeting highlights with a full searchable transcript.
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					}
					}


{/*}
				{meetingType === 'standup' &&
					<div className='docEditor-meetingTopInfo docEditor-meetingTopInfo--type--standup'>
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={300}>
								<Tooltip.Trigger asChild>																																	
									<div className='docEditor-meetingTopInfo-typeTag'>	
										<div className='docEditor-meetingTopInfo-typeTag-iconContainer'>	
											<Icon name='meetingStandupLayered' />
										</div>
										<div className='docEditor-meetingTopInfo-typeTag-label'>	
											Standup
											{standupTeam && 
												<span className='docEditor-meetingTopInfo-typeTag-label-sub'>	
													{standupTeam}
												</span>
											}
										</div>
									</div>		
								</Tooltip.Trigger>								
								<Tooltip.Content side="bottom" className="tooltip">
									Browse Desktop Standups
								</Tooltip.Content>						
							</Tooltip.Root>
						</Tooltip.Provider>
						{standupCycleName && 
							<div className='docEditor-meetingTopInfo--standup-cycleTag'>	
								<div className='docEditor-meetingTopInfo--standup-cycleTag-iconContainer'>	
									<Icon name='cycle' />
								</div>
								<div className='docEditor-meetingTopInfo--standup-cycleTag-label'>	
									{standupCycleName}
								</div>
							</div>
						}
						<div className='docEditor-meetingTopInfo-staticDateLabel'>	
							{standupDocDateLabel}
						</div>
						<div className='docEditor-meetingTopInfo-hSpacer' />	
						<MeetingParticipantList 
							meeting={meeting}
						/>
					</div>
				}

				{meetingType === 'sprintPlanning' &&
					<div className='docEditor-meetingTopInfo docEditor-meetingTopInfo--type--sprintPlanning'>
						<div className='docEditor-meetingTopInfo-typeTag'>	
							<div className='docEditor-meetingTopInfo-typeTag-iconContainer'>	
								<Icon name='meetingSprintPlanningLayered' />
							</div>
							<div className='docEditor-meetingTopInfo-typeTag-label'>	
								Sprint Planning
								<span className='docEditor-meetingTopInfo-typeTag-label-sub'> 
									{sprintPlanningTeam}
								</span>
							</div>
						</div>
						{sprintPlanningCycleName && 
							<div className='docEditor-meetingTopInfo--standup-cycleTag'>	
								<div className='docEditor-meetingTopInfo--standup-cycleTag-iconContainer'>	
									<Icon name='cycle' />
								</div>
								<div className='docEditor-meetingTopInfo--standup-cycleTag-label'>	
									Next - {sprintPlanningCycleName}
								</div>
							</div>
						}
						<div className='docEditor-meetingTopInfo-staticDateLabel'>	
							{sprintPlanningDateLabel}
						</div>
						<div className='docEditor-meetingTopInfo-hSpacer' />	
						<MeetingParticipantList 
							meeting={meeting}
						/>
					</div>
					*/}
				 