import { Plugin, PluginKey } from 'prosemirror-state'
import ReactDOM from 'react-dom';
import { Decoration, DecorationSet } from "prosemirror-view"
import HighlightFlagReactComponent from './HighlightFlagReactComponent'



const HIGHLIGHT_TEXT_CLASSNAME='doc-span--transcriptHighlight'

const widgetSpec={
  destroy(el){
    ReactDOM.unmountComponentAtNode(el)
  }
}

export const transcriptHighlightPluginKey = new PluginKey('transcriptHighlightPlugin');


function transcriptHighlightsPlugin(initialHighlights) {
  return new Plugin({
    key: transcriptHighlightPluginKey,
    state: {
      init(_, { doc }) {
        let decorationSet = DecorationSet.empty;
        if (initialHighlights.length > 0) {
          const decorations = initialHighlights.flatMap(highlight => [
            Decoration.inline(highlight.start_pos, highlight.end_pos, {class: HIGHLIGHT_TEXT_CLASSNAME}),
            Decoration.widget(highlight.start_pos, createWidget(highlight),widgetSpec),
          ]);
          decorationSet = DecorationSet.create(doc, decorations);
        }

        return {
          highlights: initialHighlights,
          decorationSet: decorationSet
        };
      },
      apply(tr, oldState) {
        let { highlights, decorationSet } = oldState;
        decorationSet = decorationSet.map(tr.mapping, tr.doc);

        const newHighlights = tr.getMeta(this);
        if (newHighlights) {
          decorationSet = DecorationSet.empty;
          // if (newHighlights.length > 0) {
          //   const decorations = newHighlights.map(highlight =>
          //     Decoration.inline(highlight.start_pos, highlight.end_pos, {class: HIGHLIGHT_TEXT_CLASSNAME})
          //   );
          //   decorationSet = DecorationSet.create(tr.doc, decorations);
          // }
           if (newHighlights.length > 0) {
            const decorations = newHighlights.flatMap(highlight => [
              Decoration.inline(highlight.start_pos, highlight.end_pos, {class: HIGHLIGHT_TEXT_CLASSNAME}),
              Decoration.widget(highlight.start_pos, createWidget(highlight),widgetSpec),
            ]);
            decorationSet = DecorationSet.create(tr.doc, decorations);
          }
          highlights = newHighlights;
        }
        return { highlights, decorationSet };
      }
    },
    props: {
      decorations(state) {
        return this.getState(state).decorationSet;
      }
    }
  });
}


function createWidget(highlight) {
  const widget = document.createElement('span');
  ReactDOM.render(<HighlightFlagReactComponent highlight={highlight}/>, widget);
  return widget;
}

export default transcriptHighlightsPlugin;



// function transcriptHighlightsPlugin(highlights) {
//   return new Plugin({
//     state: {
//     init(_, { doc }) {
//       console.log(highlights)
//       const decorations = highlights.map(highlight =>
//         Decoration.inline(highlight.start_pos, highlight.end_pos, {class: HIGHLIGHT_TEXT_CLASSNAME})
//       );
//       return DecorationSet.create(doc, decorations);
//     },
//       apply(tr, oldState) {
//         let { highlights, decorationSet } = oldState;
//         decorationSet = decorationSet.map(tr.mapping, tr.doc);

//         const newHighlights = tr.getMeta(this);
//         if (newHighlights) {
//           const decorations = newHighlights.map(highlight =>
//             Decoration.inline(highlight.from, highlight.to, {class: HIGHLIGHT_TEXT_CLASSNAME})
//           );
//           highlights = newHighlights;
//           decorationSet = DecorationSet.create(tr.doc, decorations);
//         }

//         return { highlights, decorationSet };
//       }
//     },
//     props: {
//       decorations(state) {
//         console.log('here')

//         return this.getState(state);
//       }
//     }
//   });
// }



// function transcriptHighlightsPlugin(highlights) {
//   return new Plugin({
//     key: new PluginKey('transcriptHighlightsPlugin'),
//     state: {
//       init(config, state) {
//         return []
//       },
//       apply(tr, oldState) {
//         let action = tr.getMeta('transciptHighlightsPlugin')
//         if (action && action.highlights) {
//           // console.log('here-------')
//           // console.log(action.highlights)
//           return action.highlights
//         }
//         return oldState
//       },
//     },
//     props: {
//       decorations(state) {
//         let {doc} = state
//         let decorations = []
//         // console.log('in decos----------')
//         // console.log(this.getState(state))

//         let highlights = this.getState(state)
//         if (Array.isArray(highlights)) {
//           highlights.forEach(({startPos, endPos}) => {
//             let decoration = Decoration.inline(startPos, endPos, {class: HIGHLIGHT_TEXT_CLASSNAME})
//             decorations.push(decoration)
//           })
//         }

//         return DecorationSet.create(doc, decorations)
//       }
//     }
//   })
// }

