import React from 'react'
import Icon from '../../../misc/Icon'

class DocFigmaEmbedZoomedModalThreadPanel extends React.Component{  	
	
	render(){	
		return (
			<div className='doc-zoomedFigmaModal-threadPanel forceDarkTheme'>
				<div onClick={this.props.closeThreadPanel} className='doc-zoomedFigmaModal-threadPanel-header'>
					<div className='doc-zoomedFigmaModal-threadPanel-header-iconContainer'>
						<Icon name='rightMenu' />
					</div>
					<div className='doc-zoomedFigmaModal-threadPanel-header-label'>
						Thread
					</div>					
					
					<div className='doc-zoomedFigmaModal-threadPanel-header-closeBtnSpacer'>

					</div>
					
				</div>
			</div>
		)
	}

}

export default DocFigmaEmbedZoomedModalThreadPanel
