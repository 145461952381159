import React from 'react'
import { connect } from 'react-redux'
import { withRouter} from 'react-router-dom'
import {logout} from '../../actions/auth'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import filter from 'lodash/filter'
import Icon from '../misc/Icon'
import SideMenu from './SideMenu'
import { Spring, animated, config } from 'react-spring'
import {getDocIdFromSignature} from '../../utils/getDocIdFromSignature'


const sideMenuVisibleForPage = (location) => {
  if ( // don't show nav bar for the folowing routes
    !(location.pathname.indexOf('/settings') > -1)
  ) return true
  return false
}

class SideMenuContainer extends React.Component{  

	constructor(){
		super()
		this.navigateTo=this.navigateTo.bind(this)
		this.showHoverMenu=this.showHoverMenu.bind(this)
		this.hideHoverMenu=this.hideHoverMenu.bind(this)
		this.state={
			showHoverSideMenu:false,			
		}		
	}

	showHoverMenu(){
		this.setState({showHoverSideMenu: true})
	}

	hideHoverMenu(){
		this.setState({showHoverSideMenu: false})
	}

	navigateTo(url){
		this.props.history.push(url)
	}


	render(){		
		const {sideMenuVisible, toggleSideMenuVisible} = this.props
		const {videoModalIsZoomed}=this.props
		const showSideMenu=sideMenuVisible && sideMenuVisibleForPage(this.props.history.location)
		const showHoverSideMenu=this.state.showHoverSideMenu && sideMenuVisibleForPage(this.props.history.location)
		return (	
			<React.Fragment>					
				<div id={'sideMenuContainer'} className={'sideMenuContainer ' + (showSideMenu ? ' sideMenuContainer--visible ' : ' sideMenuContainer--hidden ') + (this.props.figmaModalIsZoomed ? ' sideMenuContainer--figmaModalZoomed ' : ' ') + (this.props.messageMediaIsZoomed ? ' sideMenuContainer--messageMediaIsZoomed ' : ' ')}>						
					<div id='sideMenuContainerSpacer' className='sideMenuContainer-openCloseSpacer' /> 
					{showSideMenu &&
						<SideMenu
							key={1}
							toggleSideMenuVisible={toggleSideMenuVisible} 
							navigateTo={this.navigateTo}
							organization={this.props.organization}
							pathname={this.props.location?this.props.location.pathname:''}
							doc={this.props.doc}
							logout={this.props.logout}
							sideMenuVisible={showSideMenu}
							docPresenceMembers={this.props.docPresenceMembers}
							openOpenDocMenu={this.props.openOpenDocMenu}
							docs={this.props.docs}
							recentActivities={this.props.recentActivities}
							orgMembers={this.props.orgMembers}
							teams={this.props.teams}
							messageCount={this.props.messageCount}
							roadmap={this.props.roadmap}
							toggleKeyboardShortcutsPanel={this.props.toggleKeyboardShortcutsPanel}

							meetings={this.props.meetings}

						/> 
					}
				{!showSideMenu &&
					<React.Fragment>
						<button onClick={this.props.toggleSideMenuVisible} className='sideMenuHiddenLeftToggle' />		
						{!showHoverSideMenu && 
							<div onMouseEnter={this.showHoverMenu} className='sideMenuHiddenHoverArea'/>
						}				
						{!showHoverSideMenu && 
							<div onMouseEnter={this.showHoverMenu} className='sideMenuTopHoverArea'/>
						}
						<div onMouseLeave={this.hideHoverMenu} className={'sideMenuContainer--hidden-sideMenuOuterContainer ' + (showHoverSideMenu ? 'sideMenuContainer--hidden-sideMenuOuterContainer--show' : 'sideMenuContainer--hidden-sideMenuOuterContainer--hide')}>			
							<Spring
								opacity={showHoverSideMenu ? '1' : '0'}						
								transform={showHoverSideMenu ? 'translateX(0px)' : 'translateX(-240px)'}
								config={{
									tension: 600,
									friction: 40,
								}}
							>
				  			{styles => 
									<animated.div style={styles} className='sideMenuContainer--hidden-sideMenuInnerContainer'>
										<div className='electronMacDots electronMacDots--hoverSideMenu'>
					            <div className='electronMacDots-dot' />
					            <div className='electronMacDots-dot' />
					            <div className='electronMacDots-dot' />
					          </div>      
										<SideMenu
											key={2}
											toggleSideMenuVisible={toggleSideMenuVisible} 
											navigateTo={this.navigateTo}
											organization={this.props.organization}
											pathname={this.props.location?this.props.location.pathname:''}
											doc={this.props.doc}
											logout={this.props.logout}
											sideMenuVisible={showSideMenu}
											docPresenceMembers={this.props.docPresenceMembers}
											openOpenDocMenu={this.props.openOpenDocMenu}
											docs={this.props.docs}
											recentActivities={this.props.recentActivities}
											orgMembers={this.props.orgMembers}
											teams={this.props.teams}
											messageCount={this.props.messageCount}
											roadmap={this.props.roadmap}
											toggleKeyboardShortcutsPanel={this.props.toggleKeyboardShortcutsPanel}

											meetings={this.props.meetings}

										/> 
									</animated.div>
				  			}
				  		</Spring>
				  </div>
				
				</React.Fragment>
			}
			</div>
		</React.Fragment>
		)
	}
}


function mapStateToProps(state,ownProps) {
	const pathname=ownProps.location.pathname
	let docPresenceMembers={}
	let docId
	let doc
	const signatureComponentsArray=pathname.split(/-/)
	if(signatureComponentsArray.length>1){
		docId=signatureComponentsArray[signatureComponentsArray.length-1]
	}

	if(docId){
		docPresenceMembers=state.docPresenceMembers[docId]||{}
		doc=find(state.docs, {'doc_id':docId})
	} 

	let figmaModalIsZoomed=false
	if(state.docEditorMediaModals && state.docEditorMediaModals.zoomedFigma && state.docEditorMediaModals.zoomedFigma.visible){
		figmaModalIsZoomed=true
	}

	//if is/roadmaps/<id> find roadmap
	let roadmap
	if(pathname.indexOf('/roadmaps') > -1){
  	const segments = pathname.split("/")
  	if(segments[2]){
  		roadmap=find(state.roadmaps,{roadmap_id:segments[2]})
  	}
  }

	return {
		recentActivities:state.recentActivities, //to make sure notification count updates when recent activity updates
		organization:state.organization,	
		doc:doc,
		docPresenceMembers:docPresenceMembers,
		docs:state.docs, //to force update when docs are renamed
		orgMembers:state.orgMembers,
		videoModalIsZoomed:state.videoModalIsZoomed,
		messageMediaIsZoomed:state.messageMediaIsZoomed,
		teams:state.teams,
		messageCount:state.messages.length,
		figmaModalIsZoomed:figmaModalIsZoomed,
		roadmap:roadmap,
		meetings:state.meetings
	}
}

export default withRouter(connect(mapStateToProps,
	{logout}
)(SideMenuContainer))
