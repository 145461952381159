import React from 'react'
import { Transition, animated, config } from 'react-spring'
import RecordingPlaybackModalIdentifySpeakersPanelContents from './RecordingPlaybackModalIdentifySpeakersPanelContents'

class RecordingPlaybackModalIdentifySpeakersPanel extends React.Component{  

  constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this) 
    this.handleKeyDown=this.handleKeyDown.bind(this)   
    this.state={
      forceMount: false  
    }
  }

  componentDidMount() { 
    window.addEventListener('keydown',this.handleKeyDown)
  }

  componentWillUnmount(){
    window.removeEventListener('keydown',this.handleKeyDown)  
  }
  
  handleKeyDown(e){
    if(e.keyCode==27){ //escape to close
      this.props.closeIdentifySpeakersPanel()
    }
  }



  onRest() {  
    if(!this.props.showDialog){
      this.setState({forceMount: false})
    }else{
      this.setState({forceMount: true})
    }
  }

  render(){ 
    const {showIdentifySpeakersPanel, closeIdentifySpeakersPanel} = this.props
    const {forceMount} = this.state
    let transformStart = 'scale(0.96)'
    let transformEnd = 'scale(1)'
    // let transformStart = 'translateY(10px)'
    // let transformEnd = 'translateY(0px)'

    const panelStyle = {
      //width: `${this.props.panelWidth}px`
    }
        
    return (      
      <div className='recordingPlaybackModal-identifySpeakersPanelContainer forceDarkTheme'>
        <Transition
          items={showIdentifySpeakersPanel}
          from={{ opacity: 0, transform: `${transformStart}` }}
          enter={{ opacity: 1, transform: `${transformEnd}`  }}
          leave={{ opacity: 0, transform: `${transformStart}`  }}
          reverse={showIdentifySpeakersPanel}
          config={{tension: 700, friction: 36}}
          onRest={this.onRest}
        >
        {(styles, item) =>
         item && 
          <animated.div style={{...styles, ...panelStyle}} className="recordingPlaybackModal-identifySpeakersPanel">                                                           
            <RecordingPlaybackModalIdentifySpeakersPanelContents
              recording={this.props.recording}
              meeting={this.props.meeting}
              closeIdentifySpeakersPanel={closeIdentifySpeakersPanel}
            />                  
          </animated.div>
        }
        </Transition>                 
      </div>      
    )
  }
}

export default RecordingPlaybackModalIdentifySpeakersPanel
