import findIndex from 'lodash/findIndex'
import {calculateAllRoadmapHeatmaps} from '../utils/calculateHeatmapForRoadmap'


//calculate heatmaps
//{roadmapId:x,heatmap:y}


export default function (state = {}, action) {
	switch (action.type) {

	// case 'FETCH_ROADMAPS_SUCCESS':
	// case 'FETCH_ISSUES_SUCCESS':
	// case 'CREATE_ISSUE_SUCCESS':
	// case 'UPDATE_ISSUE_SUCCESS':
	// 	const heatmaps=calculateAllRoadmapHeatmaps()
	// 	console.log('here--------')
	// 	console.log(heatmaps)
	// 	return heatmaps


	case 'UPDATE_ROADMAPS_HEATMAPS':

		return action.heatmaps

	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}

