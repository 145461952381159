import React from 'react'
import Icon from '../../../misc/Icon'
import DocEditorMeetingContentProjectListRow from './DocEditorMeetingContentProjectListRow'
import {getProjectForId} from '../../../../utils/getProjectForId'
import {createMeetingProjectConnection} from '../../../../utils/meetings/createMeetingProjectConnection'
import {getMeetingProjectSearchResults,reindexSearch} from '../../../../utils/meetings/getMeetingProjectSearchResults'
import InsertMentionsMenuItem from '../../../../prosemirror/plugins/mentions/InsertMentionsMenuItem'

class DocEditorMeetingContentAddProjectPopoverContents extends React.Component{  
  inputRef = React.createRef()

  constructor(props){
    super(props)        
    this.state={      
      projectSearchTerm: '',      
      inputIsFocused: false,
      suggestions:[],
      activeIndex:0
    }
    this.handleSearchProject = this.handleSearchProject.bind(this);    
    this.handleInputFocus = this.handleInputFocus.bind(this);    
    this.handleInputBlur = this.handleInputBlur.bind(this);   
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.handleMouseOver=this.handleMouseOver.bind(this)
    this.handleMouseOut=this.handleMouseOut.bind(this) 
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.selectIndex=this.selectIndex.bind(this)
    this.closeMenu=this.closeMenu.bind(this)
  }

  handleSearchProject(event) {    
    const searchTerm=event.target.value    
    this.setState({projectSearchTerm: searchTerm})
    const suggestions=getMeetingProjectSearchResults(searchTerm,this.props.meeting.meeting_id)
    this.setState({suggestions:suggestions,activeIndex:0})
  }

  handleInputFocus() {    
    reindexSearch()    
    this.setState({inputIsFocused: true})
    const searchTerm=''
    const suggestions=getMeetingProjectSearchResults(searchTerm,this.props.meeting.meeting_id)
    this.setState({suggestions:suggestions})
  }

  handleInputBlur() {     
    this.setState({inputIsFocused: false,suggestions:[],activeIndex:0})
  }

  handleMouseMove(index){
    if(!this.state.mouseHasMoved){
      this.setState({mouseHasMoved:true,activeIndex:index})
    } 
  }

  handleMouseOver(index){
    if(this.state.mouseHasMoved){
      this.setState({activeIndex:index})
    } 
  }

  handleMouseOut(index){
    this.setState({activeIndex:index})
  }

  selectIndex(index,e){
    if(e){
      e.preventDefault()
      e.stopPropagation()
    }
    const project=this.state.suggestions[index]
    createMeetingProjectConnection(this.props.meeting.meeting_id,project.project_id)
    this.closeMenu()
  }

  closeMenu(){
    this.props.closePopover()
    //this.setState({projectSearchTerm:''})
    //this.inputRef.current.blur()
  }

  handleKeyDown(e){
    const {suggestions} = this.state
    if(e.keyCode === 40) { //down arrow
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex+1
      if(newIndex==suggestions.length){
        newIndex=0
      }
      if(oldIndex==null){
        newIndex=0
      }
      this.setState({activeIndex:newIndex})
    } else if (e.keyCode === 38) { //up arrow
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex-1
      if(newIndex<0){
        newIndex=suggestions.length-1
      }
      this.setState({activeIndex:newIndex})
    } else if( e.keyCode === 13) { //enter
      e.stopPropagation()
      e.preventDefault()
      this.selectIndex(this.state.activeIndex)
    } else if (e.keyCode === 27) { //escape
      //TODO blur input and clear text
      this.closeMenu()
    } 
  }

  render(){ 
    const {inputIsFocused,activeIndex} = this.state
    const hasSearchTerm = this.state.projectSearchTerm.length > 0
    return (        
      <React.Fragment>
        <div className='meetingContentAddProjectPopover-searchContainer'>
          <input 
            ref={this.inputRef}
            autoFocus
            value={this.state.projectSearchTerm}
            onChange={this.handleSearchProject} 
            onKeyDown={this.handleKeyDown}                
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            type="text"
            placeholder="Search Projects"
            className='meetingContentAddProjectPopover-search'
          />
          <div className='meetingContentAddProjectPopover-searchBarUI'>
            <div className='meetingContentAddProjectPopover-searchBarUI-searchBarIcon'>
              <Icon name='magnifyingGlass' />
            </div>
          </div>
        </div>

        <div className='meetingContentAddProjectPopover-scrollListContainer'>
          <div className='meetingContentAddProjectPopover-scrollList'>
            {this.state.suggestions.map((suggestion,index)=>{
              const suggestionType='project'
              return(
                <InsertMentionsMenuItem
                  key={`suggestion_${suggestion.project_id}`}
                  suggestion={suggestion}
                  index={index}
                  suggestionType={suggestionType}
                  activeIndex={activeIndex}
                  selectIndex={this.selectIndex}
                  handleMouseMove={this.handleMouseMove}
                  handleMouseOver={this.handleMouseOver}
                  mouseHasMoved={this.state.mouseHasMoved}
                />
                )
            })}
          </div>
        </div>


            {/*}
        <div className={'docEditor-meetingContent-projectList-addProject' + (inputIsFocused ? ' docEditor-meetingContent-projectList-addProject--focused ' : ' docEditor-meetingContent-projectList-addProject--notFocused ')}>
          <div className='docEditor-meetingContent-projectList-addProject-input'>    
            <input
              ref={this.inputRef}
              className='docEditor-meetingContent-projectList-addProject-input-input'
              value={this.state.projectSearchTerm}
              onChange={this.handleSearchProject} 
              onKeyDown={this.handleKeyDown}                
              onFocus={this.handleInputFocus}
              onBlur={this.handleInputBlur}
              type="text"
              placeholder="Search Projects"
            />            
            <div className='docEditor-meetingContent-projectList-addProject-input-ui'>
              <div className='docEditor-meetingContent-projectList-addProject-input-ui-magnifyingGlass'>
                <Icon name='magnifyingGlass' />
              </div>
              <div className='docEditor-meetingContent-projectList-addProject-input-ui-plus'>
                <Icon name='plus' />
              </div>
              <div className='docEditor-meetingContent-projectList-addProject-input-ui-label'>
                {hasProjects ? 'Add Project' : 'Add Linked Projects'}
              </div>
            </div>
          </div>
          
          {/*}
          {inputIsFocused && 
            <div className='docEditor-meetingContent-projectList-addProject-resultsDropdownContainer'>
              <div className='insertMentionsMenu insertMentionsMenu--popoverSide--right'>
                
              </div>
            </div>
          }
          */}

        </React.Fragment>

    )
  }
}

export default DocEditorMeetingContentAddProjectPopoverContents