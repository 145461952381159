import React from 'react'
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import * as Tooltip from '@radix-ui/react-tooltip'
import CodeMirrorMenuLanguagePopover from './CodeMirrorMenuLanguagePopover'
import {getLabelForLanguage} from './getLabelForLanguage'


class CodeMirrorMenuReactComponent extends React.Component{  

	constructor(){
		super()		
		this.state={
      showLanguageSelectionPopover: false,      
    }
	}

	render(){ 
		const {currentTheme}=this.props

		const darkUI = currentTheme !== 'artifactLight'

		return(
			<div className={'doc-codeMirror-menu ' + (darkUI ? ' doc-codeMirror-menu--darkUI ' : ' doc-codeMirror-menu--lightUI ')}>
				<CodeMirrorMenuLanguagePopover
					showPopover={this.state.showLanguageSelectionPopover}						
					openPopover={()=>{this.setState({showLanguageSelectionPopover: true})}}
					closePopover={()=>{this.setState({showLanguageSelectionPopover: false})}}		
					changeLanguage={this.props.changeLanguage}		
					currentLanguage={this.props.currentLanguage}										
					currentTheme={currentTheme}
					darkUI={darkUI}
				>
					<button onClick={()=>{this.setState({showLanguageSelectionPopover: true})}} className='doc-codeMirror-menu-langBtn'>
						{/*
						<div className='doc-codeMirror-menu-langBtn-iconContainer'>
							<Icon name='code' />
						</div>
						*/}
						<div className='doc-codeMirror-menu-langBtn-label'>
							{getLabelForLanguage(this.props.currentLanguage)}
						</div>	
					</button>	
				</CodeMirrorMenuLanguagePopover>	

				<div className='doc-codeMirror-menu-hSpacer' />

  			
			  
				<DropdownMenu.Root>
			    <DropdownMenu.Trigger asChild>
	    			<button className='doc-codeMirror-menu-dropdownBtn'>							
								<Icon name='ellipses' />							
						</button>	
			    </DropdownMenu.Trigger>   			 	
   			 	<DropdownMenu.Content sideOffset={1} align="end" alignOffset={-8} className={'dropdownMenu dropdownMenu--codeMirrorLanguageSelection ' + (darkUI ? ' forceDarkTheme ' : '') + (currentTheme ? ` dropdownMenu--codeMirrorLanguageSelection--theme--${currentTheme} ` : ' ')}>		
	        	
	        	<div className='dropdownMenu-subHeader'>
	        		Default Theme
	        	</div>

	        	<DropdownMenu.Item className={(currentTheme=='artifactLight' || currentTheme=='artifactDark') ? 'suggestionMenu-item--active' : ''} onSelect={()=>{this.props.changeTheme('artifact')}}>
	        		Artifact
	        	</DropdownMenu.Item>

	        	<DropdownMenu.Item className={currentTheme=='sublime' ? 'suggestionMenu-item--active' : ''} onSelect={()=>{this.props.changeTheme('sublime')}}>
	        		10X
	        	</DropdownMenu.Item>

	        	<DropdownMenu.Item className={currentTheme=='storm' ? 'suggestionMenu-item--active' : ''} onSelect={()=>{this.props.changeTheme('storm')}}>
	        		Storm
	        	</DropdownMenu.Item>		        	

	        	

	        	{/*}
	        	<DropdownMenu.Item active={currentTheme=='artifact'} onSelect={()=>{this.props.changeTheme('artifact')}}>
	        		{currentTheme=='artifact'?'A':''} Artifact
	        	</DropdownMenu.Item>
	        	*/}

	        	<DropdownMenu.Item className={currentTheme=='vampire' ? 'suggestionMenu-item--active' : ''}  active={currentTheme=='vampire'} onSelect={()=>{this.props.changeTheme('vampire')}}>
	        		Vampire
	        	</DropdownMenu.Item>	

	        	{/*}
	        	<DropdownMenu.Item className={currentTheme=='studio' ? 'suggestionMenu-item--active' : ''} onSelect={()=>{this.props.changeTheme('studio')}}>
	        		Studio
	        	</DropdownMenu.Item>
	        	*/}
	        	
	        	<DropdownMenu.Separator />
	        					
	       	 	<DropdownMenu.Item  onSelect={this.props.deleteCodemirror} className='dropdownMenu-item--warning'>
	        		Delete CodeMirror
	        	</DropdownMenu.Item>					        
			    </DropdownMenu.Content>
				</DropdownMenu.Root>	  	

			</div>
		)
	}
}

export default CodeMirrorMenuReactComponent
