const initialState = null

export default function (state = initialState, action) {
	switch (action.type) {
	
	case 'SHOW_TOAST_NOTIFICATION':
		const timestamp=new Date()
		return {
				...state,
				alertType:action.alertType,
				userName:action.userName,
				actionButton:action.actionButton,
				timestamp:timestamp
			}

	case 'HIDE_TOAST_NOTIFICATION':
		return null

	case 'LOGOUT':
		return initialState
	
	default:
		return state
	}
}
