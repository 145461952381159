import React from 'react'
import FigmaViewDeviceFrame from './FigmaViewDeviceFrame'

class FigmaViewFrameContents extends React.Component{  	

  
	render(){	
		const {calculatedFrame,deviceAugmentedFrame,frameDimensions,deviceFrameBorder}=this.props
		const {embedObj,backgroundImage,device,deviceFrame,backgroundPaddingAmount}=this.props
		
		//
		// ACTUAL IMAGE HEIGHT AND WIDTH 		
		// these can be moved into a new FigmaViewFrame component
		const augmentedFrameToCalculatedFrameRatio = calculatedFrame.width / deviceAugmentedFrame.width
		
		const calculatedFrameImageWidth = frameDimensions.width * augmentedFrameToCalculatedFrameRatio
		const calculatedFrameImageHeight = frameDimensions.height * augmentedFrameToCalculatedFrameRatio
		const calculatedFrameImageX = (deviceFrameBorder.left + backgroundPaddingAmount) * augmentedFrameToCalculatedFrameRatio
		const calculatedFrameImageY = (deviceFrameBorder.top + backgroundPaddingAmount) * augmentedFrameToCalculatedFrameRatio		

		// DEVICE FRAME CALCS
		const calculatedFrameDeviceWidth = (deviceFrameBorder.left + deviceFrameBorder.right + frameDimensions.width) * augmentedFrameToCalculatedFrameRatio
		const calculatedFrameDeviceHeight = (deviceFrameBorder.top + deviceFrameBorder.bottom + frameDimensions.height) * augmentedFrameToCalculatedFrameRatio
		const calculatedFrameDeviceX = (backgroundPaddingAmount) * augmentedFrameToCalculatedFrameRatio
		const calculatedFrameDeviceY = (backgroundPaddingAmount) * augmentedFrameToCalculatedFrameRatio
		
		
		let imageSrc = embedObj.image_url
		// should be more performant
		if(embedObj.cloudinary_slug && this.props.noCrop){
			imageSrc = `http://res.cloudinary.com/yarn/image/upload/c_limit,q_auto,h_3000,w_3000/${embedObj.cloudinary_slug}.webp`
		}


		let deviceFrameContainerZIndex = 20
		if(deviceFrame === 'desktop-arc'){
			deviceFrameContainerZIndex = 5 // arc is underneath the image
		}

		const scale = augmentedFrameToCalculatedFrameRatio
		
		let imageBorderRadius

		if(deviceFrame === 'desktop-arc'){
			imageBorderRadius = 10 * scale
		}

		if(!deviceFrame && backgroundImage){ // no-device-frame border
			imageBorderRadius = 6 * scale
		}

	  return (	  	
				<React.Fragment>			
						{/* FRAME IMAGE */} 
						<img 
							className='figmaView-frame-img'
							style={{borderRadius: `${imageBorderRadius}px`, top: `${calculatedFrameImageY}px`, left: `${calculatedFrameImageX}px`, width: `${calculatedFrameImageWidth}px`, height: `${calculatedFrameImageHeight}px`}} 
							src={imageSrc}
						/>
						{/* DEVICE */}
						<div style={{top: `${calculatedFrameDeviceY}px`, left: `${calculatedFrameDeviceX}px`, width: `${calculatedFrameDeviceWidth}px`, height: `${calculatedFrameDeviceHeight}px`, zIndex: deviceFrameContainerZIndex}} className='figmaView-deviceFrameContainer'>
							<FigmaViewDeviceFrame 
								width={calculatedFrameDeviceWidth}
								height={calculatedFrameDeviceHeight}		  						
								deviceFrameBorder={deviceFrameBorder}
								deviceFrame={deviceFrame}
								scale={scale}			
								backgroundImage={backgroundImage}
							/>
						</div>		  	

					
						
			</React.Fragment>
	  )
	}
}

export default FigmaViewFrameContents