import React from 'react'
import { format,parseISO,parseJSON } from 'date-fns';
import { DayPicker } from 'react-day-picker';
// import { format, formatDistance, formatRelative, subDays } from 'date-fns'


export default function DocDatePicker(props) {

  const [selected, setSelected] = React.useState(new Date(props.date));

  const onSelect = (date) => {
    setSelected(date);
    props.onSelect(date)
  }

  return (
    <DayPicker
      mode="single"
      selected={selected}
      defaultMonth={selected}
      onSelect={onSelect}
      showOutsideDays
    />
  );
}