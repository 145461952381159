import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import AvatarImage from '../../AvatarImage'
import * as Tooltip from '@radix-ui/react-tooltip';
import { Rnd } from "react-rnd"
import {getProjectForId} from '../../../utils/getProjectForId'
import {getUserForId} from '../../../utils/getUserForId'
import moment from 'moment'
import {showToastNotification} from '../../../actions/toastNotification'
import store from '../../../store'
import {getRootDocIdForProject} from '../../../utils/getRootDocIdForProject'
import {openDoc,backgroundOpenDoc} from '../../../utils/openDoc'
import {updateProjectTargetDateWithCb,updateProjectTargetDate} from '../../../utils/updateProject/updateProjectTargetDate'
import debounce from 'lodash/debounce'
import EditMetadataPopoverWrapper from '../../popovers/EditMetadataPopoverWrapper'


function calculateEndDateLabel(daysFromNow){
	const date= moment().startOf('day').add(daysFromNow,'days')
	const today = moment().startOf('day')
	const tomorrow = moment().add(1,'days')
	const yesterday = moment().add(-1,'days')
	const thisWeek = moment().startOf('week')
	const lastWeek=moment(thisWeek).subtract(7,'days')
	const nextWeek = moment(thisWeek).add(7,'days')
	let dateLabel
	let dur=moment().diff(moment(date))
	let days
	if(dur){
		days=dur/(1000*60*60*24)
	}
	if(days>0 && days<8){
		if (moment(date).isSame(today, 'd')) {
			dateLabel='Today'
		}else if (moment(date).isSame(yesterday, 'd')) {
			dateLabel='Yesterday'
		}else if(moment(date).isSame(thisWeek,'week')){ 
			dateLabel=`${moment(date).format('dddd')}`
		}else if(moment(date).isSame(lastWeek,'week')){ 
			dateLabel=`Last ${moment(date).format('dddd')}`
		}
	}else if(days<0 && days>-7){
		if (moment(date).isSame(today, 'd')) {
			dateLabel='Today'
		}else if (moment(date).isSame(tomorrow, 'd')) {
			dateLabel='Tomorrow'
		}else if(moment(date).isSame(thisWeek,'week')){ 
			dateLabel=`${moment(date).format('dddd')}`
		}else if(moment(date).isSame(nextWeek,'week')){ 
			dateLabel=`Next ${moment(date).format('dddd')}`
		}
	}else{
		dateLabel=`${moment(date).format("MMMM D")}`
	}
	return dateLabel
}


function calculateEndDaysFromNow(width,x,daysIntoPast,dayPixelWidth){
	const startDaysInPast=parseInt(daysIntoPast - x/dayPixelWidth)
	const totalDays = parseInt(width, 10)/dayPixelWidth
	const endDaysFromNow=totalDays-startDaysInPast 
	return endDaysFromNow
}

const DEBOUNCE_TIME=1200

class RoadmapsHomePageQuarterTimelineDraggableProjectEndHandle extends React.Component{  		
	render(){		
	  return (	  	
  		<div className='roadmapsHomePage-quarter-timeline-timeline-project-endHandle'>
  			<div className='roadmapsHomePage-quarter-timeline-timeline-project-endHandle-bar' />  					
  		</div>
	  )
	}
}

class RoadmapsHomePageQuarterTimelineDraggableProjectStartHandle extends React.Component{  		
	render(){		
	  return (	  	
  		<div className='roadmapsHomePage-quarter-timeline-timeline-project-startHandle'>
  			<div className='roadmapsHomePage-quarter-timeline-timeline-project-startHandle-bar' />  					
  		</div>
	  )
	}
}

class RoadmapsHomePageQuarterTimelineDraggableProject extends React.Component{  	
	static getDerivedStateFromProps(props, state) {
		if(state.startWidth != props.width){
			return ({x:props.start,startWidth:props.width,width:props.width})
		}
		return null
	}

	constructor(props){
    super()    
    this.handleResizeStop=this.handleResizeStop.bind(this)
    this.handleProjectRowClick=this.handleProjectRowClick.bind(this)
    this.updateDates=this.updateDates.bind(this)
    this.handleProjectRowDateClick=this.handleProjectRowDateClick.bind(this)
    this.updateProjectTargetDateFromPopover=this.updateProjectTargetDateFromPopover.bind(this)
    this.updateDates=debounce(this.updateDates,DEBOUNCE_TIME,{leading:false})
    this.state={
    	y: 0,
    	height: 36,
    	startX:props.start,
    	x: props.start,
    	startWidth:props.width,
    	width: props.width,
    	showEditProjectTargetDatePopover: false, 	
    }           
  }		

  updateProjectTargetDateFromPopover(targetDate,selcted,modifiers,e){
		e.preventDefault()
		e.stopPropagation()
		this.setState({showEditProjectTargetDatePopover:false})
		updateProjectTargetDate(this.props.project.project_id,targetDate)
	}


  updateDates(startDate,endDate){ //need to debounce or causes issues with linear failing
  	updateProjectTargetDateWithCb(this.props.projectId,startDate,endDate).then(()=>{
			//const actionType="updatedProjectTargetDate"
			//const userName=this.props.projectId
			//store.dispatch(showToastNotification(actionType,userName))
  	})
  	.catch(()=>{
  	//	console.log(`ERROR UPDATING PROJECT TAGET DATE`)
  	})
  }

  handleResizeStop(width,x){
  	const {dayPixelWidth,daysIntoPast} = this.props
  	this.setState({width:width,x:x})
  	let newStartDate= null 
  	let newEndDate = null 

  	if(!this.props.previousStart){
  		const startDaysInPast=parseInt(daysIntoPast - x/dayPixelWidth)
  		newStartDate= moment().startOf('day').subtract(startDaysInPast,'days').toDate()
  	}
  	if(!this.props.futureEnd ){
  		const startDaysInPast=parseInt(daysIntoPast - x/dayPixelWidth)
			const totalDays = parseInt(width, 10)/dayPixelWidth
			const endDaysFromNow=totalDays-startDaysInPast 
			if(this.props.openEnd){
				//check if days into the future has changed and if so lets set a new end date
				const oldStartDaysInPast=parseInt(daysIntoPast - this.state.startX/dayPixelWidth)
				const oldTotalDays = parseInt(this.state.startWidth, 10)/dayPixelWidth
				const oldDaysFromNow=oldTotalDays-oldStartDaysInPast
				if(oldDaysFromNow != endDaysFromNow){
					newEndDate= moment().startOf('day').add(endDaysFromNow,'days').toDate()
				}
			}else{
				newEndDate= moment().startOf('day').add(endDaysFromNow,'days').toDate()
			}
  	}
  	this.updateDates(newStartDate,newEndDate)
    this.props.unsetIsDraggingTimeline()
  }

	handleProjectRowClick(e){
		const projectDocId=getRootDocIdForProject(this.props.projectId)
		if(e.metaKey){
			backgroundOpenDoc(projectDocId)
		}else{
			openDoc(this.props.history,projectDocId)
		}
	}

	handleProjectRowDateClick(e){
		e.stopPropagation()
		this.setState({showEditProjectTargetDatePopover: true})
	}

	render(){		
		const {project, dayPixelWidth,daysIntoPast,openEnd,previousStart,futureEnd,futureEndDate, isDraggingTimeline,daysIntoFuture} = this.props
		const projectId=project.project_id
		const {width,x}=this.state
		const endDateDaysFromNow=calculateEndDaysFromNow(width,x,daysIntoPast,dayPixelWidth)
		let endDateLabel
		if(!openEnd){
			endDateLabel=calculateEndDateLabel(endDateDaysFromNow)
		}
		const projectName = project.name
		const projectLead = project.lead
		let projectLeadObj
		let projectLeadAvatar			
		if(projectLead){
			projectLeadObj = getUserForId(projectLead)			
			if(projectLeadObj){
				projectLeadAvatar = projectLeadObj.avatar
			}
		}
		const {showEditProjectTargetDatePopover} = this.state
		const resizeGrid = [dayPixelWidth, 1]
		let endDateCloseToEnd=false 
		if(endDateDaysFromNow>(daysIntoFuture-5)){
			endDateCloseToEnd = true // if end date is e.g. less than 5 days from end
		}
		

	  return (	  	
  		<div className='roadmapsHomePage-quarter-timeline-timeline-projectContainer'>
  			<Rnd
				  className={'roadmapsHomePage-quarter-timeline-timeline-project ' + (isDraggingTimeline ? ' roadmapsHomePage-quarter-timeline-timeline-project--dragging ' : '')} 
				  size={{ width: this.state.width,  height: this.state.height }}
				  position={{ x: this.state.x, y: this.state.y }}
				  dragAxis='x'
				  onDragStop={(e, d) => { this.setState({ x: d.x, y: d.y }) }}
				  onResize={(e, direction, ref, delta, position) => {
	            this.props.setIsDraggingTimeline()
	            this.setState({
				      	width: ref.style.width,
				      	x:position.x,				      
				    	});          	            
           }}
				  onResizeStop={(e, direction, ref, delta, position) => {
				    this.handleResizeStop(ref.style.width,position.x)				    
				  }}
				  enableResizing={{ top:false, right:!futureEnd, bottom:false, left: !previousStart, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
				  bounds='parent'
				  disableDragging={true}
				  resizeGrid={resizeGrid}
				  resizeHandleComponent={{         		
        		right: <RoadmapsHomePageQuarterTimelineDraggableProjectEndHandle />,        		
        		left: <RoadmapsHomePageQuarterTimelineDraggableProjectStartHandle />,
        	}}
				>
					<div  onClick={this.handleProjectRowClick} className={'roadmapsHomePage-quarter-timeline-timeline-project-contentContainer ' + (openEnd ? ' roadmapsHomePage-quarter-timeline-timeline-project-contentContainer--openEnd ' : ' ') + (previousStart ? ' roadmapsHomePage-quarter-timeline-timeline-project-contentContainer--previousStart ' : ' ') + (futureEnd ? ' roadmapsHomePage-quarter-timeline-timeline-project-contentContainer--futureEnd ' : ' ')} >
					  {/*}
					  <div className='roadmapsHomePage-quarter-timeline-timeline-project-avatarContainer'>
					  	<AvatarImage avatarUrl={projectLeadAvatar} className='roadmapsHomePage-quarter-timeline-timeline-project-avatar' />
					  </div>
					  */}

						<div className='roadmapsHomePage-quarter-timeline-timeline-project-iconContainer'>
					  	<LinearMarker projectId={projectId} />
					  </div>
					  <div className='roadmapsHomePage-quarter-timeline-timeline-project-name'>
					  	<div className='roadmapsHomePage-quarter-timeline-timeline-project-name-label'>
					  		{projectName}
					  	</div>
					  </div>

					  {openEnd && 
						  <div style={{width: `${dayPixelWidth}px`}} className='roadmapsHomePage-quarter-timeline-timeline-project-openEndContainer'>
						  	<div className='roadmapsHomePage-quarter-timeline-timeline-project-openEnd'>
						  		<div className='roadmapsHomePage-quarter-timeline-timeline-project-openEnd-svgContainer'>
						  			<svg width="14" height="34" viewBox="0 0 14 34">
					  					<path id="border" d="M1.378 0a3 3 0 0 1 2.51 1.357l8.694 13.278a4 4 0 0 1 .023 4.346l-8.72 13.635A3 3 0 0 1 1.358 34H0V0m1.378 1H0v32h1.358a2 2 0 0 0 1.684-.922l8.72-13.636a3 3 0 0 0-.017-3.26L3.052 1.904"/>
					  					<path id="bg" d="M1.378 1H0v32h1.358a2 2 0 0 0 1.684-.922l8.72-13.636a3 3 0 0 0-.017-3.26L3.052 1.904A2 2 0 0 0 1.378 1z"/>						  				
						  			</svg>
						  		</div>
						  	</div>
						  </div>
						}

						{!openEnd && endDateLabel && !futureEnd &&
							<EditMetadataPopoverWrapper
								showPopover={showEditProjectTargetDatePopover}						
								openPopover={()=>{this.setState({showEditProjectTargetDatePopover: true})}}
								closePopover={()=>{this.setState({showEditProjectTargetDatePopover: false})}}		
								type="projectTargetDate"  // used to load in popover contents
								layout="overdueProjectCard" // used for positioning
								projectId={projectId}
								updateProjectTargetDate={this.updateProjectTargetDateFromPopover}
								currentTargetDate={project.target_date}
								portalled
							>						
								<div onClick={this.handleProjectRowDateClick} className={'roadmapsHomePage-quarter-timeline-timeline-project-endDate ' + (endDateCloseToEnd ? 'roadmapsHomePage-quarter-timeline-timeline-project-endDate--closeToEnd' : '')}>
									{endDateLabel}							
								</div> 				  		
							</EditMetadataPopoverWrapper>										
						}
						{futureEnd &&
							<EditMetadataPopoverWrapper
								showPopover={showEditProjectTargetDatePopover}						
								openPopover={()=>{this.setState({showEditProjectTargetDatePopover: true})}}
								closePopover={()=>{this.setState({showEditProjectTargetDatePopover: false})}}		
								type="projectTargetDate"  // used to load in popover contents
								layout="overdueProjectCard" // used for positioning
								projectId={projectId}
								updateProjectTargetDate={this.updateProjectTargetDateFromPopover}
								currentTargetDate={project.target_date}
								portalled
							>														
								<div onClick={this.handleProjectRowDateClick} className='roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate'>								
									<div className='roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate-label'>
										{moment(futureEndDate).format("MMMM D, YYYY")}
									</div>
									<div className='roadmapsHomePage-quarter-timeline-timeline-project-futureEndDate-iconContainer'>
										<Icon name='calendarSimple' />
									</div>
								</div>		  		
							</EditMetadataPopoverWrapper>	

						}
						{previousStart &&
							<div className='roadmapsHomePage-quarter-timeline-timeline-project-previousStartLine' />
						}

						{futureEnd &&
							<div className='roadmapsHomePage-quarter-timeline-timeline-project-futureEndLine' />
						}
					</div>					
				</Rnd>
				{openEnd && 
					<EditMetadataPopoverWrapper
						showPopover={showEditProjectTargetDatePopover}						
						openPopover={()=>{this.setState({showEditProjectTargetDatePopover: true})}}
						closePopover={()=>{this.setState({showEditProjectTargetDatePopover: false})}}		
						type="projectTargetDate"  // used to load in popover contents
						layout="overdueProjectCard" // used for positioning
						projectId={projectId}
						updateProjectTargetDate={this.updateProjectTargetDateFromPopover}
						//updateProjectTargetDate={this.updateProjectTargetDate}
						//currentTargetDate={project.target_date}
						portalled
					>						
						<div onClick={()=>{this.setState({showEditProjectTargetDatePopover: true})}} style={{left: `${this.state.x}px`, right: '0px'}} className='roadmapsHomePage-quarter-timeline-timeline-project-openEndBg'>

							<div style={{marginLeft: `${this.state.width}px`}} className='roadmapsHomePage-quarter-timeline-timeline-project-openEndBg-label'>
								No target date
							</div>
						</div> 				  		
					</EditMetadataPopoverWrapper>								
				}				
  		</div>
	  )
	}
}

export default withRouter(RoadmapsHomePageQuarterTimelineDraggableProject)
