import React from 'react'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import {isUserOnline} from '../../utils/isUserOnline'

class SelectUserPopoverItem extends React.Component{  	
	render(){	
		const {user, onClickUserFunction, pingDoc, userIsOnline} = this.props

		let disableBtn = false
		if(this.props.pingDoc && !userIsOnline){
			disableBtn = true
		}


		return (	  	
			<button disabled={disableBtn} onClick={()=>{onClickUserFunction(user['user_id'])}} className="selectUserPopover-item" >
				<div className='selectUserPopover-item-avatarContainer'>
					<AvatarImage 
						className='selectUserPopover-item-avatar'
						avatarUrl={user.avatar}
					/>
					{this.props.pingDoc && 
						<div className={'selectUserPopover-item-avatar-statusDot ' + (userIsOnline ? 'selectUserPopover-item-avatar-statusDot--state--online' : 'selectUserPopover-item-avatar-statusDot--state--away')} />					
					}
				</div>
				<div className='selectUserPopover-item-username'>
					{user.display_name}			
				</div>				
			</button>
		)
	}
}



class SelectUserPopover extends React.Component{  	

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)
		this.handleOnClick=this.handleOnClick.bind(this)
		this.state={
      forceMount: false,      
    }
	}

		
	handleOnClick(userId){
		this.props.onClickUserFunction(userId)
		this.props.closePopover()
	}


	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

	render(){	
		const {actionType, inviteDM, pingType, showPopover, openPopover, closePopover, onClickUserFunction, forceDarkTheme, userList, inverted} = this.props
		const {forceMount} = this.state

		let title
		let description
		let keyCommand
		let keyLetter

		if(actionType === "directMessage"){
			title = "Doc DM"
			description="Discuss the doc with a teammate"
			keyCommand = true
			keyLetter='D'
		}else if(actionType === "directMessageInvite"){
			title = "Invite and DM"
			description="Share and discuss the doc"
			keyCommand = true
			keyLetter='D'
		}else if(actionType === "pingDoc"){
			title = "Ping Doc"
			description="Send the doc link"
			keyCommand = true
			keyLetter='P'
		}


		const translateYDistance = 8

		let translateYDirection = 'down'
		if(inverted){
			translateYDirection = 'up'
		}

		let translateY

		if(translateYDirection === 'down'){
			translateY = translateYDistance * -1
		}else if(translateYDirection === 'up'){
			translateY = translateYDistance * 1
		}

		// accept a button as a child
		// const actionType = directMessage
		// const actionType = ping
		// const pingType = doc
		// const pingType = media
		// const inviteDM = true/false
		// onClickUserFunction

		return (
			<React.Fragment>
				<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
					<Popover.Content
						className={'selectUserPopoverContainer ' + (actionType ? ` selectUserPopoverContainer--actionType--${actionType} ` : '') + (showPopover ? ' selectUserPopoverContainer--visible ' : ' selectUserPopoverContainer--hidden ')} 
						onEscapeKeyDown={closePopover}
						onPointerDownOutside={closePopover}
						onInteractOutside={closePopover}
						forceMount={showPopover ? true : forceMount}
						align="start"
						collisionPadding={10}
						//asChild
					>
						<Transition
							items={showPopover}
							from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
							enter={{ opacity: 1, transform: 'translateY(0px)'  }}
							leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
							reverse={showPopover}
							config={{tension: 600, friction: 35}}
							onRest={this.onRest}
						>
							{(styles, item) =>
								item && 
								 <animated.div style={styles} className="selectUserPopover popover"> 	
									<div onClick={closePopover} className='selectUserPopover-header'>
											<div className='selectUserPopover-header-leftContainer'>
												<div className='selectUserPopover-header-title'>
													{title}
												</div>
												<div className='selectUserPopover-header-description'>
													{description}
												</div>
											</div>
											{/* TO DO: ADD BACK IN
											<div className='selectUserPopover-header-rightContainer'>
												<div className='selectUserPopover-header-keyboardShortcut'>
													{keyCommand && 
														<div className='selectUserPopover-header-keyboardShortcut-iconContainer'>
															<Icon name='keyCommand' />
														</div>
													}
													<div className='selectUserPopover-header-keyboardShortcut-label'>
														{keyLetter}
													</div>
												</div>
											</div>
											*/}
										</div>

										<div className='selectUserPopover-itemList'>
											{userList && userList.map((user)=>{

												let userIsOnline
												if(actionType === "pingDoc"){
													userIsOnline=isUserOnline(user['user_id'])
												}
												return(	                    
													<SelectUserPopoverItem
														onClickUserFunction={this.handleOnClick}
														key={user['user_id']}
														user={user}
														userIsOnline={userIsOnline}
														pingDoc
													/>
												)
											 })}
										</div>
								</animated.div>
							}
						</Transition>									
					</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			</React.Fragment>
		)
	}
}

export default SelectUserPopover