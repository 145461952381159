import React from 'react'
import ReactDOM from 'react-dom'
import TranscriptSpeakerHeaderReactComponent from './TranscriptSpeakerHeaderReactComponent'


//Has header
//then content


class TranscriptSpeakerChunkNodeView {
  
  constructor(node, view, getPos,handleClickOnChunk) {
    this.getPos=getPos
    this.node=node 
    this.handleClickOnChunk=handleClickOnChunk
    this.handleClick=this.handleClick.bind(this)
    this.renderReactComponents=this.renderReactComponents.bind(this)


    this.dom = document.createElement("div")
    this.dom.className="recordingPlaybackModal-transcript-speakerChunk"
    //this.dom.addEventListener('click',this.handleClick);

    this.headerDiv=document.createElement("div")
    this.headerDiv.className="recordingPlaybackModal-transcript-speakerChunk-headerContainer"
    this.dom.appendChild(this.headerDiv)


    this.contentDiv = document.createElement("div")
    this.contentDOM=this.contentDiv
    this.dom.appendChild(this.contentDiv)

    this.renderReactComponents()
  }

  handleClick(){
    this.handleClickOnChunk(this.node)
  }


  renderReactComponents(){
    const node=this.node
    const {transcript_speaker,recording_id,start_time,end_time,active}=node.attrs

    if(active){
      this.dom.classList.add("recordingPlaybackModal-transcript-speakerChunk--active")
    }else{
      this.dom.classList.remove("recordingPlaybackModal-transcript-speakerChunk--active")
    }
    ReactDOM.render(
      <TranscriptSpeakerHeaderReactComponent    
        transcript_speaker={transcript_speaker}
        recording_id={recording_id}
        startTime={start_time}
        endTime={end_time}
      />, this.headerDiv   
    )
  }
  
  update(node){
    if (this.node.type !== node.type) {
      return false
    }
    const {active,speakerVersion}=node.attrs
    const oldIsActive=this.node.attrs.active
    const oldSpeakerVersion=this.node.attrs.speakerVersion
    this.node = node;
    if(oldIsActive != active ||oldSpeakerVersion !=speakerVersion){
      this.renderReactComponents()
    }
    return true;
  }


  getNode(){
    return this.node
  }



  destroy() {
  //  ReactDOM.unmountComponentAtNode(this.dom)
    ReactDOM.unmountComponentAtNode(this.headerDiv)

  }

}

export default TranscriptSpeakerChunkNodeView