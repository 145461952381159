const initialState={
	visible:false,
	docId:null,
	newTemplateId:null,
	addLinkToLinear:false
}


export default function (state = initialState, action) {
	switch (action.type) {
		case 'SHOW_NEW_TEMPLATE_MODAL':
			return {...state,visible:true,newTemplateId:action.newTemplateId,docId:null}

	 case 'SHOW_DOC_TEMPLATES_MODAL':
			return {...state,visible:true,docId:action.docId,addLinkToLinear:action.addLinkToLinear}
	case 'HIDE_DOC_TEMPLATES_MODAL':
			return initialState
		default:
			return state;
	}
}
