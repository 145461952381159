export const getNewState = function() {
  return {
    mouseHasMoved:false,
    active: false,
    range: {
      from: 0,
      to: 0
    },
    text: "",
    suggestions: [],
    textWithResults:'',
    index: 0 // current active suggestion index
  };
};
