import React from 'react'
import Icon from '../../../misc/Icon'
import MessagePanelHeaderFollower from './MessagePanelHeaderFollower'

class MessagePanelHeaderFollowerList extends React.Component{  

	render(){         
		const {followerList} = this.props		

		return (      
			<div className='docEditor-messagePanel-header-followerList'>
				{followerList && followerList.map((follower,i)=>{                           
					return(
						<MessagePanelHeaderFollower
							key={i}                  
							follower={follower}    
							docId={this.props.docId} 
							removeFollowerFromDoc={this.props.removeFollowerFromDoc}             
						/>
					)
				})}
			</div>    
		)
	}
}
export default MessagePanelHeaderFollowerList

// (this.props.popoverOpen ? ' docEditor-messagePanel-header-followerList--popoverOpen ' : ' docEditor-messagePanel-header-followerList--popoverClosed ')