import React from 'react'
import Measure from 'react-measure'
import {TextSelection} from "prosemirror-state"
import { withRouter} from 'react-router-dom'
// UI ELEMENTS
import DocEditorBottomRightMenu from "./menus/DocEditorBottomRightMenu"
import DocEditorDevOutputPanel from "./DocEditorDevOutputPanel"
import MessagePanel from './messages/MessagePanel'
import { Spring, animated, config } from 'react-spring'
import InsertDrawingModal from './mediaModals/InsertDrawingModal'
import DocImageZoomedModal from './mediaModals/DocImageZoomedModal'
import DocFigmaEmbedZoomedModal from './mediaModals/figma/DocFigmaEmbedZoomedModal'
import {isCurrentUser} from '../../utils/isCurrentUser' 
import {toggleShowInsertDrawingModal} from '../../utils/toggleShowInsertDrawingModal'
import DocSelectionMenu from './DocSelectionMenu'
import {duplicateDoc} from '../../utils/duplicateDoc'

import find from 'lodash/find'
import DocSummarizerProto from './prototypes/DocSummarizerProto'
import DocStashedPanel from './prototypes/DocStashedPanel'
import DocIssuePanel from './DocIssuePanel'
import DocSaveStatus from './DocSaveStatus'
import DocEditorInDocSearch from './DocEditorInDocSearch'
import DocEditorProjectDescription from './DocEditorProjectDescription'
import DocEditorProjectUpdates from './DocEditorProjectUpdates'
import DocEditorProjectContentTabs from './DocEditorProjectContentTabs'
import DocEditorProjectIcon from './DocEditorProjectIcon'
import DocEditorProjectTitle from './DocEditorProjectTitle'
import DocEditorMilestoneTitle from './DocEditorMilestoneTitle'
import DocProjectMentionsSheet from './DocProjectMentionsSheet'
import DocEditorGCalTeamSyncTitle from './DocEditorGCalTeamSyncTitle'

import DocEditorAddLinearLinkBtn from './DocEditorAddLinearLinkBtn'

import DocEditorRecordingPreview from './DocEditorRecordingPreview'

import DocEditorProjectRootDocTopMenu from './menus/DocEditorProjectRootDocTopMenu'
import DocEditorProjectSubDocTopMenu from './menus/DocEditorProjectSubDocTopMenu'
// import DocEditorTeamDocTopMenu from './menus/DocEditorTeamDocTopMenu'
import DocEditorPrivateDocTopMenu from './menus/DocEditorPrivateDocTopMenu'
import DocEditorRoadmapDocTopMenu from './menus/DocEditorRoadmapDocTopMenu'
import DocEditorOrgDocTopMenu from './menus/DocEditorOrgDocTopMenu'
import DocEditorMeetingDocTopMenu from './menus/DocEditorMeetingDocTopMenu'
import {getMeetingForId} from '../../utils/meetings/getMeetingForId'

import {getProjectSubDocs} from '../../utils/getProjectSubDocs'
import {getProjectMilestoneDocs} from '../../utils/getProjectMilestoneDocs'
import {updateIssuePanelVisibility} from '../../utils/updateIssuePanelVisibility'
import {updateMessagePanelVisibility} from '../../utils/updateMessagePanelVisibility'
import {getRecordingForMeeting} from '../../utils/meetings/getRecordingForMeeting'
import DocEditorFakePlaceholder from './DocEditorFakePlaceholder'

import DocEditorTitleRecordingIndicator from './DocEditorTitleRecordingIndicator'

import DocThreadGutter from './docThreadGutter/DocThreadGutter'
import filter from 'lodash/filter'

import EditProjectModal from '../editProjectModal/EditProjectModal'
import DocEditorMeetingInfo from './meetings/meetingInfo/DocEditorMeetingInfo'
import DocEditorMeetingContent from './meetings/meetingContent/DocEditorMeetingContent'
import RecordingPlaybackModalContainer from '../recordingPlaybackModal/RecordingPlaybackModalContainer'
//import DocEditorMeetingTemplateSelection from './meetings/DocEditorMeetingTemplateSelection'
import {calculateDocNotificationCount} from '../../utils/notifications/calculateNotificationCounts'
import DocEditorDevHTMLPanel from './DocEditorDevHTMLPanel'
import DocEditorTemplateSelection from './templateSelection/DocEditorTemplateSelection'
import WhiteboardModal from '../whiteboards/WhiteboardModal'


const DEFAULT_DOC_TITLE="Untitled Doc"
const DEFAULT_MEETING_DOC_TITLE="Untitled Meeting"

class DocEditor extends React.Component{  
	static getDerivedStateFromProps(props, state) { //Stuff to stop input resetting when renaming
		if(props.docRenamedBy && !isCurrentUser(props.docRenamedBy)){
			return ({docRenamedBy:props.docRenamedBy,docTitle:props.docName})
		}
		if(props.docType=="projectRootDoc" && props.doc.name!=state.docTitle){ //handle root doc renamed if edit project
			return ({docTitle:props.doc.name,titleLoaded:true})
		}
		if(props.docName && !state.titleLoaded){
			return ({docTitle:props.docName,titleLoaded:true})
		}
		return null
	}

	constructor(props) {
		super(props)
		this.handleTitleInputOnChange=this.handleTitleInputOnChange.bind(this)
		this.getTitleInputValue=this.getTitleInputValue.bind(this)
		this.duplicateDoc=this.duplicateDoc.bind(this)
		this.openIssuePanel=this.openIssuePanel.bind(this)
		this.closeIssuePanel=this.closeIssuePanel.bind(this)
		this.toggleIssuePanel=this.toggleIssuePanel.bind(this)
		this.toggleShowMessagePanel=this.toggleShowMessagePanel.bind(this)
		this.shouldForceOpenMessagePanel=this.shouldForceOpenMessagePanel.bind(this)
		this.openRecordingModal=this.openRecordingModal.bind(this)

		let title=''
		let titleLoaded=false
		if(props.doc){
			title=props.doc.name
			titleLoaded=true
		}
		let showEditorOutputPanel=false
		if(process.env.REACT_APP_ENV==='local'){
			showEditorOutputPanel=true
		}
		// let showMessagePanel=true 
		// if(props.doc){
		// 	if(props.doc.meeting){
		// 		showMessagePanel=false
		// 	}
		// }
		this.state={

			showRecordingPlaybackModal:false,
			//showMessagePanel:showMessagePanel,
			showEditorOutputPanel:false,

			showHTMLOutputPanel:false,
			showHoverMessagePanel:false,
			docTitle:title,
			titleLoaded:titleLoaded,
			showStashedPanel: false,
			showProjectMentionsSheet: false,
			showMoveDocPopover:false,
			showEditProjectModal: false,			
			//showWhiteboardModal: false,
			editProjectModalFocusField:null //set to title or description if want to focus these
		}
		this.timeout=null
	} 

	componentDidMount(){//Check if no title- if so then focus	
		const value=this.getTitleInputValue()
		if(!value || value.length==0){
			const input = document.getElementById('titleInput')
			if(input){
				this.timeout=setTimeout(function(){
					input.focus()
				}, 50);
			}	
		}
	}

	componentWillUnmount(){
		if(this.timeout){
			clearTimeout(this.timeout)
		}
	}

	duplicateDoc(){
		duplicateDoc(this.props.history,this.props.docId)
	}

	toggleIssuePanel(){
		updateIssuePanelVisibility(this.props.docId,!this.props.issuePanelIsVisible)
	}

	openIssuePanel(projectId){//project id for meetings!
		console.log(`open panel for ${projectId}`)
		updateIssuePanelVisibility(this.props.docId,true,projectId)		
	}

	closeIssuePanel(){
		updateIssuePanelVisibility(this.props.docId,false)
	}
	
	getTitleInputValue(){//return empty string if value is default name
		let value=this.state.docTitle
		if(value==DEFAULT_DOC_TITLE || value==DEFAULT_MEETING_DOC_TITLE){
			value=''
		}
		return value
	}

	handleTitleInputOnChange(e){
		let newTitle=e.target.value
		this.setState({docTitle:newTitle})
		let savedValue=newTitle
		if(!newTitle){
			if(this.props.doc.meeting){
				savedValue=DEFAULT_MEETING_DOC_TITLE
			}else{
				savedValue=DEFAULT_DOC_TITLE
			}
		}
		this.props.onDocNameUpdate(savedValue)
	}

	onClickFocusTrap(){
		const input = document.getElementById('titleInput')
		if(input){
			input.focus()
			input.setSelectionRange(input.value.length,input.value.length)
		}
	}

	handleTitleInputKeyDown(e){
		if(e.keyCode===13 || e.keyCode===9){
			e.preventDefault()
			if(window.view && window.view.state){
				window.view.focus()
				const state=window.view.state
				let tr=state.tr
				const from=TextSelection.atStart(state.doc).from
				const selection = new TextSelection(tr.doc.resolve(from))
   			tr.setSelection(selection)
   			window.view.dispatch(tr)
			}
		}else if(e.keyCode==40 && window.view){ //downarrow- move cursor into doc
			e.preventDefault()
			let tr = window.view.state.tr
			const selection = new TextSelection(tr.doc.resolve(2))
			tr.setSelection(selection) //position cursor at end of question body
			window.view.dispatch(tr)
			window.view.focus()
		}
	}

	toggleShowMessagePanel(){
		updateMessagePanelVisibility(this.props.docId,!this.props.messagePanelIsVisible)
	}

	shouldForceOpenMessagePanel(){
	//	console.log('check if should force open!!!')
		// //if there are unreads then lets open it!!!
		// const notificationCount=calculateDocNotificationCount(this.props.doc.doc_id,this.props.doc)
		// console.log(`notificationCount ---- ${notificationCount}`)
		// if(notificationCount){
		// 	return true
		// }
		// else{
			return false
		// //}
		
	}

	openRecordingModal(){
		this.setState({showRecordingPlaybackModal:true})
	}
	////
	/// Doc types
	// Private (doc.team==null && doc.project==null) - don't show message panel, show UI to move to project or team
	// Project Doc (doc.project==X && doc.is_project_doc==true)- parent doc, show TLDR and project sub docs
	// Project Sub Doc (doc.project==X && doc.is_project_doc==false)- Manual followers stuff
	// Team Doc (doc.team==X)
	//docType= teamDoc || projectRootDoc || projectSubDoc || privateDoc

	//March 9th new docType roadmapDoc

	render(){	
		const {showEditorOutputPanel,showHoverMessagePanel,docTitle,showStashedPanel}=this.state
		const {docName, doc, scrollTop,sideMenuVisible,docId,docType,isNewDocThread,threadParentMessage,meeting,showUnreadsIndicatorDocChatBtn}=this.props			
		const {messagePanelIsVisible} = this.props
		//console.log(`message panel is visible----- ${messagePanelIsVisible}`)


		let hasMessagePanel = true 
		if(docType==='privateDoc'){
			hasMessagePanel=false
		}
		let messagePanelShown = hasMessagePanel && messagePanelIsVisible
		if(hasMessagePanel && !messagePanelIsVisible){
			//check if should force it open e.g. unread messages
			const forceOpen=this.shouldForceOpenMessagePanel()
			if(forceOpen){
				// console.log('force open!!')
				messagePanelShown=true
			}
		}

		const showInviteButton = !hasMessagePanel // show invite button in top menu if private doc with no conversations
		const docInputValue=this.getTitleInputValue()	
		let recentActivityItem
		if(this.props.docId){
			recentActivityItem= find(this.props.recentActivities,function(activity){
				return `${activity['item_id']}`==`${docId}`
			})
		}
		const showSummarizerProto = false
		const showTldrProto = true
		const showProjectDocsProto = true
		const showLinearProjectTopMenu = true
		const showProjectEmojiProto = true
		let projectDoc=false
		let projectSubDocs=[]
		let projectMilestoneDocs=[]
		let projectId

		if(doc && doc.is_project_doc && doc.project){
			projectDoc = true
			projectSubDocs=getProjectSubDocs(doc.project)			
			projectMilestoneDocs=getProjectMilestoneDocs(doc.project)	
		}

		if(doc && doc.project){
			projectId = doc.project
		}

		const showThreadGutter = true
		let isMilestoneDoc 
		if(doc && doc.milestone){
			isMilestoneDoc=true
		}


		let meetingDoc = false
		let showMeetingTemplateSelection = false
		let recordingId
		let recording
		let meetingType
		let isGCalEvent = false
		if(doc && doc.meeting){
			meetingDoc=true
			if(meeting){		
				if(meeting.type){
					meetingType = meeting.type
				}
				if(meeting.event_id){
					isGCalEvent = true
				}
				// if(!doc.template){
				// 	showMeetingTemplateSelection = true
				// }
				recording=getRecordingForMeeting(meeting.meeting_id)
				if(recording){
					recordingId=recording.recording_id				
				}				
			}
		}

		const GCalTeamSync = meetingDoc && isGCalEvent && meetingType && meetingType === 'teamSync'
		const discoveryMeeting = meetingDoc && meetingType && meetingType === 'discovery'
		const showRecordingIndicator = discoveryMeeting && recording

		let showTemplateSelection = this.props.docIsEmpty
		if(this.props.doc && (this.props.doc.template)){
			showTemplateSelection=false
		}
		//if(!this.props.doc ){
		if(!this.props.doc || docType === 'privateDoc' || (docType=='projectSubDoc' && !isMilestoneDoc)){
			showTemplateSelection=false
		}

		const rightPanelVisible = messagePanelShown || this.props.issuePanelIsVisible

		return (
			<React.Fragment>		

				<div className={'docEditor ' + (rightPanelVisible ? ' docEditor--messagePanel--visible ' : ' docEditor--messagePanel--hidden ') + (this.props.figmaModalIsZoomed ? ' docEditor--figmaModalZoomed ' : ' ')}>
					<DocImageZoomedModal
						sendMediaModalMessage={this.props.sendMediaModalMessage}
					/>

					<WhiteboardModal
						showModal={this.props.whiteboardModalIsVisible}
						whiteboardModalEmbedId={this.props.whiteboardModalEmbedId}
						theme={this.props.theme}
						whiteboardSettings={this.props.whiteboardSettings}
					/>							



					{projectDoc &&
						<EditProjectModal 
							showModal={this.state.showEditProjectModal}
							openModal={()=>{this.setState({showEditProjectModal: true})}}
							closeModal={()=>{this.setState({showEditProjectModal: false,editProjectModalFocusField:null})}}
							projectId={projectId}			
							editProjectModalFocusField={this.state.editProjectModalFocusField}			
						/>
					}
					<DocProjectMentionsSheet
						showProjectMentionsSheet={this.state.showProjectMentionsSheet}
						closeProjectMentionsSheet={()=>{this.setState({showProjectMentionsSheet: false})}}
					/>
					{/************** headers ******************/}			
					{docType === 'projectRootDoc' &&
						<DocEditorProjectRootDocTopMenu	
							sideMenuVisible={sideMenuVisible}							
							toggleIssuePanel={this.toggleIssuePanel}
							showIssuePanel={this.props.issuePanelIsVisible}
							docName={docName}
							doc={doc}
							docId={docId}
							projectId={doc.project}
							duplicateDoc={this.duplicateDoc}

							showEditProjectModal={()=>{this.setState({showEditProjectModal: true})}}
							toggleShowMessagePanel={this.toggleShowMessagePanel}	
							showUnreadsIndicatorDocChatBtn={showUnreadsIndicatorDocChatBtn}
							messagePanelIsVisible={this.props.messagePanelIsVisible}

							showEditProjectModal={()=>{this.setState({showEditProjectModal: true})}}							

						/>								
					}
					{doc && doc.project && docType === 'projectSubDoc' &&
						<DocEditorProjectSubDocTopMenu	
							sideMenuVisible={sideMenuVisible}							
							docName={docName}
							doc={doc}
							docId={docId}
							projectId={doc.project}
							showMoveDocPopover={this.state.showMoveDocPopover}
							toggleIssuePanel={this.toggleIssuePanel}
							showIssuePanel={this.props.issuePanelIsVisible}
							setShowMoveDocPopover={(value)=>{this.setState({showMoveDocPopover:value})}}							
							duplicateDoc={this.duplicateDoc}
							toggleShowMessagePanel={this.toggleShowMessagePanel}	
							showUnreadsIndicatorDocChatBtn={showUnreadsIndicatorDocChatBtn}
							messagePanelIsVisible={this.props.messagePanelIsVisible}
						/>
					}			
					{doc && doc.roadmap && docType === 'roadmapDoc' &&
						<DocEditorRoadmapDocTopMenu	
							sideMenuVisible={sideMenuVisible}							
							docName={docName}
							docId={this.props.docId}
							roadmapId={doc.roadmap}
							duplicateDoc={this.duplicateDoc}
							toggleShowMessagePanel={this.toggleShowMessagePanel}	
							showUnreadsIndicatorDocChatBtn={showUnreadsIndicatorDocChatBtn}
							messagePanelIsVisible={this.props.messagePanelIsVisible}
							duplicateDoc={this.duplicateDoc}							
						/>								
					}	
					{doc && docType === 'orgDoc' &&
						<DocEditorOrgDocTopMenu	
							sideMenuVisible={sideMenuVisible}							
							docName={docName}
							docId={this.props.docId}
							duplicateDoc={this.duplicateDoc}
							showUnreadsIndicatorDocChatBtn={showUnreadsIndicatorDocChatBtn}
							//showUnreadsIndicatorDocChatBtn={true}
							toggleShowMessagePanel={this.toggleShowMessagePanel}	
							//showUnreadsIndicatorDocChatBtn={false}
							messagePanelIsVisible={this.props.messagePanelIsVisible}
						/>								
					}					
					{docType === 'privateDoc' && !meetingDoc && // remove !meetingDoc
						<DocEditorPrivateDocTopMenu	
							sideMenuVisible={sideMenuVisible}							
							docName={docName}								
							projects={this.props.projects}
							docId={this.props.docId}
							showDocTitle={scrollTop && scrollTop > 120}
							duplicateDoc={this.duplicateDoc}							
						/>								
					}
					{meetingDoc && meeting &&
						<DocEditorMeetingDocTopMenu	
							docId={this.props.docId}
							showDocTitle={scrollTop && scrollTop > 120}
							showMeetingInfo={scrollTop && scrollTop > 60}
							docName={doc.name}				
							meeting={meeting}				
							toggleShowMessagePanel={this.toggleShowMessagePanel}	
							showUnreadsIndicatorDocChatBtn={showUnreadsIndicatorDocChatBtn}
							messagePanelIsVisible={this.props.messagePanelIsVisible}
							toggleIssuePanel={this.toggleIssuePanel}
							showIssuePanel={this.props.issuePanelIsVisible}										
														
						/>								
					}			

					{this.props.docSearchResults && this.props.docSearchResults.searchActive &&
						<DocEditorInDocSearch 
							docSearchResults={this.props.docSearchResults}
						/>
					}

					<div id='scrollable_div' onScroll={this.props.onScroll} className='docEditor-docOuterContainer' >
						<div id='docEdge_div' className={'docEditor-docInnerContainer ' + (showTemplateSelection ? ' docEditor-docInnerContainer--showTemplate ' : '')}>
							{docType === 'projectRootDoc' &&
								<div className='docEditor-projectMarkerTopSpacer' />	
							}
							{meetingDoc && meeting &&
								<DocEditorMeetingInfo
									meeting={meeting}	
									highlights={this.props.highlights}
									docId={this.props.docId}
									openRecordingModal={this.openRecordingModal}

								/>
							}
							{projectDoc && 								
								<div className='docEditor-docTitle'>							
									<DocEditorProjectIcon
										projectId={doc.project}
									/>
									<DocEditorProjectTitle
										projectId={doc.project}
										openEditProjectModal={()=>{this.setState({showEditProjectModal: true,editProjectModalFocusField:'title'})}}
									/>
								</div>
							}

							{isMilestoneDoc && 								
								<div className='docEditor-docTitle'>																							
									<DocEditorMilestoneTitle
										milestoneId={doc.milestone}
									/>
								</div>
							}
							{GCalTeamSync &&
								<div className='docEditor-docTitle'>																							
									<DocEditorGCalTeamSyncTitle 
										meeting={meeting}
										docName={docName}
									/>
								</div>
							}
							
							{!projectDoc && !isMilestoneDoc && !GCalTeamSync &&
								<div className={'docEditor-docTitle ' + (showRecordingIndicator ? ' docEditor-docTitle--showRecordingIndicator ' : '')}>
									{showRecordingIndicator &&
										<DocEditorTitleRecordingIndicator 
											openRecordingModal={this.openRecordingModal}
											recording={recording}
										/>
									}
									<textarea 
										className={'docEditor-docTitle-input ' + (docType === 'projectRootDoc' ? ' docEditor-docTitle-input--projectRootDoc ' : '')}
										value={docInputValue}
										onKeyDown={this.handleTitleInputKeyDown}
										onChange={this.handleTitleInputOnChange}
										placeholder={meetingDoc ? 'Untitled Meeting' : 'Untitled Doc'}
										id={'titleInput'}
										autoComplete="off"
										autoCorrect="off"

										spellCheck={false}										
										disabled={isGCalEvent || docType === 'projectRootDoc'}
									/>																
									<div className='docEditor-docTitle-ghost'>																			
										{docInputValue}
									</div>	
									<button onClick={this.onClickFocusTrap} className='docEditor-docTitle-focusTrap' />
								</div>
							}				

							{!projectDoc && !meetingDoc &&
								<DocSaveStatus updatedAtTimestamp={this.props.updatedAtTimestamp}/>							
							}
							{showSummarizerProto && 
								<DocSummarizerProto 
									docId={this.props.docId}
									doc={this.props.doc}
								/>
							}
							{projectDoc &&
								<DocEditorProjectDescription
									docName={docName}
									openEditProjectModal={()=>{this.setState({showEditProjectModal: true,editProjectModalFocusField:'description'})}}
									projectDocProjectId={projectId}
								/>
							}	
							{meetingDoc && meeting &&
								<DocEditorMeetingContent
									meeting={meeting}	
									projectConnections={this.props.projectConnections}
									highlights={this.props.highlights}
									recording={recording}
									openThread={this.props.setThreadParentMessage}
									openRecordingModal={this.openRecordingModal}		
									openIssuePanel={this.openIssuePanel}		
									docMessages={this.props.docMessages}		
									issuePanelIsVisible={this.props.issuePanelIsVisible}
									issuePanelProject={this.props.issuePanelProject}
									closeIssuePanel={this.closeIssuePanel}
								/>
							}							

							{doc && docType === 'projectRootDoc' &&
								<DocEditorProjectContentTabs
									// for sub docs table
									docName={docName}
									projectSubDocs={projectSubDocs}
									projectMilestoneDocs={projectMilestoneDocs}
									doc={doc}
									hasDescription={doc.description ? true : false}
									openProjectMentionsSheet={()=>{this.setState({showProjectMentionsSheet: true})}}
									showProjectMentionsSheet={this.state.showProjectMentionsSheet}
								/>
							}
							{showThreadGutter && 
								<DocThreadGutter 
									docId={this.props.docId}
									messageSelectionPluginKey={this.props.messageSelectionPluginKey}
									messages={filter(this.props.messages,{parent_doc:this.props.docId})}
									openThread={this.props.setThreadParentMessage}
									activePanelThreadMessage={threadParentMessage? find(this.props.messages,{message_id:threadParentMessage}):null}
									setActiveMessageSelection={this.props.setActiveMessageSelection}
									recentActivities={this.props.recentActivities}
									recentActivityItem={recentActivityItem}
									threadParentMessage={this.props.threadParentMessage}
									reactions={this.props.reactions}
								/>
							}


{/*							{showMeetingTemplateSelection &&
								<DocEditorMeetingTemplateSelection
									meeting={meeting}
								/> 
							}
							*/}

							{showTemplateSelection &&
								<DocEditorTemplateSelection
									meeting={meeting}
									docId={this.props.docId}
									docType={docType}
									isProjectDoc={projectDoc}
									templateModalVisible={this.props.templateModalVisible}
								/>

							}


							{/*<div id='editor' />  PROSEMIRROR CONTENTEDITABLE WRAPPER */}
						<div id='editor'>
							{/*
							<DocEditorFakePlaceholder docId={docId}/>
							*/}
						</div> {/* PROSEMIRROR CONTENTEDITABLE WRAPPER */}
						</div>



						<div id="hoverMenu" className='hoverMenu hoverMenu--hidden'>
							<DocSelectionMenu
								newSelectionMessage={this.props.newSelectionMessage}
								isPrivateDoc={docType == 'privateDoc'}
								menuItems={this.props.menuItems}
							/>
						</div>
						<div id="textLinkHoverMenu" className='doc-textLink-newPopoverAnchor'>
						
						</div>
				</div>
				{showEditorOutputPanel &&
				<div className='docEditor-docRightMarginSpacer'>
					<DocEditorDevOutputPanel	
						showEditorOutputPanel={showEditorOutputPanel}						
						pmDoc={this.props.pmDoc}
					/>
				</div>
				}

				{this.state.showHTMLOutputPanel &&
				<div className='docEditor-docRightMarginSpacer'>
					<DocEditorDevHTMLPanel						
						pmDoc={this.props.pmDoc}
					/>
				</div>
				}
			</div>
			{showStashedPanel &&
				<DocStashedPanel 
					showStashedPanel={showStashedPanel}
					closeStashedPanel={()=>{this.setState({showStashedPanel: false})}}
				/>			
			}
			{this.props.issuePanelIsVisible && doc &&
				<DocIssuePanel
					project={doc.project}
					issuePanelProject={this.props.issuePanelProject}
					issuePanelUser={this.props.issuePanelUser}
					meeting={meeting}
					milestoneId={doc.milestone}
					closeIssuePanel={this.closeIssuePanel}
					issues={this.props.issues}
					independentLayout={!messagePanelShown}
				/>			
			}
			{messagePanelShown &&				
					<MessagePanel
						sendDocMessage={this.props.sendDocMessage}
						messages={this.props.messages}
						docTitle={docName}
						docType={docType}
						projectId={projectId}
						orgMembers={this.props.orgMembers}
						sendUserTypingEvent={this.props.sendUserTypingEvent}
						docId={this.props.docId}
						userId={this.props.userId}
						userTyping={this.props.userTyping}
						deleteMessage={this.props.deleteMessage}
						orgId={this.props.orgId}
						currentUser={this.props.user}
						messageTextSnapshot={this.props.messageTextSnapshot}
						messageSelectionId={this.props.messageSelectionId}						
						clearMessageSelection={this.props.clearMessageSelection}
						newMessageParentNodeType={this.props.newMessageParentNodeType}
						newMessageParentNodeSnapshot={this.props.newMessageParentNodeSnapshot}
						recentActivityItem={recentActivityItem}
						deleteMessageSelection={this.props.deleteMessageSelection}
						setActiveMessageSelection={this.props.setActiveMessageSelection}
						addReactionToMessage={this.props.addReactionToMessage}
						reactions={this.props.reactions}
						deleteReaction={this.props.deleteReaction}
						newMessageParentMessage={this.props.newMessageParentMessage}
						setNewMessageParentMessage={this.props.setNewMessageParentMessage}
						uploadImageToCloudinary={this.props.uploadImageToCloudinary}
						showMultiTabPrototype={this.props.docName === 'Command Menu Context'}		
						updateRecentActivity={this.props.updateRecentActivity}
						followers={this.props.doc?this.props.doc.followers:[]}
						updateRecentActivity={this.props.updateRecentActivity}
						updateThreadRecentActivity={this.props.updateThreadRecentActivity}
						recentActivities={this.props.recentActivities}
						threadId={this.props.threadId}
						setThreadParentMessage={this.props.setThreadParentMessage}
						threadParentMessage={this.props.threadParentMessage}
						isNewDocThread={isNewDocThread}
						cancelNewDocThread={this.props.cancelNewDocThread}
						messageSelectionPluginKey={this.props.messageSelectionPluginKey}
						openRecordingModal={this.openRecordingModal}
						toggleShowMessagePanel={this.toggleShowMessagePanel}	
						updateRecentActivity={this.props.updateRecentActivity}
					/>				
			}

			{this.state.showRecordingPlaybackModal &&
				<RecordingPlaybackModalContainer 
					recordingId={recordingId}
					meeting={meeting}
					recording={recording}
					doc={doc}
					showModal={this.state.showRecordingPlaybackModal}
					openModal={()=>{this.setState({showRecordingPlaybackModal: true})}}
					closeModal={()=>{this.setState({showRecordingPlaybackModal: false})}}
				/>
			}
			</React.Fragment>
		)
	}
}

export default withRouter(DocEditor)