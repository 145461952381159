import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import {formatDate} from '../../../utils/formatDate'
import {getUserForId} from '../../../utils/getUserForId'
import filter from 'lodash/filter'


class MessageOpenThreadButton extends React.Component{  

	render(){
		const {threadChildMessages,messageId,isInDocThread}=this.props 

		let messageCount
		if(threadChildMessages){
			messageCount = threadChildMessages.length
		}

		let messageCountLabel
		// this is a new comment thread with no replies yet
		if(messageCount === 0){
			messageCountLabel = 'Go to thread'
		}
		if(messageCount === 1){
			messageCountLabel = '1 reply'
		}
		if(messageCount > 1){
			messageCountLabel = `${messageCount} replies`
		}
		

		const unreadCount = filter(threadChildMessages,{isUnread:true}).length // number of messages new since user last visited thread?
		let hasUnreads = false

		if(unreadCount > 0){
			hasUnreads = true
		}
		let mostRecentChildMessageTimestampLabel
		if(messageCount>0){
			mostRecentChildMessageTimestampLabel = formatDate(threadChildMessages[threadChildMessages.length-1]['created_at'])
		}

		let threadChildrenAuthors=[]
		threadChildMessages.forEach((message)=>{
			const author=message['created_by']
			let index = threadChildrenAuthors.findIndex(user => {
				return user == author
			})
			if(index==-1){
				threadChildrenAuthors.push(author)
			}
		})


	
		return (
			<div className='message-openThreadBtnContainer'>
				<button onClick={this.props.openThread} className={'message-openThreadBtn' + (hasUnreads ? ' message-openThreadBtn--hasUnreads' : '') + (messageCount === 0 ? ' message-openThreadBtn--newThreadBtn ' : '')}>
					
					{hasUnreads &&
						<div className='message-openThreadBtn-unreadMarkerContainer'>
							<div className='message-openThreadBtn-unreadMarker' />
						</div>
					}
					<div className='message-openThreadBtn-avatarRow'>


						{/* 
								For who's avatar image to show, I'd guess something like...								
								3 people who most recently posted
								With most recent at the end								
								All uniques (i.e. don't show same avatar multiple times)
						*/}

						{threadChildrenAuthors.length > 0 && threadChildrenAuthors.map((userId,i)=>{
							const userObj=getUserForId(userId)
							if(userObj){
								return(
									<div key={`${messageId}-i-${userId}`} className='message-openThreadBtn-avatarContainer'> 
										<AvatarImage 
											key={userId}
											avatarUrl={userObj.avatar}
											className='message-openThreadBtn-avatar'
										/>
									</div>
									)
							}
						})}
					
					</div>

					<div className='message-openThreadBtn-labelRow'>
						
						{hasUnreads &&
							<div className='message-openThreadBtn-unreadCount'>
								{unreadCount} unread
							</div>
						}

						{!hasUnreads &&
							<div className='message-openThreadBtn-messageCount'>
								{messageCountLabel}
							</div>
						}
						{mostRecentChildMessageTimestampLabel &&
						<div className='message-openThreadBtn-timestampLabel'>
							{mostRecentChildMessageTimestampLabel}
						</div>
					}
					</div>

					<div className='message-openThreadBtn-endIconContainer'>
						<Icon name='chevronRightBold' />
					</div>

					
				</button>
			</div>
		)
	}
}
 


export default MessageOpenThreadButton
