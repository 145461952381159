import React from 'react'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import {getUserForId} from '../../utils/getUserForId'
import {getCurrentUserId} from '../../utils/getCurrentUser'
import {isCurrentUser} from '../../utils/isCurrentUser'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import {deleteHighlight} from '../../utils/meetings/deleteHighlight'


function formatHighlightCreatedAtTimestamp(timestamp){
	const now = moment();
	const date = moment(timestamp)
	if (now.isSame(date, 'day')) {
		return 'Today';
	} else if (now.subtract(1, 'days').isSame(date, 'day')) {
		return 'Yesterday';
	} else {
		return date.fromNow();
	}
}

class RecordingPlaybackModalHighlightGutterMarkerPopover extends React.Component{  	

	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)  
    this.handleDeleteHighlght=this.handleDeleteHighlght.bind(this)  
    this.state={
      forceMount: false,      
    }
  }

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

  handleDeleteHighlght(e){
  	e.stopPropagation()
  	e.preventDefault()
  	deleteHighlight(this.props.highlight.highlight_id)
  }

	render(){	
		const {showPopover, openPopover, closePopover,highlight} = this.props
		const {forceMount} = this.state
		const translateX = -8		
		let highlightCreatorDisplayName
		let highlightCreatorAvatarUrl
		let highlightCreatorUserObj = getUserForId(highlight.created_by)	
		if(highlightCreatorUserObj){
			highlightCreatorDisplayName = highlightCreatorUserObj.display_name
			highlightCreatorAvatarUrl = highlightCreatorUserObj.avatar
		}

		const highlightCreatorIsCurrentUser = true

		return (
			<React.Fragment>
				<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'highlightGutterMarkerPopoverContainer ' + (showPopover ? ' highlightGutterMarkerPopoverContainer--visible ' : ' highlightGutterMarkerPopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							onInteractOutside={closePopover}							
							side="right"						
							align="start"							
							sideOffset={this.props.indentLevel > 0 ? 12 : 2}
							//alignOffset={-40}
							//collisionPadding={10}
							collisionPadding={{top: 100, left: 10, right: 10, bottom: 200}}
							onOpenAutoFocus={(e)=>{e.preventDefault()}}
							forceMount={showPopover ? true : forceMount}
							//asChild
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateX(${translateX}px)` }}
								enter={{ opacity: 1, transform: 'translateX(0px)'  }}
								leave={{ opacity: 0, transform: `translateX(${translateX}px)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 35}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="highlightGutterMarkerPopover popover forceDarkTheme"> 	
											<div className='highlightGutterMarkerPopover-description'>
												<div className='highlightGutterMarkerPopover-description-avatarContainer'>
													<AvatarImage 
						                avatarUrl={highlightCreatorAvatarUrl}
						                className='highlightGutterMarkerPopover-description-avatar'
						              />         
									  		</div>
									  		<div className='highlightGutterMarkerPopover-description-header'>
									  			<div className='highlightGutterMarkerPopover-description-header-name'>
									  				{highlightCreatorDisplayName}
									  			</div>	  			
									  			<div className='highlightGutterMarkerPopover-description-header-subtitle'>
									  				{formatHighlightCreatedAtTimestamp(highlight.created_at)}{/*2 days ago*/}
									  			</div>	  			
									  		</div>
									  		<div className='highlightGutterMarkerPopover-description-description'>
									  			<div className='message-para'>
									  				{highlight.title}
									  			</div>									  			
									  		</div>
									  		{highlightCreatorIsCurrentUser &&
										  		<button onClick={this.handleDeleteHighlght} className='highlightGutterMarkerPopover-description-deleteBtn'>
										  			Delete Highlight
										  		</button>
										  		}

									  	</div>
										
							  	</animated.div>								
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			</React.Fragment>
		)
	}
}

export default RecordingPlaybackModalHighlightGutterMarkerPopover