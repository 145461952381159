import {findWrapping, liftTarget, canSplit, ReplaceAroundStep, canJoin} from "prosemirror-transform"
import {Command, EditorState, Transaction, NodeSelection, Selection} from "prosemirror-state"
import {getCurrentUserAsAssigneeObj} from '../toDos/getCurrentUserAsAssigneeObj'
import schema from '../../schema/editorSchema'  

//https://prosemirror.net/docs/ref/#transform.Transform.split
//https://github.com/ProseMirror/prosemirror-schema-list/blob/master/src/schema-list.ts

/// Build a command that splits a non-empty textblock at the top level
/// of a list item by also splitting that list item.

//TODO dont do this is plugin menu is open
//pass in plugin keys so can access state and check if active
export function splitBulletListCommand(itemType,insertPluginKey,mentionPluginKey) {
  return function(state, dispatch) {
    const insertMenuState=insertPluginKey.getState(state)
    const mentionMenuState=mentionPluginKey.getState(state)
    if(insertMenuState.active || mentionMenuState.active) return false


    let {$from, $to, node,from} = state.selection
    if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) return false
    let grandParent = $from.node(-1)
    if (grandParent.type != itemType) return false
    if ($from.parent.content.size == 0 && $from.node(-1).childCount == $from.indexAfter(-1)) {
      //if empty block then convert into a normal paragraph 
      if ($from.depth == 3 || $from.node(-3).type != itemType ||
          $from.index(-2) != $from.node(-2).childCount - 1) return false
      if (dispatch) {
        let wrap = Fragment.empty
        let depthBefore = $from.index(-1) ? 1 : $from.index(-2) ? 2 : 3
        // Build a fragment containing empty versions of the structure
        // from the outer list item to the parent node of the cursor
        for (let d = $from.depth - depthBefore; d >= $from.depth - 3; d--)
          wrap = Fragment.from($from.node(d).copy(wrap))
        let depthAfter = $from.indexAfter(-1) < $from.node(-2).childCount ? 1
            : $from.indexAfter(-2) < $from.node(-3).childCount ? 2 : 3
        // Add a second list item with an empty default start node
        wrap = wrap.append(Fragment.from(itemType.createAndFill()))
        let start = $from.before($from.depth - (depthBefore - 1))
        let tr = state.tr.replace(start, $from.after(-depthAfter), new Slice(wrap, 4 - depthBefore, 0))
        let sel = -1
        tr.doc.nodesBetween(start, tr.doc.content.size, (node, pos) => {
          if (sel > -1) return false
          if (node.isTextblock && node.content.size == 0) sel = pos + 1
        })
        if (sel > -1) tr.setSelection(Selection.near(tr.doc.resolve(sel)))
        dispatch(tr.scrollIntoView())
      }
      return true
    }
    //not empty-->split
    let nextType = $to.pos == $from.end() ? grandParent.contentMatchAt(0).defaultType : null
   // const listNode=$from.node(2)
    let listNode = null;
    let depth = $from.depth;
    while (depth > 0) {
      const node = $from.node(depth);
      if (node.type === itemType) {
        listNode = node;
        break;
      }
      depth--;
    }
    let attrs={}
    let indentLevel=0
    if(listNode){
      attrs=listNode.attrs
      indentLevel=listNode.attrs.indentLevel
    }
    let tr = state.tr.delete($from.pos, $to.pos)
    
    //TODO reset assignees when split todo- maybe want different behavior when split from beinging middle and end
    //if cursor is at the begining of TODO take the assignment with you to the new one

    if(itemType.name=='toDoItem' && $from.parentOffset==0){ //cursor is at the begining of toDO-->take assignment with you
      let types = nextType && [{type: itemType,attrs:{...attrs,isChecked:false}}, {type: nextType,attrs:{indentLevel:indentLevel}}]
      if (!types){
        types = [{type: itemType,attrs:{...attrs}}, null]
      }
      if(!canSplit(tr.doc, $from.pos, 2, types)){
        return false
      }
      // if(dispatch){ //old
      //   tr.setNodeMarkup($from.pos-2, null, {...grandParent.attrs,assignees:[],isChecked:false})
      //   tr.split($from.pos, 2, types).scrollIntoView()
      //   dispatch(tr)
      //   return true
      // } 
      if(dispatch){ //April 21 create new nodes instead of split to prevent flash of checked/unchecked
        //let try inserting a new node above instead of split
        const newTodoNode = schema.nodes.toDoItem.createAndFill({...grandParent.attrs,assignees:[],isChecked:false})

        tr.insert($from.pos-2,newTodoNode)
        //tr.setNodeMarkup($from.pos-2, null, {...grandParent.attrs,assignees:[],isChecked:false})
        //tr.split($from.pos, 2, types).scrollIntoView()
        dispatch(tr)
        return true
      }      
    }
    else{
      //let types = nextType ? [null, {type: nextType}] : undefined --origional.  We updated this so we can set some none default attrs
      let types = nextType && [{type: itemType,attrs:{...attrs,isChecked:false,assignees:[]}}, {type: nextType,attrs:{indentLevel:indentLevel}}]
      if (!types) types = [{type: itemType,attrs:{...attrs,isChecked:false,assignees:[]}}, null]
      if (!canSplit(tr.doc, $from.pos, 2, types)) return false
      if (dispatch) dispatch(tr.split($from.pos, 2, types).scrollIntoView())
      return true
    }
  }
}


      //TODO maybe bring accross indent level
      // In an empty block. If this is a nested list, the wrapping
      // list item should be split. Otherwise, bail out and let next
      // command handle lifting.

// export function splitBulletListCommand(itemType: NodeType): Command {
//   return function(state: EditorState, dispatch?: (tr: Transaction) => void) {
//     let {$from, $to, node} = state.selection as NodeSelection
//     if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) return false
//     let grandParent = $from.node(-1)
//     if (grandParent.type != itemType) return false
//     if ($from.parent.content.size == 0 && $from.node(-1).childCount == $from.indexAfter(-1)) {
//       // In an empty block. If this is a nested list, the wrapping
//       // list item should be split. Otherwise, bail out and let next
//       // command handle lifting.
//       if ($from.depth == 3 || $from.node(-3).type != itemType ||
//           $from.index(-2) != $from.node(-2).childCount - 1) return false
//       if (dispatch) {
//         let wrap = Fragment.empty
//         let depthBefore = $from.index(-1) ? 1 : $from.index(-2) ? 2 : 3
//         // Build a fragment containing empty versions of the structure
//         // from the outer list item to the parent node of the cursor
//         for (let d = $from.depth - depthBefore; d >= $from.depth - 3; d--)
//           wrap = Fragment.from($from.node(d).copy(wrap))
//         let depthAfter = $from.indexAfter(-1) < $from.node(-2).childCount ? 1
//             : $from.indexAfter(-2) < $from.node(-3).childCount ? 2 : 3
//         // Add a second list item with an empty default start node
//         wrap = wrap.append(Fragment.from(itemType.createAndFill()))
//         let start = $from.before($from.depth - (depthBefore - 1))
//         let tr = state.tr.replace(start, $from.after(-depthAfter), new Slice(wrap, 4 - depthBefore, 0))
//         let sel = -1
//         tr.doc.nodesBetween(start, tr.doc.content.size, (node, pos) => {
//           if (sel > -1) return false
//           if (node.isTextblock && node.content.size == 0) sel = pos + 1
//         })
//         if (sel > -1) tr.setSelection(Selection.near(tr.doc.resolve(sel)))
//         dispatch(tr.scrollIntoView())
//       }
//       return true
//     }
//     let nextType = $to.pos == $from.end() ? grandParent.contentMatchAt(0).defaultType : null
//     let tr = state.tr.delete($from.pos, $to.pos)
//     let types = nextType ? [null, {type: nextType}] : undefined
//     if (!canSplit(tr.doc, $from.pos, 2, types)) return false
//     if (dispatch) dispatch(tr.split($from.pos, 2, types).scrollIntoView())
//     return true
//   }
// }
