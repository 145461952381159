//
// Divider between message in a panel
// Can have optional "unreads" to show red line with "new" label
//

import React from 'react'
import Icon from '../../misc/Icon'
import {formatDateLong} from '../../../utils/formatDateLong'

class MessagePanelDivider extends React.Component{  
	
	render(){	

		const {unreads, dateDivider,date} = this.props



		//const dateLabel = "Sunday, September 17"
		const dateLabel=formatDateLong(date)
	
		return (	  				
			<div className={'docEditor-messagePanel-divider ' + (unreads ? 'docEditor-messagePanel-divider--unreads' : '') + (dateDivider ? 'docEditor-messagePanel-divider--date' : '')}>
				<div className='docEditor-messagePanel-divider-line' />

				{unreads &&
					<div className='docEditor-messagePanel-divider-label docEditor-messagePanel-divider-label--new'>
						New
					</div>
				}			

				{dateDivider &&
					<React.Fragment>
						<div className='docEditor-messagePanel-divider-label docEditor-messagePanel-divider-label--date'>
							{dateLabel}
						</div>
						<div className='docEditor-messagePanel-divider-line' />
					</React.Fragment>
				}			
					
			</div>				
		)
	}
} 



export default MessagePanelDivider
