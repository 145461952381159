import schema from '../schema/editorSchema'  
import {TextSelection} from "prosemirror-state"
import {joinPoint, canJoin, findWrapping, liftTarget, canSplit,
        ReplaceStep, ReplaceAroundStep, replaceStep} from "prosemirror-transform"
import {wrapIn,lift,setBlockType,createParagraphNear,chainCommands} from 'prosemirror-commands'
import {getAnchorNode} from './getAnchorNode'

//https://prosemirror.net/docs/ref/#commands.createParagraphNear

export function insertContentBoxCommand() {
	return function(state, dispatch) {
		const nodeType=schema.nodes.contentBox
    let {$from, $to} = state.selection
    let range = $from.blockRange($to), wrapping = range && findWrapping(range, nodeType)
    //check not already in a content box
    const anchorNode=getAnchorNode($from,'contentBox')
    if(anchorNode){
    	return removeContentBoxCommand()(state,dispatch)
    }

    if (!wrapping){
    	return false
    }
    else 
    	if (dispatch){
    		let tr=state.tr
    		tr.wrap(range, wrapping).scrollIntoView()
    		dispatch(tr)
    }
    return true
  }
}

export function removeContentBoxCommand() {
	return function(state, dispatch) {
		let {$from, $to} = state.selection
  	let range = $from.blockRange($to), target = range && liftTarget(range)
  	if (target == null){
  		return false
  	}else{
  		if (dispatch){
  			dispatch(state.tr.lift(range, target).scrollIntoView())
  		}
 			 return true
  	}
  	

  }
}



export function insertContentBox() {
 const state=window.view.state
 let insertPos=state.selection.from
 let tr=state.tr
 let {$from, $to} = state.selection
 let content=tr.doc.slice($from.pos,$to.pos)
 const paragraphNode=schema.nodes.paragraph.create(null,content.content)

 const node=schema.nodes.contentBox.create(null,paragraphNode)
 tr.replaceWith(state.selection.from,state.selection.to,node)
 let selection=new TextSelection(tr.doc.resolve(state.selection.from+node.nodeSize-1))
 tr.setSelection(selection)



 window.view.dispatch(tr)
}



// export function insertContentBox(state,dispatch) {


// 	//if is in the last paragraph need to add a new paragraph at the end of the doc

// //	chainCommands(createParagraphNear()(editorView.state, editorView.dispatch, editorView),wrapIn(schema.nodes.contentBox)(editorView.state, editorView.dispatch, editorView))


// 	//chainCommands(createParagraphNear(state,dispatch)

// 	//(editorView.state, editorView.dispatch, editorView)


// 	wrapIn(schema.nodes.contentBox)
// 	return true

// 	//(editorView.state, editorView.dispatch, editorView)
// }





export function makeParagraph() {
		const editorView=window.view
	//liftParagraph()
		lift(editorView.state, editorView.dispatch, editorView)
	// const editorView=window.view
	// console.log(editorView)
	// // let {$from, $to} = state.selection
	// // const mark=schema.marks[markType]
	// // let tr=state.tr
	// //tr.toggleMark(state.selection.from,state.selection.to,mark.create())
	// //	tr.toggleMark(state.selection.from,state.selection.to,mark.create())

	// console.log('make paragraph!!!!')
	// console.log(schema.nodes)
	// lift(schema.nodes.paragraph)(editorView.state, editorView.dispatch, editorView)
	// //(editorView.state, editorView.dispatch, editorView)

	//(editorView.state, editorView.dispatch, editorView)
}


export function liftParagraph() {
	console.log('lift!!!')
	const editorView=window.view
	const state=editorView.state
	const dispatch=editorView.dispatch
  const range = getRangeForType(state, state.schema.nodes.paragraph)
  if (!range) return false

  const target = liftTarget(range)
  if (!target) return false

  if (dispatch) {
    dispatch(state.tr.lift(range, target)) //dont lift lists
  }
  return true
}



// export function blockTypeItem(nodeType: NodeType) {
//   let command = setBlockType(nodeType, options.attrs)
//   let passedOptions: MenuItemSpec = {
//     run: command,
//     enable(state) { return command(state) },
//     active(state) {
//       let {$from, to, node} = state.selection as NodeSelection
//       if (node) return node.hasMarkup(nodeType, options.attrs)
//       return to <= $from.end() && $from.parent.hasMarkup(nodeType, options.attrs)
//     }
//   }
//   for (let prop in options) (passedOptions as any)[prop] = (options as any)[prop]
//   return new MenuItem(passedOptions)
// }




// export function insertContentBox() {
// 	const state=window.view.state
// 	let insertPos=state.selection.from
// 	let tr=state.tr
// 	let {$from, $to} = state.selection
// 	let content=tr.doc.slice($from.pos,$to.pos)
// 	const paragraphNode=schema.nodes.paragraph.create(null,content.content)

// 	const node=schema.nodes.contentBox.create(null,paragraphNode)
// 	tr.replaceWith(state.selection.from,state.selection.to,node)
// 	let selection=new TextSelection(tr.doc.resolve(state.selection.from+node.nodeSize-1))
// 	tr.setSelection(selection)



// 	window.view.dispatch(tr)
// }



