



function getInitialState(){
	const meetingsPageSettings = JSON.parse(localStorage.getItem('meetingsPageSettings'))
	if(meetingsPageSettings){
		return meetingsPageSettings
	}else{
		return {}
	}

}



export default function (state = getInitialState(), action) {
	switch (action.type) {

	case 'HIDE_INTEGRATION_BANNER':
		let newState=Object.assign({},state)
		newState.banner={
			dismissed:true
		}
		localStorage.setItem('meetingsPageSettings',JSON.stringify(newState))
		return newState


	case 'HIDE_CAL_EVENT':
		let updatedState=Object.assign({},state)
		updatedState[action.id]={
			dismissed:true
		}
		localStorage.setItem('meetingsPageSettings',JSON.stringify(updatedState))
		return updatedState
	
	default:
		return state
	}
}

