import store from '../store'
import find from 'lodash/find'


function isCurrentUser(userId) {	
	const state = store.getState()
	const currentUser=state.user
	if(currentUser && userId===currentUser['user_id']){
		return true
	} else return false 
}


export function getUserName(userId) {	
	let name
	if(isCurrentUser(userId)){
		name='You'
	}else{
		const state = store.getState()
		const user= find(state.orgMembers, {'user_id':userId})
		if(user){
			name=user.display_name
		}
	}
	return name
}

