import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import orderBy from 'lodash/orderBy'
import LibraryPage from '../components/library/LibraryPage'
import moment from 'moment'
import ReactGA from 'react-ga4'

//Library shows all docs

class LibraryContainer extends Component {
	componentDidMount(){
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Team Docs" });
		}
	}

	render() {
		return (				
				<div className={'allDocsPageContainer '  + (this.props.sideMenuVisible ? ' allDocsPageContainer--sideMenuState--visible ' : ' allDocsPageContainer--sideMenuState--hidden ') + (window.isElectron ? 'allDocsPageContainer--electron' : 'allDocsPageContainer--web')}>
					<LibraryPage
						docs={this.props.docs}
						organization={this.props.organization}
						orgMembers={this.props.orgMembers}
					/>		
				</div>				
			)		
	}
}

function mapStateToProps(state,ownProps) {
	const orgDocs=filter(state.docs,{is_org_doc:true})

	// const sortedOrgDocs=sortBy(orgDocs,function(doc){
	// 		return doc["updated_at"]
	// 	}).reverse()


	const sortedDocs= orderBy(orgDocs, [function (doc) {
		// console.log(doc.updated_at)
		const momentTime=moment(doc.updated_at)
		const rounded=momentTime.startOf('minute')
		// console.log(rounded.toString())
		return (rounded)
      }, function (doc) {
        return doc.name.toString().toLowerCase()
      }], ['desc', 'asc'])


	return {
		organization:state.organization,
		orgMembers:state.orgMembers,
		docs:sortedDocs
	}
}

export default withRouter(connect(mapStateToProps,
	{	
	}
)(LibraryContainer))


	