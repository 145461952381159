import React from 'react'
import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';

class DocEditorRecordingPreview extends React.PureComponent{  

  render(){ 
    const {recording,activeUploads}=this.props
    const {mux_status,transcript_status}=recording
    let uploadState
    if(mux_status=='waiting_for_upload'){
			uploadState='uploading'
		}else if(mux_status=='created'){
			uploadState='processing'
		}else if(mux_status=='ready'){
			if(transcript_status=='ready'){
				uploadState='ready'
			}else{
				uploadState='processing'
			}
		}    
		let progress=100
		if(activeUploads && activeUploads[recording.upload_id]){
			progress=activeUploads[recording.upload_id].progress
		}
		const muxPlaybackId = recording.playback_id   
    const previewImage1 = `https://image.mux.com/${muxPlaybackId}/thumbnail.webp?width=256&fit_mode=preserve&&time=35`
    const previewImage2 = `https://image.mux.com/${muxPlaybackId}/thumbnail.webp?width=256&fit_mode=preserve&&time=150`
    const previewImage3 = `https://image.mux.com/${muxPlaybackId}/thumbnail.webp?width=256&fit_mode=preserve&&time=200`
    const previewImage4 = `https://image.mux.com/${muxPlaybackId}/thumbnail.webp?width=256&fit_mode=preserve&&time=300`
    return (      	
      <div className='docEditor-docTitle-recordingPreviewContainer'>      	
      	{uploadState === 'uploading' &&
      		<Tooltip.Provider>
      			<Tooltip.Root delayDuration={100}>
							<Tooltip.Trigger asChild> 
				      	<div className='docEditor-docTitle-recordingPreview docEditor-docTitle-recordingPreview--uploading'>
				    			<div className='docEditor-docTitle-recordingPreview--uploading-progressBar'>
				    				<CircularProgressbar 
											value={progress}
											strokeWidth={10}									
										/>
				    			</div>				    					    			
				    			<div className='docEditor-docTitle-recordingPreview--uploading-iconContainer'>
				    				<Icon name='upArrowMedium' />
				    			</div>				    			
				      	</div>
		      		</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Uploading...
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
      	}

      	{uploadState === 'processing' &&
      		<Tooltip.Provider>
      			<Tooltip.Root delayDuration={100}>
							<Tooltip.Trigger asChild> 
				      	<div className='docEditor-docTitle-recordingPreview docEditor-docTitle-recordingPreview--processing'>
				    			<div className='docEditor-docTitle-recordingPreview--processing-spinnerContainer'>
				    				<div className='docEditor-docTitle-recordingPreview--processing-spinner'>
				    					<div className='docEditor-docTitle-recordingPreview--processing-spinner-spinner' />
				    				</div>
				    			</div>
				    			{/*}
				    			<div className='docEditor-docTitle-recordingPreview-posterCover'/>
				      		<div className='docEditor-docTitle-recordingPreview--ready-imageGrid'>
				    				<div className='docEditor-docTitle-recordingPreview--ready-imageGrid-image'>
				      				<img src={previewImage1} className='docEditor-docTitle-recordingPreview-poster' />
				      			</div>
				      			<div className='docEditor-docTitle-recordingPreview--ready-imageGrid-image'>
				      			<img src={previewImage2} className='docEditor-docTitle-recordingPreview-poster' />
				      			</div>
				      			<div className='docEditor-docTitle-recordingPreview--ready-imageGrid-image'>
				      			<img src={previewImage3} className='docEditor-docTitle-recordingPreview-poster' />
				      			</div>
				      			<div className='docEditor-docTitle-recordingPreview--ready-imageGrid-image'>
				      			<img src={previewImage4} className='docEditor-docTitle-recordingPreview-poster' />
				      			</div>				      			
				      		</div>
				      		*/}
				      	</div>
		      		</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								processing Recording													
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
      	}

      	{uploadState === 'ready' &&
      		<Tooltip.Provider>
      			<Tooltip.Root delayDuration={800}>
							<Tooltip.Trigger asChild> 
				      	<button onClick={this.props.openRecordingModal} className='docEditor-docTitle-recordingPreview docEditor-docTitle-recordingPreview--ready'>
				    			<div className='docEditor-docTitle-recordingPreview--ready-iconContainer'>
				    				<Icon name='playbackPlay' />
				    			</div>
				    			<div className='docEditor-docTitle-recordingPreview-posterCover'/>
				    			<div className='docEditor-docTitle-recordingPreview--ready-imageGrid'>
				    				<div className='docEditor-docTitle-recordingPreview--ready-imageGrid-image'>
				      				<img src={previewImage1} className='docEditor-docTitle-recordingPreview-poster' />
				      			</div>
				      			<div className='docEditor-docTitle-recordingPreview--ready-imageGrid-image'>
				      			<img src={previewImage2} className='docEditor-docTitle-recordingPreview-poster' />
				      			</div>
				      			<div className='docEditor-docTitle-recordingPreview--ready-imageGrid-image'>
				      			<img src={previewImage3} className='docEditor-docTitle-recordingPreview-poster' />
				      			</div>
				      			<div className='docEditor-docTitle-recordingPreview--ready-imageGrid-image'>
				      			<img src={previewImage4} className='docEditor-docTitle-recordingPreview-poster' />
				      			</div>
				      		</div>
				      	</button>
	      			</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Watch Recording												
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
      	}
      </div>   
    )
  }
}

function mapStateToProps(state,ownProps) {
  return {
    activeUploads:state.activeUploads
  }
}


export default connect(mapStateToProps,
  {}
)(DocEditorRecordingPreview)
