
import store from '../store'
import find from 'lodash/find'


export function getProjectsForRoadmap(roadmap) {	
	const state = store.getState()
	let projects=[]
	if(roadmap && roadmap.projects){
		roadmap.projects.forEach((roadmapProject)=>{
			const project=find(state.projects,{project_id:roadmapProject})
			if(project){
				projects.push(project)
			}
		})
	}


	return projects 
}
