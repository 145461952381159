function getAttrs(dom) {
  return {
    docId: dom.getAttribute('data-docId')
  }
}


const MessageInternalLinkNodeSpec ={
    attrs: {
      docId:{default:''}
    },
    inline: true,
    group: "inline",
    draggable: true,
    parseDOM: [
    {tag: 'internalLink', getAttrs},
   ],
    toDOM(node) {
      const {docId} = node.attrs;
      return ["div",{class:`message-para-internalLinkContainer`,"data-doc-id":docId}]
    }
}
    
   

  export default MessageInternalLinkNodeSpec


