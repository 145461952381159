import React from 'react'
import Icon from '../../misc/Icon'
import * as ContextMenu from '@radix-ui/react-context-menu';
import { DayPicker } from 'react-day-picker';

class RoadmapsProjectRowEditTargetDateContextSubMenu extends React.Component{  	


	render(){	
		const {projectId,currentStatus} = this.props

		// console.log(`currentStatus: ${currentStatus}`)

		return (
			<React.Fragment>         	
				<DayPicker
		      mode="single"
		      //selected={parseISO(this.props.currentTargetDate)}
		      //defaultMonth={parseISO(this.props.currentTargetDate)}
		      //onSelect={this.props.updateProjectTargetDate}
		      showOutsideDays
		    />		
			</React.Fragment>
		)
	}
}

export default RoadmapsProjectRowEditTargetDateContextSubMenu