import store from '../store'
import findIndex from 'lodash/findIndex'

//Basically the same as closeDoc.js
//but no history for navigation (might need to do this in the future if collab deletes while in doc)
export function closeArchivedTab(docId) {
	const state = store.getState()
	const openTabs=state.openTabs
	const {tabGroups,orderedTabGroups}=openTabs
	let newTabGroups=Object.assign({},tabGroups)
	let containingGroupId

	Object.keys(tabGroups).forEach((groupId,i)=>{
		const tabs=tabGroups[groupId]
		let inGroupIndex
		tabs.forEach((tab,index)=>{
			if(tab.id===docId){
				inGroupIndex=index
				containingGroupId=groupId
			}
		})
		
		if(inGroupIndex || inGroupIndex===0){
			containingGroupId=groupId	
			let newTabGroup
			if(tabs.length===1){
				newTabGroup=[]
			}else{
				newTabGroup=[
					...tabs.slice(0,inGroupIndex),
					...tabs.slice(inGroupIndex + 1)
				]	
			}
			newTabGroups[groupId]=newTabGroup
		}else{
			newTabGroups[groupId]=tabs
		}

	})
	store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
}