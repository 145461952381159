import store from '../../store'
import find from 'lodash/find'
import {updateMeetingTypeRequest} from '../../actions/meetings'
import schema from '../../prosemirror/schema/editorSchema'
import {AllSelection} from "prosemirror-state"
import {DOMParser,DOMSerializer} from "prosemirror-model"
import { Fragment } from "prosemirror-model"

// function createTemplate(){
// 	let content=[]
	
// 	const text='i am a template'
// 	const textNode=schema.text(text)
// 	const paragraph=schema.nodes.paragraph.createAndFill(null,textNode)
// 	content.push(paragraph)

// 	const text2='this is some content'
// 	const textNode2=schema.text(text2)
// 	const paragraph2=schema.nodes.paragraph.createAndFill(null,textNode2)



// 	content.push(paragraph2)

// 	//const doc=schema.nodes.doc.createAndFill({},content)
// 	// console.log(doc)
// 	return content

// }



// //todo templates
export function setMeetingType(meetingId,meetingType) {	

	// const doc = createTemplate()


	// let view = window.view
	// let tr = view.state.tr;
	// tr.setSelection(new AllSelection(view.state.doc)); // Select the whole document
	// tr.replaceSelectionWith(doc, false); // Replace it with the template
	// view.dispatch(tr); // Dispatch the transaction

	store.dispatch(updateMeetingTypeRequest(meetingId,meetingType))
}

