import store from '../store'
import find from 'lodash/find'

export function getRootDocIdForProject(projectId) {	
	let docId
	const state = store.getState()
	const doc= find(state.docs, {'project':projectId,is_project_doc:true})
	if(doc){
		docId=doc.doc_id
	}	
	return docId
}

