import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import sortBy from 'lodash/sortBy'
import {toggleShowNotionExportModal}  from '../../../utils/toggleShowNotionExportModal'
import {duplicateDoc} from '../../../utils/duplicateDoc'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getUserForId} from '../../../utils/getUserForId'
import MoveDocPopover from '../../MoveDocPopover'
import {getProjectForId} from '../../../utils/getProjectForId'
import LinearMarker from '../../misc/LinearMarker'
import LinearMilestoneIcon from '../../misc/LinearMilestoneIcon'
import {getTabStarredStatus,starTab,unstarTab} from '../../../utils/starredTabs'
import {deleteDoc} from '../../../utils/deleteDoc'
import {openDoc,backgroundOpenDoc} from '../../../utils/openDoc'
import {getRootDocIdForProject} from '../../../utils/getRootDocIdForProject'
import KeyboardShortcutTag from '../../misc/KeyboardShortcutTag'
import {copyDocLinkToClipboard} from '../../../utils/copyDocLinkToClipboard'
import {getMilestoneForId} from '../../../utils/getMilestoneForId'
import {openProjectInLinear} from '../../../utils/openInLinear'
import {createTemplate} from '../../../utils/templates/createTemplate'
import DocEditorCreateTemplatePopover from '../DocEditorCreateTemplatePopover'

class DocEditorProjectSubDocTopMenu extends React.Component{   

	constructor(){
    super()   
    this.handleDropdownClick=this.handleDropdownClick.bind(this)
    this.handleMoveDocDropdownClick=this.handleMoveDocDropdownClick.bind(this) 
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.handleToggleIssuePanelClick=this.handleToggleIssuePanelClick.bind(this)
    this.handleOpenProjectRootDoc=this.handleOpenProjectRootDoc.bind(this)
    this.handleTemplatePopoverClick=this.handleTemplatePopoverClick.bind(this)
    this.state={
      showMoveDocPopover: false,
      showDropdown: false,
      showCreateTemplatePopover: false,
    }
  }

  componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)		
	}

	componentWillUnmount(){
		window.removeEventListener('keydown', this.handleKeyDown)		
	}

  handleDropdownClick(e){		
		this.setState({showMoveDocPopover: false})
		this.setState({showDropdown: true})		
	}

	handleToggleIssuePanelClick(e){		
		this.props.toggleIssuePanel()
		this.setState({showDropdown: false})		
	}

	handleTemplatePopoverClick(e){		
		this.setState({showCreateTemplatePopover: true})
		this.setState({showDropdown: false})		
	}

	handleMoveDocDropdownClick(e){
		this.setState({showDropdown: false})
		this.timeout=setTimeout(() => {
    	this.setState({showMoveDocPopover: true});
    }, 200);        
	}

	handleKeyDown(e){ // Open menu on shift command p		
		if(e.shiftKey && e.metaKey && e.keyCode==80){
			this.setState({showDropdown: false})
			this.setState({showMoveDocPopover:true})						
		}		
		if(e.metaKey && e.keyCode==76 && !e.shiftKey){ //command L
			e.preventDefault()
			e.stopPropagation()
			copyDocLinkToClipboard(this.props.docId)
		}
	}

	handleOpenProjectRootDoc(e){
		const projectRootDoc=getRootDocIdForProject(this.props.projectId)
		if(e.metaKey){ //command + click
			backgroundOpenDoc(projectRootDoc)
		}else{
			openDoc(this.props.history,projectRootDoc)
		}		
	}	


	render(){	

		const {sideMenuVisible,docName, projectId,docId,issuePanel,doc} = this.props
		const {showMoveDocPopover, showDropdown} = this.state
		
		let project
		if(projectId){
			project = getProjectForId(projectId)
		}
		const starred = getTabStarredStatus('doc',this.props.docId)
		const isMilestoneSubDoc = doc.milestone?true:false
		let milestone={}
		let progressPercentage
		let scope
		if(isMilestoneSubDoc){
			milestone=getMilestoneForId(doc.milestone)
			if(milestone){
				progressPercentage=milestone.progressPercentage 
				scope=milestone.scope
			}
		}
	  return (
	  	<div className={'docEditor-topMenuContainer docEditor-topMenuContainer--subDoc ' + (sideMenuVisible ? ' docEditor-topMenuContainer--sideMenuState--visible ' : ' docEditor-topMenuContainer--sideMenuState--hidden ')}>								
				<div className='docEditor-topMenu-center'>						
					<div className='docEditor-topMenu--subDoc-breadcrumb'>
						{project && 
							<button className='docEditor-topMenu--subDoc-breadcrumb-rootProject' onClick={this.handleOpenProjectRootDoc}>
								<div className='docEditor-topMenu--subDoc-breadcrumb-rootProject-marker'>
									<LinearMarker
										projectId={projectId}
									/>
								</div>
								<div className='docEditor-topMenu--subDoc-breadcrumb-rootProject-label'>
								{project.name}
								</div>
							</button>
						}
						<div className='docEditor-topMenu--subDoc-breadcrumb-slash'>
							/
						</div>
						<div className='docEditor-topMenu--subDoc-breadcrumb-currentDoc'>
							{isMilestoneSubDoc && 
								<div className='docEditor-topMenu--subDoc-breadcrumb-currentDoc-milestoneIconContainer'>
									<LinearMilestoneIcon 
										milestone={milestone}
									/>
								</div>
							}
							<div className='docEditor-topMenu--subDoc-breadcrumb-currentDoc-label'>
								{docName}
							</div>
						</div>
					</div>

					<div className='docEditor-newTemplatePopoverTriggerContainer'>
						<DocEditorCreateTemplatePopover
							showPopover={this.state.showCreateTemplatePopover}						
							openPopover={()=>{this.setState({showCreateTemplatePopover: true})}}
							closePopover={()=>{this.setState({showCreateTemplatePopover: false})}}
							docId={docId}												
						>
							<div className='docEditor-newTemplatePopoverTrigger'/>							
						</DocEditorCreateTemplatePopover>							
					</div>	
					
				</div>
				<div className='docEditor-topMenu-right'>
					<MoveDocPopover
						showPopover={showMoveDocPopover}						
						openPopover={()=>{this.setState({showMoveDocPopover: true})}}
						closePopover={()=>{this.setState({showMoveDocPopover: false})}}		
						docId={docId}		
						layout="topMenu"
					>
						<div className='docEditor-topMenu-movePopoverAnchor' /> 								
					</MoveDocPopover>	
					
					{isMilestoneSubDoc &&
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>																								
								<button onClick={this.props.toggleIssuePanel} className={'docEditor-topMenuContainer--rootDoc-linearSummary ' + (issuePanel ? ' docEditor-topMenuContainer--rootDoc-linearSummary--active ' : ' docEditor-topMenuContainer--rootDoc-linearSummary--inactive ')}>									
									
									<div className='docEditor-topMenuContainer--rootDoc-linearSummary-progressLabel'>
										{Math.floor(progressPercentage)}% <span>- {scope}</span>
									</div>	
													
								</button>																		
							</Tooltip.Trigger>		
							{!issuePanel && 		
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Preview Milestone Issues
								</Tooltip.Content>													
							}
						</Tooltip.Root>
					</Tooltip.Provider>
				}
					{!this.props.messagePanelIsVisible && 
						<button onClick={this.props.toggleShowMessagePanel} className={'docEditor-topMenu-button docEditor-topMenu-button--textAndIcon docEditor-topMenu-button--docChat ' + (this.props.showUnreadsIndicatorDocChatBtn ? ' docEditor-topMenu-button--docChat--hasUnreads ': ' ')}> 
							<div className='docEditor-topMenu-button-iconContainer'>
								{/*<Icon name='messageOutlineMedium' /> */}
								<Icon name={this.props.showUnreadsIndicatorDocChatBtn ? 'sideMenuClean' : 'sideMenu'} />
							</div>
							{this.props.showUnreadsIndicatorDocChatBtn &&
								<div className='docEditor-topMenu-button--docChat--hasUnreadsIndicator' />
							}
							<div className='docEditor-topMenu-button-label'>
								Doc Chat
							</div>						
						</button>
					}
					
					{!starred && 
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>																	
									<button className='docEditor-topMenu-button docEditor-topMenu-button--inactiveStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{starTab('doc',docId)}}>
										<Icon name='star' />
									</button>																	
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Star Doc
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					}

					{starred && 
						<Tooltip.Provider>
							<Tooltip.Root delayDuration={0}>
								<Tooltip.Trigger asChild>																	
									<button className='docEditor-topMenu-button docEditor-topMenu-button--activeStar docEditor-topMenu-button--iconOnly docEditor-topMenu-button--star' onClick={()=>{unstarTab('tab',docId)}}>
										<Icon name='starFill' />
									</button>																	
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
									Unstar Doc
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>
					}

					<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>
								<button onClick={()=>{copyDocLinkToClipboard(docId)}} className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--docLink'>
							    <Icon name='link' />
							  </button>
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								Copy Link
								<KeyboardShortcutTag									
									command
									letter='L'
								/>									
								</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					

					<DropdownMenu.Root open={showDropdown}>
				    <DropdownMenu.Trigger asChild>
				    	<button onClick={()=>{this.handleDropdownClick()}} className='docEditor-topMenu-button docEditor-topMenu-button--iconOnly docEditor-topMenu-button--dropdown'>
				    		<Icon name='ellipses' />
				    	</button>
				    </DropdownMenu.Trigger>   			 	
	   			 	<DropdownMenu.Content 
	   			 		sideOffset={1}
	   			 		align="end"
	   			 		alignOffset={-8}
	   			 		className='dropdownMenu'
	   			 		onEscapeKeyDown={()=>{this.setState({showDropdown: false})}}
							onPointerDownOutside={()=>{this.setState({showDropdown: false})}}
							onInteractOutside={()=>{this.setState({showDropdown: false})}}
						>							
	   			 		{/*
	   			 		<DropdownMenu.Item onSelect={this.integrationStuff}>
		        		Start Notion Integration
		        	</DropdownMenu.Item>
		        	
		        	
		        	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
		        		Copy Link
		        	</DropdownMenu.Item>
		        	*/}
		        	<DropdownMenu.Item onClick={()=>{this.handleToggleIssuePanelClick()}}>
		        		{this.props.showIssuePanel ? 'Hide' : 'Show'} Issue Panel
		        	</DropdownMenu.Item>		

		        	<DropdownMenu.Item onClick={()=>{this.handleMoveDocDropdownClick()}}>
		        		Move Doc...
		        	</DropdownMenu.Item>		

		        	<DropdownMenu.Separator />

		        	{/*}
		        	<DropdownMenu.Item onSelect={()=>{createTemplate(docId)}}>
		        		Turn into template...
		        	</DropdownMenu.Item>
		        	*/}

		        	<DropdownMenu.Item onSelect={()=>{this.handleTemplatePopoverClick()}}>
		        		Turn into template...
		        	</DropdownMenu.Item>


		        	<DropdownMenu.Item onSelect={this.props.duplicateDoc}>
		        		Duplicate Doc
		        	</DropdownMenu.Item>
		        	{/*} 
		        	<DropdownMenu.Separator />
		        	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
		        		Export to Notion
		        	</DropdownMenu.Item>
		        	*/}
		        	<DropdownMenu.Separator />				
		       	 	<DropdownMenu.Item  className='dropdownMenu-item--warning' onSelect={()=>{deleteDoc(docId,this.props.history)}}>
		        		Delete
		        	</DropdownMenu.Item>					        
				    </DropdownMenu.Content>
					</DropdownMenu.Root>	  
					 				
				</div>
				
			</div>		
			
	  )
	}
}

export default withRouter(DocEditorProjectSubDocTopMenu)
