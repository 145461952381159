import { Fragment, Node } from 'prosemirror-model';
import schema from '../../schema/editorSchema'  
import { createCell } from './createCell';
import {randomID} from '../../../utils/randomID'

export function createTable(
  rowsCount,
  colsCount,
  withHeaderRow
) {
  // const types = getTableNodeTypes(schema);
  const headerCells = [];
  const cells = [];

  const tableType = schema.nodes.table 
  const cellType = schema.nodes.table_cell 
  const rowType = schema.nodes.table_row 
  const headerType = schema.nodes.table_header
  const docTableNodes = schema.nodes.docTable 


  for (let index = 0; index < colsCount; index += 1) {
    const cell = createCell(cellType);

    if (cell) {
      cells.push(cell);
    }

    if (withHeaderRow) {
      const headerCell = createCell(headerType);

      if (headerCell) {
        headerCells.push(headerCell);
      }
    }
  }

  const rows = [];

  for (let index = 0; index < rowsCount; index += 1) {
    rows.push(rowType.createAndFill(null, withHeaderRow && index === 0 ? headerCells : cells));
  }
  const nodeId=randomID()

  return docTableNodes.createAndFill({nodeId:nodeId},tableType.createAndFill(null, rows))
}
