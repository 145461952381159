import React from 'react'
import Icon from '../../../misc/Icon'
import {getUserName} from '../../../../utils/getUserName'

class MessageInputBoxHintsBar extends React.Component{ 
	render(){			
		const {userTyping}=this.props
		return (	  				
			<div className='docEditor-messagePanel-inputBoxHintsBar'>					
				{userTyping &&
					<div className='docEditor-messagePanel-inputBoxHintsBar-typingIndicator'>
						<span className='docEditor-messagePanel-inputBoxHintsBar-typingIndicator-user'>{getUserName(userTyping)}</span> is typing...							 
				</div>
				}
				<div className={'docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut ' + (this.props.inputBoxNonEmptyAndFocused ? 'docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut--show' : 'docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut--hide')}>
					<span className='docEditor-messagePanel-inputBoxHintsBar-sendKeyboardShortut-shortcut'>
						 Shift + Return
					</span> to add a new line
				</div>
			</div>					
		)
	}
}


export default MessageInputBoxHintsBar