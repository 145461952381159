import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import MiniSearch from 'minisearch'
import {getProjects} from '../getProjects'
import {getRoadmaps} from '../getRoadmaps'
import {getOrg} from '../getOrg'


let minisearchProjectName = new MiniSearch({
	fields: ['name'],
	storeFields: ['name','project_id'],
	idField: 'project_id',
})

let minisearchRoadmapName = new MiniSearch({
	fields: ['name'],
	storeFields: ['name','roadmap_id'],
	idField: 'roadmap_id',
})

let minisearchOrgName = new MiniSearch({
	fields: ['name'],
	storeFields: ['name','organization_id'],
	idField: 'organization_id',
})



export function searchRoadmapsAndProjects(searchTerm) {
	if(minisearchProjectName._documentCount == 0){
		const projects=getProjects()
		const roadmaps=getRoadmaps()
		const org=getOrg()
		minisearchProjectName.addAll(projects)
		minisearchRoadmapName.addAll(roadmaps)
		minisearchOrgName.addAll([org])
	}
	let results=[]
	if(searchTerm){
		const projectResults=minisearchProjectName.search(searchTerm, {prefix:true, fuzzy:0.1})
		const roadmapResults=minisearchRoadmapName.search(searchTerm, {prefix:true, fuzzy:0.1})
		const orgResults=minisearchOrgName.search(searchTerm, {prefix:true, fuzzy:0.1})
		const combinedResults = orgResults.concat(projectResults.concat(roadmapResults))

		//sort by value

		const sorted=sortBy(combinedResults,'score').reverse()
		results=sorted
	}

	return results.slice(0,10)
}