import React from 'react'
import Icon from '../../misc/Icon'
import MeetingTypeIcon from '../../misc/MeetingTypeIcon'
import { connect } from 'react-redux'
import {getDocForId} from '../../../utils/getDocForId'
// import {getBoardObjForDoc} from '../../../utils/getBoardObjForDoc'

class InternalLinkReactComponent extends React.PureComponent{  
	
	render(){
		const {docId,docNameSnapshot}=this.props
		const doc=getDocForId(docId)
		let name =docNameSnapshot
		if(doc){
			name=doc.name
		}

		let meetingDoc = false
		let meetingType
		let meetingFormattedTimestamp

		if(doc && doc.name && doc.name === 'Prompt Templates'){
			meetingDoc = true
			meetingType = 'designReview'
			meetingFormattedTimestamp = 'June 28'
		}
		
	  return (
	  	<span className="doc-para-internalLink" onClick={()=>{this.props.handleOpenDoc(docId)}}>	  			  	
	  		<span role="img" className='doc-para-internalLink-spacer' />
	  		<span role="img" className='doc-para-internalLink-iconContainer'>
	  			{!meetingDoc && <Icon name='linkArrowBoxed' /> }
	  			{meetingDoc && <MeetingTypeIcon svgOnly meetingType={meetingType} /> }
	  		</span>
		  	<span className="doc-para-internalLink-title">
		  		{name} {meetingDoc && meetingFormattedTimestamp && <span className='doc-para-internalLink-title-dateTime'>{meetingFormattedTimestamp}</span>}
		  	</span>	  			 		  	
	  	</span>
	  )
	}
}
export default InternalLinkReactComponent