import React from 'react'
import Icon from '../../../misc/Icon'
import ReactPlayer from 'react-player/file'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'


const MOUSE_MOVE_SHORT_DEBOUNCE_TIME = 1800
const MOUSE_MOVE_LONG_DEBOUNCE_TIME = 4000

class MessageMediaMainFeedHighlightVideoPlayer extends React.Component{  

  constructor(props){
    super(props)        

    this.handlePlayPause=this.handlePlayPause.bind(this)    
    this.handlePlay=this.handlePlay.bind(this)
    this.handlePause=this.handlePause.bind(this)    
    this.handleZoomAndPlay=this.handleZoomAndPlay.bind(this)
    this.handleSeekChange=this.handleSeekChange.bind(this)
    this.handleSeekMouseUp=this.handleSeekMouseUp.bind(this)
    this.handleDoubleClick=this.handleDoubleClick.bind(this)    
    this.handleSeekMouseDown=this.handleSeekMouseDown.bind(this)
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.hideMouseMoveShort=this.hideMouseMoveShort.bind(this)    
    this.hideMouseMoveLong=this.hideMouseMoveLong.bind(this)    
    this.toggleZoom=this.toggleZoom.bind(this)    
    this.handlePlayerReady=this.handlePlayerReady.bind(this)  
    this.toggleTranscript=this.toggleTranscript.bind(this)
    this.handleTranscriptAndPlay=this.handleTranscriptAndPlay.bind(this)

    this.hideMouseMoveShort=debounce(this.hideMouseMoveShort,MOUSE_MOVE_SHORT_DEBOUNCE_TIME)
    this.hideMouseMoveLong=debounce(this.hideMouseMoveLong,MOUSE_MOVE_LONG_DEBOUNCE_TIME)

    this.handleMouseEnter=this.handleMouseEnter.bind(this)
    this.handleMouseLeave=this.handleMouseLeave.bind(this)


    this.state={      
      zoomed: false,  
      transcriptActive: false,
      url: null,      
      playing: false,     
      played: 0,
      loaded: 0,
      duration: 0,            
      mouseMovedShort:false,
      mouseMovedLong:false,
      captionsActive: false,           
      volume: 1,
      adjustingVolume: false,
      muted:false,
      playerLoaded:false,
      hasPlayed:false,
    }    
  }  

  componentDidMount(){      
    if(!this.state.playerLoaded){ //otherwise calls multiple times e.g. after seek
      const progress=0.5
    }
  }

  handlePlayerReady(){
    //this.player.seekTo(0.5)
  }

   handlePlayPause(){
    this.setState({playing:!this.state.playing})
  }

  handlePlay(){
    this.setState({playing:true,hasPlayed:true})
  }

  handlePause(){
    this.setState({playing:false})
  }

  handleSeekMouseDown(){
    this.setState({seeking:true,muted:true})
  }

	handleMouseEnter(){
    this.setState({playing:true,hasPlayed:true})
  }

  handleMouseLeave(){
    this.setState({playing:false})
    this.player.seekTo(0)
  }


  handleZoomAndPlay(){
    toggleMessageMediaZoomInRedux(true)
    this.setState({zoomed:true,playing:true})
  }

  handleTranscriptAndPlay(){    
    this.setState({transcriptActive:true,playing:true})
  }
 
  handleSeekChange(e){
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
    this.setState({playedSeconds:playedSeconds,played:e})
  }

  handleSeekMouseUp(e){
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
    this.setState({playedSeconds:playedSeconds,played:e,muted:false})
  }

  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
     // this.saveVideoProgress(state.playedSeconds)
    }
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
    this.player.seekTo(0)
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  ref = player => {
    this.player = player
  }

  handleMouseMove(){    
    this.setState({mouseMovedShort:true})
    this.setState({mouseMovedLong:true})
    this.hideMouseMoveShort()
    this.hideMouseMoveLong()
  }

  hideMouseMoveShort(){
    this.setState({mouseMovedShort:false})
  }

  hideMouseMoveLong(){
    this.setState({mouseMovedLong:false})
  }

  toggleTranscript(){
    this.setState({transcriptActive:!this.state.transcriptActive})
  }

  toggleZoom(e){
    if(!this.state.zoomed){
      toggleMessageMediaZoomInRedux(true)
      this.setState({ zoomed: true })
    }else{
      toggleMessageMediaZoomInRedux(false)
      this.setState({ zoomed: false})
    }
  } 


  handleDoubleClick(e){
    e.stopPropagation()
  }  

  render(){ 
    const {muxPlaybackID, isAudio} = this.props
    const {transcriptActive, url, playing, played, loaded, duration, zoomed, mouseMovedShort, mouseMovedLong, hasPlayed} = this.state
    const progressSeconds = duration * played
    let deliveryUrl =  `https://stream.mux.com/${muxPlaybackID}/low.mp4`
    if(isAudio){
      deliveryUrl = `https://stream.mux.com/${muxPlaybackID}.m3u8`
    }
    return (      
      <React.Fragment>
              
        <div onClick={()=>{console.log('click this bit here----')}} className={'message-content-mainFeedHighlight-btn-preview ' + (isAudio ? ' message-content-mainFeedHighlight-btn-preview--audio ' : ' message-content-mainFeedHighlight-btn-preview--video ')}>        

        <ReactPlayer          
            ref={this.ref}
            className='message-content-mainFeedHighlight-btn-preview-video'
            width='100%'
            height='100%'
            url={deliveryUrl}
            //progressInterval={50} //TODO maybe make this dynamic depending on length of video
            progressInterval={5} // made shorter because highlights are shorter
            //pip={pip}
            playing={playing}
            muted={true}
            volume={0}
            onPlay={this.handlePlay}

            onPause={this.handlePause}
            onEnded={this.handleEnded}
            onProgress={this.handleProgress}
            onDuration={this.handleDuration}
            onEnded={this.handleEnded}
            onReady={this.handlePlayerReady}
          />
        </div>

        <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className='message-content-mainFeedHighlight-btn-preview-hoverBtn' />
      </React.Fragment>


    )
  }
}

export default MessageMediaMainFeedHighlightVideoPlayer
