import React from 'react'
import Icon from '../../../components/misc/Icon'


class DocStashedItem extends React.Component{  

  render(){ 
    
    const {text,imageUrl, timestamp} = this.props

    let typeIcon 
    if(text){
      typeIcon = 'textBold'
    }
    if(imageUrl){
      typeIcon = 'insertBarImage'
    }

    return (      
      <div className='docEditor-stashedPanel-item'>  
        <div className='docEditor-stashedPanel-item-type'>  
          <div className='docEditor-stashedPanel-item-type-iconContainer'>
            <Icon name={typeIcon} />
          </div>
        </div>
        <div className='docEditor-stashedPanel-item-contentContainer'>  
          <div className='docEditor-stashedPanel-item-content'>  
            {text &&
              <div className='docEditor-stashedPanel-item-content-text'>  
                {text}
              </div>
            }
            {imageUrl &&
              <div className='docEditor-stashedPanel-item-content-imageOuterContainer'>
                <div className='docEditor-stashedPanel-item-content-imageInnerContainer'>
                  <img src={imageUrl} className='docEditor-stashedPanel-item-content-image' />
                </div>
              </div>
            }
            
          </div>
          <div className='docEditor-stashedPanel-item-footer'>  
            <div className='docEditor-stashedPanel-item-footer-timestamp'>  
              {timestamp}
            </div>
            <button className='docEditor-stashedPanel-item-footer-btn docEditor-stashedPanel-item-footer-btn--restore'>
              Restore
            </button>
            <button className='docEditor-stashedPanel-item-footer-btn docEditor-stashedPanel-item-footer-btn--delete'>
              Delete
            </button>
          </div>
        </div>           
      </div>

    )
  }
}




class DocStashedPanel extends React.Component{  

	render(){	
    
    const {showStashedPanel} = this.props


	  return (	  	
      <div className='docEditor-stashedPanelContainer'>  
  		  <div className='docEditor-stashedPanel'>
          <div onClick={this.props.closeStashedPanel}  className='docEditor-stashedPanel-header'>
            <div className='docEditor-stashedPanel-header-label'>
              Doc Stash
            </div>
            <button className='docEditor-stashedPanel-header-hideBtn'>
              <div className='docEditor-stashedPanel-header-hideBtn-inner'>
                <Icon name='hideRightMedium'/>
              </div>                  
            </button>
          </div>            

          <div className='docEditor-stashedPanel-list'>
            <DocStashedItem            
              timestamp='3 days ago'
              text='Quisque cursus feugiat mauris, ut rutrum augue laoreet in. Praesent porta cursus lobortis. Suspendisse sed felis nec mi ullamcorper vulputate sit amet sit amet felis. Aenean accumsan mi odio, faucibus convallis nisi lacinia eget. Nam varius arcu massa, ornare pretium quam pharetra a. Nunc ut congue nibh. Suspendisse cursus augue neque, at rhoncus ligula pretium et. Vestibulum at sem vitae risus egestas mattis at sed quam. Phasellus at ornare felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et tincidunt eros, nec malesuada dui. Donec mollis augue felis, in porttitor enim sagittis at. Maecenas rhoncus malesuada ipsum, sed posuere lacus dignissim id. Vivamus at convallis massa.'            
            />
              
            <DocStashedItem            
              timestamp='4 days ago'            
              imageUrl='https://res.cloudinary.com/yarn/image/upload/v1669397800/vhkleusxzij5yij8emo2.png'
            />

            <DocStashedItem            
              timestamp='3 days ago'
              text='Quisque cursus feugiat mauris, ut rutrum augue laoreet in. Praesent porta cursus lobortis. Suspendisse sed felis nec mi ullamcorper vulputate sit amet sit amet felis. Aenean accumsan mi odio, faucibus convallis nisi lacinia eget. Nam varius arcu massa, ornare pretium quam pharetra a. Nunc ut congue nibh. Suspendisse cursus augue neque, at rhoncus ligula pretium et. Vestibulum at sem vitae risus egestas mattis at sed quam. Phasellus at ornare felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et tincidunt eros, nec malesuada dui. Donec mollis augue felis, in porttitor enim sagittis at. Maecenas rhoncus malesuada ipsum, sed posuere lacus dignissim id. Vivamus at convallis massa.'            
            />

            <DocStashedItem            
              timestamp='3 days ago'
              text='Quisque cursus feugiat mauris, ut rutrum augue laoreet in. Praesent porta cursus lobortis. Suspendisse sed felis nec mi ullamcorper vulputate sit amet sit amet felis. Aenean accumsan mi odio, faucibus convallis nisi lacinia eget. Nam varius arcu massa, ornare pretium quam pharetra a. Nunc ut congue nibh. Suspendisse cursus augue neque, at rhoncus ligula pretium et. Vestibulum at sem vitae risus egestas mattis at sed quam. Phasellus at ornare felis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et tincidunt eros, nec malesuada dui. Donec mollis augue felis, in porttitor enim sagittis at. Maecenas rhoncus malesuada ipsum, sed posuere lacus dignissim id. Vivamus at convallis massa.'            
            />
          </div>
            
        


        </div>            
      </div>

	  )
	}
}

export default DocStashedPanel
