import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_DOCS_SUCCESS':
		return action.response

	case 'CREATE_DOC_REQUEST':
		const doc = action.doc		
		return [...state,doc]


	case 'UPDATE_MEETING_TYPE_REQUEST': //set template to meetingType
	case 'UPDATE_MEETING_TEMPLATE_REQUEST': 
		//teamSyncs are a bit different and template selection is a different step
		const {meetingType,meetingId} = action
	//	if(meetingType!=='teamSync'){
		//	const meetingId=action.meetingId
			let indx=findIndex(state,(doc => {
				return doc.meeting === meetingId
			}))
			if(indx > -1){
				const inState=state[indx]
				let updatedDoc={...inState}
				updatedDoc.template=meetingType
				return [
					...state.slice(0,indx),
					updatedDoc,
					...state.slice(indx + 1)
				]
			}else return state

	case 'DOC_TEMPLATE_UPDATED_PUSHER': 
		let idxt=findIndex(state,(doc => {
			return doc.doc_id === action.docId
		}))
		if(idxt > -1){
			const docInState=state[idxt]
			let updatedDocWithTemplate={...docInState}
			updatedDocWithTemplate.template=action.template
			return [
				...state.slice(0,idxt),
				updatedDocWithTemplate,
				...state.slice(idxt + 1)
			]
		}else return state

		
	case 'LINK_ADDED_TO_LINEAR':
		const linkedId = action.docId
		let indexOfLinkedDoc = findIndex(state,(doc => {
			return doc.doc_id === linkedId
		}))
		if(indexOfLinkedDoc>-1){
			const oldDoc=state[indexOfLinkedDoc]
			let updatedLinkedDoc={...oldDoc}
			updatedLinkedDoc.linear_link_created=true
			return [
				...state.slice(0,indexOfLinkedDoc),
					updatedLinkedDoc,
				...state.slice(indexOfLinkedDoc + 1)
			]
		}else return state


	case 'RENAME_DOC_LOCALLY':
		const renamedId = action.docId
		let indexOfRenamedDoc = findIndex(state,(doc => {
			return doc.doc_id === renamedId
		}))
		if(indexOfRenamedDoc>-1){
			const oldDoc=state[indexOfRenamedDoc]
			let updatedDoc={...oldDoc}
			updatedDoc.name=action.name
			return [
				...state.slice(0,indexOfRenamedDoc),
					updatedDoc,
				...state.slice(indexOfRenamedDoc + 1)
			]
		}else return state
	case 'DOC_TIMESTAMP_UPDATED_PUSHER':
		//check if we have that doc and if we do update the timestamp
		const timestampDocId = action.docId
		const timestamp=action.timestamp
		let indexOfTimestampDoc = findIndex(state,(doc => {
			return doc.doc_id === timestampDocId
		}))
		if(indexOfTimestampDoc>-1){
			const oldDoc=state[indexOfTimestampDoc]
			let updatedDoc={...oldDoc}
			//check that the new timestamp is more recent
			if(oldDoc.updated_at<timestamp){
				//console.log('update timestamp1!!!!')
				updatedDoc.updated_at=timestamp
			}
			// else{
			// 	if(updatedDoc.updated_at==timestamp){
			// 		console.log('same timestamp!')
			// 	}else{
			// 		console.log('old pusher dont update timestamp!')
			// 	}
				
			// }

			
			return [
				...state.slice(0,indexOfTimestampDoc),
					updatedDoc,
				...state.slice(indexOfTimestampDoc + 1)
			]
		}else return state

	case 'FETCH_DOC_HTML_SUCCESS':
		let indxh = findIndex(state,(doc => {
			return doc.doc_id === action.docId
		}))
		if(indxh>-1){
			const oldDoc=state[indxh]
			let updatedDoc={...oldDoc}
			updatedDoc.doc_html=action.html 
			return [
				...state.slice(0,indxh),
					updatedDoc,
				...state.slice(indxh + 1)
			]
		}else return state




	case 'DELETE_PROJECT':	
		//remove project docs
		const projectId=action.projectId
		let docs=[]
		state.forEach((doc)=>{
			if(doc.project != projectId){
				docs.push(doc)
			}else{
				//TODO remove doc
			}
		})

		return docs

	case 'FETCH_A_DOC_SUCCESS':
	case 'CREATE_DOC_SUCCESS':
		const docId = action.response['doc_id']
		let indexOfDoc = findIndex(state,(doc => {
			return doc['doc_id'] === docId
		}))
		if(indexOfDoc === -1){
			indexOfDoc=state.length
		}
		return [
			...state.slice(0,indexOfDoc),
			action.response,
			...state.slice(indexOfDoc + 1)
		]

	case 'ARCHIVE_DOC_REQUEST':
	case 'ARCHIVE_MEETING_REQUEST':
		const requestedDocId = action.docId
		let requestedIndex = findIndex(state,(doc => {
			return doc['doc_id'] === requestedDocId
		}))
		if(requestedIndex>-1){
			return [
			...state.slice(0,requestedIndex),
			...state.slice(requestedIndex + 1)
			]
		}else return state

	case 'ARCHIVE_DOC_SUCCESS':
		const archivedDocId = action.docId
		var indexOfDocToArchive = findIndex(state,(doc => {
			return doc['doc_id'] === archivedDocId
		}))
		if(indexOfDocToArchive >-1){
			return [
			...state.slice(0,indexOfDocToArchive),
			...state.slice(indexOfDocToArchive + 1)
		]
	}else return state


	case 'ADD_FOLLOWER_PUSHER':
		const followedDocId=action.docId
		const followerId = action.userId
		const indexOfFollowedDoc= findIndex(state,(doc => {
			return doc['doc_id'] === followedDocId
		}))
		if(indexOfFollowedDoc>-1){
			let oldDoc=state[indexOfFollowedDoc]
			let oldFollowArray=oldDoc.followers || []
			let newFollowArray=[...oldFollowArray]
			//check not already in there!
			const indexCheck=findIndex(oldFollowArray,(follower => {
				return follower.user_id === followerId
			}))
			if(indexCheck==-1){
				newFollowArray.push({user_id:followerId})
			}
			let newDoc={...oldDoc}
			newDoc.followers=newFollowArray
			return [
				...state.slice(0,indexOfFollowedDoc),
				newDoc,
				...state.slice(indexOfFollowedDoc + 1)
			]
		}
		else return state
 
	case 'UPDATE_DOC_SUCCESS':
		const updatedDocId = action.response['doc_id']
		let indexOfDocToUpdate = findIndex(state,(doc => {
			return doc['doc_id'] === updatedDocId
		}))
		return [
			...state.slice(0,indexOfDocToUpdate),
			action.response,
			...state.slice(indexOfDocToUpdate + 1)
		]

	case 'UPDATE_DOC_TEMPLATE_REQUEST':
		let idx = findIndex(state,(doc => {
			return doc['doc_id'] === action.docId
		}))
		let docInState=state[idx]
		let newDoc={...docInState}
		newDoc.template=action.template
		return [
			...state.slice(0,idx),
			newDoc,
			...state.slice(idx + 1)
		]



	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
