import {Plugin, PluginKey} from 'prosemirror-state';
import {Decoration, DecorationSet} from 'prosemirror-view';
import {
  TableMap,
  CellSelection,
  addRowBefore,
  addRowAfter,
  addColumnBefore,
  addColumnAfter,
  deleteColumn,
  deleteRow
} from 'prosemirror-tables';
import DocTableCellUI from '../../../components/nodeViews/tables/DocTableCellUI'
import ReactDOM from 'react-dom'
import {getNodePos} from '../../utils/getNodePos'
import TableCellContextMenuReactComponent from './TableCellContextMenuReactComponent'
//https://github.com/skiff-org/prosemirror-tables/blob/master/src/columnhandles.js

export const createElementWithClass = (type, className) => {
  const el = document.createElement(type);
  el.className = className;
  return el;
};

function setNodeAttrs(node, extraAttrs) {
  const attrsForDOM = {};
  for (const prop in extraAttrs) {
    const setter = extraAttrs[prop].setDOMAttr;
    if (setter) setter(node.attrs[prop], attrsForDOM);
  }
  return attrsForDOM;
}

export function selectionCell(state) {
  const sel = state.selection;
  if (sel.$anchorCell) {
    return sel.$anchorCell.pos > sel.$headCell.pos
      ? sel.$anchorCell
      : sel.$headCell;
  } else if (sel.node && sel.node.type.spec.tableRole == 'cell') {
    return sel.$anchor;
  }
  return cellAround(sel.$head) || cellNear(sel.$head);
}



export const key = new PluginKey('tableColumnHandles');

export class CellView {
  constructor(node, view, getPos) {
    this.getPos = getPos;
    this.node = node;
    this.setRef = this.setRef.bind(this)
    this.addAlignClassnames=this.addAlignClassnames.bind(this)
    this.clearCellContents=this.clearCellContents.bind(this)
    this.setCellBackgroundColor=this.setCellBackgroundColor.bind(this)
    this.handleContextMenuOpenChange=this.handleContextMenuOpenChange.bind(this)

    this.view = view;
    this.selectRow=this.selectRow.bind(this)
    this.selectColumn=this.selectColumn.bind(this)
    this.checkIfFirstCol=this.checkIfFirstCol.bind(this)
   // this.dom = createElementWithClass('td', `${node.attrs.type}-cell`);
    if(node.type.name=='table_cell'){
      this.dom = document.createElement('td');
    }else if(node.type.name=='table_header'){
       this.dom = document.createElement('th');
    }

    
    this.contentDOM = this.dom.appendChild(
      createElementWithClass('div', 'doc-table-cell-content')
    );    



    // ReactDOM.render(
    //  <TableCellContextMenuReactComponent 
    //   setCellBackgroundColor={this.setCellBackgroundColor}
    //   handleContextMenuOpenChange={this.handleContextMenuOpenChange} 
    //   clearCellContents={this.clearCellContents}
    //   addRow={this.addRow}
    //   addColumn={this.addColumn}
    //   deleteRow={this.deleteRow}
    //   deleteColumn={this.deleteColumn}
    //   setRef={this.setRef} 
    //   /> ,
    //   this.dom
    // );

    
  

    this.firstColumnDom=null
    this.colHeaderDom=null


    this.checkIfFirstCol(this.view);
    this.checkIfColHeader(this.view);
    this.addAlignClassnames()

    this.dom.classList.add('doc-table-cell')

    const cellBGColor = node.attrs.background
    //const cellBGColor = 'green'
    //const cellBGColor = 'purple'
    //const cellBGColor = 'red'
    //const cellBGColor = 'blue'
    //const cellBGColor = 'orange'
    //const cellBGColor = 'sky'
    //const cellBGColor = 'pink'
    //const cellBGColor = 'teal'
    //const cellBGColor = 'grey'
    this.dom.classList.add(`doc-table-cell--bg--${cellBGColor}`)
  }

  deleteRow(){//before/after
    const state=window.view.state 
    const dispatch=window.view.dispatch
    deleteRow(state,dispatch)
  }

  deleteColumn(){//before/after
    const state=window.view.state 
    const dispatch=window.view.dispatch
    deleteColumn(state,dispatch)
  }

  addRow(direction){//before/after
    const state=window.view.state 
    const dispatch=window.view.dispatch
    if(direction=='above'){
      addRowBefore(state,dispatch)
    }else{
      addRowAfter(state,dispatch)
    }
  }
  addColumn(direction){//before/after
    const state=window.view.state 
    const dispatch=window.view.dispatch
    if(direction=='before'){
      addColumnBefore(state,dispatch)
    }else{
      addColumnAfter(state,dispatch)
    }
  }

  handleContextMenuOpenChange(openState){
    if(openState==true){//select cell
      let tr=window.view.state.tr
      const pos = this.getPos();
      tr.setSelection(new CellSelection(tr.doc.resolve(pos)))
      window.view.dispatch(tr)
    }
  }

  clearCellContents(){
    const node=this.node
    const pos=this.getPos()
    let tr = window.view.state.tr  
    tr.replaceWith(pos + 1, pos + node.nodeSize - 1, window.view.state.schema.nodes.paragraph.createAndFill());
    window.view.dispatch(tr)
  }

  setCellBackgroundColor(e,color){
    e.stopPropagation()
    e.preventDefault()
    const node=this.node
    const nodePos=this.getPos()
    let attributes={...node.attrs,background:color}
    let tr = window.view.state.tr  
    tr.setNodeMarkup(nodePos, null, attributes)
    window.view.dispatch(tr)
  }


  setRef(contentDOM) {
    if (contentDOM && this.contentDOM !== contentDOM) {
      this.contentDOM = contentDOM;
      this.dom.appendChild(this.contentDOM);
    }
  }



  selectRow(){
    let tr=window.view.state.tr
    const pos = this.getPos();
    const resolvePos = view.state.doc.resolve(pos);
    const tableNode = resolvePos.node(-1);
    const tablePos=getNodePos(tableNode)
    const tableMap=TableMap.get(tableNode)
    const cellRect=tableMap.findCell(pos-tablePos-1)
    const rowIndex=cellRect.top
    const firstCellPos = tableMap.map[rowIndex * tableMap.width];
    const lastCellPos = tableMap.map[(rowIndex + 1) * tableMap.width - 1];
    const tableNodePos=getNodePos(tableNode)
    tr.setSelection(new CellSelection(tr.doc.resolve(tableNodePos + firstCellPos + 1),
                                      tr.doc.resolve(tableNodePos + lastCellPos + 1)));
    window.view.dispatch(tr)
}


  selectColumn(){
    let tr=window.view.state.tr
    const pos = this.getPos();
    const resolvePos = view.state.doc.resolve(pos);
    const tableNode = resolvePos.node(-1);
    const tablePos=getNodePos(tableNode)
   // const rowIndex=0
    const tableMap=TableMap.get(tableNode)
    const cellRect=tableMap.findCell(pos-tablePos-1)
    const columnIndex=cellRect.left
    const firstCellPos = tableMap.map[columnIndex];
    const lastCellPos = tableMap.map[(tableMap.height - 1) * tableMap.width + columnIndex];
    const tableNodePos=getNodePos(tableNode)
    tr.setSelection(new CellSelection(tr.doc.resolve(tableNodePos + firstCellPos + 1),
                                    tr.doc.resolve(tableNodePos + lastCellPos + 1)));
    window.view.dispatch(tr)
  }



  checkIfFirstCol(view) {
    const pos = this.getPos();
    const resolvePos = view.state.doc.resolve(pos);
    const tableNode = resolvePos.node(-1);
    const tableMap = TableMap.get(tableNode);
    const colNumber = tableMap.colCount(pos - resolvePos.start(-1));

    if(colNumber==0){
      if(!this.firstColumnDom){
        this.firstColumnDom= createElementWithClass('span', 'doc-table-cellUIContainer');
        this.dom.appendChild(this.firstColumnDom)
        ReactDOM.render(<DocTableCellUI buttonType={'row'} selectRow={this.selectRow}/>,this.firstColumnDom)
      }
    }else{
      if(this.firstColumnDom){
        ReactDOM.unmountComponentAtNode(this.firstColumnDom)
        this.dom.removeChild(this.firstColumnDom)
        this.firstColumnDom=null
      }
    }
  }

  checkIfColHeader(view) {
    const pos = this.getPos();
    const resolvePos = view.state.doc.resolve(pos);
    const rowStart = pos - resolvePos.parentOffset - 1;
    const rowResolvedPos = view.state.doc.resolve(rowStart);
    rowResolvedPos.parentOffset === 0;
    const cellInFirstRow = rowResolvedPos.parentOffset === 0;
    if(cellInFirstRow){
      const tableNode = resolvePos.node(-1);
      const tableMap = TableMap.get(tableNode);
      const colNumber = tableMap.colCount(pos - resolvePos.start(-1));
      if(!this.colHeaderDom){
        this.colHeaderDom= createElementWithClass('span', 'doc-table-cellUIContainer');
        this.dom.appendChild(this.colHeaderDom)
        ReactDOM.render(<DocTableCellUI buttonType={'column'} colNumber={colNumber} selectColumn={this.selectColumn}/>,this.colHeaderDom)
      }else{
        // console.log('here rerender------------')
        // console.log(colNumber)
        ReactDOM.render(<DocTableCellUI buttonType={'column'} colNumber={colNumber} selectColumn={this.selectColumn}/>,this.colHeaderDom)
      }
    }else{
      if(this.colHeaderDom){
        ReactDOM.unmountComponentAtNode(this.colHeaderDom)
        this.dom.removeChild(this.colHeaderDom)
        this.colHeaderDom=null
      }
    }
  }

  ignoreMutation(record) {
    let dataAttribute = record.target.getAttribute('data-state')
    if (
      (record.type === 'attributes' &&
        record.target.classList.contains('doc-table-editRowColBtn'))
    ) {
      return true;
    }
     if(dataAttribute) {
      return true;
    }
    return false;
  }


  addAlignClassnames(){
    const node=this.node 
    const attrs=node.attrs 

    const {align}=attrs

    if(align=='left'){
      this.dom.classList.add('doc-table-cell--leftAlign')
      this.dom.classList.remove('doc-table-cell--rightAlign')
      this.dom.classList.remove('doc-table-cell--centerAlign')
    }else if(align=='right'){
      this.dom.classList.remove('doc-table-cell--leftAlign')
      this.dom.classList.add('doc-table-cell--rightAlign')
      this.dom.classList.remove('doc-table-cell--centerAlign')
    } else if(align=='center'){
      this.dom.classList.remove('doc-table-cell--leftAlign')
      this.dom.classList.remove('doc-table-cell--rightAlign')
      this.dom.classList.add('doc-table-cell--centerAlign')
    }
  }

  update(node, b, c) {
    if (node.type != this.node.type) return false;
    if (this.dom && !this.node.sameMarkup(node)) return false;
    this.checkIfFirstCol(this.view);
    this.checkIfColHeader(this.view);

    const {background}=node.attrs
    const oldBackground=this.node.attrs.background
    this.node = node;
    if(oldBackground != background){
      this.dom.classList.add(`doc-table-cell--bg--${background}`)
    }
    this.addAlignClassnames()
    
    return true;
  }

  destroy() {
    if(this.firstColumnDom){
      ReactDOM.unmountComponentAtNode(this.firstColumnDom)
    }
    if(this.colHeaderDom){
       ReactDOM.unmountComponentAtNode(this.colHeaderDom)
    }
     if(this.dom){
       ReactDOM.unmountComponentAtNode(this.dom)
    }
  }

}

export function columnHandles({} = {}) {
  const plugin = new Plugin({
    key,
    props: {
      nodeViews: {
        table_cell: (node, view, getPos) => new CellView(node, view, getPos),
        table_header: (node, view, getPos) => new CellView(node, view, getPos)
      },
      decorations(state) {
        const sel = state.selection;

        if (sel instanceof CellSelection) {
          const $pos = selectionCell(state);
          if (!$pos) {
            // In case there's no cell
            return null;
          }
          const tableNode = $pos.node(-1);
          const tableStart = $pos.start(-1) - 1;
          const decoration = Decoration.node(
            tableStart,
            tableStart + tableNode.nodeSize,
            {class: 'tableFocus'}
          );
          return DecorationSet.create(state.doc, [decoration]);
        }

        const decorations = [];

        state.doc.nodesBetween(sel.from, sel.to, (node, pos) => {
          if (node.type.name === 'table') {
            decorations.push(
              Decoration.node(pos, pos + node.nodeSize, {class: 'tableFocus'})
            );
            return false;
          }
          return true;
        });

        if (!decorations.length) {
          return null;
        }

        return DecorationSet.create(state.doc, decorations);
      }
    }
  });
  return plugin;
}
