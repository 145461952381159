const DocDrawingNodeSpec ={
    attrs: {
      height:{default:''},
      width:{default:''},
      svg:{default:''},
      tlDrawDoc:{default:''},
      nodeId:{default:''},
    },
    group: "block",
    parseDOM: [{tag: 'docDiagram'}],
    toDOM(node) {
      const div = document.createElement('div');
      div.className = 'doc-diagramWrapper'

      const div2 = document.createElement('div');
      div2.className = 'doc-diagramContainer'    
      
      // add width and height. width is maxed to 720
      const widthDocImage = 720
      const imageDisplayWidth = widthDocImage
      const imageDisplayHeight = (node.attrs.height / node.attrs.width) * imageDisplayWidth    
      
      div2.style.cssText = `width:${imageDisplayWidth}px;height:${imageDisplayHeight}px`



      div.appendChild(div2)

      

      const div3 = document.createElement('div');
      div3.className = 'doc-image'  

      div2.appendChild(div3)  

      const img = document.createElement('img');
      img.className='doc-image-image'
      img.src=node.attrs.deliveryUrl

      div3.appendChild(img)

      return div;



     // return ['div className:doc-imageWrapper']
   },
}
    
   

export default DocDrawingNodeSpec

