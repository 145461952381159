import {fetch,put,post,batchedFetch} from '../api'
import {bumpLinearIssueVersion} from '../prosemirror/utils/bumpLinearIssueVersion'
import {recalcProjectMilestones} from '../utils/milestones/recalcProjectMilestones'
import {recalcHeatmaps} from '../utils/calculateHeatmapForRoadmap'


export function fetchIssues(){
	return (dispatch) => batchedFetch(`/issues`)
	.then((response) => {
		dispatch({ type: 'FETCH_ISSUES_SUCCESS', response })
		bumpLinearIssueVersion()
		recalcProjectMilestones()
		recalcHeatmaps()
		return response
	})
	.catch((error) => {
		return error
	})
}

export function handleIssueCreatedPusher(issue){
	return (dispatch) => {
		dispatch({ type: 'CREATE_ISSUE_SUCCESS', issue})
		recalcProjectMilestones()
		recalcHeatmaps()
	}
}


export function handleIssueUpdatedPusher(issue){
	return (dispatch) => {
		dispatch({ type: 'UPDATE_ISSUE_SUCCESS', issue})
		recalcProjectMilestones()
		recalcHeatmaps()
	}
}



export function handleIssueDeletedPusher(issueId){
	return (dispatch) => {
		dispatch({ type: 'DELETE_ISSUE', issueId})
		recalcProjectMilestones()
		recalcHeatmaps()
	}
}




