import React from 'react'
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {updateQuarterlyRoadmap} from '../../../utils/roadmaps/updateQuarterlyRoadmap'


class RoadmapsHomePageQuarterEmpty extends React.Component{  	

	constructor(){
    super()   
    this.updateQuarterRoadmap=this.updateQuarterRoadmap.bind(this) 
    this.state={
    	showDropdown: false,
    }    
  }
		
  updateQuarterRoadmap(roadmapId){
  	this.setState({showDropdown:false})
  	updateQuarterlyRoadmap(roadmapId)
  }

	render(){		
		const {roadmaps}=this.props
	  return (	  	
  		<div className='roadmapsHomePage-quarterEmpty'>	
  	
  			<DropdownMenu.Root open={this.state.showDropdown}>
					    <DropdownMenu.Trigger asChild>
					    	<button onClick={()=>{this.setState({showDropdown: !this.state.showDropdown})}} className='roadmapsHomePage-quarterEmpty-dropdownBtn'>
	  							<div className='roadmapsHomePage-quarterEmpty-dropdownBtn-label'>
	  								Select Q3 Roadmap
	  							</div>
	  							<div className='roadmapsHomePage-quarterEmpty-dropdownBtn-iconContainer'>
	  								<Icon name='chevronDownMedium' />
	  							</div>
	  						</button>
					    </DropdownMenu.Trigger>   			 	
		   			 	<DropdownMenu.Content 
		   			 		sideOffset={1}
		   			 		align="end"
		   			 		alignOffset={-8}
		   			 		className='dropdownMenu dropdownMenu--roadmapHeader'
		   			 		onEscapeKeyDown={()=>{this.setState({showDropdown: false})}}
								onPointerDownOutside={()=>{this.setState({showDropdown: false})}}
								onInteractOutside={()=>{this.setState({showDropdown: false})}}
		   			 	> 
		   			 				   			
		   			 		{roadmaps.map((roadmap)=>{
		   			 			return(
		   			 				<DropdownMenu.Item onSelect={()=>{this.updateQuarterRoadmap(roadmap.roadmap_id)}} key={roadmap.roadmap_id}>
			        				{roadmap.name}
			        			</DropdownMenu.Item>
		   			 				)
		   			 		})}		   			 		
			        				        	
					    </DropdownMenu.Content>
						</DropdownMenu.Root>	  
  			
  		</div>
	  )
	}
}

export default RoadmapsHomePageQuarterEmpty
