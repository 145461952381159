import store from '../../store'
import filter from 'lodash/filter'



export function getMeetingConnectionsForProject(projectId) {   
  const state = store.getState()
  const connections = filter(state.meetingProjectConnections, {project: projectId});
  return connections;
}
