//mention user
const UserMentionNodeSpec ={
  attrs: {
    userId:{default:''},
    userName:{default:''}
  },
  inline: true,
  group: "inline",
  draggable: true,
  toDOM(node) {
    const div = document.createElement('div');
    div.className = 'message-para-mention'
    div.innerHTML=`@${node.attrs.userName}`
    return div;
  },
}
    
export default UserMentionNodeSpec