import React from 'react'
import { connect } from 'react-redux'
import * as Popover from '@radix-ui/react-popover';
import {searchTenorGifs} from '../../../../../actions/tenorGifs' 
import throttle from 'lodash/throttle'
import Icon from '../../../../misc/Icon'
import Masonry from 'react-masonry-css'


const suggestions=[
	"mind blown",
	"good luck",
	"yes",	
	"hmm",
	"excited",
	"shrug",
	"congratulations",
	"sigh",
	"surprised",	
	"ok",
	"wow",
	"fine",
]

const textStyles={
 position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

class MessageInputBoxAddGIF extends React.Component{ 


	constructor(){
		super()
		this.selectGif=this.selectGif.bind(this)
		this.onBackButtonClick=this.onBackButtonClick.bind(this)
		this.onInputChange=this.onInputChange.bind(this)
		this.selectTermFromEmptyState=this.selectTermFromEmptyState.bind(this)
		this.searchGifs=this.searchGifs.bind(this)
		this.searchGifs=throttle(this.searchGifs,100)
		this.state={
			searchInput:'',
			gifs:[],
			isSearching:false
		}
		this.previewGifs={}
	}
	
	componentDidMount(){
		let previewGifs={}
		suggestions.forEach((suggestion)=>{
			this.props.searchTenorGifs(suggestion,1).then((response)=>{
				this.previewGifs[suggestion]=response.results[0]
			})
		})
	}

	onInputChange(e){ //do debounced search
		this.setState({searchInput:e.target.value})
		this.searchGifs(e.target.value)
	}

	searchGifs(searchTerm){
		this.props.searchTenorGifs(searchTerm).then((response)=>{
			this.setState({gifs:response.results})
		})
	}

	selectGif(gif){
		const url=gif['media_formats'].mp4.url
		this.props.addGif(url)
	}

	onBackButtonClick(e){
		this.setState({searchInput:'',gifs:[]})
		var input = document.getElementById('gifSearchInput')
		if(input){
			input.focus()
		}
	}

	selectTermFromEmptyState(suggestion){
		this.setState({searchInput:suggestion})
		this.searchGifs(suggestion)
		var input = document.getElementById('gifSearchInput')
		if(input){
			input.focus()
		}
	}

	render(){			
			
		return (			
		  	<Popover.Root onOpenChange={()=>{this.setState({searchInput:'',gifs:[]})}}>
			    <Popover.Trigger className='message-inputBox-addMediaBtn message-inputBox-addMediaBtn--GIF'>
			    	<Icon name='gif' />
			    </Popover.Trigger>
			    <Popover.Portal>					    
			    <Popover.Content side="top" sideOffset={6} align="center"  className='popover message-inputBox-addMediaPopover'>
			      <div className='message-inputBox-addMediaPopover-search'>
			      	{this.state.searchInput &&
			      		<button className='message-inputBox-addMediaPopover-search-clearBtn' onClick={this.onBackButtonClick}>
			      			Clear
			      		</button>	
			     	 }
			      	<input 
			      		id={"gifSearchInput"}
			      		className='message-inputBox-addMediaPopover-search-input' 
			      		value={this.state.searchInput}
			      		onChange={(e)=>{this.onInputChange(e)}}
			      		placeholder="Search Tenor"
			      	/>			      
			      </div>


			      {!this.state.searchInput &&
			      	<div className='message-inputBox-addMediaPopover-resultsContainer message-inputBox-addMediaPopover-resultsContainer--categoriesGrid'>
			      			{suggestions.map((suggestion,i)=>{
			      				const previewGif=this.previewGifs[suggestion]
			      				let url=''
			      				if(previewGif){
			      					url=previewGif['media_formats'].tinygif.url
			      				}
			      				return(
			      					<div key={`gif-suggestion-${i}`} className='message-inputBox-addMediaBtn--GIF-category' onClick={()=>{this.selectTermFromEmptyState(suggestion)}}>
			      						<div className='message-inputBox-addMediaBtn--GIF-category-title'>
			      							{suggestion}
			      						</div>
			      						<img className='message-inputBox-addMediaBtn--GIF-category-preview' src={url}/>			      								      						
			      					</div>
			      				)
			      			})}
			      	</div>
			      }

			      {this.state.searchInput &&
			      	<Masonry
							  breakpointCols={2}
							  className="message-inputBox-addMediaPopover-resultsContainer message-inputBox-addMediaPopover-resultsContainer--GIFSearchResultsGrid"
							  columnClassName="message-inputBox-addMediaPopover-resultsContainer--GIFSearchResultsGrid-column"
							>							  			      

			      	{this.state.gifs.map((gif,i)=>{
			      		const previewUrl=gif['media_formats'].tinygif.url

			      		return(
			      			<div 
			      				key={`gif-suggestion-${i}`}
			      				className='message-inputBox-addMediaBtn--GIF-searchResult' key={gif.id}
			      				onClick={()=>{this.selectGif(gif)}}
			      				>
			      					<img className='message-inputBox-addMediaBtn--GIF-searchResult-preview' src={previewUrl}/>

			      			</div>
			      			)

			      	})}
			      
			  
			      </Masonry>
			    }
			    </Popover.Content>
			    </Popover.Portal>
			  </Popover.Root>	        
		)
	}
}
 


function mapStateToProps(state,ownProps) {
	return {}
}

export default connect(
	mapStateToProps,
	{searchTenorGifs
	})(MessageInputBoxAddGIF)
