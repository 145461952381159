import React from 'react'
import Icon from '../../misc/Icon'

class DocTableToggleHeaderColumnButton extends React.Component{  
	render(){
	  return (
	  
		  	<button onClick={this.props.toggleHeaderColumn} >
		  		Toggle Header Column		
		  	</button>
		  	
	
	  )
	}
}

export default DocTableToggleHeaderColumnButton
