import React from 'react'
import DocFigmaEmbedTopbar from './DocFigmaEmbedTopbar'
import { Spring, animated, config, easings } from 'react-spring'
import Icon from '../../misc/Icon'

const DOC_WIDTH = 650

class DocFigmaEmbedPlaceholder extends React.Component{  

	render(){ 
		const {docFigmaContainerWidth}=this.props
		const frameTitle = 'Home Feed Example'						
		const marginOffset = (DOC_WIDTH - docFigmaContainerWidth) / 2
		return(
			<div className='doc-figmaEmbed--placeholder'>				
				<div className='doc-figmaEmbed--placeholder-imageContainer'>
					<div className='doc-figmaEmbed--placeholder-imageContainer-figmaLogoContainer'>
						<Spring
		  				native								
							from={{
							opacity: 0.8,//transform: 'scale(1.0)'
							}}
							to={[
	          		{ opacity: 0.7,//transform: 'scale(0.95)' 
	          		},
	          		{ opacity: 0.8,//transform: 'scale(1)' 
	          		},	          		
	        		]}			        								
							loop
							config={{									
								duration: 2000,									
								easing: easings.easeInOutCubic,
							}}
						>
			  			{styles => 
								<animated.div style={styles} className='doc-figmaEmbed--placeholder-imageContainer-figmaLogo'>
									<Icon name='insertBarFigma' />
								</animated.div>
			  			}
			  		</Spring>	  		 			
					</div>
					
					<div className='doc-figmaEmbed--placeholder-imageContainer-loadingBar'>
		  			<Spring
		  				native								
							from={{
								opacity: 0.4,
								transform: 'translateX(-210px)'
							}}
							to={[
	          		{ 
	          			opacity: 0.05,
	          			transform: 'translateX(208px)' 
	          		},	          		
	        		]}								
							loop
							config={{									
								duration: 2000,									
								easing: easings.easeInOutCubic,
							}}
						>
			  			{styles => 
								<animated.div style={styles} className='doc-figmaEmbed--placeholder-imageContainer-loadingBar-ball' />
			  			}
			  		</Spring>	  		 			
			  	</div>
				</div>				
			</div>
		)
	}
}

export default DocFigmaEmbedPlaceholder
