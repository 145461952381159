//Middleware to keep state up to date between tabs by syncing actions
//e.g. if rename a set in one tab we want the updated set name to appear in all tabs
//-tabs listen for actions in local storage, if the action sourceId is different to the tabs sourceId then dispatch the action


//Give each tab an id so we can tell them apart and make sure we dont dispatch the action again on the tab that it originated in
const sourceId = Math.floor(Math.random() * 10000);

const storageKey = 'yarn-tab-sync'

function wrapAction(action) {
	return {
		action,
		sourceId,
		time: Date.now(),
		fromStorage:true,
	}
}

export function storageMiddleware() {
	return () => next => action => {

		if(action.syncAction===false){

		}
		if(action && action.syncAction===true){		//we only want to sync some actions 
		//if(action && action.syncAction!=false){		//For now lets sync everything
			const wrappedAction = wrapAction(action)
			localStorage.setItem(
				storageKey,
				JSON.stringify(wrappedAction)
			)
		}
		next(action)
	}

}


export function createOnStorage(store) {
	return () => {
		const wrappedAction = JSON.parse(localStorage.getItem(storageKey))
		if (wrappedAction && wrappedAction.sourceId !== sourceId) {

			store.dispatch({...wrappedAction.action,syncAction:false})
		}
	}
}
