import React from 'react'
import { withRouter} from 'react-router-dom'

import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import * as Dialog from '@radix-ui/react-dialog';
import AvatarImage from '../AvatarImage'
import { isCurrentUser } from '../../utils/isCurrentUser'
import filter from 'lodash/filter'
import store from '../../store'
import ActionCommandMenuRow from './ActionCommandMenuRow'
import sortBy from 'lodash/sortBy'
import { Spring, animated, config } from 'react-spring'
import {pingCursorToUser} from '../../utils/pingCursorToUser'
import {jumpToCursor} from '../../utils/jumpToCursor'


class ActionCommandMenu extends React.Component{  
 static getDerivedStateFromProps(props, state) {
		if(!state.orgMembers && (props.orgMembers && props.orgMembers.length)){
			const sortedMembers = sortBy(props.orgMembers, function(member){
      return member.name.toString().toLowerCase()
    })

			return ({orgMembers:props.orgMembers,filteredUsers:sortedMembers})
		}
		if(state.showActionCommandMenu && (!props.showActionCommandMenu)){
			const sortedMembers = sortBy(props.orgMembers, function(member){
      	return member.name.toString().toLowerCase()
    	})

			return ({showActionCommandMenu:props.showActionCommandMenu,filteredUsers:sortedMembers,value:'',activeIndex:0})
		}
		if(!state.showActionCommandMenu && (props.showActionCommandMenu)){
			return ({showActionCommandMenu:props.showActionCommandMenu})
		}
		return null
	}



 	constructor(props) {
    super(props)    
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.handleClick=this.handleClick.bind(this)
    let orgMembers=[]
    if(props.orgMembers){
    	orgMembers = sortBy(props.orgMembers, function(member){
      return member.name.toString().toLowerCase()
    })
    }


    this.state = {
    	value: '',
    	filteredUsers:orgMembers,
    	activeIndex:0,
    	activated: false,
   	};

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleClick(user){
		const {actionCommandMenuType}=this.props
		if(actionCommandMenuType==='ping'){				
				pingCursorToUser(user['user_id'])
				this.props.hideActionCommandMenu()
			}else if(actionCommandMenuType==="jumpToCursor"){				
				jumpToCursor(user['user_id'],this.props.history)
				this.props.hideActionCommandMenu()
			}
	}

	handleKeyDown(e){		
		if(e.keyCode==13 && this.props.showActionCommandMenu){
			const activeUser=this.state.filteredUsers[this.state.activeIndex]
			const {actionCommandMenuType}=this.props
			if(actionCommandMenuType==='ping'){
				pingCursorToUser(activeUser['user_id'])
				this.props.hideActionCommandMenu()
			}else if(actionCommandMenuType==="jumpToCursor"){
				jumpToCursor(activeUser['user_id'],this.props.history)
				this.props.hideActionCommandMenu()
			}

			//this.setState({activated: !this.state.activated})		
		}
		else if(e.keyCode==40 && this.props.showActionCommandMenu){ //down arrow
			const {filteredUsers,activeIndex}=this.state
			const newActiveIndex=Math.min(activeIndex+1,Math.max(0,filteredUsers.length-1))
			this.setState({activeIndex: newActiveIndex})		
		}
		else if(e.keyCode==38 && this.props.showActionCommandMenu){ //up arrow
			const {filteredUsers,activeIndex}=this.state
			const newActiveIndex=Math.max(0,activeIndex-1)
			this.setState({activeIndex: newActiveIndex})	
		}
	}


  handleChange(event) {
  	const {orgMembers}=this.props
  	const searchTerm=event.target.value
   
    let membersArray=[]
    if(orgMembers){
      Object.keys(orgMembers).forEach((key)=>{
        let member=orgMembers[key]       
        membersArray.push(member)
      })
    }

    const sortedMembers = sortBy(membersArray, function(member){
      return member.name.toString().toLowerCase()
    })

    let filteredArray = []    

    // to do support N returning nicole
    sortedMembers.forEach((member)=>{
		if((member.name.toLowerCase()).startsWith(searchTerm)){
			filteredArray.push(member)
		}
		})
    
    this.setState({value: searchTerm,filteredUsers:filteredArray});


  }

	sendCastingInvitation(user){
		if(this.props.doc){
			const docId=this.props.doc['doc_id']
			store.dispatch({type:'SEND_CASTING_INVITATION',user:user,docId:docId})
			this.props.hideSpecialKMenu()
		}
	}


	render(){			

		const {value,filteredUsers,activeIndex} = this.state
		const {hideActionCommandMenu,actionCommandMenuType,orgMembers} = this.props

		// actionCommandMenuType
		// ping
		// jumpToCursor
		// directMessage

		//
    const pingType = 'doc'    
    // doc
    // later
    // selection
    // image (also used for Figma)
    // drawing

		let inputEmpty = true

		if(value.length > 0){
			inputEmpty = false
		}	


		const noResults = filteredUsers.length == 0
	
		let autoCompleteLabel // this will actually be the active row (and also perfectly matches, which is built in)		

		if(filteredUsers[0]){
			autoCompleteLabel = filteredUsers[0].name
		}

    let placeholderLabel 
    let inputUIIcon

    if(actionCommandMenuType === 'directMessage'){
    	placeholderLabel = "Open doc conversation"
    	inputUIIcon = 'commentBubbleOutline'
    }
    if(actionCommandMenuType === 'ping'){
    	placeholderLabel = "Ping doc to teammate"
    	inputUIIcon = 'broadcast'
    }
    if(actionCommandMenuType === 'jumpToCursor'){
    	placeholderLabel = "Jump to teammate"	
    	inputUIIcon = 'jump'
    }
		

    const docChannel = 'private' // assuming we're still doing this?

    let directMessageGuidanceDetail = ''
    if(actionCommandMenuType === 'directMessage' && docChannel === 'private'){
    	directMessageGuidanceDetail = '(like this one)'
    }

	  return (		
	  	<React.Fragment>
			  <Dialog.Root id='specialKMenu' open={this.props.showActionCommandMenu}>			    		   
			    <Dialog.Portal>
			      <Dialog.Overlay className='dialogOverlay dialogOverlay--invisible dialogOverlay--actionCommandMenu'>
				      <Dialog.Content 
				      	className={'actionCommandMenu ' + (actionCommandMenuType && `actionCommandMenu--actionCommandMenuType--${actionCommandMenuType}`)}
				      	onEscapeKeyDown={hideActionCommandMenu}
				      	onPointerDownOutside={hideActionCommandMenu}
				      	onInteractOutside={hideActionCommandMenu}
				      >
								      					  	
					  		<div className={'actionCommandMenu-input ' + (inputEmpty ? ' actionCommandMenu-input--empty' : ' actionCommandMenu-input--notEmpty')}>
					  			<input 
										placeholder={placeholderLabel}
										className='actionCommandMenu-input-input'
										value={this.state.value}
										onChange={()=>{this.handleChange}}
										autoFocus
									/>
									<div className='actionCommandMenu-input-UI'>
										
										<div className='actionCommandMenu-input-UI-iconBoxContainer'>
											<div className='actionCommandMenu-input-UI-iconBox'>
												<Icon name={inputUIIcon} />
											</div>
										</div>
										
										{!inputEmpty && autoCompleteLabel &&
										<div className='actionCommandMenu-input-UI-autocompleteLabel'>
											{autoCompleteLabel}
										</div>
										}										
							  		
					

							  		
									</div>
					  		</div>	  	
					  		<div className='actionCommandMenu-resultsList'>
					  												
					  			{orgMembers &&
						  			<React.Fragment>
							  			{filteredUsers.map((user,i)=>{
							  				
							  		{/*		const userIsCurrentUser = isCurrentUser(user['user_id'])*/}
							  				const isActive=i===activeIndex

							  					return(
													<ActionCommandMenuRow
														key={user['user_id']}																
														active={isActive} // refactor
														onClickFunction={()=>{this.handleClick(user)}}
														action={actionCommandMenuType}
														userName={user.display_name}	
														user={user}
														filteredResults={!inputEmpty}
														pingType={pingType}														
													/>														
							  				)
						  			
							  				
							  			})}
										</React.Fragment>					  	
							  		}					  			
						  	
									{noResults &&
							  		<div className='actionCommandMenu-resultsList-noResults'>
							  			No teammates found.
							  		</div>
						  		}
		
						  		<div className='actionCommandMenu-resultsList-actionGuidanceContainer'>								

							  		{actionCommandMenuType === 'ping' &&
								  		<div className='actionCommandMenu-resultsList-actionGuidance'>
								  			<emph>Pinging the doc instantly sends a notification with the doc link.</emph> {/*If they don’t have Yarn open, we’ll send a Slack message with the link instead.*/}
								  		</div>
							  		}

							  		{actionCommandMenuType === 'jumpToCursor' &&
								  		<div className='actionCommandMenu-resultsList-actionGuidance'>
								  			<emph>Instantly jump to the where a teammate is in Yarn. </emph> They’ll be notified.
								  		</div>
							  		}
							  	</div>

					  		</div>		  		

				      </Dialog.Content>
			      </Dialog.Overlay>
			    </Dialog.Portal>
			  </Dialog.Root>	
			</React.Fragment>

	  )
	}
}



function mapStateToProps(state,ownProps) {
	let members=[]
	if(state.orgMembers && state.user){
		state.orgMembers.forEach((member)=>{
			if(member['user_id']!=state.user['user_id']){
				members.push(member)
			}


		})
	}


	return {
		orgMembers:members
	}	
}

export default withRouter(connect(mapStateToProps,
	{	
		}
)(ActionCommandMenu))

