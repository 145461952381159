import filter from 'lodash/filter'
import store from '../../store';	
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import {isCurrentUser} from '../isCurrentUser'
import {userIsFollower} from '../userIsFollower'
import {currentUserIsInThread} from '../threads/currentUserIsInThread'

//21st Nov update logic to not include docs you are not following in bage cound



// March 16th update to include thread stuff



export function calculateAppNotificationCount(){
	let notificationCount=0
	const state = store.getState()
	const docs=state.docs
	docs.forEach((doc)=>{
		const docNotificationCount=calculateDocNotificationCount(doc['doc_id'],doc)
		notificationCount+=docNotificationCount
	})
	
	return notificationCount
}


	// docMessages.forEach((message,i)=>{
	// 		let newMessageObj={...message}
	// 		const createdAt=message.created_at
	// 		const isCurrentUser=message.created_by===currentUser.user_id
	// 		let isUnread
	// 		if(!message.thread_parent){
	// 			isUnread=(!isCurrentUser && (createdAt>recentActivityTime || !recentActivityTime))
	// 		}else{
	// 			//if is the thread 
	// 			const userIsInThread=currentUserIsInThread(message.thread_parent)
	// 			console.log(`is in thread for ${message.message_id}`)
	// 			console.log('--------------------------------')
	// 			console.log(userIsInThread)
	// 			if(userIsInThread){
	// 				const threadActivityItem=find(recentActivities,{item_id:message.thread_parent})
	// 				let threadActivityTime
	// 				if(threadActivityItem){
	// 					threadActivityTime=threadActivityItem.activity_time
	// 				}
	// 				isUnread=(!isCurrentUser && (createdAt>threadActivityTime || !threadActivityTime))
	// 			}

	// 		}



export function calculateDocNotificationCount(docId,doc) {
	const isFollower=userIsFollower(docId)
	let notificationCount=0
	if(userIsFollower(docId)){
		const state = store.getState()
		//const messages=filter(state.messages,{parent_doc:docId})
		const messages=filter(state.messages,{parent_doc:docId,auto_created:false})
		if(messages){
			messages.forEach((message)=>{
				let isUnread=false
				
				if(!message.thread_parent){
					let recentActivityItem = find(state.recentActivities,function(activity){
						return `${activity['item_id']}`==`${docId}`
					})
			
					let lastActiveTime=null
					if(recentActivityItem){
						lastActiveTime=recentActivityItem['activity_time']
					}
					isUnread=message['created_at'] && (!lastActiveTime || message['created_at']>lastActiveTime)//if no lastActiveTime then all are unread
					if(isCurrentUser(message['created_by'])){
						isUnread=false
					}
				}
				else{
					const userIsInThread=currentUserIsInThread(message.thread_parent)
					if(userIsInThread){

						let recentActivityItem = find(state.recentActivities,function(activity){
							return `${activity['item_id']}`==`${message.thread_parent}`
						})
		
						let lastActiveTime=null
						if(recentActivityItem){
							lastActiveTime=recentActivityItem['activity_time']
						}
						isUnread=message['created_at'] && (!lastActiveTime || message['created_at']>lastActiveTime)//if no lastActiveTime then all are unread
					
						if(isCurrentUser(message['created_by'])){
							isUnread=false
						}
					}
				}
			if(isUnread){
				notificationCount+=1
			}				
			})
		}
	}
	// if(docId=='1478321082'){
	// 		console.log(`notification count is---------- ${notificationCount}`)
	// }

	return notificationCount	
}
