import store from '../store'
import {addLinkToLinear} from '../actions/docs'


export function addDocLinkToLinear(docId) {
	return store.dispatch(addLinkToLinear(docId))
}



export function handleDocLinkAddedPusher(docId) {
	store.dispatch({ type: 'LINK_ADDED_TO_LINEAR', docId })
}
