//Forces speaker headers to rerender when speaker assignent changes
export function bumpTranscriptSpeakerVersion() {
	if(window.transcriptView && window.transcriptView.state){
		let tr = window.transcriptView.state.tr
		window.transcriptView.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.transcriptSpeakerVersion || node.attrs.transcriptSpeakerVersion===0){
				tr.setNodeMarkup(pos, null, {...node.attrs,transcriptSpeakerVersion:node.attrs.transcriptSpeakerVersion+1})
			}
		})
		tr.setMeta('addToHistory', false)  
		window.transcriptView.dispatch(tr)
	}
}