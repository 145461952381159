import React from 'react'
import ReactDOM from 'react-dom'
import { escapeRegExp } from "lodash";
import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view"
import {getNewState} from './utils/getNewState'
import {getSuggestions} from './utils/getSuggestions'
import {getMatch} from './utils/getMatch'
import schema from '../../messageSchema'
import MessageInsertMentionsMenuReactComponent from './MessageInsertMentionsMenuReactComponent'
import store from '../../../../store'

const char="@"
const triggerRegex = new RegExp(`${escapeRegExp(char)}([^${escapeRegExp(char)}]*)$`);
const suggestionTextClass="message-para-inlineMentionSearchBar" //add a decoration around matched text- used to position the dropdown
const activeClass='suggestionMenu-item--active'


//positioning stuff
//when show menu is active

//
// POSITIONING IS SIMPLIFED BECAUSE ALWAYS BOTTOM RIGHT AND POINTING UP

function calcOrderedSuggestionsAndOffset(view,suggestions){
	const node = view.domAtPos(view.state.selection.$from.pos)
	const paraDOM = node.node
	const textDOM = paraDOM.querySelector("." + suggestionTextClass) //This is span added around the suggestin text e.g. "@JA"-Why not select element by id
	let offset
	if(textDOM){
		offset = textDOM.getBoundingClientRect()
	}
	
	let orderedSuggestions=suggestions
	// there was old code here about shortening list, we don't need because position is fixed bottom and we don't supports small heights
	
	return {orderedSuggestions:orderedSuggestions,offset:offset,textDOM:textDOM}
}

function addStylesToEl(el,offset,textDOM){
	el.style.position = "fixed";
	
	// REFACTOR TO SUPPORT WIDE SCREENS
	// if(offset){
	// 	el.style.left = offset.left + "px";
	// }

	const widthMentionsMenu = 380
	el.style.width = widthMentionsMenu + "px";

	const menuRightEdgeBuffer = 20 // this is distance between right edge of menu and screen

	const leftOverhangToCursor = 12 // we want to overhang cursor a little bit so text is aligned

	// want to put X position at left of offset
	// but it can't overflow the page
	// so the max left is window inner - widthMentionsMenu - buffer 
	const maxLeftOffset = window.innerWidth - widthMentionsMenu - menuRightEdgeBuffer	

	let leftOffset 
	if(offset){
		leftOffset = Math.min(offset.left, maxLeftOffset) - leftOverhangToCursor
		el.style.left = leftOffset + "px";	
	}	

	el.style.zIndex = 5000;

	// VERTICAL OFFSET	
	let bottomOffset

	if(offset){
		bottomOffset = window.innerHeight - offset.top + 4 // buffer get to the top, then add a bit of buffer      
		el.style.bottom = bottomOffset + "px";	
	}
	
	el.style.display = "block"
	return el
}

export function messageMentionsPlugin(pluginKey) {
	let el = document.createElement("div")//dropdown element
	el.addEventListener("mousedown", function(e) { //prevent clicking defocusing selection item
		e.preventDefault()
		e.stopPropagation()
	})

	const showList = function(view, state, suggestions) {		
		store.dispatch({type:"SHOW_MESSAGE_MENTION_DROPDOWN"})

		const calced=calcOrderedSuggestionsAndOffset(view,suggestions)
		const {orderedSuggestions,offset,textDOM}=calced
		
		function selectIndex(index){
			select(view,state,orderedSuggestions,index)
			view.focus()
		}
		ReactDOM.render(	
			<MessageInsertMentionsMenuReactComponent
				suggestions={orderedSuggestions}
				selectIndex={selectIndex}
				hideList={hideList}
				text={state.text}
			/>, el)
		document.getElementById("message_input_box").appendChild(el)
		addStylesToEl(el,offset,textDOM)
	}

	const hideList = function() {
		el.style.display = "none"
		el.style.bottom = null
		el.style.top = null
		ReactDOM.unmountComponentAtNode(el)
		store.dispatch({type:"HIDE_MESSAGE_MENTION_DROPDOWN"})
	}

	const select = function(view,state,suggestions,index) {
		const item = suggestions[index]
		let tr = view.state.tr
		if(item.user_id){
			const text=item.name
			const user_id = item.user_id
			const userName = item.display_name
			//add mention node
			let type = schema.nodes.userMention	
			const node = type.createAndFill({userId:user_id,userName:userName})
			tr.replaceWith(state.range.from, state.range.to, node)
			// const text=`${\u00a}`
			tr.insertText(`\u{00A0}`) //blank space

			tr.setMeta("messageMentionPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}else if(item.issue_id){
			let type = schema.nodes.linearIssueMention		
			const node = type.createAndFill({issueId:item.issue_id})
			tr.replaceWith(state.range.from, state.range.to, node)
			tr.insertText(`\u{00A0}`) //blank space
			tr.setMeta("messageMentionPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}
		else if(item.project_id){
			let type = schema.nodes.linearProjectMention		
			const node = type.createAndFill({projectId:item.project_id})
			tr.replaceWith(state.range.from, state.range.to, node)
			tr.insertText(`\u{00A0}`) //blank space
			tr.setMeta("messageMentionPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}
		else if(item.doc_id){
			let type = schema.nodes.internalLink		
			const node = type.createAndFill({docId:item.doc_id})
			tr.replaceWith(state.range.from, state.range.to, node)
			tr.insertText(`\u{00A0}`) //blank space
			tr.setMeta("messageMentionPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}
	}

	return new Plugin({
		key: pluginKey,
		state: {
			init() {
				return getNewState();
			},
			apply(tr, prev,oldState,newState) { //TODO test this with colllab
				const next = { ...prev };
				const { selection } = tr
				if (
					selection.from === selection.to &&// only show popup if selection is a blinking cursor         
					!(tr.getMeta("messageMentionPlugin") && tr.getMeta("messageMentionPlugin").deactivate) &&// deactivate popup from view (e.g.: choice has been made or esc has been pressed)        
					!tr.getMeta("focus") &&// deactivate because a mouse event occurs (user clicks somewhere else in the document)
					!tr.getMeta("blur") &&
					!tr.getMeta("pointer")
				) {
					// Reset active state if we just left the previous suggestion range (e.g.: key arrows moving before /)
					if (prev.active && selection.from <= prev.range.from) {
						next.active = false;
					} else if (tr.getMeta("messageMentionPlugin") && tr.getMeta("messageMentionPlugin").activate) {
						// Start showing suggestions. activate has been set after typing a "/" (or whatever the specified character is), so let's create the decoration and initialize
						next.range = {
							from: selection.from - 1,
							to: selection.to,
						};
						next.query = "";
						next.active = true;
					} else if (prev.active) {       			
						const $position = selection.$from;
						const match = getMatch($position,triggerRegex)
						if(match) {// if match found update state       
							next.range = match.range;
							next.active = true;
							next.text = match.queryText;
						}
					}
				} 
				else {
					next.active = false;
				}
				return next
			}
		},
		props: {
			handleKeyDown(view, e) {
				var state = this.getState(view.state)
				if(e.key==='@'){ //
					let tr=view.state.tr
					const {selection}=tr
					const $position = selection.$from;
					const text = $position.doc.textBetween($position.pos-1, $position.pos);					//check is an empty space before @ symbol TODO this is a bit dodgy 
					const trimmed=text.trim()
					const length=trimmed.length
					if(length==0){
						tr.setMeta("messageMentionPlugin", { activate: true})
						view.dispatch(tr)
					}					
				}
				if(!state.active) { // don't handle if no suggestions or not in active mode
					return false
				}else if ( e.keyCode === 13) { //enter key
					if(state.suggestions.length){
						return true
					}else{
						e.stopPropagation()
						e.preventDefault()
						let tr=view.state.tr
						tr.setMeta("messageMentionPlugin", { deactivate: true})
						view.dispatch(tr)
						return true
					}
					
				}else if ( e.keyCode === 27) { //escape key
					let tr=view.state.tr
					tr.setMeta("messageMentionPlugin", { deactivate: true})
					view.dispatch(tr)
					return true
				}else if (e.keyCode===39) { //right arrow
					let tr=view.state.tr
					tr.setMeta("messageMentionPlugin", { deactivate: true})
					view.dispatch(tr)
					return false
				}
				else if (e.keyCode===37) { //left arrow- prevent left arrow when active
					if(!state.text){
						let tr=view.state.tr
						tr.setMeta("messageMentionPlugin", { deactivate: true})
						view.dispatch(tr)
					}
					return true
				}else if (e.keyCode===8) { //backspace if backspace at the begining then it should dectivate
					if(!state.text){
						let tr=view.state.tr
						tr.setMeta("messageMentionPlugin", { deactivate: true})
						view.dispatch(tr)
						return true
					}
				}
			},     
			decorations(editorState) {//to decorate the currently active suggestion text in ui (and used for positioning dropdown)
				const state=this.getState(editorState)
				const { active, range } = state
				if (!active){
					return null
				}else{
					if(!state.text){
						return DecorationSet.create(editorState.doc, [
							Decoration.inline(range.from, range.to, {
								nodeName: "span",
								class: `${suggestionTextClass} ${suggestionTextClass}--empty`
							})
						])
					}else{
						return DecorationSet.create(editorState.doc, [
							Decoration.inline(range.from, range.to, {
								nodeName: "span",
								class: suggestionTextClass
							})
						])
					}
				}
			}
		},
		view() {// To track down state mutations and update dropdown
			return {
				update: (view,prevState )=> {
					const state = this.key.getState(view.state)			
					let focused=false
					if(window.messageInputView){
						focused=window.messageInputView.hasFocus()
					}
					if (!state.active || !focused){
						hideList()
					}else{
						getSuggestions(state.text, function(suggestions) {
							state.suggestions = suggestions
							state.index=0

							if(suggestions.length){
								showList(view, state, suggestions)
							}else{
								hideList()
							}
						})
					}	
				},
				destroy: ()=>{
					hideList()
				}
			}
		}
	})
}