import {fetch,put,post} from '../api'


export function fetchTeams(){
	return (dispatch) => fetch(`/teams`)
	.then((response) => {
		dispatch({ type: 'FETCH_TEAMS_SUCCESS', response })
		return response
	})
	.catch((error) => {
		return error
	})
}


export function syncTeams(){
	return (dispatch) => put(`/teams/sync`)
	.then((response) => {
		dispatch({ type: 'FETCH_TEAMS_SUCCESS', response })
		return response
	})
	.catch((error) => {
		return error
	})
}
