import React from 'react'
import Icon from '../../misc/Icon'
import { Spring, Transition, animated, config, easings} from 'react-spring'
import MeetingParticipantList from '../../meetings/meetingParticipantList/MeetingParticipantList'
import { getMeetingForId } from '../../../utils/meetings/getMeetingForId'

class SetupTeamSyncModalDayOfWeekToggleBtn extends React.Component{  		

	
	render(){	
		const {label, active} = this.props
		
	  return (	  	
  		<button className={'setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn ' + (active ? ' setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn--active ' : ' setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn--inactive ')}>
  			<div className='setupTeamSyncModal-topRow-dateTime-dayOfWeekToggle-btn-label'>
  				{label}
  			</div>
  		</button>			
	  )
	}
}

class SetupTeamSyncModalSubmitBtn extends React.Component{  	
	render(){	
		const {newTeamSync, submitLoading, onClickFunction, disabled} = this.props		
		let buttonLabel = 'Edit Meeting'
		if(newTeamSync){
			buttonLabel = 'New Weekly Sync'
		}
		
		return (	  	  
			<div className='setupTeamSyncModal-submitBtnContainer' >       	
      		{!submitLoading && 
	        	<button id={'setupTeamSync-submit-button'} disabled={disabled} className='setupTeamSyncModal-submitBtn' onClick={this.props.onClickFunction}>
	        		<div className='setupTeamSyncModal-submitBtn-iconContainer'>
	        			<Icon name='meetingTeamSyncOutlineMedium' />
	        		</div>
	        		<div className='setupTeamSyncModal-submitBtn-label'>
	        			{buttonLabel}
	        		</div>	        		  	
	        	</button>
        	}

        	{submitLoading && 
        	<button className='setupTeamSyncModal-submitBtn'>        		        		
        		<div className='setupTeamSyncModal-submitBtn-loadingBar'>
			  			<Spring
			  				native								
								from={{
									opacity: 0.4,
									transform: 'translateX(-120px)'
								}}
								to={[
		          		{ 
		          			opacity: 0.05,
		          			transform: 'translateX(80px)' 
		          		},	          		
		        		]}								
								loop								
								config={{									
									duration: 800,									
									easing: easings.easeInOutCubic,
								}}
							>
				  			{styles => 
									<animated.div style={styles} className='setupTeamSyncModal-submitBtn-loadingBar-ball' />
				  			}
				  		</Spring>	  		 			
			  	</div>
        			
        	</button>
        	}
      </div>
    )
	}
}

 

class SetupTeamSyncModalContents extends React.Component{  		

	constructor(props){
    super(props)    
    this.handleTitleChange=this.handleTitleChange.bind(this)
        
    this.state={
      titleValue: props.title,      
      submitLoading:false  
    }    
  }

  handleTitleChange(event) {    
    this.setState({titleValue: event.target.value})      
  }


	render(){	
		const {showModal, openModal, newTeamSync} = this.props

		const exampleMeetingId = '1818690405'
		const exampleMeeting = getMeetingForId(exampleMeetingId)
		
	  return (
	  	<React.Fragment>
	  		<div className='setupTeamSyncModal-topRow'>
	  			{/*<div className='setupTeamSyncModal-topRow-modalLabel'>
	  				<div className='setupTeamSyncModal-topRow-modalLabel-iconContainer'>
	  					<Icon name='meetingTeamSyncOutlineMedium' />
	  				</div>
	  				<div className='setupTeamSyncModal-topRow-modalLabel-label'>
	  					Setup Weekly Sync
	  				</div>
	  			</div>
	  			<div className='setupTeamSyncModal-topRow-modalLabel-hSpacer' />
	  			*/}
	  			<div className='setupTeamSyncModal-topRow-participantList'>
	  				{/*<div className='setupTeamSyncModal-topRow-participantList-label'>
	  					Participants
	  				</div>
	  				*/}
	  				<div className='setupTeamSyncModal-topRow-participantList-container'>
	  					<MeetingParticipantList 
	  						meeting={exampleMeeting}
	  						setupTeamSyncModal
	  					/>
	  				</div>

	  			</div>
	  		</div>
	  		<div className='setupTeamSyncModal-titleRow'>
	  			<input 
	  				id='setupTeamSync-title-input'	  					  				  			
						placeholder='Untitled Weekly Sync'
	  				className='setupTeamSyncModal-titleRow-input'
						value={this.state.titleValue}					  						
						onChange={this.handleTitleChange}
						autoComplete="off"
						autoCorrect="off"
						spellCheck={false}
						maxLength={1024} // can be very long in GCal so might as well support now
	  			/>
	  		</div>
	  		<div className='setupTeamSyncModal-dateTimeRow'>	  			
	  			<div className='setupTeamSyncModal-dateTimeRow-label'>
	  				Every week
	  			</div>
	  			<div className='setupTeamSyncModal-topRow-dateTimeRow-dayOfWeekToggle'>
	  				<SetupTeamSyncModalDayOfWeekToggleBtn 
	  					label='Mon'
	  				/>
	  				<SetupTeamSyncModalDayOfWeekToggleBtn 
	  					label='Tue'
	  				/>
	  				<SetupTeamSyncModalDayOfWeekToggleBtn 
	  					label='Wed'
	  				/>
	  				<SetupTeamSyncModalDayOfWeekToggleBtn 
	  					label='Thu'	  					
	  				/>
	  				<SetupTeamSyncModalDayOfWeekToggleBtn 
	  					label='Fri'	
	  					active  					
	  				/>
	  				<SetupTeamSyncModalDayOfWeekToggleBtn 
	  					label='Sat'	  					
	  				/>
	  				<SetupTeamSyncModalDayOfWeekToggleBtn 
	  					label='Sun'	  					
	  				/>	 
	  			</div>
	  			<div className='setupTeamSyncModal-topRow-dateTimeRow-time'> 			
	  				<button className='setupTeamSyncModal-topRow-dateTimeRow-time-btn setupTeamSyncModal-topRow-dateTimeRow-time-btn--startTime'>
	  					<div className='setupTeamSyncModal-topRow-dateTimeRow-time-btn-iconContainer'>
	  						<Icon name='clock' />
	  					</div>
	  					<div className='setupTeamSyncModal-topRow-dateTimeRow-time-btn-label'>
	  						4:00PM
	  					</div>
		  			</button>
		  			<button className='setupTeamSyncModal-topRow-dateTimeRow-time-btn setupTeamSyncModal-topRow-dateTimeRow-time-btn--endTime'> 			
		  				<div className='setupTeamSyncModal-topRow-dateTimeRow-time-btn-iconContainer'>
	  						<Icon name='arrowRight' />
	  					</div>
	  					<div className='setupTeamSyncModal-topRow-dateTimeRow-time-btn-label'>
	  						4:30PM
	  					</div>
		  			</button>
	  			</div>
	  		
	  		</div>

	  		<div className='setupTeamSyncModal-vSpacer' />

	  		<div className='setupTeamSyncModal-docTemplateRow'>
	  			<div className='setupTeamSyncModal-docTemplateRow-label'>
	  				Doc Template
	  			</div>
	  			<button className='setupTeamSyncModal-docTemplateRow-btn'>
	  				<div className='setupTeamSyncModal-docTemplateRow-btn-iconContainer'>
	  					<Icon name='docAlt' />
	  				</div>
	  				<div className='setupTeamSyncModal-docTemplateRow-btn-label'>
	  					Participant sections
	  				</div>
	  			</button>
	  		</div>

	  		<SetupTeamSyncModalSubmitBtn
	  			onClickFunction={()=>{console.log('SAVE STUFF')}}
	  			newTeamSync={newTeamSync}
	  			disabled={this.state.titleValue === ''}
	  			submitLoading={false}
	  		/>

	  		

			</React.Fragment>
	  )
	}
}
 
export default SetupTeamSyncModalContents