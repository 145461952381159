//window.location.assign(url)

import store from '../store'


function getOrgUrlKey(){
	const state=store.getState()
	let urlKey
	if(state.organization){
		urlKey=state.organization.url_key
		if(!urlKey){
			urlKey=state.organization.name.toLowerCase()
		}
		//orgName=state.organization.name.toLowerCase()
	}
	return urlKey
}

export function openIssueInLinear(issueIdentifier) {
	const urlKey=getOrgUrlKey()
	// const url=`linear://${orgName}/issue/${issueIdentifier}`
	const url=`linear://${urlKey}/issue/${issueIdentifier}`
	// window.location.assign(url)
	if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:url})     
    }
    else{
      window.open(url,'_blank')
    }
}

export function openRoadmapInLinear(roadmapSlug) {
	const urlKey=getOrgUrlKey()
	const url=`linear://${urlKey}/roadmap/${roadmapSlug}`

	// window.location.assign(url)
	if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:url})     
    }
    else{
      window.open(url,'_blank')
    }
}



export function openProjectInLinear(projectSlug) {
	const urlKey=getOrgUrlKey()
	const url=`linear://${urlKey}/project/${projectSlug}`
	// window.location.assign(url)
	if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:url})     
    }
    else{
      window.open(url,'_blank')
    }
}

export function openUserProfileInLinear(displayName) {	
	const urlKey=getOrgUrlKey()
	const url=`linear://${urlKey}/profiles/${displayName}`
	// window.location.assign(url)
	if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:url})     
    }
    else{
      window.open(url,'_blank')
    }
}

export function openTeamInLinear(linear_key) {		
	const urlKey=getOrgUrlKey()
	const url=`linear://${urlKey}/team/${linear_key}/active`
	// window.location.assign(url)
	if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:url})     
    }
    else{
      window.open(url,'_blank')
    }
}