import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import {archiveContact} from '../actions/contacts'
import Icon from '../components/misc/Icon'
import AvatarImage from '../components/AvatarImage'
import {getOrgMembers} from '../utils/getOrgMembers'
import {isCurrentUser} from '../utils/isCurrentUser'
import {getCurrentUser} from '../utils/getCurrentUser'
import {getVersionNumber} from '../utils/getVersionNumber'
import SettingsFigmaIntegration from '../components/integrations/SettingsFigmaIntegration'
import SettingsGoogleCalendarIntegration from '../components/integrations/SettingsGoogleCalendarIntegration'
import {logout} from '../actions/auth'
import {toggleTheme} from '../utils/toggleTheme'
import ReactGA from 'react-ga4'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const JOIN_SLACK_URL="https://join.slack.com/t/yarncustomers/shared_invite/zt-1pi4wxy3l-Hjbv1loRn8cXpNA07CeGjw"

class SettingsPageTeammateRow extends React.PureComponent{  	
	render(){	
		const {user} = this.props		
	  return (	 	  	
			<div className="settingsPage-content-section--teammates-row" >
				<div className='settingsPage-content-section--teammates-row-avatarContainer'>
					<AvatarImage 
						className='settingsPage-content-section--teammates-row-avatar'
						avatarUrl={user.avatar}
					/>
				</div>
				<div className='settingsPage-content-section--teammates-row-username'>
					{user.display_name}			
				</div>
				<div className='settingsPage-content-section--teammates-row-email'>
					{user.email}
				</div>
			</div>
	  )
	}
}

class SettingsPageContactRow extends React.PureComponent{  	
	render(){	
		const {contact} = this.props		
		let primaryLabel
		let secondaryLabel
		let tertiaryLabel 
		if(contact.name){
			primaryLabel = contact.name
			if(contact.company){
				secondaryLabel = contact.company	
			}
			if(contact.email){
				tertiaryLabel = contact.email
			}
		} 
		if(!contact.name){
			primaryLabel = contact.email
			if(contact.company){
				secondaryLabel = contact.company	
			}			
		} 
	  return (	 	  	
			<div className="settingsPage-content-section--contacts-row" >
				<div className='settingsPage-content-section--contacts-row-avatarContainer'>
					<AvatarImage 
						className='settingsPage-content-section--contacts-row-avatar'
						contactId={contact.contact_id}
						sideLength={28}
					/>
				</div>
				<div className='settingsPage-content-section--contacts-row-primary'>
					{primaryLabel}
				</div>
				{secondaryLabel &&
					<div className='settingsPage-content-section--contacts-row-secondary'>
						{secondaryLabel}
					</div>
				}
				{tertiaryLabel && 
					<div className='settingsPage-content-section--contacts-row-tertiary'>
						{tertiaryLabel}
					</div>
				}
				<DropdownMenu.Root>
					<DropdownMenu.Trigger className='settingsPage-content-section--contacts-row-dropdownBtn'>
						<Icon name='ellipses' />						
					</DropdownMenu.Trigger>   			 	
					<DropdownMenu.Content align="end" alignOffset={-12} className='dropdownMenu dropdownMenu--settingsContact'>
						<DropdownMenu.Item onClick={()=>{this.props.archiveContact(this.props.contact.contact_id)}} className='dropdownMenu-item--warning'>
							Delete Contact
						</DropdownMenu.Item>					        										       
					</DropdownMenu.Content>
				</DropdownMenu.Root>				
			</div>
	  )
	}
}


class SettingsPageThemeButton extends React.PureComponent{  	
	render(){	
		const {active, theme} = this.props		
		const themeLabel = theme
	  return (	 	  	
			<button onClick={this.props.setTheme} className={'settingsPage-content-section--theme-btn ' + (active ? ' settingsPage-content-section--theme-btn--active ' : ' settingsPage-content-section--theme-btn--inactive ')}>
				<div className='settingsPage-content-section--theme-btn-radioContainer'>
					<div className='settingsPage-content-section--theme-btn-radio'>
						{active && <div className='settingsPage-content-section--theme-btn-radio-inner' /> }
					</div>
				</div>
				<div onClick={this.props.toggleTheme} className='settingsPage-content-section--theme-btn-label'>
					{themeLabel}
				</div>
			</button>
	  )
	}
}

class SettingsPageContainer extends Component {
	
	constructor(){
		super()
		this.goBack=this.goBack.bind(this)
		this.setLightTheme=this.setLightTheme.bind(this)
		this.setDarkTheme=this.setDarkTheme.bind(this)
	}

	componentDidMount(){
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Settings Page" });
		}
	}

	setLightTheme(){
		if(this.props.theme!='light'){
			toggleTheme()
		}
	}

	setDarkTheme(){
		if(this.props.theme!='dark'){
			toggleTheme()
		}
	}


	openSlackLink(){
    if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:JOIN_SLACK_URL})     
    }else{
      window.open(JOIN_SLACK_URL,'_blank')
    }
  }

  goBack(){
  	if(this.props.history.length>1){
  		this.props.history.goBack()
  	}else{
  		this.props.history.push('/')
  	}
  }


	render() {
		const {theme,contacts}=this.props
		const versionNumber=getVersionNumber()

		const currentUser = getCurrentUser()
		let userDisplayName
		let userEmail
		let userAvatar

		if(currentUser){
			userDisplayName = currentUser.display_name
			userEmail = currentUser.email
			userAvatar = currentUser.avatar
		}

		let orgName
		if(this.props.organization){
			orgName=this.props.organization.name
		}

		const orgMembers = getOrgMembers()
		
		let membersArray=[]
    if(orgMembers){
      Object.keys(orgMembers).forEach((key)=>{
        let member=orgMembers[key]    
        if(!isCurrentUser(member.user_id)){
        	 membersArray.push(member)
        }   
      })
    }
		const sortedMembers = sortBy(membersArray, function(member){
      return member.name.toString().toLowerCase()
    })
		return (				
			<div className='settingsContainer'>
				<div className='settings-sideMenu'>
					<div className='settings-sideMenu-backBtnContainer'>						
						<button className='settings-sideMenu-backBtn' onClick={this.goBack}>
							<Icon name='backArrow'/>
						</button>
					</div>
					<div className='settings-sideMenu-content'>
						<div className='settings-sideMenu-content-profile'>
							<div className='settings-sideMenu-content-profile-avatarContainer'>
								<AvatarImage 
									className='settings-sideMenu-content-profile-avatar'
									avatarUrl={userAvatar}
									largeAvatar
								/>
							</div>
							<div className='settings-sideMenu-content-profile-displayName'>
								{userDisplayName}
							</div>
							<div className='settings-sideMenu-content-profile-orgName'>
								{orgName}
							</div>
							<div className='settings-sideMenu-content-profile-email'>
								{userEmail}
							</div>
						</div>	
						<div className='settings-sideMenu-content-vSpacer'/>
						<div className='settings-sideMenu-content-footer'>														
							<button onClick={this.openSlackLink} className='settings-sideMenu-content-footer-btn settings-sideMenu-content-footer-btn--slack'>
								Join the Slack Community
							</button>
							<div className='settings-sideMenu-content-footer-btn settings-sideMenu-content-footer-btn--versionNumber'>
								Version {versionNumber}
							</div>
							
							{/* THIS IS SIGN OUT */}
							<button className='settings-sideMenu-content-footer-btn settings-sideMenu-content-footer-btn--signOut' onClick={this.props.logout}>
								Sign out
							</button>
						</div>						
					</div>
				</div>
				<div className='settingsPage'>
					<div className='settingsPage-leftSpacer'/>
					<div className='settingsPage-content'>						
						<div className='settingsPage-content-header'>						
							Settings
						</div>
						<div className='settingsPage-content-section settingsPage-content-section--theme'>
							<div className='settingsPage-content-section-header'>
								<div className='settingsPage-content-section-header-title'>
									Appearance
								</div>
								<div className='settingsPage-content-section-header-subheader'>
									Switch with Shift + Command + \
								</div>																
							</div>
							<div className='settingsPage-content-section--theme-btnContainer'>
								<SettingsPageThemeButton 
									theme='light'
									active={theme === 'light'}
									setTheme={this.setLightTheme}
								/>
								<SettingsPageThemeButton 
									theme='dark'
									active={theme === 'dark'}
									setTheme={this.setDarkTheme}
								/>
							</div>						
						</div>

						<div className='settingsPage-content-divider' />

						<div className='settingsPage-content-section settingsPage-content-section--figma'>
							<div className='settingsPage-content-section-header'>
								<div className='settingsPage-content-section-header-title'>
									Figma Integration
								</div>
								<div className='settingsPage-content-section-header-subheader'>
									Preview pasted Figma links in docs and messages.
								</div>																
							</div>							
							<SettingsFigmaIntegration
							
							/>												
						</div>

						<div className='settingsPage-content-divider' />

						<div className='settingsPage-content-section settingsPage-content-section--GCal'>
							<div className='settingsPage-content-section-header'>
								<div className='settingsPage-content-section-header-title'>
									Google Calendar Integration
								</div>
								<div className='settingsPage-content-section-header-subheader'>
									Attach and share meeting notes to calendar events.
								</div>																
							</div>							
							<SettingsGoogleCalendarIntegration

							/>												
						</div>

						<div className='settingsPage-content-divider' />

						<div className='settingsPage-content-section settingsPage-content-section--teammates'>
							<div className='settingsPage-content-section-header'>
								<div className='settingsPage-content-section-header-title'>
									Teammates
								</div>								
							</div>
							<div className='settingsPage-content-section--teammates-list'>
								{sortedMembers && sortedMembers.map((user)=>{                                						                  
									return(	                    
										<SettingsPageTeammateRow			                    	
											key={user['user_id']}
											user={user}						                    	
										/>
									)
								})}							               	

							</div>						
						</div>

						{/*} MAYBE PUT BACK LATER FOR SMALLER ORGS
						<div className='settingsPage-content-divider' />
						
						{contacts && contacts.length > 0 && 
							<div className='settingsPage-content-section settingsPage-content-section--contacts'>
								<div className='settingsPage-content-section-header'>
									<div className='settingsPage-content-section-header-title'>
										Contacts
									</div>								
								</div>
								<div className='settingsPage-content-section--contacts-list'>
									{contacts && contacts.map((contact)=>{                                						                  
										return(	                    
											<SettingsPageContactRow			                    	
												key={contact['contact_id']}
												contact={contact}				
												archiveContact={this.props.archiveContact}
											/>
										)
									})}	            
								</div>						
							</div>
						}
						*/}
					</div>
					<div className='settingsPage-rightSpacer'/>
				</div>				
			</div>
			)		
	}
}

function mapStateToProps(state,ownProps) {
	return {
		theme:state.theme,
		organization:state.organization,
		contacts:state.contacts
	}
}

export default withRouter(connect(mapStateToProps,
	{	logout,archiveContact}
)(SettingsPageContainer))


	