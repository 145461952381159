import React from 'react'
import ReactDOM from 'react-dom';
import data from '../../../emojiData'



function getEmojiForString(string){
	// console.log('get emoji for string')
	// console.log(string)
  const natives=data.natives
 // console.log(natives)
  const keys=Object.keys(natives)
  let emoji
  keys.map((key)=>{
    if(natives[key]==string && !emoji){
      emoji=key
    }
  })
  return emoji
}

const regExp = ':(.*):' //match :heart:


class EmojiNodeView {

	constructor(node, view, getPos) {
		//this.getNode=this.getNode.bind(this)
		this.dom = document.createElement("span")
		this.dom.setAttribute("role", "img");
		this.dom.className="doc-para-emoji"

		const {
			emojiString
		} = node.attrs
	
		// const test='emojiString'
		const emojiCharacter=getEmojiForString(emojiString)
	//	console.log(emojiCharacter)

		//const span2 = document.createElement('span');
    //span2.className = 'doc-para-emoji-inner'
    // this.dom.appendChild(span2)

    this.dom.innerHTML=emojiCharacter


		}

	

}

export default EmojiNodeView