import {createTemplateRequest} from '../../actions/docTemplates'
import store from '../../store'

// export function createTemplate(docId){
// 	return store.dispatch(createTemplateRequest(docId))
// }


export function createTemplate(docId,templateName,templateDescription){
	return store.dispatch(createTemplateRequest(docId,templateName,templateDescription))
}