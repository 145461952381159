import React from 'react'
import DrawingCanvasContainer from './DrawingCanvasContainer'
import * as Dialog from '@radix-ui/react-dialog';

class InsertDrawingModal extends React.Component{  
  render(){	
    const {closeDrawingModal, showInsertDrawingModal}=this.props

	  return (	 
      <React.Fragment>
        <Dialog.Root id='insertDrawingModal' open={showInsertDrawingModal}>
          <Dialog.Portal>
            <Dialog.Overlay className='dialogOverlay'>
              <Dialog.Content 
                className='docEditor-insertImageModal'
                onEscapeKeyDown={closeDrawingModal}
                onPointerDownOutside={closeDrawingModal}
                //onInteractOutside={closeDrawingModal}
                onInteractOutside={()=>{console.log('outside!')}}
              >
                
                <DrawingCanvasContainer
                  isEdit={false}
                  insertDrawing={this.props.insertDrawing}
                  closeModal={closeDrawingModal}
                />
                
              </Dialog.Content>
            </Dialog.Overlay>
          </Dialog.Portal>
        </Dialog.Root>       
      </React.Fragment>
    ) 	
  	
	}
}

export default InsertDrawingModal
