import findIndex from 'lodash/findIndex'

export default function (state = true, action) {
	switch (action.type) {

	case 'AUTOPLAY_HIGHLIGHT':
	 	return true

	case 'CLEAR_AUTOPLAY_HIGHLIGHT':
		return false

	case 'LOGOUT':
		return FALSE
	
	default:
		return state
	
	}
}
