import schema from '../../schema/editorSchema'  
import {randomID} from '../../../utils/randomID'
import findIndex from 'lodash/findIndex'

//returns the tr
export function addAssigneeToToDoTr(user,toDoNode) {
	const attrs=toDoNode.attrs
	let newAttrs={...attrs}
	const assignees=attrs.assignees
	let newAssignees=[...assignees]
	let assignee={}
	//save a subset of user obj
	assignee['user_id']=user['user_id']
	assignee.name=user.name
	assignee.color=user.color
	assignee['slack_avatar']=user['slack_avatar']
	//check dont already have addignn
	const index=findIndex(assignees,(assignee => {
 		return assignee['user_id'] == user['user_id']
	}))
	if(index==-1){
		newAssignees.push(assignee)
	}
	newAttrs.assignees=newAssignees

	const editorView=window.view
	let tr = window.view.state.tr	
	editorView.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if (node==toDoNode){
			tr.setNodeMarkup(pos, null, newAttrs)
		}
	})     
	return tr
}

export function removeAssigneeFromToDo(user,toDoNode) {
	const attrs=toDoNode.attrs
	let newAttrs={...attrs}
	const assignees=attrs.assignees
	let newAssignees=[]
	assignees.forEach((assignee)=>{
		if(assignee.user_id !==user.user_id){
			newAssignees.push(assignee)
		}
	})
	newAttrs.assignees=newAssignees

	const editorView=window.view
	let tr = window.view.state.tr	
	editorView.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if (node==toDoNode){
			tr.setNodeMarkup(pos, null, newAttrs)
		}
	})     
	window.view.dispatch(tr)
}



export function addAssigneeToToDo(user,toDoNode) {
	const attrs=toDoNode.attrs
	let newAttrs={...attrs}
	const assignees=attrs.assignees
	let newAssignees=[...assignees]
	let assignee={}
	//save a subset of user obj
	assignee['user_id']=user['user_id']
	assignee.name=user.display_name
	assignee.color=user.color
	assignee['slack_avatar']=user['slack_avatar']
	//check dont already have addignn
	const index=findIndex(assignees,(assignee => {
 			return assignee['user_id'] == user['user_id']
		}))
	if(index==-1){
		newAssignees.push(assignee)
	}
	newAttrs.assignees=newAssignees

	const editorView=window.view
	let tr = window.view.state.tr	
	editorView.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
		if (node==toDoNode){
			tr.setNodeMarkup(pos, null, newAttrs)
		}
	})     
	window.view.dispatch(tr)
}