import React from 'react'
import Icon from '../../misc/Icon'
import {fetchSubtitles} from '../../../actions/video'
import parseSRT from '../../../utils/srtToJson'

class DocVideoCaptions extends React.Component{  
	 constructor(){
    super()
    this.getSubtitleText=this.getSubtitleText.bind(this)
    this.state={
      subtitlesJson:null
    }
  }

  componentDidMount(){
  	fetchSubtitles(this.props.subtitleUrl).then((response)=>{
  		const json=parseSRT(response)
  		this.setState({subtitlesJson:json})
  	})
  }

  getSubtitleText(){
  	const {subtitlesJson}=this.state
  	const {progressSeconds}=this.props
  	let text
  	if(subtitlesJson){
  		subtitlesJson.forEach((subtitle,i)=>{
  			if((subtitle.start<progressSeconds || subtitle.start==progressSeconds) && subtitle.end>progressSeconds){
  				text=subtitle.text
  			}
  		})
  	}
  	return text
  }

	render(){ 
		const captionText=this.getSubtitleText()		
		return (	
			<div className={'doc-video-captionsOuterContainer ' + (this.props.inline ? ' doc-video-captionsOuterContainer--inline ' : ' doc-video-captionsOuterContainer--zoomed ') + (this.props.showInlineControlBar ? ' doc-video-captionsOuterContainer--showInlineControlBar ' : ' ') + (this.props.hasTimestampedComments ? ' doc-video-captionsOuterContainer--hasComments ' : ' ')}>
				{captionText &&
					<div className='doc-video-captionsInnerContainer'>
						<div className='doc-video-captions'>
							{captionText}
						</div>
					</div>
				}
			</div>						
		)
	}
}

export default DocVideoCaptions