import React from 'react'
import Icon from '../misc/Icon'
import OnboardingModalDevice from './OnboardingModalDevice'
import OnboardingModalSelectBtn from './OnboardingModalSelectBtn'
import {randomID} from '../../utils/randomID'

const OAUTH_URL='https://www.figma.com/oauth'
const SCOPE="file_read"


const FIGMA_CLIENT_ID=process.env.REACT_APP_FIGMA_CLIENT_ID || window.envVars.reactAppFigmaClientId
const REDIRECT_URI=process.env.REACT_APP_FIGMA_REDIRECT_URI || window.envVars.reactAppFigmaRedirectUri

class OnboardingModalProjectStage extends React.Component {  	
  
  constructor(props) {
    super(props);   
    this.handleKeyDown=this.handleKeyDown.bind(this)   
    //this.handleSelectTemplate=this.handleSelectTemplate.bind(this)
    this.state = {
      activeOption:'figma'
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)    
  }
  
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e){
    const {activeOption}=this.state
    if(e.keyCode==13){ //enter
      if(activeOption=='figma'){
        this.figmaAuth()
      }else{
        this.props.advanceStage()
      }
    }
    else if(e.keyCode==40 || e.keyCode==38){//down arrow
      if(activeOption=='figma'){
        this.setState({activeOption:'later'})
      }else{
        this.setState({activeOption:'figma'})
      }
    } 
  }





  figmaAuth(){
    const randID=randomID()
    if(window.isElectron){
      let url = `${OAUTH_URL}?response_type=code&scope=${SCOPE}&client_id=${FIGMA_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${randID}`
      window.ipcRenderer.send('open-oauth-window',{url:url})     
    }
    else{
      let url = `${OAUTH_URL}?response_type=code&scope=${SCOPE}&client_id=${FIGMA_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${randID}`
      window.location= url
    }
  }



  render() {
    
    const orgName = 'Acme Inc'

    return (      
       
      <div className='onboardingModal-stage onboardingModal-stage--figma onboardingModal-stage--leftAlign'>
        <div className='onboardingModal-stage--leftAlign-leftSpacer' />
        <div className='onboardingModal-stage-contentContainer'>
          <div className='onboardingModal-stage-topSpacer' />
          <div className='onboardingModal-stage-h1'>
            Add Figma frames directly into projects. <span className='onboardingModal-stage-h1-light'>Connect for embeds.</span>
          </div>
          <div className='onboardingModal-stage-keyHints'>
            Use arrow keys ↑ ↓ and Enter
          </div>
          <div className='onboardingModal-stage--project-selectBtnContainer'>
            <OnboardingModalSelectBtn
              active={this.state.activeOption=='figma'}
              companyIcon='figma'
              label='Sign in with Figma'
              onSelect={this.figmaAuth}
            />
            <OnboardingModalSelectBtn                            
              label='Do later'
              active={this.state.activeOption=='later'}
              onSelect={this.props.advanceStage}
            />            
          </div>
          <div className='onboardingModal-stage-vSpacer' />

          <div className='onboardingModal-stage-skipBtnContainer'>
            <button onClick={this.props.advanceStage} className='onboardingModal-stage-skipBtn'>
              Skip
            </button>
          </div>
          
          <div className='onboardingModal-stage-bottomSpacer' />
          
        </div>
        <div className='onboardingModal-stage-visualOuterContainer'>
          <div className='onboardingModal-stage-visualInnerContainer'>
            <OnboardingModalDevice 
              stage="figma"
            />
          </div>
        </div>
      </div>      
    )
  }
}

export default OnboardingModalProjectStage