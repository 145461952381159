import React, {Component} from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import pusherInstance from '../pusherInstance'
import {fetchRecentAcitivities} from '../actions/recentActivities'
import {syncLinearData} from '../actions/user'
import {fetchContacts} from '../actions/contacts'
import {fetchAWhiteboardEmbed} from '../actions/whiteboardEmbed'
import {fetchDocs,fetchADoc,handleDocTimestampUpdatedPusher,handleNewDocFollowerPusher,handleDocTemplatePusher} from '../actions/docs'
import {fetchRecording,handleRecordingArchivedPusher} from '../actions/recording'
import {fetchRoadmaps,handleRoadmapUpdatedPusher} from '../actions/roadmaps'
import {handleHighlightUpdatedPusher,handleHighlightArchivedPusher} from '../actions/highlights'
import {fetchAllMessages,fetchAMessage,handleMessageDeletedPusher,handleNewMessagePusher,handleNewAutoMessagePusher} from '../actions/messages'
import {handleIssueCreatedPusher,handleIssueDeletedPusher,handleIssueUpdatedPusher,fetchIssues} from '../actions/issues'
import {handleProjectCreatedPusher,handleProjectDeletedPusher,handleProjectUpdatedPusher} from '../actions/projects'
import {handleNewFigmaEmbedPusher} from '../actions/figma'
import {handleNewProjectConnectionPusher,handleProjectConnectionDeletedPusher} from '../actions/meetings'
import {refetchLinearData} from '../actions/user'
import {fetchAMeeting} from '../actions/meetings'
import {orgPresencePusherSetup} from '../utils/orgPresencePusherSetup'
import {isCurrentUser} from '../utils/isCurrentUser'
import {openDoc} from '../utils/openDoc'
import {showToastNotification} from '../actions/toastNotification'
import {getUserName} from '../utils/getUserName'
import {showElectronNotification} from '../utils/notifications/showElectronNotification'
import {jumpToCursor} from '../utils/jumpToCursor'
import {calculateAppNotificationCount} from '../utils/notifications/calculateNotificationCounts'
import {showNewMessageNotification} from '../utils/notifications/showNewMessageNotification'
import {maybeAddTab} from '../utils/maybeAddTab'
import {handleDocArchivedPusher} from '../utils/handleDocArchivedPusher'
import {handleMeetingArchivedPusher} from '../utils/meetings/handleMeetingArchivedPusher'
import {fetchMeetingProjectConnecions} from '../actions/meetings'
import {userIsFollower} from '../utils/userIsFollower'
import {handleDocLinkAddedPusher} from '../utils/addDocLinkToLinear'
import {currentUserIsInThread} from '../utils/threads/currentUserIsInThread'
import find from 'lodash/find'
import {bumpLinearIssueVersion} from '../prosemirror/utils/bumpLinearIssueVersion'
import qs from 'qs'
//Do org pusher stuff here so can access router for jump to cursor

class OrgPusherContainer extends Component {

	constructor(){
		super()
		this.handleRecordingReady=this.handleRecordingReady.bind(this)
		this.handleRecordingArchived=this.handleRecordingArchived.bind(this)
		this.handleMeetingCreated=this.handleMeetingCreated.bind(this)
		this.handleMeetingUpdated=this.handleMeetingUpdated.bind(this)
		this.handleContactsUpdated=this.handleContactsUpdated.bind(this)
		this.handleHighlightUpdated=this.handleHighlightUpdated.bind(this)
		this.handleNewProjectConnection=this.handleNewProjectConnection.bind(this)
		this.handleProjectConnectionDeleted=this.handleProjectConnectionDeleted.bind(this)
		this.handleDocTemplateSelected=this.handleDocTemplateSelected.bind(this)
		this.handleHighlightArchived=this.handleHighlightArchived.bind(this)
		this.handleMeetingArchived=this.handleMeetingArchived.bind(this)

		this.handleNewWhiteboard=this.handleNewWhiteboard.bind(this)
		this.handleWhiteboardUpdated=this.handleWhiteboardUpdated.bind(this)

		this.subscribeToOrganisationPusherChannel=this.subscribeToOrganisationPusherChannel.bind(this)
		this.handlePingCursorPusher=this.handlePingCursorPusher.bind(this)
		this.handleJumpedToCursorPusher=this.handleJumpedToCursorPusher.bind(this)
		this.calculateNewMessageNotification=this.calculateNewMessageNotification.bind(this)
		this.calculateElectronBadgeCount=this.calculateElectronBadgeCount.bind(this)
		this.handleDocSharedPusher=this.handleDocSharedPusher.bind(this)
		this.handleDocCreatedPusher=this.handleDocCreatedPusher.bind(this)
		this.handleDocUpdatedPusher=this.handleDocUpdatedPusher.bind(this)
		this.handleDocArchivedPusher=this.handleDocArchivedPusher.bind(this)
		this.handleNewMessagePusher=this.handleNewMessagePusher.bind(this)
		this.handleMessageDeletedPusher=this.handleMessageDeletedPusher.bind(this)
		// this.handleNewConversationPusher=this.handleNewConversationPusher.bind(this)
		this.handleFollowPusher=this.handleFollowPusher.bind(this)
		this.handleIssueCreatedPusher=this.handleIssueCreatedPusher.bind(this)
		this.handleIssueUpdatedPusher=this.handleIssueUpdatedPusher.bind(this)
		this.handleIssueDeletedPusher=this.handleIssueDeletedPusher.bind(this)
		this.handleIssuesSyncedPusher=this.handleIssuesSyncedPusher.bind(this)

		this.handleProjectCreatedPusher=this.handleProjectCreatedPusher.bind(this)
		this.handleProjectDeletedPusher=this.handleProjectDeletedPusher.bind(this)
		this.handleProjectUpdatedPusher=this.handleProjectUpdatedPusher.bind(this)

		this.handleNewAutoMessagePusher=this.handleNewAutoMessagePusher.bind(this)

		this.handleNewDocFollowPusher=this.handleNewDocFollowPusher.bind(this)
		this.handleDocTimestampPusher=this.handleDocTimestampPusher.bind(this)

		this.handleSyncRequiredPusher=this.handleSyncRequiredPusher.bind(this)
		this.handleFigmaEmbedCreatedPusher=this.handleFigmaEmbedCreatedPusher.bind(this)

		this.handleLinearDataSyncedPusher=this.handleLinearDataSyncedPusher.bind(this)
		this.handleRoadmapUpdatedPusher=this.handleRoadmapUpdatedPusher.bind(this)
		this.handleFetchRoadmapsPusher=this.handleFetchRoadmapsPusher.bind(this)

		this.connectedToPusher=false
	}


	componentDidMount() {
		if(window.isElectron){
			window.ipcRenderer.receive('jump-to-cursor', (args) => {
				jumpToCursor(args.jumpTo,this.props.history)
			});
			window.ipcRenderer.receive('open-doc-from-notification', (args) => {
				openDoc(this.props.history,args.docId)
			});
			//calculate initial badge count- add a slight delay so stuff has fetched
			setTimeout(function() { 
				this.calculateElectronBadgeCount()
			}.bind(this), 2000)
		}
	}

	componentDidUpdate(prevProps) {
 		if(!this.connectedToPusher && this.props.organization && this.props.organization['organization_id'] ){
 			this.subscribeToOrganisationPusherChannel()
 		}
 		if(prevProps.recentActivities !== this.props.recentActivities){
 			this.calculateElectronBadgeCount()
 		}
	}

	subscribeToOrganisationPusherChannel(){
		const channel = pusherInstance.subscribe(`organization-${this.props.organization['organization_id']}`);

		//Jun 16th recording stuff
		channel.bind('recording-ready', this.handleRecordingReady) 
		channel.bind('recording-archived', this.handleRecordingArchived) 
		channel.bind('recording-updated', this.handleRecordingReady) 
		channel.bind('meeting-archived', this.handleMeetingArchived) 
		channel.bind('meeting-created', this.handleMeetingCreated) 
		channel.bind('meeting-updated', this.handleMeetingUpdated) 


		//Whiteboards
		channel.bind('new-whiteboard', this.handleNewWhiteboard) 
		channel.bind('whiteboard-updated', this.handleWhiteboardUpdated) 


		channel.bind('doc-template-selected', this.handleDocTemplateSelected)

		channel.bind('deleted-project-connection', this.handleProjectConnectionDeleted) 

		

		channel.bind('new-project-connection', this.handleNewProjectConnection) 

		channel.bind('highlight-updated', this.handleHighlightUpdated) 
		channel.bind('highlight-archived', this.handleHighlightArchived) 

		channel.bind('contacts-updated', this.handleContactsUpdated) 

		

		channel.bind('linear-data-synced', this.handleLinearDataSyncedPusher) 

		channel.bind('sync-required', this.handleSyncRequiredPusher) 

		channel.bind('roadmap-updated', this.handleRoadmapUpdatedPusher) 
		channel.bind('fetch-roadmaps', this.handleFetchRoadmapsPusher) 

		channel.bind('doc-created', this.handleDocCreatedPusher) 
		channel.bind('doc-updated', this.handleDocUpdatedPusher) 
		channel.bind('doc-archived', this.handleDocArchivedPusher) 
		channel.bind('doc-renamed', this.handleDocUpdatedPusher) 
		channel.bind('doc-shared', this.handleDocSharedPusher)
		channel.bind('doc-linear-link-created', this.handleDocLinearLinkCreated)

		channel.bind('doc-timestamp-updated', this.handleDocTimestampPusher)
		
		//Figma embed
		channel.bind('figma-embed-created', this.handleFigmaEmbedCreatedPusher)
		
		//issues
		channel.bind('issue-created', this.handleIssueCreatedPusher) 
		channel.bind('issue-deleted', this.handleIssueDeletedPusher) 
		channel.bind('issue-updated', this.handleIssueUpdatedPusher) 
		channel.bind('issues-synced', this.handleIssuesSyncedPusher) 

		//issues
		channel.bind('project-created', this.handleProjectCreatedPusher) 
		channel.bind('project-deleted', this.handleProjectDeletedPusher) 
		channel.bind('project-updated', this.handleProjectUpdatedPusher) 

		//new follow
		channel.bind('new-doc-follow', this.handleNewDocFollowPusher) 


		//messages
		channel.bind('new-message', this.handleNewMessagePusher) 
		channel.bind('new-auto-message', this.handleNewAutoMessagePusher) 
		channel.bind('message-deleted', this.handleMessageDeletedPusher) 

		channel.bind('ping-cursor', this.handlePingCursorPusher)  
		channel.bind('jumped-to-cursor', this.handleJumpedToCursorPusher)  

		channel.bind('doc-followed', this.handleFollowPusher)  
		channel.bind('doc-unfollowed', this.handleFollowPusher)  	
		orgPresencePusherSetup(this.props.organization['organization_id'])
		this.connectedToPusher=true
	}

	handleNewWhiteboard(pusherData){
		if(!isCurrentUser(pusherData.userId)){ //only if you are not the user who did it to prevent flash of reload
			this.props.fetchAWhiteboardEmbed(pusherData.embedId)
		}
	}

	handleWhiteboardUpdated(pusherData){
		if(!isCurrentUser(pusherData.userId)){
			this.props.fetchAWhiteboardEmbed(pusherData.embedId)
		}
	}

	handleDocTemplateSelected(pusherData){
		this.props.handleDocTemplatePusher(pusherData.docId,pusherData.template)
	}

	handleProjectConnectionDeleted(pusherData){
		this.props.handleProjectConnectionDeletedPusher(pusherData.connectionId)
	}

	handleNewProjectConnection(pusherData){
		const connection = pusherData.connection 
		this.props.handleNewProjectConnectionPusher(connection)
	}

	handleHighlightUpdated(pusherData){
		if(!pusherData.highlight.archived_at){
			this.props.handleHighlightUpdatedPusher(pusherData.highlight)
		}
	}

	handleRecordingArchived(pusherData){
		this.props.handleRecordingArchivedPusher(pusherData.recordingId)
		this.props.fetchAllMessages()
	}

	handleHighlightArchived(pusherData){
		this.props.handleHighlightArchivedPusher(pusherData.highlightId)
		this.props.fetchAllMessages()
	}

	handleContactsUpdated(pusherData){
		this.props.fetchContacts()
	}

	handleMeetingCreated(pusherData){
		const meetingId=pusherData.meetingId 
		const docId=pusherData.docId
		this.props.fetchAMeeting(meetingId) //TODO only fetch the new meeting
		this.props.fetchADoc(docId)
	}

	handleMeetingUpdated(pusherData){
		const meetingId=pusherData.meetingId 
		this.props.fetchAMeeting(meetingId)
	}

	handleMeetingArchived(pusherData){
		const meetingId=pusherData.meetingId
		const docId=pusherData.docId
		handleDocArchivedPusher(docId)
		handleMeetingArchivedPusher(meetingId)
		this.props.fetchMeetingProjectConnecions()
	}

	handleRecordingReady(pusherData){
		this.props.fetchRecording(pusherData.recordingId)
	}

	handleFetchRoadmapsPusher(pusherData){
		this.props.fetchRoadmaps()
	}

	handleRoadmapUpdatedPusher(pusherData){
		this.props.handleRoadmapUpdatedPusher(pusherData.roadmap)
	}

	handleLinearDataSyncedPusher(){
		this.props.refetchLinearData()
	}

	handleDocLinearLinkCreated(pusherData){
		handleDocLinkAddedPusher(pusherData.docId)
	}


	handleFigmaEmbedCreatedPusher(pusherData){
		this.props.handleNewFigmaEmbedPusher(pusherData)
	}

	handleSyncRequiredPusher(){
		this.props.syncLinearData()
	}


	handleDocTimestampPusher(pusherData){
		this.props.handleDocTimestampUpdatedPusher(pusherData.docId,pusherData.updatedAt)
	}

	handleNewDocFollowPusher(pusherData){
		const {docId,userId}=pusherData
		this.props.handleNewDocFollowerPusher(docId,userId)
	}


	handleProjectCreatedPusher(pusherData){
		const {project,projectDocId}=pusherData
		this.props.handleProjectCreatedPusher(project)
		this.props.fetchADoc(projectDocId)
	}

	handleProjectDeletedPusher(pusherData){
		this.props.handleProjectDeletedPusher(pusherData.projectId)
	}

	handleProjectUpdatedPusher(pusherData){
		const {project,projectRenamed,projectDocId}=pusherData
		this.props.handleProjectUpdatedPusher(project)
		if(projectRenamed){
			this.props.fetchADoc(projectDocId)
		}
	}

	handleIssuesSyncedPusher(){
		this.props.fetchIssues()
	}
	handleIssueCreatedPusher(pusherData){
		this.props.handleIssueCreatedPusher(pusherData.issue)
	}

	handleIssueUpdatedPusher(pusherData){
		this.props.handleIssueUpdatedPusher(pusherData.issue)
		setTimeout(function() {
			bumpLinearIssueVersion()
		}.bind(this), 300)
	}

	handleIssueDeletedPusher(pusherData){
		this.props.handleIssueDeletedPusher(pusherData.issueId)
	}

	handleMessageDeletedPusher(pusherData){
		this.props.handleMessageDeletedPusher(pusherData.messageId)
	}

	handleNewAutoMessagePusher(pusherData){
		this.props.handleNewAutoMessagePusher(pusherData.message)
	}

	//TODO check when need to fetch conversations and recent activity
	handleNewMessagePusher(pusherData){ 
		this.props.handleNewMessagePusher(pusherData.message)
			if(pusherData.message.message_id){
				this.calculateElectronBadgeCount()
				//work out if we need to show a notification or open a tab
				this.calculateNewMessageNotification(pusherData)
		}
	}

	handleDocCreatedPusher(pusherData){
		const docId=pusherData.docId
		if(docId){
			this.props.fetchADoc(docId)
		}
	}

	handleFollowPusher(pusherData){
		const docId=pusherData.docId
		const updatedBy=pusherData.updatedBy //don't update if you are the user making changes (flashes while adding and removing)
		if(!isCurrentUser(updatedBy) && docId){
			this.props.fetchADoc(docId)
		}
	}

	handleDocUpdatedPusher(pusherData){ //TODO dont fetch if dont have permission for doc
		let changeIsCurrentUser=false
		if(pusherData.userId){
			if(isCurrentUser(pusherData.userId)){
				changeIsCurrentUser=true
			}
		}
		if(!changeIsCurrentUser){
			const docId=pusherData.docId
			if(docId){
				this.props.fetchADoc(docId)
			}
		}	
	}

	handleDocArchivedPusher(pusherData){
		const docId=pusherData.docId
		handleDocArchivedPusher(docId) //removed from state and removes tab
	}


	handlePingCursorPusher(data){
		if(isCurrentUser(data.sentTo)){
			const alertType="pingCursorAlert"
			const userName=getUserName(data.sentBy)
			const toastActionButton={
				buttonLabel:"Jump To Cursor",
				buttonActionType:"jumpToCursor",
				docId:data.docId,
				sentBy:data.sentBy
			}
			this.props.showToastNotification(alertType,userName,toastActionButton)
										
			const title=`${this.props.organization.name}`
			const subtitle=null
			const body=`${userName} is pinging you`
			const history=this.props.history
			showElectronNotification(title,subtitle,body,data.sentBy)
		}
	}
	
	// const pusherData={
	// 	docId:parent_doc,
	// 	sharedBy:user_id,
	// 	sharedWith:invitedUser
	// }

	handleDocSharedPusher(pusherData){ ///This isnt being used at the moment
		if(isCurrentUser(pusherData.sharedWith)){
			const docId=pusherData.docId
			this.props.fetchADoc(docId).then((response)=>{
				if(response){
					maybeAddTab(pusherData.docId)
					const title=response.name
					const notificationArgs={
							title:title,
							subtitle:`${getUserName(pusherData.sharedBy)} shared`,
							body:'with you',
							docId:pusherData.docId
					}
					showNewMessageNotification(notificationArgs)
					this.props.fetchConversations()
				}
			})
		}
	}


	handleJumpedToCursorPusher(data){//if you are the user that got jumped to show an alert
		if(isCurrentUser(data.actionTo)){
			const alertType="jumpToCursorAlert"
			const userName=getUserName(data.actionBy)
			this.props.showToastNotification(alertType,userName)
		}
	}

	calculateNewMessageNotification(pusherData){ //TODO user in doc stuff- maybe check if is in the foreground

		if(pusherData && pusherData.postedBy){
			if(!isCurrentUser(pusherData.postedBy)){
				let doc=find(this.props.docs,{'doc_id':`${pusherData.docId}`})
				if(doc){
					const message=pusherData.message
					let showNotification=userIsFollower(pusherData.docId)
					const pathname=this.props.location.pathname
					let userIsInDoc=false
					//check user is not currently in
					let urlDocId
					const signatureComponentsArray=pathname.split(/-/)
					if(signatureComponentsArray.length>1){
						urlDocId=signatureComponentsArray[signatureComponentsArray.length-1]
					}
					// //if you are not in the doc always show notification
					// //if you are in a doc not in a thread and the message has a thread then show notification
					// //or if doc is in thread and message is a different thread or no thread then show 

					// const query=qs.parse(this.props.location.search.slice(1))
					// const currentActiveThread=query.thread
					// console.log(`------------ currentActiveThread ${currentActiveThread}`)


					if(doc.doc_id==urlDocId){ //show alert if in doc if it is in a thread
						if(!message.thread_parent){
							userIsInDoc=true
						}
						//if message is in a thread and you are not currently 
					}

					if(message.thread_parent){
						const userInThread=currentUserIsInThread(message.thread_parent)
						//console.log(`------------------userInThread ${userInThread}`)
						if(!userInThread){
							showNotification=false
						}
					}

					if(showNotification && !userIsInDoc){
						maybeAddTab(pusherData.docId)
						const title=doc.name
						const notificationArgs={
							title:`${this.props.organization.name}`,
							subtitle:`${title}`,
							body: `${getUserName(pusherData.postedBy)}: ${pusherData.messagePreview}`,
							docId:pusherData.docId,
							thread:message.thread_parent
						}
						showNewMessageNotification(notificationArgs)
					}
				}
			}
		}
	}					

	calculateElectronBadgeCount(){
		if(window.isElectron){
			const notificationCount=calculateAppNotificationCount()
			const args={badgeCount:notificationCount}
			window.ipcRenderer.send('set-badge-count',args)
		}
	}

	render() {
		return null
	}
}


const mapStateToProps = (state) => ({
	organization:state.organization,
	docs:state.docs,
	recentActivities:state.recentActivities,
});

export default withRouter(connect(
	mapStateToProps,
	{	fetchIssues,
		fetchRoadmaps,
		handleRoadmapUpdatedPusher,
		syncLinearData,
		fetchDocs,
		handleIssueCreatedPusher,
		handleIssueDeletedPusher,
		handleIssueUpdatedPusher,
		fetchAllMessages,
		showToastNotification,
		fetchRecentAcitivities,
		//fetchConversations,
		fetchADoc,
		fetchAMessage,
		handleMessageDeletedPusher,
		handleNewMessagePusher,
		handleProjectCreatedPusher,
		handleProjectDeletedPusher,
		handleProjectUpdatedPusher,
		handleNewDocFollowerPusher,
		handleNewAutoMessagePusher,
		handleDocTimestampUpdatedPusher,
		handleNewFigmaEmbedPusher,
		refetchLinearData,
		fetchRecording,
		fetchAMeeting,
		fetchContacts,
		handleHighlightUpdatedPusher,
		handleNewProjectConnectionPusher,
		handleProjectConnectionDeletedPusher,
		handleDocTemplatePusher,
		handleRecordingArchivedPusher,
		handleHighlightArchivedPusher,
		fetchMeetingProjectConnecions,
		fetchAWhiteboardEmbed
	})(OrgPusherContainer))
