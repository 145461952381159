import store from '../store'
import find from 'lodash/find'



export function getDocForId(docId) {	
	const state = store.getState()
	const doc= find(state.docs, {'doc_id':`${docId}`})
	return doc
}

