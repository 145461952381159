
export function getMatch($position,triggerRegex) {
  // take current para text content upto cursor start.
  // this makes the regex simpler and parsing the matches easier.
  var parastart = $position.before();
  const text = $position.doc.textBetween(parastart, $position.pos, "\n", "\0");
  var triggerMatch = text.match(triggerRegex);
  const match = triggerMatch
  //if match found, return match with useful information.
  if (match) {
    var to =  $position.pos
    var from = to - match[0].length;
    var queryText = match[1];

    return {
      range: { from: from, to: to},
      queryText: queryText,
    };
  }
  // else if no match don't return anything.
}