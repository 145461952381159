import React from 'react'
import Icon from '../misc/Icon'
import OnboardingModalDevice from './OnboardingModalDevice'
import OnboardingModalSelectBtn from './OnboardingModalSelectBtn'


class OnboardingModalProjectStage extends React.Component {  	
  
  constructor(props) {
    super(props);    
    this.state = {
      
    }
  }

  render() {
    
    const exampleUpcomingProjectName = 'Stripe API'

    return (      
       
      <div className='onboardingModal-stage onboardingModal-stage--docSearch onboardingModal-stage--centerAlign'>        
        <div className='onboardingModal-stage-contentContainer'>
          <div className='onboardingModal-stage-topSpacer' />
          <div className='onboardingModal-stage-h1'>
            Teleport around Yarn using ⌘T <br/><span className='onboardingModal-stage-h1-light'>Try jumping to your <span className='onboardingModal-stage-h1-underline'>{exampleUpcomingProjectName}</span> project.</span>
          </div>
          <div className='onboardingModal-stage-skipBtnContainer'>
            <button className='onboardingModal-stage-skipBtn'>
              Skip
            </button>
          </div>         
          <div className='onboardingModal-stage-visualOuterContainer'>
            <div className='onboardingModal-stage-visualInnerContainer'>
              <OnboardingModalDevice 
                stage="docSearch"
              />
            </div>
          </div>                  
        </div>        
      </div>      
    )
  }
}

export default OnboardingModalProjectStage