import {lift} from "prosemirror-commands"
import {TextSelection} from "prosemirror-state"
import {getCurrentUserAsAssigneeObj} from '../toDos/getCurrentUserAsAssigneeObj'

//TODO try doing this with list/wrap stuff
//TODO test selection stuff (trying to keep cursor in the same position)
export function toggleParagraphToToDoItem(state,dispatch,view) {
	
	const {selection, schema,doc} = state
	if(selection.empty){
	let {tr} = state
	const {from, to} = selection	
	
	let toDoNode
	let paragraphNode
	let toDoFrom
	let toDoTo
	let paragraphFrom
	let paragraphTo
	doc.nodesBetween(from, to, (node, pos) => {
		const nodeType = node.type;
		if(nodeType.name === 'toDoItem'){
			toDoNode=node
			toDoFrom=pos
			toDoTo=pos+node.nodeSize
		}
		if(nodeType.name === 'paragraph'){
			paragraphNode=node
			paragraphFrom=pos
			paragraphTo=pos+node.nodeSize
		}
	});

	if(toDoNode){
		//replace with paragraph node
		tr.replaceWith(toDoFrom, toDoTo, paragraphNode)
		let selection=new TextSelection(tr.doc.resolve(from-1,to-1))

		tr.setSelection(selection)
	}else if(paragraphNode){
		//wrap paragraph in todoitem
		let type = schema.nodes.toDoItem
		const indentLevel=paragraphNode.attrs.indentLevel

		let attrs={
			indentLevel:indentLevel
		}
		const assignee=getCurrentUserAsAssigneeObj()
		if(assignee){
			attrs.assignees=[assignee]
		}


		const node = type.createAndFill(attrs,paragraphNode)
		tr.replaceWith(paragraphFrom,paragraphTo, node)
		let selection=new TextSelection(tr.doc.resolve(from+1,to+1))

		tr.setSelection(selection)
	}
	if (tr.docChanged) {
		dispatch && dispatch(tr);
		return true;
		} else {
		return false;
	}
}else return false
}
