import React from 'react'
import {createTemplate} from '../../utils/templates/createTemplate'
import Icon from '../misc/Icon'
import { Spring, Transition, animated, config, easings} from 'react-spring'
import {showToastNotification} from '../../actions/toastNotification'
import store from '../../store'
import {getDocForId} from '../../utils/getDocForId'

class DocEditorCreateTemplateContents extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleKeyDown=this.handleKeyDown.bind(this)
		this.handleNameChange = this.handleNameChange.bind(this)
		this.handleDescriptionChange = this.handleDescriptionChange.bind(this)
		this.handleCreateTemplate = this.handleCreateTemplate.bind(this)
		let nameValue=''
		const docObj=getDocForId(props.docId)
		if(docObj && docObj.name !=='Untitled Doc'){
			nameValue=docObj.name
		}
		this.state={      		    	
    	nameValue: nameValue,
    	descriptionValue: '',   	
    	submitLoading:false
		}
	}


	componentDidMount(){
		window.addEventListener('keydown', this.handleKeyDown)
		setTimeout(() => { 
			const input = document.getElementById('createTemplateTitleInput')
			if(input){
				input.focus()
				input.setSelectionRange(input.value.length,input.value.length)
			}
		}, 200);
	}


	handleKeyDown(e){
	 if(e.keyCode==27){ //escape
			e.preventDefault()
			e.stopPropagation();
			this.props.closePopover()
		}
		else if(e.keyCode==13){ //enter
			e.preventDefault()
			e.stopPropagation();
			this.handleCreateTemplate()
		}
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

  handleNameChange(event) {    
    this.setState({nameValue: event.target.value})  
  }

  handleDescriptionChange(event) {    
    this.setState({descriptionValue: event.target.value})  
  }

  handleCreateTemplate(){
  	const {nameValue,descriptionValue}=this.state
  	if(nameValue){
  		this.setState({submitLoading:true})
  		createTemplate(this.props.docId,nameValue,descriptionValue).then((response)=>{
	  		setTimeout(() => { 
					this.props.closePopover()
					const actionType="createdTemplate"
					const userName=response.template_id
    			store.dispatch(showToastNotification(actionType,userName))
				}, 400);
  		})
  	}
  }



	render(){	
		const {docId} = this.props
		const submitDisabled=!this.state.nameValue

		const submitLoading = this.state.submitLoading

		return (
			<React.Fragment>         	
				<button onClick={this.props.closePopover} className='docEditor-createTemplatePopover-cancelBtn'>
					Cancel
				</button>

				<div className='docEditor-createTemplatePopover-titleInputContainer'>
					<input 			
						id={'createTemplateTitleInput'}					  						
						className='docEditor-createTemplatePopover-titleInput'
						placeholder='Untitled Template'
						value={this.state.nameValue}	  						
						onChange={this.handleNameChange} 				
						autoComplete="off"
						autoCorrect="off"
						spellCheck={false}
						maxLength={100}									
					/>
				</div>
				<div className='docEditor-createTemplatePopover-descriptionTextAreaContainer'>
					<textarea 
	  				className='docEditor-createTemplatePopover-descriptionTextArea'
	  				value={this.state.descriptionValue}								
						onChange={this.handleDescriptionChange}
						//placeholder='Description of template and any instructions on how to use it.'
						placeholder='Description'
						autoComplete="off"
						autoCorrect="off"
						spellCheck={false}
						maxLength={255}					
	  			/>
	  		</div>
  			<div className='docEditor-createTemplatePopover-submitBtnContainer'>
  				{!submitLoading && 
	        	<button disabled={submitDisabled} className='docEditor-createTemplatePopover-submitBtn' onClick={this.handleCreateTemplate}>
	        		<div className='docEditor-createTemplatePopover-submitBtn-iconContainer'>
	  						<Icon name='template' />
	  					</div>
	        		<div className='docEditor-createTemplatePopover-submitBtn-label'>
	        			Create Template
	        		</div>	        		  	
	        	</button>
        	}

        	{submitLoading && 
        	<button className='docEditor-createTemplatePopover-submitBtn'>        		        		
	        		<div className='docEditor-createTemplatePopover-submitBtn-loadingBar'>
				  			<Spring
				  				native								
									from={{
										opacity: 0.4,
										transform: 'translateX(-120px)'
									}}
									to={[
			          		{ 
			          			opacity: 0.05,
			          			transform: 'translateX(80px)' 
			          		},	          		
			        		]}								
									loop								
									config={{									
										duration: 800,									
										easing: easings.easeInOutCubic,
									}}
								>
					  			{styles => 
										<animated.div style={styles} className='docEditor-createTemplatePopover-submitBtn-loadingBar-ball' />
					  			}
					  		</Spring>	  		 			
				  	</div>
        			
        	</button>
        	}


	  			
				</div>


			</React.Fragment>
		)
	}
}

export default DocEditorCreateTemplateContents