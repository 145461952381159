import React from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import * as Dialog from '@radix-ui/react-dialog';
import { Transition, animated, config } from 'react-spring'
import OnboardingModal from './OnboardingModal'



class OnboardingModalContainer extends React.Component{  	
	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)        
    this.state={
      forceMount: false,   			
    }
  }

  onRest() {	
		if(!this.props.modalVisible){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

	render(){			
		const {showModal, openModal, closeModal} = this.props
		const {forceMount} = this.state		
		let modalTransformStart = 'scale(0.95)'		
		let modalTransformEnd = 'scale(1)'		
	  return (
	  	<React.Fragment>
	  		<Dialog.Root>
					<Dialog.Trigger asChild>
						{this.props.children}
					</Dialog.Trigger>
					<Dialog.Portal
						forceMount={showModal ? true : forceMount}						
					>											
						<Dialog.Content
				    	className={'onboardingModalContainer ' + (showModal ? ' onboardingModalContainer--visible ' : ' onboardingModalContainer--hidden ')} 
				    	onEscapeKeyDown={closeModal}					    
					    forceMount={showModal ? true : forceMount}					    
				    >
					    <Transition
				        items={showModal}
				        from={{ opacity: 0, transform: `${modalTransformStart}` }}
				        enter={{ opacity: 1, transform: `${modalTransformEnd}`  }}
				        leave={{ opacity: 0, transform: `${modalTransformStart}`  }}
				        reverse={showModal}
				        config={{tension: 700, friction: 36}}				        
				        config={{duration: 0}}
				        onRest={this.onRest}
				       >
				        {(styles, item) =>
				          item && 
				          <animated.div style={styles} className="onboardingModal"> 					           	
				           	<OnboardingModal 
				           		path={this.props.path}
				           		//path={'short'}
				           		stage={this.props.stage} 
				           		closeModal={closeModal}/>
				          </animated.div>
				        }
							</Transition>	
						
					</Dialog.Content>				    
				</Dialog.Portal>
			</Dialog.Root>					
		</React.Fragment>
	  )
	}
}

function mapStateToProps(state,ownProps) {
	let showModal=false 
	let stage
	let path //full or short
	const onboarding=state.onboarding 
	if(onboarding && onboarding.stage!=='complete'){
		showModal=true 
		stage=onboarding.stage
		path = onboarding.onboarding_path
	}
	return {
		showModal:showModal,
		stage:stage,
		path:path
	}
}


export default withRouter(connect(
	mapStateToProps,
	{

	})(OnboardingModalContainer))




//export default OnboardingModalContainer