import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import ReactPlayer from 'react-player/file'
import VideoDuration from '../../../utils/videoDuration'
import DocMediaZoomedSidePanel from '../docMediaUIComponents/DocMediaZoomedSidePanel'
import DocMediaZoomedReactionBar from '../docMediaUIComponents/DocMediaZoomedReactionBar'
import DocVideoInlineUI from './DocVideoInlineUI'
import DocVideoZoomedUI from './DocVideoZoomedUI'
// import {saveVideoProgress} from '../../../utils/videoProgress'
// import {getSavedVideoProgress} from '../../../utils/videoProgress'
import {toggleVideoZoomInRedux} from '../../../utils/toggleVideoZoomInRedux'



const MOUSE_MOVE_SHORT_DEBOUNCE_TIME = 1800
const MOUSE_MOVE_LONG_DEBOUNCE_TIME = 4000

//const SAVE_VIDEO_PROGRESS_THROTTLE_TIME=1000

// These are hardcoded global variables somewhere
const MAX_VIDEO_DOC_WIDTH = 720
const MAX_VIDEO_DOC_HEIGHT = 600 // this prevents excessively tall images
const MAX_VIDEO_DOC_ASPECT = MAX_VIDEO_DOC_WIDTH / MAX_VIDEO_DOC_HEIGHT

const SKIP_TO_COMMENT_OFFSET=2000

const SKIP_AMOUNT=5000 //skip amount inms

class DocVideoReactComponent extends React.Component{  

	 constructor(props){
    super(props)
    this.handlePlayPause=this.handlePlayPause.bind(this)
    this.seekToComment=this.seekToComment.bind(this)
    this.handlePlay=this.handlePlay.bind(this)
    this.handlePause=this.handlePause.bind(this)
    this.handleZoom=this.handleZoom.bind(this)
    this.handleZoomAndPlay=this.handleZoomAndPlay.bind(this)
    this.handleSeekChange=this.handleSeekChange.bind(this)
    this.handleSeekMouseUp=this.handleSeekMouseUp.bind(this)
    this.handleDoubleClick=this.handleDoubleClick.bind(this)
    this.handleSkip=this.handleSkip.bind(this)
    this.handleSeekMouseDown=this.handleSeekMouseDown.bind(this)
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.hideMouseMoveShort=this.hideMouseMoveShort.bind(this)    
    this.hideMouseMoveLong=this.hideMouseMoveLong.bind(this)    
    this.toggleZoom=this.toggleZoom.bind(this)
    this.handleVolumeChange=this.handleVolumeChange.bind(this)
    this.handleVolumeMouseUp=this.handleVolumeMouseUp.bind(this)
    this.handleVolumeMouseDown=this.handleVolumeMouseDown.bind(this)  
   	this.handlePlayerReady=this.handlePlayerReady.bind(this)  

    this.hideMouseMoveShort=debounce(this.hideMouseMoveShort,MOUSE_MOVE_SHORT_DEBOUNCE_TIME)
    this.hideMouseMoveLong=debounce(this.hideMouseMoveLong,MOUSE_MOVE_LONG_DEBOUNCE_TIME)

    //this.saveVideoProgress=this.saveVideoProgress.bind(this)
    //this.saveVideoProgress=throttle(this.saveVideoProgress,SAVE_VIDEO_PROGRESS_THROTTLE_TIME)

    this.state={
      url: null,	    
	    playing: false,	    
	    played: 0,
	    loaded: 0,
	    duration: 0,	    
      zoomed: false,  
      mouseMovedShort:false,
      mouseMovedLong:false,
      captionsActive: false,           
      volume: 1,
      adjustingVolume: false,
      muted:false,
      playerLoaded:false,
      hasPlayed:false,
    }
  }

  componentDidMount(){
  	// try and preload storyboard image
  	const storyboardImage = new Image();
  	storyboardImage.src = `https://image.mux.com/${this.props.muxId}/storyboard.png`
  	if(!this.state.playerLoaded){ //otherwise calls multiple times e.g. after seek

  		const progress=0.5

    //	this.player.seekTo(progress)
    		//this.player.seekTo(progress,'seconds')
    	 //this.setState({playing:true})
    	 //  setTimeout(() => {
      //      this.setState({playing:false,playerLoaded:true})
      //   }, 5);
    	 

    //	this.setState({playerLoaded:true,played:0.5,playedSeconds:100})
    // 	this.setState({
				// duration:185.485714,
				// loaded:0.5205428650963383,
				// loadedSeconds:96.553265,
				// played:0.5074996287854276,
				// playedSeconds:94.133931,
				// playerLoaded:true,
    // 	})
  	}
  }

  handlePlayerReady(){
  	//this.player.seekTo(0.5)
  }

  componentWillUnmount(){
  //	const progressSeconds = this.state.duration * this.state.played
  	//saveVideoProgress(this.props.nodeId,progressSeconds)
  }

  handlePlayPause(){
    this.setState({playing:!this.state.playing})
  }

  handlePlay(){
    this.setState({playing:true,hasPlayed:true})
  }

  handlePause(){
    this.setState({playing:false})
  }

  handleSeekMouseDown(){
    this.setState({seeking:true,muted:true})
  }

  handleZoom(){
  	toggleVideoZoomInRedux(true)
    this.setState({zoomed:!this.state.zoomed})
  }

  handleZoomAndPlay(){
  	toggleVideoZoomInRedux(true)
    this.setState({zoomed:true,playing:true})
  }
 
  handleSeekChange(e){
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
  	this.setState({playedSeconds:playedSeconds,played:e})
  }

  handleSeekMouseUp(e){
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e))
    const playedSeconds=e*this.state.duration
  	this.setState({playedSeconds:playedSeconds,played:e,muted:false})
  }

  // saveVideoProgress(seconds){
  // 	if(this.state.playerLoaded){
  // 		saveVideoProgress(this.props.nodeId,seconds)
  // 	}
  // }

  handleProgress = state => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state)
     // this.saveVideoProgress(state.playedSeconds)
    }
  }

  handleEnded = () => {
    this.setState({ playing: this.state.loop })
    this.player.seekTo(0)
  }

  handleDuration = (duration) => {
    this.setState({ duration })
  }

  ref = player => {
    this.player = player
  }

  handleMouseMove(){    
    this.setState({mouseMovedShort:true})
    this.setState({mouseMovedLong:true})
    this.hideMouseMoveShort()
    this.hideMouseMoveLong()
  }

  hideMouseMoveShort(){
    this.setState({mouseMovedShort:false})
  }

  hideMouseMoveLong(){
    this.setState({mouseMovedLong:false})
  }

  toggleZoom(e){
  	if(!this.state.zoomed){
  		toggleVideoZoomInRedux(true)
  		this.setState({ zoomed: true })
  	}else{
  		toggleVideoZoomInRedux(false)
  		this.setState({ zoomed: false})
  	}
  }  

  handleVolumeMouseUp = e => {
    this.setState({ adjustingVolume: false })
    this.setState({ volume: parseFloat(e) })
  }

  handleVolumeMouseDown = e => {
    this.setState({ adjustingVolume: true })   
  }

  handleVolumeChange = e => {
    this.setState({ volume: parseFloat(e) })    
  }

  handleDoubleClick(e){
  	e.stopPropagation()
  }

  handleSkip(skipDirection){
  	const playedSeconds=this.state.playedSeconds
  	let newProgress
  	if(skipDirection==='forward'){
  		newProgress=Math.min(playedSeconds+SKIP_AMOUNT/1000,this.state.duration)
  		this.player.seekTo(newProgress,'seconds')
  	}
  	else if(skipDirection==='back'){
  		newProgress=Math.max(playedSeconds-SKIP_AMOUNT/1000,0)
  		this.player.seekTo(newProgress,'seconds')
  	}
  }


  seekToComment(e,commentTimestamp){
  	e.stopPropagation()
  	e.preventDefault()
  	const newProgress=Math.max(commentTimestamp-SKIP_TO_COMMENT_OFFSET/1000,0)
  	this.player.seekTo(newProgress,'seconds')
  }
 
	render(){ 
		const { url, playing, controls, played, loaded, duration, volume, zoomed, mouseMovedShort, mouseMovedLong, hasPlayed, adjustingVolume} = this.state
		const progressSeconds = duration * played

		
		const videoTitle = this.props.title
		
		const muxPlaybackID = this.props.muxId
		const deliveryUrl =  `https://stream.mux.com/${muxPlaybackID}/high.mp4`		
		const originalVideoWidth = this.props.dimensions.width
		const originalVideoHeight = this.props.dimensions.height

		const subtitleUrl = this.props.subtitles
		
		//

		const originalVideoAspect = originalVideoWidth / originalVideoHeight
		
		let videoWidth
		let videoHeight

		let videoContainerWidth
		let videoContainerHeight
		let videoContainerTop
		let videoContainerLeft

		let videoContainerStyle = {}


		//
		// Inline Video Size		

			// Fit
			let inlineVideoDocWidth = MAX_VIDEO_DOC_WIDTH
			let inlineVideoDocHeight = MAX_VIDEO_DOC_WIDTH / originalVideoAspect

			//if aspect is such that it will be too tall, then fit			
			if(MAX_VIDEO_DOC_ASPECT > originalVideoAspect){
				inlineVideoDocHeight = MAX_VIDEO_DOC_HEIGHT
				inlineVideoDocWidth = inlineVideoDocHeight * originalVideoAspect
			}

			if(!zoomed){			
				videoWidth = inlineVideoDocWidth
				videoHeight = inlineVideoDocHeight

				// don't need to set video container width
				videoContainerWidth = MAX_VIDEO_DOC_WIDTH
				videoContainerHeight = ''
				videoContainerTop = ''
				videoContainerLeft = ''

			}

			if(!zoomed){
				videoContainerStyle = {				
					width: `${videoContainerWidth}px`,				
				}
			}
		


			


		//
		// Zoomed Video Size

		// this is calculated screen size
		const windowWidth = window.innerWidth
		const windowHeight = window.innerHeight
	
		// Hardcoded Zoom Layout Values
		// this is the distance from the window to the modal edge
		const windowInnerLeftRightMargin = 20
		const windowInnerTopMargin = 30
		const windowInnerBottomMargin = 20

		const zoomedVideoModalTopPadding = 0 // from the edge to the video
		const zoomedVideoModalBottomPadding = 88 // from the edge to the video

		const minimiumModalWidth = 1180

		const maxAvailableZoomedVideoWidth = windowWidth - (windowInnerLeftRightMargin * 2)
		const maxAvailableZoomedVideoHeight = windowHeight - (windowInnerTopMargin + windowInnerBottomMargin) - zoomedVideoModalTopPadding - zoomedVideoModalBottomPadding
		const maxAvailableZoomedVideoAspect = maxAvailableZoomedVideoWidth / maxAvailableZoomedVideoHeight

		let zoomedVideoFitWidth = maxAvailableZoomedVideoWidth
		let zoomedVideoFitHeight = maxAvailableZoomedVideoWidth / originalVideoAspect

		if(maxAvailableZoomedVideoAspect > originalVideoAspect){
			zoomedVideoFitHeight = maxAvailableZoomedVideoHeight
			zoomedVideoFitWidth = maxAvailableZoomedVideoHeight * originalVideoAspect
		}

		if(zoomed){
			videoWidth = Math.min(zoomedVideoFitWidth, originalVideoWidth) // don't make bigger than original dimensions			
			videoHeight = videoWidth / originalVideoAspect

			videoContainerWidth = Math.max(minimiumModalWidth, videoWidth)
			videoContainerHeight= videoHeight + zoomedVideoModalTopPadding + zoomedVideoModalBottomPadding
			videoContainerTop = Math.max(((windowHeight - videoContainerHeight) / 2), windowInnerTopMargin)
			videoContainerLeft = (windowWidth - videoContainerWidth) / 2
		}



		if(zoomed){
			videoContainerStyle = {
				top: `${videoContainerTop}px`,
				left: `${videoContainerLeft}px`,
				width: `${videoContainerWidth}px`,
				height: `${videoContainerHeight}px`
			}
		}
		
		let videoStyle = {
			width: `${videoWidth}px`,
			height: `${videoHeight}px`
		}

		const {messages}=this.props 
		let timestampedMessages=[]
		messages.forEach((message)=>{
			if(message.selection_snapshot && (message.selection_snapshot.timestampSeconds || message.selection_snapshot.timestampSeconds==0)){
				timestampedMessages.push(message)
			}
		})

			// to correctly horizontally center the image in zoomed container
		// const zoomedVideoXOffset = (maxAvailableZoomedVideoWidth - zoomedVideoFitWidth) / 2

		// if(zoomed){
		// 	videoStyle = {
		// 		width: `${videoWidth}px`,
		// 		height: `${videoHeight}px`,
		// 		left: `${zoomedVideoXOffset * -1}px`
		// 	}
		// }

		return (
			<React.Fragment>
				<div style={videoContainerStyle} className={'doc-videoContainer' + (zoomed ? ' doc-videoContainer--zoomed ' : ' doc-videoContainer--inline ')} onMouseMove={this.handleMouseMove}>
						{!zoomed && 
							<DocVideoInlineUI 
								newMessage={this.props.newMessage}
								playing={playing}
								played={played}
								hasPlayed={hasPlayed}
								progressSeconds={progressSeconds}
								videoTitle={videoTitle}
								handleZoomAndPlay={this.handleZoomAndPlay}
								handleZoom={this.handleZoom}
								mouseMovedShort={mouseMovedShort}
								mouseMovedLong={mouseMovedLong}
								duration={duration}
								handleSeekMouseUp={this.handleSeekMouseUp}
								handleSeekMouseDown={this.handleSeekMouseDown}
								onChange={this.handleSeekChange}
								handleSeekChange={this.handleSeekChange}
								handlePlayPause={this.handlePlayPause}
								captionsActive={this.state.captionsActive}
								toggleCaptions={()=>{this.setState({captionsActive: !this.state.captionsActive})}}
								handleVolumeChange={this.handleVolumeChange}
								handleVolumeMouseUp={this.handleVolumeMouseUp}
								handleVolumeMouseDown={this.handleVolumeMouseDown}
								volume={volume}
								adjustingVolume={adjustingVolume}
								subtitleUrl={subtitleUrl}
								timestampedMessages={timestampedMessages}
								deleteVideo={this.props.deleteVideo}
								seekToComment={this.seekToComment}
								nodeId={this.props.nodeId}
								title={this.props.title}
								handleSkip={this.handleSkip}
								updateTitle={this.props.updateTitle}
							/>		
						}

						{/* SHOW OUTLINE WHEN SELECTED */}
						{!zoomed && 
							<div className='doc-videoContainer--inline--selectionBG'/>
						}

						{zoomed &&
							<DocVideoZoomedUI
								playing={playing}
								played={played}
								progressSeconds={progressSeconds}
								videoTitle={videoTitle}
								handleZoomAndPlay={this.handleZoomAndPlay}
								handleZoom={this.toggleZoom}
								mouseMovedShort={mouseMovedShort}
								mouseMovedLong={mouseMovedLong}
								duration={duration}
								handleSeekChange={this.handleSeekChange}
								handleSeekMouseUp={this.handleSeekMouseUp}
								handleSeekMouseDown={this.handleSeekMouseDown}
								onChange={this.handleSeekChange}
								pauseVideo={()=>{this.setState({playing:false})}} //seperate to toggle because should always pause 
								handlePlayPause={this.handlePlayPause}
								captionsActive={this.state.captionsActive}
								toggleCaptions={()=>{this.setState({captionsActive: !this.state.captionsActive})}}
								handleVolumeChange={this.handleVolumeChange}
								handleVolumeMouseUp={this.handleVolumeMouseUp}
								handleVolumeMouseDown={this.handleVolumeMouseDown}
								volume={volume}
								adjustingVolume={adjustingVolume}
								commentRowWidth={videoContainerWidth}
								muxPlaybackID={muxPlaybackID}
								subtitleUrl={subtitleUrl}
								sendMediaModalMessage={this.props.sendMediaModalMessage}
								timestampedMessages={timestampedMessages}
								handleSkip={this.handleSkip}
								seekToComment={this.seekToComment}
							/>
						}

					<div className='doc-videoInnerContainer'>
						<div style={videoStyle} className='doc-video'>											
							<ReactPlayer   			  
		  			    ref={this.ref}
		            className='doc-video-video'
		            width='100%'
		            height='100%'
		            url={deliveryUrl}
		            progressInterval={50} //TODO maybe make this dynamic depending on length of video
		            //pip={pip}
		            playing={playing}
		            muted={this.state.muted}
		            volume={volume}		                 
		            onPlay={this.handlePlay}
		            //onEnablePIP={this.handleEnablePIP}
		            //onDisablePIP={this.handleDisablePIP}
		            onPause={this.handlePause}
		            onEnded={this.handleEnded}
		            onProgress={this.handleProgress}
		            onDuration={this.handleDuration}
		            onEnded={this.handleEnded}
		            onReady={this.handlePlayerReady}
		    			/>
						</div>		
					</div>					

					{/*}
					{zoomed &&
						<DocMediaZoomedSidePanel 
							width={zoomImageModalSidePanelWidth}
							toggleZoom={this.toggleZoom}
							title={videoTitle}
							description={videoDescription}
						/>
					}
					*/}
			</div>
				{zoomed &&
		      <div onClick={this.toggleZoom} className='doc-videoContainerZoomBG'/>
		    }
			</React.Fragment>
		)
	}
}

export default DocVideoReactComponent
