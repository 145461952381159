import React from 'react'
import Icon from '../../misc/Icon'
import {getUserForId} from '../../../utils/getUserForId'

class DocBlockLinearIssueAssignee extends React.Component{  	

	render(){	

		const {label,assignee} = this.props				
		
		let avatar
		const user=getUserForId(assignee)
		if(user){
			avatar=user.avatar
		}
	
	  return (
	  	  	
  		<div className='doc-blockLinearIssue-assigneeContainer'>
  			{/* MIGHT ADD IN A DROPDOWN TO EDIT */}

  			
  			{!assignee &&
	  			<div className='doc-blockLinearIssue-assignee doc-blockLinearIssue-assignee--unassigned'>
	  				<Icon name='userCircle' />
	  			</div>
  			}

  			{assignee && 
	  			<div className='doc-blockLinearIssue-assignee doc-blockLinearIssue-assignee--assigned'> 
						<img className='doc-blockLinearIssue-assignee-img' src={avatar} />
					</div>
				}
  		</div>	  
			
	  )
	}
}

export default DocBlockLinearIssueAssignee
