import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {isPrivateDoc} from '../../../utils/isPrivateDoc'
import {openInFigma} from '../../../utils/openInFigma'
import {calculateUnreadsForThread} from '../../../utils/notifications/calculateUnreadsForThread'
import {getOrgThreadsEnabled} from '../../../utils/getOrgThreadsEnabled'
import FigmaEmbedTopBarOpenThreadBtn from './FigmaEmbedTopBarOpenThreadBtn'

class DocFigmaEmbedTopbar extends React.Component{  
   
	render(){ 	
		const {loadingPlaceholder, title,docId,docThread} = this.props
		let effectsTooltipLabel = 'Add Presentation Effects'
		if(this.props.hasPresentationEffects){
			effectsTooltipLabel = 'Edit Presentation Effects'
		}
		let disableEffectsBtn = false
		if(this.props.fullyInternalCrop || this.props.showEffectsToolbar){
			disableEffectsBtn = true
		}
		const isPrivate=isPrivateDoc(docId)
		let unreadCount
		if(docThread){
			unreadCount=calculateUnreadsForThread(this.props.docId,docThread.threadParent.message_id)
		}

		const threadsEnabled=getOrgThreadsEnabled()

		return (			
			<div style={{width:`${this.props.width}px`, marginLeft:`${this.props.leftMargin}px`}} className={'doc-figma-topbar ' + (loadingPlaceholder ? ' doc-figma-topbar--placeholder ' : ' ')}>
				{/* CLICK TO OPEN FIGMA DEEPLINK */}
				<button className='doc-figma-topbar-titleBtn' onClick={()=>{openInFigma(this.props.embedId)}}>
					<div className='doc-figma-topbar-titleBtn-iconOuterContainer'>
						{!loadingPlaceholder && 
							<div className='doc-figma-topbar-titleBtn-iconContainer doc-figma-topbar-titleBtn-iconContainer--grayscale'>
								<Icon name='insertBarFigma' />
							</div>						
						}
						{loadingPlaceholder && 
						<div className='doc-figma-topbar-titleBtn-iconContainer doc-figma-topbar-titleBtn-iconContainer--color'>
							<Icon name='insertBarFigmaColor' />
						</div>						
						}
					</div>
					{!loadingPlaceholder && 
					<div className='doc-figma-topbar-titleBtn-label'>
						{title}
					</div>
					}
					{loadingPlaceholder &&
						<div className='doc-figma-topbar-titleBtn-label'>
							Loading Preview
						</div>					
					}
					{/*}
					<div className='doc-figma-topbar-titleBtn-tooltipIconOuterContainer doc-figma-topbar-titleBtn-tooltipIconOuterContainer'>
						<div className='doc-figma-topbar-titleBtn-tooltipIconContainer'>
							<Icon name='linkArrow' />
						</div>
					</div>
					*/}
					{this.props.width > 500 && !loadingPlaceholder &&
					<div className='doc-figma-topbar-titleBtn-tooltipContainer' >
						<div className='doc-figma-topbar-titleBtn-tooltip'>
							Open in Figma
						</div>
					</div>
					}
				</button>

				<div className='doc-figma-topbar-hSpacer'/>
				
				
				{!loadingPlaceholder &&	!isPrivate &&	!threadsEnabled && 	
					<button onClick={this.props.newMessage} className='doc-figma-topbar-commentBtn'>
						<div className='doc-figma-topbar-commentBtn-iconContainer'>
							<Icon name='messageOutlineMedium' />
						</div>
						<div className='doc-figma-topbar-commentBtn-label'>
							Comment
						</div>
					</button>													
				}
				

				{!loadingPlaceholder &&	!isPrivate &&	!docThread && threadsEnabled &&
					<button onClick={(e)=>{this.props.newMessage(e,true)}} className='doc-figma-topbar-commentBtn'>
						<div className='doc-figma-topbar-commentBtn-iconContainer'>
							<Icon name='threadRegular' />
						</div>
						<div className='doc-figma-topbar-commentBtn-label'>
							Thread
						</div>
					</button>													
				}
				{!loadingPlaceholder &&	!isPrivate &&	docThread && threadsEnabled &&

					<FigmaEmbedTopBarOpenThreadBtn 
						isInDocThread
						thread={docThread}
						openThread={(e)=>{this.props.openThread(docThread.threadParent.message_id)}}
						threadChildMessages={docThread.threadChildren}
						messageId={docThread.threadParent.message_id}
						unreadCount={unreadCount}
					/>
				}


				{/*}
				<button onClick={(e)=>{this.props.openThread(docThread.threadParent.message_id)}} className='doc-figma-topbar-commentBtn'>
						<div className='doc-figma-topbar-commentBtn-iconContainer'>
							<Icon name='threadRegular' />
						</div>
						<div className='doc-figma-topbar-commentBtn-label'>
							Count:{docThread.threadChildren.length+1}
							unreads:{unreadCount}
						</div>


					</button>				

	*/}
					

				
				{/*}
				{!loadingPlaceholder &&
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={300}>
							<Tooltip.Trigger asChild>																	
								<button onClick={this.props.toggleShowEffectsToolbar} className='doc-figma-topbar-toggleEffectsBtn'>
									<Icon name='effects' />
								</button>
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																																	
								{effectsTooltipLabel}
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
				}
				*/}

				<DropdownMenu.Root>
					{!loadingPlaceholder && 
				    <DropdownMenu.Trigger asChild>		    			    	
							<button className='doc-figma-topbar-dropdownBtn'>
								<Icon name='ellipses' />
							</button>										
				    </DropdownMenu.Trigger>
			  	}
		    
		    <DropdownMenu.Content align="end" alignOffset={-8} className='dropdownMenu dropdownMenu--figmaEmbed'>		    	
		    	

		    	<DropdownMenu.Item onSelect={()=>{openInFigma(this.props.embedId)}}>
		        Open in Figma
		      </DropdownMenu.Item> 	

		      {/* LATER! 
					<DropdownMenu.Separator />									
					<DropdownMenu.Item>
						Ping...
					</DropdownMenu.Item>
					<DropdownMenu.Item>
						Send via Slack...
					</DropdownMenu.Item>
					<DropdownMenu.Item>
						Copy Link
					</DropdownMenu.Item>						
					*/}									
					{/* NOT SURE WE NEED THIS 
					<DropdownMenu.Item>
						Copy Figma URL
					</DropdownMenu.Item>				
		      <DropdownMenu.Separator />
					<DropdownMenu.Item className='dropdownMenu-item--disabled'>
						Refresh Frame
					</DropdownMenu.Item>
					<DropdownMenu.Item>
						Replace...
					</DropdownMenu.Item>
					<DropdownMenu.Item>
						Crop...
					</DropdownMenu.Item>	
		      <DropdownMenu.Separator />
		      */}				

		      {!disableEffectsBtn && 
		    	<DropdownMenu.Item onSelect={this.props.openEffectsToolbar}>
	        	{effectsTooltipLabel}
	        </DropdownMenu.Item>	 
	        } 

	        {disableEffectsBtn && 			
	       	<DropdownMenu.Item className='dropdownMenu-item--disabled'>
	        	{effectsTooltipLabel}
	        </DropdownMenu.Item>	  
	        } 	       
	        <DropdownMenu.Separator />  

	        {!isPrivate && threadsEnabled &&
	        	<React.Fragment>
				    	<DropdownMenu.Item onSelect={this.props.newMessage}>
				        Comment...
				      </DropdownMenu.Item> 
				      <DropdownMenu.Separator />
			      </React.Fragment>
		    	}

	        <DropdownMenu.Item onSelect={this.props.deleteFigmaEmbed}>
	        	Delete 
	        </DropdownMenu.Item>	 	        
		    </DropdownMenu.Content>
		  </DropdownMenu.Root>			
		</div>							
		)
	}
}

export default DocFigmaEmbedTopbar
