import React, {Component} from 'react'
import { connect } from 'react-redux'
import {authenticate} from '../actions/auth'
import { withRouter } from 'react-router-dom'
import SignIn from '../components/SignIn'
import ReactGA from 'react-ga4'


class LoginContainer extends Component {
	componentDidMount(){
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Login Page" });
		}
	}

	render() {
		return (
			<div className="loginPageContainer">			 
			 <SignIn 
			 />
			</div>
		)
	}
}


const mapStateToProps = (state) => ({});


export default withRouter(connect(
	mapStateToProps,
	{
	})(LoginContainer))