import store from '../../store'
import filter from 'lodash/filter'

export function openTranscriptSearch() {	
	store.dispatch({type:'OPEN_TRANSCRIPT_SEARCH'})
}

export function closeTranscriptSearch() {	
	store.dispatch({type:'CLOSE_TRANSCRIPT_SEARCH'})
	const view=window.transcriptView
	if(view){
		let tr = view.state.tr
		tr.setMeta("transcriptSearchPlugin", { deactivate: true})
		view.dispatch(tr)
	}
}


export function changeTranscriptSearchActiveIndex(searchTerm,index) {	
	const view=window.transcriptView
	if(view){
		let tr = view.state.tr
		tr.setMeta("transcriptSearchPlugin", { activate: true,searchTerm:searchTerm,activeIndex:index})
		view.dispatch(tr)
	}
}

export function updateTranscriptSearchResults(results,activeIndex) {	
	store.dispatch({type:'UPDATE_TRANSCRIPT_SEARCH_RESULTS',results,activeIndex})
}



