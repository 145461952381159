import findIndex from 'lodash/findIndex'


export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_MEETING_PROJECT_CONNECTIONS_SUCCESS':
		return action.response


	case 'CREATE_MEETING_PROJECT_CONNECTION_REQUEST':
		return [...state,action.connection]

	case 'ARCHIVE_PROJECT_CONNECTION':
		let archivedIndex = findIndex(state,(connection => {
			return connection['connection_id'] === action.connectionId
		}))
		if(archivedIndex>-1){
			return [
			...state.slice(0,archivedIndex),
			...state.slice(archivedIndex + 1)
			]
		}else return state

		
	
	case 'CREATE_MEETING_PROJECT_CONNECTION_SUCCESS':
		const connectionId=action.response.connection_id
		let createdIndex=findIndex(state,(connection => {
			return connection.connection_id === connectionId
		}))
		if(createdIndex != -1){
			return [
				...state.slice(0,createdIndex),
				action.response,
				...state.slice(createdIndex + 1)
			]
		}else return [...state,action.response]


	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
 