export function getNodePos(anchorNode){
	if(window.view && window.view.state){

		const state=window.view.state
		let nodePos
		state.doc.nodesBetween(0,state.doc.content.size, (node, pos) => {
			if(anchorNode===node){
				nodePos=pos
			}
		})

		return nodePos
	}else return null
}
