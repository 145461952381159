import React from 'react'
import Icon from '../../misc/Icon'
import DocImageZoomedModalBtn from '../../docEditor/mediaModals/DocImageZoomedModalBtn'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

class DocVideoZoomedUITitleBarDropdown extends React.Component{  


	render(){ 		
		
		return (
			<DropdownMenu.Root>
		    <DropdownMenu.Trigger className='doc-zoomedImageModal-header-menuBtn'>
					<Icon name='imageMenuBars' />								
		    </DropdownMenu.Trigger>
		    <DropdownMenu.Content className='dropdownMenu dropdownMenu--commentReactionsMenu'>
		    	
		    	<DropdownMenu.Item>
		        Copy link
		       </DropdownMenu.Item> 	
		       <DropdownMenu.Separator />
		    	<DropdownMenu.Item className='dropdownMenu-item--warning'>
	        	Display as Thumbnail
	        </DropdownMenu.Item>	  
	        <DropdownMenu.Separator />    		      	
	        <DropdownMenu.Item className='dropdownMenu-item--warning'>
	        	Delete
	        </DropdownMenu.Item>	      	
		    </DropdownMenu.Content>
		  </DropdownMenu.Root>			
		)
	}
}


class DocVideoZoomedUITitleBar extends React.Component{  
	   
	render(){ 		
				
		return (	
			
			<div className='doc-video-zoomedUI-closeBtnHoverContainer'>
				<button onClick={this.props.handleZoom} className='doc-video-zoomedUI-closeBtnOuterContainer'>
					<div className='doc-video-zoomedUI-closeBtn'>
						<Icon name='cross' />
					</div>
				</button>

				{/*}	
			<div className='doc-video-zoomedUI-titleBarContainer'>
				<div className='doc-video-zoomedUI-titleBar'>
					<div className='doc-video-zoomedUI-titleBar-leftContainer'>						
						<DocVideoZoomedUITitleBarDropdown
							
						 />
						<input 
  						className='doc-video-zoomedUI-titleBar-videoTitleInput'
  						placeholder='Add a title...'
  						value={this.props.title}
  						//value={this.state.value}
							//onChange={this.handleChange} 						
  					/>
					</div>
					<div className='doc-video-zoomedUI-titleBar-rightContainer'>
						<div onClick={this.props.handleZoom} className='doc-video-zoomedUI-titleBar-closeBtnOuterContainer'>
							<DocImageZoomedModalBtn
								iconName='cross'								
							/>
						</div>
					</div>						
				</div>				
			</div>
			*/}

			</div>
			
			
							
		)
	}
}

export default DocVideoZoomedUITitleBar
