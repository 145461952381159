import vampire from './vampire'
import storm  from './storm';
import sublime from './sublime'
import studio from './studio'
import {artifactLight} from './artifact'
import {artifactDark} from './artifact'


const themes = {
	vampire,
  storm,
  sublime,
  studio,
  artifactLight,
  artifactDark

};

export default themes

