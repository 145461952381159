import React from 'react'
import Icon from '../misc/Icon'
import {getDocForId} from '../../utils/getDocForId'
import {createMarkup} from '../../utils/createMarkup'

class LibraryDocPreview extends React.Component{  
	render(){	

		const {docId}=this.props

		let doc

		if(docId){
			doc = getDocForId(docId)
		
		}

		



	  return (	  	
			<div className={'libraryDocPreviewContainer ' + (docId ? ' libraryDocPreviewContainer--show ' : ' libraryDocPreviewContainer--hide ')}>
				{doc && 
					<div className='libraryDocPreview'>
						<div className='libraryDocPreview-docContainer'>
							<div className='libraryDocPreview-doc'>
								<div className='libraryDocPreview-doc-title'>
									{doc.name}
								</div>
								<div 
									className="libraryDocPreview-doc-innerWrapper"
									dangerouslySetInnerHTML={createMarkup(doc.doc_html)}
								/>
							</div>
						</div>
						<div className='libraryDocPreview-bottomGradient' />
					</div>
				}
			</div>
	  )
	}
}

export default LibraryDocPreview
