import {fetch,put} from '../api'


export function fetchTranscript(transcriptId) {

	return (dispatch) => fetch(`/transcripts/${transcriptId}`)
		.then((response) => {
			dispatch({ type: 'FETCH_A_TRANSCRIPT_SUCCESS', response })
		
			return response
		})
		.catch((error) => {
			// console.log('error in fetch transcript request')
			// console.log(error)
			return error
		})
}
