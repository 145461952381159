const HorizontalLineNodeSpec ={
    group: "block",
    selectable:false, //can't cursor into it
    //content: "block*",
    parseDOM: [{
	    tag: "div",
	    getAttrs: dom => {
	      let classname = dom.getAttribute("class")
	      return classname==='doc-divider'
	    	}
  	}],
    toDOM: () => ["div",{class:'doc-divider'}],
}
    
   

 export default HorizontalLineNodeSpec
 
