import React from 'react'
import Icon from '../../misc/Icon'
import LinearMarker from '../../misc/LinearMarker'
import AvatarImage from '../../AvatarImage'
import StaticCheckbox from '../../uiKit/StaticCheckbox'
import sortBy from 'lodash/sortBy'
import {getOrgMembers} from '../../../utils/getOrgMembers'
import {getContacts} from '../../../utils/meetings/getContacts'
import {getOrg} from '../../../utils/getOrg'
import EditMeetingParticipantListPopoverNewContact from './EditMeetingParticipantListPopoverNewContact'


class EditMeetingParticipantMemberRow extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectMember=this.handleSelectMember.bind(this)
		this.state={
      checked: this.props.checked
    }
	}

	handleSelectMember(){
		const newCheckedStatus=!this.state.checked
		let newParticipantsArray=[]
		let newContactParticipantsArray=[]
		const {isContact} = this.props
		if(isContact){
			newParticipantsArray=this.props.participants
			if(newCheckedStatus==true){
				newContactParticipantsArray=this.props.contactParticipants.slice()
				newContactParticipantsArray.push(this.props.contact.contact_id)
			}else{
				this.props.contactParticipants.forEach((contactId)=>{
				if(contactId!=this.props.contact.contact_id){
					newContactParticipantsArray.push(contactId)
				}
			})
		}
		}else{
			newContactParticipantsArray=this.props.contactParticipants
			if(newCheckedStatus==true){
				newParticipantsArray=this.props.participants.slice()
				newParticipantsArray.push(this.props.user.user_id)
			}else{
				this.props.participants.forEach((memberId)=>{
				if(memberId!=this.props.user.user_id){
					newParticipantsArray.push(memberId)
				}
			})
			}
		}
		this.setState({checked: newCheckedStatus})		
		this.props.updateMeetingParticipants(newParticipantsArray,newContactParticipantsArray)
		if(this.props.resetSearchInput){
			this.props.resetSearchInput()
		}
	}

	render(){	
		const {active, user, isContact, contact, selected} = this.props	
		const {checked} = this.state	
		let contactName,contactCompany,contactEmail		
		if(isContact){
			contactName=contact.name 
			contactCompany=contact.company
			contactEmail=contact.email
		}										
		let contactPrimaryLabel = contactName
		if(!contactName){
			contactPrimaryLabel = contactEmail
		}
		let contactSecondaryLabel = contactCompany
		if(!contactCompany && contactName && contactEmail){
			contactSecondaryLabel = contactEmail
		}		
		if(!contactCompany && !contactName){
			contactSecondaryLabel = null
		}		

		return (
			<div data-state={checked ? "checked" : "unchecked"} data-state={selected ? "selected" : "notSelected"} onClick={this.handleSelectMember} className={'popoverMenu-item popoverMenu-item--meetingParticipantRow'}>
				{!isContact && 
					<div className='popoverMenu-item-avatarContainer'>					
						<AvatarImage smallAvatar className='popoverMenu-item-avatar' avatarUrl={user.avatar}/> 
					</div>
				}
				{!isContact && 
					<div className='popoverMenu-item-label'>
						{user.display_name}
					</div>
				}
				{isContact && 					
					<div className='popoverMenu-item-avatarContainer'>
						<AvatarImage className='popoverMenu-item--meetingParticipantRow-contactAvatar' contactId={contact.contact_id} sideLength={20}/> 
					</div>				
				}
				{isContact && 					
					<div className='popoverMenu-item-label'>	
						<div className='popoverMenu-item--meetingParticipantRow-primaryLabel'>
							{contactPrimaryLabel}
						</div>
						{contactSecondaryLabel && 
							<div className='popoverMenu-item--meetingParticipantRow-secondaryLabel'>
								{contactSecondaryLabel}
							</div>
						}
					</div>				
				}
				<div className='popoverMenu-item--meetingParticipantRow-editBtnContainer'>					
					{!checked &&
						<div className='popoverMenu-item--meetingParticipantRow-addBtn'>
							<Icon name='plus' />
						</div>
					}
					{checked &&
						<div className='popoverMenu-item--meetingParticipantRow-removeBtn'>
							<Icon name='cross-small' />
						</div>
					}
				</div>	
			</div>
		)
	}
}




class EditParticipantListPopoverContents extends React.Component{  

	constructor(props) {
    super(props)    
   	this.handleChange = this.handleChange.bind(this);
   	this.handleBackFromNewContact = this.handleBackFromNewContact.bind(this);
   	this.resetSearchInput=this.resetSearchInput.bind(this)
   	this.handleKeyDown=this.handleKeyDown.bind(this)	
   	this.selectIndex=this.selectIndex.bind(this)
   	this.addNewContactAsParticipant=this.addNewContactAsParticipant.bind(this)
   	this.updateMeetingParticipants=this.updateMeetingParticipants.bind(this)
    this.state = {
    	searchValue: '',   
    	activeIndex:0,
    	showNewContactScreen: false, 	
    	suggestions:[]
   	};    
  }

  handleKeyDown(e){
    const {suggestions} = this.state
    if(e.keyCode === 40) { //down arrow
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex+1
      if(newIndex==suggestions.length){
        newIndex=0
      }
      if(oldIndex==null){
        newIndex=0
      }
      this.setState({activeIndex:newIndex})
    } else if (e.keyCode === 38) { //up arrow
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex-1
      if(newIndex<0){
        newIndex=suggestions.length-1
      }
      this.setState({activeIndex:newIndex})
    } else if( e.keyCode === 13) { //enter
      e.stopPropagation()
      e.preventDefault()
      const hasSearchValue = this.state.searchValue.length > 0   	
   		const hasSearchResults = this.state.suggestions.length>0 
      if(hasSearchValue){
      	if(hasSearchResults){
      		this.selectIndex(this.state.activeIndex)
      	}else{
      		this.setState({showNewContactScreen:true})
      	}
      }
    } 
  }

  selectIndex(index){
  	const suggestion=this.state.suggestions[index]
  	let newParticipantsArray=[]
		let newContactParticipantsArray=[]
		if(suggestion.contact_id){
			const isChecked = this.props.contactParticipants.some(participant => participant === suggestion.contact_id);
			const newCheckedStatus=!isChecked
			newParticipantsArray=this.props.participants
			if(newCheckedStatus==true){
				newContactParticipantsArray=this.props.contactParticipants.slice()
				newContactParticipantsArray.push(suggestion.contact_id)
			}else{
				this.props.contactParticipants.forEach((contactId)=>{
				if(contactId!=suggestion.contact_id){
					newContactParticipantsArray.push(contactId)
				}
			})
			}
		}else{
			const isChecked = this.props.participants.some(participant => participant === suggestion.user_id);
			const newCheckedStatus=!isChecked
			newContactParticipantsArray=this.props.contactParticipants
			if(newCheckedStatus==true){
				newParticipantsArray=this.props.participants.slice()
				newParticipantsArray.push(suggestion.user_id)
			}else{
				this.props.participants.forEach((memberId)=>{
				if(memberId!=suggestion.user_id){
					newParticipantsArray.push(memberId)
				}
			})
			}
		}	
		this.updateMeetingParticipants(newParticipantsArray,newContactParticipantsArray)
		this.resetSearchInput()
  }

  updateMeetingParticipants(newParticipantsArray,newContactParticipantsArray){
  	const scrollableEl=document.getElementById('editMeetingParticipantsPopover-scrollList')
  	if(scrollableEl){
  		scrollableEl.scrollTop = 0
  	}
  	this.props.updateMeetingParticipants(newParticipantsArray,newContactParticipantsArray)
  	const inputEl=document.getElementById('editMeetingParticipantsPopover-search')
  	if(inputEl){
  		inputEl.focus()
  	}
  }

  addNewContactAsParticipant(contact){
  	let newParticipantsArray=this.props.participants
		let newContactParticipantsArray=this.props.contactParticipants.slice()
		newContactParticipantsArray.push(contact.contact_id)
		this.updateMeetingParticipants(newParticipantsArray,newContactParticipantsArray)
		this.resetSearchInput()
  }

	handleChange(event) {  	  
		const searchTerm=event.target.value
		const searchTermLowerCase=searchTerm.toLowerCase()
    this.setState({searchValue: searchTerm})
    const orgMembers = getOrgMembers()	
		const contacts = getContacts()
		//let suggestions=orgMembers.concat(contacts)
		let suggestions=[]
		orgMembers.forEach((user)=>{
			if((user.display_name.toLowerCase()).startsWith(searchTermLowerCase)){
					suggestions.push(user) 			
			}
		})
		contacts.forEach((contact)=>{
			if((contact.name.toLowerCase()).startsWith(searchTermLowerCase)){
				suggestions.push(contact) 			
			}
		})
		this.setState({suggestions:suggestions,activeIndex:0})
  }

  handleBackFromNewContact(event) {  	   
    this.setState({showNewContactScreen: false});
    this.setState({searchValue: ''});
  }

  resetSearchInput(){
  	this.setState({searchValue:'',suggestions:[],activeIndex:0})
  }


	render(){	
		const {participants,contactParticipants} = this.props	
		const {searchValue, showNewContactScreen,suggestions,activeIndex} = this.state
		const orgMembers = getOrgMembers()	
		const contacts = getContacts()
		// alphabetical sort
		const sortedMembers = sortBy(orgMembers, function(user){
			return user.display_name.toString().toLowerCase()
   	})
   	const sortedContacts = sortBy(contacts, function(contact){
			return contact.name.toString().toLowerCase()
   	})

   	const org = getOrg()
   	let orgName
   	if(org){
   		orgName = org.name
   	}

		const hasSearchValue = searchValue.length > 0   	
   	const hasSearchResults = this.state.suggestions.length>0 // I think for now probably easiest way of adding external users 
		const uncheckedOrgMembers = sortedMembers.filter(orgMember => !participants.includes(orgMember.user_id));
		const noUncheckedOrgMembers = uncheckedOrgMembers.length === 0

	  return (
	  	<div className='editMeetingParticipantsPopover-contents'>
	  		
	  		{showNewContactScreen &&
	  			<EditMeetingParticipantListPopoverNewContact 
	  				handleBackFromNewContact={this.handleBackFromNewContact}
	  				addNewContactAsParticipant={this.addNewContactAsParticipant}
	  				searchValue={searchValue}
	  			/>
	  		}

	  		{!showNewContactScreen && 
	  			<React.Fragment>
			  		<div className='editMeetingParticipantsPopover-searchContainer'>
			  			<input 
			  				autoFocus
			  				id='editMeetingParticipantsPopover-search'
								placeholder='Search or type email'
								className='editMeetingParticipantsPopover-search'
								value={this.state.searchValue}
								onChange={this.handleChange}			
								onKeyDown={this.handleKeyDown}
							/>
							<div className='editMeetingParticipantsPopover-searchBarUI'>
								<div className='editMeetingParticipantsPopover-searchBarUI-searchBarIcon'>
									<Icon name='magnifyingGlass' />
								</div>
							</div>
			  		</div>
			  		<div id='editMeetingParticipantsPopover-scrollList' className='editMeetingParticipantsPopover-scrollList'>
			  			{/* SEARCH STATE WITHOUT RESULTS MEANS PROMPT TO ADD NEW CONTACT */}
			  			{hasSearchValue && !hasSearchResults && 
			  				<React.Fragment>
				  				<div className='popoverMenu-item popoverMenu-item--newContact'>
				  					{/*<div className='popoverMenu-item--newContact-iconOuterContainer'>
				  						<div className='popoverMenu-item--newContact-iconInnerContainer'>
				  							<Icon name='userAdd' />
				  						</div>
				  					</div>
				  					*/}
				  					<div className='popoverMenu-item--newContact-labelContainer' onClick={()=>{this.setState({showNewContactScreen:true})}}>
				  						<div className='popoverMenu-item--newContact-labelContainer-primary'>
				  							{searchValue}
				  						</div>
				  						<div className='popoverMenu-item--newContact-labelContainer-secondary'>
				  							Add new contact
				  						</div>
				  					</div>
				  					<div className='popoverMenu-item--newContact-rightContainer'>
				  						<Icon name='chevronRight' />
				  					</div>
				  				</div>
				  				<div className='editMeetingParticipantsPopover-scrollList-vSpacer'/>
				  				<div className='editMeetingParticipantsPopover-newContactInfo'>
				  					<div className='editMeetingParticipantsPopover-newContactInfo-emoji'>
				  						📇
				  					</div>
				  					<div className='editMeetingParticipantsPopover-newContactInfo-description-para'>
				  						Contacts are customers, users, or partners.
				  					</div>
				  					<div className='editMeetingParticipantsPopover-newContactInfo-description-para'>
				  						You can associate highlights, meetings, and other data to contact records.
				  					</div>
				  				</div>
			  				</React.Fragment>
			  			}

			  			{/* SEARCH STATE WITH RESULTS – I think just show not-added people */}
			  			{hasSearchValue && hasSearchResults && 
			  				<React.Fragment>
				  				{suggestions.map((suggestion,i) => {
				  					let isContact=suggestion.contact_id?true:false
				  					if(isContact){
				  						const isChecked = contactParticipants.some(participant => participant === suggestion.contact_id);
								 			return (
										    <EditMeetingParticipantMemberRow
										      key={`suggestion_${suggestion.contact_id}`}
										      checked={isChecked}
										      isContact
										      contact={suggestion}
										      updateMeetingParticipants={this.updateMeetingParticipants}
										      participants={participants}
										      contactParticipants={contactParticipants}
										      checked={isChecked}
										      resetSearchInput={this.resetSearchInput}
										      selected={activeIndex === i}
										    />
									  	)
				  						}else{
				  							const isChecked = participants.some(participant => participant === suggestion.user_id);
										 		return (
											    <EditMeetingParticipantMemberRow
											      key={`suggestion_${suggestion.user_id}`}
											      user={suggestion}
											      checked={isChecked}
											      updateMeetingParticipants={this.updateMeetingParticipants}
											      participants={participants}
											      contactParticipants={contactParticipants}
											      checked={isChecked}
											      resetSearchInput={this.resetSearchInput}
											      selected={activeIndex === i}
											    />
										  	)
						  					}
								  	})} 
							  </React.Fragment>	
			  			}
			  			{/* DEFAULT STATE */}
			  			{!hasSearchValue && 
				  			<React.Fragment>
						  		<div className='editMeetingParticipantsPopover-participantList'>
								  	{sortedMembers.map((orgMember) => {
										  const isChecked = participants.some(participant => participant === orgMember.user_id);
									 		if(isChecked){
									 			return (
										    <EditMeetingParticipantMemberRow
										      key={orgMember.user_id}
										      user={orgMember}
										      updateMeetingParticipants={this.updateMeetingParticipants}
										      participants={participants}
										      contactParticipants={contactParticipants}
										      checked={isChecked}
										    />
									  	)}
									  	else return null				 	
										})}	
										{sortedContacts.map((contact) => {
								  	const isChecked = contactParticipants.some(participant => participant === contact.contact_id);
									  if(isChecked){
								 		return (
									    <EditMeetingParticipantMemberRow
									      key={contact.contact_id}
									      isContact
									      contact={contact}
									      updateMeetingParticipants={this.updateMeetingParticipants}
									      participants={participants}
									      contactParticipants={contactParticipants}
									      checked={isChecked}
									    />
								  	)}
								  	else return null 
								  })}
										{participants.length== 0 && contactParticipants.length==0 &&
									  	<div className='editMeetingParticipantsPopover-participantList-emptyLabel editMeetingParticipantsPopover-participantList-emptyLabel--invited'> 
									  		No participants yet
									  	</div>
								  	}
							  	</div>
							  	{!noUncheckedOrgMembers &&
										<div className='editMeetingParticipantsPopover-addList'>
											<div className='editMeetingParticipantsPopover-addList-border'/>
											<div className='editMeetingParticipantsPopover-addList-header'>
												{/* {orgName} */} Add participants
											</div>
												{sortedMembers.map((orgMember) => {
											  	const isChecked = participants.some(participant => participant === orgMember.user_id);
												  if(!isChecked){
											 		return (
												    <EditMeetingParticipantMemberRow
												      key={orgMember.user_id}
												      user={orgMember}
												      updateMeetingParticipants={this.updateMeetingParticipants}
												      participants={participants}
												      contactParticipants={contactParticipants}
												      checked={isChecked}
												    />
											  	)}
											  	else return null 
											  })} 	
										</div>
									}
									
								</React.Fragment>
							}
						</div>
					</React.Fragment>
				}
			</div>
	  )
	}
}

export default EditParticipantListPopoverContents
