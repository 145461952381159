import React from 'react'
import Icon from '../../misc/Icon'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import CommentThreadMarkerPopoverMessage from './CommentThreadMarkerPopoverMessage'
import {getUserForId} from '../../../utils/getUserForId'
import {getCurrentUserId} from '../../../utils/getCurrentUser'
import {isCurrentUser} from '../../../utils/isCurrentUser'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
// const threadObj={
// 	threadParent:message,
// 	threadChildren:[message],
// 	indentLevel:number,
// 	selectionStart:number,
// 	markerStartY:number,
// 	markerEndY:number
// }

class CommentThreadMarkerPopover extends React.Component{  	

	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.state={
      forceMount: false,      
    }
  }

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

	render(){	
		const {showPopover, openPopover, closePopover, assignee, workflowType, workflowStateName, workflowColorHex, workflowTypeStateIndex, workflowTypeStatesCount, title, identifier, priority, description} = this.props
		const {forceMount} = this.state

		const {thread, reactions}=this.props
		const {threadParent,threadChildren}=thread

		const translateX = -8		
	  
  	const threadParentUser = getUserForId(threadParent['created_by'])

  	let allMessages=[threadParent,...threadChildren]
  	const sorted=sortBy(allMessages,'created_at')
 
  	let previewMessages=sorted.slice(Math.max(0,sorted.length-3),sorted.length)
  	const previousMessageCount=(sorted.length-previewMessages.length)

		return (
			<React.Fragment>
				<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'commentThreadMarkerPopoverContainer ' + (showPopover ? ' commentThreadMarkerPopoverContainer--visible ' : ' commentThreadMarkerPopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							onInteractOutside={closePopover}							
							side="right"						
							align="start"							
							sideOffset={this.props.indentLevel > 0 ? 12 : -3}
							alignOffset={-40}
							collisionPadding={10}
							onOpenAutoFocus={(e)=>{e.preventDefault()}}
							forceMount={showPopover ? true : forceMount}
							//asChild
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateX(${translateX}px)` }}
								enter={{ opacity: 1, transform: 'translateX(0px)'  }}
								leave={{ opacity: 0, transform: `translateX(${translateX}px)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 35}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="commentThreadMarkerPopover popover"> 	
										

										{previousMessageCount>0 &&
											<div className='commentThreadMarkerPopover-moreMessagesHeader'>
												<div className='commentThreadMarkerPopover-moreMessagesHeader-line'/>
												<div className='commentThreadMarkerPopover-moreMessagesHeader-label'>
													{sorted.length} messages in thread
												</div>
												<div className='commentThreadMarkerPopover-moreMessagesHeader-line'/>											
											</div>
										}
										
										{/*

							 			<CommentThreadMarkerPopoverMessage 								 			
								 			message={threadParent}
								 			userDisplayName={threadParentUser.display_name}
								 			avatarUrl={threadParentUser.avatar}
								 			//isCurrentUser={messageIsCurrentUser}
											messageReactions={filter(reactions,{message_id:threadParent['message_id']})}
								 		/>
										*/}
										 {previewMessages.map((message,i)=>{
										 	const user=getUserForId(message['created_by'])
											let messageIsCurrentUser=isCurrentUser(message['created_by'])
											const messageReactions=filter(reactions,{message_id:message['message_id']})
										 	return(										 		
										 		<CommentThreadMarkerPopoverMessage 
										 			key={`thread_preview_${message.message_id}`}
										 			message={message}
										 			userDisplayName={user.display_name}
										 			avatarUrl={user.avatar}
										 			isCurrentUser={messageIsCurrentUser}
										 			userId={getCurrentUserId()}
													messageReactions={messageReactions}
										 		/>
										 		)
										 })}

							  	</animated.div>								
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			</React.Fragment>
		)
	}
}

export default CommentThreadMarkerPopover