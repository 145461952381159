import React from 'react'
import Icon from '../misc/Icon'
import { CircularProgressbar } from 'react-circular-progressbar';
import { Spring, animated, config } from 'react-spring'

const OPEN_EXPAND_DELAY=200

class ActiveRecordingUploadPanelContents extends React.Component{  

  constructor(props){
    super(props)
    
    this.handleMouseEnter=this.handleMouseEnter.bind(this) 
    this.handleMouseLeave=this.handleMouseLeave.bind(this)

    this.state={
      isExpanded: false,
     // showActiveRecordingUploadPanel:false
    }

    this.timeout=null
  }

  handleMouseEnter(){
    this.setState({isExpanded:true})
    // this.timeout=setTimeout(function() {
    //   this.setState({isExpanded:true})
    // }.bind(this), OPEN_EXPAND_DELAY)
  }

  handleMouseLeave(){
    this.setState({isExpanded:false})
    // if(this.timeout){
    //   clearTimeout(this.timeout)
    // }
    // if(this.state.isExpanded){
    //   this.setState({isExpanded:false})
    // }
  }


  render(){ 
    const {activeUploadsCount,combinedProgress} = this.props
    const {isExpanded} = this.state
    //const activeUploadsCount = 2
    //const combinedProgress = 0.4

    let label = 'recording'
    let filesLeftLabel = '1 file'

    if(activeUploadsCount > 1){
      label = 'recordings'
      filesLeftLabel = `${activeUploadsCount} files left`
    }

    return (      

      <React.Fragment>

        <Spring
          width={isExpanded ? 235 : 60}         
          delay={isExpanded ? 0 : 85}
          config={{
            tension: 600,
            friction: 38,
            
          }}
        >
          {styles => 
            <animated.div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} style={styles} className='activeRecordingUploadPanel-bg' />
          }
        </Spring>           


        <div className='activeRecordingUploadPanel-contents'>

          <div className='activeRecordingUploadPanel-progressBarContainer'>
            <div className='activeRecordingUploadPanel-progressBar'>
              <CircularProgressbar 
                value={combinedProgress * 100}
                strokeWidth={8}
              />
              <div className='activeRecordingUploadPanel-progressBar-iconContainer'>
                <Icon name='upArrowSemibold' />
              </div>
            </div>

             <Spring
              opacity={isExpanded ? 1 : 0}
              transform={isExpanded ? 'translateX(0)' : 'translateX(-8px)'}
              delay={isExpanded ? 85 : 0}
              config={{
                tension: 600,
                friction: 38,                
              }}
            >
              {styles => 
                <animated.div style={styles} className='activeRecordingUploadPanel-rightContainerContainer'>                  
                  <div className='activeRecordingUploadPanel-title'>
                    Uploading {label}
                  </div>
                  <div className='activeRecordingUploadPanel-subtitle'>
                    <div className='activeRecordingUploadPanel-subtitle-percentage'>
                      {Math.round(combinedProgress * 100)}%
                    </div>
                    {filesLeftLabel && 
                      <div className='activeRecordingUploadPanel-subtitle-filesLeft'>
                        · {filesLeftLabel}
                      </div>                
                    }
                  </div>                  
                </animated.div>
              }
            </Spring>  

            
          </div>      

        
        </div>
        
      </React.Fragment>


    )
  }
}

export default ActiveRecordingUploadPanelContents


{/*

  <div className='activeRecordingUploadPanel-rightContainer'>
          <div className='activeRecordingUploadPanel-title'>
            Uploading {label}
          </div>
          <div className='activeRecordingUploadPanel-progressBarContainer'>
            <div className='activeRecordingUploadPanel-progressBar'>
              <div style={{width: `${combinedProgress * 100}%`}} className='activeRecordingUploadPanel-progressBar-bar' />
              <div className='activeRecordingUploadPanel-progressBar-track' />
            </div>
          </div>
        </div>

*/}