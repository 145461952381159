import React from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import * as Dialog from '@radix-ui/react-dialog';
import { Transition, animated, config } from 'react-spring'
import TemplateModal from './TemplateModal'
import {showDocTemplatesModal} from '../../utils/templates/showDocTemplatesModal'
import {hideDocTemplatesModal} from '../../utils/templates/hideDocTemplatesModal'
import {archiveTemplate,fetchArchivedTemplates,restoreArchivedTemplate,fetchTemplates} from '../../actions/docTemplates'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import includes from 'lodash/includes'
import find from 'lodash/find'

// function scrollIntoViewIfOffScreen(templateDiv, scrollDiv) {
//     const rect = templateDiv.getBoundingClientRect();
//     const containerRect = scrollDiv.getBoundingClientRect();
//     const topXPos = rect.top; // relative to the viewport
//     const bottomXPos = rect.bottom; // relative to the viewport

//     const divBelow = bottomXPos > containerRect.bottom; // Div is below container
//     const divAbove = topXPos < containerRect.top; // Div is above container

//     const topPageHeaderBuffer = 0
//     //34-26; // Your buffer

//     const scrollAmountAbove = containerRect.top - topXPos +topPageHeaderBuffer ; // Amount to scroll if div is above container
//     const scrollAmountBelow = bottomXPos - containerRect.bottom -topPageHeaderBuffer; // Amount to scroll if div is below container

//     // console.log(`div below--- ${divBelow}`)
//     console.log(`scroll amount above ${scrollAmountAbove}`)
//     console.log(`scroll amount below --- ${scrollAmountBelow}`)
//     console.log(`divBelow---${divBelow}`)
//     console.log(`divAbove--- ${divAbove}`)
//     //scrollDiv.scrollBy(0, 100)

//     if (divAbove) {
//         scrollDiv.scrollBy(0, scrollAmountAbove); // Scroll up if div is above container
//     } else if (divBelow) {
//         scrollDiv.scrollBy(0, scrollAmountBelow); // Scroll down if div is below container
//     }
// }




class TemplateModalContainer extends React.Component{  	
	
	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.handleArchiveTemplate=this.handleArchiveTemplate.bind(this)
    this.setActiveTemplate=this.setActiveTemplate.bind(this)
    this.handleHideDocTemplatesModal=this.handleHideDocTemplatesModal.bind(this)
    this.handleRestoreTemplate=this.handleRestoreTemplate.bind(this)
    this.state={
      forceMount: false,   
			activeTemplate:null,  
    }
  }



  onRest() {	
		if(!this.props.modalVisible){
			this.setState({forceMount: false})
		}else{
			this.setState({forceMount: true})
		}
	}

	handleHideDocTemplatesModal(){
		this.setState({activeTemplate:this.props.docTemplates[0]})
		hideDocTemplatesModal()
	}

	setActiveTemplate(e,template){
		if(e){
			e.preventDefault()
			e.stopPropagation()
		}
		this.setState({activeTemplate:template})
		//reset scroll
		const scrollDiv=document.getElementById('template_modal_scrollable_div')        
   	if(scrollDiv){         
   		scrollDiv.scrollTop=0
   	}
   	// const templateDiv=document.getElementById(template.template_id)    
   	// const scrollableListDiv=document.getElementById('modal_scrollable_list')    
   	// if(templateDiv && scrollableListDiv){
   	// 	scrollIntoViewIfOffScreen(templateDiv,scrollableListDiv)
   	// }
		
	}

	handleArchiveTemplate(templateId){
		this.props.archiveTemplate(templateId)
		this.setState({activeTemplate:this.props.docTemplates[0]})
	}

	handleRestoreTemplate(templateId){
		this.props.restoreArchivedTemplate(templateId)
		let activeTemplate={...this.state.activeTemplate}
		activeTemplate.archived_at=null 
		this.setState({activeTemplate:activeTemplate})
	}

	render(){	
		const showModal=this.props.modalVisible
		const {forceMount} = this.state		
		let modalTransformStart = 'scale(0.95)'		
		let modalTransformEnd = 'scale(1)'		
	  return (
	  	<React.Fragment>
	  		<Dialog.Root>
					<Dialog.Trigger asChild>
						{this.props.children}
					</Dialog.Trigger>
					<Dialog.Portal
						forceMount={showModal ? true : forceMount}						
					>											
						<Dialog.Content
				    	className={'templateModalContainer ' + (showModal ? ' templateModalContainer--visible ' : ' templateModalContainer--hidden ')} 
				    	onEscapeKeyDown={this.handleHideDocTemplatesModal}
					    onPointerDownOutside={this.handleHideDocTemplatesModal}
					   // onInteractOutside={this.handleHideDocTemplatesModal}
					    forceMount={showModal ? true : forceMount}					    
				    >
					    <Transition
				        items={showModal}
				        from={{ opacity: 0, transform: `${modalTransformStart}` }}
				        enter={{ opacity: 1, transform: `${modalTransformEnd}`  }}
				        leave={{ opacity: 0, transform: `${modalTransformStart}`  }}
				        reverse={showModal}
				        config={{tension: 700, friction: 36}}				        
				        onRest={this.onRest}
				       >
				        {(styles, item) =>
				          item && 
				          <animated.div style={styles} className="templateModal"> 					           	
				           	<TemplateModal 
				           		closeModal={this.handleHideDocTemplatesModal}
				           		docTemplates={this.props.docTemplates}
				           		orgTemplates={this.props.orgTemplates}
				           		publicTemplates={this.props.publicTemplates}
				           		archivedDocTemplates={this.props.archivedDocTemplates}
				           		archiveTemplate={this.handleArchiveTemplate}
				           		setActiveTemplate={this.setActiveTemplate}
				           		activeTemplate={this.state.activeTemplate}
				           		fetchArchivedTemplates={this.props.fetchArchivedTemplates}
				           		fetchTemplates={this.props.fetchTemplates}
				           		restoreTemplate={this.handleRestoreTemplate}
				           		newTemplateId={this.props.newTemplateId}
				           		templateDocId={this.props.templateDocId}
				           		docId={this.props.docId}
				           		currentMeeting={this.props.currentMeeting}
				           		currentDocIsMeeting={this.props.currentDocIsMeeting}
				           		addLinkToLinear={this.props.addLinkToLinear}
				           	/>
				          </animated.div>
				        }
							</Transition>	
						
					</Dialog.Content>				    
				</Dialog.Portal>
			</Dialog.Root>		
			<div onClick={this.handleHideDocTemplatesModal} className={'templateModalModalUnderlay ' + (showModal ? ' templateModalModalUnderlay--show ': ' templateModalModalUnderlay--hide ')} />					
		</React.Fragment>
	  )
	}
}


function mapStateToProps(state,ownProps){
	let modalVisible = state.docTemplatesModal && state.docTemplatesModal.visible
	let newTemplateId = state.docTemplatesModal.newTemplateId 
	let templateDocId = state.docTemplatesModal.docId //for applyingtemplate to a doc rather than creating a new doc
	let addLinkToLinear = state.docTemplatesModal.addLinkToLinear
	const docTemplates = state.docTemplates
	const orgTemplates=filter(docTemplates,{public:false})
	//need to remove quotes because they effect the sort order
	const sortedOrgTemplates = sortBy(orgTemplates, function(template){
		let templateName=template.name || "untitled"
		let name = templateName.toString();
		name = name.replace(/['"]+/g, ''); // This will remove both single and double quotes
		return name.toLowerCase();
	})

	const publicTemplates=filter(docTemplates,{public:true})
	//put meeting templates first then others
	const publicMeetingTemplateNames=['Design Review','Discovery Call','Team Sync']

	const sortedPublicTemplates = sortBy(publicTemplates, function(template){
		let templateName=template.name || "untitled"
		let name = templateName.toString()
		name = name.replace(/['"]+/g, ''); // This will remove both single and double quotes
		return name.toLowerCase();
	})

	let sortedMeetingTemplates=[]
	let sortedOtherTemplates=[]

	sortedPublicTemplates.forEach((template)=>{
		if(includes(publicMeetingTemplateNames,template.name)){
			sortedMeetingTemplates.push(template)
		}else{
			sortedOtherTemplates.push(template)
		}

	})

	//let templates=[...sortedOrgTemplates,...sortedPublicTemplates]
	let templates=[...sortedOrgTemplates,...sortedMeetingTemplates,...sortedOtherTemplates]
	
	const archivedDocTemplates=sortBy(state.archivedDocTemplates, function(template){
		// let templateName=template.name || "untitled"
		// let name = templateName.toString();
		// name = name.replace(/['"]+/g, ''); // This will remove both single and double quotes
		return template.archived_at;
	}).reverse()

	const pathname=ownProps.location.pathname
	let docId
	let currentDocIsMeeting
	let currentMeeting
	const signatureComponentsArray=pathname.split(/-/)
	if(signatureComponentsArray.length>1){
		docId=signatureComponentsArray[signatureComponentsArray.length-1]
		const doc = find(state.docs, {'doc_id':docId})
		if(doc && doc.meeting){
			currentDocIsMeeting=true
			currentMeeting=find(state.meetings,{meeting_id:doc.meeting})
		}
	}

	return {
		docTemplates:templates,
		currentDocIsMeeting:currentDocIsMeeting,
		currentMeeting:currentMeeting,
		docId:docId,
		orgTemplates:sortedOrgTemplates,
		publicTemplates:sortedPublicTemplates,
		archivedDocTemplates:archivedDocTemplates,
		modalVisible:modalVisible,
		newTemplateId:newTemplateId,
		templateDocId:templateDocId,
		addLinkToLinear:addLinkToLinear
	}
}


export default withRouter(connect(
	mapStateToProps,
	{archiveTemplate,
		fetchArchivedTemplates,
		restoreArchivedTemplate,
		fetchTemplates
	})(TemplateModalContainer))


//export default TemplateModalContainer


