import React from 'react'
import Icon from './Icon'
import {getColorForLinearHex} from '../../utils/getColorForLinearHex'

class LinearIssueWorkflowStateIcon extends React.PureComponent{    
  render(){ 
    const {workflowColorHex, workflowType, workflowTypeStatesCount, workflowTypeStateIndex} = this.props

    //
    // Can be named color or custom color
    // if named color (e.g. 'dark red') then we switch to our palette
    // if custom color, then we apply that color
    let customColor
    const namedColor = getColorForLinearHex(workflowColorHex)
    if(!namedColor){
      customColor=workflowColorHex
    }


    
    // PROGRESS ICON    
    let progressIconName
    
    //
    // workflowTypeStatesCount === 1
    if(workflowTypeStatesCount === 1 && workflowTypeStateIndex === 0){
      progressIconName = 'OneHalf'
    }


    //
    // workflowTypeStatesCount === 2
    if(workflowTypeStatesCount === 2){      
      if(workflowTypeStateIndex === 0){
        progressIconName = 'OneHalf'
      }
      if(workflowTypeStateIndex === 1){
        progressIconName = 'ThreeQuarters'
      }
    }

    //
    // workflowTypeStatesCount === 3
    if(workflowTypeStatesCount === 3){      
      if(workflowTypeStateIndex === 0){
        progressIconName = 'OneQuarter'
      }
      if(workflowTypeStateIndex === 1){
        progressIconName = 'OneHalf'
      }
      if(workflowTypeStateIndex === 2){
        progressIconName = 'ThreeQuarters'
      }
    }

    //
    // workflowTypeStatesCount === 4
    if(workflowTypeStatesCount === 4){      
      if(workflowTypeStateIndex === 0){
        progressIconName = 'OneFifth'
      }
      if(workflowTypeStateIndex === 1){
        progressIconName = 'TwoFifths'
      }
      if(workflowTypeStateIndex === 2){
        progressIconName = 'ThreeFifths'
      }
      if(workflowTypeStateIndex === 3){
        progressIconName = 'FourFifths'
      }
    }

    //
    // workflowTypeStatesCount === 5
    if(workflowTypeStatesCount === 5){      
      if(workflowTypeStateIndex === 0){
        progressIconName = 'OneSixth'
      }
      if(workflowTypeStateIndex === 1){
        progressIconName = 'OneThird' 
      }
      if(workflowTypeStateIndex === 2){
        progressIconName = 'OneHalf'
      }
      if(workflowTypeStateIndex === 3){
        progressIconName = 'TwoThirds'
      }
      if(workflowTypeStateIndex === 4){
        progressIconName = 'FiveSixths'
      }
    }




    return (
      <div className={'linearIssueWorkflowStateIconContainer ' + ((namedColor || customColor) ? ' ' : ' linearIssueWorkflowStateIconContainer--defaultColor  ')+ (namedColor ? `linearIssueWorkflowStateIconContainer--workflowColorName--${namedColor}` : '')}>
      
        {workflowType === 'backlog' &&
          <div style={{'borderColor': `${customColor}`}} className='linearIssueWorkflowStateIcon linearIssueWorkflowStateIcon--backlog' />
        }

        {workflowType === 'unstarted' &&
          <div style={{'borderColor': `${customColor}`}} className='linearIssueWorkflowStateIcon linearIssueWorkflowStateIcon--unstarted' />
        }

        {workflowType === 'started' &&
          <div style={{'borderColor': `${customColor}`}} className='linearIssueWorkflowStateIcon linearIssueWorkflowStateIcon--started'>            
            <Icon fill={customColor} name={`linearStartedStateIcon${progressIconName}`} />            
          </div>
        }

        {workflowType === 'completed' &&
          <div style={{'background': `${customColor}`}} className='linearIssueWorkflowStateIcon linearIssueWorkflowStateIcon--completed'>
            <Icon name='toDoItemCheckmark' />
            <div className='linearIssueWorkflowStateIcon--completed--innerBorder' />
          </div>
        }

        {workflowType === 'triage' &&
          <div className='linearIssueWorkflowStateIcon linearIssueWorkflowStateIcon--triage'>
            <Icon name='linearTriageStateIcon' /> 
            <div className='linearIssueWorkflowStateIcon--canceled--innerBorder' />
          </div>
        }

        {workflowType === 'canceled' &&
          <div style={{'background': `${customColor}`}} className='linearIssueWorkflowStateIcon linearIssueWorkflowStateIcon--canceled'>
            <Icon name='linearCancelledStateIcon' /> 
            <div className='linearIssueWorkflowStateIcon--canceled--innerBorder' />
          </div>
        }
      
      </div>
    )
  }
}

export default LinearIssueWorkflowStateIcon
