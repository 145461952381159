import findIndex from 'lodash/findIndex'
import moment from 'moment'

//Check update_at timestamp and only update if is more recent than timetamp in redux

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_PROJECTS_SUCCESS':
		return action.response

	case 'CREATE_PROJECT_SUCCESS':
	case 'UPDATE_PROJECT_SUCCESS':
		const project_id=action.project['project_id']
	
		let index=findIndex(state,(project => {
			return project.project_id === project_id
		}))
		if(index === -1){
			index=state.length
		}
		const projectInState=state[index]
		if(projectInState){
		//	console.log(`project in state updated at------- ${projectInState.updated_at}`)
			if(!projectInState.updated_at || moment(action.project.updated_at).isAfter(projectInState.updated_at)){
			//	console.log('!!!!!!!!!!!!!! UPDATE WITH PROJECT RESPONSE')
				return [
					...state.slice(0,index),
					action.project,
				...state.slice(index + 1)
			]
			}else{
			//	console.log(`IN REDUCER PROJECT IS OLDER THAN ONE IN STATE SO IGNORE`)
			
				return [
					...state.slice(0,index),
					action.project,
				...state.slice(index + 1)
			]
			}
		}else{
				return [
					...state.slice(0,index),
					action.project,
				...state.slice(index + 1)
			]
		} 


	case 'UPDATE_PROJECT_REQUEST':

		const updatedProjectId=action.updatedProject.project_id
		let updatedIndex=findIndex(state,(project => {
			return project.project_id === updatedProjectId
		}))
		if(updatedIndex > -1){
			const inState=state[updatedIndex]
			if(inState){
				if(!inState.updated_at || moment(action.updatedProject.updated_at).isAfter(moment(inState.updated_at))){
					return [
						...state.slice(0,updatedIndex),
						action.updatedProject,
						...state.slice(updatedIndex + 1)
					]
				}else{
				//	console.log(`IN REDUCER ______ UPDATE ________PROJECT IS OLDER THAN ONE IN STATE SO IGNORE`)
				//	console.log(`instateupdated ${inState.updated_at}`)
				//	console.log(`requst updated at ${action.updatedProject.updated_at}`)
					return state
				}
			}else return state
		}else return state
	

		

		case 'DELETE_PROJECT':
			const projectId=action.projectId
			let deletedIndex=findIndex(state,(project => {
				return project.project_id == projectId
			}))
			if(deletedIndex === -1){
				return state
			}else{
				return [
					...state.slice(0,deletedIndex),
					...state.slice(deletedIndex + 1)
				]
			}

	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}
 