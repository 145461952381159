import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_ARCHIVED_TEMPLATES_SUCCESS':
		return action.response

	
		

	// case 'ARCHIVE_TEMPLATE_REQUEST':
	// case 'ARCHIVE_TEMPLATE_SUCCESS':
	// 	const templateId = action.templateId
	// 	let index = findIndex(state,(template => {
	// 		return template['template_id'] === templateId
	// 	}))
	// 	if(index>-1){
	// 		return [
	// 		...state.slice(0,index),
	// 		...state.slice(index + 1)
	// 		]
	// 	}else return state

	case 'SIGN_OUT':
			return []
	
	default:
		return state
	}
}

