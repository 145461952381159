



function getInitialState(){
	const issuePanels = JSON.parse(localStorage.getItem('issuePanels'))
	if(issuePanels){
		return issuePanels
	}else{
		return {}
	}

}



export default function (state = getInitialState(), action) {
	switch (action.type) {

	case 'UPDATE_ISSUE_PANEL_VISIBILITY':
		const {docId,isVisible,projectId,userId}=action	
		let newState=Object.assign({},state)
		newState[docId]={
			isVisible:isVisible,
			project:projectId,
			userId:userId
		}
		localStorage.setItem('issuePanels',JSON.stringify(newState))
		return newState
	default:
		return state
	}
}

