import {fetch,post,deleteRequest} from '../api'
import store from '../store'
import {fetchRecentAcitivities} from './recentActivities'
import {randomID} from '../utils/randomID'

export function handleNewReactionPusher(reaction) {
	return (dispatch) => {
		dispatch({ type: 'NEW_REACTION_PUSHER',reaction:reaction })	
	}
}

export function handleReactionDeletedPusher(reactionId) {
	return (dispatch) => {
		dispatch({ type: 'REACTION_DELETED_PUSHER',reactionId:reactionId })	
	}
}

export function deleteReaction(reactionId,docId) {
	return (dispatch) => {
		dispatch({ type: 'DELETE_REACTION_REQUEST',reactionId})
		return deleteRequest(`/docs/${docId}/reaction/${reactionId}`)
			.then((response) => {
				dispatch(fetchReactions(docId))
				return 
			})
			.catch((error) => {
				return error
			})
		}
	}

export function fetchReactions(docId) {
	return (dispatch) => fetch(`/docs/${docId}/reaction`)
		.then((response) => {
			dispatch({ type: 'FETCH_REACTIONS_SUCCESS', response })
			return response
		})
		.catch((error) => {
			return error
		})
}

export function addReactionToMessage(reactionType,messageId,docId) {
	const id=randomID()
	const requestBody={
		message_id:messageId,
		reaction_type:reactionType,
		reaction_id:id
	}
	const state = store.getState()
	const currentUser=state.user
	let userId
	if(currentUser){
		userId=currentUser['user_id']
	}
	const tempReaction={ //to update state while wait for api request
		...requestBody,
		parent_doc:docId,
		reaction_id:id,
		created_by:userId
	}
	return (dispatch) => {
		dispatch({ type: 'ADD_REACTION_REQUEST',tempReaction })
		return post(`/docs/${docId}/reaction`,requestBody)
			.then((response) => {
				//dispatch(fetchReactions(docId))
				dispatch(fetchRecentAcitivities())
			return 
		})
		.catch((error) => {
			return error
		})
	}
}

	