import React from 'react'
import Icon from '../../../misc/Icon'
import DocEditorMeetingHighlightListHighlight from './DocEditorMeetingHighlightListHighlight'
import {getMessageForHighlight} from '../../../../utils/meetings/getMessageForHighlight'
import filter from 'lodash/filter'

function messagesForHighlight(highlightId,messages){

  const parentMessage = getMessageForHighlight(highlightId)
  console.log(parentMessage)
  const childMessages=filter(messages,{thread_parent:parentMessage.message_id})
  console.log('child messages')
  console.log(childMessages)
  return childMessages

  // let highlightMessages=[]
  // messages.forEach((message)=>{
  //   if(message.recording && message.recording.highlight_id==highlightId){
  //     highlightMessages.push(message)
  //   }
  // })
  // console.log('highlight messages--------')
  // console.log(highlightMessages)
  // return highlightMessages
}



class DocEditorMeetingHighlightList extends React.PureComponent{  

  render(){     

    const {highlights,docMessages} = this.props
    return (        
      
      <div className='docEditor-meetingContent-highlightsList'>
        <div className='docEditor-meetingContent-highlightsList-list'>     
          {highlights.map((highlight)=>{
            const highlightMessages=messagesForHighlight(highlight.highlight_id,docMessages)
            return(
              <DocEditorMeetingHighlightListHighlight 
                creator={highlight.created_by} // userId
                highlight={highlight}
                description= {highlight.title}
                openThread={this.props.openThread}
                muxPlaybackID = {highlight.playback_id} // highlight example
                highlightMessages={highlightMessages}
              />
              )
          })}

          {/*
          <DocEditorMeetingHighlightListHighlight 
            creator="219" // userId
            description= "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce at pellentesque nulla. Nulla facilisi. Integer aliquam dapibus laoreet. Ut arcu ipsum, lacinia eget urna a, viverra posuere lacus."
            muxPlaybackID = 'NAcJRUMmIdgm5T2x8FF2NqsN00gL2zWdLJI008Q1ugyj4' // highlight example
          />
          <DocEditorMeetingHighlightListHighlight 
            creator="219" // userId
            description= "Nullam egestas, nibh in sollicitudin finibus, turpis felis ultricies diam."
            muxPlaybackID = 'NAcJRUMmIdgm5T2x8FF2NqsN00gL2zWdLJI008Q1ugyj4' // highlight example
          />
          <DocEditorMeetingHighlightListHighlight 
            creator="219" // userId
            description= "Vivamus ut augue elementum, suscipit ipsum eget, porttitor lorem. Sed pulvinar porta libero eu malesuada. Mauris sed accumsan nisl. Phasellus et sollicitudin mauris, at egestas tellus."
            muxPlaybackID = 'NAcJRUMmIdgm5T2x8FF2NqsN00gL2zWdLJI008Q1ugyj4' // highlight example
          />
          <DocEditorMeetingHighlightListHighlight 
            creator="219" // userId
            description= "Nam aliquet, ligula eget efficitur ullamcorper, quam libero ullamcorper est, at lobortis erat quam sit amet nibh. Curabitur sem lorem, ultricies vel venenatis at, venenatis sed nunc."
            muxPlaybackID = 'NAcJRUMmIdgm5T2x8FF2NqsN00gL2zWdLJI008Q1ugyj4' // highlight example
          />
          <DocEditorMeetingHighlightListHighlight 
            creator="219" // userId
            description= "Nam tristique vel turpis et mattis. Pellentesque interdum, velit luctus bibendum pulvinar, lorem leo egestas massa, ut fermentum nulla velit nec ipsum."
            muxPlaybackID = 'NAcJRUMmIdgm5T2x8FF2NqsN00gL2zWdLJI008Q1ugyj4' // highlight example
          />
          <DocEditorMeetingHighlightListHighlight 
            creator="219" // userId
            description= "Praesent dapibus id risus id venenatis. Ut vel tristique lectus. Donec ut imperdiet ex, ut varius sem."
            muxPlaybackID = 'NAcJRUMmIdgm5T2x8FF2NqsN00gL2zWdLJI008Q1ugyj4' // highlight example
          />
          */}
        </div>              
        {/*}
        <button className='docEditor-meetingContent-highlightGrid-seeMoreButton'>
          See 4 more
        </button>            
        */}
      </div>
      
    )
  }
}

export default DocEditorMeetingHighlightList