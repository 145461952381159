export default function (state = false, action) {
	switch (action.type) {
	 case 'SHOW_NOTION_EXPORT_MODAL':
			return true
	case 'HIDE_NOTION_EXPORT_MODAL':
			return false
		default:
			return state;
	}
}
