import moment from 'moment'

//
//const dateLabel = "Sunday, September 17"
export function formatDateLong(timestamp) {
	const nowDate =moment()
	const today = moment(nowDate).startOf('day')
	const week = moment(nowDate).startOf('isoWeek')
	const year = moment(nowDate).startOf('isoYear')
 
	let formatedTimestamp

	if (moment(timestamp).isSame(today, 'd')) {
			formatedTimestamp = `Today`
	}else{
		formatedTimestamp = moment(timestamp).format('dddd, MMMM Do')
	}


	return formatedTimestamp

}