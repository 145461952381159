import React from 'react'
import Icon from '../misc/Icon'

class RecordingPlaybackModalMediaPlaybackControlsHighlights extends React.PureComponent{  

  
	render(){ 
		const {highlights,duration}=this.props 

    // const highlights = [
    //   { progress: 0.04, duration: 0.008 },
    //   { progress: 0.2, duration: 0.003 },      
    //   { progress: 0.3, duration: 0.008 },
    //   { progress: 0.43, duration: 0.009 },
    //   { progress: 0.7, duration: 0.008 },
    //   { progress: 0.92, duration: 0.005 },
    //   { progress: 0.8, duration: 0.004 },
    // ];


		return (
			<div className='recordingPlaybackModal-media-playbackControls-highlights'>
        
        {highlights.map((highlight, index) =>{
          const progress=highlight.start_time/duration

          const highlightDuration=(highlight.end_time-highlight.start_time)/duration

          return(
            <button
              onClick={()=>{this.props.skipToHighlight(highlight)}}
              key={index}
              //onClick seeks to start of this segment
              style={{
              left: `${progress * 100}%`,
              width: `${highlightDuration * 100}%`,
              }}
              className='recordingPlaybackModal-media-playbackControls-highlights-highlightBtn'
            >
              <div className='recordingPlaybackModal-media-playbackControls-highlights-highlightBtn-inner' />
            </button>
            )
        }
        )}     

			</div>
		)
	}
}

export default RecordingPlaybackModalMediaPlaybackControlsHighlights
