import store from '../../store'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'



export function getOnboardingProjects() {	
	const state = store.getState()
	let projects
	const plannedProjects=filter(state.projects,{state:'planned'})
	// console.log('pkanned')
	// console.log(plannedProjects)
	const backlogProjects = filter(state.projects,{state:'backlog'})
	// console.log('backlo----')
	// console.log(backlogProjects)

	if(plannedProjects.length>3){ //only use planned
		projects=plannedProjects
	}else{
		projects=plannedProjects.concat(backlogProjects)
	}
	const sortedProjects=	sortBy(projects, function(project){
 		return project.name.toString().toLowerCase()
  })


	return sortedProjects.slice(0,4)
}

