import React from 'react'
import { withRouter} from 'react-router-dom'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import MessageReactionsPanel from './MessageReactionsPanel'
import MessageOpenThreadBtn from './MessageOpenThreadBtn'
import MessageHoverMenu from './MessageHoverMenu'
import {getMessageForId} from '../../../utils/getMessageForId'
import {getUserForId} from '../../../utils/getUserForId'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import find from 'lodash/find'



//To add more reaction types add to this array (should work both in UI and API)
const reactionDefinitions=[
	{type:"like",emoji:"👍"},
	{type:"fire",emoji:"🔥"},
	{type:"bullseye",emoji:"🎯"}
]


////Recording message types
/// when recording is uploaded and ready
// auto_created=true
// message.recording={type:'recording',recodingId:id,action:'created'}

class RecordingMessage extends React.PureComponent{  

	render(){	
		const {message,isCurrentUser,messageReactions,followOnMessage,messageIsHighlighted,threadParentMessage}=this.props
		const recording = message.recording
		const parentMessageId=message.parent_message
		const showReactionsPanel=messageReactions.length>0
		const {auto_created}=message
		let isHighlight = message.recording.type=='highlight'
		const isInThread=threadParentMessage?true:false
		return (	  	
			<div id={`message_${message['message_id']}`} className={'messageContainer ' + (auto_created ? ' messageContainer--autoGenerated ' : '')  + (messageIsHighlighted ? ' messageContainer--highlighted ' : '') + (followOnMessage ? ' messageContainer--followOnMessage ' : '')}>
				<div className='message' >
					<div className='message-leftContainer'>					
						<AvatarImage 
							avatarUrl={this.props.avatarUrl}
							className='message-avatarContainer'
						/>											
					</div>
					<div className='message-rightContainer'>
						<div className='message-header'>					
							<div className='message-header-name'>
								{this.props.userDisplayName}
							</div>						
							<div className='message-header-timestamp'>
								{this.props.timestampLabel}
							</div>						
						</div>
						<div className='message-content'>
							{parentMessageId && !message['is_thread'] &&
								<button className={'message-content-selection message-content-selection--message ' + (parentMessage['plain_text'] ? '' : ' message-content-selection--message--deleted ')} onClick={()=>{this.props.highlightMessage(parentMessageId)}}>
									<div className='message-content-selection-bar'/>
									<div className='message-content-selection-content'>
										{parentMessage['plain_text'] || 'Message deleted'}								
									</div>
								</button>
							}					
							{isHighlight &&
								<div onClick={()=>{this.props.openThread()}} >video: playback id {message.recording.playback_id}</div>
								}
							{!isHighlight &&
								<div onClick={this.props.openRecordingModal}>video: playback id {message.recording.playback_id}</div>
							}
							{!isHighlight &&
								<div>
									Added A recording 
								</div>
							}
							{isHighlight &&
								<div> added a HIGHLIGHT! {isInThread?'EXPANDED':''} </div>
							}
						</div>        
					</div>
					</div>  	
					{showReactionsPanel &&
						<MessageReactionsPanel							
							hideReactions={this.hideReactions}
							messageId={message.id}
							messageReactions={messageReactions}
							userId={this.props.userId}
							docId={this.props.docId}
							addReactionToMessage={this.props.addReactionToMessage}
							deleteReaction={this.props.deleteReaction}
							messageId={message['message_id']}
							reactionDefinitions={reactionDefinitions}						
						/>
					}				
					{((this.props.isThreadParent && this.props.threadChildMessages)||message.in_doc_thread || isHighlight)&& !this.props.threadParentMessage &&
						<MessageOpenThreadBtn
							isInDocThread={message.in_doc_thread}
							openThread={this.props.openThread}
							threadChildMessages={this.props.threadChildMessages}
							messageId={message['message_id']}
						/>
					}
					<MessageHoverMenu	
						setNewMessageParentMessage={()=>{this.props.setNewMessageParentMessage(message)}}		
						deleteMessage={()=>{this.props.deleteMessage(message,this.props.orgId)}}
						isCurrentUser={isCurrentUser}
						messageId={message['message_id']}
						docId={this.props.docId}
						addReactionToMessage={this.props.addReactionToMessage}
						reactionDefinitions={reactionDefinitions}
						replyInThread={this.props.replyInThread}
						isInThread={this.props.isInThread}
						isThreadParent={this.props.isThreadParent}
						autoCreated={auto_created}
						threadsEnabled={this.props.threadsEnabled}
						isInDocThread={message.in_doc_thread}
					/>  		
				</div>
		)
	}
}
 

function mapStateToProps(state,ownProps) {	
	return {

	}
}

export default withRouter(connect(
	mapStateToProps,
	{
	})(RecordingMessage))

