import React from 'react'
import Icon from '../../misc/Icon'

//March 9th new docType roadmapDoc

class MessagePanelMentionTipPreloadMessage extends React.Component{  
	

	render(){	
		const {docType} = this.props
		return (	  															
			<div className='messagePanel-preloadMessageContainer messagePanel-messagePanel-preloadMessageContainer--mentionTip'>
				{docType !== 'projectRootDoc' &&
					<div className='messagePanel-preloadMessage'>
						<div className='messagePanel-preloadMessage-emoij'>
							📨
						</div>
						<div className='messagePanel-preloadMessage-title'>
							Mention teammates to notify them
						</div>
						<div className='messagePanel-preloadMessage-description'>					
							<div className='messagePanel-preloadMessage-description-para'>
								Everyone can find and edit this doc – mentioning teammates with @ will notify them about new comments and doc updates.
							</div>						
						</div>					
					</div>
				}
				{docType === 'projectRootDoc' &&
					<div className='messagePanel-preloadMessage'>
						<div className='messagePanel-preloadMessage-emoij'>
							📤
						</div>
						<div className='messagePanel-preloadMessage-title'>							
							Mention teammates to add them
						</div>
						<div className='messagePanel-preloadMessage-description'>					
							<div className='messagePanel-preloadMessage-description-para'>
								 Every project member will be notified about new comments and updates.
							</div>
							<div className='messagePanel-preloadMessage-description-para'>
								 Project members are real-time synced with Linear.
							</div>
						</div>
					</div>
				}
			</div>
						
		)
	}
} 



export default MessagePanelMentionTipPreloadMessage
