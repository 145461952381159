import store from '../store'

export function toggleMessageMediaZoomInRedux(isZoomed) {	
	if(isZoomed){
		store.dispatch({ type:'ZOOM_MESSAGE_MEDIA_MODAL'})
	}else{
		store.dispatch({ type:'UNZOOM_MESSAGE_MEDIA_MODAL'})
	}	
}



