import React from 'react'
import ReactDOM from 'react-dom'
import { escapeRegExp } from "lodash";
import { Plugin } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view"
import {getNewState} from './utils/getNewState'
import {getSuggestions,reindexSearch} from './utils/getSuggestions'
import {getMatch} from './utils/getMatch'
import schema from '../../schema/editorSchema'
import InsertMentionsMenuReactComponent from './InsertMentionsMenuReactComponent'
import {createNewDocForLink} from '../../../utils/createNewDoc'
import {getAnchorNode} from '../../utils/getAnchorNode'
import {getDefaultIssueAttrs} from '../../utils/getDefaultIssueAttrs'
import {getDateSuggestion} from './utils/getDateSuggestion'
import {getAssignSuggestions} from './utils/getAssignSuggestions' 
import {addAssigneeToToDoTr} from '../../utils/toDos/addAssigneeToToDo'

const char="@"
const triggerRegex = new RegExp(`${escapeRegExp(char)}([^${escapeRegExp(char)}]*)$`);
//https://github.com/joelewis/prosemirror-mentions
const suggestionTextClass="doc-para-inlineMentionSearchBar" //add a decoration around matched text- used to position the dropdown
const activeClass='suggestionMenu-item--active'





//positioning stuff
//when show menu is active
//Decoration added around current suggestion with suggestionTextClass "prosemirror-suggestion"
//create a div and add it to element with id "editor"-- maybe we want to change this for scrollable_div?
function calcOrderedSuggestionsAndOffset(suggestions){
	const node = view.domAtPos(view.state.selection.$from.pos)
	const paraDOM = node.node
	const textDOM = paraDOM.querySelector("." + suggestionTextClass) //This is span added around the suggestin text e.g. "@JA"-Why not select element by id
	let offset
	if(textDOM){
		offset = textDOM.getBoundingClientRect()
	}
	let orderedSuggestions=suggestions
	// there was old code here about shortening list, but we don't supports small heights
	return {orderedSuggestions:orderedSuggestions,offset:offset,textDOM:textDOM}
}

function addStylesToEl(el,offset,textDOM){
	el.style.position = "fixed";
	if(offset ){
		el.style.left = offset.left + "px";
	}
	el.style.zIndex = 5000;
	// VERTICAL OFFSET
	// ABOVE OR BELOW
	let dropdownPosition
	if(offset && offset.top > (window.innerHeight / 2)){
		dropdownPosition = "above"
	}else{
		dropdownPosition = "below"
	}
	if(dropdownPosition === "above"){
		const bottom = window.innerHeight - offset.top + 4 // buffer get to the top, then add a bit of buffer      
		el.style.bottom = bottom + "px";
	}else if(dropdownPosition === "below" && textDOM) {       
		const top = (textDOM.offsetHeight) + offset.top + 2 // get height of text line then buffer      
		el.style.top = top + "px";
	}
	el.style.display = "block"
	return el
}

export function mentionsPlugin(pluginKey) {
	let el = document.createElement("div")//dropdown element
	el.addEventListener("mousedown", function(e) { //prevent clicking defocusing selection item
		e.preventDefault()
		e.stopPropagation()
	})
	const showList = function(view, state, suggestions,dateSuggestion,assignSuggestions) {		
		const calced=calcOrderedSuggestionsAndOffset(suggestions)
		const {orderedSuggestions,offset,textDOM}=calced
		// put popovers on the left hand if will overflow right side
		let popoverSide = "right"	
		const menuPlusPopoverWidth = 750 // a bit hacky
		let offsetDistanceToScreenEdge=window.innerWidth
		if(offset){
			offsetDistanceToScreenEdge = window.innerWidth - offset.left
		}
		
		if(offsetDistanceToScreenEdge < menuPlusPopoverWidth){
			popoverSide = "left"	
		}
		// if near bottom, prevent bottom results from overflowing bottom
		let nearBottom = false
		const bottomBuffer = 220 // a bit hacky, don't want pages to overflow bottom		
		const offsetDistanceToScreenBottom = window.innerHeight - offset.top
		if(offsetDistanceToScreenBottom < bottomBuffer){
			nearBottom = true	
		}
		// if near bottom, prevent bottom results from overflowing top
		let nearTop = false
		const topBuffer = 130 // a bit hacky, don't want pages to overflow bottom		
		const offsetDistanceToTop = offset.top
		if(offsetDistanceToTop < topBuffer){
			nearTop = true	
		}

		function selectIndex(index){
			select(view,state,orderedSuggestions,dateSuggestion,assignSuggestions,index)
			view.focus()
		}

		ReactDOM.render(	
			<InsertMentionsMenuReactComponent
				suggestions={suggestions}
				dateSuggestion={dateSuggestion}
				assignSuggestions={assignSuggestions}
				selectIndex={selectIndex}
				hideList={hideList}
				text={state.text}
				popoverSide={popoverSide}
				nearBottom={nearBottom}
				nearTop={nearTop}
			/>, el)
		document.getElementById("editor").appendChild(el)
		addStylesToEl(el,offset,textDOM)
	}

	const hideList = function() {
		el.style.display = "none"
		el.style.bottom = null
		el.style.top = null
		ReactDOM.unmountComponentAtNode(el)
	}

	const select = function(view,state,suggestions,dateSuggestion,assignSuggestions,index) {
		let tr=view.state.tr
		const {selection}=tr
		if(index==null){ //if index is null just shut the menu
			tr.setMeta("mentionPlugin", { deactivate: true })
			view.dispatch(tr)
			hideList()
		}else{
			let combinedSuggestions=suggestions
			if(dateSuggestion){
				combinedSuggestions=[dateSuggestion].concat(suggestions)
			}
			if(assignSuggestions.length>0){
				combinedSuggestions=assignSuggestions.concat(combinedSuggestions)
			}
			const item=combinedSuggestions[index]
			if(item.issue_id){//check if selection is in empty paragraph (will have text that is matched e.g. #ABC)if it is then insert as layout=block otherwise layout=inline
				let layout='inline'
				let type = schema.nodes.linearIssueMention	
				let attrs=getDefaultIssueAttrs()
				const node = type.createAndFill({...attrs,issueId:item.issue_id,layout:layout})
				tr.replaceWith(state.range.from, state.range.to, node)
				tr.insertText(`\u{00A0}`) //blank space
				tr.setMeta("mentionPlugin", { deactivate: true })
				view.dispatch(tr)
				hideList()
			}else if(item.doc_id){//insert internal link
				let type = schema.nodes.internalLink	
				const node = type.createAndFill({docId:item.doc_id,docNameSnapshot:item.name})
				let tr = view.state.tr
				tr.replaceWith(state.range.from, state.range.to, node)
				tr.insertText(`\u{00A0}`) //blank space
				tr.setMeta("mentionPlugin", { deactivate: true })
				view.dispatch(tr)
				hideList()
			}else if(item.project_id){//insert internal link
				let type = schema.nodes.linearProjectMention
				const node = type.createAndFill({projectId:item.project_id})
				let tr = view.state.tr
				tr.replaceWith(state.range.from, state.range.to, node)
				tr.insertText(`\u{00A0}`) //blank space
				tr.setMeta("mentionPlugin", { deactivate: true })
				view.dispatch(tr)
				hideList()
			}
			else if(item.date){//insert doc date
				let type = schema.nodes.docDate
				const node = type.createAndFill({date:item.date})
				let tr = view.state.tr
				tr.replaceWith(state.range.from, state.range.to, node)
				tr.insertText(`\u{00A0}`) //blank space
				tr.setMeta("mentionPlugin", { deactivate: true })
				view.dispatch(tr)
				hideList()
			}
			else if(item.user_id){//assign todo!
				let tr=view.state.tr
				const {selection}=tr
				const nodeTypeName='toDoItem'
				const anchorNode=getAnchorNode(selection.$from,nodeTypeName)
				tr=addAssigneeToToDoTr(item,anchorNode)
				const node = view.state.schema.text('\u00a0')//clean up text
				tr.replaceWith(state.range.from, state.range.to)
				tr.setMeta("mentionPlugin", { deactivate: true })
				view.dispatch(tr)		
				hideList()
			}
		}
	}

	return new Plugin({
		key: pluginKey,
		state: {
			init() {
				return getNewState();
			},
			apply(tr, prev,oldState,newState) { //TODO test this with colllab
				const next = { ...prev };
				const { selection } = tr
				if (
					selection.from === selection.to &&// only show popup if selection is a blinking cursor         
					!(tr.getMeta("mentionPlugin") && tr.getMeta("mentionPlugin").deactivate) &&// deactivate popup from view (e.g.: choice has been made or esc has been pressed)        
					!tr.getMeta("focus") &&// deactivate because a mouse event occurs (user clicks somewhere else in the document)
					!tr.getMeta("blur") &&
					!tr.getMeta("pointer")
				) {
					// Reset active state if we just left the previous suggestion range (e.g.: key arrows moving before /)
					if (prev.active && selection.from <= prev.range.from) {
						next.active = false;
					} else if (tr.getMeta("mentionPlugin") && tr.getMeta("mentionPlugin").activate) {
						// Start showing suggestions. activate has been set after typing a "/" (or whatever the specified character is), so let's create the decoration and initialize
						next.range = {
							from: selection.from - 1,
							to: selection.to,
						};
						next.query = "";
						next.active = true;
						const isToDo=tr.getMeta("mentionPlugin").isToDo
						const assignees=tr.getMeta("mentionPlugin").assignees
						next.isToDo=isToDo
						next.assignees=assignees
					} else if (prev.active) {       			
						const $position = selection.$from;
						const match = getMatch($position,triggerRegex)
						if(match) {// if match found update state       
							next.range = match.range;
							next.active = true;
							next.text = match.queryText;
						}
					}
				} 
				else {
					next.active = false;
				}
				return next
			}
		},
		props: {
			handleKeyDown(view, e) {
				var state = this.getState(view.state)
				// if(e.key==='@'){ //check is an empty space before @ symbol TODO this is a bit dodgy 
				// 	let tr=view.state.tr
				// 	const {selection}=tr
				// 	const $position = selection.$from;
				// 	const text = $position.doc.textBetween($position.pos-1, $position.pos);
				// 	const trimmed=text.trim()
				// 	const length=trimmed.length
				// 	if(length==0){
				// 		tr.setMeta("mentionPlugin", { activate: true})
				// 		reindexSearch()
				// 		view.dispatch(tr)
				// 	}		
				// }
				if(e.key==='@'){ //
					let tr=view.state.tr
					const {selection}=tr
					const $position = selection.$from;

					//Update 21st Nov only require blank space before if NOT inside a todo
					const nodeTypeName='toDoItem'
					const anchorNode=getAnchorNode(selection.$from,nodeTypeName)
					let isToDo=false
					let assignees=[]
					if(anchorNode){
						isToDo=true
						assignees=anchorNode.attrs.assignees
					}
					if(isToDo){
						reindexSearch()
						tr.setMeta("mentionPlugin", { activate: true,isToDo:isToDo,assignees:assignees})
						view.dispatch(tr)
					}else{
						//check is an empty space before @ symbol TODO this is a bit dodgy 
						const text = $position.doc.textBetween($position.pos-1, $position.pos);
						const trimmed=text.trim()
						const length=trimmed.length
						if(length==0){
							//check if we are inside a todo item
							tr.setMeta("mentionPlugin", { activate: true,isToDo:isToDo})
							reindexSearch()
							view.dispatch(tr)
						}
					}					
				}


				//if(!state.active || !state.suggestions.length) { // don't handle if no suggestions or not in active mode
				if(!state.active ) { 
					return false
				}else if ( e.keyCode === 13) { //enter key
					if(state.suggestions.combined && state.suggestions.combined.length==0){
						let tr=view.state.tr
						tr.setMeta("mentionPlugin", { deactivate: true})
						view.dispatch(tr)
					}
					return true	
				}else if ( e.keyCode === 27) { //escape key
					let tr=view.state.tr
					tr.setMeta("mentionPlugin", { deactivate: true})
					view.dispatch(tr)
					return true
				}else if (e.keyCode===39) { //right arrow
					let tr=view.state.tr
					tr.setMeta("mentionPlugin", { deactivate: true})
					view.dispatch(tr)
					return false
				}else if (e.keyCode===37) { //left arrow- prevent left arrow when active
					if(!state.text){
						let tr=view.state.tr
						tr.setMeta("mentionPlugin", { deactivate: true})
						view.dispatch(tr)
					}
					return true
				}else if (e.keyCode===8) { //backspace //if backspace at the begining then it should dectivate
					if(!state.text){
						let tr=view.state.tr
						tr.setMeta("mentionPlugin", { deactivate: true})
						view.dispatch(tr)
						return true
					}
				}
			},     
			decorations(editorState) {//to decorate the currently active suggestion text in ui (and used for positioning dropdown)
				const state=this.getState(editorState)
				const { active, range } = state
				if (!active){
					return null
				}else{
					if(!state.text){
						return DecorationSet.create(editorState.doc, [
							Decoration.inline(range.from, range.to, {
								nodeName: "span",
								class: `${suggestionTextClass} ${suggestionTextClass}--empty`
							}),
						])
					}else{
						return DecorationSet.create(editorState.doc, [
							Decoration.inline(range.from, range.to, {
								nodeName: "span",
								class: suggestionTextClass
							}),							
						])
					}				
			}
		}
	},
		view() {// To track down state mutations and update dropdown
			return {
				update: (view,prevState )=> {
					const state = this.key.getState(view.state)			
					let focused=false
					if(window.view){
						focused=window.view.hasFocus()
					}
					if (!state.active || !focused){
						hideList()
					}else{
						const dateSuggestion=getDateSuggestion(state.text)
						const assignSuggestions = getAssignSuggestions(state.text,state.isToDo,state.assignees)
						getSuggestions(state.text,function(suggestions) {
							state.suggestions=suggestions
							state.dateSuggestion=dateSuggestion
							state.assignSuggestions=assignSuggestions
							if(suggestions.length || dateSuggestion || assignSuggestions.length){
								showList(view, state, suggestions,dateSuggestion,assignSuggestions)
							}else{
								hideList()
							}
						})
					}	
				},
				destroy: ()=>{
					hideList()
				}
			}
		}
	})
}