import React from 'react'
import Icon from '../../misc/Icon'

class ToggleListItemExpandArrowButton extends React.Component{  
	render(){
	  return (
	  	<React.Fragment>
		  	<button className='doc-toggleListItem-marker' onClick={this.props.toggleOpenState} >
		  		<Icon name='toggleArrow' />	  		
		  	</button>
		  	<button className='doc-toggleListItem-marker-lineContainer' onClick={this.props.toggleOpenState}>
	  			<div className='doc-toggleListItem-marker-line' />
	  		</button>
	  	</React.Fragment>
	  )
	}
}

export default ToggleListItemExpandArrowButton
