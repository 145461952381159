import React from 'react'
import ReactPlayer from 'react-player/file'

class MessageMediaGIF extends React.Component{  

	constructor(props){
    super(props)
    this.handleClick=this.handleClick.bind(this)
    let defaultMinimized=true
    if(props.isUnread){
    	defaultMinimized=false
    }

    this.state={
      minimized:true,
      playing: false,	  
      seeking: false,
      // mouseMoved:false
    }    
  }  

  handleClick(){
		if(this.state.minimized){
			this.setState({minimized: false})
			this.setState({playing: true })
		}
		if(!this.state.minimized){
			this.setState({minimized: true})
			this.setState({ seeking: true })
    	this.player.seekTo(0)
			this.setState({playing: false})
		}
	}
	 
  handleOnReady = () => {
    if(!this.state.minimized){			
			this.setState({playing: true })
		}
		if(this.state.minimized){			
			this.setState({playing: false})
		}
  }

	ref = player => {
    this.player = player
  }

	render(){	

		const {tenorMp4Url,isUnread}=this.props
		const {minimized, playing}=this.state			
		return (
			<div onClick={this.handleClick} className={'message-content-gifContainer ' + (minimized ? ' message-content-gifContainer--minimized ' : ' message-content-gifContainer--default ')}>
				<div className='message-content-gif'>
					<div className='message-content-gif-gifContainer'>
				  	<ReactPlayer   			  
					    ref={this.ref}
		          className='message-content-gif-gif'
		          width="100%"
		          height="100%"
		          url={tenorMp4Url}
		          autoPlay={!minimized}	          		          
		          playing={playing}
		          controls={false}		          
		          loop={true}		          
		          muted={true}
		          onReady={this.handleOnReady}		          
		          onPlay={this.handlePlay}		          
		          onPause={this.handlePause}   

		          config={{
						    file: {
						      attributes: {
						        style: { width: "100%", height: "100%", maxHeight: '200px', objectFit: "contain" },
						      },
						    },
						  }}      
		  			/>
				  	
		       </div>

	        {minimized &&
	        	<div className='message-content-gif-label'>
	        		Play GIF
	        	</div>
	      	}
      	</div>
        
			</div>
		)
	}
}
 


export default MessageMediaGIF
