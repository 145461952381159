import React from 'react'
import Icon from '../../misc/Icon'

class DocMediaZoomedReactionBar extends React.Component{  
 
  constructor(){
    super()
    this.state={
      // zoomed: false,  
      // mouseMoved:false
    }
  }  

	render(){ 
		
		return (
			<div style={{width: `${this.props.containerWidth}px`}} className='doc-mediaContainer-zoomedReactionPanelContainer'>
				<div className='doc-mediaContainer-zoomedReactionPanel'>


				</div>
			</div>
		)
	}
}

export default DocMediaZoomedReactionBar
