const DocImageNodeSpec ={
  attrs: {
    deliveryUrl: {default:''},
    nodeId:{default:''},
    height:{default:''},
    width:{default:''},
    title:{default:''},
  },
  group: "block",
  parseDOM: [{tag: 'docImage'}],
  toDOM(node) {
    const div = document.createElement('div');
    div.className = 'doc-imageWrapper'
    const div2 = document.createElement('div');
    div2.className = 'doc-imageContainer'    
    // add width and height. width is maxed to 720
    const widthDocImage = 720
    const imageDisplayWidth = widthDocImage
    const imageDisplayHeight = (node.attrs.height / node.attrs.width) * imageDisplayWidth    
    div2.style.cssText = `width:${imageDisplayWidth}px;height:${imageDisplayHeight}px`
    div.appendChild(div2)
    const div3 = document.createElement('div');
    div3.className = 'doc-image'  
    div2.appendChild(div3)  
    const img = document.createElement('img');
    img.className='doc-image-image'
    img.src=node.attrs.deliveryUrl
    div3.appendChild(img)
    return div
  },
}
export default DocImageNodeSpec



//   <div className='doc-imageWrapper'>
//    <div className='doc-imageContainer'>
//        <div className='doc-image'>
//            <img className='doc-image-image' src={imageUrl}>
//        </div>
//    </div>
// </div>

