import React from 'react'
import Icon from '../../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import {getMessageForHighlight} from '../../../../utils/meetings/getMessageForHighlight'
import DocEditorMeetingContentHighlightGridHighlight from './DocEditorMeetingContentHighlightGridHighlight'
import filter from 'lodash/filter'
import DocEditorMeetingContentAddRecordingButton from './DocEditorMeetingContentAddRecordingButton'

function messagesForHighlight(highlightId,messages){
  const parentMessage = getMessageForHighlight(highlightId)
  const childMessages=filter(messages,{thread_parent:parentMessage.message_id})
  return childMessages
}


class DocEditorMeetingContentHighlights extends React.PureComponent{  

  constructor(props) {
    super(props)   
    this.state={      
      
    }
  }   

  render(){ 
    const {recording, meeting, highlights, meetingInPast, docMessages} = this.props
    const {hideUploadButton} = this.state
        
    const hasHighlights = highlights.length > 0
    
    const showAddRecordingButton = !recording
    
    let mux_status
    let transcript_status
    let uploadState

    if(recording){
      mux_status = recording.mux_status
      transcript_status = recording.transcript_status      
      if(mux_status=='waiting_for_upload'){
        uploadState='uploading'
      }else if(mux_status=='created'){
        uploadState='processing'
      }else if(mux_status=='ready'){
        if(transcript_status=='ready'){
          uploadState='ready'
        }else{
          uploadState='processing'
        }
      }    
    }
    
    const recordingInProgress = uploadState === 'processing' || uploadState === 'uploading'
    


    return (        

      <React.Fragment>      
    
        {showAddRecordingButton &&
          <DocEditorMeetingContentAddRecordingButton 
            meeting={meeting}
          />          
        }

        {recording &&
         <div className={'docEditor-meetingContent-highlights ' + (recordingInProgress ? ' docEditor-meetingContent-highlights--disabled ' : '')}>
            <div className='docEditor-meetingContent-menu'>
              <div className='docEditor-meetingContent-menu-headerLabel'>            
                Highlights
              </div>      
              <div className='docEditor-meetingContent-menu-hSpacer' />
              {highlights.length > 2 &&                 
                <Tooltip.Provider>
                  <Tooltip.Root delayDuration={600}>
                    <Tooltip.Trigger asChild>                                 
                      <button onClick={this.props.openRecordingModal} className='docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--iconOnly'>
                        <div className='docEditor-meetingContent-menu-btn-iconContainer'>
                          <Icon name='plus' />
                        </div>
                        {/*<div className='docEditor-meetingContent-menu-btn-label'>
                          Add Highlight
                        </div>
                        */}
                      </button>        
                    </Tooltip.Trigger>        
                    <Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">                                                                  
                      Add Highlight
                    </Tooltip.Content>                          
                  </Tooltip.Root>
                </Tooltip.Provider>
              }   
            </div>          
            
            <div className={'docEditor-meetingContent-highlights-grid ' + (highlights.length < 4 ? ' docEditor-meetingContent-highlights-grid--autoResize ' : '')}>
             

              
              {highlights.map((highlight)=>{
                const highlightMessages=messagesForHighlight(highlight.highlight_id,docMessages)
                return(
                  <DocEditorMeetingContentHighlightGridHighlight 
                    key={`highlight_grid_${highlight.highlight_id}`}
                    creator={highlight.created_by} // userId
                    highlight={highlight}
                    description= {highlight.title}
                    openThread={this.props.openThread}
                    muxPlaybackID = {highlight.playback_id} // highlight example
                    highlightMessages={highlightMessages}
                    mainRecording={recording}
                  />
                  )
              })}
                

              {highlights.length < 3 && 
                <Tooltip.Provider>
                  <Tooltip.Root delayDuration={600}>
                    <Tooltip.Trigger asChild>                     
                      <button onClick={this.props.openRecordingModal} className='docEditor-meetingContent-highlights-grid-addHighlightBtn'>
                        
                        <div className='docEditor-meetingContent-highlights-grid-addHighlightBtn-iconContainer'>
                          <Icon name='plus' />
                        </div>

                        {/*<div className='docEditor-meetingContent-highlights-grid-addHighlightBtn-label'>
                          Add Highlight
                        </div>
                        */}
                      </button>        
                    </Tooltip.Trigger>        
                    <Tooltip.Content side="bottom" sideOffset={-14} className="tooltip tooltip--textSelectionUI">                                                                  
                      Add Highlight
                    </Tooltip.Content>                          
                  </Tooltip.Root>
                </Tooltip.Provider>
              }

            </div>
          </div>
        }

      </React.Fragment>
    )
  }
}

export default DocEditorMeetingContentHighlights
