
//https://github.com/ProseMirror/prosemirror-example-setup/blob/master/src/inputrules.js
import {inputRules, wrappingInputRule, textblockTypeInputRule,
        smartQuotes, emDash, ellipsis} from "prosemirror-inputrules"
import {arrowInputRule} from './arrowInputRule'
//import {dashInputRule} from './dashInputRule'
import {horizontalLineInputRule} from './horizontalLineInputRule'
import {bulletListInputRule} from './bulletListInputRule'
import {dashListInputRule} from './dashListInputRule'
import {markdownBoldInputRule} from './markdownBoldInputRule'
import {headingInputRule} from './headingInputRule'
import {numberListInputRule} from './numberListInputRule'
import {alphabetListInputRule} from './alphabetListInputRule'
import {toDoListItemInputRule} from './toDoListItemInputRule'
import {toggleListItemInputRule} from './toggleListItemInputRule'
import {codeBlockInputRule} from './codeBlockInputRule'
// import {alphabetLowerCaseListInputRule} from './alphabetLowerCaseListInputRule'


// : (NodeType) → InputRule
// Given a blockquote node type, returns an input rule that turns `"> "`
// at the start of a textblock into a blockquote.
// function blockQuoteRule(nodeType) {
//   return wrappingInputRule(/^\s*>\s$/, nodeType)
// }

// // : (NodeType) → InputRule
// // Given a list node type, returns an input rule that turns a number
// // followed by a dot at the start of a textblock into an ordered list.
// function orderedListRule(nodeType) {
//   return wrappingInputRule(/^(\d+)\.\s$/, nodeType, match => ({order: +match[1]}),
//                            (match, node) => node.childCount + node.attrs.order == +match[1])
// }

// // : (NodeType) → InputRule
// // Given a list node type, returns an input rule that turns a bullet
// // (dash, plush, or asterisk) at the start of a textblock into a
// // bullet list.
// function bulletListRule(nodeType) {
//   return wrappingInputRule(/^\s*([-+*])\s$/, nodeType)
// }

// : (NodeType) → InputRule
// Given a code block node type, returns an input rule that turns a
// textblock starting with three backticks into a code block.
// function codeBlockRule(nodeType) {
//   return textblockTypeInputRule(/^```$/, nodeType)
// }

// : (NodeType, number) → InputRule
// Given a node type and a maximum level, creates an input rule that
// turns up to that number of `#` characters followed by a space at
// the start of a textblock into a heading whose level corresponds to
// // the number of `#` signs.
// function headingRule(nodeType, maxLevel) {
//   return textblockTypeInputRule(new RegExp("^(#{1," + maxLevel + "})\\s$"),
//                                 nodeType, match => ({level: match[1].length}))
// }

// : (Schema) → Plugin
// A set of input rules for creating the basic block quotes, lists,
// code blocks, and heading.
export function buildInputRules(schema) {
  let rules=[]
  rules.push(markdownBoldInputRule())
  rules.push(horizontalLineInputRule())
  rules.push(bulletListInputRule())
  rules.push(dashListInputRule())
  rules.push(headingInputRule())
  rules.push(numberListInputRule())
  rules.push(alphabetListInputRule())
  rules.push(toDoListItemInputRule())
  rules.push(toggleListItemInputRule())
  rules.push(codeBlockInputRule())
  // rules.push(alphabetLowerCaseListInputRule())
  return inputRules({rules:rules})
}