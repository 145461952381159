import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { Draggable } from '@hello-pangea/dnd'
import Icon from '../../../components/misc/Icon'
import MeetingTypeIcon from '../../../components/misc/MeetingTypeIcon'
import LinearMilestoneIcon from '../../../components/misc/LinearMilestoneIcon'
import {getDocForId} from '../../../utils/getDocForId'
import {getRoadmapForId} from '../../../utils/getRoadmapForId'
import {closeTab} from '../../../utils/closeTab'
import {openRoadmap} from '../../../utils/openRoadmap'
import {openDoc} from '../../../utils/openDoc'
import * as ContextMenu from '@radix-ui/react-context-menu'
import DraggableTabContextMenuContents from './DraggableTabContextMenuContents'
import {calculateDocNotificationCount} from '../../../utils/notifications/calculateNotificationCounts'
import LinearMarker from '../../../components/misc/LinearMarker'
import MoveDocPopover from '../../../components/MoveDocPopover'
import {makeUrlForDoc} from '../../../utils/openDoc'
import {getMilestoneForId} from '../../../utils/getMilestoneForId'
import {getMeetingForId} from '../../../utils/meetings/getMeetingForId'
import {getFormattedTimestampForMeeting} from '../../../utils/meetings/getFormattedTimestampForMeeting'

//Tabs have {type:<doc/roadmap>,id:<id>}

class DraggableTab extends React.Component{  

	constructor(){
    super() 
    this.openMoveDocPopover=this.openMoveDocPopover.bind(this) 
    this.openTab=this.openTab.bind(this)  
    this.state={
      showMoveDocPopover: false,
      showLabel: true,
    }
    this.timeout=null
  }

	componentWillUnmount(){
		if(this.timeout){
			clearTimeout(this.timeout)
			this.timeout=null
		}
	}

	openTab(){
		const {tab}=this.props
		if(tab.type=='doc'){
			openDoc(this.props.history,tab.id)
		}else if(tab.type=='roadmap'){
			openRoadmap(this.props.history,tab.id)
		}
	}

  openMoveDocPopover(e){
  	e.stopPropagation()
		this.timeout=setTimeout(() => {
    	this.setState({showMoveDocPopover: true});
    }, 200);        
	}

	render(){  
		const {tab,index} = this.props
		const showDeleteButton = this.props.independentTab //is not in stared
		const {showMoveDocPopover, showLabel} = this.state
		const pathname=this.props.location?this.props.location.pathname:''
		if(tab.type=='doc'){
			const doc = getDocForId(tab.id)
			let tabUrl = makeUrlForDoc(tab.id)
			const totalNotificationCount = calculateDocNotificationCount(tab.id)
			const hasNotifications = totalNotificationCount > 0
			let notificationCountLabel = totalNotificationCount
			if(totalNotificationCount > 9){
				notificationCountLabel = '9+'
			}
			
			//handle doc renames so path doesn't matrch
			let urlDocId
			const signatureComponentsArray=pathname.split(/-/)
			if(signatureComponentsArray.length>1){
				urlDocId=signatureComponentsArray[signatureComponentsArray.length-1]
			}
			let active = tab.id==urlDocId


			let showMarker=false
			let docType
			if(doc && doc.is_project_doc){
				showMarker=true
				docType='projectRootDoc'
			}
			
			let isMilestoneSubDoc=false 
			let milestone={}
			if(doc && doc.milestone){
				isMilestoneSubDoc=true
				milestone=getMilestoneForId(doc.milestone)
			}


			let meetingDoc = false
			let meetingObj
			let meetingType
			let meetingDateTime			
			let meetingFormattedDateTime
			
			if(doc && doc.meeting){
				meetingDoc = true
				meetingObj = getMeetingForId(doc.meeting)
				if(meetingObj){
					meetingType = meetingObj.type
					meetingFormattedDateTime = getFormattedTimestampForMeeting(meetingObj,'sideMenu')
				}
			}
		

			return (	  	
				<Draggable key={`${tab.type}-${tab.id}`} draggableId={tab.id} index={index}>
					{(provided, snapshot) => (					
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							className={'sideMenu-tab sideMenu-tab--tabType--doc ' + (snapshot.isDragging && ' sideMenu-tab--dragging ') + (showDeleteButton && ' sideMenu-tab--showTabActions ') + (active ? ' sideMenu-tab--active ' : ' sideMenu-tab--inactive ')}
							onClick={this.openTab}
						>
							{showMarker &&
								<div className='sideMenu-tab-iconContainer'>
									<LinearMarker projectId={doc.project} />
								</div>
							}
							{isMilestoneSubDoc &&
								<div className='sideMenu-tab-iconContainer sideMenu-tab-iconContainer--milestone'>
									<LinearMilestoneIcon 
										milestone={milestone}
									/>
								</div>
							}
							
							{meetingDoc && meetingType && meetingType !== 'blank' &&
								<div className='sideMenu-tab-iconContainer sideMenu-tab-iconContainer--meetingDoc'>
									<MeetingTypeIcon meetingType={meetingType} />
								</div>
							}

							<div className='sideMenu-tab-moveDocAnchor' />
							{!docType==='projectRootDoc' && tab &&
								<MoveDocPopover
									showPopover={showMoveDocPopover}						
									openPopover={this.openMoveDocPopover}
									closePopover={()=>{this.setState({showMoveDocPopover: false})}}		
									docId={tab.id}	
									layout="sideMenu"
								>
									<div className='sideMenu-tab-moveDocPopoverAnchor' />
								</MoveDocPopover>								
							}
							{!showMarker &&
								<div className='sideMenu-tab-noIconSpacer'/>
							}
							<div className='sideMenu-tab-labelContainer'>
								{doc && showLabel &&
								<div className='sideMenu-tab-label'>
									{doc.name}
								</div>
								}
								{showLabel && meetingDoc && meetingFormattedDateTime &&
									<div className='sideMenu-tab-label sideMenu-tab-label--dateTime'>
										{meetingFormattedDateTime}
									</div>
								}
							</div>
							{hasNotifications && !active &&
								<div className='sideMenu-tab-notificationContainer'>
									<div className='sideMenu-tab-notification'>
										{notificationCountLabel}
									</div>
								</div>
							}
							{showDeleteButton &&
								<div className='sideMenu-tab-actionsContainer'>
									<div onClick={(e)=>{closeTab(e,active,this.props.history,tab)}} className='sideMenu-tab-actionBtn sideMenu-tab-actionBtn--closeBtn'>
										<Icon name='cross-small' />
									</div>
								</div>
							}
							<ContextMenu.Root> 
		    			<ContextMenu.Trigger asChild>	
		    				<div className='sideMenu-tab-contextMenuAnchor' />
							</ContextMenu.Trigger>
					 		<ContextMenu.Portal>
						 		<ContextMenu.Content collisionPadding={10} className='contextMenu contextMenu--tab'>	
						 			{doc &&
							 			<DraggableTabContextMenuContents 
							 				tabType='doc'
							 				itemId={doc.doc_id}
							 				isProjectDoc={docType==='projectRootDoc'} 				
							 				docType={docType}
							 				openMoveDocPopover={this.openMoveDocPopover}
							 			/>
						 			}
						 			{/* I COULDN'T SEEM TO CHANGE STATE HERE WHEN WAS IN COMPONENT CONTENTS COMPONENT */}
						 			{doc && !docType==='projectRootDoc' &&
						       	<ContextMenu.Item onSelect={this.openMoveDocPopover}>        	
									    Move Doc...
									  </ContextMenu.Item>
						 			}								 			
							    <ContextMenu.Separator />						  	
							    <ContextMenu.Item onClick={(e)=>{closeTab(e,active,this.props.history,tab)}}>
							      Close Tab
							    </ContextMenu.Item>
						 		</ContextMenu.Content>	 		
					 		</ContextMenu.Portal>
					 		</ContextMenu.Root>		
						</div>
						)}
				</Draggable>
			)
		}else if(tab.type=='roadmap'){
			const roadmap=getRoadmapForId(tab.id)
			const tabUrl=`/roadmaps/${roadmap.roadmap_id}`
			let active = tabUrl && pathname==tabUrl
			return (	  	
				<Draggable key={`${tab.type}-${tab.id}`} draggableId={tab.id} index={index}>
					{(provided, snapshot) => (					
						<div
							ref={provided.innerRef}
							{...provided.draggableProps}
							{...provided.dragHandleProps}
							className={'sideMenu-tab sideMenu-tab--tabType--doc ' + (snapshot.isDragging && ' sideMenu-tab--dragging ') + (showDeleteButton && ' sideMenu-tab--showTabActions ') + (active ? ' sideMenu-tab--active ' : ' sideMenu-tab--inactive ')}
							onClick={this.openTab}
						>
							<div className='sideMenu-tab-iconContainer sideMenu-tab-iconContainer--roadmap'>
								<Icon name='roadmapOutlineMedium' />
							</div>
							<div className='sideMenu-tab-labelContainer'>
								{roadmap && 
								<div className='sideMenu-tab-label'>
									{roadmap.name}						
								</div>
								}
						</div>
						{showDeleteButton &&
							<div className='sideMenu-tab-actionsContainer'>
								<div onClick={(e)=>{closeTab(e,active,this.props.history,tab)}} className='sideMenu-tab-actionBtn sideMenu-tab-actionBtn--closeBtn'>
									<Icon name='cross-small' />
								</div>
							</div>
						}
						<ContextMenu.Root> 
		    			<ContextMenu.Trigger asChild>	
		    				<div className='sideMenu-tab-contextMenuAnchor' />
							</ContextMenu.Trigger>
					 		<ContextMenu.Portal>
						 		<ContextMenu.Content collisionPadding={10} className='contextMenu contextMenu--tab'>	
						 			{roadmap &&
							 			<DraggableTabContextMenuContents 
							 				tabType='roadmap'
							 				itemId={roadmap.roadmap_id}
							 				roadmapSlug={roadmap.slug_id}
							 			/>
						 			}

						 			<ContextMenu.Separator />						  	
							    <ContextMenu.Item onClick={(e)=>{closeTab(e,active,this.props.history,tab)}}>        	
							      Close Tab
							    </ContextMenu.Item>
						 			
						 		</ContextMenu.Content>	 		
					 		</ContextMenu.Portal>
					 		</ContextMenu.Root>		

						</div>
					)}
			</Draggable>
			)
		}else return null
	}
}

export default withRouter(DraggableTab)
