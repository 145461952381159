// import {modifiedWrappingInputRule} from './modifiedWrappingInputRule'
import {wrappingInputRule} from 'prosemirror-inputrules'
import schema from '../../schema/editorSchema'
import {getAnchorNode} from '../../utils/getAnchorNode'

export function dashListInputRule(){
	return new wrappingInputRule(/^-\s/g, schema.nodes.bulletListItem,(match)=>{
		const state=window.view.state
		let {$from} = state.selection
		const anchorNode=getAnchorNode($from,'paragraph')
		let indentLevel=0
		if(anchorNode){ //Think this should always be true so might not need this logic
			if(anchorNode.attrs.indentLevel){
				indentLevel=anchorNode.attrs.indentLevel
			}
		}
		return {indentLevel:indentLevel}
	}
	)
}

