import React from 'react'
import Icon from '../../misc/Icon'
import * as ContextMenu from '@radix-ui/react-context-menu';
import {getOrgMembers} from '../../../utils/getOrgMembers'
import {getProjectForId} from '../../../utils/getProjectForId'
import {updateProjectLead} from '../../../utils/updateProject/updateProjectLead'
import AvatarImage from '../../AvatarImage'

class RoadmapsProjectRowEditLeadContextSubMenuItem extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectLeadUser=this.handleSelectLeadUser.bind(this)
	}

	handleSelectLeadUser(){
		updateProjectLead(this.props.projectId,this.props.user.user_id)
	}

	render(){	
		const {active, user} = this.props	
		return (
			<ContextMenu.Item onSelect={this.handleSelectLeadUser} className={'popoverMenu-item popoverMenu-item--editProjectLead ' + (active ? ' popoverMenu-item--active ' : ' popoverMenu-item--inactive ')}>
				<div className='popoverMenu-item-avatarContainer'>
					<AvatarImage className='popoverMenu-item-avatar' avatarUrl={user.avatar}/> 
				</div>
				<div className='popoverMenu-item-label'>
					{user.display_name}
				</div>				
			</ContextMenu.Item>
		)
	}
}


class RoadmapsProjectRowEditLeadContextSubMenu extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectUnassignLeadUser=this.handleSelectUnassignLeadUser.bind(this)
	}

	handleSelectUnassignLeadUser(){
		updateProjectLead(this.props.projectId,null)
	}


	render(){	
		const {leadUserId} = this.props
		
		const orgMembers = getOrgMembers()


		return (
			<React.Fragment>         	
				{orgMembers
					//used GTP for this, dunno if it's okay
					.sort((a, b) => a.display_name.localeCompare(b.display_name)) // Sort orgRoadmaps alphabetically by the name field
					.map((orgMember) => {
				  // Check if the user is the lead user 
				  const isActive = orgMember.user_id === leadUserId
				  return (
				    <RoadmapsProjectRowEditLeadContextSubMenuItem
				      key={orgMember.user_id}
				      projectId={this.props.projectId}
				      user={orgMember}
				      active={isActive}
				    />
				  );
				})}

				<div className='popoverMenu-separator popoverMenu-separator--spacer' />

				<div onClick={this.handleSelectUnassignLeadUser} className='popoverMenu-item popoverMenu-item--unassignProjectLead'>					
					<div className='popoverMenu-item-label'>
						Unassign Project Lead
					</div>									
				</div>

			</React.Fragment>
		)
	}
}

export default RoadmapsProjectRowEditLeadContextSubMenu