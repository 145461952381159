import MiniSearch from 'minisearch'
import sortBy from 'lodash/sortBy'

let minisearchName = new MiniSearch({
	fields: ['name','label','altNames'],
	storeFields: ['iconName','name','label','altNames'],
	idField: 'name',
})


function filterSuggestions(searchTerm,suggestions,isDropdown){
	let results=suggestions //empty state is all results
	if(minisearchName._documentCount == 0){
		minisearchName.addAll([...suggestions].reverse()) //reverse so that Header 1 appears before Header 2 in search results (both have same score if. e.g. type 'h')
	}
	if(searchTerm){
		const nameResults=minisearchName.search(searchTerm, {prefix:true, fuzzy:0.2})
		//results=nameResults
		results = sortBy(nameResults, function(item){
		 return item.label.toString().toLowerCase()
  	})

	}
	return results
}

export function getSuggestions(text,suggestions,isDropdown,done){

	const filteredSuggestions=filterSuggestions(text,suggestions,isDropdown)
	done(filteredSuggestions)
}