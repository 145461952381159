import React from 'react'
import { withRouter} from 'react-router-dom'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import MessageReactionsPanel from './MessageReactionsPanel'
import MessageOpenThreadBtn from './MessageOpenThreadBtn'
import MessageHoverMenu from './MessageHoverMenu'
import MessageMediaGIF from './messageMedia/MessageMediaGIF'
import MessageMediaVideo from './messageMedia/MessageMediaVideo'
import MessageMediaImage from './messageMedia/MessageMediaImage'
import MessageMediaHighlight from './messageMedia/MessageMediaHighlight'
import MessageMediaMainFeedHighlight from './messageMedia/MessageMediaMainFeedHighlight'
import MessageMediaRecording from './messageMedia/MessageMediaRecording'
import MessageDocSelectionPreview from './MessageDocSelectionPreview'
import MessageMediaDrawing from './messageMedia/MessageMediaDrawing'
import MessageMediaFigma from './messageMedia/MessageMediaFigma'
import {getMessageForId} from '../../../utils/getMessageForId'
import {getUserForId} from '../../../utils/getUserForId'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import VideoDuration from '../../../utils/videoDuration'
import {openDoc} from '../../../utils/openDoc'
import find from 'lodash/find'
// import MessageLinearIssueMentionReactComponent from './messageInputBox/nodeViews/MessageLinearIssueMentionReactComponent'
import StaticMessageLinearIssueMention from './staticMessageReactComponents/StaticMessageLinearIssueMention'
import StaticMessageLinearProjectMention from './staticMessageReactComponents/StaticMessageLinearProjectMention'
import StaticMessageInternalLink from './staticMessageReactComponents/StaticMessageInternalLink'

// TO DO ITEM HARDCODED VALUE
const toDoItemLabel = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed finibus dapibus felis quis pharetra.'
const toDoItemChecked = true


function createMarkup(input) {
  return {__html: input}
}

//To add more reaction types add to this array (should work both in UI and API)
const reactionDefinitions=[
	{type:"like",emoji:"👍"},
	{type:"fire",emoji:"🔥"},
	{type:"bullseye",emoji:"🎯"}
]


////Recording message types
/// when recording is uploaded and ready
// auto_created=true
// message.recording={type:'recording',recodingId:id,action:'created'}

class Message extends React.PureComponent{  
	// static whyDidYouRender = true;

	componentDidMount(){ //After mounted check for mention HTML and replace with react components
		this.els=[]
		const issueElements=ReactDOM.findDOMNode(this).getElementsByClassName('message-para-linearIssueOuterContainer')
		
		for (let i = 0; i < issueElements.length; ++i) {
			const el = issueElements[i];  
			const issueId=el.getAttribute("data-issue-id")
			ReactDOM.render(
				<StaticMessageLinearIssueMention 
					issueId={issueId}
				/>, el)
			this.els.push(el)
			}
		
		const projectElements=ReactDOM.findDOMNode(this).getElementsByClassName('message-para-projectLinkOuterContainer')
		for (let i = 0; i < projectElements.length; ++i) {
			const el = projectElements[i];  
			const projectId=el.getAttribute("data-project-id")
			ReactDOM.render(
				<StaticMessageLinearProjectMention 
					handleOpenDoc={(docId)=>{openDoc(this.props.history,docId)}}
					projectId={projectId}
				/>, el)
			this.els.push(el)
			}

		const linkElements=ReactDOM.findDOMNode(this).getElementsByClassName('message-para-internalLinkContainer')
		for (let i = 0; i < linkElements.length; ++i) {
			const el = linkElements[i];  
			const docId=el.getAttribute("data-doc-id")
			ReactDOM.render(
				<StaticMessageInternalLink 
					handleOpenDoc={(docId)=>{openDoc(this.props.history,docId)}}
					docId={docId}
				/>, el)
			this.els.push(el)
			}	
	}

	componentWillUnmount(){ //lets cleanup after ourselves
		this.els.forEach((el)=>{
			ReactDOM.unmountComponentAtNode(el)
		})
	}


	render(){	
		//console.log('message render---')
		const {message,isCurrentUser,messageReactions,followOnMessage,messageIsHighlighted,threadParentMessage}=this.props
		const showReactionsPanel=messageReactions.length>0
		let videoTitle,muxId,videoPosterSrc,mediaDuration,videoTimestamp

		let highlightMessage = false
		let recordingMessage = false
		let highlightId
		let muxPlaybackId

		if(message.recording){

			muxPlaybackId = message.recording.playback_id
			// console.log(message.recording)
			if(message.recording.type==='highlight'){
				highlightMessage = true	
				highlightId=message.recording.highlight_id
			}
			if(message.recording.type!=='highlight'){
				recordingMessage = true	
			}			
		}

		if(message.selection_type=='video' && message.selection_snapshot){
			const snapshot=message.selection_snapshot
			videoTitle = snapshot.title
			muxId = snapshot.muxId
			videoPosterSrc = `https://image.mux.com/${muxId}/thumbnail.png?width=160&height=90&fit_mode=crop&time=0`
			mediaDuration = "3:05"
			videoTimestamp = snapshot.timestampSeconds||null
			if(videoTimestamp){
				videoTimestamp=Math.floor(videoTimestamp)
			}
		}
		let imageTitle,imageSrc
		if(message.selection_type=='image' && message.selection_snapshot){
			const snapshot=message.selection_snapshot
			imageTitle = snapshot.title
			imageSrc = snapshot.deliveryUrl
		}

		let figmaEmbedTitle=''
		let figmaEmbedDeliveryUrl=''

		if(message.selection_type=='figmaEmbed' && message.selection_snapshot){
			const snapshot=message.selection_snapshot
			figmaEmbedTitle = snapshot.node_title
			figmaEmbedDeliveryUrl = snapshot.image_url
		}

		const parentMessageId=message.parent_message
		let parentMessage
		let parentMessageUserName
		if(parentMessageId){
			parentMessage=getMessageForId(parentMessageId)
			if(parentMessage){
				const parentMessageUser=getUserForId(parentMessage.created_by)
				if(parentMessageUser){
					parentMessageUserName=parentMessageUser.display_name
				}
			}		
		}
		let selectionIsValid=true
		if(message.message_selection){
			const selectionObj=find(this.props.messageSelections,{id:message.message_selection})
			if(selectionObj && !selectionObj.isValid){
				selectionIsValid=false
			}
		}
		
		const {auto_created}=message
	return (	  	
		<div id={`message_${message['message_id']}`} className={'messageContainer ' + (auto_created ? ' messageContainer--autoGenerated ' : '')  + (messageIsHighlighted ? ' messageContainer--highlighted ' : '') + (followOnMessage ? ' messageContainer--followOnMessage ' : '')}>
			<div className='message'>
				<div className='message-leftContainer'>					
					<AvatarImage 
						avatarUrl={this.props.avatarUrl}
						className='message-avatarContainer'
					/>											
				</div>
				<div className='message-rightContainer'>
					<div className='message-header'>					
						<div className='message-header-name'>
							{this.props.userDisplayName}
						</div>						
						<div className='message-header-timestamp'>
							{this.props.timestampLabel}
						</div>						
					</div>

					<div className='message-content'>
						{parentMessageId && !message['is_thread'] &&
							<button className={'message-content-selection message-content-selection--message ' + (parentMessage['plain_text'] ? '' : ' message-content-selection--message--deleted ')} onClick={()=>{this.props.highlightMessage(parentMessageId)}}>
								<div className='message-content-selection-bar'/>
								<div className='message-content-selection-content'>
									{parentMessage['plain_text'] || 'Message deleted'}								
								</div>
							</button>
						}					
												
						{message.message_type=='richText' && message.selection_type=='docSelection' &&
							<MessageDocSelectionPreview
								docSelectionText={message.selection_snapshot?message.selection_snapshot.text:''}									
								selectionIsValid={selectionIsValid}
								goToDocSelection={()=>{this.props.handleClickOnDocSelection(message.message_selection)}}
								message={message}
							/>
						}					


						{message.message_type==='richText' && message.selection_type=='image' && 
							<button className='message-content-selection message-content-selection--image' onClick={()=>{this.props.handleClickOnMediaNodeSelection('image', message.selection_node_id)}}>								
								{/*{message.selection_snapshot} */}
								<div className='message-content-selection-bar' />
								<div className='message-content-selection-content'>
									<div className='message-content-selection-content-mediaContainer message-content-selection-content-mediaContainer--image'>
										<img src={imageSrc} className='message-content-selection-content-media' />
									</div>
									{imageTitle &&
									<div className='message-content-selection-content-mediaTitle'>										
										{imageTitle}
									</div>
									}
									{!imageTitle && 
									<div className='message-content-selection-content-mediaTitle message-content-selection-content-mediaTitle--untitled'>
										{selectionIsValid ? 'Untitled Image' : 'INVALID'}
									</div>
									}
								</div>
							</button>
						}
						{message.message_type==='richText' && message.selection_type=='table' && 
							<button className='message-content-selection message-content-selection--image' onClick={()=>{this.props.handleClickOnMediaNodeSelection('table', message.selection_node_id)}}>								
								{/*{message.selection_snapshot} */}
								<div className='message-content-selection-bar' />
								<div className='message-content-selection-content'>
									<div className='message-content-selection-content-mediaContainer message-content-selection-content-mediaContainer--image'>
										
									</div>
								
									<div className='message-content-selection-content-mediaTitle'>										
										TABLE
									</div>
									
									
								</div>
							</button>
						}

						{message.message_type==='richText' && message.selection_type=='figmaEmbed' && 
							<button className='message-content-selection message-content-selection--figmaEmbed' onClick={()=>{this.props.handleClickOnMediaNodeSelection('figmaEmbed', message.selection_node_id)}}>								
								{/*{message.selection_snapshot} */}
								<div className='message-content-selection-bar' />
								<div className='message-content-selection-content'>
									<div className='message-content-selection-content-mediaContainer message-content-selection-content-mediaContainer--figmaEmbed'>
										<img src={figmaEmbedDeliveryUrl} className='message-content-selection-content-media' />
									</div>									
									<div className='message-content-selection-content-mediaTitle'>										
										{figmaEmbedTitle}
									</div>
								</div>
							</button>
						}

						{message.message_type==='nodeSelection' && message.node_type=='todoItem' &&
							<button className='message-content-selection message-content-selection--todoItem'>								
								{/*{message.selection_snapshot} */}
								<div className='message-content-selection-bar' />
								<div className='message-content-selection-content'>
									<div className='message-content-selection-content-toDoItemCheckboxContainer'>
										<div className={'message-content-selection-content-toDoItemCheckbox ' + (toDoItemChecked ? ' message-content-selection-content-toDoItemCheckbox--checked ' : ' message-content-selection-content-toDoItemCheckbox--unchecked ')}>
											{toDoItemChecked && <Icon name='toDoItemCheckmark' /> }											 
										</div>
									</div>
									<div className='message-content-selection-content-toDoItemLabel'>
										{toDoItemLabel}
									</div>
								</div>
							</button>
						}					
						{message.message_type==='richText' && message.selection_type=='video' && 
							<button className='message-content-selection message-content-selection--video' onClick={()=>{this.props.handleClickOnMediaNodeSelection('video', message.selection_node_id)}}>
								{/*{message.selection_snapshot} */}
								<div className='message-content-selection-bar' />
								<div className='message-content-selection-content'>
									<div className='message-content-selection-content-mediaContainer message-content-selection-content-mediaContainer--video'>
										<img src={videoPosterSrc} className='message-content-selection-content-media' />
									</div>
									<div className='message-content-selection-content-rightContainer'>
										{videoTitle &&
											<div className='message-content-selection-content-mediaTitle'>										
												{videoTitle}
											</div>
										}
										{!videoTitle && 
											<div className='message-content-selection-content-mediaTitle message-content-selection-content-mediaTitle--untitled'>
												Untitled Video
											</div>
										}
										{!videoTimestamp && videoTimestamp!=0 &&
											<div className='message-content-selection-content-mediaDuration'>
												Video
											</div>
										}
										{(videoTimestamp || videoTimestamp==0) &&
											<div className='message-content-selection-content-videoTimestamp'>
												<div className='message-content-selection-content-videoTimestamp-iconContainer'>
													<Icon name='stopwatchBold' />
												</div>
												<div className='message-content-selection-content-videoTimestamp-label'>
													<VideoDuration seconds={videoTimestamp}/>
												</div>
											</div>
										}
									</div>
								</div>
							</button>
						}
						{(message.message_type==='richText' || message.message_type==='docSelection' || message.message_type==='nodeSelection') &&
							<div dangerouslySetInnerHTML={createMarkup(message.html)}/>
						}
						{message.message_type==='gif' &&														
							<MessageMediaGIF tenorMp4Url={message.message_media_url} isUnread={message.isUnread}/>
						} 
						{message.message_type==='video' &&
							<MessageMediaVideo cloudinaryID={message.message_media_url} />
						}
						{message.message_type==='image' &&
							<MessageMediaImage url={message.message_media_url}/>
						}
						{message.message_type==='drawing' &&
							<MessageMediaDrawing/>
						}
						{message.message_type==='figma' &&
							<MessageMediaFigma/>
						}
						{recordingMessage &&
							<MessageMediaRecording 								
								openRecordingModal={this.props.openRecordingModal}
								muxPlaybackID={muxPlaybackId}
								duration={message.recording.duration}
								isAudio={true}
							/>
						}
						{highlightMessage && !this.props.threadParentMessage &&
							<MessageMediaMainFeedHighlight 
								openThread={()=>{this.props.openThread(message.message_id)}}			
								muxPlaybackID={muxPlaybackId}
								duration={message.recording.duration}
								description={message.recording.title}
						/>
						}
						{highlightMessage && this.props.threadParentMessage &&
							<MessageMediaHighlight
								muxPlaybackID={muxPlaybackId}	
								highlightId={highlightId}
								openRecordingModal={this.props.openRecordingModal}							
								description={message.recording.title}		
								duration={message.recording.duration}					
							/>
						}
					</div>        
				</div>
				</div>  	
				{showReactionsPanel &&
					<MessageReactionsPanel							
						hideReactions={this.hideReactions}
						messageId={message.id}
						messageReactions={messageReactions}
						userId={this.props.userId}
						docId={this.props.docId}
						addReactionToMessage={this.props.addReactionToMessage}
						deleteReaction={this.props.deleteReaction}
						messageId={message['message_id']}
						reactionDefinitions={reactionDefinitions}						
					/>
				}				
				{((this.props.isThreadParent && this.props.threadChildMessages)|| message.in_doc_thread )&& !this.props.threadParentMessage &&
					<MessageOpenThreadBtn
						isInDocThread={message.in_doc_thread}
						openThread={()=>{this.props.openThread(message.message_id)}}
						threadChildMessages={this.props.threadChildMessages}
						messageId={message['message_id']}
					/>
				}
				
				<MessageHoverMenu	
					setNewMessageParentMessage={()=>{this.props.setNewMessageParentMessage(message)}}		
					deleteMessage={()=>{this.props.deleteMessage(message,this.props.orgId)}}
					isCurrentUser={isCurrentUser}
					messageId={message['message_id']}
					docId={this.props.docId}
					addReactionToMessage={this.props.addReactionToMessage}
					reactionDefinitions={reactionDefinitions}
					replyInThread={()=>{this.props.replyInThread(message.message_id)}}
					isInThread={this.props.isInThread}
					isThreadParent={this.props.isThreadParent}
					autoCreated={auto_created}
					threadsEnabled={this.props.threadsEnabled}
					isInDocThread={message.in_doc_thread}
				/>  		
			</div>
		)
	}
}
 

function mapStateToProps(state,ownProps) {	
	return {
		messageSelections:state.messageSelections
	}
}

export default withRouter(connect(
	mapStateToProps,
	{
	})(Message))


		// TOAST NOTIFICATION FOR DELETED REPLY
		// const actionType="clickDeletedMessageReply"								
		// TOAST NOTIFICATION FOR DELETED IMAGE
		// const actionType="clickDeletedMessageImage"				
		// TOAST NOTIFICATION FOR DELETED VIDEO		
		// const actionType="clickDeletedMessageVideo"
		// const userName=null
		// store.dispatch(showToastNotification(actionType,userName))
		

	// 	message(
//   message_id,
//   parent_message,--for whatsapp replies
//   thread_parent, --for threaded replies
//   created_at,
//   updated_at,
//   archived_at,
//   created_by,
//   --message content
//   message_type, --RichText,Gif,Video,Image
//   message_media_url, --If type is not rich text then has gif, video or image url (cannot combine text and media)
//   plain_text, --for RT, null for other message types
//   source, --pm for RT, null for other message types
//   html, --for RT, null for other message types
//   --Doc selections (if message_type is RT it can have a selection which references a bit of the doc e.g. text selection)
//   selection_type,--null, text, Image, video
//   selection_node_id, --media node id for  selection_type=image etc, null for text selection
//   selection_snapshot, --plain text snapshoted when message created or media node url
//   message_selection --for text selections, message selection obj is mapped through doc transforms
// );


