import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';

class DocDrawingTopRightMenu extends React.Component{  
 
  constructor(){
    super()
    this.state={
      
    }
  }  

	render(){ 

		const {addComment,deleteImage}=this.props
		
		return (			
			<div className='doc-media-topRightMenu'>				
				<button className='doc-media-topRightMenu-btn doc-media-topRightMenu-btn--iconAndLabel'>
					<div className='doc-media-topRightMenu-btn-iconContainer'>
						<Icon name='commentLayered' />
					</div>
					<div className='doc-media-topRightMenu-btn-label'>
						Comment
					</div>			
				</button>				
			
				<Tooltip.Provider>
					<Tooltip.Root delayDuration={50}>
						<Tooltip.Trigger onClick={this.props.deleteImage} className='doc-media-topRightMenu-btn doc-media-topRightMenu-btn--iconOnly doc-media-topRightMenu-btn--delete'>
							<div className='doc-media-topRightMenu-btn-iconContainer'>
								<Icon name='trash' />
							</div>					
						</Tooltip.Trigger>						
						<Tooltip.Content side="top" className="tooltip tooltip--mediaHoverMenu">
							Delete 
						</Tooltip.Content>					
					</Tooltip.Root>
				</Tooltip.Provider>
			</div>			

		)
	}
}

export default DocDrawingTopRightMenu
