import React from 'react'
import moment from 'moment'
import Icon from '../misc/Icon'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import MeetingsPageNewMeetingPopover from './MeetingsPageNewMeetingPopover'

const meetings = [
  { timestamp: "2023-07-22T18:00:00Z", name: "YouTube Uploads – Design Review", GCalOnly: false },
  { timestamp: "2023-07-22T22:00:00Z", name: "Design Sync", GCalOnly: true },
  { timestamp: "2023-07-24T20:00:00Z", name: "Check-in", GCalOnly: true },
  { timestamp: "2023-07-24T22:00:00Z", name: "API Sync", GCalOnly: false },
  { timestamp: "2023-07-25T17:00:00Z", name: "BetaWorks Demo", GCalOnly: false },
  { timestamp: "2023-07-25T20:00:00Z", name: "All Hands", GCalOnly: false },
  
  // Add more meetings as needed
].map(meeting => ({
  ...meeting,
  timestamp: moment(meeting.timestamp),
}));


class Meeting extends React.Component {
  

  constructor(props){
    super(props)    
    this.state={      
      showNewMeetingPopover: false, 
      left: 0,
      top: 0,     
    }
    this.buttonRef = React.createRef();
  }
  
  handleClick = (event) => {
    // Get the button's bounding rectangle
    const buttonRect = this.buttonRef.current.getBoundingClientRect();

    // Calculate the mouse's position relative to the button
    const left = event.clientX - buttonRect.left;
    const top = event.clientY - buttonRect.top;

    this.setState({
      showNewMeetingPopover: true,
      left: left,
      top: top,
    });
  }


  render() {
    const {meeting} = this.props;

    let meetingType

    if(meeting.name === 'YouTube Uploads – Design Review'){
    	meetingType = 'designReview'
    }
    if(meeting.name === 'API Sync'){
    	meetingType = 'teamSync'
    }
    if(meeting.name === 'BetaWorks Demo'){
    	meetingType = 'discovery'
    }
    if(meeting.name === 'All Hands'){
    	meetingType = 'teamSync'
    }

     return meeting.GCalOnly ? (
      
        <MeetingsPageNewMeetingPopover
          showPopover={this.state.showNewMeetingPopover}
          openPopover={()=>{this.setState({showNewMeetingPopover: true})}}
          closePopover={()=>{this.setState({showNewMeetingPopover: false})}}
          createMeeting={(value)=>{this.props.createMeeting(value)}}          
          left={this.state.left}
          top={this.state.top}
          location='GCalEvent'
        >
          <button ref={this.buttonRef} onClick={this.handleClick} className="meetingNotesPage-yourCalendar-day-list-meeting meetingNotesPage-yourCalendar-day-list-meeting--isGCal">        
            <div className='meetingNotesPage-yourCalendar-day-list-meeting-name'>
              
              <div className='meetingNotesPage-yourCalendar-day-list-meeting-name-label'>
              {meeting.name}
              </div>
              <div className='meetingNotesPage-yourCalendar-day-list-meeting-name-iconContainer'>
                <Icon name='plus' />
              </div>
            </div>
            <div className='meetingNotesPage-yourCalendar-day-list-meeting-timestamp'>
              {meeting.timestamp.format('h:mm a')}
            </div>          

          </button>

        </MeetingsPageNewMeetingPopover>


      
    ) : (      
      <button className='meetingNotesPage-yourCalendar-day-list-meeting meetingNotesPage-yourCalendar-day-list-meeting--meeting '>
        {meetingType && 
          <div className='meetingNotesPage-yourCalendar-day-list-meeting-iconContainer'>
            <MeetingTypeIcon meetingType={meetingType} />
          </div>
        }
        <div className='meetingNotesPage-yourCalendar-day-list-meeting-name'>
          {meeting.name}
        </div>
        <div className='meetingNotesPage-yourCalendar-day-list-meeting-timestamp'>
          {meeting.timestamp.format('h:mm a')}
        </div>                     
      </button>
    );
  }
}

class Day extends React.Component {
  
  constructor(props){
    super(props)    
    this.state={      
      showNewMeetingPopover: false,      
    }
  }


  render() {
    const { dayName, date, month, children } = this.props;

    return (
      <div className={'meetingNotesPage-yourCalendar-day ' + (dayName === 'Today' ? 'meetingNotesPage-yourCalendar-day--today' : '')}>
        <div className='meetingNotesPage-yourCalendar-day-header'>
        	<div className='meetingNotesPage-yourCalendar-day-header-dayOfWeek'>
        		{dayName} 
        	</div>
        	<div className='meetingNotesPage-yourCalendar-day-header-date'>
        		{month && month + " "} {date}
        	</div>          
        </div>
        <div className='meetingNotesPage-yourCalendar-day-list'>
          {children}
          
          {children.length < 1 && 
	          <div className='meetingNotesPage-yourCalendar-day-list-empty'>
	          	None
	          </div>
        	}
          
          

          {/* MAYBE SHOW IF NO GCAL INTEGRATION 
          <MeetingsPageNewMeetingPopover
            showPopover={this.state.showNewMeetingPopover}
            openPopover={()=>{this.setState({showNewMeetingPopover: true})}}
            closePopover={()=>{this.setState({showNewMeetingPopover: false})}}
            createMeeting={(value)=>{this.props.createMeeting(value)}}
            location='calendarPlus'
          >

            <button onClick={()=>{this.setState({showNewMeetingPopover: true})}} className='meetingNotesPage-yourCalendar-day-list-newBtn'>
              <Icon name='plus' />
            </button>

          </MeetingsPageNewMeetingPopover>
          */}


        </div>
      </div>
    )
  }
}

class MeetingsPageYourCalendarProto extends React.Component{  
  state = {
    currentDay: moment().startOf('day'),
  }

  handlePrevDays = () => {
    this.setState((prevState) => ({
      currentDay: prevState.currentDay.subtract(5, 'days'),
    }));
  };

  handleNextDays = () => {
    this.setState((prevState) => ({
      currentDay: prevState.currentDay.add(5, 'days'),
    }));
  };

  getDays() {
    const { currentDay } = this.state;

    return Array(5).fill().map((_, index) => {
      const day = currentDay.clone().add(index, 'days');
      const isToday = day.isSame(moment(), 'day');
    	const date = day.date();

      let month = null;

      if (day.date() === 1 || day.date() === day.daysInMonth()) {
        month = day.format('MMM');
      }

      const dayMeetings = meetings
  			.filter(meeting => meeting.timestamp.isSame(day, 'day'))
  			.sort((a, b) => a.timestamp.isBefore(b.timestamp) ? -1 : 1)
  			.map(meeting => <Meeting key={meeting.name} meeting={meeting} />);

      return (
        <Day
          key={index}
          dayName={isToday ? 'Today' : day.format('dddd')}
          date={date}
          month={month}
          createMeeting={(value)=>{this.props.createMeeting(value)}}
        >
          {dayMeetings}
        </Day>
      );
    });
  }

  render(){			
    return (
      <div className='meetingNotesPage-yourCalendar'>
        {this.getDays()}  
        
        <button className='meetingNotesPage-yourCalendar-navBtn meetingNotesPage-yourCalendar-navBtn--previous' onClick={this.handlePrevDays}>
        	<Icon name='chevronRight' />
        </button>
        
        <button className='meetingNotesPage-yourCalendar-navBtn meetingNotesPage-yourCalendar-navBtn--next' onClick={this.handleNextDays}>
        	<Icon name='chevronRight' />
        </button>
        
      </div>			 	
    )
  }
}

export default MeetingsPageYourCalendarProto
