import React from 'react'
import Icon from '../misc/Icon'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import { withRouter} from 'react-router-dom'
import {getDocForMeeting} from '../../utils/meetings/getDocForMeeting'
import {getRecordingForMeeting} from '../../utils/meetings/getRecordingForMeeting'
import {openDoc,backgroundOpenDoc} from '../../utils/openDoc'
//import {formatMeetingDateTime} from '../../utils/meetings/formatMeetingDateTime'
import {getFormattedTimestampForMeeting} from '../../utils/meetings/getFormattedTimestampForMeeting'
import MeetingParticipantList from '../meetings/meetingParticipantList/MeetingParticipantList'
import moment from 'moment'
import {formatDate} from '../../utils/formatDate'
import * as ContextMenu from '@radix-ui/react-context-menu';
import {copyDocLinkToClipboard} from '../../utils/copyDocLinkToClipboard'
import {deleteMeeting} from '../../utils/meetings/deleteMeeting'


class MeetingsPageMeetingRow extends React.Component{  	

	constructor(props){
		super(props)
		this.handleMeetingRowClick=this.handleMeetingRowClick.bind(this)
	}

	handleMeetingRowClick(e){
		const {meeting}=this.props
		const doc=getDocForMeeting(meeting.meeting_id)
		if(doc){
			if(e.metaKey){
			backgroundOpenDoc(doc.doc_id)
		}else{
			openDoc(this.props.history,doc.doc_id)
		}
		}
	}

	render(){	
		const {meeting, section}=this.props
		const doc=getDocForMeeting(meeting.meeting_id)
		let lastEditedTimestamp
		let docId
		if(doc){
			//const lastEditedTimestamp = 'Today 4:05PM'
			lastEditedTimestamp = formatDate(doc.updated_at)
			docId=doc.doc_id
		}


		const hasThumbnail = false
		let meetingTypeLabel
		if(meeting.type === 'designReview'){
			meetingTypeLabel = 'Design Review'
		}else if(meeting.type === 'teamSync'){
			meetingTypeLabel = 'Team Sync'
		}else if(meeting.type === 'discovery'){
			meetingTypeLabel = 'Discovery'
		}else if(meeting.type === 'adhoc'){
			meetingTypeLabel = 'Ad hoc'
		}
		const recording = getRecordingForMeeting(meeting.meeting_id)
		//const hasRecording = section === 'allMeetings' && (meeting.meeting_id === '28' || meeting.meeting_id === '29')
		const hasRecording = recording?true:false

		let formattedTimestamp=getFormattedTimestampForMeeting(meeting,'row')
		// if(meeting.meeting_date){
		// 	formattedTimestamp=formatMeetingDateTime(meeting.meeting_date)
		// }
		//const 

		let recordingPosterImageUrl 
		let muxPlaybackID
		if(recording){
			muxPlaybackID = recording.playback_id
			recordingPosterImageUrl = `https://image.mux.com/${muxPlaybackID}/thumbnail.webp?width=64&fit_mode=preserve`
		}




	  return (
	  	<ContextMenu.Root key={docId}> 
				<ContextMenu.Trigger asChild>	
				  <div onClick={this.handleMeetingRowClick} className='meetingNotesPage-meetingRow'>
				  	<div className='meetingNotesPage-meetingRow-startSpacer' /> 
				  	{/* 
				  	{hasRecording && 
					  	<div className='meetingNotesPage-meetingRow-thumbnailPreviewContainer'>
					  		<img src={recordingPosterImageUrl} className='meetingNotesPage-meetingRow-thumbnailPreview' />
					  	</div>
				  	}
				  	*/}
				  	{meeting && meeting.type && meeting.type !== 'blank' && 
					  	<div className='meetingNotesPage-meetingRow-iconContainer'>
					  		<MeetingTypeIcon 
									meetingType={meeting.type}
								/>
					  	</div>
				  	}
				  	{hasRecording && 
					  	<div className='meetingNotesPage-meetingRow-recordingIcon'>
					  		<Icon name='playTV' />
					  	</div>
				  	}
				  	{doc && 
					  	<div className='meetingNotesPage-meetingRow-title'> 
					  		{doc.name}					  
					  	</div>
				  	}

				  	
					  	<div className='meetingNotesPage-meetingRow-timestampContainer'>			  		
					  		{formattedTimestamp && 
						  		<div className='meetingNotesPage-meetingRow-timestamp'>
						  			{formattedTimestamp}
						  		</div>			  		
					  		}
					  	</div>



				  	{meeting.type && meeting.type !== 'blank' && 
				  		<div className='meetingNotesPage-meetingRow-meetingTypeLabelContainer'>
						  	<div className={'meetingNotesPage-meetingRow-meetingTypeLabel ' + (meeting.type ? `meetingNotesPage-meetingRow-meetingTypeLabel--type--${meeting.type}` : '')}>
						  		{meetingTypeLabel}
						  	</div>
					  	</div>
				  	}
			  	{/*}
			  	<div className='meetingNotesPage-meetingRow-timestampContainer'> 

			  		{formattedTimestamp}

			  	</div>
			  	*/}
			  	<div className='meetingNotesPage-meetingRow-participantsContainer'> 
			  		<MeetingParticipantList 
			  			meeting={meeting}	  			
			  		/>
			  	</div>

			  	<div className='meetingNotesPage-meetingRow-lastUpdated'>
						{lastEditedTimestamp}
					</div>

			  	<div className='meetingNotesPage-meetingRow-endSpacer' /> 
				</div>
			</ContextMenu.Trigger>
	  	<ContextMenu.Portal>

	  	<ContextMenu.Content collisionPadding={10} className='contextMenu'>	        	       	        
															
					<ContextMenu.Item onSelect={()=>{copyDocLinkToClipboard(docId)}}>
						Copy Link
					</ContextMenu.Item>

					<ContextMenu.Separator />
				
					<ContextMenu.Item onSelect={()=>{deleteMeeting(meeting.meeting_id,docId)}} className='contextMenu-item--warning' >
						Delete Meeting
					</ContextMenu.Item>		

				</ContextMenu.Content>
			</ContextMenu.Portal>
		</ContextMenu.Root>		 


	  )
	}
}
export default withRouter(MeetingsPageMeetingRow)
	  	
	  	{/*}
	  	<div className='meetingNotesPage-meetingRow-timestampContainer'>
	  		{section === 'today' && 
		  		<div className='meetingNotesPage-meetingRow-timestamp'>
		  			Today 4:30PM
		  		</div>
	  		}
	  		{section === 'tomorrow' && 
		  		<div className='meetingNotesPage-meetingRow-timestamp'>
		  			8:30PM
		  		</div>
	  		}

	  		{section === 'thisWeek' && 
		  		<div className='meetingNotesPage-meetingRow-timestamp'>
		  			Friday
		  		</div>
	  		}

	  		{section === 'nextWeek' && 
		  		<div className='meetingNotesPage-meetingRow-timestamp'>
		  			Next Wednesday
		  		</div>
	  		}
	  	</div>
	  	*/}
	  	
