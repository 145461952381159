import {Plugin,PluginKey} from "prosemirror-state"
import {TranscriptSearchState} from './transcriptSearch'

const key = new PluginKey('transcriptSearchPlugin')

export const transcriptSearchPlugin = new Plugin({
  key:key,
  state: {
    init:TranscriptSearchState.init,
    apply(tr, prev) { return prev.apply(tr) }
  },
  props: {
    decorations(state) { return this.getState(state).decos }
  }
})


 