import React from 'react'
import { withRouter} from 'react-router-dom'
import {openDoc,backgroundOpenDoc} from '../../../../utils/openDoc'
import LinearMilestoneIcon from '../../../misc/LinearMilestoneIcon'
import * as ContextMenu from '@radix-ui/react-context-menu';
import {getProjectMilestoneDocs} from '../../../../utils/getProjectMilestoneDocs'


class DocEditorMeetingContentProjectListRowMilestoneRow extends React.Component{  	
	constructor(props){
		super(props)
		this.handleRowClick=this.handleRowClick.bind(this)
	}

	handleRowClick(e){
		const {docId}=this.props
		if(e.metaKey){
			backgroundOpenDoc(docId)
		}else{
			openDoc(this.props.history,docId)
		}
	}

	render(){	
		const {milestone}=this.props		
		const completed = milestone.isComplete
		const activeMilestone=milestone.isActiveMilestone

		let showNotStarted = false
		if(milestone.progressPercentage === 0){
			showNotStarted = true
		}

		// 

	  return (
	  	<div className={'meetingProjectListRow-milestoneRow ' + (completed ? ' meetingProjectListRow-milestoneRow--completed ' : ' meetingProjectListRow-milestoneRow--incomplete ')}>	
	  		<div className='meetingProjectListRow-milestoneRow-iconContainer'>
					<LinearMilestoneIcon 
						milestone={milestone}
					/>
				</div>
				<div  className='meetingProjectListRow-milestoneRow-name'>
					<button onClick={this.handleRowClick} className='meetingProjectListRow-milestoneRow-name-name'>
						{milestone.name}
					</button>
	  			<span className='meetingProjectListRow-milestoneRow-name-milestoneStats'>
	  				 
	  					<React.Fragment>
			  				<span className='meetingProjectListRow-milestoneRow-name-milestoneStats-progress'>
									{Math.floor(milestone.progressPercentage)}% 						
								</span>
								<span className='meetingProjectListRow-milestoneRow-name-milestoneStats-dash'>
									- 
								</span>
								<span className='meetingProjectListRow-milestoneRow-name-milestoneStats-total'>
									{milestone.scope}
								</span>
							</React.Fragment>
						
					</span>
				</div>
			</div>
	  )
	}
}


class DocEditorMeetingContentProjectListRowMilestones extends React.Component{  	
	
	render(){	
		const {projectId}=this.props
		const projectMilestoneDocs=getProjectMilestoneDocs(projectId)
		if(projectMilestoneDocs && projectMilestoneDocs.length>0){
			return (
		  	<div className='meetingProjectListRow-milestonesContainer'>
		  		<div className='meetingProjectListRow-milestonesContainer-line' />
					{projectMilestoneDocs.map((milestoneDoc)=>{
						const milestoneObj=milestoneDoc.milestoneObj
						return(
							<DocEditorMeetingContentProjectListRowMilestoneRow 
								key={milestoneDoc.doc_id}
								docId={milestoneDoc.doc_id}
								milestone={milestoneObj}		
								history={this.props.history}	
							/>
							)
					})}
				</div>
	  )
		}else return null
	}
}
export default withRouter(DocEditorMeetingContentProjectListRowMilestones)