import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_CONTACTS_SUCCESS':
	 	return action.response

	case 'CREATE_CONTACT_SUCCESS':
		const contactId=action.response.contact_id
		let index=findIndex(state,(contact => {
			return contact['contact_id'] === contactId
		}))
		if(index === -1){
			index=state.length
		}
		return [
			...state.slice(0,index),
			action.response,
			...state.slice(index + 1)
			]

	
	case 'ARCHIVE_CONTACT_REQUEST':
	case 'ARCHIVE_CONTACT_SUCCESS':
		const archivedContactId = action.contactId
		let archivedIndex = findIndex(state,(contact => {
			return contact['contact_id'] === archivedContactId
		}))
		if(archivedIndex>-1){
			return [
			...state.slice(0,archivedIndex),
			...state.slice(archivedIndex + 1)
			]
		}else return state

	case 'LOGOUT':
		return []
	
	default:
		return state
	
	}
}
