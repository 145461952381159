import React from 'react'
import Icon from '../../misc/Icon'
import { Spring, animated, config } from 'react-spring'

//on click toggle isChecked
//when isChecked changes set preAnimate:true
//after delay set preAnimate:false
//


const shortIds=[
	"DSK-231",
	"API-985",
	"MOB-453",
	"BE-120",
	"SEC-448",
	"GEN-553",
	"QSR-731",
	"MRM-845",
	"FRQ-453",
	"SLT-128",
	"MNP-208",
	"FQR-583",

]




const TIMEOUT_INTERVAL=100

class ToDoListCheckboxReactComponent extends React.PureComponent{  
	 constructor(props){
    super(props)
    this.handleWindowMouseUp=this.handleWindowMouseUp.bind(this)
    this.onMouseDown=this.onMouseDown.bind(this)
    this.onMouseUp=this.onMouseUp.bind(this)
    this.onStorage=this.onStorage.bind(this)
    let theme='light'
		const localStorageTheme = localStorage.getItem('theme')
		if(localStorageTheme){
			theme=localStorageTheme
		}
    this.state={
       preAnimate: false,
       mouseDown: false,
       isCheckedProp:props.isCheckedProp,
       theme:theme
    }
    this.timer=null
  }

  componentDidMount(){
  	window.addEventListener('mouseup', this.handleWindowMouseUp)
  	window.addEventListener('storage',this.onStorage)
  }

  componentDidUpdate(prevProps){
		if(prevProps.isCheckedProp !== this.props.isCheckedProp){
			this.setState({preAnimate:true})
			this.startTimeout()
		}
	}

	// 	componentDidUpdate(prevProps){
	// 	console.log('**************** did update')
	// 	console.log(prevProps)
	// 	console.log(this.props)
	// 	Object.entries(this.props).forEach(([key, val]) =>
 //  	  prevProps[key] !== val && console.log(`Prop '${key}' changed`)
 // 	 );
	// }


	componentWillUnmount(){
		if(this.timer){
			clearTimeout(this.timer)
		}
		window.removeEventListener('mouseup', this.handleWindowMouseUp)
		window.removeEventListener('storage', this.onStorage)
	}
  
  onStorage(event){
  	let theme='light'
		const localStorageTheme = localStorage.getItem('theme')
		if(localStorageTheme){
			theme=localStorageTheme
		}
		this.setState({theme:theme})
  }

  handleWindowMouseUp(){
  	if(this.state.mouseDown){
  		this.onMouseUp()
  		const {isCheckedProp}=this.props
  		let isChecked=false
			if(isCheckedProp && isCheckedProp=='true' || isCheckedProp==true){
				isChecked=true
			}
  		this.props.toggleIsChecked(!isChecked)
  	}
  }

	startTimeout(){
		if(this.timer){
			clearTimeout(this.timer)
		}
		this.timer=setTimeout(function() {
			this.setState({preAnimate:false})
		}.bind(this), TIMEOUT_INTERVAL)
  }

  onMouseDown(e){
		this.setState({preAnimate: true})
		this.setState({mouseDown: true})
	}

	onMouseUp(e){
		this.setState({preAnimate: false})
		this.setState({mouseDown: false})
	}

	render(){ 
		const {isCheckedProp, toggleIsChecked} = this.props
		const {preAnimate, mouseDown} = this.state	

		let isChecked=false
		if(isCheckedProp && isCheckedProp=='true' || isCheckedProp==true){
			isChecked=true
		}

		const holdCheck = !isChecked && preAnimate && mouseDown
		const clickCheck = isChecked && preAnimate && !mouseDown

		const holdUncheck =  isChecked && preAnimate && mouseDown
		const clickUncheck = !isChecked && preAnimate && !mouseDown

		const outlineActive = (clickCheck || holdCheck)

		let checkboxConfig
		let checkboxScaleConfig

		if(holdCheck){
			checkboxConfig ={
				tension: 170, friction: 30
			}	
			checkboxScaleConfig={
				tension: 200, friction: 14
			}
		}
		if(clickCheck){
			checkboxConfig ={
				tension: 400, friction: 30
			}
			checkboxScaleConfig={
				tension: 180, friction: 25
			}
		}
		if(holdUncheck){
			checkboxConfig ={
				tension: 300, friction: 30
			}
			checkboxScaleConfig={
				tension: 200, friction: 14
			}
		}
		if(clickUncheck){
			checkboxConfig ={
				tension: 600, friction: 30
			}
			checkboxScaleConfig={
				tension: 180, friction: 25
			}
		}

		//
		// COLORS AND THEMES

		let uncheckedBGCheckbox = 'rgb(255,255,255)'
		let uncheckedBorderCheckbox = 'rgb(181, 181, 192)'
		let checkedBGCheckbox = 'rgb(24, 122, 242)'
		let checkedBorderCheckbox = 'rgb(0, 103, 230)'

		// let checkedBGCheckbox = 'rgb(135, 135, 145)'
		// let checkedBorderCheckbox = 'rgb(90, 90, 105)'

		if(this.state.theme === 'dark'){
			uncheckedBGCheckbox = 'rgb(28,26,32)'
			uncheckedBorderCheckbox = 'rgb(255,255,255,0.25)'
			//checkedBGCheckbox = 'rgb(24, 122, 242)'
			checkedBGCheckbox = 'rgb(111, 173, 249)'
			checkedBorderCheckbox = 'rgb(97, 152, 220)'
		}


		const shortId = shortIds[Math.floor(Math.random()*shortIds.length)];



		return (			
			<React.Fragment>
				<Spring config={checkboxScaleConfig} transform={preAnimate ? 'scale(1.18)' : 'scale(1)'} >
					{checkboxContainerStyles =>
						<animated.div style={checkboxContainerStyles}  onMouseDown={()=>{this.onMouseDown()}} onMouseUp={()=>{this.onMouseUp()}} onClick={()=>{this.props.toggleIsChecked(!isChecked)}} className='doc-toDoItem-checkboxContainer'>
							<Spring 
								config={checkboxConfig}
								backgroundColor={isChecked ? checkedBGCheckbox : uncheckedBGCheckbox}
								borderColor={isChecked ? checkedBorderCheckbox : uncheckedBorderCheckbox}
							>
							{checkboxStyles => 
								<animated.div style={checkboxStyles} className={'doc-toDoItem-checkbox ' + (isChecked ? ' doc-toDoItem-checkbox--state--checked ' : ' doc-toDoItem-checkbox--state--unchecked ')}>		                	
									<Icon name='toDoItemCheckmark' /> 
								</animated.div>
							}
						</Spring>
						<Spring 						  		
							opacity={outlineActive ? 0.5 : 0} 
							transform={outlineActive ? 'scale(1.2)' : 'scale(1)'} 
						>
							{checkboxOutlineStyles => 
								<animated.div style={checkboxOutlineStyles} className='doc-toDoItem-checkbox-outline' />				                	
							}
						</Spring>
						<div className={'doc-toDoItem-checkboxTooltip ' + (mouseDown ? ' doc-toDoItem-checkboxTooltip--forceHide ' : '')}>
							<div className='doc-toDoItem-checkboxTooltip-iconContainer'>
								<Icon name='keyCommand'/> 
							</div>
							<div className='doc-toDoItem-checkboxTooltip-label'>
								Enter
							</div>
						</div>
					</animated.div>
				}
				</Spring>
				<Spring config={preAnimate ? {tension: 800, friction: 30} : {tension: 170, friction: 26}} opacity={preAnimate ? 1 : 0} transform={preAnimate ? 'scale(1)' : 'scale(0.999)'} >
					{labelStyles => 
						<animated.div style={labelStyles} className='doc-toDoItem-checkboxOuterContainer-mouseDownBG' />
					}
				</Spring>

				{/*}
				<div className='doc-toDoItem-shortIdContainer'>
					<div className='doc-toDoItem-shortId'>
	            	{shortId}
	            </div>
	         </div>
				{/*}
				<Spring config={isChecked ? config.slow : config.stiff} delay={isChecked ? 100 : 0} opacity={isChecked ? 0.6 : 0} >
				{specialStyles => 
				<animated.div style={specialStyles} className='doc-toDoItem-checkboxOuterContainer-checkedOpacityFake' />
				}
				</Spring>
				*/}
			</React.Fragment>  
		)
	}
}

export default ToDoListCheckboxReactComponent