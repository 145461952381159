
//https://github.com/ProseMirror/prosemirror-example-setup/blob/master/src/inputrules.js
import {inputRules, wrappingInputRule, textblockTypeInputRule,
        smartQuotes, emDash, ellipsis} from "prosemirror-inputrules"

// import {inlineCodeInputRule} from './inlineCodeInputRule'
import {arrowInputRule} from '../../plugins/inputRules/arrowInputRule'
import {mentionInputRule} from './mentionInputRule'
// import {dashInputRule} from './dashInputRule'
// import {horizontalLineInputRule} from './horizontalLineInputRule'
// import {blockquoteInputRule} from './blockquoteInputRule'

// : (NodeType) → InputRule
// Given a blockquote node type, returns an input rule that turns `"> "`
// at the start of a textblock into a blockquote.
// function blockQuoteRule(nodeType) {
//   return wrappingInputRule(/^\s*>\s$/, nodeType)
// }


function codeBlockRule(nodeType) {
  return textblockTypeInputRule(/^```$/, nodeType)
}

// // : (NodeType) → InputRule
// // Given a list node type, returns an input rule that turns a bullet
// // (dash, plush, or asterisk) at the start of a textblock into a
// // bullet list.
// function bulletListRule(nodeType) {
//   return wrappingInputRule(/^\s*([-+*])\s$/, nodeType)
// }

// // : (NodeType) → InputRule
// // Given a code block node type, returns an input rule that turns a
// // textblock starting with three backticks into a code block.
// function codeBlockRule(nodeType) {
//   return textblockTypeInputRule(/^```$/, nodeType)
// }


// : (Schema) → Plugin
// A set of input rules for creating the basic block quotes, lists,
// code blocks, and heading.
export function buildInputRules(schema) {
  let rules=[]
  //let rules = smartQuotes.concat(ellipsis, emDash)
 // rules.push(arrowInputRule()) 
  //rules.push(mentionInputRule())
  // rules.push(blockquoteInputRule())



  return inputRules({rules:rules})
}