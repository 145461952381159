import React from 'react'
import Icon from '../misc/Icon'
import { connect } from 'react-redux'
import * as Tooltip from '@radix-ui/react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';
import { RecordingsContext } from '../../containers/RecordingsProvider'
import {cancelRecordingUpload} from '../../utils/meetings/cancelRecordingUpload'

class DocEditorTitleRecordingIndicator extends React.Component{  

  constructor(props){
    super(props) 
    this.cancelUpload=this.cancelUpload.bind(this)  

  }

  cancelUpload(){
    cancelRecordingUpload(this.props.recording,this.context)
  }


	render(){	    
		    
    const {recording,activeUploads}=this.props


    const {mux_status,transcript_status}=recording
    let uploadState
    if(mux_status=='waiting_for_upload'){
      uploadState='uploading'
    }else if(mux_status=='created'){
      uploadState='processing'
    }else if(mux_status=='ready'){
      if(transcript_status=='ready'){
        uploadState='ready'
      }else{
        uploadState='processing'
      }
    }    
    let progress = 100
    if(activeUploads && activeUploads[recording.upload_id]){
      progress=activeUploads[recording.upload_id].progress
    }

	  return (	  	
      <div className='docEditor-docTitle-recordingIndicator'>

        {uploadState === 'uploading' &&         
          <div className='docEditor-docTitle-recordingIndicator-uploading'> 
            <button onClick={this.cancelUpload} className='docEditor-docTitle-recordingIndicator-uploading-cancelButton'>
              <Icon name='crossSemibold' />
            </button>
            <div className='docEditor-docTitle-recordingIndicator-uploading-progressBar'>
              <CircularProgressbar 
                value={progress}
                strokeWidth={8}
              />
              <div className='docEditor-docTitle-recordingIndicator-uploading-progressBar-iconContainer'>
                <Icon name='upArrowSemibold' />
              </div>              
            </div>
          </div>
        }


        {uploadState === 'processing' && 
          <Tooltip.Provider>
            <Tooltip.Root delayDuration={100}>
              <Tooltip.Trigger asChild>                                                 
                <div className='docEditor-docTitle-recordingIndicator-processing'>            
                  <div className='docEditor-docTitle-recordingIndicator-processing-spinner'/>            
                </div>                 
              </Tooltip.Trigger>        
              <Tooltip.Content side="bottom" sideOffset={2} className="tooltip tooltip--textSelectionUI">                                                                  
                Processing Transcript
              </Tooltip.Content>                          
            </Tooltip.Root>
          </Tooltip.Provider>

        }

        
        {uploadState === 'ready' && 
          <Tooltip.Provider>
            <Tooltip.Root delayDuration={1200}>
              <Tooltip.Trigger asChild>                                 
                <button onClick={this.props.openRecordingModal} className='docEditor-docTitle-recordingIndicator-playBtn'>
                  <Icon name='playRectangle' />
                </button>                     
              </Tooltip.Trigger>        
              <Tooltip.Content side="bottom" sideOffset={-2} className="tooltip tooltip--textSelectionUI">                                                                  
                Play Call Recording
              </Tooltip.Content>                          
            </Tooltip.Root>
          </Tooltip.Provider>
        }
      	
      </div>
	  )
	}
}


function mapStateToProps(state,ownProps) {
  return {
    activeUploads:state.activeUploads
  }
}

DocEditorTitleRecordingIndicator.contextType = RecordingsContext;

export default connect(mapStateToProps,
  {}
)(DocEditorTitleRecordingIndicator)


