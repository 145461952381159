import React from 'react'
import Icon from './Icon'
import * as ContextMenu from '@radix-ui/react-context-menu';

class ContextMenuCheckboxItem extends React.Component{  	

	constructor(){
    super()       
    this.handleContextMenuItemClick=this.handleContextMenuItemClick.bind(this)
    this.state={
    
    }
  }

	handleContextMenuItemClick(e){		
		e.preventDefault()
		this.props.onClickFunction()
	}

	render(){						

		const {label, onClickFunction, checked} = this.props
		
	  return (	  		  	
			<ContextMenu.Item data-is-checked={checked ? true : false} className='contextMenu-item--checkboxItem' onClick={this.handleContextMenuItemClick}>
				

				<div className='contextMenu-item-label'>{label}</div>

				<div className='contextMenu-item--checkboxItem-checkboxContainer'>
					<div className='contextMenu-item--checkboxItem-checkbox'>						
						<Icon name='toDoItemCheckmark' />						
					</div>
				</div>
			</ContextMenu.Item>												
	  )
	}
}

export default ContextMenuCheckboxItem


