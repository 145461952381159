import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import DocEditorCreateTemplatePopoverContents from './DocEditorCreateTemplatePopoverContents'

class DocEditorCreateTemplatePopover extends React.Component{  	

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)		
		this.state={
      forceMount: false,      
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }

	render(){	
		const {layout, showPopover, openPopover, closePopover} = this.props
		const {forceMount} = this.state
		
		const popoverSide = "bottom"
		const popoverSideOffset= 4
		const popoverAlign = "middle"
		const popoverAlignOffset = 0

		let transformStart = 'translateY(4px) scale(0.95)'
		let transformEnd = 'translateY(0px) scale(1)'
		let transformLeave = 'translateY(10px) scale(1)'		
		
		return (
			<React.Fragment>
				<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>					
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'docEditor-createTemplatePopoverContainer ' + (showPopover ? ' docEditor-createTemplatePopoverContainer--visible ' : ' docEditor-createTemplatePopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							//onPointerDownOutside={closePopover}
							//onInteractOutside={closePopover}							
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `${transformStart}` }}
				        enter={{ opacity: 1, transform: `${transformEnd}`  }}
				        leave={{ opacity: 0, transform: `${transformLeave}`  }}
								reverse={showPopover}
								config={{tension: 700, friction: 36}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="docEditor-createTemplatePopover popover"> 	
										<DocEditorCreateTemplatePopoverContents 
											docId={this.props.docId}
											closePopover={closePopover}
										/>
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			</React.Fragment>
		)
	}
}

export default DocEditorCreateTemplatePopover