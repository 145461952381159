import React from 'react'
import SideMenuHeader from './SideMenuHeader'
import SideMenuTabSections from './SideMenuTabSections'
import SideMenuFooter from './footer/SideMenuFooter'

class SideMenu extends React.Component{  

	render(){				
		const {sideMenuVisible,toggleSideMenuVisible,pathname,docs} = this.props	
	  return (
			<div className='sideMenu'>							
				<SideMenuHeader					
					toggleSideMenuVisible={toggleSideMenuVisible}
					sideMenuVisible={sideMenuVisible}					
					navigateTo={this.props.navigateTo}
					logout={this.props.logout}					
				/>	
				<SideMenuTabSections
					navigateTo={this.props.navigateTo}
					organization={this.props.organization}
					pathname={this.props.pathname}
					docPresenceMembers={this.props.docPresenceMembers}	
					openOpenDocMenu={this.props.openOpenDocMenu}			
					doc={this.props.doc}
					org={this.props.organization}
					docs={docs}
					recentActivities={this.props.recentActivities}
					teams={this.props.teams}
					messageCount={this.props.messageCount}//to trigger notification count update when recieve message
					toggleKeyboardShortcutsPanel={this.props.toggleKeyboardShortcutsPanel}

					meetings={this.props.meetings}

				/>
				<SideMenuFooter 
					docPresenceMembers={this.props.docPresenceMembers}
					pathname={this.props.pathname}
					doc={this.props.doc}
					orgMembers={this.props.orgMembers}
					org={this.props.organization}
					roadmap={this.props.roadmap}
				/>
			</div>
	  )
	}
}

export default SideMenu 