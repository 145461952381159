import React from 'react'
import Icon from '../misc/Icon'


class TemplateModalStarterTemplateDescriptions extends React.Component{  	
	

  constructor(props) {
    super(props)        
    this.state = {
   	};
  }


  openLink(url){
    if(window.isElectron){ 
      window.ipcRenderer.send('open-external-link',{url:url})     
    }else{
      window.open(url,'_blank')
    }
  }


	render(){	
		const {templateName} = this.props 		
		
		{/* <span className='templateModal-details-descriptionTextArea-light'>Adapted from Linear CEO Karri Saarinen’s <span className='templateModal-details-descriptionTextArea-link'>Product Management Best Practices</span> */}

	  return (
		  <React.Fragment>
		  	

		  	{templateName === 'Stripe\'s Operating Principles' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			  Stripe wrote their operating principles the year they were founded. They scaled decision-making across engineering, design, product, process, and hiring as they grew from 5 to 500 employees. <span onClick={()=>{this.openLink('https://review.firstround.com/to-grow-faster-hit-pause-and-ask-these-questions-from-stripes-coo')}} className='templateModal-details-descriptionTextArea-link templateModal-details-descriptionTextArea-light'>Learn more about how the principles defined Stripe's culture.</span>
				  </div>
				}		
		  			 

		  	{templateName === '\"We don\'t sell saddles here\"' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			  CEO Stewart Butterfield shared this memo before Slack launched. It explains why Slack exists, how to talk to people about a new category, how they will change their users' behaviour, and why an insanely high bar for implementation is essential to get there.		  			 
				  </div>
				}		

		  	{templateName === '\"Ten things we know to be true\"' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			 Google’s early obsession with users and product defined a new era in Silicon Valley culture. This is the 2006 version of the essay from their founders. At the time, it truly encoded their culture and defined their values as they hyper-scaled.
				  </div>
				}		

				{templateName === 'Technical Spec' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			 As a remote team, Mixpanel relies on technical specs alongside their roadmaps and meeting notes to coordinate and ship. A light, fast artifact for engineering-heavy projects. <span onClick={()=>{this.openLink('https://www.notion.so/customers/mixpanel')}} className='templateModal-details-descriptionTextArea-link templateModal-details-descriptionTextArea-light'>Learn more about how Mixpanel approaches remote teams communication.</span>
				  </div>
				}		

				{templateName === 'RFC' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			 For substantial projects, the React.js team use this RFC help them discuss, refine, and communicate complex technical decisions. <span onClick={()=>{this.openLink('https://github.com/reactjs/rfcs')}} className='templateModal-details-descriptionTextArea-link templateModal-details-descriptionTextArea-light'>Learn more about when to use RFCs, their lifecycle, and the process for reviewing them.</span>
				  </div>
				}		

				{templateName === 'Service RFC' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			 Uber has always used a lightweight RFC process to discuss service and backend changes and to help them ship at lightspeed. The RFC itself is customized to the needs of each team, and is constantly being iterated. <span onClick={()=>{this.openLink('https://blog.pragmaticengineer.com/scaling-engineering-teams-via-writing-things-down-rfcs/')}} className='templateModal-details-descriptionTextArea-link templateModal-details-descriptionTextArea-light'>Learn more RFCs at Uber.</span>
				  </div>
				}		


				{templateName === 'PRD' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			 When you're delivering a complex feature, Square's PRD helps you think intentionally about what user problems your solving (and which you're not). Get cross-team buy-in and plan your launch sequence. Adapted from Kevin Yien's <span onClick={()=>{this.openLink('https://docs.google.com/document/d/1mEMDcHmtQ6twzNlpvF-9maNlAcezpWDtCnyIqWkODZs/edit')}} className='templateModal-details-descriptionTextArea-link templateModal-details-descriptionTextArea-light'> PRD template.</span>
				  </div>
				}		


				{templateName === 'PR/FAQ' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			 At Amazon, they start every project with the press release. Will this new product be compelling to customers? The PR/FAQ is refined, redrafted, and iterated based on team feedback. Only then do they start building. <span onClick={()=>{this.openLink('https://www.amazon.com/Working-Backwards-Insights-Stories-Secrets/dp/1250267595')}} className='templateModal-details-descriptionTextArea-link templateModal-details-descriptionTextArea-light'>Learn more about Amazon's PR/FAQ process.</span>
				  </div>
				}		
				
				{templateName === 'Enterprise PRD' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			Designed for large projects where you need alignment across multiple teams, Figma’s PRD carefully steps through problem alignment, exploring user flows and key features, and getting launch ready. <span onClick={()=>{this.openLink('https://coda.io/@yuhki/figmas-approach-to-product-requirement-docs')}} className='templateModal-details-descriptionTextArea-link templateModal-details-descriptionTextArea-light'>Learn more about Figma’s approach.</span>
				  </div>
				}		
				{templateName === 'User Persona' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			Start with the user. Dig into their motivations, clarify the ICP, find out what you don’t know about them yet, and know how to talk to them. <span className='templateModal-details-descriptionTextArea-light'>Adapted from Brie Wolfson's (ex-Figma/Stripe) User Persona doc.</span>
				  </div>
				}		
		  	{templateName === 'Competitor Battlecard' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			How you stack up against a competitor and how to talk to users about them. Clarify your own positioning and help teammates understand the competitive landscape better. <span className='templateModal-details-descriptionTextArea-light'>Adapted from Brie Wolfson's (ex-Figma/Stripe) Competitor Battlecard template.</span>
				  </div>
				}		
		  	{templateName === 'Incident Postmortem' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
		  			After infrastructure incidents, communicate concisely and precisely what happened, what caused it, and how you will improve and harden your infrastructure as a result. <span onClick={()=>{this.openLink('https://postmortems.pagerduty.com/')}} className='templateModal-details-descriptionTextArea-link templateModal-details-descriptionTextArea-light'>Learn more about postmortems and how to run them from the experts at PagerDuty.</span>
				  </div>
				}					  	
		  	{templateName === 'Product Brief' && 
		  		<div className='templateModal-details-descriptionTextArea'>	      			
				    Linear’s product briefs are written by the designers and engineers who will actually build the feature. For teams who want to build great products at speed. <span className='templateModal-details-descriptionTextArea-light'>Adapted from Linear CEO Karri Saarinen’s <span className='templateModal-details-descriptionTextArea-link'>Product Management Best Practices</span>.</span>
				  </div>
				}
	  	</React.Fragment>
	  )	  
	}
}

export default TemplateModalStarterTemplateDescriptions
