import React from 'react'
import Icon from '../../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as ContextMenu from '@radix-ui/react-context-menu';
import {CellSelection} from "prosemirror-tables";
import {getNodePos} from '../../../prosemirror/utils/getNodePos'
import {getAnchorNode} from '../../../prosemirror/utils/getAnchorNode'
import {addColumnBefore,addColumnAfter,addRowBefore,addRowAfter,deleteColumn,deleteRow,toggleHeaderColumn,toggleHeaderRow,TableMap} from 'prosemirror-tables'
import DocTableEditRowColBtn from './DocTableEditRowColBtn'
import { Spring, animated, config } from 'react-spring'
import { findParentNode } from 'prosemirror-utils';
import { ReplaceStep } from 'prosemirror-transform';
import { Slice, Fragment } from 'prosemirror-model';
import KeyboardShortcutTag from '../../misc/KeyboardShortcutTag'
import {maybeAddTableColumnAfter,maybeAddTableColumnBefore,maybeAddTableRowBefore,maybeAddTableRowAfter} from '../../../prosemirror/utils/commands/tableCommands'
import { Mixpanel } from '../../../Mixpanel'

// function selectCellsInRow(tableNode,rowIndex) {
//   let tr=window.view.state.tr
//   const tableMap=TableMap.get(tableNode)
//   const firstCellPos = tableMap.map[rowIndex * tableMap.width];
//   const lastCellPos = tableMap.map[(rowIndex + 1) * tableMap.width - 1];
//   const tableNodePos=getNodePos(tableNode)
//   tr.setSelection(new CellSelection(tr.doc.resolve(tableNodePos + firstCellPos + 1),
//                                     tr.doc.resolve(tableNodePos + lastCellPos + 1)));
//   window.view.dispatch(tr)
// }


// function selectCellsColumn(tableNode,columnIndex) {
//   let tr=window.view.state.tr
//   const tableMap = TableMap.get(tableNode);
//   const firstCellPos = tableMap.map[columnIndex];
//   const lastCellPos = tableMap.map[(tableMap.height - 1) * tableMap.width + columnIndex];

//   const tableNodePos=getNodePos(tableNode)
//   tr.setSelection(new CellSelection(tr.doc.resolve(tableNodePos + firstCellPos + 1),
//                                     tr.doc.resolve(tableNodePos + lastCellPos + 1)));
//   window.view.dispatch(tr)
// }

function clearCellContent(state){
    if (!(state.selection instanceof CellSelection)) return false;
   const { $from, $to,$anchorCell } = state.selection;
   let tr=state.tr;
   const schema=state.schema;
   const table = $anchorCell.node(-1);
   const tableStart = $anchorCell.start(-1);
   const tableMap = TableMap.get(table);
   const selection = state.selection;
   let offset = 0;  // keep track of the offset
   selection.forEachCell((node, pos) => {
      if(node.type.name=='table_cell'){
         let emptyCell = schema.nodes.table_cell.createAndFill();
         tr.replaceWith(pos + offset, pos + offset + node.nodeSize, emptyCell);
         // calculate the offset
         const diff = node.nodeSize - emptyCell.nodeSize;
         offset -= diff;
      }
      if(node.type.name=='table_header'){
         let emptyCell = schema.nodes.table_header.createAndFill();
         tr.replaceWith(pos + offset, pos + offset + node.nodeSize, emptyCell);
         // calculate the offset
         const diff = node.nodeSize - emptyCell.nodeSize;
         offset -= diff;
      }
   });
   window.view.dispatch(tr);
   Mixpanel.track('table_dropwdown_clear_content')
}



function duplicateRow(state, dispatch) {
  if (!(state.selection instanceof CellSelection)) return false;
	let tr=state.tr
	const { $anchorCell } = state.selection
	const table = $anchorCell.node(-1)
	const tableStart = $anchorCell.start(-1);
	const tableMap = TableMap.get(table)
	 	const cellIndex = tableMap.map.indexOf($anchorCell.pos - $anchorCell.start(-1));
	 	const rowIndex = Math.floor(cellIndex / tableMap.width);
	const rowStart = tableMap.map[rowIndex * tableMap.width];
	const rowEnd = tableMap.map[(rowIndex + 1) * tableMap.width - 1];
	const endCell = rowEnd !== undefined ? table.nodeAt(rowEnd) : table.lastChild;
  // Calculate the position of the row in the document
	const startPos = $anchorCell.start(-1) + rowStart;
	const endPos = $anchorCell.start(-1) + rowEnd + endCell.nodeSize;
  // Create a copy of the row
  const row = table.child(rowIndex);
  let cells=[]
	row.forEach((cellNode, offset) => {
		if (cellNode.type.spec.tableRole === "cell" || cellNode.type.spec.tableRole === "header_cell") {
			const newCell=cellNode.type.createAndFill(cellNode.attrs,cellNode.content)
			cells.push(newCell)
		}
	});
	const clonedRow = row.type.create(row.attrs, cells);
	tr.insert(rowStart+tableStart-1,clonedRow);
  window.view.dispatch(tr)
  Mixpanel.track('table_dropdown_duplicate_row')
}


// function duplicateColumn(state, dispatch) {
// 	if (!(state.selection instanceof CellSelection)) return false;
  
//   const { $from, $to,$anchorCell } = state.selection;
//   let tr=state.tr;
//   const schema=state.schema;
//   const table = $anchorCell.node(-1);
//   const tableStart = $anchorCell.start(-1);
//   const tableMap = TableMap.get(table);
//   const selection = state.selection;
//   let offset = 0;  // keep track of the offset
  
//   selection.forEachCell((node, pos) => {
//   	console.log(`selection cell- ${pos}`)
//     const cellType = node.type.name === 'table_header' ? schema.nodes.table_header : schema.nodes.table_cell;
// 		const newCell = cellType.createAndFill(node.attrs, node.content);
// 		// calculate the position for the new cell, considering the offset
// 		const newPos = tableMap.positionAt($anchorCell.index(-1), $anchorCell.index(-2) + 1 , table);
// 		tr.insert(tr.mapping.map(tableStart + newPos+offset), newCell);
// 		offset += newCell.nodeSize;
//    });
//    window.view.dispatch(tr);	
// }

function duplicateColumn(state, dispatch) {
    if (!(state.selection instanceof CellSelection)) return false;
  
    const { $from, $to, $anchorCell } = state.selection;
    let tr=state.tr;
    const schema=state.schema;
    const selection = state.selection;
  
    selection.forEachCell((node, pos) => {
        const cellType = node.type.name === 'table_header' ? schema.nodes.table_header : schema.nodes.table_cell;
        const newCell = cellType.createAndFill(node.attrs, node.content);
        const newPos = pos + node.nodeSize;
        tr.insert(tr.mapping.map(newPos), newCell);
    });

    // if (dispatch) {
        window.view.dispatch(tr);
        Mixpanel.track('table_dropdown_duplicate_column')
   // }
}


function toggleAlternatingRowBackground(){
  const state=window.view.state 
  const dispatch=window.view.dispatch
  let tr = state.tr  
  const {selection}=state 
  const {$from}=selection
  const anchorNode=getAnchorNode($from,'docTable')
  if(anchorNode){
    const nodePos=getNodePos(anchorNode)
    let attributes={...anchorNode.attrs}
    attributes.alternatingRowBG=!anchorNode.attrs.alternatingRowBG
    tr.setNodeMarkup(nodePos, null, attributes)
    window.view.dispatch(tr)
    Mixpanel.track('table_dropdown_toggle_alternating_background',{value: attributes.alternatingRowBG})
  }
}


class DocTableCellUI extends React.Component{  
	constructor(){
    super()   
    this.selectColumn=this.selectColumn.bind(this)       
    this.selectRow=this.selectRow.bind(this)     
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.closeDropdown=this.closeDropdown.bind(this)
    this.toggleAlternatingRowBackground=this.toggleAlternatingRowBackground.bind(this)
    this.handleContextMenuOpenChange=this.handleContextMenuOpenChange.bind(this)  
    this.state={      
      showRowDropdown: false,
      showColDropdown: false,
      mouseOverCol: false,
      mouseOverRow: false,
      contextMenuOpen:false
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)  
  }

  componentWillUnmount(){
    window.removeEventListener('keydown', this.handleKeyDown)  
  }

  closeDropdown(){
    this.setState({showRowDropdown:false,showColDropdown:false})
  }

  handleKeyDown(e){
    if(e.altKey && e.keyCode==38){ //option + up arrow
      if((this.state.contextMenuOpen && this.props.buttonType=='row') || this.state.showRowDropdown){
        maybeAddTableRowBefore(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    }
    else if(e.altKey && e.keyCode==40){ //option + down arrow
      if((this.state.contextMenuOpen && this.props.buttonType=='row')|| this.state.showRowDropdown){
        maybeAddTableRowAfter(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    }
    else if(e.altKey && e.keyCode==38){ //option + up arrow
      if((this.state.contextMenuOpen && this.props.buttonType=='row') || this.state.showRowDropdown){
        maybeAddTableRowBefore(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    }
    else if(e.altKey && e.keyCode==37){ //option + left arrow
      if((this.state.contextMenuOpen && this.props.buttonType=='column')|| this.state.showColDropdown){
        maybeAddTableColumnBefore(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    }
    else if(e.altKey && e.keyCode==39){ //option + right arrow
      if((this.state.contextMenuOpen && this.props.buttonType=='column')|| this.state.showColDropdown){
        maybeAddTableColumnAfter(window.view.state,window.view.dispatch,window.view)
        this.closeDropdown()
      }
    } 
  }

  handleContextMenuOpenChange(openState){
   this.setState({contextMenuOpen:openState})
    if(openState){
      if(this.props.buttonType=='row'){
        this.selectRow()
      }else{
        this.selectColumn()
      }
    }
  }

  selectColumn(e, shouldOpenDropdown = false){
    if(e){
      e.stopPropagation()
      e.preventDefault()
    }
    this.props.selectColumn()
		if(shouldOpenDropdown){
			this.setState({showColDropdown: true})	
		}		
  }   

  selectRow(e, shouldOpenDropdown = false){
    if(e){
      e.stopPropagation()
      e.preventDefault()
    }
    this.props.selectRow()
		if(shouldOpenDropdown){
			this.setState({showRowDropdown: true})
		}
  }

  clearContents(e,type){
    // e.stopPropagation()
    // e.preventDefault()
    clearCellContent(window.view.state,window.view.dispatch)
    this.closeDropdown()
  }

  addBefore(e,type){
  	// e.stopPropagation()
  	// e.preventDefault()
  	if(type=='column'){
  		addColumnBefore(window.view.state,window.view.dispatch)
  	}
  	else if(type=='row'){
  		addRowBefore(window.view.state,window.view.dispatch)
  	}
    this.closeDropdown()
  }

	addAfter(e,type){
  	// e.stopPropagation()
  	// e.preventDefault()
  	if(type=='column'){
  		addColumnAfter(window.view.state,window.view.dispatch)
  	}else if(type=='row'){
  		addRowAfter(window.view.state,window.view.dispatch)
  	}
    this.closeDropdown()
  }

  deleteCells(e,type){
  	// e.stopPropagation()
  	// e.preventDefault()
  	if(type=='column'){
  		deleteColumn(window.view.state,window.view.dispatch)
      this.setState({showColumnDropdown: false})
  	}
  	else if(type=='row'){
      deleteRow(window.view.state,window.view.dispatch)
      this.setState({showRowDropdown: false})
  	}
    this.closeDropdown()
  }


  toggleHeader(e,type){
  	//e.preventDefault()
  	if(type=='column'){
      Mixpanel.track('table_dropdown_toggle_header_column')
  		toggleHeaderColumn(window.view.state,window.view.dispatch)
  	}
  	else if(type=='row'){
      Mixpanel.track('table_dropdown_toggle_header_row')
  		toggleHeaderRow(window.view.state,window.view.dispatch)
  	}
    this.closeDropdown()
  }

  duplicateCells(e,type){
  	// e.stopPropagation()
  	// e.preventDefault()
  	if(type=='column'){
  		duplicateColumn(window.view.state)
  	}
  	else if(type=='row'){
  		duplicateRow(window.view.state)
  	}
    this.closeDropdown()
  }

  toggleAlternatingRowBackground(){
    toggleAlternatingRowBackground()
    this.closeDropdown()
  }

	render(){
    const {buttonType}=this.props //row or column
		const {showRowDropdown, showColDropdown, mouseOverRow, mouseOverCol} = this.state
		const showLargeColBtn = mouseOverCol || showColDropdown
		const showLargeRowBtn = mouseOverRow || showRowDropdown
   // console.log(`table cell ui----- type ${buttonType}`)
   // if(buttonType=='column'){
   //   console.log(`colNumber----- ${this.props.colNumber}`)
   // }
  
	  if(buttonType=='row'){
       return (
        <React.Fragment>
          <ContextMenu.Root onOpenChange={(openState)=>{this.handleContextMenuOpenChange(openState)}}>  
            <ContextMenu.Trigger>              
              <DropdownMenu.Root modal={false} open={this.state.showRowDropdown}>
                <DropdownMenu.Trigger asChild>
                  <div onClick={(e)=>this.selectRow(e, true)} className='doc-table-editRowColBtn doc-table-editRowColBtn--row'>                    
                    <div className='doc-table-editRowColBtn-dropdownBtn' />
                  </div>
                </DropdownMenu.Trigger>           
                <DropdownMenu.Content 
                  sideOffset={1}
                  side="left"
                  sideOffset={-12}
                  align="start"
                  alignOffset={-24}
                  className='dropdownMenu'
                  onEscapeKeyDown={()=>{this.setState({showRowDropdown: false})}}
                  onPointerDownOutside={()=>{this.setState({showRowDropdown: false})}}
                  onInteractOutside={()=>{this.setState({showRowDropdown: false})}}
                  collisionPadding={36}
                >                                            
                  <DropdownMenu.Item onSelect={(e)=>{this.addBefore(e,'row')}}>                
                    Add Above
                    <div className='dropdownMenu-item-keyboardShortcutContainer'>
                       <KeyboardShortcutTag              
                        option                  
                        iconName='arrowUp'
                      />
                    </div>
                  </DropdownMenu.Item>  
                  <DropdownMenu.Item onSelect={(e)=>{this.addAfter(e,'row')}}>                
                    Add Below
                    <div className='dropdownMenu-item-keyboardShortcutContainer'>
                       <KeyboardShortcutTag              
                        option                  
                        iconName='arrowDown'
                      />
                    </div>
                  </DropdownMenu.Item>
                  <DropdownMenu.Separator/>
                  {/*}
                  <DropdownMenu.Item onSelect={(e)=>{this.toggleHeader(e,'row')}}>
                    Toggle Header Row
                  </DropdownMenu.Item>                  
                  */}
                  <DropdownMenu.Item onSelect={(e)=>{this.duplicateCells(e,'row')}}>
                    Duplicate Row
                  </DropdownMenu.Item>                    
                  <DropdownMenu.Item onSelect={this.toggleAlternatingRowBackground}>
                    Alternate Row Highlight
                  </DropdownMenu.Item>
                  <ContextMenu.Separator/>
                  <DropdownMenu.Item onSelect={(e)=>{this.clearContents(e,'row')}}>
                    Clear Contents
                  </DropdownMenu.Item>  
                  <DropdownMenu.Item onSelect={(e)=>{this.deleteCells(e,'row')}}>
                    Delete Row
                  </DropdownMenu.Item>    
                </DropdownMenu.Content>
              </DropdownMenu.Root> 
          </ContextMenu.Trigger>
            <ContextMenu.Portal>
              <ContextMenu.Content collisionPadding={10} className='contextMenu'>                           
                <ContextMenu.Item onSelect={(e)=>{this.addBefore(e,'row')}}>                
                  Add Above
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                     <KeyboardShortcutTag              
                      option                  
                      iconName='arrowUp'
                    />
                  </div>
                </ContextMenu.Item>  
                <ContextMenu.Item onSelect={(e)=>{this.addAfter(e,'row')}}>                
                  Add Below
                  <div className='dropdownMenu-item-keyboardShortcutContainer'>
                     <KeyboardShortcutTag              
                      option                  
                      iconName='arrowDown'
                    />
                  </div>
                </ContextMenu.Item>
                <ContextMenu.Separator/>
                
                {/*}
                <ContextMenu.Item onSelect={(e)=>{this.toggleHeader(e,'row')}}>
                  Toggle Header Row
                </ContextMenu.Item>
                */}                
                
                <ContextMenu.Item onSelect={(e)=>{this.duplicateCells(e,'row')}}>
                  Duplicate Row
                </ContextMenu.Item>                  
                <ContextMenu.Item onSelect={this.toggleAlternatingRowBackground}>
                  Alternate Row Highlight
                </ContextMenu.Item>
                <ContextMenu.Separator/>
                <ContextMenu.Item onSelect={(e)=>{this.clearContents(e,'row')}}>
                  Clear Contents
                </ContextMenu.Item>  
                <ContextMenu.Item onSelect={(e)=>{this.deleteCells(e,'row')}}>
                  Delete Row
                </ContextMenu.Item>   
              </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>   

        </React.Fragment>
    )
}else{
   return (
        <React.Fragment>
          <ContextMenu.Root onOpenChange={(openState)=>{this.handleContextMenuOpenChange(openState)}}>  
            <ContextMenu.Trigger>              
              <DropdownMenu.Root modal={false} open={this.state.showColDropdown}>
                <DropdownMenu.Trigger asChild>              
                  <div onClick={(e)=>this.selectColumn(e, true)}  className='doc-table-editRowColBtn doc-table-editRowColBtn--col'>                    
                    <div className='doc-table-editRowColBtn-dropdownBtn'/ >                       
                  </div>
                </DropdownMenu.Trigger>           
                <DropdownMenu.Content                   
                  side="bottom"
                  sideOffset={-26}
                  align="end"
                  alignOffset={-190}
                  className='dropdownMenu'
                  onEscapeKeyDown={()=>{this.setState({showColDropdown: false})}}
                  onPointerDownOutside={()=>{this.setState({showColDropdown: false})}}
                  onInteractOutside={()=>{this.setState({showColDropdown: false})}}                  
                >                                            
                  <DropdownMenu.Item onSelect={(e)=>{this.addAfter(e,'column')}}>                
                    Add After
                    <div className='dropdownMenu-item-keyboardShortcutContainer'>
                       <KeyboardShortcutTag              
                        option                  
                        iconName='arrowRight'
                      />
                    </div>
                  </DropdownMenu.Item>  
                  <DropdownMenu.Item onSelect={(e)=>{this.addBefore(e,'column')}}>                
                    Add Before
                    <div className='dropdownMenu-item-keyboardShortcutContainer'>
                       <KeyboardShortcutTag              
                        option                  
                        iconName='arrowLeft'
                      />
                    </div>
                  </DropdownMenu.Item>  
                  <DropdownMenu.Separator/>
                  {/*}                  
                  <DropdownMenu.Item onSelect={(e)=>{this.toggleHeader(e,'column')}}>
                    Toggle Header Column
                  </DropdownMenu.Item>                  
                  */}
                  <DropdownMenu.Item onSelect={(e)=>{this.duplicateCells(e,'column')}}>
                    Duplicate Column
                  </DropdownMenu.Item>  
                  <DropdownMenu.Separator/>
                  <DropdownMenu.Item onSelect={(e)=>{this.clearContents(e,'column')}}>
                    Clear Contents
                  </DropdownMenu.Item>  
                  <DropdownMenu.Item onSelect={(e)=>{this.deleteCells(e,'column')}}>
                    Delete Column
                  </DropdownMenu.Item>  
                </DropdownMenu.Content>
              </DropdownMenu.Root>              
            </ContextMenu.Trigger>
            <ContextMenu.Portal>
              <ContextMenu.Content collisionPadding={10} className='contextMenu'>                           
                  <ContextMenu.Item onSelect={(e)=>{this.addAfter(e,'column')}}>                
                    Add After
                    <div className='dropdownMenu-item-keyboardShortcutContainer'>
                       <KeyboardShortcutTag              
                        option                  
                        iconName='arrowRight'
                      />
                    </div>
                  </ContextMenu.Item>  
                  <ContextMenu.Item onSelect={(e)=>{this.addBefore(e,'column')}}>                
                    Add Before
                    <div className='dropdownMenu-item-keyboardShortcutContainer'>
                       <KeyboardShortcutTag              
                        option                  
                        iconName='arrowLeft'
                      />
                    </div>
                  </ContextMenu.Item>  
                  <ContextMenu.Separator/>
                  {/*()}
                  <ContextMenu.Item onSelect={(e)=>{this.toggleHeader(e,'column')}}>
                    Toggle Header Column
                  </ContextMenu.Item>
                  */}
                  <ContextMenu.Item onSelect={(e)=>{this.duplicateCells(e,'column')}}>
                    Duplicate Column
                  </ContextMenu.Item>  
                  <ContextMenu.Separator/>
                  <ContextMenu.Item onSelect={(e)=>{this.clearContents(e,'column')}}>
                    Clear Contents
                  </ContextMenu.Item>  
                  <ContextMenu.Item onSelect={(e)=>{this.deleteCells(e,'column')}}>
                    Delete Column
                  </ContextMenu.Item>  
              </ContextMenu.Content>
          </ContextMenu.Portal>
        </ContextMenu.Root>   
      </React.Fragment>
    )
}
   
	}
}

export default DocTableCellUI
