import React from 'react'
import Icon from '../misc/Icon'
import FigmaStatusIcon from '../misc/FigmaStatusIcon'
import DocImageZoomedModalBtn from '../docEditor/mediaModals/DocImageZoomedModalBtn'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';


class FigmaViewZoomedUIStatusMenuBtn extends React.Component{  		

	render(){	
		const {active,status} = this.props
		

		let statusLabel = 'No status'
		if(status ==='concept'){
			statusLabel = 'Concept'
		}
		if(status ==='started'){
			statusLabel = 'In Progress'
		}
		if(status ==='proposed'){
			statusLabel = 'Proposed'
		}
		if(status ==='completed'){
			statusLabel = 'Done'
		}

		return (
			<button onClick={this.props.onClickFunction} className={'doc-zoomedFigmaModal-status-menu-btn ' + (active ? ' doc-zoomedFigmaModal-status-menu-btn--active ' : ' doc-zoomedFigmaModal-status-menu-btn--inactive ')}>
				
				{status && 
				<div className='doc-zoomedFigmaModal-status-menu-btn-iconContainer'>
					<FigmaStatusIcon status={status} />
				</div>		
				}		
				<div className='doc-zoomedFigmaModal-status-menu-btn-label'>
					{statusLabel}
				</div>
			</button>
		)
	}
}


class FigmaViewZoomedUIStatus extends React.Component{  	

	constructor(){
    super()       
    this.state={      
      
    }
  }

	render(){	
		const {status, changeStatus} = this.props		
		

		return (
			
				<div className='doc-zoomedFigmaModal-status-menuContainer forceDarkTheme'>
					<div className='doc-zoomedFigmaModal-status-menu'>
						<FigmaViewZoomedUIStatusMenuBtn 						
							status='concept'
							onClickFunction={()=>{this.props.changeStatus('concept')}}
							active={status === 'concept'}
						/>
						<FigmaViewZoomedUIStatusMenuBtn 						
							status='started'
							onClickFunction={()=>{this.props.changeStatus('started')}}
							active={status === 'started'}
						/>
						<FigmaViewZoomedUIStatusMenuBtn 						
							status='proposed'
							onClickFunction={()=>{this.props.changeStatus('proposed')}}
							active={status === 'proposed'}
						/>
						<FigmaViewZoomedUIStatusMenuBtn 						
							status='completed'
							onClickFunction={()=>{this.props.changeStatus('completed')}}
							active={status === 'completed'}
						/>
						<FigmaViewZoomedUIStatusMenuBtn 													
							onClickFunction={()=>{this.props.changeStatus(null)}}
							active={!status}
						/>
					</div>
				</div>				
		)
	}
}

export default FigmaViewZoomedUIStatus
