import store from '../../../../store'
import MiniSearch from 'minisearch'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import {getOrgMembers} from '../../../../utils/getOrgMembers'
import {getCurrentUser} from '../../../../utils/getCurrentUser'
import {isCurrentUser} from '../../../../utils/isCurrentUser'
import findIndex from 'lodash/findIndex'
import find from 'lodash/find'

//show 10 ppl if empty
//else show 5

export function getAssignSuggestions(searchTerm,isToDo,assignees){
	let suggestions=[]
	if(isToDo){
		let orgUsers=getOrgMembers()
		let users=[]
		const currentUser=getCurrentUser()
		if(searchTerm){
			const searchTermLowerCase=searchTerm.toLowerCase()
			const meString="me"//is search term matches "me" add current user to search results
			let includeCurrentUser=false
			if(meString.startsWith(searchTermLowerCase)){
				const meUser=currentUser
				users.push(meUser)
				includeCurrentUser=true
			}
			orgUsers.forEach((orgUser)=>{
				if((orgUser.display_name.toLowerCase()).startsWith(searchTermLowerCase)){
					if(!includeCurrentUser || includeCurrentUser && !isCurrentUser(orgUser.user_id)){ //prevent duplicate is already included in me
						users.push(orgUser) 
					}					
				}
			})
		}else{
			users=orgUsers
		}
		const sortedUsers= sortBy(users,function(user){
			return user.name.toString().toLowerCase()
		})
		const currentUserIndex=findIndex(sortedUsers,{user_id:currentUser.user_id})

		//put current user at the top
		let finalSorted=sortedUsers
		if(currentUserIndex>-1){
			finalSorted=[sortedUsers[currentUserIndex],...sortedUsers.slice(0,currentUserIndex),...sortedUsers.slice(currentUserIndex+1)]
		}
		//remove users already assigned
		let notAssigned=[]
		finalSorted.forEach((user)=>{
			if(!find(assignees,{user_id:user.user_id})){
				notAssigned.push(user)
			}
		})
		suggestions=notAssigned
	}
	if(searchTerm){
		suggestions= suggestions.slice(0,5)
	}else{
		suggestions= suggestions.slice(0,10)
	}

	return suggestions
}

