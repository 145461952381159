import React from 'react'
import Icon from '../misc/Icon'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

class RecordingPlaybackModalTranscriptSearchBar extends React.Component{  	

	 

	render(){	
	
		const {hasSearchTerm} = this.props

	  return (	  		  		
	  	<React.Fragment>


  				<div className='recordingPlaybackModal-transcript-header-searchBarContainer'>
  					<input 
  						id={'in-transcript-search-input'}
							placeholder='Search Transcript...'
							className='recordingPlaybackModal-transcript-header-searchBar'
							value={this.props.searchValue}
							onChange={this.props.searchOnChange}		
							onKeyDown={this.props.searchOnKeyDown}						
						/>
						{hasSearchTerm && 
							<div className={'recordingPlaybackModal-transcript-header-searchResultsLabel ' + (this.props.searchResultsCount === 0 ? ' recordingPlaybackModal-transcript-header-searchResultsLabel--noResults ' : '')}>
    						{this.props.searchInputResultsLabel}
    					</div>
						}
						<div className='recordingPlaybackModal-transcript-header-searchBarUI'>
							<div className='recordingPlaybackModal-transcript-header-searchBarUI-searchBarIcon'>
								<Icon name='magnifyingGlass' />
							</div>							
						</div>

						{hasSearchTerm &&
		  				<div className='recordingPlaybackModal-transcript-header-navigateBtnContainer'>
		  					<button disabled={this.props.searchResultsCount === 0} onClick={this.props.changeTranscriptSearchIndexUp} className='recordingPlaybackModal-transcript-header-navigateBtn recordingPlaybackModal-transcript-header-navigateBtn--up'>
		  						<Icon name='chevronUpMedium'/>
		  					</button>
		  					<button disabled={this.props.searchResultsCount === 0} onClick={this.props.changeTranscriptSearchIndexDown} className='recordingPlaybackModal-transcript-header-navigateBtn recordingPlaybackModal-transcript-header-navigateBtn--down'>
		  						<Icon name='chevronDownMedium'/>
		  					</button>
		  				</div>
	  				}

  				</div>
					
					{!hasSearchTerm &&
  				<div className='recordingPlaybackModal-transcript-header-endContainer'>
  				 {this.props.showResetAutoScrollButton &&
    				<button onClick={this.props.resetAutoScroll} className={'recordingPlaybackModal-transcript-header-autoScrollBtn ' + (this.props.showResetAutoScrollButton ? ' recordingPlaybackModal-transcript-header-autoScrollBtn--bold ' : ' recordingPlaybackModal-transcript-header-autoScrollBtn--light ' )}>
      				Auto Scroll
      			</button>
    			}
    			</div>
    			}

    	

  				

  				{hasSearchTerm &&
	  				<button onClick={this.props.handleTranscriptSearchDone} className='recordingPlaybackModal-transcript-header-doneSearchBtn'>
      				Done
      			</button>
    			}  			

    			
    			

	  	</React.Fragment>
			
	  )
	}
}

export default RecordingPlaybackModalTranscriptSearchBar