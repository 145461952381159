import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
// import {createMeeting} from '../actions/meetings'
import { connect } from 'react-redux'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import MeetingsPage from '../components/meetings/MeetingsPage'
import ReactGA from 'react-ga4'
import moment from 'moment'
import {createNewMeeting} from '../utils/meetings/createNewMeeting'
import {fetchGoogleCalEvents} from '../actions/googleIntegration'
// import {syncRecurringEvents} from '../actions/meetings'
//Private shows all docs not in a team or project

class MeetingsPageContainer extends Component {
	
	constructor(props){
		super(props)
		this.createMeeting=this.createMeeting.bind(this)
	}

	componentDidMount(){
		if(process.env.REACT_APP_ENV=='production'){
			const page=window.location.pathname
			ReactGA.send({ hitType: "pageview", page: {page}, title: "Meetings" });
		}
		this.props.fetchGoogleCalEvents()
	//	this.props.syncRecurringEvents()
	}

	createMeeting(type){
		createNewMeeting(type,this.props.history)
	}



	render() {
		return (				
			<div className={'meetingNotesPageContainer '  + (this.props.sideMenuVisible ? ' meetingNotesPageContainer--sideMenuState--visible ' : ' meetingNotesPageContainer--sideMenuState--hidden ') + (window.isElectron ? 'privateDocsPageContainer--electron' : 'privateDocsPageContainer--web')}>
				<MeetingsPage
					meetings={this.props.meetings}
					createMeeting={this.createMeeting}
					calendarEvents={this.props.calendarEvents}
					meetingsPageSettings={this.props.meetingsPageSettings}
				/>		
			</div>				
		)		
	}
}

function mapStateToProps(state,ownProps) {
	//const meetings=filter(state.meetings)
	
	const sortedMeetings = orderBy(state.meetings, [function (meeting) {
		const doc = find(state.docs, {'meeting': meeting.meeting_id});
		if (!doc) {
			return null;
		}
		const momentTime = moment(doc.updated_at);
		const rounded = momentTime.startOf('minute');
		return rounded;
	}], ['desc']);

	return {
		// meetings:sortBy(meetings,function(meeting){
		// 	return meeting["created_at"]
		// }).reverse(),
		meetings:sortedMeetings,
		calendarEvents:state.calendarEvents,
		meetingsPageSettings:state.meetingsPageSettings
	}
}

export default withRouter(connect(mapStateToProps,
	{	
		fetchGoogleCalEvents,
		
	}
)(MeetingsPageContainer))


	