import React from 'react'
import Icon from '../../../misc/Icon'
import { withRouter} from 'react-router-dom'
import LinearMarker from '../../../misc/LinearMarker'
import LinearProjectStatusIcon from '../../../misc/LinearProjectStatusIcon'
import AvatarImage from '../../../AvatarImage'
import {getUserForId} from '../../../../utils/getUserForId'
import {getTeamForId} from '../../../../utils/getTeamForId'
import {getRootDocIdForProject} from '../../../../utils/getRootDocIdForProject'
import {openDoc,backgroundOpenDoc} from '../../../../utils/openDoc'
import moment from 'moment'
import {getRoadmapsForProject} from '../../../../utils/getRoadmapsForProject'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as ContextMenu from '@radix-ui/react-context-menu';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import RoadmapsProjectRowContextMenuContents from '../../../roadmaps/RoadmapsProjectRowContextMenuContents'
import EditMetadataPopoverWrapper from '../../../popovers/EditMetadataPopoverWrapper'
import {updateProjectLead} from '../../../../utils/updateProject/updateProjectLead'
import {updateProject} from '../../../../utils/updateProject/updateProject'
import {updateProjectRoadmaps} from '../../../../utils/updateProject/updateProjectRoadmaps'
import {updateProjectTargetDate} from '../../../../utils/updateProject/updateProjectTargetDate'
import includes from 'lodash/includes'
import {updateProjectStatus} from '../../../../utils/updateProject/updateProjectStatus'
import {getProjectMilestoneDocs} from '../../../../utils/getProjectMilestoneDocs'
import { CircularProgressbar } from 'react-circular-progressbar';
import {calcProjectStats} from '../../../../utils/calcProjectStats'
import {archiveMeetingProjectConnection} from '../../../../utils/meetings/archiveMeetingProjectConnection'
import DocEditorMeetingContentProjectListRowMilestones from './DocEditorMeetingContentProjectListRowMilestones'

function formatTargetDateLabel(targetDate){
  const year = moment().startOf('isoYear')
  let formatedTimestamp
  if (moment(targetDate).isSame(year, 'y')) {
    formatedTimestamp=moment(targetDate).format('MMM DD')
  }else{
    formatedTimestamp=moment(targetDate).format('MMM YY')
  }
  return formatedTimestamp
}

class DocEditorMeetingContentProjectListRow extends React.Component{   

  constructor(props){
    super(props)
    this.handleProjectRowClick=this.handleProjectRowClick.bind(this)
    this.handleProjectStatusClick=this.handleProjectStatusClick.bind(this)
    this.handleProjectLeadClick=this.handleProjectLeadClick.bind(this)
    this.handleProjectRoadmapsClick=this.handleProjectRoadmapsClick.bind(this)
    this.handleProjectTargetDateClick=this.handleProjectTargetDateClick.bind(this)
    this.updateProjectLead=this.updateProjectLead.bind(this)
    this.handleOnOpenChange=this.handleOnOpenChange.bind(this)
    this.handleUpdateProjectMembers=this.handleUpdateProjectMembers.bind(this)
    this.handleUpdateProjectTeams=this.handleUpdateProjectTeams.bind(this)
    this.handleUpdateProjectRoadmaps=this.handleUpdateProjectRoadmaps.bind(this)
    this.updateProjectTargetDate=this.updateProjectTargetDate.bind(this)
    this.handleUpdateProjectStatus=this.handleUpdateProjectStatus.bind(this)
    this.handleCloseEditProjectRoadmapsPopover=this.handleCloseEditProjectRoadmapsPopover.bind(this)
    this.handleArchiveConnection=this.handleArchiveConnection.bind(this)
    this.handleToggleShowIssuePanel=this.handleToggleShowIssuePanel.bind(this)
    const project = props.project
    let teams=[]
    let members=[] 
    let roadmaps=[]
    let projectRoadmaps=[]
    if(project){
      members=project.members
      teams=project.teams
      roadmaps=getRoadmapsForProject(project.project_id)
      roadmaps.forEach((roadmap)=>{
        projectRoadmaps.push(roadmap.roadmap_id)
      })
    }
    this.state={    
      showEditProjectStatusPopover: false,
      showEditProjectTargetDatePopover: false,
      showEditProjectLeadPopover: false,
      showEditProjectRoadmapsPopover: false,
      teams:teams,
      members:members,
      roadmaps:projectRoadmaps,
      showToast:false
    }
  }

  handleProjectRowClick(e){
    const {project}=this.props
    const projectDocId=getRootDocIdForProject(project.project_id)
    if(e.metaKey){
      backgroundOpenDoc(projectDocId)
    }else{
      openDoc(this.props.history,projectDocId)
    }
  }

  handleProjectStatusClick(e){
    e.stopPropagation()
    this.setState({showEditProjectStatusPopover: true})
  }

  handleProjectLeadClick(e){
    e.stopPropagation()
    this.setState({showEditProjectLeadPopover: true})
  }

  updateProjectLead(lead){
    updateProjectLead(this.props.project.project_id,lead)
  }

  handleProjectRoadmapsClick(e){
    e.stopPropagation()
    this.setState({showEditProjectRoadmapsPopover: true})
  }

  handleProjectTargetDateClick(e){
    e.stopPropagation()
    this.setState({showEditProjectTargetDatePopover: true})
  }

  updateProjectTargetDate(targetDate,selcted,modifiers,e){
    e.preventDefault()
    e.stopPropagation()
    this.setState({showEditProjectTargetDatePopover:false})
    updateProjectTargetDate(this.props.project.project_id,targetDate)
  }

  handleUpdateProjectStatus(status){
    this.setState({showEditProjectStatusPopover:false})
    updateProjectStatus(this.props.project.project_id,status)
  }

  handleOnOpenChange(open){
    if(open){
      const {project}=this.props
      this.setState({teams:project.teams,members:project.members})
    }else{
      const oldProject=this.props.project
      if(oldProject.members != this.state.members || oldProject.teams!=this.state.teams){
        let newProject={...oldProject}
        newProject.members=this.state.members
        newProject.teams=this.state.teams
        const showToast=this.state.showToast
        updateProject(newProject,showToast)
      }else{
        //console.log('nothing changedd!')
      }
      //check roadmaps
      const {roadmaps}=this.state
      let roadmapsToRemove=[]
      let roadmapsToAdd=[]
      const origionalRoadmaps=getRoadmapsForProject(this.props.project.project_id)
      origionalRoadmaps.forEach((roadmap)=>{
        if(!includes(roadmaps,roadmap.roadmap_id)){
          roadmapsToRemove.push(roadmap.roadmap_id)
        }
      })
      roadmaps.forEach((roadmapId)=>{
        let isNewRoadmap=true
        origionalRoadmaps.forEach((roadmap)=>{
          if(roadmap.roadmap_id==roadmapId){
            isNewRoadmap=false
          }
        })
        if(isNewRoadmap){
          roadmapsToAdd.push(roadmapId)
        }
      })
      if(roadmapsToRemove.length || roadmapsToAdd.length){
        let actions=[]
        roadmapsToRemove.forEach((roadmapId)=>{
          actions.push({roadmapId:roadmapId,action:'remove'})
        })
        roadmapsToAdd.forEach((roadmapId)=>{
          actions.push({roadmapId:roadmapId,action:'add'})
        })
        const showToast=true
        updateProjectRoadmaps(this.props.project.project_id,actions,showToast)
        }
    }
  }

  handleUpdateProjectMembers(projectMembers){
    this.setState({members:projectMembers,showToast:true})
  }

  handleUpdateProjectTeams(teams){
    this.setState({teams:teams,showToast:true})
  }

  handleUpdateProjectRoadmaps(roadmaps){
    this.setState({roadmaps:roadmaps})
  }

  handleCloseEditProjectRoadmapsPopover(){
    this.setState({showEditProjectRoadmapsPopover:false})
    this.handleOnOpenChange(false)
  }

  handleArchiveConnection(e){
    e.preventDefault()
    e.stopPropagation()
    archiveMeetingProjectConnection(this.props.connectionId)
  }

  handleToggleShowIssuePanel(e){
    e.preventDefault()
    e.stopPropagation()
    const issuePanelActive = this.props.issuePanelProject && (this.props.issuePanelProject === this.props.project.project_id) && this.props.issuePanelIsVisible
    if(issuePanelActive){//close
      this.props.closeIssuePanel()
    }else{
      this.props.openIssuePanel(this.props.project.project_id)
    } 
  }

  

  render(){ 
    const {project, lastItemInGroup}=this.props
    const {showEditProjectStatusPopover, showEditProjectTargetDatePopover, showEditProjectLeadPopover, showEditProjectRoadmapsPopover} = this.state
    const {name,lead,teams,project_id,description,state}=project
    const user = getUserForId(lead)   
    let targetDateLabel
    let targetDateWarning = false // if target date is before today, and not completed
    if(project.target_date){
      targetDateLabel=formatTargetDateLabel(project.target_date)
      if(project.state !=='completed'){
        const today = moment().startOf('day'); 
        if(moment(project.target_date).isBefore(today)){
          targetDateWarning=true
        }
      }
    }

    //console.log(`lead: ${lead}`)
    const roadmaps = getRoadmapsForProject(project_id)
    let filteredRoadmaps=[]
    roadmaps.forEach((roadmap)=>{ //only show roadmap label for roadmaps that are not the current page
      if(roadmap.roadmap_id!=this.props.currentRoadmapId){
        filteredRoadmaps.push(roadmap)
      }
    })
    let showTargetDate = false
    if(state === 'started' || targetDateLabel){
      showTargetDate = true     
    }
    let projectStatusLabel = state    
    if(state === 'started'){
      projectStatusLabel = 'in progress'
    }
    const editMetadataPopoverOpen = showEditProjectStatusPopover || showEditProjectTargetDatePopover || showEditProjectLeadPopover || showEditProjectRoadmapsPopover

    let startedNoMilestones = false

    if(project && project.project_id && project.state === 'started' && this.props.hasStartedProjectsWithMilestones){
      const projectMilestoneDocs=getProjectMilestoneDocs(project.project_id)
      if(projectMilestoneDocs){
        startedNoMilestones = projectMilestoneDocs.length < 1
      }
    }
    

    //const projectStats=calcProjectStats(project.project_id)
    // new Linear data 
    const projectStats=calcProjectStats(project_id)
    // new Linear data 
    const linearProjectProgressPercentage = Math.floor(projectStats.progressPercentage)// rounding down to nearest % --TODO test against linear
    const linearProjectScope = projectStats.scope

    //onClick={this.handleProjectRowClick} 


    const issuePanelActive = this.props.issuePanelProject && (this.props.issuePanelProject === project_id) && this.props.issuePanelIsVisible
 

    return (
      <React.Fragment>
      <ContextMenu.Root key={project_id} onOpenChange={this.handleOnOpenChange}> 
        <ContextMenu.Trigger asChild>
          <div className={'meetingProjectListRow ' + (editMetadataPopoverOpen ? ' meetingProjectListRow--popoverOpen ' : ' ')}>      
            <EditMetadataPopoverWrapper
              showPopover={this.state.showEditProjectStatusPopover}           
              openPopover={()=>{this.setState({showEditProjectStatusPopover: true})}}
              closePopover={()=>{this.setState({showEditProjectStatusPopover: false})}}   
              type="projectStatus"  // used to load in popover contents
              layout="roadmapProjectRow" // used for positioning
              projectId={project_id}
              projectStatus={state} 
              updateProjectStatus={this.handleUpdateProjectStatus}
              portalled
            >
              <div className='meetingProjectListRow-statusCol'>
                <Tooltip.Provider>
                  <Tooltip.Root delayDuration={600}>
                    <Tooltip.Trigger asChild>                                                                     
                      <button onClick={this.handleProjectStatusClick} className={'meetingProjectListRow-status ' + (state ? ` meetingProjectListRow-status--state--${state} ` : '')}>
                        <div className='meetingProjectListRow-status-markerContainer'>      
                          <LinearProjectStatusIcon 
                            projectStatus={state}
                          />                  
                        </div>
                                              
                      </button>               
                    </Tooltip.Trigger>                          
                    <Tooltip.Content side="top" sideOffset={2} className="tooltip">                      
                      Edit status                      
                    </Tooltip.Content>  
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div> 
            </EditMetadataPopoverWrapper>
            
            
              
              <div className='meetingProjectListRow-nameCol'>     
                <button onClick={this.handleProjectRowClick} className='meetingProjectListRow-name'>
                  <div className='meetingProjectListRow-name-markerContainer'>
                    <LinearMarker projectId={project_id} />
                  </div>
                  <div className='meetingProjectListRow-name-label'>         
                    {name}
                  </div>
                </button>
              </div>
                                

              <div className='meetingProjectListRow-linearSummaryCol'>    
                <Tooltip.Provider>
                  <Tooltip.Root delayDuration={600}>
                    <Tooltip.Trigger asChild>                                               
                       <button onClick={this.handleToggleShowIssuePanel} data-state={issuePanelActive ? 'active' : 'inactive'}  className='meetingProjectListRow-linearSummary'>              
                        <div className='meetingProjectListRow-linearSummary-progressCircleContainer'>
                          <CircularProgressbar 
                            value={linearProjectProgressPercentage} 
                            strokeWidth={13}                  
                          />
                        </div>                
                        <div className='meetingProjectListRow-linearSummary-progressLabel'>
                          {linearProjectProgressPercentage}% <span>- {linearProjectScope}</span>
                        </div>     
                      </button>                                
                    </Tooltip.Trigger>    
                    {!issuePanelActive &&     
                      <Tooltip.Content side="top" sideOffset={2} className="tooltip tooltip--textSelectionUI">                                                                  
                        Preview project issues
                      </Tooltip.Content>                          
                    }
                  </Tooltip.Root>
                </Tooltip.Provider>
            </div>            
                  
            <EditMetadataPopoverWrapper
              showPopover={showEditProjectTargetDatePopover}            
              openPopover={()=>{this.setState({showEditProjectTargetDatePopover: true})}}
              closePopover={()=>{this.setState({showEditProjectTargetDatePopover: false})}}   
              type="projectTargetDate"  // used to load in popover contents
              layout="projectRootDocTopMenu" // used for positioning
              projectId={project_id}
              updateProjectTargetDate={this.updateProjectTargetDate}
              currentTargetDate={project.target_date}
              portalled
            >
              <div className='meetingProjectListRow-targetDateCol'>
                <Tooltip.Provider>
                  <Tooltip.Root delayDuration={600}>
                    <Tooltip.Trigger asChild>       
                      <button className={'meetingProjectListRow-targetDateContainer ' + (targetDateLabel ? ' roadmapsProjectRow-targetDateContainer--edit ' : ' roadmapsProjectRow-targetDateContainer--add ' )}>   
                        <div onClick={this.handleProjectTargetDateClick} className={'meetingProjectListRow-targetDate ' + (targetDateWarning ? ' meetingProjectListRow-targetDate--warning ' : ' ')}>
                                                    
                          <div className='meetingProjectListRow-targetDate-iconContainer'>
                            <Icon name='calendarSimple' />
                          </div>                          

                          {!targetDateLabel && 
                            <div className='meetingProjectListRow-targetDate-label'>
                              No target
                            </div>
                          }
                          {targetDateLabel && 
                            <div className='meetingProjectListRow-targetDate-label'>
                              {targetDateLabel}
                            </div>
                          }
                          
                        </div>           
                      </button>  
                    </Tooltip.Trigger>  
                    {!targetDateLabel &&         
                      <Tooltip.Content side="top" sideOffset={2} className="tooltip tooltip--textSelectionUI">                                                                  
                        Add target date
                      </Tooltip.Content>
                    }                                             
                    {targetDateLabel &&         
                      <Tooltip.Content side="top" sideOffset={2} className="tooltip tooltip--textSelectionUI">                                                                  
                        Edit target date
                      </Tooltip.Content>
                    }                                             
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
            </EditMetadataPopoverWrapper>   
          
            {this.props.linkedProject &&                   
              <Tooltip.Provider>
                <Tooltip.Root delayDuration={600}>
                  <Tooltip.Trigger asChild>       
                    <button onClick={this.handleArchiveConnection} className='meetingProjectListRow-removeBtn'>
                      <div className='meetingProjectListRow-removeBtn-inner'>
                        <Icon name='minus' />
                      </div>
                    </button>
                  </Tooltip.Trigger>                          
                  <Tooltip.Content side="right" sideOffset={0} className="tooltip tooltip--textSelectionUI">                                                                  
                    Unlink from project
                  </Tooltip.Content>
                </Tooltip.Root>
              </Tooltip.Provider>
            }
            


            
          {/* HIDE LEAD FOR NOW *
           <EditMetadataPopoverWrapper
                  showPopover={showEditProjectLeadPopover}            
                  openPopover={()=>{this.setState({showEditProjectLeadPopover: true})}}
                  closePopover={()=>{this.setState({showEditProjectLeadPopover: false})}}   
                  type="projectLead"  
                  updateProjectLead={this.updateProjectLead}
                  leadUserId={user.user_id}
                  layout="roadmapProjectRow"
                  projectId={this.props.projectId}  
                  portalled     
                >
                  <div className='meetingProjectListRow-leadAvatarCol'>
                    <Tooltip.Provider>
                      <Tooltip.Root delayDuration={400ead ? 150 : 0}>
                        <Tooltip.Trigger asChild>                                               
                          <div onClick={this.handleProjectLeadClick} className={'meetingProjectListRow-leadAvatarContainer ' + (lead ? ' roadmapsProjectRow-leadAvatarContainer--hasLead ' : ' roadmapsProjectRow-leadAvatarContainer--noLead ' )}>
                            {lead && 
                              <AvatarImage
                                className='meetingProjectListRow-leadAvatar'
                                avatarUrl={user.avatar}
                                smallAvatar
                              />
                            }
                            {!lead &&
                              <Icon name='userAdd' />
                            }
                          </div>
                        </Tooltip.Trigger>    
                        {lead &&    
                          <Tooltip.Content side="top" className="tooltip tooltip--projectLead">
                            <div className="tooltip--projectLead-avatarContainer">
                              <AvatarImage
                                className='tooltip--projectLead-avatar'
                                avatarUrl={user.avatar}
                              />
                            </div>
                            <div className="tooltip--projectLead-rightContainer forceDarkTheme">
                              <div className="tooltip--projectLead-displayName">
                                {user.display_name ? `${user.display_name}` : 'Unassigned'}
                              </div>
                              <div className="tooltip--projectLead-attribute">
                                Project Lead
                              </div>
                            </div>                
                          </Tooltip.Content>  
                        }   
                        {!lead &&     
                          <Tooltip.Content side="top" className="tooltip tooltip--textSelectionUI">
                            Add Project Lead
                          </Tooltip.Content>  
                        }                       
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  </div>
            </EditMetadataPopoverWrapper>
            */}
           </div>
        </ContextMenu.Trigger>
        <ContextMenu.Portal>
          <ContextMenu.Content collisionPadding={10} className='contextMenu'>                           
            <RoadmapsProjectRowContextMenuContents 
              openEditProjectModal={this.props.openEditProjectModal}
              handleUpdateProjectMembers={this.handleUpdateProjectMembers}
              handleUpdateProjectTeams={this.handleUpdateProjectTeams}
              projectMembers={this.state.members}
              projectTeams={this.state.teams}
              project={project}
              handleUpdateProjectRoadmaps={this.handleUpdateProjectRoadmaps}
              projectRoadmaps={this.state.roadmaps}
              removeFromMeeting={this.handleArchiveConnection} 
              toggleMeetingIssuePanel={this.handleToggleShowIssuePanel} 
              unlinkFromMeeting={this.handleArchiveConnection}
              issuePanelIsVisible={this.props.issuePanelIsVisible}
              isTeamSync={this.props.isTeamSync}
              isMeetingContent
            />            
          </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>  

    {state === 'started' && 
      <DocEditorMeetingContentProjectListRowMilestones 
        projectId={project.project_id}
      />
    }  

    </React.Fragment> 
    )
  }
}
export default withRouter(DocEditorMeetingContentProjectListRow)