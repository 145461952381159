import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import DocTable from '../docTable/DocTable'
import {getUserForId} from '../../utils/getUserForId'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import LinearMarker from '../misc/LinearMarker'
import LibraryDocPreview from './LibraryDocPreview'
import {isUserOnline} from '../../utils/isUserOnline'
import {openTeamInLinear} from '../../utils/openInLinear'
import {isCurrentUser} from '../../utils/isCurrentUser'
import moment from 'moment-timezone'
import sortBy from 'lodash/sortBy'

class MemberGridMember extends React.Component{  

	render(){	

		const {user} = this.props
				
		const isActive=isUserOnline(user.user_id)					  				
		const userIsCurrentUser=isCurrentUser(user.user_id)
		let localTime

    if(user.timezone){
      localTime=moment().tz(user.timezone).format('hh:mm A')
    }

    //
    // HARDCODED FOR DEMOING
    if(user.email === "jasper+ali@yarn.so"){
      localTime=moment().tz('America/Los_Angeles').format('hh:mm A')
    }

    if(user.email === "jasper+simon@yarn.so"){
      localTime=moment().tz('Europe/Berlin').format('hh:mm A')
    }

    if(user.email === "jasper+zoe@yarn.so"){
      localTime=moment().tz('America/Toronto').format('hh:mm A')
    }

    
	  return (
	  	<div className='memberGrid-memberContainer'>
		  	<div className='memberGrid-member'>
		  		<div className='memberGrid-member-avatarContainer'>
		  			<AvatarImage 
		  				className='memberGrid-member-avatar'
   						avatarUrl={user.avatar}
   						largeAvatar
		  			/>
		  			
		  			{!isActive &&
		  				<div className='memberGrid-member-statusIndicator memberGrid-member-statusIndicator--away' />
		  			}

		  			{isActive &&
		  				<div className='memberGrid-member-statusIndicator memberGrid-member-statusIndicator--active' />
		  			}
		  			

		  		</div>
					<div className='memberGrid-member-centerContainer'>	  		
						<div className='memberGrid-member-name'>	  		
							{user.display_name} {userIsCurrentUser && <span className='memberGrid-member-name-you'> you</span>}
						</div>
						<div className='memberGrid-member-time'>	  		
							{localTime} local time
						</div>
					</div>

		  		
		  	</div>
	  	</div>
	  )
	}
}

export default MemberGridMember