import React from 'react'
import Icon from '../../misc/Icon'
import ReactSlider from 'react-slider'
import VideoDuration from '../../../utils/videoDuration'
import DocVideoCommentRow from './DocVideoCommentRow'

class DocVideoInlineUIStartedControlsVolumeSlider extends React.Component{  
   
   constructor(){

    super()

    this.onMuteClick=this.onMuteClick.bind(this)    

    this.state={
      preMuteVolume: 1,
    }
  }

  onMuteClick(){    
    if(this.props.volume > 0){
      this.setState({preMuteVolume:this.props.volume})
      this.props.handleVolumeChange(0)
    }
    if(this.props.volume === 0){
      this.props.handleVolumeChange(this.state.preMuteVolume)
    }
  }
  
  render(){     
    const {handleVolumeChange, volume, handleVolumeMouseUp, handleVolumeMouseDown, adjustingVolume} = this.props
    const wave0limit = 0.25
    const wave1limit = 0.5
    const wave2limit = 0.75    
    return (  
                
        <div className={'doc-video-inlineUI-controlBar-controlRow-volumeSlider' + (adjustingVolume ? ' doc-video-inlineUI-controlBar-controlRow-volumeSlider--adjustingVolume ' : '') + (volume === 1 ? ' doc-video-inlineUI-controlBar-controlRow-volumeSlider--maxVolume ' : ' ')}>
          <button onClick={this.onMuteClick} className='doc-video-inlineUI-controlBar-controlRow-volumeSlider-speakerBtn'>
            
            {volume === 0 &&
              <Icon name='speaker-muted' />
            }
            {volume > 0 && volume <= wave0limit &&
              <Icon name='speaker-wave-0' />
            }
            {volume > wave0limit && volume <= wave1limit &&
              <Icon name='speaker-wave-1' />
            }
            {volume > wave1limit && volume <= wave2limit &&
              <Icon name='speaker-wave-2' />
            }
            {volume > wave2limit && volume <= 1 &&
              <Icon name='speaker-wave-3' />
            }
            
          </button>          
          <div className='doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBarContainer'>
            <ReactSlider
              className="doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar"
              thumbClassName="doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-thumb"
              trackClassName="doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-track"                  
              renderThumb={(props, state) => <div className='doc-video-inlineUI-controlBar-controlRow-volumeSlider-seekBar-thumb-thumb' {...props} />}
              value={volume}
              min={0}
              max={1}
              step={0.01}
              onAfterChange={handleVolumeMouseUp}
              onBeforeChange={handleVolumeMouseDown}
              onChange={handleVolumeChange}                    
            />
          </div>          
        </div>          
    )
  }
}




class DocVideoInlineUIStartedControls extends React.Component{  
	 constructor(){

    super()
    this.state={
      captionsActive:false
    }
  }

  
	render(){ 		

		const {showControlBar, played, playing, handleSeekMouseUp, handleSeekMouseDown, handleSeekChange, handleVolumeChange, handlePlayPause, duration, progressSeconds, toggleCaptions, captionsActive, handleZoom, volume, handleVolumeMouseDown, handleVolumeMouseUp, adjustingVolume} = this.props
		
    const {timestampedMessages}=this.props
    const hasTimestampedComments = timestampedMessages && timestampedMessages.length>0

		return (	
				<React.Fragment>
        {progressSeconds > 0 &&
          <React.Fragment>
          <button onClick={()=>{this.props.handleSkip('back')}} className='doc-video-inlineUI-hoverSeekButton doc-video-inlineUI-hoverSeekButton--backwards'>
            <div className='doc-video-inlineUI-hoverSeekButton-inner'>
              <Icon name='playbackSeekBack5' />
            </div>
          </button>
          <button onClick={()=>{this.props.handleSkip('forward')}} className='doc-video-inlineUI-hoverSeekButton doc-video-inlineUI-hoverSeekButton--forwards'>
            <div className='doc-video-inlineUI-hoverSeekButton-inner'>
              <Icon name='playbackSeekForward5' />
            </div>
          </button>
          </React.Fragment>
        }

					<div className={'doc-video-inlineUI-controlBar ' + (showControlBar ? ' doc-video-inlineUI-controlBar--show ' : ' doc-video-inlineUI-controlBar--hide ')  + (hasTimestampedComments ? ' doc-video-inlineUI-controlBar--hasTimestampedComments ' : ' doc-video-inlineUI-controlBar--noTimestampedComments ')}>
						
            {hasTimestampedComments &&
              <div className='doc-video-inlineUI-controlBar-commentRowContainer'>
                <DocVideoCommentRow 
                  rowWidth={466}
                  darkModeMessage
                  duration={this.props.duration}
                  timestampedMessages={this.props.timestampedMessages}
                  seekToComment={this.props.seekToComment}
                />
              </div>
            }

            <div className='doc-video-inlineUI-controlBar-seekBarContainer'>
              <ReactSlider
                className="doc-video-inlineUI-controlBar-seekBar"
                thumbClassName="doc-video-inlineUI-controlBar-seekBar-thumb"
                trackClassName="doc-video-inlineUI-controlBar-seekBar-track"                  
                renderThumb={(props, state) => <div className='doc-video-inlineUI-controlBar-seekBar-thumb-thumb' {...props} />}

                value={played}
                min={0}
                max={0.999999}
                step={0.00001}
                onAfterChange={handleSeekMouseUp}
                onBeforeChange={handleSeekMouseDown}
                onChange={handleSeekChange}
              />              
            </div>

            <div className='doc-video-inlineUI-controlBar-counterRow'>
              <div className='doc-video-inlineUI-controlBar-counterRow-counter doc-video-inlineUI-controlBar-counterRow-counter--elapsed'>
  							<VideoDuration seconds={progressSeconds} />
                {/*{volume} */}
  						</div>
  						
  						<div className='doc-video-inlineUI-controlBar-counterRow-counter doc-video-inlineUI-controlBar-counterRow-counter--duration'>
  							<VideoDuration seconds={duration} />
  						</div>
            </div>
            <div className='doc-video-inlineUI-controlBar-controlRow'>

              
              <DocVideoInlineUIStartedControlsVolumeSlider 
                handleVolumeChange={handleVolumeChange}
                handleVolumeMouseDown={handleVolumeMouseDown}
                handleVolumeMouseUp={handleVolumeMouseUp}
                volume={volume}
                adjustingVolume={adjustingVolume}
              />
                          

            	<button onClick={toggleCaptions} className={'doc-video-inlineUI-controlBar-controlRow-secondaryBtn doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captions ' + (captionsActive ? ' doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captions--active ' : ' doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captions--inactive ')}>
            		<div className='doc-video-inlineUI-controlBar-controlRow-secondaryBtn--captionsIcon'>
            			CC
            		</div>            		
            	</button>

              <button onClick={handlePlayPause} className='doc-video-inlineUI-controlBar-controlRow-playPauseBtn'>
                  {playing ?
                    <Icon name='playbackPause' /> 
                    :
                    <Icon name='playbackPlay' /> 
                  }                
              </button>

              <button onClick={handleZoom} className='doc-video-inlineUI-controlBar-controlRow-secondaryBtn doc-video-inlineUI-controlBar-controlRow-secondaryBtn--zoom'>
              	<Icon name='playbackZoomOnly' />
            	</button>
              
            </div>

					</div>            
				</React.Fragment>					
		)
	}
}

export default DocVideoInlineUIStartedControls
