import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import {showDocMediaModal} from '../../../utils/showDocMediaModal'



class DocThumbnailReactComponent extends React.Component{  
 
  constructor(){
    super()
    this.handleImageClick=this.handleImageClick.bind(this)
    this.convertThumbnailToImage=this.convertThumbnailToImage.bind(this)
    this.zoomImage=this.zoomImage.bind(this)
    // this.state={
    //   zoomed: false,  
    // }
  }

  
  // toggleZoom(e){
  //   this.setState({ zoomed: !this.state.zoomed })
  // }

  handleImageClick(e){
    if(draftMode === false){
    	this.toggleZoom()
    }
  }

  convertThumbnailToImage(){
  	this.props.convertThumbnailToImage()
  	this.setState({ zoomed: false })
  }

   zoomImage(){
  	const modalType="zoomedImage"
  	const nodeId=this.props.nodeId
  	showDocMediaModal(modalType,nodeId)
  }



	render(){ 
		const {deliveryUrl,height,width,title,description}=this.props


		return (
			<React.Fragment>
	
				<img 
					alt=''
					src={deliveryUrl}
					onClick={this.zoomImage}
				/>

				<div className='doc-imageThumbnail-overlayBorder' />

				<div className='doc-imageThumbnail-overlayIcon'>
					<Icon name='expandVideo' />
				</div>

				

			</React.Fragment>
		)
	}
}

export default DocThumbnailReactComponent