import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../../misc/Icon'
import DocEditorMeetingContentPlayRecordingButton from './DocEditorMeetingContentPlayRecordingButton'
import {createMeetingRecording} from '../../../../utils/meetings/createMeetingRecording'
import { RecordingsContext } from '../../../../containers/RecordingsProvider'

class DocEditorMeetingContentAddRecordingButton extends React.PureComponent{  

  
  constructor(props){
    super(props)        
    this.handleUploadRecording=this.handleUploadRecording.bind(this)   
    this.state={

    }
  }


  handleUploadRecording(e){
    const file=event.target.files[0]
    createMeetingRecording(this.props.meeting.meeting_id,file,this.context)
  }

  render(){ 

    const {strong, light} = this.props

    return (        
      <React.Fragment>

      <div className='docEditor-meetingContent-addRecording'>          
        <button onClick={()=>{createMeetingRecording(this.props.meeting.meeting_id,this.context)}} className='docEditor-meetingContent-menu-primaryBtn docEditor-meetingContent-menu-primaryBtn--addRecording'>
          <label htmlFor="recordingUpload">                       
            <div className='docEditor-meetingContent-menu-primaryBtn-iconContainer'>
              <Icon name='upArrowCircleLayered' />
            </div>
            <div className='docEditor-meetingContent-menu-primaryBtn-label'>
              Add Call Recording
            </div>
          </label>
          <input 
            id="recordingUpload"
            type="file"
            className='docEditor-meetingContent-menu-primaryBtn-hiddenFileInput'
            onChange={this.handleUploadRecording} 
            value={''} 
            accept={'video/*, audio/*'}
          />
        </button>                  
      </div>

      {/*
      <button  className='docEditor-meetingContent-menu-btn docEditor-meetingContent-menu-btn--strong  docEditor-meetingContent-menu-btn--addRecording'>
        <label htmlFor="recordingUpload">                       
          <div className='docEditor-meetingContent-menu-btn-iconContainer'>
            <Icon name='cameraOutline' />
          </div>
          <div className='docEditor-meetingContent-menu-btn-label'>
            Add Recording
          </div>
        </label>
        <input 
          id="recordingUpload"
          type="file"
          className='docEditor-meetingContent-menu-btn-label-hiddenFileInput'
          onChange={this.handleUploadRecording} 
          value={''} 
          accept={'video/*, audio/*'}
        />
      </button>  
      */}

      </React.Fragment>
      
    )
  }
}


DocEditorMeetingContentAddRecordingButton.contextType = RecordingsContext;

export default withRouter(DocEditorMeetingContentAddRecordingButton)
