import moment from 'moment'

export function formatMeetingDate(date) {
	const nowDate = moment();
	const today = moment(nowDate).startOf('day');
	const yesterday = moment(nowDate).subtract(1, 'days').startOf('day');
	const dayBeforeYesterday = moment(nowDate).subtract(2, 'days').startOf('day');
	const tomorrow = moment(nowDate).add(1, 'days').startOf('day');
	const week = moment(nowDate).startOf('isoWeek');	
  const sevenDaysFromNow = moment(nowDate).add(7, 'days');
  const nextWeekButLessThan7Days = moment(nowDate).add(1, 'weeks').subtract(1, 'days');
	const nextWeek = moment(nowDate).add(1, 'weeks').startOf('isoWeek');
	const endOfNextWeek = moment(nowDate).add(1, 'weeks').endOf('isoWeek');
	const sixHoursFromNow = moment(nowDate).add(6, 'hours');

	const startOfThisWeek = moment().startOf('isoWeek');
  const endOfThisWeek = moment().endOf('isoWeek');
  const startOfNextWeek = moment().add(1, 'weeks').startOf('isoWeek');


 
	let formattedMeetingDate;


	if (moment(date).isSame(today, 'd')) {
		// today
		formattedMeetingDate = 'Today';
	}
	else if (moment(date).isSame(yesterday, 'd')) {
  	// yesterday
  	formattedMeetingDate = 'Yesterday';
  }
	else if (moment(date).isSame(tomorrow, 'd')) {
  	// tomorrow
  	formattedMeetingDate = 'Tomorrow';
  }	
  else if (moment(date).isSameOrAfter(startOfThisWeek) && moment(date).isBefore(yesterday)) {
    // earlier this week
    formattedMeetingDate = `${moment(date).format('ddd MMMM D')}`;
  }
	else if (moment(date).isAfter(tomorrow) && moment(date).isSameOrBefore(endOfThisWeek)) {
    // later this week but not today
    formattedMeetingDate = `${moment(date).format('dddd')}`;
  } 
	else if (moment(date).isBetween(nextWeek, nextWeekButLessThan7Days)) {
    // next week but less than 7 days
    formattedMeetingDate = `${moment(date).format('dddd')}`;
  } 
  else if (moment(date).isBetween(nextWeek, sevenDaysFromNow)) {
    // next week and 7 or more days away
    formattedMeetingDate = `Next ${moment(date).format('ddd MMMM D')}`;
  }
	else{
		// all others
		//formattedMeetingDate = moment(date).format('ddd MMMM D'); // with dayofweek
		formattedMeetingDate = moment(date).format('ddd MMMM D');
	}

	return formattedMeetingDate;
}