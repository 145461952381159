const ToDoItemNodeSpec = {
	 attrs: {
    isChecked: {default: false},
    indentLevel:{default:0},
    assignees:{default:[]},
    usersVersion:{default:0} //bump to reload when users loaded from api
  },
	group: "block",
	content: "block",
  defining:true,
	//selectable:false,
	parseDOM: [{
    tag: 'toDoItem', getAttrs(dom) {
      return {
      	isChecked: dom.getAttribute('data-is-checked')==='true',
        indentLevel: parseInt(dom.getAttribute("data-indent-level"),10),
        assignees:JSON.parse(dom.getAttribute("data-assignees"))
      }
    }
  }],
  toDOM(node) {
    const {isChecked,indentLevel,assignees} = node.attrs;
    return ["toDoItem",{"data-is-checked":isChecked,"data-indent-level":indentLevel,"data-assignees":JSON.stringify(assignees)},0]
  }
} 


export default ToDoItemNodeSpec 