import React from 'react'
import { connect } from 'react-redux'
import Icon from '../../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import MessagePanelHeaderDNDButton from './MessagePanelHeaderDNDButton'
import {setDoNotDisturbUntil} from '../../../../utils/notifications/setDoNotDisturbUntil'
import {showToastNotification} from '../../../../actions/toastNotification'
import store from '../../../../store'

import moment from 'moment'

const DO_NOT_DISTURB_HOURS=4
//Now + x hours
function calculateNewDoNotDisturbUntil(){
  const nowDate =moment()
  const endTime=nowDate.add(DO_NOT_DISTURB_HOURS, 'hours')
  //const endTime=nowDate.add(DO_NOT_DISTURB_HOURS, 'minutes')
  const remainder = 15 - (endTime.minute() % 15) //round to 15 mins
  const roundedEndTime = moment(endTime).add(remainder, "minutes")
  return roundedEndTime
}

function formatDateTimeLabel(timestamp){ //e.g. 2.45pm
  const formatted=`Until ${moment(timestamp).format('h:mm A')}`
  return formatted
}



class MessagePanelHeaderDNDButtonContainer extends React.Component{  


  constructor(){
    super()
    this.handleOnClick=this.handleOnClick.bind(this)
  } 

  handleOnClick(newDoNotDisturbUntil){    
    if(!newDoNotDisturbUntil){
      const userName=null
      const actionType="dndSwitchOff"       
      store.dispatch(showToastNotification(actionType,userName))
    }
    if(newDoNotDisturbUntil){
      const userName=null
      const actionType="dndSwitchOn"       
      store.dispatch(showToastNotification(actionType,userName))
    }

    setDoNotDisturbUntil(newDoNotDisturbUntil)
    
  }

  render(){ 
    const {doNotDisturbUntil}=this.props

    const dndActive=doNotDisturbUntil?true:false

   // const {dndActive} = this.props

    const springConfig = this.props.springAnimationConfig    
    const defaultButtonBGColor = 'rgb(240,240,244)'
    const activeButtonBGColor = 'rgb(244,244,250)'
    let newDoNotDisturbUntil=null
    let labelText=''
    if(!dndActive){
      newDoNotDisturbUntil=calculateNewDoNotDisturbUntil().toISOString()
      labelText=formatDateTimeLabel(newDoNotDisturbUntil)
    }


    return (      
       <Tooltip.Provider>
          <Tooltip.Root delayDuration={0}>
            <Tooltip.Trigger onClick={()=>{this.handleOnClick(newDoNotDisturbUntil)}} className='docEditor-messagePanel-header-dndButtonContainer'>              
              <MessagePanelHeaderDNDButton 
                dndActive={dndActive}
                springAnimationConfig={this.props.springAnimationConfig}
              />          
            </Tooltip.Trigger>            
            {dndActive &&
              <Tooltip.Content side="bottom" className="tooltip tooltip--dndButton" >
                Resume Notifications
              </Tooltip.Content>    
            }
            {!dndActive &&
              <Tooltip.Content side="bottom" className="tooltip tooltip--dndButton">
                <div>Do Not Disturb</div>
                <div className='tooltip--dndButton-light'>{labelText}</div>
              </Tooltip.Content>    
            }

          </Tooltip.Root>
        </Tooltip.Provider>
      
    )
  }
}

// export default MessagePanelHeaderDocGroupDNDButtonContainer


const mapStateToProps = (state) => ({
  doNotDisturbUntil:state.doNotDisturbUntil
});

export default connect(
  mapStateToProps,
  {

  })(MessagePanelHeaderDNDButtonContainer)

