export function bumpFigmaEmbedVersion() {
	if(window.view && window.view.state){
		let tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.attrs.figmaEmbedVersion || node.attrs.figmaEmbedVersion===0){
				tr.setNodeMarkup(pos, null, {...node.attrs,figmaEmbedVersion:node.attrs.figmaEmbedVersion+1})
			}
		})
		tr.setMeta('addToHistory', false)  
		window.view.dispatch(tr)
	}
}