import React from 'react'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'

class ActionCommandMenuRow extends React.Component{  

  render(){ 

    const {action, user, active, pingType, filteredResults} = this.props
    
    //
    // ACTION OPTIONS    
    //action="pingDoc"
		//action="jumpToCursor"
		//action="directMessage"

    let actionLabel

    if(action === 'ping'){
      if(pingType === 'doc'){
        actionLabel = 'Ping doc'  
      }
      if(pingType === 'selection'){
        actionLabel = 'Ping selection'
      }
      if(pingType === 'image'){
        actionLabel = 'Ping image'  
      }
      if(pingType === 'drawing'){
        actionLabel = 'Ping drawing'  
      }    	  	
    }

    if(action === 'jumpToCursor'){
    	actionLabel = 'Jump'
    }

    const directMessageType  = 'newConversation'

    if(action && action === 'directMessage' && directMessageType){
      if(directMessageType === 'invitation'){
        actionLabel = 'Invite to doc'  
      }
      if(directMessageType === 'newConversation'){
        //actionLabel = 'Start conversation' 
        //actionLabel = 'Discuss Doc'
        actionLabel = null        
      }
      
      if(directMessageType === 'existingConversation'){
        //actionLabel = 'Open conversation'  
        //actionLabel = 'Discuss Doc'
        actionLabel = null
      }          
    }    

    return (      
      <React.Fragment>
        
        {user &&
          <div onClick={this.props.onClickFunction} className={'actionCommandMenu-row actionCommandMenu-row--actionMenuUser ' + (active ? ' actionCommandMenu-row--activeStatus--active ' : ' actionCommandMenu-row--activeStatus--inactive ') + (action ? ` actionCommandMenu-row--actionMenuUser--actionType--${action} ` : '')}>
            <div className='actionCommandMenu-row-visual actionCommandMenu-row-visual--avatar'>
              <AvatarImage 
                avatarUrl={user.avatar}
                className='actionCommandMenu-row-visual--avatar-avatar'
              />         
            </div>   
            <div className='actionCommandMenu-row-label'>              
              {user.display_name}              
            </div>
            
            {filteredResults &&                     
              <div className={'actionCommandMenu-row-actionLabel ' + (filteredResults ? 'actionCommandMenu-row-actionLabel--filteredResults' : 'actionCommandMenu-row-actionLabel--emptyState')}>
                {actionLabel}                
              </div>            
            }

            {active &&             
              <div className='actionCommandMenu-row-enterKeyTag'>
                <div className='actionCommandMenu-row-enterKeyTag-label'>
                  Enter
                </div>
                <div className='actionCommandMenu-row-enterKeyTag-iconContainer'>
                  <Icon name='enterKey' />
                </div>
              </div>              
            }
          </div>
        }

      </React.Fragment>
    )
  }
}

export default ActionCommandMenuRow
