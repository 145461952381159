import React, { Component } from 'react'
import { Droppable } from '@hello-pangea/dnd'
import DraggableTab from './DraggableTab'


class DroppableTabList extends Component {
	render(){  
		const {tabs,tabGroup}=this.props
		return (	  	
				<Droppable droppableId={`${tabGroup.id}`} type="TAB">
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							//style={{ backgroundColor: snapshot.isDraggingOver ? 'blue' : 'grey' }}
							className={'sideMenu-tabGroup-tabList ' + (this.props.independentTabs ? 'sideMenu-tabGroup-tabList--independentTabs' : '')}
						>
							{tabs && tabs.map((tab, index) => (
								<DraggableTab
									key={`tab-${tab.type}-${tab.id}`}
									tab={tab}
									index={index}
									moveToNewGroup={this.props.moveToNewGroup}
									independentTab={this.props.independentTabs}
								/>
							))}
							{provided.placeholder}
						</div>
					)}
			</Droppable>  	
		)
	}
}

export default DroppableTabList

