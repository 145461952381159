const DocTableNodeSpec = {
	group: "block",
	attrs: {
  	nodeId:{default:''},
  	alternatingRowBG:{default:false},
  	headerRow:{default:false},
  	headerColumn:{default:false}
  },
	// defining:true,
	content: "table",
	// isolating: true,
 	toDOM(node) {
    return ['docTable',0]},
  parseDOM: [{tag: 'docTable'}],

}
export default DocTableNodeSpec


