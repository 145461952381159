import {fetch,put,post} from '../api'
import {recalcProjectMilestones} from '../utils/milestones/recalcProjectMilestones'
import {fetchDocs} from './docs'

export function fetchMilestones(){
	return (dispatch) => fetch(`/milestones`)
	.then((response) => {
		dispatch({ type: 'FETCH_MILESTONES_SUCCESS', response })
		recalcProjectMilestones()
		return response
	})
	.catch((error) => {
		return error
	})
}

///TODO maybbe only refetch milestone docs - lets see about performance
export function syncMilestones(){
	return (dispatch) => put(`/milestones/sync`)
	.then((response) => {
		dispatch({ type: 'FETCH_MILESTONES_SUCCESS', response })
		dispatch(fetchDocs())
		recalcProjectMilestones()
		return response
	})
	.catch((error) => {
		return error
	})
}