import store from '../../store'
import find from 'lodash/find'


export function getHighlightForId(highlightId) {	

	const state = store.getState()
	const highlight= find(state.highlights, {'highlight_id':highlightId})

	return highlight
}

