import store from '../store'
import {duplicateDocAction} from '../actions/docs'
import {getDocForId} from './getDocForId'
import {openDoc} from './openDoc'


export function duplicateDoc(history,docId) {
	store.dispatch(duplicateDocAction(docId)).then((doc)=>{
		openDoc(history,doc['doc_id'])
	})
}
