import store from '../store'
import find from 'lodash/find'
import {fetch} from '../api'
import {openDoc} from './openDoc'
import {showToastNotification} from '../actions/toastNotification'
import {getUserName} from './getUserName'

export function jumpToCursor(userId,history) {	
	fetch(`/activeDoc/${userId}`).then((response)=>{
		const docId=response['doc_id']
		if(docId){
			openDoc(history,docId)
			const actionType="jumpToCursorConfirmation"
			const userName=getUserName(userId)
			store.dispatch(showToastNotification(actionType,userName))
		}
	})
}