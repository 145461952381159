import store from '../store'
import find from 'lodash/find'


export function getCurrentUser() {	
	const state = store.getState()
	const currentUser=state.user
	return currentUser 
}


export function getCurrentUserId() {	
	let id
	const state = store.getState()
	const currentUser=state.user
	if(currentUser){
		id=currentUser.user_id
	}
	return id 
}
