const initialState = {
	isAuthenticated: false,
	willAuthenticate: true,
};

export default function (state = initialState, action) {
	switch (action.type) {
		case 'AUTHENTICATION_REQUEST':
			return {
				...state,
				willAuthenticate: true,
			};
		case 'AUTHENTICATION_SUCCESS':
			return {
				...state,
				willAuthenticate: false,
				isAuthenticated: true,
			};
		case 'AUTHENTICATION_FAILURE':
			return {
				...state,
				willAuthenticate: false,
			};
		case 'SIGN_OUT':
			return {
				isAuthenticated: false,
				willAuthenticate: false,
			}
		default:
			return state;
	}
}