import schema from '../schema/editorSchema'  
import {TextSelection} from "prosemirror-state"


export function insertCodeBlock() {
	const state=window.view.state
	let insertPos=state.selection.from
	let tr=state.tr
	let {$from, $to} = state.selection
	let content=tr.doc.slice($from.pos,$to.pos)
	const paragraphNode=schema.nodes.paragraph.create(null,content.content)
	const node=schema.nodes.codeBlock.create(null,paragraphNode)
	tr.replaceWith(state.selection.from,state.selection.to,node)
	let selection=new TextSelection(tr.doc.resolve(state.selection.from+node.nodeSize-1))
	tr.setSelection(selection)
	window.view.dispatch(tr)
}

