import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { DragDropContext, Droppable,Draggable } from '@hello-pangea/dnd'
import DroppablePageArea from './DroppablePageArea' //(board)
import DroppableTabList from './DroppableTabList'
import {reorderTabs} from '../../../utils/reorderTabs'
import findIndex from 'lodash/findIndex'
import {randomID} from '../../../utils/randomID'

// DroppablePageArea (DropArea for DraggableTabGroup)
// 	-DraggableTabGroup (draggable and is droppable in DroppablePageArea)
//		-DroppableTabList (droppable area for DraggableTab)
//			-DraggableTab (draggable and droppable in DroppableTabList)

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result
}

class DnDTabsList extends Component {

	constructor() {
		super()
		this.onDragEnd=this.onDragEnd.bind(this)
		this.moveToNewGroup=this.moveToNewGroup.bind(this)
		this.renameTabGroup=this.renameTabGroup.bind(this)
		this.deleteTabGroup=this.deleteTabGroup.bind(this)
		this.state={
			newTabGroupId:null
		}
	}

	onDragEnd(result) {
    if (!result.destination) {// dropped outside the list
      return;
    }
    if(result.type==="TABGROUP"){
    	const {openTabs}=this.props
			const {tabGroups,orderedTabGroups}=openTabs
			const newOrderedTabGroups = reorder(
				orderedTabGroups,
				result.source.index,
				result.destination.index,
			);
			reorderTabs(tabGroups,newOrderedTabGroups)
    }else if(result.type==="TAB"){
			const {openTabs}=this.props
			const {tabGroups,orderedTabGroups}=openTabs
			let newTabGroups=Object.assign({},tabGroups)			
			const sourceIndex=result.source.index
			const sourceDroppableId=result.source.droppableId
			const sourceTabGroup=[...newTabGroups[sourceDroppableId]]
			let removed=sourceTabGroup.splice(sourceIndex,1)
			newTabGroups[sourceDroppableId]=sourceTabGroup
			const destinationDroppableId=result.destination.droppableId
			const destinationIndex=result.destination.index
			const destinationTabGroup=[...newTabGroups[destinationDroppableId]]
			destinationTabGroup.splice(destinationIndex,0,removed[0])
			newTabGroups[destinationDroppableId]=destinationTabGroup
			reorderTabs(newTabGroups,orderedTabGroups)
    }
  }


moveToNewGroup(tabId){

	const randId=randomID()
	//create a new tab group
	let newTabGroup={
		id:randId,
		name:''
	}
	const {openTabs}=this.props
	const {tabGroups,orderedTabGroups}=openTabs

	let newOrderedTabGroups=orderedTabGroups.slice()
	newOrderedTabGroups.push(newTabGroup)
	let newTabGroups=Object.assign({},tabGroups)
	let tabMoved
	Object.keys(tabGroups).forEach((key)=>{
		const tabGroup=tabGroups[key].slice()
		const tabIndex=findIndex(tabGroup,(tab => {
 			return tab === tabId
		}))
		if(tabIndex===-1){}else{
			const newTapGroup=[
				...tabGroup.slice(0,tabIndex),
				...tabGroup.slice(tabIndex + 1)
			]	
			newTabGroups[key]=newTapGroup
			tabMoved=tabGroup[tabIndex]
		}	
	})
	newTabGroups[randId]=[tabMoved]
	reorderTabs(newTabGroups,newOrderedTabGroups)
	this.setState({newTabGroupId:randId})
}

	renameTabGroup(tabGroupId,newName){
		let updatedTabGroup={
			id:tabGroupId,
			name:newName
		}
		const {openTabs}=this.props
		const {tabGroups,orderedTabGroups}=openTabs

		const indexOfTabGroup=findIndex(orderedTabGroups,(tabGroup => {
 			return tabGroup.id === tabGroupId
		}))
		if(indexOfTabGroup>-1){
			const newOrderedTabGroups=[
				...orderedTabGroups.slice(0,indexOfTabGroup),
				updatedTabGroup,
				...orderedTabGroups.slice(indexOfTabGroup + 1)
			]	
			reorderTabs(tabGroups,newOrderedTabGroups)
			this.setState({newTabGroupId:null})
		}	
	}


	deleteTabGroup(tabGroupId){
		const {openTabs}=this.props
		const {tabGroups,orderedTabGroups}=openTabs
		let newTabGroups=Object.assign({},tabGroups)
		delete newTabGroups[tabGroupId]
		let newOrderedTabGroups=[]
		orderedTabGroups.forEach((group)=>{
			if(group.id != tabGroupId){
				newOrderedTabGroups.push(group)
			}
		})
		reorderTabs(newTabGroups,newOrderedTabGroups)
	}

	render(){  
		const {openTabs}=this.props
		const {newTabGroupId}=this.state
		const {tabGroups,orderedTabGroups}=openTabs
		const nonDraggableTabGroup=	{name:"default",id:"default"}
		let showStarredTabGroup=tabGroups && tabGroups.starred && tabGroups.starred.length>0
		let cleanedTabGroups={default:tabGroups.default} //only show starred when there are starred docs
		let cleanedOrderedTabGroups=[
			{name:"default",id:"default"},
		]
		if(showStarredTabGroup){
			cleanedTabGroups.starred=tabGroups.starred
			cleanedOrderedTabGroups.push({name:"starred",id:"starred"})
		}


		return (	  	
			<div className='sideMenu-docTabsContainer'>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<DroppablePageArea 
						tabGroups={cleanedTabGroups}
						orderedTabGroups={cleanedOrderedTabGroups}
						moveToNewGroup={this.moveToNewGroup}
						renameTabGroup={this.renameTabGroup}
						deleteTabGroup={this.deleteTabGroup}
						newTabGroupId={newTabGroupId}
						messageCount={this.props.messageCount}
					/>
					<div className='sideMenu-docTabsGroup sideMenu-docTabsGroup--independent'>
						<div className='sideMenu-docTabsGroup--independent-header'>
							<div className='sideMenu-docTabsGroup--independent-header-bar' />
						</div>
						<DroppableTabList
							tabs={cleanedTabGroups[nonDraggableTabGroup.id]} 
							tabGroup={nonDraggableTabGroup}
							moveToNewGroup={this.moveToNewGroup}
							independentTabs
							docs={this.props.docs}
							roadmaps={this.props.roadmaps}
							showStarredTabGroup={showStarredTabGroup}
							messageCount={this.props.messageCount}
						/>
					</div>
				</DragDropContext>
			</div>	
		)
	}
}

function mapStateToProps(state,ownProps) {
	return {
		openTabs:state.openTabs,
		docs:state.docs,
		roadmaps:state.roadmaps
	}
}
export default withRouter(connect(mapStateToProps,
	{}
)(DnDTabsList))	