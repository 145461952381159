export default function (state = false, action) {
	switch (action.type) {
	 case 'SHOW_INSERT_DRAWING_MODAL':
			return true
	case 'HIDE_INSERT_DRAWING_MODAL':
			return false
		default:
			return state;
	}
}
