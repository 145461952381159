import React from 'react'
import Icon from '../misc/Icon'
import LinearMarker from '../misc/LinearMarker'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'
import {getTeamForId} from '../../utils/getTeamForId'
import * as Tooltip from '@radix-ui/react-tooltip';
import moment from 'moment'
import {updateProjectPageSetting} from '../../utils/updateProjectPageSetting'
import groupBy from 'lodash/groupBy'
import {getUserForId} from '../../utils/getUserForId'
import RoadmapsHomePageProjectListControls from './RoadmapsHomePageProjectListControls'
import RoadmapsProjectRow from './RoadmapsProjectRow'
import RoadmapsHomePageGrid from './RoadmapsHomePageGrid'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import EditProjectModal from '../editProjectModal/EditProjectModal'
import RoadmapsHomePageQuarter from './roadmapsHomeQuarter/RoadmapsHomePageQuarter'
import RoadmapsHomePageQuarterEmpty from './roadmapsHomeQuarter/RoadmapsHomePageQuarterEmpty'
import {getMilestonesForProject} from '../../utils/getMilestonesForProject'


function hasStartedWithMilestones(projects){
	let hasStartedProjectWithMilestones=false
	projects.forEach((project)=>{
		if(project.state=='started'){
			const milestones=getMilestonesForProject(project.project_id)
			if(milestones.length>0){
				hasStartedProjectWithMilestones=true
			}
		}
	})
	return hasStartedProjectWithMilestones
}


class RoadmapsHomePage extends React.Component{  
	static getDerivedStateFromProps(props, state) {
		if(props.quarterRoadmap != state.selectedQuarterRoadmapId){ 
			return ({selectedQuarterRoadmapId:props.quarterRoadmap})
		}
		return null
	}

	constructor(props) {
		super(props)	
		this.openEditProjectModal=this.openEditProjectModal.bind(this)
		this.closeModal=this.closeModal.bind(this)
		this.state={			
			showEditProjectModal: false,
			editProjectId:null,
			selectedQuarterRoadmapId: props.quarterRoadmap
		}
		this.timeout=null
	}

	componentWillUnmount(){
	 	if(this.timeout){
 			clearTimeout(this.timeout)
 		}
	}

	openEditProjectModal(projectId,e){
		this.timeout= setTimeout(() => { //had to add a small delay otherwise modal closes dunno why
 			this.setState({showEditProjectModal:true,editProjectId:projectId})
  	 }, 1);
	}


	closeModal(){
		this.setState({showEditProjectModal: false,editProjectId:null})
	}

	render(){	
		const {completedProjectsCount,showAllCompleted,oldCompletedProjectsCount}=this.props
		const {selectedQuarterRoadmapId} = this.state

		let showSticky = false
		let showAllProjectsSticky = false
		let allProjectsHeaderTop
		const allProjectsHeaderEl = document.getElementById('roadmapsHomePageProjectListHeader')	
		if(allProjectsHeaderEl){
			const allProjectsHeaderRect = allProjectsHeaderEl.getBoundingClientRect()
			allProjectsHeaderTop = allProjectsHeaderRect.y + this.props.scrollTop - 10			
		}
		if(this.props.scrollTop > 80){ // a bit hacky to hardcode
			
		}
		if(this.props.scrollTop > allProjectsHeaderTop){ // a bit hacky to hardcode
			showAllProjectsSticky = true
			showSticky = true
		}
		const {roadmaps,projects,grouping,sortOrder}=this.props		

		// Show hide completed projects

		let countCompletedProjectsLabel = null
		const countCompletedProjects = completedProjectsCount // this is count of all completed projects in org
		if(oldCompletedProjectsCount && countCompletedProjects > 0){
			countCompletedProjectsLabel = countCompletedProjects
		}

		const hiddenCount = 0

		let showRoadmapsHomePageGrid = false
		if(!selectedQuarterRoadmapId){
			showRoadmapsHomePageGrid = roadmaps.length > 0
		}		
		if(selectedQuarterRoadmapId){
			showRoadmapsHomePageGrid = roadmaps.length > 1
		}

		const hasStartedProjectsWithMilestones = hasStartedWithMilestones(projects)

		
	  return (
	  	<React.Fragment>
				<EditProjectModal 
					showModal={this.state.showEditProjectModal}
					openModal={()=>{this.setState({showEditProjectModal: true})}}
					closeModal={this.closeModal}					
					newProject={this.state.editProjectId?false:true}
					projectId={this.state.editProjectId}
				/>

		  	{/*<div className={'roadmapsHomePage-stickyContainer ' + (showSticky ? ' roadmapsHomePage-stickyContainer--show ' : ' roadmapsHomePage-stickyContainer--hide ') }>
					<div className='roadmapsHomePage-sticky'>
						<div className='roadmapsHomePage-leftSpacer'/>
						<div className='roadmapsHomePage-sticky-title'>
							Roadmaps
						</div>
						<div className={'roadmapsHomePage-sticky-subtitle ' + (showAllProjectsSticky ? ' roadmapsHomePage-sticky-subtitle--show ' : ' roadmapsHomePage-sticky-subtitle--hide ') }>
							All Projects
						</div>
					</div>
			  </div>
			  */}

				<div className='roadmapsHomePage'>
					<div className='roadmapsHomePage-leftSpacer'/>
					<div className='roadmapsHomePage-center'>	 
						{/*}
						{selectedQuarterRoadmapId &&
				  		<RoadmapsHomePageQuarter
				  			quarterRoadmapId={selectedQuarterRoadmapId}
				  			roadmaps={roadmaps}
				  		/>
			  		}
			  		*/}
			  		{/*}
			  		{!selectedQuarterRoadmapId &&
				  		<RoadmapsHomePageQuarterEmpty
				  			roadmaps={roadmaps}
				  		/>
			  		}
			  		*/}

			  		{showRoadmapsHomePageGrid && 
				  		<RoadmapsHomePageGrid 
				  			activeStatus={this.props.activeStatus}
				  			roadmaps={roadmaps}
				  			quarterRoadmapId={selectedQuarterRoadmapId}
				  		/>
			  		}

			  		<div className='roadmapsHomePage-projectList'>
			  			<div id="roadmapsHomePageProjectListHeader" className='roadmapsHomePage-projectList-header'>		  				
			  				<div className='roadmapsHomePage-projectList-header-label'>
			  					All Projects	
			  				</div>
			  			</div>
		  				<div className='roadmapsHomePage-projectList-content'>
				  			<div className='roadmapsHomePage-projectList-content-list'>
				  				{projects.map((project, i)=>{
				  					let lastItemInGroup=false
											if(grouping=='status'){
												if(projects.length == (i+1)){
													 lastItemInGroup=true
												}else if(project.state!=projects[i+1].state){
													lastItemInGroup=true
												}
											}else if(grouping=='lead'){
												if(projects.length == (i+1)){
													 lastItemInGroup=true
												}else if(project.lead!=projects[i+1].lead){
													lastItemInGroup=true
												}
											}
										return(
											<RoadmapsProjectRow										
												key={project.project_id}
												lastItemInGroup={lastItemInGroup}
												project={project}
												openEditProjectModal={this.openEditProjectModal}
												hasStartedProjectsWithMilestones={hasStartedProjectsWithMilestones}
												//projectDocs={projectDocs} // not sure we still need this, maybe in dropdown or something
											/>
											)
									})}
				  				{countCompletedProjectsLabel &&
										<button className='roadmapsHomePage-projectList-content-list-completedToggle' onClick={this.props.updateShowAllCompleted}>
											{showAllCompleted ? 'Hide projects completed over 2 weeks ago' : `Show all ${countCompletedProjectsLabel} completed projects`}
										</button>
									}
								</div>
								<div className='roadmapsHomePage-projectList-content-controls'>
									<div className='roadmapsHomePage-projectList-content-controls-newProjectBtnContainer'>									
					  				<button onClick={()=>{this.setState({showEditProjectModal: true})}} className='roadmapsHomePage-projectList-header-newProjectBtn'>
											<div className='roadmapsHomePage-projectList-header-newProjectBtn-iconContainer'>
												<Icon name='plus' />
											</div>
											<div className='roadmapsHomePage-projectList-header-newProjectBtn-label'>
					  						New Project										
											</div>
					  				</button>
									</div>

									<RoadmapsHomePageProjectListControls 
			  						sortOrder={sortOrder}
			  						grouping={grouping}
			  						updateSettings={this.props.updateSettings}
			  					/>		  							  			
								</div>						

							</div>
			  		</div>		  					  			
				</div>
				<div className='roadmapsHomePage-rightPanel'>
				</div>
	  	</div>
	  	</React.Fragment>
	  )
	}
}

export default RoadmapsHomePage
