import schema from '../../schema/editorSchema'  
import {TextSelection} from "prosemirror-state"
import {createTable} from './createTable'
import { Mixpanel } from '../../../Mixpanel'


//({ rows: 3, cols: 3, withHeaderRow: true })

export function insertTable() {
	const state=window.view.state
	let insertPos=state.selection.from
	let tr=state.tr
	let {$from, $to} = state.selection
	let content=tr.doc.slice($from.pos,$to.pos)
	const node=createTable(3,3,false)
	tr.replaceWith(state.selection.from,state.selection.to,node)
	let selection=new TextSelection(tr.doc.resolve(state.selection.from+6))
	tr.setSelection(selection)
	window.view.dispatch(tr)
	Mixpanel.track('insert_table')
}

