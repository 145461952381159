import React from 'react'
import InsertMentionsMenuItem from './InsertMentionsMenuItem'





class InsertMentionsMenuReactComponent extends React.Component{  

  constructor(){
    super()
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.handleMouseOver=this.handleMouseOver.bind(this)
    this.handleMouseOut=this.handleMouseOut.bind(this)
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.state = {
      activeIndex:0,
      mouseHasMoved:false
    }
  }

  componentDidMount(){
    window.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount(){
   window.removeEventListener('keydown', this.handleKeyDown)
  }

  componentDidUpdate(prevProps){ //only autoselect first result when there is text
    if(prevProps.text!==this.props.text){ //reset selected index when text changes
      if(this.props.text){
       this.setState({activeIndex:0,mouseHasMoved:false})
      }else{
        if(this.props.assignSuggestions.length>0){
          this.setState({activeIndex:0,mouseHasMoved:false})
        }else{
          this.setState({activeIndex:null,mouseHasMoved:false})
        }
        
      }
    }
  } 

  handleMouseMove(index){
    if(!this.state.mouseHasMoved){
      this.setState({mouseHasMoved:true,activeIndex:index})
    } 
  }

  handleMouseOver(index){
    if(this.state.mouseHasMoved){
      this.setState({activeIndex:index})
    } 
  }

  handleMouseOut(index){
    this.setState({activeIndex:index})
  }

  handleKeyDown(e){
    let combinedSuggestions=this.props.suggestions
    if(this.props.dateSuggestion){
      combinedSuggestions=[this.props.dateSuggestion].concat(this.props.suggestions)
    }
    if(e.keyCode === 40) { //down arrow
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex+1
      if(newIndex==combinedSuggestions.length){
        newIndex=0
      }
      if(oldIndex==null){
        newIndex=0
      }
      this.setState({activeIndex:newIndex})
    } else if (e.keyCode === 38) { //up arrow
      e.stopPropagation()
      e.preventDefault()
      const oldIndex=this.state.activeIndex
      let newIndex=oldIndex-1
      if(newIndex<0){
        newIndex=combinedSuggestions.length-1
      }
      this.setState({activeIndex:newIndex})
      
    } else if( e.keyCode === 13) { //enter
      e.stopPropagation()
      e.preventDefault()
      this.props.selectIndex(this.state.activeIndex)
    } else if (e.keyCode === 27) { //escape
      this.props.hideList()
    } 
  }


//////
/// suggestionType is "doc","project" or "issue", or "assign"
///////
//Default state is to show none, except if assign (todo)
//when have a search term show combined 
  render(){
    const {activeIndex}=this.state
    const {suggestions,dateSuggestion,assignSuggestions,text,popoverSide, nearBottom, nearTop}=this.props

    const emptyAssignSuggestions = assignSuggestions.length > 0 && !text

    return (           
        <div className={"insertMentionsMenu " + (emptyAssignSuggestions ? ' insertMentionsMenu--emptyAssignSuggestions ' : '') + (nearTop ? ' insertMentionsMenu--nearTop ' : '') + (nearBottom ? ' insertMentionsMenu--nearBottom ' : '') + (popoverSide ? ` insertMentionsMenu--popoverSide--${popoverSide} ` : '')}>
        
        {assignSuggestions.length>0 &&
          <React.Fragment>
            <div className='insertMentionsMenu-segmentHeader'>
              <div className='insertMentionsMenu-segmentHeader-title'>
                Assign
              </div>
            </div>
            {assignSuggestions.map((assignSuggestion,i)=>{
              return(
              <InsertMentionsMenuItem
                key={`assign_suggestion_${i}`}
                suggestion={assignSuggestion}
                index={i}
                suggestionType={'assign'}
                activeIndex={activeIndex}
                selectIndex={this.props.selectIndex}
                handleMouseMove={this.handleMouseMove}
                handleMouseOver={this.handleMouseOver}
                mouseHasMoved={this.state.mouseHasMoved}
              />
              )
            })
          }
           

            {((suggestions.length!==0 && !dateSuggestion) || (dateSuggestion)) && 
              <div className='insertMentionsMenu-dividerContainer'>
                <div className='insertMentionsMenu-divider' />
              </div>
            }
           </React.Fragment>
          
          }

        {dateSuggestion &&
          <React.Fragment>
            <div className='insertMentionsMenu-segmentHeader'>
              <div className='insertMentionsMenu-segmentHeader-title'>
                Date
              </div>
            </div>
            <InsertMentionsMenuItem
              key={`suggestion_today`}
              suggestion={dateSuggestion}
              index={assignSuggestions.length}
              suggestionType={'date'}
              activeIndex={activeIndex}
              selectIndex={this.props.selectIndex}
              handleMouseMove={this.handleMouseMove}
              handleMouseOver={this.handleMouseOver}
              mouseHasMoved={this.state.mouseHasMoved}
            />

            {suggestions.length!==0 &&
              <div className='insertMentionsMenu-dividerContainer'>
                <div className='insertMentionsMenu-divider' />
              </div>
            }
           </React.Fragment>
          }

          
          {suggestions.map((suggestion,index)=>{
            let suggestionType='doc'
            if(suggestion.project_id){
              suggestionType='project'
            }else if(suggestion.issue_id){
              suggestionType='issue'
            }
            let suggestionIndex=index
            if(dateSuggestion){
              suggestionIndex+=1
            }if(assignSuggestions.length){
              suggestionIndex+=assignSuggestions.length
            }
            return(
              <InsertMentionsMenuItem
                key={`suggestion_${index}`}
                suggestion={suggestion}
                index={suggestionIndex}
                suggestionType={suggestionType}
                activeIndex={activeIndex}
                selectIndex={this.props.selectIndex}
                handleMouseMove={this.handleMouseMove}
                handleMouseOver={this.handleMouseOver}
                mouseHasMoved={this.state.mouseHasMoved}
              />
            )}
          )}            
      </div>
    )
  }
}

export default InsertMentionsMenuReactComponent



// {showSegmented &&
//           <React.Fragment>
//             <div className='insertMentionsMenu-segmentHeader'>
//               <div className='insertMentionsMenu-segmentHeader-title'>
//                 Issues
//               </div>
//             </div>
//             {segmentedSuggestions.issues.map((suggestion,index)=>{
//               return(
//                 <InsertMentionsMenuItem
//                   key={`issue_${suggestion.issue_id}`}
//                   suggestion={suggestion}
//                   index={index}
//                   suggestionType={'issue'}
//                   activeIndex={activeIndex}
//                   selectIndex={this.props.selectIndex}
//                   handleMouseMove={this.handleMouseMove}
//                   handleMouseOver={this.handleMouseOver}
//                 />
//                 )
//             })}            
//             <div className='insertMentionsMenu-dividerContainer'>
//               <div className='insertMentionsMenu-divider' />
//             </div>
//             <div className='insertMentionsMenu-segmentHeader'>
//               <div className='insertMentionsMenu-segmentHeader-title'>
//                 Projects
//               </div>
//             </div>
//             {segmentedSuggestions.projects.map((suggestion,index)=>{
//               return(
//                 <InsertMentionsMenuItem
//                   key={`project_${suggestion.project_id}`}
//                   suggestion={suggestion}
//                   index={index+3}
//                   suggestionType={'project'}
//                   activeIndex={activeIndex}
//                   selectIndex={this.props.selectIndex}
//                   handleMouseMove={this.handleMouseMove}
//                   handleMouseOver={this.handleMouseOver}
//                 />
//                 )
//             })}            
//             <div className='insertMentionsMenu-dividerContainer'>
//               <div className='insertMentionsMenu-divider' />
//             </div>
//             <div className='insertMentionsMenu-segmentHeader'>
//               <div className='insertMentionsMenu-segmentHeader-title'>
//                 All Docs
//               </div>
//             </div>
//             {segmentedSuggestions.docs.map((suggestion,index)=>{
//               return(
//                 <InsertMentionsMenuItem
//                   key={`doc_${suggestion.doc_id}`}
//                   suggestion={suggestion}
//                   index={index+6}
//                   suggestionType={'doc'}
//                   activeIndex={activeIndex}
//                   selectIndex={this.props.selectIndex}
//                   handleMouseMove={this.handleMouseMove}
//                   handleMouseOver={this.handleMouseOver}
//                 />
//                 )
//             })}          
//             </React.Fragment>
//           }
