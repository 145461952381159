import React from 'react'
import { withRouter} from 'react-router-dom'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import MessageReactionsPanel from '../messages/MessageReactionsPanel'
import MessageOpenThreadBtn from '../messages/MessageOpenThreadBtn'
import MessageHoverMenu from '../messages/MessageHoverMenu'
import MessageMediaGIF from '../messages/messageMedia/MessageMediaGIF'
import MessageMediaVideo from '../messages/messageMedia/MessageMediaVideo'
import MessageMediaImage from '../messages/messageMedia/MessageMediaImage'
import MessageDocSelectionPreview from '../messages/MessageDocSelectionPreview'
import MessageMediaDrawing from '../messages/messageMedia/MessageMediaDrawing'
import MessageMediaFigma from '../messages/messageMedia/MessageMediaFigma'
import {getMessageForId} from '../../../utils/getMessageForId'
import {getUserForId} from '../../../utils/getUserForId'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import VideoDuration from '../../../utils/videoDuration'
import {openDoc} from '../../../utils/openDoc'
import find from 'lodash/find'
// import MessageLinearIssueMentionReactComponent from './messageInputBox/nodeViews/MessageLinearIssueMentionReactComponent'
import StaticMessageLinearIssueMention from '../messages/staticMessageReactComponents/StaticMessageLinearIssueMention'
import StaticMessageLinearProjectMention from '../messages/staticMessageReactComponents/StaticMessageLinearProjectMention'
import StaticMessageInternalLink from '../messages/staticMessageReactComponents/StaticMessageInternalLink'
import {formatDate} from '../../../utils/formatDate'


function createMarkup(input) {
  return {__html: input}
}

//To add more reaction types add to this array (should work both in UI and API)
const reactionDefinitions=[
	{type:"like",emoji:"👍"},
	{type:"fire",emoji:"🔥"},
	{type:"bullseye",emoji:"🎯"}
]



class CommentThreadMarkerPopoverMessage extends React.PureComponent{  

	componentDidMount(){ //After mounted check for mention HTML and replace with react components
		this.els=[]
		const issueElements=ReactDOM.findDOMNode(this).getElementsByClassName('message-para-linearIssueOuterContainer')
		
		for (let i = 0; i < issueElements.length; ++i) {
			const el = issueElements[i];  
			const issueId=el.getAttribute("data-issue-id")
			ReactDOM.render(
				<StaticMessageLinearIssueMention 
					issueId={issueId}
				/>, el)
			this.els.push(el)
			}
		
		const projectElements=ReactDOM.findDOMNode(this).getElementsByClassName('message-para-projectLinkOuterContainer')
		for (let i = 0; i < projectElements.length; ++i) {
			const el = projectElements[i];  
			const projectId=el.getAttribute("data-project-id")
			ReactDOM.render(
				<StaticMessageLinearProjectMention 
					handleOpenDoc={(docId)=>{openDoc(this.props.history,docId)}}
					projectId={projectId}
				/>, el)
			this.els.push(el)
			}

		const linkElements=ReactDOM.findDOMNode(this).getElementsByClassName('message-para-internalLinkContainer')
		for (let i = 0; i < linkElements.length; ++i) {
			const el = linkElements[i];  
			const docId=el.getAttribute("data-doc-id")
			ReactDOM.render(
				<StaticMessageInternalLink 
					handleOpenDoc={(docId)=>{openDoc(this.props.history,docId)}}
					docId={docId}
				/>, el)
			this.els.push(el)
			}	
	}

	componentWillUnmount(){ //lets cleanup after ourselves
		this.els.forEach((el)=>{
			ReactDOM.unmountComponentAtNode(el)
		})
	}


	render(){	
		const {message,isCurrentUser,messageReactions,followOnMessage,messageIsHighlighted,threadParentMessage}=this.props
		
		const showReactionsPanel=messageReactions.length>0

		let videoTitle,muxId,videoPosterSrc,mediaDuration,videoTimestamp
		if(message.selection_type=='video' && message.selection_snapshot){
			const snapshot=message.selection_snapshot
			videoTitle = snapshot.title
			muxId = snapshot.muxId
			videoPosterSrc = `https://image.mux.com/${muxId}/thumbnail.png?width=160&height=90&fit_mode=crop&time=0`
			mediaDuration = "3:05"
			videoTimestamp = snapshot.timestampSeconds||null
			if(videoTimestamp){
				videoTimestamp=Math.floor(videoTimestamp)
			}
		}
		let imageTitle,imageSrc
		if(message.selection_type=='image' && message.selection_snapshot){
			const snapshot=message.selection_snapshot
			imageTitle = snapshot.title
			imageSrc = snapshot.deliveryUrl
		}

		let figmaEmbedTitle=''
		let figmaEmbedDeliveryUrl=''

		if(message.selection_type=='figmaEmbed' && message.selection_snapshot){
			const snapshot=message.selection_snapshot
			figmaEmbedTitle = snapshot.node_title
			figmaEmbedDeliveryUrl = snapshot.image_url
		}

		const parentMessageId=message.parent_message
		let parentMessage
		let parentMessageUserName
		if(parentMessageId){
			parentMessage=getMessageForId(parentMessageId)
			if(parentMessage){
				const parentMessageUser=getUserForId(parentMessage.created_by)
				if(parentMessageUser){
					parentMessageUserName=parentMessageUser.display_name
				}
			}		
		}
		let selectionIsValid=true
		if(message.message_selection){
			const selectionObj=find(this.props.messageSelections,{id:message.message_selection})
			if(selectionObj && !selectionObj.isValid){
				selectionIsValid=false
			}
		}
		
		const {auto_created}=message


	return (	  	
		<div id={`message_${message['message_id']}`} className={'messageContainer ' + (followOnMessage ? ' messageContainer--followOnMessage ' : '')}>
			<div className='message'>
				<div className='message-leftContainer'>					
					<AvatarImage 
						avatarUrl={this.props.avatarUrl}
						className='message-avatarContainer'
					/>
				</div>
				<div className='message-rightContainer'>
					<div className='message-header'>					
						<div className='message-header-name'>
							test
						</div>						
						<div className='message-header-timestamp'>
							{formatDate(message.created_at)}
						</div>						
					</div>

					<div className='message-content'>
						{parentMessageId && !message['is_thread'] &&
							<button className={'message-content-selection message-content-selection--message ' + (parentMessage['plain_text'] ? '' : ' message-content-selection--message--deleted ')} onClick={()=>{this.props.highlightMessage(parentMessageId)}}>
								<div className='message-content-selection-bar'/>
								<div className='message-content-selection-content'>
									{parentMessage['plain_text'] || 'Message deleted'}								
								</div>
							</button>
						}					
												
						{message.message_type=='richText' && message.selection_type=='docSelection' &&
							<MessageDocSelectionPreview
								docSelectionText={message.selection_snapshot?message.selection_snapshot.text:''}									
								selectionIsValid={selectionIsValid}
								goToDocSelection={()=>{this.props.handleClickOnDocSelection(message.message_selection)}}
								message={message}
							/>
						}					

						{message.message_type==='richText' && message.selection_type=='image' && 
							<button className='message-content-selection message-content-selection--image' onClick={()=>{this.props.handleClickOnMediaNodeSelection('image', message.selection_node_id)}}>								
								{/*{message.selection_snapshot} */}
								<div className='message-content-selection-bar' />
								<div className='message-content-selection-content'>
									<div className='message-content-selection-content-mediaContainer message-content-selection-content-mediaContainer--image'>
										<img src={imageSrc} className='message-content-selection-content-media' />
									</div>
									{imageTitle &&
									<div className='message-content-selection-content-mediaTitle'>										
										{imageTitle}
									</div>
									}
									{!imageTitle && 
									<div className='message-content-selection-content-mediaTitle message-content-selection-content-mediaTitle--untitled'>
										{selectionIsValid ? 'Untitled Image' : 'INVALID'}
									</div>
									}
								</div>
							</button>
						}

						{message.message_type==='richText' && message.selection_type=='figmaEmbed' && 
							<button className='message-content-selection message-content-selection--figmaEmbed' onClick={()=>{this.props.handleClickOnMediaNodeSelection('figmaEmbed', message.selection_node_id)}}>								
								{/*{message.selection_snapshot} */}
								<div className='message-content-selection-bar' />
								<div className='message-content-selection-content'>
									<div className='message-content-selection-content-mediaContainer message-content-selection-content-mediaContainer--figmaEmbed'>
										<img src={figmaEmbedDeliveryUrl} className='message-content-selection-content-media' />
									</div>									
									<div className='message-content-selection-content-mediaTitle'>										
										{figmaEmbedTitle}
									</div>
								</div>
							</button>
						}
					
						{(message.message_type==='richText' || message.message_type==='docSelection' || message.message_type==='nodeSelection') &&
							<div dangerouslySetInnerHTML={createMarkup(message.html)}/>
						}
						{message.message_type==='gif' &&
							<MessageMediaGIF tenorMp4Url={message.message_media_url} isUnread={message.isUnread}/>
						}
						{message.message_type==='video' &&
							<MessageMediaVideo cloudinaryID={message.message_media_url} />
						}
						{message.message_type==='image' &&
							<MessageMediaImage url={message.message_media_url}/>
						}												
					</div>        
				</div>
				</div>  	
				{showReactionsPanel &&
					<MessageReactionsPanel							
						hideReactions={this.hideReactions}
						messageId={message.id}
						messageReactions={messageReactions}
						userId={this.props.userId}
						docId={this.props.docId}
						addReactionToMessage={this.props.addReactionToMessage}
						deleteReaction={this.props.deleteReaction}
						messageId={message['message_id']}
						reactionDefinitions={reactionDefinitions}						
					/>
				}				
						
			</div>
		)
	}
}
 
export default CommentThreadMarkerPopoverMessage