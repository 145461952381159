import React from 'react'
import Icon from '../misc/Icon'
import {toggleTheme} from '../../utils/toggleTheme'
import { Spring, animated, config } from 'react-spring'

class OnboardingModalThemeDevice extends React.Component {    
  
  

  render() {
    
    

    return (             
      
      <div className={'onboardingModal-stage--theme-device ' + (this.props.theme ? ` onboardingModal-stage--theme-device--theme--${this.props.theme} `:'')}>
        
        <div className='onboardingModal-stage--theme-device-activeBorder' />

        <div className='onboardingModal-stage--theme-device-sideMenu'>
          <div className='onboardingModal-stage--theme-device-sideMenu-tab' />
          <div className='onboardingModal-stage--theme-device-sideMenu-tab' />
          <div className='onboardingModal-stage--theme-device-sideMenu-tab' />
          <div className='onboardingModal-stage--theme-device-sideMenu-tab' />          
        </div>
        <div className='onboardingModal-stage--theme-device-page'>
          <div className='onboardingModal-stage--theme-device-page-inner'>
            <div className='onboardingModal-stage--theme-device-page-inner-header' />
            <div className='onboardingModal-stage--theme-device-page-inner-calendar'>
              <div className='onboardingModal-stage--theme-device-page-inner-calendar-day'>
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-header' />
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-block' />
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-block' />
              </div>
              <div className='onboardingModal-stage--theme-device-page-inner-calendar-day'>
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-header' />
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-block' />                
              </div>
              <div className='onboardingModal-stage--theme-device-page-inner-calendar-day'>
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-header' />
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-block' />
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-block' />
              </div>
              <div className='onboardingModal-stage--theme-device-page-inner-calendar-day'>
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-header' />
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-block' />                
              </div>
              <div className='onboardingModal-stage--theme-device-page-inner-calendar-day'>
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-header' />
                <div className='onboardingModal-stage--theme-device-page-inner-calendar-day-block' />                
              </div>              
            </div>
            <div className='onboardingModal-stage--theme-device-page-inner-row' />
            <div className='onboardingModal-stage--theme-device-page-inner-row' />
            <div className='onboardingModal-stage--theme-device-page-inner-row' />
            <div className='onboardingModal-stage--theme-device-page-inner-row' />            
          </div>
        </div>        
      </div>
    )
  }
}


class OnboardingModalTheme extends React.Component {  	
  
  constructor(props) {
    super(props);  
    this.handleKeyDown=this.handleKeyDown.bind(this)  
    this.handleClickTheme=this.handleClickTheme.bind(this)   
    this.state = {
      
    }
  }

  componentDidMount() {
    if(this.props.stageVisible){
      window.addEventListener('keydown', this.handleKeyDown)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.stageVisible && this.props.stageVisible) {
      // component just became visible
      window.addEventListener('keydown', this.handleKeyDown)
    } else if (prevProps.stageVisible && !this.props.stageVisible) {
      // component just became invisible
     // console.log('theme unmount0000000000')
      window.removeEventListener('keydown', this.handleKeyDown)
    }
  }


  // componentDidMount() {
  //   window.addEventListener('keydown', this.handleKeyDown)    
  // }
  
  // componentWillUnmount() {
  //   console.log('theme unmount0000000000')
  //   window.removeEventListener('keydown', this.handleKeyDown)
  // }

  handleKeyDown(e){        
    if(e.keyCode==13){ //enter
      this.props.advanceStage()
    }
    const {theme}=this.props
    if(e.keyCode==39){ //right arrow      
      toggleTheme()
    }
    else if(e.keyCode==37){      
      toggleTheme()
    }    
  }

  handleClickTheme(theme){
    if(theme!==this.props.theme){
      toggleTheme()
    }
  }




  render() {

    const {theme, stageVisible} = this.props
    
    return (             
      
      <React.Fragment>
        
        {/*}
        {stageVisible && 
          <div className='onboardingModal-stage-skipBtnContainer'>
            <button onClick={this.advanceStage} className='onboardingModal-stage-skipBtn'>
              Skip
            </button>
          </div>
        }
        */}

        {stageVisible && 

          <div className='onboardingModal-stage-contentContainer onboardingModal-stage-contentContainer--centerAlign'>
            <div className='onboardingModal-stage-topSpacer' />
            <div className='onboardingModal-stage-h1'>
              Select your theme
            </div>
            {/*}
            <div className='onboardingModal-stage-keyHints'>
              Use arrow keys ← →
            </div>
            */}
            <div className='onboardingModal-stage--theme-selectBtnContainer'>
              <button onClick={()=>{this.handleClickTheme('light')}} data-state={theme=='light'?'active':'inactive'}  className='onboardingModal-stage-selectBtn onboardingModal-stage-selectBtn--theme'>                          
                <div className='onboardingModal-stage-selectBtn--theme-onboardingContainer'>
                  <OnboardingModalThemeDevice theme="light" />
                </div>          
                <div className='onboardingModal-stage-selectBtn--theme-border' />              
                <div className='onboardingModal-stage-selectBtn--theme-label'>
                  Light
                </div>
              </button>
              <button onClick={()=>{this.handleClickTheme('dark')}} data-state={theme=='dark'?'active':'inactive'} className='onboardingModal-stage-selectBtn onboardingModal-stage-selectBtn--theme'>                                     
                <div className='onboardingModal-stage-selectBtn--theme-onboardingContainer'>
                  <OnboardingModalThemeDevice theme="dark"/>
                </div>                
                <div className='onboardingModal-stage-selectBtn--theme-border' />
                <div className='onboardingModal-stage-selectBtn--theme-label'>
                  Dark
                </div>
              </button>
             
            </div>
            
            <div className='onboardingModal-stage--theme-continueBtnContainer'>
              <button onClick={this.props.advanceStage} className='onboardingModal-stage--theme-continueBtn'>
                <div className='onboardingModal-stage--theme-continueBtn-primaryLabel'>
                  Continue
                </div>
                <div className='onboardingModal-stage--theme-continueBtn-sub'>                
                  <div className='onboardingModal-stage--theme-continueBtn-sub-label'>
                    Enter
                  </div>
                  <div className='onboardingModal-stage--theme-continueBtn-sub-iconContainer'>
                    <Icon name='enterKey' />
                  </div>                
                </div>
              </button>
            </div>

            <div className='onboardingModal-stage-vSpacer' />

            
            <div className='onboardingModal-stage-bottomSpacer' />
          </div>

        }

      </React.Fragment>
    )
  }
}

export default OnboardingModalTheme