////Message mention node spec (different spec for doc)

function getAttrs(dom) {
  return {
    projectId: dom.getAttribute('data-project-id')
  }
}


const MessageLinearProjectMentionNodeSpec ={

  attrs: {
    projectId:{default:''}
  },
  inline: true,
  group: "inline",
  draggable: true,
  parseDOM: [
    {
        tag: "linearProject",
        getAttrs: (dom) => ({
          projectId: dom.getAttribute("data-project-id"),
        })}
    ],
    toDOM(node) {
      const {projectId} = node.attrs;
      return ["div",{class:`message-para-projectLinkOuterContainer`,"data-project-id":projectId}]
    }
}

export default MessageLinearProjectMentionNodeSpec


