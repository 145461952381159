import React from 'react'
import ReactDOM from 'react-dom';
import DocFigmaEmbedReactComponent from './DocFigmaEmbedReactComponent'
import {getFigmaEmbedForId} from '../../../utils/getFigmaEmbedForId'


class DocFigmaEmbedNodeView {

  constructor(node, view, getPos,newNodeMessage,docId,sendMediaModalMessage,setThreadParentMessage) {
    this.getNode=this.getNode.bind(this)
    this.deleteFigmaEmbed=this.deleteFigmaEmbed.bind(this)
    this.createNewNodeMessage=this.createNewNodeMessage.bind(this)
    this.updateNodeAttribute=this.updateNodeAttribute.bind(this)
    this.newNodeMessage=newNodeMessage
    this.sendMediaModalMessage=sendMediaModalMessage
    this.setThreadParentMessage=setThreadParentMessage
    this.node = node 
    this.getPos=getPos

    this.dom = document.createElement("div")
    this.dom.addEventListener("mousedown", this.handleDomClick)


    const {nodeId}=node.attrs
    this.dom.id=nodeId
    this.docId=docId
    this.renderReactComponent=this.renderReactComponent.bind(this)
    this.dom.className="doc-figmaWrapper"
    this.renderReactComponent()
  }
 
  handleDomClick(e){
    e.stopPropagation()
  }

  renderReactComponent(){
    const {embedId,documentTitle,crop,backgroundImage,backgroundPadding,deviceFrame,nodeId,messagesVersion}=this.node.attrs 
    ReactDOM.render(
      <DocFigmaEmbedReactComponent 
        embedId={embedId}
        documentTitle={documentTitle}
        crop={crop}
        backgroundImage={backgroundImage}
        backgroundPadding={backgroundPadding}
        deviceFrame={deviceFrame}
        updateNodeAttribute={this.updateNodeAttribute}
        deleteFigmaEmbed={this.deleteFigmaEmbed}
        newMessage={this.createNewNodeMessage}
        docId={this.docId}
        nodeId={nodeId}
        sendMediaModalMessage={this.sendMediaModalMessage}
        openThread={this.setThreadParentMessage}
        messagesVersion={messagesVersion}
      />, this.dom)
  }

  createNewNodeMessage(e,isDocThreadParent){
    e.stopPropagation()
    const {embedId}=this.node.attrs
    const embedObj=getFigmaEmbedForId(embedId)
    const snapshot=embedObj
    this.newNodeMessage(this.node.attrs.nodeId,'figmaEmbed',snapshot,isDocThreadParent)
  }

  updateNodeAttribute(field,value){
    const node=this.getNode()
    const nodePos=this.getPos(node)
    let attributes={...node.attrs}
    attributes[field]=value
    let tr = window.view.state.tr  
    tr.setNodeMarkup(nodePos, null, attributes)
    window.view.dispatch(tr)
  }

  update(node) {
    if (node.type !== this.node.type) return false
    this.node=node
    this.renderReactComponent()
    return true
  }

  // selectNode() {
  //   this.dom.classList.add("doc-figmaWrapper--selected")
  // }

  // deselectNode() {
  //   this.dom.classList.remove("doc-figmaWrapper--selected")
  // }

  getNode(){
    return this.node
  }

  deleteFigmaEmbed(e){
    e.stopPropagation()
    e.preventDefault()
    let tr = window.view.state.tr
    const nodePos=this.getPos()
    tr.delete(nodePos,nodePos+this.node.nodeSize)
    window.view.dispatch(tr)
  }

  stopEvent(event){ //prevent typing in modal input from bubbling to editor so that e.g. backspace doesnt delete 
    if(event.type=='keypress'){
       return true
    }
  }

  destroy() {
    this.dom.removeEventListener("mousedown", this.handleDomClick)
     ReactDOM.unmountComponentAtNode(this.dom)
  }


}

export default DocFigmaEmbedNodeView