import data from '../../../../../emojiData'
import { SearchIndex } from 'emoji-mart'



async function search(value) {
  const emojis = await SearchIndex.search(value)
  const results = emojis.map((emoji) => {
  	const obj={
  		id:emoji.id,
  		emoji:emoji.skins[0].native
  	}
  	return obj
    //return emoji.skins[0].native
  })

 // console.log(results)
  return results
}



export function getSuggestions(text,done){


	//const suggestions=['heart']
	if(text){
		const searchPromise=search(text)
	return Promise.resolve(searchPromise).then((results)=>{

			const slice=results.slice(0,10)

	//const filteredSuggestions=filterSuggestions(text,suggestions,isDropdown)
		return slice
	})
}else{
	return []
}
	

}