import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import {getProjectForId} from '../../../utils/getProjectForId'
import LinearMarker from '../../misc/LinearMarker'
import moment from 'moment'
import {openRoadmap} from '../../../utils/openRoadmap'
import RoadmapsHomePageQuarterProgressRing from './RoadmapsHomePageQuarterProgressRing'
import {updateQuarterlyRoadmap} from '../../../utils/roadmaps/updateQuarterlyRoadmap'

// Q1: January 1 - March 31
// Q2: April 1 - June 30
// Q3: July 1 - September 30
// Q4: October 1 - December 31


const quarterStartTimestamp = 'July 1'		  	
const quarterEndTimestamp = 'September 30'


function calculateQuarterPercentageLabel(){
	let currentDate = moment();
	let startOfQuarter = moment(currentDate).startOf('quarter');
	let endOfQuarter = moment(currentDate).endOf('quarter');
	let totalDays = endOfQuarter.diff(startOfQuarter, 'days') + 1;
	let passedDays = currentDate.diff(startOfQuarter, 'days') + 1;
	let percentagePassed = (passedDays / totalDays) * 100;
	const label=percentagePassed.toFixed(0) + '%'
	return label
}

function calculateWeeksLeftQuarter(){
	let currentDate = moment();
	let startOfQuarter = moment(currentDate).startOf('quarter');
	let endOfQuarter = moment(currentDate).endOf('quarter');
	let totalDays = endOfQuarter.diff(startOfQuarter, 'days') + 1;
	let passedDays = currentDate.diff(startOfQuarter, 'days') + 1;
	let daysLeft = (totalDays - passedDays);
	let weeksLeft = (totalDays - passedDays) / 7;
	const label=(Math.round(weeksLeft)) + ' weeks left'
	return label
}




class RoadmapsHomePageQuarterHeader extends React.Component{  	

	constructor(){
    super()  
    this.openRoadmap=this.openRoadmap.bind(this) 
    this.updateQuarterRoadmap=this.updateQuarterRoadmap.bind(this) 
    this.state={
    	showDropdown: false,
    }    
  }		

  updateQuarterRoadmap(roadmapId){
  	this.setState({showDropdown:false})
  	updateQuarterlyRoadmap(roadmapId)
  }

  openRoadmap(){
  	openRoadmap(this.props.history,this.props.roadmap.roadmap_id)
  }

	render(){		
		const {roadmap,roadmaps} = this.props
		const roadmapName = roadmap.name
  	const percentThroughQuarterLabel = calculateQuarterPercentageLabel()
  	const daysLeftLabel = calculateWeeksLeftQuarter()
	  return (	  	
  		<div className='roadmapsHomePage-quarter-header' >
  				<div className='roadmapsHomePage-quarter-header-visualContainer'>  
  								
  					<div className='roadmapsHomePage-quarter-header-subtitle-vizContainer'>
  						<RoadmapsHomePageQuarterProgressRing 
		  					startedProjects={this.props.startedProjects}
		  					plannedProjects={this.props.plannedProjects}
		  					backlogProjects={this.props.backlogProjects}
		  					completedProjects={this.props.completedProjects}
		  				/>
	  				</div>	 
  				</div>
  				<div className='roadmapsHomePage-quarter-header-leftContainer'>  			
	  				<div className='roadmapsHomePage-quarter-header-titleContainer'>  			
	  					<button className='roadmapsHomePage-quarter-header-title' onClick={this.openRoadmap}>
	  						{roadmapName}
	  					</button>
	  				</div>
	  				<div className='roadmapsHomePage-quarter-header-subtitle-label'>
							{quarterStartTimestamp} – {quarterEndTimestamp}<span>({percentThroughQuarterLabel} - {daysLeftLabel})</span>
						</div>	  					

	  			</div>
	  				
	  			<div className='roadmapsHomePage-quarter-header-rightContainer'>
	  				<DropdownMenu.Root open={this.state.showDropdown}>
					    <DropdownMenu.Trigger asChild>
					    	<button onClick={()=>{this.setState({showDropdown: !this.state.showDropdown})}} className='roadmapPage-header-topRow-btn roadmapPage-header-topRow-btn--dropdown'>
	  							<Icon name='ellipses' />
	  						</button>
					    </DropdownMenu.Trigger>   			 	
		   			 	<DropdownMenu.Content 
		   			 		sideOffset={1}
		   			 		align="end"
		   			 		alignOffset={-8}
		   			 		className='dropdownMenu dropdownMenu--roadmapHeader'
		   			 		onEscapeKeyDown={()=>{this.setState({showDropdown: false})}}
								onPointerDownOutside={()=>{this.setState({showDropdown: false})}}
								onInteractOutside={()=>{this.setState({showDropdown: false})}}
		   			 	> 
		   			 		
		   			 		<div className='contextMenu-subMenu--moveDoc-header'>
									Select Q3 Roadmap
								</div>
			        	{roadmaps.map((roadmap)=>{
		   			 			return(
		   			 				<DropdownMenu.Item onSelect={()=>{this.updateQuarterRoadmap(roadmap.roadmap_id)}} key={roadmap.roadmap_id}>
			        				{roadmap.name}
			        			</DropdownMenu.Item>
		   			 				)
		   			 		})}		   
					    </DropdownMenu.Content>
						</DropdownMenu.Root>	  


	  				{/*}
	  				<button onClick={()=>{this.setState({lookback: 'short'})}} className={'roadmapsHomePage-quarter-header-lookbackBtn roadmapsHomePage-quarter-header-lookbackBtn--short ' + (lookback === 'short' ? ' roadmapsHomePage-quarter-header-lookbackBtn--active ' : ' roadmapsHomePage-quarter-header-lookbackBtn--inactive ')}>
	  					21D
	  				</button>
	  				<button onClick={()=>{this.setState({lookback: 'long'})}} className={'roadmapsHomePage-quarter-header-lookbackBtn roadmapsHomePage-quarter-header-lookbackBtn--long '  + (lookback === 'long' ? ' roadmapsHomePage-quarter-header-lookbackBtn--active ' : ' roadmapsHomePage-quarter-header-lookbackBtn--inactive ')}>
	  					90D
	  				</button>
	  				*/}
	  			</div>
  			</div>
	  )
	}
}

export default withRouter(RoadmapsHomePageQuarterHeader)
