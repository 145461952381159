import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../misc/Icon'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import AvatarImage from '../AvatarImage'
import {getUserForId} from '../../utils/getUserForId'
import {openDoc,backgroundOpenDoc} from '../../utils/openDoc'
import {formatDate} from '../../utils/formatDate'
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import {createNewProjectDoc} from '../../utils/createNewDoc'
import sortBy from 'lodash/sortBy'
import LinearMilestoneIcon from '../misc/LinearMilestoneIcon'
import {getMeetingConnectionsForProject} from '../../utils/meetings/getMeetingConnectionsForProject'
import {getDocForMeeting} from '../../utils/meetings/getDocForMeeting'
import {getMeetingForId} from '../../utils/meetings/getMeetingForId'
import {createNewLinkedMeeting} from '../../utils/meetings/createNewMeeting'
import {getFormattedTimestampForMeeting} from '../../utils/meetings/getFormattedTimestampForMeeting'

class DocProjectSubDocsTableRowAvatarGroupAvatar extends React.Component{  
	render(){
   	const {user} = this.props
	  return (	  			
			<div className='docEditor-projectSubDocsTable-row-avatarGroup-avatarContainer'>				
				<AvatarImage
					className='docEditor-projectSubDocsTable-row-avatarGroup-avatar'
					avatarUrl={user.avatar}
				/>
			</div>  			
	  )
	}
}

	
class DocProjectSubDocsTableRow extends React.Component{  
	constructor(){
		super()
		this.handleTableRowClick=this.handleTableRowClick.bind(this)
	}

	handleTableRowClick(e){
		const {doc}=this.props
		if(e.metaKey){
			backgroundOpenDoc(doc.doc_id)
		}else{
			openDoc(this.props.history,doc.doc_id)
		}
	}
	render(){	
		const {doc, milestoneDoc, meetingDoc, subDoc, completedIssues, totalIssues} = this.props
		
		// for followers
		let followersArray=[]
		let sortedFollowersArray=[]
		if(doc && doc.followers){
			doc.followers.map((follower)=>{
				const user=getUserForId(follower.user_id)
				if(user && user.user_id){
					followersArray.push(user)
				}
			})
	     sortedFollowersArray=sortBy(followersArray, function(user){
				return user.display_name.toString().toLowerCase()
			})
	  }
	  let meetingType
	  let meetingFormattedDateTime
	  if(meetingDoc && this.props.meeting){
	  	meetingType = this.props.meeting.type
	  	meetingFormattedDateTime = getFormattedTimestampForMeeting(this.props.meeting,'projectSubDocTable')
	  }
	  return (	  	
  		<div className='docEditor-projectSubDocsTable-row' onClick={this.handleTableRowClick}>
  			<div className='docEditor-projectSubDocsTable-row-startSpacer'/>
  			{milestoneDoc && 
  				<div className='docEditor-projectSubDocsTable-row-milestoneIcon'>	
  					<LinearMilestoneIcon 
  						milestone={doc.milestoneObj}
  					/>
  				</div>
  			}
  			{meetingDoc && meetingType && meetingType !== 'blank' &&
  				<div className='docEditor-projectSubDocsTable-row-meetingDocIcon'>	
  					<MeetingTypeIcon 
  						//meetingId={meetingType}
  						meetingType={meetingType}
  					/>
  				</div>
  			}
  			{subDoc && 
  				<div className='docEditor-projectSubDocsTable-row-subDocIcon'>	
  					<Icon name='docSimple' />
  				</div>
  			}
  			<div className='docEditor-projectSubDocsTable-row-docName'>
  				{this.props.docName}  	
  				{meetingFormattedDateTime &&
  					<span className='docEditor-projectSubDocsTable-row-docName-dateTime'>
  						{meetingFormattedDateTime}
  					</span>
  				}
  				{milestoneDoc && 
		  			<span className='docEditor-projectSubDocsTable-row-milestoneStats'>
		  				<span className='docEditor-projectSubDocsTable-row-milestoneStats-progress'>
								{Math.floor(doc.milestoneObj.progressPercentage)}% 						
							</span>
							<span className='docEditor-projectSubDocsTable-row-milestoneStats-dash'>
								- 
							</span>
							<span className='docEditor-projectSubDocsTable-row-milestoneStats-total'>
								{doc.milestoneObj.scope}
							</span>
						</span>
					}
  			</div>
  			{/*}
  			<div className='docEditor-projectSubDocsTable-row-avatarGroup'>
  				{sortedFollowersArray.map((follower)=>{														
							return(																
								<DocProjectSubDocsTableRowAvatarGroupAvatar 
				  				key={follower.user_id}
				  				user={follower}
				  			/>
							)
					})}  			
				</div> 			
				*/}
				{!meetingDoc && 
	  			<div className='docEditor-projectSubDocsTable-row-timestampLabel'>
	  				{this.props.timestampLabel}
	  			</div>
	  		}
  			

        <div className='docEditor-projectSubDocsTable-row-endSpacer'/>
      </div>
	  )
	}
}


class DocProjectSubDocsTable extends React.Component{  


	handleOpenDocClick(){}

	render(){	
		const {projectSubDocs,projectMilestoneDocs,doc}=this.props 
		const hasSubDocs = projectSubDocs.length > 0
		let newButtonLabel = 'New Project Doc'
		if(hasSubDocs){
			newButtonLabel = 'New Doc'
		}
		const meetingConnections=getMeetingConnectionsForProject(doc.project)
	  return (	  	
			<div className={'docEditor-projectSubDocsTableContainer ' + (hasSubDocs ? 'docEditor-projectSubDocsTableContainer--hasDocs' : 'docEditor-projectSubDocsTableContainer--empty')}>
				<div className='docEditor-projectSubDocsTable'>
					<div className='docEditor-projectSubDocsTable-list'>	
						{projectMilestoneDocs && projectMilestoneDocs.map((doc)=>{
							let showTimestamp=true
							let timestampLabel=formatDate(doc.updated_at)
							if(doc.created_at==doc.updated_at){
								showTimestamp=false
								timestampLabel=null
							}
							return(
								<DocProjectSubDocsTableRow
									key={doc.doc_id}
									doc={doc}
									docName={doc.name}
									milestoneDoc
									history={this.props.history}
									completedIssues={14}
									totalIssues={14}
									timestampLabel={timestampLabel}
								/>
							)
						})}
			
						{projectSubDocs.map((doc)=>{
							return(
								<DocProjectSubDocsTableRow
									docName={doc.name}
									doc={doc}
									subDoc
									timestampLabel={formatDate(doc.updated_at)}
									history={this.props.history}
									// openDoc={()=>{openDoc(this.props.history,doc.doc_id)}}
									// backgroundOpenDoc={()=>{backgroundOpenDoc(doc.doc_id)}}
									key={doc.doc_id}									
								/>
							)
						})}				

						{/* EXAMPLE MEETINGS */}		
						{meetingConnections.map((connection,i)=>{
							const meetingId=connection.meeting
							const meeting=getMeetingForId(meetingId)
							const meetingDoc=getDocForMeeting(meetingId) ||{}
							return(
								<DocProjectSubDocsTableRow
									key={`meeting_row_${meeting?meeting.meeting_id:''}`}
									doc={meetingDoc}
									docName={meetingDoc.name}
									history={this.props.history}
									meeting={meeting}
									meetingDoc											
								/>
								)
						})}
						{/*
						<DocProjectSubDocsTableRow
							//key={doc.doc_id}
							//doc={doc}
							docName="Design Review - May 24"
							meetingDoc											
						/>

						<DocProjectSubDocsTableRow
							//key={doc.doc_id}
							//doc={doc}
							docName="Team Sync - June 1"
							meetingDoc											
						/>
*/}	

					</div>
				
					<div className='docEditor-projectSubDocsTable-newDocBtnContainer'>
						<button onClick={()=>{createNewProjectDoc(this.props.history,doc.project)}} className='docEditor-projectSubDocsTable-newDocBtn '>							
							<div className='docEditor-projectSubDocsTable-newDocBtn-iconContainer'>
								<Icon name='plus' />
							</div>							
							<div className='docEditor-projectSubDocsTable-newDocBtn-label'>
								{newButtonLabel}
							</div>
						</button>
						<button onClick={()=>{createNewLinkedMeeting('designReview',this.props.doc.project,this.props.history)}} className='docEditor-projectSubDocsTable-newDocBtn docEditor-projectSubDocsTable-newDocBtn--meeting'>							
								<div className='docEditor-projectSubDocsTable-newDocBtn-iconContainer'>
									<Icon name='calendar' />
								</div>							
							<div className='docEditor-projectSubDocsTable-newDocBtn-label'>
								{hasSubDocs ? 'New Design Review' : 'New Design Review Meeting'}
							</div>
						</button>

						{/*}
						<div className='docEditor-projectSubDocsTable-newDocBtnKeyboardShortcut'>
							<KeyboardShortcutTag 
								commandMedium
								shiftOutlineMedium
								letter='N'
							/>	      			
						</div>
						*/}
					</div>				
				</div>
			</div>
	  )
	}
}

export default withRouter(DocProjectSubDocsTable)

