import React from 'react'
import Icon from '../misc/Icon'
import LinearProjectStatusIcon from '../misc/LinearProjectStatusIcon'
import {updateProjectStatus} from '../../utils/updateProject/updateProjectStatus'
import {getOrgMembers} from '../../utils/getOrgMembers'
import {getProjectForId} from '../../utils/getProjectForId'
import AvatarImage from '../AvatarImage'
import StaticCheckbox from '../uiKit/StaticCheckbox'
import sortBy from 'lodash/sortBy'

class EditProjectMemberRow extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectMember=this.handleSelectMember.bind(this)
		this.state={
      checked: this.props.active
    }
	}

	handleSelectMember(){
		const newCheckedStatus=!this.state.checked
		let newMembersArray=[]
		if(newCheckedStatus==true){
			newMembersArray=this.props.projectMembers
			newMembersArray.push(this.props.user.user_id)
		}else{
			this.props.projectMembers.forEach((memberId)=>{
				if(memberId!=this.props.user.user_id){
					newMembersArray.push(memberId)
				}
			})
		}
		this.setState({checked: newCheckedStatus})		
		this.props.updateProjectMembers(newMembersArray)
	}

	render(){	
		const {active, user} = this.props	
		const {checked} = this.state
		return (
			<div onClick={this.handleSelectMember} className='popoverMenu-item popoverMenu-item--editProjectLead'>
				<div className='popoverMenu-item-avatarContainer'>
					<AvatarImage smallAvatar className='popoverMenu-item-avatar' avatarUrl={user.avatar}/> 
				</div>
				<div className='popoverMenu-item-label'>
					{user.display_name}
				</div>				
				<div className='popoverMenu-item-checkboxContainer'>
					<StaticCheckbox 
						checked={checked}
					/>					
				</div>	
			</div>
		)
	}
}



class EditProjectMember extends React.Component{  	

	render(){	
		const {closePopover,members,leadUserId} = this.props
		let projectMembers = members
		const orgMembers = getOrgMembers()
		const sortedMembers = sortBy(orgMembers, function(user){
			return user.display_name.toString().toLowerCase()
   	})
		return (
			<React.Fragment>         	
				{sortedMembers.map((orgMember) => {
				  const isActive = projectMembers.some(projectMember => projectMember === orgMember.user_id);
				 	if(orgMember.user_id !== leadUserId){
				 		return (
					    <EditProjectMemberRow
					      key={orgMember.user_id}
					      closePopover={closePopover}
					      user={orgMember}
					      projectMembers={projectMembers}
					      updateProjectMembers={this.props.updateProjectMembers}
					      active={isActive} // Set the active prop based on the isActive variable
					    />
				  	);
				 	}
				  
				})}				

			</React.Fragment>
		)
	}
}

export default EditProjectMember