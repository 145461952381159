import React from 'react'

class PopoverItemDivider extends React.Component{  	

	render(){	

	  return (	  	
	  	<div className='popover-divider' />
	  )
	}
}

export default PopoverItemDivider
