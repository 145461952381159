import React from 'react'
import Icon from '../../../misc/Icon'
import VideoDuration from '../../../../utils/videoDuration'
import ReactSlider from 'react-slider'

const TIMEOUT_INTERVAL=250

class MessageMediaHighlightVideoPlaybackControls extends React.Component{  

	constructor(props){
    super(props)        
		this.onClick=this.onClick.bind(this)
    this.onDoubleClick=this.onDoubleClick.bind(this)
    this.timer=null    
  }  

  componentWillUnmount(){
		if(this.timer){
			clearTimeout(this.timer)
		}
	}

	onClick(e){
		e.stopPropagation()
		e.preventDefault()
		if(this.timer){
			clearTimeout(this.timer)
		}
		this.timer=setTimeout(function() {
			this.props.handlePlayPause()
		}.bind(this), TIMEOUT_INTERVAL)
	}

	onDoubleClick(){
		if(this.timer){
			clearTimeout(this.timer)
		}
		this.props.handleZoom		
	}

	

	render(){	

		const {	playing, played, hasPlayed, progressSeconds, 
						duration, zoomed, handleZoomAndPlay, toggleZoom, mouseMovedShort, 
						mouseMovedLong, handleSeekMouseUp, handleSeekMouseDown, handleSeekChange, 
						handlePlayPause, transcriptActive, toggleTranscript, handleTranscriptAndPlay, handleTranscriptAndZoomAndPlay
				} = this.props
		
		const initialState = played === 0 && !playing && !hasPlayed && !this.props.isAudio

		let showControlBar

		if(!hasPlayed && mouseMovedShort && !initialState){
			showControlBar = true
		}
		if(!playing && hasPlayed && !initialState){
			showControlBar = true
		}
		if(playing && mouseMovedShort && !initialState){
			showControlBar = true
		}

		if(this.props.isAudio){
			showControlBar = true
		}

		let showZoomBtn

		if(!hasPlayed){
			showZoomBtn = true
		}
		if(!playing && hasPlayed){
			showZoomBtn = true
		}
		if(playing && mouseMovedShort){
			showZoomBtn = true
		}



		return (
			
				<div className={'message-content-highlight-video-playbackControlsContainer ' + (mouseMovedShort ? ' message-content-highlight-video-playbackControlsContainer--mouseMovedShort--active ' : ' message-content-highlight-video-playbackControlsContainer--mouseMovedShort--inactive ') + ((!mouseMovedLong && playing) ? ' message-content-highlight-video-playbackControlsContainer--cursor--hide ' : ' message-content-highlight-video-playbackControlsContainer--cursor--show ')}>					
					
					
						<div data-state={showControlBar ? 'visible' : 'hidden'} className='message-content-highlight-video-playbackControls'>
							<div className='message-content-highlight-video-playbackControls-seekBarContainer'>
								<ReactSlider
		              className="message-content-highlight-video-playbackControls-seekBar"
		              thumbClassName="message-content-highlight-video-playbackControls-seekBar-thumb"
		              trackClassName="message-content-highlight-video-playbackControls-seekBar-track"                  
		              renderThumb={(props, state) => <div className='message-content-highlight-video-playbackControls-seekBar-thumb-thumb' {...props} />}
		              value={played}
		              min={0.000001}
		              max={0.999999}
		              step={0.0001}
		              onAfterChange={handleSeekMouseUp}
		              onBeforeChange={handleSeekMouseDown}
		              onChange={handleSeekChange}	              
		            />              							
							</div>

							<button onClick={this.props.handlePlayPause} className='message-content-highlight-video-playbackControls-btn message-content-highlight-video-playbackControls-btn--playback'>
								<div className='message-content-highlight-video-playbackControls-btn-iconContainer'>
									{playing &&
										<Icon name='playbackPause' />
									}
									{!playing &&
										<Icon name='playbackPlay' />
									}								
								</div>
							</button>

							<div className='message-content-highlight-video-playbackControls-timestamp message-content-highlight-video-playbackControls-timestamp--progress'>
								<VideoDuration seconds={this.props.progressSeconds} />
							</div>

							<div className='message-content-highlight-video-playbackControls-timestamp message-content-highlight-video-playbackControls-timestamp--total'>
								<VideoDuration seconds={this.props.duration} />
							</div>

							<div className='message-content-highlight-video-playbackControls-hSpacer' />
							
							{!this.props.isAudio &&
							<button onClick={this.props.toggleZoom} className='message-content-highlight-video-playbackControls-btn message-content-highlight-video-playbackControls-btn--zoom'>
								<div className='message-content-highlight-video-playbackControls-btn-iconContainer'>
									<Icon name={zoomed ? 'unZoomMedia' : 'expandVideo'} />
								</div>
							</button>
							}
						</div>
					




					{initialState && 
						<div className='message-content-highlight-video-loadState'>
							<button onClick={this.props.handlePlay} className='message-content-highlight-video-playbackControls-btn message-content-highlight-video-playbackControls-btn--playback'>
								<div className='message-content-highlight-video-playbackControls-btn-iconContainer'>
									<Icon name='playbackPlay' />
								</div>
							</button>

							<div className='message-content-highlight-video-playbackControls-timestamp message-content-highlight-video-playbackControls-timestamp--total'>
								<VideoDuration seconds={this.props.duration} />
							</div>
						</div>
					}
					
					{/*}
					{initialState && 
						<button onClick={handleTranscriptAndZoomAndPlay} onDoubleClick={handleTranscriptAndZoomAndPlay} className='message-content-highlight-video-playbackControls-btn message-content-highlight-video-playbackControls-btn--playInitial'>
							<div className='message-content-highlight-video-playbackControls-btn-iconContainer'>
								{playing &&
									<Icon name='playbackPlay' />
								}	
							</div>
							<div className='message-content-highlight-video-playbackControls-btn-label'>
								<VideoDuration seconds={this.props.duration} />
							</div>
						</button>
					}
					*/}

		


					{/*}
					<button onClick={toggleZoom} className={'message-content-highlight-video-playbackControls-btn message-content-highlight-video-playbackControls-btn--toggleTranscript' + (showZoomBtn ? ' message-content-highlight-video-playbackControls-btn--toggleTranscript--show ' : ' message-content-highlight-video-playbackControls-btn--toggleTranscript--hide ')}>
						<Icon name={zoomed ? 'unZoomMedia' : 'expandVideo'} />
					</button>
					*/}

				{/*}
					<button onClick={toggleTranscript} className={'message-content-highlight-video-playbackControls-btn message-content-highlight-video-playbackControls-btn--toggleTranscript' + (showZoomBtn ? ' message-content-highlight-video-playbackControls-btn--toggleTranscript--show ' : ' message-content-highlight-video-playbackControls-btn--toggleTranscript--hide ')}>
						<div className='message-content-highlight-video-playbackControls-btn-iconContainer'>
							<Icon name={transcriptActive ? 'transcriptFill' : 'transcript'} />
						</div>						
					</button>
					*/}

					{/*}
					<button onClick={toggleZoom} className={'message-content-highlight-video-playbackControls-btn message-content-highlight-video-playbackControls-btn--toggleZoom ' + (showZoomBtn ? ' message-content-highlight-video-playbackControls-btn--toggleZoom--show ' : ' message-content-highlight-video-playbackControls-btn--toggleZoom--hide ')}>
						<div className='message-content-highlight-video-playbackControls-btn-iconContainer'>
							<Icon name={zoomed ? 'unZoomMedia' : 'expandVideo'} />
						</div>						
					</button>


					
					

					{(played > 0 || playing) &&
						<div className={'message-content-highlight-video-playbackControls-seekbar ' + (showControlBar ? 'message-content-highlight-video-playbackControls-seekbar--show' : 'message-content-highlight-video-playbackControls-seekbar--hide')}>							

							<div className='message-content-highlight-video-playbackControls-seekBarContainer'>
								<ReactSlider
	                className="message-content-highlight-video-playbackControls-seekBar"
	                thumbClassName="message-content-highlight-video-playbackControls-seekBar-thumb"
	                trackClassName="message-content-highlight-video-playbackControls-seekBar-track"                  
	                renderThumb={(props, state) => <div className='message-content-highlight-video-playbackControls-seekBar-thumb-thumb' {...props} />}

	                value={played}
	                min={0.000001}
	                max={0.999999}
	                step={0.0001}
	                onAfterChange={handleSeekMouseUp}
	                onBeforeChange={handleSeekMouseDown}
	                onChange={handleSeekChange}
	              />              
							</div>

							

						</div>
					}
					*/}
					

					<button onClick={this.props.handlePlayPause} onDoubleClick={this.props.toggleZoom} className='message-content-highlight-video-playbackControlsBgPlayPause' />
					
				</div>			
		)
	}
}
 


export default MessageMediaHighlightVideoPlaybackControls
