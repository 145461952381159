import React, { Component } from 'react'
import { withRouter} from 'react-router-dom'
import { Mixpanel } from '../Mixpanel'


class DeepLinkContainer extends Component {
	
	componentDidMount() {	
		if(window.isElectron){
			window.ipcRenderer.receive('deeplink-redirect', (args) => {
				this.props.history.push(`/${args.url}`)
				Mixpanel.track('deep-link',{url:args.url})
			});
		}
	}

	render() {
		return null	
	}
}

export default withRouter(DeepLinkContainer)


	