import React from 'react'
import Icon from '../misc/Icon'
import {formatDate} from '../../utils/formatDate'


class DocSaveStatus extends React.Component{  


	constructor(){
		super()
		this.state={
			timestampKey:0,
		}	
		this.interval=null
	}

	componentDidMount() {	//force rerender so the timestamp updates if doc is not being edited
		this.interval=setInterval(() => { 
			const newKey=this.state.timestamp+1
			this.setState({timestampKey:newKey})
  	},20000)
	}

	componentWillUnmount() {
		if(this.interval){
			clearInterval(this.interval)
		}
	}


  render(){ 

    const {updatedAtTimestamp}=this.props
    return (      
      <div className='docEditor-saveStatus'>
{/*				Edited 20 minutes ago */}
				Edited {formatDate(updatedAtTimestamp)}     
      </div>   
    )
  }
}

export default DocSaveStatus
