import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { exchangeFigmaCode } from "../actions/figma"
import qs from 'qs'



class FigmaIntegrationCallbackPage extends Component {


  componentDidMount(){
    const query=qs.parse(this.props.location.search.slice(1))
    const code=query.code
    if(code){
      this.props.exchangeFigmaCode(code).then((response)=>{   //get code from url and use it to request login from API
        this.props.history.replace("/")
        this.props.history.push('/settings')
    })
    }
  }

  render(){  
    return null
}
}



function mapStateToProps(state,ownProps) {

  return {
    
  }
}


export default withRouter(connect(mapStateToProps,
  {exchangeFigmaCode}
)(FigmaIntegrationCallbackPage))  


