import {InputRule} from 'prosemirror-inputrules'
import editorSchema from '../../schema/editorSchema'
import {getAnchorNode} from '../../utils/getAnchorNode'
import {TextSelection} from "prosemirror-state"
import {isInTable} from 'prosemirror-tables'

const squareBracketRule = new RegExp(/^\[\]$/);

export function toDoListItemInputRule(){
	return new InputRule(/^\[\]\s/g, (state, match, start, end) => {
		const inTable=isInTable(state)
		if(inTable){
			return false
		}else{
		let tr = state.tr
		let sel = state.selection
		const $from=state.doc.resolve(sel.anchor)
		const anchorNode=getAnchorNode($from,'paragraph')
		if(anchorNode){
			let indentLevel=0
			if(anchorNode){
				indentLevel=anchorNode.attrs.indentLevel
			}
			//check if anchor node (paragraph) is empty- if so replace it with todo
			//otherwise add todo after paragraph			
	
			let type = editorSchema.nodes.toDoItem
			let attrs={	
				indentLevel:indentLevel
			}
			const node = type.createAndFill(attrs) 
			tr.replaceWith(start-1, end+1, node)
			let selection=new TextSelection(tr.doc.resolve(start+1))
			tr.setSelection(selection)
			return tr
		}else return false
	}
	})
}


