import React from 'react'
import Icon from '../../misc/Icon'
import SelectUserPopover from '../../uiKit/SelectUserPopover'
import sortBy from 'lodash/sortBy'
import {pingCursorToUser} from '../../../utils/pingCursorToUser'
import { withRouter} from 'react-router-dom'
import {isCurrentUser} from '../../../utils/isCurrentUser'
//export function pingCursorToUser(userId,docId,history){	

class SideMenuFooterDocDefault extends React.Component{  	


	constructor(props){
    super(props)
    this.state={
      showPingDocPopover: false,
    }
  }

	render(){	
		const {showPingDocPopover} = this.state
		const {orgMembers} = this.props
    let membersArray=[]
    if(orgMembers){
      Object.keys(orgMembers).forEach((key)=>{
        let member=orgMembers[key]    
        if(!isCurrentUser(member.user_id)){
        	 membersArray.push(member)
        }   
      })
    }

    const sortedMembers = sortBy(membersArray, function(member){
      return member.name.toString().toLowerCase()
    })

    const userList = sortedMembers


	  return (					
			<div className='sideMenu-tabList-footer sideMenu-tabList-footer--docDefault'>
				<div className='sideMenu-tabList-footer-divider' />																				
					<SelectUserPopover
						actionType='pingDoc'				
						inverted					
						showPopover={showPingDocPopover}
						openPopover={()=>{this.setState({showPingDocPopover: true})}}
						closePopover={()=>{this.setState({showPingDocPopover: false})}}
						onClickUserFunction={(userId)=>{pingCursorToUser(userId,this.props.doc.doc_id,this.props.history)}}
						userList={userList}
					>	
						<button disabled={showPingDocPopover} onClick={()=>{this.setState({showPingDocPopover: true})}}  className={'sideMenu-tabList-footer--docDefault-btn ' + (showPingDocPopover ? ' sideMenu-tabList-footer--docDefault-btn--menuOpen ' : ' sideMenu-tabList-footer--docDefault-btn--menuClosed ')}>
				    	<div className='sideMenu-tabList-footer--docDefault-btn-iconContainer'>
								<Icon name='broadcast' /> 
							</div>
							<div className='sideMenu-tabList-footer--docDefault-btn-labelContainer'>								
								<div className='sideMenu-tabList-footer--docDefault-btn-label sideMenu-tabList-footer--docDefault-btn-label--docName'>							
									Ping Doc
									{/*{this.props.doc.name}*/}
								</div>								
								<div className='sideMenu-tabList-footer--docDefault-btn-label sideMenu-tabList-footer--docDefault-btn-label--instructions'>															
									Select teammate...
								</div>												
							</div>
						</button>
					</SelectUserPopover>
			</div>									
	  )
	}
}

export default withRouter(SideMenuFooterDocDefault)