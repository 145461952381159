import React from 'react'
import Icon from '../../../../components/misc/Icon'
import LinearIssueWorkflowStateIcon from '../../../../components/misc/LinearIssueWorkflowStateIcon'
import LinearMarker from '../../../../components/misc/LinearMarker'
import AvatarImage from '../../../../components/AvatarImage'
import LinearIssuePreviewPopoverContent from '../../../../components/docEditor/mentionPopovers/LinearIssuePreviewPopoverContent'
import LinearProjectPreviewPopoverContent from '../../../../components/docEditor/mentionPopovers/LinearProjectPreviewPopoverContent'
import {getDocForId} from '../../../../utils/getDocForId'
import {getIssueForId} from '../../../../utils/getIssueForId'
import {getProjectForId} from '../../../../utils/getProjectForId'
import {getRoadmapForId} from '../../../../utils/getRoadmapForId'
import {getUserForId} from '../../../../utils/getUserForId'
import {isCurrentUser} from '../../../../utils/isCurrentUser'
import {getOrg} from '../../../../utils/getOrg'

function createMarkup(input) {
  return {__html: input}
}

const activeClass='messageInsertMentionsMenu-item--active'


class MessageInsertMentionsMenuItem extends React.Component{  
  
  render(){ 
    const {index,suggestion,activeIndex,suggestionType}=this.props

    if(suggestionType=='doc'){
     const doc=getDocForId(suggestion.doc_id)||{}
      let docType
      let project
      let roadmap
      let org
      if(suggestion.project){
        project = getProjectForId(suggestion.project)
        docType = 'projectSubDoc'
      }else if(suggestion.roadmap){
        roadmap = getRoadmapForId(suggestion.roadmap)
        docType = 'roadmapDoc'
      }else if(suggestion.is_org_doc){
        org = getOrg()
        docType = 'orgDoc'
      }
      return(
        <div 
          key={index}
          onClick={()=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'messageInsertMentionsMenu-item ' + (suggestionType ? ` messageInsertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' messageInsertMentionsMenu-item--active ': ' ') + (activeIndex == index ? ' messageInsertMentionsMenu-item--mouseMoved ': ' messageInsertMentionsMenu-item--mouseNotMoved ')}         
        >
          <div className='messageInsertMentionsMenu-item-label'>
            {doc.name}
          </div>
          {docType === 'projectSubDoc' && project &&
            <div className='messageInsertMentionsMenu-item--doc-projectTag'>
              <div className='messageInsertMentionsMenu-item--doc-projectTag-marker'>
                <LinearMarker 
                  projectId={suggestion.project}
                />
              </div>
              <div className='messageInsertMentionsMenu-item--doc-projectTag-label'>
                {project.name}
              </div>
            </div>
          }
          {docType === 'roadmapDoc' && roadmap &&
            <div className='insertMentionsMenu-item--doc-roadmapTag'>
              <div className='insertMentionsMenu-item--doc-roadmapTag-icon'>
                <Icon name='roadmapOutline' />
              </div>
              <div className='insertMentionsMenu-item--doc-roadmapTag-label'>
                {roadmap.name}
              </div>
            </div>
          }
          {docType === 'orgDoc' && org &&
            <div className='insertMentionsMenu-item--doc-orgTag'>
              <div className='insertMentionsMenu-item--doc-orgTag-image'>
                <img src={org.logo} className='insertMentionsMenu-item--doc-orgTag-image-image' />                        
              </div>
              <div className='insertMentionsMenu-item--doc-orgTag-label'>
                {org.name}
              </div>
            </div>
          }
          <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='messageInsertMentionsMenu-item-hoverPreviewContainer'>                        
            <div className='messageInsertMentionsMenu-item--doc-previewInnerContainer popover'>
              <div className='messageInsertMentionsMenu-item--doc-preview'>
                <div className='messageInsertMentionsMenu-item--doc-preview-doc'>
                  <div className='messageInsertMentionsMenu-item--doc-preview-doc-title'>
                    {doc.name}
                  </div>   
                  <div 
                    key={suggestion.doc_id} 
                    className='messageInsertMentionsMenu-item--doc-preview-doc-innerWrapper' 
                    dangerouslySetInnerHTML={createMarkup(doc.doc_html)}                
                  />
                </div>
              </div>
            </div>
          </div>       
        </div> 
      )
    }else if(suggestionType=='user'){
      let currentUser = false
      if(suggestion.user_id){
        currentUser = isCurrentUser(suggestion.user_id)
      }
      return(
        <div 
          key={index}
          onClick={()=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'messageInsertMentionsMenu-item ' + (suggestionType ? ` messageInsertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' messageInsertMentionsMenu-item--active ': ' ')}         
        >
          <div className='messageInsertMentionsMenu-item--user-avatarContainer'>
            <AvatarImage avatarUrl={suggestion.avatar} />
          </div>
          <div className='messageInsertMentionsMenu-item-label'>
            {suggestion.display_name} {currentUser && <span className='messageInsertMentionsMenu-item--user-currentUserLabel'>you</span>}
          </div>
        </div>            
      )
    }else if(suggestionType=='project'){
      return (
        <div 
          key={index}
          onClick={()=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'messageInsertMentionsMenu-item ' + (suggestionType ? ` messageInsertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' messageInsertMentionsMenu-item--active ': ' ')}         
        >
          <div className='messageInsertMentionsMenu-item--project-markerContainer'>
            <LinearMarker projectId={suggestion.project_id} />
          </div>
          <div className='messageInsertMentionsMenu-item-label'>
           {suggestion.name} 
          </div>
          <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='messageInsertMentionsMenu-item-hoverPreviewContainer'>            
            <LinearProjectPreviewPopoverContent
              name={suggestion.name}
              leadUserId={suggestion.lead}
              description={suggestion.description}
              memberIds={suggestion.members}
              teamIds={suggestion.teams}
              status={suggestion.state}
              projectId={suggestion.project_id}
            />
          </div>
        </div>            
      )
    }else if(suggestionType=='issue'){
      const issueObj = getIssueForId(suggestion.issue_id)
      const issueWorkflowColorHex=issueObj.workflow_state.color
      const issueWorkflowType=issueObj.workflow_state.type
      const issueWorkflowStateName=issueObj.workflow_state.name 
      const issueWorkflowTypeStateIndex = issueObj.workflow_state.workflowTypeStateIndex
      const issueWorkflowTypeStatesCount = issueObj.workflow_state.workflowTypeStatesCount           
      const projectObj = getProjectForId(suggestion.project) 
      const assigneeObj=getUserForId(suggestion.assignee)         
      return (
        <div 
          key={index}
          onClick={()=>{this.props.selectIndex(activeIndex)}}
          onMouseMove={()=>{this.props.handleMouseMove(index)}} 
          onMouseOver={()=>{this.props.handleMouseOver(index)}} 
          className={'messageInsertMentionsMenu-item ' + (suggestionType ? ` messageInsertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' messageInsertMentionsMenu-item--active ': ' ')}         
        >
          <div className='messageInsertMentionsMenu-item--issue-workflowStateIconContainer'>
            <LinearIssueWorkflowStateIcon
              workflowColorHex={issueWorkflowColorHex}
              workflowType={issueWorkflowType}
              workflowTypeStateIndex={issueWorkflowTypeStateIndex}
              workflowTypeStatesCount={issueWorkflowTypeStatesCount}         
            />
          </div>
      
          <div className='messageInsertMentionsMenu-item-label'>
           {suggestion.title}
          </div>
          <div className='messageInsertMentionsMenu-item--issue-identifierContainer'>
            {suggestion.identifier}
          </div>
          <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='messageInsertMentionsMenu-item-hoverPreviewContainer'>
           {projectObj &&
            <LinearIssuePreviewPopoverContent
              projectId={projectObj.project_id}
              project={projectObj}               
              identifier={suggestion.identifier}
              title={suggestion.title}
              assignee={assigneeObj}
              workflowColorHex={issueWorkflowColorHex}
              workflowType={issueWorkflowType}
              workflowTypeStatesCount={issueWorkflowTypeStatesCount}
              workflowTypeStateIndex={issueWorkflowTypeStateIndex}
              workflowStateName={issueWorkflowStateName}
              priority={suggestion.priority}                   
              description={suggestion.description}
            />
          }
          </div>
        </div>            
      )
    }


    return (
      <div 
        key={index}
        onClick={()=>{this.props.selectIndex(activeIndex)}}
        onMouseMove={()=>{this.props.handleMouseMove(index)}} 
        onMouseOver={()=>{this.props.handleMouseOver(index)}} 
        className={'messageInsertMentionsMenu-item ' + (suggestionType ? ` messageInsertMentionsMenu-item--${suggestionType} ` : ' ') + (activeIndex == index ? ' messageInsertMentionsMenu-item--active ': ' ')}         
      >
        {suggestionType=='project' &&
          <div className='messageInsertMentionsMenu-item--project-markerContainer'>
            <LinearMarker projectId={suggestion.project_id} />
          </div>
        }

        {suggestionType=='issue' &&
          <div className='messageInsertMentionsMenu-item--issue-workflowStateIconContainer'>
            <LinearIssueWorkflowStateIcon
              workflowColorHex={issueWorkflowColorHex}
              workflowType={issueWorkflowType}
              workflowTypeStateIndex={issueWorkflowTypeStateIndex}
              workflowTypeStatesCount={issueWorkflowTypeStatesCount}         
            />
          </div>
        }

        {suggestionType=='user' &&
          <div className='messageInsertMentionsMenu-item--user-avatarContainer'>
            <AvatarImage avatarUrl={suggestion.avatar} />
          </div>
        }

        <div className='messageInsertMentionsMenu-item-label'>
         {suggestionLabel} {currentUser && <span className='messageInsertMentionsMenu-item--user-currentUserLabel'>you</span>}
        </div>

        
        {suggestionType=='issue' &&
          <div className='messageInsertMentionsMenu-item--issue-identifierContainer'>
            {suggestion.identifier}
          </div>
        }


        {suggestionType=='doc' && docType === 'projectSubDoc' && docProjectObj &&
          <div className='messageInsertMentionsMenu-item--doc-projectTag'>
            <div className='messageInsertMentionsMenu-item--doc-projectTag-marker'>
              <LinearMarker 
                projectId={suggestion.project}
              />
            </div>
            <div className='messageInsertMentionsMenu-item--doc-projectTag-label'>
              {docProjectObj.name}
            </div>
          </div>
        }


        {suggestionType === 'issue' &&
          <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='messageInsertMentionsMenu-item-hoverPreviewContainer'>
            
            <LinearIssuePreviewPopoverContent
              projectId={projectObj.project_id}
              project={projectObj}               
              identifier={suggestion.identifier}
              title={suggestion.title}
              assignee={assigneeObj}
              workflowColorHex={issueWorkflowColorHex}
              workflowType={issueWorkflowType}
              workflowTypeStatesCount={issueWorkflowTypeStatesCount}
              workflowTypeStateIndex={issueWorkflowTypeStateIndex}
              workflowStateName={issueWorkflowStateName}
              priority={suggestion.priority}                   
              description={suggestion.description}
            />
            
          </div>
        } 
        
        {suggestionType === 'project' && 
          <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='messageInsertMentionsMenu-item-hoverPreviewContainer'>            
            
            <LinearProjectPreviewPopoverContent
              name={suggestion.name}
              leadUserId={suggestion.lead}
              description={suggestion.description}
              memberIds={suggestion.members}
              teamIds={suggestion.teams}
              status={suggestion.state}
              projectId={suggestion.project_id}
            />
            
          </div>
        }

        {suggestionType === 'doc' && docObj && 
          <div onClick={()=>{this.props.selectIndex(activeIndex)}} className='messageInsertMentionsMenu-item-hoverPreviewContainer'>            
            
            <div className='messageInsertMentionsMenu-item--doc-previewInnerContainer popover'>
              <div className='messageInsertMentionsMenu-item--doc-preview'>
                <div className='messageInsertMentionsMenu-item--doc-preview-doc'>
                  <div className='messageInsertMentionsMenu-item--doc-preview-doc-title'>
                    {suggestion.name}
                  </div>   
                  <div 
                    key={suggestion.doc_id} 
                    className='messageInsertMentionsMenu-item--doc-preview-doc-innerWrapper' 
                    dangerouslySetInnerHTML={createMarkup(docObj.doc_html)}                
                  />
                </div>
              </div>
            </div>
            
          </div>
        }
      </div>            
    )
  }
}

export default MessageInsertMentionsMenuItem
