import React from 'react'
import {toggleMessageMediaZoomInRedux} from '../../../../utils/toggleMessageMediaZoomInRedux'

class MessageMediaImage extends React.Component{  

	constructor(props){
    super(props)        

   	this.toggleZoom=this.toggleZoom.bind(this)    

    this.state={      
      zoomed: false,        
    }    
  }  


  toggleZoom(e){
  	if(!this.state.zoomed){
  		toggleMessageMediaZoomInRedux(true)
  		this.setState({ zoomed: true })
  	}else{
  		toggleMessageMediaZoomInRedux(false)
  		this.setState({ zoomed: false})
  	}
  }  


	render(){	
			const {url} = this.props
			const {zoomed} = this.state
			const imageUrl = url 

		return (
			<React.Fragment>
				<div onClick={this.toggleZoom} className='message-content-imageContainer'>
					<div className='message-content-imageInnerContainer'>				
			  		<img className='message-content-image' src={imageUrl}/>	
			  	</div>        
				</div>			
				{zoomed && 
					<div onClick={this.toggleZoom} className='message-content-zoomImageContainer'>
						<div className='message-content-zoomImageInnerContainer'>				
							<div className='message-content-zoomImageWrapper'>
				  			<img className='message-content-zoomImage' src={imageUrl}/>	
				  		</div>
				  	</div>        
					</div>
				}
				
			</React.Fragment>
		)
	}
}
 


export default MessageMediaImage
