///default blank instance

const blankDoc={
	"type": "doc", 
	"content": [
		{"type": "editorPage", "content": [
			{"type": "paragraph"}
		]}
	]}

const blankInstance={
	doc: blankDoc,
	version: 0,
	messageSelections: [],
	messageSelectionVersion: 0,
	userSelections:{}
}


export default function (state = {}, action) {
	switch (action.type) {
		case 'FETCH_COLLAB_INSTANCE_SUCCESS':
			return {...state,
				[`${action.docId}`]:action.instance
			}

		case 'CREATE_DOC_REQUEST': //NEW feb 8th so can start editing straight away before instance is created on server
			const doc=action.doc
			let instance = blankInstance
			if(doc.source){
				instance.doc=doc.source
			}
			return {...state,
				[`${doc.doc_id}`]:instance
			}

		case 'CLEAR_COLLAB_INSTANCE':
			const docId=action.docId
			let instances=state 
			delete instances[docId];
			return instances

		case 'SIGN_OUT':
	      return {}
		
		default:
			return state
	}
}

//TO THINK ABOUT
//what happens if send steps to server and collab instance isn't created yet?
//Maybe create it in parrallel when create doc
//TODO think about failure handling