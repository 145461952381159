import store from '../../store'
import find from 'lodash/find'



export function getDocForMeeting(meetingId) {	
	const state = store.getState()
	const doc= find(state.docs, {'meeting':`${meetingId}`})
	return doc
}

