import React from 'react'
import Icon from '../misc/Icon'
import StaticCheckbox from '../uiKit/StaticCheckbox'
import {updateProjectStatus} from '../../utils/updateProject/updateProjectStatus'
import {getRoadmaps} from '../../utils/getRoadmaps'
import {getRoadmapsForProject} from '../../utils/getRoadmapsForProject'
import sortBy from 'lodash/sortBy'


class EditProjectRoadmapsRow extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectRoadmap=this.handleSelectRoadmap.bind(this)
		this.state={
      checked: this.props.active
    }
	}

	handleSelectRoadmap(){
		const newCheckedStatus=!this.state.checked
		let newRoadmapsArray=[]
		if(newCheckedStatus==true){
			this.props.projectRoadmaps.forEach((roadmapId)=>{
				newRoadmapsArray.push(roadmapId)
			})
			newRoadmapsArray.push(this.props.roadmap.roadmap_id)
			
		}else{
			this.props.projectRoadmaps.forEach((roadmapId)=>{
				if(roadmapId != this.props.roadmap.roadmap_id){
					newRoadmapsArray.push(roadmapId)
				}
			})
		}
		this.setState({checked: newCheckedStatus})	

		this.props.updateProjectRoadmaps(newRoadmapsArray)
	}

	render(){	
		const {active, roadmap} = this.props	
		const {checked} = this.state

		return (
			<div onClick={this.handleSelectRoadmap} className='popoverMenu-item popoverMenu-item--editProjectRoadmaps '>
								
				<div className='popoverMenu-item-markerContainer'>
					<Icon name='roadmapOutline' />					
				</div>

				<div className='popoverMenu-item-label'>
					{roadmap.name}
				</div>	

				
				<div className='popoverMenu-item-checkboxContainer'>
					<StaticCheckbox 
						checked={checked}
					/>					
				</div>			

			</div>
		)
	}
}



class EditProjectRoadmapsPopover extends React.Component{ 


	render(){	
		const {projectId} = this.props
		const projectRoadmaps=getRoadmapsForProject(projectId)
		const orgRoadmaps = getRoadmaps()		
		const sortedRoadmaps = sortBy(orgRoadmaps, function(roadmap){
			return roadmap.name.toString().toLowerCase()
   	})
		return (
			<React.Fragment>         	

				{sortedRoadmaps.map((roadmap) => {
				  // Check if the current roadmap exists in the projectRoadmaps list
				  const isActive = projectRoadmaps.some(projectRoadmap => projectRoadmap.roadmap_id === roadmap.roadmap_id);
				  return (
				    <EditProjectRoadmapsRow
				      key={roadmap.roadmap_id}
				      projectRoadmaps={this.props.updatedProjectRoadmaps}
				      // closePopover={closePopover}
				      roadmap={roadmap}
				      updateProjectRoadmaps={this.props.updateProjectRoadmaps}
				      projectId={projectId}
				      active={isActive} // Set the active prop based on the isActive variable
				    />
				  );
				})}
					
			</React.Fragment>
		)
	}
}

export default EditProjectRoadmapsPopover