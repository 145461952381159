import React from 'react'
import Icon from '../misc/Icon'
import { Transition, animated, config, Spring } from 'react-spring'
import OnboardingModalDevice from './OnboardingModalDevice'
import OnboardingModalSelectBtn from './OnboardingModalSelectBtn'
import {getOnboardingProjects} from '../../utils/onboarding/getOnboardingProjects'

class OnboardingModalProjectStage extends React.Component {  	
  
  constructor(props) {
    super(props);   
    this.handleKeyDown=this.handleKeyDown.bind(this)   
    this.handleSelectProject=this.handleSelectProject.bind(this)    
    this.state = {
      activeIndex: 0,      
    }
  }

  componentDidMount() {
    if(this.props.stageVisible){
      window.addEventListener('keydown', this.handleKeyDown)
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.stageVisible && this.props.stageVisible) {
      // component just became visible
      window.addEventListener('keydown', this.handleKeyDown)
    } else if (prevProps.stageVisible && !this.props.stageVisible) {
      // component just became invisible
     // console.log('theme unmount0000000000')
      window.removeEventListener('keydown', this.handleKeyDown)
    }
  }


  // componentDidMount() {
  //   window.addEventListener('keydown', this.handleKeyDown)    
  // }
  
  // componentWillUnmount() {
  //   console.log('unmount project stage-------------')
  //   window.removeEventListener('keydown', this.handleKeyDown)
  // }


  handleSelectProject(project){
    this.props.setOnboardingProject(project)
    this.props.advanceStage()
  }

  handleKeyDown(e){
    if(e.keyCode==13){ //enter
      const projects=getOnboardingProjects()
      const activeProject=projects[this.state.activeIndex]
      this.props.setOnboardingProject(activeProject)
      this.props.advanceStage()
    }
    else if(e.keyCode==40){//down arrow
     // console.log('DOWN ARROW ooooooooooooooo')
      const {activeIndex}=this.state
      const projects=getOnboardingProjects()
      let newIndex
      if(activeIndex<projects.length-1){
        newIndex=activeIndex+1
      }else{
        newIndex=0
      }
      this.setState({activeIndex:newIndex})
    } 
    else if(e.keyCode==38){//up arrow
      const {activeIndex}=this.state
      const projects=getOnboardingProjects()
      let newIndex
      if(activeIndex==0){
        newIndex=projects.length-1
      }else{
        newIndex=activeIndex-1
      }
      this.setState({activeIndex:newIndex})
    } 
  }


  render() {
    
    const {stageVisible} = this.props
    const projects=getOnboardingProjects()

    const deviceTransformFrom = 'translateX(20px) translateY(0px)'
    const deviceTransformEnter = 'translateX(0px) translateY(0px)'
    const deviceTransformLeave = 'translateX(00px)  translateY(0px)'

    const contentTransformFrom = 'translateX(20px)'
    const contentTransformEnter = 'translateX(0px)'
    const contentTransformLeave = 'translateX(-20px)'


    return (      
       
      <React.Fragment>
        
        {stageVisible && 
          <div className='onboardingModal-stage-skipBtnContainer'>
            <button onClick={this.props.skipProjectStage} className='onboardingModal-stage-skipBtn'>
              Skip
            </button>
          </div>
        }

        <Transition
          items={stageVisible}
          from={{ opacity: 0, transform: `${contentTransformFrom}` }}
          enter={{ opacity: 1, transform: `${contentTransformEnter}`  }}
          leave={{ opacity: 0, transform: `${contentTransformLeave}`  }}
          reverse={stageVisible}
          config={{
            tension: stageVisible ? 200 : 400, 
            friction: stageVisible ? 26 : 32
          }}
        >
          {(styles, item) =>
            item && 
             <animated.div style={styles}  className='onboardingModal-stage-contentContainer onboardingModal-stage-contentContainer--leftAlign'>
              <div className='onboardingModal-stage-topSpacer' />
                <div className='onboardingModal-stage-h1'>
                  Yarn’s built for projects. <span className='onboardingModal-stage-h1-light'><br/>Pick one to get started.</span>
                </div>                

                <Spring
                  from={{ opacity: stageVisible ? 0 : 1, transform: stageVisible ? 'translateY(-5px)' : 'translateY(0px)' }}
                  to={{ opacity: stageVisible ? 1 : 0, transform: stageVisible ? 'translateY(0px)' : 'translateY(-5px)' }}
                  config={{tension: 200, friction: 26}}
                  delay={400}
                >
                  {selectBtnListStyles => 
                    <animated.div style={selectBtnListStyles} className='onboardingModal-stage-bottomContainer'>
                      <div className='onboardingModal-stage-keyHints'>
                        Use arrow keys ↑ ↓ and Enter
                      </div>
                      <div className='onboardingModal-stage--project-selectBtnContainer'>
                        {projects.map((project, i) => {
                          return(
                            <OnboardingModalSelectBtn
                              key={`project_button_${project.project_id}`}
                              onSelect={()=>{this.handleSelectProject(project)}}
                              active={this.state.activeIndex == i}                          
                              projectId={project.project_id}
                              label={project.name}
                            />
                          )
                        })}
                      </div>
                    </animated.div>
                  }
                </Spring>

            </animated.div>
          }
        </Transition>   

        

        <Transition
          items={stageVisible}
          from={{ opacity: 0, transform: `${deviceTransformFrom}` }}
          enter={{ opacity: 1, transform: `${deviceTransformEnter}`  }}
          leave={{ opacity: 1, transform: `${deviceTransformLeave}`  }}
          reverse={stageVisible}
          delay={stageVisible ? 1000 : 0}          
          config={{
            tension: stageVisible ? 170 : 170, 
            friction: stageVisible ? 26 : 26
          }}
         >
          {(styles, item) =>
            item && 
             <animated.div style={styles} className='onboardingModal-stage-visualOuterContainer'>
              <div className='onboardingModal-stage-visualInnerContainer'>
                <OnboardingModalDevice 
                  stage="project"
                />
              </div>
            </animated.div>
          }
        </Transition>       






      </React.Fragment>
    )
  }
}

export default OnboardingModalProjectStage