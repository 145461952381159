import {fetch,put,post} from '../api'
import {bumpFigmaEmbedVersion} from '../prosemirror/utils/bumpFigmaEmbedVersion'

export function exchangeFigmaCode(code) {
	let url = `/figma/callback?code=${code}`
	return (dispatch) => {
		return fetch(url)
		.then((response) => {
				dispatch({ type: 'FETCH_FIGMA_INTEGRATION_SUCCESS', response })
			return response
		})
		.catch((error) => {
			
		})
	}
}


export function fetchFigmaIntegration() {
	let url = `/figma-integration`
	return (dispatch) => {
		return fetch(url)
		.then((response) => {
			dispatch({ type: 'FETCH_FIGMA_INTEGRATION_SUCCESS', response })			
		})
		.catch((error) => {
			
		})
	}
}


export function removeFigmaIntegration() {
	let url = `/figma-integration/archive`
	return (dispatch) => {
		return put(url)
		.then((response) => {
			dispatch({ type: 'REMOVE_FIGMA_INTEGRATION_SUCCESS' })
			
		})
		.catch((error) => {
			
		})
	}
}




export function createFigmaEmbed(requestBody) {
	let url = `/figma/embeds`
	return (dispatch) => {
		return post(url,requestBody)
		.then((response) => {

			//dispatch({ type: 'REMOVE_FIGMA_INTEGRATION_SUCCESS' })
			
		})
		.catch((error) => {
			
		})
	}
}

export function fetchFigmaEmbeds() {
	let url = `/figma/embeds`
	return (dispatch) => {
		return fetch(url)
		.then((response) => {
			dispatch({ type: 'FETCH_FIGMA_EMBEDS_SUCCESS',response })
			bumpFigmaEmbedVersion()		
		})
		.catch((error) => {
		})
	}
}

export function handleNewFigmaEmbedPusher(embed){
	return (dispatch) => {
		dispatch({ type: 'CREATE_FIGMA_EMBED_SUCCESS', embed})
		bumpFigmaEmbedVersion()		
	}
}


