import React from 'react'
import Icon from '../../misc/Icon'
import * as Popover from '@radix-ui/react-popover'
import {showToastNotification} from '../../../actions/toastNotification'
import store from '../../../store'

class MessageDocSelectionPreview extends React.PureComponent{  

	constructor(props) {
		super(props)
		this.handleClick=this.handleClick.bind(this)		
		this.state={
			showPreviewPopover: false,
		}
	} 

	handleClick(selectionIsClipped){		
		if(this.props.selectionIsValid){
			this.props.goToDocSelection()
		}
		else{
			if(!this.props.selectionIsValid){				
				this.setState({showPreviewPopover: true}) // no pointer events when active to prevent double click
			}			
		}
	}


	render(){
		const {docSelectionText, message}=this.props 
		const {showPreviewPopover}=this.state 
		return (	  	
			<div id='message_doc_selection' onClick={()=>{this.handleClick()}} className={'message-content-selection--docSelection-container ' + (showPreviewPopover ? 'message-content-selection--docSelection-container--popoverOpen' : 'message-content-selection--docSelection-container--popoverClosed')}>
				<button className='message-content-selection message-content-selection--docSelection'>
					<div className='message-content-selection-bar'/>
					<div className='message-content-selection-content' >
						{docSelectionText}						
					</div>
				</button>
						
				<Popover.Root open={showPreviewPopover}>
					<Popover.Trigger asChild>
						<div className='message-content-selection--docSelection-popoverFinder' />
					</Popover.Trigger>
					<Popover.Portal>
					<Popover.Content
			    	className={'message-content-selection--docSelection-popover popover ' + (showPreviewPopover ? ' message-content-selection--docSelection-popover--visible ' : ' message-content-selection--docSelection-popover--hidden ')} 
			    	onEscapeKeyDown={()=>{this.setState({showPreviewPopover: false})}}
				    onPointerDownOutside={()=>{this.setState({showPreviewPopover: false})}}
				    onInteractOutside={()=>{this.setState({showPreviewPopover: false})}}				    
				    side="left" // left
				    sideOffset={12} // padding from right
				    align="start" // top
				    alignOffset={-31} // align start of text
				    
			    >
				   <div className='message-content-selection--docSelection-popover-header'>
				   		Original Doc Text
				   </div>
				   <div className='message-content-selection--docSelection-popover-text'>
				   	<div className='message-content-selection message-content-selection--docSelection message-content-selection--docSelection--preview'>
							<div className='message-content-selection-bar'/>
							<div className='message-content-selection-content' >
								{docSelectionText}
							</div>
						</div>
				   </div>
  									
			    </Popover.Content>
			    </Popover.Portal>
			  </Popover.Root>	

			  {/* MAYBE ADD BACK IN, ONLY SHOWS PREVIEW IF DELETED AND CLIPPED
				<div id={`message_doc_selection_ghost-${message.message_id}`} className='message-content-selection message-content-selection--docSelection message-content-selection--docSelection--ghost'>
					<div className='message-content-selection-bar'/>
					<div className='message-content-selection-content' >
						{docSelectionText}
					</div>				
				</div>
				*/}

			</div>
		)
	}
}
 


export default MessageDocSelectionPreview
