import React from 'react'
import Icon from '../misc/Icon'
import { withRouter} from 'react-router-dom'
import {createNewProjectDoc} from '../../utils/createNewDoc'

class DocEditorProjectContentTabsTabBar extends React.Component{    

	render(){	

    const {activeTab, setActiveTab, hasFigmas, hasMentions, hasProjectDocs, doc} = this.props
    
	  return (	  	      
        		
      <div className='docEditor-contentTabs-tabBar'>
        
        {hasProjectDocs && 
          <button onClick={()=>{this.props.setActiveTab('docs')}} className={'docEditor-contentTabs-tabBar-tab docEditor-contentTabs-tabBar-tab--docs ' + (activeTab === 'docs' ? ' docEditor-contentTabs-tabBar-tab--active ' : ' docEditor-contentTabs-tabBar-tab--inactive ')}>
            <div className='docEditor-contentTabs-tabBar-tab-iconContainer'>
              <Icon name='docSimple' />
            </div>
            <div className='docEditor-contentTabs-tabBar-tab-label'>
              Docs
            </div>
          </button>
        }

        {!hasProjectDocs && 
          <button onClick={()=>{createNewProjectDoc(this.props.history,doc.project)}} className='docEditor-contentTabs-tabBar-tab docEditor-contentTabs-tabBar-tab--newDoc docEditor-contentTabs-tabBar-tab--inactive'>
            <div className='docEditor-contentTabs-tabBar-tab-iconContainer'>
              <Icon name='docSimple' />
            </div>
            <div className='docEditor-contentTabs-tabBar-tab-label'>
              New Project Doc
            </div>
          </button>
        }


        {hasFigmas && 
          <button onClick={()=>{this.props.setActiveTab('figma')}} className={'docEditor-contentTabs-tabBar-tab docEditor-contentTabs-tabBar-tab--figma ' + (activeTab === 'figma' ? ' docEditor-contentTabs-tabBar-tab--active ' : ' docEditor-contentTabs-tabBar-tab--inactive ')}>
            <div className='docEditor-contentTabs-tabBar-tab-iconContainer'>
              <Icon name='insertBarFigma' />
            </div>
            <div className='docEditor-contentTabs-tabBar-tab-label'>
              Figmas
            </div>          
          </button>
        }


        <div className='docEditor-contentTabs-tabBar-tab-spacer' />
        
        {hasMentions &&       
        <button onClick={this.props.openProjectMentionsSheet} className={'docEditor-contentTabs-tabBar-tab docEditor-contentTabs-tabBar-tab--related ' + (this.props.showProjectMentionsSheet ? ' docEditor-contentTabs-tabBar-tab--active ' : ' docEditor-contentTabs-tabBar-tab--inactive ')}>
          <div className='docEditor-contentTabs-tabBar-tab-label'>
            Mentions
          </div>
          <div className='docEditor-contentTabs-tabBar-tab--related-iconContainer'>
            <Icon name='slackIcon' />
          </div>
          <div className='docEditor-contentTabs-tabBar-tab-counter'>
            17
          </div>
          <div className='docEditor-contentTabs-tabBar-tab--related-iconContainer'>
            <Icon name='linearIcon' />
          </div>
          <div className='docEditor-contentTabs-tabBar-tab-counter'>
            8
          </div>
        </button>
        }
        

      </div>
      
	  )
	}
}

export default withRouter(DocEditorProjectContentTabsTabBar)

