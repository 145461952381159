import React, {Component} from 'react'
import { connect } from 'react-redux'
import {syncLinearData} from '../actions/user'
import {fetchAllMessages} from '../actions/messages'

//Periodically syncs some linear bits that dont have webhooks

//const SYNC_INTERVAL=60*1000*20 //every 20 mins! --TODO if fails want to sync in certain order

const SYNC_INTERVAL=60*1000*10//every 20 mins! --TODO if fails want to sync in certain order

class LinearSyncContainer extends Component {

	componentDidMount() {	
		this.syncOrgInterval=setInterval(() => {
			this.props.syncLinearData()
			this.props.fetchAllMessages()
  	},SYNC_INTERVAL)
	}

	componentWillUnmount() {
		if(this.syncOrgInterval){
			clearInterval(this.syncOrgInterval)
		}
		
	}
	render() {
		return null
	}
}

function mapStateToProps(state,ownProps){
	return {}
}
export default connect(
	mapStateToProps,
	{
		syncLinearData,
		fetchAllMessages
	})(LinearSyncContainer)
