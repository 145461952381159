import React from 'react'
import { connect } from 'react-redux'
import Icon from '../misc/Icon'
import * as Dialog from '@radix-ui/react-dialog';
import AvatarImage from '../AvatarImage'
import filter from 'lodash/filter'
import store from '../../store'
import CommandMenuRow from './CommandMenuRow'
import sortBy from 'lodash/sortBy'
import { Spring, animated, config } from 'react-spring'

class CommandMenu extends React.Component{  
 
 	constructor(props) {
    super(props)    
    this.handleKeyDown=this.handleKeyDown.bind(this)
    this.state = {
    	value: '',
    	filteredUsers:[],
    	activated: false,
   	};

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
		window.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.handleKeyDown)
	}

	handleKeyDown(e){		
		// if(e.keyCode==13){
		// 	this.setState({activated: !this.state.activated})		
		// }
	}


  handleChange(event) {
  	const searchTerm=event.target.value

  	const filteredUsers = filter(this.props.orgMembers,function(obj) {
			return obj.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
		});

    this.setState({value: searchTerm,filteredUsers:filteredUsers});
  }

	sendCastingInvitation(user){
		if(this.props.doc){
			const docId=this.props.doc['doc_id']
			store.dispatch({type:'SEND_CASTING_INVITATION',user:user,docId:docId})
			this.props.hideSpecialKMenu()
		}
	}


	render(){			

		const {value,filteredUsers} = this.state
		const {hideCommandMenu,orgMembers} = this.props

		let inputEmpty = true

		if(value.length > 0){
			inputEmpty = false
		}	

		let membersArray=[]
    if(orgMembers){
      Object.keys(orgMembers).forEach((key)=>{
        let member=orgMembers[key]      
        //member.isPending=false    
        membersArray.push(member)
      })
    }

    const sortedMembers = sortBy(membersArray, function(member){
      return member.name.toString().toLowerCase()
    })

    const showKayleeResult = value.substring(0, 1) === 'k'
    const showZoeResult = value.substring(0, 1) === 'z'

    let kayleeUser
    let kayleeAvatarUrl

    let zoeUser
    let zoeAvatarUrl

    if(orgMembers && sortedMembers){
    	kayleeUser = sortedMembers[3]
    	zoeUser = sortedMembers[6]
    }

    if(kayleeUser){
    	kayleeAvatarUrl = kayleeUser.avatar	    
    }

    if(zoeUser){
    	zoeAvatarUrl = zoeUser.avatar	    
    }
		

	  return (		
	  	<React.Fragment>
			  <Dialog.Root id='specialKMenu' open={this.props.showCommandMenu}>			    		   
			    <Dialog.Portal>
			      <Dialog.Overlay className='dialogOverlay dialogOverlay--invisible dialogOverlay--commandMenu'>
				      <Dialog.Content 
				      	className='commandMenu'
				      	onEscapeKeyDown={hideCommandMenu}
				      	onPointerDownOutside={hideCommandMenu}
				      	onInteractOutside={hideCommandMenu}
				      >
								      					  	
					  		<div className={'commandMenu-input ' + (inputEmpty ? ' commandMenu-input--empty' : ' commandMenu-input--notEmpty')}>
					  			<input 
										placeholder='Type Command'
										className='commandMenu-input-input'
										value={this.state.value}
										onChange={this.handleChange}
										autoFocus
									/>
									<div className='commandMenu-input-UI'>
										{inputEmpty &&
											<div className='commandMenu-input-UI-iconContainer'>
												<Icon name='chevronSquare' />
											</div>
										}
										{showKayleeResult &&
										<div className='commandMenu-input-UI-nicoleTest'>
											kaylee
										</div>
										}

										{showZoeResult &&
										<div className='commandMenu-input-UI-nicoleTest'>
											zoe
										</div>
										}

										{showKayleeResult &&										
												<div className='commandMenu-input-UI-avatarContainer'>																															
													<AvatarImage 
														className='commandMenu-input-UI-avatar' 
														avatarUrl={kayleeAvatarUrl}
													/>													
													<div className='commandMenu-input-UI-avatarContainer-border' />
												</div>														
							  		}

							  		{showKayleeResult &&
							  		<div  className='commandMenu-input-UI-status'>
											<div className='commandMenu-input-UI-status-timezone'>
							  				10:05 local time
							  			</div>
								  			<div className='commandMenu-input-UI-status-presenceTag'>
								  				<div className='commandMenu-input-UI-status-presenceTag-circle'/>
								  				<div className='commandMenu-input-UI-status-presenceTag-label'>
								  					Active
								  				</div>
								  			</div>
											</div>																					  		
										}

										{showZoeResult &&										
												<div className='commandMenu-input-UI-avatarContainer'>																															
													<AvatarImage 
														className='commandMenu-input-UI-avatar' 
														avatarUrl={zoeAvatarUrl}
													/>													
													<div className='commandMenu-input-UI-avatarContainer-border' />
												</div>														
							  		}
							  		
							  		{showZoeResult &&
							  		<div  className='commandMenu-input-UI-status'>
											<div className='commandMenu-input-UI-status-timezone'>
							  				14:57 local time
							  			</div>
								  			<div className='commandMenu-input-UI-status-presenceTag'>
								  				<div className='commandMenu-input-UI-status-presenceTag-circle'/>
								  				<div className='commandMenu-input-UI-status-presenceTag-label'>
								  					Active
								  				</div>
								  			</div>
											</div>																					  		
										}

							  		
									</div>
					  		</div>	  	
					  		<div className='commandMenu-resultsList'>
					  												
					  			

					  			{/* FOUR ACTION TYPES 
					  				//action="directMessage"
										//action="pingCursor"
										//action="jumpToCursor"
										//action="roundRobin"	
										//action="sendLinkViaSlack"										
									*/}

									{/* GENERAL STATE */}
									{inputEmpty && 
										<React.Fragment>
											<CommandMenuRow 
												key={4}
												rowType="generalAction"										
												action="roundRobin"
												active={true}
												//secondaryLabel="Select teammate or channel"
											/>
											<CommandMenuRow 
												key={1}
												rowType="generalAction"										
												action="pingCursor"
												active={false}
												secondaryLabel="Select teammate or channel"
											/>
											<CommandMenuRow 
												key={2}
												rowType="generalAction"										
												action="jumpToCursor"
												active={false}			
												secondaryLabel="Select teammate"
											/>
											<CommandMenuRow 
												key={3}
												rowType="generalAction"										
												action="directMessage"
												active={false}
												secondaryLabel="Select teammate"
											/>

											<div className='commandMenu-resultsList-sectionDivider' />
											
											{orgMembers &&
								  			<React.Fragment>
									  			{sortedMembers.map((user,i)=>{
									  				return(
															<CommandMenuRow 
																key={user['user_id']}
																//active={i===0}
																active={false}
																rowType="generalUser"													
																userName={user.display_name}	
																user={user}																
															/>
									  				)
									  			})}
												</React.Fragment>					  	
									  		}


											{/*}
											<CommandMenuRow 
												key={4}
												rowType="generalAction"										
												action="sendLinkViaSlack"
												active={true}						
											/>

											<CommandMenuRow 
												key={5}
												rowType="generalUser"										
												userName={"ali"}
												active={true}						
											/>
											<CommandMenuRow 
												key={1}
												rowType="generalAction"										
												action="pingDoc"
												active={true}						
											/> */}
										</React.Fragment>									
									}

									{(showKayleeResult || showZoeResult) &&
										<React.Fragment>
											<CommandMenuRow 
												key={1}
												rowType="userMatchAction"										
												action="pingCursor"
												active={true}		
												activated={this.state.activated}				
											/>
											<CommandMenuRow 
												key={2}
												rowType="userMatchAction"										
												action="jumpToCursor"
												active={false}						
											/>
											<CommandMenuRow 
												key={3}
												rowType="userMatchAction"										
												action="directMessage"
												active={false}						
											/>
											<CommandMenuRow 
												key={4}
												rowType="userMatchAction"										
												action="sendLinkViaSlack"
												active={false}						
											/>	
										</React.Fragment>									
									}

									{/*}
					  			<CommandMenuRow 
										key={1}
										rowType="userAction"
										userName="nicole"
										action="jumpToCursor"
										active={true}								
									/>

									<CommandMenuRow 
										key={2}
										rowType="userAction"													
										userName="nicole"										
										action="newDiscussion"			
									/>

									<CommandMenuRow 
										key={3}
										rowType="userAction"													
										userName="nicole"										
										action="pingCursor"										
									/>

									<CommandMenuRow 
										key={4}
										rowType="userAction"													
										userName="nicole"										
										action="sendLinkViaSlack"					
									/>

									

					  			{/* FROM BEFORE 
									{value &&
					  			<React.Fragment>
						  			{filteredUsers.map((user,i)=>{
						  				return(
												<CommandMenuRow 
													key={user['user_id']}
													active={i===0}
													rowType="userAction"													
													userName={user.display_name}	
													user={user}
													action="PingCursor"
												/>
						  					)
						  			})}
									</React.Fragment>					  	
						  		}
						  		*/}
						  	
					  		</div>		  		

				      </Dialog.Content>
			      </Dialog.Overlay>
			    </Dialog.Portal>
			  </Dialog.Root>	
			</React.Fragment>

	  )
	}
}



function mapStateToProps(state,ownProps) {

	return {
		orgMembers:state.orgMembers
	}	
}

export default connect(mapStateToProps,
	{	
		}
)(CommandMenu)

