import React from 'react'
import Icon from '../misc/Icon'
import LinearProjectStatusIcon from '../misc/LinearProjectStatusIcon'
import {updateProjectStatus} from '../../utils/updateProject/updateProjectStatus'
import {getOrgMembers} from '../../utils/getOrgMembers'
import {getProjectForId} from '../../utils/getProjectForId'
import AvatarImage from '../AvatarImage'

class EditProjectLeadRow extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectLeadUser=this.handleSelectLeadUser.bind(this)
	}

	handleSelectLeadUser(e){
		e.stopPropagation()
		e.preventDefault()
		this.props.updateProjectLead(this.props.user.user_id)
		this.props.closePopover()

	}

	render(){	
		const {active, user} = this.props	
		return (
			<div onClick={this.handleSelectLeadUser} className={'popoverMenu-item popoverMenu-item--editProjectLead ' + (active ? ' popoverMenu-item--active ' : ' popoverMenu-item--inactive ')}>
				<div className='popoverMenu-item-avatarContainer'>
					<AvatarImage 
						className='popoverMenu-item-avatar'
						avatarUrl={user.avatar}
						smallAvatar
					/> 
				</div>
				<div className='popoverMenu-item-label'>
					{user.display_name}
				</div>				
			</div>
		)
	}
}



class EditProjectLeadPopover extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectUnassignLeadUser=this.handleSelectUnassignLeadUser.bind(this)
	}

	handleSelectUnassignLeadUser(){

		this.props.updateProjectLead(null)
		this.props.closePopover()
	}


	render(){	
		const {closePopover,currentStatus,leadUserId} = this.props
		const orgMembers = getOrgMembers()
		return (
			<React.Fragment>         	
				{orgMembers
					//used GTP for this, dunno if it's okay
					.sort((a, b) => a.display_name.localeCompare(b.display_name)) // Sort orgRoadmaps alphabetically by the name field
					.map((orgMember) => {
				  // Check if the user is the lead user 
				  const isActive = orgMember.user_id === leadUserId
				  return (
				    <EditProjectLeadRow
				      key={orgMember.user_id}
				      closePopover={closePopover}
				      updateProjectLead={this.props.updateProjectLead}
				      user={orgMember}
				      active={isActive} // Set the active prop based on the isActive variable
				    />
				  );
				})}
				{leadUserId && 
				<React.Fragment>
					<div className='popoverMenu-separator popoverMenu-separator--spacer' />
					
					<div onClick={this.handleSelectUnassignLeadUser} className='popoverMenu-item popoverMenu-item--unassignProjectLead'>					
						<div className='popoverMenu-item-label'>
							Unassign Project Lead
						</div>									
					</div>
				</React.Fragment>
				}

			</React.Fragment>
		)
	}
}

export default EditProjectLeadPopover