import json from '../../package.json'


export function getVersionNumber() {	
	let version
	if(json){
		version=json.version
	}
	return version
}


