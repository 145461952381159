import {Schema} from 'prosemirror-model'
import EditorNodes from './nodes/EditorNodes'


//https://discuss.prosemirror.net/t/nodes-append-is-not-a-function/2216/2
const mySchema = new Schema({
  nodes:EditorNodes,
  // marks: EditorMarks
})


const editorSchema = new Schema({
  nodes:mySchema.spec.nodes,
 // marks: EditorMarks
})


export default editorSchema