import React from 'react'
import {getMeetingForId} from '../../utils/meetings/getMeetingForId'
import moment from 'moment'

// function formatGCalTeamSyncDate(timestamp) {
//   const nowDate = moment();
//   const today = moment(nowDate).startOf('day');
//   const yesterday = moment(nowDate).subtract(1, 'days').startOf('day');
//   const dayBeforeYesterday = moment(nowDate).subtract(2, 'days').startOf('day');
//   const tomorrow = moment(nowDate).add(1, 'days').startOf('day');
//   const week = moment(nowDate).startOf('isoWeek');  
//   const sevenDaysFromNow = moment(nowDate).add(7, 'days');
//   const nextWeekButLessThan7Days = moment(nowDate).add(1, 'weeks').subtract(1, 'days');
//   const nextWeek = moment(nowDate).add(1, 'weeks').startOf('isoWeek');
//   const endOfNextWeek = moment(nowDate).add(1, 'weeks').endOf('isoWeek');
//   const sixHoursFromNow = moment(nowDate).add(6, 'hours');
 
//   let formattedTimestamp;


//   if (moment(timestamp).isSame(today, 'd')) {
//     // today
//     formattedTimestamp = 'Today';
//   }
//   else if (moment(timestamp).isSame(yesterday, 'd')) {
//     // yesterday
//     formattedTimestamp = `Yesterday`;
//   }
//   else if (moment(timestamp).isSame(tomorrow, 'd')) {
//     // tomorrow
//     formattedTimestamp = `Tomorrow`;
//   } 
//    else if (moment(timestamp).isBetween(week, dayBeforeYesterday)) {
//     // day before yesterday but within this week
//     formattedTimestamp = `${moment(timestamp).format('dddd MMMM D')}`;
//   }
//   else if (moment(timestamp).isBetween(week, today)) {
//     // this week, after today
//     formattedTimestamp = moment(timestamp).format('dddd');
//   }   
//   else if (moment(timestamp).isBetween(nextWeek, nextWeekButLessThan7Days)) {
//     // next week but less than 7 days
//     formattedTimestamp = `${moment(timestamp).format('dddd')}`;
//   } 
//   else if (moment(timestamp).isBetween(nextWeek, sevenDaysFromNow)) {
//     // next week and 7 or more days away
//     formattedTimestamp = `Next ${moment(timestamp).format('ddd MMMM D')}`;
//   }
//   else{
//     // all others
//     //formattedTimestamp = moment(timestamp).format('ddd MMMM D'); // with dayofweek
//     formattedTimestamp = moment(timestamp).format('MMMM D');
//   }

//   return formattedTimestamp;
// }

class DocEditorGCalTeamSyncTitle extends React.Component{  


	render(){	    
		
    const meetingObj = getMeetingForId(this.props.meeting.meeting_id)
    let meetingFormattedDateTime
    let timestamp

    if(meetingObj){      
      timestamp = meetingObj.meeting_start
      meetingFormattedDateTime = moment(timestamp).format('MMMM D')
    }

	  return (	  	
            
      <div className='docEditor-GCalMeetingTitle'>
      	<div className='docEditor-GCalMeetingTitle-title'>
        	{this.props.docName} <span className='docEditor-GCalMeetingTitle-title-date'>{meetingFormattedDateTime}</span>
        </div>
      </div>      
	  )
	}
}

export default DocEditorGCalTeamSyncTitle
