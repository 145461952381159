import {put,fetch} from '../api'
import axios from 'axios'
  

export async function fetchStoryboardImageJSON(muxPlaybackId) {
	const url=`https://image.mux.com/${muxPlaybackId}/storyboard.json`
	try{
	  delete axios.defaults.headers.common['Cache-Control'];
	  delete axios.defaults.headers.common['Pragma'];
	  delete axios.defaults.headers.common['Expires'];
	  const result = await axios.get(url,{ withCredentials: false })
	    axios.defaults.headers.common['Cache-Control']='no-cache'
	    axios.defaults.headers.common['Pragma']='no-cache'
	    axios.defaults.headers.common['Expires']='0'
	  return result.data;
	}catch(error){

	}
};


  

export async function fetchSubtitles(url) {
	//const url=`https://res.cloudinary.com/yarn/raw/upload/v1667574282/dummydata/Notion_ewe58o.srt`
	try{
	  delete axios.defaults.headers.common['Cache-Control'];
	  delete axios.defaults.headers.common['Pragma'];
	  delete axios.defaults.headers.common['Expires'];
	  const result = await axios.get(url,{ withCredentials: false })
	    axios.defaults.headers.common['Cache-Control']='no-cache'
	    axios.defaults.headers.common['Pragma']='no-cache'
	    axios.defaults.headers.common['Expires']='0'
	  return result.data;
	}catch(error){
	}
};





export function createUpload() {
	return (dispatch) => put(`/upload`)
		.then((response) => {
			return response
		})
		.catch((error) => {
			return error
		})
}




export function fetchUpload(id) {
	return (dispatch) => fetch(`/upload/${id}`)
		.then((response) => {
			return response
		})
		.catch((error) => {
			return error
		})
}
