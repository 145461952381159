import {Plugin} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"

//Add classname to empty headers
export function headingPlaceholderPlugin(){
	return new Plugin({
		props: {
			decorations: ({ doc, plugins, selection }) => {
				const decorations = []
				const { anchor } = selection
				doc.descendants((node, pos) => {
					if (node.type.name==='heading' && !node.textContent && node.nodeSize==2) {
				
						const level=node.attrs.level
						const placeholderDiv=document.createElement("div")
						placeholderDiv.classList.add("doc-headerPlaceholder")
						placeholderDiv.textContent=`Header ${level}`
						decorations.push(Decoration.node(pos, pos + node.nodeSize, {class: 'doc-header--empty'}))					
					}     		
				})
			return DecorationSet.create(doc, decorations)
		}
	}
	})
}