import React from 'react'
import Icon from '../../misc/Icon'
import VideoDuration from '../../../utils/videoDuration'

class MediaModalCommentInput extends React.Component{  
 
 constructor(props) {
    super(props) 
    this.handleChange = this.handleChange.bind(this);
    this.sendMessage=this.sendMessage.bind(this)
    this.onKeyDown=this.onKeyDown.bind(this)       
    this.state = {
    	value: '',    	
   	};

  }

  handleChange(event) {
  	event.preventDefault()
  	event.stopPropagation()
  	if(this.props.onMessageValueUpdate){
  		this.props.onMessageValueUpdate(event.target.value)
  	}else{
  		this.setState({value: event.target.value}); 
  	}
    
  }

  onKeyDown(e){		
  	e.stopPropagation()
		if(e.keyCode===13){
			if(this.state.value.trim().length > 0 || (this.props.messageValue && this.props.messageValue.trim().length>0)){
				e.preventDefault()
				e.stopPropagation()			
				this.sendMessage()
			}else this.props.unzoom()
		}else if(e.keyCode==27){
			if(this.props.unzoom){
				this.props.unzoom()
			}
		}
	} 

  sendMessage(){
  	if(this.state.value.trim().length>0 || (this.props.messageValue && this.props.messageValue.trim().length>0)){
  		let value=this.state.value
  		if(this.props.messageValue){
  			value=this.props.messageValue
  		}
  		this.props.sendMediaModalMessage(value)
  		this.setState({value:''})
  	}
  }

	render(){ 
		const {value} = this.state
		const {video, playing, messageWithTimestamp} = this.props
		const inputEmpty = value.length < 1
		const showTimestampButton = this.props.timestamp
		let inputValue=value
		if(this.props.onMessageValueUpdate){
			inputValue=this.props.messageValue
		}
		return (
			<div className={'doc-mediaModalCommentInputContainer ' + (inputEmpty ? ' doc-mediaModalCommentInputContainer--empty ' : ' doc-mediaModalCommentInputContainer--nonEmpty ') + (showTimestampButton ? ' doc-mediaModalCommentInputContainer--video--showTimestampButton ' : '') + (playing ? ' doc-mediaModalCommentInputContainer--video--playing ' : '')}>
				<div className='doc-mediaModalCommentInput'>
					<input className='doc-mediaModalCommentInput-input'
						placeholder='Add a comment...'
						value={inputValue}
						onChange={this.handleChange}
						onFocus={this.props.pauseVideo} 
						onKeyDown={this.onKeyDown}						
						autoFocus
					/>
					<div className='doc-mediaModalCommentInput-UI'>
						{showTimestampButton && 
							<button className={'doc-mediaModalCommentInput-UI-timestampButton ' + (messageWithTimestamp ? ' doc-mediaModalCommentInput-UI-timestampButton--active ' : ' doc-mediaModalCommentInput-UI-timestampButton--inactive' )}>
								
								<div className='doc-mediaModalCommentInput-UI-timestampButton-label'>
									{messageWithTimestamp &&
										<VideoDuration seconds={Math.floor(this.props.timestamp)} />
									}
									{!messageWithTimestamp &&
										<span>Add</span>
									}
								</div>
								<div className='doc-mediaModalCommentInput-UI-timestampButton-iconContainer'>
									<Icon name='stopwatch' />
								</div>
							</button>

						}
						<button className='doc-mediaModalCommentInput-UI-sendBtn' onClick={this.sendMessage}>
							<Icon name='paperAirplane' />
						</button>
					</div>
				</div>
			</div>
			
		)
	}
}

export default MediaModalCommentInput
