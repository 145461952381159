import React from 'react'
import { Spring, animated, config, easings } from 'react-spring'
import Icon from '../../misc/Icon'

const DOC_WIDTH = 650

class DocFigmaEmbedPlaceholder extends React.Component{  

	render(){ 
		
		const {docFigmaContainerWidth}=this.props

		const frameTitle = 'Home Feed Example'						
		const marginOffset = (DOC_WIDTH - docFigmaContainerWidth) / 2

		return(

			<div className='doc-imageContainer doc-imageContainer--placeholder'>
				<div className='doc-image doc-image--placeholder'>
					<div className='doc-image--placeholder-imageIconContainer'>
						<Spring
		  				native								
							from={{
								opacity: 0.8,
								//transform: 'scale(1.0)'
							}}
							to={[
	          		{ 
	          			opacity: 0.7,
	          			//transform: 'scale(0.95)' 
	          		},
	          		{ 
	          			opacity: 0.8,
	          			//transform: 'scale(1)' 
	          		},	          		
	        		]}			        								
							loop
							config={{									
								duration: 2000,									
								easing: easings.easeInOutCubic,
							}}
						>
			  			{styles => 
								<animated.div style={styles} className='doc-image--placeholder-imageLogo'>
									<Icon name='insertBarImage' />
								</animated.div>
			  			}
			  		</Spring>	  		 			



					</div>




					<div className='doc-image--placeholder-loadingBar'>
		  			<Spring
		  				native								
							from={{
								opacity: 0.4,
								transform: 'translateX(-210px)'
							}}
							to={[
	          		{ 
	          			opacity: 0.05,
	          			transform: 'translateX(208px)' 
	          		},	          		
	        		]}								
							loop
							config={{									
								duration: 2000,									
								easing: easings.easeInOutCubic,
							}}
						>
			  			{styles => 
								<animated.div style={styles} className='doc-image--placeholder-loadingBar-ball' />
			  			}
			  		</Spring>	  		 			
			  	</div>
				</div>													
			</div>

		
		)
	}
}

export default DocFigmaEmbedPlaceholder
