import {Plugin,PluginKey} from "prosemirror-state"
import { Decoration, DecorationSet } from "prosemirror-view"
import React from 'react'
import ReactDOM from 'react-dom'
import filter from 'lodash/filter'
import {getUserName} from '../../../utils/getUserName'
import {getUserForId} from '../../../utils/getUserForId'
import {isCurrentUser} from '../../../utils/isCurrentUser'

//https://discuss.prosemirror.net/t/setting-the-cursor-bias-assoc-when-merging-changes-from-collab/611
const key = new PluginKey('collabSelectionPlugin')


function mapThrough(mapping,selections){
	let newSelections={}
	let selectionsArray=Object.keys(selections)
	selectionsArray.map((key)=>{
		let selection=selections[key]
		//let anchor = mapping.map(selection.anchor, 1), head = mapping.map(selection.head, -1)
		let anchor = mapping.map(selection.anchor, -1), head = mapping.map(selection.head, -1)
		//think about removing if not in valid bit anymore
		selection.anchor = anchor
		selection.head = head
		newSelections[selection.userId]=selection
	})
	return newSelections

}

function checkTimestamps(selections,prevSelections){
	let newSelections={}
	let selectionsArray=Object.keys(selections)
	selectionsArray.map((key)=>{
		let selection=selections[key]
		let prevSelection=prevSelections[key]
		let isUpdated=true
		if(prevSelection && prevSelection.updatedAt>selection.updatedAt){
			isUpdated=false
		}
		if(isUpdated){
			newSelections[selection.userId]=selection
		}else{
			newSelections[selection.userId]=prevSelection
		}
		
	})
	return newSelections
}


export function collabSelectionPlugin(userSelections) {
	return new Plugin({
		key,
		state: {
		init() {
			return {selections:userSelections,showNameFlags:false};
		},
		apply(tr,prevState) {
			let action = tr.getMeta('collabSelectionPlugin'), actionType = action && action.type
			if (actionType == "timeout"){
				return {...prevState,showNameFlags:false}
			}

			if (actionType == "selectionUpdated"){
				return {selections:action.selectionData,showNameFlags:true}
			}else{//check if a transaction from syncing with server in which case user those selections.  otherwise map locally
				const mapping=tr.mapping 
				if(tr.meta.userSelections){
					return {selections:tr.meta.userSelections,showNameFlags:true}
				}else if(tr.docChanged && mapping && prevState.selections){
					const newSelections=mapThrough(mapping,prevState.selections)
					return {selections:newSelections,showNameFlags:false}
				}
				else{
					return prevState
				}			
			}
		}
	},
		props: {
			decorations: (editorState) => {
				const pluginState=key.getState(editorState)
				const selections=pluginState.selections
				const doc=editorState.doc
				let decos=[]
				const selectionsArray=Object.keys(selections)
				if(selectionsArray.length>0){
					selectionsArray.map((key)=>{
						if(key !== "undefined"){ //TEMP fix for undefined cursor selection thing
							const sel=selections[key]
							const decoStart=Math.min(sel.anchor,sel.head)
							const decoEnd=Math.max(sel.anchor,sel.head)
							const user=getUserForId(sel.userId)
						
							//TODO maybe try again after a short delay if user obj not available on first try
							if(user && !isCurrentUser(user.user_id)){ //only show if have user- TODO look into what was causing the undefined thing
								const color=user.color //TODO color stuff!
								decos.push(Decoration.inline(decoStart, decoEnd, {class: `doc-paragraph-selection--collab doc-paragraph-selection--userColor--${color}`,id: `collab-selection-user-${sel.userId}`}))
								const cursorSpan=document.createElement("span") 		
								if(pluginState.showNameFlags){			
									cursorSpan.innerHTML+=`<span>${getUserName(sel.userId)}</span>`
									cursorSpan.className=`doc-collabCursorFlag doc-collabCursorFlag--defaultFlag doc-collabCursorFlag--userColor--${color}`
								}else{
									cursorSpan.innerHTML+=`<span></span>`
									cursorSpan.className=`doc-collabCursorFlag doc-collabCursorFlag--minimalFlag doc-collabCursorFlag--userColor--${color}`
								}
								
								decos.push(Decoration.widget(sel.head, cursorSpan))
							}
						} 
					})
				}
			return DecorationSet.create(doc, decos)
		}
	}
	})
}
