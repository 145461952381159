function getAttrs(dom) {
  return {
    userId: dom.getAttribute('data-userId')
  }
}

const ParticipantHeaderNodeSpec ={
    attrs: {
      userId:{default:''},
      issueUpdateVersion:{default:0}
    },
    group: "block",
    parseDOM: [
    {tag: 'participantHeader', getAttrs},
   ],
    toDOM(node) {
      return ['participantHeader', {'data-userId':node.attrs.userId}]
  }
}
    

  export default ParticipantHeaderNodeSpec




