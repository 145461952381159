import findIndex from 'lodash/findIndex'

export default function (state = null, action) {
	switch (action.type) {

	case 'FETCH_ONBOARDING_STATUS_SUCCESS':
	 	return action.response


	case 'UPDATE_ONBOARDING_STAGE_REQUEST':
	 	return {...state,stage:action.stage}


	 	

	case 'LOGOUT':
		return null
	
	default:
		return state
	
	}
}
