import React, { useEffect } from 'react'
import Icon from '../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useEditor, useValue } from "@tldraw/tldraw"
import {updateWhiteboardSetting} from '../../utils/whiteboards/updateWhiteboardSetting'


const WhiteboardHeader = () => {
	const editor = useEditor()

	const isGridMode = useValue('isGridMode', () => editor.isGridMode, [editor])
	const isToolLocked = useValue('isToolLocked', () => editor.isToolLocked, [editor])
	const isSnapMode = useValue('isSnapMode', () => editor.isSnapMode, [editor])

	const handleSelectGridMode = () => {
		const newGridMode = !isGridMode;
		editor.setGridMode(newGridMode);
		updateWhiteboardSetting('isGridMode', newGridMode);
	}

	const handleSelectSnapMode = () => {
		const newSnapMode = !isSnapMode;
		editor.setSnapMode(newSnapMode);
		updateWhiteboardSetting('isSnapMode', newSnapMode);
	}

	const handleSelectToolLock = () => {
		const newToolLock = !isToolLocked;
		editor.setToolLocked(newToolLock);
		updateWhiteboardSetting('isToolLocked', newToolLock);
	}


    return (
        <div className='whiteboard-header'>
            <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                    <button className='whiteboard-header-dropdownBtn'>
                        <Icon name='imageMenuBars' />
                    </button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content className='dropdownMenu dropdownMenu--commentReactionsMenu'>
                    <DropdownMenu.Item onSelect={handleSelectGridMode}>
                        {isGridMode?'Hide Grid':'Show Grid'}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onSelect={handleSelectSnapMode}>
                        {isSnapMode ?'Default Snapping':'Always Snap'}
                    </DropdownMenu.Item>
                    <DropdownMenu.Item onSelect={handleSelectToolLock}>
                        {isToolLocked?'Turn off Tool Lock':'Tool Lock'}
                    </DropdownMenu.Item>

                    <DropdownMenu.Separator />
                    <DropdownMenu.Item className='dropdownMenu-item--warning'>
                        Delete Whiteboard
                    </DropdownMenu.Item>

                </DropdownMenu.Content>

            </DropdownMenu.Root>
        </div>
    );
}

export default WhiteboardHeader;
