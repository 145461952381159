import DocImageNodeSpec from './media/DocImageNodeSpec'
import DocThumbnailNodeSpec from './media/DocThumbnailNodeSpec'

import DocVideoNodeSpec from './media/DocVideoNodeSpec'

import DocDrawingNodeSpec from './media/DocDrawingNodeSpec'

import ContentBoxNodeSpec from './ContentBoxNodeSpec'

import CodeBlockNodeSpec from './CodeBlockNodeSpec'

import HorizontalLineNodeSpec from './HorizontalLineNodeSpec'

import InternalLinkNodeSpec from './InternalLinkNodeSpec'

import ToDoItemNodeSpec from './lists/ToDoItemNodeSpec'
import BulletListItemNodeSpec from './lists/BulletListItemNodeSpec'
import DocDateNodeSpec from './DocDateNodeSpec'


import LinearIssueMentionNodeSpec from './integrations/LinearIssueMentionNodeSpec'
import LinearProjectMentionNodeSpec from './integrations/LinearProjectMentionNodeSpec'

import DocFigmaEmbedNodeSpec from './integrations/DocFigmaEmbedNodeSpec'

import EmojiNodeSpec from './EmojiNodeSpec'


import TextLinkNodeSpec from './TextLinkNodeSpec'

import ToggleListItemNodeSpec from './toggleLists/ToggleListItemNodeSpec'
import ToggleListItemTitleNodeSpec from './toggleLists/ToggleListItemTitleNodeSpec'
import ToggleListItemDetailsNodeSpec from './toggleLists/ToggleListItemDetailsNodeSpec'


import DocTableNodeSpec from './DocTableNodeSpec'
import DocWhiteboardEmbedNodeSpec from './DocWhiteboardEmbedNodeSpec'

import ParticipantHeaderNodeSpec from './ParticipantHeaderNodeSpec'

import DocCodeMirrorNodeSpec from './DocCodeMirrorNodeSpec'

//import DocFigmaNodeSpec from './integrations/DocFigmaNodeSpec'


//https://github.com/ProseMirror/prosemirror-schema-basic/blob/master/src/schema-basic.js
const blockquoteDOM = ["blockquote", 0],
			preDOM = ["pre", ["code", 0]], brDOM = ["br"]

export const nodes = {
	doc: {
		content: "editorPage"
	},
	editorPage:{
		//content: "(paragraph | bulletListItem | heading | contentBox | codeBlock | toggleListItem | toDoItem) block+ (paragraph | bulletListItem | heading | contentBox | codeBlock | toggleListItem | toDoItem)",
		content: "block* (paragraph | bulletListItem | heading | contentBox | codeBlock | toggleListItem | toDoItem)",
		//content: "block+",
		toDOM: () => ["div",{class:'docInnerWrapper'}, 0]
	},
	paragraph: {
		attrs:{
			indentLevel:{default:0},
		},
		content: "inline*",
		group: "block",
		parseDOM: [
      {
        tag: "div.doc-para",
        getAttrs: (dom) => ({
          indentLevel: parseInt(dom.getAttribute("data-indent-level"),10),
      }),
      }
    ],
	// toDOM(node) {
	//   const { indentLevel } = node.attrs;
	//   const content = node.content.size === 0 ? ["br", {class: "ProseMirror-trailingBreak"}] : 0;
	//   return ["div", { class: `doc-para`, "data-indent-level": indentLevel }, content];
	// }},

		toDOM(node) {
			const {indentLevel} = node.attrs;
			return ["div",{class:`doc-para`,"data-indent-level":indentLevel}, 0]
		}},
		
		toggleListItem:ToggleListItemNodeSpec,
		toggleListItemTitle:ToggleListItemTitleNodeSpec,
		toggleListItemDetails:ToggleListItemDetailsNodeSpec,
		emoji:EmojiNodeSpec,
		textLink:TextLinkNodeSpec,
		docImage:DocImageNodeSpec,
		docThumbnail:DocThumbnailNodeSpec,
		docDrawing:DocDrawingNodeSpec,
		docVideo:DocVideoNodeSpec,
		contentBox:ContentBoxNodeSpec,
		codeBlock:CodeBlockNodeSpec,
		horizontalLine:HorizontalLineNodeSpec,
		linearIssueMention:LinearIssueMentionNodeSpec,
		linearProjectMention:LinearProjectMentionNodeSpec,
		docFigmaEmbed:DocFigmaEmbedNodeSpec,
		docWhiteboardEmbed:DocWhiteboardEmbedNodeSpec,
		internalLink:InternalLinkNodeSpec,
		toDoItem:ToDoItemNodeSpec,
		bulletListItem:BulletListItemNodeSpec,
		docDate:DocDateNodeSpec,

		participantHeader:ParticipantHeaderNodeSpec,

		docCodeMirror:DocCodeMirrorNodeSpec,

		blockquote: {
			content: "block+",
			group: "block",
			defining: true,
			parseDOM: [{tag: "blockquote"}],
			toDOM() { return blockquoteDOM }
		},
		heading: {
			attrs: {level: {default: 1}},
			content: "inline*",
			group: "block",
			defining: true,
		 parseDOM: [{
				tag: "div",
				getAttrs: dom => {
					let classname = dom.getAttribute("class")
					if(classname==='doc-h1'){
						return {level:1}
					}else if(classname==='doc-h2'){
						return {level:2}
					}else return false
				}
			}],
		toDOM(node) { return ["div",{class:`doc-h${node.attrs.level}`}, 0] }
	 },
	code_block: {
		content: "text*",
		marks: "",
		group: "block",
		code: true,
		defining: true,
		parseDOM: [{tag: "pre", preserveWhitespace: "full"}],
		toDOM() { return preDOM }
	},
	text: {
		group: "inline"
	},
	hard_break: {
		inline: true,
		group: "inline",
		selectable: false,
		parseDOM: [{tag: "br"}],
		toDOM() { return brDOM }
	}
}

export default nodes

