import React, {Component} from 'react'
import { connect } from 'react-redux'
import {getDocInstance,clearCollabInstanceFromState,fetchADocHTML} from '../actions/docs'
import pusherInstance from '../pusherInstance'
import throttle from 'lodash/throttle'
import {fetchReactions,handleNewReactionPusher,handleReactionDeletedPusher} from '../actions/reaction'
import {isCurrentUser} from '../utils/isCurrentUser'

const THROTTLE_TIME=1000
const HTML_THROTTLE_TIME=10000


class CollabInstanceManager extends Component {

	constructor(){
		super()
		this.handleReactionDeletedPusher=this.handleReactionDeletedPusher.bind(this)
		this.handleNewReactionPusher=this.handleNewReactionPusher.bind(this)
		this.handleTabOpened=this.handleTabOpened.bind(this)
		this.handleTabRemoved=this.handleTabRemoved.bind(this)
		this.docPusherSetup=this.docPusherSetup.bind(this)
		this.handleDocUpdatedPusher=this.handleDocUpdatedPusher.bind(this)
		this.refetchDocInstance=this.refetchDocInstance.bind(this)
		this.refetchDocInstance=throttle(this.refetchDocInstance,THROTTLE_TIME,{leading:true,trailing:true})
		this.fetchDocHtml=this.fetchDocHtml.bind(this)
		this.fetchDocHtml=throttle(this.fetchDocHtml,HTML_THROTTLE_TIME,{leading:true,trailing:true})
	}

	componentDidMount() {	
		this.props.openTabs.forEach((docId)=>{
			this.props.getDocInstance(docId)
			this.docPusherSetup(docId)
		})
	}

	componentDidUpdate(prevProps) {
 		if(prevProps.openTabs !== this.props.openTabs){
 			const oldTabs=prevProps.openTabs
 			const newTabs=this.props.openTabs
 			let tabsRemoved=[]
 			let tabsAdded=[]
 			oldTabs.forEach((docId)=>{
 				if(!newTabs.includes(docId)){
 					tabsRemoved.push(docId)
 				}
 			})
 			newTabs.forEach((docId)=>{
 				if(!oldTabs.includes(docId)){
 					tabsAdded.push(docId)
 				}
 			})
 			tabsAdded.forEach((docId)=>{
 				this.handleTabOpened(docId)
 			})
 			tabsRemoved.forEach((docId)=>{
 				this.handleTabRemoved(docId)
 			})
 		}
	}

	handleTabOpened(docId){
		if(docId){
			this.docPusherSetup(docId)
		}	
	}

	handleTabRemoved(docId){
		if(docId){
			this.props.clearCollabInstanceFromState(docId)
			pusherInstance.unsubscribe(`document-${docId}`)
		}
	}

	docPusherSetup(docId) {
		const channel = pusherInstance.subscribe(`document-${docId}`);
		channel.bind('new-reaction', this.handleNewReactionPusher)  
		channel.bind('reaction-deleted', this.handleReactionDeletedPusher)  
		channel.bind('doc-updated', this.handleDocUpdatedPusher) 
		this.props.fetchReactions(docId)
	}

	handleReactionDeletedPusher(pusherData){
		if(!isCurrentUser(pusherData.userId)){
			this.props.handleReactionDeletedPusher(pusherData.reactionId)
		}
	}

	handleNewReactionPusher(pusherData){
		if(!isCurrentUser(pusherData.reaction.created_by)){
			this.props.handleNewReactionPusher(pusherData.reaction)
		}
	}	

	refetchDocInstance(docId){
		this.props.getDocInstance(docId)
	}

	fetchDocHtml(docId){
		this.props.fetchADocHTML(docId)
	}

	//for now just refetch the collab instance when its updated
	//maybe add throttle!
	handleDocUpdatedPusher(data){
		const docId=data.data.docId
		if(docId){
			this.refetchDocInstance(docId)
			this.fetchDocHtml(docId)
		}
	}

	render() {
		return null
	}
}

function mapStateToProps(state,ownProps){
	let openDocs=[]
	if(state.openTabs && state.openTabs.tabGroups){
		Object.keys((state.openTabs.tabGroups)).forEach((tabGroupId)=>{
			const tabGroup=state.openTabs.tabGroups[tabGroupId]
			tabGroup.forEach((tab)=>{
				if(tab.type=='doc'){
					openDocs.push(tab.id)
				}
				
			})
		})
	}
	return {
		openTabs:openDocs
	}
}

export default connect(
	mapStateToProps,
	{
		getDocInstance,
		fetchADocHTML,
		clearCollabInstanceFromState,
		fetchReactions,
		handleNewReactionPusher,
		handleReactionDeletedPusher
	})(CollabInstanceManager)



