import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import DocEditorMeetingInfoEditDatePopoverContents from './DocEditorMeetingInfoEditDatePopoverContents'

class DocEditorMeetingInfoEditDatePopover extends React.Component{  

	constructor(){
		super()
		this.onRest=this.onRest.bind(this)			
		this.state={
      forceMount: false,            
    }
	}

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }  

	render(){	
		const {meeting} = this.props
		const {showPopover, openPopover, closePopover} = this.props
		const {forceMount} = this.state
		const translateY = -8
		const popoverSide = "bottom"
		const popoverSideOffset= 4
		const popoverAlign = "start"
		const popoverAlignOffset= -20
	  return (
	  	<React.Fragment>
		  	<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>						
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'editMeetingEditDatePopoverContainer ' + (showPopover ? ' editMeetingEditDatePopoverContainer--visible ' : ' editMeetingEditDatePopoverContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							//onInteractOutside={closePopover}							
							side={popoverSide}
							sideOffset={popoverSideOffset}
							align={popoverAlign}
							alignOffset={popoverAlignOffset}
							forceMount={showPopover ? true : forceMount}
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateY(${translateY}px)` }}
								enter={{ opacity: 1, transform: 'translateY(0px)'  }}
								leave={{ opacity: 0, transform: `translateY(${translateY}px)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 35}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
										<animated.div style={styles} className="editMeetingEditDatePopover popover"> 												
											<DocEditorMeetingInfoEditDatePopoverContents 
												meeting={meeting}
												selectNewMeetingDate={this.props.selectNewMeetingDate}
												closePopover={closePopover}									
											/>										
										</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>
			</React.Fragment>
	  )
	}
}

export default DocEditorMeetingInfoEditDatePopover
