import React from 'react'
import Icon from '../../misc/Icon'
import {createContact} from '../../../utils/meetings/createContact'
import { Spring, Transition, animated, config, easings} from 'react-spring'

class EditParticipantListPopoverNewContact extends React.Component{  

	constructor(props) {
    super(props)    
   	this.handleNameChange = this.handleNameChange.bind(this);
   	this.handleEmailChange = this.handleEmailChange.bind(this);
   	this.handleCompanyChange = this.handleCompanyChange.bind(this);
   	this.createContact = this.createContact.bind(this)
    this.handleKeyDown=this.handleKeyDown.bind(this)
    let nameValue=''
    let emailValue=''
    if(props.searchValue.includes("@")){
      emailValue=props.searchValue
    }else{
      nameValue=props.searchValue
    }
    this.state = {
    	nameValue:nameValue,
    	companyValue: '',
    	emailValue: emailValue,
      submitLoading:false
   	};
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown)
  }
  
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown(e){
    if(e.keyCode==13){ //enterKey
      const {nameValue,emailValue}=this.state
      if(nameValue.length > 1 || emailValue.length > 1){
        this.createContact()
      }
    }
  }


	handleNameChange(event) {  	   
    this.setState({nameValue: event.target.value});
  }
  handleEmailChange(event) {  	   
    this.setState({emailValue: event.target.value});
  }
  handleCompanyChange(event) {  	   
    this.setState({companyValue: event.target.value});
  }

  createContact(){
    this.setState({submitLoading:true})
  	const name = this.state.nameValue
  	const company = this.state.companyValue
  	const email = this.state.emailValue
  	createContact(name,company,email).then((response)=>{
      this.props.addNewContactAsParticipant(response)
  		this.props.handleBackFromNewContact()
  	})
  }

	render(){	
		const {participants} = this.props	
		const {nameValue, companyValue, emailValue,submitLoading} = this.state

		const submitBtnEnabled = (nameValue.length > 1 || emailValue.length > 1)

	  return (
  		<div className='editMeetingParticipantsPopover-newContact'>
  			<div className='editMeetingParticipantsPopover-newContact-header'>
  				<button onClick={this.props.handleBackFromNewContact} className='editMeetingParticipantsPopover-newContact-header-backButton'>
  					<Icon name='chevronRight' />
  				</button>
  				<div className='editMeetingParticipantsPopover-newContact-header-label'>
  					New Contact
  				</div>
  			</div>
  			<div className='editMeetingParticipantsPopover-newContact-input'>
  				<div className='editMeetingParticipantsPopover-newContact-input-label'>
  					Name
  				</div>
  				<div className='editMeetingParticipantsPopover-newContact-input-inputContainer'>
		  			<input 			
              autoFocus				
							placeholder='Joe Licklider'
							className='editMeetingParticipantsPopover-newContact-input-input'
							value={nameValue}
							onChange={this.handleNameChange}								
						/>
					</div>
				</div>
				<div className='editMeetingParticipantsPopover-newContact-input'>
  				<div className='editMeetingParticipantsPopover-newContact-input-label'>
  					Company
  				</div>
  				<div className='editMeetingParticipantsPopover-newContact-input-inputContainer'>
		  			<input 
							placeholder='ARPA'
							className='editMeetingParticipantsPopover-newContact-input-input'
							value={companyValue}
							onChange={this.handleCompanyChange}								
						/>
					</div>
				</div>
				<div className='editMeetingParticipantsPopover-newContact-input'>
  				<div className='editMeetingParticipantsPopover-newContact-input-label'>
  					Email
  				</div>
  				<div className='editMeetingParticipantsPopover-newContact-input-inputContainer'>
		  			<input 
							placeholder='jcr@arpa.net'
							className='editMeetingParticipantsPopover-newContact-input-input'
							value={emailValue}
							onChange={this.handleEmailChange}								
						/>
					</div>
				</div>
				<div className='editMeetingParticipantsPopover-newContact-vSpacer' />
				<div className='editMeetingParticipantsPopover-newContact-submitBtnContainer'>
					
					{!submitLoading &&
						<button 
							onClick={this.createContact}
							disabled={!submitBtnEnabled}
							className='editMeetingParticipantsPopover-newContact-submitBtn'
						>
	  					Add Contact
	  				</button>
  				}

  				{submitLoading &&
						<button
							onClick={this.createContact}
							disabled={!submitBtnEnabled}
							className='editMeetingParticipantsPopover-newContact-submitBtn editMeetingParticipantsPopover-newContact-submitBtn--submitLoading'
						>
	  					<div className='editMeetingParticipantsPopover-newContact-submitBtn-loadingBar'>
				  			<Spring
				  				native								
									from={{
										opacity: 0.4,
										transform: 'translateX(-120px)'
									}}
									to={[
			          		{ 
			          			opacity: 0.05,
			          			transform: 'translateX(140px)' 
			          		},	          		
			        		]}								
									loop								
									config={{									
										duration: 800,									
										easing: easings.easeInOutCubic,
									}}
								>
					  			{styles => 
										<animated.div style={styles} className='editProjectModal-footerRow-submitBtn-loadingBar-ball' />
					  			}
					  		</Spring>	  		 			
				  	</div>
	  				</button>
  				}

  			</div>
  		</div>
	  )
	}
}

export default EditParticipantListPopoverNewContact
