import store from '../store'
import find from 'lodash/find'


export function getOrgThreadsEnabled() {	
	let threads_enabled=false
	const state = store.getState()
	const org=state.organization
	if(org && org.threads_enabled){
		threads_enabled=true
	}

	return threads_enabled 
}
