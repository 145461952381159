import React from 'react'
import Icon from '../../misc/Icon'

const raycastBlushingFirePreviewUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899463/Devices/backgroundImages/previews/blushing-fire_copy_kpirnj.png'
const raycastRoseThornPreviewUrl = 'https://res.cloudinary.com/yarn/image/upload/v1675899464/Devices/backgroundImages/previews/rose-thorn_copy_owpc0o.png'
const richBlueSpotlightPreviewUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676900586/Devices/backgroundImages/previews/richBlueSpotPreview_k2jfdl.png'
let venturaPreviewUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676501667/Devices/backgroundImages/previews/venturaLight_bvwzas.png'
let montereyPreviewUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676501667/Devices/backgroundImages/previews/montereyLight_fmj1be.png'
const meshGreenBluePreviewUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676900007/Devices/backgroundImages/previews/meshgreenbluepreview_bpsocv.png'



class DocFigmaEmbedEffectsToolbarBGButton extends React.Component{  
  
  constructor(props){
    super()    
    this.handleBackgroundBGBtnClick=this.handleBackgroundBGBtnClick.bind(this)    
  }

  handleBackgroundBGBtnClick(){    
    const bgName = this.props.bgName
    if(this.props.backgroundImage !== this.props.bgName){
    	this.props.editBackgroundImage(bgName)	
    }else{
    	this.props.editBackgroundImage(null)
    }
  }

	render(){ 
		const {bgName, backgroundImage}=this.props
		const isActive = bgName === backgroundImage
		const theme = 'light'
		if(theme === 'dark'){
			venturaPreviewUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676501667/Devices/backgroundImages/previews/venturaDark_wjl9nx.png'
			montereyPreviewUrl = 'https://res.cloudinary.com/yarn/image/upload/v1676501667/Devices/backgroundImages/previews/montereyDark_u5htea.png'
		}
		return (			
			<div className={'doc-figma-effectsToolbar-bgButtonCarousel-btnContainer' + (isActive ? ' doc-figma-effectsToolbar-bgButtonCarousel-btnContainer--active ' : ' doc-figma-effectsToolbar-bgButtonCarousel-btnContainer--inactive ' )}>
				<button onClick={this.handleBackgroundBGBtnClick} className={'doc-figma-effectsToolbar-bgButtonCarousel-btn ' + (bgName ? ` doc-figma-effectsToolbar-bgButtonCarousel-btn--${bgName} `: '')}>
					{bgName === 'monterey' && 
						<img src={montereyPreviewUrl} className='doc-figma-effectsToolbar-bgButtonCarousel-btn-image' />
					}
					{bgName === 'ventura' && 
						<img src={venturaPreviewUrl} className='doc-figma-effectsToolbar-bgButtonCarousel-btn-image' />
					}
					{bgName === 'raycastBlushingFire' && 
						<img src={raycastBlushingFirePreviewUrl} className='doc-figma-effectsToolbar-bgButtonCarousel-btn-image' />
					}
					{bgName === 'raycastRoseThorn' && 
						<img src={raycastRoseThornPreviewUrl} className='doc-figma-effectsToolbar-bgButtonCarousel-btn-image' />
					}
					{bgName === 'meshGreenBlue' && 
						<img src={meshGreenBluePreviewUrl} className='doc-figma-effectsToolbar-bgButtonCarousel-btn-image' />
					}
					{bgName === 'richBlueSpotlight' && 
						<img src={richBlueSpotlightPreviewUrl} className='doc-figma-effectsToolbar-bgButtonCarousel-btn-image' />
					}
				</button>
				<div className='doc-figma-effectsToolbar-bgButtonCarousel-btnActiveBorder' />
			</div>		
		)
	}
}


class DocFigmaEmbedEffectsToolbarBGBtnCarousel extends React.Component{  
  
	render(){ 
		const {backgroundImage, device}=this.props
		return (			
			<div className='doc-figma-effectsToolbar-bgButtonCarousel'>
				{/*}
				<div className='doc-figma-effectsToolbar-bgButtonCarousel-scrollBtnContainer'>
					<button className='doc-figma-effectsToolbar-bgButtonCarousel-scrollBtn doc-figma-effectsToolbar-bgButtonCarousel-scrollBtn--previous'>
						<Icon name='chevronRight' />
					</button>
				</div>
				*/}
				<div className='doc-figma-effectsToolbar-bgButtonCarousel-contentsContainer'>
					<div className='doc-figma-effectsToolbar-bgButtonCarousel-contents'>
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='light'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='lightBlue'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='lightOrange'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='lightPurple'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='lightGreen'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						{device === 'desktop' &&
							<DocFigmaEmbedEffectsToolbarBGButton
								bgName='monterey'
								editBackgroundImage={this.props.editBackgroundImage}
								backgroundImage={this.props.backgroundImage}
							/>
						}
						{device === 'desktop' &&
							<DocFigmaEmbedEffectsToolbarBGButton
								bgName='ventura'
								editBackgroundImage={this.props.editBackgroundImage}
								backgroundImage={this.props.backgroundImage}
							/>
						}
						{/*}
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='raycastBlushingFire'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='raycastRoseThorn'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						*/}				
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='meshGreenBlue'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='richBlueSpotlight'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						{/*}
						<DocFigmaEmbedEffectsToolbarBGButton
							bgName='TBCD'
							editBackgroundImage={this.props.editBackgroundImage}
							backgroundImage={this.props.backgroundImage}
						/>
						*/}
					</div>
				</div>
				{/*}
				<div className='doc-figma-effectsToolbar-bgButtonCarousel-scrollBtnContainer'>
					<button className='doc-figma-effectsToolbar-bgButtonCarousel-scrollBtn doc-figma-effectsToolbar-bgButtonCarousel-scrollBtn--next'>
						<Icon name='chevronRight' />
					</button>
				</div>
				*/}
			</div>									
		)
	}
}

export default DocFigmaEmbedEffectsToolbarBGBtnCarousel
