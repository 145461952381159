import React from 'react'
import Icon from '../../misc/Icon'

class DocTableNewColumnButton extends React.Component{  
	render(){
	  return (

		  	<button className='doc-table-newColumnBtn' onClick={this.props.addNewColumn} onMouseDown={(e)=>{e.stopPropagation()}}>
		  		<Icon name='plus'/>
		  	</button>
		  	

	  )
	}
}

export default DocTableNewColumnButton
