import Pusher from 'pusher-js'

const PUSHER_KEY=process.env.REACT_APP_PUSHER_KEY||window.envVars.reactAppPusherKey

let API=process.env.REACT_APP_API_ENDPOINT||window.envVars.reactAppApiEndpoint


Pusher.Runtime.createXHR = function () {
var xhr = new XMLHttpRequest();
xhr.withCredentials = true;
return xhr;
};

//Pusher.logToConsole = true 

const pusher = new Pusher(PUSHER_KEY, {
	encrypted: true,
	cluster: "us2",
	authEndpoint: `${API}/pusher/auth`,
})

export default pusher