import React from 'react'
import Icon from '../misc/Icon'
import FigmaStatusIcon from '../misc/FigmaStatusIcon'
import DocImageZoomedModalBtn from '../docEditor/mediaModals/DocImageZoomedModalBtn'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import FigmaViewZoomedUIStatusMenu from './FigmaViewZoomedUIStatusMenu'


class FigmaViewZoomedUIStatus extends React.Component{  	

	constructor(){
    super()       
    this.state={      
      showMenu: false,
      status: 'started'
    }
  }

	render(){	
		const {cropModeActive,showEffectsToolbar,viewOnlyMode} = this.props
		const {showMenu,status} = this.state

		let statusLabel = 'Add Status'
		if(status ==='concept'){
			statusLabel = 'Concept'
		}
		if(status ==='started'){
			statusLabel = 'In Progress'
		}
		if(status ==='proposed'){
			statusLabel = 'Proposed'
		}
		if(status ==='completed'){
			statusLabel = 'Done'
		}


		return (
			<React.Fragment>

			{/*}
				<DocImageZoomedModalBtn
					iconName='upArrowMedium'
					tooltipLabel='Update from Figma. To replace, paste a Figma URL.'
					name='figmaModalUpdate'
				/>			
				*/}
		
				<div className={'doc-zoomedFigmaModal-statusBtnWrapper' + (status ? '' : ' doc-zoomedFigmaModal-statusBtnWrapper--noStatus')}>
					<DocImageZoomedModalBtn							
						name='figmaModalStatus'
						label={statusLabel}
						onClickFunction={()=>{this.setState({showMenu: !showMenu})}}
					>
						{status && 
							<div className='doc-zoomedFigmaModal-status-customIcon'>
								<FigmaStatusIcon status={status} />
							</div>
						}

					</DocImageZoomedModalBtn>							
				</div>

				{showMenu &&				
					<FigmaViewZoomedUIStatusMenu 
						changeStatus={(value)=>{this.setState({status: value})}}
						status={status}
					/>					
				}
				
				
		

				{/* }
				<DocImageZoomedModalBtn
					label='v4'
					tooltipLabel='Browse previous versions'
					name='figmaModalVersions'
				/>	
				*/}		




			</React.Fragment>
		)
	}
}

export default FigmaViewZoomedUIStatus
