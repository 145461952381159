import store from '../store'
import {fetch,put,post,deleteRequest,batchedFetch} from '../api'
import {fetchRecentAcitivities} from './recentActivities'
import {backroundOpenDocsWithUnreads} from './docs'
import {bumpMessageVersion} from '../prosemirror/utils/bumpMessageVersion'
import { Mixpanel } from '../Mixpanel'

export function fetchAllMessages(isInitialLoad){
	return (dispatch) => batchedFetch(`/messages`)
	.then((response) => {
		dispatch({ type: 'FETCH_ALL_MESSAGES_SUCCESS', response })
		bumpMessageVersion()
	//	console.log('is initial load!!!!')
		if(isInitialLoad){ //check for any docs with unreads and open them
			//if(state.docs && state.docs.length){
				backroundOpenDocsWithUnreads()
			// }else{
			// 	console.log('docs havent loaded yet')
			// }
		}

		return response
	})
	.catch((error) => {
		return error
	})
}

export function handleNewMessagePusher(message){
	return (dispatch) => {
		dispatch({ type: 'CREATE_MESSAGE_REQUEST', message})
	}
}

export function handleNewAutoMessagePusher(message){
	return (dispatch) => {
		dispatch({ type: 'CREATE_MESSAGE_REQUEST', message})
	}
}


export function handleMessageDeletedPusher(messageId){
	return (dispatch) => {
		dispatch({ type: 'DELETE_MESSAGE_SUCCESS', messageId})
	}
}

export function fetchAMessage(messageId){
	return (dispatch) => fetch(`/messages/${messageId}`)
	.then((response) => {
		dispatch({ type: 'FETCH_A_MESSAGES_SUCCESS', response })
		return response
	})
	.catch((error) => {
		return error
	})
}

export function createMessage(message,orgId){
	let requestBody={
		message:message,
		orgId:orgId
	}
	return (dispatch) => {
		dispatch({ type: 'CREATE_MESSAGE_REQUEST', message})
		return post(`/messages`,requestBody)
			.then((response) => {
				dispatch({ type: 'CREATE_MESSAGE_SUCCESS', response})
				dispatch(fetchRecentAcitivities())
				let isThreadReply=false 
				if(message.thread_parent){
					isThreadReply=true
				}
				const isDocThread=message.in_doc_thread
				Mixpanel.track('send_message',{isDocThread:isDocThread,isThreadReply:isThreadReply,orgId:orgId})
			return response
		})
		.catch((error) => {
			return error
		})
	}
}

export function deleteMessage(message,orgId){
	const messageId=message['message_id']
	return (dispatch) =>{
		dispatch({ type: 'DELETE_MESSAGE_REQUEST', messageId})
		return deleteRequest(`/messages/${messageId}`)
		.then((response) => {
			dispatch({ type: 'DELETE_MESSAGE_SUCCESS', messageId})
			dispatch(fetchRecentAcitivities())
			return response
		})
		.catch((error) => {
			return error
		})
	}
}
