import { Plugin  } from 'prosemirror-state';
import { Decoration,DecorationSet } from 'prosemirror-view';
import { tableNodes } from 'prosemirror-tables';
import { Slice, Fragment } from 'prosemirror-model';
import {getAnchorNode} from '../../utils/getAnchorNode'
import editorSchema from '../../schema/editorSchema'
import {randomID} from '../../../utils/randomID'
import {getNodePos} from '../../utils/getNodePos'

//wrap any tables in docTable
//we can also use this for nodeids for copy/paste table nodes (or maybe we will do this in transform pasted)

function wrapTablesInDocTable(tr, schema) {
  let modified = false;
  
  tr.doc.forEach((docNode, docOffset) => {
    if(docNode.type.name=='editorPage'){
      docNode.forEach((node, offset) => { //lets go 1 level deep
        if(node.type.name=='table'){ //lets wrap it in a docTable
          const nodeId=randomID()
          const newNode = editorSchema.nodes.docTable.create({nodeId:nodeId}, node)
          tr.replaceWith(docOffset + offset, docOffset + offset + node.nodeSize, newNode);
          modified=true
        }  
        if(node.type.name=='docTable' && !node.attrs.nodeId){ //lets wrap it in a docTable
          //if it doesnt have a nodeId e.g. from copy paste table then add a node id
          console.log('ADD AN ID!!!!!!')
          const nodeId=randomID()
          const nodePos=docOffset+offset
          tr.setNodeMarkup(docOffset + offset+1, null, {...node.attrs,nodeId:nodeId})
          //const newNode = editorSchema.nodes.docTable.create({nodeId:nodeId}, node)
          //tr.replaceWith(docOffset + offset, docOffset + offset + node.nodeSize, newNode);
          modified=true
        }  
      })
    }
  })


  return modified ? tr : null;
}



// function wrapTablesInDocTable(tr, schema) {
//   let modified = false;

//   tr.doc.descendants((node, pos) => {
//     if(node.type.name == 'table'){
//       const docTableNode=getAnchorNode(pos,'docTable')
//       console.log('docTableNode')
//       // // Need to add 1 to the start position to avoid including the parent node itself.
//       // let startPos = pos + 1; 
//       // let endPos = startPos + node.nodeSize;
//       if(!docTableNode){
//         console.log(`pos ${pos}`)
//         console.log(`end ${pos+node.nodeSize}`)
//         tr.wrap(pos, pos+node.nodeSize, [{ type: schema.nodes.docTable }]);
//         modified = true;
//       }
//     }
//   });

//   return modified ? tr : null;
// }

export function tablePastingPlugin() {
  return new Plugin({
    appendTransaction: (transactions, oldState, newState) => {
      if (transactions.some(tr => tr.docChanged)) {
        return wrapTablesInDocTable(newState.tr, newState.schema);
      }
  }
})
}
