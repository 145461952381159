import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { useEffect } from 'react'
import { useLayoutEffect, useState } from 'react'
import { track, useEditor } from "@tldraw/tldraw"
import { Tldraw ,TLEditorComponents,createTLStore} from "@tldraw/tldraw"
import { throttle } from '@tldraw/utils'
import {
	Canvas,
	ContextMenu,
	TldrawEditor,
	TldrawUi,
	defaultShapes,
	defaultTools,

} from '@tldraw/tldraw'
import '@tldraw/tldraw/tldraw.css'
import './custom-ui.css'
// import {useEditor } from "@tldraw/tldraw"
// import throttle from 'lodash/throttle'
import TLDrawUI from './TLDrawUI'

const PERSISTENCE_KEY = 'test'


// import TLDrawCustomUI from './TLDrawCustomUI'
// import { track } from 'signia-react'
// import TLDrawTest from './TLDrawTest'

const THROTTLE_TIME=500

// const CustomUi = track(() => {
// 	const editor = useEditor()

// 	useEffect(() => {
// 		const handleKeyUp = (e: KeyboardEvent) => {
// 			switch (e.key) {
// 				case 'Delete':
// 				case 'Backspace': {
// 					editor.deleteShapes()
// 				}
// 			}
// 		}

// 		window.addEventListener('keyup', handleKeyUp)
// 		return () => {
// 			window.removeEventListener('keyup', handleKeyUp)
// 		}
// 	})

// 	return (
// 		<div className="custom-layout">
// 			<div className="custom-toolbar">
// 				<button
// 					className="custom-button"
// 					data-isactive={editor.currentToolId === 'select'}
// 					onClick={() => editor.setSelectedTool('select')}
// 				>
// 					Select
// 				</button>
// 				<button
// 					className="custom-button"
// 					data-isactive={editor.currentToolId === 'draw'}
// 					onClick={() => editor.setSelectedTool('draw')}
// 				>
// 					Pencil
// 				</button>
// 				<button
// 					className="custom-button"
// 					data-isactive={editor.currentToolId === 'eraser'}
// 					onClick={() => editor.setSelectedTool('eraser')}
// 				>
// 					Eraser
// 				</button>
// 			</div>
// 		</div>
// 	)
// })


export default function TLDrawTestContainer(){
	const [store] = useState(() => createTLStore({shapes:defaultShapes,tools:defaultTools})) 
	const [loadingState, setLoadingState] = useState({
		status: 'loading',
	});


	useLayoutEffect(() => {
		const persistedSnapshot = localStorage.getItem(PERSISTENCE_KEY)
		if (persistedSnapshot) {
			try {
				const snapshot = JSON.parse(persistedSnapshot)
				store.loadSnapshot(snapshot)
				setLoadingState({ status: 'ready' })
			} catch (error) {
				setLoadingState({ status: 'error', error: error.message }) // Something went wrong
			}
		} else {
			setLoadingState({ status: 'ready' }) // Nothing persisted, continue with the empty store
		}
		// Each time the store changes, run the (debounced) persist function
		const cleanupFn = store.listen(
			throttle(() => {
				const snapshot = store.getSnapshot()
				console.log('snapshot is------')
				console.log(snapshot)
				// console.log(store)
				localStorage.setItem(PERSISTENCE_KEY, JSON.stringify(snapshot))
			}, THROTTLE_TIME)
		)
		return () => {
			cleanupFn()
		}
	}, [store])




	// constructor() {
	// 	super()
	// 	const tldrDoc = JSON.parse(localStorage.getItem('tldrDoc'))

	// 	let doc=null
	// 	if(tldrDoc){
	// 		doc=tldrDoc
	// 	}


	// 	this.handleOnChange=this.handleOnChange.bind(this)
	// 	this.handleOnChange=throttle(this.handleOnChange,THROTTLE_TIME,{leading:true,trailing:true})

	// 	this.state={
	// 		document:doc
	// 	}
	// }


// handleMount(){
// 	console.log('handle mount')
// }

// 	handleOnChange(TLDrawApp){
// 		//console.log('handle on change-----')
// 		console.log(TLDrawApp)
// 		const docJson=TLDrawApp.state.document
// 		//console.log(docJson)
// 		//this.setState({document:docJson})
// 		localStorage.setItem(
// 			'tldrDoc',
// 				JSON.stringify(docJson)
// 		)
// 		TLDrawApp.copySvg().then((svg)=>{
// 			console.log(svg)
// 		})
		

// 	}

	//render(){  
		// console.log(defaultTools)
		// console.log(defaultShapes)
		// console.log(`loading state is`)
		// console.log(loadingState)
		// console.log('store is')
		// console.log(store)

		return (	  	
			<div
				style={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "50vw",
				height: "50vh"
				}}
			>
			 TLDraw test page!

			 	<TldrawEditor 
			 		store={store}
			 		shapes={defaultShapes} 
			 		tools={defaultTools} autoFocus>
				
					<Canvas />

				<TLDrawUI/>
			
			</TldrawEditor>
			</div>    			     	
		)
	//}
}



// function mapStateToProps(state,ownProps) {


// 	return {
			
// 	}
// }


// export default withRouter(connect(mapStateToProps,
// 	{}
// )(TLDrawTestContainer))	



// const CustomUi = track(() => {
// 	const editor = useEditor()

// 	useEffect(() => {
// 		const handleKeyUp = (e: KeyboardEvent) => {
// 			switch (e.key) {
// 				case 'Delete':
// 				case 'Backspace': {
// 					editor.deleteShapes()
// 				}
// 			}
// 		}

// 		window.addEventListener('keyup', handleKeyUp)
// 		return () => {
// 			window.removeEventListener('keyup', handleKeyUp)
// 		}
// 	})
// 	console.log('here-------!!! custom ui')
// 	console.log(editor)
// 	console.log(editor.currentToolId)

// 	return (
// 		<div className="custom-layout">
// 			<div className="custom-toolbar">
// 				<button
// 					className="custom-button"
// 					data-isactive={editor.currentToolId === 'select'}
// 					onClick={() => editor.setSelectedTool('select')}
// 				>
// 					Select
// 				</button>
// 				<button
// 					className="custom-button"
// 					data-isactive={editor.currentToolId === 'draw'}
// 					onClick={() => editor.setSelectedTool('draw')}
// 				>
// 					Pencil
// 				</button>
// 				<button
// 					className="custom-button"
// 					data-isactive={editor.currentToolId === 'eraser'}
// 					onClick={() => editor.setSelectedTool('eraser')}
// 				>
// 					Eraser
// 				</button>


// 				<button
// 					className="custom-button"
// 					data-isactive={editor.currentToolId === 'eraser'}
// 					onClick={() => editor.setSelectedTool('eraser')}
// 				>
// 					Blue
// 				</button>

// 			</div>
// 		</div>
// 	)
// })


