import React from 'react'
import { withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import Icon from './misc/Icon'
import LinearMarker from './misc/LinearMarker'
import * as Toast from '@radix-ui/react-toast';
import {openDoc} from '../utils/openDoc'
import {getTeamForId} from '../utils/getTeamForId'
import {getRoadmapForId} from '../utils/getRoadmapForId'
import {getDocForId} from '../utils/getDocForId'
import {getProjectForId} from '../utils/getProjectForId'
import {jumpToCursor} from '../utils/jumpToCursor'
import {getOrg} from '../utils/getOrg'
import {showNewTemplateInModal} from '../utils/templates/showNewTemplateInModal'


//const activeUserName = "nicole"
//let toastType = "jumpToCursorAlert" 				// someone's come to your cursor
//let toastType = "jumpToCursorConfirmation" 	// you've gone to someone's curspr
//let toastType = "pingCursorAlert" 					// someone has pinged their cursor to you
// let toastType = "pingCursorConfirmation" 		// you've successfully sent your cursor to someone

class ToastNotifications extends React.Component{  
	render(){			
		const {toastNotification}=this.props
		let userName
		let toastType
		let toastDuration
		let iconName
		let emoji		
		let label
		let docName
		let teamName
		let roadmapName
		let teamId
		let projectName
		let projectId
		let orgName
		let orgLogo

		if(toastNotification){
			userName=toastNotification.userName
			toastType=toastNotification.alertType
		}
		if(toastType === 'jumpToCursorAlert'){
			toastDuration = 2000
			iconName='jump'
			emoji="✈️"			
		}else if(toastType === 'jumpToCursorConfirmation'){
			toastDuration = 2000
			iconName='jump'
			emoji="✈️"		
		}else if(toastType === 'pingCursorAlert'){
			toastDuration = 10000			
			iconName='broadcast'			
			emoji="👋"			
		}else if(toastType === 'pingCursorConfirmation'){
			toastDuration = 10000	
			iconName='broadcast'
			emoji="📡"						
		}else if(toastType === 'clickDeletedMessageReply'){
			toastDuration = 5000			
			emoji="🗑️"			
		}else if(toastType === 'clickDeletedMessageVideo'){
			toastDuration = 5000			
			emoji="🎞️"			
		}else if(toastType === 'clickDeletedMessageImage'){
			toastDuration = 5000
			emoji="🏞️"			
		}else if(toastType === 'clickDeletedMessageFigma'){
			toastDuration = 5000
			emoji="🎨"			
		}else if(toastType === 'updatedTargetDateCalendar'){
			toastDuration = 1000
			emoji="🗓️"
		}
		else if(toastType === 'sentMediaModalComment'){
			toastDuration = 1000
			emoji="✉️"
		}else if(toastType === 'updatedProject'){
			toastDuration = 1000
			emoji="🗂️"
		}
		else if(toastType === 'updatedProjectError'){
			toastDuration = 2000
			emoji="🚩"
		}		
		else if(toastType === 'createdProject'){			
			const projectObj = getProjectForId(userName)
			projectName = projectObj.name
			projectId = projectObj.project_id
			toastDuration = 2000
			emoji=null
		}
		else if(toastType === 'updatedRoadmaps'){			
			const projectObj = getProjectForId(userName)
			projectName = projectObj.name
			projectId = projectObj.project_id
			toastDuration = 3000
			emoji=null
		}
		else if(toastType === 'addSingleRoadmapToProject'){			
			const projectObj = getProjectForId(userName)
			projectName = projectObj.name
			projectId = projectObj.project_id

			const roadmapObj = getRoadmapForId(toastNotification.actionButton)
			roadmapName = roadmapObj.name			

			toastDuration = 3000
			emoji=null
		}
		else if(toastType === 'removeSingleRoadmapFromProject'){			
			const projectObj = getProjectForId(userName)
			projectName = projectObj.name
			projectId = projectObj.project_id

			const roadmapObj = getRoadmapForId(toastNotification.actionButton)
			roadmapName = roadmapObj.name			
			
			toastDuration = 3000
			emoji=null
		}
		
		else if(toastType === 'createdProjectError'){
			toastDuration = 2000
			emoji="🚩"
		}		
		else if(toastType === 'dndSwitchOn'){
			toastDuration = 2500
			emoji="🎧"			
		}else if(toastType === 'saveDefaultIssueMentionDisplayProperties'){
			toastDuration = 3000			
			emoji="🗃️"
		}
		else if(toastType === 'createdTemplate'){
			toastDuration = 5000
			emoji="🗂️"
		}
		else if(toastType === 'archivedTemplate'){
			toastDuration = 2500			
			emoji = "🗃️"
		}
		else if(toastType === 'addedMeetingToCal'){
			toastDuration = 2500			
			emoji = "🗓️"
		}	
		else if(toastType === 'dndSwitchOff'){
			toastDuration = 2500
			emoji="🔔"
		}else if(toastType === 'moveDocToOrg'){
			toastDuration = 3000			
			const orgObj = getOrg()
			orgName = orgObj.name
			orgLogo = orgObj.logo
		}else if(toastType === 'copiedDocLink' && userName){			
			const docObj = getDocForId(userName)
			docName = docObj.name			
			toastDuration = 3000
			emoji="📋"
		}else if(toastType === 'copiedRoadmapLink' && userName){			
			const roadmapObj = getRoadmapForId(userName)
			docName = roadmapObj.name			
			toastDuration = 3000
			emoji="📋"
		}
		else if(toastType === 'moveDocToTeam' && userName){
			const teamObj = getTeamForId(userName)
			teamName = teamObj.name
			teamId = teamObj.team_id
			toastDuration = 3000							
			emoji=null	
		}else if(toastType === 'moveDocToRoadmap' && userName){
			const roadmapObj = getRoadmapForId(userName)
			roadmapName = roadmapObj.name
			teamId = roadmapObj.roadmap_id
			toastDuration = 3000									
			emoji=null	
		}else if(toastType === 'moveDocToProject' && userName){
			const projectObj = getProjectForId(userName)
			projectName = projectObj.name
			projectId = projectObj.project_id
			toastDuration = 3000
			emoji=null
		}
		else if(toastType === 'updatedProjectTargetDate' && userName){
			const projectObj = getProjectForId(userName)
			projectName = projectObj.name
			projectId = projectObj.project_id
			toastDuration = 3000
			emoji=null
		}		


		if(toastNotification){
		  return (		
		  	<React.Fragment key={toastNotification.timestamp}>
					<Toast.Provider className='toastContainer' duration={toastDuration}>
			   	 <Toast.Root className={'toast ' + (toastType ? ` toast--type--${toastType}` : '' )}>
			    	{/* ICON APPROACH
			      	<div className='toast-iconContainer'>
			      		<Icon name={iconName} />
			      	</div>
			    	*/}
			    	{emoji && 
				      <div className='toast-emojiContainer'>
				      	{emoji}
				      </div>
				    }
				   	{toastType === 'sentMediaModalComment' &&
			      		<div className='toast-label'>			      			
			      			Comment added
			      		</div>
			      	}
			      	{toastType === 'clickDeletedMessageReply' &&
			      		<div className='toast-label'>			      			
			      			The linked message was deleted.
			      		</div>
			      	}
			      	{(toastType === 'copiedDocLink'||toastType === 'copiedRoadmapLink') && docName &&
			      		<div className='toast-label'>			      			
			      			<span className='toast-label-white'>{docName}</span> URL copied to clipboard.      			
			      		</div>
			      	}
			      	
							{toastType === 'moveDocToRoadmap' && roadmapName && 
								<div className='toast-label toast-label--hasMarker'>	
									<div className='toast-label--hasMarker-label'>
										Moved to 
									</div>			      			
									<div className='toast-label--hasMarker--roadmap'>
										<Icon name='roadmapOutline' />
									</div>
									<div className='toast-label--hasMarker-label'>
										<span className='toast-label-white'>
											{roadmapName}
										</span>
									</div>
							</div>
							}
							{toastType === 'moveDocToOrg' && orgName && orgLogo &&
								<React.Fragment>									
									<div className='toast-label toast-label--hasMarker'>	
										<div className='toast-label--hasMarker-label'>
											Moved to 	
										</div>			
										<div className='toast-label--hasMarker--orgLogo'>
											<img src={orgLogo} className='toast-label--hasMarker--orgLogo-image' />
										</div>					
										<div className='toast-label--hasMarker-label'>
											<span className='toast-label-white'>
												{orgName}
											</span>
										</div>
									</div>
								</React.Fragment>
							}
			      	{toastType === 'moveDocToProject' && projectName && projectId && 
			      		<div className='toast-label toast-label--hasMarker'>	
			      			<div className='toast-label--hasMarker-label'>
			      				Moved to 
			      			</div>			      			
			      			<div className='toast-label--hasMarker-linearMarkerContainer'>
			      				<LinearMarker projectId={projectId} />
			      			</div>
			      			<div className='toast-label--hasMarker-label'>
			      				<span className='toast-label-white'>
			      					{projectName}
			      				</span>
			      			</div>
			      		</div>
			      	}
			      	{toastType === 'updatedProjectTargetDate' && projectName && projectId && 
			      		<div className='toast-label toast-label--hasMarker'>				      				
			      			<div className='toast-label--hasMarker-label'>			      				
			      				Target date updated			      				
			      			</div>			      	
			      			{/*}
			      			<div className='toast-label--hasMarker-linearMarkerContainer'>
			      				<LinearMarker projectId={projectId} />
			      			</div>
			      			<div className='toast-label--hasMarker-label'>			      				
			      				{projectName} 			      				
			      			</div>			      			
			      			*/}
			      		</div>
			      	}
			      	{toastType === 'saveDefaultIssueMentionDisplayProperties' &&
			      		<div className='toast-label'>			      			
			      			Default saved.
			      		</div>
			      	}
			      	{toastType === 'dndSwitchOn' &&
			      		<div className='toast-label'>			      			
			      			All notifications have been muted.
			      		</div>
			      	}
			      	{toastType === 'dndSwitchOff' &&
			      		<div className='toast-label'>			      			
			      			All notifications have been switched back on.
			      		</div>
			      	}
			      	{toastType === 'clickDeletedMessageImage' &&
			      		<div className='toast-label'>
			      			Annoyingly, the linked image was deleted.
			      		</div>
			      	}
			      	{toastType === 'clickDeletedMessageFigma' &&
			      		<div className='toast-label'>
			      			The linked Figma embed was removed.
			      		</div>
			      	}
			      	{toastType === 'clickDeletedMessageVideo' &&
			      		<div className='toast-label'>
			      			Sadly the linked video was deleted.
			      		</div>
			      	} 
			      	{toastType === 'updatedTargetDateCalendar' &&
			      		<div className='toast-label'>
			      			Updated target date
			      		</div>
			      	} 
			      	{toastType === 'jumpToCursorAlert' &&
			      		<div className='toast-label'>
			      			<span className='toast-label-emph'>{userName}</span> jumped to your cursor
			      		</div>
			      	}
			      	{toastType === 'jumpToCursorConfirmation' &&
			      		<div className='toast-label'>
			      			Jumped to <span className='toast-label-emph'>{userName}'s</span> cursor
			      		</div>
			      	}
			      	{toastType === 'pingCursorAlert' &&
			      		<div className='toast-label'>
			      			<span className='toast-label-emph'>{userName}</span> is pinging their cursor location
			      		</div>
			      	}
			      	{toastType === 'pingCursorConfirmation' &&
			      		<div className='toast-label'>
			      			Pinging your cursor location to <span className='toast-label-emph'>{userName}</span>
			      		</div>
			      	}
			      	{toastType === 'updatedProject' &&
			      		<div className='toast-label'>
			      			Project updated.
			      		</div>
			      	}			      	

			      	{toastType === 'createdTemplate' &&
			      		<div className='toast-label'>
			      			Template created
			      		</div>
			      	}

			      	{toastType === 'addedMeetingToCal' &&
			      		<div className='toast-label'>
			      			Event created and invites sent!
			      		</div>
			      	}
			      	

			      	

			      	{toastType === 'archivedTemplate' && 			      		
			      		<div className='toast-label'>		      			
			      				Archived <span className='toast-label-white'>{toastNotification.userName}</span>
			      		</div>

			      	}


			      	{toastType === 'updatedProjectError' &&
			      		<div className='toast-label'>
			      			There was an error updating the project.
			      		</div>
			      	}
			      	{toastType === 'createdProject' && projectName && projectId && 
			      		<div className='toast-label toast-label--hasMarker'>			      			
			      			<div className='toast-label--hasMarker-linearMarkerContainer'>
			      				<LinearMarker projectId={projectId} />
			      			</div>
			      			<div className='toast-label--hasMarker-label'>
			      				<span className='toast-label-white'>{projectName}</span> created.
			      			</div>
			      		</div>
			      	}
			      	{toastType === 'addSingleRoadmapToProject' && projectName && projectId && roadmapName && 
			      		<div className='toast-label toast-label--hasMarker'>			      			
			      			<div className='toast-label--hasMarker-linearMarkerContainer'>
			      				<LinearMarker projectId={projectId} />
			      			</div>
			      			<div className='toast-label--hasMarker-label'>
			      				<span className='toast-label-white'>{projectName}</span> added to
			      			</div>
			      			<div className='toast-label--hasMarker-linearMarkerContainer'>
			      				<Icon name='roadmapOutline' />
			      			</div>
			      			<div className='toast-label--hasMarker-label'>
			      				<span className='toast-label-white'>{roadmapName}.</span>
			      			</div>			      		
			      		</div>			      		
			      	}
			      	{toastType === 'removeSingleRoadmapFromProject' && projectName && projectId && roadmapName && 
			      		<div className='toast-label toast-label--hasMarker'>			      			
			      			<div className='toast-label--hasMarker-linearMarkerContainer'>
			      				<LinearMarker projectId={projectId} />
			      			</div>
			      			<div className='toast-label--hasMarker-label'>
			      				<span className='toast-label-white'>{projectName}</span> removed from
			      			</div>
			      			<div className='toast-label--hasMarker-linearMarkerContainer'>
			      				<Icon name='roadmapOutline' />
			      			</div>
			      			<div className='toast-label--hasMarker-label'>
			      				<span className='toast-label-white'>{roadmapName}.</span>
			      			</div>			      		
			      		</div>			      		
			      	}
			      	{toastType === 'createdProjectError' &&
			      		<div className='toast-label'>
			      			There was an error creating the project.
			      		</div>
			      	}
			      	{toastType === 'updatedRoadmaps' && projectName && 			      		
			      		<div className='toast-label toast-label--hasMarker'>			      			
			      			<div className='toast-label--hasMarker-label'>
			      				Roadmaps for 
			      			</div>
			      			<div className='toast-label--hasMarker-linearMarkerContainer'>
			      				<LinearMarker projectId={projectId} />
			      			</div>
			      			<div className='toast-label--hasMarker-label'>
			      				<span className='toast-label-white'>{projectName}</span> updated.
			      			</div>
			      		</div>

			      	}
			      	



			      	{/* {toastText} */}			      
			      	{toastType === 'pingCursorAlert' &&
				      	<Toast.Action asChild altText="jump">
					      	<button className='toast-actionBtn' onClick={()=>{jumpToCursor(toastNotification.actionButton.sentBy,this.props.history)}}>
		            		<div className='toast-actionBtn-iconContainer'>
		            			<Icon name="jump" />
		            		</div>
		            		<div className='toast-actionBtn-label'>
		            			Jump
		            		</div>
		            		<div className='toast-actionBtn-keyboardShortcut'>
		            			<Icon name="keyCommand" />
		            			<div className='toast-actionBtn-keyboardShortcut-span'>
		            				J
		            			</div>
		            		</div>
		         			</button>
		         			</Toast.Action>
         			}


         				{toastType === 'createdTemplate' &&
				      	<Toast.Action asChild altText="jump">
						      	<button className='toast-actionBtn' onClick={()=>{showNewTemplateInModal(userName)}}>			            		
			            		<div className='toast-actionBtn-label'>
			            			Browse templates
			            		</div>
			         			</button>
		         			</Toast.Action>
         			}





         		{/*}
			      {toastNotification.actionButton && toastNotification.actionButton.buttonActionType==='jumpToCursor' &&
			       <Toast.Action asChild altText="Go to cursor">
         			 <button className='toast-btn' onClick={()=>{openDoc(this.props.history,toastNotification.actionButton.docId)}}>
            			jump to cursor
         				 </button>
        			</Toast.Action>
        		}
        		*/}
			    </Toast.Root>
			    <Toast.Viewport className='toastContainer'/>
			  </Toast.Provider>
				</React.Fragment>
		  )
		}else return null
	}
}

const mapStateToProps = (state) => ({
	toastNotification: state.toastNotification
});

export default withRouter(connect(
	mapStateToProps,
	{
	})(ToastNotifications))



