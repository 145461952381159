import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import debounce from 'lodash/debounce'
import DocImageTopRightMenu from '../docMediaUIComponents/DocImageTopRightMenu'
import DocImageReactionBar from '../docMediaUIComponents/DocImageReactionBar'
import {showDocMediaModal} from '../../../utils/showDocMediaModal'
import {isPrivateDoc} from '../../../utils/isPrivateDoc'
import DocImagePlaceholder from './DocImagePlaceholder'


const MOUSE_MOVE_DEBOUNCE_TIME = 2000
const IMAGE_DOC_WIDTH = 720


class DocImageReactComponent extends React.Component{  
 
  constructor(){
    super()
    this.handleMouseMove=this.handleMouseMove.bind(this)
    this.hideMouseMoveControls=this.hideMouseMoveControls.bind(this)
    this.zoomImage=this.zoomImage.bind(this)
    this.hideMouseMoveControls=debounce(this.hideMouseMoveControls,MOUSE_MOVE_DEBOUNCE_TIME)
    this.state={
      mouseMoved:false
    }
  }

  handleMouseMove(){    
    this.setState({mouseMoved:true})
    this.hideMouseMoveControls()
  }

  hideMouseMoveControls(){
    this.setState({mouseMoved:false})
  }


  zoomImage(){
  	const modalType="zoomedImage"
  	const nodeId=this.props.nodeId
  	showDocMediaModal(modalType,nodeId)
  }

	render(){ 
		const { mouseMoved} = this.state
		const {deliveryUrl,height,width,title,nodeId,docId}=this.props
		//height and width are only available after sucessful upload
		const uploadComplete = deliveryUrl 
		let imageWidth
		let imageHeight
		if(uploadComplete){
			imageWidth = IMAGE_DOC_WIDTH
			imageHeight = IMAGE_DOC_WIDTH / (width / height) // fit image
		}

		const isPrivate=isPrivateDoc(docId)
		const imageLoaded=false
		

		return (
			<React.Fragment>		
				{!uploadComplete && 
					<DocImagePlaceholder
					/>
				}
				
				{uploadComplete &&
				<div style={{width: `${imageWidth}px`, height: `${imageHeight}px`}} className={'doc-imageContainer' +' doc-imageContainer--defaultMode ' + (mouseMoved ? ' doc-imageContainer--mouseMoved ' : ' ') + ' doc-imageContainer--inline '} onMouseMove={this.handleMouseMove}>
					<div onClick={this.zoomImage} className='doc-image'>
						<div className='doc-image-overlayBorder' />
						<DocImageTopRightMenu 
							newMessage={this.props.newMessage}
							convertToThumbnail={this.props.convertToThumbnail}
							deleteImage={this.props.deleteImage}
							isPrivateDoc={isPrivate}
						/>
						<img 
							alt=''
							draggable="false"
							src={deliveryUrl}
							className='doc-image-image'							
						/>
						<div className='doc-image-underlayBG' />
					</div>
					<div className='doc-image--selectionBG'/>
				</div>
				}
			</React.Fragment>
		)
	}
}

export default DocImageReactComponent
