import {fetch,put,post,batchedFetch} from '../api'
import { Mixpanel } from '../Mixpanel'

export function fetchHighlights(){
	return (dispatch) => fetch(`/highlights`)
	.then((response) => {
		dispatch({ type: 'FETCH_HIGHLIGHTS_SUCCESS', response })
		return response
	})
	.catch((error) => {
		console.log(error)
		return error
	})
}


export function archiveHighlight(highlightId) {
	return (dispatch) =>{ 
		dispatch({ type: 'ARCHIVE_HIGHLIGHT_REQUEST', highlightId })
		 return put(`/highlight/${highlightId}/archive`)
		.then((response) => {
			Mixpanel.track('archive_highlight')
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

export function handleHighlightArchivedPusher(highlightId){
	return (dispatch) => {
		dispatch({ type: 'ARCHIVE_HIGHLIGHT_REQUEST', highlightId })
	}
}


export function createHighlight(data) {
	return (dispatch) =>{ 
		dispatch({ type: 'CREATE_HIGHLIGHT_REQUEST', response:data })
		 return post(`/highlights`,data)
		.then((response) => {
			const duration=Math.round(response.end_time-response.start_time)
			Mixpanel.track('create_highlight',{highlight:response,duration:duration})
			dispatch({ type: 'CREATE_HIGHLIGHT_SUCCESS',response})
			return response
		})
		.catch((error) => {
			console.log(error)
			return error
		})
	}
}

export function handleHighlightUpdatedPusher(highlight){
	return (dispatch) => {
		dispatch({ type: 'UPDATE_HIGHLIGHT_PUSHER', response:highlight })
	}
}