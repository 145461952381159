import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import Icon from '../../../../misc/Icon'

class MessageInputBoxAddEmoji extends React.Component{ 


	constructor(){
		super()
		this.state={
			searchInput:'',
		}
	}
	
	onInputChange(e){ //do debounced search
		this.setState({searchInput:e.target.value})	
	}


	render(){			
			
		return (			
		  	<Popover.Root>
			    <Popover.Trigger className='message-inputBox-addMediaBtn message-inputBox-addMediaBtn--emoji'>
			    	<Icon name='emoji' />
			    </Popover.Trigger>	
			    <Popover.Portal>				    
			    <Popover.Content side="top" sideOffset={6} className='popover message-inputBox-addMediaPopover'>
			      <div className='message-inputBox-addMediaPopover-search'>
			      	{this.state.searchInput &&
			      		<button onClick={this.onBackButtonClick}>back</button>	
			     	 }
			      	<input 			      		
			      		className='message-inputBox-addMediaPopover-search-input' 
			      		value={this.state.searchInput}
			      		onChange={(e)=>{this.onInputChange(e)}} 
			      	/>			      
			      </div>


			    {this.state.searchInput &&
			      <div className='message-inputBox-addMediaPopover-resultsContainer'>

			      	
			      
			      
			      </div>
			    }
			    </Popover.Content>
			    </Popover.Portal>
			  </Popover.Root>	        
		)
	}
}
 

export default MessageInputBoxAddEmoji