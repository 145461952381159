import store from '../store'
// import includes from 'lodash/includes'
import find from 'lodash/find'
//for now pass history as prop
//TODO think about pushing to history outside of a react component using BrowserRouter and HashRouter

//TODO combine docs and roadmaps

export function getTabStarredStatus(itemType,itemId) {
	let docIsStarred=false
	const state = store.getState()
	const openTabs=state.openTabs
	const starredDocs=openTabs.tabGroups.starred
	//check if array includes docId id so return true
	if(find(starredDocs,{type:itemType,id:itemId})){
		docIsStarred=true
	}

	return docIsStarred
}


export function starTab(tabType,itemId) {
	//add to starred docs and remove from default group if it is there
	const state = store.getState()
	const openTabs=state.openTabs
	const {tabGroups,orderedTabGroups}=openTabs
	let newTabGroups=Object.assign({},tabGroups)
	
	let starredDocs=[...tabGroups.starred]
	starredDocs.push({type:tabType,id:itemId})

	let defaultDocs=[]
	tabGroups.default.forEach((tab)=>{
		if(tab.id !== itemId){
			defaultDocs.push(tab)
		}
	})
	newTabGroups.default=defaultDocs
	newTabGroups.starred=starredDocs
	store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
}

export function unstarTab(itemType,itemId) {
	//add to starred docs and remove from default group if it is there
	const state = store.getState()
	const openTabs=state.openTabs
	const {tabGroups,orderedTabGroups}=openTabs
	let newTabGroups=Object.assign({},tabGroups)
	
	let defaultDocs=[...tabGroups.default]
	defaultDocs.push({type:itemType,id:itemId})

	let starredDocs=[]
	tabGroups.starred.forEach((tab)=>{
		if(tab.id !== itemId){
			starredDocs.push(tab)
		}
	})
	newTabGroups.default=defaultDocs
	newTabGroups.starred=starredDocs

	store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
	
}




