import store from '../../store'
import filter from 'lodash/filter'
import find from 'lodash/find'


export function getIssuesForUser(userId) {	
	let issues=[]
	const state = store.getState()
	const filteredIssues= filter(state.issues, {'assignee':userId})
	filteredIssues.forEach((issue)=>{
		const workflow_state=find(state.workflowStates,{state_id:issue.workflow_state})
		if(workflow_state.type!=='canceled' && workflow_state.type!=='completed'){
			issues.push(issue)
		}
	})
	const urgentPriority=filter(issues, {priority:1})
	const highPriority=filter(issues,{priority:2})
	return {
		urgent:urgentPriority,
		high:highPriority
	}
}

