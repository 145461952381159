import React from 'react'
import { withRouter} from 'react-router-dom'
import Icon from '../misc/Icon'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Tooltip from '@radix-ui/react-tooltip';
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import {createNewDoc} from '../../utils/createNewDoc'
import {toggleTheme} from '../../utils/toggleTheme'
import SettingsDialog from '../SettingsDialog'

class SideMenuHeader extends React.Component{  
	constructor(props) {
    super(props);
    this.toggleTheme=this.toggleTheme.bind(this)  
    this.handleGoBack=this.handleGoBack.bind(this)  
    this.handleGoForward=this.handleGoForward.bind(this)
   	this.updateNavigationState=this.updateNavigationState.bind(this)
   	this.handleKeyDown=this.handleKeyDown.bind(this)
    this.state={
    	canGoBack:false,
    	canGoForward:false,
    	historyStack: [props.location],
      currentIndex: 0

    }
  }

  componentDidMount() {
  	window.addEventListener('keydown', this.handleKeyDown)		

    this.unlisten = this.props.history.listen((location, action) => {
      const { historyStack, currentIndex } = this.state;
      let newStack, newIndex;

      if (action === 'POP') {
        const targetIndex = historyStack.findIndex(
          (item) => item.key === location.key
        );
        if (targetIndex !== -1) {
          newIndex = targetIndex;
        } else {
          // Fallback for edge cases where the index is not found
          newIndex = Math.max(currentIndex - 1, 0);
        }
        newStack = historyStack;
      } else {
        newStack = historyStack.slice(0, currentIndex + 1);
        newStack.push(location);
        newIndex = newStack.length - 1;
      }

      this.setState({
        historyStack: newStack,
        currentIndex: newIndex
      }, this.updateNavigationState);
    });
  }

  componentWillUnmount() {
  	window.removeEventListener('keydown', this.handleKeyDown)		
    this.unlisten();
  }

  handleKeyDown(e){
  	if(e.metaKey && e.keyCode==219){ //command + [
  		if(this.state.canGoBack){
  			e.preventDefault()
  			this.handleGoBack()
  		}
  	}
  	else if(e.metaKey && e.keyCode==221){ //command + ]
  		if(this.state.canGoForward){
  			e.preventDefault()
  			this.handleGoForward()
  		}
  	}
  }
 

 updateNavigationState() {
    this.setState({
      canGoBack: this.state.currentIndex > 0,
      canGoForward: this.state.currentIndex < this.state.historyStack.length - 1
    });
  }

  handleGoBack(){
  	this.props.history.goBack()
  }

  handleGoForward(){
  	this.props.history.goForward()
  }

  toggleTheme(e) {
  	toggleTheme()    
	}

	render(){	
		const backAvailable = this.state.canGoBack
		const forwardAvailable = this.state.canGoForward

	  return (
			<div className='sideMenu-header'>
				<div className='sideMenu-header-top'>
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={50} skipDelayDuration={0}>
							<Tooltip.Trigger onClick={this.props.toggleSideMenuVisible} className='sideMenu-header-top-btn sideMenu-header-top-btn--toggleInterface'>								
									<Icon name='sideMenu' />								
							</Tooltip.Trigger>									
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																									
								{this.props.sideMenuVisible ? 'Hide sidebar' : 'Show sidebar'}
								<KeyboardShortcutTag
									command={true}											
									letter='S'											
								/>									
								</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>
					
					<Tooltip.Provider>
						<Tooltip.Root delayDuration={50} skipDelayDuration={0}>
							<Tooltip.Trigger onClick={()=>{createNewDoc(this.props.history)}} className='sideMenu-header-top-btn sideMenu-header-top-btn--newDoc'>
								<Icon name='plus' />
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																									
								New Private Draft
								<KeyboardShortcutTag
									command={true}											
									letter='N'											
								/>									
								</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>

					<div className='sideMenu-header-forwardBackButtons'>

						<Tooltip.Provider>					
							<Tooltip.Root delayDuration={150}>
								<Tooltip.Trigger asChild >
									<button disabled={!backAvailable} onClick={this.handleGoBack} className='sideMenu-header-top-btn sideMenu-header-top-btn--back'>					    		
										<Icon name='chevronRight' />					   
						    	</button> 
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI tooltip--withSquareBracket">
									Go Back						
									<KeyboardShortcutTag
										command={true}											
										letter='['											
									/>									
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>				

						<Tooltip.Provider>					
							<Tooltip.Root delayDuration={150}>
								<Tooltip.Trigger asChild >
									<button disabled={!forwardAvailable} onClick={this.handleGoForward} className='sideMenu-header-top-btn sideMenu-header-top-btn--forward'>					    		
										<Icon name='chevronRight' />					   
						    	</button> 
								</Tooltip.Trigger>				
								<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI  tooltip--withSquareBracket">																									
									Go Forward	
									<KeyboardShortcutTag
										command={true}											
										letter=']'											
									/>								
								</Tooltip.Content>													
							</Tooltip.Root>
						</Tooltip.Provider>				
						
					</div>

				</div>
			</div>					
	  )
	}
}

export default withRouter(SideMenuHeader)


				{/*}
				{org &&

					<div className='sideMenu-header-orgBtnContainer'>						
						
							 className='sideMenu-header-orgBtn'>					    		
							
					    	<div className='sideMenu-header-orgBtn-orgLogoContainer'>
					    		
					    	</div>	
					    	

					    	<div className='sideMenu-header-orgBtn-label'>
					    		{orgName}
					    	</div>
					    	<div className='sideMenu-header-orgBtn-iconContainer'>
					    		<Icon name='cog' />
					    	</div>					   
					    </button> 


					</div>

				}		
				*/}		

					{/*<SettingsDialog																									
						showDialog={showSettingsDialog}						
						openDialog={()=>{this.setState({showSettingsDialog: true})}}
						closeDialog={()=>{this.setState({showSettingsDialog: false})}}							
						//userList={orgMembers}							
					>	

					</SettingsDialog>	

					<Tooltip.Provider>
						<Tooltip.Root delayDuration={50}>
							<Tooltip.Trigger asChild >
								<button onClick={()=>(this.setState({showSettingsDialog: true}))} className='sideMenu-header-top-btn sideMenu-header-top-btn--settings'>					    		
									<Icon name='avSettings' />					   
					    	</button> 
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">																									
								Settings								
							</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>

					*/}
																				 

