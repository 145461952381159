import React from 'react'
import AvatarImage from '../AvatarImage'


class MultiplayerPlaygroundSelection extends React.Component{  	

	render(){	

		const {color, theme} = this.props

	
	  return (
	  		  	
			<div className={'multiPlaygroundSelectionContainer ' + (theme === 'dark' ? ' multiPlaygroundSelectionContainer--theme--dark ' : '')}>
				<div className={'multiPlaygroundSelection ' + (theme === 'dark' ? ' multiPlaygroundSelection--theme--dark ' : '')}>
					<div className='multiPlaygroundSelection-para'>
						<div className={'multiPlaygroundSelection-para-selection multiPlaygroundSelection-para-selection--top ' + (color ? `multiPlaygroundSelection-para-selection--color--${color}` : '')}/>
						<div className={'multiPlaygroundSelection-para-selection multiPlaygroundSelection-para-selection--middle '  + (color ? `multiPlaygroundSelection-para-selection--color--${color}` : '')}/>
						<div className={'multiPlaygroundSelection-para-selection multiPlaygroundSelection-para-selection--bottom ' + (color ? `multiPlaygroundSelection-para-selection--color--${color}` : '')}/>
						<div className={'multiPlaygroundSelection-para-docFlag ' + (color ? `multiPlaygroundSelection-para-docFlag--color--${color}` : '')}>
							<div className='multiPlaygroundSelection-para-docFlag-stem'/>
							<div className='multiPlaygroundSelection-para-docFlag-labelContainer'>
								<AvatarImage 
									className='multiPlaygroundSelection-para-docFlag-image'
								/>
								<div className='multiPlaygroundSelection-para-docFlag-label'>

									nicole
								</div>
							</div>
						</div>
						Lorem ipsum dolor sit amet, consectetur. Proin at lacus ac nisl ultrices mollis. Donec in ex in sapien consequat sollicitudin. Maecenas semper lorem risus, tempus consequat diam iaculis non. Etiam ex diam, facilisis at lobortis in, tempus in nunc. Aliquam ultricies libero tortor.
					</div>
				</div>
			</div>
			
	  )
	}
}

export default MultiplayerPlaygroundSelection
