import React from 'react'
import { connect } from 'react-redux'
import Icon from '../../misc/Icon'
import {toggleTheme} from '../../../utils/toggleTheme'
import { withRouter} from 'react-router-dom'

class DocEditorBottomRightMenu extends React.Component{  

	constructor(props) {
    super(props);
    this.toggleTheme=this.toggleTheme.bind(this)
  }

	toggleTheme(e) {
		toggleTheme() 
	}


	render(){	
		const {theme}=this.props
		let darkMode=false
		if(theme==='dark'){
			darkMode=true
		}
	  return (
			<div className='docEditor--draft-bottomRightFixedMenu'>							
				{/*}
				<button className='docEditor--draft-bottomRightFixedMenu-btn docEditor--draft-bottomRightFixedMenu-btn--inactive'>
					B
				</button>
				<button className='docEditor--draft-bottomRightFixedMenu-btn docEditor--draft-bottomRightFixedMenu-btn--inactive'>
					C
				</button>					
			*/}

				{/*}
				<button disabled={this.props.hideSplitScreenOption} onClick={this.props.toggleShowSplitScreen} className={'docEditor-bottomRightFixedMenu-btn ' + (this.props.showSplitScreen ? ' docEditor-bottomRightFixedMenu-btn--active ' : ' docEditor-bottomRightFixedMenu-btn--inactive ')}>
					<Icon name='calendar' />
				</button>
				*/}

				{/*}
				<button onClick={this.props.toggleShowSpecialKMenu} className={'docEditor-bottomRightFixedMenu-btn ' + (this.props.showSpecialKMenu ? ' docEditor-bottomRightFixedMenu-btn--active ' : ' docEditor-bottomRightFixedMenu-btn--inactive ')}>
					<Icon name='chevronSquare' />
				</button>
				<button onClick={this.props.toggleShowOpenDocMenu} className={'docEditor-bottomRightFixedMenu-btn ' + (this.props.showOpenDocMenu ? ' docEditor-bottomRightFixedMenu-btn--active ' : ' docEditor-bottomRightFixedMenu-btn--inactive ')}>
					<Icon name='magnifyingGlassSemibold' />
				</button>
				*/}
				{/*}
				<button onClick={this.toggleTheme} className='docEditor-bottomRightFixedMenu-btn docEditor-bottomRightFixedMenu-btn--inactive'>
					{!darkMode &&
						<Icon name='lightMode' />
					}
					{darkMode &&
						<Icon name='darkMode' />
					}
				</button>		

				{/*}
				<button onClick={()=>{this.props.history.push('/')}}>home</button>
				*/}


				<button onClick={this.props.toggleShowEditorOutputPanel} className={'docEditor-bottomRightFixedMenu-btn ' + (this.props.showEditorOutputPanel ? ' docEditor-bottomRightFixedMenu-btn--active ' : ' docEditor-bottomRightFixedMenu-btn--inactive ')}>
					<Icon name='code' />
				</button>					
				
			</div>
			
			
	  )
	}
}


function mapStateToProps(state) {
	return {
		theme:state.theme
	}
}

export default withRouter(connect(mapStateToProps,{})(DocEditorBottomRightMenu))
