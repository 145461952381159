import store from '../store'
import pusherInstance from '../pusherInstance'


export function orgPresencePusherSetup(orgId) {
	let presenceChannel = pusherInstance.subscribe(`presence-org-${orgId}`)
	presenceChannel.bind('pusher:subscription_succeeded', handlePresenceSubscriptionSucceeded)  
	presenceChannel.bind('pusher:member_removed', handlePresenceMemberRemoved)  
	presenceChannel.bind('pusher:member_added', handlePresenceMemberAdded)  
}
 
function handlePresenceSubscriptionSucceeded(data){
	const members=data.members
	store.dispatch({ type: 'ORG_PRESENCE_SUBSCRIBE_SUCCESS',members})
}

function handlePresenceMemberRemoved(data){
	const userId=data.id
	store.dispatch({ type: 'ORG_PRESENCE_REMOVED',userId})
}

function handlePresenceMemberAdded(data){
	const userId=data.id
	store.dispatch({ type: 'ORG_PRESENCE_ADDED',userId,data})
}