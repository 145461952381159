import React, {Component} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import DocEditorContainer from './DocEditorContainer'
import {getDocIdFromSignature} from '../utils/getDocIdFromSignature'

//Wraps DocEditorContainer to key it so reloads with new docId
class DocWrapperContainer extends Component {
	render() {
		return (
			<DocEditorContainer key={this.props.docId} {...this.props}/>
		)
	}
}

function mapStateToProps(state,ownProps) {
	const docSignature=ownProps.match.params.docSignature
	const thread=ownProps.match.params.thread
	const docId=getDocIdFromSignature(docSignature)
	return {
		docId:docId,
		threadId:thread
	}
}

export default withRouter(connect(
	mapStateToProps,
	{
	})(DocWrapperContainer))