import React from 'react'
import Icon from '../misc/Icon'
import AvatarImage from '../AvatarImage'
import {getUserForId} from '../../utils/getUserForId'

class DocProjectMentionsSheetContentsMentionMessage extends React.Component{  

  render(){ 

    const {userId} = this.props

    let userObj
    let userAvatar
    let userDisplayName
    
    if(userId){
      userObj=getUserForId(userId)
    }
    
    if(userObj){
      userAvatar = userObj.avatar
      userDisplayName = userObj.display_name
    }

    console.log(`userObj: ${userObj}`)

    return (        
          
          <React.Fragment>
            <div className='docEditor-projectMentionsSheet-mention-messagePreview'>
              <div className='docEditor-projectMentionsSheet-mention-messagePreview-avatarContainer'>
                <AvatarImage avatarUrl={userAvatar} className='docEditor-projectMentionsSheet-mention-messagePreview-avatar' />
              </div>
              <div className='docEditor-projectMentionsSheet-mention-messagePreview-content'>
                <div className='docEditor-projectMentionsSheet-mention-messagePreview-content-title'>
                  <div className='docEditor-projectMentionsSheet-mention-messagePreview-content-title-name'>
                    {userDisplayName}
                  </div>
                </div>
                <div className='docEditor-projectMentionsSheet-mention-messagePreview-content-messageContent'>
                  {this.props.messageText}
                </div>
              </div>
            </div>
          </React.Fragment>                      
        
    )
  }
}

class DocProjectMentionsSheetContentsMentionWrapper extends React.Component{  

  constructor(props) {
    super(props)   
    this.state={
      
    }    
  } 

  render(){ 

    const {userId, source} = this.props

    let userObj
    let userAvatar
    let userDisplayName
    
    if(userId){
      userObj=getUserForId(userId)
    }
    
    if(userObj){
      userAvatar = userObj.avatar
      userDisplayName = userObj.display_name
    }

    console.log(`userObj: ${userObj}`)

    return (        
          
          <div className='docEditor-projectMentionsSheet-mention'>
            <div className={'docEditor-projectMentionsSheet-mention-header ' + (this.props.source ? `docEditor-projectMentionsSheet-mention-header--${source}` : '')}>
              <div className='docEditor-projectMentionsSheet-mention-header-sourceLogoContainer'>
                {source === 'linear' &&
                  <Icon name='linearIcon' />
                }
                {source === 'slack' &&
                  <Icon name='slackIcon' />
                }
              </div>
              <div className='docEditor-projectMentionsSheet-mention-header-dividerDot' />


              {source === 'linear' && this.props.issueIdentifier &&
                <button className='docEditor-projectMentionsSheet-mention-header-linearIssueIdentifier'>
                  {this.props.issueIdentifier}
                </button>
              }

              {source === 'slack' && this.props.channelName &&
                <button className='docEditor-projectMentionsSheet-mention-header-channelName'>
                  <div className='docEditor-projectMentionsSheet-mention-header-channelName-icon'>
                    <Icon name='hash' />
                  </div>
                  <div className='docEditor-projectMentionsSheet-mention-header-channelName-name'>
                    {this.props.channelName}
                  </div>
                </button>
              }

              <div className='docEditor-projectMentionsSheet-mention-header-subtitle'>
                <div className='docEditor-projectMentionsSheet-mention-header-goToLabel'>
                  Open in {source === 'slack' ? 'Slack' : 'Linear'}
                </div>

                <div className='docEditor-projectMentionsSheet-mention-header-timestamp'>
                  {this.props.timestampLabel}
                </div>
              </div>

              <button className='docEditor-projectMentionsSheet-mention-header-dots'>
                <Icon name='ellipses' />
              </button>
              

            </div>

            {this.props.children}
            

          </div>
        
    )
  }
}

class DocProjectMentionsSheetContents extends React.Component{  

  constructor(props) {
    super(props)   
    this.state={
      
    }    
  } 

  render(){ 


    return (        
      
        <React.Fragment>

          <div onClick={this.props.closeProjectMentionsSheet} className='docEditor-projectMentionsSheet-header'>

            <div className='docEditor-projectMentionsSheet-header-label'>
              Mentions
            </div>          

            <button className='docEditor-projectMentionsSheet-header-closeBtn'>
              <div className='docEditor-projectMentionsSheet-header-closeBtn-inner'>
                <Icon name='cross-small'/>
              </div>                  
            </button>           


          </div>

          <div className='docEditor-projectMentionsSheet-list'>
          

            <DocProjectMentionsSheetContentsMentionWrapper 
              source="slack"
              channelName='support'
              timestampLabel='5 days ago'              
            >

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="5" 
                messageText="Elena at Undivided mentioned that they are getting pinged a bit too much. They like DND-mode but yeah, just a bit much for them atm."
              />

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="1"                 
                messageText={
                    <React.Fragment>
                      Cool yep we're doing a pass on <span>notifications</span> in the next few weeks. I might message her to learn more!
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>


            <DocProjectMentionsSheetContentsMentionWrapper
              source="linear"
              issueIdentifier='DSK-232'
              timestampLabel='Monday 4:45pm' 
            > 

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="3"                
                messageText={
                    <React.Fragment>
                      Maybe we can look at digests the next time we pass through on <span>notifications</span>. I know we've discussed digests a few times, I think they could be amazing – like async passive intelligence.
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>


            <DocProjectMentionsSheetContentsMentionWrapper 
              source="slack"
              channelName='design'
              timestampLabel='12 April'              
            >

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="1"                 
                messageText={
                    <React.Fragment>
                      I like these context menus, are you thinking for <span>notifications</span> dropdowns?
                    </React.Fragment>
                  }
              />

               <DocProjectMentionsSheetContentsMentionMessage 
                userId="2"                 
                messageText={
                    <React.Fragment>
                      Yeah that's current thoughts. Also in Figma edit dropdowns...
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

            
            <DocProjectMentionsSheetContentsMentionWrapper
              source="linear"
              issueIdentifier='API-1087'
              timestampLabel='8 days ago'              
            > 

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="3"                
                messageText={
                    <React.Fragment>
                      I guess email infrastructure might come as part of the upcoming <span>notifications</span> round...
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>


            <DocProjectMentionsSheetContentsMentionWrapper
              source="linear"
              issueIdentifier='DSK-5484'
              timestampLabel='8 April'
            > 

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="2"                
                messageText={
                    <React.Fragment>
                      Got a few more few tickets about <span>notifications</span> not coming through...
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>


             <DocProjectMentionsSheetContentsMentionWrapper 
              source="slack"
              channelName='general'
              timestampLabel='4 April'
            >

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="1"                 
                messageText={
                    <React.Fragment>
                      Have we updated the help documentation about <span>notifications</span>?
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>


            <DocProjectMentionsSheetContentsMentionWrapper
              source="linear"
              issueIdentifier='DSK-102'
              timestampLabel='22 March'
            > 

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="2"                
                messageText={
                    <React.Fragment>
                      Remember to test the new <span>notifications</span> feature across different time zones. We don't want to wake our users up at 3 AM.
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

            <DocProjectMentionsSheetContentsMentionWrapper
              source="linear"
              issueIdentifier='DSK-102'
              timestampLabel='22 March'
            > 

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="2"                
                messageText={
                    <React.Fragment>
                      AI summarization might make a lot of sense in async contexts like <span>notifications</span> and digests.
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

            <DocProjectMentionsSheetContentsMentionWrapper
              source="linear"
              issueIdentifier='API-1832'
              timestampLabel='22 March'
            > 

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="5"
                messageText={
                    <React.Fragment>
                      Successfully integrated the <span>notifications</span> API. Initial testing looks good.
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

             <DocProjectMentionsSheetContentsMentionWrapper 
              source="slack"
              channelName='design'
              timestampLabel='02 March'
            >

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="3"                 
                messageText={
                    <React.Fragment>
                      We should consider adding customizable options for <span>notifications</span>. Users might want to choose what kind of alerts they receive.
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

             <DocProjectMentionsSheetContentsMentionWrapper 
              source="slack"
              channelName='support'
              timestampLabel='21 February'
            >

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="1"                 
                messageText={
                    <React.Fragment>
                      Btw we've decided to push the 'mute  <span>notifications</span>' feature based on current roadmap and timeline."
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

            <DocProjectMentionsSheetContentsMentionWrapper 
              source="slack"
              channelName='product'
              timestampLabel='21 February'
            >

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="4"                 
                messageText={
                    <React.Fragment>
                      It's important that our <span>notifications</span>  respect the user's privacy settings. Let's ensure this is part of our discussions.
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

             <DocProjectMentionsSheetContentsMentionWrapper 
              source="slack"
              channelName='product'
              timestampLabel='02 March'
            >

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="3"                 
                messageText={
                    <React.Fragment>
                      What's the analytics layer we're thinking of for <span>notifications</span>? Can we track opens?
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

             <DocProjectMentionsSheetContentsMentionWrapper 
              source="slack"
              channelName='general'
              timestampLabel='14 February'
            >

              <DocProjectMentionsSheetContentsMentionMessage 
                userId="4"                
                messageText={
                    <React.Fragment>
                      Let's talk about <span>notifications</span>, what we're doing now and what we're doing next...
                    </React.Fragment>
                  }
              />

            </DocProjectMentionsSheetContentsMentionWrapper>

            
          </div>
        
                          
        </React.Fragment>
      
    )
  }
}

export default DocProjectMentionsSheetContents