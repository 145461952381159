import React from 'react'
import Icon from '../../misc/Icon'
import * as Tooltip from '@radix-ui/react-tooltip';
import { CircularProgressbar } from 'react-circular-progressbar';
import { ResponsivePie } from '@nivo/pie'  


class RoadmapsHomePageQuarterProgressRingTooltipStatus extends React.Component{  	

	
	render(){		
		
		const {status,count, totalProjects}=this.props		

		let statusLabel = status
		if(status === 'started'){
			statusLabel = 'In Progress projects'
		}
		if(status === 'completed'){
			statusLabel = 'Completed projects'
		}
		if(status === 'planned'){
			statusLabel = 'Planned projects'
		}

	  return (	  	  		
			<div className={'roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status ' + (status ? `roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status--${status}` : '')}>
				<div className='roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-label'>
					{statusLabel}
				</div>
				<div className='roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-count'>
					{count}
				</div>
				<div className='roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar'>
					<div style={{width: `${(count / totalProjects) * 100}%`}} className='roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar-bar' />
					<div className='roadmapsHomePage-quarter-header-subtitle-viz-tooltip-status-bar-track' />
				</div>

			</div>  			
	  )
	}
}




class RoadmapsHomePageQuarterProgressRing extends React.Component{  	

	constructor(){
    super()    
    this.state={
    	
    }    
  }		

	render(){		
		const {quarterRoadmapId}=this.props  	

		const {startedProjects,plannedProjects,backlogProjects,completedProjects}=this.props		




		const completedProjectCount = completedProjects.length
		const startedProjectCount = startedProjects.length
		const plannedProjectCount = plannedProjects.length


		const quarterRoadmapData = [	  
	  {
	    "id": "1",	    
	    "value": completedProjectCount, // count completed
	    "color": "rgb(85,104,249)"
	  },	 	  
	  {
	    "id": "2",	    
	    "value": startedProjectCount, // count in progress
	    "color": "rgb(255,170,112)"
	  },  
	  {
	    "id": "3",	    
	    "value": plannedProjectCount, // count planned progress
	    "color": "var(--line300)"
	  },   
]

		const totalProjects = completedProjectCount + startedProjectCount + plannedProjectCount

	  return (	  	
  		<div className='roadmapsHomePage-quarter-header-subtitle-viz'>
  				<ResponsivePie
		        data={quarterRoadmapData}
		        margin={{ top: 4, right: 4, bottom: 4, left: 4 }}
		        innerRadius={0.75}
		        padAngle={0.5}
		        cornerRadius={0}
		        activeOuterRadiusOffset={0}
		        borderWidth={1}
		        borderColor={'var(--253gray)'}
		        colors={{ datum: 'data.color' }}
		        motionConfig="default"		        
		        tooltip={() => (<></>)}
		        enableArcLabels={false}
		        enableArcLinkLabels={false}		        
		    />
  			<div className='tooltip roadmapsHomePage-quarter-header-subtitle-viz-tooltip'>
  				<RoadmapsHomePageQuarterProgressRingTooltipStatus 
  					status='completed'
  					count={completedProjectCount}
  					totalProjects={totalProjects}
  				/>  				
  				<RoadmapsHomePageQuarterProgressRingTooltipStatus 
  					status='started'
  					count={startedProjectCount}
  					totalProjects={totalProjects}
  				/>  				
  				<RoadmapsHomePageQuarterProgressRingTooltipStatus 
  					status='planned'
  					count={plannedProjectCount}
  					totalProjects={totalProjects}
  				/>  				

  			</div>
  		</div>
	  )
	}
}

export default RoadmapsHomePageQuarterProgressRing
