import {InputRule} from 'prosemirror-inputrules'
import editorSchema from '../../schema/editorSchema'
import {isInTable} from 'prosemirror-tables'
	//return new InputRule(/—-/g, (state, match, start, end) => {
export function horizontalLineInputRule(){
	return new InputRule(/---/g, (state, match, start, end) => {
		const inTable=isInTable(state)
		if(inTable){
			return false
		}else{
			let tr = state.tr
			let hasMultipleNodes=false
			state.doc.nodesBetween(start, end, (node, position) => {
			if (!node.isText || start === end) return
				const startPosition = Math.max(position, start)
				const endPosition = Math.min(position + node.nodeSize, end)
				if(startPosition===start && endPosition===end){ //don't apply input rule if matched text is accross multiple nodes
					const type = editorSchema.nodes.horizontalLine  
					const node=type.create(null)
					//if at the end of doc then add a paragraph after so can cursor in
					tr.replaceWith(end-1,end,editorSchema.nodes.paragraph.create()) //add blank space after line.
					tr.replaceWith(start, end-1, node)
				}else{
				hasMultipleNodes=true
			}
		})
		if(hasMultipleNodes){
			return false
		}else{
			return tr
		}
	}
	})
}


