import React from 'react'
import ReactDOM from 'react-dom'
import InternalLinkReactComponent from './InternalLinkReactComponent'




class InternalLinkNodeView {
  
  constructor(node, view, getPos,handleOpenDoc) {
    this.dom = document.createElement("span")
    this.dom.className="doc-para-internalLinkContainer"
    const {docId,docNameSnapshot}=node.attrs
    ReactDOM.render(
      <InternalLinkReactComponent 
        handleOpenDoc={handleOpenDoc}
        docId={docId}
        docNameSnapshot={docNameSnapshot}
      />, this.dom
    )
  }
  
  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom)
  }

}

export default InternalLinkNodeView