import {Plugin} from "prosemirror-state"
import {Decoration, DecorationSet} from "prosemirror-view"

const SEARCH_RESULT_TEXT_CLASSNAME='doc-span--searchResult'
const SEARCH_RESULT_TEXT_ACTIVE_CLASSNAME='recordingPlaybackModal-transcript-speakerChunk-transcriptWord--active'
//const SEARCH_RESULT_TEXT_ACTIVE_CLASSNAME='doc-span--searchResult'

export class ActiveWordState {
 
  constructor(decos) {
    // this.activeStart=activeStart
    // this.activeEnd=activeEnd
   // this.decos = decos

    // this.decos = Array.isArray(decos) ? decos.filter(Boolean) : [];
     this.decos = decos


  }

  apply(tr) { 
   let action = tr.getMeta('highlightActiveWordPlugin')

   if(action){
    let currentTime=action.currentTime
    let decos=[]
    let deco=null
    tr.doc.descendants((node, pos) => {
      if(node && node.type.name=='transcriptWord'){
        if(!deco){ //always have an active word
          deco = Decoration.inline(pos, pos+node.nodeSize+1, {class: SEARCH_RESULT_TEXT_ACTIVE_CLASSNAME,id:'active-word'}) 
        }
        //if(node.attrs.start<currentTime && node.attrs.end>currentTime){
          if(node.attrs.start<currentTime || node.attrs.start==currentTime){
          deco = Decoration.inline(pos, pos+node.nodeSize+1, {class: SEARCH_RESULT_TEXT_ACTIVE_CLASSNAME,id:'active-word'}) 
          //return false //break the loop
        }      
      }
    })
    if(deco){
      decos.push(deco)
    }

     return new ActiveWordState(DecorationSet.create(tr.doc, decos))
   }
   return this
  }

  static init(config) {
    // let activeStart=null
    // let activeEnd=null
    let decos = []
    const decoSet=DecorationSet.create(config.doc, decos)
    return new ActiveWordState(decoSet)
  }
}

