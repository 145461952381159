import React from 'react'
import Icon from '../misc/Icon'
import KeyboardShortcutTag from '../misc/KeyboardShortcutTag'
import MeetingTypeIcon from '../misc/MeetingTypeIcon'
import MeetingsPageMeetingRow from './MeetingsPageMeetingRow'
import { withRouter} from 'react-router-dom'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import MeetingsPageNewMeetingPopover from './MeetingsPageNewMeetingPopover'
import MeetingsPageTableFilterPopover from './MeetingsPageTableFilterPopover'
import filter from 'lodash/filter'
import {getCurrentUserId} from '../../utils/getCurrentUser'
import includes from 'lodash/includes'
import * as Tooltip from '@radix-ui/react-tooltip';

class MeetingTypeFilterBtn extends React.Component {
  
  constructor(props){
    super(props)
    this.handleClick=this.handleClick.bind(this)   
  }

	handleClick(){
    const {type, activeFilter} = this.props;

    console.log('CLICK BUTTON')

    if(activeFilter === type){
      this.props.setMeetingTypeFilter(null)
    }else{
      this.props.setMeetingTypeFilter(type)
    }
	}

  render() {
    const {type, label, activeFilter} = this.props;

    return (
      <button onClick={this.handleClick} className={'meetingNotesPage-table-filterBar-btn meetingNotesPage-table-filterBar-btn--typeFilter ' + (activeFilter === type ? ' meetingNotesPage-table-filterBar-btn--active ' : ' meetingNotesPage-table-filterBar-btn--inactive ')}>
        <div className='meetingNotesPage-table-filterBar-btn-iconContainer'>
          <MeetingTypeIcon meetingType={type} />
        </div>
        <div className='meetingNotesPage-table-filterBar-btn-label'>
          {label}
        </div>
      </button>
    )
  }
}


class MeetingsPageTable extends React.PureComponent{  
	
	constructor(props){
    super(props)  
    this.setMeetingTypeFilter=this.setMeetingTypeFilter.bind(this)  
    this.getFilteredMeetings=this.getFilteredMeetings.bind(this)
    this.state={      
      showNewMeetingPopover: false,
      showTypeFilterPopover: false,
      meetingTypeFilter: null, // each type            
      showTimeFilterPopover: false,
      timeFilter: null, // past or upcoming
      showMembershipFilterPopover: false,
      everyoneMeeting: true,
    }
  }



  setMeetingTypeFilter(type){
  	this.setState({meetingTypeFilter:type,everyoneMeeting:true})
  }



  getFilteredMeetings(){
  	const {meetingTypeFilter,everyoneMeeting}=this.state
  	let filteredMeetings=this.props.meetings 
  	if(meetingTypeFilter){
  		filteredMeetings= filter(this.props.meetings,function(meeting) {
  			return meeting.type==meetingTypeFilter;
   		});
  	}
  	if(!everyoneMeeting){
  		const currentUserId=getCurrentUserId()
  		filteredMeetings = filter(filteredMeetings,function(meeting) {
  			if (includes(meeting.participants,currentUserId)){
  				return meeting
  			}
   		})
  	}
  	return filteredMeetings
  }


  handleYourMeetingClick = (event) => {
    this.setState({everyoneMeeting: false})
    this.setState({meetingTypeFilter: null})  
  }

  handleAllMeetingsClick = (event) => {
    this.setState({everyoneMeeting: true})
    this.setState({meetingTypeFilter: null})  
  }



	render(){			

		const {meetingTypeFilter, timeFilter, membershipFilter, everyoneMeeting} = this.state	

		//

		let meetingTypeFilterLabel

		if(meetingTypeFilter === 'discovery'){
			meetingTypeFilterLabel = 'Discovery Call'
		}
		if(meetingTypeFilter === 'designReview'){
			meetingTypeFilterLabel = 'Design Review'
		}
		if(meetingTypeFilter === 'teamSync'){
			meetingTypeFilterLabel = 'Team Sync'
		}
		
		//

		let timeFilterLabel = 'Any time'

		if(timeFilter === 'future'){
			timeFilterLabel = 'Upcoming Meetings'
		}
		
		if(timeFilter === 'past'){
			timeFilterLabel = 'Past Meetings'
		}

		// //

		// let membershipFilterLabel

		// if(membershipFilter === 'everyone'){
		// 	membershipFilterLabel = 'Everyone'
		// }

		// if(membershipFilter === 'yourMeetings'){
		// 	membershipFilterLabel = 'Your Meetings'
		// }

		const filteredMeetings=this.getFilteredMeetings()

		const activeYourMeetings = !this.state.everyoneMeeting && meetingTypeFilter === null
		const activeAllMeetings = this.state.everyoneMeeting && meetingTypeFilter === null


	  return (
	  	<div className='meetingNotesPage-table'>

	  		{/*<div className='meetingNotesPage-table-header'>
	  			All Meetings
	  		</div>
	  		*/}

	  		<div className={'meetingNotesPage-table-filterBar ' + (meetingTypeFilter ? ' meetingNotesPage-table-filterBar--typeFilter--active ' : ' meetingNotesPage-table-filterBar--typeFilter--inactive ')}>
	  			
	  			{/*}
	  			<MeetingTypeFilterBtn 
		        type='designReview' 
		        label='Design Reviews'	        
		        activeFilter={meetingTypeFilter}
		        setMeetingTypeFilter={(value)=>{this.setState({meetingTypeFilter: value})}}
			    />
		   
			    <MeetingTypeFilterBtn 
		        type='discovery' 
		        label='Discovery Calls' 	        
		        activeFilter={meetingTypeFilter}
		        setMeetingTypeFilter={(value)=>{this.setState({meetingTypeFilter: value})}}
			    />

			    <MeetingTypeFilterBtn 
		        type='teamSync' 
		        label='Team Syncs'	        
		        activeFilter={meetingTypeFilter}
		        setMeetingTypeFilter={(value)=>{this.setState({meetingTypeFilter: value})}}
			    />	
			    */}	

			    {/* {meetingTypeFilter ? `type filter: ${meetingTypeFilter}` : 'no meeting type filter'} */}

					<button onClick={this.handleAllMeetingsClick} data-state={activeAllMeetings ? "active" : "inactive"} className='meetingNotesPage-table-filterBar-tabBtn'>
						<div className='meetingNotesPage-table-filterBar-tabBtn-iconContainer'>
							<Icon name='calendarSimple' />
						</div>
						<div className='meetingNotesPage-table-filterBar-tabBtn-label'>
							All Meetings
						</div>
					</button>
					<button onClick={()=>{this.setMeetingTypeFilter('designReview')}} data-state={meetingTypeFilter === 'designReview' ? "active" : "inactive"} className='meetingNotesPage-table-filterBar-tabBtn'>
						<div className='meetingNotesPage-table-filterBar-tabBtn-iconContainer'>
							<MeetingTypeIcon meetingType='designReview' />
						</div>
						<div className='meetingNotesPage-table-filterBar-tabBtn-label'>
							Design Reviews
						</div>
					</button>
					<button onClick={()=>{this.setMeetingTypeFilter('discovery')}} data-state={meetingTypeFilter === 'discovery' ? "active" : "inactive"}  className='meetingNotesPage-table-filterBar-tabBtn'>
						<div className='meetingNotesPage-table-filterBar-tabBtn-iconContainer'>
							<MeetingTypeIcon meetingType='discovery' />
						</div>
						<div className='meetingNotesPage-table-filterBar-tabBtn-label'>
							Discovery Calls
						</div>
					</button>
					<button onClick={()=>{this.setMeetingTypeFilter('teamSync')}} data-state={meetingTypeFilter === 'teamSync' ? "active" : "inactive"}  className='meetingNotesPage-table-filterBar-tabBtn'>
						<div className='meetingNotesPage-table-filterBar-tabBtn-iconContainer'>
							<MeetingTypeIcon meetingType='teamSync' />
						</div>
						<div className='meetingNotesPage-table-filterBar-tabBtn-label'>
							Team Syncs
						</div>
						
					</button>
					<button onClick={this.handleYourMeetingClick} data-state={activeYourMeetings ? "active" : "inactive"}   className='meetingNotesPage-table-filterBar-tabBtn'>
						<div className='meetingNotesPage-table-filterBar-tabBtn-iconContainer'>
							<Icon name='userCircle' />
						</div>
						<div className='meetingNotesPage-table-filterBar-tabBtn-label'>
							Your Meetings
						</div>
					</button>


					<div className='meetingNotesPage-table-filterBar-hSpacer' />	  	
					
					{/*}							
					{!meetingTypeFilter && 
						<MeetingsPageTableFilterPopover
		  				showPopover={this.state.showMeetingTypePopover}
		  				openPopover={()=>{this.setState({showMeetingTypePopover: true})}}
		  				closePopover={()=>{this.setState({showMeetingTypePopover: false})}}
		  				setMeetingTypeFilter={this.setMeetingTypeFilter}
		  			>
			  			<button onClick={()=>{this.setState({showMeetingTypePopover: true})}}  className='meetingNotesPage-table-filterBar-btn meetingNotesPage-table-filterBar-btn--type'>
			  				<div className='meetingNotesPage-table-filterBar-btn-iconContainer'>
			  					<Icon name='meetingType' />
			  				</div>
			  				<div className='meetingNotesPage-table-filterBar-btn-label'>
			  					Any Type
			  				</div>		  			
			  			</button>
		  			</MeetingsPageTableFilterPopover>
	  			}
	  			

	  			{meetingTypeFilter && 
		  			<button onClick={()=>{this.setState({meetingTypeFilter: null})}} className='meetingNotesPage-table-filterBar-btn meetingNotesPage-table-filterBar-btn--noType'>
		  				<div className='meetingNotesPage-table-filterBar-btn-iconContainer'>
		  					<Icon name={meetingTypeFilter} />
		  				</div>
		  				<div className='meetingNotesPage-table-filterBar-btn-label'>
		  					{meetingTypeFilterLabel}
		  				</div>
			  		</button>		
	  			}
	  			*/}



	  			{/*}

	  			<MeetingsPageTableFilterPopover
	  				showPopover={this.state.showTimeFilterPopover}
	  				openPopover={()=>{this.setState({showTimeFilterPopover: true})}}
	  				closePopover={()=>{this.setState({showTimeFilterPopover: false})}}
	  				setTimeFilter={(value)=>{this.setState({timeFilter: value})}}
	  			>
		  			<button onClick={()=>{this.setState({showTimeFilterPopover: true})}}  className='meetingNotesPage-table-filterBar-btn meetingNotesPage-table-filterBar-btn--time'>
		  				<div className='meetingNotesPage-table-filterBar-btn-iconContainer'>
		  					<Icon name='calendarSimple' />
		  				</div>
		  				<div className='meetingNotesPage-table-filterBar-btn-label'>
		  					{timeFilterLabel}
		  				</div>
		  			</button>
	  			</MeetingsPageTableFilterPopover>
	  			*/}

					{/*}
	  			<MeetingsPageTableFilterPopover
	  				showPopover={this.state.showMembershipFilterPopover}
	  				openPopover={()=>{this.setState({showMembershipFilterPopover: true})}}
	  				closePopover={()=>{this.setState({showMembershipFilterPopover: false})}}
	  			>
		  			
	  			</MeetingsPageTableFilterPopover>


	  			<button onClick={()=>{this.setState({everyoneMeeting: !this.state.everyoneMeeting})}}  className='meetingNotesPage-table-filterBar-btn meetingNotesPage-table-filterBar-btn--everyone'>
		  				<div className='meetingNotesPage-table-filterBar-btn-iconContainer'>
		  					<Icon name='userCircle' />
		  				</div>
		  				<div className='meetingNotesPage-table-filterBar-btn-label'>
		  					{everyoneMeeting ? 'Everyone':'Your Meetings' }
		  				</div>
		  			</button>
					*/}
	  			
	  			

	  			{/*}
	  			<MeetingsPageNewMeetingPopover
	  				showPopover={this.state.showNewMeetingPopover}
	  				openPopover={()=>{this.setState({showNewMeetingPopover: true})}}
	  				closePopover={()=>{this.setState({showNewMeetingPopover: false})}}
	  				createMeeting={(value)=>{this.props.createMeeting(value)}}
	  			>
		  			<button onClick={()=>{this.setState({showNewMeetingPopover: true})}} className='meetingNotesPage-table-filterBar-btn meetingNotesPage-table-filterBar-btn--newMeeting'>
		  				<div className='meetingNotesPage-table-filterBar-btn-iconContainer'>
		  					<Icon name='plus' />
		  				</div>
		  				<div className='meetingNotesPage-table-filterBar-btn-label'>
		  					New
		  				</div>
		  			</button>
	  			</MeetingsPageNewMeetingPopover>	  
	  			*/}				  		  
	  			

	  				<Tooltip.Provider>
						<Tooltip.Root delayDuration={0}>
							<Tooltip.Trigger asChild>
								<button onClick={()=>{this.props.createMeeting('')}} className='meetingNotesPage-table-filterBar-btn meetingNotesPage-table-filterBar-btn--newMeeting'>
				  				<div className='meetingNotesPage-table-filterBar-btn-iconContainer'>
				  					<Icon name='plus' />
				  				</div>
				  				<div className='meetingNotesPage-table-filterBar-btn-label'>
				  					New
				  				</div>
				  			</button>
							</Tooltip.Trigger>				
							<Tooltip.Content side="bottom" className="tooltip tooltip--textSelectionUI">								
								<KeyboardShortcutTag									
									command
									shift
									letter='M'
									keyboardShortcutOnly
								/>									
								</Tooltip.Content>													
						</Tooltip.Root>
					</Tooltip.Provider>

		  			
	  			
	  			

	  		</div>

	  		

	  		<div className='meetingNotesPage-table-list'>
		  		
		  		{filteredMeetings.map((meeting)=>{
	  				if(meeting.meeting_id){
	  					return(
		  					<MeetingsPageMeetingRow
	  						key={meeting.meeting_id} 
	  						meeting={meeting}
	  						section="nextWeek"
	  					/>
		  				)
	  				}
		  		})}

		  		{filteredMeetings && filteredMeetings.length < 1 &&
		  			<div className='meetingNotesPage-table-list-empty'>
		  			  No meetings yet. 
		  				<button onClick={()=>{this.props.createMeeting('')}} className='meetingNotesPage-table-list-empty-btn'> Add your first meeting</button>
		  			</div>
		  		}

		  		
		  		{/*}
	  			<button className='meetingNotesPage-table-list-emptyNewBtn'>	  				
	  				New blank meeting
	  			</button>
	  			<button className='meetingNotesPage-table-list-emptyNewBtn'>
	  				New design review
	  			</button>
	  			<button className='meetingNotesPage-table-list-emptyNewBtn'>
	  				New discovery call
	  			</button>
	  			<button className='meetingNotesPage-table-list-emptyNewBtn'>
	  				New team sync
	  			</button>
	  			*/}
	  		</div>
		 	</div>

	  )
	}
}

export default withRouter(MeetingsPageTable)