import React from 'react'
import Icon from '../../misc/Icon'
import AvatarImage from '../../AvatarImage'
import sortBy from 'lodash/sortBy'
import {getOrgMembers} from '../../../utils/getOrgMembers'
import {getContacts} from '../../../utils/meetings/getContacts'
import {getOrg} from '../../../utils/getOrg'


class ViewMeetingParticipantMemberRow extends React.Component{  		

	render(){	
		
		const {active, user, isContact, contact, selected} = this.props

		let contactName,contactCompany,contactEmail		
		if(isContact){
			contactName=contact.name 
			contactCompany=contact.company
			contactEmail=contact.email
		}										
		let contactPrimaryLabel = contactName
		if(!contactName){
			contactPrimaryLabel = contactEmail
		}
		let contactSecondaryLabel = contactCompany
		if(!contactCompany && contactName && contactEmail){
			contactSecondaryLabel = contactEmail
		}		
		if(!contactCompany && !contactName){
			contactSecondaryLabel = null
		}		

		return (
			<div className='popoverMenu-item popoverMenu-item--meetingParticipantRow'>
				{!isContact && 
					<div className='popoverMenu-item-avatarContainer'>					
						<AvatarImage smallAvatar className='popoverMenu-item-avatar' avatarUrl={user.avatar}/> 
					</div>
				}
				{!isContact && 
					<div className='popoverMenu-item-label'>
						{user.display_name}
					</div>
				}
				{isContact && 					
					<div className='popoverMenu-item-avatarContainer'>
						<AvatarImage className='popoverMenu-item--meetingParticipantRow-contactAvatar' contactId={contact.contact_id} sideLength={20}/> 
					</div>				
				}
				{isContact && 					
					<div className='popoverMenu-item-label'>	
						<div className='popoverMenu-item--meetingParticipantRow-primaryLabel'>
							{contactPrimaryLabel}
						</div>
						{contactSecondaryLabel && 
							<div className='popoverMenu-item--meetingParticipantRow-secondaryLabel'>
								{contactSecondaryLabel}
							</div>
						}
					</div>				
				}
				
			</div>
		)
	}
}



class ViewParticipantListPopoverContents extends React.Component{  

	constructor(props) {
    super(props)       	   	
    this.state = {

   	};    
  }

	render(){	
		const {participants,contactParticipants} = this.props	
		const orgMembers = getOrgMembers()	
		const contacts = getContacts()
		// alphabetical sort
		const sortedMembers = sortBy(orgMembers, function(user){
			return user.display_name.toString().toLowerCase()
   	})
   	const sortedContacts = sortBy(contacts, function(contact){
			return contact.name.toString().toLowerCase()
   	})		

	  return (
	  	<div className='viewMeetingParticipantsPopover-contents'>	  		
	  		<div className='viewMeetingParticipantsPopover-scrollList'>
		  		<div className='viewMeetingParticipantsPopover-participantList'>
				  	
				  	{sortedMembers.map((orgMember) => {
						  const isChecked = participants.some(participant => participant === orgMember.user_id);
					 		if(isChecked){
					 			return (
						    <ViewMeetingParticipantMemberRow
						      key={orgMember.user_id}
						      user={orgMember}
						      participants={participants}
						      externalParticipants={contactParticipants}						      
						    />
					  	)}
					  	else return null				 	
						})}	

						{sortedContacts.map((contact) => {
					  	const isChecked = contactParticipants.some(participant => participant === contact.contact_id);
						  if(isChecked){
					 		return (
						    <ViewMeetingParticipantMemberRow
						      key={contact.contact_id}
						      isContact
						      contact={contact}						      
						      participants={participants}
						      externalParticipants={contactParticipants}						      
						    />
					  	)}
					  	else return null 
					  })}

						{participants.length== 0 && contactParticipants.length==0 &&
					  	<div className='viewMeetingParticipantsPopover-participantList-emptyLabel viewMeetingParticipantsPopover-participantList-emptyLabel--invited'> 
					  		No participants yet
					  	</div>
				  	}

			  	</div>							  	
				</div>
			</div>
	  )
	}
}

export default ViewParticipantListPopoverContents
