
export function getAllImageNodes() {

	let imageNodes=[]
	if(window.view && window.view.state){
		let tr = window.view.state.tr
		window.view.state.doc.nodesBetween(0,tr.doc.content.size, (node, pos) => {
			if(node.type.name==="docImage" || node.type.name==="docThumbnail"){
				imageNodes.push(node)
			}	
		})

	}
	return imageNodes
}