import store from '../../store'
import {randomID} from '../randomID'
import {getCurrentUser} from '../getCurrentUser'
import {createMeetingWithId,createMeetingForDateRequest} from '../../actions/meetings'
import {createMeetingProjectConnection} from './createMeetingProjectConnection'
import {getProjectForId} from '../getProjectForId'
import find from 'lodash/find'
import { Mixpanel } from '../../Mixpanel'

const template={
	version:0,
	name:'Untitled Meeting',
	source:{
		"type": "doc", "content": [
			{"type": "editorPage", "content": [
				{"type": "paragraph"}
			]}
		]}
}

function getNewDocTemplate(){
	const currentUser=getCurrentUser()
	let doc={}
	const nowDate=new Date()
	if(currentUser){
		doc.created_by=currentUser.user_id
	}
	doc.created_at=nowDate
	doc.updated_at=nowDate
	doc.name=template.name
	doc.source=template.source
	doc.version=1
	doc.team=null
	doc.roadmap=null
	doc.project=null
	doc.is_org_doc=false 
	const docId=`${randomID()}`
	doc.doc_id=docId
	return doc
}







export function createNewMeetingForDate(date,history) {
	const meetingId=`${randomID()}`
	const docId=`${randomID()}`
	let meetingDoc=getNewDocTemplate()
	meetingDoc.meeting=meetingId
	meetingDoc.followers=[]
	meetingDoc.doc_id=docId
	store.dispatch(createMeetingForDateRequest(date,meetingId,docId,meetingDoc,history))
}





export function createNewMeetingFromTemplate(template,history) {
	let meetingType='blank'
	if(template){
		if(template.name=='Discovery Call'){
			meetingType='discovery'
		}else if(template.name=='Design Review'){
			meetingType='designReview'
		}else if(template.name=='Team Sync'){
			meetingType='teamSync'
		}
	}

	const meetingId=`${randomID()}`
	const docId=`${randomID()}`
	
	let meetingDoc=getNewDocTemplate()
	meetingDoc.meeting=meetingId
	meetingDoc.followers=[]
	meetingDoc.doc_id=docId
	if(template){
		meetingDoc.source=template.source
		meetingDoc.doc_html=template.html
		meetingDoc.template=template.template_id
		meetingDoc.name=name||`Untitled ${template.name}`
	}
	store.dispatch(createMeetingWithId(meetingType,meetingId,docId,meetingDoc,history))
}



export function createNewMeeting(type,history,meeting_id,name,template,addLinkToLinear,projectId) {
	console.log('create new meeting here-----------')
	console.log(`add link to linear----- ${addLinkToLinear}`)


	const meetingId=meeting_id ||`${randomID()}`
	const docId=`${randomID()}`
	
	let meetingDoc=getNewDocTemplate()
	meetingDoc.meeting=meetingId
	meetingDoc.followers=[]
	meetingDoc.doc_id=docId
	meetingDoc.name=name||'Untitled Meeting'

	if(template){
		meetingDoc.template=template.template_id
		meetingDoc.source=template.source
		meetingDoc.doc_html=template.html
	}

	store.dispatch(createMeetingWithId(type,meetingId,docId,meetingDoc,history,addLinkToLinear,projectId))
}


//new lets add a link to linear
export function createNewLinkedMeeting(type,projectId,history) { //for create new design review inside of project
	const meetingId=`${randomID()}`
	const project = getProjectForId(projectId)
	let name=''
	if(project){
		name=`${project.name} – Design Review`
	}
	const state = store.getState()
	const template = find(state.docTemplates,{name:'Design Review'})
	const addLinkToLinear=true

	createNewMeeting(type,history,meetingId,name,template,addLinkToLinear,projectId)
	createMeetingProjectConnection(meetingId,projectId)
	Mixpanel.track('create_linked_design_review',{project:project.name})
}


