import React from 'react'
import { Transition, animated, config } from 'react-spring'
import * as Popover from '@radix-ui/react-popover';
import DocProjectMentionsSheetContents from './DocProjectMentionsSheetContents'


class DocProjectMentionsSheet extends React.Component{  

  constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.state={
      forceMount: false  
    }
  }

  onRest() {  
    if(!this.props.showProjectMentionsSheet){
      this.setState({forceMount: false})
    }else{
      this.setState({forceMount: true})
    }
  }

  render(){ 

    const {showProjectMentionsSheet, closeProjectMentionsSheet} = this.props
    const {forceMount} = this.state

    let transformStart = 'translateX(60px)'
    let transformEnd = 'translateX(0px)'

    
        
    return (            
        
        <React.Fragment>

          <Popover.Root open={showProjectMentionsSheet} modal={true}>
            <Popover.Trigger asChild>
              <div className='docEditor-projectMentionsSheetAnchor'/>
            </Popover.Trigger>          
            <Popover.Portal forceMount={showProjectMentionsSheet ? true : forceMount}>
              <Popover.Content
                className={'docEditor-projectMentionsSheetContainer ' + (showProjectMentionsSheet ? ' docEditor-projectMentionsSheetContainer--visible ' : ' docEditor-projectMentionsSheetContainer--hidden ')} 
                onEscapeKeyDown={closeProjectMentionsSheet}
                onPointerDownOutside={closeProjectMentionsSheet}
                onInteractOutside={closeProjectMentionsSheet}
                side="left"        
                align="start"                
                forceMount={showProjectMentionsSheet ? true : forceMount}
              >
                <Transition
                  items={showProjectMentionsSheet}
                  from={{ opacity: 0, transform: `${transformStart}` }}
                  enter={{ opacity: 1, transform: `${transformEnd}`  }}
                  leave={{ opacity: 0, transform: `${transformStart}`  }}
                  reverse={showProjectMentionsSheet}
                  config={{tension: 700, friction: 36}}           
                  onRest={this.onRest}
                >
                  {(styles, item) =>
                    item && 
                     <animated.div style={styles} className="docEditor-projectMentionsSheet">
                      <DocProjectMentionsSheetContents
                        closeProjectMentionsSheet={closeProjectMentionsSheet}
                      />

                    </animated.div>
                  }
                </Transition>                 
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>

        </React.Fragment>


    )
  }
}

export default DocProjectMentionsSheet