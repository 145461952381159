import React from 'react';
import { Spring, animated, config, easings } from 'react-spring'

class LoadingProto extends React.Component {  	
  
  constructor(props) {
    super(props);    
    this.state = {      
    }
  }

  render() {		
    
    return (
      <div onClick={this.props.setLoadingComplete} className='loadingProto forceDarkTheme'>

          <div className='loadingPage-content'>
          <div className='loadingPage-bar'>
            <Spring
            native
              //opacity={active ? 1 : 0}          
              from={{
                opacity: 0.7,
                transform: 'translateX(-210px)'
              }}
              to={[
                { 
                  opacity: 0.25,
                  transform: 'translateX(208px)' 
                },                
              ]}
              //reverse
              loop
              config={{
                // mass: 0.1,
                // tension: 300,
                // friction: 100,
                //precision: 0.1,
                duration: 2000,

                // easing: easings.easeInOutQuart,
                easing: easings.easeInOutCubic,
              }}
            >
              {styles => 
                <animated.div style={styles} className='loadingPage-bar-ball' />
              }
            </Spring>             
          </div>

        </div>

      </div>
    )
  }
}

export default LoadingProto
