import store from '../store'
import find from 'lodash/find'

export function maybeAddTab(docId) {
	const state = store.getState()
	const openTabs=state.openTabs
	const {tabGroups,orderedTabGroups}=openTabs
	let tabAlreadyOpen=false
	
	Object.keys(tabGroups).forEach((groupId)=>{
		const tabs=tabGroups[groupId]
		if(find(tabs,{type:'doc',id:docId})){
			tabAlreadyOpen=true
		}
	})
	if(!tabAlreadyOpen){
		let newTabGroups=Object.assign({},tabGroups)
		let newDefaultGroup=newTabGroups.default
		newDefaultGroup.push({type:'doc',id:docId})
		newTabGroups.default=newDefaultGroup
		store.dispatch({type:'UPDATE_OPEN_TAB_GROUPS',tabGroups:newTabGroups,orderedTabGroups:orderedTabGroups})
	}		
}