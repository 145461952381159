import React from 'react'
import * as Popover from '@radix-ui/react-popover';
import { Transition, animated, config } from 'react-spring'
import LinearProjectPreviewPopoverContent from './LinearProjectPreviewPopoverContent'


class LinearProjectPreviewPopover extends React.Component{  	

	constructor(props){
    super(props)
    this.onRest=this.onRest.bind(this)    
    this.state={
      forceMount: false,      
    }
  }

	onRest() {  
    if(!this.props.showPopover){
      this.setState({forceMount: false})
    } else{
      this.setState({forceMount: true})
    }
  }	

	render(){	
		const {showPopover, openPopover, closePopover, name, leadUserId, description, memberIds, teamIds, status, projectId} = this.props
		const {forceMount} = this.state

		const translateX = -10		
		
		return (
			<React.Fragment>
				<Popover.Root open={showPopover}>
					<Popover.Trigger asChild>
						{this.props.children}
					</Popover.Trigger>
					<Popover.Portal forceMount={showPopover ? true : forceMount}>
						<Popover.Content
							className={'linearIssuePreviewPopoverOuterContainer ' + (showPopover ? ' linearIssuePreviewPopoverOuterContainer--visible ' : ' linearIssuePreviewPopoverOuterContainer--hidden ')} 
							onEscapeKeyDown={closePopover}
							onPointerDownOutside={closePopover}
							onInteractOutside={closePopover}							
							side="left"						
							align="center"
							sideOffset={8}
							forceMount={showPopover ? true : forceMount}
							//asChild
						>
							<Transition
								items={showPopover}
								from={{ opacity: 0, transform: `translateX(${translateX}px)` }}
								enter={{ opacity: 1, transform: 'translateX(0px)'  }}
								leave={{ opacity: 0, transform: `translateX(${translateX}px)`  }}
								reverse={showPopover}
								config={{tension: 600, friction: 35}}
								onRest={this.onRest}
							>
								{(styles, item) =>
									item && 
									 <animated.div style={styles} className="linearIssuePreviewPopoverInnerContainer"> 	
										<LinearProjectPreviewPopoverContent
											name={name}
											leadUserId={leadUserId}
											description={description}
											memberIds={memberIds}
											teamIds={teamIds}
											status={status}
											projectId={projectId}
										/>
									</animated.div>
								}
							</Transition>									
						</Popover.Content>
					</Popover.Portal>
				</Popover.Root>		
			</React.Fragment>
		)
	}
}

export default LinearProjectPreviewPopover