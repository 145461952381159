import store from '../../store'
import find from 'lodash/find'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'


const templateCompany = [
  {title: "Competitor Analysis", company: "yarn"},
  {title: "Customer Persona", company: "yarn"},
  {title: "Amazon Press Release", company: "amazon"},
  {title: "Postmortem", company: "pagerduty"},
  {title: "PRD", company: "square"},
  {title: "Product Brief", company: "linear"},
  {title: "RFC", company: "react"},
  {title: "Service RFC", company: "uber"},
  {title: "Stripe's Operating Principles", company: "stripe"},
  {title: "Enterprise PRD", company: "figma"},
  {title: "Technical Spec", company: "mixpanel"},
  {title: "\"We don't sell saddles here\"", company: "slack"},
  {title: "\"Ten things we know to be true\"", company: "slack"},
]


const blankTemplate={
	label:'Blank',
	name:'blank',
	template_id:'blank',
	source:{
		"type": "doc", "content": [
			{"type": "editorPage", "content": [
				{"type": "paragraph"}
			]}
		]}
}

//const selectedPublicTemplateNames=['Product Brief','Technical Spec','RFC']

const publicTemplateNames={
	projectRootDoc:['Product Brief','Technical Spec','RFC'],
	roadmapDoc:['Competitor Battlecard','Product Brief','PR/FAQ'],
	orgDoc:['Competitor Battlecard','User Persona',"\"We don't sell saddles here\""],
	projectSubDoc:['Product Brief','Technical Spec','RFC'] //milestones
}

const publicMeetingTemplateNames=['Design Review','Discovery Call','Team Sync']

const recurringMeetingTemplateNames=['Team Sync','Design Review','Discovery Call','Team Sync']


export function getInDocTemplateOptions(docType,isMeeting,isRecurring) {	
	if(!isMeeting){
		const selectedPublicTemplateNames=publicTemplateNames[docType]
		const state = store.getState()
		const docTemplates = state.docTemplates
		const orgTemplates=filter(docTemplates,{public:false})
		const sortedOrgTemplates = sortBy(orgTemplates, function(template){
			const templateName=template.name||'untitled' //fix for ones without names
			let name = templateName.toString();
			name = name.replace(/['"]+/g, ''); // need to remove quotes because they effect the sort order
			return name.toLowerCase();
		})

		let selectedPublicTemplates=[]
		if(selectedPublicTemplateNames){
			selectedPublicTemplateNames.forEach((name)=>{
			const template = find(docTemplates,{name:name})
			if(template){
				selectedPublicTemplates.push(template)
			}
		})
		}
		
		let defaults = [...sortedOrgTemplates.slice(0,3),...selectedPublicTemplates]
		return [blankTemplate,...defaults,{label:'Modal',template:'modal',template_id:'modal'}] 
	}else{
		//console.log(`in template options---- is recurring ${isRecurring}`)

		let selectedPublicTemplateNames=publicMeetingTemplateNames
		if(isRecurring){
			selectedPublicTemplateNames=recurringMeetingTemplateNames
		}
		const state = store.getState()
		const docTemplates = state.docTemplates
		let selectedPublicTemplates=[]
		
		selectedPublicTemplateNames.forEach((name)=>{
			const template = find(docTemplates,{name:name})
			if(template){
				selectedPublicTemplates.push(template)
			}
		})

		//let defaults = [...sortedOrgTemplates.slice(0,3),...selectedPublicTemplates]
		return [...selectedPublicTemplates,blankTemplate] 
	}
	
}
