import findIndex from 'lodash/findIndex'

export default function (state = [], action) {
	switch (action.type) {

	case 'FETCH_RECENT_ACTIVITIES_SUCCESS':
		return action.response

 	case 'UPDATE_RECENT_ACTIVITY_SUCCESS':
		const activityId = action.response['recent_activity_id']
		let index=findIndex(state,{recent_activity_id:activityId})
		if(index===-1){
			index=state.length
		}
		return [
				...state.slice(0,index),
				action.response,
				...state.slice(index + 1)
			]

	case 'SIGN_OUT':
      return []
	

	default:
		return state
	}
}



