import React from 'react'
import {getProjectForId} from '../../utils/getProjectForId'


class DocEditorProjectTitle extends React.Component{  


	render(){	    

		
    const projectObj  = getProjectForId(this.props.projectId)
    let name
    if(projectObj){
      name = projectObj.name
    }
    

	  return (	  	
            
      <div  onClick={this.props.openEditProjectModal} className='docEditor-docTitle-projectDocTitle'>
      	<div className='docEditor-docTitle-projectDocTitle-title'>
        	{name}
        </div>
        <div className='docEditor-docTitle-projectDocTitle-bg' />
      </div>      
	  )
	}
}

export default DocEditorProjectTitle
