import React from 'react'
import Icon from '../../misc/Icon'
import * as ContextMenu from '@radix-ui/react-context-menu';
import {getRoadmaps} from '../../../utils/getRoadmaps'
import {getRoadmapsForProject} from '../../../utils/getRoadmapsForProject'
import StaticCheckbox from '../../uiKit/StaticCheckbox'

class RoadmapsProjectRowEditRoadmapsContextSubMenuItem extends React.Component{  	

	constructor(props){
		super(props)   
		this.handleSelectRoadmap=this.handleSelectRoadmap.bind(this)
		this.state={
      checked: this.props.active
    }
	}

	handleSelectRoadmap(){
		const newCheckedStatus=!this.state.checked
		let newRoadmapsArray=[]
		if(newCheckedStatus==true){
			this.props.projectRoadmaps.forEach((roadmapId)=>{
				newRoadmapsArray.push(roadmapId)
			})
			newRoadmapsArray.push(this.props.roadmap.roadmap_id)
			
		}else{
			this.props.projectRoadmaps.forEach((roadmapId)=>{
				if(roadmapId != this.props.roadmap.roadmap_id){
					newRoadmapsArray.push(roadmapId)
				}
			})
		}
		this.setState({checked: newCheckedStatus})	
		this.props.updateProjectRoadmaps(newRoadmapsArray)
	}

	render(){	
		const {active, roadmap} = this.props	
		const {checked} = this.state

		return (
			<div onClick={this.handleSelectRoadmap} className='popoverMenu-item popoverMenu-item--editProjectRoadmaps '>
								
				<div className='popoverMenu-item-markerContainer'>
					<Icon name='roadmapOutline' />					
				</div>

				<div className='popoverMenu-item-label'>
					{roadmap.name}
				</div>	
				
				<div className='popoverMenu-item-checkboxContainer'>
					<StaticCheckbox 
						checked={checked}
					/>					
				</div>			

			</div>
		)
	}
}


class RoadmapsProjectRowEditRoadmapsContextSubMenu extends React.Component{  	


	render(){	
		const {projectId, closePopover,projectRoadmaps} = this.props


		const orgRoadmaps = getRoadmaps()		

		return (
			<React.Fragment>         	

				{orgRoadmaps
					//used GTP for this, dunno if it's okay
					.sort((a, b) => a.name.localeCompare(b.name)) // Sort orgRoadmaps alphabetically by the name field
					.map((roadmap) => {
				  // Check if the current roadmap exists in the projectRoadmaps list
				  const isActive = projectRoadmaps.some(projectRoadmap => projectRoadmap === roadmap.roadmap_id);

				  return (
				    <RoadmapsProjectRowEditRoadmapsContextSubMenuItem
				      key={roadmap.roadmap_id}
				      closePopover={closePopover}
				      roadmap={roadmap}
				      projectRoadmaps={this.props.projectRoadmaps}
				      updateProjectRoadmaps={this.props.updateProjectRoadmaps}
				      active={isActive} // Set the active prop based on the isActive variable
				    />
				  );
				})}
					
			</React.Fragment>
		)
	}
}

export default RoadmapsProjectRowEditRoadmapsContextSubMenu